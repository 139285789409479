import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _ from 'lodash';

class CertificationRatingManager extends ItemManager {
    constructor(observationMgr, globalItemState, globalAuthState, globalAppUserState) {
        super(ItemServiceFactory.ItemServiceEnum.CertificationContentRubricRating, globalAuthState, globalAppUserState, globalItemState,
            (filter, item) => {
                var hasSearch = item?.Name?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
                return hasSearch
            });
        this._errorUtil = undefined;
        this._rubricMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Rubric, globalAuthState, globalAppUserState);
        this._observationMgr = observationMgr;
    }

    async grabCertificationRatingsKey(rubricId, contentId) {
        return await this._rubricMgr.runOperation("CertificationRubricKey", rubricId, undefined, { contentId: contentId }).then(r => { return r.Items.first() })
    }

    async transformRubric(rubric) {
        return await this._observationMgr.transformData(rubric)
    }
    get DefaultItem() {
        return {
        };
    }
}

export default CertificationRatingManager;