import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIsAuthenticated, useMsal, useAccount } from '@azure/msal-react';
import { useHookstate } from '@hookstate/core';
import globalAuthStateStore from '../stores/globalAuthStateStore';
import { InteractionStatus, EventType } from '@azure/msal-browser';
import globalAppUserStore, {
  APIStatusType,
} from '../stores/globalAppUserStore';
import { useAppUserManager } from '../hooks/useManagers';
import { ProfileUpdateTypeEnum } from '../domains/profile/types/ProfileUpdateType.enum';
import { UserProfileItemService } from "../services/ItemServices";
import config from '../config.json';

import _ from 'lodash';
import PublicLayout from '../components/layout/PublicLayout';

let _timeoutKey = -1;

export function useDefaultRoute(redirectUrl) {
  const navigate = useNavigate();
  const appUserMgr = useAppUserManager();
  useEffect(() => {
    if (appUserMgr?.AppUserState?.apiStatus == APIStatusType?.OnlineSecure) {
      if (redirectUrl)
        navigate(redirectUrl);
      else
        navigate(appUserMgr.defaultRoute());
    }
  }, [appUserMgr])
}

export function useCheckAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const globalAppUserState = useHookstate(globalAppUserStore);
  const appUser = globalAppUserState.get();
  const authErrorPath = '/AuthError';

  useEffect(() => {
    if (
      isAuthenticated &&
      appUser.initFailed &&
      location.pathname.toLowerCase() != authErrorPath.toLowerCase()
    ) {
      navigate(authErrorPath);
    }
  }, [appUser]);

  return isAuthenticated;
}

export function useAuthState() {
  const globalAuthState = useHookstate(globalAuthStateStore);
  return globalAuthState;
}

//Only call in app.js
export function useManageAuth() {
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const globalAuthState = useHookstate(globalAuthStateStore);
  const authState = globalAuthState.get();

  if (!location.pathname.toLowerCase().startsWith("/logout") &&
    !location.pathname.toLowerCase().startsWith("/login") &&
    !location.pathname.toLowerCase().startsWith("/auth") &&
    location.pathname.toLowerCase() !== "/") {
    if(!window.localStorage.getItem("LastUri")?.startsWith(location.pathname))
    //console.log("LastUri question", location.pathname + location.search)
      window.localStorage.setItem("LastUri", location.pathname + location.search);
  }

  const checkForToken = () => {
    const expireDateValue = authState?.idTokenClaims
      ? new Date(authState.idTokenClaims.exp * 1000)
      : null;

    const expiresIn =
      expireDateValue !== null
        ? expireDateValue?.getTime() - new Date().getTime()
        : 0;

    if (expiresIn !== null) {
      if (_timeoutKey > -1) clearTimeout(_timeoutKey);

      const refreshTokenTime = expiresIn - 15000;
      _timeoutKey = setTimeout(() => {
        const expireDate = authState?.idTokenClaims
          ? new Date(authState.idTokenClaims.exp * 1000)
          : null;
        //console.log("Token Refresh Exp: " + expireDate);

        const newAuthState = {
          isAuthenticated,
          isAuthLoadComplete: inProgress === InteractionStatus.None,
        };
        if (newAuthState?.isAuthenticated && newAuthState?.isAuthLoadComplete) {
          const profileUpdateForceToken = sessionStorage.getItem(
            'profile-update-force-token'
          );
          if (profileUpdateForceToken) {
            sessionStorage.removeItem('profile-update-force-token');
          }

          instance
            .acquireTokenSilent({
              account: accounts[0],
              forceRefresh: profileUpdateForceToken !== null,
              scopes: [
                `https://${config.AUTH.ADB2C_TENANT_NAME}.onmicrosoft.com/core-api/core-api.access`,
              ],
            })
            .then(async (response) => {
              let accessToken = response.accessToken;
              let idToken = response.idToken;
              let idTokenClaims = response.idTokenClaims;
              globalAuthState.merge({
                userId: authState?.idTokenClaims?.oid,
                accessToken,
                isAuthLoadComplete: true,
                isAuthenticated: true,
                idToken,
                idTokenClaims,
              });
              //RZ: Removing this feature to address deadlocking issues in the UI, need to find a better way to address updates from B2C back to the client.
              /*var userProfileItemSvc = new UserProfileItemService(
                globalAuthState
              );
              await userProfileItemSvc.getOperation(
                "AzureB2CUpdateUserProfile", authState.idTokenClaims.oid);*/
            })
            .catch((error) => {
              console.log('Silent token acquisition failed, logging out.');
              localStorage.clear();
              sessionStorage.clear();
              window.location.href = "/";
            });
        } else if (
          authState.isAuthenticated != newAuthState.isAuthenticated ||
          authState.isAuthLoadComplete != newAuthState.isAuthLoadComplete
        ) {
          globalAuthState.merge(newAuthState);
        }
      }, refreshTokenTime);
    }
  }

  useEffect(() => {
    checkForToken();
  }, [authState, globalAppUserStore, isAuthenticated, instance, accounts, inProgress, navigate]);


  useEffect(() => {
    const callbackId = instance.addEventCallback(async (message) => {
      if (message.eventType === EventType.LOGIN_FAILURE) {
        navigate('/logout');
      } else if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        const profileUpdateType = sessionStorage.getItem('profile-update-type');

        const skipLogoutFromProfileScreen =
          profileUpdateType !== null &&
          profileUpdateType !== ProfileUpdateTypeEnum.PROFILE_CONFIGURE_MFA;

        if (profileUpdateType) {
          sessionStorage.removeItem('profile-update-type');
        }

        if (!skipLogoutFromProfileScreen) {
          navigate('/logout');
        } else {
          sessionStorage.setItem('profile-update-force-token', true);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return <PublicLayout></PublicLayout>;
}
