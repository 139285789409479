

//https://blog.logrocket.com/simplify-react-state-management-with-hookstate/
import { hookstate } from '@hookstate/core'

const adminContentStore = hookstate({
    allContent: null,
    contentFilter: { tagIds: [], contentTypeIds: [], search: null, isEnabled: undefined },
    filteredContent: [],
    avaliableContentFilters: null,
    selectedContent: null,
    forceContentUpdate: false
});
export default adminContentStore;