import _, { debounce } from 'lodash';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { CheckTreePicker } from 'rsuite';

const UserOrgTreeControl = ({
    className,
    childrenKey,
    labelKey,
    valueKey,
    data,
    selectedValues,
    defaultValues,
    type,
    menuClassName,
    onClean,
    onSelect,
    placement,
    disabled
}) => {
    const [visibleData, setVisibleData] = useState([])
    const [fullData, setFullData] = useState([])
    const [orgIsSearching, setOrgIsSearching] = useState(false)
    const [expandedValues, setExpandedValues] = useState([])
    useEffect(() => {
        if (data) {
            if (defaultValues) {

                const filterChildren = (children) => {
                    if (!children) {
                        return [];
                    }
                    return children.map(x => {
                        return {
                            ...x,
                            Children: filterChildren(x.Children)
                        };
                    }).filter(x => {
                        return defaultValues.includes(x.Id) || x.Children.length > 0;
                    });
                };

                setVisibleData(data.map(o => {
                    return {
                        ...o,
                        Children: filterChildren(o.Children)
                    }
                }))
            } else {

                setVisibleData(data.map(x => {
                    return {
                        ...x, Children: []
                    }
                }))
            }
        }
    }, [data])
    const insertItem = useCallback((value, fullTree, updatedGroup, selectedIds) => {
        fullTree.forEach((org) => {
            if (org.Name.toLowerCase().includes(value?.toLowerCase())) {
                updatedGroup.push({ ...org, Children: [] })
            } else if (selectedIds?.includes(org.Id)) {
                updatedGroup.push({ ...org, visible: false, check: true, Children: [] })
            }
            if (org.Children && org.Children.length > 0) {
                org.Children.forEach((district) => {
                    const orgIndex = _.findIndex(updatedGroup, x => x.Id === org.Id)
                    if (district.Name.toLowerCase().includes(value?.toLowerCase())) {
                        if (orgIndex === -1) {
                            updatedGroup.push({ ...org, Children: [{ ...district, Children: [] }] })
                        } else {
                            updatedGroup[orgIndex].Children.push({ ...district, Children: [] })
                        }
                    } else if (selectedIds?.includes(district.Id)) {
                        if (orgIndex === -1) {
                            updatedGroup.push({ ...org, visible: false, Children: [{ ...district, visible: false, check: true, Children: [] }] })
                        } else {
                            updatedGroup[orgIndex].Children.push({ ...district, visible: false, check: true, Children: [] })
                        }
                    }
                    if (district.Children && district.Children.length > 0) {
                        district.Children.forEach((school) => {
                            const orgIndex = _.findIndex(updatedGroup, x => x.Id === org.Id)
                            if (school.Name.toLowerCase().includes(value?.toLowerCase()) && value) {
                                if (orgIndex === -1) {
                                    updatedGroup.push({ ...org, Children: [{ ...district, Children: [{ ...school, Children: [] }] }] })
                                } else {
                                    const districtIndex = _.findIndex(updatedGroup[orgIndex].Children, x => x.Id === district.Id)
                                    if (districtIndex === -1) {
                                        updatedGroup[orgIndex].Children.push({ ...district, Children: [{ ...school, Children: [] }] })
                                    } else {
                                        updatedGroup[orgIndex].Children[districtIndex].Children.push({ ...school, Children: [] })
                                    }
                                }

                            } else if (selectedIds?.includes(school.Id)) {
                                if (orgIndex === -1) {
                                    updatedGroup.push({ ...org, Children: [{ ...district, Children: [{ ...school, visible: false, check: true, Children: [] }] }] })
                                } else {
                                    const districtIndex = _.findIndex(updatedGroup[orgIndex].Children, x => x.Id === district.Id)
                                    if (districtIndex === -1) {
                                        updatedGroup[orgIndex].Children.push({ ...district, visible: false, Children: [{ ...school, visible: false, check: true, Children: [] }] })
                                    } else {
                                        updatedGroup[orgIndex].Children[districtIndex].Children.push({ ...school, visible: false, check: true, Children: [] })
                                    }
                                }
                            }
                            if (school.Children && school.Children.length > 0) {
                                school.Children.forEach((user) => {
                                    const orgIndex = _.findIndex(updatedGroup, x => x.Id === org.Id)
                                    if (user.Name.toLowerCase().includes(value?.toLowerCase()) && value) {
                                        if (orgIndex === -1) {
                                            updatedGroup.push({ ...org, Children: [{ ...district, Children: [{ ...school, Children: [user] }] }] })
                                        } else {
                                            const districtIndex = _.findIndex(updatedGroup.Children, x => x.Id === district.Id)
                                            if (districtIndex === -1) {
                                                updatedGroup[orgIndex].Children.push({ ...district, Children: [{ ...school, Children: [user] }] })
                                            } else {
                                                const schoolIndex = _.findIndex(updatedGroup, x => x.Id === school.Id)
                                                if (schoolIndex === -1) {
                                                    updatedGroup[orgIndex].Children[districtIndex].push({ ...school, Children: [user] })
                                                } else {
                                                    updatedGroup[orgIndex].Children[districtIndex].Children[schoolIndex].push({ user })
                                                }
                                            }
                                        }

                                    } else if (selectedIds?.includes(user.Id)) {
                                        if (orgIndex === -1) {
                                            updatedGroup.push({ ...org, Children: [{ ...district, Children: [{ ...school, Children: [{ ...user, visible: false, check: true, }] }] }] })
                                        } else {
                                            const districtIndex = _.findIndex(updatedGroup.Children, x => x.Id === district.Id)
                                            if (districtIndex === -1) {
                                                updatedGroup[orgIndex].Children.push({ ...district, Children: [{ ...school, Children: [{ ...user, visible: false, check: true, }] }] })
                                            } else {
                                                const schoolIndex = _.findIndex(updatedGroup, x => x.Id === school.Id)
                                                if (schoolIndex === -1) {
                                                    updatedGroup[orgIndex].Children[districtIndex].push({ ...school, Children: [{ ...user, visible: false, check: true, }] })
                                                } else {
                                                    updatedGroup[orgIndex].Children[districtIndex].Children[schoolIndex].push({ ...user, visible: false, check: true, })
                                                }
                                            }
                                        }
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }, [])

    const handleOnSearch = (value) => {
        let updatedGroup = []
        insertItem(value, data, updatedGroup, selectedValues)
        if (value !== undefined && value !== null && value !== false && value !== '') {
            setOrgIsSearching(true)
        } else {
            setOrgIsSearching(false)
        }
        setVisibleData(updatedGroup)
    }

    const handleOnExit = () => {
        if (selectedValues && selectedValues.length === 0) {
            setOrgIsSearching(false)
            setVisibleData(data?.map(x => {
                return {
                    ...x, Children: []
                }
            }))
        }
    }
    const handleOnExpand = (array, item) => {
        const removeSubValues = []
        let updatedGroup = [...visibleData]
        const org = data.find(x => x.OrgId === item.OrgId)
        const orgIndex = _.findIndex(updatedGroup, x => x.OrgId === item.OrgId)
        const nodeTypePrio = {
            Org: 1,
            District: 2,
            School: 3
        }

        const recursiveFinder = (children, isExpand) => {
            children?.forEach(x => {

                if (selectedValues?.includes(x.Id) && x.NodeType !== item.NodeType) {
                    addChild(x)
                }
                if (!isExpand && nodeTypePrio[x.NodeType] >= nodeTypePrio[item.NodeType]) {
                    removeSubValues.push(x.Id)
                }
                if (x.Children && x.Children.length > 0) {
                    recursiveFinder(x.Children)
                }
            })
        }
        const addChild = (itemToAdd) => {
            if (itemToAdd.NodeType === 'School') {
                const districtIndex = _.findIndex(updatedGroup[orgIndex].Children, d => d.DistrictId === itemToAdd.DistrictId)
                const district = org.Children.find(d => d.DistrictId === itemToAdd.DistrictId)
                if (districtIndex === -1) {
                    updatedGroup[orgIndex].Children.push({ ...district, Children: district.Children.filter(s => selectedValues?.includes(s.Id)) })
                } else {
                    updatedGroup[orgIndex].Children[districtIndex].Children = district.Children.filter(s => selectedValues?.includes(s.Id))
                }
            } else if (itemToAdd.NodeType === 'User') {

                if (itemToAdd.DistrictId) {
                    const districtIndex = _.findIndex(updatedGroup[orgIndex].Children, d => d.DistrictId === itemToAdd.DistrictId)
                    const district = org.Children.find(d => d.DistrictId === itemToAdd.DistrictId)
                    if (districtIndex === -1) {
                        updatedGroup[orgIndex].Children.push({
                            ...district, Children: district.Children.filter(s => selectedValues?.includes(s.Id)).map(newSchool => {
                                return {
                                    ...newSchool,
                                    Children: newSchool.Children?.filter(u => selectedValues?.includes(u.Id))
                                }
                            })
                        })
                    } else {
                        if (itemToAdd.SchoolId) {
                            const school = district.Children.find(s => s.SchoolId === itemToAdd.SchoolId)
                            const schoolIndex = _.findIndex(updatedGroup[orgIndex].Children[districtIndex].Children, x => x.SchoolId === itemToAdd.SchoolId)
                            if (schoolIndex === -1) {
                                updatedGroup[orgIndex].Children[districtIndex].Children.push({ ...school, Children: school.Children.filter(u => selectedValues?.includes(u.Id)) })
                            } else {
                                updatedGroup[orgIndex].Children[districtIndex].Children[schoolIndex].Children = school.Children.filter(u => selectedValues?.includes(u.Id))
                            }
                        } else {
                            updatedGroup[orgIndex].Children[districtIndex].Children = district.Children.filter(x => selectedValues?.includes(x.Id))
                        }
                    }
                } else {
                    updatedGroup[orgIndex].Children = org.Children.filter(x => selectedValues?.includes(x.Id))
                }
            }
        }

        if (expandedValues.includes(item.Id)) {

            //Loop over children
            //If satisfy condition, updated the satisfied depth.
            //If there are no more childrens, use the satisfied depth to populate all of its parents.
            //If the childrens have childrens, loop over them again. 

            if (item.NodeType === "Org") {
                updatedGroup[orgIndex].Children = []
            } else if (item.NodeType === "District") {
                const districtIndex = _.findIndex(updatedGroup[orgIndex].Children, x => x.Id === item.Id)
                updatedGroup[orgIndex].Children[districtIndex].Children = []
            } else if (item.NodeType === "School") {
                const districtIndex = _.findIndex(updatedGroup[orgIndex].Children, x => x.DistrictId === item.DistrictId)
                const schoolIndex = _.findIndex(updatedGroup[orgIndex].Children[districtIndex].Children, x => x.Id === item.Id)
                updatedGroup[orgIndex].Children[districtIndex].Children[schoolIndex].Children = []
            }
            recursiveFinder(org.Children)
            setExpandedValues(expandedValues.filter(x => x !== item.Id && !removeSubValues.includes(x)))

        } else {
            setExpandedValues([...expandedValues, item.Id])
            if (item.NodeType === 'Org') {

                //add children if theres any
                updatedGroup[orgIndex].Children = org.Children?.map(d => {
                    return {
                        ...d,
                        Children: d.Children?.map(s => {
                            return {
                                ...s,
                                Children: s.Children?.filter(u => selectedValues?.includes(u.Id))
                            }
                        }).filter(sf => selectedValues?.includes(sf.Id) || (sf.Children && sf.Children?.length > 0))
                    }
                })
            } else if (item.NodeType === 'District') {
                const districtIndex = _.findIndex(updatedGroup[orgIndex].Children, x => x.Id === item.Id)
                const district = org.Children.find(x => x.Id === item.Id)
                updatedGroup[orgIndex].Children[districtIndex].Children = district.Children?.map(s => {
                    return {
                        ...s,
                        Children: s.Children?.filter(u => selectedValues?.includes(u.Id))
                    }
                })
            } else if (item.NodeType === 'School') {
                const orgIndex = _.findIndex(updatedGroup, x => x.OrgId === item.OrgId)
                const districtIndex = _.findIndex(updatedGroup[orgIndex].Children, x => x.DistrictId === item.DistrictId)
                const schoolIndex = _.findIndex(updatedGroup[orgIndex].Children[districtIndex].Children, x => x.Id === item.Id)
                updatedGroup[orgIndex].Children[districtIndex].Children[schoolIndex].Children = org.Children.find(x => x.DistrictId === item.DistrictId).Children.find(x => x.Id === item.Id).Children
            }
        }


        setVisibleData(updatedGroup)
    }
    //going throgh Districts, Districts

    return (
        <CheckTreePicker
            virtualized={true}
            className={className}
            childrenKey={childrenKey}
            labelKey={labelKey}
            valueKey={valueKey}
            data={visibleData}
            menuStyle={{
                width: '300px',
            }}
            defaultValue={defaultValues}
            disabled={disabled}
            expandItemValues={expandedValues}
            menuClassName={menuClassName}
            onExit={() => handleOnExit()}
            onClean={onClean}
            onSelect={onSelect}
            onSearch={debounce((value, e) => handleOnSearch(value, e), 300)}
            onExpand={!orgIsSearching ? (array, item) => handleOnExpand(array, item) : null}
            placement={placement}
        />
    );
};

export default UserOrgTreeControl;