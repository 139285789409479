import { useEffect, useState } from "react";
import "./details.scss";
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import {
	CheckboxControl,
	DatePickerControl,
	SelectListControl,
} from "../../../../components/controls";
import { useValidationManager } from "../../../../hooks/useValidation";
import ReactQuill from "react-quill";
import { CheckTreePicker, TreePicker } from "rsuite";
import { useOrgManager } from "../../../../hooks/useManagers";
import Loading from "../../../../components/Loading";
import _ from "lodash";
import { FormatUTCDateM, FormatUTCDateTime, FormatUTCDateTimeM } from "../../../../utilities/DateFormater";
import moment from "moment/moment";
import UserOrgTreeControl from '../../../../components/controls/UserOrgTreeControl';

const NotificationDetails = ({
	notificationMgr,
	notification,
	onCancel,
	onSave,
	onErrors,
	onValidationErrors,
}) => {
	const [notificationItem, setNotificationItem] = useState(
		notification ?? notificationMgr.DefaultItem
	);
	const [saving, setSaving] = useState(false);
	const [forceValidate, setForceValidate] = useState(false);
	const validationMgr = useValidationManager(false, "NOTIFICATION");

	const orgMgr = useOrgManager();
	const [orgUserTreeData, setUserTreeData] = useState(null); // the overall org tree
	const [itemOrgTreeDefault, setItemOrgTreeDefault] = useState(null); // the org tree for the notification item
	const [itemOrgTree, setItemOrgTree] = useState(null);
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);

	useEffect(() => {
		if (orgMgr) {
			orgMgr.loadOrgUserTree().then((orgTree) => {
				setUserTreeData(orgTree);
				let customOrgTree = [];

				['OrganizationIds', 'DistrictIds', 'SchoolIds', 'ClusterIds', 'UserIds'].forEach((division) => {
					if (division === "OrganizationIds" && notificationItem["OrganizationIdsData"] !== null && notificationItem["OrganizationIdsData"] !== "") {
						_.forEach(notificationItem["OrganizationIdsData"]?.split(","), (id) => {
							customOrgTree.push(`o-` + id);
						})
					} else if (notificationItem[division] !== undefined && notificationItem[division] !== null && notificationItem[division] !== "") {
						_.forEach(notificationItem[division], (id) => {
							customOrgTree.push(`${division.charAt(0).toLowerCase()}-` + id);
						})
					}

				})
				setItemOrgTree(customOrgTree)
				setItemOrgTreeDefault(customOrgTree);
			});
		}
	}, [orgMgr]);

	useEffect(() => {
		if (!fromDate) {
			if (notificationItem && !notificationItem?.FromDate) {
				setFromDate(undefined);
			}
			else if (notificationItem && notificationItem?.FromDate) {
				setFromDate(FormatUTCDateTimeM(notificationItem?.FromDate));
			}
		}
	}, [notificationItem?.FromDate])

	useEffect(() => {
		if (!toDate) {
			if (notificationItem && !notificationItem?.ToDate) {

				setToDate(undefined);
			}
			else if (notificationItem && notificationItem?.ToDate) {
				setToDate(FormatUTCDateTimeM(notificationItem?.ToDate));
			}
		}
	}, [notificationItem?.ToDate])

	const handleOnCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const handleOnSave = async (e) => {
		/*if (!notificationItem.FromDate) {
			notificationItem.FromDate = moment().utc();
		}*/

		const validateResult = validationMgr.checkIsGroupValid(notificationItem);
		if (validateResult?.length === 0) {
			setForceValidate(false);
			if (onSave) {
				setSaving(true);
				notificationItem.fromDate = moment(fromDate).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
				notificationItem.toDate = moment(toDate).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
				var result = await onSave(notificationItem);
				if (result?.Success) {
					handleOnCancel();
					setSaving(false);
				} else if (onErrors) {
					onErrors([
						result?.Message?.DisplayMessage ?? [
							...(result?.MessageDetails ?? ["Unknown Error"]),
						],
					]);
				}
				return result;
			}
		} else if (onErrors) {
			setForceValidate(true);
		}
	};
	const handleOnChange = (value, field) => {
		let updatedNotification = { ...notificationItem };
		if (field === "OrgStructure") {
			for (const property in value) {
				updatedNotification[property] = value[property];
			}
		} else if (field === 'FromDate') {
			setFromDate(value);
		}
		else if (field === 'ToDate') {
			setToDate(value);
		} else {
			updatedNotification[field] = value;
		}
		setNotificationItem(updatedNotification);
	};
	const onTreeSelect = (node, vals) => {
		let newTreeIds = orgMgr?.findSelected(vals);
		let organizationIds = [];
		let districtIds = [];
		let schoolIds = [];
		let clusterIds = [];
		let userIds = [];
		newTreeIds.forEach((id) => {
			if (id.substring(0, 2) === "o-") {
				organizationIds.push(id.substring(2));
			} else if (id.substring(0, 2) === "d-") {
				districtIds.push(id.substring(2));
			} else if (id.substring(0, 2) === "s-") {
				schoolIds.push(id.substring(2));
			} else if (id.substring(0, 2) === "c-") {
				clusterIds.push(id.substring(2));
			} else if (id.substring(0, 2) === "u-") {
				userIds.push(id.substring(2));
			}
		});

		if (handleOnChange) {
			setItemOrgTree(newTreeIds)
			handleOnChange(
				{
					OrganizationIdsData: organizationIds.join(),
					DistrictIdsData: districtIds.join(),
					SchoolIdsData: schoolIds.join(),
					ClusterIdsData: clusterIds.join(),
					UserIdsData: userIds.join()
				},
				"OrgStructure"
			);
			// if (node.NodeType === "Org") {
			// 	handleOnChange(
			// 		{
			// 			OrganizationIdsData: organizationIds.join(),
			// 			DistrictIdsData: districtIds.join(),
			// 			SchoolIdsData: schoolIds.join(),
			// 			ClusterIdsData: clusterIds.join(),
			// 			UserIdsData: userIds.join()
			// 		},
			// 		"OrgStructure"
			// 	);
			// } else if (node.NodeType === "District") {
			// 	handleOnChange(
			// 		{
			// 			DistrictIdsData: districtIds.join(),
			// 			SchoolIdsData: schoolIds.join(),
			// 			ClusterIdsData: clusterIds.join(),
			// 			UserIdsData: userIds.join()
			// 		},
			// 		"OrgStructure"
			// 	);
			// } else if (node.NodeType === "School") {
			// 	handleOnChange(
			// 		{
			// 			SchoolIdsData: schoolIds.join(),
			// 			ClusterIdsData: clusterIds.join(),
			// 			UserIdsData: userIds.join()
			// 		},
			// 		"OrgStructure"
			// 	);
			// } else if (node.NodeType === "Cluster") {
			// 	handleOnChange(
			// 		{
			// 			ClusterIdsData: clusterIds.join(),
			// 			UserIdsData: userIds.join()
			// 		},
			// 		"OrgStructure"
			// 	);
			// } else if (node.NodeType === "User") {
			// 	handleOnChange(
			// 		{
			// 			ClusterIdsData: clusterIds.join(),
			// 			UserIdsData: userIds.join()
			// 		},
			// 		"OrgStructure"
			// 	);
			// }
		}

	};

	const onTreeClean = (id) => {
		handleOnChange(
			{
				OrganizationIdsData: "",
				DistrictIdsData: "",
				SchoolIdsData: "",
				ClusterIdsData: "",
				UserIdsData: ""
			},
			"OrgStructure"
		);
	};

	const notificationTypes = [
		{ text: "Message", value: 0 },
		{ text: "Important", value: 1 },
		{ text: "Urgent", value: 2 },
	];
	return (
		<>
			<div className="control-box-wrapper notification-details">
				{notificationItem && (
					<>
						<div className="control-box box-two-column role-left-box">
							<InputField
								forceValidate={forceValidate}
								type="text"
								title="Title"
								placeholder={"Enter Title"}
								value={notificationItem?.Title}
								fieldName="Title"
								groupId={"NOTIFICATION"}
								onChange={handleOnChange}
							/>
							<InputField
								title="Notification Type"
								value={_.find(notificationTypes, x => x.text == notificationItem.NotificationType || x.value == notificationItem.NotificationType)?.value ?? 0}
								fieldName="NotificationType"
								groupId={"NOTIFICATION"}
								onChange={handleOnChange}
							>
								<SelectListControl
									hidePleaseSelect={true}
									fieldName="NotificationType"
									textValuePairs={notificationTypes}
								/>
							</InputField>
							<InputField
								title="From Date"
								value={
									fromDate
										? fromDate
										: undefined
								}
								maxLength={100}
								onChange={handleOnChange}
								groupId={"NOTIFICATION"}
								fieldName="FromDate"
							>
								<DatePickerControl
									includeTime={true}
									maxDate={
										toDate
											? toDate
											: undefined
									}
								/>
							</InputField>
							<InputField
								title="To Date"
								maxLength={100}
								onChange={handleOnChange}
								groupId={"NOTIFICATION"}
								fieldName="ToDate"
								value={
									toDate
										? toDate
										: undefined
								}
							>
								<DatePickerControl
									includeTime={true}
									minDate={
										fromDate
											? moment(fromDate).local().format("YYYY-MM-DD HH:mm")
											: moment().format("YYYY-MM-DD HH:mm")
									}
								/>
							</InputField>
							{orgUserTreeData ? (
								<InputField
									title="Organization & Users"
									fieldName="ClientId"
									groupId={"NOTIFICATION"}
								>
									{/* <CheckTreePicker
										height={320}
										width={400}
										data={orgUserTreeData ?? []}
										defaultValue={itemOrgTreeDefault}
										childrenKey={"Children"}
										disabled={notificationItem?.AllUsers}
										labelKey={"Name"}
										valueKey={"Id"}
										onClean={onTreeClean}
										onSelect={onTreeSelect}
										placement="autoVerticalStart"
									/> */}

									<UserOrgTreeControl
										className={'filter-control-input'}
										childrenKey="Children"
										labelKey="Name"
										valueKey="Id"
										selectedValues={itemOrgTree}
										defaultValues={itemOrgTreeDefault}
										data={orgUserTreeData ?? []}
										menuClassName={'org-tree-menu'}
										onClean={onTreeClean}
										placement="autoVerticalStart"
										onSelect={(a, v) => onTreeSelect(a, v)}
										disabled={notificationItem?.AllUsers}
									/>
								</InputField>
							) : (
								<Loading />
							)}
							<InputField
								title="Emergency Message"
								subTitle={'Show message on the under the header of the application. Only used for for Urgent notifications.'}
								value={notificationItem?.ShowGlobally}
								disableError={true}
								fieldName="ShowGlobally"
								groupId={"NOTIFICATION"}
								onChange={handleOnChange}

							>
								<CheckboxControl disabled={!(notificationItem?.NotificationType == 'Urgent' || notificationItem?.NotificationType == 2)} />
							</InputField>
							<InputField
								title="All Users"
								value={notificationItem?.AllUsers}
								disableError={true}
								fieldName="AllUsers"
								groupId={"NOTIFICATION"}
								onChange={handleOnChange}
							>
								<CheckboxControl />
							</InputField>
						</div>
						<div className="control-box box-two-column role-right-box">
							<InputField
								forceValidate={forceValidate}
								value={notificationItem?.HtmlValue}
								title="Message"
								subTitle={
									"Please enter the notification text and adjust the layout and formatting as you see fit."
								}
								fieldName="HtmlValue"
								groupId={"NOTIFICATION"}
								validateChildren={true}
							>
								<ReactQuill
									theme="snow"
									className="rq"
									onChange={(v) => handleOnChange(v, "HtmlValue")}
								/>
							</InputField>
							<InputField
								title="Active?"
								value={notificationItem?.IsActive}
								disableError={true}
								fieldName="IsActive"
								groupId={"NOTIFICATION"}
								onChange={handleOnChange}
							>
								<CheckboxControl />
							</InputField>
						</div>
					</>
				)}
			</div>
			<div className="screen-footer screen-footer-right">
				<div className="btn-wrapper-left screen-footer__btn">
					<ButtonControl
						disabled={saving}
						type={"cancel"}
						onClick={handleOnCancel}
					>
						Cancel
					</ButtonControl>
				</div>
				<div className="btn-wrapper-right screen-footer__btn">
					<ButtonControl
						loading={saving}
						type={"okay"}
						disabled={!notificationItem}
						onClick={handleOnSave}
					>
						Save
					</ButtonControl>
				</div>
			</div>
		</>
	);
};

export default NotificationDetails;
