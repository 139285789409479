import { ItemServiceFactory } from '../services/ItemServiceFactory';
import ItemManager from './ItemManager';
import _ from 'lodash';

class TOSManager extends ItemManager {
  constructor(globalItemState, globalAuthState, globalAppUserState) {

    super(ItemServiceFactory.ItemServiceEnum.TOS, globalAuthState, globalAppUserState, globalItemState,
      (filter, item) => {
        var hasIsActive = item?.IsActive?.toString() === filter?.isActive?.toString() || (filter?.isActive === undefined || filter?.isActive === null || filter?.isActive === 'UNK');
        return hasIsActive
      });

    this._errorUtil = undefined;
  }

  get DefaultItem() {
    return {
      TermsHtml: "",
      IsActive: false
    };
  }

  async getActiveTOS() {
    const result = await this.list({
      "Skip": 0,
      "Take": 1,
      "RootFilterNode": {
        "Variable": "x",
        "Method": "&&",
        "IsConjunction": true,
        "Left": {
          "Variable": "x",
          "Name": "IsActive",
          "Method": "==",
          "Value": true,
          "IsConjunction": false
        },
        "Right": {
          "Variable": "x",
          "Name": "IsDeleted",
          "Method": "==",
          "Value": false,
          "IsConjunction": false
        }
      }
    });

    if (result?.Success) {
      return result?.Items.first();
    }
    else {
      return null;
    }
  }

  async deactivate(idToSkip) {
    const result = await this.list();
    if (result?.Success) {
      const items = result.Items
      const itemsToInActivate = _.filter(items, item => item.Id != idToSkip && item.IsActive === true);
      const allP = [];
      _.each(itemsToInActivate, item => {
        item.IsActive = false;
        allP.push(this.save(item))
      });

      if (allP.length > 0)
        await Promise.all(allP);


      return items;
    }
    else
      return [];
  }
}

export default TOSManager;