import { ContentItemService, UserProfileItemService } from "../services/ItemServices";
import { AppItemService } from "../services/ItemServices";
import Manager from "./Manager";
import configData from "../config.json";
// import { Persistence } from '@hookstate/persistence';
import { AuthPermissionService, PermissionMapService, StatusCheckItemService } from "../services/SystemServices";
import * as gaMgr from './GoogleAnalyticsManager';
import { EventTypeEnum } from './GoogleAnalyticsManager';
import { APIStatusType } from "../stores/globalAppUserStore";
import _ from "lodash";
   
import { json } from "react-router-dom";
import moment from "moment";
import ItemManager from "./ItemManager";
import { ItemServiceFactory } from "../services/ItemServiceFactory";
import { FormatUTCDate } from "../utilities/DateFormater";


class AppUserManager extends Manager {
    static LASTISONLINE = true;
    static CHECKSTATUS_TIMEOUT_ID = -1;
    static CHECKSTATUS_INTERVAL_ID = -1;
    static LOADING = {
        app: false,
        userProfile: false,
        userPermissionsMap: false,
    };
    constructor(globalAuthState, globalAppUserState) {
        super(globalAuthState, globalAppUserState);
        this._userProfileItemSvc = new UserProfileItemService(globalAuthState);
        this._userProfileItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.UserProfile, globalAuthState, globalAppUserState);
        this._appItemSvc = new AppItemService(globalAuthState);
        this._statusCheckItemSvc = new StatusCheckItemService(globalAuthState);
        this._authPermissionSvc = new AuthPermissionService(globalAuthState);
        this._contentItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Content, globalAuthState, globalAppUserState);
        this._clientItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Client, globalAuthState, globalAppUserState);
        this._globalAuthState = globalAuthState;
        this._appId = configData.APP_ID;
    }

    GetGlobalState() {
        return this._globalAppUserState.get({ noproxy: true });
    }

    GetGlobalAuthState() {
        return this._globalAuthState.get({ noproxy: true });
    }

    _setApiStatus(appUserState, context) {

        let apiStatus = appUserState.apiStatus;
        if (appUserState.isAuthenticated && (appUserState.apiStatus === APIStatusType.Offline || appUserState.apiStatus === APIStatusType.Startup)) {
            apiStatus = APIStatusType.OnlineSecure;
        }
        else if (!appUserState.isAuthenticated && (appUserState.apiStatus === APIStatusType.Offline || appUserState.apiStatus === APIStatusType.Startup)) {
            apiStatus = APIStatusType.Online;
        }

        if (apiStatus && apiStatus !== appUserState.apiStatus) {
            context._globalAppUserState.merge({ apiStatus });
        }
    }

    async _checkAPIStatusOnInterval() {
        const context = this;
        const appUserState = context._globalAppUserState.get();
        if (appUserState.apiStatus !== APIStatusType.Initializing) {
            if (AppUserManager.CHECKSTATUS_INTERVAL_ID === -1 && configData.APP.CHECK_STATUS_INTERVAL > 500) {
                AppUserManager.CHECKSTATUS_INTERVAL_ID = setInterval(() => {
                    context._statusCheckItemSvc.CheckStatus().then(status => {

                        if (AppUserManager.LASTISONLINE !== status?.first()?.Success && !AppUserManager.LASTISONLINE) {
                            //Restart system.. do somthing to refresh items on the page in the future.. only if state is mantained...
                            console.log("System Back Online...");
                        }

                        AppUserManager.LASTISONLINE = status?.first()?.Success;
                        if (AppUserManager.LASTISONLINE) {
                            this._setApiStatus(appUserState, context);
                        }
                        else {
                            context._globalAppUserState.merge({ apiStatus: APIStatusType.Offline });
                        }
                        this.WriteDebugLog("AppUserManager.checkAPIStatus", "API Check Status Interval Complete: System " + (status?.first()?.Success ? "ONLINE." : "OFFLINE."));
                    }).catch(r => {
                        this.WriteErrorLog("AppUserManager.checkAPIStatus", "API Check Status Interval Failed: System OFFLINE.");
                        console.log(r);
                    });
                }, configData.APP.CHECK_STATUS_INTERVAL);
            }
        }
    }


    async GetShareUsersTree() {
        return await this._clientItemMgr.runOperation('UserOrgTree');
    }

    async checkAPIStatus(context) {
        const appUserState = context._globalAppUserState.get();
        if (appUserState.apiStatus !== APIStatusType.Initializing) {
            context._statusCheckItemSvc.CheckStatus().then(r => {
                if (r?.Success || (r?.length !== undefined && r?.first().Success)) {
                    this._setApiStatus(appUserState, this);
                    this.WriteDebugLog("AppUserManager.checkAPIStatus", "API Check Status Complete, Reset Timeout Id: " + AppUserManager.CHECKSTATUS_TIMEOUT_ID)
                    if (AppUserManager.CHECKSTATUS_TIMEOUT_ID > 0) {
                        clearTimeout(AppUserManager.CHECKSTATUS_TIMEOUT_ID);
                        AppUserManager.CHECKSTATUS_TIMEOUT_ID = 0;
                    }
                }
                else {
                    context._globalAppUserState.merge({ apiStatus: APIStatusType.Offline });
                }
            });
        }
    }
    setOnlineSecure() {
        if (!AppUserManager.LOADING.permissionsMap && !AppUserManager.LOADING.userProfile && !AppUserManager.LOADING.userPermissionsMap && !AppUserManager.LOADING.userProfile && this._globalAppUserState.apiStatus !== APIStatusType.TOSNotFound) {
            this._globalAppUserState.merge({ apiStatus: APIStatusType.OnlineSecure });
        }
    }

    async GetUserProfileConfig(schoolYearIdentifier) {
        var date = new Date();
        return await this._userProfileItemSvc.getOperation("UserProfileConfig", undefined, {
            //Removed.. date index not required Obosete
            //"dateindex": date.getFullYear() * 1e4 + (date.getMonth() + 1) * 100 + date.getDate() + '',
            sy: schoolYearIdentifier ?? 0
        });
    }



    initialize() {
        const appUserState = this._globalAppUserState.get();
        const authState = this._globalAuthState.get();

        if (authState.isAuthenticated) {

            if (appUserState.apiStatus === APIStatusType.Startup) {
                this._globalAppUserState.merge({ apiStatus: APIStatusType.Initializing });
            }

            const userProfile = appUserState.userProfile;

            if (authState?.isAuthenticated && !AppUserManager.LOADING.userProfile) {

                if (userProfile === null && authState?.idTokenClaims) {

                    this.WriteDebugLog("AppUserManager.initialize", "Get User Profile Data for '" + authState?.idTokenClaims?.oid + "'.");
                    AppUserManager.LOADING.userProfile = true;
                    let currentUserProfile;
                    this.GetUserProfileConfig(sessionStorage.getItem('SelectedSchoolYear')).then(r => {
                        if (r?.first()?.Success && r?.first()?.Items?.length > 0) {
                            this.WriteDebugLog("AppUserManager.initialize", "UserProfileConfig returned for '" + authState?.idTokenClaims?.oid + "'.");
                            const tempUserPermissionsMap = {};
                            if (r.first().Items.first().UserProfile) {
                                this._globalAppUserState.merge({
                                    DefaultAnalyticsLayout: r.first().Items.first().DefaultAnalyticsLayout,
                                    HasGeneralSurvey: r.first().Items.first().HasGeneralSurvey,
                                    SchoolNames: r.first().Items.first().SchoolNames,
                                    DistrictNames: r.first().Items.first().DistrictNames,
                                    OrganizationNames: r.first().Items.first().OrganizationNames,
                                    ClusterNames: r.first().Items.first().ClusterNames,
                                    userProfile: r.first().Items.first().UserProfile,
                                    permissions: r.first().Items.first().Permissions,
                                    rolePrecedence: r.first().Items.first().RolePrecedence,
                                    observationConfig: r.first().Items.first().ObservationConfig,
                                    urgentNotification: r.first().Items.first().UrgentNotification,
                                });
                                currentUserProfile = r.first().Items.first().UserProfile;
                                AppUserManager.LOADING.userProfile = false;
                                if (!currentUserProfile?.SignedTOSDate) {
                                    this._globalAppUserState.merge({ apiStatus: APIStatusType.TOSNotFound });
                                }
                                else {
                                    this.setOnlineSecure();
                                    //add event for login 
                                    gaMgr.AddEvent(EventTypeEnum.Auth_Login, null, null, this);
                                }
                            }

                            if (r.first().Items.first().UserPermissions?.length > 0) {
                                r.first().Items.first().UserPermissions.forEach(authPermission => {
                                    tempUserPermissionsMap[authPermission.Permission] = {
                                        CanDelete: authPermission.CanDelete,
                                        CanExecute: authPermission.CanExecute,
                                        CanHardDelete: authPermission.CanHardDelete,
                                        CanView: authPermission.CanView,
                                        CanWrite: authPermission.CanWrite
                                    };
                                });
                                this._globalAppUserState.merge({ userPermissionsMap: tempUserPermissionsMap });
                                AppUserManager.LOADING.userPermissionsMap = false;
                            }
                            if (r.first().Items.first().SchoolYears?.length > 0) {
                                let tempSchoolYearMap = {};
                                let currentSchoolYear = null;
                                let canWriteSchoolYearData = false;
                                r.first().Items.first().SchoolYears.forEach(schoolyear => {
                                    tempSchoolYearMap[schoolyear.SchoolYearIdentifier] = schoolyear;
                                    if (schoolyear.CurrentSchoolYear) {
                                        currentSchoolYear = schoolyear.SchoolYearIdentifier;
                                        canWriteSchoolYearData = new Date() < new Date(FormatUTCDate(schoolyear.SchoolYearEnd));
                                    }
                                });
                                this._globalAppUserState.merge({
                                    schoolYearMap: tempSchoolYearMap,
                                    selectedSchoolYear: sessionStorage.getItem("SelectedSchoolYear") ? sessionStorage.getItem("SelectedSchoolYear") : currentSchoolYear,
                                    currentSchoolYear,
                                    canWriteSchoolYearData
                                });
                            }


                        }
                        else {
                            if (this._globalAppUserState.apiStatus !== this._globalAppUserState.InitFailed.UserSetup && !r?.first()?.Success) {
                                if (r?.first()?.Message?.Code == "A1") {
                                    this._globalAppUserState.merge({ apiStatus: APIStatusType.AccessDenied });
                                }
                                else {
                                    this._globalAppUserState.merge({ apiStatus: APIStatusType.InitFailed });
                                }
                            }
                            else if (this._globalAppUserState.apiStatus !== this._globalAppUserState.InitFailed.UserSetup && r?.first()?.Success && r?.first()?.Items.length === 0) {
                                this._globalAppUserState.merge({ apiStatus: APIStatusType.UserSetup });
                            }
                            this.WriteErrorLog("AppUserManager.initialize", "(" + r.first().Message.Code + ") User Permissions Data failed for '" + authState?.idTokenClaims?.oid + "'.", r?.first()?.EventId);
                        }
                    });
                }
            }



            this._checkAPIStatusOnInterval();
        }
        else {
            if (AppUserManager.CHECKSTATUS_TIMEOUT_ID === -1) {
                AppUserManager.CHECKSTATUS_TIMEOUT_ID = 0;
                this.checkAPIStatus(this);
            }
            else if (AppUserManager.CHECKSTATUS_TIMEOUT_ID === 0 && AppUserManager.CHECKSTATUS_INTERVAL_ID === -1) {
                AppUserManager.CHECKSTATUS_TIMEOUT_ID = setTimeout(() => this.checkAPIStatus(this), authState?.isAuthenticated ? 500 : 60000);
            }
        }
    }


    async getUser(userId) {
        let result = await this._userProfileItemMgr.get(userId)
        if (result.Success) {
            return result.Items.first()
        } else {
            return
        }
    }

    async getUserPositionTypeByUserId(userId) {
        let result = await this._userProfileItemMgr.runOperation('GetUserPositionTypeByUserId', userId);
        if (result.Success) {
            return result.Items.first()
        } else {
            return
        }
    }

    async SaveContentPreferances(preferanceTags) {
        return this._userProfileItemSvc.single(this._globalAppUserState.get()?.userProfile?.UserId).then(async r => {
            if (r?.first()?.Success && r?.first()?.Items?.length > 0) {
                const updates = {
                    ...r?.first().Items.first(),
                    RecommendedContentTags: preferanceTags
                };
                const finalResult = await this._userProfileItemSvc.save(updates);
                if (finalResult?.first().Success)
                    this._globalAppUserState.merge({ userProfile: updates });

                this._userProfileItemMgr.runOperation('RecommendContent', this._globalAppUserState.get()?.userProfile?.UserId);
                return finalResult;
            }
            else {
                return r;
            }
        });
    }

    async setupUserProfile(tosId) {
        const appUserState = this._globalAppUserState.get();
        const authState = this.GetGlobalAuthState();

        if (authState?.isAuthenticated && appUserState.apiStatus === APIStatusType.UserSetup) {
            this.WriteDebugLog("AppUserManager.setupUserProfile", `Create New User Profile for ${authState.idTokenClaims.emails.first()}`);
            const itemToSave = {
                FirstName: authState.idTokenClaims.extension_FirstName,
                LastName: authState.idTokenClaims.extension_LastName,
                UserName: authState.idTokenClaims.emails.first(),
                Email: authState.idTokenClaims.emails.first(),
                ClientID: authState.idTokenClaims.extension_CoreClientId,
                Roles: authState.idTokenClaims.extension_Roles ? JSON.parse(authState.idTokenClaims.extension_Roles) : [],
                TimeZone: authState.idTokenClaims.extension_TimeZoneAbbreviation,
                Locale: authState.idTokenClaims.extension_Locale,
                UniqueId: authState.idTokenClaims.oid,
                NickName: authState.idTokenClaims.name,
                DisplayName: authState.idTokenClaims.name,
                PrimaryPhone: authState.idTokenClaims.phone ?? null,
                PreferredLanguage: authState.idTokenClaims.extension_Locale,
                CurrentTOSId: tosId,
                UserStatus: 1,
                UserType: 100,
                UserPositionType: 100,
                SystemId: null,
                SignedTOSDate: tosId ? moment().utc() : undefined
            };
            return await this._userProfileItemSvc.postOperation('AzureB2CSaveUserProfile', undefined, itemToSave, {nietemail:true}, false, true);
        }
        else {
            return null;
        }
    }



    async updateTOS(tosId) {
        const appUserState = this._globalAppUserState.get();
        const authState = this._globalAuthState.get({ noproxy: true });
        if (authState?.isAuthenticated && appUserState.apiStatus === APIStatusType.TOSNotFound) {
            this.WriteDebugLog("AppUserManager.updateTos", `Updating the TOS for ${authState.idTokenClaims.emails.first()}`);
            return this._userProfileItemSvc.single(authState?.idTokenClaims?.oid).then(async r => {
                if (r?.first().Success && r?.first()?.Items?.length > 0) {
                    const userProfileUpdate = {
                        ...r?.first().Items.first(),
                        CurrentTOSId: tosId,
                        SignedTOSDate: tosId ? moment().utc() : undefined
                    };
                    return await this._userProfileItemSvc.save(userProfileUpdate);
                }
                else {
                    return null;
                }
            });
        }
        else {
            return null;
        }
    }

    defaultRoute() {
        if (AppUserManager.checkPermission("HomeSysAdmin", "View", this.AppUserState)) {
            return '/admin/organization'
        }
        else if (AppUserManager.checkPermission("HomeContent", "View", this.AppUserState)) {
            return '/content'

        } else if (AppUserManager.checkPermission("MyDashboard", "View", this.AppUserState)) {
            return '/my-dashboard'
        }
        else {
            return '/profile'
        }
    }


    checkRoute(permission, pathName) {
        const guidRegex = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;
        const subPath = pathName.substring(pathName.lastIndexOf('/') + 1);
        const isGUID = subPath.match(guidRegex);
        let hasAccess = false;

        if (Array.isArray(permission)) {
            hasAccess = permission.every(p => AppUserManager.checkPermission(p, "View", this.AppUserState) === true)
        }
        else {
            hasAccess = AppUserManager.checkPermission(permission, "View", this.AppUserState)
        }
        //first permission must have write access all others, at least view
        if (hasAccess && (isGUID || subPath === 'addnew' || subPath === 'create') && pathName.indexOf('/admin/') > -1) {
            hasAccess = AppUserManager.checkPermission(Array.isArray(permission) ? permission.first() : permission, "Write", this.AppUserState)
        }

        if (pathName.indexOf('/admin/') > -1) {
            hasAccess = AppUserManager.checkPermission('Administration', "View", this.AppUserState);
        }

        return hasAccess;
    }

    //Checks to see if the user can read across one or more permisions.
    AccessCheck(permissions) {
        let hasPermission = false;
        if (typeof permissions === "string") {
            permissions = [permissions];
        }

        hasPermission = (permissions.every(p =>
            AppUserManager.checkPermission(p, "View", this.AppUserState) === true
        ))

        return hasPermission;
    }

    //View,Write,Delete,HardDelete,Execute
    static checkPermission(permission, permissionType, globalAppUser, isOp) {
        const userPermissionsMap = globalAppUser.userPermissionsMap;
        const permissions = globalAppUser.permissions;
        const isValidPermission = permissions[permission] === permission;


        let hasAccess = false;
        if (isValidPermission) {
            if (userPermissionsMap[permission] !== undefined && userPermissionsMap[permission] != null) {
                hasAccess = userPermissionsMap[permission]["Can" + permissionType];
            }
        }

        if ((globalAppUser.selectedSchoolYear != globalAppUser.currentSchoolYear || !globalAppUser.canWriteSchoolYearData)
            && (permissionType == "Write" || permissionType == "Delete") && hasAccess
            && (permission.toLowerCase().indexOf("observation") > -1
                ||
                (permission.toLowerCase().indexOf("certification") > -1
                    //Custom Logic to support access to certifications...
                    && (permission.toLowerCase().indexOf("navadmincertification") == -1
                        && permission.toLowerCase().indexOf("navteachercertification") == -1)
                )
                || permission.toLowerCase().indexOf("survey") > -1)) {

            if (userPermissionsMap["PastSchoolYearDataMgmt"] !== undefined) {
                hasAccess = userPermissionsMap["PastSchoolYearDataMgmt"]["Can" + permissionType];
                if (hasAccess == false) {
                    hasAccess = globalAppUser.canWriteSchoolYearData;
                }
            }
            else if ((permissionType == "Write" || permissionType == "Delete")) {
                hasAccess = globalAppUser.canWriteSchoolYearData;
            }

        }
        return hasAccess;
    }


    canView(permission) {
        return AppUserManager.checkPermission(permission, "View", this.AppUserState);
    }

    canWrite(permission) {

        return AppUserManager.checkPermission(permission, "Write", this.AppUserState);
    }

    canDelete(permission) {
        return AppUserManager.checkPermission(permission, "Delete", this.AppUserState);
    }

    canHardDelete(permission) {
        return AppUserManager.checkPermission(permission, "HardDelete", this.AppUserState);
    }

    canExecute(permission) {
        return AppUserManager.checkPermission(permission, "Execute", this.AppUserState);
    }

    cleanup() {
        this.WriteDebugLog("AppUserManager.cleanup", "Do cleanup of 'appuser-global-store'");
        this._localStorageUtil.removeAllContaining("appuser-global-store");
        this._globalAuthState.set({
            idToken: null,
            accessToken: null,
            idTokenClaims: null
        });
        this._globalAppUserState.set({
            user: null,
            userProfile: null,
            userPermissionsMap: null,
            permissionsMap: null,
            app: null,
            initFailed: false,
            apiStatus: APIStatusType.Online
        });
    }
}

export default AppUserManager;