import React from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import Navigation from './Navigation';
import ProfileNav from './ProfileNav';

const InitHeader = () => {
  return (
    <div className={`header header-box-shadow`}>
      <div className={'eepass-banner'} onClick={() => { window.location.href = '/' }}></div>
      <div>&nbsp;</div>
      <div className={'eepass-banner'}></div>
    </div>
  );
}

export default InitHeader;