//https://blog.logrocket.com/simplify-react-state-management-with-hookstate/
import { hookstate } from '@hookstate/core'

const globalTagStore = hookstate({
    allTags: null,
    filteredTags: [],
    forceUpdate: false,
    parentTags: [],
    tagFilter: { Search: '', ParentTagIds: [], IsActive: undefined, IsSecure: undefined }
});

export default globalTagStore;