import React, { useState } from 'react';
import { useEffect } from 'react';
import MainLayout from '../../../components/layout/MainLayout';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import _ from 'lodash';
import { useAppUserManager, useCertificationQuestionManager, useImportExportManager, useOrgManager } from '../../../hooks/useManagers';
import { ObservationSection } from '../components/certificationQuestion/ObservationComponents/ObservationComponents';
import '../components/observation/observationconfig.scss'
import ButtonControl from '../../../components/controls/ButtonControl';
import SystemTypes from '../../../SystemTypes';
import { useNavigate } from 'react-router-dom';

const ObservationConfigMgmt = () => {
    const orgMgr = useOrgManager();
    const navigate = useNavigate();
    const certificationQuestionMgr = useCertificationQuestionManager();
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState(null);
    const [saving, setSaving] = useState(false)
    const [validationErrors, setValidationErrors] = useState(null)
    const [forceValidate, setForceValidate] = useState(false)
    const [observationConfig, setObservationConfig] = useState(null)
    const [activeObservationConfigTab, setActiveObservationConfigTab] = useState('Admin')
    const [teacherSurveyList, setTeacherSurveyList] = useState(null)
    const [adminSurveyList, setAdminSurveyList] = useState(null)
    const [orgTreeUsers, setOrgTreeUsers] = useState(null)
    const [certificationQuestions, setCertificationQuestions] = useState(null)
    const [formattedTeacherObservationRubrics, setFormattedTeacherObservationRubrics] = useState(null)
    const [formattedTeacherCertificationRubrics, setFormattedTeacherCertificationRubrics] = useState(null)

    const [formattedAdminObservationRubrics, setFormattedAdminObservationRubrics] = useState(null)
    const [formattedAdminCertificationRubrics, setFormattedAdminCertificationRubrics] = useState(null)


    const importExportMgr = useImportExportManager();
    const [exportLoading, setExportLoading] = useState(false);

    useEffect(() => {
        if (orgMgr && certificationQuestionMgr) {
            grabOrgDetails();
            orgMgr.loadOrgUserTree().then((r) => {
                const orgUsers = orgMgr.UsersOnly();
                setOrgTreeUsers(orgUsers);
            })
        }
    }, [orgMgr, certificationQuestionMgr])

    const handleOnSave = async () => {
        setSaving(true)
        await orgMgr.saveObservationConfig(observationConfig).then(r => {
            const copiedObsConfig = { ...r }
            setObservationConfig(r)
            setSaving(false)
        })
        window.location.reload();
    }

    const grabOrgDetails = async () => {

        const orgObsConfig = orgMgr.getObservationConfigByOrg(null).then((r) => {
            if (r.Success) {
                let observationConfig = r.Items.first();
                let certificationRubricId = observationConfig.CertificationRubricId;
                setLoading(false)

                orgMgr.getTeacherRubrics(null, false, ['RubricDomainMap'], certificationRubricId).then(r => {
                    if (r.first().Success) {
                        setFormattedTeacherCertificationRubrics(_.map(_.filter(r.first().Items, x => (x.RubricType === 3 || x.RubricType === 1) && x.RubricDomainMap?.some(z => z.UseForCertification === true)), y => { return { text: y.Name, value: y.Id } }))
                        setFormattedTeacherObservationRubrics(_.map(_.filter(r.first().Items, x => x.RubricType === 1), y => { return { text: y.Name, value: y.Id } }))
                    }
                })

                setObservationConfig(observationConfig)
            }
        })


        const adminRubric = orgMgr.getAdminRubrics(null, false, ['RubricDomainMap']).then(r => {
            if (r.first().Success) {
                setFormattedAdminCertificationRubrics(_.map(_.filter(r.first().Items, x => (x.RubricType === 7 || x.RubricType === 4) && x.RubricDomainMap?.some(z => z.UseForCertification === true)), y => { return { text: y.Name, value: y.Id } }))
                setFormattedAdminObservationRubrics(_.map(_.filter(r.first().Items, x => x.RubricType === 4), y => { return { text: y.Name, value: y.Id } }))
            }
        })

        const teacherSurveyTemplate = orgMgr.getSurveyTemplates(null, 4).then(r => {
            if (r.first().Success) {
                setTeacherSurveyList(r.first().Items)
            }
        })
        const adminSurveyTemplate = orgMgr.getSurveyTemplates(null, 5).then(r => {
            if (r.first().Success) {
                setAdminSurveyList(r.first().Items)
            }
        })

        const grabCertificationQuestions = certificationQuestionMgr.list(certificationQuestionMgr.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false")).then(r => setCertificationQuestions(r.Items))

        const result = Promise.all([orgObsConfig, adminRubric, teacherSurveyTemplate, adminSurveyTemplate, grabCertificationQuestions])
    }


    const updateObservationConfigs = (value, field) => {
        //Provided a flag called was Edited to avoid saving if it wasn't edited.
        let updatedConfig = { ...observationConfig };
        updatedConfig.wasEdited = true
        if (value?.FileId) {
            updatedConfig[field] = value?.FileId;
            if (field === 'CertificationLogTeacherFileId') {
                updatedConfig.CertificationLogTeacherFile = value;
            }
        } else {
            updatedConfig[field] = value;
        }

        setObservationConfig(updatedConfig);
    }
    const updateUserPositionAlternativeMap = (value, userPosition) => {
        let updatedConfig = { ...observationConfig };
        updatedConfig.wasEdited = true
        let updatedUserPositionAlternativeMap = [...observationConfig.UserPositionAlternativeMap]
        let userPositionToUpdate = _.find(updatedUserPositionAlternativeMap, x => x.UserPosition == userPosition)
        if (userPositionToUpdate) {
            let userPositionToUpdateIndex = _.findIndex(updatedUserPositionAlternativeMap, x => x.UserPosition == userPosition)
            userPositionToUpdate.Name = value
            updatedUserPositionAlternativeMap.splice(userPositionToUpdateIndex, 1, userPositionToUpdate)
        } else {
            userPositionToUpdate = {
                Name: value,
                UserPosition: userPosition,
            }
            updatedUserPositionAlternativeMap.push(userPositionToUpdate)
        }
        updatedConfig.UserPositionAlternativeMap = updatedUserPositionAlternativeMap
        setObservationConfig(updatedConfig)
    }

    const updateUserPositionRubricMap = (value, userPosition, rubricType) => {
        let updatedConfig = { ...observationConfig };
        if (value === 'UNK') {
            value = null
        }
        updatedConfig.wasEdited = true
        let updatedUserPositionRubricMap = [...observationConfig.UserPositionRubricMap]
        let userPositionToUpdate = _.find(updatedUserPositionRubricMap, x => x.UserPosition == userPosition && x.RubricType == rubricType)
        if (userPositionToUpdate) {
            let userPositionToUpdateIndex = _.findIndex(updatedUserPositionRubricMap, x => x.UserPosition == userPosition && x.RubricType == rubricType)
            userPositionToUpdate.RubricId = value
            updatedUserPositionRubricMap.splice(userPositionToUpdateIndex, 1, userPositionToUpdate)
        } else {
            userPositionToUpdate = {
                RubricId: value,
                UserPosition: userPosition,
                RubricType: rubricType
            }
            updatedUserPositionRubricMap.push(userPositionToUpdate)
        }
        updatedConfig.UserPositionRubricMap = updatedUserPositionRubricMap
        setObservationConfig(updatedConfig)
    }
    const updateUserPositionSurveyTemplateMap = (value, userPosition, surveyType) => {
        let updatedConfig = { ...observationConfig };
        if (value === 'UNK') {
            value = null
        }
        updatedConfig.wasEdited = true
        let updatedUserPositionSurveyTemplateMap = [...observationConfig.UserPositionSurveyTemplateMap]
        let userPositionToUpdate = _.find(updatedUserPositionSurveyTemplateMap, x => x.UserPosition == userPosition && x.SurveyType == surveyType)
        if (userPositionToUpdate) {
            let userPositionToUpdateIndex = _.findIndex(updatedUserPositionSurveyTemplateMap, x => x.UserPosition == userPosition && x.SurveyType == surveyType)
            userPositionToUpdate.SurveyTemplateId = value
            updatedUserPositionSurveyTemplateMap.splice(userPositionToUpdateIndex, 1, userPositionToUpdate)
        } else {
            userPositionToUpdate = {
                SurveyTemplateId: value,
                UserPosition: userPosition,
                SurveyType: surveyType
            }
            updatedUserPositionSurveyTemplateMap.push(userPositionToUpdate)
        }
        updatedConfig.UserPositionSurveyTemplateMap = updatedUserPositionSurveyTemplateMap
        setObservationConfig(updatedConfig)
    }

    /*const handleOnImport = async () => {
    }*/

    const handleOnCancel = () => {
        window.location.href = '/admin/Configuration';
    }

    const handleOnExport = async () => {
        setExportLoading(true);
        const result = await importExportMgr.ExportData(SystemTypes.ImportExportType.ExportObservationConfig);
        if (!result.Success) {
            setExportLoading(null);
            setErrors(["Failed to export Observation Configs, if this issue continues please contact NIET support."]);
        }
        else {
            importExportMgr.downloadFile(result.Items.first()).then(r => {
                setExportLoading(false);
            }).catch(e => {
                setExportLoading(false);
                setErrors(["Observation Config export has been created and failed to download. Please check Import/Export Admin."]);
            });
        }
        return result;
    }


    return (<>
        <MainLayout errors={errors} validationErrors={validationErrors} >
            <div className='screen-header'>
                <h3>Configuration Management {observationConfig && <>for {observationConfig?.SchoolYearIdentifier}</>}</h3>
                <h5>Edit Default Configuration </h5>
            </div>
            <ScreenWrapper loading={loading} className={'no-height'}>
                <div className='observation-config-container fullscreen'>
                    <div className="tab-container">
                        <div className={"tab" + (activeObservationConfigTab === 'Admin' ? ' active' : '')} onClick={() => setActiveObservationConfigTab('Admin')}><span>School Admin</span></div>
                        <div className={"tab" + (activeObservationConfigTab === 'Teacher' ? ' active teacher-tab' : '')} onClick={() => setActiveObservationConfigTab('Teacher')}><span>Teacher</span></div>
                        <div className={"tab" + (activeObservationConfigTab === 'Display' ? ' active teacher-tab' : '')} onClick={() => setActiveObservationConfigTab('Display')}><span>Display</span></div>
                        <div className={"tab hidden-tab"} />
                    </div>
                    <div className={"observation-config"} >
                        <ObservationSection
                            downloadMgr={orgMgr?.OrganizationManager}
                            observationConfig={observationConfig}
                            activeObservationConfigTab={activeObservationConfigTab}
                            updateObservationConfigs={updateObservationConfigs}
                            updateUserPositionRubricMap={updateUserPositionRubricMap}
                            updateUserPositionSurveyTemplateMap={updateUserPositionSurveyTemplateMap}
                            showCertPositions={true}
                            forceValidate={forceValidate}
                            adminSurveyList={adminSurveyList}
                            teacherSurveyList={teacherSurveyList}
                            formattedTeacherObservationRubrics={formattedTeacherObservationRubrics}
                            formattedTeacherCertificationRubrics={formattedTeacherCertificationRubrics}
                            formattedAdminObservationRubrics={formattedAdminObservationRubrics}
                            formattedAdminCertificationRubrics={formattedAdminCertificationRubrics}
                            orgTreeUsers={orgTreeUsers}
                            updateUserPositionAlternativeMap={updateUserPositionAlternativeMap}
                            certificationQuestions={certificationQuestions}
                        />

                    </div>
                </div>
                <div className='screen-footer screen-footer-right'>
                    <div className='btn-wrapper-right screen-footer__btn'>
                        {orgMgr?.CanView && <><ButtonControl loading={exportLoading} type={'cancel'}
                            onClick={handleOnExport}>
                            Export All
                        </ButtonControl>&nbsp;&nbsp;</>}
                    </div>
                    {<div className='btn-wrapper-right screen-footer__btn'>
                        <><ButtonControl loading={saving} type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl>&nbsp;&nbsp;</>
                    </div>}
                    {<div className='btn-wrapper-right screen-footer__btn'>
                        <><ButtonControl loading={saving} type={'okay'} disabled={!observationConfig?.wasEdited} onClick={handleOnSave}>Save</ButtonControl>&nbsp;&nbsp;</>
                    </div>}



                </div>
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default ObservationConfigMgmt;