import { useEffect, useState, useMemo } from 'react';
import _, { difference } from 'lodash';
import ButtonControl from '../../../../components/controls/ButtonControl';
import certImg from '../../certificationimg.png'
import certImg2 from '../../certificationimg2.png'
import rubricIcon from '../../../../content/icons/grading.svg'
import checkmark from '../../../../content/icons/Vector.svg'
import failIcon from '../../../../content/icons/Close.svg'
import videoIcon from '../../../../content/icons/Video.svg'
import documentIcon from '../../../../content/icons/document.png'
import announcementIcon from '../../../../content/icons/Announcement.svg'
import SurveyTable from '../../../admin/components/observation/SurveyTable';
import { faCloudArrowDown, faEllipsisVertical, faPrint, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../../../../components/Loading';
import { DialogControl } from '../../../../components/controls/DialogControl';
import moment from 'moment';
import RubricPrintScreen from '../../../observations/components/RubricPrintScreen'
import { useNavigate } from 'react-router-dom';
import { useAppUserManager } from '../../../../hooks/useManagers';
import BitmovinPlayer from '../../../contentLibrary/app-components/Content/Player/BitmovinPlayer';
import ReactQuill from 'react-quill';

const CertificationTeacherDetails = ({
    certificationTeacherMgr,
    handleOpenPrint,
    observationConfig,
    certificationHistory,
    setCertificationHistory,
    certificationRatingMgr,
    loadingRubric,
    data,
    createNewCertificate,
    gridData,
    ratingSelectOptions,
    setRatingSelectOptions,
    saveCertification,
    updateUserPermissions,
    setErrors }) => {
    //Revist why app user mgr is here
    const appUserMgr = useAppUserManager();
    const [openRubricDialog, setOpenRubricDialog] = useState(false)
    const [certificationData, setCertificationData] = useState(data)
    const [currentPhase, setCurrentPhase] = useState(1)
    const [questions, setQuestions] = useState(null)
    const [currentQuestion, setCurrentQuestion] = useState(null)
    const [printing, setPrinting] = useState(false)
    const [isSnappedLeft, setIsSnappedLeft] = useState(true);
    const [isSnappedRight, setIsSnappedRight] = useState(false);
    const [leftWidth, setLeftWidth] = useState(40);
    const [indicatorsRated, setIndicatorsRated] = useState(null)
    const [answerVideo, setAnswerVideo] = useState(null)
    const [openAnswerFileDialog, setOpenAnswerFileDialog] = useState(false)
    const [watchedRubricVideo, setWatchedRubricVideo] = useState(false)
    const [watchedQuestionVideo, setWatchedQuestionVideo] = useState(false)
    const [saving, setSaving] = useState(false)
    const [useBitmovinPlayer, setUseBitmovinPlayer] = useState(true)
    const [currentTime, setCurrentTime] = useState(null)
    const navigate = useNavigate()
    const FIXED_ROW_WIDTH = "9rem"
    useEffect(() => {
        if (ratingSelectOptions) {
            let ir = Object.keys(ratingSelectOptions).reduce((r, cv) => {
                if (ratingSelectOptions[cv].selectedRating) {
                    r++;
                }
                return r;
            }, 0);
            setIndicatorsRated(ir);
        }
    }, [ratingSelectOptions])

    useEffect(() => {
        if (data.CurrentVideoTimeStamp === "FINISHED") {
            setWatchedRubricVideo(true)
        }
        if (data.Status !== 1 && data.Status !== 0 && data.Status !== 3 && !data.CompletedDate && currentPhase !== 7) {
            setCurrentPhase(6)
        }
    }, [data])

    const grabCurrentOrNextQuestion = (certificationQuestions) => {
        const questionNumber = _.findIndex(certificationQuestions ? certificationQuestions : certificationData.Questions, x => currentQuestion ? currentQuestion.Id !== x.Id && x.SelectedAnswer === null : x.SelectedAnswer === null)
        if (questionNumber !== -1) {
            const question = _.find(certificationQuestions ? certificationQuestions : certificationData.Questions, x => currentQuestion !== null ? currentQuestion.Id !== x.Id && x.SelectedAnswer === null : x.SelectedAnswer === null)
            const moveToBottomAnswerIndex = _.findIndex(question.CertificationQuestion.Answers, x => x.MoveToBottom === true)
            if (moveToBottomAnswerIndex !== -1) {
                const answer = question.CertificationQuestion.Answers.splice(moveToBottomAnswerIndex, 1)[0]
                question.CertificationQuestion.Answers.push(answer)
            }
            const questionObj = {
                QuestionText: question.CertificationQuestion.QuestionText,
                Answers: question.CertificationQuestion.Answers,
                CertificationId: question.CertificationQuestion.CertificationId,
                SchoolYearIdentifier: question.CertificationQuestion.SchoolYearIdentifier,
                SelectedAnswer: null,
                Id: question.Id,
                FileUrl: question.CertificationQuestion.File?.FileUrl,
                hasFile: question.CertificationQuestion.File && true,
                number: questionNumber + 1,
            }
            setCurrentQuestion(questionObj)
        }
    }


    const updateQuestionAnswer = (value) => {
        const updateCurrentQuestion = { ...currentQuestion }
        updateCurrentQuestion.SelectedAnswer = value
        setCurrentQuestion(updateCurrentQuestion)
    }

    const saveQuestion = async () => {
        setSaving(true)
        const updateQuestions = [...questions]
        const questionToUpdate = _.find(updateQuestions, x => x.Id === currentQuestion.Id)
        const questionIndex = _.findIndex(updateQuestions, x => x.Id === currentQuestion.Id)
        questionToUpdate.SelectedAnswer = currentQuestion.SelectedAnswer
        questionToUpdate.QuestionText = currentQuestion.QuestionText
        questionToUpdate.SelectedAnswerText = _.find(currentQuestion.Answers, x => x.Id === currentQuestion.SelectedAnswer)?.AnswerText
        questionToUpdate.IsCorrect = currentQuestion.SelectedAnswer === _.find(currentQuestion.Answers, x => x.IsCorrect)?.Id


        updateQuestions.splice(questionIndex, 1, questionToUpdate)
        setQuestions(updateQuestions)

        let result = await certificationTeacherMgr.saveCertificationQuestion(questionToUpdate).then(r => {
            setSaving(false)
            return true
        })
        return result
    }

    const updateCertification = async (action, currentTimeStamp) => {
        setSaving(true)
        const spreadData = { ...certificationData }
        if (action === "FinishedRubricVideo") {
            if (certificationData.CurrentVideoTimeStamp !== "FINISHED") {
                spreadData.CurrentVideoTimeStamp = "FINISHED"
                setWatchedRubricVideo(true)
            }
        } else if (action === 'NextBtnRubric') {
            spreadData.Status = 1
            setWatchedQuestionVideo(false)
        } else if (action === 'FinishRubricRatings') {
            //How many to pass?
            const didPass = await tallyRubricScore()
            if (didPass) {
                //Pass Part 1
                spreadData.Status = 2
                spreadData.CompletedDatePart1 = moment().utc().toISOString()
                spreadData.UserPosition = spreadData.User.UserPositionType;
            } else {
                //Fail Part 1
                let failedCertResult = await certificationTeacherMgr.handleCertificationFailed(3, spreadData.Id)
                const copiedCert = { ...failedCertResult.Items.first() }
                copiedCert.RetakeCountPart1 = spreadData.RetakeCountPart1
                setCertificationData(copiedCert)
                setSaving(false)
                return failedCertResult
            }
        } else if (action === 'BeginPart2') {
            if (spreadData.Status !== 4) {
                spreadData.Status = 4
                spreadData.CompletedDate = null
            }
        } else if (action === 'FinishQuestions') {
            if (_.countBy(questions, "IsCorrect")?.true >= observationConfig?.NumberToPassTeacherCert) {
                spreadData.Status = 5
                spreadData.UserPosition = spreadData.User.UserPositionType;
                spreadData.DistrictId = spreadData.DistrictIds?.first();
                spreadData.SchoolId = spreadData.SchoolIds?.first();
                spreadData.CompletedDate = moment().utc().toISOString()
                if (!(appUserMgr.canView('CertificationTraining') || appUserMgr.canExecute('CertificationTraining'))) {
                    updateUserPermissions();
                }
            } else {
                //failure
                let failedCertResult = await certificationTeacherMgr.handleCertificationFailed(6, spreadData.Id)
                const copiedCert = { ...failedCertResult.Items.first() }
                copiedCert.RetakeCount = spreadData.RetakeCount
                setCertificationData(copiedCert)
                setSaving(false)
                return failedCertResult
            }
        } else if (action === 'UpdateRubricVideoTimeStamp') {
            spreadData.CurrentVideoTimeStamp = currentTimeStamp
        }

        setCertificationData(spreadData)
        let result = await saveCertification(spreadData).then(() => {
            setSaving(false)
        })
        return result
    }


    const tallyRubricScore = async () => {
        return await certificationRatingMgr.grabCertificationRatingsKey(certificationData?.RubricId, certificationData?.ContentId).then(r => {
            const compareRatingArray = _.map(ratingSelectOptions, ratingSelectOption => {
                const selectedRating = parseInt(ratingSelectOption.selectedRating)
                const correctRating = _.find(r.Rubric.RubricIndicatorRatings, x => x.SelectedRating && x.RubricIndicatorId === ratingSelectOption.rubricIndicatorId)?.Rating
                return {
                    selectedRating: selectedRating,
                    correctRating: correctRating,
                    differenceInRating: Math.abs(selectedRating - correctRating)
                }
            })
            const differencesCounted = _.countBy(compareRatingArray, 'differenceInRating')
            if (compareRatingArray.some(x => !x.correctRating)) {
                setErrors(["Rubric has no correct ratings set up yet."])
            } else if (compareRatingArray.some(x => x.differenceInRating >= 4)) {
                return false
            } else if (differencesCounted[2] > observationConfig.CertificationRatingOffBy2Teacher || differencesCounted[3] > observationConfig.CertificationRatingOffBy3Teacher) {
                return false
            } else return true
        })
    }


    const checkIsVideo = (fileUrl) => {
        return (fileUrl?.toLowerCase()?.includes('.mpeg') ||
            fileUrl?.toLowerCase()?.includes('.mpg') ||
            fileUrl?.toLowerCase()?.includes('.mpga') ||
            fileUrl?.toLowerCase()?.includes('.avi') ||
            fileUrl?.toLowerCase()?.includes('.mp4') ||
            fileUrl?.toLowerCase()?.includes("streaming.media.azure.net")
        )
    }
    function handleNextPhase() {
        setCurrentPhase(currentPhase + 1);
    }


    const selectRating = (indicator, rating) => {
        let ratingSelectOpts = { ...ratingSelectOptions };
        ratingSelectOpts[indicator].isExpanded = !ratingSelectOpts[indicator].isExpanded;
        ratingSelectOpts[indicator].selectedRating = rating;

        let certificationRatingAnswers = {
            RubricId: certificationData.RubricId,
            CertificationId: certificationData.Id,
            RubricRatingId: gridData.metaData.reduce((r, cv) => {
                if (cv.rating == rating) {
                    r = cv.rubricRatingId;
                }
                return r;
            }, null),
            RubricIndicatorId: ratingSelectOpts[indicator].rubricIndicatorId,
        }
        if (ratingSelectOpts[indicator].surveyRatingId) {
            certificationRatingAnswers.CertificationRubricRatingId = ratingSelectOpts[indicator].surveyRatingId
            certificationRatingAnswers.Id = ratingSelectOpts[indicator].surveyRatingId
        }

        certificationTeacherMgr.saveCertificationRubricRating(certificationRatingAnswers).then(res => {
            if (res) {
                if (res?.Items?.first()) {
                    let item = res.Items.first();
                    ratingSelectOpts[indicator].surveyRatingId = item.Id;
                }
            }
        })
        setRatingSelectOptions(ratingSelectOpts);
    }

    const toggleRatingOptions = (indicator) => {
        let ratingSelectOpts = { ...ratingSelectOptions };
        if (ratingSelectOpts[indicator]) {
            ratingSelectOpts[indicator].isExpanded = !ratingSelectOpts[indicator]?.isExpanded;
            setRatingSelectOptions(ratingSelectOpts);
        }
    }

    const mouseDownHandler = (mouseDownEvent) => {

        function onMouseMove(mouseMoveEvent) {

            if (isSnappedLeft) {
                setLeftWidth(40);
                setIsSnappedLeft(false);
            }
            else if (isSnappedRight) {
                setLeftWidth(100);
                setIsSnappedRight(false);
            }
            setLeftWidth(Math.max(40, 100 * (mouseMoveEvent.pageX / document.body.clientWidth)))
        }

        function onMouseUp() {
            document.body.removeEventListener("mousemove", onMouseMove);
        }

        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onMouseUp, { once: true });
    }
    const handleOnPrintReport = async () => {
        setPrinting(true);
    }

    const printCompleted = () => {
        setPrinting(false)
    }

    const renderRubricModal = () => {
        return <>
            {printing && <RubricPrintScreen rubricData={gridData} handlePrintCompleted={printCompleted} />}

            <div className={'blockui'} />
            <div className={`rubric-modal expanded-height`}>
                <div className={'rubric-modal-header'}>
                    <div className={'rubric-title'}>
                        {gridData?.rubricName} Rubric
                    </div>
                    <div className={'btn-ctrl-section'}>
                        {printing ? <div style={{ marginRight: '2rem' }}><Loading /></div> : <FontAwesomeIcon className={'fa-icon'} icon={faPrint} onClick={() => handleOnPrintReport(true)} />}
                        {!printing &&
                            <>
                                <FontAwesomeIcon className={'fa-icon'} icon={faCloudArrowDown} />
                                <FontAwesomeIcon className={'fa-icon'} icon={faXmark} onClick={() => setOpenRubricDialog(false)} />
                            </>
                        }
                    </div>
                </div>
                <div className={'rubric-section'}>
                    {loadingRubric ? <Loading type='default' size='5rem' /> : <SurveyTable
                        isSelfReflection={true}
                        disableActOfRating={true}
                        gridData={gridData}
                        fixedRowWidth={FIXED_ROW_WIDTH}
                        hideRatingBtn={true}
                        hideIndicatorsProgress={true}
                    />}
                </div>
            </div>
        </>
    }
    const userCantRetake = (part) => {
        const differenceInDate = new Date() - new Date(moment.utc(certificationData.CompletedDate).local());
        const differenceInDays = differenceInDate / (1000 * 60 * 60 * 24)
        if (differenceInDays < observationConfig?.DaysUntillRetryTeacherCert ||
            (part === 2 && certificationData.RetakeCount + 1 >= observationConfig?.CertificationRetryAttemptsTeacher) ||
            (part === 1 && certificationData.RetakeCountPart1 + 1 >= observationConfig?.CertificationRetryAttemptsTeacherPart1)) {
            return true
        }
    }
    const handleRetake = async (part) => {
        setSaving(true)
        const throwAwayCert = { ...certificationData }
        throwAwayCert.IsActive = false
        await saveCertification(throwAwayCert).then(() => {
            certificationTeacherMgr.getSingleCertificationWithUserId(certificationData.UserId).then((newCertResult) => {
                const newCertObject = newCertResult.Items.first()
                if (part === 2) {
                    newCertObject.Status = 4
                }
                createNewCertificate(newCertObject, true).then(r => {
                    setWatchedRubricVideo(false)
                    setSaving(false)
                    setCertificationData(r)
                    if (part === 2) {
                        grabCurrentOrNextQuestion(r.Questions)
                        setQuestions(r.Questions)
                        setCurrentPhase(7)
                    } else handleNextPhase()
                })
            })
        })
    }
    const renderPhaseOne = () => {
        return <div className='certification'>
            <h1>ONLINE CERTIFICATION</h1>
            <div className="certification-content">
                <div className="certification-para-wrapper">
                    <p className="certification-para">Welcome to Evaluator Certification. To become certified to perform Teacher Evaluations, please complete this two-step process.</p>
                    <p className="certification-para">
                        1. View and evaluate a teacher lesson.
                        <br />
                        2. Answer 8 questions regarding the Post-Conference.
                    </p>
                    <p className="certification-para">
                        After you click BEGIN, do NOT use your browser's back arrow button. For assistance with the Online Certification Process, please click <a target="_blank" href='/downloads/Teacher Evaluator Certification User Guide_08.16.pdf'>Online Certification User Guide</a>.
                    </p>
                </div>
                <figure className='certification-image-wrapper'><img src={certImg} alt="certImg" className='certification-image' /></figure>
            </div>
            <div className="certification-double-column">
                <div className="double-column-left">
                    <h3>{certificationData?.SchoolYearIdentifier - 1}-{certificationData?.SchoolYearIdentifier} School Year</h3>
                    <div className="row-header">
                        <div className="col">Step</div>
                        <div className="col">Description</div>
                        <div className="col">Status</div>
                        <div className="col">{certificationData?.Status !== 5 && 'Action'}</div>
                    </div>
                    <div className="row">
                        <div className="col">1</div>
                        <div className="col">View Lesson</div>
                        <div className="col">
                            {(certificationData.Status === 1) ? "In Progress"
                                :
                                (certificationData.Status === 0) ? "No Attempt"
                                    :
                                    (certificationData.Status === 2 || certificationData.Status >= 4) ? "Passed" : certificationData.RetakeCountPart1 + 1 >= observationConfig?.CertificationRetryAttemptsTeacherPart1 ? "Max Attempts" : "Failed"
                            }
                        </div>
                        <div className="col">
                            {((certificationData.Status === 1 || certificationData.Status === 0)) ?
                                <ButtonControl type={'okay'}
                                    loading={saving}
                                    onClick={async () => {
                                        if (_.isNil(certificationData.Questions)) {
                                            setSaving(true)
                                            await createNewCertificate(null, true).then(r => setCertificationData(r))
                                            setSaving(false)
                                        }
                                        if (certificationData.Status === 1 && certificationData.CurrentVideoTimeStamp === "FINISHED") {
                                            setCurrentPhase(4)
                                        } else if (certificationData.CurrentVideoTimeStamp) {
                                            setCurrentPhase(3)
                                        } else handleNextPhase();
                                    }}
                                    disabled={!appUserMgr?.canWrite('Certification') || (!observationConfig || userCantRetake(1)) && certificationData.Status === 3}
                                >{certificationData.Status === 1 ? "Continue" : "Begin"}
                                </ButtonControl>
                                :
                                (certificationData.Status === 3) && <ButtonControl type={'okay'}
                                    loading={saving}

                                    onClick={async () => {
                                        await handleRetake()
                                    }}
                                    disabled={!appUserMgr?.canWrite('Certification') || ((!observationConfig || userCantRetake(1)) && certificationData.Status === 3)}
                                >{certificationData.RetakeCountPart1 + 1 >= observationConfig?.CertificationRetryAttemptsTeacherPart1 ? "Locked" : "Retake"}</ButtonControl>}
                        </div>

                    </div>
                    <div className="row">
                        <div className="col">2</div>
                        <div className="col">Post Conference</div>
                        <div className="col">{(certificationData.Status === 4) ? "In Progress" : ((certificationData.Status === 5 || certificationData.Status == 9) ? "Passed" : certificationData.Status !== 6 ? "No Attempt" : certificationData.RetakeCount + 1 >= observationConfig?.CertificationRetryAttemptsTeacher ? "Max Attempt" : "Failed")}</div>
                        <div className="col">{(certificationData.Status === 2 || certificationData.Status === 4) ? <ButtonControl type={'okay'}
                            onClick={() => {
                                if (certificationData.Status !== 4) {
                                    updateCertification("BeginPart2")
                                }
                                if (certificationData.Status === 4) {
                                    setCurrentPhase(8)
                                } else handleNextPhase();
                                grabCurrentOrNextQuestion()
                                setQuestions(certificationData.Questions)
                            }}>{certificationData.Status === 2 ? "Begin" : "Continue"}</ButtonControl>
                            :
                            (certificationData.Status === 6) && <ButtonControl type={'okay'}
                                loading={saving}
                                onClick={async () => {
                                    await handleRetake(2)
                                }}
                                disabled={!appUserMgr?.canWrite('Certification') || ((!observationConfig || userCantRetake(2)) && certificationData.Status === 6)}
                            >{certificationData.RetakeCount + 1 >= observationConfig?.CertificationRetryAttemptsTeacher ? "Locked" : "Retake"}</ButtonControl>}</div>
                    </div>
                </div>
                <div className="double-column-right">
                    <h3>History</h3>
                    <div className="row-header">
                        <div className="col">School Year</div>
                        <div className="col">Print Certificate</div>
                    </div>
                    {_.map(certificationHistory, x => {
                        return <div className="row">
                            <div className='col'>{x.SchoolYearIdentifier}</div>
                            <div className="col">
                                {
                                    printing === x.Id ? <div className="loading-wrapper"><Loading type='default' size='1rem' /></div> :
                                        printing ?
                                            <FontAwesomeIcon className={'fa-icon fa-icon-disabled'} icon={faPrint} />
                                            :
                                            <FontAwesomeIcon className={'fa-icon'} onClick={() => {
                                                setPrinting(x.Id)
                                                handleOpenPrint(x, x).then(r => {
                                                    setPrinting(null)
                                                })
                                            }} icon={faPrint} />
                                }

                            </div>
                        </div>
                    })}

                </div>
            </div>
        </div>
    }

    const renderPhaseTwo = (passed) => {
        if (certificationData.Status === 2 || certificationData.Status === 5) {
            passed = true
        } else if (certificationData.Status === 3 || certificationData.Status === 6) {
            passed = false
        }

        const retakeDate = new Date(Date.now(certificationData.CompletedDate) + (observationConfig?.DaysUntillRetryTeacherCert * 1000 * 60 * 60 * 24))
        let hours = retakeDate.getHours();
        const minutes = retakeDate.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours || 12;

        const minutesStr = minutes < 10 ? '0' + minutes : minutes;

        return <div className='certification'>
            <div className='phase-two'>
                <h1>ONLINE CERTIFICATION</h1>
                <div className="certification-subheader-wrapper">
                    <h2> General Knowledge and Case Studies</h2>
                    <button className='view-rubric-btn' onClick={() => setOpenRubricDialog(true)}>
                        <img src={rubricIcon} alt="" />
                        View Rubric
                    </button>
                </div>
                <div className="certification-content">
                    <figure className='certification-image-wrapper'>
                        <img className='certification-image' src={certImg2} alt="certImg2" />
                    </figure>
                    <div className="certification-para-wrapper">
                        {passed !== undefined ?
                            passed ? <h3><img src={checkmark} alt="" /> Exam Result: <span>Passed</span></h3> : <h3><img src={failIcon} alt="" /> Exam Result: <span>Did not meet the requirements</span></h3>
                            :
                            <h3> {certificationData?.Status == 4 ? "Step #2: Post-Conference" : "Step #1A: Observe a Lesson"}</h3>

                        }
                        <p className="certification-para">
                            {passed !== undefined ? passed ? certificationData.Status == 2 ? 'Congratulations! You have passed Part I of the Teacher Evaluator Certification. Click "Finish" to move on to start Part II.'
                                : 'Congratulations! You have passed Part II of the Teacher Evaluator Certification.' :
                                "The number of correct questions was not answered to pass this exam. "
                                :
                                certificationData?.Status == 1 ? "Please watch the following video of a teacher's lesson and prepare to evaluate it using the rubric provided. You may pause the video at any time, but the entire video must be viewed in order to proceed to the next screen to evaluate the lesson. "
                                    : "Please answer the following multiple choice questions in this step. You must view all documents and watch all videos prior to selecting your answer choice. Remember, do NOT use your browser's back arrow button."}
                        </p>
                        {passed === false && <div className="attempt-text-wrapper">
                            <img src={announcementIcon} alt="" />
                            <p>{((certificationData.Status == 3 && certificationData.RetakeCountPart1 + 1 >= observationConfig?.CertificationRetryAttemptsTeacherPart1) ||
                                (certificationData.Status == 6 && certificationData.RetakeCount + 1 >= observationConfig?.CertificationRetryAttemptsTeacher)) ? 'You have reached the maximum number of attempts for this certification.' : `You may retake the exam after ${retakeDate.toLocaleDateString('en-us')} at ${hours}:${minutesStr} ${ampm}.`}</p>
                        </div>}
                        <div className="btn-wrapper">
                            {passed !== undefined ?
                                passed ? <ButtonControl type={'okay'} onClick={() => {
                                    if (certificationData.Status === 2) {
                                        handleNextPhase();
                                    } else if (certificationData.Status === 5) {
                                        setCertificationHistory([...certificationHistory, certificationData])
                                        setCurrentPhase(1)
                                    }
                                }}>FINISH</ButtonControl>
                                    :
                                    <ButtonControl type={'cancel'} onClick={() => {
                                        setCurrentPhase(1)
                                    }}>RETURN</ButtonControl>
                                :
                                <ButtonControl type={'okay'} onClick={handleNextPhase}>START</ButtonControl>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const [pauseVideo, setPauseVideo] = useState(false)
    

    const memoizedBitmovinVideo = () => {
        return <div className='video-player'>
                <div id='player-wrapper'>
                    <BitmovinPlayer content={certificationData?.Content?.File} 
                        onEnded={() => {
                            updateCertification("FinishedRubricVideo")
                        }}
                        onPause={(currentTime) => {
                            setCurrentTime(currentTime);
                        }}
                        currentTimeStamp={parseInt(certificationData?.CurrentVideoTimeStamp)}
                    />
                </div>
            </div>
    }
    

    const handlePauseVideoAndSave = () => {
        updateCertification("UpdateRubricVideoTimeStamp", currentTime)
        navigate('/certifications/teacher')
    }

    const onUnMountRubricVideo = (currentTime) => {
        if (certificationData.CurrentVideoTimeStamp !== "FINISHED" && currentTime !== 0) {
            updateCertification("UpdateRubricVideoTimeStamp", currentTime)
        }
    }

    const onBitmovinPlayer = () => {
        setUseBitmovinPlayer(!useBitmovinPlayer);
    }

    const renderPhaseThree = () => {
        return <div className='phase-three'>
            <div className={'video-section'} style={isSnappedLeft ? { width: '40%' } : (isSnappedRight ? { width: `calc(100% - calc(16px + ${FIXED_ROW_WIDTH}))` } : { width: `${leftWidth}%`, maxWidth: `calc(100% - calc(16px + ${FIXED_ROW_WIDTH}))` })}>
                <h1>ONLINE CERTIFICATION</h1>
                <div className="phase-three-subheader">
                    <div className="left">
                        <h3>Step #1A: Observe a Lesson</h3>

                        {/* Make dynamic later */}
                        <p>Video: {certificationData?.Content?.DisplayFileName}</p>
                    </div>
                    <div className="right">
                        <div className="button-wrapper">
                            <ButtonControl
                                type={'okay'}
                                loading={saving}
                                onClick={() => {
                                    handlePauseVideoAndSave()
                                }}
                            >
                                SAVE & CONTINUE LATER
                            </ButtonControl>
                            <ButtonControl
                                disabled={!watchedRubricVideo}
                                type={'okay'}
                                loading={saving && certificationData.CurrentVideoTimeStamp === "FINISHED"}
                                onClick={() => {
                                    updateCertification("NextBtnRubric").then(() => {
                                        if (!saving) {
                                            handleNextPhase()
                                        }
                                    })
                                }}
                            >
                                NEXT
                            </ButtonControl>
                        </div>
                        <span>
                            <em>Entire video must be watched to proceed.</em>
                        </span>
                    </div>
                </div>
                <div className="video-wrapper">
                    {memoizedBitmovinVideo()}
                </div>
            </div>
            <div className={`rubric-section`} style={isSnappedRight ? { width: `calc(${FIXED_ROW_WIDTH} + 16px)` } : (isSnappedLeft ? { width: '60%' } : { width: `${100 - leftWidth}%` })}>
                <div
                    className={`drag-me`}
                    onMouseDown={mouseDownHandler}
                    onDoubleClick={(e) => {
                        let isLeft = isSnappedLeft;
                        let isRight = isSnappedRight;

                        if (isLeft || isRight) {
                            setIsSnappedLeft(!isLeft);
                            setIsSnappedRight(isLeft);
                        } else {
                            if (e.pageX < ((1.6 * document.body.clientWidth) / 2)) {
                                setIsSnappedRight(true);
                            } else {
                                setIsSnappedLeft(true);
                            }
                        }
                    }}>
                    <FontAwesomeIcon icon={faEllipsisVertical} className={'person-icon'} />
                </div>
                <div className={`content-area`}>
                    {loadingRubric ? <div className="loading-wrapper"><Loading type='default' size='5rem' /> </div> : <SurveyTable
                        isSelfReflection={true}
                        disableActOfRating={true}
                        gridData={gridData}
                        fixedRowWidth={FIXED_ROW_WIDTH}
                        hideRatingBtn={true}
                        hideIndicatorsProgress={true}
                    />}
                </div>
            </div>
        </div >
    }
    const renderPhaseFour = () => {
        return <div className="phase-four">
            <div className="container">
                <h1>ONLINE CERTIFICATION</h1>
                <div className="phase-three-subheader">
                    <div className="left">
                        <h3>Step #1B: Rate the Lesson</h3>
                        <p>Video: {certificationData?.Content?.DisplayFileName}</p>
                    </div>
                    <div className="right">
                        <ButtonControl
                            type={'okay'}
                            disabled={!_.every(ratingSelectOptions, "selectedRating") || loadingRubric}
                            loading={saving}
                            onClick={async () => {
                                await updateCertification("FinishRubricRatings")
                                handleNextPhase()
                            }}
                        >
                            Finish
                        </ButtonControl>
                        <span>
                            <em>All rubric items must be rated to proceed.</em>
                        </span>
                    </div>
                </div>
                <div className={"rubric-wrapper" + (loadingRubric ? ' no-borders' : '')}>
                    {loadingRubric ? <Loading type='default' size='5rem' /> : <SurveyTable
                        isSelfReflection={true}
                        ratingSelectOptions={ratingSelectOptions}
                        indicatorsRated={indicatorsRated}
                        gridData={gridData}
                        fixedRowWidth={FIXED_ROW_WIDTH}
                        toggleRatingOptions={toggleRatingOptions}
                        selectRating={selectRating} />
                    }
                </div>
            </div>

        </div>
    }

    const memoizedBitmovinQuestionVideo = () => {
        return <div className='video-player'>
                <div id='player-wrapper'>
                    <BitmovinPlayer content={currentQuestion?.File} 
                        onEnded={() => {
                            setWatchedQuestionVideo(true)
                        }}
                    />
                </div>
            </div>
    }    

    const renderPhaseFive = () => {
        return <div className='certification'>
            <div className="certification-header-wrapper">
                <h1>ONLINE CERTIFICATION</h1>
                <span className='current-total-question'>{`${currentQuestion.number - 1} questions completed / ${questions.length} total`}</span>
            </div>
            {/* Make dynamic */}
            <div className={"certification-question-wrapper" + (currentQuestion.hasFile ? " two-columns" : "")}>
                <div>
                    <h2>Question #{currentQuestion.number}</h2>
                    <p dangerouslySetInnerHTML={{ __html: currentQuestion.QuestionText.replace(/\n/g, '<br />') }} />
                </div>
                {
                    currentQuestion.hasFile && <div className="file-wrapper" onClick={() => { setWatchedQuestionVideo(true) }}>
                        {checkIsVideo(currentQuestion.FileUrl) ? <div onClick={() => { setWatchedQuestionVideo(true) }}>{memoizedBitmovinQuestionVideo()}</div> : <a href={currentQuestion.FileUrl}><img className='file-img' src={documentIcon} /></a>}
                        <div className="view-file" >{checkIsVideo(currentQuestion.FileUrl) ? "Watch Video" : <a href={currentQuestion.FileUrl}>View Document</a>}</div>
                    </div>
                }
            </div>
            <div className="certification-answer-wrapper">
                <h3>Answers:</h3>
                <div className="answer-options-wrapper">
                    <div className="answer-options">
                        <div className="option">Select</div>
                        <div className="option">Answer</div>
                        <div className="option">Description</div>
                        {_.some(currentQuestion.Answers, x => x.FileId) && <div className="option-video">Video/Document</div>}
                    </div>
                    {
                        _.map(currentQuestion.Answers, (x, i) => <div className="answer-options" key={i}>
                            <div className="option">
                                <input disabled={!watchedQuestionVideo && currentQuestion.FileUrl} onChange={() => updateQuestionAnswer(x.Id)} checked={currentQuestion.SelectedAnswer === x.Id} type="radio" />
                            </div>
                            <div className="option">
                                {String.fromCharCode(65 + i)}
                            </div>
                            <div className="option" >
                                <ReactQuill
                                    readOnly={true}
                                    theme="bubble"
                                    value={x.AnswerText}
                                    className='rq'
                                />
                            </div>
                            {x.FileId && <div className="option-video">{checkIsVideo(x.File.FileUrl) ? <img src={videoIcon} onClick={() => {
                                setAnswerVideo(x?.File)
                                setOpenAnswerFileDialog(`Answer Choice ${String.fromCharCode(65 + i)}`)
                            }} alt="" /> : <a href={x.File.FileUrl} download={"file"}><img className='document-icon' src={documentIcon} alt="" /></a>}</div>}
                        </div>)
                    }
                </div>
            </div>
            <div className="btn-wrapper"><ButtonControl
                loading={saving}
                disabled={!currentQuestion.SelectedAnswer || !currentQuestion}
                type="okay"
                onClick={async () => {
                    grabCurrentOrNextQuestion()
                    setWatchedQuestionVideo(false)
                    let result = await saveQuestion()
                    if (_.countBy(questions, (question) => question.SelectedAnswer !== null).true === questions.length && result) {
                        await updateCertification('FinishQuestions')
                        handleNextPhase()
                    }

                }}>{_.countBy(questions, (question) => question.SelectedAnswer !== null).true === questions.length - 1 ? "FINISH" : "NEXT"}</ButtonControl></div>
        </div >
    }

    const memoizedBitmovinAnswerVideo = () => {
        return <div className='video-player'>
                <div id='player-wrapper'>
                    <BitmovinPlayer content={answerVideo} />
                </div>
            </div>
    }    

    return <>
        {openAnswerFileDialog && <DialogControl title={openAnswerFileDialog} openDialog={openAnswerFileDialog} excludeCancel={true} onCancel={() => setOpenAnswerFileDialog(false)} >
            <>{memoizedBitmovinAnswerVideo}</>
        </DialogControl>}
        {openRubricDialog && renderRubricModal()}
        {currentPhase === 1 && renderPhaseOne()}
        {currentPhase === 2 && renderPhaseTwo()}
        {currentPhase === 3 && renderPhaseThree()}
        {currentPhase === 4 && renderPhaseFour()}
        {currentPhase === 5 && renderPhaseTwo(certificationData)}
        {currentPhase === 6 && renderPhaseOne()}
        {currentPhase === 7 && renderPhaseTwo()}
        {currentPhase === 8 && renderPhaseFive()}
        {currentPhase === 9 && renderPhaseTwo(certificationData)}
    </>


}

export default CertificationTeacherDetails;


