import { useEffect, useState } from 'react';
import './details.scss';
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import _, { toInteger } from 'lodash';
import { CheckboxControl, DatePickerControl, SelectListControl } from '../../../../components/controls';
import { useValidationManager } from '../../../../hooks/useValidation';
import { useOrgManager } from '../../../../hooks/useManagers';
import Loading from '../../../../components/Loading';
import { InputPicker, TreePicker } from 'rsuite';
import moment from 'moment/moment';

//TODO_R Format these to match the Identifier(Year), Month, Day to start
const SchoolYearDetails = ({ schoolYearMgr, schoolYear, onCancel, onSave, onLoadingError, onLoading, onErrors, onValidationErrors }) => {

    const [schoolYearItem, setSchoolYearItem] = useState(schoolYear ?? schoolYearMgr.DefaultItem);
    const [saving, setSaving] = useState(false);
    const [forceValidate, setForceValidate] = useState(false);
    const [organizations, setOrganizations] = useState(null);
    const validationMgr = useValidationManager(false, 'SCHOOL_YEAR');
    const orgMgr = useOrgManager();

    useEffect(() => {
        if (orgMgr) {
            if (!schoolYearItem.Id)
                schoolYearItem.IsActive = false;
            orgMgr.loadOrgTree().then(r => {
                setOrganizations(orgMgr.OrgsOnly());
            });
        }
    }, [orgMgr]);

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    const handleOnSave = async (e) => {
        let validateClient = false;
        if (schoolYearItem.isCopy || schoolYearItem.ClientId) {
            validateClient = true;
        }


        const isCopy = schoolYearItem.isCopy;
        schoolYearItem.isCopy = undefined;
        let validateResult = validationMgr.checkIsGroupValid(schoolYearItem);

        if (!validateClient) {
            validateResult = _.filter(validateResult, x => x.toLowerCase().indexOf('organization') === -1);
        }

        if (validateResult?.length === 0) {
            setForceValidate(false);
            if (onSave) {
                setSaving(true);
                var result = await onSave(schoolYearItem);
                if (result.Success) {
                    handleOnCancel();
                }
                else if (onErrors) {
                    onErrors([result?.Message?.DisplayMessage ?? [...(result?.MessageDetails ?? ['Unknown Error'])]])
                    setSchoolYearItem({ ...schoolYearItem, isCopy });
                }

                setSaving(false);
                return result;
            }
        }
        else if (onErrors) {
            setForceValidate(true);
            onValidationErrors(validateResult);
            setSchoolYearItem({ ...schoolYearItem, isCopy });
        }

    }

    const handleOnChange = (value, field) => {
        let updatedSchoolYear = { ...schoolYearItem };
        if (field === 'SchoolYearIdentifier') {
            value = Math.min(Math.max(toInteger(value), 0), 99999);
            updatedSchoolYear[field] = value;
        } else if (field === 'ConfigurationLockedDate' ||
            field === 'ConfigurationDueDate' ||
            field === 'RolloverDate') {
            updatedSchoolYear[field] = moment.utc(value).format("YYYY-MM-DD HH:mm:ss");
        } else {
            updatedSchoolYear[field] = value;
        }
        setSchoolYearItem(updatedSchoolYear);
    }


    return <div className='control-box-wrapper school-year-details'>
        {schoolYearItem && <> <div className='control-box box-two-column'>
            <InputField forceValidate={forceValidate} type="text" subTitle={'Once set, the school year cannot be changed.'} value={schoolYearItem?.SchoolYearIdentifier} isDisabled={schoolYearItem?.CreatedDate} title="School Year" fieldName="SchoolYearIdentifier" groupId={'SCHOOL_YEAR'} onChange={handleOnChange} />
            <InputField
                forceValidate={forceValidate}
                value={schoolYearItem?.SchoolYearMonth}
                title="Starting Month"
                subTitle={'Starting Month of the month can only be changed when School Year is not active.'}
                fieldName="SchoolYearMonth"
                groupId={'SCHOOL_YEAR'}
                isDisabled={schoolYearItem?.IsActive}
            >
                <InputPicker
                    size='sm'
                    className="input-picker"
                    data={[...Array(12).keys()].map(i => { return { label: i + 1, value: i + 1 } })}
                    onClean={() => handleOnChange(null, "SchoolYearMonth")}
                    onSelect={(a, item) => handleOnChange(item.value, 'SchoolYearMonth')}
                    placement="autoVerticalStart"
                />
            </InputField>
            <InputField
                forceValidate={forceValidate}
                value={schoolYearItem?.SchoolYearDay}
                title="Starting Day"
                subTitle={'Starting day of the month can only be changed when School Year is not active.'}
                fieldName="SchoolYearDay"
                groupId={'SCHOOL_YEAR'}
                isDisabled={schoolYearItem?.IsActive}
            >
                <InputPicker
                    size='sm'
                    className="input-picker"
                    data={(schoolYearItem?.SchoolYearIdentifier && schoolYearItem?.SchoolYearMonth) ? [...Array(moment(`
                        ${schoolYearItem?.SchoolYearIdentifier}-${schoolYearItem?.SchoolYearMonth}
                        `, "Y-MM").daysInMonth()).keys()].map(i => { return { label: i + 1, value: i + 1 } })
                        : []}
                    onClean={() => handleOnChange(null, "SchoolYearDay")}
                    onSelect={(a, item) => handleOnChange(item.value, 'SchoolYearDay')}
                    placement="autoVerticalStart"
                />
            </InputField>
            {(schoolYearItem.isCopy || schoolYearItem.ClientId) && <>
                {organizations ? <InputField
                    forceValidate={forceValidate}
                    value={schoolYearItem?.ClientId}
                    subTitle={"The organization that this school year belongs to."}
                    title="Organization"
                    fieldName='ClientId'
                    groupId={'SCHOOL_YEAR'}
                >
                    <TreePicker
                        size='sm'
                        data={organizations ?? []}
                        valueKey={'OrgId'}
                        labelKey={'Name'}
                        onClean={() => handleOnChange(null, "ClientId")}
                        onSelect={(a, val) => handleOnChange(val, 'ClientId')}
                        placement="autoVerticalStart"
                    />
                </InputField> : <Loading />}</>}
            <InputField
                forceValidate={forceValidate}
                type="text"
                value={schoolYearItem?.Title ? schoolYearItem.Title : `${schoolYearItem?.SchoolYearIdentifier}-${toInteger(schoolYearItem?.SchoolYearIdentifier) + 1}`}
                title="Title"
                subTitle={"The name of the school year."}
                fieldName="Title"
                groupId={'SCHOOL_YEAR'}
                onChange={handleOnChange}
            />
            <InputField
                title="Active?"
                value={schoolYearItem?.IsActive}
                disableError={true}
                fieldName="IsActive"
                groupId={"SCHOOL_YEAR"}
                onChange={handleOnChange}
            >
                <CheckboxControl />
            </InputField>
        </div>

            <div className='control-box box-two-column'>
                <InputField
                    forceValidate={forceValidate}
                    title="Data Entry Lock Date"
                    subTitle={"The date that the school year will be locked and no further edits can be made without the correct permissions."}
                    maxLength={100}
                    onChange={handleOnChange}
                    groupId={"SCHOOL_YEAR"}
                    value={!schoolYearItem?.ConfigurationLockedDate || schoolYearItem?.ConfigurationLockedDate.toString().includes('0001-01-01') ? moment(`${schoolYearItem?.SchoolYearMonth}/${schoolYearItem?.SchoolYearDay}/${schoolYearItem?.SchoolYearIdentifier - 1}`, 'MM/DD/YYYY').add(-2, 'days').format('YYYY-MM-DD') : moment(schoolYearItem?.ConfigurationLockedDate).format('YYYY-MM-DD')}
                    fieldName="ConfigurationLockedDate"
                >
                    <DatePickerControl
                        includeTime={false}
                    />
                </InputField>
                <InputField
                    forceValidate={forceValidate}
                    title="Configuration Due Date"
                    subTitle={"The date that all configurations must be made."}
                    value={!schoolYearItem?.ConfigurationDueDate || schoolYearItem?.ConfigurationDueDate.toString().includes('0001-01-01') ? moment(`${schoolYearItem?.SchoolYearMonth}/${schoolYearItem?.SchoolYearDay}/${schoolYearItem?.SchoolYearIdentifier - 1}`, 'MM/DD/YYYY').add(-7, 'days').format('YYYY-MM-DD') : moment(schoolYearItem?.ConfigurationDueDate).format('YYYY-MM-DD')}
                    maxLength={100}
                    onChange={handleOnChange}
                    groupId={"SCHOOL_YEAR"}
                    fieldName="ConfigurationDueDate"
                >
                    <DatePickerControl
                        includeTime={false}
                    />
                </InputField>

                <InputField
                    forceValidate={forceValidate}
                    title="Rollover Date"
                    subTitle={"The date of the rollover."}
                    value={!schoolYearItem?.RolloverDate || schoolYearItem?.RolloverDate.toString().includes('0001-01-01') ? moment(`${schoolYearItem?.SchoolYearMonth}/${schoolYearItem?.SchoolYearDay}/${schoolYearItem?.SchoolYearIdentifier - 1}`, 'MM/DD/YYYY').format('YYYY-MM-DD') : moment(schoolYearItem?.RolloverDate).format('YYYY-MM-DD')}
                    maxLength={100}
                    onChange={handleOnChange}
                    groupId={"SCHOOL_YEAR"}
                    fieldName="RolloverDate"
                >
                    <DatePickerControl
                        includeTime={false}
                    />
                </InputField>
                <InputField
                    title="Hold Rollover"
                    value={schoolYearItem?.HoldRollover}
                    disableError={true}
                    fieldName="HoldRollover"
                    groupId={"SCHOOL_YEAR"}
                    onChange={handleOnChange}
                >
                    <CheckboxControl />
                </InputField>
            </div>
        </>}
        <div className='screen-footer right'>
            <div className='btn-wrapper-left screen-footer__btn'><ButtonControl disabled={saving} type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl></div>
            <div className='btn-wrapper-right screen-footer__btn'><ButtonControl loading={saving} type={'okay'} disabled={!schoolYearItem} onClick={handleOnSave}>Save</ButtonControl></div>

        </div>
    </div>
}

export default SchoolYearDetails;


