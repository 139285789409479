import axios from 'axios';
import configData from "../../config.json";
import fileDownload from "js-file-download";

export class BaseService {

    constructor(apiName, globalAuthState, uriType, timeout) {

        const authState = globalAuthState.get();
        this._globalAuthState = globalAuthState;
        if (authState?.isAuthenticated &&
            authState?.accessToken != null &&
            authState.idToken != null) {
            this._clientId = authState.idTokenClaims.clientId;
            this._isAuth = true;
        }
        else {
            this._isAuth = false;
        }

        if (uriType)
            this._uriType = uriType
        else
            this._uriType = "CORE";

        if (timeout)
            this._timeout = timeout;
        else
            this._timeout = 29000;

        this._urlBase = configData.API_URIS[this._uriType]

        this._url = this._urlBase + "/" + apiName;
        this._apiName = apiName;
        axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
    }

    get APIName() {
        return this._apiName;
    }

    _createAxiosInstance(ignoreSqlInclude, itemIncludes) {
        const authState = this._globalAuthState.get();
        const token = authState?.accessToken;

        let headers = {};
        if (token) {
            headers["Authorization"] = "Bearer " + token;
        }

        if (this._clientId) headers["Client_Id"] = this._clientId;

        headers["C-DX-App-Id"] = configData.APP_ID;
        if (ignoreSqlInclude) {
            headers["C-DX-Ignore-Sql-Include"] = ignoreSqlInclude;
        }

        if (itemIncludes && itemIncludes.length > 0) {
            headers["C-DX-Item-Includes"] = itemIncludes;
        }

        headers["C-DX-App-Id"] = configData.APP_ID;

        const instance = axios.create({
            timeout: this.timeout,
            headers: headers
        });

        return instance;
    }

    _createAxiosError(e) {
        return e.response && e.response?.data !== "" ? e.response.data :
            ([{
                Exception: null,
                Message: {
                    DisplayMessage: e.message,
                    Code: "JSE1",
                    Info: e.stack,
                    IsFailMessage: true,
                    Name: "JS_ERROR"
                },
                InnerResults: [],
                Items: [],
                EventPersisted: false,
                EventDefinitionId: "SYSTEM",
                EventId: "00000000-0000-0000-0000-000000000000",
                CurrentPage: 0,
                TotalItemCount: 0,
                ItemsPerPage: 0,
                MessageDetails: e.stack,
                Success: false
            }]);
    }


    _createAxiosDownloadSuccess() {
        return [{
            Exception: null,
            Message: {
                DisplayMessage: "Download has been completed.",
                Code: "JSDS",
                Info: null,
                IsFailMessage: false,
                Name: "Download Success"
            },
            InnerResults: [],
            Items: [],
            EventPersisted: false,
            EventDefinitionId: "SYSTEM",
            EventId: "00000000-0000-0000-0000-000000000000",
            CurrentPage: 0,
            TotalItemCount: 0,
            ItemsPerPage: 0,
            MessageDetails: null,
            Success: true
        }];
    }


    operation(item, operationType, queryParams) {
        const axiosInstance = this._createAxiosInstance();
        axiosInstance.defaults.headers.common["c-dx-operation"] = operationType;


        let apiFunc = this._url;

        if (queryParams != null) {
            apiFunc = apiFunc + "?";
            for (let key in queryParams) {
                apiFunc = apiFunc + key + "=" + queryParams[key] + "&";
            }
            apiFunc = apiFunc.substring(0, apiFunc.length - 1);
        }
        return axiosInstance.post(apiFunc, item)
            .then(r => { return r.data; })
            .catch(e => { return this._createAxiosError(e); });
    }


    async postOperation(operationType, id, item, queryParams, isFile, isItem) {
        const axiosInstance = this._createAxiosInstance();
        axiosInstance.defaults.headers.common["c-dx-operation"] = operationType;

        if (isItem) {
            axiosInstance.defaults.headers.common["c-dx-operation-Format"] = 'Item';
        }

        let apiFunc = `${this._url}${(id !== undefined ? `/${id}` : '')}`;

        if (queryParams) {
            apiFunc = apiFunc + "?";
            for (let key in queryParams) {
                apiFunc = apiFunc + key + "=" + queryParams[key] + "&";
            }
            apiFunc = apiFunc.substring(0, apiFunc.length - 1);
        }

        let blobResponseType;
        if (isFile) {
            blobResponseType = { 'responseType': 'blob' };
        }
        return axiosInstance.post(apiFunc, item, blobResponseType)
            .then(r => {
                if ((r.headers["content-disposition"]?.indexOf("attachment") ?? -1) > -1) {
                    const fileName = r.headers["content-disposition"].split(";")[1].split("=")[1];
                    fileDownload(r.data, fileName, r.headers["content-type"]);
                    return this._createAxiosDownloadSuccess();
                }
                else {
                    return r.data;
                }
            })
            .catch(e => { return this._createAxiosError(e); });
    }

    async getFile(id, fileName) {
        const axiosInstance = this._createAxiosInstance();
        let apiFunc = `${this._urlBase}/File/Download/${id}`;
        let blobResponseType = { 'responseType': 'blob' };

        return axiosInstance.get(apiFunc, blobResponseType)
            .then(r => {
                if ((r.headers["content-disposition"]?.indexOf("attachment") ?? -1) > -1) {
                    if (!fileName)
                        fileName = r.headers["content-disposition"].split(";")[1].split("=")[1];

                    fileDownload(r.data, fileName, r.headers["content-type"]);
                    return this._createAxiosDownloadSuccess();
                }
                else {
                    fileDownload(r.data, fileName, r.headers["content-type"]);
                    return this._createAxiosDownloadSuccess();
                }
            })
            .catch(e => {
                return this._createAxiosError(e);
            });
    }

    async getOperation(operationType, id, queryParams, isFile) {
        const axiosInstance = this._createAxiosInstance();
        axiosInstance.defaults.headers.common["c-dx-operation"] = operationType;
        let apiFunc = `${this._url}${(id !== undefined ? `/${id}` : '')}`;

        if (queryParams) {
            apiFunc = apiFunc + "?";
            for (let key in queryParams) {
                apiFunc = apiFunc + key + "=" + queryParams[key] + "&";
            }
            apiFunc = apiFunc.substring(0, apiFunc.length - 1);
        }
        let blobResponseType;

        if (isFile) {
            blobResponseType = { 'responseType': 'blob' };
        }

        return axiosInstance.get(apiFunc, blobResponseType)
            .then(r => {
                if ((r.headers["content-disposition"]?.indexOf("attachment") ?? -1) > -1) {
                    const fileName = r.headers["content-disposition"].split(";")[1].split("=")[1];
                    fileDownload(r.data, fileName, r.headers["content-type"]);
                    return this._createAxiosDownloadSuccess();
                }
                else {
                    return r.data;
                }
            })
            .catch(e => {
                return this._createAxiosError(e);
            });
    }

}