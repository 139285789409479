const TagValidateObject = () => {
    return {
        Name: {
            fieldName: 'Name',
            messageTitle: 'tag name',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'TAG'
        },
        Description: {
            fieldName: 'Description',
            messageTitle: 'tag description',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'TAG'
        },
        IsPreferenceRoot: {
            fieldName: 'IsPreferenceRoot',
            messageTitle: 'preference parent',
            isRequired: false,
            dataType: 'BOOL',
            groupId: 'TAG',
            customValidator: (value, data) => {
                if (value === true && data["ParentTagId"]) {
                    return "A preference parent cannot be be a child. Please deselect the parent tag."
                }
            },
            regEx: undefined,
            requiredDependency: undefined
        }
    }
}

export default TagValidateObject;