//https://blog.logrocket.com/simplify-react-state-management-with-hookstate/
import { hookstate } from '@hookstate/core'

const globalItemStore = hookstate({
    selectedList: null,
    selectedItemMetadata: null,
    dialogState: "closed", //closed,edit,view
    refreshItemList:false
});

export default globalItemStore;