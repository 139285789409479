import React, { useState } from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { DatePickerControl, SelectListControl } from '../../../../components/controls';

export default function NotificationFilter({ notificationMgr, onFilterChange }) {


    const handleFilterChange = (value, field) => {
        if (onFilterChange) {
            onFilterChange(field, value);
        }
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        notificationMgr.filterData({ search: null, isActive: 'UNK' });
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Notification Management</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search'
                            value={notificationMgr?.Filter?.search ?? ''}
                            fieldName='search'
                            disableError={true}
                            placeholder='Title, Message Contents'
                            onChange={handleFilterChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='type'
                            title='Type'
                            fieldName='type'
                            value={notificationMgr?.Filter?.type ?? 'UNK'}
                            disableError={true}
                            onChange={handleFilterChange}>
                            <SelectListControl
                                textValuePairs={[
                                    { text: 'Message', value: 0 },
                                    { text: 'Important', value: 1 },
                                    { text: 'Urgent', value: 2 },
                                ]}
                            />
                        </InputField>
                        <InputField id='isActive'
                            title='Active?'
                            value={notificationMgr?.Filter?.isActive ?? 'UNK'}
                            fieldName='isActive'
                            disableError={true}
                            onChange={handleFilterChange}>
                            <SelectListControl textValuePairs={[{ text: 'Yes', value: true }, { text: 'No', value: false }]} />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='fromDate'
                            title='From-To Range'
                            subTitle='Start of date range'
                            value={notificationMgr?.Filter?.fromDate ?? ''}
                            fieldName='fromDate'
                            disableError={true}
                            onChange={handleFilterChange}>
                            <DatePickerControl
                                includeTime={false}
                                maxDate={notificationMgr?.Filter?.toDate}
                            />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='toDate'
                            subTitle='End of date range'
                            value={notificationMgr?.Filter?.toDate ?? ''}
                            fieldName='toDate'
                            disableError={true}
                            onChange={handleFilterChange}>
                            <DatePickerControl
                                includeTime={false}
                                minDate={notificationMgr?.Filter?.fromDate}
                            />
                        </InputField>
                    </div>
                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}