
import React, { useState } from 'react';
import { HexColorPicker } from "react-colorful";

const ColorPickerControl = ({ field,
    value,
    onChange,
    onBlur,
    className,
    disabled,
    loading,
    props
}) => {

    const [selectedColor, setSelectedColor] = useState(value)
    let idLabel = "field_label_" + field.FieldName;
    const onChangeColor = (value, field) => {
        setSelectedColor(value);
        onChange({ target: { value } }, field);
    }

    return (
        <>
            <span {...(field.IsRequired && { required: true })} id={idLabel}>{field.DisplayName}</span>
            <HexColorPicker
                id={"field_cpk_" + field.FieldName}
                name='color'
                color={value}
                onChange={(context) => onChangeColor(context, field)}
                {...props} />
            <div>{selectedColor}</div>
        </>
    );
}

export default ColorPickerControl;