export default [
    {
        Order: 1,
        Text: '"For our small school and our small community, [NIET] has been a game changer for us. The partnership came around at the best moment because we were looking to reform our school, support our teachers more, make them even more effective, grow our students, and help them achieve more.”',
        Name: 'Stephen Prince',
        Position: 'Principal',
        School: 'Cross County High School',
        District: 'Cross County School District',
        State: 'Arkansas',
        Image: 'Stephen Prince_M6530-D050 300dpi.jpg',
        StateImage: 'US_Map_48-AR.svg'
    },
    {
        Order: 2,
        Text: '"Our partnership with NIET is a game-changer. NIET has empowered our educators to give their very best and our students to do their best."',
        Name: 'Pat Mapes',
        Position: 'Former Superintendent',
        School: 'Perry Township Schools',
        District: '',
        State: 'Indiana',
        Image: 'Pat Mapes_Screenshot 2023-01-19 96dpi.jpg',
        StateImage: 'US_Map_48-IN.svg'
    },
    {
        Order: 3,
        Text: '"I belong to a district who saw great potential in its people and invested a lot of training and support in us. Over the years, I have been able to embrace and benefit from the multiple career paths laid out by the NIET process. I have been able to grow as a teacher and as a leader to have a greater impact on our students, our school, our district, our community, and our great Navajo Nation."',
        Name: 'Melissa Martin',
        Position: 'Principal',
        School: 'Chinle Junior High School',
        District: 'Chinle Unified School District',
        State: 'Arizona',
        Image: 'Melissa Martin_M6519-D029 300dpi.jpg',
        StateImage: 'US_Map_48-AZ.svg'
    },
    {
        Order: 4,
        Text: '"I wish I knew about NIET in my first year as principal. Our work with NIET has resulted in an instructional culture that is more focused, reflective and purpose-driven. Teachers are delivering instruction with more clarity, depth and enjoyment!"',
        Name: 'Hayward Jean',
        Position: 'Director of Student Services',
        School: '',
        District: 'Orangeburg County School District',
        State: 'South Carolina',
        Image: 'Hayward Jean - 300dpi.jpg',
        StateImage: 'US_Map_48-SC.svg'
    },
    {
        Order: 5,
        Text: '"One of my greatest concerns as a school system leader is ensuring equality across the district. Through our partnership with NIET, and by closely following the practices established through the TAP System, I have peace of mind in knowing that all of our children are being impacted in the same way across our system. Personally, I feel like the most vital piece of support NIET brings to the table is the challenge to be better."',
        Name: 'Clay Corley',
        Position: 'Superintendent',
        School: 'DeSoto Parish Schools',
        District: '',
        State: 'Lousiana',
        Image: 'Clay Corley 300dpi.jpg',
        StateImage: 'US_Map_48-LA.svg'
    }
]