import React from 'react';
import { Button } from '@mui/material';
import { ContentTypeEnum } from '../../../../services/ContentService';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ShareIcon from '@mui/icons-material/Share';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import CircularProgress from '@mui/material/CircularProgress';


import './Content.scss';
import { useContentManager } from '../../../../../../hooks/useManagers';
import Loading from '../../../../../../components/Loading';
import { useState } from 'react';

const Content = ({ authMgr, lowResBackupPhoto, content, onClose, onView, onLike, onShare, onDownload, canShare, onFullScreen }) => {

  const contentMgr = useContentManager();
  const [downloading, setDownloading] = useState(false);

  const handleOnDownload = async (id) => {
    if (onDownload) {
      setDownloading(true);
      await onDownload(id);
      setDownloading(false);
    }
  }

  return <div className="content">
    <div className="content__background">
      <div className="content__background__shadow" />
      <div
        className="content__background__image"
        style={{ backgroundImage: `url(${lowResBackupPhoto})` }}
      />
      <div
        className="content__background__image"
        style={{ backgroundImage: `url(${content.imgUrlLarge})` }}
      />
    </div>
    <div className="content__area">
      <div className="content__area__container">
        <div className="content__titleHeader mb-4">
          <div className="content__title">{content.Title}</div>
          {content.SharedWithUser && <Button title="Shared with Me" disableRipple="true" className="viewerActionButtons ml-4"><SupervisedUserCircleIcon /></Button>}
        </div>
        <div className="content__description">
          <div className="content__descriptionText mb-4">{content.Description}</div>
          <div className="content__descriptionText mb-4">{content.RunningTime}</div>
          {authMgr?.canExecute("ContentLibrary") && <div className="buttonFrame">
            {authMgr?.canView("LikeContent") && <Button title="Rating" className="viewerActionButtons mr-3" onClick={(e) => onLike(content.KeyId)}>
              {!contentMgr?.RatingLoading && <>{!content.HasUserRating ? <div className="contentIcons"><ThumbUpAltOutlinedIcon />Like</div> : <div className="contentIcons"><ThumbUpIcon />Like</div>}</>}
              {contentMgr?.RatingLoading && <div className="contentIcons"><CircularProgress color="inherit" size={36} /></div>}
            </Button>}
            {canShare && <>
              {!content.UserHasShared ? <Button title="Share" className="viewerActionButtons mr-3" onClick={(e) => onShare(content.KeyId)}><div className="contentIcons"><ShareIcon />Share</div></Button> : <Button title="Shared with Others" className="viewerActionButtons mr-3" onClick={(e) => onShare(content.KeyId)}><div className="contentIcons"><GroupAddIcon />Share</div></Button>}
            </>}
            <Button title="View" className="viewerActionButtons contentIcons" onClick={(e) => onView(content.KeyId)}>
              <div className="contentIcons">
                <VisibilityIcon />View
              </div>
            </Button>
            {content.ContentTypeId == ContentTypeEnum.LearningModule &&
              <Button className="viewerActionButtons ml-3" onClick={(e) => onFullScreen(content)}>
                <div title="Fullscreen" className="contentIcons">
                  <ScreenShareOutlinedIcon />Fullscreen
                </div>
              </Button>
            }
            {authMgr?.canView("DownloadContent") && content.ContentTypeId == ContentTypeEnum.Resource && <Button title="Download" className="viewerActionButtons ml-3" onClick={(e) => handleOnDownload(content.KeyId)}>
              <div className="contentIcons">
                {!downloading ? <><CloudDownloadIcon />Download</> : <><div className='loading-icon-wrapper'><Loading size={'2rem'} /></div></>}
              </div>
            </Button>}
          </div>}
        </div>
      </div>
      <Button title="Close" className="content__close" onClick={onClose}>
        <CloseIcon />
      </Button>
    </div>
  </div>
}

export default Content;
