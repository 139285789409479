import _ from "lodash";
import moment from "moment";
import react, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { asyncForEach } from "../../../contentLibrary/utils/jsUtil";
import { FormatUTCDate } from "../../../../utilities/DateFormater";


const TagTable = ({ filteredItems, onEdit, onDelete }) => {
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Name',
            dataProp: "Name",
            width: '26%',
            canSort: true
        },
        {
            header: 'Parent Tag',
            dataProp: 'ParentTagId',
            width: '21%',
            canSort: true
        },
        {
            header: 'Description',
            dataProp: 'Description',
            width: '25%',
            canSort: true
        },
        {
            header: 'Create Date',
            dataProp: 'CreatedDate',
            width: '10%',
            datatype: 'date',
            canSort: true
        },
        {
            header: 'Content Library?',
            dataProp: 'ShowInContentLibrary',
            width: '10%',
            datatype: 'bool',
            isBool: true,
            canSort: true
        },
        {
            header: 'Enabled?',
            dataProp: 'IsActive',
            width: '10%',
            datatype: 'bool',
            isBool: true,
            canSort: true
        }
    ]);

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            setRows([...filteredItems]);
        }
    }, [filteredItems]);

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                permission='Tag'
                data={rows}
                useIntegratedPaging={['10', '25', '50', '100']}
                onEdit={(row) => onEdit(row.TagId)}
                onDelete={onDelete}
            />
        </div>
    </>
}

export default TagTable;