import { useEffect, useState } from 'react';
import _ from 'lodash';


const TeacherSurveyResults = ({ data, saveSurvey }) => {
    const [surveyQnA, setSurveyQnA] = useState(null)
    useEffect(() => {
        if (data) {
            const formattedSurveyQnA = _.reduce(_.orderBy(data.SurveyTemplate.SurveyTemplateFields, ['Sequence'], ['asc']), (newMap, x) => {
                const surveyItemDetails = _.find(data.SurveySections, ds => ds.SurveyTemplateFieldId === x.Id)
                const surveyItem = {
                    FieldDisplayText: x.DisplayText,
                    Value: x.SurveyFieldType == 3 ? surveyItemDetails?.Value?.split(',') : surveyItemDetails?.Value,
                    SurveyContents: surveyItemDetails?.SurveyContents?.first()
                }
                newMap.push(surveyItem)
                return newMap
            }, [])
            setSurveyQnA(formattedSurveyQnA)
        }
    }, [data])

    const renderSurveyFields = (field) => {
        return <div className="review-row">
            <div className="item-question name">
                {field?.FieldDisplayText}
            </div>
            <div className="item-value-wrapper rating">
                {field.Value}
            </div>
        </div>
    }

    return <>
        {data && <>
            <div className="survey-container">
                <div className="survey">
                    <div className="review-header">
                        <div className="survey-result-header review-header name">
                            Survey Question
                        </div>
                        <div className="survey-result-header-answer rating">
                            Response
                        </div>
                    </div>
                    {_.map(surveyQnA, (field, i) => {
                        return renderSurveyFields(field)
                    })}
                </div>
            </div>

        </>
        }
    </>
}

export default TeacherSurveyResults;


