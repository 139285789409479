//https://blog.logrocket.com/simplify-react-state-management-with-hookstate/
import { hookstate } from '@hookstate/core'


export const APIStatusType = {
    AccessDenied: -3,
    InitFailed: -2,
    Startup: -1,
    Offline: 0,
    Initializing: 1,
    OnlineSecure: 2,
    Online: 3,
    UserSetup: 4,
    TOSNotFound: 5,

}

const globalAppUserStore = hookstate({
    userProfile: null,
    userPermissionsMap: null,
    permissionsMap: null,
    schoolYearMap: null,
    selectedSchoolYear: null,
    currentSchoolYear: null,
    app: null,
    initFailed: false,
    loadingData: false, // Update all managers.
    apiStatus: APIStatusType.Startup,
    reportParameters: null,
});

export default globalAppUserStore;