import { DialogControl } from "../../../../components/controls/DialogControl";
import BitmovinPlayer from '../../../contentLibrary/app-components/Content/Player/BitmovinPlayer';

const ContentModal = ({
    open,
    title,
    setOpen,
    okText,
    handleOk,
    item
}) => {

    const checkIsVideo = (fileExtension) => {
        return (
            fileExtension === 'mp4' ||
            fileExtension === 'avi' ||
            fileExtension === 'mpg' ||
            fileExtension === 'mpga' ||
            fileExtension === 'streaming.media.azure.net'
        );
    }

    return (
        <DialogControl
            className="certq-details-ans-content-dialog"
            openDialog={open}
            title={title}
            onCancel={() => setOpen(false)}
            okText={okText}
            onOk={(e) => {
                setOpen(false);
                handleOk(e);
            }}
        >
            {/* have a dialog with the openAnswerContent image or video */}
            {item?.File && (
                <div>
                    <div className={`__content ${checkIsVideo(item.File.FileExtention) ? "file-upload-image" : ""}`}>
                        {checkIsVideo(item.File.FileExtention) ? 
                            item.File && item.File.FileUrl !== null && item.File.FileUrl !== undefined ? 
                                <div className='video-player'>
                                    <div id='player-wrapper'>
                                        <BitmovinPlayer content={item.File} />
                                    </div>
                                </div>
                                : 
                                <></>
                          : (
                            <img
                                src={item.File.FileUrl}
                                alt="Answer Content"
                            />
                        )}
                    </div>
                    <div className="certq-filename">
                        <span>{item.File.DisplayFileName}</span>
                        <span>{"." + item.File.FileExtention}</span>
                    </div>
                </div>
            )}
        </DialogControl>
    );
}

export default ContentModal;