import { React, useEffect, useState } from 'react';
import { Panel } from 'rsuite';
import { InputField } from '../../../../../components/controls/InputField';
import { LabelControl } from '../../../../../components/controls';
import './../styles/adminWeightedPerformanceMetrics.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import ButtonControl from "../../../../../components/controls/ButtonControl";
import _ from 'lodash';

const AdminWeightedPerformanceMetrics = ({
    payoutConfigMetrics,
    payoutWeightedPerformanceRatios,
    configId,
    setPayoutConfigMetrics,
    setCustomMetricWasDeleted,
    setWeightedPerformanceRatios }) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const handleOnChangePrincipalScoreRatio = (value, field, metric) => {
        const updatedConfigMetricData = _.cloneDeep(payoutConfigMetrics);
        const metricIndex = payoutConfigMetrics.findIndex(x => x.Sequence == metric.Sequence)
        const metricToUpdate = { ...updatedConfigMetricData[metricIndex] }
        if (value === '' || value === null || value === undefined) {
            value = 0;
        }
        if (metricIndex !== -1) {
            const principalScoreRatio = updatedConfigMetricData[metricIndex].PayoutAdminPrincipalScoreRatios.find(x => x.Score == field)
            const principalScoreRatioIndex = updatedConfigMetricData[metricIndex].PayoutAdminPrincipalScoreRatios.findIndex(x => x.Score == field)
            principalScoreRatio.RatioAmount = value
            metricToUpdate.PayoutAdminPrincipalScoreRatios.splice(principalScoreRatioIndex, 1, principalScoreRatio)
            updatedConfigMetricData[metricIndex] = metricToUpdate
            setPayoutConfigMetrics(updatedConfigMetricData)
        }

    }
    const handleOnChangeAssistantPrincipalScoreRatio = (value, field, metric) => {
        const updatedConfigMetricData = _.cloneDeep(payoutConfigMetrics);
        const metricIndex = payoutConfigMetrics.findIndex(x => x.Sequence == metric.Sequence)
        const metricToUpdate = { ...updatedConfigMetricData[metricIndex] }
        if (value === '' || value === null || value === undefined) {
            value = 0;
        }
        if (metricIndex !== -1) {
            const principalScoreRatio = updatedConfigMetricData[metricIndex].PayoutAdminAssistantPrincipalScoreRatios.find(x => x.Score == field)
            const principalScoreRatioIndex = updatedConfigMetricData[metricIndex].PayoutAdminAssistantPrincipalScoreRatios.findIndex(x => x.Score == field)
            principalScoreRatio.RatioAmount = value
            metricToUpdate.PayoutAdminAssistantPrincipalScoreRatios.splice(principalScoreRatioIndex, 1, principalScoreRatio)
            updatedConfigMetricData[metricIndex] = metricToUpdate
            setPayoutConfigMetrics(updatedConfigMetricData)
        }
    }

    const handleOnChangeConfigMetric = (value, field, metricSequence) => {
        const updatedConfigMetricData = _.cloneDeep(payoutConfigMetrics);
        const metricToUpdate = updatedConfigMetricData.findIndex(x => x.Sequence == metricSequence)
        updatedConfigMetricData[metricToUpdate][field] = value;
        setPayoutConfigMetrics(updatedConfigMetricData);
    }

    const handleOnChangeWeightedPerformanceRatio = (value, field) => {
        const updatedWeightedPerformanceRatioData = { ...payoutWeightedPerformanceRatios };
        updatedWeightedPerformanceRatioData[field] = value;
        setWeightedPerformanceRatios(updatedWeightedPerformanceRatioData);
    }
    const handleOnMetricCreate = () => {
        var metricSet = [...payoutConfigMetrics];
        let principalScoreRatios = [];
        let assistantPrincipalScoreRatios = [];
        let newMetric = {
            Name: `Custom Metric${payoutConfigMetrics.length + 1}`,
            Description: '',
            isNewMetric: true,
            PayoutAdminConfigurationId: configId,
            AssistantPrincipalAllocation: 0,
            PrincipalAllocation: 0,
            Sequence: payoutConfigMetrics.length + 1,
            IsActive: true
        }

        for (let i = 0; i < 9; i++) {
            const score = 5 - i * 0.5;
            const principalScoreRatio = {
                Score: score,
                PayoutAdminConfigurationId: configId,
            }
            const assistantPrincipalScoreRatio = {
                Score: score,
                PayoutAdminConfigurationId: configId,
            }
            principalScoreRatios.push(principalScoreRatio)
            assistantPrincipalScoreRatios.push(assistantPrincipalScoreRatio)
        }


        newMetric.PayoutAdminPrincipalScoreRatios = principalScoreRatios
        newMetric.PayoutAdminAssistantPrincipalScoreRatios = assistantPrincipalScoreRatios
        metricSet.push(newMetric);
        setPayoutConfigMetrics(metricSet);
    }

    const handleOnToggleMetric = (metricSequence, action) => {
        const copiedUpdatedMetrics = _.cloneDeep(payoutConfigMetrics)
        const metricIndex = payoutConfigMetrics.findIndex((x) => x.Sequence === metricSequence);
        if (metricIndex !== -1) {
            if (action === "DELETE") {
                copiedUpdatedMetrics[metricIndex].IsActive = false;
                setCustomMetricWasDeleted(true)
            } else if (action === "REACTIVATE") {
                copiedUpdatedMetrics[metricIndex].IsActive = true;
            }
        }
        setPayoutConfigMetrics(copiedUpdatedMetrics);
    }

    return (
        <>
            <Panel header="Weighted Performance Ratios" bordered>
                <table className="allocation-table">
                    <thead>
                        <tr>
                            <th className='blank-header'></th>
                            <th>Maximum Allocation ($)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='row-header'>Principal</td>
                            <td>
                                <InputField placeholder={'0'} value={payoutWeightedPerformanceRatios?.PrincipalRatio}
                                    onChange={(e) => handleOnChangeWeightedPerformanceRatio(e, 'PrincipalRatio')} type="text" disableError={true} />
                            </td>
                        </tr>
                        <tr>
                            <td className='row-header'>Assistant Principal</td>
                            <td>
                                <InputField placeholder={'0'} value={payoutWeightedPerformanceRatios?.AssistantPrincipalRatio}
                                    onChange={(e) => handleOnChangeWeightedPerformanceRatio(e, 'AssistantPrincipalRatio')} type="text" disableError={true} />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className='threshold-header'>
                    Threshold Adjustments
                </div>

                <div className='admin-observer-table'>
                    {payoutConfigMetrics ?
                        <>
                            {payoutConfigMetrics?.map((metricObject, i) => {
                                return metricObject.IsActive ?
                                    <Panel bordered key={i}>
                                        <div className='admin-metric-table'>
                                            <table className="metric-header-table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Description</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className='row-header'>
                                                            <InputField className={'custom-metric-name'} placeholder={''} value={metricObject?.Name ?? "Custom Metric"}
                                                                onChange={(e) => handleOnChangeConfigMetric(e, 'Name', metricObject.Sequence)} type="text" disableError={true} />
                                                        </td>
                                                        <td className='description-cell'>
                                                            <InputField Id={metricObject.Id} placeholder={''} value={metricObject.Description}
                                                                onChange={(e) => handleOnChangeConfigMetric(e, 'Description', metricObject.Sequence)} type="text" disableError={true} />
                                                        </td>
                                                        <td className='metric-icon-container'>
                                                            <FontAwesomeIcon className={'fa-icon metric-trashcan'} icon={faTrashCan}
                                                                onClick={() => handleOnToggleMetric(metricObject.Sequence, "DELETE")} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='metric-ratio-table'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th className='percent-row'>%</th>
                                                        <th>5</th>
                                                        <th>4.5</th>
                                                        <th>4</th>
                                                        <th>3.5</th>
                                                        <th>3</th>
                                                        <th>2.5</th>
                                                        <th>2</th>
                                                        <th>1.5</th>
                                                        <th>1</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className='row-header'>Principal</td>
                                                        <td className='percent-row'>
                                                            <InputField
                                                                placeholder={'0'}
                                                                value={metricObject.PrincipalAllocation}
                                                                onChange={(e) => handleOnChangeConfigMetric(e, 'PrincipalAllocation', metricObject.Sequence)}
                                                                disableError={true}
                                                            />
                                                        </td>
                                                        {_.orderBy(metricObject.PayoutAdminPrincipalScoreRatios, ['Score'], ['desc'])?.map((x, index) => (
                                                            <td key={`principal-${metricObject.Sequence}-${index}`}>
                                                                <InputField
                                                                    placeholder={'0'}
                                                                    fieldName={x.Score}
                                                                    value={x.RatioAmount}
                                                                    onChange={(value, fieldName) => handleOnChangePrincipalScoreRatio(value, fieldName, metricObject)}
                                                                    disableError={true}
                                                                />
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    <tr>
                                                        <td className='row-header assist-row'>Assistant</td>
                                                        <td className='percent-row assist-row'>
                                                            <InputField
                                                                placeholder={'0'}
                                                                value={metricObject.AssistantPrincipalAllocation}
                                                                onChange={(e) => handleOnChangeConfigMetric(e, 'AssistantPrincipalAllocation', metricObject.Sequence)}
                                                                disableError={true}
                                                            />
                                                        </td>
                                                        {_.orderBy(metricObject.PayoutAdminAssistantPrincipalScoreRatios, ['Score'], ['desc'])?.map((x, index) => (
                                                            <td key={index} className='assist-row'>
                                                                <InputField
                                                                    placeholder={'0'}
                                                                    fieldName={x.Score}
                                                                    value={x.RatioAmount}
                                                                    onChange={(value, fieldName) => handleOnChangeAssistantPrincipalScoreRatio(value, fieldName, metricObject)}
                                                                    disableError={true}
                                                                />
                                                            </td>
                                                        ))}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Panel>
                                    :
                                    <Panel className='inactive-metric' bordered key={i}>
                                        <span onClick={() => handleOnToggleMetric(metricObject.Sequence, "REACTIVATE")}>Undo {metricObject.Name}</span>
                                    </Panel>
                            }

                            )}
                        </>
                        : <></>}
                </div>
                <div>
                    {payoutConfigMetrics?.length < 10 && < ButtonControl className={'create-metric-btn'} type={'okay'} onClick={handleOnMetricCreate}>Add New Metric</ButtonControl>}
                </div>
            </Panel >
        </>
    );
}

export default AdminWeightedPerformanceMetrics;