import PropTypes from 'prop-types';

const getSimplePieOptions = (data, style, legend) => {
    let formatted = data?.map((d, i) => {
        const addBy = (85 - 18) / data?.length
        return {
            value: d.value,
            seriesName: "",
            name: d.name,
            itemStyle: {
                color: `hsl(204, 100%, ${18 + (addBy * i)}%)`,
            }
        }
    })

    if (style?.sorted) // sort by the value when sorted is true
        formatted.sort((a, b) => b.value - a.value);

    const option = {
        tooltip: {
            trigger: 'item',
        },
        emphasis: {
            label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
            }
        },

        seriesName: 'Reinforcement / Refinement',
        series: [
            {
                type: 'pie',
                avoidLabelOverlap: false,
                width: 100,
                left: 'center',
                label: {
                    // show: false,
                    // formatter: (x) => { return x.name.substring(x.name.indexOf('(') + 1, x.name.length - 1) }
                },
                data: data ? formatted : [

                ],
                itemStyle: {
                    type: 'scroll',
                    borderColor: '#D5D2CF',
                    borderWidth: 1
                },
            }
        ],
        legend: {
            orient: 'horizontal',
            bottom: 0,
            selectedMode: false,
            show: false
        },
    };
    switch (legend) {
        case 'right':
            option.legend = {
                ...option.legend,
                orient: 'vertical',
                // right: '33%',
                left: style?.legend?.right ? style?.legend?.right : 'right', // 'right
                top: '5%',
                bottom: '20%',
            };
            break;
        case 'left':
            option.legend = {
                ...option.legend,
                orient: 'vertical',
                left: style?.legend?.left ? style?.legend?.left : 'left', // 'right
                top: '5%',
                bottom: '20%',
            };
            break;
        case true:
        case 'top':
            option.legend = {
                ...option.legend,
                orient: 'horizontal',
                left: 'center',
                top: 'top',
            };
            break;
        case 'bottom':
            option.legend = {
                ...option.legend,
                orient: 'horizontal',
                left: 'center',
                bottom: 'bottom',
            };
            break;
        case undefined:
        case false:
        case null:
        default:
            break;
    }
    return option;
}

getSimplePieOptions.propTypes = {
    data: PropTypes.object,
    legend: PropTypes.oneOf(['right', 'left', 'top', 'bottom', true, false, undefined, null]),
    style: PropTypes.object,
}

getSimplePieOptions.defaultProps = {
    style: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
}

export { getSimplePieOptions };