import './ContentRecommendation.scss';
import React, { useState, useEffect } from 'react';
import ButtonControl from '../../../../components/controls/ButtonControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlay, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import globalAppUserStore from '../../../../stores/globalAppUserStore';
import { useHookstate } from '@hookstate/core';
import FileUploaderControl from '../../../../components/controls/FileUploaderControl';

export default function ContentRecommendation({
    isActive,
    recommendedContentItems,
    indicatorName,
    observee,
    observer,
    onClose,
    recommend,
    onSkip,
    onSend,
    onPrevious,
    saveContentAsSeen,
    isReviewPage,
    canMakeSelections,
    showMessage
}) {
    //hook stuff
    const appUserStore = useHookstate(globalAppUserStore);

    //state stuff
    const [contentExpanded, setContentExpanded] = useState(null);

    const checkIsVideo = (fileUrl) => { //TODO: Put in some sort of utility. This is all over the place...GET RID OF DUPLICATE CODE!!!
        return ((fileUrl?.toLowerCase()?.includes('.mpeg') ||
            fileUrl?.toLowerCase()?.includes('.mpg') ||
            fileUrl?.toLowerCase()?.includes('.mpga') ||
            fileUrl?.toLowerCase()?.includes('.avi') ||
            fileUrl?.toLowerCase()?.includes('.mp4') ||
            fileUrl?.toLowerCase()?.includes("streaming.media.azure.net")
        ));
    }

    return isActive &&
        <>
            <div className={'backdrop'}></div>
            <div className={'cr-wrapper'}>
                {onClose && !contentExpanded && <div className={'close-btn'} onClick={onClose}>X</div>}
                <div className={'cr-header'}>Content Recommendation</div>
                {!isReviewPage ? <div className={'cr-header-subtxt'}>The observation has been <span>Successfully Submitted</span>. <br /><br /><b>{observee}</b> has been notified to complete the Self-Reflection. Please review<br />the content below to prepare for your Post-Conference.</div>
                    : <div className={'cr-header-subtxt'}>Based on your Refinement opportunities, please select content to improve your practice.</div>}
                {contentExpanded &&
                    <div className={'expanded-content'} style={{ backgroundImage: `url(${contentExpanded.ThumbnailFileUrl})` }}>
                        {
                            checkIsVideo(contentExpanded.FileUrl) &&
                            <FileUploaderControl
                                disabled={true}
                                controlId={`${contentExpanded.FileId}-content-recommended-expanded`}
                                maxFileSize={52428800}
                                fileUrl={contentExpanded.FileUrl}
                                canUploadAnyFile={true}
                                widthRes={812}
                                heightRes={512}
                                forceDim={true}
                                dontDisplayFileName={true}
                                positionTopZero={true}
                            />
                        }
                        <div className={'back'} onClick={() => setContentExpanded(null)}>
                            <FontAwesomeIcon icon={faArrowLeft} className={'fa-icon'} />
                        </div>
                    </div>}
                {
                    !contentExpanded &&
                    <div className={`content-pane-wrapper`}>
                        {recommendedContentItems?.length > 0 && <div className={'title'}><span>Refinement:</span>{indicatorName}</div>}
                        <div className={'pane-container'}>
                            {recommendedContentItems?.length === 0 && <h2 style={{ width: '100%', textAlign: 'center' }}>Recommended Content Coming Soon!</h2>}
                            {!contentExpanded && recommendedContentItems?.map((item) => {
                                return <div key={item._id}
                                    className={`cr-card`}
                                    style={{ backgroundImage: `url(${item.ThumbnailFileUrl})` }}
                                    onClick={() => {
                                        item.HasViewed = true;
                                        if (saveContentAsSeen) {
                                            saveContentAsSeen(item);
                                        }
                                        if (item.ContentTypeId === 1 || item.ContentTypeId === 2) {
                                            setContentExpanded(item);
                                        }
                                        else if (item.ContentTypeId === 3) {
                                            window.open(`/learningmodules/${item.FileId}/story_html5.html`);
                                        }
                                    }}
                                >
                                    <div className={'cr-title'}>
                                        {item.DisplayFileName}
                                    </div>
                                    <div className={'checkbox'}
                                        style={!canMakeSelections ? { cursor: 'not-allowed' } : {}}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (canMakeSelections) {
                                                recommend(item._id);
                                            }
                                        }}
                                    >
                                        {item.Recommended && <FontAwesomeIcon icon={faSquareCheck} className={'fa-icon'} />}
                                    </div>
                                    {checkIsVideo(item.FileUrl) && <FontAwesomeIcon icon={faPlay} className={'fa-icon-play'} />}
                                    {checkIsVideo(item.FileUrl) && <div className={'video-length'}>{item.RunningTime}</div>}
                                </div>
                            })}
                        </div>
                    </div>}
                {
                    !contentExpanded &&
                    <div className={'cr-btn-area'}>
                        {onPrevious && <ButtonControl type={'sub-action'} value={'PREVIOUS'} className={'cr-sub-action'} onClick={onPrevious} />}
                        {onSkip && <ButtonControl type={'sub-action'} value={recommendedContentItems?.length > 0 ? 'SKIP' : 'NEXT'} className={'cr-sub-action'} onClick={onSkip} />}
                        {canMakeSelections && recommendedContentItems?.length > 0 && <ButtonControl type={'okay'} value={`${isReviewPage ? 'SHARE CONTENT' : 'RECOMMEND CONTENT'}`} className={'cr-okay'} onClick={() => {
                            if (onSend) {
                                onSend()
                            }
                        }} />}
                    </div>
                }
            </div >
        </>
}