
import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe'
import InPageLoading from '../../../app-components/Loading/InPageLoading'
import { useContentManager } from '../../../../../hooks/useManagers';

export default function Articulate() {
    const contentMgr = useContentManager();
    const [articulateURL, setArticulateURL] = useState(null);
    const selectedContent = contentMgr?.SelectedContent;

    useEffect(() => {
        if (selectedContent) {
            const url = `/learningmodules/${selectedContent.FileId}`;
            if (selectedContent.Metadata)
                setArticulateURL(url + "/" + selectedContent.Metadata);
            else
                setArticulateURL(url + "/story_html5.html");
        }
    }, [selectedContent]);

    return (
        <>
            {articulateURL != null ?
                <Iframe url={articulateURL}
                    id="articulateIframe"
                    className="learningModuleFrame" />
                : <InPageLoading />
            }
        </>
    );
}
