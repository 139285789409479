const TestValidateObject = () => {
    return {
        Email: {
            fieldName: 'Email',
            messageTitle: 'email',
            isRequired: true,
            dataType: 'EMAIL',//IMAGE, INT, DEC, SELECT,BOOL,EMAIL
            groupId: 'TEST',
            regEx: undefined,
            requiredDependency: undefined
        }
    }
}

export default TestValidateObject;