
import { useState, useEffect, useContext } from 'react';
import ValidationManager from '../managers/Validation/ValidationManager';

export function useValidationManager(forceValidation, groupIds) {
    const [validationManager, setValidationManager] = useState(null);

    if (validationManager === null) {
        setValidationManager(new ValidationManager(forceValidation, groupIds));
    }

    return validationManager;
}


export function useValidationManagers(forceValidation, groupIds) {
    const [validationManagers, setValidationManagers] = useState({});
    let validationManger = validationManagers[groupIds.toString()];
    if (!validationManger) {
        const updatedMgrs = { ...validationManagers };
        updatedMgrs[groupIds.toString()] = new ValidationManager(forceValidation, groupIds);
        validationManger = updatedMgrs[groupIds.toString()];
        setValidationManagers(updatedMgrs);
    }

    return validationManger;
}