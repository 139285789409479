const UserValidateObject = () => {
    return {
        Email: {
            fieldName: 'Email',
            messageTitle: 'email',
            isRequired: true,
            dataType: 'EMAIL',
            groupId: 'USER'
        },
        FirstName: {
            fieldName: 'FirstName',
            messageTitle: 'first name',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'USER'
        },
        LastName: {
            fieldName: 'LastName',
            messageTitle: 'last name',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'USER'
        },
        PrimaryPhone: {
            fieldName: 'PrimaryPhone',
            messageTitle: 'phone number',
            isRequired: false,
            dataType: 'PHONE',
            groupId: 'USER'
        }
    }
}

export default UserValidateObject;