const OrganizationValidateObject = () => {
    return {
        Name: {
            fieldName: 'Name',
            messageTitle: 'name',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'ALL'
        },
        DisplayName: {
            fieldName: 'DisplayName',
            messageTitle: 'display name',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'ORGANIZATION'
        },
        ClientId: {
            fieldName: 'ClientId',
            messageTitle: 'organization',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'DISTRICT'
        },
        DistrictId: {
            fieldName: 'DistrictId',
            messageTitle: 'district',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'SCHOOL'
        },
        DistrictNumber: {
            fieldName: 'DistrictNumber',
            messageTitle: 'district number',
            isRequired: false,
            dataType: 'STRING',
            groupId: 'DISTRICT'
        },
        SchoolId: {
            fieldName: 'SchoolId',
            messageTitle: 'school',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'CLUSTER'
        },
        Email: {
            fieldName: 'Email',
            messageTitle: 'email',
            isRequired: true,
            dataType: 'EMAIL',
            groupId: 'ORGANIZATION'
        },
    }
}

export default OrganizationValidateObject;