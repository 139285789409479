import './controls.scss';
import _ from 'lodash';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { InputField } from '../../components/controls/InputField';
import { SelectPicker } from 'rsuite';
import { ElectricBike } from '@mui/icons-material';

const SelectListControl = ({
  field,
  fieldName,
  value,
  textValuePairs,
  disabledOptions,
  onChange,
  onBlur,
  className,
  disabled,
  placeholder,
  hidePleaseSelect,
  plsOpt,
  addOptionText,
  searchable,
  styles,
  data,
  size,
  loading,
  props
}) => {

  const inputRef = useRef();
  useEffect(() => {
    if (!searchable) {
      if (value === '' || value === 'UNK') {
        inputRef.current.value = "UNK";
      }
      else {
        if (textValuePairs) {

          let valueFound = textValuePairs.find(x => (x.Value ?? x.value) == value);
          if (valueFound) {
            inputRef.current.value = value;
          }
          else {
            inputRef.current.value = 'UNK';
          }
        }
      }
    }
  }, [value, textValuePairs]);

  return (
    <>
      {searchable ? (disabledOptions?.length > 0 ?
        <SelectPicker size={size} placeholder={value ? value : placeholder} disabled={disabled} value={value} data={data} disabledItemValues={data.map(di => { if (disabledOptions.includes(di.label)) { return di.value } })} style={styles} onChange={(e, i) => onChange(e, i)} />
        :
        <SelectPicker size={size} placeholder={value ? value : placeholder} disabled={disabled} value={value} data={data} style={styles} onChange={(e, i) => onChange(e, i)} />
      ) : (
        <select
          id={`field_sl_${field?.FieldName ?? fieldName}`}
          value={value}
          ref={inputRef}
          {...(disabled ? { disabled } : {})}
          className={`sl_control ${className}`}
          onBlur={(context) => { if (onBlur) { onBlur(context, field) } }}
          onChange={(e) => { if (onChange) { onChange(e, field) } }}
        >
          {!hidePleaseSelect &&
            <option key={"selectItem_sl_" + field?.FieldName ?? fieldName} disabled={disabledOptions?.includes('UNK') ? true : false} value="UNK">{placeholder ?? 'Select'}</option>}
          {plsOpt &&
            <option key={"selectItem_sl_addOption"} value="addOption">{addOptionText ?? 'Add New Option +'}</option>
          }
          {_.map(textValuePairs, (x, index) => {
            if (disabledOptions?.includes(x.Value)) {
              return (<option disabled key={"menuItem_sl_" + field.FieldName + "_" + (x.Value ?? x.value) + "_" + index} value={x.Value ?? x.value}>{x.Text ?? x.text ?? x.value ?? x.Value}</option>)
            }
            else {
              return (<option key={"menuItem_sl_" + field.FieldName + "_" + (x.Value ?? x.value) + "_" + index} value={x.Value ?? x.value}>{x.Text ?? x.text ?? x.value ?? x.Value}</option>)
            }
          })}
        </select>
      )
      }
    </>
  );
}

export default SelectListControl;