import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CheckTreePicker } from "rsuite";
import { CheckboxControl, SelectListControl } from "../../../../components/controls";
import ButtonControl from "../../../../components/controls/ButtonControl";
import FileUploaderControl from "../../../../components/controls/FileUploaderControl";
import { InputField } from "../../../../components/controls/InputField";
import '../../../../styles/rsuite_override.css';
import './details.scss';
import { ContentTypeEnum } from "../../../contentLibrary/services/ContentService";
import { useValidationManager } from "../../../../hooks/useValidation";
import { useContentLibraryManager, useOrgManager, useTagManager } from "../../../../hooks/useManagers";
import _ from 'lodash';
import Loading from '../../../../components/Loading';
import { guid } from 'rsuite/esm/utils';

const ContentLibraryDetails = ({ canWrite, contentId, onSave, onCancel, onValidationErrors, onErrors, onMessage, onLoadingError, onLoading }) => {
    const [itemData, setItemData] = useState({ UniqueId: contentId, FileId: null, ThumbnailFileId: null });
    const contentLibraryMgr = useContentLibraryManager();
    const tagMgr = useTagManager();
    const validationManager = useValidationManager(false, ['CONTENT']);
    const [forceValidation, setForceValidation] = useState(false);
    const [tags, setTags] = useState(null);
    const [districts, setDistricts] = useState(null);
    const [allContent, setAllContent] = useState(null);
    const organizationMgr = useOrgManager();
    const [fileLoading, setFileLoading] = useState({ content: false, image: false });
    const [thumbDld, setThumbDld] = useState(false);
    const [fileDld, setFileDld] = useState(false);
    const [disabledTags, setDisabledTags] = useState([]);
    const [defaultDistrictIds, setDefaultDistrictIds] = useState(null);
    const emptyGuid = '00000000-0000-0000-0000-000000000000';
    const [reloadingLM, setReloadingLM] = useState(false);

    useEffect(() => {
        if (tagMgr) {

            tagMgr.FullTagTree().then(r => {
                if (r?.Success) {
                    setDisabledTags(_.map(_.filter(r.Items, x => x.IsDisabled === true) ?? [], y => y.value));
                    setTags(r.Items);
                }
            });
        }
    }, [tagMgr]);

    useEffect(() => {
        if (contentLibraryMgr) {
            let query = "x => x.IsDeleted == false";
            let params;
            if (contentId) {
                query += " && x.Id != contentId";
                params = { contentId };
            }

            contentLibraryMgr.ItemManager.query(query, params, true, true).then((r) => {
                if (r?.Success) {
                    setAllContent(_.map(r?.Items ?? [], c => { return { label: c.Title, value: c.Id, children: undefined } }));
                }
            });

            organizationMgr.loadOrgDistrictTree().then(items => {
                items.unshift({
                    "OrgId": null,
                    "DistrictId": null,
                    "SchoolId": null,
                    "UserId": null,
                    "Name": "-- Common Content --",
                    "Email": null,
                    "FullName": "Common Content",
                    "NodeType": "Org",
                    "UserPositionType": 0,
                    "IsCertifiedTeacher": false,
                    "IsCertifiedSchoolAdmin": false,
                    "Children": null,
                    "_id": emptyGuid,
                    "Id": emptyGuid,
                    "UniqueId": emptyGuid,
                    "ClientId": null,
                    "IsDeleted": false,
                    "visible": true,
                    "refKey": `String_${emptyGuid}`
                });
                setDistricts(items);
            });
        }
    }, [contentLibraryMgr]);

    useEffect(() => {
        const screenData = sessionStorage.getItem('screen-data');
        if (screenData) {
            setItemData(JSON.parse(screenData));
        }
        else {
            if (contentLibraryMgr && contentId) {
                if (onLoading)
                    onLoading(true);

                contentLibraryMgr.ItemManager.get(contentId).then((r) => {
                    if (r?.Success && r?.Items?.length > 0) {
                        var item = r?.Items?.first();
                        setItemData(item);
                        saveItemInStorage(item);
                    }
                    else if (onLoadingError) {
                        onLoadingError(r?.Message.DisplayMessage);
                    }
                    if (onLoading)
                        onLoading(false);
                });
            }
        }
    }, [contentLibraryMgr]);

    const saveItemInStorage = (item) => {
        sessionStorage.setItem('screen-data', JSON.stringify(item));
    };

    const handleOnChange = (value, field) => {
        const updatedItemData = { ...itemData };
        if (value?.FileId) {
            updatedItemData[field] = value?.FileId;
            if (field === 'FileId') {
                updatedItemData.File = value;
            }
        }
        else if (field == 'DistrictIds') {
            if (value?.length === 0) {
                updatedItemData['DistrictIdsData'] = '';
                updatedItemData['DistrictIds'] = [emptyGuid];
            }
            else {
                updatedItemData['DistrictIdsData'] = value?.toString();
                updatedItemData['DistrictIds'] = value;
            }
        }
        else if (field === 'TagIds') {
            updatedItemData['TagIds'] = value ?? [];
            updatedItemData['TagIdsData'] = value?.toString() ?? '';
        }
        else {
            updatedItemData[field] = value;
        }

        saveItemInStorage(updatedItemData);
        setItemData(updatedItemData);
    }

    const onTreeClean = (event) => {
        handleOnChange(null, "TagIds");
    }

    const onTreeCleanDistrict = (event) => {
        handleOnChange([], "DistrictIds");
    }

    const onTreeCleanContentAssociation = (event) => {
        handleOnChange([], "ContentAssociations");
    }

    const onTreeSelect = (activeNode, vals) => {
        let tagTree = tags;
        let newTagIds = [];
        _.forEach(vals, val => {
            newTagIds.push(val);
            /*const tag = _.find(tagTree, t => t.value === val);
            if (tag?.children?.length > 0) {
                _.forEach(tag.children, t => {
                    newTagIds.push(t.value);
                });
            }*/
        });

        if (handleOnChange)
            handleOnChange(newTagIds, "TagIds");
    }


    const onTreeSelectDistrict = (activeNode, vals) => {
        let districtTree = districts;
        let newDistrictIds = [];
        vals.forEach(val => {
            if (!val.startsWith('o-'))
                newDistrictIds.push(val);
            const item = districtTree.find(t => t.Id == val);
            if (item && item.Children) {
                item.Children.forEach(c => {
                    newDistrictIds.push(c.Id);
                });
            }
        });

        if (handleOnChange)
            handleOnChange(newDistrictIds.map(x => x.replace('d-', '')), "DistrictIds");
    }

    const onTreeSelectContentAssociation = (activeNode, vals) => {
        let contentAssociationsTree = allContent;
        let newContentAssociations = [];
        let i = 0;
        vals.forEach(val => {
            i = i + 1;
            newContentAssociations.push({ ContentId: itemData?.UniqueId, AssociatedContentId: val, Sequence: i });
            const ca = contentAssociationsTree.find(t => t.AssociatedContentId == val);
            if (ca && ca.ParentTagId == null && ca.children) {
                ca.children.forEach(c => {
                    i = i + 1;
                    newContentAssociations.push({ ContentId: itemData?.UniqueId ?? null, AssociatedContentId: c.AssociatedContentId, Sequence: i });
                });
            }
        });

        if (handleOnChange)
            handleOnChange(newContentAssociations, "ContentAssociations");
    }

    const handleOnSave = async () => {
        const errors = validationManager.checkIsGroupValid(itemData);
        if (errors.length === 0) {
            if (onSave) {
                onLoading(true);
                itemData.File = null;
                itemData.ThumbnailFile = null;
                const contentAssociations = [...itemData.ContentAssociations ?? []];
                itemData.ContentAssociations = null;
                const result = await contentLibraryMgr.ItemManager.save(itemData);
                if (result.Success) {
                    await contentLibraryMgr.syncAssocatedContent(result.Items.first().Id, contentAssociations);
                    sessionStorage.removeItem('FileUpload-content');
                    sessionStorage.removeItem('FileUpload-displayContent');
                    onSave(result);
                    onLoading(false);
                }
                else if (onErrors) {
                    onErrors([result.Message.DisplayMessage]);
                    onLoading(false);
                }
            }
        }
        else {
            if (onValidationErrors)
                onValidationErrors(errors);
            validationManager.setForceValidate(true);
            setForceValidation(true);
        }
    }

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }

    }

    const handleResetFile = (controlId) => {
        //Clears fires a confirm dialog and clears the file.
        if (controlId === 'content') {
            sessionStorage.removeItem('FileUpload-content');
            handleOnChange(null, 'FileId');
        }

        if (controlId === 'displayContent') {
            sessionStorage.removeItem('FileUpload-displayContent');
            handleOnChange(null, 'ThumbnailFileId');
        }
    }

    const handleDownloadFile = async (isThumb) => {
        let result = null;

        if (isThumb) {
            setThumbDld(true);
            result = await contentLibraryMgr.ItemManager.getFile(itemData?.ThumbnailFile?.Id, `${itemData.ThumbnailFile?.DisplayFileName}.${itemData.ThumbnailFile?.FileExtention}`);
            setThumbDld(false);
        }
        else {
            setFileDld(true);
            result = await contentLibraryMgr.ItemManager.getFile(itemData?.File?.Id, `${itemData.File?.DisplayFileName}.${itemData.File?.FileExtention}`);
            setFileDld(false);
        }
    }

    const handleRetryArticulate = async () => {
        setReloadingLM(true);
        const result = await contentLibraryMgr.ItemManager.runOperation('ArticulateExtract', itemData?.File?.Id, undefined, undefined, false, false);
        if (result.Success) {
            setReloadingLM(false);
            onMessage(['Reloading Learning Module, this process may take an additional few minutes to complete.']);
        }
        else {
            setReloadingLM(false);
            onErrors([`Failed to start reload of learning module`, result.MessageDetails])

        }
    }

    useEffect(() => {
        if (itemData && districts) {
            setDefaultDistrictIds((!itemData?.DistrictIds || itemData?.DistrictIds?.length === 0) ? [emptyGuid] : itemData?.DistrictIds.map(x => x !== emptyGuid ? `d-${x}` : x));
        }
    }, [itemData, districts])

    return <div className='control-box-wrapper'>
        <div className='control-box box-two-column'>
            <InputField title="Title" forceValidate={forceValidation} value={itemData?.Title} onChange={handleOnChange} groupId={'CONTENT'} placeholder={'Content Title'} maxLength={400} subTitle={"Enter the title describing your content."} fieldName="Title" validationName="Content_Title" />
            <InputField title="Description" forceValidate={forceValidation} value={itemData?.Description} onChange={handleOnChange} maxLength={250} groupId={'CONTENT'} subTitle={"Provide a detailed description of your content."} type="textarea" fieldName="Description" validationName="Content_Description" />
            <InputField title="File Display Name" forceValidate={forceValidation} value={itemData?.DisplayFileName} onChange={handleOnChange} maxLength={50} groupId={'CONTENT'} subTitle={"Provide a file name to be used when a user downloads the file."} fieldName="DisplayFileName" validationName="Content_DisplayFileName" />
            <InputField title="Content Type" forceValidate={forceValidation} value={itemData?.ContentTypeId ?? 'UNK'} onChange={handleOnChange} groupId={'CONTENT'} fieldName="ContentTypeId" validationName='Content_ContentTypeId'  >
                <SelectListControl textValuePairs={
                    [{ Value: 1, Text: 'Video' }, { Value: 2, Text: 'Resource' }, { Value: 3, Text: 'Learning Module' }]
                } />
            </InputField>
            {itemData?.ContentTypeId == ContentTypeEnum.Video && <InputField title="Running Time" value={itemData?.RunningTime} placeholder="00:00:00" onChange={handleOnChange} maxLength={8} groupId={'CONTENT'} subTitle={"Amount of time to complete the video."} fieldName="RunningTime" validationName="Content_RunningTime" />}
            {(tags && itemData) ? <InputField
                id='tags'
                title='Assign Tags'
                subTitle={'Tags will be displayed as "Categories" within the Content Library.'}
                fieldName='Tags'
                className={'field-input__checkTreePicker'}
                validationName={'Content_Tags'}>
                <CheckTreePicker
                    height={320}
                    width={400}
                    cascade={false}
                    disabledItemValues={disabledTags}
                    data={tags ?? []}
                    defaultValue={itemData?.TagIds ?? []}
                    onClean={onTreeClean}
                    onSelect={onTreeSelect}
                    placement="autoVerticalStart"
                />
            </InputField> : <InputField
                id='tags'
                title='Tags'
                fieldName='Tags'
                className={'field-input__checkTreePicker'}
            ><Loading type="Default"></Loading></InputField>}
            {(allContent && itemData) ? <InputField
                id='associatedContent'
                title='Associated Content'
                fieldName='ContentAssociations'
                className={'field-input__checkTreePicker'}
                validationName={'Content_ContentAssociations'}>
                <CheckTreePicker
                    height={320}
                    width={400}
                    data={allContent ?? []}
                    defaultValue={_.map(itemData?.ContentAssociations ?? [], ac => ac.AssociatedContentId) ?? []}
                    onClean={onTreeCleanContentAssociation}
                    onSelect={onTreeSelectContentAssociation}
                    placement="top"
                />
            </InputField> : <InputField
                id='associatedContent'
                title='Associated Content'
                fieldName='ContentAssociations'
                className={'field-input__checkTreePicker'}
            ><Loading type="Default"></Loading></InputField>}
            <InputField
                id='DistrictIds'
                title='Assign to Districts'
                subTitle={<ul><li>If "Common Content" is seleted, all users will have access to the content.</li><li>If "Common Content" is <b>not</b> selected, only districts selected will have access to the content.</li><li> If both "Common Content" and one or more districts are selected then the content will be filtered for districts with the "District Only Content" flag checked in district configuration.</li><li>Please note, if nothing is selected the system will default to "Common Content".</li></ul>}
                fieldName='DistrictIds'
                className={'field-input__checkTreePicker'}
                validationName={'Content_DistrictIds'}>
                {(districts && itemData && defaultDistrictIds) ? <CheckTreePicker
                    height={320}
                    width={400}
                    labelKey="Name"
                    valueKey="Id"
                    disabledItemValues={_.map(_.filter(districts, x => !x.Children && x.Id != emptyGuid) ?? [], y => y.Id)}
                    defaultValue={defaultDistrictIds}
                    childrenKey={"Children"}
                    data={districts ?? []}
                    onClean={onTreeCleanDistrict}
                    onSelect={onTreeSelectDistrict}
                    placement="autoVerticalStart"
                /> : <Loading type="Default"></Loading>}
            </InputField>
            <InputField title="Certification?" value={itemData?.UseForCertification ?? false} onChange={handleOnChange} maxLength={100} groupId={'CONTENT'} fieldName='UseForCertification' subTitle={"Is this content used for a certification? When checked, the content will NOT be shown in the content library."} >
                <CheckboxControl />
            </InputField>
            <InputField title="Hide From Library?" value={itemData?.HideFromLibrary ?? true} onChange={handleOnChange} maxLength={100} groupId={'CONTENT'} fieldName='HideFromLibrary' subTitle={"Allows the content to be used in other parts of the system, such as the homepage, but hides the content from the library."} >
                <CheckboxControl />
            </InputField>
            <InputField title="Enabled?" value={itemData?.IsActive ?? true} onChange={handleOnChange} maxLength={100} groupId={'CONTENT'} fieldName='IsActive' validationName={'Content_IsActive'} subTitle={"Can this content be accessed in the system?"} >
                <CheckboxControl />
            </InputField>
        </div>
        <div className='control-box box-two-column'>
            <InputField title="Content available for use on Homepage?" value={itemData?.IsPublicContent ?? false} onChange={handleOnChange} maxLength={100} groupId={'CONTENT'} fieldName='IsPublicContent' subTitle={"This must be checked prior to uploading the homepage content."} >
                <CheckboxControl disabled={itemData?.FileId !== null} />
            </InputField>
            <InputField title="Upload Content" forceValidate={forceValidation} value={itemData?.FileId} onChange={(value) => handleOnChange(value, 'FileId')} groupId={'CONTENT'} fieldName='FileId' validationName={'Content_FileId'} subTitle={"Drag content into or click the box below to upload content."}>
                <FileUploaderControl canUploadAnyFile={true} controlId={`content`} isPublic={itemData?.IsPublicContent ?? false} value={itemData?.FileId} fileUrl={itemData?.File?.FileUrl} overrideMaxWidth={400} widthRes={1920} heightRes={1080} maxFileSize={1024 * 1024 * 99} onFileLoading={(val) => setFileLoading({ ...fileLoading, content: val })} />
            </InputField>
            {itemData?.FileId !== null && <div className="reset-file-btn"><ButtonControl onClick={() => handleResetFile('content')}>Reset File</ButtonControl>{itemData?.File?.FileUrl && itemData.ContentTypeId !== ContentTypeEnum.Video && <ButtonControl type={'okay'} loading={fileDld} onClick={() => handleDownloadFile()}>Download</ButtonControl>}{itemData?.File?.FileUrl && itemData.ContentTypeId === ContentTypeEnum.LearningModule && <ButtonControl type={'okay'} loading={reloadingLM} onClick={() => handleRetryArticulate()}>Reload</ButtonControl>}</div>}
            <InputField title="Display image available for use on Homepage?" value={itemData?.IsPublicDisplayContent ?? false} onChange={handleOnChange} maxLength={100} groupId={'CONTENT'} fieldName='IsPublicDisplayContent' subTitle={"This must be checked prior to uploading the homepage image. If this is not checked, the content will not be available as trending content."} >
                <CheckboxControl disabled={itemData?.ThumbnailFileId !== null} />
            </InputField>
            <InputField title="Upload Display Image" onChange={(value) => handleOnChange(value, 'ThumbnailFileId')} value={itemData?.ThumbnailFileId} maxLength={100} groupId={'CONTENT'} fieldName='ThumbnailFileId' validationName={'Content_IsActive'} subTitle={"Drag an image into or click the box  below to upload an image."}>
                <FileUploaderControl controlId={`displayContent`} isPublic={itemData?.IsPublicDisplayContent ?? false} overrideMaxWidth={300} value={itemData?.ThumbnailFileId} fileUrl={itemData?.ThumbnailFile?.FileUrl} widthRes={1500} heightRes={700} onFileLoading={(val) => setFileLoading({ ...fileLoading, image: val })} />
            </InputField>
            {itemData?.ThumbnailFileId !== null && <div className="reset-file-btn"><ButtonControl onClick={() => handleResetFile('displayContent')}>Reset File</ButtonControl>{itemData?.ThumbnailFile?.FileUrl && <ButtonControl type={'okay'} loading={thumbDld} onClick={() => handleDownloadFile(true)}>Download</ButtonControl>}</div>}
        </div>
        <div className='screen-footer right'>
            <div className='btn-wrapper-left screen-footer__btn'><ButtonControl type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl></div>
            {canWrite && <div className='btn-wrapper-right screen-footer__btn'><ButtonControl disabled={fileLoading.content || fileLoading.image} type={'okay'} onClick={handleOnSave}>Save</ButtonControl></div>}

        </div>
    </div>
}

export default ContentLibraryDetails;


