import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import RoleDetails from '../components/role/details';
import RoleFilter from '../components/role/filter';
import RoleTable from '../components/role/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useAppUserManager, useImportExportManager, useRoleManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';
import SystemTypes from '../../../services/SystemTypes';

const RoleMgmt = () => {
    const roleMgr = useRoleManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);

    const appUserMgr = useAppUserManager();
    const importExportMgr = useImportExportManager();
    const [exportLoading, setExportLoading] = useState(false);

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (roleMgr && !params?.roleId) {
            setLoading(true);
            roleMgr.search().then(fb => {
                setManageMode('list');
                setLoading(false);
            });
        }
        else if (!selectedRole && params?.roleId && roleMgr) {
            setLoading(true);
            if (params?.roleId.toLowerCase() === 'addnew') {
                setSelectedRole(roleMgr.DefaultItem);
                setManageMode('view');
                setLoading(false);
            }
            else {
                roleMgr.get(params?.roleId).then((r) => {
                    if (r.Success) {
                        setManageMode('view');
                        setSelectedRole(r.Items.first());
                    }
                    setLoading(false);
                });
            }
        }
    }, [roleMgr, params?.roleId]);

    const onAddNew = () => {
        setSelectedRole(roleMgr.DefaultItem);
        setManageMode('view');
        navigate('/admin/role/addnew');
    }

    const handleOnFilterChange = async (field, value) => {

        const roleFilter = roleMgr.Filter;
        const updatedRoleFilter = { ...roleFilter };
        updatedRoleFilter[field] = value !== '' ? value : null;
        await roleMgr.filterData(updatedRoleFilter);
    }

    const handleOnSave = async (item) => {

        var result = await roleMgr.saveItem(item);
        return result;

    }

    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        roleMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.Role}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnEdit = (item) => {
        setSelectedRole(item);
        setManageMode('view');
        navigate(`/admin/role/${item.Id}`);
    }

    const handleOnCancel = () => {
        navigate('/admin/role');
        if (roleMgr.FilteredData) {
            setManageMode('list');
        }
        else {
            setManageMode(null);
        }
        setLoadingError(null);
        setErrors(null);
    }

    const handleOnExport = async () => {
        const idList = roleMgr.FilteredData.map(x => x.Id);
        setExportLoading(true);
        const result = await importExportMgr.ExportData(SystemTypes.ImportExportType.ExportRoleMatrix, idList);
        if (!result.Success) {
            setExportLoading(null);
            setErrors(["Failed to export Role Matrix, if this issue continues please contact NIET support."]);
        }
        else {
            importExportMgr.downloadFile(result.Items.first()).then(r => {
                setExportLoading(false);
            }).catch(e => {
                setExportLoading(false);
                setErrors(["Role Matrix export has been created and failed to download. Please check Import/Export Admin."]);
            });
        }
        return result;
    }

    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete the role '${itemToDelete?.Role}'? `} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <MainLayout errors={errors} validationErrors={validationErrors} >
            {manageMode === 'view' &&
                <div className='screen-header'>
                    <h3>Role Management</h3>
                    <h5>{`${selectedRole.Id ? 'Edit' : 'Create'} Role`}</h5>
                </div>
            }
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <RoleFilter roleMgr={roleMgr} onFilterChange={handleOnFilterChange} />
                            <RoleTable filteredItems={roleMgr?.FilteredData ?? []} onEdit={handleOnEdit} onDelete={(item) => { setItemToDelete(item); }} />
                        </div>
                        <div className='screen-footer list'>
                            {roleMgr?.CanWrite && <><ButtonControl type={"create"} onClick={onAddNew}>Create Role</ButtonControl>&nbsp;&nbsp;</>}
                            {roleMgr?.CanView && <><ButtonControl loading={exportLoading} type={'cancel'}
                                onClick={handleOnExport}>
                                Export Role Matrix
                            </ButtonControl>&nbsp;&nbsp;</>}
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&
                    <RoleDetails canWrite={roleMgr.CanWrite} role={selectedRole} onCancel={handleOnCancel} onSave={handleOnSave} onLoading={setLoading} onErrors={setErrors} onValidationErrors={setValidationErrors} onLoadingError={setLoadingError} />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default RoleMgmt;