
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ContentManager from '../domains/contentLibrary/managers/ContentManager'
import { useHookstate } from '@hookstate/core';
import globalAuthStateStore from '../stores/globalAuthStateStore';
import globalAppUserStore, { APIStatusType } from '../stores/globalAppUserStore';

import _ from 'lodash';
import AppUserManager from '../managers/AppUserManager';
import contentStore from '../domains/contentLibrary/stores/contentStore';
import ItemManager from '../managers/ItemManager';
import ContentLibraryManager from '../managers/ContentLibaryManager';
import ObservationManager from '../managers/ObservationManager';
import adminContentStore from '../domains/contentLibrary/stores/adminContentStore';
import TagManager from '../managers/TagManager';
import globalTagStore from '../stores/globalTagStore';
import globalFeedbackStore from '../stores/globalFeedbackStore';
import FeedbackManager from '../managers/FeedbackManager';
import LogManager from '../managers/LogManager';
import globalLogStore from '../stores/globalLogStore';
import rubricStore from '../domains/admin/stores/rubricStore';
import RubricManager from '../managers/RubricManager';
import PermissionManager from '../managers/PermissionManager';
import SchoolYearManager from '../managers/SchoolYearManager';
import globalPermissionStore from '../stores/globalPermissionStore';
import globalSchoolYearStore from '../stores/globalSchoolYearStore';
import RoleManager from "../managers/RoleManager";
import globalRoleStore from '../stores/globalRoleStore';
import globalImportExportStore from '../stores/globalImportExportStore';
import OrganizationManager from '../managers/OrganizationManager';
import UserManager from '../managers/UserManager';
import UserProfileEmailManager from '../managers/UserProfileEmailManager';
import globalOrganizationStore from '../stores/globalOrganizationStore';
import DistrictManager from '../managers/DistrictManager';
import SchoolManager from '../managers/SchoolManager';
import PayoutManager from '../managers/PayoutManager';
import globalPayoutStore from '../stores/globalPayoutStore';
import globalDistrictStore from '../stores/globalDistrictStore';
import globalSchoolStore from '../stores/globalSchoolStore';
import globalClusterStore from '../stores/globalClusterStore';
import ClusterManager from '../managers/ClusterManager';
import globalTOSStore from '../stores/globalTOSStore';
import TOSManager from '../managers/TOSManager';
import TransactionManager from '../managers/TransactionManager';
import globalNotificationStore from '../stores/globalNotificationStore';
import NotificationManager from '../managers/NotificationManager';
import globalSurveyTemplateStore from '../stores/globalSurveyTemplateStore';
import SurveyTemplateManager from '../managers/SurveyTemplateManager';
import SurveyManager from '../managers/SurveyManager';
import globalSurveyStore from '../stores/globalSurveyStore';
import globalUserProfileStore from '../stores/globalUserProfileStore';
import globalStagedUserProfileStore from '../stores/globalStagedUserProfileStore';
import GeneralSurveyManager from '../managers/GeneralSurveyManager';
import globalGeneralSurveyStore from '../stores/globalGeneralSurveyStore';
import globalGeneralSurveyConfigStore from '../stores/globalGeneralSurveyConfigStore';
import GeneralSurveyConfigManager from '../managers/GeneralSurveyConfigManager';
import ContentSyncManager from '../managers/ContentSyncManager';
import globalContentSyncStore from '../stores/globalContentSyncStore';
import CertificationQuestionManager from '../managers/CertificationQuestionManager';
import ReportManager from '../managers/ReportManager';
import globalCertificationQuestionStore from '../stores/globalCertificationQuestionStore';
import globalAdminSurveyStore from '../stores/globalAdminSurveyStore';
import globalTeacherSurveyStore from '../stores/globalTeacherSurveyStore';
import globalObservationTeacherStore from '../stores/globalObservationTeacherStore';
import globalObservationSchoolAdminStore from '../stores/globalObservationSchoolAdminStore';
import CertificationRatingManager from '../managers/CertificationRatingManager';
import globalCertificationRatingStore from '../stores/globalCertificationRatingStore';
import CertificationManager from '../managers/CertificationManager';
import globalCertificationTeacherStore from '../stores/globalCertificationTeacherStore';
import RubricIndicatorManager from '../managers/RubricIndicatorManager';
import globalCertificationAdminStore from '../stores/globalCertificationAdminStore';
import ContentMyDashboardManager from '../managers/ContentMyDashboardManager';
import globalTransactionStore from '../stores/globalTransactionStore';
import ImportExportManager from '../managers/ImportExportManager';
import StagedUserManager from '../managers/StagedUserManager';
import AnalyticManager from '../managers/AnalyticManager';
import globalAnalyticStore from '../stores/globalAnalyticStore';
import globalPayoutAdminStore from '../stores/globalPayoutAdminStore';

let _timeoutKey = -1;

export function useContentManager() {
    const [contentManager, setContentManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const contentStateStore = useHookstate(contentStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (contentManager === null && authState?.accessToken !== null &&
            (appUserState?.apiStatus === APIStatusType.Online || appUserState?.apiStatus === APIStatusType.OnlineSecure)) {
            const contentMgr = new ContentManager(contentStateStore, authStore, appUserStore);
            setContentManager(contentMgr);
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken, contentStateStore]);

    return contentManager
}

export function useAdminContentManager() {
    const [adminContentManager, setAdminContentManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const contentStateStore = useHookstate(adminContentStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (adminContentManager === null && authState?.accessToken !== null &&
            (appUserState?.apiStatus === APIStatusType.Online || appUserState?.apiStatus === APIStatusType.OnlineSecure)) {
            const contentMgr = new ContentManager(contentStateStore, authStore, appUserStore);
            setAdminContentManager(contentMgr);
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken, contentStateStore]);
    return adminContentManager
}

export function useImportExportManager() {
    const [importExportManager, setImportExportManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const importExportStateStore = useHookstate(globalImportExportStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (importExportManager === null && authState?.accessToken !== null &&
            (appUserState?.apiStatus === APIStatusType.Online || appUserState?.apiStatus === APIStatusType.OnlineSecure)) {
            const contentMgr = new ImportExportManager(importExportStateStore, authStore, appUserStore);
            setImportExportManager(contentMgr);
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken, importExportStateStore]);
    return importExportManager
}

export function useContentMyDashboardManager() {
    const [contentMyDashboardManager, setContentMyDashboardManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (contentMyDashboardManager === null) {
            const contentMgr = new ContentMyDashboardManager(authStore, appUserStore);
            setContentMyDashboardManager(contentMgr);
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);
    return contentMyDashboardManager
}

export function useContentLibraryManager() {
    const [contentLibraryManager, setContentLibraryManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (contentLibraryManager === null) {
            const contentMgr = new ContentLibraryManager(authStore, appUserStore);
            setContentLibraryManager(contentMgr);
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);
    return contentLibraryManager
}


export function useTagManager() {
    const [tagManager, setTagManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const tagStore = useHookstate(globalTagStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (tagManager === null && authState?.accessToken !== null &&
            (appUserState?.apiStatus === APIStatusType.Online || appUserState?.apiStatus === APIStatusType.OnlineSecure)) {
            const tagMgr = new TagManager(tagStore, authStore, appUserStore);
            setTagManager(tagMgr);
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);
    return tagManager
}


export function useAppUserManager() {
    const [appUserManager, setAppUserManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();
    useEffect(() => {
        if (appUserManager === null &&
            authState?.accessToken &&
            appUserState?.apiStatus === APIStatusType.OnlineSecure) {
            const appUserMgr = new AppUserManager(authStore, appUserStore);
            setAppUserManager(appUserMgr);
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return appUserManager
}



export function useItemMananger(itemManagerName) {
    const [itemManagers, setItemManagers] = useState({});
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (itemManagers[itemManagerName] === undefined &&
            authState?.accessToken &&
            appUserState?.apiStatus === APIStatusType.OnlineSecure) {
            const itemManager = new ItemManager(itemManagerName, authStore, appUserStore);
            const updatedItemMgrs = { ...itemManagers };
            updatedItemMgrs[itemManagerName] = itemManager;
            setItemManagers(updatedItemMgrs);
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return itemManagers[itemManagerName];
}

export function useRubricManager() {
    const [rubricManager, setRubricManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const rubricStateStore = useHookstate(rubricStore);
    const observationMgr = useObservationManager();
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (rubricManager === null && authState?.accessToken !== null && observationMgr &&
            (appUserState?.apiStatus === APIStatusType.Online || appUserState?.apiStatus === APIStatusType.OnlineSecure)) {
            const rubricMgr = new RubricManager(observationMgr, rubricStateStore, authStore, appUserStore);
            setRubricManager(rubricMgr);
        }
    }, [observationMgr, appUserState, authState, appUserState?.apiStatus, authState?.accessToken, rubricStateStore]);
    return rubricManager
}

export function useRubricIndicatorManager() {
    const [rubricIndicatorManager, setRubricIndicatorManager] = useState(null);
    const appUserManager = useAppUserManager();
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const rubricStateStore = useHookstate(rubricStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (rubricIndicatorManager === null && authState?.accessToken !== null &&
            (appUserState?.apiStatus === APIStatusType.Online || appUserState?.apiStatus === APIStatusType.OnlineSecure)) {
            const rubricMgr = new RubricIndicatorManager(rubricStateStore, authStore, appUserStore);
            setRubricIndicatorManager(rubricMgr);
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken, rubricStateStore]);
    return rubricIndicatorManager
}


export function useCertificationRatingManager() {
    const [mgr, setMgr] = useState(null);
    const observartionMgr = useObservationManager();
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const certificationRatingStateStore = useHookstate(globalCertificationRatingStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (mgr === null && observartionMgr) {
            setMgr(new CertificationRatingManager(observartionMgr, certificationRatingStateStore, authStore, appUserStore));
        }
    }, [observartionMgr, appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);
    return mgr
}


export function useCertificationTeacherManager() {
    const [mgr, setMgr] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const certStore = useHookstate(globalCertificationTeacherStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (mgr == null) {
            setMgr(new CertificationManager({ sy: appUserState.selectedSchoolYear, ct: 2 }, certStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return mgr
}

export function useCertificationAdminManager() {
    const [mgr, setMgr] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const certStore = useHookstate(globalCertificationAdminStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (mgr == null) {
            setMgr(new CertificationManager({ sy: appUserState.selectedSchoolYear, ct: 1 }, certStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return mgr
}

export function useFeedbackManager() {
    const [feedbackManager, setFeedbackManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const feedbackStore = useHookstate(globalFeedbackStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (feedbackManager === null) {
            setFeedbackManager(new FeedbackManager(feedbackStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return feedbackManager
}

export function useLogManager() {
    const [logManager, setLogManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const logStore = useHookstate(globalLogStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (logManager === null) {
            setLogManager(new LogManager(logStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return logManager
}

export function usePermissionManager() {
    const [permissionManager, setPermissionManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const permissionStore = useHookstate(globalPermissionStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (permissionManager === null) {
            setPermissionManager(new PermissionManager(permissionStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return permissionManager
}

export function useContentSyncManager() {
    const [mgr, setMgr] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalContentSyncStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (mgr === null) {
            setMgr(new ContentSyncManager(store, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return mgr
}

export function useSchoolYearManager() {
    const [schoolYearManager, setSchoolYearManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const schoolYearStore = useHookstate(globalSchoolYearStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (schoolYearManager === null) {
            setSchoolYearManager(new SchoolYearManager(schoolYearStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return schoolYearManager
}

export function useRoleManager() {
    const [roleManager, setRoleManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const roleStore = useHookstate(globalRoleStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (roleManager === null) {
            setRoleManager(new RoleManager(roleStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return roleManager
}

export function useSurveyManager() {
    const [surveyManager, setSurveyManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const surveyStore = useHookstate(globalSurveyStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (surveyManager == null) {
            setSurveyManager(new SurveyManager(null, surveyStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return surveyManager;
}

export function useAnalyticManager() {
    const [analyticManager, setAnalyticManager] = useState(null);
    const orgMgr = useOrgManager()
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const analyticStore = useHookstate(globalAnalyticStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (analyticManager == null && orgMgr) {
            setAnalyticManager(new AnalyticManager(orgMgr, analyticStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken, orgMgr]);

    return analyticManager;
}

export function useObservationManager(isTeacher) {
    const [observationManager, setObservationManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const observationStore = useHookstate(globalObservationTeacherStore);
    const observationStoreAdmin = useHookstate(globalObservationSchoolAdminStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (observationManager == null) {
            setObservationManager(new ObservationManager(isTeacher ? observationStore : observationStoreAdmin, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return observationManager
}

export function useObservationSchoolAdminManager() {
    const [observationSchoolAdminManager, setObservationSchoolAdminManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const observationStore = useHookstate(globalObservationSchoolAdminStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (observationSchoolAdminManager == null) {
            setObservationSchoolAdminManager(new ObservationManager(observationStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return observationSchoolAdminManager
}


export function useObservationTeacherManager() {
    const [observationTeacherManager, setObservationTeacherManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const observationStore = useHookstate(globalObservationTeacherStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (observationTeacherManager == null) {
            setObservationTeacherManager(new ObservationManager(observationStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return observationTeacherManager
}
export function useOrgManager() {
    const [orgManager, setOrgManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const orgStore = useHookstate(globalOrganizationStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (orgManager == null) {
            setOrgManager(new OrganizationManager(appUserState.selectedSchoolYear, orgStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return orgManager
}

export function useUserManager() {
    const [userManager, setUserManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const userProfileState = useHookstate(globalUserProfileStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (userManager == null) {
            setUserManager(new UserManager(userProfileState, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return userManager
}


export function useStagedUserManager() {
    const [userManager, setUserManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const userProfileState = useHookstate(globalStagedUserProfileStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (userManager == null) {
            setUserManager(new StagedUserManager(userProfileState, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return userManager
}

export function useUserProfileEmailManager() {
    const [userProfileEmailManager, setUserProfileEmailManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const userProfileStore = useHookstate(globalUserProfileStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (userProfileEmailManager == null) {
            setUserProfileEmailManager(new UserProfileEmailManager(userProfileStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return userProfileEmailManager
}

export function useDistrictManager() {
    const [districtManager, setDistrictManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalDistrictStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (districtManager == null) {
            setDistrictManager(new DistrictManager(appUserState.selectedSchoolYear, store, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return districtManager
}


export function useTOSManager() {
    const [tosMgr, setTOSMgr] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalTOSStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (tosMgr == null) {
            setTOSMgr(new TOSManager(store, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return tosMgr;
}

export function useAdminSurveyManager() {
    const [mgr, setMgr] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalAdminSurveyStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();
    useEffect(() => {
        if (mgr === null) {
            setMgr(new SurveyManager({ sy: appUserState.selectedSchoolYear, st: 5 }, store, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return mgr;
}

export function useTeacherSurveyManager() {
    const [mgr, setMgr] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalTeacherSurveyStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();
    useEffect(() => {
        if (mgr === null) {
            setMgr(new SurveyManager({ sy: appUserState.selectedSchoolYear, st: 4 }, store, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return mgr;
}


export function useGeneralSurveyConfigManager() {
    const [mgr, setMgr] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalGeneralSurveyConfigStore);
    const surveyTemplateMgr = useSurveyTemplateManager();
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();
    useEffect(() => {
        if (mgr === null && surveyTemplateMgr) {
            setMgr(new GeneralSurveyConfigManager(surveyTemplateMgr, store, authStore, appUserStore));
        }
    }, [appUserState, authState, surveyTemplateMgr, appUserState?.apiStatus, authState?.accessToken]);

    return mgr;
}
export function useGeneralSurveyManager() {
    const [mgr, setMgr] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalGeneralSurveyStore);
    const observationMgr = useObservationManager();
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();
    useEffect(() => {
        if (mgr === null && observationMgr) {
            setMgr(new GeneralSurveyManager(observationMgr, store, authStore, appUserStore));
        }
    }, [observationMgr, appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return mgr;
}


export function useNotificationManager() {
    const [notificationMgr, setNotificationMgr] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalNotificationStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (notificationMgr == null) {
            setNotificationMgr(new NotificationManager(store, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return notificationMgr;
}


export function useSurveyTemplateManager() {
    const [mgr, setMgr] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const surveyMgr = useSurveyManager();
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalSurveyTemplateStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();
    useEffect(() => {
        if (mgr === null && surveyMgr) {
            setMgr(new SurveyTemplateManager(surveyMgr, store, authStore, appUserStore));
        }
    }, [surveyMgr, appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return mgr;
}




export function useSchoolManager() {
    const [schoolManager, setSchoolManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalSchoolStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (schoolManager == null) {
            setSchoolManager(new SchoolManager(appUserState.selectedSchoolYear, store, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return schoolManager
}

export function useClusterManager() {
    const [clusterManager, setClusterManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalClusterStore);
    const orgManager = useOrgManager();
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();


    useEffect(() => {
        if (clusterManager == null && orgManager) {

            setClusterManager(new ClusterManager(orgManager, store, authStore, appUserStore));
        }
    }, [appUserState, authState, orgManager, appUserState?.apiStatus, authState?.accessToken]);

    return clusterManager
}

export function useTransactionManager() {
    const [transactionManager, setTransactionManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalTransactionStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (transactionManager == null) {

            setTransactionManager(new TransactionManager(store, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return transactionManager
}

export function useCertificationQuestionManager() {
    const [certificationQuestionManager, setCertificationQuestionManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalCertificationQuestionStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (certificationQuestionManager == null) {
            setCertificationQuestionManager(new CertificationQuestionManager(store, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return certificationQuestionManager
}

export function useReportManager() {
    const [reportManager, setReportManager] = useState(null);
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const store = useHookstate(globalClusterStore);
    const orgManager = useOrgManager();
    const clusterManager = useClusterManager();
    const schoolYearManager = useSchoolYearManager();
    const userManager = useUserManager();
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (reportManager == null && orgManager && clusterManager && schoolYearManager && userManager) {

            setReportManager(new ReportManager(orgManager, clusterManager, schoolYearManager, userManager, store, authStore, appUserStore));
        }
    }, [appUserState, authState, orgManager, clusterManager, schoolYearManager, userManager, appUserState?.apiStatus, authState?.accessToken]);

    return reportManager
}

export function usePayoutManager() {
    const [payoutManager, setPayoutManager] = useState(null);
    const { pathname } = useLocation();
    const appUserStore = useHookstate(globalAppUserStore);
    const authStore = useHookstate(globalAuthStateStore);
    const payoutStateStore = useHookstate(pathname.toLowerCase().includes('teacher') ? globalPayoutStore : globalPayoutAdminStore);
    const appUserState = appUserStore?.get();
    const authState = authStore?.get();

    useEffect(() => {
        if (payoutManager == null) {
            setPayoutManager(new PayoutManager(appUserState.selectedSchoolYear, payoutStateStore, authStore, appUserStore));
        }
    }, [appUserState, authState, appUserState?.apiStatus, authState?.accessToken]);

    return payoutManager
}