const GeneralSurveyValidateObject = () => {
    return {
        Name: {
            fieldName: 'Name',
            messageTitle: 'title',
            isRequired: true,
            dataType: 'STRING',//IMAGE, INT, DEC, SELECT,BOOL,FILE
            groupId: 'GENERALSURVEY',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
        Description: {
            fieldName: 'Description',
            messageTitle: 'survey instructions',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'GENERALSURVEY',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
        SurveyTemplateId: {
            fieldName: 'SurveyTemplateId',
            messageTitle: 'survey template',
            isRequired: true,
            dataType: 'SELECT',
            groupId: 'GENERALSURVEY',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
        DueDate: {
            fieldName: 'StartDate',
            messageTitle: 'start date',
            isRequired: true,
            dataType: 'SELECT',
            groupId: 'GENERALSURVEY',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
    }
}

export default GeneralSurveyValidateObject;