import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _, { has } from 'lodash';

class CertificationQuestionManager extends ItemManager {
  constructor(globalItemState, globalAuthState, globalAppUserState) {


    super(ItemServiceFactory.ItemServiceEnum.CertificationQuestion, globalAuthState, globalAppUserState, globalItemState,
      (filter, item) => {
        var hasSearch = item.QuestionText?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
        var hasIsActive = item?.IsActive === (filter?.isActive === '1' ? true : false) || (filter?.isActive === undefined || filter?.isActive === null || filter?.isActive === 'UNK');
        var hasIsDefault = item?.IsDefault === (filter?.isDefault === '1' ? true : false) || (filter?.isDefault === undefined || filter?.isDefault === null || filter?.isDefault === 'UNK');
        return hasSearch && hasIsActive && hasIsDefault;
      });

    this._errorUtil = undefined;
    this._questionItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.CertificationQuestion, globalAuthState, globalAppUserState);
    this._answerItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.CertificationAnswer, globalAuthState, globalAppUserState);
    this._certificationAnswerSvc = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.CertificationAnswer, globalAuthState);

  }

  get QuestionItemManager() {
    return this._questionItemMgr;
  }

  get AnswerItemManager() {
    return this._answerItemMgr;
  }

  async GetAllAnswers(questionId) {
    return await this._certificationAnswerSvc.list(this.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false && x.CertificationQuestionId == questionId", { questionId }));
  }

  async GetActiveAnswerBank() {
    return await this._certificationAnswerSvc.list(this.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false", {}));
  }

  async getActiveQuestionBank(certType) {
    return this.search(this.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false && x.CertificationType == certType", { certType }), true)
  }

  async getAllQuestions(certType) {
    return this.search(this.buildJSONObjFilter("x => x.IsDeleted == false && x.CertificationType == certType", { certType }), true)
  }

  async DeleteAnswersForQuestion(CertificationQuestionId) {
    const allAnswers = [];
    this._certificationAnswerSvc.list().then((currAnswers) => {
      currAnswers?.first()?.Items.forEach((oldAnswers) => {
        if (oldAnswers.CertificationQuestionId === CertificationQuestionId) {
          allAnswers.push(this._certificationAnswerSvc.delete(oldAnswers.Id));
        }
      })
    });
    const result = await Promise.all(allAnswers)
    return result;
  }

  async SaveAnswers(CQId, newAnswers) {
    const allAnswers = [];

    if (newAnswers === null) {
      return;
    }


    for (const answer of newAnswers) {
      if (answer.Deleted === true && answer.Id !== null) {
        allAnswers.push(this._certificationAnswerSvc.delete(answer.Id));
      } else {
        const retItem = {
          Id: answer.Id,
          CertificationQuestionId: CQId,
          AnswerText: answer.AnswerText,
          FileId: answer.FileId,
          IsCorrect: answer.IsCorrect,
          MoveToBottom: answer.MoveToBottom
        }
        allAnswers.push(this._certificationAnswerSvc.save(retItem));
      }
    }

    const result = await Promise.all(allAnswers)
    return result;
  }

  get DefaultItem() {
    return {

    };
  }
}

export default CertificationQuestionManager;