import React from 'react';
import './../faq.scss';
import { Panel } from 'rsuite';

const FAQ = () => {

  return (
    <>
      <div className='faq'>
        <h1 className='faq-header'>
          Frequently Asked Questions
        </h1>
        <div className='faq-container'>
          <div className='faq-subheader'>
            <h3 className='subheader-title'>General</h3>
            <div>
              <Panel header="What is EE PASS?" collapsible bordered aria-expanded>
                The NIET Educator Effectiveness Preparation and Support System is a wonderful repository of resources that support building a teacher's capacity to execute best practices that will grow teachers and their students towards excellence. Your school administrator may have created your account. Please follow up with an administrator to advise you of next steps.
              </Panel>
              <Panel header="Who is NIET?" collapsible bordered aria-expanded>
                Knowing that teacher quality is the single biggest in-school factor for student success, the National Institute for Excellence in Teaching (NIET) builds educator excellence to give all students the opportunity for success.
                <br /><br />
                Our vision is that every student in America is taught by an excellent teacher and supported by effective leaders every year—and that has been the foundation of our work for the past two decades. We believe intentional, sustained and high-quality investments in educators directly result in success for all students and are essential to eliminating equity gaps. We have optimism about what every teacher and student is capable of, and we see them rise to new levels of success every day.
                <br /><br />
                As a result, NIET is committed to raising achievement levels for all students by focusing on the most powerful lever for change: teachers and the leadership that supports them.
              </Panel>
            </div>
          </div>

          <div className='faq-subheader'>
            <h3 className='subheader-title'>Service & Support</h3>
            <div>
              <Panel header="Can I access EE PASS from a mobile device?" collapsible bordered aria-expanded aria-selected="true">
                Yes, you can access EE PASS from a mobile device using an updated web browser application.
              </Panel>
              <Panel header="Who do I contact if I need technical support?" collapsible bordered aria-expanded>
                Should you have any questions related to the technology or the content within EE PASS, please feel free to contact our support team at support@niet.org or call us at 800-575-NIET. Our support team responds to user requests Monday through Friday, 8:30 am to 5:30 pm, central standard time. Holidays are exceptions.
              </Panel>
              <Panel header="How do I reset my password?" collapsible bordered aria-expanded>
                To reset your password, click the Forgot Password link and follow the prompts on screen to verify your email address and process a verification code. For assistance, contact support@niet.org.
              </Panel>
              <Panel header="What is the most compatible web browser?" collapsible bordered aria-expanded>
                The most compatible web browser is Google Chrome. Be sure the application is up to date and pop-ups are enabled.
              </Panel>
              <Panel header="How do I update my profile?" collapsible bordered aria-expanded>
              Once you are logged into EE PASS you can update your profile picture and information by click on Your Name > Profile. When you access your profile you can change the following: user profile picture, name, email, phone number, password, configure multi-factor authentication settings and update your content preferences.
              </Panel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQ;