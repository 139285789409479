import _ from "lodash";
import moment from "moment";
import react, { useState } from "react";
import { useEffect } from "react";
import { faClose as completeIcon } from '@fortawesome/free-solid-svg-icons'
import { TableControl } from "../../../../components/controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormatUTCDate } from "../../../../utilities/DateFormater";
import { useAppUserManager } from "../../../../hooks/useManagers";



const PermissionTable = ({ canWrite, filteredItems, onEdit, onDelete }) => {
    const [rows, setRows] = useState([]);

    const [columns] = useState([
        {
            header: 'Type',
            dataProp: "permissionTypeName",
            width: '10%',
            canSort: true
        },
        {
            header: 'Permission',
            dataProp: "Permission",
            width: '20%',
            canSort: true
        },
        {
            header: 'Display Name',
            dataProp: 'DisplayName',
            width: '27%',
            canSort: true
        },
        {
            header: 'Configuration',
            dataProp: 'allConfig',
            width: '20%',
            canSort: true
        },
        {
            header: 'Create Date',
            dataProp: 'CreatedDate',
            datatype: 'date',
            width: '15%',
            canSort: true
        }
    ]);

    const checkHideAction = (row, action) => {

        if (action === "delete") {
            return row.PermissionType !== 2
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (c) => {
                newRows.push({
                    ...c,
                    permissionType: c.PermissionType,
                    permissionTypeName: c.PermissionTypeName,
                    allConfig: `${c.CanView ? 'View, ' : ''}${c.CanWrite ? 'Write, ' : ''}${c.CanExecute ? 'Execute, ' : ''}${c.CanDelete ? 'Delete, ' : ''}${c.CanHardDelete ? 'Hard Delete, ' : ''}`.slice(0, -2),
                });
            });
            setRows(newRows);
        }
    }, [filteredItems]);

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                permission='Permission'
                useIntegratedPaging={['10', '25', '50', '100']}
                onEdit={(row) => onEdit(row)}
                onDelete={(row) => onDelete(row)}
                checkHideAction={checkHideAction}
            />
        </div>
    </>
}

export default PermissionTable;