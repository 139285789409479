import React from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { SelectListControl } from '../../../../components/controls';
import { CheckTreePicker } from 'rsuite';
import Loading from '../../../../components/Loading';

export default function SurveyTemplateFilter({ organizations, surveyTemplateMgr, onFilterChange }) {



    const onTreeClean = (id) => {
        handleOnChange(null, id);
    }

    const handleOnChange = (value, field) => {
        if (value === "true") {
            value = true
        } else if (value === 'false') {
            value = false
        }
        if (onFilterChange)
            onFilterChange(field, value);
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        surveyTemplateMgr.filterData({ search: null, surveyType: null, orgIds: [], isActive: 'UNK' });
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Survey Builder</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search'
                            value={surveyTemplateMgr?.Filter?.search ?? ''}
                            fieldName='search'
                            disableError={true}
                            placeholder='Title'
                            onChange={handleOnChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        {surveyTemplateMgr && <InputField id='surveyType'
                            title='Survey Type'
                            value={surveyTemplateMgr?.Filter?.surveyType ?? 'UNK'}
                            fieldName='surveyType'
                            disableError={true}
                            onChange={handleOnChange}>
                            <SelectListControl textValuePairs={surveyTemplateMgr.SurveyTypes ?? []} />
                        </InputField>}
                    </div>
                    <div className="filterControl">
                        {organizations ? <InputField
                            value={surveyTemplateMgr?.Filter?.orgIds ?? []}
                            title="Organization"
                            fieldName="orgIds"
                            disableError={true}
                            groupId={'SURVEYTEMPLATE'}
                        >
                            <CheckTreePicker
                                height={320}
                                width={400}
                                data={organizations ?? []}
                                valueKey={'OrgId'}
                                labelKey={'Name'}
                                onClean={() => onTreeClean("orgIds")}
                                onSelect={(a, val) => handleOnChange(val, 'orgIds')}
                                placement="autoVerticalStart"
                            />
                        </InputField> : <Loading />}
                    </div>
                    <div className="filterControl">
                        {surveyTemplateMgr && <InputField id='surveyType'
                            title='Active?'
                            value={surveyTemplateMgr?.Filter?.IsActive ?? 'UNK'}
                            fieldName='IsActive'
                            disableError={true}
                            onChange={handleOnChange}>
                            <SelectListControl textValuePairs={[{ Text: 'Yes', Value: true }, { Text: 'No', Value: false }]} />
                        </InputField>}
                    </div>

                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}