import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TableBody, Table, Switch, TableCell, TableContainer, TableHead, TableRow, FormControlLabel, Toolbar, IconButton, Paper, Typography, Checkbox, Tooltip } from '@mui/material';
import { TablePagination, TableSortLabel } from '@mui/material';
import { ContentTypeEnum } from '../../../services/ContentService';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ShareIcon from '@mui/icons-material/Share';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import TableControl from '../../../../../components/controls/TableControl/TableControl';
import { asyncForEach, stableSort, getComparator } from '../../../utils/jsUtil';
import { useAppUserManager, useContentManager } from '../../../../../hooks/useManagers';
import { FormatUTCDate } from '../../../../../utilities/DateFormater';

export default function ListDisplay({ authMgr, updateContent, onLike, onDownload, onView, onShare, onFullScreen }) {
    const [rows, setRows] = useState([]);
    const contentMgr = useContentManager();
    const filteredContent = contentMgr?.FilteredContent;

    const [columns] = useState([
        {
            header: '',
            dataProp: "thumbnail",
            datatype: 'image',
            width: '5%',
            canSort: false
        },
        {
            header: 'Title',
            dataProp: "title",
            width: '15%',
            canSort: true
        },
        {
            header: 'Content Type',
            dataProp: "contentType",
            width: '10%',
            canSort: true
        },
        {
            header: 'Description',
            dataProp: "description",
            width: '33%',
            canSort: true
        },
        {
            header: 'Run Time',
            dataProp: "runningTime",
            width: '7%',
            canSort: true
        },
        {
            header: 'Shared',
            dataProp: "shared",
            width: '10%',
            canSort: true
        },
        {
            header: 'Date Uploaded',
            dataProp: "createdOn",
            width: '10%',
            canSort: true
        }
    ])

    useEffect(() => {
        let newRows = [];
        if (filteredContent) {
            asyncForEach(filteredContent, (c) => {
                if (c.Title && c.Description) {
                    newRows.push({
                        contentId: c.ContentId,
                        keyId: c.KeyId,
                        FileId: c.FileId,
                        contentTypeId: c.ContentTypeId,
                        thumbnail: c.thumbUrl,
                        title: c.Title,
                        contentType: c.ContentTypeName,
                        description: c.Description,
                        runningTime: c.RunningTime,
                        rating: c.OverallRating,
                        hasUserRating: c.HasUserRating == undefined || c.HasUserRating == null ? false : c.HasUserRating,
                        mimeType: c.MimeType,
                        shared:  c.UserHasShared === undefined || c.UserHasShared === null ? 'No' : (c.UserHasShared ? 'Yes' : 'No'),//c.SharedWithUser === undefined || c.SharedWithUser === null ? 'No' : (c.SharedWithUser ? 'Yes' : 'No'),
                        createdOn: FormatUTCDate(c.CreatedOn),
                        metadata: c.Metadata,
                        contentTypeId: c.ContentTypeId
                    });
                }
            }).then(() => {
                setRows(newRows);
            });
        }
    }, [updateContent, filteredContent]);


    return <>
        <div className="contentDisplay contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                useIntegratedPaging={['10', '25', '50', '100']}
                onView={(row) => onView(row.keyId)}
                onCustom1={authMgr.canView("LikeContent") && authMgr.canExecute("ContentLibrary") && {
                    title: 'Like',
                    action: (row) => { return !row?.hasUserRating ? <div title="Like Content"> <ThumbUpAltOutlinedIcon className={'btn-icon'} onClick={(e) => onLike(row.keyId)} /> </div> : <ThumbUpIcon className={'btn-icon'} onClick={(e) => onLike(row.keyId)} /> }
                }}
                onCustom2={authMgr.canWrite("ContentShare") && authMgr.canExecute("ContentLibrary") && {
                    title: 'Share',
                    action: (row) => { return <div title="Share Content"> <ShareIcon className={'btn-icon'} onClick={(e) => onShare(row.keyId)} /> </div> }
                }}
                onCustom3={{
                    title: 'Full Screen',
                    action: (row) => { return row.contentTypeId == ContentTypeEnum.LearningModule ? <div title="Fullscreen Content"> <ScreenShareOutlinedIcon className={'btn-icon'} onClick={(e) => onFullScreen({ KeyId: row.keyId, FileId: row.FileId, Metadata: row.metadata, ContentTypeId: row.contentTypeId })} /> </div> : <div title="Fullscreen Unavailable"> <ScreenShareOutlinedIcon className={'btn-icon disabled'} /> </div>}
                }}
                onCustom4={authMgr.canView("DownloadContent") && authMgr.canExecute("ContentLibrary") && {
                    title: 'Download',
                    action: (row) => { return row.contentTypeId == ContentTypeEnum.Resource ? <div title="Download Content"> <CloudDownloadIcon className={'btn-icon'} onClick={(e) => onDownload(row.keyId)} /> </div>: <div title="Download Unavailable"> <CloudDownloadIcon className={'btn-icon disabled'} /> </div>}
                }}
            />
        </div>
    </>
}
