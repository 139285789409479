import React from 'react';

import clsx from 'clsx';

import { Button, Tooltip } from '@mui/material';


const SidebarHeader = ({ onSidebarToggle, sidebarToggle }) => {

  return (
    <>
      <div className="app-sidebar--header">
        <Button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic ',
            { 'is-active': sidebarToggle }
          )}
          onClick={onSidebarToggle}
          style={{ position: "absolute", right: "1rem" }}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Button>
      </div>
    </>
  );
};

export default SidebarHeader;
