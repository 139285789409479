import React, { useState } from 'react';
import '../table.scss';
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { asyncForEach } from "../../../contentLibrary/utils/jsUtil";
import CircularProgress from '@mui/material/CircularProgress';
import ButtonControl from '../../../../../src/components/controls/ButtonControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faNoteSticky, faPowerOff, faReceipt } from '@fortawesome/free-solid-svg-icons';
import userData from './userData.json'
import { useAppUserManager } from '../../../../hooks/useManagers';
import _ from 'lodash';

const UserTable = ({ filteredUsers, organizations, districts, schools, roles, onView, onEdit, onDisableEnable, onTransactions, onLogs }) => {
    const appUserMgr = useAppUserManager();
    const [rows, setRows] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [columns] = useState([
        {
            header: 'EE PASS #',
            dataProp: "eePassId",
            width: '8%',
            canSort: true
        },
        {
            header: 'Organization',
            dataProp: 'organization',
            width: '17%',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'District',
            dataProp: "district",
            width: '17%',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'School',
            dataProp: "school",
            width: '17%',
            leftAlign: true,
            canSort: true
        },

        {
            header: 'Information',
            dataProp: 'info',
            width: '17%',
            sortBy: 'LastName',
            canSort: true
        },
        {
            header: 'Job Title',
            dataProp: 'userPositionName',
            width: '11%',
            canSort: true
        },
        {
            header: 'Archived?',
            dataProp: 'IsArchived',
            width: '8%',
            isBool: true,
            centerItems: true,
            canSort: true,
            hideDataMissing: true
        },
        {
            header: 'Active?',
            dataProp: 'IsActive',
            width: '7%',
            isBool: true,
            centerItems: true,
            canSort: true
        },
        {
            header: 'Create Date',
            dataProp: 'CreatedDate',
            width: '10%',
            datatype: 'date',
            centerItems: true,
            canSort: true
        }
    ])

    useEffect(() => {
        let newRows = [];
        if (filteredUsers && roles) {
            _.each(filteredUsers, (u) => {
                newRows.push({
                    ...u,
                    organization: u.Organizations,
                    district: u.Districts,
                    school: u.Schools,
                    eePassId: u.SystemId,
                    info: <><div>{u.LastName}, {u.FirstName}<br />{u.Email}</div></>,
                    roles: getRoleData(u.RoleData)
                });
            });
            setRows(newRows);
            setLoading(false);
        }
    }, [filteredUsers, organizations, roles, districts, schools]);
    const getRoleData = (roleData) => {

        const data = roleData.split(",");
        const newLineData = data.join("\n");

        return newLineData;
    }

    const getOrgs = (userOrgs, orgDataSet) => {

        if (userOrgs?.length > 0) {
            let orgArr = [];
            userOrgs.forEach(o => {
                let match = orgDataSet.find(x => x.value === o.ClientId);
                if (match && !orgArr.includes(match.label)) {
                    orgArr.push(match.label);
                }
            })
            let formattedA = orgArr.join(", ");
            return formattedA;
        }
        else {
            return '';
        }
    }

    const getDistricts = (userDistricts, districtDataSet) => {

        if (userDistricts && userDistricts.length > 0) {
            let dArr = [];
            userDistricts.forEach(o => {
                let match = districtDataSet.find(x => x.value === o.DistrictId);
                if (match && !dArr.includes(match.label)) {
                    dArr.push(match.label);
                }
            })
            let formattedA = dArr.join(", ");
            return formattedA;
        }
        else {
            return '';
        }
    }

    const getSchools = (userSchools, schoolDataSet) => {

        if (userSchools && userSchools.length > 0) {
            let sArr = [];
            userSchools.forEach(o => {
                let match = schoolDataSet.find(x => x.value === o.SchoolId);
                if (match && !sArr.includes(match.label)) {
                    sArr.push(match.label);
                }
            })
            let formattedA = sArr.join(", ");
            return formattedA;
        }
        else {
            return '';
        }
    }

    const getFormatedDate = (dateInput) => {

        let date = new Date(dateInput);
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }

    const handleCheckHideAction = (row, field) => {
        if (field === 'custom1') {
            return !appUserMgr.canDelete(appUserMgr.GetGlobalState().permissions?.UserProfile);
        }
        else if (field === 'custom2') {
            return !appUserMgr.canExecute(appUserMgr.GetGlobalState().permissions?.Log);
        }
        else if (field === 'custom3') {
            return !appUserMgr.canView(appUserMgr.GetGlobalState().permissions?.Transaction);
        }
    }

    const handleCheckDisableAction = (row, field) => {
        if (field === 'edit') {
            return row.UserPositionType < appUserMgr?.GetGlobalState()?.userProfile?.UserPositionType;
        }
    }
    {/* onCustom3={{ action: (row) => <FontAwesomeIcon title='View Transaction' className={'btn-icon-fa'} icon={faReceipt} onClick={() => onTransactions(row)} /> }}*/ }
    return <>
        <div className="contentDisplay-list">
            {!loading && rows ?
                <TableControl
                    columns={columns}
                    data={rows}
                    useIntegratedPaging={['10', '25', '50', '100']}
                    onEdit={onEdit}
                    checkHideAction={handleCheckHideAction}
                    checkDisableAction={handleCheckDisableAction}
                    onCustom2={{ action: (row) => <FontAwesomeIcon title='View Logs' className={'btn-icon-fa'} icon={faNoteSticky} onClick={() => onLogs(row)} /> }}

                    useScrollCol={true}
                    userTable={true}
                />
                : <div className="table-loader"><CircularProgress color={'inherit'} size={50} /></div>
            }
        </div>
    </>
}

export default UserTable;