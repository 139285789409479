
import _ from 'lodash';
import moment from 'moment';
import RoleValidateObject from './Objects/RoleValidateObject';
import ContentValidateObject from './Objects/ContentValidateObject';
import TestValidateObject from './Objects/TestValidateObject';
import TagValidateObject from './Objects/TagValidateObject';
import PermissionValidateObject from './Objects/PermissionValidateObject';
import SchoolYearValidateObject from './Objects/SchoolYearValidateObject';
import ObservationValidateObject from './Objects/ObservationValidateObject';
import OrganizationValidateObject from './Objects/OrganizationValidateObject';
import SurveyTemplateValidateObject from './Objects/SurveyTemplateValidateObject';
import GeneralSurveyValidateObject from './Objects/GeneralSurveryValidateObject';
import NotificationValidateObject from './Objects/NotificationValidateObject';
import SurveyTemplateFieldValidateObject from './Objects/SurveyTemplateFieldValidateObject';
import UserValidateObject from './Objects/UserValidateObject';
import CertificationQuestionObject from './Objects/CertificationQuestionValidateObject';
import CertificationAnswerObject from './Objects/CertificationAnswerValidateObject';

export default class ValidationManager {

    constructor(forceValidation, groupIds) {
        this._forceValidation = forceValidation ?? false;
        this._defaultConfigObject = {
            isRequired: false
        }

        this._groupIds = typeof groupIds === 'string' ? [groupIds] : groupIds;
        this.rxDecimal = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
        this.rxEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        this.rxPhone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
        this.rxInt = /^[0-9]*$/
        this.rxCharOnly = /[A-Za-z0-9]+$/
        this.rxDateTime = /[0-9]{4}-[0-9]{2}-[0-9]{2}.[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]+)?([Zz]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?/
        this.rxStripHtml = /<\/?[\w\s]*>|<.+[\W]>/g

        this._configObject = {};
        const roleValidateObj = RoleValidateObject()
        for (let fieldName in roleValidateObj) {
            if (fieldName.toLocaleLowerCase() !== 'first') {
                this._configObject[`role_${fieldName}`.toLowerCase()] = roleValidateObj[fieldName];
            }
        }

        const contentValidateObj = ContentValidateObject()
        for (let fieldName in contentValidateObj) {
            if (fieldName.toLocaleLowerCase() !== 'first') {
                this._configObject[`content_${fieldName}`.toLowerCase()] = contentValidateObj[fieldName];
            }
        }

        const tagValidateObj = TagValidateObject()
        for (let fieldName in tagValidateObj) {
            if (fieldName.toLocaleLowerCase() !== 'first') {
                this._configObject[`tag_${fieldName}`.toLowerCase()] = tagValidateObj[fieldName];
            }
        }

        const permissionValidateObj = PermissionValidateObject()
        for (let fieldName in permissionValidateObj) {
            if (fieldName.toLocaleLowerCase() !== 'first') {
                this._configObject[`permission_${fieldName}`.toLowerCase()] = permissionValidateObj[fieldName];
            }
        }

        const schoolYearValidateObj = SchoolYearValidateObject()
        for (let fieldName in schoolYearValidateObj) {
            if (fieldName.toLocaleLowerCase() !== 'first') {
                this._configObject[`school_year_${fieldName}`.toLowerCase()] = schoolYearValidateObj[fieldName];
            }
        }

        const observationValidateObj = ObservationValidateObject()
        for (let fieldName in observationValidateObj) {
            if (fieldName.toLocaleLowerCase() !== 'first') {
                this._configObject[`observation_${fieldName}`.toLowerCase()] = observationValidateObj[fieldName];
            }
        }


        this.buildValidationObject(GeneralSurveyValidateObject(), "generalsurvey");
        const userValidateObj = UserValidateObject()
        for (let fieldName in userValidateObj) {
            if (fieldName.toLocaleLowerCase() !== 'first') {
                this._configObject[`user_${fieldName}`.toLowerCase()] = userValidateObj[fieldName];
            }
        }

        this.buildValidationObject(OrganizationValidateObject(), "organization");
        this.buildValidationObject(SurveyTemplateValidateObject(), "surveytemplate");
        this.buildValidationObject(SurveyTemplateFieldValidateObject(), "surveytemplate_field");
        this.buildValidationObject(TestValidateObject(), "test");
        this.buildValidationObject(NotificationValidateObject(), "notification");
        this.buildValidationObject(CertificationQuestionObject(), "certificationquestion");
        this.buildValidationObject(CertificationAnswerObject(), "certificationanswer");
    }


    buildValidationObject(validateObj, groupName) {
        for (let fieldName in validateObj) {
            if (fieldName.toLocaleLowerCase() !== 'first') {
                this._configObject[`${groupName}_${fieldName}`.toLowerCase()] = validateObj[fieldName];
            }
        }
    }

    setForceValidate(forceValidate) {
        this._forceValidation = forceValidate;
    }

    getConfig(validationName) {
        return this._configObject[validationName] ?? this._defaultConfigObject;
    }

    runFieldMask(value, validationName, maxLength) {
        let maskPassed = true;
        const { dataType } = this.getConfig(validationName);
        if (maxLength !== undefined && value !== undefined && !dataType && value.toString().replace('.', '').replace(',', '').length > maxLength) {
            maskPassed = false;
        }
        else if (maxLength !== undefined && value !== undefined && ((dataType?.toUpperCase() !== "DEC" && value.length > maxLength) || (dataType?.toUpperCase() === "DEC" && value.toString().replace('.', '').replace(',', '').length > maxLength))) {
            maskPassed = false;
        }
        else if (value && dataType?.toUpperCase() === "DEC") {
            maskPassed = value.toString().match(this.rxDecimal);
        }
        else if (value && dataType?.toUpperCase() === "INT") {
            maskPassed = value.toString().match(this.rxInt);
        }
        else if (value && dataType?.toUpperCase() === "CHARONLY") {
            maskPassed = value.toString().match(this.rxCharOnly);
        }

        return maskPassed;
    }

    checkIsGroupValid(data) {
        let errors = [];

        for (const validationName in this.config) {
            if ((typeof this._groupIds === 'string' && this._groupIds.toLowerCase() === this.config[validationName?.toLowerCase()]?.groupId?.toLowerCase()) || this._groupIds?.includes(this.config[validationName?.toLowerCase()]?.groupId)) {
                const validationResult = this.runValidation(data[this.config[validationName.toLowerCase()].fieldName], validationName?.toLowerCase(), data, true);
                errors = [...errors, ...validationResult.messages];
            }
        }

        return errors;
    }

    runValidation(value, validationName, data, overrideForceValidation) {
        let { isRequired, dataType, regEx, message, customValidator, messageTitle, requiredDependency, groupId } = this.getConfig(validationName?.toLowerCase());
        let messages = [];
        const doValidation = (value !== undefined && value !== null) || this._forceValidation === true || overrideForceValidation === true; // value==="" means the user started typing.. this is undfined or null prior to this point
        if (doValidation && (typeof this._groupIds === 'string' && this._groupIds.toLowerCase() === this.config[validationName?.toLowerCase()]?.groupId?.toLowerCase()) || this._groupIds?.includes(this.config[validationName?.toLowerCase()]?.groupId)) {

            const required = (isRequired && !requiredDependency) || (isRequired && requiredDependency && data && data[requiredDependency]);
            if (
                (required && dataType === 'HTML' && (value === undefined || value === null || value === '' || value.replace(this.rxStripHtml, '').length === 0))
                ||
                (required && (dataType === 'DATETIME' || dataType === 'DATE') && (value === undefined || value === null || value.toLowerCase() === 'invalid date'))
                ||
                (required && value?.toString() === "" || (dataType === 'ARRAY' && value?.length === 0))
                ||
                (required && (value == undefined || value == null || (dataType === 'ARRAY' && value.length === 0)))
                ||
                (required && (dataType === 'SELECT' || dataType === 'SELECT-AN') && (value === '' || value === 'UNK' || value == undefined || value == null))
            ) {
                if (dataType === "EMAIL") {
                    messages.push(`Please enter a valid email address.`);
                }
                else if (dataType === "PHONE") {
                    messages.push(`Please enter a valid phone number for ${messageTitle}.`);
                }
                else if (dataType === "IMAGE" || dataType === "FILE") {
                    messages.push(`Please upload a ${messageTitle}.`);
                }
                else if (dataType === "SELECT") {
                    messages.push(`Please select a ${messageTitle}.`);
                }
                else if (dataType === "SELECT-AN") {
                    messages.push(`Please select an ${messageTitle}.`);
                }
                else if (dataType === "STRING-NA") {
                    messages.push(`Please enter ${messageTitle}.`);
                }
                else if (dataType === "INT" || dataType === "DEC" || dataType == "ARRAY") {
                    messages.push(`Please enter ${messageTitle}.`);
                }
                else if (dataType === "USER") {
                    messages.push(`Please enter an ${messageTitle}.`);
                }
                else
                    messages.push(`Please enter a ${messageTitle}.`);
            }
            else if (value !== undefined) {
                if (regEx !== undefined) {//Custom RegEx
                    if (!value?.match(regEx)) {
                        messages.push(message ?? "");
                    }
                }

                if (dataType?.toUpperCase() === "DEC") {//Decmal
                    if (!value?.toString()?.match(this.rxDecimal)) {
                        messages.push(`Please enter a valid decimal for ${messageTitle}.`);
                    }
                }
                else if (dataType?.toUpperCase() === "INT") {//int
                    if (!value?.toString()?.match(this.rxInt)) {
                        messages.push(`Please enter a valid whole number for ${messageTitle}.`);
                    }
                }
                else if (dataType?.toUpperCase() === "EMAIL") {//Email
                    if (!value?.match(this.rxEmail)) {
                        messages.push(`Please enter a valid email address.`);
                    }
                }
                else if (dataType?.toUpperCase() === "PHONE") {//Email
                    if (value !== '' && !value?.match(this.rxPhone)) {
                        messages.push(`Please enter a valid phone number.`);
                    }
                }
                /*  else if ((dataType?.toUpperCase() === "DATETIME" || dataType?.toUpperCase() === "DATE") && value) { // Date and Time
                      console.log(validationName, value);
                      if (moment(value).toString() === 'Invalid date') {
                          messages.push(`Please enter a valid date.`);
                      }
                  }*/
            }

            if (customValidator) {
                const message = customValidator(value, data);
                if (message) {
                    messages.push(message);
                }
            } //else no data or custom validation
        }

        return {
            isValid: messages.length === 0,
            messages,
            validationName
        };
    }

    get config() {
        return this._configObject;
    }

}