import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { useAppUserManager } from "../../../hooks/useManagers";
import MainLayout from "../../../components/layout/MainLayout";
import ScreenWrapper from "../../../components/layout/screenWrapper";

import ReportMgmt from "./ReportMgmt";

import "../reportsMgmt.scss";

const ReportsMgmt = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const appUserMgr = useAppUserManager();

  useEffect(() => {
    const screen = window.document.getElementsByClassName("screen");
    if (screen.length > 0) {
      screen[0].scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  const allReports = () => {
    if ("/reports" === location.pathname.toLowerCase()) {
      return (
        <div className="categoryWrapper reports-title">
          <h1>Reports</h1>
        </div>
      );
    }
    return null;
  };

  const teacherLevelReports = () => {
    if (
      ["/reports", "/reports/teacher"].includes(
        location.pathname.toLowerCase()
      )
    ) {
      return (
        <div className="contentDisplay">
          <div className='maintenance-banner'>
            NIET Reports are undergoing maintenance and will be updated on our platform system shortly. 
            We apologize for the inconvenience, and we thank you for your patience. 
            We will remove this message when development on NIET Reports is complete.
          </div>
          <div className="categoryWrapper">
            <h2>Teacher Level Reports</h2>
          </div>
          {(appUserMgr?.AccessCheck([
            "ReportsTeacherOverallAverageRubricIndicator",
          ]) ||
            appUserMgr?.AccessCheck(["ReportsTeacherOverallAverageObserver"]) ||
            appUserMgr?.AccessCheck(["ReportsTeacherOverallAverageEducator"]) ||
            appUserMgr?.AccessCheck([
              "ReportsTeacherOverallAverageSubject",
            ])) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() => navigate("/report/teacher-overall-average", {state: "VISITED"})}
                >
                  Teacher Overall Average
                </h3>
                <div>
                  {appUserMgr?.AccessCheck([
                    "ReportsTeacherOverallAverageRubricIndicator",
                  ]) && (
                      <div className="report-type">
                        <h4>By Rubric Indicator:</h4>
                        <h5>
                          Up-to-date averages of total school Observations by rubric
                          indicators to target areas of strengths and weaknesses of
                          whole-school instruction. To maximize the effectiveness of
                          this report, use it in conjunction with the "Overall Avg
                          by Subject" and "Overall Avg by Grade" reports to target
                          specific areas of strength or needs for improvement.
                          Afterwards, review the "Teachers Averages by Rubric
                          Domain" and "Overall Averages by Teacher" reports.
                        </h5>
                      </div>
                    )}
                  {appUserMgr?.AccessCheck([
                    "ReportsTeacherOverallAverageObserver",
                  ]) && (
                      <div className="report-type">
                        <h4>By Observer:</h4>
                        <h5>
                          Compares the overall observation averages among the
                          observers for the year.
                        </h5>
                      </div>
                    )}
                  {appUserMgr?.AccessCheck([
                    "ReportsTeacherOverallAverageEducator",
                  ]) && (
                      <div className="report-type">
                        <h4>By Educator:</h4>
                        <h5>
                          Provides quick view of low, proficient, or high performing
                          teachers. To maximize the effectiveness of this report,
                          use it in conjunction with the "Educator Averages by
                          Domain" and "Historical Average by Educator" reports.
                        </h5>
                      </div>
                    )}
                  {appUserMgr?.AccessCheck([
                    "ReportsTeacherOverallAverageSubject",
                  ]) && (
                      <div className="report-type">
                        <h4>By Subject:</h4>
                        <h5>
                          Quick view of overall average by subject to target
                          low/high performing area.
                        </h5>
                      </div>
                    )}
                </div>
              </div>
            )}
          {(appUserMgr?.AccessCheck([
            "ReportsTeacherRefinementAndReinforcementGoalsRefinement",
          ]) ||
            appUserMgr?.AccessCheck([
              "ReportsTeacherRefinementAndReinforcementGoalsReinforcement",
            ])) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate("/report/teacher-refinement-or-reinforcement-goals", {state: "VISITED"})
                  }
                >
                  Teacher Refinement and Reinforcement Goals
                </h3>
                <h4 className="report-description">
                  Quick view of refinement and reinforcement areas for total of
                  school Observations.
                </h4>
              </div>
            )}
          {appUserMgr?.AccessCheck(["ReportsHistoricalAveragesByEducator"]) && (
            <div className="categoryWrapper">
              <h3
                className="categoryTitle report report-title"
                onClick={() =>
                  navigate("/report/historical-averages-by-educator", {state: "VISITED"})
                }
              >
                Historical Averages by Educator
              </h3>
              <h4 className="report-description">
                Compares overall average of each teacher's score by Observation
                by date to other teachers for trending and improvement. To
                maximize the effectiveness of this report, use it in conjunction
                with the "Educator Averages by Domain" and "Overall Averages by
                Educator" reports.
              </h4>
            </div>
          )}
          {appUserMgr?.AccessCheck(["ReportsAverageObserverVsSelfScore"]) && (
            <div className="categoryWrapper">
              <h3
                className="categoryTitle report report-title"
                onClick={() =>
                  navigate("/report/average-observer-vs-self-score", {state: "VISITED"})
                }
              >
                Average Observer vs Self Score
              </h3>
              <h4 className="report-description">
                Comparison of overall Observer score versus overall
                self-Observation score to target trends of each by indicator.
              </h4>
            </div>
          )}
          {appUserMgr?.AccessCheck([
            "ReportsEducatorAverageByRubricDomain",
          ]) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate("/report/educator-average-by-rubric-domain", {state: "VISITED"})
                  }
                >
                  Educator Average by Rubric Domain
                </h3>
                <h4 className="report-description">
                  Total Observations completed on each teacher with average score
                  by rubric indicator/domain to target teacher's instructional
                  strengths and "needs for improvement" areas. This information
                  can be used in guiding a teacher's Individual Growth plan. To
                  maximize the effectiveness of this report, use it in conjunction
                  with the "Overall Average by Educator" report.
                </h4>
              </div>
            )}
          {appUserMgr?.AccessCheck([
            "ReportsObserverAverageByRubricDomain",
          ]) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate("/report/observer-average-by-rubric-domain", {state: "VISITED"})
                  }
                >
                  Observer Average by Rubric Domain
                </h3>
                <h4 className="report-description">
                  Provides Observer's average score of total number of
                  Observations by rubric indicator/domain to target inter-rater
                  reliability.
                </h4>
              </div>
            )}
          {appUserMgr?.AccessCheck([
            "ReportsTeacherGradeLevelAveragesByRubricDomain",
          ]) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate(
                      "/report/teacher-grade-level-average-by-rubric-domain", {state: "VISITED"}
                    )
                  }
                >
                  Teacher Grade Level Averages by Rubric Domain
                </h3>
                <h4 className="report-description">
                  Total number of Observations by grade with average score per
                  rubric indicator/domain to target instructional areas of
                  strength and "needs for improvement". This report is useful for
                  targeting instructional needs by indicators for areas in testing
                  grades prior to testing.
                </h4>
              </div>
            )}
          {appUserMgr?.AccessCheck(["ReportsTeacherSurveyReport"]) && (
            <div className="categoryWrapper">
              <h3
                className="categoryTitle report report-title"
                onClick={() => navigate("/report/teacher-survey-report", {state: "VISITED"})}
              >
                Teacher Survey Report
              </h3>
              <h4 className="report-description">
                This report summarizes and compares survey data and calculations
                using mean and median benchmarks.
              </h4>
            </div>
          )}
          {appUserMgr?.AccessCheck(["ReportsTeacherIndividualSummary"]) && (
            <div className="categoryWrapper">
              <h3
                className="categoryTitle report report-title"
                onClick={() => navigate("/report/teacher-individual-summary", {state: "VISITED"})}
              >
                Teacher Individual Summary
              </h3>
              <h4 className="report-description">
                This report displays the educator's: (1) overall average by Domain for each of the completed observations executed on them, (2) overall average of surveys that were filled out on them by their peers/leadership team members, (3) a compilation of all "comments" filled out on them from completed surveys, (4) the weights of the Rubric Domains and Evaluators being applied to them.
              </h4>
            </div>
          )}
          {appUserMgr?.AccessCheck(["ReportsTeacherSKRSummaryRounding"]) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() => navigate("/report/teacher-skr-summary-rounding", {state: "VISITED"})}
                >
                  Teacher SKR Summary
                </h3>
                <div>
                  <h4 className="report-description">
                    This report calculates each teacher's Skills, Knowledge, and
                    Responsibilities year-end score (SKR Score = overall
                    effectiveness measure). These scores are a result of different
                    metrics being calculated together. If metrics are missing, the
                    report will not display a SKR score.
                  </h4>
                </div>
              </div>
          )}
          {/* {appUserMgr?.AccessCheck(["ReportsCompassEquivalent"]) && (
            <div className="categoryWrapper">
              <h3
                className="categoryTitle report report-title"
                onClick={() => navigate("/report/compass-equivalent", {state: "VISITED"})}
              >
                Compass Equivalent
              </h3>
            </div>
          )} */}
          {appUserMgr?.AccessCheck([
            "ReportsObservationRefinementReinforcementDescriptors",
          ]) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate(
                      "/report/observation-refinement-or-reinforcement-descriptors", {state: "VISITED"}
                    )
                  }
                >
                  Observation Refinement & Reinforcement Descriptors
                </h3>
                <h4 className="report-description">
                  Provides a view of the Refinement and Reinforcement Descriptors.
                </h4>
              </div>
            )}

            {/* 12514 - Not Showing this Report Yet */}
               {/* {appUserMgr?.AccessCheck(["ReportsLeadershipLogCategories"]) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate("/report/leadership-log-categories", {state: "VISITED"})
                  }
                >
                  Leadership Log Categories
                </h3>
                <div>
                    <h4 className="report-description">
                    This report summarizes the percentage of time that the leadership team has devoted to each of the categories: School Plan, Cluster Goals, Evaluation, Inter-Rater Reliability, Data, and/or IGP during their weekly Leadership Team Meetings.
                    </h4>
                </div>
              </div>
            )} */}
        </div>
      );
    }
    return null;
  };

  const schoolAdminLevelReports = () => {
    if (
      ["/reports", "/reports/school-administrator"].includes(
        location.pathname.toLowerCase()
      )
    ) {
      return (
        <div className="contentDisplay">
          <div className='maintenance-banner'>
            NIET Reports are undergoing maintenance and will be updated on our platform system shortly. 
            We apologize for the inconvenience, and we thank you for your patience. 
            We will remove this message when development on NIET Reports is complete.
          </div>
          <div className="categoryWrapper">
            <h2>School Administrator Level Reports</h2>
          </div>
          {appUserMgr?.AccessCheck(["ReportsObservationSummaryByEducator"]) && (
            <div className="categoryWrapper">
              <h3
                className="categoryTitle report report-title"
                onClick={() =>
                  navigate("/report/observation-summary-by-educator", {state: "VISITED"})
                }
              >
                Observation Summary by Educator
              </h3>
              <h4 className="report-description">
                This report summarizes and allows comparison of an individual
                teacher's observations. It also provides a means to compare
                observer inter-rater reliability by virtue of the Overall
                Average Score column.
              </h4>
            </div>
          )}
          {appUserMgr?.AccessCheck(["ReportsTeacherObservationStatus"]) && (
            <div className="categoryWrapper">
              <h3
                className="categoryTitle report report-title"
                onClick={() => navigate("/report/observation-status", {state: "VISITED"})}
              >
                Teacher Observation Status
              </h3>
              <h4 className="report-description">
              Provides an overview of the current observation status of each teacher's observation in the system.
              </h4>
            </div>
          )}
          {appUserMgr?.AccessCheck(["ReportsTeacherIndividualSummaryWithOverallAvg"]) && (
            <div className="categoryWrapper">
              <h3
                className="categoryTitle report report-title"
                onClick={() => navigate("/report/teacher-individual-summary-with-overall-avg", {state: "VISITED"})}
              >
                Teacher Individual Summary - With Overall Average
              </h3>
              <h4 className="report-description">
              This report displays the educator's: (1) overall average by Domain for each of the completed observations executed on them, (2) overall average of surveys that were filled out on them by their peers/leadership team members, (3) a compilation of all "comments" filled out on them from completed surveys, (4) the weights of the Rubric Domains and Evaluators being applied to them.
              </h4>
            </div>
          )}
          {(appUserMgr?.AccessCheck(["ReportsTeacherSKRSummaryRounding"]) ||
            appUserMgr?.AccessCheck([
              "ReportsTeacherSKRSummaryNoRounding",
            ])) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() => navigate("/report/teacher-skr-summary", {state: "VISITED"})}
                >
                  Teacher SKR Summary
                </h3>
                <div>
                  <h4 className="report-description">
                    This report calculates each teacher's Skills, Knowledge, and
                    Responsibilities year-end score (SKR Score = overall
                    effectiveness measure). These scores are a result of different
                    metrics being calculated together. If metrics are missing, the
                    report will not display a SKR score.
                  </h4>
                  {appUserMgr?.AccessCheck([
                    "ReportsTeacherSKRSummaryRounding",
                  ]) && (
                      <div className="report-type">
                        <h4>Rounding:</h4>
                        <h5>
                          Scores are rounded to the official half point scoring
                          system.
                        </h5>
                      </div>
                    )}
                  {appUserMgr?.AccessCheck([
                    "ReportsTeacherSKRSummaryNoRounding",
                  ]) && (
                      <div className="report-type">
                        <h4>No Rounding:</h4>
                        <h5>
                          Scores are prior to being rounded to the official half
                          point scoring system.
                        </h5>
                      </div>
                    )}
                </div>
              </div>
            )}

          {(appUserMgr?.AccessCheck([
            "ReportsTeacherEvaluatorCertificationRecordsExport",
          ]) ||
            appUserMgr?.AccessCheck([
              "ReportsSchoolAdministratorEvaluatorCertificationRecordsExport",
            ])) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate("/report/evaluator-certification-records-export", {state: "VISITED"})
                  }
                >
                  Evaluator Certification Records Export
                </h3>
                <div>
                  {appUserMgr?.AccessCheck([
                    "ReportsTeacherEvaluatorCertificationRecordsExport",
                  ]) && (
                      <div className="report-type">
                        <h4>Teacher:</h4>
                        <h5>
                          Provides the Certification step status with date and time
                          stamp for each Teacher Evaluator.
                        </h5>
                      </div>
                    )}
                  {appUserMgr?.AccessCheck([
                    "ReportsSchoolAdministratorEvaluatorCertificationRecordsExport",
                  ]) && (
                      <div className="report-type">
                        <h4>School Administrator:</h4>
                        <h5>
                          Provides the School Administrator step status with date
                          and time stamp for each School Administrator Evaluator.
                        </h5>
                      </div>
                    )}
                </div>
              </div>
            )}
           {appUserMgr?.AccessCheck(["ReportsTeacherEvaluatorCertificationStatus"]) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate("/report/teacher-evaluator-certification-status", {state: "VISITED"})
                  }
                >
                  Teacher Evaluator Certification Status
                </h3>
                <div>
                  
                        <h4 className="report-description">
                          Provides an overview of the certification status of the
                          Teacher Evaluators.
                        </h4>
                  
                </div>
              </div>
            )}
               {appUserMgr?.AccessCheck(["ReportsSchoolAdministratorEvaluatorCertificationStatus"]) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate(
                      "/report/school-administrator-evaluator-certification-status", {state: "VISITED"}
                    )
                  }
                >
                  School Administrator Evaluator Certification Status
                </h3>
                <div>
                  {appUserMgr?.AccessCheck([
                    "ReportsSchoolAdministratorEvaluatorCertificationStatus",
                  ]) && (
                        <h4 className="report-description">
                          Provides an overview of the certification status of the
                          School Administrator Evaluators.
                        </h4>
                    )}
                </div>
              </div>
            )}
          {(appUserMgr?.AccessCheck([
            "ReportsTeacherEvaluatorCertificationStatistics",
          ]) ||
            appUserMgr?.AccessCheck([
              "ReportsSchoolAdministratorEvaluatorCertificationStatistics",
            ])) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate("/report/evaluator-certification-statistics", {state: "VISITED"})
                  }
                >
                  Evaluator Certification Statistics
                </h3>
                <div>
                  {appUserMgr?.AccessCheck([
                    "ReportsTeacherEvaluatorCertificationStatistics",
                  ]) && (
                      <div className="report-type">
                        <h4>Teacher:</h4>
                        <h5>
                          Provides the number of Teacher Evaluator certification
                          attempts and their passage rates.
                        </h5>
                      </div>
                    )}
                  {appUserMgr?.AccessCheck([
                    "ReportsSchoolAdministratorEvaluatorCertificationStatistics",
                  ]) && (
                      <div className="report-type">
                        <h4>School Administrator:</h4>
                        <h5>
                          Provides the number of School Administrator Evaluator
                          certification attempts and their passage rates.
                        </h5>
                      </div>
                    )}
                </div>
              </div>
            )}
          {appUserMgr?.AccessCheck(["ReportsUserExport"]) && (
            <div className="categoryWrapper">
              <h3
                className="categoryTitle report report-title"
                onClick={() => navigate("/report/user-export", {state: "VISITED"})}
              >
                User Export
              </h3>
              <h4 className="report-description">
                This export provides an overview of all users and their
                respective status within EE PASS.
              </h4>
            </div>
          )}
          {appUserMgr?.AccessCheck([
            "ReportsSchoolAdministratorIndividualSummary",
          ]) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate("/report/school-administrator-individual-summary", {state: "VISITED"})
                  }
                >
                  School Administrator Individual Summary
                </h3>
                <h4 className="report-description">
                This report displays the Principal's: (1) overall average by Domain for each of the completed observations executed on them, (2) overall average of surveys that were filled out on them by their peers/leadership team members, (3) a compilation of all "comments" filled out on them from completed surveys, (4) the weights of the Rubric Domains and Evaluators being applied to them.
                </h4>
              </div>
            )}
          {(appUserMgr?.AccessCheck([
            "ReportsSchoolAdministratorEndOfYearRatingRounding",
          ]) ||
            appUserMgr?.AccessCheck([
              "ReportsSchoolAdministratorEndOfYearRatingNoRounding",
            ])) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate("/report/school-administrator-end-of-year-rating", {state: "VISITED"})
                  }
                >
                  School Administrator End of Year Rating
                </h3>
                <div>
                  <h4 className="report-description">
                    This report calculates each Principal's Skills, Knowledge, and
                    Responsibilities year-end score.
                  </h4>
                  {appUserMgr?.AccessCheck([
                    "ReportsSchoolAdministratorEndOfYearRatingRounding",
                  ]) && (
                      <div className="report-type">
                        <h4>Rounding:</h4>
                        <h5>
                          Scores are rounded to the official half point scoring
                          system.
                        </h5>
                      </div>
                    )}
                  {appUserMgr?.AccessCheck([
                    "ReportsSchoolAdministratorEndOfYearRatingNoRounding",
                  ]) && (
                      <div className="report-type">
                        <h4>No Rounding:</h4>
                        <h5>
                          Scores are prior to being rounded to the official half
                          point scoring system.
                        </h5>
                      </div>
                    )}
                </div>
              </div>
            )}
          {appUserMgr?.AccessCheck([
            "ReportsSchoolAdministratorObservationSummary",
          ]) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate("/report/school-administrator-observation-summary", {state: "VISITED"})
                  }
                >
                  School Administrator Observation Summary
                </h3>
                <h4 className="report-description">
                  This report summarizes and allows comparison of an individual
                  Principal's observations.
                </h4>
              </div>
            )}
          {appUserMgr?.AccessCheck([
            "ReportsOverallAverageBySchoolAdministrator",
          ]) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate("/report/overall-average-by-school-administrator", {state: "VISITED"})
                  }
                >
                  Overall Average by School Administrator
                </h3>
                <h4 className="report-description">
                  Provides quick view of Principal's Observation averages.
                </h4>
              </div>
            )}
          {(appUserMgr?.AccessCheck([
            "ReportsSchoolAdministratorRefinementAndReinforcementGoalsRefinement",
          ]) ||
            appUserMgr?.AccessCheck([
              "ReportsSchoolAdministratorRefinementAndReinforcementGoalsReinforcement",
            ])) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate(
                      "/report/school-administrator-refinement-and-reinforcement-goals", {state: "VISITED"}
                    )
                  }
                >
                  School Administrator Refinement and Reinforcement Goals
                </h3>
                <h4 className="report-description">
                  Quick view of refinement and reinforcement areas for Principal
                  Observations.
                </h4>
              </div>
            )}
          {appUserMgr?.AccessCheck([
            "ReportsSchoolAdministratorSurveyReport",
          ]) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate("/report/school-administrator-survey-report", {state: "VISITED"})
                  }
                >
                  School Administrator Survey Report
                </h3>
                <h4 className="report-description">
                  This report summarizes and compares Principal survey data and
                  calculations using mean and median scores.
                </h4>
              </div>
            )}
          {appUserMgr?.AccessCheck([
            "ReportsSchoolAdministratorAverageByRubricDomain",
          ]) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate(
                      "/report/school-administrator-average-by-rubric-domain", {state: "VISITED"}
                    )
                  }
                >
                  School Administrator Average by Rubric Domain
                </h3>
                <h4 className="report-description">
                  Total Observations completed on each Principal with average
                  score by rubric indicator/domain to target Principal's
                  instructional strengths and "needs for improvement" areas.
                </h4>
              </div>
            )}
        </div>
      );
    }
    return null;
  };

  const nietLevelReports = () => {
    if (
      ["/reports", "/reports/niet"].includes(
        location.pathname.toLowerCase()
      )
    ) {
      return (
        <div className="contentDisplay">
          <div className="categoryWrapper">
            <h2>NIET Level Reports</h2>
          </div>
          {(appUserMgr?.AccessCheck([
            "ReportsTeacherEvaluatorCertificationStatus",
          ]) ||
            appUserMgr?.AccessCheck([
              "ReportsTeacherEvaluatorCertificationResets",
            ]) ||
            appUserMgr?.AccessCheck([
              "ReportsTeacherEvaluatorCertificationFailures",
            ]) ||
            appUserMgr?.AccessCheck([
              "ReportsTeacherEvaluatorCertificationResults",
            ])) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate("/report/teacher-evaluator-certification", {state: "VISITED"})
                  }
                >
                  Teacher Evaluator Certification
                </h3>
                <div>
                  {appUserMgr?.AccessCheck([
                    "ReportsTeacherEvaluatorCertificationStatus",
                  ]) && (
                      <div className="report-type">
                        <h4>By Status:</h4>
                        <h5>
                          Provides an overview of the certification status of the
                          Teacher Evaluators.
                        </h5>
                      </div>
                    )}
                  {appUserMgr?.AccessCheck([
                    "ReportsTeacherEvaluatorCertificationResets",
                  ]) && (
                      <div className="report-type">
                        <h4>By Resets:</h4>
                        <h5>
                          This report reviews individual users' experiences with the
                          certification process. Status such as "Interaction",
                          "Pass", "Fail", "Reset", and "Manually Passed" will
                          display with a date and time stamp as a user engages with
                          those options.
                        </h5>
                      </div>
                    )}
                  {appUserMgr?.AccessCheck([
                    "ReportsTeacherEvaluatorCertificationFailures",
                  ]) && (
                      <div className="report-type">
                        <h4>By Failures:</h4>
                        <h5>
                          Provides the correct answer and user's answer for each
                          Teacher Evaluator who failed the certification test.
                        </h5>
                      </div>
                    )}
                  {appUserMgr?.AccessCheck([
                    "ReportsTeacherEvaluatorCertificationResults",
                  ]) && (
                      <div className="report-type">
                        <h4>By Results:</h4>
                        <h5>
                          Provides all user's answers for the Teacher Evaluator
                          Certification test including correct answers.
                        </h5>
                      </div>
                    )}
                </div>
              </div>
            )}
          {(appUserMgr?.AccessCheck([
            "ReportsSchoolAdministratorEvaluatorCertificationStatus",
          ]) ||
            appUserMgr?.AccessCheck([
              "ReportsSchoolAdministratorEvaluatorCertificationResets",
            ]) ||
            appUserMgr?.AccessCheck([
              "ReportsSchoolAdministratorEvaluatorCertificationFailures",
            ]) ||
            appUserMgr?.AccessCheck([
              "ReportsSchoolAdministratorEvaluatorCertificationResults",
            ])) && (
              <div className="categoryWrapper">
                <h3
                  className="categoryTitle report report-title"
                  onClick={() =>
                    navigate(
                      "/report/school-administrator-evaluator-certification", {state: "VISITED"}
                    )
                  }
                >
                  School Administrator Evaluator Certification
                </h3>
                <div>
                  {appUserMgr?.AccessCheck([
                    "ReportsSchoolAdministratorEvaluatorCertificationStatus",
                  ]) && (
                      <div className="report-type">
                        <h4>By Status:</h4>
                        <h5>
                          Provides an overview of the certification status of the
                          School Administrator Evaluators.
                        </h5>
                      </div>
                    )}
                  {appUserMgr?.AccessCheck([
                    "ReportsSchoolAdministratorEvaluatorCertificationResets",
                  ]) && (
                      <div className="report-type">
                        <h4>By Resets:</h4>
                        <h5>
                          This report reviews individual users' experiences with the
                          certification process. Status such as "Interaction",
                          "Pass", "Fail", "Reset", and "Manually Passed" will
                          display with a date and time stamp as a user engages with
                          those options.
                        </h5>
                      </div>
                    )}
                  {appUserMgr?.AccessCheck([
                    "ReportsSchoolAdministratorEvaluatorCertificationFailures",
                  ]) && (
                      <div className="report-type">
                        <h4>By Failures:</h4>
                        <h5>
                          Provides the correct answer and user's answer for each
                          School Administrator Evaluator who failed the
                          certification test.
                        </h5>
                      </div>
                    )}
                  {appUserMgr?.AccessCheck([
                    "ReportsSchoolAdministratorEvaluatorCertificationResults",
                  ]) && (
                      <div className="report-type">
                        <h4>By Results:</h4>
                        <h5>
                          Provides all user's answers for the School Administrator
                          Evaluator Certification test including correct answers and
                          pass/fail.
                        </h5>
                      </div>
                    )}
                </div>
              </div>
            )}
          {appUserMgr?.AccessCheck(["ReportsSchoolAuthorization"]) && (
            <div className="categoryWrapper">
              <h3
                className="categoryTitle report report-title"
                onClick={() => navigate("/report/school-authorization", {state: "VISITED"})}
              >
                School Authorization
              </h3>
              <h4 className="report-description">
                This report will provide information on whether individual
                schools are "Authorized" (access to EE PASS) or "Not Authorized"
                (no access to EE PASS). It will also list which modules are
                turned on for "Authorized" schools to be able to access and use.
              </h4>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <MainLayout>
        <ScreenWrapper loading={false} loadingError={false} onReturn={() => { }}>
          <div className="reports">
            {allReports()}
            {teacherLevelReports()}
            {schoolAdminLevelReports()}
            {nietLevelReports()}
          </div>
        </ScreenWrapper>
      </MainLayout>
    </>
  );
};

export default ReportsMgmt;
