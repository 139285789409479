import { Public } from '@mui/icons-material';
import React from 'react';
import Footer from './Footer';
import PublicHeader from './PublicHeader';
import './layouts.scss';
import InitHeader from './InitHeader';

const InitLayout = ({ children }) => {
    return (<>
        <InitHeader />
        <div className='screen'>
            <div className='screen__content'>
                {children}
            </div>
        </div>
        <Footer />
    </>
    );
}

export default InitLayout;


