import './InputField.scss';
import React, { useEffect, useState } from 'react';
import { commatizeNum } from '../../../utilities/TextFormatter';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import Info from '../Info';
import { useValidationManager } from '../../../hooks/useValidation';
import TextBoxControl from '../TextBoxControl';
import TextAreaControl from '../TextAreaControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons'


export default function InputField({
    value,
    fieldName,
    validationName,
    title,
    info,
    subTitle,
    placeholder,
    units,
    type,
    showPasswordEye,
    preventPasswordAutoComplete,
    styleType,
    formatter,
    maxLength,
    onChange, //fires when you change
    validateOnBlur,
    forceValidate,
    onUpdate, //fires when you lose focus
    groupId, //check why this is gray
    isDisabled,
    notifyAboveOfValidationErrs,
    currentData,
    disableError,
    className,
    customValidate,
    inputClassName,
    viewOnlyMode,
    children,
    validateChildren,
}) {
    const [validationErrors, setValidationErrors] = useState([]);
    const [childElement, setChildElement] = useState(null);
    const validationMgr = useValidationManager(forceValidate, groupId);


    const updatedValidationName = validationName?.toLowerCase() ?? `${groupId}_${fieldName}`.toLowerCase()
    const isRequired = validationMgr?.getConfig(updatedValidationName)?.isRequired && validationMgr?.getConfig(updatedValidationName)?.groupId === groupId;
    //if it's a password
    const [passwordVisible, setIsPasswordVisible] = useState(false);

    let unitRightStyle = {
        top: '1.5rem'
    }

    if (subTitle) {
        unitRightStyle.top = '3rem'
    }

    useEffect(() => {
        if (validationMgr) {
            validationMgr.setForceValidate(forceValidate);
        }

        if (forceValidate) {
            handleValidate(value);
        }

    }, [forceValidate]);

    useEffect(() => {
        if (validateChildren) {
            handleValidateChildChange(value);
        }
    }, [value]);

    useEffect(() => {
        if (children) {
            let elementClone = {
                ...children.props,
                value: value,
                onBlur: onBlur
            };

            if (onChange) {
                elementClone.onChange = (e, field) => {
                    if (e?.target) {
                        handleOnChange(e.target.value, field?.FieldName ?? fieldName)
                    }
                };
            }

            if (onUpdate) {
                elementClone.onUpdate = onUpdate;
            }

            if (isDisabled) {
                elementClone.disabled = isDisabled;
            }

            elementClone.field = {
                PlaceHolder: placeholder,
                FieldName: fieldName
            }

            if (validationErrors) {
                elementClone.error = validationErrors.length > 0 ? 'true' : 'false';
            }

            const child = React.cloneElement(children, elementClone);
            setChildElement(child);
        }
    }, [value, children, validationErrors]);


    useEffect(() => {
        if (validationErrors && validationErrors.length > 0) {
            if (notifyAboveOfValidationErrs) {
                notifyAboveOfValidationErrs(true);
            }
        }
        else if (validationErrors && validationErrors.length === 0) {
            if (notifyAboveOfValidationErrs) {
                notifyAboveOfValidationErrs(false);
            }
        }
    }, [validationErrors])


    const applyFormatter = (value) => {
        switch (formatter ? formatter.toUpperCase() : '') {
            case 'COMMA':
                return commatizeNum(value);
            case '':
                return value;
            default:
                return value;
        }
    };
    const handleValidate = (currentValue) => {
        if (formatter === 'COMMA' && currentValue) {
            currentValue = currentValue.replace(/,/g, '');
        }
        let validationResult = null;
        if (validateOnBlur || forceValidate) {
            if (!customValidate) {
                validationResult = validationMgr.runValidation(currentValue, updatedValidationName, currentData);
            }
            else {
                validationResult = customValidate(currentValue, currentData);
            }
            setValidationErrors(validationResult.messages);
        }
    }

    const handleOnChange = (currentValue, e) => {
        if (formatter && formatter.toUpperCase() === 'COMMA') {
            currentValue = currentValue.replace(/,/g, '');
        }
        if (validationMgr.runFieldMask(currentValue, updatedValidationName, maxLength)) {
            if (currentValue && currentValue !== '') {
                setValidationErrors([]);
            }

            if (onChange) {
                onChange(currentValue, fieldName);
            }
        }
    }

    const handleValidateChildChange = (currentValue) => {
        if (formatter && formatter.toUpperCase() === 'COMMA') {
            currentValue = currentValue.replace(/,/g, '');
        }

        if (validationMgr.runFieldMask(currentValue, updatedValidationName, maxLength)) {
            if (currentValue && currentValue !== '') {
                setValidationErrors([]);
            }
        }
    }

    const onBlur = (e) => {
        if (validateOnBlur)
            handleValidate(e.target.value);

        if (onUpdate)
            onUpdate(e.target.value, fieldName);
    }

    return (
        <div className='field-input' >
            <div className={`generic-input-component`} title={title && !info ? title.replace('*', '') : ''} field-name={fieldName}>
                <div className='title-header'>
                    {title && <div className={`title`}><div>{title}{isRequired ? "*" : ""}</div><div>{info && <Info>{info}</Info>}</div></div>}
                    {subTitle ? (typeof subTitle).toLowerCase() === 'string' ? <div className={'subtitle'} dangerouslySetInnerHTML={{ __html: subTitle }} ></div> : <div className={'subtitle'}>{subTitle}</div> : null}
                </div>
                <div className={`${className ?? ''} child`}>
                    {childElement}
                </div>
                {!childElement &&
                    <>
                        {(!type || type.toLowerCase() == 'text' || type.toLowerCase() === 'number') &&
                            <div className={`input-text-wrapper ${className ?? ''} ${type === 'number' ? 'number' : ''}`}>
                                {units ? <div className={'units-align-right'}>{units}</div> : null}
                                <TextBoxControl disabled={isDisabled}
                                    field={{
                                        PlaceHolder: placeholder,
                                        FieldName: fieldName
                                    }}
                                    maxLength={maxLength}
                                    error={validationErrors.length > 0 ? 'true' : 'false'}
                                    onBlur={onBlur}
                                    preventPasswordAutoComplete={preventPasswordAutoComplete}
                                    value={value ? applyFormatter(value) : ''}
                                    onChange={(e) => handleOnChange(e.target.value, e)} type={(type === 'password' && showPasswordEye && passwordVisible) ? 'text' : type} />
                                {showPasswordEye && type === 'password' ? <div className={'password-viewer-eye'} onClick={() => { setIsPasswordVisible(!passwordVisible) }}>
                                    <FontAwesomeIcon icon={faEye} className={'fa-signin-icon'} />
                                </div> : null}
                            </div>}
                        {type?.toLowerCase() === "label" && <div className={className ?? ''}>
                            <span>{value ? applyFormatter(value) : ''}</span>
                        </div>}
                        {type?.toLowerCase() == 'textarea' && <div className={className ?? ''}>
                            <TextAreaControl disabled={isDisabled} field={{
                                PlaceHolder: placeholder,
                                FieldName: fieldName,

                            }}
                                className={inputClassName}
                                error={validationErrors.length > 0 ? 'true' : 'false'}
                                onBlur={onBlur}
                                value={value ?? ''}
                                onChange={(e) => handleOnChange(e.target.value, e)} />
                            {maxLength && <div className={'char-cnt'}>{value?.length ?? 0}/{maxLength}</div>}
                        </div>}
                    </>
                }
            </div>
            {!disableError && <div className={'error-wrapper'}>
                {validationErrors.map(message => <span key={message} className={'error'}>{message}&nbsp;</span>)}
            </div>}

        </div>
    );
}