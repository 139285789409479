import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { useAppUserManager, useOrgManager } from "../../../../hooks/useManagers";
import { FormatUTCDate } from "../../../../utilities/DateFormater";
import ContentModal from "./ContentModal";



const CertificationQuestionTable = ({ certificationQuestionMgr, filteredItems, onEdit, onDelete }) => {
    const [contentOpen, setContentOpen] = useState(false);
    const [focusedRow, setFocusedRow] = useState(null);
    const orgMgr = useOrgManager();
    const [organizations, setOrganizations] = useState(null);
    const appUserMgr = useAppUserManager();
    useEffect(() => {
        if (orgMgr) {
            orgMgr.loadOrgTree().then(r => {
                setOrganizations(orgMgr.OrgsOnly());
            });
        }
    }, [orgMgr]);


    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Question',
            dataProp: 'QuestionText',
            datatype: 'html',
            width: '60%',
            canSort: false
        },
        {
            header: 'Content',
            dataProp: "FileImgSrc",
            datatype: 'contentModal',
            width: '20%',
            canSort: false
        },
        {
            header: 'Default?',
            dataProp: 'Default',
            width: '10%',
            canSort: true
        },
        {
            header: 'Active?',
            dataProp: 'Active',
            width: '10%',
            canSort: true
        },
    ]);

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (c) => {
                newRows.push({
                    ...c,
                    Active: c.IsActive ? 'Yes' : 'No',
                    Default: c.IsDefault ? 'Yes' : 'No',
                    // sending both the extension and path so that the content modal can check if it's an image or video
                    // if this is not handled properly, any filter change will break the table
                    FileImgSrc: { path: c.File?.FileUrlPath, extension: c.File?.FileExtention, name: c.File ? c.File?.DisplayFileName + '.' + c.File?.FileExtention : '' },
                });
            });
            setRows(newRows);
        }
    }, [filteredItems, organizations]);

    const checkIsImage = (fileExtension) => {
        return (
            fileExtension === 'apng' ||
            fileExtension === 'gif' ||
            fileExtension === 'ico' ||
            fileExtension === 'jpg' ||
            fileExtension === 'jpeg' ||
            fileExtension === 'jfif' ||
            fileExtension === 'pjpeg' ||
            fileExtension === 'pjp' ||
            fileExtension === 'png' ||
            fileExtension === 'svg'
        );
    };

    const checkIsVideo = (fileExtension) => {
        return (
            fileExtension === 'mp4' ||
            fileExtension === 'avi' ||
            fileExtension === 'mpg' ||
            fileExtension === 'mpga' ||
            fileExtension === 'streaming.media.azure.net'
        );
    }

    const handleDownloadFile = async (row) => {
        await certificationQuestionMgr.QuestionItemManager.getFile(row?.File?.Id, `${row.File?.DisplayFileName}.${row.File?.FileExtention}`);
    }

    const handleContentModal = (row) => {
        if (row?.File && row?.File?.FileExtention) {
            if (checkIsImage(row?.File?.FileExtention) || checkIsVideo(row?.File?.FileExtention)) {
                setContentOpen(true);
                setFocusedRow(row);
            } else {
                handleDownloadFile(row);
            }
        }

    }

    return <>
        <ContentModal
            open={contentOpen}
            setOpen={setContentOpen}
            title="View Question Content"
            handleOk={() => onEdit(focusedRow)}
            okText="Edit"
            item={focusedRow}
        />
        <div className="contentDisplay-list">
            <TableControl
                permission='CertificationQuestion'
                columns={columns}
                data={rows}
                useIntegratedPaging={['10', '25', '50', '100']}
                onEdit={onEdit}
                onDelete={onDelete}
                onOpen={(row) => handleContentModal(row)}
            />
        </div>
    </>
}

export default CertificationQuestionTable;