import React, { useState } from 'react';
import MainLayout from '../../../components/layout/MainLayout';
import { useRubricManager } from '../../../hooks/useManagers';
import RubricTable from '../components/rubric/RubricTable';
import RubricFilter from '../components/rubric/RubricFilter';
import RubricDialog from '../components/rubric/RubricDialog';
import { DialogControl } from '../../../components/controls/DialogControl';
import { ButtonControl } from '../../../components/controls/ButtonControl';
import '../rubricList.scss';
import { useEffect } from 'react';
import evaluationFilterTypes from '../../dev/data/filterEvalSet';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import RubricPrintScreen from '../../observations/components/RubricPrintScreen';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import SurveyTable from '../components/observation/SurveyTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { el } from 'date-fns/locale';

const RubricMgmt = ({ authMgr }) => {
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState(null);
    const [rubricData, setRubricData] = useState([]);
    const [schoolYearData, setSchoolYearData] = useState([]);
    const [currentYear, setCurrentYear] = useState();
    const [loading, setLoading] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [rubricRatings, setRubricRatings] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openVerifyDeleteDialog, setOpenVerifyDeleteDialog] = useState();
    const [openVerifyDuplicateDialog, setOpenVerifyDuplicateDialog] = useState();
    const [selectedRubric, setSelectedRubric] = useState();
    const [onLoading, setOnLoading] = useState(true);
    const [openViewRubricDialog, setOpenViewRubricDialog] = useState(false)
    const [gridData, setGridData] = useState(null)
    const [printing, setPrinting] = useState(false)
    const [currentFilter, setCurrentFilter] = useState();
    const rubricMgr = useRubricManager();
    const navigate = useNavigate();

    useEffect(() => {
        if (rubricMgr) {
            let ignore = false;
            setOnLoading(true);
            async function fetchData() {
                // You can await here
                setLoading(true);
                let rubricResult = rubricMgr.SearchRubrics().then(rubrics => {
                    setData(rubrics);
                    setRubricRatings(rubricMgr.RubricMetadata.Ratings);
                    let schoolYears = rubricMgr.RubricMetadata.SchoolYears.sort(function (a, b) {
                        return new Date(a.SchoolYearStart) - new Date(b.SchoolYearStart);
                    });
                    const schoolYearDataMap = schoolYears.map(item => ({ ClientId: item.Organization?.Id, label: getFormatedDate(item.SchoolYearStart) + (item.Organization ? ` - ${item.Organization.Name}` : ''), value: item.Id, identifier: item.SchoolYearIdentifier }));
                    var currentSchoolYear = schoolYearDataMap.find(x => x.label.endsWith('Current Year)'));
                    setCurrentYear(currentSchoolYear);
                    setSchoolYearData(schoolYearDataMap);

                    let orgs = rubricMgr.RubricMetadata.Clients.map(item => ({ label: item.DisplayName, value: item.ClientId }))
                    setOrganizations(orgs);
                    setOnLoading(false);
                    return true;
                });

                return await Promise.all([rubricResult]).then(r => {
                    if (!r || r.length == 0) {
                        setErrors(["Failed to load rubrics, if this problem continues please contact customer support."]);
                        setOnLoading(false);
                    }
                });
            }

            fetchData();

            return () => { ignore = true }; //cleanup
        }
    }, [rubricMgr]);

    useEffect(() => {
        if (rubricMgr) {
            if (rubricMgr._rubricFilter) {
                setCurrentFilter(rubricMgr._rubricFilter);
            }
        }
    }, [rubricMgr]);

    const getFormatedDate = (startDate) => {
        let date = new Date(startDate);
        var year = date.getFullYear();
        let isCurrentYear = year + 1 == rubricMgr?.AppUserState.currentSchoolYear;
        return year + '-' + (year + 1 + (isCurrentYear ? " (Current Year)" : ''));
    }

    const handleOnFilterChange = async (field, value) => {

        const rubricFilter = rubricMgr._rubricFilter;
        const updatedRubricFilter = { ...rubricFilter };    //load contents into this 

        if (field === 'EvaluationType') {
            updatedRubricFilter.EvaluationType = value;
        }
        else if (field === 'SchoolYear') {
            updatedRubricFilter.SchoolYear = value;
        }
        else if (field === 'Organization') {
            updatedRubricFilter.Organization = value;
        }
        else if (field === 'Date') {
            updatedRubricFilter.Date = value;
        }
        else if (field === 'Status') {
            updatedRubricFilter.Status = value;
        }
        else if (field === 'Search') {
            updatedRubricFilter.Search = value;
        }

        setData(await rubricMgr.FilterRubrics(updatedRubricFilter));
    }

    const deleteRubric = async () => {
        const rubricResult = await rubricMgr.ItemManager.get(selectedRubric.Id);
        if (rubricResult.Success) {
            const rubric = rubricResult.Items.first();
            await rubricMgr.ItemManager.delete(rubric.Id);

            for (const row of rubric.RubricRatingMap) {
                const rowResult = await rubricMgr.RatingMapItemMgr.delete(row.Id);
            }

            for (const domainMapItem of rubric.RubricDomainMap) {
                const domainResult = await rubricMgr.RubricDomainMapItemMgr.delete(domainMapItem.Id);
            }
            var currentTableData = [...data];
            currentTableData = currentTableData.filter(item => item.Id !== rubric.Id)

            setSelectedRubric();
            setOpenVerifyDeleteDialog(false);
            setData(currentTableData);
        }
        else {
            setErrors(["Failed to delete rubric, invalid rubric information."])
        }
    }

    const handleOnDelete = (rubric) => {
        setOpenVerifyDeleteDialog(true);
        setSelectedRubric(rubric);
    }

    const handleOnVerifyDuplicate = (rubric) => {
        setOpenVerifyDuplicateDialog(true);
        setSelectedRubric(rubric);
    }

    const handleOnEdit = (row) => {
        navigate(`/admin/RubricIndicator/${row.Id}`);
    }

    const handleOnView = (row) => {
        rubricMgr.getFormattedRubric(row.Id).then(r => {
            setOpenViewRubricDialog(true)
            let copiedGridData = { ...r.gridData, name: row.name }
            setGridData(copiedGridData)
        })
    }
    const handleOnPrint = (row) => {
        rubricMgr.getFormattedRubric(row.Id).then(r => {
            setPrinting(true)
            let copiedGridData = { ...r.gridData, name: row.name }
            setGridData(copiedGridData)
        })
    }

    const handleOnDuplicate = async () => {
        //copy selected row and save to db, update list
        const rubricResult = await rubricMgr.ItemManager.get(selectedRubric.Id);
        if (rubricResult.Success) {
            const rubric = rubricResult.Items.first();
            setOpenVerifyDuplicateDialog(false);
            setOnLoading(true);
            let newRubricCopy = {
                Name: rubric.Name + ' - Copy',
                GlobalRubricId: rubric.GlobalRubricId,
                SchoolYearId: rubric.SchoolYearId,
                SchoolYearIdentifier: rubric.SchoolYearIdentifier,
                ClientId: rubric.ClientId,
                RubricType: rubric.RubricType,
                IsDeleted: false,
                IsActive: rubric.IsActive,
                Status: 1 //all copies are set to Draft
            }
            const rubricSaveResult = await rubricMgr.ItemManager.save(newRubricCopy);
            const newRubricId = rubricSaveResult.Items[0]?.Id;

            for (const row of rubric.RubricRatingMap) {
                let rowToSave = {
                    RubricRatingId: row.RubricRatingId,
                    RubricId: newRubricId, //new rubric saved above now has Id
                    ShowInRubric: row.ShowInRubric,
                    Sequence: row.Sequence,
                    ClientId: row.ClientId,
                    IsDeleted: false,
                    IsActive: true
                }
                const rowResult = await rubricMgr.RatingMapItemMgr.save(rowToSave);
            }

            for (const domainMapItem of rubric.RubricDomainMap) {
                let domainMapItemToSave = {
                    RubricId: newRubricId, //new rubric saved above now has Id
                    RubricDomainId: domainMapItem.RubricDomainId,
                    Sequence: domainMapItem.Sequence,
                    ClientId: domainMapItem.ClientId
                }
                const domainResult = await rubricMgr.RubricDomainMapItemMgr.save(domainMapItemToSave);
            }

            for (const indicatorItem of rubric.RubricIndicatorMap) {
                let indicatorMapItem = {
                    RubricId: newRubricId,
                    RubricIndicatorId: indicatorItem.RubricIndicatorId,
                    RubricDomainId: indicatorItem.RubricDomainId,
                    Sequence: indicatorItem.Sequence,
                    ClientId: indicatorItem.ClientId
                }
                const rubricIndicatorMapResult = await rubricMgr.IndicatorMapItemManager.save(indicatorMapItem);
            }

            //for each indicator saved, save each rating description and copy it over
            for (const indicatorRatingItem of rubric.RubricIndicatorRatingMap) {
                let indicatorRatingMapToSave = {
                    RubricId: newRubricId,
                    RubricIndicatorId: indicatorRatingItem.RubricIndicatorId,
                    RubricRatingId: indicatorRatingItem.RubricRatingId,
                    Description: indicatorRatingItem.Description,
                    ClientId: indicatorRatingItem.ClientId
                }
                const result = await rubricMgr.IndicatorRatingMapItemMgr.save(indicatorRatingMapToSave);
            }

            newRubricCopy.Id = newRubricId;
            newRubricCopy.RubricDomainMap = rubric.RubricDomainMap;
            newRubricCopy.RubricRatingMap = rubric.RubricRatingMap;
            newRubricCopy['SchoolYear'] = rubric.SchoolYear;
            newRubricCopy['UpdatedDate'] = rubricSaveResult.Items[0]?.UpdatedDate;

            var currentTableData = JSON.parse(JSON.stringify(data));

            currentTableData.unshift(newRubricCopy);

            setData(currentTableData);
            setOnLoading(false);

            navigate(`/admin/RubricIndicator/${rubricSaveResult.Items[0].Id}`);
        }
        else {
            setErrors(["Failed to duplicate rubric, rubric failed to load."])
        }
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
        setOpenVerifyDeleteDialog(false);
        setOpenVerifyDuplicateDialog(false);
    }

    const handleOnCreateRubric = () => {
        setRubricData([]);
        setEditClicked(false);
        setOpenDialog(true);
    }

    const handlePrintCompleted = () => {
        setPrinting(false)
        setGridData(null)
    }

    return (<>
        {gridData && printing && <RubricPrintScreen rubricData={gridData} handlePrintCompleted={handlePrintCompleted} />}
        {
            openViewRubricDialog &&
            <>
                <div className={'blockui'}></div>
                <div className={`rubric-modal expanded-height`}>
                    <div className={'rubric-modal-header'}>
                        <div className={'rubric-title'}>
                            {gridData?.name} Rubric
                        </div>
                        <div className={'btn-ctrl-section'}>
                            {!printing &&
                                <>
                                    <FontAwesomeIcon title="Close" className={'fa-icon'} icon={faXmark} onClick={() => setOpenViewRubricDialog(false)} />
                                </>
                            }
                        </div>
                    </div>
                    <div className={'rubric-section'}>
                        <SurveyTable
                            isSelfReflection={true}
                            disableActOfRating={true}
                            gridData={gridData}
                            fixedRowWidth={'9rem'}
                            hideRatingBtn={true}
                            hideIndicatorsProgress={true}
                        />
                    </div>
                </div>
            </>
        }
        <RubricDialog dialogState={openDialog} dialogClose={handleDialogClose} editRubricData={rubricData} editClicked={editClicked} schoolYears={schoolYearData} currentYear={currentYear} organizationDataSet={organizations} rubricRatingDataSet={rubricRatings} />
        <DialogControl title={'Delete Rubric?'} subTitle={'Deleting this Rubric will also remove its associated Domains and Indicators from the list.'} openDialog={openVerifyDeleteDialog} excludeCancel={true} onCancel={handleDialogClose} customYes={true} isYes={true} onYes={deleteRubric} isNo={true} onNo={handleDialogClose} />
        <DialogControl title={'Duplicate Rubric?'} subTitle={'This will make a copy of this rubric, including its Indicators and Domains.'} openDialog={openVerifyDuplicateDialog} excludeCancel={true} onCancel={handleDialogClose} customYes={true} isYes={true} onYes={handleOnDuplicate} isNo={true} onNo={handleDialogClose} />
        <MainLayout errors={errors}>
            <div className="screen-wrapper">
                <div className='control-box-wrapper'>
                    {!onLoading ? <div className='control-box-list'>
                        <RubricFilter evalTypes={evaluationFilterTypes} organizations={organizations} schoolYears={schoolYearData} currentYear={currentYear} onFilterChange={handleOnFilterChange} currentFilter={currentFilter} />
                        <RubricTable filteredContent={data ?? []} organizations={organizations} onDelete={handleOnDelete} onEdit={handleOnEdit} onView={handleOnView} onPrint={handleOnPrint} onDuplicate={handleOnVerifyDuplicate}
                        />
                    </div> : <>
                        <div className='rubric-page-loading'><Loading type='default' size='4rem' /><div className='loading-font'>Loading...</div></div>
                    </>}
                    <div className='screen-footer list'>
                        {rubricMgr?.ItemManager?.CanWrite && !onLoading &&
                            <ButtonControl type={"create"} onClick={handleOnCreateRubric}>
                                Create Rubric
                            </ButtonControl>
                        }
                    </div>
                </div>
            </div>
        </MainLayout>
    </>
    );
}

export default RubricMgmt;