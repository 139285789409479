import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import '../../../certifications/Certification.scss'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ScreenWrapper from '../../../../components/layout/screenWrapper';
import MainLayout from '../../../../components/layout/MainLayout';
import { useCertificationRatingManager } from '../../../../hooks/useManagers';
import SurveyTable from '../observation/SurveyTable';
import ButtonControl from '../../../../components/controls/ButtonControl';
import BitmovinPlayer from '../../../contentLibrary/app-components/Content/Player/BitmovinPlayer';

const CertificationRubricConfigAnswer = () => {
    const certificationRatingMgr = useCertificationRatingManager();
    const [loading, setLoading] = useState(false)
    const [videoSrc, setVideoSrc] = useState(null)
    const [gridData, setGridData] = useState(null)
    const [contentAndRubricData, setContentAndRubricData] = useState(null)
    const [ratingSelectOptions, setRatingSelectOptions] = useState(null)
    const [indicatorsRated, setIndicatorsRated] = useState(null)
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
    }, [])

    useEffect(() => {
        if (certificationRatingMgr && params) {
            grabData()
        }
    }, [certificationRatingMgr])


    const grabData = async () => {
        await certificationRatingMgr.grabCertificationRatingsKey(params.rubricId, params.contentId).then(r => {
            setContentAndRubricData(r)
            setVideoSrc(r.Content.File)
            certificationRatingMgr.transformRubric(r).then(tr => {
                setGridData(tr.gridData);
                if (tr.tagMap) {

                    let ratingSelectOpts = {};

                    Object.keys(tr.tagMap).forEach(t => {
                        let selectedRatingValue = tr.gridData.data?.reduce((r, cv) => {
                            if (tr.tagMap[t].name === cv.tag?.name && cv.selectedRating !== '') {
                                r = cv.selectedRating;
                            }
                            return r;
                        }, null)

                        ratingSelectOpts[tr.tagMap[t].name] = {
                            isExpanded: false, surveyRatingId: tr.gridData.data?.reduce((r, cv) => {
                                if (tr.tagMap[t].name === cv.tag?.name) {
                                    r = cv.surveyRatingId
                                }
                                return r;
                            }, null),
                            selectedRating: selectedRatingValue,
                            rubricRatingId: _.find(tr.gridData.metaData, y => y.rating === selectedRatingValue)?.rubricRatingId,
                            rubricIndicatorId: tr.tagMap[t].id
                        };
                    })
                    setRatingSelectOptions(ratingSelectOpts);
                }
            })
            setLoading(false)
        })
    }



    const selectRating = (indicator, rating) => {
        let ratingSelectOpts = { ...ratingSelectOptions };
        if (ratingSelectOpts[indicator]) {
            ratingSelectOpts[indicator].isExpanded = !ratingSelectOpts[indicator]?.isExpanded;
            ratingSelectOpts[indicator].selectedRating = rating;


            let certificationRatingAnswers = {
                RubricId: contentAndRubricData.Rubric.RubricId,
                ContentId: contentAndRubricData.ContentId,
                RubricRatingId: gridData.metaData.reduce((r, cv) => {
                    if (cv.rating == rating) {
                        r = cv.rubricRatingId;
                    }
                    return r;
                }, null),
                ObservationRatingTypeId: 0,
                RubricIndicatorId: ratingSelectOpts[indicator].rubricIndicatorId
            }

            if (ratingSelectOpts[indicator].surveyRatingId) {
                certificationRatingAnswers.SurveyRatingId = ratingSelectOpts[indicator].surveyRatingId
            }

            certificationRatingMgr.save(certificationRatingAnswers).then(res => {
                if (res) {
                    if (res?.Items?.first()) {
                        let item = res.Items.first();
                        ratingSelectOpts[indicator].surveyRatingId = item.SurveyRatingId;
                    }
                }
            })

            setRatingSelectOptions(ratingSelectOpts);
        }
    }

    useEffect(() => {
        if (ratingSelectOptions) {
            let ir = Object.keys(ratingSelectOptions).reduce((r, cv) => {
                if (ratingSelectOptions[cv].selectedRating) {
                    r++;
                }
                return r;
            }, 0);
            setIndicatorsRated(ir);
        }
    }, [ratingSelectOptions])

    const toggleRatingOptions = (indicator) => {
        let ratingSelectOpts = { ...ratingSelectOptions };
        if (ratingSelectOpts[indicator]) {
            ratingSelectOpts[indicator].isExpanded = !ratingSelectOpts[indicator]?.isExpanded;
            setRatingSelectOptions(ratingSelectOpts);
        }
    }
    return (
        <MainLayout className={"certification-rubric-page"}>
            <ScreenWrapper loading={loading}>
                <div className='screen-header'>
                    <h3>{contentAndRubricData?.Content.Title}</h3>
                    <h5>{`Rate Using ${contentAndRubricData?.Rubric.Name} for Certification`} </h5>
                </div>
                <div className="video-rubric-wrapper">
                    <div className="video-wrapper">
                        {!_.isNil(videoSrc) && 
                            <div className='video-player'>
                                <div id='player-wrapper'>
                                    <BitmovinPlayer content={videoSrc} />
                                </div>
                            </div>                        
                        }
                    </div>
                    <SurveyTable
                        isSelfReflection={true}
                        ratingSelectOptions={ratingSelectOptions}
                        indicatorsRated={indicatorsRated}
                        gridData={gridData}
                        fixedRowWidth={'9rem'}
                        toggleRatingOptions={toggleRatingOptions}
                        selectRating={selectRating} />
                </div>
                <div className="button-row">
                    <ButtonControl type='cancel' onClick={() => { navigate(-1) }}>Cancel</ButtonControl>
                    <ButtonControl type='okay' onClick={() => { navigate(-1) }}>Save</ButtonControl>
                </div>
            </ScreenWrapper>
        </MainLayout>
    );
}

export default CertificationRubricConfigAnswer;