import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function getBarOption(data) {
  //Primary Blue, Light Blue, DarkBlue, Orange, Gray
  const colorScheme = ['#00548b', '#9ad7d8', '#003c5b', '#f47e3a', '#9E9E9E']
  let series;
  let xDataLabel = _.map(_.sortBy(data.Data, x => x.GroupByName), x => x.GroupByName)
  let baseBarConfig = {
    type: 'bar',
    cursor: 'auto',
    barMaxWidth: '40',
    emphasis: {
      focus: 'series'
    },
  }
  let avgScore = {
    ...baseBarConfig,
    name: "Average Self Rating",
    color: colorScheme[1],
    data: _.map(_.sortBy(data.Data, x => x.GroupByName), x => x.Value2?.toFixed(2))
  }
  let avgScoreByObserver = {
    name: "Average Observer Rating",
    ...baseBarConfig,
    color: colorScheme[0],
    data: _.map(_.sortBy(data.Data, x => x.GroupByName), x => x.Value1?.toFixed(2))
  }
  //Certification Status
  let notStartedStatusCount = {
    ...baseBarConfig,
    name: "Not Started ",
    color: colorScheme[4],
    data: _.map(_.sortBy(data.Data, x => x.GroupByName), x => x.Value1?.toFixed(2))
  }
  let inProgressStatusCount = {
    ...baseBarConfig,
    name: "In Progress ",
    color: colorScheme[1],
    data: _.map(_.sortBy(data.Data, x => x.GroupByName), x => x.Value2?.toFixed(2))
  }
  let passedStatusCount = {
    name: "Passed ",
    ...baseBarConfig,
    color: colorScheme[2],
    data: _.map(_.sortBy(data.Data, x => x.GroupByName), x => x.Value3?.toFixed(2))
  }
  let failStatusCount = {
    name: "Failed ",
    ...baseBarConfig,
    color: colorScheme[3],
    data: _.map(_.sortBy(data.Data, x => x.GroupByName), x => x.Value4?.toFixed(2))
  }
  //Observation Status
  let observationNotStarted = {
    name: "Not Started",
    ...baseBarConfig,
    color: colorScheme[5],
    data: _.map(_.sortBy(data.Data, x => x.GroupByName), x => x.Value1?.toFixed(2))
  }
  let observationInProgress = {
    name: "In Progress",
    ...baseBarConfig,
    color: colorScheme[1],
    data: _.map(_.sortBy(data.Data, x => x.GroupByName), x => x.Value3?.toFixed(2))
  }
  let observationCompleted = {
    name: "Completed ",
    ...baseBarConfig,
    color: colorScheme[0],
    data: _.map(_.sortBy(data.Data, x => x.GroupByName), x => x.Value2?.toFixed(2))
  }
  let required = {
    name: "Required Observation",
    ...baseBarConfig,
    barGap: '-90%',
    barMaxWidth: '50',
    color: colorScheme[0],
    data: _.map(_.sortBy(data.Data, x => x.GroupByName), x => x.Value1?.toFixed(2))
  }
  let submitted = {
    name: "Completed Observation",
    ...baseBarConfig,
    color: colorScheme[1],
    data: _.map(_.sortBy(data.Data, x => x.GroupByName), x => x.Value2?.toFixed(2))
  }


  if (data.AnalyticType == 0) {
    series = [avgScoreByObserver]
  } else if (data.AnalyticType == 1) {
    series = [avgScore]
  } else if (data.AnalyticType == 4) {
    series = [avgScoreByObserver, avgScore]
  } else if (data.AnalyticType == 2) {
    series = [notStartedStatusCount, inProgressStatusCount, passedStatusCount, failStatusCount]
  } else if (data.AnalyticType == 3) {
    series = [observationNotStarted, observationInProgress, observationCompleted]
  } else if (data.AnalyticType == 8) {
    series = [required, submitted]
  }
  return {

    tooltip: {
      cursor: 'auto',
      axisPointer: { type: 'shadow' },
    },
    grid: {
      bottom: '25%',
      height: '60%',
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        width: 70,
        overflow: 'truncate',
        interval: 0,
        rotate: 45,
        hideOverlap: true,
      },
      data: xDataLabel
    },
    yAxis: {
      type: 'value'
    },
    series: series
  };
};

