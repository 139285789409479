import React, { useEffect, useState, useRef } from 'react';
import '../../admin.scss';

import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import DatePickerControl from '../../../../components/controls/DatePickerControl';

import { CheckPicker, SelectPicker } from 'rsuite';
import '../../../../styles/rsuite_override.css';
import _, { groupBy } from 'lodash';
import $ from 'jquery';
import { useAppUserManager } from '../../../../hooks/useManagers';
import './transactionFilter.scss'

const TransactionFilter = ({ onFilterChange, data }) => {
    const authMgr = useAppUserManager();
    const { permissions } = authMgr?.GetGlobalState() ?? {};
    const [statusTypes, setStatusTypes] = useState();
    const [selectedUsername, setSelectedUsername] = useState();
    const [selectedTransactionType, setSelectedTransactionType] = useState();
    const [selectedDate, setSelectedDate] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [transactionTypeData, setTransactionTypeData] = useState();

    const statusData = [{label: 'Active', value: 1} , {label: 'Inactive', value: 2}].map(
        item => ({ label: item.label, value: item.value })
      );

    useEffect(() => {
        if (data) {
            const transactionTypes = [...new Set(data.map(item => item.ItemType))]
                .map(itemType => ({ label: itemType, value: itemType }));
            setTransactionTypeData(transactionTypes);
        }
    }, [data]);

    const handleOnChange = (value, fieldName) => {
        if (onFilterChange)
            onFilterChange(fieldName, value);
    }

    const onTransactionTypeSelect = (value) => {
        if (onFilterChange)
            onFilterChange("TransactionType", value);
    }

    const onTextChange = (value, fieldName) => {
        if (onFilterChange)
            onFilterChange(fieldName, value);
    }

    const onTreeClean = (fieldName) => {
        switch (fieldName) {
            case 'Username':
                setSelectedUsername();
                break;
            case 'TransactionType':
                setSelectedTransactionType();
                break;
            case 'Status':
                setSelectedStatus();
                break;
            case 'Date':
                setSelectedDate();
            default:
          }
        
        if (onFilterChange)
            onFilterChange(fieldName, []);
    }

    const onStatusCheckSelect = (activeNodes) => {
        setSelectedStatus(activeNodes);

        if (onFilterChange)
            onFilterChange("Status", activeNodes);
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        //TODO: Reset all controls to their default value
        $(".rs-picker-toggle-clean").trigger("click");
        $("#field_dp_Date").val("mm/dd/yyyy");

        if (onFilterChange) {
            setSelectedUsername([]);
            setSelectedTransactionType([]);
            setSelectedDate();
            setSelectedStatus([]);

            onFilterChange("Username", '');
            onFilterChange("TransactionType", '');
            onFilterChange("Status", []);
        }
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Transaction Management</div>
                    <div className="filterControl">
                        <div className='bottom-space'>
                            <InputField
                                id='username'
                                title='Username'
                                value={selectedUsername ?? ''}
                                fieldName='Username'
                                disableError={true}
                                placeholder='Search Username'
                                onChange={((fieldname, value) => onTextChange(fieldname, value))}>
                            </InputField>
                        </div>
                    </div>
                    <div className='title-header'>
                        <div style={{marginTop: '-1em'}} className={`title`}><div>Transaction Type</div><div></div>
                        </div>
                    </div> 
                    <SelectPicker data={transactionTypeData} searchable={false} onChange={onTransactionTypeSelect} />

                    <div>
                        <div className="title-header">
                            <div className="title">
                                Transaction Status
                            </div>
                        </div>
                        <SelectPicker data={statusData} searchable={false} onSelect={onStatusCheckSelect}/>
                    </div>
                    
                    <div>
                        <InputField title="Date" maxLength={100} onChange={handleOnChange} value={selectedDate} groupId={'USER'} fieldName="Date" disableError={true} >
                            <DatePickerControl includeTime={false} value={selectedDate} />
                        </InputField>
                    </div>

                    <div style={{ paddingTop: '1.3em' }} className="filterControl">
                        <ButtonControl
                            onClick={handleClearFilter}>
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}

export default TransactionFilter;