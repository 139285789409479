import { useEffect, useState } from "react";
import ButtonControl from "../controls/ButtonControl";
import Loading from "../Loading";
import { DialogControl } from "../controls/DialogControl";
import _ from "lodash";

const ScreenWrapper = ({ loading, loadingError, onReturn, children, className }) => {
    return <>
        {
            !loading && !loadingError && children && <div className={"screen-wrapper " + (className ? className : '')}>{children}</div>
        }
        {
            loading &&
            <div className="init__content">
                <Loading type='default' size={'5rem'}></Loading><h5>Loading data...</h5>
            </div>
        }
        {
            loadingError &&
            <div className={'screen-wrapper ' + className}>
                <div className='control-box-wrapper'>
                    <div className='loading-box'>
                        <div className='error'>An error has occurred while loading: {loadingError}</div><br />
                        <ButtonControl onClick={onReturn} type={'okay'}>Return to List</ButtonControl>
                    </div>
                </div>
            </div>
        }
    </>
}

export default ScreenWrapper;