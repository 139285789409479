import { React, useEffect, useState } from 'react';
import { Panel } from 'rsuite';
import { InputField } from '../../../../../components/controls/InputField';
import { LabelControl } from '../../../../../components/controls';
import './../styles/observationDomainWeights.scss';
import _ from 'lodash';

const ObservationDomainWeights = ({ data, setData, settings, onChange }) => {
    const [observationDomainWeightData, setObservationDomainWeightData] = useState();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [administratorWeightData, setAdministratorWeightData] = useState();
    const [masterTeacherWeightData, setMasterTeacherWeightData] = useState();
    const [mentorTeacherWeightData, setMentorTeacherWeightData] = useState();
    const [selfEvalWeightData, setSelfEvalWeightData] = useState();
    const observedPositionTypeOrder = ['Career Teacher', 'Mentor Teacher', 'Master Teacher'];

    useEffect(() => {
        if (data) {
            let sortedData = sortObserverDomainData(data);
            setObservationDomainWeightData(sortedData);
        }
    }, [data]);
    const sortObserverDomainData = (dataSet) => {

        //sort the array by "Rubric Domain Name" alphabetically
        const sortedData = dataSet.sort((a, b) => {
            const nameA = a.RubricDomainName?.toLowerCase();
            const nameB = b.RubricDomainName?.toLowerCase();
            if (nameA < nameB)
                return -1;
            if (nameA > nameB)
                return 1;
            return 0;
        });

        const groupedData = sortedData.reduce((groups, item) => {
            const domainName = item.RubricDomainName;
            if (!groups[domainName]) {
                groups[domainName] = [];
            }
            groups[domainName].push(item);
            return groups;
        }, {});

        return groupedData;
    }

    const handleWeightChange = (domain, positionId, value) => {
        const updatedObservationDomainWeightData = [...data];
        const domainEntry = updatedObservationDomainWeightData.find(entry => entry.ObservedPositionTypeName === positionId && entry.RubricDomainName === domain);
        if (value === '' || value === null || value === undefined) {
            value = 0;
        }
        if (domainEntry) {
            domainEntry.Weight = value;

            setData(updatedObservationDomainWeightData);
        }
    }
    return (
        <>
            <Panel header="Observation Domain Weights" bordered>
                <div className='observation-domain-weights-table'>
                    {observationDomainWeightData ? (
                        <table className="observation-domain-weights-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    {observedPositionTypeOrder.map((positionType) => (
                                        <th key={positionType}>{positionType}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(observationDomainWeightData).map((domain) => (
                                    <tr key={domain}>
                                        <td className='row-header'>{domain}</td>
                                        {observedPositionTypeOrder.map((positionType) => {
                                            const entry = observationDomainWeightData[domain].find(e => e.ObservedPositionTypeName === positionType);

                                            return (
                                                <td key={positionType} >
                                                    <InputField
                                                        value={entry ? entry.Weight : ''}
                                                        placeholder={'0'}
                                                        onChange={(e) => handleWeightChange(entry.RubricDomainName, entry.ObservedPositionTypeName, e)}
                                                        type="number"
                                                        disableError={true} />
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                                <tr>
                                    <th></th>
                                    <th>Total: {_.sumBy(_.groupBy(data, x => x.ObservedPositionTypeName)['Career Teacher'], y => parseInt(y.Weight))}</th>
                                    <th>Total: {_.sumBy(_.groupBy(data, x => x.ObservedPositionTypeName)['Mentor Teacher'], y => parseInt(y.Weight))}</th>
                                    <th>Total: {_.sumBy(_.groupBy(data, x => x.ObservedPositionTypeName)['Master Teacher'], y => parseInt(y.Weight))}</th>
                                </tr>
                            </tbody>
                        </table>
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
            </Panel>
        </>
    );
}

export default ObservationDomainWeights;