import React, { useState } from 'react';
import _, { set } from 'lodash';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ScreenWrapper from '../../components/layout/screenWrapper';
import MainLayout from '../../components/layout/MainLayout';
import { useCertificationContext } from './components/CertificationDataProvider';
import CertificationAdminDetails from './components/administratorcertification/details';
import CertificationAdminFilters from './components/administratorcertification/filter';
import CertificationAdminList from './components/administratorcertification/table';
import { default as Print } from './components/certificationprint/PrintCertification';
import moment from 'moment';
import { DialogControl } from '../../components/controls/DialogControl';
import ButtonControl from '../../components/controls/ButtonControl';

const CertificationAdmins = () => {
    const { managerIsReady, certificationAdminMgr, observationMgr, userMgr, userProfile, orgMgr, appUserMgr } = useCertificationContext();
    const [manageMode, setManageMode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null)
    const [observationConfig, setObservationConfig] = useState(null)
    const [selectedCeretification, setSelectedCertification] = useState(null)
    const [observationConfigPrint, setObservationConfigPrint] = useState(null)
    const [openResetCertificationDialog, setOpenResetCertificationDialog] = useState(false)
    const [openManuallyPassCert, setOpenManuallyPassCert] = useState(false)
    const [certificationHistory, setCertificationHistory] = useState(null)
    const [print, setPrint] = useState(false);
    const [saving, setSaving] = useState(null)
    const [orgs, setOrgs] = useState(null)
    const [errors, setErrors] = useState(null)
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (managerIsReady) {
            setLoading(true)
            handleParamChange();
            orgMgr.loadOrgUserTree().then((r) => {
                setOrgs(r)
            })
        }
    }, [managerIsReady, params]);

    const [canCertify, setCanCertify] = useState(null);
    useEffect(() => {
        if (certificationAdminMgr?._allData && canCertify === null) {
            let existingCertForLoggedInUser = certificationAdminMgr._allData.find(x => x.UserId === appUserMgr.AppUserState.userProfile.Id);
            if(existingCertForLoggedInUser) {
                setCanCertify(certificationAdminMgr._allData.find(x => x.UserId === appUserMgr.AppUserState.userProfile.Id));
            } else {
                let isSysAdmin = appUserMgr.AppUserState.userProfile.Roles.some(role => role === 'SysAdmin');
                if(!isSysAdmin) {
                    let newCertforUser = {
                        UserId: appUserMgr.AppUserState.userProfile.Id,
                        Status: 'Admin' //not started
                    }
                    setCanCertify(newCertforUser);
                }
            }
        }
    }, [certificationAdminMgr?._allData]);


    const handleParamChange = async () => {
        //Check if they have permission to see list. Change config
        // Permission for a VIEW only answer.
        // Permission to EDIT the answers too.
        if (params.id) {
            //Change this to params
            setManageMode('view')
            const userId = params.id
            const currentSchoolYear = certificationAdminMgr.AppUserState.currentSchoolYear
            certificationAdminMgr.list(certificationAdminMgr.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false && x.CertificationType == 1 && x.UserId == userId && x.SchoolYearIdentifier != currentSchoolYear || ((x.Status == 5 || x.Status == 9) && x.SchoolYearIdentifier == currentSchoolYear)", { userId, currentSchoolYear })).then(r => setCertificationHistory(r.Items))
            await certificationAdminMgr.getSingleCertificationWithUserId(params.id).then(result => {
                if (result.Success) {
                    if (result.Items.length === 0) {
                        setErrors(["User is not configured to be evaluated for a certification."])
                    } else {
                        setData(result.Items.first())

                        const orgId = result.Items.first().OrganizationIds.first()
                        const districtId = result.Items.first().DistrictIds.first()
                        const schoolId = result.Items.first().SchoolIds.first()
                        const schoolYear = result.Items.first().SchoolYearIdentifier
                        observationMgr.observationConfigOperation(orgId, districtId, schoolId, schoolYear).then(r => {
                            if (r) {
                                setObservationConfig(r)
                                setLoading(false)
                            } else console.log("No observation Config Found")
                        })
                    }
                } else console.log('User ID not found for certification retrieval.')
            })
        } else {
            certificationAdminMgr.search(undefined, true).then(r => {
                if (_.every(r, x => x.UserId === userProfile.Id)) {
                    navigate(`/certifications/administrator/${userProfile.Id}`)
                } else {
                    setData(r)
                    setLoading(false)
                    setManageMode('list')
                }
            })
        }
    }


    const createNewCertificate = async (certificationData) => {
        if (!certificationData) {
            certificationData = data
        }
        let result = await certificationAdminMgr.save({ ...certificationData, User: undefined, Status: 4, StartedDate: moment().utc().toISOString() }).then(r => {
            if (r.Success) {
                return certificationAdminMgr.getSingleCertificationWithUserId(r.Items.first().UserId).then(r => {
                    if (r.Success && r?.Items?.first()) {
                        setData(r.Items.first())
                        return r.Items.first()
                    } else setErrors(['Error in loading certification. Please contact NIET support'])
                })
            } else console.log('fail to save new certificate.')
        })
        return result
    }

    const saveCertification = (certification) => {
        return certificationAdminMgr.save(certification)
    }

    const handleOnEdit = (row) => {
        navigate(`/certifications/administrator/${row.UserId}`)
    }

    const handleOpenPrint = async (row, certData) => {
        setSelectedCertification(row)
        if (!certData) {
            await certificationAdminMgr.getSingleCertificationWithUserId(row.UserId).then(result => {
                if (result.Success) {
                    const orgId = result.Items.first().OrganizationIds.first()
                    const districtId = result.Items.first().DistrictIds.first()
                    const schoolId = result.Items.first().SchoolIds.first()
                    const schoolYear = result.Items.first().SchoolYearIdentifier
                    observationMgr.observationConfigOperation(orgId, districtId, schoolId, schoolYear).then(r => {
                        if (r) {
                            setObservationConfigPrint(r)
                        }
                    })
                }
            })
        } else {
            await certificationAdminMgr.getSingleCertificationWithUserId(row.UserId, certData.SchoolYearIdentifier, 2).then(result => {
                if (result.Success) {
                    const orgId = result.Items.first().OrganizationIds.first()
                    const districtId = result.Items.first().DistrictIds.first()
                    const schoolId = result.Items.first().SchoolIds.first()
                    const schoolYear = result.Items.first().SchoolYearIdentifier
                    observationMgr.observationConfigOperation(orgId, districtId, schoolId, schoolYear).then(r => {
                        if (r) {
                            setObservationConfigPrint(r)
                        } else console.log("No observation ConfigFound")
                    })
                }
            })
        }

        setPrint(true);
        return true;
    }

    const handleClosePrint = () => {
        setSelectedCertification(null)
        setObservationConfigPrint(null);
        setPrint(false)
    }
    const handlePassingCertification = (row) => {
        setOpenManuallyPassCert(true)
        setSelectedCertification(row)
    }
    const resetCertification = async (row) => {
        setOpenResetCertificationDialog(true)
        setSelectedCertification(row)
    }

    const handleOnFilterChange = async (field, value) => {
        const certificationFilter = certificationAdminMgr.Filter;
        const updatedCertificationFilter = { ...certificationFilter };
        updatedCertificationFilter[field] = value !== '' ? value : null;
        updatedCertificationFilter["Identifier"] = 'SchoolAdmin';
        if (field === "groups") {
            const orgs = []
            const districts = []
            const schools = []
            const users = []
            value.map(group => {
                if (group.substring(0, 2) === 'o-') {
                    orgs.push(group.substring(2, group.length))
                } else if (group.substring(0, 2) === 'd-') {
                    districts.push(group.substring(2, group.length))
                } else if (group.substring(0, 2) === 's-') {
                    schools.push(group.substring(2, group.length))
                } else if (group.substring(0, 2) === 'u-') {
                    users.push(group.substring(2, group.length))
                }
            })
            updatedCertificationFilter["orgs"] = orgs
            updatedCertificationFilter["districts"] = districts
            updatedCertificationFilter["users"] = users
            updatedCertificationFilter["schools"] = schools
        }
        await certificationAdminMgr.filterData(updatedCertificationFilter);
    }


    const updateUserPermissions = () => {
        const userProfile = { ...data?.User }
        userProfile.RoleData = [...userProfile.Roles, 'CertifiedSchoolAdmin'].join(',')
        userMgr.ItemManager.runOperation('AzureB2CSaveUserProfile', undefined, userProfile, undefined, undefined, true).then(() => sessionStorage.setItem('profile-update-force-token', true))
    }

    const takeCertification = async () => {
        if (canCertify)
            return await handleOnEdit(canCertify);
        else {
            setErrors(["Current logged in user is not able to be certified."]);
        }

    }

    return (
        <>
            {print && <Print close={handleClosePrint} data={selectedCeretification} observationConfig={observationConfigPrint} certType={1} />}
            <DialogControl
                title={`Are you sure you want to reset certification for ${selectedCeretification?.User?.FullName}? `}
                openDialog={openResetCertificationDialog}
                onCancel={() => {
                    setOpenResetCertificationDialog(false)
                }}
                loadingSave={saving}
                disableXButton={true}
                onOk={async () => {
                    setSaving(true)
                    const userProfile = { ...selectedCeretification?.User }
                    userProfile.RoleData = _.filter(userProfile.RoleData.split(','), x => x !== 'CertifiedSchoolAdmin').join(',')
                    const resetUserCertStatus = userMgr.ItemManager.runOperation('AzureB2CSaveUserProfile', undefined, userProfile, undefined, undefined, true)
                    const resetCert = certificationAdminMgr.resetCertificate(userProfile.Id)
                    await Promise.all([resetUserCertStatus, resetCert]).then(async (r) => {
                        if (_.every(r, x => x.Success === true)) {
                            await handleParamChange();
                            setSaving(false)
                            setOpenResetCertificationDialog(false)
                        }
                    })
                }}
            />
            <DialogControl
                title={`Are you sure you want to manually certify  ${selectedCeretification?.User?.FullName}?`}
                openDialog={openManuallyPassCert}
                onCancel={() => {
                    setOpenManuallyPassCert(false)
                }}
                loadingSave={saving}
                disableXButton={true}
                onOk={async () => {
                    setSaving(true)
                    const copiedCert = { ...selectedCeretification }
                    copiedCert.Status = 9;
                    copiedCert.StartedDate = copiedCert.StartedDate ? copiedCert.StartedDate : moment().utc().toISOString()
                    copiedCert.CompletedDate = copiedCert.CompletedDate ? copiedCert.CompletedDate : moment().utc().toISOString()
                    const userProfile = { ...selectedCeretification?.User }
                    userProfile.RoleData = [...userProfile.Roles, 'CertifiedSchoolAdmin'].join(',')
                    const saveCert = certificationAdminMgr.save(copiedCert)
                    const updateUserProfile = userMgr.ItemManager.runOperation('AzureB2CSaveUserProfile', undefined, userProfile, undefined, undefined, true)
                    await Promise.all([saveCert, updateUserProfile]).then(async (r) => {
                        await handleParamChange()
                        setSaving(false)
                        setOpenManuallyPassCert(false)
                    })
                }}
            />
            <MainLayout hideErrorCloseBtn={manageMode === 'view' && true} errors={errors} className={manageMode === 'view' && !loading ? 'certification-page' : ''}>
                <ScreenWrapper loading={loading} >
                    {(manageMode === 'view') && <CertificationAdminDetails
                        certificationAdminMgr={certificationAdminMgr}
                        observationConfig={observationConfig}
                        data={data}
                        createNewCertificate={createNewCertificate}
                        saveCertification={saveCertification}
                        certificationHistory={certificationHistory}
                        setCertificationHistory={setCertificationHistory}
                        handleOpenPrint={handleOpenPrint}
                        updateUserPermissions={updateUserPermissions}
                    />}
                    {manageMode === 'list' &&
                        <div className='control-box-wrapper'>
                            <div className='control-box-list'>
                                <CertificationAdminFilters certificationAdminMgr={certificationAdminMgr} onFilterChange={handleOnFilterChange} orgMgr={orgMgr} orgs={orgs} />
                                <CertificationAdminList
                                    appUserMgr={appUserMgr}
                                    handlePassingCertification={handlePassingCertification}
                                    certificationAdminMgr={certificationAdminMgr}
                                    filteredItems={certificationAdminMgr?.FilteredData ?? []}
                                    onEdit={handleOnEdit}
                                    onPrint={handleOpenPrint}
                                    resetCertification={resetCertification}
                                />
                            </div>
                            <div className='screen-footer list'>
                                {canCertify && <ButtonControl type={"play"} onClick={takeCertification}>{`${(canCertify?.Status === 5 || canCertify?.Status === 9) ? 'View' : 'Take'} Certification`}</ButtonControl>}
                            </div>
                        </div>
                    }
                </ScreenWrapper>
            </MainLayout>
        </>
    );
}

export default CertificationAdmins;