//TODO_R: determine validation fields (initial stub year, month, day below), see other XXValidateObjects in dir for examples/variations. 
const SchoolYearValidateObject = () => {
    return {
        SchoolYearIdentifier: {
            fieldName: 'SchoolYearIdentifier',
            messageTitle: 'school year identifier',
            isRequired: true,
            dataType: 'INT',
            groupId: 'SCHOOL_YEAR',
            message: 'SchoolYearIdentifier can only contain numbers (e.g. 2023).'
        },
        SchoolYearMonth: {
            fieldName: 'SchoolYearMonth',
            messageTitle: 'school year, starting month',
            isRequired: true,
            dataType: 'INT',
            groupId: 'SCHOOL_YEAR',
            message: 'SchoolYearMonth can only contain numbers (e.g. 6).'
        },
        ClientId: {
            fieldName: 'ClientId',
            messageTitle: 'organization',
            isRequired: true,
            dataType: 'SELECT',
            groupId: 'SCHOOL_YEAR',
            message: 'Organization must be set.',
        },
        SchoolYearDay: {
            fieldName: 'SchoolYearDay',
            messageTitle: 'school year, starting day',
            isRequired: true,
            dataType: 'INT',
            groupId: 'SCHOOL_YEAR',
            message: 'SchoolYearDay can only contain numbers (e.g. 1).'
        },
        ConfigurationDueDate: {
            fieldName: 'ConfigurationDueDate',
            messageTitle: 'configuration due date',
            isRequired: true,
            dataType: 'DATETIME',
            groupId: 'SCHOOL_YEAR',
            message: 'ConfigurationDueDate must be a valid date (e.g. 2023-06-01).',
        },
        ConfigurationLockedDate: {
            fieldName: 'ConfigurationLockedDate',
            messageTitle: 'configuration lock date',
            isRequired: true,
            dataType: 'DATETIME',
            groupId: 'SCHOOL_YEAR',
            message: 'ConfigurationLockedDate must be a valid date (e.g. 2023-06-01).',
        },
        RolloverDate: {
            fieldName: 'RolloverDate',
            messageTitle: 'rollover date',
            isRequired: false,
            dataType: 'DATETIME',
            groupId: 'SCHOOL_YEAR',
            message: 'RolloverDate must be a valid date (e.g. 2023-06-01).',
        }
    }
}

export default SchoolYearValidateObject;