import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _ from 'lodash';

class GeneralSurveyManager extends ItemManager {
    constructor(observationMgr, globalItemState, globalAuthState, globalAppUserState) {
        const userSurveyMapSvc = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.UserSurveyMap, globalAuthState);

        super(ItemServiceFactory.ItemServiceEnum.GeneralSurvey,
            globalAuthState,
            globalAppUserState,
            globalItemState,
            (filter, item) => {
                var hasSearch = item?.GeneralSurvey?.Name?.toLowerCase()?.startsWith(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
                var hasAssignedBy = item?.AssignedByUser?.FullName?.toLowerCase()?.includes(filter?.assignedBy?.toLowerCase()) || (filter?.assignedBy === undefined || filter?.assignedBy === null || filter?.assignedBy === '');
                var hasStatus = (filter?.status == 2 ? item?.UserSurveyMap?.Survey?.SurveyStatusType == 3 : filter?.status == 1 ? item?.UserSurveyMap?.Survey?.SurveyStatusType == 2 || item?.UserSurveyMap?.Survey?.SurveyStatusType == 1 : item?.UserSurveyMap?.Survey?.SurveyStatusType == filter?.status || item?.UserSurveyMap?.Survey?.SurveyStatusType == undefined) || (filter?.status === undefined || filter?.status === null || filter?.status === '' || filter?.status === "UNK");
                var hasAssignedTo = item?.UserSurveyMap?.User?.FullName?.toLowerCase()?.includes(filter?.assignedTo?.toLowerCase()) || (filter?.assignedTo === undefined || filter?.assignedTo === null || filter?.assignedTo === '');
                var hasOrg = !filter?.organizations || filter?.organizations.length === 0 || _.find(filter?.organizations ?? [], treeId => treeId.indexOf("o-") === 0 && treeId.substr(2).toLowerCase() === item?.UserSurveyMap?.Survey?.SurveyedUser?.ClientId?.toLowerCase());                
                var hasSchool = !filter?.organizations || filter?.organizations.length === 0 || _.find(filter?.organizations ?? [], treeId => treeId.indexOf("s-") === 0 && treeId.substr(2).toLowerCase() === item?.UserSurveyMap?.Survey?.SurveyedUser?.SchoolIds?.first()?.toLowerCase());
                var hasDistrict = !filter?.organizations || filter?.organizations.length === 0 || _.find(filter?.organizations ?? [], treeId => treeId.indexOf("d-") === 0 && treeId.substr(2).toLowerCase() === item?.UserSurveyMap?.Survey?.SurveyedUser?.DistrictIds?.first()?.toLowerCase());
                return (hasOrg || hasDistrict || hasSchool) && hasSearch && hasAssignedBy && hasStatus && hasAssignedTo
            },
            async () => (await userSurveyMapSvc.getOperation("GeneralSurveyUser")).first()
        );
        this._surveyMgr = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.Survey, globalAuthState);
        this._observationMgr = observationMgr;
        this._errorUtil = undefined;
        this.setSelectedSurveyData = (value) => { globalItemState.merge({ selectedSurveyData: value }) }
        this._userSurveyMapSvc = userSurveyMapSvc
    }

    get SelectedSurveyData() {
        return this._globalItemState.get({ noproxy: true }).selectedSurveyData;
    }



    async getSurveyAndRubric(surveyId) {
        return this._surveyMgr.getOperation('SurveyAndRubric', surveyId).then(async r => {
            if (r?.first().Items?.first()) {
                return await this._observationMgr?.transformData(r.first().Items.first())
            } else {
                return null
            }
        })
    }



    async saveRating(SurveyRatingDTI) {
        return this._observationMgr.runOperation('SurveyRatingSave', null, SurveyRatingDTI);
    }

    async RetrieveUserGeneralSurvey(id) {
        return await this._userSurveyMapSvc.getOperation("GeneralSurveyUser", id)
    }



    async UpdateUsers(items) {
        const saveUsers = items.reduce((saveBox, item) => {
            if (!item.IsDeleted) {
                saveBox.push(this._userSurveyMapSvc.save(item))
            }
            return saveBox
        }, [])

        const resolvedSaved = await Promise.all(saveUsers);
        const resolvedSaveMap = _.map(resolvedSaved, x => x.first().Items.first())
        const deleteUsers = _.map(items, i => {
            if (i.IsDeleted) {
                this._userSurveyMapSvc.hardDelete(i.Id)
            }
        })
        const resolvedDelete = await Promise.all(deleteUsers)
        if (this._allData) {
            const allData = JSON.parse(JSON.stringify(this._allData))
            const item = _.find(allData, (data) => data.Id === items.first().GeneralSurveyId)
            item.UserGeneralSurveys = resolvedSaveMap ?? item.UserGeneralSurveys
            this._setAllData(allData);
            this.filterData(undefined, allData);
        }
        return resolvedSaved;
    }


    get DefaultItem() {
        return {
            UserGeneralSurveys: [],
        };
    }
}

export default GeneralSurveyManager;