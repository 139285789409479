import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import moment from 'moment';



const NotificationTable = ({ filteredItems, onEdit, onDelete }) => {
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Notification',
            dataProp: 'Title',
            width: '15%',
            canSort: true
        },
        {
            header: 'Message',
            dataProp: 'HtmlValue',
            width: '35%',
            datatype: 'html',
            canSort: false
        },
        {
            header: 'Type',
            dataProp: "NotificationType",
            width: '10%',
            canSort: true
        },
        {
            header: 'From Date',
            dataProp: 'FromDate',
            datatype: 'dateTime',
            width: '15%',
            canSort: true
        },
        {
            header: 'To Date',
            dataProp: "ToDate",
            datatype: 'dateTime',
            width: '15%',
            canSort: true
        },
        {
            header: 'Created Date',
            dataProp: "CreatedDate",
            width: '10%',
            datatype: 'date',
            canSort: true
        },
        {
            header: 'Active?',
            dataProp: 'IsActive',
            width: '10%',
            canSort: true
        }
    ]);

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (c) => {
                let type = '';
                switch (c.NotificationType) {
                    case 0:
                        type = 'Message';
                        break;
                    case 1:
                        type = 'Important';
                        break;
                    case 2:
                        type = 'Urgent';
                        break;
                    default:
                        type = 'UNK';
                        break;
                }
                newRows.push({
                    ...c,
                    NotificationType: type,
                    CreatedDate: c.CreatedDate
                });
            });
            setRows(newRows);
        }
    }, [filteredItems]);

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                permission='Notification'
                useIntegratedPaging={['10', '25', '50', '100']}
                onEdit={(row) => onEdit(row)}
                onDelete={(row) => onDelete(row)}
            />
        </div>
    </>
}

export default NotificationTable;