import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _ from 'lodash';
import { config } from '@fortawesome/fontawesome-svg-core';
import { data } from 'jquery';

class FeedbackManager extends ItemManager {
  constructor(globalfeedbackState, globalAuthState, globalAppUserState) {
    super(ItemServiceFactory.ItemServiceEnum.Feedback, globalAuthState, globalAppUserState, globalfeedbackState,
      (filter, item) => {
        var hasName = item.Name?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
        var hasEmail = item.Email?.toLowerCase()?.startsWith(filter?.email?.toLowerCase()) || (filter?.email === undefined || filter?.email === null || filter?.email === '');
        return hasName && hasEmail;
      });
    this._errorUtil = undefined;
    this._globalfeedbackState = globalfeedbackState;
    this._setShowContactUs = (value) => this._globalfeedbackState.merge({ showContactUs: value });
  }


  get DefaultFeedback() {
    const userProfile = this._globalAppUserState.get({ noproxy: true }).userProfile;
    if (userProfile) {
      let inst = "";
      if (userProfile?.Districts?.length > 0)
        inst = userProfile?.Districts?.first()?.Name;

      if (userProfile?.Schools?.length > 0)
        inst = inst + ", " + userProfile?.Schools?.first()?.Name;

      return {
        Name: userProfile.FullName,
        Email: userProfile.Email,
        Institution: inst,
        SendEmail: true,
        Feedback: undefined,
        Request: undefined
      }
    }
    else
      return {};
  }

  get ShowContactUs() {
    return this.GetGlobalState().showContactUs;
  }

  SetShowContactUs(show) {
    if (show == undefined)
      show = true;
    this._setShowContactUs(show);
  }
}

export default FeedbackManager;