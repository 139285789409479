import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef } from 'react';
import reactStringReplace from 'react-string-replace';
import useAutosizeTextArea from '../../hooks/useAutosizeTextArea';

const TextAreaControl = ({ 
    field,
    value,
    onChange,
    onBlur,
    maxRows,
    className,
    disabled,
    loading,
    hasErrors,
    autoGrowHeight,
    useHighlights,
    highlightedParts,
    highlightMenuHandler,
    props
}) => {

    const [highlightedTxt, sethightlightedTxt] = useState('');
    const [showHighlightMenu, setShowHighlightMenu] = useState(false);

    const textAreaRef = useRef(null);
    useAutosizeTextArea(textAreaRef, value);



    const mouseDownHandler = (mouseDownEvent) => {

        function onMouseMove(mouseMoveEvent) {
        }

        function onMouseUp() {
            //document.body.removeEventListener("mousemove", onMouseMove);
            sethightlightedTxt(window.getSelection().toString());
        }

        //document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onMouseUp, { once: true });
    }


    const applyHighlights = () => {
        let annotatedTxt = value;
        Object.keys(highlightedParts).forEach(part => {
            annotatedTxt = (
                reactStringReplace(annotatedTxt, highlightedParts[part], (match, i) => (
                    // <span className='highlights'>{match}<span className={'hide-text'}>`[${part}]`</span></span>
                    <span className='highlights'>{match}</span>
                ))
            )
        });
        return annotatedTxt;
    }

    const applySubscriptOnTextarea = () => {

        let txtWithSubscript = value;

        // Object.keys(highlightedParts).forEach(part => {
        //     console.log('part: ', part);
        //     console.log('highlightedParts[part]: ', highlightedParts[part]);
        //     txtWithSubscript = txtWithSubscript.replace(highlightedParts[part], highlightedParts[part] + `  <${part}>`)
        // })


        return txtWithSubscript;

        //return value;
    }

    const handleOnChange = (context, field) => {
        onChange(context, field)
    }

    if (autoGrowHeight) {
        if (useHighlights) {
            return (
                <div className={'edit-container'}>
                    <div className={'backdrop'}>
                        {/* {value}
                        <div className={'highlights'}></div> */}
                        {applyHighlights()}
                    </div>
                    <textarea
                        id={"field_txa_" + field.FieldName}
                        multiline='multiline'
                        className={hasErrors ? `input-error ${className ?? ''}` : className}
                        placeholder={field.PlaceHolder}
                        onBlur={(context) => onBlur(context, field)}
                        value={applySubscriptOnTextarea()}
                        ref={textAreaRef}
                        onChange={(context) => handleOnChange(context, field)}
                        onMouseDown={mouseDownHandler}
                        {...(disabled ? {disabled} : {})}
                        {...props} />
                    {
                        highlightedTxt?.length > 0 ?
                            <FontAwesomeIcon icon={faTag} className={'tag-icon'} onClick={() => { setShowHighlightMenu(true) }} />
                            : null
                    }
                    {showHighlightMenu && highlightMenuHandler ? highlightMenuHandler() : null}
                </div>
            );
        }
        else {
            return (
                <textarea
                    id={"field_txa_" + field.FieldName}
                    multiline='multiline'
                    className={hasErrors ? `input-error ${className ?? ''}` : className}
                    placeholder={field.PlaceHolder}
                    onBlur={(context) => onBlur(context, field)}
                    value={value}
                    ref={textAreaRef}
                    onChange={(context) => onChange(context, field)}
                    {...(disabled ? {disabled} : {})}
                    {...props} />
            );
        }
    } else {
        return (
            <textarea
                style={{}}
                id={"field_txa_" + field.FieldName}
                multiline='multiline'
                className={hasErrors ? `input-error ${className ?? ''}` : className}
                placeholder={field.PlaceHolder}
                onBlur={(context) => onBlur(context, field)}
                value={value}
                onChange={(context) => onChange(context, field)}
                {...(disabled ? {disabled} : {})}
                {...props} />
        );
    }
}

export default TextAreaControl;