class LocalStorageUtility {
    constructor(){
        this._localStorage = window.localStorage;
    }

    get data(){
        return this._localStorage;
    }

    removeAllContaining(containingKey){
        for(var i=0;i<this._localStorage.length;++i){
            if(this._localStorage.key(i).indexOf(containingKey) > -1)
            this._localStorage.removeItem(this._localStorage.key(i));
        }
    }
}

export default LocalStorageUtility;