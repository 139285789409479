import { useEffect, useState } from 'react';
import './details.scss';
import { InputField } from "../../../../components/controls/InputField";
import { useFeedbackManager } from "../../../../hooks/useManagers";
import ButtonControl from "../../../../components/controls/ButtonControl";
import _ from 'lodash';
import { FormatUTCDateTime } from '../../../../utilities/DateFormater';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import ContentSyncRawDataTable from './rawDataTable';
import { Toggle } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter as faFilter } from '@fortawesome/free-solid-svg-icons'

const ContentSyncDetails = ({ contentSync, onCancel, onLoadingError, onLoading }) => {

    const [dataMode, setDataMode] = useState('grid');
    const [filteredData, setFilteredData] = useState([]);
    const [filter, setFilter] = useState({ row: null });

    useEffect(() => {
        if (contentSync?.FileJson) {
            setFilteredData(JSON.parse(contentSync?.FileJson))
            filterData();
        }
    }, [contentSync?.FileJson]);

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    const handleOnToggleDataView = (val) => {
        setDataMode(val ? 'grid' : 'data');
    }

    const filterData = (field, value) => {
        const updatedFilter = { ...filter };
        if (field === 'row') {
            const match = value.match(/#(\d+)/);
            if (match !== null) {
                const number = parseInt(match[1]);
                updatedFilter.row = number;
            }

        }

        setFilter(updatedFilter);
    }

    useEffect(() => {
        if (filter) {
            const data = JSON.parse(contentSync?.FileJson);
            const fData = _.filter(data, (rowData) => {
                const hasRow = filter?.row == rowData.row || filter?.row === undefined || filter?.row === null;
                return hasRow;
            });
            setFilteredData(fData);
        }
    }, [filter]);

    return <div className='contentSync-details control-box-wrapper'>
        {contentSync && <>
            <div className='control-box box-three-column'>
                <InputField type="label" title="Loaded By User" value={contentSync?.LoadedByUser.FullName} fieldName="Name" />
                <InputField type="label" title="Status" value={contentSync?.Status} fieldName="Status" />
                <InputField type="label" title="Start Date & Time" value={FormatUTCDateTime(contentSync?.StartDateTime)} fieldName="StartDateTime" />
                <InputField type="label" title="End Date & Time" value={FormatUTCDateTime(contentSync?.EndDateTime)} fieldName="EndDateTime" />
                <InputField type="label" title="Record Count" value={contentSync?.RecordCount} fieldName="RecordCount" />
                <InputField type="label" title="CSV File Name" value={contentSync?.FileName} fieldName="FileName" />
                {/*(contentSync?.FailedRows && contentSync?.FailedRows !== '') && <InputField type="label" title="Rows that contained failures" value={contentSync?.FailedRows} fieldName="FailedRows" />*/}
                {(contentSync?.Messages?.length > 0) && <><InputField disableError={true} type="label" title="Messages" subTitle={`${contentSync?.Messages?.length ?? 0} messages found during content sync:`} fieldName="Messages" />
                    <ul className='content-sync-messages'>
                        {_.map(contentSync?.Messages, msg => <li>
                            <div className='message'>{msg}&nbsp;{msg.toLowerCase().includes('row #') && <div title={`Filter to Row`} onClick={() => filterData('row', msg)}>&nbsp;<FontAwesomeIcon className={'fa-filter'} icon={faFilter} /></div>}</div>
                        </li>)
                        }
                    </ul>
                </>}
                {contentSync?.ErrorMessage && <InputField type="label" title="Error Message" value={contentSync?.ErrorMessage} fieldName="ErrorMessage" />}
                <div className='contentSync-btn-clear'><ButtonControl  type="cancel" className={'clear-btn'} onClick={() => setFilter({ row: null })} >Clear</ButtonControl></div>
            </div>
            <div className='control-box box-twothirds-column'>
                <div className='contentSync-data-header'>
                    <h4>CSV File Data</h4>
                    <div><span className='toggle-text'>Data View</span> <Toggle defaultChecked onChange={((val) => handleOnToggleDataView(val))} /> <span className='toggle-text'>Table View</span></div>
                </div>
                {dataMode === 'data' ? <JSONPretty id="json-pretty" data={JSON.stringify(filteredData)}></JSONPretty> :
                    <ContentSyncRawDataTable data={filteredData} />}
            </div>
        </>
        }
        <div className='screen-footer screen-footer-right'>
            <div className='btn-wrapper-right screen-footer__btn'><ButtonControl type={'cancel'} onClick={handleOnCancel}>Back</ButtonControl></div>
        </div>
    </div>
}

export default ContentSyncDetails;


