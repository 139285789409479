import { Public } from '@mui/icons-material';
import React from 'react';
import Footer from './Footer';
import PublicHeader from './PublicHeader';
import './layouts.scss';
import ContactUsDialog from './ContactUsDialog';

const PublicLayout = ({ children, headerHeight }) => {

    const height = headerHeight ?? '5rem';
    return (<>
        <ContactUsDialog />
        <PublicHeader />
        <div className='screen'>
            <div className='screen__content'>
                {children}
            </div>
        </div>
        <Footer />
    </>
    );
}

export default PublicLayout;


