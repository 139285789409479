import { React, useEffect, useState } from 'react';
import { Panel } from 'rsuite';
import { InputField } from '../../../../../components/controls/InputField';
import { LabelControl } from '../../../../../components/controls';
import './../styles/schoolAchievementPayRatios.scss';

const SchoolAchievementPayRatios = ({data, setData, selectedPayoutConfigId, settings, onChange }) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [payRatioData, setPayRatioData] = useState();
    const [shownRows, setShownRows] = useState(3); 
    const [payoutConfigId, setPayoutConfigId] = useState();

    useEffect(() => {
        if (data && selectedPayoutConfigId) {
            setPayoutConfigId(selectedPayoutConfigId);

            let sortedData = sortPayRatioData(data);
            setPayRatioData(sortedData);
        }
    }, [data])

    const sortPayRatioData = (dataSet) => {
        //sort the array by score 
        const groupedData = dataSet.reduce((groups, item) => {
            const score = item.Score;
            if (!groups[score]) {
              groups[score] = [];
            }
            groups[score].push(item);
            return groups;
        }, {});

        const sortedGroupedData = Object.entries(groupedData).sort(([scoreA], [scoreB]) => {
            const parsedScoreA = parseFloat(scoreA);
            const parsedScoreB = parseFloat(scoreB);
          
            if (parsedScoreA < parsedScoreB) {
              return -1;
            } else if (parsedScoreA > parsedScoreB) {
              return 1;
            } else {
              return 0;
            }
        });
          
        const sortedDataWithinGroups = sortedGroupedData.reduce((result, [score, group]) => {
            result[score] = group;
            return result;
        }, {});

        const sortedDataArray = Object.entries(sortedDataWithinGroups)
            .sort(([scoreA], [scoreB]) => parseFloat(scoreA) - parseFloat(scoreB))
            .map(([score, group]) => ({ score, group })
        );

        setShownRows(sortedDataArray.length);
        return sortedDataArray;
    } 

    const handleRatioChange = (score, value) => {
        const updatedData = [ ...data ];
        let updatedScore = updatedData.find(x => x.Score === score);

        updatedScore["PercentPaid"] = value;

        setData(updatedData);
    }

    const handleAddRow = () => {
        if (shownRows < 7) {
            setShownRows(shownRows + 1);
        
            const newScore = 
            {
                Score: (payRatioData.length + 1),
                PercentPaid: 0,
                PayoutConfigurationId: payoutConfigId
            } 

            const updatedData = [...data];
            updatedData.push(newScore);

            setData(updatedData);
        }
    };

    const handleDeleteRow = () => {
        const dataCopy = [ ...data ];
        const lastEntry = payRatioData[payRatioData.length - 1];

        const updatedData = dataCopy.filter(obj => obj.Score.toString() !== lastEntry.score);
        setData(updatedData);
    };

    return (
        <>
        <Panel className='school-panel' header="School Achievement">
        <div className='school-achievement-pay-ratios-table'>
        {payRatioData ? (
            <table className="school-achievement-pay-ratios-table">
                <thead>
                    <tr>
                        <th>Score</th>
                        <th>Percent Paid</th>
                    </tr>
                </thead>
                <tbody>
                {payRatioData.map(({ score, group }) => (
                    <tr key={score}>
                        <td className='row-header'>{score}</td>
                        {group.map((entry, index) => (
                            <td key={index}>
                                <InputField
                                    value={entry.PercentPaid}
                                    placeholder={'0'}
                                    onChange={(value) => handleRatioChange(entry.Score, value)}
                                    type="number"
                                    disableError={true} />
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        ) : (
            <div>Loading...</div>
        )}
        <div className='btn-container'>
            {shownRows < 7 && (
                <div className="add-row-btn-container">
                    <button className="add-row-btn" onClick={handleAddRow}>
                    Add Row
                    </button>
                </div>
            )}
            {shownRows > 3 && (
                <div className="delete-row-btn-container">
                    <button className="delete-row-btn" onClick={handleDeleteRow}>
                    Delete Row
                    </button>
                </div>
            )}
        </div>
        </div>
        </Panel>
        </>
    );
}

export default SchoolAchievementPayRatios;