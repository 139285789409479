import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import globalAppUserStore from '../../stores/globalAppUserStore';
import { useHookstate } from '@hookstate/core';
import AppUserManager from '../../managers/AppUserManager';
import * as gaMgr from './../../managers/GoogleAnalyticsManager';
import { EventTypeEnum } from './../../managers/GoogleAnalyticsManager';
import { useAuthState } from '../../hooks/useAuth';
import globalAuthStateStore from '../../stores/globalAuthStateStore';
import InitLayout from '../layout/InitLayout';
import Loading from '../Loading';
import { useAppUserManager } from '../../hooks/useManagers';

export default function Logout() {
    const { instance } = useMsal();
    const appUserMgr = useAppUserManager();
    useEffect(() => {
        try {
            gaMgr.AddEvent(EventTypeEnum.Auth_Logout, null, null, appUserMgr);

            instance.logout().then(response => {
                console.log("Auth Logout Success, cleaning up...");
                appUserMgr.cleanup();
                console.log("logout Response: " + response.json(), 'background: #222; color: #bada55');
                document.location.href = "/";
            });
        }
        catch (err) {
            console.error("An error occurred on logout.");
            console.error(err);
        }
    }, []);


    return <InitLayout><div className='init__content'><Loading size='5rem' /><h5>Logging out...</h5></div></InitLayout>;
}