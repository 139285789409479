import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _ from 'lodash';

class GeneralSurveyConfigManager extends ItemManager {
    constructor(surveyTemplateMgr, globalItemState, globalAuthState, globalAppUserState) {
        super(ItemServiceFactory.ItemServiceEnum.GeneralSurvey, globalAuthState, globalAppUserState, globalItemState,
            (filter, item) => {
                var hasSearch = item?.Name?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
                var IsActive = item?.IsActive === filter?.IsActive || (filter?.IsActive === undefined || filter?.IsActive === null || filter?.IsActive === 'UNK')
                return hasSearch && IsActive
            });
        this._userSurveyMapSvc = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.UserSurveyMap, globalAuthState);
        this._errorUtil = undefined;
        this._surveyTemplateMgr = surveyTemplateMgr;
    }

    get SurveyTemplateFormatted() {
        return this._surveyTemplateMgr.list(this.buildJSONObjFilter('x => x.IsActive == true && x.IsDeleted == false && x.SurveyType == 2', {})).then(r => {
            return _.map(r.Items, survey => {
                return {
                    Text: survey.Title, Value: survey.Id
                }
            })
        })
    }

    async sendEmailToUsers(id) {
        return this._userSurveyMapSvc.getOperation("GeneralSurveyReadyEmail", id).then(r => console.log(r))
    }

    async UpdateUsers(items) {
        const saveUsers = items.reduce((saveBox, item) => {
            if (!item.IsDeleted) {
                saveBox.push(this._userSurveyMapSvc.save(item))
            }
            return saveBox
        }, [])

        const resolvedSaved = await Promise.all(saveUsers);
        const resolvedSaveMap = _.map(resolvedSaved, x => x.first().Items.first())
        const deleteUsers = _.map(items, i => {
            if (i.IsDeleted) {
                this._userSurveyMapSvc.hardDelete(i.Id)
            }
        })
        const resolvedDelete = await Promise.all(deleteUsers)
        if (this._allData && items.length >= 1) {
            const allData = JSON.parse(JSON.stringify(this._allData))
            const item = _.find(allData, (data) => data.Id === items.first().GeneralSurveyId)
            item.UserGeneralSurveys = resolvedSaveMap ?? item.UserGeneralSurveys
            this._setAllData(allData);
            this.filterData(undefined, allData);
        }
        return resolvedSaved;
    }


    get DefaultItem() {
        return {
            UserGeneralSurveys: [],
        };
    }
}

export default GeneralSurveyConfigManager;