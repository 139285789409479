import React, { useState } from 'react';
import '../../../admin/DomainIndicatorTableControl.scss';
import { useEffect } from "react";
import { asyncForEach } from "../../../contentLibrary/utils/jsUtil";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown, faTrashCan } from '@fortawesome/free-solid-svg-icons';

const IndicatorRowTable = ({ data, onSaveInner, onDeleteRow }) => {
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Indicator',
            dataProp: "indicator",
            width: '60'
        },
        {
            header: 'Order',
            dataProp: "sequence",
            width: '20'
        }
    ])

    useEffect(() => {
        let newRows = [];
        if (data) {
            asyncForEach(data.indicators, (i) => {
                newRows.push({
                    indicator: i.RubricIndicator.Name,
                    sequence: i.Sequence,
                    expandRow: data.expandRow,
                    domainId: i.RubricDomainId
                });
            }).then(() => {
                sortRows(newRows);
            });
        }
    }, [data]);

    const sortRows = (rows) => {
        var currentRowSet = [...rows];

        currentRowSet = currentRowSet.sort((a, b) =>
            (a.sequence > b.sequence) ? 1 :
                (a.sequence < b.sequence) ? -1 : 0
        );
        setRows(currentRowSet);
    }

    const handleOnOrderChange = (sequence, isMovedUp) => {
        //Sort Data table, re-render table rows 
        let currentRowSet = [...rows];
        let index = currentRowSet.findIndex(r => r.sequence === sequence);

        if (index < 0 || index >= currentRowSet.length || (index == 0 && isMovedUp) || (index + 1 >= currentRowSet.length && !isMovedUp)) {
            // Can't move the -1st item or any item outside range
            return;
        }

        if (isMovedUp) {
            [currentRowSet[index - 1], currentRowSet[index]] = [currentRowSet[index], currentRowSet[index - 1]];
        }
        else {
            [currentRowSet[index + 1], currentRowSet[index]] = [currentRowSet[index], currentRowSet[index + 1]];
        }
        setRows(currentRowSet);

        onSaveInner(currentRowSet);
    };

    const handleOnDeleteRow = (row) => {
        let currentRowSet = [...rows];
        let index = currentRowSet.findIndex(r => r.sequence === row.sequence);

        currentRowSet.splice(index, 1);
        setRows(currentRowSet);

        onDeleteRow(row);
    };

    const handleCheckDisableAction = (row, action) => {
        if (action === 'delete' && data.rubricStatus === 2) {
            return 'disableAction';
        }
    }

    const renderHeader = () => {
        let jsxCols = columns.reduce((r, col) => {
            r.push(
                <div key={col.header} className={`col header-indicator`} style={{ width: col.width + '%' }}>
                    {col.header}
                </div>
            )
            return r;
        }, []);

        jsxCols.push(
            <div key={'indicator-actions'} className={'col header-indicator'} style={{ width: '20%', cursor: 'unset' }}>
                Actions
            </div>
        )

        return <div className={'header-row-indicator'}>{jsxCols}</div>
    };

    const renderRows = () => {
        let dataRows = [];

        for (let i = 0; i < rows.length; i++) {
            let dataRecord = rows[i];
            dataRows.push(
                <div key={`indicatorTable_${i}`} className={`data-row-indicator`}>
                    {columns.reduce((r, col) => {

                        let dataRowValue = dataRecord[col.dataProp];
                        let isOrder = col.dataProp !== null ? col.dataProp === 'sequence' ? true : false : false; //displays arrow up/down icons

                        r.push(
                            <>
                                <div key={`${col.header}_${dataRowValue}`} style={{ width: `${col.width.length > 0 ? col.width : 100 / (columns.length + 1)}%` }}
                                    className={`col`}>
                                    {isOrder ? (
                                        <div style={{ paddingLeft: '1.8em' }}>
                                            <FontAwesomeIcon style={{ fontSize: 'large' }} className={`order-by-indicator ${i === 0 ? 'disableAction' : ''}`} icon={faCaretUp} onClick={() => { handleOnOrderChange(dataRecord.sequence, true) }} />
                                            <FontAwesomeIcon style={{ fontSize: 'large' }} className={`order-by-indicator ${(i === rows.length - 1) ? 'disableAction' : ''}`} icon={faCaretDown} onClick={() => { handleOnOrderChange(dataRecord.sequence, false) }} />
                                        </div>
                                    ) :
                                        dataRowValue
                                    }
                                </div>
                            </>
                        )
                        return r;
                    }, [])}
                    <div key={`table_action_${i}`} className={'col actions'} style={{ width: '20%' }}>
                        <FontAwesomeIcon className={`btn-icon-fa ${handleCheckDisableAction(dataRecord, 'delete')}`} icon={faTrashCan} onClick={() => handleOnDeleteRow(dataRecord)} />
                    </div>
                </div>
            )
        }

        let rowAreaStyle = {};
        return (
            <div className={'row-area'} style={rowAreaStyle}>
                {dataRows}
            </div>
        );
    };

    return (
        <div className='table-wrapper-indicator'>
            {renderHeader()}
            {renderRows()}
        </div>
    )
}

export default IndicatorRowTable;