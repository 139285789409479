import { useState } from "react";
import ButtonControl from "../../../../../components/controls/ButtonControl";
import FileUploaderControl from "../../../../../components/controls/FileUploaderControl";
import { InputField } from "../../../../../components/controls/InputField";
import CertificationTable from "../CertificationTable/CertificationTable";
import '../details.scss';
import { InputPicker, TagPicker } from 'rsuite';
import SystemTypes from '../../../../../SystemTypes';
import _ from 'lodash';
import { CheckboxControl, DatePickerControl, SelectListControl } from '../../../../../components/controls';
import { useEffect } from 'react';
import Loading from '../../../../../components/Loading';
import MetricsComponent from "./MetricsComponent";
// replace * inside field with name
const getFieldName = (name, field) => {
  return field?.replace("*", name);
};

const getValue = (obj, name, field) => {
  const value = field?.replace("*", name);
  return obj?.[value];
};

const getPositions = (min, max, defaultUserPositionTypes) => {
  const userPositionTypes = defaultUserPositionTypes ?? SystemTypes.UserPositionType
  return Object.keys(userPositionTypes).filter(x => userPositionTypes[x] >= min && userPositionTypes[x] <= max).map(y => {
    return {
      label: y,
      value: userPositionTypes[y]
    }
  })
}

export const SaveObservationQuestions = (names, observationConfigs) => {
  if (observationConfigs) {
    names?.forEach((name) => {
      const value = getValue(observationConfigs, name, "Certification*QuestionIds");
      observationConfigs[getFieldName(name, "Certification*QuestionIdsData")] = value ? value.toString() : null;
    });
  }
  return observationConfigs;
}

export const TableComponent = ({
  updateObservationConfigs,
  observationConfig,
  addFilter,
  defaultFilter,
  ids,
  name,
  certificationQuestions
}) => {
  return (
    <CertificationTable
      name={name}
      handleIds={(ids) => {
        if (ids) {
          if (ids.length > 0) {
            updateObservationConfigs(ids, getFieldName(name, "Certification*QuestionIds"))
          } else {
            updateObservationConfigs(null, getFieldName(name, "Certification*QuestionIds"))
          }
        }
      }}
      ids={ids}
      addFilter={addFilter}
      certificationQuestions={certificationQuestions}
      defaultFilter={defaultFilter}
    />
  );
};

const SettingsComponent = ({
  downloadMgr,
  name,
  observationConfig,
  updateObservationConfigs,
  forceValidate,
  showCertPositions,
  formattedTeacherCertificationRubrics,
  formattedAdminCertificationRubrics,
  defaultUserPositionTypes,
  orgTreeUsers
}) => {
  const [fileLoading, setFileLoading] = useState(false);
  const [formattedUserEmailMap, setFormattedUserEmailMap] = useState([])

  useEffect(() => {
    //Filter out emails not in system.
    const outsideEmails = _.map(_.filter(getValue(observationConfig, name, "Certification*Emails"), x => {
      if (!_.find(orgTreeUsers, y => y.Email === x)) {
        return true
      }
    }), z => {
      return {
        label: z,
        value: z
      }
    })
    setFormattedUserEmailMap(outsideEmails.concat(_.map(orgTreeUsers, x => {
      return {
        label: x.Name,
        value: x.Email
      }
    })))
  }, [orgTreeUsers])

  const handleResetFile = (name) => {
    //Clears fires a confirm dialog and clears the file.
    sessionStorage.removeItem(`FileUpload-logo-question-${name}-certification`);
    updateObservationConfigs(null, `CertificationLog${name}FileId`);
  };

  const handleDownloadFile = async () => {
    const fileId = getValue(observationConfig, name, "CertificationLog*File")?.Id;
    const fileName = `${getValue(observationConfig, name, "CertificationLog*File")?.DisplayFileName}.${getValue(observationConfig, name, "CertificationLog*File")?.FileExtention
      }`;
    await downloadMgr.getFile(fileId, fileName);
  };
  const handleEmails = (value, event) => {
    const emailRgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (event.code === 'Enter' && event.target.value.match(emailRgx)) {
      const spreadEmailArray = getValue(observationConfig, name, "Certification*Emails") ? [...getValue(observationConfig, name, "Certification*Emails")] : []
      updateObservationConfigs([...spreadEmailArray, event.target.value], getFieldName(name, 'Certification*Emails'))
      setFormattedUserEmailMap([...formattedUserEmailMap, {
        label: event.target.value,
        value: event.target.value
      }])
    } else {
      updateObservationConfigs(value, getFieldName(name, 'Certification*Emails'))
    }
  }
  return (
    <div className='admin-cert-config-wrapper'>
      {/* TODO create validation object for organization */}
      <div className="admin-cert-config-left-col">

        {
          name === "Teacher" && <>
            {<InputField subTitle={"Rubric"}
              value={observationConfig?.CertificationRubricId}
              fieldName={"CertificationRubricId"}
              onChange={(value, fieldName) => updateObservationConfigs(value, fieldName)}>
              <SelectListControl textValuePairs={formattedTeacherCertificationRubrics} hidePleaseSelect={false} />
            </InputField>}
            <InputField
              forceValidate={forceValidate}
              subTitle={'Part I: Number of ratings allowed off by +2'}
              fieldName={getFieldName(name, "CertificationRatingOffBy2*")}
              value={getValue(observationConfig, name, "CertificationRatingOffBy2*")}
              onChange={updateObservationConfigs}
            />
            <InputField
              forceValidate={forceValidate}
              subTitle={'Part I: Number of ratings allowed off by +3'}
              fieldName={getFieldName(name, "CertificationRatingOffBy3*")}
              value={getValue(observationConfig, name, "CertificationRatingOffBy3*")}
              onChange={updateObservationConfigs}
            />
          </>
        }
        <InputField
          forceValidate={forceValidate}
          subTitle={(name === "Teacher" ? "Part II: " : "") + "Total Number of Questions"}
          fieldName={getFieldName(name, "NumberOfQuestions*Cert")}
          value={getValue(observationConfig, name, "NumberOfQuestions*Cert")}
          onChange={updateObservationConfigs}
        />
        <InputField
          forceValidate={forceValidate}
          subTitle={(name === "Teacher" ? "Part II: " : "") + "Total Correct Questions Needed to Pass"}
          fieldName={getFieldName(name, "NumberToPass*Cert")}
          value={getValue(observationConfig, name, "NumberToPass*Cert")}
          onChange={updateObservationConfigs}
        />
        <InputField
          forceValidate={forceValidate}
          subTitle="Lockout Period (Days)"
          fieldName={getFieldName(name, "DaysUntillRetry*Cert")}
          value={getValue(observationConfig, name, "DaysUntillRetry*Cert")}
          onChange={updateObservationConfigs}
        />
        {
          name === "Teacher" && <InputField
            forceValidate={forceValidate}
            subTitle="Part I: Maxiumum Attempts Part 1"
            fieldName={"CertificationRetryAttemptsTeacherPart1"}
            value={observationConfig?.CertificationRetryAttemptsTeacherPart1}
            onChange={updateObservationConfigs}
          />
        }
        <InputField
          forceValidate={forceValidate}
          subTitle={(name === "Teacher" ? "Part II: " : "") + `Maximum Attempts` + (name === "Teacher" ? " Part 2" : "")}
          fieldName={getFieldName(name, "CertificationRetryAttempts*")}
          value={getValue(observationConfig, name, "CertificationRetryAttempts*")}
          onChange={updateObservationConfigs}
        />
        {showCertPositions && <InputField
          subTitle="Certifiable Positions"
        >
          <TagPicker
            key={name}
            className='tag-picker'
            data={getPositions(1, 99, defaultUserPositionTypes)}
            defaultValue={getValue(observationConfig, name, "CertificationUserPositions*")}
            onChange={(value) => updateObservationConfigs(value, `CertificationUserPositions${name}`)}
            block
            preventOverflow
          />
        </InputField>
        }

        <InputField forceValidate={forceValidate} subTitle="Certifications Email Recipient">
          <TagPicker
            key={name}
            data={formattedUserEmailMap}
            defaultValue={getValue(observationConfig, name, "Certification*Emails")}
            valueKey={'value'}
            onChange={handleEmails}
          />
        </InputField>
      </div>
      <div className="admin-cert-config-right-col">
        <InputField
          forceValidate={forceValidate}
          subTitle="Name of Certification"
          fieldName={getFieldName(name, "CertificationName*")}
          value={getValue(observationConfig, name, "CertificationName*")}
          onChange={updateObservationConfigs}
        />
        <InputField
          forceValidate={forceValidate}
          subTitle="Certification Title"
          fieldName={getFieldName(name, "CertificationTitle*")}
          value={getValue(observationConfig, name, "CertificationTitle*")}
          onChange={updateObservationConfigs}
        />
        <InputField
          title="Certification Logo"
          forceValidate={forceValidate}
          value={getValue(observationConfig, name, "CertificationLog*FileId")}
          onChange={(value) =>
            updateObservationConfigs(
              value,
              getFieldName(name, "CertificationLog*FileId")
            )
          }
          fieldName={getFieldName(name, "CertificationLog*FileId")}
          subTitle={
            "Drag into or click the box below to upload the certification logo."
          }
        >

          <FileUploaderControl
            canUploadAnyFile={true}
            controlId={`logo-question-${name}-certification`}
            value={getValue(observationConfig, name, "CertificationLog*FileId")}
            fileUrl={getValue(observationConfig, name, "CertificationLog*File")?.FileUrl}
            overrideMaxWidth={350}
            widthRes={1920}
            heightRes={1080}
            maxFileSize={1024 * 1024 * 99}
            onFileLoading={(val) => setFileLoading(val)}
          />
        </InputField>
        {!(
          getValue(observationConfig, name, "CertificationLog*FileId") === null ||
          getValue(observationConfig, name, "CertificationLog*FileId") === undefined
        ) && (
            <div className="reset-file-btn">
              <ButtonControl onClick={() => handleResetFile(name)}>
                Reset File
              </ButtonControl>
              {getValue(observationConfig, name, "CertificationLog*File")?.FileUrl && (
                <ButtonControl
                  type={"okay"}
                  loading={fileLoading}
                  onClick={() => handleDownloadFile()}
                >
                  Download
                </ButtonControl>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

const DisplaySettings = ({ observationConfig, updateUserPositionAlternativeMap }) => {
  return (
    <div className="observation-component">
      <div className="config-wrapper display-config-wrapper">
        {
          _.map(getPositions(1, 100), (userPosition, index) => {
            return <div className="user-position-row" key={index}>
              <InputField subTitle={userPosition.label}
                value={_.find(observationConfig?.UserPositionAlternativeMap, x => x.UserPosition === userPosition.value)?.Name}
                fieldName={userPosition.value}
                onChange={(value, fieldName) => updateUserPositionAlternativeMap(value, fieldName)}
              >
              </InputField>
            </div>
          })
        }
      </div>
    </div>
  )
}

const UserPositionRubricMap = ({
  name,
  observationConfig,
  updateUserPositionRubricMap,
  formattedTeacherObservationRubrics,
  formattedAdminObservationRubrics,
  updateObservationConfigs,
  adminSurveyList,
  teacherSurveyList,
  updateUserPositionSurveyTemplateMap,
  defaultUserPositionTypes
}) => {
  return <div className='observation-component'>
    <div className="config-wrapper">
      {
        _.map(name === 'Teacher' ? getPositions(40, 50, defaultUserPositionTypes) : getPositions(10, 30, defaultUserPositionTypes), (userPosition, index) => {
          return <div className="user-position-row" key={index}>
            <InputField subTitle={userPosition?.label.replace(/([A-Z])/g, ' $1').trim() + " Rubric"} value={_.find(observationConfig?.UserPositionRubricMap, x => x.UserPosition === userPosition.value && x.RubricType === 1)?.RubricId} fieldName={userPosition.value} onChange={(value, fieldName) => updateUserPositionRubricMap(value, fieldName, 1)}>
              {(name === 'Teacher' && formattedTeacherObservationRubrics || name === 'Admin' && formattedAdminObservationRubrics) ?
                <SelectListControl textValuePairs={name === 'Teacher' ? formattedTeacherObservationRubrics : formattedAdminObservationRubrics} hidePleaseSelect={true} />
                :
                <div className="select-loading-wrapper">
                  <Loading />
                </div>}
            </InputField>
          </div>
        })
      }
      <InputField subTitle="Amount of Evaluations" value={getValue(observationConfig, name, "NumberOf*Evals")} onChange={updateObservationConfigs} fieldName={getFieldName(name, "NumberOf*Evals")} />
      <InputField subTitle="Disable Self-Reflection" value={getValue(observationConfig, name, "DisableSelfReflection*")} onChange={updateObservationConfigs} fieldName={getFieldName(name, "DisableSelfReflection*")} >
        <CheckboxControl />
      </InputField>
    </div>
    <div className="config-wrapper">
      {
        _.map(name === 'Teacher' ? getPositions(40, 50, defaultUserPositionTypes) : getPositions(10, 30, defaultUserPositionTypes), (userPosition, index) => {
          const surveyType = name === 'Teacher' ? 4 : 5
          return <div className="user-position-row" key={index}>
            <InputField subTitle={userPosition?.label.replace(/([A-Z])/g, ' $1').trim() + " Survey Template"} value={_.find(observationConfig?.UserPositionSurveyTemplateMap, x => x.UserPosition === userPosition.value && x.SurveyType === surveyType)?.SurveyTemplateId} fieldName={userPosition.value} onChange={(value, fieldName) => updateUserPositionSurveyTemplateMap(value, fieldName, surveyType)}>
              {(name === 'Teacher' && teacherSurveyList || name === 'Admin' && adminSurveyList) ?
                <SelectListControl textValuePairs={_.map(name === 'Teacher' ? teacherSurveyList : adminSurveyList ?? [], x => { return { Text: x.Title, Value: x.Id } })} hidePleaseSelect={true} />
                :
                <Loading />}
            </InputField>
          </div>
        })
      }
      <InputField subTitle="Start Date" value={getValue(observationConfig, name, "*SurveyStartDate") ? new Date(getValue(observationConfig, name, "*SurveyStartDate"))?.toISOString()?.slice(0, 10) : ""} maxLength={100} onChange={updateObservationConfigs} fieldName={getFieldName(name, "*SurveyStartDate")}  >
        <DatePickerControl minDate={new Date().toISOString().slice(0, 10)} />
      </InputField>
    </div>
  </div>

}


export const ObservationSection = ({
  observationConfig,
  updateObservationConfigs,
  forceValidate,
  activeObservationConfigTab,
  disabled,
  showCertPositions,
  updateUserPositionRubricMap,
  formattedTeacherObservationRubrics,
  formattedTeacherCertificationRubrics,
  formattedAdminObservationRubrics,
  formattedAdminCertificationRubrics,
  updateUserPositionSurveyTemplateMap,
  updateUserPositionAlternativeMap,
  adminSurveyList,
  teacherSurveyList,
  orgTreeUsers,
  certificationQuestions,
  school
}) => {
  const [defaultUserPositionTypes, setDefaultUserPositionTypes] = useState(null)
  useEffect(() => {
    if (observationConfig && !observationConfig?.wasEdited) {
      const updatedDefaultUserPositionTypes = {}
      for (const [key, value] of Object.entries(SystemTypes.UserPositionType)) {
        const userPostionAlternative = _.find(observationConfig.UserPositionAlternativeMap, x => x.UserPosition === value)
        if (userPostionAlternative && userPostionAlternative.Name) {
          updatedDefaultUserPositionTypes[userPostionAlternative.Name] = value
        } else {
          updatedDefaultUserPositionTypes[key] = value
        }
      }
      setDefaultUserPositionTypes(updatedDefaultUserPositionTypes)
    }
  }, [observationConfig])
  return (
    <>
      {/* <span className={"observation-component-text"} style={{ display: disabled ? "block" : "none" }}>{disabledText}</span> */}
      <div className={`observation-component-wrapper ${disabled ? "disabled" : ""}`}>
        {activeObservationConfigTab === 'Display' && <div className="observation-config-wrapper">
          <h3>User Positions Alternative Display</h3>
          <DisplaySettings
            observationConfig={observationConfig}
            updateUserPositionAlternativeMap={updateUserPositionAlternativeMap}
          />
        </div>}
        {(activeObservationConfigTab === 'Admin' || activeObservationConfigTab === 'Teacher') && <div className='observation-config-wrapper'>
          <h3> Observation Settings</h3>
          {
            activeObservationConfigTab === 'Admin' && <UserPositionRubricMap
              observationConfig={observationConfig}
              name='Admin'
              updateUserPositionRubricMap={updateUserPositionRubricMap}
              updateUserPositionSurveyTemplateMap={updateUserPositionSurveyTemplateMap}
              adminSurveyList={adminSurveyList}
              teacherSurveyList={teacherSurveyList}
              formattedTeacherObservationRubrics={formattedTeacherObservationRubrics}
              formattedAdminObservationRubrics={formattedAdminObservationRubrics}
              updateObservationConfigs={updateObservationConfigs}
              defaultUserPositionTypes={defaultUserPositionTypes}
            />
          }
          {activeObservationConfigTab === 'Teacher' && <UserPositionRubricMap
            observationConfig={observationConfig}
            updateObservationConfigs={updateObservationConfigs}
            name='Teacher'
            updateUserPositionRubricMap={updateUserPositionRubricMap}
            updateUserPositionSurveyTemplateMap={updateUserPositionSurveyTemplateMap}
            adminSurveyList={adminSurveyList}
            teacherSurveyList={teacherSurveyList}
            formattedTeacherObservationRubrics={formattedTeacherObservationRubrics}
            formattedAdminObservationRubrics={formattedAdminObservationRubrics}
            defaultUserPositionTypes={defaultUserPositionTypes}
          />}
        </div>}
        {(activeObservationConfigTab === 'Admin' || activeObservationConfigTab === 'Teacher') && <div className='observation-config-wrapper'>
          <h3> Certification Settings</h3>
          {activeObservationConfigTab === 'Admin' && <SettingsComponent
            name="Admin"
            observationConfig={observationConfig}
            updateObservationConfigs={updateObservationConfigs}
            forceValidate={forceValidate}
            getValue={getValue}
            getFieldName={getFieldName}
            showCertPositions={showCertPositions}
            defaultUserPositionTypes={defaultUserPositionTypes}
            orgTreeUsers={orgTreeUsers}
          />
          }
          {activeObservationConfigTab === 'Teacher' && <SettingsComponent
            name="Teacher"
            observationConfig={observationConfig}
            updateObservationConfigs={updateObservationConfigs}
            forceValidate={forceValidate}
            getValue={getValue}
            getFieldName={getFieldName}
            showCertPositions={showCertPositions}
            formattedTeacherCertificationRubrics={formattedTeacherCertificationRubrics}
            formattedAdminCertificationRubrics={formattedAdminCertificationRubrics}
            defaultUserPositionTypes={defaultUserPositionTypes}
            orgTreeUsers={orgTreeUsers}
          />}
        </div>
        }
        {activeObservationConfigTab === 'Admin' &&
          <TableComponent
            name="Admin"
            observationConfig={observationConfig}
            updateObservationConfigs={updateObservationConfigs}
            getFieldName={getFieldName}
            ids={observationConfig?.CertificationAdminQuestionIds}
            certificationQuestions={certificationQuestions}
            defaultFilter={(items) => {
              return items?.filter(x => x?.IsActive === true &&
                x?.IsDefault === true && x?.CertificationType == 1)
            }} // return active and default questions
          />}
        {activeObservationConfigTab === 'Teacher' && <TableComponent
          name="Teacher"
          observationConfig={observationConfig}
          updateObservationConfigs={updateObservationConfigs}
          getFieldName={getFieldName}
          ids={observationConfig?.CertificationTeacherQuestionIds}
          certificationQuestions={certificationQuestions}
          defaultFilter={(items) => { return _.filter(items, x => x?.IsActive === true && x?.IsDefault === true && x?.CertificationType == 2) }}
        />}
        {activeObservationConfigTab === 'Metrics' && 
          <MetricsComponent
            observationConfig={observationConfig}
            school={school}
        />}
      </div>
    </>
  );
};

ObservationSection.defaultProps = {
  disabled: false,
  disabledText: "Please fill out the above configurations before modifying certification settings.",
};