import React, { useState } from 'react';
import { useEffect } from 'react';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useAppUserManager, useCertificationQuestionManager, useDistrictManager, useImportExportManager, useOrgManager, useSchoolManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';
import SchoolTable from '../components/school/table';
import SchoolFilter from '../components/school/filter';
import SchoolDetails from '../components/school/details';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import '../components/observation/observationconfig.scss'
import SystemTypes from '../../../services/SystemTypes';

const SchoolMgmt = () => {
    const schoolMgr = useSchoolManager();
    const orgMgr = useOrgManager();
    const districtMgr = useDistrictManager();
    const certificationQuestionMgr = useCertificationQuestionManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams({});
    const [districtItem, setDistrictItem] = useState(null)
    const [teacherRubricList, setTeacherRubricList] = useState(null)
    const [adminRubricList, setAdminRubricList] = useState(null)
    const [teacherSurveyList, setTeacherSurveyList] = useState(null)
    const [adminSurveyList, setAdminSurveyList] = useState(null)
    const [observationConfig, setObservationConfig] = useState(null)
    const [loadingConfigs, setLoadingConfigs] = useState(false)
    const [orgTreeUsers, setOrgTreeUsers] = useState(null)
    const [certificationQuestions, setCertificationQuestions] = useState(null)
    const [org, setOrg] = useState(null)
    const params = useParams();
    const navigate = useNavigate();
    const [deleting, setDeleting] = useState(false);
    const appUserMgr = useAppUserManager();
    const importExportMgr = useImportExportManager();
    const [exportLoading, setExportLoading] = useState(false);



    useEffect(() => {
        if (params?.schoolId && schoolMgr && orgMgr && certificationQuestionMgr) {
            setLoading(true);
            orgMgr.loadOrgTree().then(r => {
                setOrg(r)
            });

            if (!selectedItem) {
                if (params?.schoolId !== 'create') {
                    schoolMgr.get(params?.schoolId).then((r) => {
                        if (r.Success) {
                            setManageMode('view');
                            setSelectedItem(r.Items.first());
                            setLoading(false);
                            grabObservationDetails(r.Items.first());
                        }
                    });
                } else {
                    setManageMode('view');
                    setLoading(false);
                    grabObservationDetails()
                }
            } else {
                setLoading(false)
                grabObservationDetails(selectedItem);
            }
        }
        else if (schoolMgr && orgMgr) {
            setLoading(true);
            setSelectedItem(null)
            orgMgr.loadOrgTree().then(r => {
                setOrg(r)
            });


            let search = "x => x.IsDeleted == false";
            let districtId = undefined;
            if (searchParams.get('districtid')) {
                search += ' && DistrictId == districtId';
                districtId = searchParams.get('districtid');
            }

            schoolMgr.search(search, true, { districtId }).then(fb => {
                if (searchParams.get('districtid')) {
                    handleOnFilterChange("DistrictId", searchParams.get('districtid'))
                    districtMgr?.get(searchParams.get('districtid')).then(r => {
                        setDistrictItem(r?.Items?.first())
                        setLoading(false)
                        setManageMode('list')
                    })
                } else {
                    setLoading(false)
                    setManageMode('list')
                };
                ;
            });


        }
    }, [schoolMgr, orgMgr, params, certificationQuestionMgr]);

    const grabObservationDetails = async (school) => {
        setLoadingConfigs(true);
        let schoolId = params?.schoolId && params?.schoolId !== 'create' ? params?.schoolId : null
        const observationConfig = schoolMgr.getObservationConfigBySchool(schoolId ?? null, school?.DistrictId ?? searchParams.get('districtid') ?? null, school?.ClientId ?? searchParams.get('ordId') ?? null).then((r) => {
            if (r.Success) {
                let observationConfig = r.Items.first();
                setObservationConfig(observationConfig)
            }
        })

        const orgIdOrNull = school?.ClientId ?? null

        const teacherRubric = orgMgr.getTeacherRubrics(orgIdOrNull, false, ['RubricDomainMap']).then(r => {
            if (r.first().Success) {
                setTeacherRubricList(r.first().Items.filter(x => x.SchoolYearIdentifier == orgMgr.AppUserState.selectedSchoolYear && x.Status !== 1))
            }
        })
        const adminRubric = orgMgr.getAdminRubrics(orgIdOrNull, false, ['RubricDomainMap']).then(r => {
            if (r.first().Success) {
                setAdminRubricList(r.first().Items.filter(x => x.SchoolYearIdentifier == orgMgr.AppUserState.selectedSchoolYear && x.Status !== 1))
            }
        })
        const teacherSurveyTemplate = orgMgr.getSurveyTemplates(orgIdOrNull, 4).then(r => {
            if (r.first().Success) {
                setTeacherSurveyList(r.first().Items)
            }
        })
        const adminSurveyTemplate = orgMgr.getSurveyTemplates(orgIdOrNull, 5).then(r => {
            if (r.first().Success) {
                setAdminSurveyList(r.first().Items)
            }
        })
        const orgUsers = orgMgr.loadOrgUserTree().then((r) => {
            setOrgTreeUsers(orgMgr.UsersOnly('org', orgIdOrNull))
        })

        const grabCertificationQuestions = certificationQuestionMgr.list(certificationQuestionMgr.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false")).then(r => setCertificationQuestions(r.Items))
        const result = Promise.all([observationConfig, teacherRubric, adminRubric, teacherSurveyTemplate, adminSurveyTemplate, grabCertificationQuestions]).then((r) => setLoadingConfigs(false))
    }

    const onAddNew = () => {
        setManageMode('view');
        grabObservationDetails();
        setLoadingConfigs(true)
        if (searchParams.get('districtid')) {
            const preSelectedOrgItem = {
                ClientId: schoolMgr?.FilteredData?.first()?.ClientId ?? districtItem?.ClientId,
                DistrictId: searchParams.get('districtid'),
            }
            setSelectedItem(preSelectedOrgItem)
            navigate({
                pathname: '/admin/school/create',
                search: `?${createSearchParams({ districtid: searchParams.get('districtid') })}`,
            });
        } else {
            navigate('/admin/school/create')
        }
    }

    const handleOnFilterChange = async (field, value) => {
        const dataFilter = schoolMgr.Filter;
        const updatedDataFilter = { ...dataFilter };
        updatedDataFilter[field] = value !== '' ? value : null;
        await schoolMgr.filterData(updatedDataFilter);
    }

    const handleOnSave = async (item, config) => {
        item.Organization = null;
        item.District = null;
        var result = await schoolMgr.saveItem(item);
        if (!item.Id && config?.wasEdited) {
            config.SchoolId = result.Items.first().Id
        }
        var configResult = orgMgr.saveObservationConfig(config)
        return result;
    }

    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        setDeleting(true);
        orgMgr.deleteSchool(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.Name}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                schoolMgr.deleteItem(item).then(rs => {
                    if (rs.Success) {
                        setItemToDelete(null);
                        setDeleting(false);
                    }
                    else {
                        setItemToDelete(item);
                        setDeleteError(`Failed to delete '${item.Name}' ` + ` - ${rs?.Message?.DisplayMessage ?? (rs?.MessageDetails?.toString() ?? 'Unknown Error')}`)
                    }
                });
            }
        });
    }

    const handleOnEdit = (item) => {
        setSelectedItem(item);
        setManageMode('view');
        if (searchParams.get('districtid')) {
            navigate({
                pathname: `/admin/school/${item.Id}`,
                search: `?${createSearchParams({ districtid: searchParams.get('districtid') })}`,
            });
        } else {
            navigate(`/admin/school/${item.Id}`);
        }
    }
    const handleOnDrillDown = (item) => {
        navigate({
            pathname: '/admin/cluster',
            search: `?${createSearchParams({ schoolid: item.Id })}`,
        });
    }

    const handleOnCancel = () => {
        if (searchParams.get('districtid')) {
            navigate({
                pathname: '/admin/school',
                search: `?${createSearchParams({ districtid: searchParams.get('districtid') })}`,
            });
        } else {
            navigate('/admin/school');
        }

        setLoadingError(null);
        setErrors(null);
        setSelectedItem(null)
        setObservationConfig(null)
        setTeacherRubricList(null)
        setAdminRubricList(null)
        setTeacherSurveyList(null)
        setAdminSurveyList(null)
    }


    const handleOnExport = async () => {
        const idList = schoolMgr.FilteredData.map(x => x.Id);
        setExportLoading(true);
        const result = await importExportMgr.ExportData(SystemTypes.ImportExportType.ExportSchool, idList);
        if (!result.Success) {
            setExportLoading(null);
            setErrors(["Failed to export School, if this issue continues please contact NIET support."]);
        }
        else {
            importExportMgr.downloadFile(result.Items.first()).then(r => {
                setExportLoading(false);
            }).catch(e => {
                setExportLoading(false);
                setErrors(["School export has been created and failed to download. Please check Import/Export Admin."]);
            });
        }
        return result;
    }


    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} loadingOk={deleting} subTitle={`Are you sure you want to delete '${itemToDelete?.Name}' ? `} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <MainLayout errors={errors} validationErrors={validationErrors} >
            {manageMode === 'view' && !loading &&
                <div className='screen-header'>
                    <h3>School Management</h3>
                    {params?.schoolId === 'create' ? <h5>Add School</h5> : <h5>Edit School</h5>}
                </div>
            }
            <ScreenWrapper className={manageMode === 'view' ? ' no-height' : ''} loading={loading} loadingError={loadingError}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <SchoolFilter orgMgr={orgMgr} org={org} schoolMgr={schoolMgr} onFilterChange={handleOnFilterChange} hideFilter={searchParams.get('districtid')} />
                            <div className={"breadcrumb-table-wrapper" + (searchParams.get('districtid') ? " " : '-nodrill')}>
                                {
                                    searchParams.get('districtid') && <div className="breadcrumb-wrapper">
                                        <div className="breadcrumb" onClick={() => navigate('/admin/organization')}>{schoolMgr?.FilteredData?.first()?.Organization?.Name ?? districtItem?.Organization?.Name}</div>
                                        <FontAwesomeIcon
                                            icon={faAngleRight}
                                            className={"fa-angle-right"}
                                        />
                                        <div className="breadcrumb" onClick={() => navigate({
                                            pathname: '/admin/district',
                                            search: `?${createSearchParams({ orgid: schoolMgr?.FilteredData?.first()?.ClientId ?? districtItem?.ClientId })}`,
                                        })}>{schoolMgr?.FilteredData?.first()?.District?.Name ?? districtItem?.Name}</div>
                                        <FontAwesomeIcon
                                            icon={faAngleRight}
                                            className={"fa-angle-right"}
                                        />
                                        <div className="breadcrumb">School</div>
                                    </div>
                                }
                                <SchoolTable filteredItems={schoolMgr?.FilteredData ?? []} onEdit={handleOnEdit} onDelete={(item) => { setItemToDelete(item) }} onDrillDown={handleOnDrillDown} isDrilledDown={searchParams.get('districtid')} />
                            </div>
                        </div>
                        <div className='screen-footer list'>
                            {(schoolMgr?.CanWrite && schoolMgr?.AppUserState?.userProfile?.UserPositionType <= SystemTypes.UserPositionType.Admin) && <><ButtonControl type={"create"} onClick={onAddNew}>Create school</ButtonControl>&nbsp;&nbsp;</>}
                            {schoolMgr?.CanView && appUserMgr?.canView(appUserMgr?.AppUserState?.permissions.ExportSchool) && <><ButtonControl loading={exportLoading} type={'cancel'}
                                onClick={handleOnExport}>
                                Export Schools
                            </ButtonControl>&nbsp;&nbsp;</>}
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&

                    <SchoolDetails canWrite={schoolMgr.CanWrite} orgMgr={orgMgr} org={org}
                        teacherRubricList={teacherRubricList}
                        adminRubricList={adminRubricList}
                        preSelectedDistrictId={searchParams.get('districtid')}
                        teacherSurveyList={teacherSurveyList}
                        adminSurveyList={adminSurveyList}
                        observationConfigs={observationConfig}
                        loadingConfigs={loadingConfigs}
                        schoolMgr={schoolMgr}
                        school={selectedItem}
                        onCancel={handleOnCancel}
                        onSave={handleOnSave}
                        onLoading={setLoading}
                        onErrors={setErrors}
                        onValidationErrors={setValidationErrors}
                        onLoadingError={setLoadingError}
                        certificationQuestions={certificationQuestions}
                        grabObservationDetails={grabObservationDetails}
                        orgTreeUsers={orgTreeUsers}
                    />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default SchoolMgmt;