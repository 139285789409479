import React, { useState } from 'react';
import moment from "moment";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { asyncForEach } from "../../../contentLibrary/utils/jsUtil";
import ButtonControl from '../../../../components/controls/ButtonControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { InputField } from '../../../../components/controls/InputField'
import CheckboxControl from '../../../../components/controls/CheckboxControl';
import { ConstructionOutlined } from '@mui/icons-material';
import { Toggle } from 'rsuite';
import RubricRatings from './RubricRatings';

const RubricConfigureTable = ({ data, rubricRatingData, previousRubricRatingData, setRubricRatingUpdate, assignDomains, onDomainChange, saveNewRubricRatingItem, handleRatingSave, handleDeleteRow, enableNext }) => {
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [ratingData, setRatingData] = useState(null);
    const [rows, setRows] = useState([]);
    const [disableAddPerformanceButton, setDisableAddPerformanceButton] = useState(false);
    const [numSet, setNumSet] = useState(0);
    const [columns] = useState([
        {
            header: 'Performance Level',
            dataProp: "level",
            width: '25',
            justifyLeft: true,
            canSort: false
        },
        {
            header: 'Description',
            dataProp: "description",
            width: '35',
            justifyLeft: true,
            canSort: false
        },
        {
            header: 'Rating',
            dataProp: "rating",
            width: '8.66',
            canSort: false
        },
        {
            header: 'Show in Evaluations',
            dataProp: "showInEval",
            width: '10.66',
            wrapText: true,
            canSort: false,
        },
        {
            header: 'Order',
            dataProp: "order",
            width: '6.66',
            canSort: false
        }
    ])

    useEffect(() => {
        var result = data.filter(x => x.Title !== null).map(x => {
            return {
                ...x,
                "ratingId": x.Id,
                "Value": `${x.Title} (${x.Value})`,
                "Text": `${x.Title} (${x.Value})`,
                "level": `${x.Title} (${x.Value})`,
                "description": x.ShortDescription,
                "showInEval": x.ShowInRubric,
                "sequence": x.Sequence,
                "Rating": x.Value
            }
        });
        setRatingData(result);
    }, [data]);

    useEffect(() => {
        let newRows = [];
        let incomingData = [];
        incomingData = rubricRatingData ? rubricRatingData : (previousRubricRatingData ? previousRubricRatingData : false);
        if (incomingData) {
            incomingData.forEach((c) => {
                let descriptionFormat = getDescription(c.description ?? "");
                newRows.push({
                    ratingId: c.ratingId,
                    level: c.level,
                    description: descriptionFormat + ' (' + c.rating + ')',
                    sequence: c.sequence,
                    rating: c.rating,
                    showInEval: c.showInEval ? c.showInEval : c.ShowInRubric ? c.ShowInRubric : false,
                    isEditMode: c.isEditMode ? c.isEditMode : false,
                    isNewRow: c.isNewRow ? c.isNewRow : false
                });
            });
            setRows(newRows);
        }
    }, [rubricRatingData, previousRubricRatingData]);

    const getDescription = (description) => {
        let index = description?.indexOf('(');
        if (index != -1) {
            description = description?.substring(0, index);
        }
        return description;
    }

    const onAddPerformanceLevel = () => {
        var currentRowSet = [...rows];
        if (currentRowSet.length < 10) {
            //add new row to table
            const newRow = {
                "ratingId": '',
                "level": "",
                "description": "",
                "sequence": currentRowSet.length + 1,
                "rating": '',
                "showInEval": false,
                "isEditMode": true,
                "isNewRow": false
            }
            currentRowSet.push(newRow);
            setRows(currentRowSet);
            setEditMode(true);
            setDisableAddPerformanceButton(true);
            enableNext(false);
        }
    };

    const onEditRow = (row) => {
        let currentRowSet = [...rows];
        let index = currentRowSet.findIndex(r => r.sequence === row.sequence);

        currentRowSet[index].isEditMode = true;

        setRows(currentRowSet);
    };

    const onSaveRow = (row) => {
        let currentRowSet = [...rows];
        let index = currentRowSet.findIndex(r => r.sequence === row.sequence);

        currentRowSet[index].isEditMode = false;

        if (row.isNewRow) {
            currentRowSet[index].isNewRow = false;
            let currentRatingSet = [...ratingData];
            const newRating = {
                "ratingId": row.ratingId,
                "Value": row.level,
                "Text": row.level,
                "description": row.description,
                "ShowInRubric": row.showInEval,
                "Rating": row.rating,
                "Sequence": row.sequence
            }
            currentRatingSet.push(newRating);
            setRatingData(currentRatingSet);
            saveNewRubricRatingItem(newRating);
        } else {
            handleRatingSave(row);
        }
        setRows(currentRowSet);
        setRubricRatingUpdate(currentRowSet);
        setDisableAddPerformanceButton(false);
        enableNext(true);
    };

    const onDeleteRow = (row) => {
        let currentRowSet = [...rows];
        let index = currentRowSet.findIndex(r => r.sequence === row.sequence);
        currentRowSet.splice(index, 1);
        currentRowSet = currentRowSet.map((x, i) => {
            return {
                ...x,
                sequence: i + 1
            }
        })
        handleDeleteRow(row);
        setRows(currentRowSet);
        setRubricRatingUpdate(currentRowSet);

        setDisableAddPerformanceButton(false);
        enableNext(true);
    };

    const onCheckboxChangeRow = (row) => {
        if (row.isEditMode) {
            let currentRowSet = [...rows];
            let index = currentRowSet.findIndex(r => r.sequence === row.sequence);

            if (currentRowSet[index].showInEval === false) {
                currentRowSet[index].showInEval = true;
            } else {
                currentRowSet[index].showInEval = false;
            }

            setRows(currentRowSet);
            setRubricRatingUpdate(currentRowSet);
        }
    };

    const handleOnOrderChange = (sequence, isMovedUp) => {
        //Sort Data table, re-render table rows 
        let currentRowSet = [...rows];
        let index = currentRowSet.findIndex(r => r.sequence === sequence);

        if (index < 0 || index >= currentRowSet.length || (index == 0 && isMovedUp) || (index + 1 >= currentRowSet.length && !isMovedUp)) {
            // Can't move the -1st item or any item outside range
            return;
        }
        if (isMovedUp) {
            currentRowSet[index].sequence -= 1;
            currentRowSet[index - 1].sequence += 1;
            [currentRowSet[index - 1], currentRowSet[index]] = [currentRowSet[index], currentRowSet[index - 1]];
        }
        else {
            currentRowSet[index].sequence += 1;
            currentRowSet[index + 1].sequence -= 1;
            [currentRowSet[index + 1], currentRowSet[index]] = [currentRowSet[index], currentRowSet[index + 1]];
        }
        setRows(currentRowSet);
        setRubricRatingUpdate(currentRowSet);
    };

    const handleOnDropdownChange = (e, row) => {
        let currentRowSet = [...rows];
        let index = currentRowSet.findIndex(r => r.sequence === row.sequence);

        if (e.target.value == 'addOption') {
            currentRowSet[index].isNewRow = true;
            currentRowSet[index].level = '';
            currentRowSet[index].description = '';
            currentRowSet[index].ratingId = '';
            currentRowSet[index].showInEval = false;
            currentRowSet[index].rating = '';
        } else {
            let selectedRatingIndex = ratingData.findIndex(r => r.Value == e.target.value);
            currentRowSet[index].level = e.target.value;
            currentRowSet[index].description = ratingData[selectedRatingIndex].ShortDescription; //TODO: ratingData will be pulled in from API call for dropdown
            currentRowSet[index].ratingId = ratingData[selectedRatingIndex].ratingId;
            currentRowSet[index].showInEval = ratingData[selectedRatingIndex].ShowInRubric;
            currentRowSet[index].rating = ratingData[selectedRatingIndex].Rating;
        }

        setRows(currentRowSet);
        setRubricRatingUpdate(currentRowSet);
    };

    const handleOnChangeInput = (e, field, row) => {
        let currentRowSet = [...rows];
        let index = currentRowSet.findIndex(r => r.sequence === row.sequence);

        if (field === 'level') {
            currentRowSet[index].level = e;
        }
        if (field === 'description') {
            currentRowSet[index].description = e;
        }
        if (field === 'rating') {
            currentRowSet[index].rating = e;
        }

        setRows(currentRowSet);
        setRubricRatingUpdate(currentRowSet);
    };

    const handleCheckHideAction = (row, field) => {
        if (field === 'save') {
            if (row.isNewRow) {
                return false;
            }
            if (!row.isNewRow && row.ratingId === '') {
                return true;
            }
            return false;
        }
    }

    return <>
        <div className="configureRubric-list">
            <TableControl
                columns={columns}
                data={rows}
                customBorder={true}
                onEdit={(row) => onEditRow(row)}
                onDelete={(row) => onDeleteRow(row)}
                onSave={(row) => onSaveRow(row)}
                onCheckboxChange={(row) => onCheckboxChangeRow(row)}
                onOrderChange={(sequence, isMovedUp) => handleOnOrderChange(sequence, isMovedUp)}
                onChange={handleOnDropdownChange}
                onChangeInput={handleOnChangeInput}
                customActionsStyle={'8'}
                isEditMode={editMode}
                dropdownData={ratingData}
                checkDisableAction={handleCheckHideAction}
                noDataText={'No Performance Levels Have Been Added'}
                useColumnWidthCustom={true}
            />
        </div>
        <div className='performance-btn-row'>
            <ButtonControl disabled={disableAddPerformanceButton} loading={loading} type={!disableAddPerformanceButton ? 'create' : 'create-disabled'} className={'add-performance-btn'} onClick={onAddPerformanceLevel}>
                Add Performance Level
            </ButtonControl>
            <span className='assign-domain'>
                Assign Domains
                {/* <CheckboxControl value={vassignDomains ?? false} onChange={onDomainChange} /> */}
            </span>
            <Toggle checked={assignDomains ?? false} onChange={onDomainChange} />

        </div>

    </>
}

export default RubricConfigureTable;