import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _ from 'lodash';

class ContentSyncManager extends ItemManager {
  constructor(globalItemState, globalAuthState, globalAppUserState) {
    super(ItemServiceFactory.ItemServiceEnum.ContentSync, globalAuthState, globalAppUserState, globalItemState,
      (filter, item) => {
        if (filter) {
          var hasSearch = item.FileName?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || item?.LoadedByUser?.FullName?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
          var hasStatus = item.Status == filter?.status || (
            filter?.status === 'Running' && !item.IsComplete)
            || (filter?.status === undefined || filter?.status === null || filter?.status === 'UNK');
          return hasSearch && hasStatus;
        }
        else
          return true;
      });

    this._errorUtil = undefined;
  }

  async addActiveSync(activeSync) {
    const allData = [...this.GetGlobalState().allData];
    allData.push(activeSync);
    this.filterData(undefined, allData);
  }

  async runSync(id) {
    //Syncs files and content
    return await this.runOperation('FolderSyncFile', id);
  }
}

export default ContentSyncManager;