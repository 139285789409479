import React from "react";

import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";

import config from "../../../config.json";
import { ProfileUpdateTypeEnum } from "../types/ProfileUpdateType.enum";

import "./ProfileDetails.scss";
import { useAppUserManager } from "../../../hooks/useManagers";
import { useEffect } from "react";
import { APIStatusType } from "../../../stores/globalAppUserStore";
import { useState } from "react";
import { DialogControl } from "../../../components/controls/DialogControl";
import { InputField } from "../../../components/controls/InputField";
import FileUploaderControl from "../../../components/controls/FileUploaderControl";
import ButtonControl from "../../../components/controls/ButtonControl";
import Loading from "../../../components/Loading";

const ProfileDetails = ({
  displayName,
  profileImage,
  email,
  mobilePhone,
  organization,
  district,
  school,
  cluster,
  roles,
  jobTitle,
  setProfileUpdateType,
  handleProfileImage,
  downloadMgr,
}) => {
  const { instance } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [showProfileImageDialog, setShowProfileImageDialog] = useState(false);
  const [profileImageFile, setProfileImageFile] = useState(profileImage);
  const [downloading, setDownloading] = useState(false);
  const appUserManager = useAppUserManager();
  const navigate = useNavigate();

  useEffect(() => {
    if (profileImage && profileImage?.FileUrl) {
      setProfileImageFile(profileImage);
    }
  }, [profileImage]);

  useEffect(() => {
    if (appUserManager) {
      setIsAuthenticated(appUserManager.GetGlobalState().apiStatus === APIStatusType.OnlineSecure);
    }
  }, [appUserManager]);

  const onEditNameClicked = () => {
    if (isAuthenticated === false) {
      navigate("/logout");
    } else if (isAuthenticated) {
      setProfileUpdateType(ProfileUpdateTypeEnum.PROFILE_EDIT_NAME);
      instance.loginRedirect({
        redirectUri: window.location.href,
        extraQueryParameters: {
          p: config.AUTH.ADB2C_EDIT_NAME_POLICY,
          cancelUrl: encodeURIComponent(window.location.href),
        },
      });
    }
  };

  const onEditEmailClicked = () => {
    if (isAuthenticated === false) {
      navigate("/logout");
    } else if (isAuthenticated) {
      setProfileUpdateType(ProfileUpdateTypeEnum.PROFILE_EDIT_EMAIL);
      instance.loginRedirect({
        redirectUri: window.location.href,
        extraQueryParameters: {
          p: config.AUTH.ADB2C_EDIT_ACCOUNT_EMAIL_POLICY,
          cancelUrl: encodeURIComponent(window.location.href),
        },
      });
    }
  };

  const onEditMobileClicked = () => {
    if (isAuthenticated === false) {
      navigate("/logout");
    } else if (isAuthenticated) {
      setProfileUpdateType(ProfileUpdateTypeEnum.PROFILE_EDIT_MOBILE);
      instance.loginRedirect({
        redirectUri: window.location.href,
        extraQueryParameters: {
          p: config.AUTH.ADB2C_EDIT_MOBILE_POLICY,
          cancelUrl: encodeURIComponent(window.location.href),
        },
      });
    }
  };

  const onEditProfileImageClicked = () => {
    if (isAuthenticated === false) {
      navigate("/logout");
    } else if (isAuthenticated) {
      setShowProfileImageDialog(true);
    }
  };

  const handleProfileImageChange = (value) => {
    setShowProfileImageDialog(false);
    handleProfileImage(value);
  };

  const loadRoles = (roles) => {
    if (roles) {
      return roles?.map((role) => <li key={role}>{role}</li>);
    }
    else {
      return <Loading />
    }
  };

  const handleResetFile = () => {
    //Clears fires a confirm dialog and clears the file.
    sessionStorage.removeItem('FileUpload-profile-image');
    handleProfileImage(undefined);
    setProfileImageFile(undefined);
  }


  return (<>
    {isAuthenticated !== null && <>
      <DialogControl
        openDialog={showProfileImageDialog}
        title={'Change Profile Image'}
        onCancel={() => handleProfileImageChange(undefined)}
        onOk={() => handleProfileImageChange(profileImageFile)}
      >

        <InputField title="Profile Picture" value={profileImageFile?.Id} onChange={(value) => setProfileImageFile(value)} fieldName='File' subTitle={"Drag into or click the below box to upload a profile image."}>
          <FileUploaderControl canUploadAnyFile={false} controlId={`profile-image`} value={profileImageFile?.Id} fileUrl={profileImageFile?.FileUrl} overrideMaxWidth={400} widthRes={1920} heightRes={1080} maxFileSize={1024 * 1024 * 99} onFileLoading={(val) => { }} />
        </InputField>
        {!(profileImageFile?.FileId === null || profileImageFile?.FileId === undefined) && <div className="reset-file-btn"><ButtonControl onClick={() => handleResetFile()}>Remove</ButtonControl></div>}

      </DialogControl>
      <div className="screen-header profile__heading-container">
        <div className="profile__image" value="true" onClick={onEditProfileImageClicked}> {profileImage ?
          <img src={profileImageFile?.FileUrl} alt="profile" />
          : <FontAwesomeIcon className={"btn-icon-fa"} icon={faPlus} />
        }</div>
        <div>
          <h2 className="profile__heading">Profile</h2>
          {!profileImage && <h6 className="profile__heading instructions" >Click the "+" icon to upload a user profile photo.</h6>}
        </div>
      </div>

      <h4 className="profile__subheading">Contact</h4>
      <div className="field-input">
        <div className="generic-input-component">
          <div className="profile__field-contact">
            <div className="title">
              <div>Name</div>
            </div>
            <div className="profile__field-value">
              {displayName}
              <span className="profile__field-edit">
                <FontAwesomeIcon
                  className="btn-icon-fa"
                  icon={faPencil}
                  onClick={onEditNameClicked}
                />
              </span>
            </div>
          </div>
          <div className="profile__field-contact">
            <div className="title">
              <div>Email</div>
            </div>
            <div className="profile__field-value">
              {email}
              <span className="profile__field-edit">
                <FontAwesomeIcon
                  className="btn-icon-fa"
                  icon={faPencil}
                  onClick={onEditEmailClicked}
                />
              </span>
            </div>
          </div>
          <div className="profile__field-contact">
            <div className="title">
              <div>Mobile</div>
            </div>
            <div className="profile__field-value">
              {mobilePhone}
              <span className="profile__field-edit">
                <FontAwesomeIcon
                  className="btn-icon-fa"
                  icon={faPencil}
                  onClick={onEditMobileClicked}
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      <h4 className="profile__subheading">Organization</h4>
      <div className="field-input">
        <div className="generic-input-component">
          <div className="profile__field-organization">
            <div className="title">
              <div>Organization</div>
            </div>
            <div className="profile__field-value">{organization}</div>
          </div>
          <div className="profile__field-organization">
            <div className="title">
              <div>District</div>
            </div>
            <div className="profile__field-value">{district}</div>
          </div>
          <div className="profile__field-organization">
            <div className="title">
              <div>School</div>
            </div>
            <div className="profile__field-value">{school}</div>
          </div>
          <div className="profile__field-organization">
            <div className="title">
              <div>Cluster</div>
            </div>
            <div className="profile__field-value">{cluster}</div>
          </div>
        </div>
      </div>

      <h4 className="profile__subheading">Access</h4>
      <div className="field-input">
        <div className="profile__field-contact">
          <div className="title">
            <div>Job Title</div>
          </div>
          <div className="profile__field-value">
            {jobTitle}
          </div>
        </div>
        <div className="generic-input-component">
          <div className="profile__field-permissions">
            <div className="title">
              <div>Roles</div>
            </div>
          </div>
          <div className="profile__roles-list">
            <ul>{loadRoles(roles)}</ul>
          </div>
        </div>
      </div>
    </>}</>
  );
};

export default ProfileDetails;
