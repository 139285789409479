import { useEffect, useState } from 'react';
import './details.scss';
import { InputField } from "../../../../components/controls/InputField";
import { useLogManager } from "../../../../hooks/useManagers";
import ButtonControl from "../../../../components/controls/ButtonControl";
import _ from 'lodash';
import { FormatUTCDateTime } from '../../../../utilities/DateFormater';

const LogDetails = ({ log, onCancel, onLoadingError, onLoading }) => {

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    return <div className='control-box-wrapper'>
        {log && <div className='control-box'>
            <InputField type="label" title="Message" value={log?.Message} fieldName="Message" />
            <InputField type="label" title="Created By" value={log?.CreatedBy} fieldName="CreatedBy" />
            {log?.MessageDetails &&
                <InputField type="label" title="Message Details" value={log?.MessageDetails} fieldName="MessageDetails" />
            }
            <InputField type="label" title="Event Name" value={log?.EventName} fieldName="EventName" />
            <InputField type="label" title="Location" value={log?.Location} fieldName="Location" />
            <InputField type="label" title="Start Time" value={log?.StartTime} fieldName="StartTime" />
            <InputField type="label" title="End Time" value={log?.EndTime} fieldName="EndTime" />
            <InputField type="label" title="Date Created" value={FormatUTCDateTime(log?.CreatedDate)} fieldName="CreatedDate" />
        </div>}
        <div className='screen-footer right'>
            <div className='btn-wrapper-right screen-footer__btn'><ButtonControl type={'cancel'} onClick={handleOnCancel}>Back</ButtonControl></div>
        </div>
    </div>
}

export default LogDetails;


