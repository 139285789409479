import React, { useState } from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { SelectListControl } from '../../../../components/controls';

export default function ImportExportFilter({ importExportMgr, onFilterChange }) {
    const handleOnChange = (value, field) => {
        if (onFilterChange)
            onFilterChange(field, value);
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        importExportMgr.filterData({ search: null, ImportExportStatus: null });
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Import/Export Management</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search'
                            value={importExportMgr?.Filter?.search ?? ''}
                            fieldName='search'
                            disableError={true}
                            placeholder='Name or File Name'
                            onChange={handleOnChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField id='Status'
                            title='Status'
                            value={importExportMgr?.Filter?.ImportExportStatus ?? '0'}
                            fieldName='ImportExportStatus'
                            disableError={true}
                            onChange={handleOnChange}>
                            <SelectListControl textValuePairs={[{ text: 'Created', value: 1 }, { text: 'In-Progress', value: 2 }, { text: 'Success', value: 4 }, { text: 'Partial Success', value: 5 }, { text: 'Failed', value: 3 }]} />
                        </InputField>
                    </div>
                    <div className="filterControl  btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}