const RoleValidateObject = () => {
    return {
        Role: {
            fieldName: 'Role',
            messageTitle: 'role name',
            isRequired: true,
            dataType: 'STRING',//IMAGE, INT, DEC, SELECT,BOOL
            groupId: 'ROLE',
            regEx: /^[a-zA-Z0-9_]*$/,
            requiredDependency: undefined
        },
        Name: {
            fieldName: 'Name',
            messageTitle: 'display name',
            isRequired: true,
            dataType: 'STRING',//IMAGE, INT, DEC, SELECT,BOOL
            groupId: 'ROLE',
            regEx: undefined,
            requiredDependency: undefined
        },
        Description: {
            fieldName: 'Description',
            messageTitle: 'description',
            isRequired: false,
            dataType: 'STRING',
            groupId: 'ROLE',
            regEx: undefined,
            requiredDependency: undefined
        },
        Sequence: {
            fieldName: 'Sequence',
            messageTitle: 'role precedence',
            isRequired: true,
            dataType: 'INT', //IMAGE, INT, DEC, SELECT, BOOL
            groupId: 'ROLE',
            regEx: undefined,
            requiredDependency: undefined
        },
        IsSystemAdmin: {
            fieldName: 'IsSystemAdmin',
            messageTitle: 'is system admin',
            isRequired: false,
            dataType: 'BOOL',
            groupId: 'ROLE',
            regEx: undefined,
            requiredDependency: undefined
        }
    }
}

export default RoleValidateObject;