import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import globalAppUserStore from '../../stores/globalAppUserStore';
import { useHookstate, useHookState } from '@hookstate/core';
import globalAuthStateStore from '../../stores/globalAuthStateStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faUser, faRightFromBracket, faArrowDown, faArrowDownShortWide, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { DialogControl } from '../controls/DialogControl';
import { SelectListControl } from '../controls';
import { useAppUserManager } from '../../hooks/useManagers';
import { useRef } from 'react';
import _ from 'lodash';
import { intlFormat } from 'date-fns';

const ProfileNav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const globalAuthState = useHookstate(globalAuthStateStore);
  const authState = globalAuthState.get();
  const authMgr = useAppUserManager();
  const globalAppUserState = useHookstate(globalAppUserStore);
  const appUserStateItem = globalAppUserState.get();
  const userProfile = authMgr?.GetGlobalState()?.userProfile;
  const idTokenClaims = authMgr?.GetGlobalAuthState()?.idTokenClaims;
  const { isAuthenticated } = authState;
  const [profileMenuActive, setProfileMenuActive] = useState(false);
  const [userProfileFile, setUserProfileFile] = useState(userProfile?.File);
  const [showSchoolYearSelection, setShowSchoolYearSelection] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [schoolYears, setSchoolYears] = useState(null);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
  const [showSchoolYearDropdown, setShowSchoolYearDropdown] = useState(false);

  const logout = async () => {
    navigate("/logout");
  };

  const login = () => {
    navigate('/login');
  }

  const loadSchoolYears = () => {
    let tmpSchoolYears = [];
    Object.keys(appUserStateItem.schoolYearMap).forEach(schoolYear => {
      tmpSchoolYears.push({
        Text: (appUserStateItem.schoolYearMap[schoolYear]?.Title ?? schoolYear) + `${schoolYear == appUserStateItem.currentSchoolYear && schoolYear == appUserStateItem.selectedSchoolYear ? ('  (Current & Selected School Year)') : schoolYear == appUserStateItem.currentSchoolYear ? ' (Current Year)' : (schoolYear == appUserStateItem.selectedSchoolYear ? ' (Selected Year)' : '')}`,
        Value: schoolYear
      });
    })
    setSchoolYears(tmpSchoolYears)
  }

  useEffect(() => {
    if (isAuthenticated && appUserStateItem && (selectedSchoolYear === null || schoolYears === null) && appUserStateItem.schoolYearMap !== null) {
      loadSchoolYears();
      setSelectedSchoolYear(appUserStateItem.selectedSchoolYear);
    }
  }, [appUserStateItem]);

  useEffect(() => {
    if(userProfile) {
      let roles = userProfile.Roles
      const hasAdminRole = roles?.includes('NIETAdmin') || roles?.includes('SysAdmin');
      let isDev = false;
      const hostName = window.location.hostname.toLowerCase();
      if (hostName.includes('local') || hostName.includes('qa') || hostName.includes('qa')) { 
        isDev = true;
      }
      if(hasAdminRole || isDev) {
        setShowSchoolYearDropdown(true);
      }
    }
  }, [userProfile])

  const changeSchoolYear = () => {
    setShowSchoolYearSelection(false);
    if (selectedSchoolYear && selectedSchoolYear != appUserStateItem.selectedSchoolYear) {
      setRefreshing(true);
      globalAppUserState.merge({ selectedSchoolYear: selectedSchoolYear });
      sessionStorage.setItem("SelectedSchoolYear", selectedSchoolYear);
      window.location.href = window.location.href;
      return { Success: false };
    }
    else
      return { Success: true };


  }

  const profileRefs = useRef([]);

  const handleDocumentClick = (e, setState) => {
    if (profileRefs?.current && profileRefs?.current?.getBoundingClientRect) {
      const { clientX, clientY } = e;
      const box = profileRefs?.current?.getBoundingClientRect();
      if (box) {
        if (box.left !== 0 && box.right !== 0 && box.top !== 0 && box.bottom !== 0) {
          const insideBox = (clientX > box.left && clientX < box.right && clientY > box.top - 31 && clientY < box.bottom);
          if (!insideBox) {
            setState(null);
          }
        }
      }

    }
  }

  useEffect(() => {
    document.addEventListener('click', (e) => handleDocumentClick(e, setProfileMenuActive));
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    }
  }, []);

  useEffect(() => {
    if (userProfile?.File && userProfile?.File?.FileUrl) {
      setUserProfileFile(userProfile?.File);
    }
  }, [userProfile?.File])

  const profileOptions = () => {
    return (<>
      <div onClick={() => setProfileMenuActive(!profileMenuActive)} className={`profile-dropdown`} ref={(el) => { profileRefs.current = el }}>
        <div className={'name-and-icon-wrapper'}>
          <span className={'profile-wrapper'}>
            <div className="profile-image">
              {userProfileFile?.FileUrl ? <img src={userProfileFile.FileUrl} alt="profile" />
                : <FontAwesomeIcon className={"btn-icon-fa"} icon={faUser} />}
            </div>
            <div className={'display-name'}>{idTokenClaims?.name}</div>
            <div className={'menu-btn'}><FontAwesomeIcon icon={faChevronDown} className={`drop-down-icon${profileMenuActive ? ' active' : ''}`} /></div>
          </span>
        </div>
      </div>
      {profileMenuActive ? <div className={'active-drop-down'}>
        <div className={`drop-down-option${'/profile' === pathname.toLowerCase() ? ' active' : ''}`} style={'/profile' === pathname.toLowerCase() ? { cursor: 'default' } : {}} onClick={() => {
          if ('/profile' !== pathname.toLowerCase()) {
            setProfileMenuActive(false);
            navigate('/profile');
          }
        }}>
          Profile
        </div>
        {/*<div className={`drop-down-option${'/my-dashboard' === pathname.toLowerCase() ? ' active' : ''}`} style={'/profile' === pathname.toLowerCase() ? { cursor: 'default' } : {}} onClick={() => {
          if ('/my-dashboard' !== pathname.toLowerCase()) {
            setProfileMenuActive(false);
            navigate('/my-dashboard');
          }
        }}>
          Dashboard
      </div>*/}
        {showSchoolYearDropdown &&
          <>
            <div className={'drop-down-option'} onClick={() => {
              setShowSchoolYearSelection(true);
              setProfileMenuActive(false);
            }}>
              School Year <div className={`selected-school-year${selectedSchoolYear != appUserStateItem.currentSchoolYear ? ' not-current-year' : ''}`}>{` ${appUserStateItem.schoolYearMap[selectedSchoolYear]?.Title ?? selectedSchoolYear}`}</div>
            </div>
          </>
        }
        <div className={'drop-down-option'} onClick={logout}>
          Logout
          <FontAwesomeIcon icon={faRightFromBracket} />
        </div>
      </div> : null}
    </>)
  }



  let render = <div className='profile'><div className='profile-btn' onClick={login}>SIGN IN</div></div>
  if (isAuthenticated) {
    render = <div className={`profile authenticated`}>
      {profileOptions()}
      <DialogControl
        openDialog={showSchoolYearSelection}
        title={`Please select a school year`}
        subTitle={'By selecting a new school year and clicking submit the application will reloaded you will loose any unsaved changes.'}
        onOk={() => { return changeSchoolYear() }}
        loading={refreshing}
        onCancel={() => { setShowSchoolYearSelection(false) }}
        okText={'Change'}
        loadingOk={refreshing}
        className={'school-year-selection-dialogue'}
      >
        <SelectListControl
          field={{ FieldName: 'SchoolYear' }}
          textValuePairs={schoolYears}
          value={selectedSchoolYear}
          onChange={(e) => {
            setSelectedSchoolYear(e.target.value);
            //TODO: Reload the context... with the new school year in mind... figure out how this works..
          }}
          className='school-year-select'
        >
        </SelectListControl>
      </DialogControl>
    </div>
  };

  return (render);
}

export default ProfileNav;