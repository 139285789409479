import React from 'react';
import _ from 'lodash';
import { useObservationTeacherManager } from '../../../hooks/useManagers';
import ObservationMgmt from './ObservationMgmt';


const ObservationTeacherMgmt = () => {
    var observationMgr = useObservationTeacherManager();
    return <ObservationMgmt isTeacher={true} observationMgr={observationMgr} />
}

export default ObservationTeacherMgmt;