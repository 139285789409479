import _ from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import { faCopy as copyIcon } from '@fortawesome/free-solid-svg-icons'
import { faArrowCircleRight as rolloverIcon } from '@fortawesome/free-solid-svg-icons'
import { TableControl } from "../../../../components/controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppUserManager } from "../../../../hooks/useManagers";



const SchoolYearTable = ({ orgs, filteredItems, onEdit, onDelete, onCopy, onRollover }) => {
    const appUserMgr = useAppUserManager();
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Organization - Year',
            dataProp: "OrgYear",
            width: '20%',
            canSort: true
        },
        {
            header: 'Title',
            dataProp: 'Title',
            width: '15%',
            canSort: true
        },
        {
            header: 'Start Date',
            dataProp: 'StartDate',
            width: '10%',
            canSort: true
        },
        {
            header: 'Data Entry Lock Date',
            dataProp: 'ConfigurationLockedDate',
            datatype: 'dateTime',
            width: '15%',
            canSort: true
        },
        {
            header: 'Rollover Date',
            dataProp: 'RolloverDate',
            datatype: 'dateTime',
            width: '15%',
            canSort: true
        },
        {
            header: 'Rollover Completed',
            dataProp: 'RolloverCompletedDate',
            datatype: 'dateTime',
            width: '15%',
            canSort: true
        },
        {
            header: 'Active?',
            dataProp: 'IsActive',
            width: '10%',
            canSort: true
        }
    ]);

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (c) => {
                const orgName = c.ClientId !== null ? _.find(orgs, org => org.OrgId === c.ClientId)?.Name ?? '' : '';

                newRows.push({
                    ...c,
                    Active: c.IsActive ? 'Yes' : 'No',
                    allConfig: `${c.CanView ? 'View, ' : ''}${c.CanWrite ? 'Write, ' : ''}${c.CanExecute ? 'Execute, ' : ''}${c.CanDelete ? 'Delete, ' : ''}${c.CanHardDelete ? 'Hard Delete, ' : ''}`.slice(0, -2),
                    OrganizationName: orgName,
                    OrgYear: `${orgName ? `${orgName} - ` : ''}${c.SchoolYearIdentifier}`,
                    StartDate: `${c.SchoolYearMonth}/${c.SchoolYearDay}/${c.SchoolYearIdentifier - 1}`
                });
            });
            setRows(newRows);
        }
    }, [filteredItems]);

    const handleCheckDisableAction = (row, action) => {
        if (action === 'custom1') {
            return row.ClientId != null;
        }
        else if (action === 'custom2') {
            return row.SchoolYearIdentifier > appUserMgr.AppUserState.currentSchoolYear;
        }
        else if (action === 'delete') {
            return row.ClientId == null;
        }
        else {
            return false;
        }
    }

    const handleCheckHideAction = (row, action) => {
        if (action === 'custom1') {
            return !appUserMgr.canExecute('SchoolYearRollOver');
        }
        else {
            return false;
        }
    }

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                useIntegratedPaging={['10', '25', '50', '100']}
                onEdit={(row) => onEdit(row)}
                onDelete={(row) => onDelete(row)}
                checkDisableAction={handleCheckDisableAction}
                checkHideAction={handleCheckHideAction}
                // TODO: potentially make it so that there is a restriction on this action
                onCustom1={{ action: (row) => <FontAwesomeIcon aria-label="Copy" title="Duplicate" className={'btn-icon-fa'} icon={copyIcon} onClick={() => onCopy(row)} /> }}
                onCustom2={{ action: (row) => <FontAwesomeIcon aria-label="Rollover" title="Force Rollover" className={'btn-icon-fa'} icon={rolloverIcon} onClick={() => onRollover(row)} /> }}
            />
        </div>
    </>
}

export default SchoolYearTable;