import { useEffect, useState } from 'react';
import { InputField } from "../../../../components/controls/InputField";
import '../../../../styles/rsuite_override.css';
import TransactionDetailsTable from './TransactionDetailsTable';
import ButtonControl from "../../../../components/controls/ButtonControl";
import { DialogControl } from '../../../../components/controls/DialogControl';
import _ from 'lodash';
import Loading from '../../../../components/Loading';
import './transactionDetails.scss';

const TransactionDetails = ({ transactionMgr, transactionId, onCancel, isEdit, onLoadingError, onCancelSubscription, onProcessRefund }) => {
    const [transactionData, setTransactionData] = useState();
    const [forceValidation, setForceValidation] = useState(false);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedRoleValues, setSelectedRoleValues] = useState();
    const [subscription, setSubscription] = useState();
    const [createdDate, setCreatedDate] = useState();
    const [salesTax, setSalesTax] = useState();
    const [noPaymentPosted, setNoPaymentPosted] = useState(false);
    const [isInactive, setIsInactive] = useState(false);
    const [selectedTransactionDetail, setSelectedTransactionDetail] = useState();
    const [openRefundDialog, setOpenRefundDialog] = useState();

    useEffect(() => {
        if (transactionMgr && transactionId) {
            let ignore = false;

            async function fetchTransactionData() {
                await transactionMgr.SearchTransactions(transactionId).then((r) => {
                    if (r?.length > 0) {
                        var item = r?.first();
                        setTransactionData(item);
                        getFormatedDate(item.CreatedDate);

                        if (item.Status === 2) {
                            setIsInactive(true);
                        }

                        if (!item.TransactionDetails) {
                            setNoPaymentPosted(true);
                        }
                        else {
                            setNoPaymentPosted(false);
                        }
                    }
                    else if (onLoadingError) {
                        onLoadingError(r?.Message.DisplayMessage);
                    }
                    if (item?.SalesTaxId) {
                        transactionMgr.SalesTaxItemMgr.get(item?.SalesTaxId).then((t) => {
                            if (t.Items?.length > 0) {
                                var tax = t.Items?.first();
                                tax = tax.Amount * 100;
                                tax = parseFloat(tax).toFixed(1);
                                tax = tax + "%";
                                setSalesTax(tax);
                            }
                            else if (onLoadingError) {
                                onLoadingError(r?.Message.DisplayMessage);
                            }
                        });
                    }

                    transactionMgr.SubscriptionItemManager.get(item?.ItemId).then((s) => {
                        if (s.Items?.length > 0) {
                            var sub = s.Items?.first();
                            setSubscription(sub);
                        }
                        else if (onLoadingError) {
                            onLoadingError(r?.Message.DisplayMessage);
                        }
                        if (loading)
                            setLoading(false);
                    });
                });
            }

            fetchTransactionData();
            return () => { ignore = true }; //cleanup
        }
    }, [transactionMgr, transactionId]);

    const getFormatedDate = (dateInput) => {

        let date = new Date(dateInput);
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        let newDate = month + '/' + day + '/' + year;

        setCreatedDate(newDate);
    }

    const handleOnCancelSubscription = () => {
        onCancelSubscription(transactionData);
    }

    const handleOnRefund = (transactionDetail) => {
        setSelectedTransactionDetail(transactionDetail);
        setOpenRefundDialog(true);
    }

    const handleDialogClose = () => {
        setOpenRefundDialog(false);
    }

    const handleOnRefundTransaction = async () => {
        const transactionId = { transactiondetailid: selectedTransactionDetail.id };

        let result = await transactionMgr.RefundItemMgr.runOperation('PaymentInvoiceRefund', undefined, transactionId, undefined, undefined, false);
        if (result.Success && result.Items) {
            //Success message?
            let updatedData = { ...transactionData };
            let refundedTran;

            if (Array.isArray(updatedData.TransactionDetails)) {
                refundedTran = updatedData.TransactionDetails.find(x => x.Id === selectedTransactionDetail.id);
            }
            else {
                refundedTran = updatedData.TransactionDetails?.Id === selectedTransactionDetail.id ? updatedData.TransactionDetails : null;
            }
            if (refundedTran) {
                refundedTran.TransactionType = 2;
                setTransactionData(updatedData);
            }
        }
        setOpenRefundDialog(false);
    }

    return (<>
        <DialogControl title={'Refund Transaction?'} subTitle={'The selected transaction will be refunded.'} openDialog={openRefundDialog}
            excludeCancel={true} onCancel={handleDialogClose} customYes={true} isYes={true} onYes={handleOnRefundTransaction} isNo={true} onNo={handleDialogClose} />

        <div className='control-box-wrapper profile-content transactions'>
            {!loading ?
                <>
                    <div className='control-box single-column'>
                        <div className='bottom-space'>
                            <InputField title="Transaction Date" fieldName="TransactionDate" isDisabled={true} value={createdDate} groupId={'TRANSACTION'} disableError={true} />
                        </div>
                        <div className='bottom-space'>
                            <InputField title="Name" fieldName="FullName" isDisabled={true} value={transactionData?.UserProfile?.FullName} groupId={'TRANSACTION'} disableError={true} />
                        </div>
                        <div className='bottom-space'>
                            <InputField title="Email" fieldName="Email" isDisabled={true} value={transactionData?.UserProfile?.Email} groupId={'TRANSACTION'} disableError={true} />
                        </div>
                        <div className='bottom-space'>
                            <InputField title="Primary Phone Number (if available)" fieldName="PrimaryPhone" isDisabled={true} value={transactionData?.UserProfile?.PrimaryPhone} groupId={'TRANSACTION'} disableError={true} />
                        </div>
                        <div className='bottom-space'>
                            <InputField title="Price" fieldName="Amount" isDisabled={true} value={'$' + transactionData?.Price} groupId={'TRANSACTION'} disableError={true} />
                        </div>
                        <div className='bottom-space'>
                            <InputField title="Tax" fieldName="Tax" isDisabled={true} value={salesTax} groupId={'TRANSACTION'} disableError={true} />
                        </div>
                        <div className='bottom-space'>
                            <InputField title="Fees" fieldName="Fees" isDisabled={true} value={'0'} groupId={'TRANSACTION'} disableError={true} />
                        </div>
                        <div className='bottom-space'>
                            <InputField title="Discount Amount" fieldName="DiscountAmount" isDisabled={true} value={'0'} groupId={'TRANSACTION'} disableError={true} />
                        </div>
                        <div className='bottom-space'>
                            <InputField title="Transaction Type" fieldName="TransactionType" isDisabled={true} value={transactionData?.ItemType} groupId={'TRANSACTION'} disableError={true} />
                        </div>
                        <div className='bottom-space'>
                            <InputField title="Status" fieldName="Status" isDisabled={true} value={transactionData?.Status === 1 ? 'Active' : 'Inactive'} groupId={'TRANSACTION'} disableError={true} />
                        </div>
                        {/* <div className='bottom-space'>
                        <InputField title="Processor Response" fieldName="ProcessorResponse" isDisabled={true} value={transactionData?.ProcessorResponse} groupId={'TRANSACTION'} disableError={true}/>
                    </div>                                                                                 */}
                    </div>
                    <div className='control-box single-column'>
                        <TransactionDetailsTable data={transactionData} subscription={subscription} onRefund={(row) => handleOnRefund(row)} />
                    </div>
                    <div className={'screen-footer btn-row'}>
                        <ButtonControl disabled={saving} type={'cancel'} onClick={onCancel}>Back</ButtonControl>
                        <ButtonControl loading={saving} disabled={noPaymentPosted || isInactive} type={'okay'} onClick={handleOnCancelSubscription}>Cancel Subscription</ButtonControl>
                    </div>
                </>
                :
                <>
                    <div className='user-details-page-loading'><Loading type='default' size={'5rem'} /><h5 style={{ paddingTop: '1.3em' }}>Loading Data...</h5></div>
                </>
            }
        </div>
    </>
    )
}

export default TransactionDetails;