import { faArrowDown, faFileExport, faCalculator } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from "lodash";
import React, { useState, useMemo } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import './styles/payout-list.scss';
import Loading from '../../../../components/Loading';
import { useAppUserManager } from '../../../../hooks/useManagers';

const PayoutTable = ({ isAdmin, filteredItems, onEdit, onCalculate, onExport, appUserMgr }) => {
    const [isAdminTable, setIsAdminTable] = useState(false);
    const [orgDistSchoolHeader, setOrgDistSchoolHeader] = useState('Organization, District, School');
    const [rows, setRows] = useState([]);
    const [actionIsLoading, setActionIsLoading] = useState({ action: '', id: '' })

    const columns = useMemo(() => [
        {
            header: 'School Year',
            dataProp: 'SchoolYearIdentifier',
            width: '10%',
            canSort: true
        },
        {
            header: orgDistSchoolHeader,
            dataProp: 'OrgDistSchool',
            width: '40%',
            canSort: true
        },
        {
            header: 'Status',
            dataProp: 'PayoutStatus',
            width: '15%',
            canSort: true
        },
        {
            header: 'Payout Completed',
            dataProp: 'PayoutDate',
            width: '15%',
            canSort: true
        }
    ], [orgDistSchoolHeader]);

    useEffect(() => {
        if (isAdmin) {
            setIsAdminTable(true);
            setOrgDistSchoolHeader('Organization, District');
        }
    }, [isAdmin]);

    useEffect(() => {
        if (!filteredItems || filteredItems.length === 0) {
            setRows([]);
            return;
        }

        let newRows = filteredItems.map((row) => {
            return {
                ...row,
                OrgDistSchool: getFormattedInfo(row),
                PayoutStatus: row.PayoutStatus === 1 ? 'Not Started' : row.PayoutStatus === 2 ? 'In Progress' : row.PayoutStatus === 3 ? 'Completed' : '',
                PayoutDate: row.PayoutDate === null ? '' : getFormatedDate(row.PayoutDate),
            }
        });
        //if admin only add unique org and district to remove duplicate rows
        if (isAdmin) {
            const uniqueOrgDistSchool = new Set() //initiate new set for better performance
            const uniqueRows = newRows.filter((row) => {
                if (uniqueOrgDistSchool.has(row.OrgDistSchool)) {
                    return false
                } else {
                    uniqueOrgDistSchool.add(row.OrgDistSchool);
                    return true
                }
            })
            newRows = uniqueRows;
        }

        setRows(newRows);

    }, [filteredItems]);



    const getFormattedInfo = (payoutSchool) => {
        let org = payoutSchool.Client?.DisplayName;
        let district = payoutSchool.District?.Name;
        let school = payoutSchool.School?.Name;

        let formatedInfo = org + ', ' + district;

        if (!isAdmin) {
            formatedInfo += ', ' + school;
        }

        return formatedInfo;
    }

    const getFormatedDate = (dateInput) => {

        let date = new Date(dateInput);
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }

    const handleCheckDisableAction = (row, action) => {
        if (action.toLowerCase() == 'custom1') {
            return row.PayoutStatus !== "Completed"
        } else if (action.toLowerCase() == 'custom2') {
            return row.PayoutStatus === "Not Started"
        }
        else if (action.toLowerCase() == 'edit') {
            if (row?.SchoolYearIdentifier < 2024) {
                return row.LegacyReferenceId
            }
        }
        else {
            return false;
        }
    }
    const checkHideAction = (row, action) => {
        if (action.toLowerCase() == 'edit') {
            let test = !appUserMgr?.canWrite('PayoutTeacher');
            return !appUserMgr?.canWrite('PayoutTeacher')
        } else return false
    }
    return <>
        <div className="contentDisplay-list payout-list">
            <TableControl
                columns={columns}
                data={rows}
                useIntegratedPaging={['10', '25', '50', '100']}
                onEdit={(row) => { onEdit(row) }}
                actionIsLoadingCheck={actionIsLoading}
                checkDisableAction={handleCheckDisableAction}
                checkHideAction={checkHideAction}
                printing={true}
                onCustom1={{
                    action: (row) => <div className='icon-wrapper'>
                        {actionIsLoading.id === row.Id && actionIsLoading.action === 'custom1' ? <Loading type='table-spinner' size={'1.25rem'} /> : <FontAwesomeIcon title='Payout Export'
                            className={`btn-icon-fa ${row.PayoutStatus !== 'Completed' ? 'disabled' : ''}`} icon={faCalculator}
                            onClick={async () => {
                                if (!handleCheckDisableAction(row, 'custom1')) {
                                    // setActionIsLoading({ action: "custom1", id: row.Id })
                                    onCalculate(row)
                                }
                            }} />
                        }
                    </div>
                }}
                onCustom2={{
                    action: (row) =>
                        <div className="icon-wrapper">
                            {
                                actionIsLoading.id === row.Id && actionIsLoading.action === 'custom2' ? <Loading type='table-spinner' size={'1.25rem'} /> : <FontAwesomeIcon title='Export' className={`btn-icon-fa ${row.PayoutStatus === 'Not Started' ? 'disabled' : ''}`} icon={faFileExport}
                                    onClick={async () => {
                                        if (!handleCheckDisableAction(row, 'custom2')) {
                                            // setActionIsLoading({ action: "custom2", id: row.Id })
                                            onExport(row)
                                        }
                                    }} />
                            }
                        </div>
                }}
            />
        </div>
    </>
}

export default PayoutTable;