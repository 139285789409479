import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useCheckAuth, useDefaultRoute } from '../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { useHookstate } from '@hookstate/core';
import globalAppUserStore, { APIStatusType } from '../../stores/globalAppUserStore';
import InitLayout from '../layout/InitLayout';
import Loading from '../Loading';
import { useParams } from 'react-router-dom';

export default function Login() {

    useDefaultRoute();
    const [params, setParams] = useSearchParams({});
    const location = useLocation();
    const appUserState = useHookstate(globalAppUserStore)
    const appUser = appUserState.get();
    const { instance } = useMsal();
    const isAuth = useCheckAuth();

    useEffect(() => {
        if (!appUser.authRedirectRunning && !isAuth) {
            appUserState.merge({ authRedirectRunning: true });
            try {
                instance.loginRedirect({
                    redirectStartPage: `${location.pathname}`,

                    extraQueryParameters: {
                        cancelUrl: encodeURIComponent(!window.location.href.includes('?b=1') ? `${window.location.href}?b=1` : window.location.href),
                    },
                }).then(r => {
                    appUserState.merge({ authRedirectRunning: false });
                }).catch(e => {
                    if (params.get('b') == 1) {
                        window.location.reload();
                    }
                })
            }
            catch (err) {
                appUserState.merge({ authRedirectRunning: false });
            }
        }
    }, [isAuth]);

    return <InitLayout><div className='init__content'><Loading type='default' size='5rem' /><h5>Signing in...</h5></div></InitLayout>;
}