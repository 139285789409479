import _ from "lodash";
import react, { useState } from "react";
import { useEffect } from "react";
import { faClose as completeIcon, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { faCopy as copyIcon } from '@fortawesome/free-solid-svg-icons'
import { TableControl } from "../../../../components/controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppUserManager } from "../../../../hooks/useManagers";
import './table.scss';
import Loading from "../../../../components/Loading";



const ContentSyncTable = ({ filteredItems, onView, onDelete, onRetry, onReload, refreshing }) => {
    const [rows, setRows] = useState([]);
    const appUserMgr = useAppUserManager();
    const [columns] = useState([
        {
            header: 'CSV File Name',
            dataProp: 'FileName',
            width: '25%',
            canSort: true
        },

        {
            header: 'Count',
            dataProp: 'RecordCount',
            width: '8%',
            canSort: true
        },
        {
            header: 'Status',
            dataProp: 'Status',
            width: '35%',
            datatype: 'jsx',
            canSort: false
        },
        {
            header: 'Start Date',
            dataProp: 'StartDateTime',
            width: '10%',
            datatype: 'dateTime',
            canSort: true
        },
        {
            header: 'End Date',
            dataProp: 'EndDateTime',
            datatype: 'dateTime',
            width: '10%',
            canSort: true
        },
        {
            header: 'Retry?',
            dataProp: 'IsRetry',
            width: '5%',
            canSort: true,
            isBool: true
        },
    ]);


    const statusClass = (status) => {
        return `status status-${status?.replace(' ', '-')}`;
    }

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (fi) => {
                newRows.push({
                    ...fi,
                    EndDateTime: fi.EndDateTime?.toString()?.indexOf("0001") == -1 ? fi.EndDateTime : null,
                    Name: fi.LoadedByUser?.FullName ?? fi.CreatedBy,
                    IsRetry: fi.ParentContentSyncId ? 'Yes' : 'No',
                    Status: <div className={statusClass(fi.Status)}>{fi.Status}</div>
                });
            });
            setRows(newRows);
        }
    }, [filteredItems]);
    {/*onCustom1={appUserMgr?.canDelete('ContentSync') && { action: (row) => row.IsComplete && <FontAwesomeIcon title="Retry" className={'btn-icon-fa'} icon={copyIcon} onClick={() => onRetry(row)} /> }}*/ }
    return <>
        <div className="contentDisplay-list contentSync-table">
            <TableControl
                columns={columns}
                data={rows}
                defaultSortBy='StartDateTime'
                defaultSortDesc={false}
                permission='ContentSync'
                useIntegratedPaging={['10', '25', '50', '100']}
                onView={(row) => onView(row)}
                checkHideAction={(row, action) => {
                    if (action === 'view') {
                        return row.Id ? false : true;
                    }
                }}

                onCustom2={appUserMgr?.canDelete('ContentSync') && { action: (row) => row.IsComplete && <FontAwesomeIcon title="Delete" className={'btn-icon-fa'} icon={completeIcon} onClick={() => onDelete(row)} /> }}
                onCustom3={{ action: (row) => !row.IsComplete && <FontAwesomeIcon title="Refresh" className={refreshing ? 'btn-icon-fa' : 'fa-spin'} icon={faRefresh} onClick={() => onReload(row)} /> }}
            />
        </div>
    </>
}

export default ContentSyncTable;