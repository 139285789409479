import { useEffect, useState } from 'react';
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import { LabelControl } from '../../../../components/controls';
import Loading from '../../../../components/Loading';
import '../../payment.scss';
import './styles/payoutSettings.scss';
import { Panel, SelectPicker } from 'rsuite';
import ObservationAchievementPayRatios from './settings/ObservationAchievmentPayRatios';
import StudentAchievementPayRatios from './settings/StudentAchievementPayRatios';
import SchoolAchievementPayRatios from './settings/SchoolAchievementPayRatios';
import AchievementWeights from './settings/AchievementWeights';
import ObservationDomainWeights from './settings/ObservationDomainWeights';
import ObserverWeights from './settings/ObserverWeights';
import { useNavigate, useLocation } from 'react-router-dom';
import { CheckboxControl } from '../../../../components/controls';
import { DialogControl } from '../../../../components/controls/DialogControl';
import _ from 'lodash';

const PayoutSettings = ({ payoutTeacherConfig, payoutMgr, payoutInfo, onPrevious, onCaluclate, appUserMgr, setValidationErrors, handleOnComplete }) => {
    const [loading, setLoading] = useState(true);
    const [selectedOrg, setSelectedOrg] = useState();
    const [selectedDistrict, setSelectedDistrict] = useState();
    const [selectedSchool, setSelectedSchool] = useState();
    const [payoutConfigSettings, setPayoutConfigSettings] = useState();
    const [achievementWeightData, setAchievementWeightData] = useState();
    const [observerWeightData, setObserverWeightData] = useState();
    const [observationDomainWeightData, setObservationDomainWeightData] = useState();
    const [observationAchievementPayRatiosData, setObservationAchievementPayRatiosData] = useState();
    const [observationNonAchievementPayRatiosData, setObservationNonAchievementPayRatiosData] = useState();
    const [studentAchievementPayRatiosData, setStudentAchievementPayRatiosData] = useState();
    const [schoolAchievementPayRatiosData, setSchoolAchievementPayRatiosData] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState();
    const [forceValidation, setForceValidation] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedSchoolConfig, setSelectedSchoolConfig] = useState();
    const [selectedPayoutConfigId, setSelectedPayoutConfigId] = useState();
    const [schoolPayoutConfigDataSet, setSchoolPayoutConfigDataSet] = useState([]);
    const [hasPayoutId, setHasPayoutId] = useState(false);
    const location = useLocation();
    const isMetricsPage = location.pathname.includes('Metrics');
    const [applySettingsToDistrict, setApplySettingsToDistrict] = useState(false);
    const [openSaveCompleteDialog, setOpenSaveCompleteDialog] = useState(false);

    ///FOR VALIDATION ///
    const [totalObservedWeights, setTotalObservedWeights] = useState([])
    const [totalAchievementWeights, SetTotalAchievementWeights] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (payoutMgr && payoutTeacherConfig) {
            let ignore = false;
            async function fetchData() {
                if (!ignore) {
                    payoutMgr._payoutConfigurationItemMgr.query("x => x.SchoolYearIdentifier == sy && x.ClientId == clientId && x.IsActive == true && x.IsDeleted == false && x.SchoolId != schoolId",
                        {
                            sy: payoutMgr.AppUserState.selectedSchoolYear,
                            clientId: payoutTeacherConfig?.PayoutConfiguration?.ClientId ? payoutTeacherConfig.PayoutConfiguration.ClientId : payoutTeacherConfig.ClientId,
                            schoolId: payoutTeacherConfig?.PayoutConfiguration?.SchoolId ? payoutTeacherConfig.PayoutConfiguration.SchoolId : payoutTeacherConfig.SchoolId
                        }).then(configs => {
                            if (configs.Items.length > 0) {
                                //sort through and pull out Id, clientId, schoolId, schoolYearId
                                let arr = []
                                configs.Items.forEach(c => {
                                    let newConfig = {
                                        Id: c.Id,
                                        ClientId: c.ClientId,
                                        SchoolId: c.SchoolId,
                                        DistrictId: c.School.DistrictId,
                                        SchoolYearId: c.SchoolYearId,
                                        SchoolName: c.School?.Name,
                                        label: c.School?.Name,
                                        value: c.Id
                                    }
                                    arr.push(newConfig);
                                })
                                //push into data set of drop down
                                setSchoolPayoutConfigDataSet(arr);
                            }
                        });
                    setSelectedPayoutConfigId(payoutTeacherConfig.PayoutConfiguration?.Id);
                    setPayoutConfigSettings(payoutTeacherConfig.PayoutConfiguration);
                    setAchievementWeightData(payoutTeacherConfig.PayoutAchievementWeights);
                    setObserverWeightData(payoutTeacherConfig.PayoutObserverWeights);
                    setObservationDomainWeightData(payoutTeacherConfig.PayoutObservationDomainWeights);
                    setObservationAchievementPayRatiosData(payoutTeacherConfig.PayoutObservationAchievementPayRatios);
                    setObservationNonAchievementPayRatiosData(payoutTeacherConfig.PayoutObservationNonAchievementPayRatios);
                    setStudentAchievementPayRatiosData(payoutTeacherConfig.PayoutStudentAchievementPayRatios);
                    setSchoolAchievementPayRatiosData(payoutTeacherConfig.PayoutSchoolAchievementPayRatios);

                }
            }
            fetchData();
            return () => { ignore = true }; //cleanup
        }
    }, [payoutMgr, payoutTeacherConfig])

    const loadPayoutConfig = (configObj) => {
        setIsLoading(true);
        payoutMgr.GetPayoutConfiguration(configObj.ClientId, configObj.DistrictId, configObj.SchoolId).then((response) => {
            if (response.Items?.length > 0) {
                setIsLoading(false);
                let payoutConfig = response.Items[0];
                setSelectedPayoutConfigId(payoutConfig.PayoutConfiguration?.Id);
                setPayoutConfigSettings({ ...payoutConfig.PayoutConfiguration, Id: payoutTeacherConfig.Id, UniqueId: payoutTeacherConfig.UniqueId, _id: payoutTeacherConfig.Id });
                setAchievementWeightData({ ...payoutConfig.PayoutAchievementWeights, PayoutConfigurationId: payoutTeacherConfig.Id });
                setObserverWeightData(payoutConfig.PayoutObserverWeights.map(x => { return { ...x, PayoutConfigurationId: payoutTeacherConfig.Id } }));
                setObservationDomainWeightData(payoutConfig.PayoutObservationDomainWeights.map(x => { return { ...x, PayoutConfigurationId: payoutTeacherConfig.Id } }));
                setObservationAchievementPayRatiosData(payoutConfig.PayoutObservationAchievementPayRatios.map(x => { return { ...x, PayoutConfigurationId: payoutTeacherConfig.Id } }));
                setObservationNonAchievementPayRatiosData(payoutConfig.PayoutObservationNonAchievementPayRatios.map(x => { return { ...x, PayoutConfigurationId: payoutTeacherConfig.Id } }));
                setStudentAchievementPayRatiosData(payoutConfig.PayoutStudentAchievementPayRatios.map(x => { return { ...x, PayoutConfigurationId: payoutTeacherConfig.Id } }));
                setSchoolAchievementPayRatiosData(payoutConfig.PayoutSchoolAchievementPayRatios.map(x => { return { ...x, PayoutConfigurationId: payoutTeacherConfig.Id } }));
            }
        });

    }
    useEffect(() => {
        if (selectedSchoolConfig) {
            let configObj = schoolPayoutConfigDataSet.find(x => x.Id === selectedSchoolConfig)
            loadPayoutConfig(configObj)
        }
    }, [selectedSchoolConfig])

    const handleSchoolConfigSelect = (value) => {
        setSelectedSchoolConfig(value)
    }

    const handleOnChange = (value, field) => {
        const updatedPayoutConfig = { ...payoutConfigSettings };
        updatedPayoutConfig[field] = value;
        setPayoutConfigSettings(updatedPayoutConfig);
    }

    const handleOnSave = async (isCalculate) => {
        let errors = validationCheck();
        setSaving(true);
        if (errors.length === 0) {
            //OverallEffectivenessScoreCheck
            var oeScoreValue = payoutConfigSettings["OverallEffectivenessScore"];
            var updatedEoScore = oeScoreValue == "" ? 0 : oeScoreValue;
            payoutConfigSettings["OverallEffectivenessScore"] = updatedEoScore

            const payoutConfig = {
                Id: payoutConfigSettings?.Id,
                PayoutConfiguration: payoutConfigSettings,
                PayoutAchievementWeights: achievementWeightData,
                PayoutObserverWeights: observerWeightData,
                PayoutObservationDomainWeights: observationDomainWeightData,
                PayoutObservationAchievementPayRatios: observationAchievementPayRatiosData,
                PayoutObservationNonAchievementPayRatios: observationNonAchievementPayRatiosData,
                PayoutStudentAchievementPayRatios: studentAchievementPayRatiosData,
                PayoutSchoolAchievementPayRatios: schoolAchievementPayRatiosData,
                ApplySettingsToAllSchoolsInDistrict: applySettingsToDistrict,
                DistrictId: payoutInfo.District?.Id,
                SchoolYear: payoutInfo.SchoolYearIdentifier
            }
            const result = await payoutMgr.SavePayoutConfiguration(payoutConfig);
            if (result.Success && !isCalculate) {
                setSaving(false);
                setOpenSaveCompleteDialog(true);
                return true
            } else if (result.Success && isCalculate) {
                return true
            }
            else if (!result.Success) {
                setErrorMessage([result.Message.DisplayMessage]);
                setSaving(false);
                return false
            }
        }
        else {
            setSaving(false);
            setValidationErrors(errors)
            return false
        }
    }
    const validationCheck = () => {
        let errors = []
        if (totalAchievementWeights.achievementSum != 100) {
            errors.push('Total Achievement Weight  does not total to 100')
        }
        if (totalAchievementWeights.nonAchievementSum != 100) {
            errors.push('Total Non-Achievement Weight  does not total to 100')
        }
        totalObservedWeights.forEach((x, i) => {
            //1. Career 2. Mentor 3. Master. 4. Self
            const positionMap = {
                0: 'Career Teacher',
                1: 'Mentor Teacher',
                2: 'Master Teacher',
                3: 'Self Evaluation'
            }
            if (x !== 100) {
                errors.push(`Observer Weights for ${positionMap[0]} does not total to 100`)
            }
        })
        if (_.sumBy(_.groupBy(observationDomainWeightData, x => x.ObservedPositionTypeName)['Career Teacher'], y => parseInt(y.Weight)) !== 100) {
            errors.push('Observation Domain Weights for Career Teacher does not total to 100')
        }
        if (_.sumBy(_.groupBy(observationDomainWeightData, x => x.ObservedPositionTypeName)['Mentor Teacher'], y => parseInt(y.Weight)) !== 100) {
            errors.push('Observation Domain Weights for Mentor Teacher does not total to 100')
        }
        if (_.sumBy(_.groupBy(observationDomainWeightData, x => x.ObservedPositionTypeName)['Master Teacher'], y => parseInt(y.Weight)) !== 100) {
            errors.push('Observation Domain Weights for Master Teacher does not total to 100')
        }
        return errors
    }

    const handleOnCancel = () => {
        if(isMetricsPage) {
            navigate(`/admin/school/${payoutInfo?.School?.Id}`);
        } else {
            navigate(`/admin/Payout/Teacher`);
        }
    }

    const handleOnCalculate = async () => {
        let errors = validationCheck();
        if (errors.length === 0) {
            await onCaluclate(formValues);
            setSaving(false)
            return true
        }
        else {
            setValidationErrors(errors)
            setSaving(false)
            return false
        }
    }

    const handleMetricPrevious = () => {
        navigate(`/admin/school/${payoutInfo?.School?.Id}`);
    }

    return <div className='payout-settings'>
        <div className='payout-container'>
            <DialogControl title={`Configuration Saved`}
                subTitle={"The Metric Coniguration has been saved successfully. Click OK to return to the previous page."}
                openDialog={openSaveCompleteDialog}
                okText={`OK`}
                onCancel={() => {
                    setOpenSaveCompleteDialog(false)
                }}
                onOk={() => handleOnCancel()}>
            </DialogControl>
            <div className='title-container'>
                <div className='payout-title'>
                    <h2>Teacher Metrics Configuration</h2>
                    <h5>{payoutInfo?.Client?.Name} &gt; {payoutInfo?.District?.Name} &gt; {payoutInfo?.School?.Name}</h5>
                </div>
                <div className='school-copy-dropdown'>
                    <div className='school-copy-title'>Use Existing School Configuration: </div>
                    <SelectPicker data={schoolPayoutConfigDataSet} searchable={false} value={selectedSchoolConfig} onChange={handleSchoolConfigSelect} className={'picker-style'} />
                </div>
            </div>
            {!isLoading ?
                <>
                    <Panel header="Payout Settings" bordered>
                        <div className="container">
                            <div className='top-line'>
                                <div className='school-info'>
                                    <div className='headerColor'>School: </div>
                                    <div class="headerValue">{payoutInfo?.School?.Name}</div>
                                </div>
                                <div className='year-info'>
                                    <div className='headerColor'>School Year: </div>
                                    <div class="headerValue">{payoutInfo?.SchoolYearIdentifier}</div>
                                </div>
                            </div>
                            <div className='bottom-line'>
                                <InputField title="School Achievement Score" forceValidate={forceValidation} value={payoutConfigSettings?.SchoolAchievementScore}
                                    onChange={(value, fieldName) => handleOnChange(value, fieldName)} groupId={'PAYOUT_SETTINGS'} placeholder={'School Achievement Score'} type="number" fieldName="SchoolAchievementScore" />
                                <InputField title="Teacher Base Allotment" forceValidate={forceValidation} value={payoutConfigSettings?.TeacherBaseAllotment}
                                    onChange={(value, fieldName) => handleOnChange(value, fieldName)} groupId={'PAYOUT_SETTINGS'} placeholder={'Teacher Base Allotment'} type="number" fieldName="TeacherBaseAllotment" />
                                <InputField title="Overall Effectiveness Score" forceValidate={forceValidation} value={payoutConfigSettings?.OverallEffectivenessScore === 0 ? '' : payoutConfigSettings?.OverallEffectivenessScore}
                                    onChange={(value, fieldName) => handleOnChange(value, fieldName)} groupId={'PAYOUT_SETTINGS'} placeholder={'Overall Effectiveness Score'} type="number" fieldName="OverallEffectivenessScore" />
                            </div>
                        </div>
                    </Panel>
                    <AchievementWeights
                        data={achievementWeightData}
                        setData={setAchievementWeightData}
                        totalAchievementWeights={totalAchievementWeights}
                        SetTotalAchievementWeights={SetTotalAchievementWeights} />
                    <ObserverWeights data={observerWeightData} setData={updatedData => setObserverWeightData(updatedData)} setTotalObservedWeights={setTotalObservedWeights} totalObservedWeights={totalObservedWeights} />
                    <ObservationDomainWeights data={observationDomainWeightData} setData={updatedData => setObservationDomainWeightData(updatedData)} />
                    <ObservationAchievementPayRatios observeAchievement={observationAchievementPayRatiosData} setAchieveData={updatedData => setObservationAchievementPayRatiosData(updatedData)}
                        observeNonAchievement={observationNonAchievementPayRatiosData} setNonAchieveData={updatedData => setObservationNonAchievementPayRatiosData(updatedData)} />

                    <div className='student-school-ratio-container'>
                        <Panel header="Student & School Achievement Pay Ratios" bordered>
                            <StudentAchievementPayRatios data={studentAchievementPayRatiosData} selectedPayoutConfigId={selectedPayoutConfigId}
                                setData={updatedData => setStudentAchievementPayRatiosData(updatedData)} />
                            <SchoolAchievementPayRatios data={schoolAchievementPayRatiosData} selectedPayoutConfigId={selectedPayoutConfigId}
                                setData={updatedData => setSchoolAchievementPayRatiosData(updatedData)} />
                        </Panel>
                    </div>

                    {error &&
                        <div className='error-msg'>*{errorMessage}</div>
                    }
                    <div className='button-container'>
                        {isMetricsPage && 
                            <>
                            <div className='apply-all-settings-container'>
                                <InputField
                                    title={`Apply Settings to All Schools in ${payoutInfo?.District?.Name}?`}
                                    value={applySettingsToDistrict}
                                    disableError={true}
                                    fieldName="applySettingsToDistrict"
                                    onChange={() => setApplySettingsToDistrict(!applySettingsToDistrict)}>
                                    <CheckboxControl />
                                </InputField>
                            </div>
                            </>
                        }
                        <div className='btn-wrapper-left screen-footer__btn'>
                            <ButtonControl disabled={saving} type={'cancel'} onClick={isMetricsPage ? handleMetricPrevious : onPrevious}>Previous</ButtonControl>
                        </div>
                        
                        <div className='btn-wrapper-left screen-footer__btn'>
                            <ButtonControl disabled={saving} type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl>
                        </div>
                        <div className='btn-wrapper-left screen-footer__btn'>
                            <ButtonControl loading={saving} type={'okay'} onClick={() => handleOnSave()}>Save</ButtonControl>
                        </div>
                        {!isMetricsPage &&
                            <>
                            <div className='btn-wrapper-left screen-footer__btn'>
                                <ButtonControl loading={saving} type={'okay'} onClick={async () => {
                                    let saveResult = await handleOnSave(true)
                                    if (saveResult) {
                                        await handleOnCalculate()
                                    }
                                }}>Calculate</ButtonControl>
                            </div>
                            {appUserMgr.canExecute('CompletePayoutTeacher') && 
                                <div className='btn-wrapper-left screen-footer__btn'>
                                    <ButtonControl loading={saving} disabled={(payoutInfo.PayoutStatus == "Completed" || payoutInfo.PayoutStatus == 3)} type={'okay'} onClick={async () => {
                                        setSaving(true)
                                        await handleOnComplete();
                                        setSaving(false)
                                    }}>Complete</ButtonControl>
                                </div>
                            }
                            </>
                        }
                    </div>
                </>
                :
                <>
                    <div style={{ textAlign: 'center', marginTop: '24rem' }}>
                        <Loading type='default' size={'5rem'}></Loading><h5 style={{ paddingTop: '1rem' }}>Loading data...</h5>
                    </div>
                </>
            }
        </div>
    </div>

}

export default PayoutSettings;