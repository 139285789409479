import { React, useEffect, useState } from 'react';
import { Panel } from 'rsuite';
import { InputField } from '../../../../../components/controls/InputField';
import { LabelControl } from '../../../../../components/controls';
import './../styles/achievementWeights.scss';
import _ from 'lodash';

const AchievementWeights = ({
    data,
    setData,
    totalAchievementWeights,
    SetTotalAchievementWeights
}) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [achievementWeightData, setAchievementWeightData] = useState();


    useEffect(() => {
        if (data) {
            setAchievementWeightData(data);
            const calculatedSums = Object.entries(data).reduce((sums, [key, value]) => {
                if (key.toLowerCase().includes("nonachievement")) {
                    sums.nonAchievementSum += parseInt(value)
                } else if (key.toLowerCase().includes('achievement')) {
                    sums.achievementSum += parseInt(value)
                }
                return sums
            }, { achievementSum: 0, nonAchievementSum: 0 })
            SetTotalAchievementWeights(calculatedSums)
        }
    }, [data]);

    const handleOnChange = (value, field) => {
        if (_.isNil(value) || value === '') {
            value = 0
        }
        const updatedAchievementWeightData = { ...achievementWeightData };
        updatedAchievementWeightData[field] = value;
        setData(updatedAchievementWeightData);
    }
    return (
        <>
            <Panel header="Achievement Weights" bordered>
                <div className='achievement-table'>
                    <table className="achievement-weights-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Achievement</th>
                                <th>Non-Achievement</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='row-header'>Teacher Observations (SKR)</td>
                                <td>
                                    <InputField fieldName={'TeacherObservationSkrAchievementAmount'} placeholder={'0'} value={achievementWeightData?.TeacherObservationSkrAchievementAmount}
                                        onChange={(value, fieldName) => handleOnChange(value, fieldName)} type="number" disableError={true} />
                                </td>
                                <td>
                                    <InputField fieldName={'TeacherObservationSkrNonAchievementAmount'} placeholder={'0'} value={achievementWeightData?.TeacherObservationSkrNonAchievementAmount}
                                        onChange={(value, fieldName) => handleOnChange(value, fieldName)} type="number" disableError={true} />
                                </td>
                            </tr>
                            <tr>
                                <td className='row-header'>Student Achievement</td>
                                <td>
                                    <InputField fieldName={'StudentAchievementAmount'} placeholder={'0'} value={achievementWeightData?.StudentAchievementAmount}
                                        onChange={(value, fieldName) => handleOnChange(value, fieldName)} type="number" disableError={true} />
                                </td>
                                <td>
                                    <InputField fieldName={'StudentAchievementNonAchievementAmount'} placeholder={'0'} value={achievementWeightData?.StudentAchievementNonAchievementAmount}
                                        onChange={(value, fieldName) => handleOnChange(value, fieldName)} type="number" disableError={true} />
                                </td>
                            </tr>
                            <tr>
                                <td className='row-header'>School Achievement</td>
                                <td>
                                    <InputField fieldName={'SchoolAchievementAchievementAmount'} placeholder={'0'} value={achievementWeightData?.SchoolAchievementAchievementAmount}
                                        onChange={(value, fieldName) => handleOnChange(value, fieldName)} type="number" disableError={true} />
                                </td>
                                <td>
                                    <InputField fieldName={'SchoolAchievementNonAchievementAmount'} placeholder={'0'} value={achievementWeightData?.SchoolAchievementNonAchievementAmount}
                                        onChange={(value, fieldName) => handleOnChange(value, fieldName)} type="number" disableError={true} />
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <th>Total: {totalAchievementWeights?.achievementSum}</th>
                                <th>Total: {totalAchievementWeights?.nonAchievementSum}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Panel>
        </>
    );
}

export default AchievementWeights;