import { useEffect, useState } from 'react';
import './details.scss';
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import _ from 'lodash';
import { CheckboxControl, DatePickerControl, SelectListControl } from '../../../../components/controls';
import { useValidationManager } from '../../../../hooks/useValidation';
import Loading from '../../../../components/Loading';
import { CheckTreePicker } from 'rsuite';
import { DialogControl } from '../../../../components/controls/DialogControl';
import { treeViewUserSelect } from '../../../contentLibrary/utils/componentHelpers';
import { v4 as uuidv4 } from 'uuid';
import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';
import { useOrgManager } from '../../../../hooks/useManagers';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { FormatUTCDateM, FormatUTCDateTime, FormatUTCDateTimeM } from '../../../../utilities/DateFormater';

const GeneralSurveyDetails = ({ params, authMgr, canWrite, generalSurveyConfigMgr, survey, onCancel, onSave, onErrors, onValidationErrors }) => {
    const [surveyItem, setSurveyItem] = useState(survey ?? generalSurveyConfigMgr?.DefaultItem);
    const [saving, setSaving] = useState(false);
    const [forceValidate, setForceValidate] = useState(false);
    const [formattedSurveyTemplates, setFormattedSurveyTemplates] = useState(null)
    const [userData, setUserData] = useState(null)
    const [orgTree, setOrgTree] = useState(null)
    const [orgTreeData, setOrgTreeData] = useState(null)
    const [openUserDialogue, setOpenUserDialogue] = useState(null)
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [filterInput, setFilterInput] = useState('')
    const validationMgr = useValidationManager(false, 'GENERALSURVEY');
    const orgMgr = useOrgManager();

    const [startDate, setStartDate] = useState(null);

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    useEffect(() => {
        if (generalSurveyConfigMgr && orgMgr) {
            generalSurveyConfigMgr?.SurveyTemplateFormatted.then(r => {
                setFormattedSurveyTemplates(r)
            })
            handleInital();
            if (surveyItem.UserGeneralSurveys) {
                setSelectedUsers(_.map(surveyItem.UserGeneralSurveys, user => user.UserId))
            }
        }
    }, [generalSurveyConfigMgr, orgMgr, params])

    useEffect(() => {
        if (!startDate) {
            if (surveyItem && !surveyItem?.StartDate) {
                setStartDate(new Date());
            }
            else if (surveyItem && surveyItem?.StartDate) {
                setStartDate(FormatUTCDateTimeM(surveyItem?.StartDate));
            }
        }
    }, [surveyItem?.StartDate])


    const handleInital = async () => {
        let result = await orgMgr.loadOrgUserTree().then(r => {
            setUserData(orgMgr.UsersOnly())
            setOrgTreeData(r)
            return r
        })
        // For some reason deepCopyResult has an empty array of users right off the bat?
        if (result && surveyItem.UserGeneralSurveys.length > 0) {
            const deepCopyResult = JSON.parse(JSON.stringify(result))
            recursiveRemovalFunction(deepCopyResult, _.map(surveyItem.UserGeneralSurveys, user => user.UserId))
            setOrgTree(angryTree(deepCopyResult))
        } else setOrgTree(result)
    }

    const handleOnSave = async (e) => {
        const validateResult = validationMgr.checkIsGroupValid(surveyItem);
        if (validateResult?.length === 0) {
            setForceValidate(false);
            if (onSave) {
                let result;
                let updateUserResult;
                setSaving(true);
                surveyItem.StartDate = moment(startDate).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                if (e === "NewSurvey") {
                    result = await onSave(surveyItem, true);

                } else {
                    result = await onSave(surveyItem);
                    if (surveyItem.Id) {
                        updateUserResult = await generalSurveyConfigMgr.UpdateUsers(surveyItem.UserGeneralSurveys)
                    }
                }

                if (result.Success) {
                    if (updateUserResult) {
                        handleOnCancel();
                    } else if (e === "NewSurvey") {
                        setOpenUserDialogue(true)
                        handleOnChange(result?.Items.first()?.Id, "Id")
                    } else handleOnCancel();
                }
                else if (onErrors) {
                    onErrors([result?.Message?.DisplayMessage ?? [...(result?.MessageDetails ?? ['Unknown Error'])]])
                }

                setSaving(false);
                return result;
            }
        }
        else if (onErrors) {
            setForceValidate(true);
            onValidationErrors(validateResult);
        }
    }

    const handleOnChange = (value, field) => {
        let updatedSurvey = { ...surveyItem };
        const spreadOrgTreeData = JSON.parse(JSON.stringify(orgTreeData))
        let spreadOrgTree = JSON.parse(JSON.stringify(orgTree))

        if (field === "DELETE") {
            const removedValueIndex = _.findIndex(updatedSurvey.UserGeneralSurveys, (v) => v.UserId === value)
            const removedValue = _.find(updatedSurvey.UserGeneralSurveys, (v) => v.UserId === value)
            setSelectedUsers(_.map(_.filter(selectedUsers, user => {
                if (user !== value && !removedValue?.IsDeleted) {
                    return user
                }
            })))
            if (removedValue?.IsDeleted === undefined) {
                updatedSurvey.UserGeneralSurveys?.splice(removedValueIndex, 1)
            } else {
                removedValue['IsDeleted'] = true
                updatedSurvey.UserGeneralSurveys?.splice(removedValueIndex, 1, removedValue)
            }
            const usersSelected = [...selectedUsers]
            _.remove(usersSelected, user => user === value)
            setSelectedUsers(usersSelected)
            recursiveRemovalFunction(spreadOrgTreeData, updatedSurvey.UserGeneralSurveys)
            setOrgTree(angryTree(spreadOrgTreeData))
        }
        else if (field === "UserGeneralSurveys") {
            const formattedUsers = value.reduce((users, v) => {
                const userExist = _.find(updatedSurvey.UserGeneralSurveys, d => v === d.UserId)
                if (userExist) {
                    const removedValueIndex = _.findIndex(updatedSurvey.UserGeneralSurveys, x => x.UserId === v)
                    userExist['IsDeleted'] = false
                    updatedSurvey.UserGeneralSurveys?.splice(removedValueIndex, 1, userExist)
                } else users.push({
                    UserId: v,
                    GeneralSurveyId: surveyItem?.Id,
                    Id: _.find(updatedSurvey.UserGeneralSurveys, user => user.Id === value) ?? uuidv4(),
                    SurveyStartDate: surveyItem?.StartDate
                })
                return users
            }, [])
            updatedSurvey[field] = updatedSurvey[field]?.concat(formattedUsers);
            recursiveRemovalFunction(spreadOrgTree, selectedUsers)

            setOrgTree(angryTree(spreadOrgTree))
        }
        else {
            if (field === 'StartDate') {
                setStartDate(value);
            } else {
                updatedSurvey[field] = value;
            }
        }
        setSurveyItem(updatedSurvey);
    }

    const recursiveRemovalFunction = (branch, users) => {
        _.forEach(branch, b => {
            if (b?.Children?.first()?.NodeType.toLowerCase() === "user") {
                b.Children = _.map(_.filter(b.Children, child => {
                    if (!users.includes(child.UserId) || (_.find(surveyItem.UserGeneralSurveys, x => x.UserId === child.UserId)?.IsDeleted === true && users.includes(child.UserId))) {
                        return child
                    }
                }))
            }
            recursiveRemovalFunction(b.Children, users)
        })
    }
    const angryTree = (branch) => {
        let newBranch = JSON.parse(JSON.stringify(branch))
        _.forEach(newBranch, (org, i) => {
            if (org.Children.length > 0 && org.Children.first()?.NodeType.toLowerCase() !== "user") {
                org.Children = _.map(_.filter(org.Children, district => {
                    district.Children = _.map(_.filter(district?.Children, school => {
                        if (school.Children?.length > 0) {
                            return school
                        }
                    }))
                    if (district.Children?.length > 0) {
                        return district
                    }
                }))
            } else if (org?.Children.first()?.NodeType.toLowerCase() !== "user") {
                newBranch.splice(i, 0)
            }
        })
        newBranch = _.filter(newBranch, b => b.Children.length > 0)
        return newBranch
    }
    const recursiveFunction = (activeNode, vals) => {
        activeNode.Children = _.map(activeNode.Children, child => {
            return {
                ...child,
                check: !child.check
            }
        }
        )
        if (!_.isNil(activeNode.Children)) {
            activeNode.Children.forEach((chd) => {
                recursiveFunction(chd, chd.Id)
            });
        }
    }

    const onTreeSelect = (activeNode, vals) => {
        recursiveFunction(activeNode)
        treeViewUserSelect(activeNode, vals, setSelectedUsers, selectedUsers)
    }

    return <div className='control-box-wrapper'>
        {surveyItem && <>
            <DialogControl
                openDialog={openUserDialogue}
                title={'Add Users'}
                disableOk={selectedUsers?.length === 0}
                onCancel={() => { setOpenUserDialogue(false); setSelectedUsers([]); }}
                onOk={() => {
                    setOpenUserDialogue(false)
                    handleOnChange(selectedUsers, "UserGeneralSurveys")
                }}
                okText={'add'}
            >
                <InputField>
                    {orgTree && <CheckTreePicker
                        height={320}
                        data={orgTree ?? []}
                        valueKey="Id"
                        labelKey="Name"
                        childrenKey="Children"
                        defaultValue={_.map(selectedUsers, user => `u-${user}`)}
                        onClean={() => onTreeSelect("CLEAN")}
                        onSelect={onTreeSelect}
                        placement="top"
                        className="general-survey-tree-picker"
                        searchable={true}
                    />}
                </InputField>
            </DialogControl>
            <div className='control-box box-two-column'>
                <InputField forceValidate={forceValidate} type="text" value={surveyItem?.Name} title="Title" fieldName="Name" groupId={'GENERALSURVEY'} onChange={handleOnChange} />
                <InputField forceValidate={forceValidate} type="textarea" maxLength={500} value={surveyItem?.Description} title="Survey Instructions" fieldName="Description" groupId={'GENERALSURVEY'} onChange={handleOnChange} />
                {(generalSurveyConfigMgr && formattedSurveyTemplates) ? <InputField forceValidate={forceValidate} value={surveyItem?.SurveyTemplateId} title="Template Name" fieldName="SurveyTemplateId" groupId={'GENERALSURVEY'} onChange={handleOnChange} >
                    <SelectListControl textValuePairs={formattedSurveyTemplates ?? []} />
                </InputField> : <Loading />}
                {startDate && <InputField title="Start Date" forceValidate={forceValidate} value={startDate} maxLength={100} onChange={handleOnChange} groupId={'GENERALSURVEY'} fieldName="StartDate"  >
                    <DatePickerControl includeTime={true} />
                </InputField>}
                <InputField title="Active?" value={(surveyItem?.IsActive === undefined || surveyItem?.IsActive === null) ? true : surveyItem?.IsActive} fieldName="IsActive" groupId={'GENERALSURVEY'} onChange={handleOnChange} >
                    <CheckboxControl />
                </InputField>
            </div>
            <div className='control-box box-two-column role-perm-editor__wrapper'>
                <div className='role-perm-editor__header'>
                    <h3>Users</h3>
                    <ButtonControl disabled={!surveyItem.Name || !surveyItem.StartDate || !surveyItem.Description || !surveyItem.SurveyTemplateId} onClick={() => surveyItem.Id ? setOpenUserDialogue(true) : handleOnSave("NewSurvey")}>Add Users</ButtonControl>
                </div>
                <div className='role-perm-editor__search'>
                    <InputField
                        type="text"
                        value={filterInput}
                        fieldName='search'
                        disableError={true}
                        placeholder='Search User Name'
                        onChange={(value) => setFilterInput(value?.toLowerCase())}>
                    </InputField>
                </div>
                <div className='detail-table-wrapper general-survey-table'>
                    <table className="__table">
                        <thead className="__head">
                            <tr>

                                <th className="__name">First Name</th>
                                <th className="__flex">Last Name</th>
                                <th className="__flex">Organization</th>
                            </tr>
                        </thead>
                        <tbody >
                            {
                                (orgMgr && generalSurveyConfigMgr) ?

                                    _.map(_.filter(userData, user => user?.Name.replace(',', '').toLowerCase().includes(filterInput) && (_.map(_.filter(surveyItem.UserGeneralSurveys, userSurvey => { if (!userSurvey.IsDeleted) { return userSurvey } }), user => `u-${user.UserId}`).includes(user.Id))), (users, i) => {
                                        const org = _.find(orgTreeData, o => o.OrgId === users.OrgId)?.Name
                                        return (
                                            <tr key={i}>
                                                <td className="__name">{users.Name.split(' ')[0]}</td>
                                                <td className="__flex">{users.Name.split(' ')[1]}</td>
                                                <td className='__flex'>{org}</td>
                                                <td className="__icon">
                                                    {<FontAwesomeIcon className={'icon btn-icon-fa'} icon={faTrashCan} onClick={() => handleOnChange(users.UserId, 'DELETE')} />}
                                                </td>
                                            </tr>)
                                    }
                                    )

                                    : <div className='role-detail-loading-wrapper'><Loading type='default' size='3rem' /></div>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
        }
        <div className='screen-footer right'>
            <div className='btn-wrapper-right screen-footer__btn'><ButtonControl disabled={saving} type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl></div>
            {canWrite && <div className='btn-wrapper-right screen-footer__btn'><ButtonControl loading={saving} type={'okay'} disabled={!surveyItem} onClick={handleOnSave}>Save</ButtonControl></div>}
        </div>
    </div >
}

export default GeneralSurveyDetails;


