import React, { useState } from 'react';
import { useEffect } from "react";
import { LabelControl } from "../../../../components/controls";
import { InputField } from '../../../../components/controls/InputField'
import { DialogControl } from '../../../../components/controls/DialogControl';
import SelectListControl from '../../../../components/controls/SelectListControl';
import { SelectPicker } from 'rsuite';

import evaluationTypes from '../../../dev/data/evaluationTypeSet';
import '../../rubricDialog.scss';
import { useRubricManager } from '../../../../hooks/useManagers';
import RubricConfigureTable from './RubricConfigureTable';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

const RubricDialog = ({ dialogState, dialogClose, onEdit, editRubricData, editClicked, schoolYears, currentYear, organizationDataSet, rubricRatingDataSet }) => {

    const [openDialog, setOpenDialog] = useState(false);
    const [rubricData, setRubricData] = useState([]);
    const [isSave, setIsSave] = useState(false);
    const [assignDomains, setAssignDomains] = useState(true);
    const [domain, setDomain] = useState();
    const [defaultDomainId, setDefaultDomainId] = useState('B6FB41AB-C432-438A-8137-F5F5B0D81099'); //TODO: add in GET API call for client's default domain
    const [rubricTypeHeaderText, setRubricTypeHeaderText] = useState();
    const [createRubricPhase, setCreateRubricPhase] = useState(1);
    const [dialogTitle, setDialogTitle] = useState('Create Rubric');
    const [dataItem, setDataItem] = useState({});
    const [rubricRatingData, setRubricRatingData] = useState([]);
    const [previousRubricRatingData, setPreviousRubricRatingData] = useState([]);
    const [isPrevious, setIsPrevious] = useState(false);
    const [name, setName] = useState();
    const [schoolYearTitle, setSchoolYearTitle] = useState();
    const [selectedSchoolYear, setSelectedSchoolYear] = useState();
    const [schoolYearData, setSchoolYearData] = useState();
    const [currentSchoolYear, setCurrentSchoolYear] = useState();
    const [defaultSchoolYear, setDefaultSchoolYear] = useState();
    const [rubricType, setRubricType] = useState();
    const [clientId, setClientId] = useState();
    const [organization, setOrganization] = useState();
    const [organizations, setOrganizations] = useState();
    const [rubricId, setRubricId] = useState();
    const [statusType, setStatusType] = useState();
    const [enableNextBtn, setEnableNextBtn] = useState(false);
    const [rubricSaved, setRubricSaved] = useState(false);
    const [isEditRubricDialog, setIsEditRubricDialog] = useState();
    const [enableStatusDropdown, setEnableStatusDropdown] = useState();
    const [archiveWarning, setArchiveWarning] = useState(false);
    const [isArchived, setIsArchived] = useState(false);
    const [innerClassName, setInnerClassName] = useState('narrow-content');
    const [rubricDomainMap, setRubricDomainMap] = useState([]);
    const [rubricIndicatorMap, setRubricIndicatorMap] = useState([]);
    const [rubricRatingMap, setRubricRatingMap] = useState([]);
    const [localRubricRatingData, setLocalRubricRatingData] = useState([]);
    const [isSaveNext, setIsSaveNext] = useState(false);
    const [btnText, setBtnText] = useState();
    const [loadingSave, setLoadingSave] = useState(false);
    const rubricMgr = useRubricManager();
    const navigate = useNavigate();

    const [statusData, setStatusData] = useState([
        { Value: 1, Text: 'Draft' },
        { Value: 2, Text: 'Published' },
        { Value: 3, Text: 'Archived' }
    ].map(item => ({ Text: item.Text, Value: item.Value })));

    const styles = { width: '100%', display: 'block', marginBottom: 10 };

    useEffect(() => {
        if (editRubricData && editRubricData.Id && editClicked) {
            //set name
            setDialogTitle('Edit Rubric Details');
            setName(editRubricData.Name);

            //set eval type
            setRubricType(editRubricData.RubricType);
            setRubricTypeHeaderText(editRubricData.evaluationType === undefined ? editRubricData.RubricType : editRubricData.evaluationType);

            //set school year
            if (editRubricData.SchoolYear) {
                setSelectedSchoolYear(editRubricData.SchoolYear.Title);
            }
            setSelectedSchoolYear(editRubricData.SchoolYearId);
            mapSchoolYearData(schoolYears);

            //TODO: need Org name property
            setOrganization(editRubricData.CustomFields);
            setClientId(editRubricData.ClientId);

            //cycle through rating set, 
            //set rating data
            setRubricRatingData(editRubricData.RubricRatingMap);

            setStatusType(editRubricData.Status);

            if (editRubricData.Status === 2) { //published
                const filteredStatusData = statusData.filter(pair => {
                    return pair.Value !== 1; //cant set to draft after published
                });
                setStatusData(filteredStatusData);
            }

            setRubricDomainMap(editRubricData.RubricDomainMap);
            setRubricIndicatorMap(editRubricData.RubricIndicatorMap);
            setRubricRatingMap(editRubricData.RubricRatingMap);
            setOrganizations(organizationDataSet);

            setEnableNextBtn(true);
            setEnableStatusDropdown(true);
            setIsEditRubricDialog(true);
            setIsSave(true);
        }
        else {
            resetRubricDialog();
        }
        setOpenDialog(dialogState);
    }, [editRubricData, editClicked, dialogState]);

    useEffect(() => {
        if (schoolYears && currentYear && organizationDataSet.length > 0 && !editClicked) {
            //set name
            mapSchoolYearData(schoolYears);
            let mappedCurrent = { SchoolYearId: currentYear.value, Value: currentYear.value, Text: currentYear.label };
            setCurrentSchoolYear(mappedCurrent);

            //see if rubric has current, if not, setSelected as Current Year
            setSelectedSchoolYear(mappedCurrent.Value);
            setSchoolYearTitle(mappedCurrent.Text);

            setOrganizations(organizationDataSet);
            setRubricType('0');
        }
    }, [schoolYears, currentYear, organizationDataSet, dialogClose]);

    const mapSchoolYearData = (schoolYears) => {
        if (schoolYears) {
            let mappedSchoolYears = schoolYears.map(item => ({ ClientId: item.ClientId, Identifier: item.identifier, SchoolYearId: item.value, Value: item.value, Text: item.label })) ?? [];
            setSchoolYearData(mappedSchoolYears);
        }
    }

    const handleOnNext = async () => {

        //update state of Create rubric form to phase '' based on what step we're on
        if (createRubricPhase == 1) {
            if (!isEditRubricDialog) {
                //map api call
                dataItem.SchoolYearId = selectedSchoolYear;
                dataItem['ClientId'] = clientId;
                dataItem['Status'] = 1; //draft

                dataItem['RubricDomainMap'] = rubricDomainMap;
                dataItem['RubricIndicatorMap'] = rubricIndicatorMap;
                dataItem['RubricRatingMap'] = rubricRatingMap;
                setRubricData(dataItem);
            }
            setCreateRubricPhase(2);
            setDialogTitle('Configure Rubric');
            setInnerClassName('');
            setIsPrevious(true);
            setIsSaveNext(true);
        }
        if (createRubricPhase == 2) {
            handleOnSave(false);
        }
    }

    useEffect(() => {
        if (rubricType !== '0' && name) {
            if (name.trim().length > 0)
                setEnableNextBtn(true);
        } else {
            setEnableNextBtn(false);
        }
    }, [dataItem]);

    const handleOnPrevious = async () => {
        //update state of Create rubric form to phase '' based on what step we're on
        setEnableNextBtn(true);
        if (createRubricPhase == 2) {
            setCreateRubricPhase(1);
            setDialogTitle('Create Rubric');
            setInnerClassName('narrow-content');
            setIsPrevious(false);
            setIsSave(false);
            setAssignDomains(true);
            setPreviousRubricRatingData(localRubricRatingData);
            setIsSaveNext(false);

            //TODO: api save on previous? comment out block for now. Just save state 
            // if(rubricRatingData.length > 0) {
            //     let testPayload = sampleFullRubricRatingData;
            //     const result = await rubricMgr.ItemManager.save(testPayload);
            // }
        }
    }

    const handleOrgChange = (value, item) => {
        if (item.target.tagName === 'svg') {
            setOrganization(null);
        }
        else {
            setOrganization(item.target.innerText);
            setClientId(value);
        }
    }

    const handleOnChange = (value, field) => {
        if (field == 'SchoolYearId') {
            let selectedSchoolYearIndex = schoolYearData.findIndex(r => r.Value == value);

            if (selectedSchoolYearIndex == -1) {
                setSelectedSchoolYear('not found');
            } else {
                setSelectedSchoolYear(value);
                setSchoolYearTitle(schoolYearData[selectedSchoolYearIndex].Text);
            }
        }
        if (field == 'Name') {
            setName(value);
        }
        if (field == 'Status') {
            var selectedStatus = statusData.find(status => status.Value === Number(value));

            setStatusType(selectedStatus.Value);
        }
        const updatedDataItem = { ...dataItem };
        updatedDataItem[field] = value;

        setDataItem(updatedDataItem);
    }

    const handleSelect = (value, item) => {
        const evalSet = evaluationTypes;

        var selectedRubricType = evalSet.find(evalType => evalType.value === value);

        if (selectedRubricType) {
            setRubricTypeHeaderText(selectedRubricType.label);
            setRubricType(value);
            const updatedDataItem = { ...dataItem };
            updatedDataItem['RubricType'] = value;

            setDataItem(updatedDataItem);
        }
    }

    //onSave
    const handleOnSave = async (isDefaultDomain) => {
        setLoadingSave(true);

        if (createRubricPhase == 1) {
            if (isEditRubricDialog) {
                // if(!isArchived){
                //     setArchiveWarning(true);
                // }
                // else {
                //map api call
                const schoolYear = schoolYearData?.find(x => x.SchoolYearId == selectedSchoolYear);

                let rubricToUpdate = {
                    Id: editRubricData.Id,
                    Name: name,
                    RubricType: rubricType,
                    SchoolYearId: selectedSchoolYear,
                    SchoolYearIdentifier: schoolYear?.Identifier ?? editRubricData.SchoolYearIdentifier,
                    ClientId: clientId,
                    Status: statusType
                }

                //make api Save call
                if (!rubricSaved) {
                    const result = await rubricMgr.ItemManager.save(rubricToUpdate);

                    if (result.Items[0]) {
                        if (result.Items[0].Id) {
                            setRubricSaved(true);
                            let updatedRubric = result.Items[0];
                            updatedRubric.CustomFields = organization;
                            updatedRubric.RubricDomainMap = editRubricData.RubricDomainMap;
                            updatedRubric.RubricIndicatorMap = editRubricData.RubricIndicatorMap;
                            updatedRubric.RubricIndicatorRatingMap = editRubricData.RubricIndicatorRatingMap;
                            updatedRubric.RubricRatingMap = editRubricData.RubricRatingMap;
                            onEdit(updatedRubric);
                        }
                    }
                }
                setOpenDialog(false);
                dialogClose();
                setRubricSaved(false);
                // }
            }
        }
        else {
            //On configure Rubric Table, save Rubric data from Phase 1 screen and phase 2 screen
            const schoolYear = schoolYearData?.find(x => x.SchoolYearId == selectedSchoolYear);
            dataItem.SchoolYearIdentifier = schoolYear?.Identifier ?? null;
            const rubricResult = await rubricMgr.ItemManager.save(dataItem);
            let newRubricId = '';
            if (rubricResult.Items[0]) {
                if (rubricResult.Items[0].Id) {
                    newRubricId = rubricResult.Items[0].Id;
                    setRubricId(rubricResult.Items[0].Id);
                    setRubricSaved(true);

                    let rubricToSend = rubricResult.Items[0];
                    rubricToSend.RubricDomainMap = rubricDomainMap;
                    rubricToSend.RubricIndicatorMap = rubricIndicatorMap;
                    rubricToSend.RubricRatingMap = rubricRatingMap;
                    setRubricData(rubricToSend);
                }
            }


            for (const row of localRubricRatingData) {
                let rowToSave = {
                    RubricRatingId: row.ratingId,
                    RubricId: newRubricId,
                    ShowInRubric: row.showInEval,
                    Sequence: row.sequence,
                    ClientId: clientId,
                    IsDeleted: false,
                    IsActive: true
                }
                const result = await rubricMgr.RatingMapItemMgr.save(rowToSave);
                if (result.Success && result.Items) {
                    rubricData.RubricRatingMap.push(result.Items[0]);
                }
            }

            let domainIdToSet = '';
            //if isDefault, ("dont assign domains", set Default domain for rubric)
            if (isDefaultDomain) {
                let domainToSave = {
                    Name: 'Default',
                    ClientId: rubricData.ClientId,
                }
                const domainSaveResult = await rubricMgr.DomainItemManager.save(domainToSave);

                //saving RubricDomainMap using new rubricDomainId returned
                if (domainSaveResult.Items[0]) {
                    domainIdToSet = domainSaveResult.Items[0].Id;
                    let rubricDomainMap = {
                        RubricId: newRubricId,
                        RubricDomainId: domainSaveResult.Items[0].Id,
                        Sequence: 1
                    }
                    const rubricDomainMapSaveResult = await rubricMgr.RubricDomainMapItemMgr.save(rubricDomainMap);

                    if (!rubricDomainMapSaveResult.Success) {
                        console.log('DomainRubricMap Save Error');
                    }
                }
                navigate(`/admin/RubricIndicator/${newRubricId}/${domainIdToSet}`);
            }
            else {
                navigate(`/admin/RubricIndicator/${newRubricId}`);
            }

            setLoadingSave(false);
            setOpenDialog(false);
        }
    }

    const handleRatingSave = async (row) => {
        //TODO: save is done on next/save click, function may not be needed
        //Saves row by row as a new rating is added
        // let rowToSave = {
        //     RubricId: rubricId,
        //     RubricRatingId: row.Id,
        //     ShowInRubric: row.showInEval,
        //     Sequence: row.sequence,
        //     ClientId: clientId,
        //     IsDeleted: false            
        // }
        // //save rubric rating set from phase 2 Configure Rubric
        // const result = await rubricMgr.RatingMapItemMgr.save(rowToSave);

        //TODO: get new Id from the saved rating item and assign it here
    }

    const handleOnDomainChange = (value, field) => {
        if (assignDomains) {
            setAssignDomains(false);
            setDomain(defaultDomainId); //set to default
            setIsSave(true);
            //setBtnText('Save');
        } else {
            setAssignDomains(true);
            setIsSave(false);
            //setBtnText('Next');
        }
    }

    const handleNewRubricRatingItem = async (ratingItem) => {

        let newRatingItem = {
            Title: ratingItem.Text,
            ShortDescription: ratingItem.description,
            Value: ratingItem.Rating,
            ShowInRubric: ratingItem.ShowInRubric,
            Sequence: ratingItem.Sequence
        }

        const result = await rubricMgr.RatingItemManager.save(newRatingItem);
        if (result.Success && result.Items) {
            let localRatings = [...localRubricRatingData];
            let newRating = localRatings.find(x => x.level === result.Items[0].Title);
            if (newRating) {
                newRating.ratingId = result.Items[0].Id;
                setLocalRubricRatingData(localRatings);
            }
        }
    }

    const handleDeleteRating = async (ratingItem) => {
        //delete api call here
        //Delete from row, or include db delete?
        //const result = await rubricMgr.RatingItemManager.delete(ratingItem);
    }

    const handleOnCancel = () => {
        resetRubricDialog();
        setOpenDialog(false);
        setPreviousRubricRatingData();
        dialogClose();
    }

    const handleArchiveClose = (isArchived) => {
        if (isArchived) {
            setIsArchived(true);
            handleOnSave();
        }
        setArchiveWarning(false);
    }

    const resetRubricDialog = () => {
        setDialogTitle('Create Rubric');
        setCreateRubricPhase(1);
        setEnableNextBtn(false);
        setInnerClassName('narrow-content');
        setIsPrevious(false);
        setName();
        setDataItem({});
        setSchoolYearTitle();

        //set eval type
        setRubricType();
        setRubricTypeHeaderText();

        setSelectedSchoolYear();

        //set org 
        setOrganization();
        setClientId();

        //cycle through rating set, 
        //set rating data
        setRubricRatingData();

        setStatusType();

        setEnableStatusDropdown(false);
        setIsEditRubricDialog(false);
        setLoadingSave(false);
        setIsSave(false);
        setIsSaveNext(false);
    }

    const handleRubricRatingUpdate = (data) => {
        setLocalRubricRatingData(data);
    }

    const handleEnableNext = (enable) => {
        setEnableNextBtn(enable);
    }

    const schoolYearClientId = schoolYearData?.find(x => x.SchoolYearId == selectedSchoolYear)?.ClientId;
    return <>
        <DialogControl className={'rubric-dialog'} openDialog={openDialog} loadingSave={loadingSave} innerclassName={innerClassName} excludeCancel={true} onCancel={handleOnCancel}
            title={dialogTitle} onNext={handleOnNext} onPrevious={handleOnPrevious} enableNext={enableNextBtn} isPrevious={isPrevious} customNext={isSaveNext}
            onSave={() => handleOnSave(true)} isSave={isSave} nextText={btnText} footerClassName={'extra-top-space'}>
            {createRubricPhase == 1 &&
                <>

                    <InputField title="Name" placeholder={'Rubric Name'} groupId={'DIALOG'} validationName={'Rubric_Name'} maxLength={100} fieldName="Name" onChange={handleOnChange} value={name} />
                    <div className='FieldInput' style={{ height: '6.5rem' }}>
                        <div className='generic-input-component'>
                            <div className='title-header'>
                                <div className={`title`}><div>Evaluation Type</div><div></div>
                                </div>
                            </div>
                            <SelectPicker data={evaluationTypes} searchable={false} value={rubricType} onChange={handleSelect} className={'picker-style'} />
                        </div>
                    </div>
                    <InputField title="School Year" fieldName="SchoolYearId" onChange={handleOnChange} value={selectedSchoolYear}>
                        <SelectListControl hidePleaseSelect={true} textValuePairs={schoolYearData} />
                    </InputField>
                    <div className='title-header'>
                        <div className={`title`}><div>Organization</div><div></div>
                        </div>
                    </div>
                    <SelectListControl plsOpt={false} disabled={schoolYearClientId ?? false} hidePleaseSelect={true} searchable={true} onChange={handleOrgChange}
                        placeholder={'Select an Organization'} size='md' styles={styles} value={schoolYearClientId ?? organization}
                        data={organizations} />

                    {enableStatusDropdown &&
                        <>
                            <div style={{ paddingTop: '.8rem' }} />
                            <InputField title="Status" hidePleaseSelect={true} maxLength={100} fieldName="Status" onChange={handleOnChange} value={statusType}>
                                <SelectListControl hidePleaseSelect={true} value={statusType} textValuePairs={statusData} />
                            </InputField>
                        </>
                    }
                    {archiveWarning &&
                        <DialogControl openDialog={archiveWarning} isYes={true} onYes={handleArchiveClose(true)} isNo={true} onNo={handleArchiveClose(false)} title={"Are you sure?"}>
                            <div>
                                Are you sure you want to archive this rubric?
                            </div>
                        </DialogControl>
                    }
                </>
            }
            {createRubricPhase == 2 &&
                <>
                    <div className="configureContent">
                        <div className="container">
                            <div className="wrapper">
                                <div className="rubric-header-inner"><div className='headerColor'>Name: </div><LabelControl className="configureHeaderLabel" field="Name" value={name}></LabelControl></div>
                                <div className="rubric-header-inner"><div className='headerColor'>Evaluation Type: </div><LabelControl className="configureHeaderLabel" field="RubricType" value={rubricTypeHeaderText}></LabelControl></div>
                            </div>
                            <div className="wrapper">
                                <div className="rubric-header-inner-rightside"><div className='headerColor'>School Year: </div><LabelControl className="configureHeaderLabel" field="SchoolYearId" value={schoolYearTitle}></LabelControl></div>
                                <div className="rubric-header-inner-rightside"><div className='headerColor'>Organization: </div><LabelControl className="configureHeaderLabel" field="ClientId" value={organization}></LabelControl></div>
                            </div>
                        </div>

                        <RubricConfigureTable
                            data={rubricRatingDataSet}
                            rubricRatingData={rubricRatingData}
                            previousRubricRatingData={previousRubricRatingData}
                            setRubricRatingUpdate={(dataSet) => handleRubricRatingUpdate(dataSet)}
                            onDomainChange={handleOnDomainChange}
                            assignDomains={assignDomains}
                            saveNewRubricRatingItem={(ratingItem) => handleNewRubricRatingItem(ratingItem)}
                            handleRatingSave={(ratingItem) => handleRatingSave(ratingItem)}
                            handleDeleteRow={(ratingItem) => handleDeleteRating(ratingItem)}
                            enableNext={handleEnableNext} />
                    </div>

                </>
            }

        </DialogControl>
    </>
}

export default RubricDialog;