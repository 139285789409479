import { useEffect, useState } from 'react';
import _ from 'lodash';
import { InputField } from '../../../../components/controls/InputField';
import { TagPicker } from 'rsuite';
import FileUploaderControl from '../../../../components/controls/FileUploaderControl';
import { SelectListControl } from '../../../../components/controls';
import { DialogControl } from '../../../../components/controls/DialogControl';
import SurveyTable from '../../../admin/components/observation/SurveyTable';
import Loading from '../../../../components/Loading';
import PrintLayout from '../../../../components/layout/PrintLayout';
import moment from 'moment';

const GeneralSurveyPrintEmptyScreen = ({ generalSurveyMgr, data, rubricLoading, rubricData, ratingSelectOptions, setRatingSelectOptions, printingCompleted }) => {
    const [surveyQnA, setSurveyQnA] = useState(null)
    const [formattedFields, setFormattedFields] = useState([])
    const [isFileLoading, setIsFileLoading] = useState(false)
    const [indicatorRating, setIndicatorsRated] = useState(0)
    const [print, setPrint] = useState(null)
    const [numMedia, setNumMedia] = useState(null);
    const [mediaLoadingComplete, setMediaLoadingComplete] = useState(false);
    const [mediasLoaded, setMediasLoaded] = useState([]);
    useEffect(() => {
        if (data) {
            let likertFields = []
            const surveyFieldMap = _.reduce(_.orderBy(data.SurveyTemplateFields, ['Sequence'], ['asc']), (newMap, x) => {
                const surveyItem = {
                    FieldDisplayText: x.DisplayText,
                    Title: x.Title,
                    Value: null,
                    SurveyFieldType: x.SurveyFieldType,
                    SurveyTemplateFieldId: x.Id,
                    Options: _.map(x.Options, y => {
                        if (x.SurveyFieldType == 2) {
                            return {
                                Text: y,
                                Value: y
                            }
                        } else return {
                            label: y,
                            value: y
                        }
                    }) ?? []
                }
                if (!_.isEmpty(data.Sections)) {
                    const surveyItemDetails = _.find(data.Sections, ds => ds.SurveyTemplateFieldId === x.Id)
                    let value = surveyItemDetails?.Value ?? null;

                    if (x.SurveyFieldType == 3) {
                        value = value?.split(';')
                    }
                    surveyItem.SurveyContents = surveyItemDetails?.SurveyContents
                    surveyItem.SurveySectionId = surveyItemDetails?.Id ?? undefined

                }
                newMap.push(surveyItem)
                return newMap
            }, [])

            const likertGrouped = _.reduce(surveyFieldMap, (groupedFields, field, i) => {
                if ((surveyFieldMap[i - 1]?.SurveyFieldType == 5 && field.SurveyFieldType == 5) || (surveyFieldMap[i + 1]?.SurveyFieldType == 5 && field.SurveyFieldType == 5)) {
                    likertFields.push(field)
                    if (surveyFieldMap.length === i + 1 && likertFields.length > 1) {
                        groupedFields.push(likertFields)
                    }
                } else if (likertFields.length > 1) {
                    groupedFields.push(likertFields)
                    groupedFields.push(field)
                    likertFields = []
                } else groupedFields.push(field)
                return groupedFields
            }, [])

            setPrint(true)
            setSurveyQnA(surveyFieldMap)
            setFormattedFields(likertGrouped)
        }
    }, [data])




    const handleUpdateSurvey = (value, fieldName, surveyField) => {
        const copySurveyQnA = [...surveyQnA]
        const surveyFieldIndex = _.findIndex(surveyQnA, x => x.SurveyTemplateFieldId === surveyField.SurveyTemplateFieldId)
        if (surveyField.SurveyFieldType == 4) {
            surveyField['Value'] = value.FriendlyFileName
            if (!_.isEmpty(surveyField.SurveyContents)) {
                let copySurveyContent = { ...surveyField.SurveyContents.first() }
                copySurveyContent.FileId = value.FileId
                copySurveyContent.File = value
                surveyField.SurveyContents = [copySurveyContent]
            } else surveyField.SurveyContents = [{
                FileId: value.FileId,
                File: value,
                SurveySectionId: surveyField.SurveySectionId,
                SurveyContentType: 2,
            }]
        } else {
            if (value === "UNK") {
                value = null
            }
            surveyField['Value'] = value
        }
        copySurveyQnA.splice(surveyFieldIndex, 1, surveyField)
        setSurveyQnA(copySurveyQnA)
    }


    const renderLikertHeader = () => {
        let calculatedWidth;
        if (data?.IncludeLikertNA) {
            calculatedWidth = { width: `calc(100% / (${data?.SurveyLikertMap?.length} + 1))` }
        } else {
            calculatedWidth = { width: `calc(100% / ${data?.SurveyLikertMap?.length})` }
        }
        return <>
            <div className="top-item-row">
                <div className="item-value-wrapper">
                    {_.map(data?.SurveyLikertMap, (x, i) => <div key={i} className="item-value" style={calculatedWidth}>{x.Title}</div>)}
                    {data?.IncludeLikertNA && <div className="item-value" style={calculatedWidth}></div >}
                </div>
            </div>
            <div className="top-item-row">
                <div className="item-value-wrapper">
                    {_.map(data?.SurveyLikertMap, (x, i) => <div key={i} className="item-value" style={calculatedWidth}>{x.Value}</div>)}
                    {data?.IncludeLikertNA && <div className="item-value" style={calculatedWidth}>N/A</div >}
                </div>
            </div>
        </>
    }


    const renderLikertFields = (likertField, likertIndex) => {
        let calculatedWidth;
        if (data?.IncludeLikertNA) {
            calculatedWidth = { width: `calc(100% / (${data?.SurveyLikertMap?.length} + 1))` }
        } else {
            calculatedWidth = { width: `calc(100% / ${data?.SurveyLikertMap?.length})` }
        }
        return <div className="item-row" key={likertIndex} >
            <div className="item-question" >
                {likertField?.FieldDisplayText}
            </div>
            <div className="item-value-wrapper">
                {_.map(data?.SurveyLikertMap, (x, i) => <div className="item-value" key={i} style={calculatedWidth}><input onChange={() => handleUpdateSurvey(x.Value, likertField?.FieldDisplayText, likertField)} checked={x.Value == likertField?.Value} type="radio" /></div>)}
                {data?.IncludeLikertNA && <div className="item-value" style={calculatedWidth}><input onChange={() => handleUpdateSurvey('N/A', likertField?.FieldDisplayText, likertField)} checked={'N/A' == likertField?.Value} type="radio" /></div >}
            </div>
        </div>

    }

    const renderOtherSurveyFields = (field, i) => {
        return <div className="item-row" key={i}>
            <div className="item-question">
                {field?.FieldDisplayText}
            </div>

            {field?.SurveyFieldType == 1 &&
                <div className="item-value-wrapper">
                    <InputField type="textarea" maxLength={250} value={field?.Value} fieldName={field?.FieldDisplayText} onChange={(value, fieldName) => handleUpdateSurvey(value, fieldName, field)} />
                </div>}

            {field?.SurveyFieldType == 2 &&
                <div className="item-value-wrapper">
                    <InputField fieldName={field?.FieldDisplayText} value={field?.Value ?? 'UNK'} onChange={(value, fieldName) => handleUpdateSurvey(value, fieldName, field)}>
                        <SelectListControl textValuePairs={field?.Options} />
                    </InputField>
                </div>}
            {field?.SurveyFieldType == 3 &&
                <div className="item-value-wrapper">
                    <InputField>
                        <TagPicker
                            className='tag-picker'
                            data={field?.Options}
                            defaultValue={field?.Value}
                            onChange={(value) => handleUpdateSurvey(value, field?.FieldDisplayText, field)}
                            valueKey={'value'}
                            block
                            preventOverflow
                        />
                    </InputField>
                </div>}
            {field?.SurveyFieldType == 4 &&
                <div className="item-value-wrapper">
                    <div className="file-upload-wrapper">
                        <InputField value={field?.SurveyContents?.first()?.FileId} fieldName={field?.FieldDisplayText} onChange={(value, fieldName) => handleUpdateSurvey(value, fieldName, field)}>
                            <FileUploaderControl
                                canUploadAnyFile={true}
                                controlId={`blank-survey`}
                                isPublic={true}
                                overrideMaxWidth={400} widthRes={1920} heightRes={1080}
                                maxFileSize={1024 * 1024 * 99}
                                onFileLoading={(val) => setIsFileLoading(val)} />
                        </InputField>
                    </div>
                </div>}
        </div>
    }

    //Rubric Section

    const selectRating = (indicator, rating) => {
        let ratingSelectOpts = { ...ratingSelectOptions };
        if (ratingSelectOpts[indicator]) {
            ratingSelectOpts[indicator].isExpanded = !ratingSelectOpts[indicator]?.isExpanded;
            ratingSelectOpts[indicator].selectedRating = rating;

            let surveyratingdti = {
                SurveyId: data.Survey.SurveyId,
                RubricRatingId: rubricData.metaData.reduce((r, cv) => {
                    if (cv.rating == rating) {
                        r = cv.rubricRatingId;
                    }
                    return r;
                }, null),
                ObservationRatingTypeId: 0,
                ClientId: data.ClientId,
                RubricIndicatorId: ratingSelectOpts[indicator].rubricIndicatorId
            }

            if (ratingSelectOpts[indicator].surveyRatingId) {
                surveyratingdti.SurveyRatingId = ratingSelectOpts[indicator].surveyRatingId
            }

            generalSurveyMgr.saveRating({ surveyratingdti }).then(res => {
                if (res) {
                    if (res?.Items?.first()) {
                        let item = res.Items.first();
                        ratingSelectOpts[indicator].surveyRatingId = item.SurveyRatingId;
                    }
                }
            })

            setRatingSelectOptions(ratingSelectOpts);
        }
    }

    useEffect(() => {
        if (ratingSelectOptions) {
            let ir = Object.keys(ratingSelectOptions).reduce((r, cv) => {
                if (ratingSelectOptions[cv].selectedRating) {
                    r++;
                }
                return r;
            }, 0);
            setIndicatorsRated(ir);
        }
    }, [ratingSelectOptions])

    const toggleRatingOptions = (indicator) => {
        let ratingSelectOpts = { ...ratingSelectOptions };
        if (ratingSelectOpts[indicator]) {
            ratingSelectOpts[indicator].isExpanded = !ratingSelectOpts[indicator]?.isExpanded;
            setRatingSelectOptions(ratingSelectOpts);
        }
    }
    return <PrintLayout print={print} cleanUp={printingCompleted} className={'survey-print'}>
        <div className='survey-screen-header'>
            <div className="survey-header-row">
                <div className="survey-header-wrapper">
                    <h1>{data?.GeneralSurveyName}</h1>
                </div>
                <div className="survey-subheader-wrapper">
                    <div className="survey-subheader-left">Survey Assigned By:</div>
                    <div className="survey-subheader-right">{data?.AssignedByUser?.FullName}</div>
                </div>
                <div className="survey-subheader-wrapper">
                    <div className="survey-subheader-left">Survey Assigned To:</div>
                    <div className="survey-subheader-right">{data?.AssignedTo}</div>
                </div>
                {/* <div className="survey-subheader-wrapper">
                    <div className="survey-subheader-left">Start Date:</div>
                    <div className="survey-subheader-right"> {moment.utc(data?.StartDate).local().format("MMMM D, YYYY")}</div>
                </div> */}
            </div>
        </div>
        <div className={"survey-container"}>
            <h5 className={'survey-desc' + (data?.SurveyDescription?.length < 80 ? ' center-text' : '')}>
                <div>{data?.SurveyDescription}</div>
            </h5>
            <div className={"survey"}>
                {!data?.ShowRubric ? <></> : (rubricData && ratingSelectOptions) ? <SurveyTable
                    isSelfReflection={true}
                    indicatorsRated={indicatorRating}
                    gridData={rubricData}
                    ratingSelectOptions={ratingSelectOptions}
                    toggleRatingOptions={toggleRatingOptions}
                    fixedRowWidth={'9rem'}
                    selectRating={selectRating}
                /> : <div className="loading-wrapper">{rubricLoading !== "NoRubric" ? <Loading type='default' size='5rem' /> : <>No Rubric Found.</>}</div>
                }

                {_.map(formattedFields, (field, i) => {
                    if (Array.isArray(field)) {
                        // If it is an array, this means that there are two or more likert scales.
                        return <div key={i}>
                            {renderLikertHeader()}
                            {_.map(field, (x, likertIndex) => { return renderLikertFields(x, likertIndex) })}
                        </div>
                        // Otherwise if its a lonely likert
                    } else if (field.SurveyFieldType == 5) {
                        return <div key={i}>
                            {_.filter(surveyQnA, x => x.SurveyFieldType == 5).length === 1 && renderLikertHeader()}
                            {renderLikertFields(field)}
                        </div>


                    } else return renderOtherSurveyFields(field, i)
                })}
            </div>
        </div>
    </PrintLayout>
}

export default GeneralSurveyPrintEmptyScreen;






