import React from 'react';
import { Link } from 'react-router-dom';
import { useAppUserManager } from '../../hooks/useManagers';
import './header.scss';
import Navigation from './Navigation';
import ProfileNav from './ProfileNav';

const PublicHeader = () => {
  const appUserMgr = useAppUserManager();
  return (
    <div className={`header header-box-shadow`}>
      {appUserMgr?.GetGlobalAuthState()?.isAuthenticated ? <Navigation /> : <div>&nbsp;</div>}
      <div className={appUserMgr?.GetGlobalAuthState()?.isAuthenticated ? 'eepass-banner' : 'eepass-banner-homepage'}></div>
      <ProfileNav />
    </div>
  );
}

export default PublicHeader;