import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas, faImage, faList } from '@fortawesome/free-solid-svg-icons'
import '../../admin.scss';

import { FormControlLabel, Grid, Checkbox, Switch, Button, TextField, FilledInput } from '@mui/material';

import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import SelectListControl from '../../../../components/controls/SelectListControl';
import DatePickerControl from '../../../../components/controls/DatePickerControl';

import { CheckPicker, CheckTreePicker, TagPicker } from 'rsuite';
import '../../../../styles/rsuite_override.css';
import '../../rubricFilter.scss';
import _ from 'lodash';
import $ from 'jquery';
import { useAppUserManager, useContentManager, useOrgManager } from '../../../../hooks/useManagers';

export default function RubricFilter({ evalTypes, organizations, schoolYears, currentYear, onFilterChange, currentFilter }) {
    const authMgr = useAppUserManager();
    const { permissions } = authMgr?.GetGlobalState() ?? {};
    //const [organizationIds, setOrganizationIds] = useState(contentFilter?.organizationIds);
    const searchInput = useRef(null);
    const learningModuleInput = useRef();
    const [organizationDataSet, setOrganizationDataSet] = useState();
    const [selectedOrganizations, setSelectedOrganizations] = useState([]);
    const [selectedEvalTypes, setSelectedEvalTypes] = useState([]);
    const [schoolYearData, setSchoolYearData] = useState([]);
    const [currentSchoolYear, setCurrentSchoolYear] = useState();
    const [selectedSchoolYear, setSelectedSchoolYear] = useState();
    const [selectedSchoolYearValues, setSelectedSchoolYearValues] = useState([]);
    const [selectedDate, setSelectedDate] = useState();
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [search, setSearch] = useState(null);
    const [orgs, setOrgs] = useState(null);
    const orgMgr = useOrgManager();

    useEffect(() => {
        if (schoolYears && currentYear && organizations.length > 0 && orgMgr) {
            setSchoolYearData(schoolYears);
            setOrganizationDataSet(organizations);
            setSelectedSchoolYearValues([currentYear.value]);
            orgMgr.loadOrgTree(true, true).then((r) => {
                setOrgs(_.filter(orgMgr.OrgsOnly(), x => x.OrgId))
            })
        }
    }, [schoolYears, currentYear, organizations, orgMgr]);

    useEffect(() => {
        if (currentFilter) {
            if (currentFilter.Date !== null) {
                setSelectedDate(currentFilter.Date);
            }
            if (currentFilter.EvaluationType.length !== 0) {
                setSelectedEvalTypes(currentFilter.EvaluationType);
            }
            if (currentFilter.Organization.length !== 0) {
                setSelectedOrganizations(currentFilter.Organization);
            }
            if (currentFilter.SchoolYear.length !== 0) {
                setSelectedSchoolYear(currentFilter.SchoolYear);
            }
            if (currentFilter.Status.length !== 0) {
                setSelectedStatus(currentFilter.Status);
            }

            if (currentFilter.Search) {
                setSearch(currentFilter.Search);
            }
        }
    }, [currentFilter]);

    const evalDataSet = evalTypes.map(
        item => ({ label: item.Text, value: item.Value })
    );

    const statusData = [
        { Id: 1, Text: 'Draft' },
        { Id: 2, Text: 'Published' },
        { Id: 3, Text: 'Archived' }
    ].map(item => ({ label: item.Text, value: item.Id }));

    const onChange = (value, fieldName) => {
        if (fieldName === 'Date') {
            setSelectedDate(value);
        }
        if (onFilterChange)
            onFilterChange(fieldName, value);
    }

    const onTreeClean = (fieldName) => {

        switch (fieldName) {
            case 'SchoolYear':
                setSelectedSchoolYearValues();
                break;
            case 'EvaluationType':
                setSelectedEvalTypes();
                break;
            case 'Organization':
                setSelectedOrganizations();
                break;
            case 'Status':
                setSelectedStatus();
            default:
        }

        if (onFilterChange)
            onFilterChange(fieldName, []);
    }

    const onEvalCheckSelect = (activeNodes) => {
        setSelectedEvalTypes(activeNodes);

        if (onFilterChange)
            onFilterChange("EvaluationType", activeNodes);
    }

    const onOrgCheckSelect = (activeNodes) => {
        setSelectedOrganizations(activeNodes);

        if (onFilterChange)
            onFilterChange("Organization", activeNodes);
    }

    const onStatusCheckSelect = (activeNodes) => {
        setSelectedStatus(activeNodes);

        if (onFilterChange)
            onFilterChange("Status", activeNodes);
    }

    const onSchoolYearCheckSelect = (activeNodes, value) => {
        setSelectedSchoolYearValues(activeNodes);

        if (onFilterChange)
            onFilterChange("SchoolYear", activeNodes);
    }

    const onSearchChanged = (value) => {
        setSearch(value);
        if (onFilterChange)
            onFilterChange("Search", value);
    }

    const onTreeCleanOrg = () => {
        onOrgCheckSelect([]);
    }


    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        //TODO: Reset all controls to their default value
        // $(".rs-picker-toggle-clean").trigger("click");
        // $("#field_dp_Date").val("mm/dd/yyyy");

        if (onFilterChange) {
            setSelectedEvalTypes([]);
            setSelectedOrganizations([]);
            setSelectedSchoolYear([]);
            setSelectedSchoolYearValues([]);
            setSelectedDate('');
            setSelectedStatus([]);
            setSearch();

            onFilterChange("EvaluationType", []);
            onFilterChange("Organization", []);
            onFilterChange("SchoolYear", []);
            onFilterChange("Date", null);
            onFilterChange("Status", []);
            onFilterChange("Search", '');
        }
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Rubric Management</div>
                    <InputField
                        className="searchWidthFilter"
                        placeholder="Search By Name"
                        title={'Search'}
                        id={'search'}
                        disableError={true}
                        value={search}
                        onChange={onSearchChanged} />
                    <div>
                        <div className="title-header">
                            <div className="title">
                                Evaluation type
                            </div>
                        </div>
                        <CheckPicker
                            className="searchWidthFilter"
                            placeholder="Evaluation Type"
                            id="evalType"
                            height={320}
                            data={evalDataSet ?? []}
                            value={selectedEvalTypes}
                            onClean={() => onTreeClean('EvaluationType')}
                            onSelect={onEvalCheckSelect}
                        />
                    </div>
                    <div>
                        <div className="title-header">
                            <div className="title">
                                School Year
                            </div>
                        </div>
                        <CheckPicker
                            className="searchWidthFilter"
                            placeholder="School Year"
                            title={'SchoolYear'}
                            id={'SchoolYear'}
                            searchable={false}
                            onClean={() => onTreeClean('SchoolYear')}
                            value={selectedSchoolYearValues}
                            data={schoolYearData ?? []}
                            onSelect={onSchoolYearCheckSelect} />
                    </div>
                    <div>
                        <div className="title-header">
                            <div className="title">
                                Organizations
                            </div>
                        </div>
                        <CheckTreePicker
                            className={'searchWidthFilter'}
                            childrenKey="Children"
                            labelKey="Name"
                            valueKey="OrgId"
                            value={selectedOrganizations}
                            data={orgs ?? []}
                            onClean={onTreeCleanOrg}
                            onSelect={(a, v) => onOrgCheckSelect(v)}
                            placement="autoHorizontalStart"
                        />
                    </div>
                    <div>
                        <InputField title="Date" maxLength={100} onChange={onChange} value={selectedDate} groupId={'USER'} fieldName="Date" disableError={true} >
                            <DatePickerControl includeTime={false} value={selectedDate} />
                        </InputField>
                    </div>
                    <div className="title-header">
                        <div className="title">
                            Status
                        </div>
                    </div>
                    <CheckPicker
                        className="searchWidthFilter"
                        placeholder="Rubric Status"
                        searchable={false}
                        title='Status'
                        id='status'
                        height={320}
                        value={selectedStatus}
                        data={statusData ?? []}
                        onClean={() => onTreeClean('Status')}
                        onSelect={onStatusCheckSelect}
                    />
                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}