//https://blog.logrocket.com/simplify-react-state-management-with-hookstate/
import { hookstate } from '@hookstate/core'


export const initalState = {
        idToken: null,
        accessToken: null,
        idTokenClaims:null
}

const globalAuthStateStore = hookstate(initalState);

export default globalAuthStateStore;