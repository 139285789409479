import './ImageUploader.scss';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faArrowUp, faPlus } from '@fortawesome/free-solid-svg-icons'
import { fileUploadWorkerScript } from './FileUploadApi';
import ReactPlayer from 'react-player';
import { BaseService } from '../../../services/base/BaseService';
import {   hookstate } from '@hookstate/core';
import configData from "../../../config.json";
import { useAppUserManager } from '../../../hooks/useManagers';
import documentIcon from '../../../content/icons/document.png';
import { AzureMP } from '../../azure-mp';
import { ConstructionOutlined } from '@mui/icons-material';



export default function FileUploaderControl({
    controlId, value, fileUrl, currentFileName, overrideMaxWidth, hideControls,
    widthRes, heightRes, disabled, isPublic, canUploadVideo, canUploadAnyFile,
    maxFileSize, onChange, onFileLoading, dontUseSessionStorage, dontShowImage, dontDisplayFileName, forceDim, positionTopZero }) {

    const setSessionStorage = (fileId, fileUrl) => {
        sessionStorage.setItem(`FileUpload-${controlId}`, JSON.stringify({ fileId, fileUrl }));
    }

    const getFromStorage = () => {
        const strItem = sessionStorage.getItem(`FileUpload-${controlId}`);

        if (strItem != null) {
            const item = JSON.parse(strItem);
            return item;
        }
        else
            return;
    }

    const getWrapperWidth = () => {
        const maxWidth = overrideMaxWidth ?? 500;
        const minWidth = 150;
        const widthAdjust = 600;
        let currentWidth = document.body.getBoundingClientRect().width ?? 1;
        currentWidth = currentWidth - widthAdjust < maxWidth ? currentWidth - widthAdjust : maxWidth
        if (currentWidth <= minWidth) {
            currentWidth = minWidth;
        }
        return currentWidth;
    }

    const checkIsVideo = (fileUrl) => {
        return ((canUploadVideo === true || canUploadAnyFile === true) &&
            (fileUrl?.toLowerCase()?.includes('.mpeg') ||
                fileUrl?.toLowerCase()?.includes('.mpg') ||
                fileUrl?.toLowerCase()?.includes('.mpga') ||
                fileUrl?.toLowerCase()?.includes('.avi') ||
                fileUrl?.toLowerCase()?.includes('.mp4') ||
                fileUrl?.toLowerCase()?.includes("streaming.media.azure.net")
            ));
    }

    const authStateManager = useAppUserManager();
    const [errorMessages, setErrorMessages] = useState([]);
    const [width, setWidth] = useState(forceDim ? widthRes : getWrapperWidth());
    const [height, setHeight] = useState(forceDim ? heightRes : (heightRes / widthRes) * width);
    const [currentWidth, setCurrentWidth] = useState(getWrapperWidth());
    const [fileId, setFileId] = useState(value);
    const [fileName, setFileName] = useState(currentFileName);
    const [fileUri, setFileUri] = useState(fileUrl);
    const [dragOn, setDragOn] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [isVideo, setIsVideo] = useState(null);
    const [playingVideo, setPlayingVideo] = useState(false);
    const inputFile = useRef();

    // useEffect(() => {
    //     if(dontShowImage){
    //         if(inputFile.current.files.length > 0){
    //             console.log('assafter: ', inputFile)
    //             inputFile.current.value = null;
    //         }
    //         else {
    //             console.log('assbefore: ', inputFile)
    //         }
    //     }
    // }, [inputFile])

    useEffect(() => {
        if (fileUrl) {
            //[{ src: fileUrl, type: "application/vnd.ms-sstr+xml" }]
            setIsVideo(checkIsVideo(fileUrl))
        }
    }, [fileUrl])

    useEffect(() => {
        if (!dontUseSessionStorage) {
            if (!fileId && getFromStorage()?.fileId) {
                setFileId(getFromStorage()?.fileId);
                setFileUri(getFromStorage()?.fileUrl);
                setIsVideo(checkIsVideo(getFromStorage()?.fileUrl));
            }
        }
    }, [fileId]);

    useEffect(() => {
        if (!dontShowImage) {
            if (fileId !== value) {
                setFileId(value);
                if (value === null) {
                    setFileUri(null);
                    setFileName(null);
                }
                else {
                    setFileUri(fileUrl);
                }
            }
        }
    }, [value]);

    useEffect(() => {
        if (!forceDim) {
            window.addEventListener("resize", (event) => {
                setCurrentWidth(getWrapperWidth());
            }, false);
        }
    }, []);

    useEffect(() => {
        if (!forceDim) {
            setWidth(currentWidth);
            setHeight((heightRes / widthRes) * currentWidth)
        }
    }, [currentWidth]);

    const handleOnDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!disabled) {
            setDragOn(true);
        }
    }

    const handleOnDragEnter = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!disabled) {
            setDragOn(true);
        }
    }

    const handleOnDragLeave = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!disabled) {
            setDragOn(false);
        }
    }

    const handleOnDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!disabled) {
            setDragOn(false);
            const dt = e.dataTransfer;
            handleOnFileChange(dt);
        }
    }


    const handleOnFileChange = (dt) => {
        if (!disabled) {
            const current = dt ?? inputFile.current;
            let currentFileId = fileId;
            if (current?.files[0]) {
                let fileName = current.files[0].name;
                fileName = fileName.substr(0, fileName.lastIndexOf('.'));
                if (selectFile(current.files[0])) {
                    if (onFileLoading)
                        onFileLoading(true);
                    setFileLoading(true);

                    const uploadWorker = new Worker(fileUploadWorkerScript);
                    uploadWorker.onmessage = (postResult) => {
                        const result = postResult?.data?.first();
                        if (result.Success) {
                            const item = result.Items?.first();
                            if (item) {
                                currentFileId = item.FileId;

                                if (!dontShowImage) {
                                    setFileId(item.FileId);
                                    setFileUri(item.FileUrl);
                                    setIsVideo(checkIsVideo(item.FileUrl));
                                }

                                if (!dontUseSessionStorage) {
                                    setSessionStorage(item.FileId, item.FileUrl);
                                }
                                if (onChange) {
                                    onChange({ target: { value: item }, });
                                }
                                if (dontShowImage) {
                                    //console.log('current: ', current);
                                    current.value = "";
                                }
                            }
                        }
                        else {
                            setErrorMessages([`Failed to upload ${fileName}: ${result?.Message?.DisplayMessage}`]);
                            setFileName(null);
                            current.value = "";
                        }
                        setFileLoading(false);
                        if (onFileLoading) {
                            onFileLoading(false);
                        }
                    }

                    const gas = authStateManager._globalAuthState.get({ noproxy: true });

                    const service = new BaseService(`file/upload${currentFileId ? `/${currentFileId}` : ''}`, authStateManager._globalAuthState, undefined, 20 * 60 * 60);
                    const serviceConfig = {
                        accessToken: gas.accessToken,
                        timeout: service._timeout,
                        url: service._url,
                        clientId: service._clientId,
                        appId: configData.APP_ID
                    };

                    uploadWorker.postMessage({ file: current.files[0], currentFileId, serviceConfig, isPublic: isPublic ?? false });
                }
            }
            if (dontShowImage) {
                dt.target.value = null;
            }
        }
    }

    const selectFile = (selectedFile) => {
        let isValid = true;
        if (selectedFile) {
            let validationMessages = validateImage(selectedFile);
            if (validationMessages.length > 0) {
                setErrorMessages([...validationMessages]);
                setFileName(null);
                isValid = false;
            }
            else {
                setErrorMessages([]);
                setFileName(selectedFile.name);
            }
        }
        return isValid;
    }

    const validateImage = (selectedFile) => {
        let validationMessages = [];
        let currentMaxFileSize = (maxFileSize ?? (2 * 1024 * 1024));

        if (selectedFile && selectedFile?.type) {
            if (selectedFile.type.toLowerCase() !== "image/png" &&
                selectedFile.type.toLowerCase() !== "image/jpeg" &&
                selectedFile.type.toLowerCase() !== "image/jpg" &&
                selectedFile.type.toLowerCase() !== "image/gif" &&
                selectedFile.type.toLowerCase() !== "image/tiff"
            ) {
                if (canUploadVideo || canUploadAnyFile) {
                    if (selectedFile.type.toLowerCase() !== "video/mp4" &&
                        selectedFile.type.toLowerCase() !== "video/x-msvideo" &&
                        selectedFile.type.toLowerCase() !== "video/x-m4v" &&
                        selectedFile.type.toLowerCase() !== "video/quicktime"
                    ) {
                        if (!canUploadAnyFile) {
                            validationMessages.push("Invalid file type! Please upload a PNG, JPEG, GIF, MP4, M4v, AVI, MOV or MPEG file. ");
                        }
                    }
                }
                else {
                    validationMessages.push("Invalid file type! Please upload a PNG, JPEG, TIFF or GIF file.");
                }
            }
        }

        if (validationMessages.length === 0) {
            if (selectedFile && selectedFile.size > currentMaxFileSize) {
                let number = ((selectedFile.size / 1024) / 1024);
                validationMessages.push(`File size limit: ${(currentMaxFileSize / 1024) / 1024} MB.Attempted file upload size: ${number.toFixed(3)} MB`);
            }
        }

        return validationMessages;
    }

    const handleSelectFile = (event) => {
        if (!disabled) {
            inputFile.current.click();
        }
    }

    const handleSelectFileDoubleClick = (event) => {
        if (!disabled) {
            if (event.detail === 2) {
                event.preventDefault();
                inputFile.current.click();
            }
        }
    }

    const getFileUri = () => {
        if (!fileUri.toLowerCase().includes(".png") &&
            !fileUri.toLowerCase().includes(".jpeg") &&
            !fileUri.toLowerCase().includes(".jpg") &&
            !fileUri.toLowerCase().includes(".gif") &&
            !fileUri.toLowerCase().includes(".tiff") &&
            !fileUri.toLowerCase().includes(".mp4") &&
            !fileUri.toLowerCase().includes(".mpeg") &&
            !fileUri.toLowerCase().includes(".avi") &&
            !fileUri.toLowerCase().includes(".mov") &&
            !fileUri.toLowerCase().includes("streaming.media.azure.net")
        ) {
            return documentIcon;
        }
        else {
            return fileUri;
        }
    }

    const getVideo = useMemo(
        () => <AzureMP
            id="azureplayer"
            skin="amp-flush"
            controls={!hideControls}
            className={'video-player'}
            src={[{ src: fileUrl, type: "application/vnd.ms-sstr+xml" }]}
        />,
        [fileUrl]
    );

    return (
        <>
            <div>
                {errorMessages.map((x, indx) => <div key={indx} className={'error'}>{x}</div>)}
            </div>
            <div className={`upload-area${positionTopZero ? ' top-zero' : ''}`}>
                {fileLoading &&
                    <div className={'image-upload-picture-loader'} style={{ width: `${width}px`, height: `${height}px` }} onDragEnter={handleOnDragEnter} onDragLeave={handleOnDragLeave} onDragOver={handleOnDragOver} onDrop={handleOnDrop} onClick={handleSelectFile}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="200px" height="200px" viewBox="0 0 100 100">
                            <path d="M 50,50 L 33,60.5 a 20 20 -210 1 1 34,0 z" fill="#1d2b727c">
                                <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="1s" repeatCount="indefinite" />
                            </path>
                            <circle cx="50" cy="50" r="16" fill="#f4f1ed"></circle>
                        </svg>
                    </div>
                }
                {!fileLoading && fileUri ?
                    !fileLoading && !dragOn &&
                    <div className={`file-upload-image${disabled ? ' disable-pointer' : ''}`} style={{ width: `${width}px`, height: `${height}px`, backgroundImage: `${!isVideo && !dontShowImage ? `url(${getFileUri()})` : 'none'} ` }} onDragEnter={handleOnDragEnter} onDragOver={handleOnDragOver} onDragLeave={handleOnDragLeave} onDrop={handleOnDrop} onClick={isVideo ? handleSelectFileDoubleClick : handleSelectFile}>
                        {isVideo && fileUrl &&
                            <>{getVideo}</>
                        }
                        {/*<ReactPlayer
                        width="100%"
                        height="100%"
                        playing={playingVideo}
                        controls={playingVideo}
                        url={fileUri}></ReactPlayer>
                        [{ src: , type: "application/vnd.ms-sstr+xml" }]}
                        */}
                    </div>
                    :
                    !fileLoading && !dragOn && <div className={'image-upload-picture-icon no-bk-color'} style={{ width: `${width}px`, height: `${height}px` }} onDragEnter={handleOnDragEnter} onDragLeave={handleOnDragLeave} onDragOver={handleOnDragOver} onDrop={handleOnDrop} onClick={handleSelectFile}>
                        {/* <FontAwesomeIcon icon={faCamera} className={'faCamera-icon'} /> */}
                        {/* <FontAwesomeIcon icon={faPlus} className={'faCamera-icon'} /> */}
                        <div className={'plus-sign'}>+</div>
                    </div>
                }
                {!fileLoading && dragOn && <div className={'image-upload-picture-icon__drag-over'} style={{ width: `${width}px`, height: `${height}px` }} onDragEnter={handleOnDragEnter} onDragLeave={handleOnDragLeave} onDragOver={handleOnDragOver} onDrop={handleOnDrop} onClick={handleSelectFile}>
                    <FontAwesomeIcon icon={faArrowUp} className={'faUpload-icon'} />
                </div>}
                {fileName && !dontShowImage && !dontDisplayFileName && <div className={'file-name'}>Selected File:&nbsp;<span>{fileName}</span></div>}
                <div className={'image-upload-wrapper'}>
                    <input type="file" className={`input-file`} value={''} onChange={(e) => {
                        handleOnFileChange(e.target)
                    }}
                        ref={inputFile}></input>
                </div>
            </div>
        </>
    );
}