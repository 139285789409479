import { useRef } from "react";

const Print = ({ printUrl, onPrinting }) => {

    const loadFrame = () => {
        const handleEvent = e => {
            if (e.data === 'print') {
                if (onPrinting)
                    onPrinting();
                window.removeEventListener('message', handleEvent);
            } else if (e.data.includes('error')) {
                console.log('error', e.data)
                printUrl = false
            }
        };
        window.addEventListener('message', handleEvent);
    }
    return (printUrl && <iframe src={printUrl} width='1' onLoad={loadFrame} height='1' className='print-frame'></iframe>);
}

export default Print;