import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import benchmarkIcon from '../../../../content/icons/benchmark.svg'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/StickyNote2';
import SystemTypes from '../../../../SystemTypes';

const TeacherSurveyList = ({ filteredItems, orgs, onEdit, onView, onPrint, onDuplicate, onDelete, appUserMgr, handlePrintEmpty }) => {
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Title',
            dataProp: 'SurveyTemplateName',
            width: '26%',
            canSort: true
        },
        {
            header: 'Teacher Surveyed',
            dataProp: 'SurveyedTeacher',
            width: '18%',
            canSort: true,
            sortBy: 'SurveyedTeacherFullName'
        },
        {
            header: 'Organization, District, School',
            dataProp: 'FullOrg',
            width: '18%',
            canSort: true,
            sortBy: 'FullOrg'
        },
        {
            header: 'Start Date',
            dataProp: 'SurveyDate',
            datatype: 'dateTime',
            width: '11%',
            canSort: true
        },
        {
            header: 'Surveyor',
            dataProp: 'SurveyGivenBy',
            width: '18%',
            canSort: true
        },
        {
            header: 'Surveyor Role',
            dataProp: 'SurveyorRole',
            width: '10%',
            canSort: true
        },
        {
            header: 'Status',
            dataProp: 'Status',
            width: '8%',
            canSort: true
        },
    ]);

    const statusIdentifer = (item) => {
        if (item?.SurveyStatusType == 1) {
            return "In Progress"
        } else if (item?.SurveyStatusType == 2) {
            return "In Progress"
        } else if (item?.SurveyStatusType == 3) {
            return "Submitted"
        } else return "Not Started"
    }

    const userPositionIdentifer = (value) => {
        const differentName = _.find(appUserMgr?.AppUserState.observationConfig.UserPositionAlternativeMap, x => x?.UserPosition == value && x?.Name)?.Name
        if (differentName) {
            return differentName
        }
        if (value === 40) {
            return "Master Teacher"
        } else if (value === 41) {
            return "Mentor Teacher"
        } else return "Teacher"
    }

    useEffect(() => {
        let newRows = [];
        if (filteredItems && orgs) {
            _.each(filteredItems, (st) => {
                let surveyedFullName = st?.SurveyedUser?.FullName;
                let surveyedEmail = st?.SurveyedUser?.Email;
                let surveyorFullName = st?.User?.FullName;
                let surveyorEmail = st?.User?.Email;
                let org = orgs.find(x => x.OrgId === st.OrganizationId);
                let districtname = st?.School?.District?.Name;
                if(!org?.Name) {
                    if(districtname) {
                        org = orgs.find(x => x.OrgId === st.School.ClientId);
                    };
                }
                newRows.push({
                    ...st,
                    Status: statusIdentifer(st),
                    SurveyGivenBy: st?.SurveyStatusType !== 0 ? st?.User?.FullName !== undefined ? <div>{surveyorFullName}<br /><span className='table-sub-email-survey'>{surveyorEmail}</span></div> : '' : '',
                    SurveyorRole: st?.SurveyStatusType !== 0 ? userPositionIdentifer(st?.User?.UserPositionType) : '',
                    SurveyedTeacher: <div>{surveyedFullName}<br /><span className='table-sub-email-survey'>{surveyedEmail}</span></div>,   
                    SurveyedTeacherFullName: surveyedFullName,   
                    SurveyTemplateName: st?.Name ?? (st?.SurveyTemplate?.Title ?? "NO Survey Configured"),
                    UserPosition: userPositionIdentifer(st.SurveyedUser.UserPositionType),
                    SchoolNameplus: st?.School?.Name,
                    DistrictName: st?.District?.Name,
                    FullOrg: <>{`${org?.Name ?? ''}`}<br />{`${districtname}`}<br /> {`${st?.School?.Name}`}</>,
                    OrgName: org?.Name ?? ''
                });
            });
            setRows(newRows);
        }
    }, [filteredItems, orgs]);

    const handleCheckDisableAction = (row, action) => {
        if (action.toLowerCase() == 'edit' && appUserMgr.AppUserState.userProfile.UserPositionType !== 1) {
            let disabled = false;
            if(statusIdentifer(row) === 'In Progress' && appUserMgr.AppUserState.userProfile.Id !== row?.User?.Id) {
                disabled = true;
            }
            else if (new Date() < new Date(row.SurveyDate) || statusIdentifer(row) === 'Completed' || statusIdentifer(row) === 'Submitted' || !row?.SurveyTemplate || row.UserId == null) {
                disabled = true;
            }
            return disabled;        }
        else if (action.toLowerCase() == 'view') {
            return (statusIdentifer(row) === 'In Progress' || statusIdentifer(row) === 'Not Started' || !row?.SurveyTemplate);
        } else if (action.toLowerCase() == 'print') {
            return (statusIdentifer(row) === 'In Progress' || statusIdentifer(row) === 'Not Started' || !row?.SurveyTemplate);
        }
        else if (action.toLowerCase() == 'custom1') {
            return (statusIdentifer(row) !== 'Not Started' || row.ExculdeFromOverallScoring || !row?.SurveyTemplate);
        }
        else {
            return false;
        }
    }

    const handleCheckHideDelete = (row, action) => {
        if (action === 'delete') {
            if (statusIdentifer(row) === 'Not Started') {
                return true;
            } else {
                return !(appUserMgr.AppUserState.userProfile.UserPositionType === SystemTypes.UserPositionType.Admin || appUserMgr.AppUserState.userProfile.UserPositionType === 20);
            }
        }
        return false;
    };

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                checkDisableAction={handleCheckDisableAction}
                useIntegratedPaging={['10', '25', '50', '100']}
                onCustom1={{
                    action: (row) => <img title='Take Benchmark' className={'btn-icon-fa'} src={benchmarkIcon} onClick={() => {
                        if (!handleCheckDisableAction(row, 'custom1')) {
                            onDuplicate(row)
                        }
                    }} />
                }}
                onCustom2={{
                    action: (row) => <div title='Print Blank Survey' className='icon-wrapper'>
                        <LocalPrintshopOutlinedIcon className='icon' onClick={() => {
                            handlePrintEmpty(row)
                        }} />

                    </div>
                }}
                onPrint={(row) => onPrint(row)}
                onEdit={(row) => onEdit(row)}
                onView={(row) => onView(row)}
                onDelete={(row) => onDelete(row)}
                checkHideAction ={handleCheckHideDelete}
            />
        </div>
    </>
}

export default TeacherSurveyList;