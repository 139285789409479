import './ChartControl.scss';
import React, { useEffect, useState } from 'react';
// import the core library.
import ReactEChartsCore from 'echarts-for-react/lib/core';
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
import { VisualMapComponent } from 'echarts/components';



import { getGaugeOption } from './GaugeOptions';
import { getSimplePieOptions } from './SimplePieOptions';
import { getPieOption } from './getPieOption';
import { getBarOption } from './SimpleBarOptions';
import { getSimpleLineOptions } from './SimpleLineOptions';
// Import charts, all with Chart suffix
import {
    LineChart,
    BarChart,
    PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    TreemapChart,
    // GraphChart,
    GaugeChart,
    // FunnelChart,
    // ParallelChart,
    SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    // LinesChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    // ToolboxComponent,
    TooltipComponent,
    // AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    LegendComponent,
    // LegendScrollComponent,
    // LegendPlainComponent,
    // DataZoomComponent,
    // DataZoomInsideComponent,
    // DataZoomSliderComponent,
    // VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    // TransformComponent,
    DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';
import { getStandardTreeMapOptions } from './TreeMapOptions';
import _ from 'lodash';
import { lv } from 'date-fns/locale';
import { ChurnColors, GrowthColors } from './Colors';
import { getSankyOptions } from './SankyOptions';


const ChartControl = ({
    field,
    xData,
    yData,
    data,
    value,
    onChange,
    onBlur,
    className,
    detailsFormatter,
    disabled,
    customOptions,
    type,
    fixedHeight,
    fixedWidth,
    selectedId,
    selectedX,
    legend,
    style,
    props
}) => {
    const [selected, setSelected] = useState(selectedX ?? []);
    echarts.use([LineChart, VisualMapComponent, TitleComponent, TooltipComponent, GridComponent, GaugeChart, BarChart, SankeyChart, PieChart, TreemapChart, LegendComponent, CanvasRenderer]);

    useEffect(() => {
        setSelected(selectedX)
    }, [selectedX]);

    const onEvents = {
        'click': (e) => {
            if (selectedX === undefined) return;
            let updatedSelected = [...selected];
            if (selected.includes(e.name)) {
                updatedSelected = _.filter(selected, x => x !== e.name);
            }
            else
                updatedSelected.push(e.name);

            setSelected(updatedSelected);
            if (onChange)
                onChange({ ...e, target: { value: e.value } }, updatedSelected);
        }
    }

    const onChartReadyCallback = (e) => {
        // console.log('onChartReadyCallback: ', e);
    }

    let options = null;
    let updatedFixedHeight = fixedHeight;
    //horizontalBarChart,donutChart,verticalBarChart,smallGauge,smallGauge-reversed,treeMap,customCurrentProductChart,customLevelChart
    if (type === 'custom') {
        options = customOptions;
    } else if (type === 'pie') {
        options = getPieOption(data, selectedId);
    } else if (type === 'dashboardPie') {
        options = getSimplePieOptions(data, style, legend)
    }
    else if (type === 'bar') {
        // console.log('bar', data);
        options = getBarOption(data);
    } else if (type === 'line') {
        // console.log('line', data);
        options = getSimpleLineOptions(data, style, legend);
    } else if (type === 'gauge') {
        options = getGaugeOption(data, selectedId)
    }


    return (
        <>

            {type && <ReactEChartsCore
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                style={updatedFixedHeight ? { height: updatedFixedHeight, width: fixedWidth } : undefined}
                onChartReady={onChartReadyCallback}
                onEvents={onEvents}
            />}
        </>
    );
}

export default ChartControl;