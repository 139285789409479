import React, { useState } from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { DatePickerControl } from '../../../../components/controls';
import { useEffect } from 'react';
import { SelectListControl } from '../../../../components/controls';
import moment from 'moment';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import MessageTypes from '../../data/messageTypes.json';

export default function LogFilter({ logMgr, onFilterChange, onClear, updateData }) {
    const [messageTypes] = useState(MessageTypes);
    useEffect(() => {

    }, []);
    const handleMTIDChange = (value) => {
        if (onFilterChange)
            onFilterChange("MessageTypeId", value);
    }

    const handleEventNameChange = (value) => {
        if (onFilterChange)
            onFilterChange("EventName", value);
    }

    const handleLogLevelChange = (value) => {
        if (onFilterChange)
            onFilterChange("LogLevel", value);
    }

    const handleCreatedByChange = (value) => {
        if (onFilterChange)
            onFilterChange("CreatedBy", value);
    }

    const handleCreatedDateChange = (value) => {
        if (onFilterChange)
            onFilterChange("CreatedDate", value);
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        var filter = { MessageTypeId: null, EventName: null, LogLevel: '1', CreatedBy: null, CreatedDate: moment().utc().add(-14, "day").format('YYYY-MM-DD') };
        logMgr.filterData(filter);
        if (onClear)
            onClear(filter);
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Log Management</div>
                    <div className="filterControl">
                        <InputField
                            id='MessageTypeId'
                            title='Message Type'
                            type='number'
                            value={logMgr?.Filter?.MessageTypeId ?? ''}
                            fieldName='MessageTypeId'
                            disableError={true}
                            placeholder='Numeric'
                            onChange={handleMTIDChange}>
                            <SelectListControl textValuePairs={MessageTypes} />
                        </InputField>
                    </div>
                    <div className="filterControl" >
                        <InputField
                            id='EventName'
                            title='Event Name'
                            value={logMgr?.Filter?.EventName ?? ''}
                            fieldName='EventName'
                            disableError={true}
                            placeholder='Event'
                            onChange={handleEventNameChange}>
                        </InputField>
                        <div className="img-wrapper"><RestartAltIcon onClick={() => updateData(logMgr.filter)} /></div>
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='LogLevel'
                            title='Log Level'
                            fieldName='LogLevel'
                            value={logMgr?.Filter?.LogLevel ?? 4}
                            disableError={true}
                            onChange={handleLogLevelChange}>
                            <SelectListControl
                                hidePleaseSelect={true}
                                textValuePairs={[
                                    { text: 'Info', value: 1 },
                                    { text: 'Timing', value: 2 },
                                    { text: 'Warning', value: 3 },
                                    { text: 'Error', value: 4 },
                                    { text: 'Change', value: 5 }
                                ]}
                            />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='CreatedBy'
                            title='Created By'
                            value={logMgr?.Filter?.CreatedBy ?? ''}
                            fieldName='CreatedBy'
                            disableError={true}
                            subTitle='Last Name, First Name'
                            placeholder='Name'
                            onChange={handleCreatedByChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField title="Created Date" value={logMgr?.Filter?.CreatedDate ?? moment().utc().add(-14, "day").format('YYYY-MM-DD')} maxLength={100} onChange={handleCreatedDateChange} groupId={'USER'} fieldName="CreatedDate" disableError={true} >
                            <DatePickerControl includeTime={false} />
                        </InputField>
                    </div>
                    <div className="filterControl btn-clear"
                    >
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}