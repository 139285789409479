import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _ from 'lodash';
import { ConstructionOutlined } from '@mui/icons-material';
import SystemTypes from '../SystemTypes';

class ClusterManager extends ItemManager {
    constructor(orgManager, globalItemState, globalAuthState, globalAppUserState) {
        super(ItemServiceFactory.ItemServiceEnum.Cluster, globalAuthState, globalAppUserState, globalItemState,
            (filter, item) => {
                var hasSearch = item.Name?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
                var IsActive = filter?.IsActive === 'UNK' || (filter?.IsActive === undefined || filter?.IsActive === null ? item.IsActive == true : item.IsActive === filter?.IsActive);
                var hasClientId = item?.ClientId === filter?.ClientId || (filter?.ClientId === undefined || filter?.ClientId === null || filter?.ClientId === 'UNK')
                var hasDistrictId = item?.DistrictId === filter?.DistrictId || (filter?.DistrictId === undefined || filter?.DistrictId === null || filter?.DistrictId === 'UNK')
                var hasSchoolId = item?.SchoolId === filter?.SchoolId || (filter?.SchoolId === undefined || filter?.SchoolId === null || filter?.SchoolId === 'UNK')
                return hasSearch && IsActive && hasClientId && hasDistrictId && hasSchoolId;
            });

        this._userClusterMapSvc = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.UserClusterMap, globalAuthState);
        this._errorUtil = undefined;
        this._orgManager = orgManager;
    }



    async UpdateUserClusters(items) {
        const saveUsers = items.reduce((saveBox, item) => {
            if (!item.IsDeleted) {
                saveBox.push(this._userClusterMapSvc.save(item))
            }
            return saveBox
        }, [])

        const resolvedSaved = await Promise.all(saveUsers);
        const resolvedSaveMap = _.map(resolvedSaved, x => x.first().Items.first())
        const deleteUsers = _.map(items, i => {
            if (i.IsDeleted) {
                this._userClusterMapSvc.hardDelete(i.Id)
            }
        })
        const resolvedDelete = await Promise.all(deleteUsers)
        if (this._allData) {
            const allData = JSON.parse(JSON.stringify(this._allData))
            const item = _.find(allData, (data) => data.Id === items.first().ClusterId)
            item.UserClusters = resolvedSaveMap ?? item.UserClusters
            this._setAllData(allData);
            this.filterData(undefined, allData);
        }
        return resolvedSaved;
    }


    async SchoolUsers(schoolId) {
        const tree = await this._orgManager?.loadOrgUserSubTree(schoolId, 'school')
        const schoolUsers = _.map(this._orgManager?.GetSchoolUsersOnly(tree, schoolId, SystemTypes.UserPositionType.SchoolAdmin, SystemTypes.UserPositionType.Teacher), (user, i) => {
            return {
                ...user,
                label: user.Name,
                value: user.UserId
            }
        })

        return schoolUsers
    }

    MappedUsers(cluster, schoolUsers) {
        return schoolUsers?.reduce((users, schoolUser) => {
            if (_.find(cluster.UserClusters, user => (user.UserId === schoolUser.UserId && !user.IsDeleted))) {
                const userClusterDetails = _.find(cluster.UserClusters, user => user.UserId === schoolUser.UserId)
                users.push({
                    FirstName: schoolUser.Name.split(' ')[0],
                    LastName: schoolUser.Name.split(' ')[1],
                    UserId: schoolUser.UserId,
                    Id: userClusterDetails.Id,
                })
            }
            return _.orderBy(users, ['FirstName'], ['asc'])
        }, [])
    }

    get DefaultItem() {
        return {
            UserClusters: []
        };
    }

}

export default ClusterManager;