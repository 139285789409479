
const asyncForEach = async (array, callback) => {
    if (array == null)
        array = [];
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}


const isDate = (date) => {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

const descendingComparator = (a, b, orderBy) => {
    let orderByA = a[orderBy];
    let orderByB = b[orderBy];

    if (isDate(orderByA)) {
        orderByA = new Date(orderByA);
    }

    if (isDate(orderByB)) {
        orderByB = new Date(orderByB);
    }

    if (orderByB < orderByA) {
        return -1;
    }
    if (orderByB > orderByA) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {

    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el) => [el]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    let result = stabilizedThis.map((el) => el[0]);
    return result;
}

export { asyncForEach, stableSort, getComparator, descendingComparator, isDate };




