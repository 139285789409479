Array.prototype.first = function () {
    if (this.length > 0)
        return this[0];
    else
        return null;
}

/*Object.prototype.first = function() {
    return this;
}*/

Number.prototype.round = function (precision) {
    var numPrecision = (!precision) ? 0 : parseInt(precision, 10);
    var numBig = this * Math.pow(10, numPrecision);
    var roundedNum;
    if (numBig - Math.floor(numBig) == 0.5)
        roundedNum = (Math.round(numBig) + 1) / Math.pow(10, numPrecision);
    else
        roundedNum = Math.round(numBig) / Math.pow(10, numPrecision);

    return roundedNum;
};

