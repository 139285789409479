import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigateBefore, ThumbUp as ThumbsUpIcon } from '@mui/icons-material';
import cx from 'classnames';
import SliderContext from './context'
import ShowDetailsButton from './ShowDetailsButton'
import Mark from './Mark'
import './Item.scss'
import recommendedIcon from '../../../../../../content/icons/recommended-white.svg'

const Item = ({ content, rowKey }) => {

  const navigate = useNavigate();

  const onViewSlide = (content) => {
    navigate(`/content?k=${content.KeyId}`);
  }

  return (
    <SliderContext.Consumer>
      {({ onSelectSlide, currentSlide, elementRef }) => {
        const isActive = currentSlide && currentSlide.KeyId === content.KeyId;
        return (
          <div
            ref={elementRef}
            style={{
              backgroundImage: `url(${content.thumbUrl})`
            }}
            className={cx('item', {
              'item--open': isActive,
            })}
          >
            <span className="itemTitle" onClick={() => onViewSlide(content)} >{content.Title} {content.RecommendedForUser ? <img src={recommendedIcon} className={'title-icon'} /> : null} {content.HasUserRating ? <ThumbsUpIcon  className={'title-icon'} /> : null}</span>
            <ShowDetailsButton rowKey={rowKey} onClick={() => onSelectSlide(content)} />
            {content.RunningTime ? <span className="itemTime">{content.RunningTime}</span> : <span></span>}
            {isActive && <Mark />}
          </div>
        );
      }}
    </SliderContext.Consumer>
  );
}
export default Item;
