import _ from 'lodash';
import React from 'react';
import { useState, useEffect } from 'react';
import './printLayout.scss';
import eepassLogo from '../../content/logos/epass_logo_banner.jpg';
import moment from 'moment';

const PrintLayout = ({ errors, className, children, print, cleanUp, hideLogo }) => {
    const [showError, setShowError] = useState(false);
    const [lastPrint, setLastPrint] = useState(null);
    useEffect(() => {
        if (errors?.length > 0) {
            if (window.parent) {
                setTimeout(() => {
                    window.print();
                    window.parent?.postMessage(`error ${errors}`, '*')
                }, 100);
            }
        }
    }, [errors]);

    useEffect(() => {
        if (print && print !== lastPrint) {
            setLastPrint(print);
            if (cleanUp) {
                window.addEventListener('afterprint', cleanUp)
            }
            if (window.parent) {
                setTimeout(() => {
                    window.print();
                    window.parent?.postMessage('print', '*')
                }, 100);
            }
            else {
                window.print();
            }
        }
        return () => {
            if (cleanUp) {
                window.removeEventListener('afterprint', cleanUp)
            }
        }
    }, [print])

    return (<>
        {<>
            <div className={`print-page ${className ?? ''}`}>
                {eepassLogo && !hideLogo &&
                    <div className='print-header'>
                        <div className='print-banner'>
                            <img src={eepassLogo} />
                        </div>
                    </div>
                }

                {!showError && <div className='print-content'>
                    {children}
                </div>}
                {showError && <div className='print-content'>
                    {_.map(errors, (e, i) => { <div key={`e_${i}`}>e</div> })}
                </div>
                }
            </div>
            <div className="divFooter"></div>
        </>
        }
    </>
    );
}

PrintLayout.defaultProps = {
    hideLogo: false
}

export default PrintLayout;


