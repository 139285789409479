import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose as completeIcon } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useNotificationManager } from "../../../../hooks/useManagers";
import "../MyDashboard.scss";
import { ReactComponent as AlertMessage } from "../assets/images/AlertMessage.svg";
import { ReactComponent as AlertUrgent } from "../assets/images/AlertUrgent.svg";
import moment from "moment/moment";
import Loading from "../../../../components/Loading";
import _ from 'lodash';
import { FormatUTCDateTimeLong } from "../../../../utilities/DateFormater";

function NotificationDashboard({ setErrors, organizationId, districtId, schoolId, userId }) {
    const notificationMgr = useNotificationManager();
    const [notifications, setNotifications] = useState(null);
    const [disabledNotif, setDisabledNotif] = useState([])
    const [loading, setLoading] = useState(false);

    moment.utc(true)
    useEffect(() => {
        if (notificationMgr && (organizationId || districtId || schoolId || userId)) {
            setLoading(true);
            // get notifications using a function that actually filters them
            notificationMgr.getActiveNotifications(userId).then((notifications) => {
                setNotifications(
                    notifications.filter(
                        (n) => {
                            return ((n.ToDate === null ||
                                moment
                                    .parseZone(new Date())
                                    .isBefore(moment.parseZone(n.ToDate))) &&
                                moment.parseZone(new Date()).isAfter(moment.parseZone(n.FromDate))
                            )
                        }
                    )
                );
                setLoading(false);
            });
        }

    }, [notificationMgr, organizationId, districtId, schoolId, userId]);

    const handleUnload = () => {
        const deleteList = _.map(_.filter(notifications, x => !x.IsActive), y => notificationMgr.saveItem(y))
    }

    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [notifications]);
    const onDelete = (notification, isUndo) => {
        // TODO make this target only the user that dismissed it, not globally setting this to not active

        const copiedNotifications = [...notifications]
        const notificationIndex = _.findIndex(notifications, x => x.Id === notification.Id)
        console.log(notificationIndex, notification)

        if (isUndo) {
            notification.IsActive = true;
        }
        else notification.IsActive = false;

        if (notificationIndex !== -1) {
            copiedNotifications.splice(notificationIndex, 1, notification)
        }
        setNotifications(copiedNotifications);
        // notificationMgr.saveItem(notification).then((r) => {
        //     if (!r.Success) {
        //         setNotifications(notifications.filter((n) => n.IsActive));
        //         setErrors(r.Errors);
        //     }
        // });
    };
    return (
        <>
            <div className="title">My Notifications</div>

            <ul className="notification-list">
                {notifications !== null && !loading ? (
                    notifications.map((notification) => {

                        return (
                            <li key={notification.Id} className="notification-list__item">
                                <div className="notification">
                                    {notification.IsActive && <div className="remove-list__remove__icon">
                                        <FontAwesomeIcon
                                            className={"btn-icon-fa"}
                                            icon={completeIcon}
                                            onClick={() => onDelete(notification)}
                                        />
                                    </div>}
                                    {
                                        notification.IsActive ?
                                            <>
                                                <div className="notification-list__item__header">
                                                    <div>
                                                        <div className="notification-list__item__header__icon">
                                                            {notification.NotificationType === 2 ? <AlertUrgent />
                                                                : notification.NotificationType === 1 ?
                                                                    <AlertMessage className="icon-red" /> : <AlertMessage className="message" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div
                                                            className="notification-list__item__header__title"
                                                            style={{
                                                                color:
                                                                    notification.NotificationType === 1 ? '#f47e3a' : notification.NotificationType === 2
                                                                        ? '#C61616'
                                                                        : '',
                                                            }}
                                                        >
                                                            {notification.Title}
                                                        </div>
                                                        <div className="notification-list__item__header__date">
                                                            {FormatUTCDateTimeLong(notification.FromDate)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="notification-list__item__content"
                                                    dangerouslySetInnerHTML={{
                                                        __html: notification.HtmlValue,
                                                    }}
                                                />
                                            </> :
                                            <div className="disabled-notification">
                                                <span onClick={() => onDelete(notification, true)}>UNDO</span>
                                            </div>
                                    }
                                </div>
                            </li>
                        );
                    })
                ) : (
                    <div className="loading-wrapper">
                        <Loading size={'2rem'} />
                    </div>
                )}
            </ul>
        </>
    );
}

export default NotificationDashboard;
