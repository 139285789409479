import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import SchoolYearDetails from '../components/schoolYear/details';
import SchoolYearFilter from '../components/schoolYear/filter';
import SchoolYearTable from '../components/schoolYear/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useAppUserManager, useOrgManager, useSchoolYearManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';

const SchoolYearMgmt = () => {
    const schoolYearMgr = useSchoolYearManager();
    const appUserMgr = useAppUserManager();

    const [manageMode, setManageMode] = useState(null);
    const [loadingRollover, setLoadingRollover] = useState(false);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [rowToRollover, setRowToRollover] = useState(null);
    const orgMgr = useOrgManager();
    const [orgs, setOrgs] = useState([]);

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (orgMgr) {
            orgMgr.loadOrgTree().then(r => {
                setOrgs(r)
            });
        }
    }, [orgMgr]);

    useEffect(() => {
        if (schoolYearMgr && !params?.schoolYearId) {
            setLoading(true);
            schoolYearMgr.search().then(fb => {
                setManageMode('list');
                setLoading(false);
            });
        } else if (!selectedSchoolYear && params?.schoolYearId && schoolYearMgr) {
            setLoading(true);
            if (params?.schoolYearId.toLowerCase() === 'addnew') {
                setSelectedSchoolYear(schoolYearMgr.DefaultItem);
                setManageMode('view');
                setLoading(false);
            }
            else {
                schoolYearMgr.get(params?.schoolYearId).then((r) => {
                    if (r.Success) {
                        setManageMode('view');
                        setSelectedSchoolYear(r.Items.first());
                    }
                    setLoading(false);
                });
            }
        }
    }, [schoolYearMgr, params?.schoolYearId]);

    const onAddNew = () => {
        setSelectedSchoolYear(schoolYearMgr.DefaultItem);
        setManageMode('view');
        navigate('/admin/SchoolYear/addnew');
    }

    const handleOnFilterChange = async (field, value) => {

        const schoolYearFilter = schoolYearMgr.Filter;
        const updatedSchoolYearFilter = { ...schoolYearFilter };
        updatedSchoolYearFilter[field] = (value !== '') ? value : null;
        await schoolYearMgr.filterData(updatedSchoolYearFilter);
    }

    const handleOnSave = async (item) => {

        let result = null;
        const clientId = item.ClientId ?? null;
        const schoolYearIdentifier = item.SchoolYearIdentifier;
        const schoolYearId = item.Id ?? null;

        const query = "x => x.ClientId == clientId && x.IsDeleted == 0 && x.SchoolYearIdentifier == schoolYearIdentifier && x.Id != schoolYearId";
        const schoolYearsForClient = await schoolYearMgr.query(query, { clientId, schoolYearIdentifier, schoolYearId });
        let saveErrors = "";

        if (schoolYearsForClient.Success && schoolYearsForClient.Items.length === 0) {
            result = await schoolYearMgr.saveItem(item);
            if (result.Success) {
                setSelectedSchoolYear(null);
                navigate('/admin/SchoolYear')
                return result;
            }
            else {
                setErrors(result.MessageDetails)
            }
        }
        else if (!clientId) {
            saveErrors = "Please select a Organization!";
        }
        else {
            saveErrors = "School Year for the selected organization already exists.  Please choose a different Organization.";
        }
        return result ?? {
            Success: false, Message: { DisplayMessage: saveErrors }
        };

    }

    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        schoolYearMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.SchoolYear}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnEdit = (item) => {
        setSelectedSchoolYear(item);
        setManageMode('view');
        navigate(`/admin/SchoolYear/${item.Id}`);
    }

    const handleOnCopy = (item) => {
        const newItem = { ...item };
        newItem.isCopy = true;
        newItem.Id = undefined;
        newItem.UniqueId = undefined;
        newItem._id = undefined;
        newItem.RolloverCompletedDate = null;
        newItem.SchoolYearId = undefined;
        setSelectedSchoolYear(newItem);
        setManageMode('view');
        navigate(`/admin/SchoolYear/addnew`);
    }


    const handleOnCancel = () => {
        navigate('/admin/SchoolYear');
        if (schoolYearMgr.FilteredData) {
            setManageMode('list');
        }
        else {
            setManageMode(null);
        }
        setLoadingError(null);
        setErrors(null);
    }

    const onDoRollover = (row) => {
        if (row) {
            let schoolYearIdentifier = appUserMgr.currentSchoolYear;
            let orgId = row?.ClientId;
            let force = false;
            if (row?.Id) {
                schoolYearIdentifier = row.SchoolYearIdentifier;
                force = true;
            }

            setLoadingRollover(true);
            schoolYearMgr.doRollover(schoolYearIdentifier, orgId, force).then((r) => {
                if (r.Success) {
                    window.location.href = window.location.href;
                    setLoadingRollover(false);
                }
                else {
                    setLoadingRollover(false);
                    setRowToRollover(null);
                    setErrors([<><b>Failed to rollover</b>, if this problem continues please contact customer support.</>, ...r.MessageDetails]);
                }
            });
        }
    }

    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete the school year '${itemToDelete?.Title}' ? `} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <DialogControl loadingOk={loadingRollover} openDialog={rowToRollover} title={'Confirm Rollover'} subTitle={!rowToRollover?.Id ? 'All School Years meet that meet the rollover criteria will be moved to the next School Year.' : `Are you sure you want to FORCE rollover the School Year: ${rowToRollover?.SchoolYearIdentifier}`} onCancel={() => setRowToRollover(null)} onOk={() => onDoRollover(rowToRollover)} okText={'Confirm'}>
            {!rowToRollover?.Id ?
                <><b>This action cannot be reversed.</b><br />Please click "Confirm" if you wish to continue.</> :
                <><b>This action cannot be reversed.</b><br />By clicking "Confirm", the rollover will happen immediately and not wait unill the the night of the rollover date.</>
            }
            <br />
            <br />
            <i>Please note, that the screen will be refresh after the operation completes.</i >

        </DialogControl >
        <MainLayout errors={errors} validationErrors={validationErrors} >
            {manageMode === 'view' &&
                <div className='screen-header'>
                    <h3>School Year Management</h3>
                    <h5>Edit School Year</h5>
                </div>
            }
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <SchoolYearFilter orgs={orgs} schoolYearMgr={schoolYearMgr} onFilterChange={handleOnFilterChange} />
                            <SchoolYearTable onRollover={setRowToRollover} orgs={orgs} filteredItems={schoolYearMgr?.FilteredData ?? []} onEdit={handleOnEdit} onCopy={handleOnCopy} onDelete={(item) => { setItemToDelete(item); }} />
                        </div>
                        <div className='screen-footer list'>
                            {appUserMgr.canExecute('SchoolYearRollOver') && <ButtonControl field={{ FieldName: 'rollover-btn' }} type={"okay"} onClick={() => setRowToRollover({})}>Rollover School Years</ButtonControl>}
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&
                    <SchoolYearDetails schoolYear={selectedSchoolYear} onCancel={handleOnCancel} onSave={handleOnSave} onLoading={setLoading} onErrors={setErrors} onValidationErrors={setValidationErrors} onLoadingError={setLoadingError} />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default SchoolYearMgmt;