import moment from "moment";

const ObservationValidateObject = () => {
    return {
        ClientId: {
            fieldName: 'ClientId',
            messageTitle: 'organization',
            isRequired: true,
            dataType: 'SELECT-AN',
            groupId: 'OBSERVATION-PHASE-ONE'
        },
        DistrictId: {
            fieldName: 'DistrictId',
            messageTitle: 'district',
            isRequired: true,
            dataType: 'SELECT',
            groupId: 'OBSERVATION-PHASE-ONE'
        },
        SchoolId: {
            fieldName: 'SchoolId',
            messageTitle: 'school',
            isRequired: true,
            dataType: 'SELECT',
            groupId: 'OBSERVATION-PHASE-ONE'
        },
        ObserverUser: {
            fieldName: 'ObserverUser',
            messageTitle: 'observer',
            isRequired: true,
            dataType: 'USER',
            groupId: 'OBSERVATION-PHASE-TWO'
        },
        PlannedObservationDateStart: {
            fieldName: 'PlannedObservationDateStart',
            messageTitle: 'start date & time',
            isRequired: true,
            dataType: 'DATETIME',
            groupId: 'OBSERVATION-PHASE-TWO'
        },
        PlannedObservationTimeEnd: {
            fieldName: 'PlannedObservationTimeEnd',
            messageTitle: 'end time',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'OBSERVATION-PHASE-TWO',
            customValidator: (value, data) => {
                if (value && data && value !== 'Invalid date') {
                    let momentDate = moment(data?.PlannedObservationDateStart ?? moment());
                    if (value.includes(':')) {
                        momentDate = moment(`${momentDate.format('YYYY-MM-DD')} ${value}`);
                    }
                    const plannedObservationTimeEnd = momentDate.utc().format();
                    if (moment(plannedObservationTimeEnd).toDate() <= moment(data?.PlannedObservationDateStart).toDate()) {
                        return 'Enter a time after the start time.';
                    }
                }
                else if (value == 'Invalid date') {
                    return 'Please enter an end time.';
                }
            }
        },
        ObservationType: {
            fieldName: 'ObservationType',
            messageTitle: 'observation type',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'OBSERVATION-PHASE-TWO'
        },
        RubricId: {
            fieldName: 'RubricId',
            messageTitle: 'rubric',
            isRequired: true,
            dataType: 'SELECT',
            groupId: 'OBSERVATION-PHASE-TWO'
        },
        UserObserved: {
            fieldName: 'UserObserved',
            messageTitle: 'observed user',
            isRequired: true,
            dataType: 'USER',
            groupId: 'OBSERVATION-PHASE-TWO'
        },
        SubjectsTaught: {
            fieldName: 'SubjectsTaught',
            messageTitle: 'subjects taught',
            isRequired: true,
            dataType: 'ARRAY',
            groupId: 'OBSERVATION-PHASE-TWO-TEACHER'
        },
        GradeLevels: {
            fieldName: 'GradeLevels',
            messageTitle: 'grade levels',
            isRequired: true,
            dataType: 'ARRAY',
            groupId: 'OBSERVATION-PHASE-TWO-TEACHER'
        },
        PlannedPostConferanceDate: {
            fieldName: 'PlannedPostConferanceDate',
            messageTitle: 'Planned Post Conferance Date',
            isRequired: false,
            dataType: 'DATETIME',
            groupId: 'OBSERVATION-PHASE-TWO'
        },









        ObserverUser: {
            fieldName: 'ObserverUser',
            messageTitle: 'observer',
            isRequired: true,
            dataType: 'USER',
            groupId: 'OBSERVATION-PHASE-TWO'
        },
        PlannedObservationDateStart: {
            fieldName: 'PlannedObservationDateStart',
            messageTitle: 'start date & time',
            isRequired: true,
            dataType: 'DATETIME',
            groupId: 'OBSERVATION-PHASE-TWO'
        },
        PlannedObservationTimeEnd: {
            fieldName: 'PlannedObservationTimeEnd',
            messageTitle: 'end time',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'OBSERVATION-PHASE-TWO',
            customValidator: (value, data) => {
                if (value && data && value !== 'Invalid date') {
                    let momentDate = moment(data?.PlannedObservationDateStart ?? moment());
                    if (value.includes(':')) {
                        momentDate = moment(`${momentDate.format('YYYY-MM-DD')} ${value}`);
                    }
                    const plannedObservationTimeEnd = momentDate.utc().format();
                    if (moment(plannedObservationTimeEnd).toDate() <= moment(data?.PlannedObservationDateStart).toDate()) {
                        return 'Enter a time after the start time.';
                    }
                }
                else if (value == 'Invalid date') {
                    return 'Please enter an end time.';
                }
            }
        },
        ObservationType: {
            fieldName: 'ObservationType',
            messageTitle: 'observation type',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'OBSERVATION-PHASE-TWO'
        },
        RubricId: {
            fieldName: 'RubricId',
            messageTitle: 'rubric',
            isRequired: true,
            dataType: 'SELECT',
            groupId: 'OBSERVATION-PHASE-TWO'
        },
        UserObserved: {
            fieldName: 'UserObserved',
            messageTitle: 'observed user',
            isRequired: true,
            dataType: 'USER',
            groupId: 'OBSERVATION-PHASE-TWO'
        },
        PlannedPostConferanceDate: {
            fieldName: 'PlannedPostConferanceDate',
            messageTitle: 'Planned Post Conferance Date',
            isRequired: false,
            dataType: 'DATETIME',
            groupId: 'OBSERVATION-PHASE-TWO'
        },
    }
}

export default ObservationValidateObject;