import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { FormatUTCDate, FormatUTCDateTime } from "../../../../utilities/DateFormater";



const RoleTable = ({ filteredItems, onEdit, onDelete }) => {
    const [rows, setRows] = useState([]);
    const [columns] = useState([

        {
            header: 'Terms of Service',
            dataProp: 'TermsHtml',
            width: '70%',
            datatype: 'html',
            canSort: true
        },
        {
            header: 'Created Date',
            dataProp: "CreatedDate",
            width: '15%',
            canSort: true
        },
        {
            header: 'Active?',
            dataProp: 'IsActive',
            width: '10%',
            canSort: true
        }
    ]);

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (c) => {
                newRows.push({
                    ...c,
                    Active: c.IsActive ? 'Yes' : 'No',
                    CreatedDate: FormatUTCDateTime(c.CreatedDate)
                });
            });
            setRows(newRows);
        }
    }, [filteredItems]);

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                permission='TOS'
                useIntegratedPaging={['10', '25', '50', '100']}
                onEdit={(row) => onEdit(row)}
                onDelete={(row) => onDelete(row)}
            />
        </div>
    </>
}

export default RoleTable;