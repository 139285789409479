import { ItemServiceFactory } from "../services/ItemServiceFactory";
import Manager from "./Manager";
import Moment from 'moment';
import _ from 'lodash';

import AppUserManager from './AppUserManager';

class ItemManager extends Manager {
    constructor(itemServiceName, globalAuthState, globalAppUserState, globalItemState, doItemFilter, doSearch) {
        super(globalAuthState, globalAppUserState);
        this._itemService = ItemServiceFactory.GetItemService(itemServiceName, globalAuthState);
        this.fieldData = {};
        if (globalItemState) {
            this._globalItemState = globalItemState;
            this._setAllData = (value) => this._globalItemState.merge({ allData: value });
            this._setFilter = (value) => this._globalItemState.merge({ filter: value });
            this._setfilteredData = (value, filter) => this._globalItemState.merge({ filteredData: value, filter });
            this._setForceUpdate = (value) => this._globalItemState.merge({ forceUpdate: value });
        }

        this._itemServiceName = itemServiceName;
        this._doItemFilter = doItemFilter;
        this._doSearch = doSearch;
    }

    get CanView() {
        return AppUserManager.checkPermission(this._itemServiceName, 'View', this.AppUserState,);
    }
    get CanWrite() {
        return AppUserManager.checkPermission(this._itemServiceName, 'Write', this.AppUserState);
    }
    get CanExecute() {
        return AppUserManager.checkPermission(this._itemServiceName, 'Execute', this.AppUserState,);
    }
    get CanHardDelete() {
        return AppUserManager.checkPermission(this._itemServiceName, 'HardDelete', this.AppUserState,);
    }
    get CanDelete() {
        return AppUserManager.checkPermission(this._itemServiceName, 'Delete', this.AppUserState,);
    }

    GetGlobalState() {
        return this._globalItemState?.get({ noproxy: true });
    }

    SetForceUpdate(value) {
        this._setForceUpdate(value);
    }

    get Filter() {
        return this.GetGlobalState()?.filter;
    }

    get FilteredData() {
        return this.GetGlobalState()?.filteredData;
    }


    get _allData() {
        return this.GetGlobalState()?.allData;
    }


    validateValue(value, field) {
        let message;
        if (value !== null && value !== undefined && value !== "") {
            if (field.ValidationRegex !== null && field.ValidationMessage !== null) {
                var regEx = new RegExp(field.ValidationRegex);
                if (!regEx.test(value)) {
                    message = field.ValidationRegexMessage;
                }
            }
        }

        return message;
    }

    validate(item, fields) {
        const validationResult = [];
        fields.forEach(field => {
            const value = item[field.FieldName];
            if (field.IsRequired && (value === null || value === undefined || value === "")) {
                validationResult.push(`${field.DisplayName} is Required.`);
            }
            else {
                const validationMessage = this.validateValue(value, field);
                if (validationMessage)
                    validationResult.push(`${validationMessage} for ${field.DisplayName}.`);
            }
        });
        return validationResult;
    }


    formatFieldValue(field, value) {
        let formattedValue = value;
        try {
            if (field) {
                let format = field.Format;
                if (format === "g") {
                    formattedValue = `Manage ${field.DisplayName} (${(value?.length ?? 0)})`;
                }
                else if (field?.DataTypeName === "Boolean") {
                    formattedValue = value ? "Yes" : "No";
                }
                else if (field?.DataTypeName === "DateTime") {
                    if (format === "s") {
                        formattedValue = Moment(value).format("MM/DD/YYYY h:mm:ss a")
                    }
                    else {
                        formattedValue = Moment(value).format(format);
                    }
                }
                else {
                    if (format === "%" && (field?.DataTypeName?.toLowerCase() === "decimal" || field?.DataTypeName?.toLowerCase() === "float")) {
                        const valueFormatted = Number(value * 100).toLocaleString();
                        formattedValue = `${valueFormatted}%`;
                    }
                    else if (format === "$" && (field?.DataTypeName?.toLowerCase() === "decimal" || field?.DataTypeName?.toLowerCase() === "float")) {
                        const valueFormatted = Number(value).toFixed(2);
                        formattedValue = `$${valueFormatted}`;
                    }
                    else if (format.startsWith("f") && (field?.DataTypeName?.toLowerCase() === "decimal" || field?.DataTypeName?.toLowerCase() === "float")) {
                        const valueFormatted = Number(value).toFixed(Number(format.substring(1, 2)));
                        formattedValue = `${valueFormatted}`;
                    }
                    else if (format.startsWith("r") && (field?.DataTypeName?.toLowerCase() === "decimal" || field?.DataTypeName?.toLowerCase() === "float")) {
                        const valueFormatted = Number(value).toFixed(Number(value).round(format.substring(1, 2)));
                        formattedValue = `${valueFormatted}`;
                    }
                    else if (format === "json") {

                        if (field?.DataJson) {
                            if (!this.fieldData[field.FieldName]) {
                                if (typeof (field.DataJson) === "string") {
                                    this.fieldData[field.FieldName] = JSON.parse(field.DataJson);
                                }
                                else {
                                    this.fieldData[field.FieldName] = field.DataJson;
                                }
                            }
                            let textValue = _.find(this.fieldData[field.FieldName], x => { return x.Value === value });
                            if (textValue)
                                formattedValue = textValue.Text;
                        }

                    }
                }
            }
        }
        catch (e) {
            formattedValue = value;
        }
        return formattedValue;
    }

    async save(item) {
        this.WriteDebugLog("ItemManager.save", "Save '" + this._itemService.APIName + " Item.'");
        return this._itemService.save(item).then((r) => {
            if ((r?.length !== undefined && r?.first()?.Success) || r?.Success) {
                this.WriteDebugLog("ItemManager.save", "Save '" + this._itemService.APIName + " Item saved Successfully.'");
            }
            else {
                this.WriteErrorLog("ItemManager.save", "Save '" + this._itemService.APIName + " Item save Failed: " + r?.Message?.DisplayMessage ?? "", (r?.length !== undefined ? r?.first() : r)?.MessageDetails);
                this.HandleErrorResult(r.first());
            }
            return r.first();
        });
    }

    async get(id) {
        this.WriteDebugLog("ItemManager.get", "Get Single '" + this._itemService.APIName + "' Item ( " + id + ").");
        return this._itemService.single(id).then((r) => {
            if ((r?.length !== undefined && r?.first()?.Success) || r?.Success) {
                this.WriteDebugLog("ItemManager.", "Get Single '" + this._itemService.APIName + "' Item returned Successfully (" + id + ").");
            }
            else {
                this.WriteErrorLog("ItemManager.get", `Get Single '${this._itemService.APIName}' Item failed to return (${id}) ${": " + (r?.Message?.DisplayMessage ?? "")}`, (r?.length !== undefined ? r?.first() : r)?.MessageDetails);
                this.HandleErrorResult(r?.first());
            }

            return r?.first();
        });
    }

    async query(lambdaStr, params, ignoreSqlInclude, itemIncludes, take) {
        const itemFilter = this.buildJSONObjFilter(lambdaStr, params, take);
        return this.list(itemFilter, ignoreSqlInclude, itemIncludes);
    }

    async list(itemFilter, ignoreSqlInclude, itemIncludes) {
        this.WriteDebugLog("ItemManager.list", `Get List of '${this._itemService.APIName}'.`);
        itemFilter = itemFilter ?? {};
        return this._itemService.list(itemFilter, ignoreSqlInclude, itemIncludes).then((r) => {
            if ((r.length !== undefined && r?.first()?.Success) || r?.Success) {
                this.WriteDebugLog("ItemManager.list", `Get List of '${this._itemService.APIName}' returned Successfully.`);
            }
            else {
                this.WriteErrorLog("ItemManager.list", `Get List of '${this._itemService.APIName}' failed to return ${(r?.Message?.DisplayMessage ? ": " + r?.Message?.DisplayMessage : "")}`, (r ? r?.first() : r)?.MessageDetails);
                this.HandleErrorResult(r.first());
            }
            this._globalAppUserState.merge({ loadingData: false })
            return r?.first();
        });
    }

    async delete(id, deletePermanently) {
        if (deletePermanently) {
            this.WriteDebugLog("ItemManager.delete|permanently", "Permanently delete '" + this._itemService.APIName + "' Item (" + id + ").");
            return this._itemService.hardDelete(id).then((r) => {
                if ((r?.length !== undefined && r?.first()?.Success) || r?.Success) {
                    this.WriteDebugLog("ItemManager.delete|permanently", "'" + this._itemService.APIName + "' Item permanently deleted Successfully (" + id + ").'");
                }
                else {
                    this.WriteErrorLog("ItemManager.delete|permanently", "'" + this._itemService.APIName + "' Item failed to permanently delete (" + id + "): " + r?.Message?.DisplayMessage ?? "" + ".", (r?.length !== undefined ? r?.first() : r)?.MessageDetails);
                    this.HandleErrorResult(r.first());
                }

                return r?.first();
            });
        }
        else {
            this.WriteDebugLog("ItemManager.delete", "Delete '" + this._itemService.APIName + "' Item (" + id + ").");
            return this._itemService.delete(id).then((r) => {
                if ((r?.length !== undefined && r?.first()?.Success) || r?.Success) {
                    this.WriteDebugLog("ItemManager.delete", "'" + this._itemService.APIName + "' Item deleted Successfully (" + id + ").'");
                }
                else {
                    this.WriteErrorLog("ItemManager.delete", "'" + this._itemService.APIName + "' Item failed to delete (" + id + "): " + r?.Message?.DisplayMessage ?? "" + ".", (r?.length !== undefined ? r?.first() : r)?.MessageDetails);
                    this.HandleErrorResult(r.first());
                }

                return r?.first();
            });
        }
    }

    async runOperation(operationName, id, item, queryParams, isFile, isItem) {
        this.WriteDebugLog("ItemManager.runOperation", operationName + " operation  for '" + this._itemService.APIName + "' is running.");

        const resultFunction = (r) => {
            if ((r?.length !== undefined && r?.first()?.Success) || r?.Success) {
                this.WriteDebugLog("ItemManager.runOperation", operationName + " operation  for '" + this._itemService.APIName + "' ran Successfully.'");
            }
            else {
                this.WriteErrorLog("ItemManager.runOperation", operationName + " operation  for '" + this._itemService.APIName + "' failed: " + r?.Message?.DisplayMessage ?? "", (r?.length !== undefined ? r?.first() : r)?.MessageDetails);
                this.HandleErrorResult(r.first());
            }

            return r?.first();
        }

        if (item) {
            return await this._itemService.postOperation(operationName, id, item, queryParams, isFile, isItem).then(resultFunction);
        }
        else {
            return await this._itemService.getOperation(operationName, id, queryParams, isFile).then(resultFunction);
        }
    }

    async getSelectData(uiMetadataField, queryParams) {
        let dataUri = uiMetadataField.DataUri ?? "SelectData";
        this.WriteDebugLog("ItemManager.getSelectData", `Get Select Data for '${this._itemService.APIName}/${uiMetadataField.FieldName}' Metadata.`);
        return this._itemService.getOperation(dataUri, uiMetadataField.DataOperation, queryParams).then((r) => {
            if ((r?.length !== undefined && r?.first()?.Success) || r?.Success) {
                this.WriteDebugLog("ItemManager.getSelectData", `Get Select Data for '${this._itemService.APIName}/${uiMetadataField.FieldName}' returned Successfully.`);
                return r.first();
            }
            else {
                this.WriteErrorLog("ItemManager.getSelectData", `Get Select Data '${this._itemService.APIName}/${uiMetadataField.FieldName}' failed to return`, (r?.length !== undefined ? r?.first() : r)?.MessageDetails);
                this.HandleErrorResult(r.first());
                return r?.first();
            }
        });
    }

    async getOperationRequest(dataUri, operationName, queryParams, isFile) { //passed in for instance 'File/Generate/id'
        this.WriteDebugLog("ItemManager.getSelectData", `Make Get Operation Request for '${this._itemService.APIName}/${dataUri}'`);
        return this._itemService.getOperation(dataUri, operationName, queryParams, isFile).then((r) => {
            if ((r?.length !== undefined && r?.first()?.Success) || r?.Success) {
                this.WriteDebugLog("ItemManager.getOperationRequest", `Make Get Operation Request for '${this._itemService.APIName}/${dataUri}' returned Successfully.`);
                return r.first();
            }
            else {
                this.WriteErrorLog("ItemManager.getOperationRequest", `Make Get Operation Request for '${this._itemService.APIName}/${dataUri}' failed to return`, (r?.length !== undefined ? r?.first() : r)?.MessageDetails);
                this.HandleErrorResult(r.first());
                return r?.first();
            }
        });
    }



    async getMetadata() {
        this.WriteDebugLog("ItemManager.get", "Get Metadata '" + this._itemService.APIName + "' Metadata.");
        return this._itemService.uiMetadata().then((r) => {
            if ((r?.length !== undefined && r?.first()?.Success) || r?.Success) {
                this.WriteDebugLog("ItemManager.", "Get Metadata '" + this._itemService.APIName + "' Metadata returned Successfully.");
                return r.first();
            }
            else {
                this.WriteErrorLog("ItemManager.get", "Get Metadata '" + this._itemService.APIName + "' Metadata failed to return", (r?.length !== undefined ? r?.first() : r)?.MessageDetails);
                this.HandleErrorResult(r.first());
                return r?.first();
            }
        });
    }



    async filterData(filter, allData) {
        if (!filter)
            filter = this.Filter;

        if (!allData)
            allData = this._allData;


        const filtered = _.filter(allData, d => {
            if (this._doItemFilter !== undefined) {

                return this._doItemFilter(filter, d);
            }
            else {
                return true;
            }
        });

        this._setfilteredData([...filtered], { ...filter });
        return filtered;
    }


    async search(searchModel, forceUpdate, params, ignoreSqlIncludes, includes, take) {
        if (!this._allData || this._forceUpdate || forceUpdate) {
            return (this._doSearch ? this._doSearch(searchModel, params, ignoreSqlIncludes, includes) : ((typeof searchModel === 'string' && searchModel?.includes("=>")) ? this.query(searchModel, params, ignoreSqlIncludes, includes, take) : this.list(searchModel, ignoreSqlIncludes, includes))).then((r) => {
                if (r.Success) {
                    this._globalAppUserState.merge({ loadingData: false })
                    this._setAllData(r.Items);
                    if (this._forceUpdate)
                        this._setForceUpdate(false);
                    return this.filterData(this._filter, r.Items).then(fc => {
                        this._setfilteredData([...fc], { ...this.Filter });
                        return fc;
                    });
                }
                else {
                    this._globalAppUserState.merge({ loadingData: false })
                    this._errorUtil.AddError(r);
                }
            });
        } else this._globalAppUserState.merge({ loadingData: false })
    }

    async deleteItem(item) {
        const allData = _.filter([...this._allData], x => x.Id !== item.Id);
        this._setAllData(allData);
        this.filterData(this.Filter, allData);
        return this.delete(item.Id).then((r) => {
            if (!r.Success) {
                if (this._allData) {
                    const dataUpdate = [...this._allData];
                    dataUpdate.push(item);
                    this._setAllData(dataUpdate);
                    this.filterData(this.Filter, dataUpdate);
                }

            }
            return r;
        });
    }

    async saveItem(item) {
        return await this.save(item).then((r) => {
            if (r.Success) {
                if (this._allData) {
                    const allData = [...this._allData];
                    var savedItem = r.Items.first();
                    const dataUpdate = _.find(allData, x => x.Id == savedItem.Id);
                    if (dataUpdate) {
                        for (const key in savedItem) {
                            dataUpdate[key] = savedItem[key];
                        }
                    }
                    else {
                        allData.unshift(r.Items.first());
                    }
                    this._setAllData(allData);
                    this.filterData(this.Filter, allData);
                }
            }
            return r;
        });
    }

    async getFile(id, fileName) {
        return await this._itemService.getFile(id, fileName);
    }

    buildJSONObjFilter = (filter, params, take) => {
        return ItemManager.JSONObjFilter(filter, params, take);
    }

    static JSONObjFilter = (filter, params, take) => {
        // All filter being passed must be strings because the transpiler is converting es6 into es5
        // -- HOW TO FORMULATE THE FILTER IF IT DONT WORK --
        // Pass in the actual function as the filter and console log it.
        // Now replace your filter with the filter being logged and surround it with quotes and voila.

        let jsonObject = {};
        const builder = (expression) => {
            const result = {};
            const splitGroup = (str, substring) => {
                let count = 0;
                let operationIndex = str.indexOf(substring);
                let before = str.slice(0, operationIndex);
                let after = str.slice(operationIndex + 2);
                if (str[0] === '(') {
                    for (let i = 0; i < str.length - 1; i++) {
                        if (str[i] === "(") {
                            count += 1;
                        } else if (str[i] === ")") {
                            count -= 1;
                            if (count === 0) {
                                before = str.substring(1, i);
                                after = str.substring(i + 1, str.length).replace(` ${substring} `, '')
                            }
                        }
                    }
                }
                return [before, after];
            }

            if (expression.includes("&&")) {
                result.Variable = "x";
                result.Method = "&&";
                result.IsConjunction = true;
                let [left, right] = splitGroup(expression, "&&");
                result.Left = builder(left.trim());
                result.Right = builder(right.trim());
            } else if (expression.includes("||")) {
                result.Variable = "x";
                result.Method = "||";
                result.IsConjunction = true;
                let [left, right] = splitGroup(expression, "||");
                result.Left = builder(left.trim());
                result.Right = builder(right.trim());
            } else {
                let [name, method, ...value] = expression.split(" ");
                value = value.join(" ")
                result.Variable = "x";
                result.Name = name.trim().replaceAll('(', '');
                result.Method = method?.trim();
                if (method.toLowerCase() === "like") {
                    //Remove Quotes Here
                    if (value.startsWith('%') && value.endsWith('%')) {
                        result.Method = 'Contains'
                        if (value.includes('"')) {
                            value = value.substring(2, value.length - 2)
                        } else {
                            value = value.substring(1, value.length - 1)
                        }
                    } else if (value.startsWith('%')) {
                        result.Method = 'EndsWith'
                        value = value.substring(1, value.length)
                    } else if (value.endsWith('%')) {
                        result.Method = 'StartsWith'
                        value = value.substring(0, value.length - 1)
                    } else result.Method = '=='
                    //Add Quotes Back In\
                    value = `"${value}"`
                }

                result.Value = value?.substring(0, 1) === '"' ? value.substring(1, value.length - 1).trim().replaceAll(')', '') : parseFloat(value) ? parseFloat(value) : value.trim().replaceAll(')', '') === "true" ? true : value.trim().replaceAll(')', '') === "null" ? null : false
                result.IsConjunction = false;
            }
            return result;
        }

        if (filter) {
            filter = filter.replace('=>', '').replaceAll('x.', '').replaceAll('  ', '').slice(1)
            _.each(Object.keys(params ?? {}), p => filter = filter.replaceAll(p, typeof params[p] === 'string' ? `"${params[p]}"` : Array.isArray(params[p]) ? params[p].join(" || ") : params[p]));

            jsonObject = {
                RootFilterNode: builder(filter)
            };
        }
        else {
            jsonObject = {
                RootFilterNode: builder('x => x.IsDeleted == false && x.IsActive == true'.replace('=>', '').replaceAll('x.', '').replaceAll('  ', '').slice(1))
            };
        }
        if (take) {

            jsonObject.Take = take
        }

        return jsonObject
    }
}

export default ItemManager;