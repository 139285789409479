import { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { createContext } from "react"
import { useAdminSurveyManager, useAppUserManager, useGeneralSurveyManager, useObservationManager, useOrgManager, useSurveyManager, useSurveyTemplateManager, useTeacherSurveyManager } from '../../../hooks/useManagers';

export const SurveyContext = createContext();


export const SurveyDataProvider = ({ children }) => {
    const [managerIsReady, setManagerIsReady] = useState(false)
    const surveyTemplateMgr = useSurveyTemplateManager()
    const generalSurveyMgr = useGeneralSurveyManager();
    const teacherSurveyMgr = useTeacherSurveyManager();
    const adminSurveyMgr = useAdminSurveyManager();
    const surveyMgr = useSurveyManager();
    const observationMgr = useObservationManager();
    const orgMgr = useOrgManager();
    const appUserMgr = useAppUserManager();

    useEffect(() => {
        if (surveyTemplateMgr && generalSurveyMgr && teacherSurveyMgr && orgMgr && adminSurveyMgr && observationMgr && surveyMgr && appUserMgr) {
            setManagerIsReady(true)
        }
    }, [surveyTemplateMgr, generalSurveyMgr, teacherSurveyMgr, orgMgr, adminSurveyMgr, observationMgr, surveyMgr, appUserMgr]);

    return (
        <SurveyContext.Provider value={{
            managerIsReady: managerIsReady,

            surveyTemplateMgr: surveyTemplateMgr,
            generalSurveyMgr: generalSurveyMgr,
            teacherSurveyMgr: teacherSurveyMgr,
            orgMgr: orgMgr,
            observationMgr: observationMgr,
            adminSurveyMgr: adminSurveyMgr,
            surveyMgr: surveyMgr,
            appUserMgr: appUserMgr
        }}>
            {children}
        </SurveyContext.Provider>
    );
};

export const useSurveyContext = () => useContext(SurveyContext);
