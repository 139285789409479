import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const getSimpleLineOptions = (data, style) => {

  const option = {
    title: {
      text: style?.title,
      textStyle: {
        color: '#003c5b',
      },
      top: '5%',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
    },
    grid: {
      bottom: '10%',
    },
    xAxis: {
      type: 'category',
      data: data.xAxis,
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        rotate: '45'
      },
      axisLine: {
        onZero: false,
      },
    },
    yAxis: {
      type: 'value',
      scale: true,
    },

    series: _.map(data.yAxis, (v, i) => ({
      type: 'line',
      name: v.name,
      data: v.data,
      showSymbol: false,
      emphasis: {
        focus: 'series'
      },
      color: style?.colors[i]
    })),
  };

  return option;
};

getSimpleLineOptions.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string,
    })
  ).isRequired,
  style: PropTypes.shape({
    sorted: PropTypes.bool,
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

getSimpleLineOptions.defaultProps = {
  style: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
};

export { getSimpleLineOptions };
