import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _ from 'lodash';
import { ConstructionOutlined } from '@mui/icons-material';

class SchoolManager extends ItemManager {
    constructor(schoolYear, globalItemState, globalAuthState, globalAppUserState) {
        super(ItemServiceFactory.ItemServiceEnum.School, globalAuthState, globalAppUserState, globalItemState,
            (filter, item) => {
                var hasSearch = item.Name?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
                var IsActive = filter?.IsActive === 'UNK' || (filter?.IsActive === undefined || filter?.IsActive === null ? item.IsActive == true : item.IsActive === filter?.IsActive);
                var hasClientId = item?.ClientId === filter?.ClientId || (filter?.ClientId === undefined || filter?.ClientId === null || filter?.ClientId === 'UNK')
                var hasDistrictId = item?.DistrictId === filter?.DistrictId || (filter?.DistrictId === undefined || filter?.DistrictId === null || filter?.DistrictId === 'UNK')
                return hasSearch && IsActive && hasClientId && hasDistrictId;
            });
        this._schoolMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.School, globalAuthState, globalAppUserState);
        this._obsConfigMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.ObservationConfig, globalAuthState, globalAppUserState);
        this._errorUtil = undefined;
        this._schoolYear = schoolYear;
    }

    get SchoolManager() {
        return this._schoolMgr;
    }

    async getObservationConfigBySchool(schoolId, districtId, orgId) {
        const observationObj = schoolId ? { oId: orgId, dId: districtId, sId: schoolId, sy: this.AppUserState.selectedSchoolYear } :
            districtId ? { oId: orgId, dId: districtId, sy: this.AppUserState.selectedSchoolYear } :
                orgId ? { oId: orgId, sy: this.AppUserState.selectedSchoolYear } : { sy: this.AppUserState.selectedSchoolYear }
        return await this._obsConfigMgr.runOperation('ObservationConfig', undefined, undefined, observationObj);
    }

    get DefaultItem() {
        return {
        };
    }

}

export default SchoolManager;