import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _ from 'lodash';
import moment from 'moment/moment';

class SchoolYearManager extends ItemManager {
  constructor(globalItemState, globalAuthState, globalAppUserState) {

    super(ItemServiceFactory.ItemServiceEnum.SchoolYear, globalAuthState, globalAppUserState, globalItemState,
      (filter, item) => {
        var hasSearch = item.Title?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
        var hasOrg = item?.ClientId === filter?.ClientId || (filter?.ClientId === undefined || filter?.ClientId === null || filter?.ClientId === 'UNK');
        const date = moment(`${item.SchoolYearIdentifier}-${item.SchoolYearMonth}-${item.SchoolYearDay}`, 'YYYY-MM-DD');
        var hasToDate = (new Date(date).getTime() <= new Date(filter?.toDate).getTime()) || (filter?.toDate === undefined || filter?.toDate === null || filter?.toDate === '');
        var hasFromDate = (new Date(date).getTime() >= new Date(filter?.fromDate).getTime()) || (filter?.fromDate === undefined || filter?.fromDate === null || filter?.fromDate === '');
        return hasSearch && hasOrg && hasToDate && hasFromDate;
      });

    this._errorUtil = undefined;
  }

  async doRollover(id, oId, force) {
    let params = { force: force ?? false };
    if (oId) {
      params.oid = oId;
    }
    return this.runOperation("SchoolYearRollOver", id, null, params, false, false);
  }

  get DefaultItem() {
    let date = moment().utc(true).date(1).month(8).startOf('date');
    return {
      SchoolYearMonth: date.month(),
      SchoolYearDay: date.date(),
      SchoolYearIdentifier: date.year(),
      ConfigurationDueDate: date.add(1, 'years').subtract(1, 'days').format("YYYY-MM-DDT23:59:59.9999Z"),
      ConfigurationLockedDate: date.add(1, 'years').subtract(7, 'days').format("YYYY-MM-DDT00:00:00.0001Z"),
      RolloverDate: date.add(1, 'days').format("YYYY-MM-DDT00:00:00.0001Z"),
      IsActive: true,
    };
  }
}

export default SchoolYearManager;