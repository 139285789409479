import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import LogDetails from '../components/log/details';
import LogFilter from '../components/log/filter';
import LogTable from '../components/log/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useLogManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import moment from 'moment';
import { data } from 'jquery';

const LogMgmt = () => {
    const logMgr = useLogManager();
    const location = useLocation();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedLog, setSelectedLog] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (logMgr && !params?.logId) {
            updateData();
        }
        else if (!selectedLog && params?.logId && logMgr) {
            setLoading(true);
            logMgr.get(params?.logId).then((r) => {
                if (r.Success) {
                    setManageMode('view');
                    setSelectedLog(r.Items.first());
                }
                setLoading(false);
            });
        }
    }, [logMgr, params?.logId]);

    const updateData = (filter) => {
        if (!filter) {
            filter = logMgr?.Filter;
        }

        setLoading(true);
        const userId = new URLSearchParams(location.search).get("uId");
        const createdDate = filter?.CreatedDate ?? moment().utc().add(-14, 'day').format();
        const logLevel = filter?.LogLevel ? Number(filter?.LogLevel) : 4;
        const eventname = filter?.EventName ? `%${filter?.EventName}%` : "%%"
        let query = 'x => MessageTypeId >= 1 && LogLevel == logLevel && CreatedDate >= createdDate && x.EventName LIKE eventname';

        if (userId) {
            query += ` && CreatedById == userId`
            setSelectedUserId(userId);
        }

        logMgr.search(query, true, { createdDate, logLevel, eventname, userId }, false, undefined, 25000).then(fb => {
            setManageMode('list');
            setLoading(false);
        });
    }

    const handleOnFilterChange = async (field, value) => {

        const logFilter = logMgr.Filter;
        const updatedLogFilter = { ...logFilter };

        if (field.toLowerCase() === 'messagetypeid') {
            updatedLogFilter.MessageTypeId = value !== '' ? value : null;
            // if (updatedLogFilter.MessageTypeId !== '' && updatedLogFilter.MessageTypeId != null)
            // updateData(updatedLogFilter);
        }
        else if (field.toLowerCase() === 'eventname') {
            updatedLogFilter.EventName = value !== '' ? value : null;
            // updateData(updatedLogFilter);
        }
        else if (field === 'LogLevel') {
            updatedLogFilter.LogLevel = value;
            updateData(updatedLogFilter);
        }
        else if (field === 'CreatedDate') {
            updatedLogFilter.CreatedDate = value;
            updateData(updatedLogFilter);
        }
        else if (field.toLowerCase() === 'createdby') {
            updatedLogFilter.CreatedBy = value !== '' ? value : null;
        }

        await logMgr.filterData(updatedLogFilter);
    }

    const handleOnComplete = async () => {
        const item = { ...itemToDelete };
        logMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.Name}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnView = (item) => {
        setSelectedLog(item);
        setManageMode('view');
        navigate(`/admin/log/${item.Id}`);
    }


    const handleOnCancel = () => {
        if (selectedUserId)
            navigate(`/admin/log?uId=${selectedUserId}`)
        else
            navigate('/admin/log');

        if (logMgr.FilteredData) {
            setManageMode('list');
        }
        else {
            setManageMode(null);
        }

        setLoadingError(null);
        setErrors(null);
    }



    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to dismiss the log from '${itemToDelete?.Name}' ? `} onCancel={() => setItemToDelete(null)} onOk={handleOnComplete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <MainLayout errors={errors} >
            {manageMode === 'view' &&
                <div className='screen-header'>
                    <h3>Log Management</h3>
                    <h5>View Log</h5>
                </div>
            }
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <LogFilter updateData={updateData} onClear={updateData} logMgr={logMgr} onFilterChange={handleOnFilterChange} />
                            <LogTable filteredItems={logMgr?.FilteredData ?? []} onDelete={(item) => setItemToDelete(item)} onView={handleOnView} />
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&
                    <LogDetails log={selectedLog} onCancel={handleOnCancel} onLoading={setLoading} onErrors={setErrors} onLoadingError={setLoadingError} />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default LogMgmt;