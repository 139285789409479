import React, { useState } from 'react';
import { useEffect } from 'react';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useAppUserManager, useCertificationQuestionManager, useDistrictManager, useImportExportManager, useOrgManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';
import DistrictTable from '../components/district/table';
import DistrictFilter from '../components/district/filter';
import DistrictDetails from '../components/district/details';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../components/observation/observationconfig.scss'
import SystemTypes from '../../../services/SystemTypes';

const DistrictMgmt = () => {
    const districtMgr = useDistrictManager();
    const orgMgr = useOrgManager();
    const certificationQuestionMgr = useCertificationQuestionManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null)
    const [org, setOrg] = useState(null)
    const [teacherRubricList, setTeacherRubricList] = useState(null)
    const [adminRubricList, setAdminRubricList] = useState(null)
    const [teacherSurveyList, setTeacherSurveyList] = useState(null)
    const [adminSurveyList, setAdminSurveyList] = useState(null)
    const [observationConfig, setObservationConfig] = useState(null)
    const [loadingConfigs, setLoadingConfigs] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams({});
    const [orgTreeUsers, setOrgTreeUsers] = useState(null)
    const [certificationQuestions, setCertificationQuestions] = useState(null)
    const [orgItem, setOrgItem] = useState(null)
    const [deleting, setDeleting] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    const appUserMgr = useAppUserManager();
    const importExportMgr = useImportExportManager();
    const [exportLoading, setExportLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);


    useEffect(() => {
        setLoading(true)
    }, [])

    useEffect(() => {


        if (params?.districtId && districtMgr && orgMgr && certificationQuestionMgr) {
            orgMgr.loadOrgTree().then(r => {
                setOrg(r)
            });
            if (!selectedItem) {
                if (params?.districtId !== 'create') {
                    districtMgr.get(params?.districtId).then((r) => {
                        if (r.Success) {
                            setManageMode('view');
                            setSelectedItem(r.Items.first());
                            setLoading(false);
                            grabObservationDetails(r.Items.first());
                        }
                    });
                } else {
                    setManageMode('view')
                    setLoading(false)
                    grabObservationDetails()
                }
            } else {
                setLoading(false)
                grabObservationDetails(selectedItem);
            }
        }
        else if (districtMgr && orgMgr) {

            setLoading(true)
            setSelectedItem(null)
            orgMgr.loadOrgTree().then(r => {
                setOrg(r)
            });

            let search = "x => x.IsDeleted == false";
            let clientId = undefined;
            if (searchParams.get('orgid')) {
                search += " && ClientId == clientId"
                clientId = searchParams.get('orgid');
            }

            districtMgr.search(search, true, { clientId }).then(fd => {
                if (searchParams.get('orgid')) {
                    handleOnFilterChange("ClientId", searchParams.get('orgid'))
                    orgMgr?.get(searchParams.get('orgid')).then(r => {
                        setOrgItem(r?.Items?.first())
                        setLoading(false);
                        setManageMode('list');
                    })
                } else {
                    setFilteredData(fd);
                    setLoading(false)
                    setManageMode('list');
                }
            })
        }
    }, [districtMgr, orgMgr, params, certificationQuestionMgr]);

    const grabObservationDetails = async (district) => {
        setLoadingConfigs(true);
        let districtId = params?.districtId && params?.districtId !== 'create' ? params?.districtId : null
        const observationConfig = districtMgr.getObservationConfigByDistrict(districtId ?? null, district?.ClientId ?? searchParams.get('orgid') ?? null).then((r) => {
            if (r.Success) {
                //If clientId is null, this indicates that the config is the default.
                const observationConfig = r.Items.first();
                setObservationConfig(observationConfig)
            }
        })

        const orgIdOrNull = district?.ClientId ?? null;

        const teacherRubric = orgMgr.getTeacherRubrics(orgIdOrNull, false, ['RubricDomainMap']).then(r => {
            if (r.first().Success) {
                setTeacherRubricList(r.first().Items.filter(x => x.SchoolYearIdentifier == orgMgr.AppUserState.selectedSchoolYear && x.Status !== 1))
            }
        })
        const adminRubric = orgMgr.getAdminRubrics(orgIdOrNull, false, ['RubricDomainMap']).then(r => {
            if (r.first().Success) {
                setAdminRubricList(r.first().Items.filter(x => x.SchoolYearIdentifier == orgMgr.AppUserState.selectedSchoolYear && x.Status !== 1))
            }
        })
        const teacherSurveyTemplate = orgMgr.getSurveyTemplates(orgIdOrNull, 4).then(r => {
            if (r.first().Success) {
                setTeacherSurveyList(r.first().Items)
            }
        })
        const adminSurveyTemplate = orgMgr.getSurveyTemplates(orgIdOrNull, 5).then(r => {
            if (r.first().Success) {
                setAdminSurveyList(r.first().Items)
            }
        })

        const orgUsers = orgMgr.loadOrgUserTree().then((r) => {
            setOrgTreeUsers(orgMgr.UsersOnly('org', orgIdOrNull))
        })

        const grabCertificationQuestions = certificationQuestionMgr.list(certificationQuestionMgr.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false")).then(r => setCertificationQuestions(r.Items))
        const result = Promise.all([observationConfig, teacherRubric, adminRubric, teacherSurveyTemplate, adminSurveyTemplate, grabCertificationQuestions]).then((r) => setLoadingConfigs(false))
    }


    const onAddNew = () => {
        if (searchParams.get('orgid')) {
            const preSelectedOrgItem = {
                ClientId: searchParams.get('orgid')
            }
            setSelectedItem(preSelectedOrgItem)
            navigate({
                pathname: '/admin/district/create',
                search: `?${createSearchParams({ orgid: searchParams.get('orgid') })}`,
            });
        } else {
            navigate('/admin/district/create')
        }
        setManageMode('view');
        grabObservationDetails();

    }

    const handleOnFilterChange = async (field, value) => {
        const dataFilter = districtMgr.Filter;
        const updatedDataFilter = { ...dataFilter };
        updatedDataFilter[field] = value !== '' ? value : null;
        const filteredResults = await districtMgr.filterData(updatedDataFilter);
        setFilteredData(filteredResults);
    }

    const handleOnSave = async (item, config) => {
        item.Organization = null
        var result = await districtMgr.saveItem(item);
        if (!item.Id && config?.wasEdited) {
            config.DistrictId = result.Items.first().Id
        }
        var configResult = orgMgr.saveObservationConfig(config)
        return result;
    }

    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        setDeleting(true);
        orgMgr.deleteDistrict(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.Name}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
                setDeleting(false);
            }
            else {

                districtMgr.deleteItem(item).then(rd => {
                    if (rd.Success) {
                        setItemToDelete(null);
                        setDeleting(false);
                        districtMgr.filterData().then(filteredResults => {
                            setFilteredData(filteredResults);
                        });

                    }
                    else {
                        setItemToDelete(item);
                        setDeleteError(`Failed to delete '${item.Name}' ` + ` - ${rd?.Message?.DisplayMessage ?? (rd?.MessageDetails?.toString() ?? 'Unknown Error')}`)
                    }
                });
            }
        });
    }

    const handleOnEdit = (item) => {
        setSelectedItem(item);
        setManageMode('view');
        if (searchParams.get('orgid')) {
            navigate({
                pathname: `/admin/district/${item.Id}`,
                search: `${createSearchParams({ orgid: searchParams.get('orgid') })}`,
            });
        } else navigate(`/admin/district/${item.Id}`);
    }

    const handleOnDrillDown = (item) => {
        navigate({
            pathname: '/admin/school',
            search: `?${createSearchParams({ districtid: item.Id, orgid: item.ClientId })}`,
        });
    }

    const handleOnCancel = () => {
        //todo: Go back to the correct stop..
        if (searchParams.get('orgid')) {
            navigate({
                pathname: '/admin/district',
                search: `?${createSearchParams({ orgid: searchParams.get('orgid') })}`,
            });
        } else {
            navigate('/admin/District');
        }
        setLoadingError(null);
        setErrors(null);
        setSelectedItem(null)
        setObservationConfig(null)
        setTeacherRubricList(null)
        setAdminRubricList(null)
        setTeacherSurveyList(null)
        setAdminSurveyList(null)
    }

    const handleOnExport = async () => {
        const idList = districtMgr.FilteredData.map(x => x.Id);
        setExportLoading(true);
        const result = await importExportMgr.ExportData(SystemTypes.ImportExportType.ExportDistrict, idList);
        if (!result.Success) {
            setExportLoading(null);
            setErrors(["Failed to export District, if this issue continues please contact NIET support."]);
        }
        else {
            importExportMgr.downloadFile(result.Items.first()).then(r => {
                setExportLoading(false);
            }).catch(e => {
                setExportLoading(false);
                setErrors(["District export has been created and failed to download. Please check Import/Export Admin."]);
            });
        }
        return result;
    }
    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete '${itemToDelete?.Name}' ? `} onCancel={() => setItemToDelete(null)} loadingOk={deleting} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <MainLayout errors={errors} validationErrors={validationErrors} >
            {manageMode === 'view' && !loading &&
                <div className='screen-header'>
                    <h3>District Management</h3>
                    {params?.districtId === 'create' ? <h5>Add District</h5> : <h5>Edit District</h5>}
                </div>
            }
            <ScreenWrapper className={manageMode === 'view' ? ' no-height' : ''} loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <DistrictFilter orgMgr={orgMgr} org={org} districtMgr={districtMgr} onFilterChange={handleOnFilterChange} hideFilter={searchParams.get('orgid')} />
                            <div className={"breadcrumb-table-wrapper" + (searchParams.get('orgid') ? "" : '-nodrill')}>
                                {
                                    searchParams.get('orgid') && <div className="breadcrumb-wrapper">
                                        <div className="breadcrumb" onClick={() => navigate('/admin/organization')}>{filteredData.first()?.Organization?.Name ?? orgItem?.Name}</div>
                                        <FontAwesomeIcon
                                            icon={faAngleRight}
                                            className={"fa-angle-right"}
                                        />
                                        <div className="breadcrumb">District</div>
                                    </div>
                                }
                                <DistrictTable filteredItems={filteredData} onEdit={handleOnEdit} onDelete={(item) => { setItemToDelete(item) }} onDrillDown={handleOnDrillDown} isDrilledDown={searchParams.get('orgid')} />
                            </div>
                        </div>
                        <div className='screen-footer list'>
                            <>{(districtMgr?.CanWrite && districtMgr?.AppUserState?.userProfile?.UserPositionType <= SystemTypes.UserPositionType.Admin) && <ButtonControl type={"create"} onClick={onAddNew}>Create district</ButtonControl>}&nbsp;&nbsp;</>
                            {districtMgr?.CanView && appUserMgr?.canView(appUserMgr?.AppUserState?.permissions.ExportDistrict) && <><ButtonControl loading={exportLoading} type={'cancel'}
                                onClick={handleOnExport}>
                                Export Districts
                            </ButtonControl>&nbsp;&nbsp;</>}
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&
                    <DistrictDetails canWrite={districtMgr.CanWrite} org={org}
                        teacherRubricList={teacherRubricList}
                        adminRubricList={adminRubricList}
                        teacherSurveyList={teacherSurveyList}
                        adminSurveyList={adminSurveyList}
                        observationConfigs={observationConfig}
                        preSelectedOrg={searchParams.get('orgid')}
                        loadingConfigs={loadingConfigs}
                        districtMgr={districtMgr}
                        district={selectedItem}
                        onCancel={handleOnCancel}
                        onSave={handleOnSave}
                        onLoading={setLoading}
                        onErrors={setErrors}
                        onValidationErrors={setValidationErrors}
                        onLoadingError={setLoadingError}
                        grabObservationDetails={grabObservationDetails}
                        certificationQuestions={certificationQuestions}
                        orgTreeUsers={orgTreeUsers}
                    />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default DistrictMgmt;