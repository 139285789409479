import InitLayout from '../layout/InitLayout';

const AccessDeny = () => {

    return <>
        <InitLayout><div className='init__content access-deny'><h1 style={{ color: "red" }}>Access Denied</h1><h3 >Your access to this page has been denied. <br /> If this is in error, please contact your local administrator or NIET customer support.</h3></div></InitLayout>;
    </>

};

export default AccessDeny;
