import { ItemServiceFactory } from "../services/ItemServiceFactory";
import ItemManager from "./ItemManager";
import Manager from "./Manager";
import ErrorUtil from '../domains/contentLibrary/utils/ErrorUtil';
  
import _ from "lodash";

export default class TransactionManager extends ItemManager {

    constructor(transactionState, globalAuthState, globalAppUserState) {
        super(ItemServiceFactory.ItemServiceEnum.Transaction, globalAuthState, globalAppUserState)
        this._globalAuthState = globalAuthState;
        this._transactionState = transactionState;

        this._setAllTransactions = (value) => transactionState.merge({ allTransactions: value });
        this._setForceTransactionUpdate = (value) => transactionState.merge({ forceTransactionUpdate: value });
        this._setAvailableTransactionFilters = (value) => transactionState.merge({ availableTransactionFilters: value });
        this._setFilteredTransactions = (value, filter) => transactionState.merge({ filteredTransaction: value, rubricFilter: _.cloneDeep(filter) });
        this._setSelectedTransaction = (value) => transactionState.merge({ selectedTransaction: value });

        this._errorUtil = new ErrorUtil();

        this._transactionItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Transaction, globalAuthState, globalAppUserState);
        this._transactionDetailsMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.TransactionDetail, globalAuthState, globalAppUserState);
        this._userProfileMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.UserProfile, globalAuthState, globalAppUserState);
        this._subscriptionMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Subscription, globalAuthState, globalAppUserState);

        this._discountCodeMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.DiscountCodeValid, globalAuthState, globalAppUserState);
        this._discountMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Discount, globalAuthState, globalAppUserState);
        this._invoiceMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Invoice, globalAuthState, globalAppUserState);
        this._refundMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.InvoiceRefund, globalAuthState, globalAppUserState);
        this._salesTaxMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.SalesTax, globalAuthState, globalAppUserState);
        this._scheduledInvoiceMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.ScheduledInvoice, globalAuthState, globalAppUserState);
        this._scheduledCancelInvoiceMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.ScheduledInvoiceCancel, globalAuthState, globalAppUserState);
        this._scheduleInvoiceSyncMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.ScheduledInvoiceSync, globalAuthState, globalAppUserState);
    }

    get _forceTransactionUpdate() { return this._transactionState.get().forceTransactionUpdate; }
    get _allTransactions() { return this._transactionState.get().allTransactions; }
    get _selectedTransaction() { return this._transactionState.get().selectedTransaction; }
    get _transactionFilter() { return this._transactionState.get({ noproxy: true }).transactionFilter; }

    get FilteredTransactions() { return this._transactionState?.get()?.filteredTransactions ?? []; }
    get AvailableTransactionFilters() { return this._transactionState?.get({ noproxy: true })?.avaiLableTransactionFilters; }

    get ItemManager() {
        return this;
    }

    get SubscriptionItemManager() {
        return this._subscriptionMgr;
    }

    get TransactionDetailsItemManager() {
        return this._transactionDetailsMgr;
    }

    get UserProfileItemMgr() {
        return this._userProfileMgr;
    }

    get DiscountCodeValidItemMgr() {
        return this._discountCodeMgr;
    }

    get DiscountItemMgr() {
        return this._discountMgr;
    }

    get InvoiceItemMgr() {
        return this._invoiceMgr;
    }

    get RefundItemMgr() {
        return this._refundMgr;
    }

    get SalesTaxItemMgr() {
        return this._salesTaxMgr;
    }

    get ScheduledInvoiceItemMgr() {
        return this._scheduledInvoiceMgr;
    }

    get ScheduledInvoieCancelItemMgr() {
        return this._scheduledCancelInvoiceMgr;
    }

    get ScheduledInvoieSyncItemMgr() {
        return this._scheduleInvoiceSyncMgr;
    }

    async SearchTransactions(transactionId = 0) {
        var result = {};
        if (transactionId != 0) {
            result = await this.ItemManager.get(transactionId);
        }
        else {
            result = await this.ItemManager.list();
        }
        var userProfileResult = await this.UserProfileItemMgr.list();
        var detailsResult = await this.TransactionDetailsItemManager.list();
        var subscriptionResult = await this.SubscriptionItemManager.list();

        if (result.Success && userProfileResult.Success && detailsResult.Success && subscriptionResult.Success) {

            let transactionSet = [];
            [...result.Items].forEach(transaction => {
                transaction = this.mapTransaction(transaction, userProfileResult.Items, detailsResult.Items, subscriptionResult.Items);
                transactionSet.push(transaction);
            });
            if (transactionId != 0) {
                return transactionSet;
            }

            this._setAllTransactions(transactionSet);
            const filteredTransactions = await this._doFilter(this._transactionFilter, result.Items);
            return filteredTransactions;
        }
        else
            return [];
    }

    mapTransaction(transaction, userProfileItems, transactionDetailsItems, subscriptionItems) {

        if (userProfileItems.length > 0) {
            let matchingUser = userProfileItems.find(d => d.Id === transaction.UserId);
            if (matchingUser) {
                transaction.UserProfile = matchingUser;
            }
        }
        if (transactionDetailsItems.length > 0) {
            let matchingTransaction = transactionDetailsItems.find(d => d.TransactionId === transaction.Id);
            if (matchingTransaction) {
                transaction.TransactionDetails = matchingTransaction;
            }
        }
        if (subscriptionItems.length > 0) {
            let matchingSubscription = subscriptionItems.find(d => d.Id === transaction.ItemId);
            if (matchingSubscription) {
                transaction.ItemName = matchingSubscription.Name;
            }
        }


        return transaction;
    }

    FilterTransactions(filter) {
        if (!filter)
            filter = this._rubricFilter;

        return this._doFilter(filter, this._allTransactions);
    }

    async _doFilter(filter, allTransactions) {

        const data = [...allTransactions];
        const filters = [searchFilter, TransactionTypeFilter, statusFilter, dateFilter]
        let filteredTransactions = filters.reduce((d, f) => d.filter(f), data);
        function searchFilter(item) {
            if (!filter.Search) {
                return true;
            }
            else if (item?.UserProfile?.Email.toLowerCase().startsWith(filter.Search.toLowerCase())) {
                return true;
            }
        }

        function TransactionTypeFilter(item) {
            if (filter.TransactionType.length === 0) {
                return true;
            }
            else if (filter.TransactionType.includes(item.ItemType.toString())) {
                return true;
            }
        }

        function statusFilter(item) {
            if (filter.Status.length === 0) {
                return true;
            }
            else if (filter.Status === item.Status) {
                return true;
            }
        }

        function dateFilter(item) {
            if (filter.Date === null) {
                return true;
            }
            else {
                let dateSub = item.UpdatedDate.substring(0, 10);
                if ((filter.Date === dateSub)) {
                    return true;
                }
            }
        }

        this._setFilteredTransactions(filteredTransactions, filter);
        return filteredTransactions;
    }
}