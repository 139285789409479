import moment from 'moment';
import { ItemServiceFactory } from '../services/ItemServiceFactory';
import ItemManager from './ItemManager';
import _ from 'lodash';

class NotificationManager extends ItemManager {
  constructor(globalItemState, globalAuthState, globalAppUserState) {

    super(ItemServiceFactory.ItemServiceEnum.Notification, globalAuthState, globalAppUserState, globalItemState,
      (filter, item) => {
        var hasIsActive = item?.IsActive?.toString() === filter?.isActive?.toString() || (filter?.isActive === undefined || filter?.isActive === null || filter?.isActive === 'UNK');
        var hasSearch = item.Title?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || item.HtmlValue?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
        var hasType = item.NotificationType?.toString() === filter?.type?.toString() || (filter?.type === undefined || filter?.type === null || filter?.type === 'UNK');
        var hasToDate = ((item.ToDate !== null && filter?.toDate) ? new Date(item.ToDate).getTime() <= new Date(filter?.toDate).getTime() : false) || (filter?.toDate === undefined || filter?.toDate === null || filter?.toDate === '');
        var hasFromDate = (filter?.fromDate ? new Date(item.FromDate).getTime() >= new Date(filter?.fromDate).getTime() : false) || (filter?.fromDate === undefined || filter?.fromDate === null || filter?.fromDate === '');
        return hasIsActive && hasSearch && hasType && hasToDate && hasFromDate;
      });
    this._errorUtil = undefined;
  }

  get DefaultItem() {
    return {
      HtmlValue: "",
      IsActive: true,
      NotificationType: 0
    };
  }



  async getActiveNotifications(userId) {
    const result = await this.query('x => x.UserIdsData like %userId% || x.AllUsers == true || x.ShowGlobally == true && (x.IsActive == true && x.IsDeleted == false)', { userId })
    if (result?.Success) {
      return result?.Items;
    }
    else {
      return null;
    }
  }

  async deactivate(idToSkip) {
    const result = await this.list();
    if (result?.Success) {
      const items = result.Items
      const itemsToInActivate = _.filter(items, item => item.Id != idToSkip && item.IsActive === true);
      const allP = [];
      _.each(itemsToInActivate, item => {
        item.IsActive = false;
        allP.push(this.save(item))
      });

      if (allP.length > 0)
        await Promise.all(allP);


      return items;
    }
    else
      return [];
  }
}

export default NotificationManager;