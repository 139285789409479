import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import CertificationQuestionDetails from '../components/certificationQuestion/details';
import CertificationQuestionFilter from '../components/certificationQuestion/filter';
import CertificationQuestionTable from '../components/certificationQuestion/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useCertificationQuestionManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';

const CertificationQuestionMgmt = () => {
    const certificationQuestionMgr = useCertificationQuestionManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedCertificationQuestion, setSelectedCertificationQuestion] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (certificationQuestionMgr && !params?.certificationQuestionId) {

            setLoading(true);
            certificationQuestionMgr.getAllQuestions(location.pathname.toLowerCase().includes('/teacher') ? 2 : 1).then(fb => {
                setManageMode('list');
                setLoading(false);
            });
        }
        else if (!selectedCertificationQuestion && params?.certificationQuestionId && certificationQuestionMgr) {
            setLoading(true);
            if (params?.certificationQuestionId.toLowerCase() === 'addnew') {
                setSelectedCertificationQuestion(certificationQuestionMgr.DefaultItem);
                setManageMode('view');
                setLoading(false);
            }
            else {
                certificationQuestionMgr.get(params?.certificationQuestionId).then((r) => {
                    if (r.Success) {
                        setManageMode('view');
                        setSelectedCertificationQuestion(r.Items.first());
                    }
                    setLoading(false);
                });
            }
        }
    }, [certificationQuestionMgr, params, location]);

    const onAddNew = () => {
        setSelectedCertificationQuestion(certificationQuestionMgr.DefaultItem);
        setManageMode('view');
        navigate(`/admin/certificationQuestion/${location.pathname.toLowerCase().includes('/teacher') ? 'teacher' : 'schooladmin'}/addnew`);
    }

    const handleOnFilterChange = async (field, value) => {

        const certificationQuestionFilter = certificationQuestionMgr.Filter;
        const updatedCertificationQuestionFilter = { ...certificationQuestionFilter };
        updatedCertificationQuestionFilter[field] = value !== '' ? value : null;
        await certificationQuestionMgr.filterData(updatedCertificationQuestionFilter);
    }

    const handleOnSave = async (item) => {
        const copiedItem = { ...item }
        copiedItem.CertificationType = location.pathname.toLowerCase().includes('/teacher') ? 2 : 1
        var result = await certificationQuestionMgr.saveItem(copiedItem);
        return result;
    }

    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        certificationQuestionMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.CertificationQuestion}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnEdit = (item) => {
        setSelectedCertificationQuestion(item);
        setManageMode('view');
        navigate(`/admin/certificationQuestion/${location.pathname.toLowerCase().includes('/teacher') ? 'teacher' : 'schooladmin'}/${item.Id}`);
    }

    const handleOnCancel = () => {
        navigate(`/admin/certificationQuestion/${location.pathname.toLowerCase().includes('/teacher') ? 'teacher' : 'schooladmin'}`);
        if (certificationQuestionMgr.FilteredData) {
            setManageMode('list');
        }
        else {
            setManageMode(null);
        }
        sessionStorage.removeItem('FileUpload-question-certification');
        setLoadingError(null);
        setErrors(null);
    }


    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete the following question:`} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            <div className="mt-2 mx-4" dangerouslySetInnerHTML={{ __html: itemToDelete?.QuestionText }} />
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <MainLayout errors={errors} validationErrors={validationErrors}>
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'view' &&
                    <div className='screen-header'>
                        <h3>{location.pathname.toLowerCase().includes('/schooladmin')
                            ? "Admin " : location.pathname.toLowerCase().includes('/teacher')
                                ? "Teacher " : ""}Certification Question Management</h3>
                        <h5>{`${selectedCertificationQuestion.Id ? 'Edit' : 'Create'} Certification Question`}</h5>
                    </div>
                }
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <CertificationQuestionFilter certificationQuestionMgr={certificationQuestionMgr} onFilterChange={handleOnFilterChange} certType={location.pathname.toLowerCase().includes('/teacher') ? 'teacher' : "schooladmin"} />
                            <CertificationQuestionTable certificationQuestionMgr={certificationQuestionMgr} filteredItems={certificationQuestionMgr?.FilteredData ?? []} onEdit={handleOnEdit} onDelete={(item) => { setItemToDelete(item); }} />
                        </div>
                        <div className='screen-footer list'>
                            {certificationQuestionMgr.CanWrite && <ButtonControl type={"create"} onClick={onAddNew}>Create Question</ButtonControl>}
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&
                    <CertificationQuestionDetails canWrite={certificationQuestionMgr.CanWrite} certificationQuestionMgr={certificationQuestionMgr} certificationQuestion={selectedCertificationQuestion} onCancel={handleOnCancel} onSave={handleOnSave} onLoading={setLoading} onErrors={setErrors} onValidationErrors={setValidationErrors} onLoadingError={setLoadingError} />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default CertificationQuestionMgmt;