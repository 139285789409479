import React, { useState } from 'react';
import MainLayout from '../../../components/layout/MainLayout';
import { useEffect } from 'react';
import { useAppUserManager, useImportExportManager, useOrgManager, useUserManager } from '../../../hooks/useManagers';
import UserTable from '../components/user/UserTable';
import UserFilter from '../components/user/UserFilter';
import { DialogControl } from '../../../components/controls/DialogControl';
import { ButtonControl } from '../../../components/controls/ButtonControl';
import { useNavigate, useParams } from 'react-router-dom';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import UserDetails from '../components/user/UserDetails';
import '../userList.scss';
import SystemTypes from '../../../services/SystemTypes';
import Import from '../components/importExport/import';
import { InputField } from '../../../components/controls/InputField';
import { CheckTreePicker, SelectPicker, TreePicker } from 'rsuite';
import Loading from '../../../components/Loading';
import _ from 'lodash';

const UserMgmt = () => {
    const [data, setData] = useState(null);
    const [errors, setErrors] = useState(null);
    const [messages, setMessages] = useState(null);
    const [manageMode, setManageMode] = useState('list');
    const [loading, setLoading] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [schools, setSchools] = useState([]);
    const [clusters, setClusters] = useState([])
    const [roles, setRoles] = useState([]);
    const [activeFlagDialogTitle, setActiveFlagDialogTitle] = useState();
    const [openActiveFlagDialog, setOpenActiveFlagDialog] = useState();
    const [selectedUserRow, setSelectedUserRow] = useState({ isActive: false, Email: 'default' });
    const [isCurrentlyActive, setIsCurrentlyActive] = useState();
    const [loadingError, setLoadingError] = useState();
    const [forceRedirect, setForceRedirect] = useState(false)
    const [isEdit, setIsEdit] = useState(false);
    const [openMergeUserDialog,setOpenMergeUserDialog] = useState(false);
    const [userMergeFrom,setUserMergeFrom] = useState(null);
    const [userMergeFromUsersFilter,setUserMergeFromUsersFilter] = useState([]);
    const [selectedMergeOrgIds,setSelectedMergeOrgIds] = useState([]);
    const [mergeSuccess,setMergeSuccess] = useState(false);
    

    const params = useParams();
    const [userId, setUserId] = new useState(params?.userId === undefined || params?.userId?.toLowerCase() === 'addnew' ? null : params?.userId);
    const navigate = useNavigate();
    const userMgr = useUserManager();
    const orgMgr = useOrgManager();
    const appUserMgr = useAppUserManager();
    const importExportMgr = useImportExportManager();
    const [exportLoading, setExportLoading] = useState(false);
    const [orgs, setOrgs] = useState(null)
    useEffect(() => {
        if (params?.userId?.toLowerCase() === 'addnew') {
            setManageMode('create');
            setUserId(undefined);
        }
        else if (params?.userId && params?.userId.toLowerCase() !== 'addnew') {
            setManageMode('edit');
            setUserId(params?.userId);
        }
        else {
            setManageMode('list');
            setUserId(0);
        }
    }, [params?.userId])
    useEffect(() => {
        if (userMgr && orgMgr) {
            let ignore = false;
            async function fetchData() {
                if (manageMode === 'list') {
                    userMgr.SearchUsers().then(async (users) => {
                        setData(users);
                    });
                }
                    orgMgr.loadOrgTree(true, true).then((items) => {
                        setOrgs(items);
                    })
                
                
                if (!ignore) {
                    if (organizations.length === 0) {
                        userMgr.ClientManager.list(userMgr.buildJSONObjFilter("x => x.IsDeleted == false && x.IsActive == true")).then(result => {
                            if (!ignore && result.length > 0) {
                                if (result[0].Success) {
                                    let orgs = result[0].Items.map(item => ({ label: item.DisplayName, value: item.ClientId, dataType: 'Organization' }))
                                    orgs.sort((a, b) => a.label.localeCompare(b.label));
                                    setOrganizations(orgs);
                                }
                            }
                        });
                    }
                    if (schools.length === 0) {
                        userMgr.SchoolManager.list(userMgr.buildJSONObjFilter("x => x.IsDeleted == false && x.IsActive == true")).then(schoolResult => {
                            if (!ignore && schoolResult.length > 0) {
                                if (schoolResult[0].Success) {
                                    let schoolsMapped = schoolResult[0].Items.map(item => ({ label: item.Name, value: item.Id, districtId: item.DistrictId, clientId: item.ClientId, dataType: 'School' }))
                                    schoolsMapped.sort((a, b) => a.label.localeCompare(b.label));
                                    setSchools(schoolsMapped);
                                }
                            }
                        })
                    }
                    if (districts.length === 0) {
                        userMgr.DistrictManager.list(userMgr.buildJSONObjFilter("x => x.IsDeleted == false && x.IsActive == true")).then(districtResult => {
                            if (!ignore && districtResult.length > 0) {
                                if (districtResult[0].Success) {
                                    let validDistricts = districtResult[0].Items.filter(item => item.ClientId !== null);
                                    let districtsMapped = validDistricts.map(item => ({ label: item.Name, value: item.Id, clientId: item.ClientId, dataType: 'District' }))
                                    districtsMapped.sort((a, b) => a.label.localeCompare(b.label));
                                    setDistricts(districtsMapped);
                                }
                            }

                        })
                    }

                    if (roles.length === 0) {
                        userMgr.RoleManager.list(userMgr.buildJSONObjFilter("x => x.IsDeleted == false && x.IsActive == true")).then(roleResult => {
                            if (!ignore && roleResult.length > 0) {
                                if (roleResult[0].Success) {
                                    let rolesMapped = roleResult[0].Items.map(item => ({ label: item.Name, value: item.Id, clientId: item.ClientId, dataType: 'Role', role: item.Role }));
                                    rolesMapped.sort((a, b) => a.label.localeCompare(b.label));
                                    setRoles(rolesMapped.filter(x => x.role !== 'Anonymous'));
                                }
                            }
                        });
                    }
                }
            }
            fetchData();
            return () => { ignore = true }; //cleanup
        }
    }, [userMgr, manageMode]);

    useEffect(() => {
        if (roles && districts && schools.length != 0 && organizations && clusters && (data || manageMode === 'create' || manageMode === 'edit')) {
            setLoading(false);
        }
    }, [roles, districts, schools, organizations, data, clusters])

    const grabClusters = (orgId) => {
        const oId = orgId
        if (clusters.length === 0) {
            userMgr.ClusterManager.list(userMgr.buildJSONObjFilter("x => x.IsDeleted == false && x.ClientId == oId", { oId })).then(clusterResult => {
                if (clusterResult.length > 0) {
                    if (clusterResult[0].Success) {
                        let clusterMapped = clusterResult[0].Items.map(item => ({ label: item.Name, value: item.Id, schoolId: item.SchoolId, districtId: item.DistrictId, clientId: item.ClientId, dataType: 'Cluster' }))
                        clusterMapped.sort((a, b) => a.label.localeCompare(b.label));
                        setClusters(clusterMapped);
                    }
                }

            })
        }
    }

    const handleOnClearFilter = async () => {
        const filteredUsers = await userMgr.FilterUsers({ Search: '', EEPass: '', JobTitle: '', userInGroup: [], IsActive: '' });
        setData(filteredUsers);
    }

    const handleOnFilterChange = async (field, currentClickedValues) => {

        const updatedUserFilter = {
            Search: userMgr._userFilter.Search, EEPass: userMgr._userFilter.EEPass, userInGroup: userMgr._userFilter.userInGroup, IsActive: userMgr._userFilter.IsActive, JobTitle: userMgr._userFilter.JobTitle
        };

        if (field === 'Search') {
            updatedUserFilter.Search = currentClickedValues;
        }
        else if (field === 'EEPass') {
            updatedUserFilter.EEPass = currentClickedValues;
        }
        else if (field === 'IsActive') {
            updatedUserFilter.IsActive = currentClickedValues === 'UNK' ? '' : currentClickedValues;
        } else if (field === 'userInGroup') {
            updatedUserFilter.userInGroup = currentClickedValues
        } else if (field === 'JobTitle') {
            updatedUserFilter.JobTitle = currentClickedValues === 'UNK' ? '' : currentClickedValues;
        }
        const filteredUsers = await userMgr.FilterUsers(updatedUserFilter);
        setData(filteredUsers);
    }

    const handleEnableDisable = async (user) => {
        if (user.IsActive) {
            setActiveFlagDialogTitle('Deactivate User?');
            setIsCurrentlyActive(true);
        } else {
            setActiveFlagDialogTitle('Activate User?');
            setIsCurrentlyActive(false);
        }
        setSelectedUserRow(user);
        setOpenActiveFlagDialog(true);
    }

    const handleOnCancel = () => {
        setOpenActiveFlagDialog(false);
    }

    const handleOnDialogYes = () => {
        setOpenActiveFlagDialog(false);
        updateActiveFlagStatus();
    }

    const updateActiveFlagStatus = async () => {
        let dataSet = [...data];
        let userToUpdate = dataSet.find(x => x.Email === selectedUserRow.Email);
        userToUpdate.IsActive = !isCurrentlyActive ? true : false;
        let result = await userMgr.ItemManager.save(userToUpdate);

        if (result.Success) {
            setData(dataSet);
        }
    }

    const handleOnEdit = (row) => {
        setManageMode('edit');
        setIsEdit(true);
        navigate(`/admin/User/${row.Id}`);
    }

    const handleOnTransactions = (row) => {
        navigate('/admin/Transactions');
    }

    const handleOnLogs = (row) => {
        navigate('/admin/Log?uId=' + row.Id);
    }

    const handleOnCreateUser = () => {
        setManageMode('create');
        setIsEdit(false);
        navigate('/admin/User/AddNew');
    }

    const handleOnLoadingError = () => {

    }

    const handleOnUserDetailsExit = () => {
        setManageMode('list');
        navigate('/admin/User')
        // setForceRedirect(true)
        // window.location.href = '/admin/User'
    }

    const handleOnExport = async () => {
        const idList = data.map(x => x.Id);
        setExportLoading(true);
        const result = await importExportMgr.ExportData(SystemTypes.ImportExportType.ExportUsers, idList);
        if (!result.Success) {
            setExportLoading(null);
            setErrors(["Failed to export Users, if this issue continues please contact NIET support."]);
        }
        else {
            importExportMgr.downloadFile(result.Items.first()).then(r => {
                setExportLoading(false);
            }).catch(e => {
                setExportLoading(false);
                setErrors(["User export has been created and failed to download. Please check Import/Export Admin."]);
            });
        }
        return result;
    }


    const handleOnCompleteImport = (success) => {
        if (success) {
            userMgr.SearchUsers().then(users => {
                userMgr.filterData(undefined, users).then(filteredData => {
                    setData(filteredData);
                    setMessages([<h5>All Users Successfully loaded.</h5>]);
                });
            });
        }
    }

    useEffect(() => {
        if (userMgr && organizations.length > 0 && selectedMergeOrgIds.length > 0 && userMgr?.AppUserState?.userProfile?.Roles?.some(x => x == 'SysAdmin' || x == 'NIETAdmin')) {
            setUserMergeFromUsersFilter(null);
            const userProfileObj = {
                isActive: false,
                isStaged: false,
                includesStaged: true,
              }

              if(selectedMergeOrgIds.startsWith('s-') && selectedMergeOrgIds != 's-'){
                userProfileObj.sId = selectedMergeOrgIds.substring(2,selectedMergeOrgIds.length);
              }
              else if(selectedMergeOrgIds.startsWith('d-') && selectedMergeOrgIds != 'd-'){
                userProfileObj.dId = selectedMergeOrgIds.substring(2,selectedMergeOrgIds.length);
              }
              else if(selectedMergeOrgIds.startsWith('o-') && selectedMergeOrgIds != 'o-'){
                userProfileObj.oId = selectedMergeOrgIds.substring(2,selectedMergeOrgIds.length);
              }


              userMgr
                .runOperation("GetUserProfileList", undefined, undefined, userProfileObj)
                .then((r) => {
                if(r.Success){
                    var userProfiles = _.sortBy(r.Items.first().Data,x=>x.LastName).map(x=>{
                        return {
                            Id: x.Id,
                            OrgIds: x.OrganizationIds.map(o=>`o-${o}`),
                            DistrctIds: x.DistrictIds.map(d=>`d-${d}`),
                            SchoolIds: x.SchoolIds.map(s=>`s-${s}`),
                            Name: `${x.SystemId} - ${x.LastName},${x.FirstName} - ${x.Email} - ${x.Schools ?? x.Districts ?? x.organizations ?? ""} ${x.IsActive == false ? ` - (Inactive) ` : '' }`
                        }
                    });
                  
                    if(userProfiles){
                        setUserMergeFromUsersFilter(userProfiles);
                    }
                    else{
                        setUserMergeFromUsersFilter([]);
                    }
                }
            });
        }
    }, [userMgr,selectedMergeOrgIds])
   

    const mergeUsers = async ()=>{
        var result = await userMgr.MergeUsers(userId,userMergeFrom);
        if(result.Success){
            setMergeSuccess(true);
            setUserMergeFromUsersFilter([]);
            setSelectedMergeOrgIds([]);
            return {Success: false, skipError:true};
        }
        else{
            return result;
        }
        
    }

    const handleOnMergeOrgChange = (value)=>{
        setSelectedMergeOrgIds(value);
       
    }

    const onMergeTreeSelectOrg = (val) => {
        
        //I actually filter by the user. because those users will be associated with the org/dis/school :)
       // let newTreeIds = orgMgr?.findSelected(vals); //used when doing multi select (CheckTreePicker)
        if (handleOnMergeOrgChange)
            handleOnMergeOrgChange(val);
    }

    const onTreeCleanOrg = () => {
        handleOnMergeOrgChange([]);
    }

    return (<>
        <MainLayout errors={errors} messages={messages}>
            <DialogControl openDialog={openMergeUserDialog} title='Select a User to Merge' 
                subTitle={<>{!mergeSuccess && <div>Please select a user you wish to merge INTO the current user.<br/>This will merge observations and certifications, but not user profile information. <br/> The user selected will be disabled and deleted once the merge is complete.<br/><i style={{color:'red'}}>ONCE THIS ACTION IS CONFIRMED IT CANNOT BE UNDONE!</i></div>}</>}
                onCancel={()=>{
                            setOpenMergeUserDialog(false); 
                            setMergeSuccess(false); 
                            setUserMergeFromUsersFilter([]);
                            setSelectedMergeOrgIds([]);
                        }
                    }  okText={'Confirm'} onOk={()=>mergeUsers()} disableOk={!userMergeFrom || mergeSuccess}>
                   {mergeSuccess && 
                   <div className='merge-input success'>
                        User Merged Successfully
                   </div>}
                   {!mergeSuccess &&
                   <>
                   <div className='merge-input'>
                    <InputField
                            id='userInGroup'
                            title='Organization Filter'
                            subTitle={'Please select one Organization, District and/or School.'}
                            value={selectedMergeOrgIds ?? []}
                            fieldName='userInGroup'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleOnMergeOrgChange}>
                            <TreePicker
                                height={320}
                                width={400}
                                className={'filter-control-input'}
                                childrenKey="Children"
                                labelKey="Name"
                                valueKey="Id"
                                data={orgs?.filter(x=>!x.Name.startsWith("--")) ?? []}
                                onClean={onTreeCleanOrg}
                                onSelect={(a, v) => onMergeTreeSelectOrg(v)}
                                
                            />
                        </InputField>
                        </div>
                        <div className='merge-input'>
                        <InputField
                            id='userMergeFrom'
                            title='User to Merge'
                            value={userMergeFrom}
                            fieldName='UserMergeFrom'
                            disableError={true}
                            placeholder=''>
                                <>
                        {userMergeFromUsersFilter ? <SelectPicker
                            className={'filter-control-input'}
                            id='userMergeFilter'
                            height={320}
                            labelKey="Name"
                            valueKey="Id"
                            placeholder= {userMergeFromUsersFilter.length > 0 ? 'Select' : 'Please select an Org, School or District to load users...'}
                            data={userMergeFromUsersFilter}
                            onClean={() => setUserMergeFrom(null)}
                            onSelect={(v) => {setUserMergeFrom(v)}}
                            placement="autoVerticalStart"
                            disabledItemValues={[userId]}
                        /> : <Loading/>}
                        </>
                        </InputField>
                    </div>
                    </>
                }
            </DialogControl>
            <DialogControl openDialog={openActiveFlagDialog} onCancel={handleOnCancel} title={activeFlagDialogTitle} onYes={handleOnDialogYes} onNo={handleOnCancel} isConfirm={true}
                subTitle={(selectedUserRow && selectedUserRow.IsActive) ? `Are you sure you want to deactivate ${selectedUserRow.Email}?` : `Are you sure you want to activate user ${selectedUserRow.Email}?`} />
            {
                !forceRedirect &&
                <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                    {(manageMode === 'edit' && !loading) &&
                        < div className='screen-header'>
                            <h3>User Management</h3>
                            <h5>Edit User</h5>
                        </div>
                    }
                    {(manageMode === 'create' && !loading) &&
                        <div className='screen-header'>
                            <h3>User Management</h3>
                            <h5>Create New User</h5>
                        </div>
                    }
                    {manageMode === 'list' ?
                        <>
                            <div className='control-box-wrapper'>
                                <div className='control-box-list'>
                                    <UserFilter
                                        userMgr={userMgr}
                                        orgs={orgs}
                                        orgMgr={orgMgr}
                                        onFilterChange={handleOnFilterChange}
                                        onClearFilter={handleOnClearFilter}
                                        filter={userMgr?._userFilter} />

                                    <UserTable
                                        filteredUsers={data}
                                        organizations={organizations}
                                        districts={districts}
                                        schools={schools}
                                        roles={roles}
                                        loading={loading}
                                        onEdit={handleOnEdit}
                                        onTransactions={handleOnTransactions}
                                        onLogs={handleOnLogs} />
                                </div>
                                <div className='screen-footer list'>
                                    {userMgr?.CanWrite && <><ButtonControl loading={exportLoading} type={'create'}
                                        onClick={handleOnCreateUser}>
                                        Create User
                                    </ButtonControl>&nbsp;&nbsp;</>}
                                    {userMgr?.CanView && appUserMgr?.canView(appUserMgr?.AppUserState?.permissions.ExportUsers) && <><ButtonControl loading={exportLoading} type={'cancel'}
                                        onClick={handleOnExport}>
                                        Export Users
                                    </ButtonControl>&nbsp;&nbsp;</>}
                                    {userMgr?.CanWrite && appUserMgr?.canView(appUserMgr?.AppUserState?.permissions.ImportUsers) && <Import importDisplayName={'User'} setErrors={setErrors} setMessages={setMessages} onComplete={handleOnCompleteImport} importExportType={SystemTypes.ImportExportType.ImportUsers} />}
                                </div>
                            </div>
                        </>
                        :
                        <UserDetails grabClusters={grabClusters} userMgr={userMgr} onMergeUser={() => setOpenMergeUserDialog(true)} isEdit={isEdit} userId={userId} tableData={data} setTableData={updatedData => setData(updatedData)} onSave={handleOnUserDetailsExit} onErrors={setErrors} onCancel={handleOnUserDetailsExit} onLoadingError={handleOnLoadingError} organizations={organizations} schools={schools} districts={districts} roles={roles} clusters={clusters} />
                    }
                </ScreenWrapper>
            }

        </MainLayout >
    </>
    );
}

export default UserMgmt;