import { useHookstate } from '@hookstate/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Login from './Login';
import { useCheckAuth } from '../../hooks/useAuth';
import globalAppUserStore, { APIStatusType } from '../../stores/globalAppUserStore';
import globalAuthStateStore from '../../stores/globalAuthStateStore';


const PublicRoute = ({ params, component: RouteComponent }) => {
  return <RouteComponent {...params} />;
};

export default PublicRoute;
