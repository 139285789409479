import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import TOSDetails from '../components/tos/details';
import TOSFilter from '../components/tos/filter';
import TOSTable from '../components/tos/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useTOSManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';

const TosMgmt = () => {
    const tosMgr = useTOSManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedTOS, setSelectedTOS] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (tosMgr && !params?.tosId) {
            setLoading(true);
            tosMgr.search().then(fb => {
                setManageMode('list');
                setLoading(false);
            });
        }
        else if (!selectedTOS && params?.tosId && tosMgr) {
            setLoading(true);
            if (params?.tosId.toLowerCase() === 'addnew') {
                setSelectedTOS(tosMgr.DefaultItem);
                setManageMode('view');
                setLoading(false);
            }
            else {
                tosMgr.get(params?.tosId).then((r) => {
                    if (r.Success) {
                        setManageMode('view');
                        setSelectedTOS(r.Items.first());
                    }
                    setLoading(false);
                });
            }
        }
    }, [tosMgr, params?.tosId]);

    const onAddNew = () => {
        setSelectedTOS(tosMgr.DefaultItem);
        setManageMode('view');
        navigate('/admin/tos/addnew');
    }

    const handleOnFilterChange = async (field, value) => {

        const tosFilter = tosMgr.Filter;
        const updatedTOSFilter = { ...tosFilter };
        updatedTOSFilter[field] = value !== '' ? value : null;
        await tosMgr.filterData(updatedTOSFilter);
    }

    const handleOnSave = async (item) => {

        var result = await tosMgr.saveItem(item);
        if (result.Success) {
            if (item.IsActive) {
                const items = await tosMgr.deactivate(result?.Items?.first()?.Id);
                await tosMgr.filterData({ ...tosMgr.Filter }, items);
            }

            setSelectedTOS(null);
            navigate('/admin/tos')
        }

        return result;

    }

    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        tosMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.TOS}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnEdit = (item) => {
        setSelectedTOS(item);
        setManageMode('view');
        navigate(`/admin/tos/${item.Id}`);
    }


    const handleOnCancel = () => {
        navigate('/admin/tos');
        if (tosMgr.FilteredData) {
            setManageMode('list');
        }
        else {
            setManageMode(null);
        }
        setLoadingError(null);
        setErrors(null);
    }

    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete this Terms of Service?`} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <MainLayout errors={errors} validationErrors={validationErrors} >
            {manageMode === 'view' &&
                <div className='screen-header'>
                    <h3>Terms of Service Management</h3>
                    <h5>View Terms of Service</h5>
                </div>
            }
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <TOSFilter tosMgr={tosMgr} onFilterChange={handleOnFilterChange} />
                            <TOSTable filteredItems={tosMgr?.FilteredData ?? []} onEdit={handleOnEdit} onDelete={(item) => { setItemToDelete(item); }} />
                        </div>
                        <div className='screen-footer list'>
                            {tosMgr.CanWrite && <ButtonControl type={"create"} onClick={onAddNew}>Create Terms of Service</ButtonControl>}
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&
                    <TOSDetails tosMgr={tosMgr} tos={selectedTOS} onCancel={handleOnCancel} onSave={handleOnSave} onLoading={setLoading} onErrors={setErrors} onValidationErrors={setValidationErrors} onLoadingError={setLoadingError} />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default TosMgmt;