import React, { useState } from 'react';
import MainLayout from '../../../components/layout/MainLayout';
import { useEffect } from 'react';
import { useAppUserManager, useImportExportManager, useOrgManager, useStagedUserManager } from '../../../hooks/useManagers';
import StagedUserTable from '../components/user/StagedUserTable';
import UserFilter from '../components/user/UserFilter';
import { DialogControl } from '../../../components/controls/DialogControl';
import { ButtonControl } from '../../../components/controls/ButtonControl';
import { useNavigate, useParams } from 'react-router-dom';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import UserDetails from '../components/user/UserDetails';
import '../userList.scss';
import SystemTypes from '../../../services/SystemTypes';
import Import from '../components/importExport/import';
import _ from 'lodash';
import { CheckTreePicker, SelectPicker, TreePicker } from 'rsuite';
import { InputField } from '../../../components/controls/InputField';
import Loading from '../../../components/Loading';

const StagedUserMgmt = () => {
    const [data, setData] = useState(null);
    const [errors, setErrors] = useState(null);
    const [messages, setMessages] = useState(null);
    const [manageMode, setManageMode] = useState('list');
    const [loading, setLoading] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [schools, setSchools] = useState([]);
    const [clusters, setClusters] = useState([])
    const [roles, setRoles] = useState([]);
    const [selectedUsersRow, setSelectedUsersRow] = useState([]);
    const [loadingError, setLoadingError] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [selectedOrgs, setSelectedOrgs] = useState([]);
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const [selectedSchools, setSelectedSchools] = useState([]);
    const params = useParams();
    const [userId, setUserId] = new useState(params?.userId === undefined || params?.userId?.toLowerCase() === 'addnew' ? null : params?.userId);
    const navigate = useNavigate();
    const userMgr = useStagedUserManager();
    const appUserMgr = useAppUserManager();
    const orgMgr = useOrgManager();
    const importExportMgr = useImportExportManager();
    const [loadingActivate, setLoadingActivate] = useState(false);
    const [importExportLoading, setImportExportLoading] = useState(false);
    const [openActiveFlagDialog, setOpenActiveFlagDialog] = useState(null);
    const [orgs, setOrgs] = useState(null)

    const [openMergeUserDialog,setOpenMergeUserDialog] = useState(false);
    const [userMergeFrom,setUserMergeFrom] = useState(null);
    const [userMergeFromUsersFilter,setUserMergeFromUsersFilter] = useState([]);
    const [selectedMergeOrgIds,setSelectedMergeOrgIds] = useState([]);
    const [mergeSuccess,setMergeSuccess] = useState(false);
    const [loadingUserList, setLoadingUserList] = useState(false);

    useEffect(() => {
        if (params?.userId?.toLowerCase() === 'addnew') {
            setManageMode('create');
            setUserId(undefined);
        }
        else if (params?.userId && params?.userId.toLowerCase() !== 'addnew') {
            setManageMode('edit');
            setUserId(params?.userId);
        }
        else {
            setManageMode('list');
            setUserId(0);
        }
    }, [params?.userId])

    useEffect(() => {
        if (userMgr && orgMgr && userMgr?.AppUserState) {
            let ignore = false;
            async function fetchData() {
                if (manageMode === 'list') {
                    setLoadingUserList(true);
                    loadUserProfiles();
                    orgMgr.loadOrgTree(true, true).then((r) => {
                        setOrgs(r)
                    })
                }
                else {
                    if (organizations.length === 0) {
                        userMgr.ClientManager.list(userMgr.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false")).then(result => {
                            if (!ignore && result.length > 0) {
                                if (result[0].Success) {
                                    let orgs = result[0].Items.map(item => ({ label: item.DisplayName, value: item.ClientId, dataType: 'Organization' }))
                                    orgs.sort((a, b) => a.label.localeCompare(b.label));
                                    setOrganizations(orgs);
                                }
                            }
                        });
                    }
                    if (schools.length === 0) {
                        userMgr.SchoolManager.list(userMgr.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false")).then(schoolResult => {
                            if (!ignore && schoolResult.length > 0) {
                                if (schoolResult[0].Success) {
                                    let schoolsMapped = schoolResult[0].Items.map(item => ({ label: item.Name, value: item.Id, districtId: item.DistrictId, clientId: item.ClientId, dataType: 'School' }))
                                    schoolsMapped.sort((a, b) => a.label.localeCompare(b.label));
                                    setSchools(schoolsMapped);
                                }
                            }
                        })
                    }
                    if (districts.length === 0) {
                        userMgr.DistrictManager.list(userMgr.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false")).then(districtResult => {
                            if (!ignore && districtResult.length > 0) {
                                if (districtResult[0].Success) {
                                    let validDistricts = districtResult[0].Items.filter(item => item.ClientId !== null);
                                    let districtsMapped = validDistricts.map(item => ({ label: item.Name, value: item.Id, clientId: item.ClientId, dataType: 'District' }))
                                    districtsMapped.sort((a, b) => a.label.localeCompare(b.label));
                                    setDistricts(districtsMapped);
                                }
                            }

                        })
                    }

                    if (roles.length === 0) {
                        userMgr.RoleManager.list().then(roleResult => {
                            if (!ignore && roleResult.length > 0) {
                                if (roleResult[0].Success) {
                                    let rolesMapped = roleResult[0].Items.map(item => ({ label: item.Name, value: item.Id, clientId: item.ClientId, dataType: 'Role', role: item.Role }));
                                    rolesMapped.sort((a, b) => a.label.localeCompare(b.label));
                                    setRoles(rolesMapped.filter(x => x.role !== 'Anonymous'));
                                }
                            }
                        });
                    }
                }
            }

            fetchData();
            return () => { ignore = true }; //cleanup
        }
    }, [userMgr, manageMode, orgMgr]);

    const loadUserProfiles = (() => {
        userMgr.runOperation('GetUserProfileList', undefined, undefined, { isstaged: true }).then(r => {
            if (r.Success) {
                let items = r.Items[0].Data;
                if (items) {
                    let users = _.map(items, x => {
                        return {
                            ...x,
                            userPositionName: _.find(userMgr?.AppUserState?.observationConfig.UserPositionAlternativeMap, y => y.UserPosition === x.UserPositionType && y.Name)?.Name
                                ?? Object.fromEntries(Object.entries(SystemTypes.UserPositionType).map(a => a.reverse()))[x.UserPositionType]
                        }
                    })
                    userMgr.SetAllUsers(users)
                    setData(users);
                    setLoadingUserList(false);
                    return true;
                }
            }
            else {
                setErrors(["Failed to load Users."]);
                return false;
            }
        })
    })

    useEffect(() => {
        if ((roles && (data || manageMode === 'edit')) || errors) {
            setLoading(false);
        }
    }, [roles, data, errors])

    const grabClusters = (orgId) => {
        const oId = orgId
        if (clusters.length === 0) {
            userMgr.ClusterManager.list(userMgr.buildJSONObjFilter("x => x.IsDeleted == false && x.ClientId == oId", { oId })).then(clusterResult => {
                if (clusterResult.length > 0) {
                    if (clusterResult[0].Success) {
                        let clusterMapped = clusterResult[0].Items.map(item => ({ label: item.Name, value: item.Id, schoolId: item.SchoolId, districtId: item.DistrictId, clientId: item.ClientId, dataType: 'Cluster' }))
                        setClusters(clusterMapped);
                    }
                }

            })
        }
    }
    const handleOnClearFilter = async () => {
        const filteredUsers = await userMgr.FilterUsers({ Search: '', EEPass: '', userInGroup: [], IsActive: '' });
        setData(filteredUsers);
    }


    const handleOnFilterChange = async (field, currentClickedValues, newValue) => {
        const userFilter = userMgr._userFilter;
        const updatedUserFilter = {
            Search: userMgr._userFilter.Search,
            EEPass: userMgr._userFilter.EEPass,
            userInGroup: userMgr._userFilter.userInGroup,
            IsActive: userMgr._userFilter.IsActive,
            JobTitle: userMgr._userFilter.JobTitle
        };

        if (field === 'Search') {
            updatedUserFilter.Search = currentClickedValues;
        }
        else if (field === 'EEPass') {
            updatedUserFilter.EEPass = currentClickedValues;
        }

        else if (field === 'IsActive') {
            updatedUserFilter.IsActive = currentClickedValues === 'UNK' ? '' : currentClickedValues;
        }
        else if (field === 'JobTitle') {
            updatedUserFilter.JobTitle = currentClickedValues === 'UNK' ? '' : currentClickedValues;
        } else if (field === 'userInGroup') {
            updatedUserFilter.userInGroup = currentClickedValues
        }
        const filteredUsers = await userMgr.FilterUsers(updatedUserFilter);
        setData(filteredUsers);
    }

    const handleOnEdit = (row) => {
        setManageMode('edit');
        setIsEdit(true);
        navigate(`/admin/StagedUser/${row.Id}`);
    }

    const handleOnUserDetailsExit = () => {
        setManageMode('list');
        navigate('/admin/StagedUser');
    }


    const handleOnExport = async () => {
        const idList = data.map(x => x.Id);
        setImportExportLoading(true);
        const result = await importExportMgr.ExportData(SystemTypes.ImportExportType.ExportUsers, idList);
        if (!result.Success) {
            setImportExportLoading(null);
            setErrors(["Failed to export Staged Users, if this issue continues please contact NIET support."]);
        }
        else {
            importExportMgr.downloadFile(result.Items.first()).then(r => {
                setImportExportLoading(false);
            }).catch(e => {
                setImportExportLoading(false);
                setErrors(["Staged User export has been created and failed to download. Please check Import/Export Admin."]);
            });
        }
        return result;
    }


    const handleEnableDisable = (row) => {
        let userRowsToActivate = [...(selectedUsersRow ?? [])];
        if (!userRowsToActivate.some(x => x.Id == row.Id)) {
            userRowsToActivate.push(row);
        }
        else {
            userRowsToActivate = userRowsToActivate.filter(x => x.Id !== row.Id);
        }
        setSelectedUsersRow(userRowsToActivate);
    }

    const toggleSelectAll = (pagedData) => {
        let copiedSelectedUsersRow = [...selectedUsersRow ?? []]
        const usersToAdd = pagedData.filter(x => {
            if (!selectedUsersRow.find(y => y.Id === x.Id)) {
                return x
            }
        })
        //If no users to add, indicates they're all in the list already so deselect them.
        if (usersToAdd.length === 0) {
            copiedSelectedUsersRow = copiedSelectedUsersRow.filter(x => {
                if (!pagedData.find(y => y.Id === x.Id)) {
                    return x
                }
            })
            setSelectedUsersRow(copiedSelectedUsersRow)
        } else {
            setSelectedUsersRow(copiedSelectedUsersRow.concat(usersToAdd))
        }
    }

    const handleOnExecuteActivate = async () => {
        const r = await userMgr.ActivateUsers(selectedUsersRow?.map(x => x.Id), openActiveFlagDialog === "send");
        if (r.Success) {
            const updatedData = data.filter(x => !selectedUsersRow.some(y => y.Id == x.Id));
            setData(updatedData);
            const updatedAllData = [...userMgr.allUsers].filter(x => !selectedUsersRow.some(y => y.Id == x.Id));
            userMgr._setAllUsers(updatedAllData);
            setSelectedUsersRow(null);
            setOpenActiveFlagDialog(null);
        }
        else {
            setOpenActiveFlagDialog(null);
            setErrors([`Failed to activate users, ${r?.MessageDetails ?? "Unknown Error"}`]);
        }
        return r;
    }

    const handleOnCompleteImport = (success) => {
        if (success) {
            loadUserProfiles().then((succeed) => {
                if (succeed) {
                    setImportExportLoading(null);
                    setMessages([<h5>All Users Successfully loaded.</h5>]);
                }
            });
        }
    }

    
    useEffect(() => {
        if (userMgr && organizations.length > 0 && selectedMergeOrgIds.length > 0 && userMgr?.AppUserState?.userProfile?.Roles?.some(x => x == 'SysAdmin' || x == 'NIETAdmin')) {
            setUserMergeFromUsersFilter(null);
            const userProfileObj = {
                isActive: false,
                isStaged: false,
                includesStaged: true,
              }

              if(selectedMergeOrgIds.startsWith('s-') && selectedMergeOrgIds != 's-'){
                userProfileObj.sId = selectedMergeOrgIds.substring(2,selectedMergeOrgIds.length);
              }
              else if(selectedMergeOrgIds.startsWith('d-') && selectedMergeOrgIds != 'd-'){
                userProfileObj.dId = selectedMergeOrgIds.substring(2,selectedMergeOrgIds.length);
              }
              else if(selectedMergeOrgIds.startsWith('o-') && selectedMergeOrgIds != 'o-'){
                userProfileObj.oId = selectedMergeOrgIds.substring(2,selectedMergeOrgIds.length);
              }


              userMgr
                .runOperation("GetUserProfileList", undefined, undefined, userProfileObj)
                .then((r) => {
                if(r.Success){
                    var userProfiles = _.sortBy(r.Items.first().Data,x=>x.LastName).map(x=>{
                        return {
                            Id: x.Id,
                            OrgIds: x.OrganizationIds.map(o=>`o-${o}`),
                            DistrctIds: x.DistrictIds.map(d=>`d-${d}`),
                            SchoolIds: x.SchoolIds.map(s=>`s-${s}`),
                            Name: `${x.SystemId} - ${x.LastName},${x.FirstName} - ${x.Email} - ${x.Schools ?? x.Districts ?? x.organizations ?? ""} ${x.IsActive == false ? ` - (Inactive) ` : '' }`
                        }
                    });
                  
                    if(userProfiles){
                        setUserMergeFromUsersFilter(userProfiles);
                    }
                    else{
                        setUserMergeFromUsersFilter([]);
                    }
                }
            });
        }
    }, [userMgr,selectedMergeOrgIds])

    //archived user toggle, fetch user set if applicable. 
    const searchArchivedUsers = (showArchivedToggle) => {
        setLoadingUserList(true);
        userMgr.runOperation('GetUserProfileList', undefined, undefined, { isstaged: true, isarchived: showArchivedToggle }).then(r => {
            if (r.Success) {
                let items = r.Items[0].Data;
                if (items) {
                    let users = _.map(items, x => {
                        return {
                            ...x,
                            userPositionName: _.find(userMgr?.AppUserState?.observationConfig.UserPositionAlternativeMap, y => y.UserPosition === x.UserPositionType && y.Name)?.Name
                                ?? Object.fromEntries(Object.entries(SystemTypes.UserPositionType).map(a => a.reverse()))[x.UserPositionType]
                        }
                    })
                    userMgr.SetAllUsers(users)
                    setData(users);
                    setLoadingUserList(false);
                }
            }
            else {
                setErrors(["Failed to load Users."]);
            }
        })
    } 

    const mergeUsers = async ()=>{
        var result = await userMgr.MergeUsers(userId,userMergeFrom);
        if(result.Success){
            setMergeSuccess(true);
            setUserMergeFromUsersFilter([]);
            setSelectedMergeOrgIds([]);
            return {Success: false, skipError:true};
        }
        else{
            return result;
        }
        
    }

    const handleOnMergeOrgChange = (value)=>{
        setSelectedMergeOrgIds(value);
    }

    const onMergeTreeSelectOrg = (val) => {
        //I actually filter by the user. because those users will be associated with the org/dis/school :)
        //let newTreeIds = orgMgr?.findSelected(vals);
        if (handleOnMergeOrgChange)
            handleOnMergeOrgChange(val);
    }

    const onTreeCleanOrg = () => {
        handleOnMergeOrgChange([]);
    }

    return (<>
        <MainLayout errors={errors} messages={messages}>
        <DialogControl openDialog={openMergeUserDialog} title='Select a User to Merge' 
                subTitle={<>{!mergeSuccess && <div>Please select a user you wish to merge INTO the current user.<br/>This will merge observations and certifications, but not user profile information. <br/> The user selected will be disabled and deleted once the merge is complete.<br/><i style={{color:'red'}}>ONCE THIS ACTION IS CONFIRMED IT CANNOT BE UNDONE!</i></div>}</>}
                onCancel={()=>{
                            setOpenMergeUserDialog(false); 
                            setMergeSuccess(false); 
                            setUserMergeFromUsersFilter([]);
                            setSelectedMergeOrgIds([]);
                        }
                    }  okText={'Confirm'} onOk={()=>mergeUsers()} disableOk={!userMergeFrom || mergeSuccess}>
                   {mergeSuccess && 
                   <div className='merge-input success'>
                        User Merged Successfully
                   </div>}
                   {!mergeSuccess &&
                   <>
                   <div className='merge-input'>
                   <InputField
                            id='userInGroup'
                            title='Organization Filter'
                            subTitle={'Please select one Organization, District and/or School.'}
                            value={selectedMergeOrgIds ?? []}
                            fieldName='userInGroup'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleOnMergeOrgChange}>
                            <TreePicker
                                height={320}
                                width={400}
                                className={'filter-control-input'}
                                childrenKey="Children"
                                labelKey="Name"
                                valueKey="Id"
                                data={orgs?.filter(x=>!x.Name.startsWith("--")) ?? []}
                                onClean={onTreeCleanOrg}
                                onSelect={(a, v) => onMergeTreeSelectOrg(v)}
                                
                            />
                        </InputField>
                        </div>
                        <div className='merge-input'>
                        <InputField
                            id='userMergeFrom'
                            title='User to Merge'
                            value={userMergeFrom}
                            fieldName='UserMergeFrom'
                            disableError={true}
                            placeholder=''>
                                <>
                        {userMergeFromUsersFilter ? <SelectPicker
                            className={'filter-control-input'}
                            id='userMergeFilter'
                            height={320}
                            labelKey="Name"
                            valueKey="Id"
                            placeholder= {userMergeFromUsersFilter.length > 0 ? 'Select' : 'Please select an Org, School or District to load users...'}
                            data={userMergeFromUsersFilter}
                            onClean={() => setUserMergeFrom(null)}
                            onSelect={(v) => {setUserMergeFrom(v)}}
                            placement="autoVerticalStart"
                            disabledItemValues={[userId]}
                        /> : <Loading/>}
                        </>
                        </InputField>
                    </div>
                    </>
                }
            </DialogControl>
            <DialogControl openDialog={openActiveFlagDialog}
                onCancel={() => setOpenActiveFlagDialog(null)}
                title={"Activate Users"}
                onYes={handleOnExecuteActivate}
                onNo={() => setOpenActiveFlagDialog(null)}
                isConfirm={true}
                subTitle={`Are you sure you want to Activate ${selectedUsersRow?.length} user(s)?`} >
                <div className='activate-users-dialog'>
                    {openActiveFlagDialog?.toLowerCase() == "send" && <h5>The following user(s) will be activated and sent welcome email(s):</h5>}
                    {openActiveFlagDialog?.toLowerCase() !== "send" && <h5>The following user(s) will be activated, <b>NO</b> email will be sent:</h5>}
                    <div className='activate-users-list'>
                        <ul>
                            {selectedUsersRow?.map(x => <li><b>{x.LastName}, {x.FirstName}:</b>&nbsp;{x.Email}</li>)}
                        </ul>
                    </div>
                </div>
            </DialogControl>
            <ScreenWrapper loading={loading} loadingError={loadingError}>
                {(manageMode === 'edit' && !loading) &&
                    < div className='screen-header'>
                        <h3>Staged User Management</h3>
                        <h5>Edit User</h5>
                    </div>
                }
                {manageMode === 'list' ?
                    <>
                        <div className='control-box-wrapper'>
                            <div className='control-box-list'>
                                <UserFilter orgs={orgs} orgMgr={orgMgr} userMgr={userMgr} isStaged={true} onFilterChange={handleOnFilterChange} onClearFilter={handleOnClearFilter} filter={userMgr?._userFilter} onArchivedToggleChange={searchArchivedUsers} />
                                {!loadingUserList ? 
                                <>
                                    <StagedUserTable toggleSelectAll={toggleSelectAll} selectedUserIds={selectedUsersRow?.map(x => x.Id)} 
                                            filteredUsers={data} roles={roles} loading={loading} onEdit={handleOnEdit} onDisableEnable={handleEnableDisable} />
                                </> 
                                : <> <div style={{ textAlign: 'center', paddingTop: '20rem' }}><Loading type='default' size={'5rem'} /><h5 style={{ textAlign: 'center', paddingTop: '1rem' }}>Loading data...</h5></div></>}
                            </div>
                            <div className='screen-footer list'>
                                {<><ButtonControl loading={importExportLoading} disabled={(selectedUsersRow?.length ?? 0) === 0} type={'okay'}
                                    onClick={() => setOpenActiveFlagDialog("dontSend")}>
                                    Activate Users
                                </ButtonControl>&nbsp;&nbsp;</>}
                                {<><ButtonControl loading={importExportLoading} disabled={(selectedUsersRow?.length ?? 0) === 0} type={'okay'}
                                    onClick={() => setOpenActiveFlagDialog("send")}>
                                    Activate Users with Welcome
                                </ButtonControl>&nbsp;&nbsp;</>}
                                {userMgr?.CanView && appUserMgr?.canView(appUserMgr?.AppUserState?.permissions.ExportUsers) && <>
                                    <ButtonControl loading={importExportLoading} type={'cancel'}
                                        onClick={handleOnExport}>
                                        Export Users
                                    </ButtonControl>&nbsp;&nbsp;
                                </>}
                                {userMgr?.CanWrite && appUserMgr?.canView(appUserMgr?.AppUserState?.permissions.ImportUsers) && <Import importDisplayName={'User'} setErrors={setErrors} setMessages={setMessages} onComplete={handleOnCompleteImport} importExportType={SystemTypes.ImportExportType.ImportUsers} />}
                            </div>
                        </div>
                    </>
                    :
                    <UserDetails
                        grabClusters={grabClusters}
                        userMgr={userMgr}
                        isEdit={isEdit}
                        onMergeUser={() => setOpenMergeUserDialog(true)} 
                        userId={userId}
                        tableData={data}
                        setTableData={updatedData => setData(updatedData)}
                        onSave={handleOnUserDetailsExit}
                        onErrors={setErrors}
                        onCancel={handleOnUserDetailsExit}
                        organizations={organizations}
                        schools={schools}
                        districts={districts}
                        roles={roles}
                        clusters={clusters}
                    />
                }
            </ScreenWrapper>
        </MainLayout >
    </>
    );
}

export default StagedUserMgmt;