import { React, useEffect, useState } from 'react';
import { Panel } from 'rsuite';
import { InputField } from '../../../../../components/controls/InputField';
import { LabelControl } from '../../../../../components/controls';
import './../styles/observationPayRatios.scss';

const ObservationAchievementPayRatios = ({ observeAchievement, observeNonAchievement, setAchieveData, setNonAchieveData }) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [observationAchievementPayRatiosData, setObservationAchievementPayRatiosData] = useState();
    const [observationNonAchievementPayRatiosData, setObservationNonAchievementPayRatiosData] = useState();
    const positionOrder = ["Career Teacher", "Mentor Teacher", "Master Teacher"];

    useEffect(() => {
        if (observeAchievement && observeNonAchievement) {
            let sortedAchieveData = sortPayRatioData(observeAchievement);
            setObservationAchievementPayRatiosData(sortedAchieveData);

            let sortedNonAchieveData = sortPayRatioData(observeNonAchievement);
            setObservationNonAchievementPayRatiosData(sortedNonAchieveData);
        }
    }, [observeAchievement, observeNonAchievement]);

    const sortPayRatioData = (dataSet) => {
        //sort the array by score 
        const groupedData = dataSet.reduce((groups, item) => {
            const score = item.Score;
            if (!groups[score]) {
                groups[score] = [];
            }
            groups[score].push(item);
            return groups;
        }, {});

        const sortedGroupedData = Object.entries(groupedData).sort(([scoreA], [scoreB]) => {
            const parsedScoreA = parseFloat(scoreA);
            const parsedScoreB = parseFloat(scoreB);

            if (parsedScoreA < parsedScoreB) {
                return -1;
            } else if (parsedScoreA > parsedScoreB) {
                return 1;
            } else {
                return 0;
            }
        });

        const sortedDataWithinGroups = sortedGroupedData.reduce((result, [score, group]) => {
            result[score] = group;
            return result;
        }, {});

        const sortedDataArray = Object.entries(sortedDataWithinGroups)
            .sort(([scoreA], [scoreB]) => parseFloat(scoreA) - parseFloat(scoreB))
            .map(([score, group]) => ({ score, group }));


        //sort by positon type
        const sortGroupByObservedPositionName = (a, b) => {
            const positionOrder = {
                "Career Teacher": 1,
                "Mentor Teacher": 2,
                "Master Teacher": 3,
            };
            const positionA = a.ObservedPositionName;
            const positionB = b.ObservedPositionName;
            return positionOrder[positionA] - positionOrder[positionB];
        };

        // Sort the group array within each object
        const sortedData = sortedDataArray.map((item) => ({
            ...item,
            group: item.group.sort(sortGroupByObservedPositionName),
        }));

        return sortedData;
    }

    const handleAchievementRatioChange = (score, observedPosition, value) => {
        const updatedAchieveObserverWeightData = [...observeAchievement];
        let updatedPosition = updatedAchieveObserverWeightData.find(x => x.ObservedPositionName === observedPosition && x.Score === score);

        updatedPosition["RatioAmount"] = value;

        setAchieveData(updatedAchieveObserverWeightData);
    }

    const handleNonAchievementRatioChange = (score, observedPosition, value) => {
        const updatedAchieveObserverWeightData = [...observeNonAchievement];
        let updatedPosition = updatedAchieveObserverWeightData.find(x => x.ObservedPositionName === observedPosition && x.Score === score);

        updatedPosition["RatioAmount"] = value;

        setNonAchieveData(updatedAchieveObserverWeightData);
    }

    return (
        <>
            <Panel header="Observation Achievement Pay Ratios" bordered>
                <div className='observation-achievement-pay-ratios-table'>
                    {observationAchievementPayRatiosData && observationNonAchievementPayRatiosData ? (
                        <>
                            <div className="table-container">
                                <table className="observation-achievement-pay-ratios-table">
                                    <thead>
                                        <tr className='main-header'>
                                            <th className='blank-header'></th>
                                            <th className='blank-header'></th>
                                            <th>Achievement</th>
                                            <th className='blank-header'></th>
                                        </tr>
                                        <tr style={{ display: 'flex', paddingTop: '.2rem' }}></tr>
                                        <tr className='sub-header'>
                                            <th className='score-header'>Score</th>
                                            <th className='norm-header'>Career Teacher</th>
                                            <th className='norm-header'>Mentor Teacher</th>
                                            <th className='norm-header'>Master Teacher</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {observationAchievementPayRatiosData.map(({ score, group }) => (
                                            <tr key={score}>
                                                <td className='row-header'>{score}</td>
                                                {group.map((entry, index) => (
                                                    <td key={index}>
                                                        <InputField
                                                            id={`achievement-${index}`}
                                                            value={entry.RatioAmount}
                                                            placeholder={'0'}
                                                            onChange={(value) => handleAchievementRatioChange(entry.Score, entry.ObservedPositionName, value)}
                                                            type="text"
                                                            disableError={true} />
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <table className="observation-achievement-pay-ratios-table">
                                    <thead>
                                        <tr className='main-header'>
                                            <th className='blank-header'></th>
                                            <th className='blank-header'></th>
                                            <th>Non-Achievement</th>
                                            <th className='blank-header'></th>
                                        </tr>
                                        <tr style={{ display: 'flex', paddingTop: '.2rem' }}></tr>
                                        <tr className='sub-header'>
                                            <th className='score-header'>Score</th>
                                            <th className='norm-header'>Career Teacher</th>
                                            <th className='norm-header'>Mentor Teacher</th>
                                            <th className='norm-header'>Master Teacher</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {observationNonAchievementPayRatiosData.map(({ score, group }) => (
                                            <tr key={score}>
                                                <td className='row-header'>{score}</td>
                                                {group.map((entry, index) => (
                                                    <td key={index}>
                                                        <InputField
                                                            id={`nonachievement-${index}`}
                                                            value={entry.RatioAmount}
                                                            placeholder={'0'}
                                                            onChange={(value) => handleNonAchievementRatioChange(entry.Score, entry.ObservedPositionName, value)}
                                                            type="text"
                                                            disableError={true} />
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
            </Panel>
        </>
    );
}

export default ObservationAchievementPayRatios;