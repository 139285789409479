import React, { useEffect, useState } from 'react';
import NFSlider from "../../../contentLibrary/app-components/Slider/components/NetflixSlider";
import { useAppUserManager, useContentMyDashboardManager } from '../../../../hooks/useManagers';
import Slider from '../../../contentLibrary/app-components/Slider';
import Loading from '../../../../components/Loading';
import _ from 'lodash';
import moment from 'moment';

export default function ContentDashboard({ onLike, onDownload, onView, onShare, onFullScreen }) {
    const contentMyDashboardMgr = useContentMyDashboardManager();
    const authMgr = useAppUserManager()

    const [contents, setContents] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const loadContent = async () => {
            setLoading(true);
            if (contentMyDashboardMgr) {
                const allContents = await contentMyDashboardMgr.SearchContent();
                if (allContents) {
                    const likedContent = {
                        name: `My Liked Content ${allContents.some(x => x.RecommendedForUser && !x.HasUserRating) ? ' & Recommended Content' : ''}`,
                        content: allContents.filter(x => x.HasUserRating || x.RecommendedForUser)
                    }
                    const sharedWithMe = {
                        name: "Shared With Me",
                        content: allContents.filter(x => x.SharedWithUser)
                    }
                    const contentHistory = {
                        name: "Content History",
                        content: _.orderBy(allContents.filter(x => x.HasViewed), ['DateViewed'], ['desc'])
                    }

                    setContents([likedContent, sharedWithMe, contentHistory])
                    setLoading(false);
                }
            }
        }
        if (contents !== null)
            loadContent();
    }, [contentMyDashboardMgr]);

    const containerRef = React.useRef();
    return (
        <div className="content-dashboard">
            {
                loading ? <div className="loading-wrapper"><Loading size={'5rem'} /></div> :
                    (contents && contents.length > 0) ?
                        contents?.map((category, index) => {
                            return (
                                <Slider
                                    authMgr={authMgr}
                                    key={`primary_display_${index}`}
                                    onFullScreen={onFullScreen}
                                    contents={category.content}
                                    categoryName={category.name}
                                    onLike={onLike}
                                    onDownload={onDownload}
                                    // onView={onView}
                                    onShare={onShare}
                                    // TODO: implement the like, download, view, share, and full screen functionality
                                    containerRef={containerRef}
                                />
                            )
                        })
                        :
                        <div className="no-records">
                            <div>
                                <h5>No Content Found.</h5>
                            </div>
                        </div>
            }
        </div>
    );
}
