export const getDefaultReportParameters = () => ({
	reportType: undefined,
	organizationId: undefined,
	districtId: undefined,
	schoolId: undefined,
	clusterId: undefined,
	teacherUserIds: undefined,
	schoolYear: undefined,
	selectDateRange: false,
	startDate: undefined,
	endDate: undefined,
	isSummarizeAtLevel: false,
	includeInactiveUsers: false,
	includeNietAdministrators: false,
	includeDirectors: false,
	isNIETAdmin: undefined
});

export const getReportParametersFromQueryString = (queryString) => {
	var dictionary = {};

	if (queryString.indexOf('?') === 0) {
		queryString = queryString.substr(1);
	}

	var parts = queryString.split('&');

	for (var i = 0; i < parts.length; i++) {
		var p = parts[i];
		var keyValuePair = p.split('=');

		var key = keyValuePair[0];
		var value = keyValuePair[1];

		value = decodeURIComponent(value);
		value = value.replace(/\+/g, ' ');

		dictionary[key] = value;
	}

	return dictionary;
}
