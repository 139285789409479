import React, { useState } from 'react';
import moment from "moment";
import '../../rubricLayout.scss';
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { asyncForEach } from "../../../contentLibrary/utils/jsUtil";
import ButtonControl from '../../../../components/controls/ButtonControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { InputField } from '../../../../components/controls/InputField'
import CheckboxControl from '../../../../components/controls/CheckboxControl';
import { ConstructionOutlined, Diversity1Outlined } from '@mui/icons-material';
import { Toggle } from 'rsuite';
import RubricRatings from './RubricRatings';

const RubricLayoutDialogTable = ({ data, setDomainTableData, domainDataSet, onSaveInner, onSaveInnerDomains, onDomainChange, saveNewDomainItem, onSave, handleDeleteRow, isEditMode, onDelete, onNavigateRow }) => {
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState();
    const [domainData, setDomainData] = useState();
    const [isDefaultDomain, setIsDefaultDomain] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Domain',
            dataProp: "domain",
            width: '70',
            justifyLeft: true,
            canSort: false
        },
        {
            header: 'Use for Certification',
            dataProp: "useForCert",
            width: '10',
            wrapText: true,
            canSort: false
        },
        {
            header: 'Order',
            dataProp: "order",
            width: '10',
            canSort: false
        }
    ])

    useEffect(() => {
        let newRows = [];
        if (data && domainDataSet) {

            if (rows.length === 0) {
                const mappedDomains = domainDataSet.map(d => {
                    return {
                        domainId: d.Id,
                        Value: d.Name,
                        Text: d.Name,
                        useForCert: d.IsActive,
                        Order: d.Sequence
                    }
                });
                setDomainData(mappedDomains);

                if (data.Status === 2) {
                    setIsPublished(true);
                }
                asyncForEach(data.RubricDomainMap, (rdmap) => {

                    if (rdmap.RubricDomain.Name === 'Default') {
                        setIsDefaultDomain(true);
                    }
                    let domainIndicators = data.RubricIndicatorMap.filter(i => i.RubricDomainId === rdmap.RubricDomainId);
                    newRows.push({
                        domainId: rdmap.RubricDomainId,
                        domain: rdmap.RubricDomain.Name + ' (' + domainIndicators.length + ')',
                        useForCert: rdmap.UseForCertification,
                        order: rdmap.Sequence,
                        isEditMode: false,
                        isNewRow: false,
                        expandRowClicked: false,
                        rubricStatus: data.Status,
                        Id: rdmap.Id
                    });

                    //add indicators associated with this domain item as it's own row, will be a mini table embedded
                    newRows.push({
                        useTableRow: true,
                        expandRow: false,
                        domainId: rdmap.RubricDomainId,
                        order: rdmap.Sequence,
                        indicators: domainIndicators,
                        rubricStatus: data.Status
                    });
                }).then(() => {
                    sortRows(newRows);
                });
            }
        }
    }, [data, domainDataSet]);

    const sortRows = (newRows) => {
        var currentRowSet = [...newRows];

        var domainArr = newRows.filter(d => d.domain);
        domainArr = domainArr.sort((a, b) =>
            (a.order > b.order) ? 1 :
                (a.order < b.order) ? -1 : 0
        );

        var indicatorArr = newRows.filter(d => d.indicators);
        let arrDomainCopy = [...domainArr];

        if (indicatorArr) {
            indicatorArr.forEach(i => {
                arrDomainCopy.forEach(d => {
                    if (i.domainId === d.domainId) {
                        let indexOfDomain = domainArr.findIndex(function (x) {
                            return x.domainId === d.domainId;
                        });

                        if (indexOfDomain + 1 > newRows.length) {
                            domainArr.push(i);
                        }
                        else {
                            domainArr.splice(indexOfDomain + 1, 0, i);
                        }
                    }
                })
            })
        }

        setRows(domainArr);
        setDomainTableData(currentRowSet);
    }

    const onAddDomain = () => {
        var currentRowSet = [...rows];

        var domainArr = currentRowSet.filter(x => x.domain);

        //add new row to table
        const newRow = {
            domain: '',
            domainId: '',
            order: currentRowSet.length + 1,
            useForCert: false,
            isEditMode: true,
            isNewRow: false,
            expandRowClicked: false
        }
        currentRowSet.push(newRow);

        const newIndicatorRow = {
            domainId: '',
            expandRow: false,
            indicators: [],
            useTableRow: true,
            order: 1
        }
        currentRowSet.push(newIndicatorRow);

        setRows(currentRowSet);
        setDomainTableData(currentRowSet);
        setEditMode(true);
        isEditMode(true);
    };

    const onEditRow = (row) => {
        let currentRowSet = [...rows];
        if (row.dataRecord) {
            if (row.expandRow) {
                let index = currentRowSet.findIndex(r => r.useTableRow === true && r.domainId === row.dataRecord.domainId);
                if (index != -1) {
                    currentRowSet[index].expandRow = !currentRowSet[index].expandRow;
                    currentRowSet[index - 1].expandRowClicked = !currentRowSet[index - 1].expandRowClicked;

                    let existingEditModeActive = currentRowSet.filter(x => x.isEditMode === true);
                    let domainName = row.row.includes('Default');


                    if (currentRowSet[index].expandRow && existingEditModeActive.length === 0) {
                        isEditMode(false);
                        setEditMode(false);
                        setIsDefaultDomain(true);
                    }
                    else if (domainName) {
                        setIsDefaultDomain(true);
                    }
                    else {
                        setIsDefaultDomain(false);
                    }
                }
            }
        }
        else {
            let index = currentRowSet.findIndex(r => r.order === row.order && r.domainId === row.domainId);
            currentRowSet[index].isEditMode = !currentRowSet[index].isEditMode;
            isEditMode(true);
            setEditMode(true);
        }

        setRows(currentRowSet);
        setDomainTableData(currentRowSet);

    };

    const onSaveRow = async (row) => {
        let currentRowSet = [...rows];
        let index = currentRowSet.findIndex(r => r.order === row.order);

        currentRowSet[index].isEditMode = false;

        if (row.isNewRow) {
            if (row.domain !== '') {
                currentRowSet[index].isNewRow = false;
                let currentDomainSet = [];

                if (domainData) {
                    currentDomainSet = [...domainData];
                }

                const newDomain = {
                    domainId: row.domainId,
                    Value: row.domain,
                    Text: row.domain,
                    useForCert: row.useForCert,
                    Order: row.order
                }
                currentDomainSet.push(newDomain);
                setDomainData(currentDomainSet);
                await onSave('row', newDomain);
            }
        } else {
            //handleDomainSave(row);
            let domainIndicators = data.RubricIndicatorMap.filter(i => i.RubricDomainId === row.domainId);
            let existingDomain = domainData.find(x => (x.Text === row.level) || (x.domainId === row.domainId));

            currentRowSet[index].isEditMode = false;
            currentRowSet[index].domain = existingDomain.Text + ' (' + domainIndicators.length + ')';

            const updatedDomain = {
                domainId: row.domainId,
                Value: row.domain,
                Text: row.domain,
                useForCert: row.useForCert,
                Order: row.order,
                Id: row.Id
            }
            await onSave('row', updatedDomain);
        }
        if (row.domain !== '') {
            setRows(currentRowSet);
            setDomainTableData(currentRowSet);
            isEditMode(false, true);
            setEditMode(false);
        }
    };

    const onDeleteRow = (row) => {
        let currentRowSet = [...rows];

        if (row.indicator) {
            onDelete(row);
        }
        else {
            let index = currentRowSet.findIndex(r => r.order === row.order);

            currentRowSet.splice(index, 1);
            currentRowSet.splice(index, 1);

            if (row.domainId !== '') {
                onDelete(row);
            }
            setRows(currentRowSet);
            setDomainTableData(currentRowSet);
        }
        setEditMode(false);
        isEditMode(false, true);

    };

    const onCheckboxChangeRow = (row) => {
        if (row.isEditMode) {
            let currentRowSet = [...rows];
            let index = currentRowSet.findIndex(r => r.order === row.order);

            currentRowSet[index].useForCert = !currentRowSet[index].useForCert;

            setRows(currentRowSet);
            setDomainTableData(currentRowSet);
        }
    };

    const handleOnOrderChange = (order, isMovedUp) => {
        // Copy the rows array to avoid modifying state directly
        const currentRowSet = [...rows];

        // Find the index of the row being moved
        const index = currentRowSet.findIndex(r => r.order === order);

        if (index < 0 || (index + 2 >= currentRowSet.length && !isMovedUp) || (index === 0 && isMovedUp)) {
            // Can't move the first item or any item outside range
            return;
        }

        const temp = currentRowSet[index];
        currentRowSet[index] = currentRowSet[isMovedUp ? index - 2 : index + 2];
        currentRowSet[isMovedUp ? index - 2 : index + 2] = temp;

        const temp2 = currentRowSet[index + 1];
        currentRowSet[index + 1] = currentRowSet[isMovedUp ? index - 1 : index + 3];
        currentRowSet[isMovedUp ? index - 1 : index + 3] = temp2;

        setRows(currentRowSet);
        onSaveInnerDomains(currentRowSet);
        setEditMode(false);
        isEditMode(false, true);
    };

    const handleOnDropdownChange = (e, row) => {
        let currentRowSet = [...rows];
        let index = currentRowSet.findIndex(r => r.order === row.order);
        if (e.target.value == 'addOption') {
            currentRowSet[index].isNewRow = true;
            currentRowSet[index].domain = '';
        } else {
            let selectedRatingIndex = domainData.findIndex(r => r.Value == e.target.value);

            currentRowSet[index].domainId = domainData[selectedRatingIndex].domainId;
            currentRowSet[index].domain = domainData[selectedRatingIndex].Value;

            //update indicator domain set reference underneath
            currentRowSet[index + 1].domainId = domainData[selectedRatingIndex].domainId
        }

        setRows(currentRowSet);
        setDomainTableData(currentRowSet);
    };

    const handleOnChangeInput = (e, field, row) => {
        let currentRowSet = [...rows];
        let index = currentRowSet.findIndex(r => r.order === row.order);

        currentRowSet[index].domain = e;
        setRows(currentRowSet);
        setDomainTableData(currentRowSet);
    };

    const handleCheckDisableAction = (row, action) => {
        if (action === 'delete' && data.Status === 2) {
            return true
        }
        else {
            return false;
        }
    }

    return <>
        <div className="configureRubric-list">
            <TableControl
                columns={columns}
                data={rows}
                customBorder={true}
                onEdit={(row) => onEditRow(row)}
                onDelete={(row) => onDeleteRow(row)}
                onSave={(row) => onSaveRow(row)}
                onSaveInner={(indicatorRowSet) => onSaveInner(indicatorRowSet)}
                onCheckboxChange={(row) => onCheckboxChangeRow(row)}
                onOrderChange={(sequence, isMovedUp) => handleOnOrderChange(sequence, isMovedUp)}
                onChange={handleOnDropdownChange}
                onChangeInput={handleOnChangeInput}
                onNavigate={(row) => onNavigateRow(row)}
                isEditMode={editMode}
                dropdownData={domainData}
                checkDisableAction={handleCheckDisableAction}
                useColumnWidthCustom={true}
                customActionsStyle={'10'}
                tableRowComponentName='IndicatorRowTable'
                noDataText={"No Domains Have Been Added"}
            />
        </div>

        <div className='performance-btn-row'>
            {!editMode && !isDefaultDomain && !isPublished &&
                <FontAwesomeIcon className="plus-icon-domain" icon={faCirclePlus} />
            }
            <ButtonControl disabled={editMode || isDefaultDomain || isPublished} loading={loading} type={'okay'} className={'add-domain-btn'} onClick={onAddDomain}>
                Add Domain
            </ButtonControl>
        </div>

    </>
}

export default RubricLayoutDialogTable;