import { useEffect, useState } from 'react';
import './details.scss';
import { Radio, RadioGroup } from 'rsuite';
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import { CheckboxControl } from '../../../../components/controls';
import { useValidationManager } from '../../../../hooks/useValidation';
import { DialogControl } from '../../../../components/controls/DialogControl';
import Loading from '../../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faEye, faPencil, faDownload } from '@fortawesome/free-solid-svg-icons'
import ReactQuill from 'react-quill';
import FileUploaderControl from '../../../../components/controls/FileUploaderControl';
import { ContentTypeEnum } from "../../../contentLibrary/services/ContentService";
import { v4 as uuidv4 } from 'uuid';
import ContentModal from './ContentModal';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';


const CertificationQuestionDetails = ({ canWrite, certificationQuestionMgr, certificationQuestion, onCancel, onSave, onErrors, onValidationErrors }) => {
    const [addAnswer, setAddAnswer] = useState(false);
    const [focusedAnswerIndex, setFocusedAnswerIndex] = useState(-1);
    const [answersSearch, setAnswersSearch] = useState('');
    const [certificationQuestionItem, setCertificationQuestionItem] = useState(certificationQuestion ?? certificationQuestionMgr.DefaultItem);
    const [saving, setSaving] = useState(false);
    const [forceValidate, setForceValidate] = useState(false);
    const [forceValidateAnswer, setForceValidateAnswer] = useState(false)
    const [answers, setAnswers] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [certificationAnswerItem, setCertificationAnswerItem] = useState({
        IsCorrect: false,
        AnswerText: '',
        FileId: null,
        File: null,
        CertificationQuestionId: null,
    });
    const questionValidationMgr = useValidationManager(false, 'CERTIFICATIONQUESTION');
    const answerValidationMgr = useValidationManager(false, 'CERTIFICATIONANSWER');
    const [fileLoading, setFileLoading] = useState({ question: false, answer: false });
    const [thumbDld, setThumbDld] = useState(false);
    const [fileDld, setFileDld] = useState(false);
    const [openAnswerContent, setOpenAnswerContent] = useState(false);
    const [correctRadioValue, setCorrectRadioValue] = useState(-1);
    const [canBeActive, setCanBeActive] = useState(false);

    useEffect(() => {
        if (certificationQuestionMgr && answers === null && certificationQuestionItem?.Id) {
            certificationQuestionMgr.GetAllAnswers(certificationQuestionItem.Id).then((r) => {
                if (r?.first()?.Success) {
                    setAnswers(r.first().Items);
                }
            });
        }
        else if (certificationQuestionMgr && answers === null && !certificationQuestionItem?.Id) {
            setAnswers([]);
        }
    }, [certificationQuestionMgr, certificationQuestionItem])

    useEffect(() => {
        setCorrectRadioValue(answers?.findIndex((answer) => answer.IsCorrect));
        let newCanBeActive = answers?.some((answer) => !answer.Deleted && answer.IsCorrect)
            && answers?.filter((answer) => !answer.Deleted).length > 1;
        if (newCanBeActive !== canBeActive) {
            setCanBeActive(newCanBeActive);
            if (newCanBeActive === false && certificationQuestionItem.IsActive === true) {
                handleOnChange(false, 'IsActive');
            }
        }
    }, [answers])


    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    const handleOnSave = (saveAndContinue) => async (e) => {
        const validateResult = questionValidationMgr.checkIsGroupValid(certificationQuestionItem);
        // sessionStorage.removeItem('FileUpload-question-certification');
        if (validateResult?.length === 0) {
            setForceValidate(false);
            if (onSave) {
                setSaving(true);
                certificationQuestionItem.File = null;
                let savedAnswers = await certificationQuestionMgr.SaveAnswers(certificationQuestionItem.Id, answers);

                var result = await onSave({ ...certificationQuestionItem, Answers: undefined });
                if (result?.Success) {
                    if (!saveAndContinue) {
                        handleOnCancel();
                    }
                    else {
                        navigate(`/admin/certificationQuestion/${location.pathname.toLowerCase().includes('/teacher') ? 'Teacher' : 'SchoolAdmin'}/${result.Items.first().Id}`)
                    }

                    const savedAnswerItems = savedAnswers.filter(x => x.first().Success).map(x => x.first().Items.first());
                    setAnswers(savedAnswerItems);
                    setSaving(false);
                    return result;
                } else if (onErrors) {
                    onErrors([result?.Message?.DisplayMessage ?? [...(result?.MessageDetails ?? ['Unknown Error'])]])
                    setSaving(false);
                    return result;
                }
            }
        } else if (onErrors) {
            setForceValidate(true);
            onValidationErrors(validateResult);
        }

    }

    const handleOnChange = (value, field) => {
        let updatedCertificationQuestion = { ...certificationQuestionItem };
        if (value?.FileId) {
            updatedCertificationQuestion[field] = value?.FileId;
            if (field === 'FileId') {
                updatedCertificationQuestion.File = value;
            }
        } else {
            if (field === 'FileId' && !value) {
                updatedCertificationQuestion[field] = value
                updatedCertificationQuestion['field'] = value
            } else updatedCertificationQuestion[field] = value;
        }

        setCertificationQuestionItem(updatedCertificationQuestion);
    }

    const handleAnswerDialog = async (value) => {
        if (addAnswer === true) { // close the dialog and add the answer, if specified
            let keepOpen = false;
            if (value !== null && value !== undefined) {
                if (focusedAnswerIndex >= 0) {
                    answers[focusedAnswerIndex] = value;
                    setAnswers(answers);
                    setFocusedAnswerIndex(-1);
                } else {
                    const res = handleAddAnswer(value);
                    if (res === null) {
                        keepOpen = true;
                    }
                }
            }
            setAddAnswer(keepOpen);
        } else if (!saving) { // open the dialog and prevent a duplicate save on double click
            if (!certificationQuestionItem?.Id) {
                let result = await handleOnSave(true)();
                if (result?.Success) {
                    setCertificationQuestionItem(result.Items[0]);
                    setCertificationAnswerItem({
                        IsCorrect: false,
                        AnswerText: '',
                        FileId: null,
                        File: null,
                        CertificationQuestionId: result.Items[0].Id,
                    });
                    setAddAnswer(true);
                }
            } else {
                setCertificationAnswerItem({
                    IsCorrect: false,
                    AnswerText: '',
                    FileId: null,
                    File: null,
                    CertificationQuestionId: certificationQuestionItem.Id,
                });
                setAddAnswer(true);
            }
        }
    }
    const handleAddAnswer = (value) => {
        const validateResult = answerValidationMgr.checkIsGroupValid(certificationAnswerItem);
        // sessionStorage.removeItem('FileUpload-question-certification');
        if (validateResult?.length !== 0) {
            setForceValidateAnswer(true);
            onValidationErrors(validateResult);
            return null;
        }


        if (answers?.length > 0) {
            // We need to add the answers to the certificationQuestion
            if (!value.IsLastAnswer) {
                setAnswers(oldAnswers => [...oldAnswers, value]);
            } else {
                // if the current last answer is marked as the last answer, we need to remove that flag
                if (answers.at(answers.length - 1).IsLastAnswer) {
                    answers.at(answers.length - 1).IsLastAnswer = false;
                }
                setAnswers(oldAnswers => [...oldAnswers, value]);
            }
        } else {
            value.IsCorrect = true;
            setAnswers(Array(value));
        }
        return value;
    }

    const handleDeleteAnswer = (answer) => (e) => {
        const answerIndex = _.findIndex(answers, (a) => a === answer)
        setAnswers(answers.map((answer, i) => (i !== answerIndex) ? answer : { ...answer, Deleted: true }));
    }
    const handleEditAnswer = (answer) => (e) => {
        const answerIndex = _.findIndex(answers, (a) => a === answer)
        setFocusedAnswerIndex(answerIndex);
        setCertificationAnswerItem(answers[answerIndex]);
        setAddAnswer(true);
    }

    const handleCorrectRadioSelect = (answer) => {
        const answerIndex = _.findIndex(answers, (a) => a === answer)
        setCorrectRadioValue(answerIndex);
        setAnswers(answers.map((answer, i) => {
            answer["IsCorrect"] = answerIndex === i;
            return answer;
        }));
    }


    const handleAnswersSearch = (value) => {
        setAnswersSearch(value.toLowerCase());
    }


    const handleResetQuestionFile = () => {
        //Clears fires a confirm dialog and clears the file.
        sessionStorage.removeItem('FileUpload-question-certification');
        handleOnChange(null, 'FileId');
    }

    const handleResetAnswerFile = () => {
        //Clears fires a confirm dialog and clears the file.
        handleAnswerChange(null, 'FileId');
    }

    const handleDownloadQuestionFile = async (isThumb) => {
        let result = null;

        if (isThumb) {
            setThumbDld(true);
            result = await certificationQuestionMgr.QuestionItemManager.getFile(certificationQuestionItem?.ThumbnailFile?.Id, `${certificationQuestionItem.ThumbnailFile?.DisplayFileName}.${certificationQuestionItem.ThumbnailFile?.FileExtention}`);
            setThumbDld(false);
        }
        else {
            setFileDld(true);
            result = await certificationQuestionMgr.QuestionItemManager.getFile(certificationQuestionItem?.File?.Id, `${certificationQuestionItem.File?.DisplayFileName}.${certificationQuestionItem.File?.FileExtention}`);
            setFileDld(false);
        }
    }

    const handleDownloadAnswerFile = async (isThumb) => {
        let result = null;

        if (isThumb) {
            setThumbDld(true);
            result = await certificationQuestionMgr.AnswerItemManager.getFile(certificationAnswerItem?.ThumbnailFile?.Id, `${certificationAnswerItem.ThumbnailFile?.DisplayFileName}.${certificationAnswerItem.ThumbnailFile?.FileExtention}`);
            setThumbDld(false);
        }
        else {
            setFileDld(true);
            result = await certificationQuestionMgr.AnswerItemManager.getFile(certificationAnswerItem?.File?.Id, `${certificationAnswerItem.File?.DisplayFileName}.${certificationAnswerItem.File?.FileExtention}`);
            setFileDld(false);
        }
    }

    const handleAnswerChange = (value, field) => {
        let updatedCertificationAnswer = { ...certificationAnswerItem };
        if (value?.FileId) {
            updatedCertificationAnswer[field] = value?.FileId;
            if (field === 'FileId') {
                updatedCertificationAnswer.File = value;
            }
        } else {
            updatedCertificationAnswer[field] = value;
        }
        setForceValidateAnswer(false)
        setCertificationAnswerItem(updatedCertificationAnswer);
    }

    const checkIsImage = (fileExtension) => {
        return (
            fileExtension === 'apng' ||
            fileExtension === 'gif' ||
            fileExtension === 'ico' ||
            fileExtension === 'jpg' ||
            fileExtension === 'jpeg' ||
            fileExtension === 'jfif' ||
            fileExtension === 'pjpeg' ||
            fileExtension === 'pjp' ||
            fileExtension === 'png' ||
            fileExtension === 'svg'
        );
    };

    const checkIsVideo = (fileExtension) => {
        return (
            fileExtension === 'mp4' ||
            fileExtension === 'avi' ||
            fileExtension === 'mpg' ||
            fileExtension === 'mpga' ||
            fileExtension === 'streaming.media.azure.net'
        );
    }

    const handleViewAnswerContent = (index) => (e) => {
        setFocusedAnswerIndex(index);
        setCertificationAnswerItem(answers[index]);
        setOpenAnswerContent(true);
    }

    const handleTableAnswerContent = (answer) => {
        const answerIndex = _.findIndex(answers, (a) => a === answer)
        return (
            answer.File ?
                checkIsImage(answer.File.FileExtention) || checkIsVideo(answer.File.FileExtention) ?
                    <FontAwesomeIcon className={'icon btn-icon-fa'} icon={faEye} onClick={handleViewAnswerContent(answerIndex)} />
                    : <FontAwesomeIcon className={'icon btn-icon-fa'} icon={faDownload} onClick={() => {
                        setCertificationAnswerItem(answer);
                        handleDownloadAnswerFile(false);
                    }} />
                : <div>&nbsp;</div>
        )
    }
    return (<>
        <ContentModal
            open={openAnswerContent}
            title={`Content for Answer Choice ${String.fromCharCode(
                focusedAnswerIndex + "a".charCodeAt(0)
            ).toUpperCase()}`}
            setOpen={setOpenAnswerContent}
            okText={"EDIT"}
            handleOk={(e) => handleEditAnswer(certificationAnswerItem)(e)}
            item={certificationAnswerItem}
        />
        <DialogControl
            className="certq-details-ans-add-dialog"
            openDialog={addAnswer}
            title={'Add Answer'}
            onCancel={() => {
                handleAnswerDialog(undefined)
                setForceValidate(false)
            }}
            onOk={() => handleAnswerDialog(certificationAnswerItem)}
        >
            <InputField
                forceValidate={forceValidateAnswer}
                value={certificationAnswerItem?.AnswerText}
                title="Answer Text"
                subTitle={"Enter text for your answer below."}
                fieldName="AnswerText"
                groupId={"CERTIFICATIONANSWER"}
                validateChildren={true}
            >
                <ReactQuill
                    theme="snow"
                    className="rq"
                    onChange={(value) => handleAnswerChange(value, "AnswerText")}
                />
            </InputField>
            <InputField title="Answer Content" forceValidate={forceValidate} value={certificationAnswerItem?.FileId} onChange={(value) => handleAnswerChange(value, 'FileId')} groupId={'CERTIFICATIONQUESTION'} fieldName='FileId' validationName={'CertificationAnswer_FileId'} subTitle={"Drag into or click the below box to upload answer content."}>
                <FileUploaderControl canUploadAnyFile={true} controlId={`answer-${uuidv4()}`} value={certificationAnswerItem?.FileId} fileUrl={certificationAnswerItem?.File?.FileUrl} overrideMaxWidth={400} widthRes={1920} heightRes={1080} maxFileSize={1024 * 1024 * 99} onFileLoading={(val) => setFileLoading({ ...fileLoading, answer: val })} />
            </InputField>
            {
                !(certificationAnswerItem?.FileId === null || certificationAnswerItem?.FileId === undefined)
                && <div className="reset-file-btn"><ButtonControl onClick={() => handleResetAnswerFile()}>Reset File</ButtonControl>{certificationAnswerItem?.File?.FileUrl
                    && <ButtonControl type={'okay'} loading={fileDld} onClick={() => handleDownloadAnswerFile()}>Download</ButtonControl>}</div>
            }
            <InputField
                title="Make Answer Last Choice?"
                value={certificationAnswerItem?.MoveToBottom}
                disableError={true}
                fieldName="IsLastAnswer"
                groupId={"CERTIFICATIONQUESTION"}
                onChange={(value) => handleAnswerChange(value, "MoveToBottom")}
            >
                <CheckboxControl />
            </InputField>
        </DialogControl>
        <div className='certq-details-wrapper control-box-wrapper'>
            {certificationQuestionItem && <> <div className='control-box box-two-column certificationQuestion-left-box'>

                <InputField
                    forceValidate={forceValidate}
                    value={certificationQuestionItem?.QuestionText}
                    title="Question Text"
                    subTitle={"Enter text for your question below."}
                    fieldName="QuestionText"
                    groupId={"CERTIFICATIONQUESTION"}
                    validateChildren={true}
                >
                    <ReactQuill
                        theme="snow"
                        className="rq"
                        onChange={(value) => handleOnChange(value, "QuestionText")}
                    />
                </InputField>
                {<InputField
                    title="Question Content"
                    forceValidate={forceValidate}
                    value={certificationQuestionItem?.FileId}
                    onChange={(value) => handleOnChange(value, 'FileId')}
                    groupId={'CERTIFICATIONQUESTION'} fieldName='FileId'
                    validationName={'CertificationQuestion_FileId'}
                    subTitle={"Drag into or click the box below to upload media content for your question."}
                >
                    <FileUploaderControl canUploadAnyFile={true} controlId={`question-certification`} isPublic={certificationQuestionItem?.IsPublicContent ?? false} value={certificationQuestionItem?.FileId} fileUrl={certificationQuestionItem?.File?.FileUrl} overrideMaxWidth={400} widthRes={1920} heightRes={1080} maxFileSize={1024 * 1024 * 99} onFileLoading={(val) => setFileLoading({ ...fileLoading, question: val })} />
                </InputField>}
                {!(certificationQuestionItem?.FileId === null || certificationQuestionItem?.FileId === undefined)
                    && <div className="reset-file-btn"><ButtonControl onClick={() => handleResetQuestionFile()}>Reset File</ButtonControl>{certificationQuestionItem?.File?.FileUrl
                        && <ButtonControl type={'okay'} loading={fileDld} onClick={() => handleDownloadQuestionFile()}>Download</ButtonControl>}</div>}
                <InputField
                    title="Default?"
                    value={certificationQuestionItem?.IsDefault}
                    disableError={true}
                    fieldName="IsDefault"
                    groupId={"CERTIFICATIONQUESTION"}
                    onChange={handleOnChange}
                >
                    <CheckboxControl />
                </InputField>
                <InputField
                    title="Active?"
                    subTitle={!canBeActive &&
                        "You must enter at least two answer choices and select the correct answer in order to make a question active."}
                    disableError={true}
                    value={canBeActive && certificationQuestionItem?.IsActive}
                    isDisabled={!canBeActive}
                    fieldName="IsActive"
                    groupId={"CERTIFICATIONQUESTION"}
                    onChange={handleOnChange}
                >
                    <CheckboxControl />

                </InputField>
            </div>
                {true &&
                    <div className='certificationQuestion-answer-editor__wrapper control-box box-two-column '>
                        <div className='certificationQuestion-answer-editor__header'>
                            <div className='certificationQuestion-answer-editor__header__text'>
                                <h3>Answers</h3>
                                <div className={'error-wrapper'} style={{ display: certificationQuestionItem?.Id === undefined ? "initial" : "none" }}>
                                    <h5>Please enter question text prior to adding answers.</h5>
                                </div>
                            </div>
                            <ButtonControl disabled={!certificationQuestionItem?.QuestionText} onClick={() => handleAnswerDialog(undefined)}>Add Answer</ButtonControl>
                        </div>
                        <div className='certificationQuestion-answer-editor__search'>
                            <InputField
                                type="text"
                                value={answersSearch}
                                fieldName='search'
                                disableError={true}
                                placeholder='Search Answer Name'
                                onChange={handleAnswersSearch}>
                            </InputField>
                        </div>
                        <div className='certificationQuestion-answer-editor'>
                            {answers ? <>
                                <table className="__table">
                                    <thead className="__head">
                                        <tr>
                                            <th className="__flex">Correct</th>
                                            <th className="__flex">Letter</th>
                                            <th className="__content">Answer Text</th>
                                            <th className="__flex">Content</th>
                                            <th className="__icon" />
                                            <th className="__icon">Edit</th>
                                            <th className="__icon">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ overflowY: answers ? "auto" : "clip" }}>{
                                        answers?.filter((cAnswer) => (cAnswer.AnswerText.toLowerCase().includes(answersSearch) || answersSearch === '') && !cAnswer.Deleted).map((cAnswer, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="__flex blue">
                                                        {correctRadioValue !== null && <input type="radio" onChange={() => handleCorrectRadioSelect(cAnswer)} checked={cAnswer.IsCorrect}></input> /* need to have this stay a controlled component, can't add it till unfortunately */}
                                                    </td>
                                                    <td className="__flex bold">{String.fromCharCode((index) + 'a'.charCodeAt(0)).toUpperCase()}</td>
                                                    <td className="__content" >
                                                        <ReactQuill
                                                            theme="bubble"
                                                            className="rq"
                                                            readOnly={true}
                                                            value={cAnswer.AnswerText}
                                                        />
                                                    </td>
                                                    <td className="__flex">
                                                        {cAnswer.File ?
                                                            <div className="certq-filename"><span>{cAnswer?.File?.DisplayFileName}</span><span>{"." + cAnswer?.File?.FileExtention}</span></div> : null
                                                        }
                                                    </td>
                                                    <td className="__icon">
                                                        {handleTableAnswerContent(cAnswer, index)}
                                                    </td>
                                                    <td className="__icon">
                                                        <FontAwesomeIcon className={'icon btn-icon-fa'} icon={faPencil} onClick={handleEditAnswer(cAnswer)} />
                                                    </td>
                                                    <td className="__icon">
                                                        <FontAwesomeIcon className={'icon btn-icon-fa'} icon={faTrashCan} onClick={handleDeleteAnswer(cAnswer)} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }</tbody>
                                </table>
                            </> : <div className='loading-wrapper'><Loading type='default' size='3rem' /></div>}
                        </div>
                    </div>
                }

            </>}
            <div className='screen-footer screen-footer-right'>
                <div className='btn-wrapper-left screen-footer__btn'><ButtonControl disabled={saving} type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl></div>
                {canWrite && <ButtonControl loading={saving} type={'cancel'} disabled={!certificationQuestionItem} onClick={handleOnSave(true)}>Save & Continue</ButtonControl>}
                {canWrite && <div className='btn-wrapper-right screen-footer__btn'><ButtonControl loading={saving} type={'okay'} disabled={!certificationQuestionItem} onClick={handleOnSave(false)}>Save</ButtonControl></div>}

            </div>
        </div>
    </>)
}

export default CertificationQuestionDetails;