import React from 'react';
import * as analytics from './../managers/GoogleAnalyticsManager';

export function useAnalytics() {

    React.useEffect(() => {
        analytics.init()
    }, [])
}

export default useAnalytics