import React, { useState } from 'react';
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { asyncForEach } from "../../../contentLibrary/utils/jsUtil";
import CircularProgress from '@mui/material/CircularProgress';

const TransactionTable = ({ filteredTransactions, onEdit, onLink }) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columns] = useState([
        {
            header: 'Transaction Type',
            dataProp: "transactionType",
            width: '10.5%',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'Name',
            dataProp: "name",
            width: '12.5%',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'Username',
            dataProp: "username",
            width: '28.5%',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'Price',
            dataProp: "total",
            width: '8.5%',
            canSort: true,
            sortBy: 'Price'
        },
        {
            header: 'Discount Amount',
            dataProp: "discount",
            width: '8.5%',
            canSort: true,
            wrapText: true
        },
        {
            header: 'Status',
            dataProp: "status",
            width: '9.5%',
            canSort: true
        },
        {
            header: 'Transaction Date',
            dataProp: "date",
            width: '12.5%',
            centerItems: true,
            canSort: true
        }
    ])

    useEffect(() => {
        let newRows = [];
        if (filteredTransactions) {
            asyncForEach(filteredTransactions, (u) => {
                let formatedDate = getFormatedDate(u.CreatedDate);
                newRows.push({
                    ...u,
                    transactionType: u.ItemType,
                    name: u.ItemName,
                    username: u.UserProfile?.Email,
                    total: '$' + u.Price,
                    discount: u.TransactionDetails?.DiscountAmount,
                    status: u.Status === 1 ? 'Active' : 'Inactive',
                    date: formatedDate
                });
            }).then(() => {
                setRows(newRows);
            });
        }
    }, [filteredTransactions]);

    const getFormatedDate = (dateInput) => {

        let date = new Date(dateInput);
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        
        return month + '/' + day + '/' + year;
    }

    return <>
        <div className="contentDisplay contentDisplay-list">
            {!loading ? 
            <>
                <TableControl
                columns={columns}
                data={rows}
                useIntegratedPaging={['10', '25', '50', '100']}
                onEdit={(row) => onEdit(row)}
                onTransactions={(row) => onLink(row)}
                removeDelete={true}
                /> 
            </> 
                : <div className="rating-dialog-loader"><CircularProgress color={'inherit'} size={25} /></div>
            }
        </div>
    </>
}

export default TransactionTable;