
import _ from "lodash";
import { ItemServiceFactory } from "../services/ItemServiceFactory";
import ItemManager from "./ItemManager";
import Manager from "./Manager";

export default class PayoutManager extends Manager {

    constructor(schoolYear, globalPayoutState, globalAuthState, globalAppUserState) {
        super(globalAuthState, globalAppUserState);
        this._globalAuthState = globalAuthState;
        this._payoutState = globalPayoutState;

        this._setAllPayouts = (value) => globalPayoutState.merge({ allPayouts: value });
        this._setFilteredPayouts = (value, filter) => globalPayoutState.merge({ filteredPayouts: value, payoutFilter: filter });

        this._schoolMgr = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.School, globalAuthState, globalAppUserState);
        this._districtMgr = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.District, globalAuthState, globalAppUserState);
        this._schoolYearItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.SchoolYear, globalAuthState, globalAppUserState);
        this._payoutSchoolItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.PayoutSchool, globalAuthState, globalAppUserState);
        this._payoutTeacherItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.PayoutTeacher, globalAuthState, globalAppUserState);
        this._payoutAdminItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.PayoutAdmin, globalAuthState, globalAppUserState);
        this._payoutConfigurationItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.PayoutConfiguration, globalAuthState, globalAppUserState);
        this._payoutAdminConfigurationItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.PayoutAdminConfiguration, globalAuthState, globalAppUserState);
        this._payoutAdminConfigurationMetricItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.PayoutAdminConfigurationMetric, globalAuthState, globalAppUserState);
        this._schoolYear = schoolYear;
    }

    get _allPayouts() { return this._payoutState.get().allPayouts; }
    get _payoutFilter() { return this._payoutState.get({ noproxy: true }).payoutFilter; }
    get FilteredPayouts() { return this._payoutState.get().filteredPayouts ?? []; }

    get SelectedSchoolYear() {
        return this._schoolYear;
    }

    get ItemManager() {
        return this;
    }

    get SchoolManager() {
        return this._schoolMgr;
    }

    get DistrictManager() {
        return this._districtMgr;
    }

    get SchoolYearItemMgr() {
        return this._schoolYearItemMgr;
    }

    get PayoutSchoolItemMgr() {
        return this._payoutSchoolItemMgr;
    }

    get PayoutTeacherItemMgr() {
        return this._payoutTeacherItemMgr;
    }

    get PayoutAdminItemMgr() {
        return this._payoutAdminItemMgr;
    }

    get PayoutAdminConfigurationMetricItemMgr() {
        return this._payoutAdminConfigurationMetricItemMgr;
    }

    async LoadPayoutSchools(id, schoolid, districtid, isadminpayout) {
        const sy = this.AppUserState.selectedSchoolYear

        var result = await this.PayoutSchoolItemMgr.runOperation("PayoutSchool", id, { schoolid, districtid, sy, isadminpayout })
        if (result.Success) {
            this._setAllPayouts(result.Items);
            const filteredPayouts = await this._doFilter(this._payoutFilter, result.Items);
            return filteredPayouts;
        }
        else
            return "Fail to load Payout List";
    }

    FilterPayouts(filter) {
        if (!filter)
            filter = this._payoutFilter;

        let payouts = _.cloneDeep(this._allPayouts)
        return this._doFilter(filter, payouts);
    }

    async _doFilter(filter, allPayouts) {
        if (!allPayouts) {
            allPayouts = this._allPayouts.map(x => x);
        }

        const filteredPayouts = _.filter([...allPayouts], t => {
            let districtFound = false;
            if (filter?.District !== undefined && filter?.District.length > 0) {
                if (t.Districts?.length > 0) {
                    filter?.District.forEach(o => {
                        let match = t.Districts.filter(x => x.DistrictId === o);
                        if (match.length > 0) {
                            districtFound = true;
                        }
                    })
                }
            }
            else {
                districtFound = true;
            }

            let schoolFound = false;
            if (filter?.School !== undefined && filter?.School.length > 0) {
                if (t.School) {
                    filter?.School.forEach(s => {
                        let match = t.School.Id === s;
                        if (match) {
                            schoolFound = true;
                        }
                    })
                }
            }
            else {
                schoolFound = true;
            }

            let statusFound = false;
            if (filter?.Status.length > 0) {
                if (t.PayoutStatus) {
                    filter?.Status.forEach(s => {
                        let match = t.PayoutStatus === s;
                        if (match) {
                            statusFound = true;
                        }
                    })
                }
            }
            else {
                statusFound = true;
            }

            let groupFound = false;
            if (filter?.Group?.length > 0) {
                if (t.DistrictId || t.SchoolId) {
                    filter?.Group.forEach(s => {
                        s = s.slice(2)
                        let match = t.DistrictId === s || t.SchoolId === s;
                        if (match) {
                            groupFound = true;
                        }
                    })
                }
            }
            else {
                groupFound = true;
            }

            return districtFound && schoolFound && statusFound && groupFound;
        });

        this._setFilteredPayouts(_.cloneDeep(filteredPayouts), _.cloneDeep(filter));
        return filteredPayouts;
    }


    async savePayoutSchool(item) {
        return this._payoutSchoolItemMgr.save(item)
    }
    async GetPayoutTeachers(clientId, schoolId, isForceScoreUpdate, isPayoutComplete) {
        return await this._payoutTeacherItemMgr.runOperation('GetPayoutTeachers', undefined, {
            clientId, schoolId, schoolYearIdentifier: this.AppUserState.selectedSchoolYear,
            isForceScoreUpdate: isForceScoreUpdate, isPayoutComplete: isPayoutComplete
        });
    }

    async GetPayoutAdmins(clientId, districtId, isForceScoreUpdate, isPayoutComplete) {
        return await this._payoutAdminItemMgr.runOperation('GetPayoutAdmins', undefined, {
            clientId, districtId, schoolYearIdentifier: this.AppUserState.selectedSchoolYear,
            isForceScoreUpdate: isForceScoreUpdate, isPayoutComplete: isPayoutComplete
        });
    }

    async GetPayoutConfiguration(clientId, districtId, schoolId) {
        return await this._payoutConfigurationItemMgr.runOperation('GeneratePayoutConfiguration', undefined, { clientId, districtId, schoolId, schoolYearIdentifier: this.AppUserState.selectedSchoolYear });
    }

    async SavePayoutConfiguration(payoutConfig) {
        return await this._payoutConfigurationItemMgr.runOperation('SavePayoutConfiguration', undefined, { payoutConfig: payoutConfig });
    }

    async GetAdminPayoutConfiguration(clientId, districtId, schoolId) {
        return await this._payoutAdminConfigurationItemMgr.runOperation('GeneratePayoutAdminConfiguration', undefined, { clientId: clientId, districtId: districtId, schoolId: schoolId, schoolYearIdentifier: this.AppUserState.selectedSchoolYear });
    }

    async SavePayoutAdminConfiguration(payoutAdminConfig) {
        return await this._payoutAdminConfigurationItemMgr.runOperation('SavePayoutAdminConfiguration', undefined, { payoutAdminConfig: payoutAdminConfig });
    }

    async CalculatePayout(schoolId, year, districtId, isAdmin) {
        return await this._payoutTeacherItemMgr.runOperation('CalculatePayout', undefined, { year: year, schoolId: schoolId, districtId: districtId, isAdmin: isAdmin });
    }


}