import React from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { SelectListControl } from '../../../../components/controls';

export default function ContentSyncFilter({ contentSyncMgr, onFilterChange }) {
    const handleOnFilterChange = (value, field) => {
        if (onFilterChange)
            onFilterChange(field, value);
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        contentSyncMgr.filterData({ search: null, status: null });
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Content Sync Management</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search'
                            value={contentSyncMgr?.Filter?.search ?? ''}
                            fieldName='search'
                            disableError={true}
                            placeholder='Name'
                            onChange={handleOnFilterChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='status'
                            title='Status'
                            value={contentSyncMgr?.Filter?.status ?? 'UNK'}
                            fieldName='status'
                            disableError={true}
                            onChange={handleOnFilterChange}>
                            <SelectListControl textValuePairs={
                                [
                                    { value: "Running", text: "Running" },
                                    { value: "Skipped", text: "Skipped" },
                                    { value: "Success", text: "Success" },
                                    { value: "Partial Success", text: "Partial Success" },
                                    { value: "Fatal Error", text: "Fatal Error" },
                                    { value: "Error", text: "Error" },
                                ]
                            } />
                        </InputField>
                    </div>
                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}