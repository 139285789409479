import './DialogControl.scss';
import React, { useEffect, useState } from 'react';
import ButtonControl from '../ButtonControl';
import _ from 'lodash'

export default function DialogControl({
    openDialog,
    title,
    subTitle,
    okText,
    cancelText,
    onCancel,
    onCustomCenter,
    disabledCustomCenter,
    loadingCustomCenter,
    customCenterText,
    customCenterType,
    disableOk,
    disableXButton,
    disableCancel,
    excludeCancel,
    onOk,
    loadingOk,
    onNext,
    onPrevious,
    enableNext,
    enableSave,
    loadingSave,
    isPrevious,
    isYes,
    onYes,
    isNo,
    onNo,
    isConfirm,
    onSave,
    customSave,
    customNext,
    customYes,
    children,
    nextText,
    isSave,
    hideButtonRow,
    className,
    footerClassName,
    innerclassName }) {

    const [open, setOpen] = useState(openDialog);
    const [saveDisabled, setSaveDisabled] = useState(false)
    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    useEffect(() => {
        setOpen(openDialog);
        setLoading(false);
    }, [openDialog]);

    useEffect(() => {
        setLoading(loadingSave);
    }, [loadingSave]);

    useEffect(() => {
        if (enableSave !== null && enableSave !== undefined) {
            if (enableSave === true) {
                setSaveDisabled(false);
            } else {
                setSaveDisabled(true);
            }
        }
    }, [enableSave]);

    const handleOnOk = async (e) => {
        if (onOk) {
            setLoading(true);
            const result = await onOk(e);

            if (result !== undefined) {
                if (result?.Success) {
                    setErrorMessages([]);
                    setLoading(false);
                    setOpen(false);
                }
                else if (!result?.Success && !result?.skipError) {
                    setLoading(false);
                    const msg = (result?.MessageDetails && result?.MessageDetails.length > 0 ? result?.MessageDetails : (result?.messages ?? result?.Messages ?? (result?.Message?.DisplayMessage ? [result?.Message?.DisplayMessage] : ["An unknown error has occurred."])));
                    setErrorMessages([...msg]);
                }
                else {
                    setLoading(false);
                }
            }
            else {
                setLoading(false);
            }
        }
        else {
            setLoading(false);
            setOpen(false);
        }
    }

    const handleOnSave = async (e) => {
        if (onSave) {
            setLoading(true);
            await onSave(e);
        }
    }

    const handleOnNext = async (e) => {
        if (onNext) {
            setLoading(true);
            await onNext(e);
        }
    }

    const handleOnYes = async (e) => {
        if (onYes) {
            setLoading(true);
            await onYes(e);
            setLoading(false);
        }
    }

    const handleOnExit = async () => {
        if (onCancel) {
            await onCancel();
        }

        setOpen(false);
        setLoading(false);
        setErrorMessages([]);
    }

    const handleOnCancel = async () => {
        if (!loading) {
            let result = undefined;
            if (onCancel)
                result = await onCancel();

            if (result === undefined || result?.Success) {
                setOpen(false);
                setLoading(false);
                setErrorMessages([]);
            }
        }
    }

    const handleOnCustomCenter = async (e) => {
        if (onCustomCenter) {
            setLoading(true);
            const result = await onCustomCenter(e);
            if (result !== undefined) {
                if (result?.Success) {
                    setErrorMessages([]);
                    setLoading(false);
                    setOpen(false);
                }
                else if (!result?.Success && !result?.skipError) {
                    setLoading(false);
                    const msg = (result?.MessageDetails && result?.MessageDetails.length > 0 ? result?.MessageDetails : (result?.messages ?? result?.Messages ?? (result?.Message?.DisplayMessage ? [result?.Message?.DisplayMessage] : ["An unknown error has occurred."])));
                    setErrorMessages([...msg]);
                }
                else {
                    setLoading(false);
                }
            } else {
                setLoading(false)
            }
        }
        else {
            setLoading(false);
        }
    }

    return <>

        {open && <><div className={`dialog-control__block-ui`}></div>
            <div className={`dialog-control`}>
                <div className={`dialog ${className ? className : ''}`}>
                    <div className='dialog__header'>
                        <div className='dialog__header-title'>
                            <h2>{title}</h2>
                            <h5>{subTitle}</h5>
                        </div>
                        {!disableXButton && <div className='dialog__header-close' onClick={handleOnExit} >
                            X
                        </div>}
                    </div>
                    {(children || errorMessages?.length > 0) &&
                        <div className={`dialog__inner ${className ?? ''} ${innerclassName ?? ''}`}>
                            {errorMessages.length > 0 &&
                                <div className='dialog__error' >
                                    {_.map(errorMessages, (msg, i) => <div key={`dialog-error-${i}`}>{msg}</div>)}
                                </div>
                            }
                            {children}
                        </div>
                    }
                    {!hideButtonRow &&
                        <div className={`dialog__footer ${footerClassName ?? ''}`}>
                            {((cancelText || onCancel) && !excludeCancel) &&
                                < ButtonControl type={'cancel'} disabled={loading || disableCancel} className={'dialog-btn'} onClick={handleOnCancel}>
                                    {cancelText ?? 'Cancel'}
                                </ButtonControl>}

                            {(onPrevious && isPrevious) &&
                                <ButtonControl loading={loading} type={'okay'} className={'dialog-btn dialog-btn-previous'} onClick={onPrevious}>
                                    {'Previous'}
                                </ButtonControl>}
                            {((nextText || onNext) && !isSave && !customNext) &&
                                <ButtonControl disabled={!enableNext} loading={loading} type={'okay'} className={'dialog-btn'} onClick={onNext}>
                                    {nextText ?? 'Next'}
                                </ButtonControl>}
                            {(((nextText || onNext) && customNext) && !isSave) &&
                                <ButtonControl disabled={!enableNext} loading={loading} type={'okay'} className={'dialog-btn'} onClick={handleOnNext}>
                                    {nextText ?? 'Next'}
                                </ButtonControl>}
                            {(isSave && onSave) &&
                                <ButtonControl disabled={saveDisabled} loading={loading} type={'okay'} className={'dialog-btn'} onClick={onSave}>
                                    {'Save'}
                                </ButtonControl>
                            }
                            {(customSave && onSave) &&
                                <ButtonControl disabled={saveDisabled} loading={loading} type={'okay'} className={'dialog-btn'} onClick={handleOnSave}>
                                    {'Save'}
                                </ButtonControl>
                            }
                            {(isNo) &&
                                <ButtonControl loading={customYes ? false : loading} type={'okay'} className={'dialog-btn'} onClick={onNo}>
                                    {'No'}
                                </ButtonControl>
                            }
                            {(isYes && !customYes) &&
                                <ButtonControl loading={loading} type={'okay'} className={'dialog-btn'} onClick={onYes}>
                                    {'Yes'}
                                </ButtonControl>
                            }
                            {(isConfirm) &&
                                <ButtonControl loading={loading} type={'okay'} className={'dialog-btn'} onClick={handleOnYes}>
                                    {'Confirm'}
                                </ButtonControl>
                            }
                            {(customYes && onYes) &&
                                <ButtonControl loading={loading} type={'okay'} className={'dialog-btn'} onClick={handleOnYes}>
                                    {'Yes'}
                                </ButtonControl>
                            }
                            {(customCenterText || onCustomCenter) &&
                                <ButtonControl type={customCenterType ?? 'okay'} loading={loading || loadingCustomCenter} disabled={disableOk || disabledCustomCenter} className={'dialog-btn'} onClick={handleOnCustomCenter}>
                                    {customCenterText ?? 'Custom'}
                                </ButtonControl>}
                            {(okText || onOk) &&
                                <ButtonControl loading={loading || loadingOk} disabled={disableOk} type={'okay'} className={'dialog-btn'} onClick={handleOnOk}>
                                    {okText ?? 'Ok'}
                                </ButtonControl>}

                        </div>
                    }
                </div>
            </div></>
        }
    </>
}