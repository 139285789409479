

const workercode = () => {

    const processFile = (file, fileId, serviceConfig, isPublic, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            if (callback) {
                callback(JSON.parse(this.responseText));
            }
        };

        xhr.open("POST", `${serviceConfig.url}/${fileId ?? ''}?isPublic=${isPublic ? '1' : '0'}`, false);
        xhr.setRequestHeader("Cache-Control", "no-cache");
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.setRequestHeader("Content-Type", file.type)
        xhr.setRequestHeader("X-File-Name", file.name);
        xhr.setRequestHeader("Authorization", "Bearer " + serviceConfig.accessToken);
        xhr.setRequestHeader("Client_Id", serviceConfig.clientId);
        xhr.setRequestHeader("C-DX-App-Id", serviceConfig.appId);
        let formData = new FormData()
        formData.append('file', file);
        xhr.send(formData);
    };

    self.onmessage = function (e) {/* eslint-disable-line no-restricted-globals */
        processFile(e.data.file, e.data.fileId, e.data.serviceConfig, e.data.isPublic, (r) => {
            self.postMessage(r);/* eslint-disable-line no-restricted-globals */
        });
    }
}


let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));
const blob = new Blob([code], { type: "application/javascript" });
const fileUploadWorkerScript = URL.createObjectURL(blob);

export { fileUploadWorkerScript };