import _ from 'lodash';
import React from 'react';
import { useState, useEffect } from 'react';
import { DialogControl } from '../controls/DialogControl';
import ContactUsDialog from './ContactUsDialog';
import Footer from './Footer';
import Header from './Header';
import './layouts.scss';

const MainLayout = ({ validationErrors, errors, messages, className, children, hideErrorCloseBtn, onMessageClosed }) => {

    const [showVidationDialog, setShowValidationDialog] = useState(false);

    useEffect(() => {
        if (validationErrors?.length > 0) {
            setShowValidationDialog(true);
        }
    }, [validationErrors]);

    const [showErrorDialog, setShowErrorDialog] = useState(false);
    useEffect(() => {
        if (errors?.length > 0) {
            setShowErrorDialog(true);
        }
    }, [errors]);

    const [showMessageDialog, setShowMessages] = useState(false);
    useEffect(() => {
        if (messages?.length > 0) {
            setShowMessages(true);
        }
    }, [messages]);

    return (<>
        <DialogControl disableXButton={true} openDialog={showVidationDialog} title={`Error${validationErrors?.length > 1 ? 's have' : ' has'} occurred`} subTitle={`${validationErrors?.length} validation error${validationErrors?.length > 1 ? 's have' : ' has'}   occurred.`} onOk={() => { setShowValidationDialog(false) }} okText={'Close'} >
            <div className="dialog-errors">
                {typeof validationErrors === 'string' ? validationErrors : _.map(validationErrors ?? [], (errorMsg) => <div className='dialog-errors-item'>{errorMsg}</div>)}
            </div>
        </DialogControl>

        <DialogControl disableXButton={true} openDialog={showMessageDialog} title={`Success`} onOk={() => { setShowMessages(false); if (onMessageClosed) onMessageClosed() }} okText={'Close'} >
            <div className="dialog-messages">
                {typeof messages === 'string' ? messages : _.map(messages ?? [], (msg) => <div className='dialog-message-item'>{msg}</div>)}
            </div>
        </DialogControl>

        <DialogControl disableXButton={true} openDialog={showErrorDialog} title={`Error${errors?.length > 1 ? 's have' : ' has'} occurred`} subTitle={`${errors?.length} error${errors?.length > 1 ? 's have' : ' has'}  has occurred.`}
            okText={!hideErrorCloseBtn ? 'Close' : ''}
            onOk={!hideErrorCloseBtn ? () => { setShowErrorDialog(false) } : ''} >
            <div className="dialog-errors">
                {typeof errors === 'string' ? errors : _.map(errors ?? [], (errorMsg) => <div className='dialog-errors-item'>{errorMsg}</div>)}
            </div>
        </DialogControl>

        <ContactUsDialog />
        <Header />
        {
            className ?
                <div className={className}>{children}</div>
                :
                <div className={`screen`}>
                    <div className={`screen__content`}>
                        {children}
                    </div>
                </div>
        }
        <Footer />
    </>
    );
}

export default MainLayout;


