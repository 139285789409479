import '../dev.scss';
import React, { useState, useEffect } from 'react';
import {useAuthState} from '../../../hooks/useAuth';
import { MediaItemService } from '../../../services/ItemServices';
import BitmovinPlayer from '../../contentLibrary/app-components/Content/Player/BitmovinPlayer';

const Media = () => {

  const  globalAuthState  = useAuthState();
  const [playerSrc, setPlayerSrc] = useState(null);
  
  useEffect(() => {
    const mediaItemService = new MediaItemService(globalAuthState);
    let url = null;
    mediaItemService.mediaServiceStream("f1da44e7-a9bb-42b7-8a57-5ff3448f30e6") //TODO: Obviously we want to be able to pull more than one thing and be able to select what we want to pull. This is just a proof of concept
        .then(data => {
            url = data[0].Items[0].Urls.STANDARD;
            setPlayerSrc({"src": url, type: 'application/vnd.ms-sstr+xml'});
        })
        .catch(err => console.log(err));

  },[]);
  
  return (
    <div className="media-wrapper">
       <div>{"Media Page"}</div>
       {playerSrc !== null ? 
          <div className='video-player'>
          <div id='player-wrapper'>
              <BitmovinPlayer content={playerSrc} />
          </div>
      </div>
      : <div className={"empty-media-screen"}></div>}
    </div>
  );
}

export default Media;

