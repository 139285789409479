const NotificationValidateObject = () => {
    return {
        Title: {
            fieldName: 'Title',
            messageTitle: 'title',
            isRequired: true,
            dataType: 'STRING',//IMAGE, INT, DEC, SELECT,BOOL,FILE
            groupId: 'NOTIFICATION',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
        HtmlValue: {
            fieldName: 'HtmlValue',
            messageTitle: 'message',
            isRequired: true,
            dataType: 'STRING',//IMAGE, INT, DEC, SELECT,BOOL,FILE
            groupId: 'NOTIFICATION',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
    }
}

export default NotificationValidateObject;