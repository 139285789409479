const axios = require('axios');
class BaseService {

    constructor() {
    }

    _handleResult(r) {
        let result = null;
        if (r === undefined || r.headers["content-type"].indexOf("text/html") > -1) {
            result = {
                data: null,
                errorMessages: ["A fatal error occurred : Please contact your system administrator."],
                errorMessage: "A fatal error occurred.",
                isSuccessful: false,
                UniqueId: "00000000-0000-0000-0000-000000000000",
                isError: true
            };
        }
        else {
            let resultData = JSON.parse(r.data);
            result = {
                data: resultData.Data,
                errorMessages: resultData.ErrorMessages,
                errorMessage: resultData.ErrorMessage,
                isSuccessful: resultData.IsSuccessful,
                UniqueId: resultData.UniqueId,
                isError: false
            };
        }

        return result;
    }

    _handleCatch(ex) {
        return {
            data: null,
            errorMessages: [ex.message],
            errorMessage: "A fatal error occurred.",
            isSuccessful: false,
            isError: true
        };
    }

    defaultFailResult() {
        return {
            data: null,
            errorMessages: [],
            errorMessage: null,
            isSuccessful: false,
            isError: false
        };
    }

    hostUri() {
        return window.location.protocol + "//" + window.location.host;
    }

    fullPath(lastPart) {
        return this.hostUri() + "/FrontEnd/" + lastPart
    }

    async AxiosImageCheck(url) {
        let result = null;
        await axios.get(url)
            .catch(() => {
                result = false;
            }).then(r => {
                result = r.headers['content-type'].indexOf('image') > -1;
            });
        return result;
    }

    async AxiosGet(url, params) {
        let result = null;
        await axios.get(url, { params: params })
            .catch((ex) => {
                result = this._handleCatch(ex);
            }).then(r => {
                result = this._handleResult(r);
            });
        return result;
    }

    async AxiosPost(url, data, errorCallback) {
        let result = null;
        await axios.post(url, data)
            .catch((ex) => {
                result = this._handleCatch(ex);
            }).then(r => {
                result = this._handleResult(r);
            });
        return result;
    }
}

export default BaseService;