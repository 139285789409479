import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { FormatUTCDate } from "../../../../utilities/DateFormater";



const ClusterTable = ({ filteredItems, onEdit, onDelete, isDrilledDown }) => {
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Name',
            dataProp: "Name",
            width: '30%',
            canSort: true
        },
        {
            header: "Organization",
            dataProp: "OrganizationName",
            width: '15%',
            canSort: true,
            hideData: isDrilledDown && true
        },
        {
            header: "District",
            dataProp: "DistrictName",
            width: '15%',
            canSort: true,
            hideData: isDrilledDown && true
        },
        {
            header: "School",
            dataProp: "SchoolName",
            width: '15%',
            canSort: true,
            hideData: isDrilledDown && true
        },
        {
            header: 'Active?',
            dataProp: 'IsActive',
            width: '8%',
            datatype: 'bool',
            isBoolean: true,
            canSort: true
        },
        {
            header: 'Created Date',
            dataProp: 'CreatedDate',
            width: '12%',
            datatype: 'date',
            canSort: true
        }
    ]);

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (c) => {
                newRows.push({
                    ...c,
                    CreatedDate: FormatUTCDate(c.CreatedDate),
                    OrganizationName: c?.Organization.Name,
                    DistrictName: c?.District.Name,
                    SchoolName: c?.School.Name,

                });
            });
            setRows(newRows);
        }
    }, [filteredItems]);


    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                permission='Cluster'
                useIntegratedPaging={['10', '25', '50', '100']}
                onEdit={onEdit}
                onDelete={onDelete}
            />
        </div>
    </>
}

export default ClusterTable;