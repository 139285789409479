import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import Manager from './Manager';
import ItemManager from './ItemManager';
import _ from 'lodash';

class ContentMyDashboardManager extends Manager {
  constructor(globalAuthState, globalAppUserState) {
    super(globalAuthState, globalAppUserState);
    this._globalAuthState = globalAuthState;

    this._contentItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Content, globalAuthState, globalAppUserState);
  }

  async SearchContent() {
    const res = await this._contentItemMgr.runOperation('SearchContent', undefined, { myd: true });
    if (res?.Success) {
      const unmodifiedContent = res?.Items;
      // we need to modify the content for it to show in the sliders
      const modifiedContent = unmodifiedContent.map((content) => {
        return { ...content, thumbUrl: content.ThumbnailFileUrl, imgUrlLarge: content.ThumbnailFileUrl };
      });
      return modifiedContent;
    } else {
      return null;
    }
  }

}

export default ContentMyDashboardManager;