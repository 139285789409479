import React, { useEffect, useState } from 'react';
import { useContentManager } from '../../../../../hooks/useManagers';
import BitmovinPlayer from './BitmovinPlayer.js';
import './index.scss';

export default function Player({ content, onVideoEnded, useBitmovinPlayer }) {
    const [playerSrc, setPlayerSrc] = useState(null);
    const [videoEnded, setVideoEnded] = useState(false);
    const [bitmovinPlayer, setBitmovinPlayer] = useState(false);
    const contentMgr = useContentManager();
    const fileUrl = contentMgr?.SelectedContent?.FileUrl;

    useEffect(() => {
        if (fileUrl) {
            setPlayerSrc([{ src: fileUrl, type: "application/vnd.ms-sstr+xml" }]);
        }
    }, [fileUrl]);

    useEffect(() => {
        if (videoEnded && onVideoEnded) {
            onVideoEnded(content);
        }
    }, [videoEnded]);

    useEffect(() => {
        setBitmovinPlayer(useBitmovinPlayer);
    }, [useBitmovinPlayer])

    return (
        <>
            <div className="videoPlayerFrame">
                {(playerSrc !== null && (playerSrc !== false)) && (
                    <>
                        <>
                        <div className='video-player'>
                            <div id='player-wrapper'>
                                <BitmovinPlayer content={content}/>
                            </div>
                        </div>
                        </> 
                    </>
                )}
                
            </div>

        </>
    );
}
