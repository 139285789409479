import React, { useEffect, useContext, useState } from 'react';
import { msalConfig } from '../configs/authConfig';
import { MsalProvider } from '@azure/msal-react';
import config from '../config.json';
import * as msal from "@azure/msal-browser";


//Wrapper Contexts
const AuthenticationContext = React.createContext();

export function AuthenticationProvider({ children }) {

    let authConfig = {};
    let authInstance = {};


    if (config.AUTH.AUTHENTICATION_PROVIDER === 'AZUREB2C') {
        authConfig = msalConfig;
        authInstance = new msal.PublicClientApplication(authConfig);
    }

    if (config.AUTH.AUTHENTICATION_PROVIDER === 'AZUREB2C') {

        return (
            <AuthenticationContext.Provider value={config.AUTH.AUTHENTICATION_PROVIDER}>
                <MsalProvider instance={authInstance}>
                    {children}
                </MsalProvider>
            </AuthenticationContext.Provider>
        )
    }
    else {
        return (
            <div>{"Please configure an authentication provider! See README for instructions!"}</div>
        )
    }
}