import _ from 'lodash';

export function getPieOption(data, selectedId) {
    // data.Data[0]
    const idToUse = selectedId ?? data.selectedId
    const chartData = _.find(data.Data, x => idToUse === x.first().GroupById)
    return {
        title: {
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'horizontal',
            bottom: 0,
            selectedMode: false,
            show: false
        },
        series: [
            {
                top: '10%',
                type: 'pie',
                radius: '50%',
                data: _.map(_.sortBy(chartData, x => x.Value1Name), x => {
                    return {
                        value: x.Value1, name: x.Value1Name
                    }
                }),
                label: {
                    // show: false,
                    formatter: (x) => { return x.name.substring(x.name.indexOf('(') + 1, x.name.length) }
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            },
        ]
    }
}


