import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonControl from '../../../components/controls/ButtonControl';
import MainLayout from '../../../components/layout/MainLayout';
import TagDetails from '../components/tag/details';
import TagFilter from '../components/tag/filter';
import TagTable from '../components/tag/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useAppUserManager, useImportExportManager, useTagManager } from '../../../hooks/useManagers';
import { useHookstate } from '@hookstate/core';
import globalTagStore from '../../../stores/globalTagStore';
import { DialogControl } from '../../../components/controls/DialogControl';
import _ from 'lodash';
import SystemTypes from '../../../services/SystemTypes';
import Import from '../components/importExport/import';

const TagMgmt = () => {
    const tagMgr = useTagManager();
    const [manageMode, setManageMode] = useState('list');
    const [loadingError, setLoadingError] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [errors, setErrors] = useState(null);
    const [messages, setMessages] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filteredTags, setFilteredTags] = useState([]);
    const [tagToDelete, setTagToDelete] = useState(null);
    const [deleteError, setDeleteError] = useState(null);

    const appUserMgr = useAppUserManager();
    const importExportMgr = useImportExportManager();
    const [exportLoading, setExportLoading] = useState(false);

    const params = useParams();
    const [tagId, setTagId] = new useState(params?.tagId === undefined || params?.tagId?.toLowerCase() === 'addnew' ? null : params?.tagId);
    const navigate = useNavigate();

    useEffect(() => {
        if (tagMgr) {
            setLoading(true)
            tagMgr.SearchTags().then(tags => {
                setFilteredTags(tags)
                setLoading(false)
            });
        }
    }, [tagMgr]);


    useEffect(() => {
        if (params?.tagId?.toLowerCase() === 'addnew') {
            setManageMode('add');
            setTagId(undefined);

        }
        else if (params?.tagId) {
            setManageMode('edit');
            setTagId(params?.tagId);
        }
        else {
            setManageMode('list');
            setTagId(undefined);
        }
    }, [params?.tagId])

    useEffect(() => {
        //Removing this will cause white screen / error.
        //TODO: Solve actual problem
    }, [tagMgr?.ParentTags]);


    const handleOnFilterChange = async (field, value) => {

        const tagFilter = tagMgr.TagFilter;
        const updatedTagFilter = { ...tagFilter };

        if (field.toLowerCase() === 'search') {
            updatedTagFilter.Search = value !== '' ? value : null;
        }
        else if (field.toLowerCase() === 'parenttagids') {
            updatedTagFilter.ParentTagIds = value;
        }
        else if (field.toLowerCase() == 'isactive') {
            if (value === '1')
                updatedTagFilter.IsActive = true;
            else if (value == '0')
                updatedTagFilter.IsActive = false;
            else
                updatedTagFilter.IsActive = undefined;
        }
        else if (field.toLowerCase() == 'issecure') {
            if (value === '1')
                updatedTagFilter.IsSecure = true;
            else if (value == '0')
                updatedTagFilter.IsSecure = false;
            else
                updatedTagFilter.IsSecure = undefined;
        }
        setFilteredTags(await tagMgr.FilterTags(updatedTagFilter));
    }

    const handleOnDelete = (item) => {
        setTagToDelete(item);
    }

    const handleOnDeleteItem = async () => {
        const result = await tagMgr.DeleteItem(tagToDelete.Id);
        if (result.Success) {
            setFilteredTags(result.Items);
            setTagToDelete(null);
        }
        else {
            return {
                fail: true,
                messages: [`Failed to delete ${tagToDelete.Name}. `, result.Message.DisplayMessage]
            }
        }
    }

    const handleOnEdit = (id) => {
        navigate(`/admin/tag/${id}`);
    }


    const handleOnCancel = () => {
        navigate('/admin/tag');
        setManageMode('list');
        setLoadingError(null);
        setValidationErrors(null);
        setErrors(null);
    }

    const handleOnSave = async (result) => {
        navigate('/admin/tag');
        setManageMode('list');
        const updatedFilteredTags = await tagMgr.FilterTags(tagMgr.TagFilter, result?.Items?.first());
        setFilteredTags(updatedFilteredTags);
        setLoadingError(null);
        setValidationErrors(null);
        setErrors(null);
    }

    const handleOnExport = async () => {
        const idList = filteredTags.map(x => x.Id);
        setExportLoading(true);
        const result = await importExportMgr.ExportData(SystemTypes.ImportExportType.ExportTags, idList);
        if (!result.Success) {
            setExportLoading(null);
            setErrors(["Failed to export Tags, if this issue continues please contact NIET support."]);
        }
        else {
            importExportMgr.downloadFile(result.Items.first()).then(r => {
                setExportLoading(false);
            }).catch(e => {
                setExportLoading(false);
                setErrors(["Tag export has been created and failed to download. Please check Import/Export Admin."]);
            });
        }
        return result;
    }


    const handleOnCompleteImport = async (success) => {
        if (success) {
            const tags = await tagMgr.SearchTags()
            const updatedFilteredTags = await tagMgr.filterData(undefined, tags);
            setFilteredTags(updatedFilteredTags);
            setMessages([<h5>One or more tags Successfully loaded.</h5>]);
        }
    }

    return (<>
        <MainLayout errors={errors} messages={messages} validationErrors={validationErrors} >
            <DialogControl openDialog={tagToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete ${tagToDelete?.Name}?`} onCancel={() => setTagToDelete(null)} onOk={handleOnDeleteItem} okText={'Confirm'}>
                {deleteError && <div className='error'>
                    {deleteError}
                </div>}
            </DialogControl>
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' &&
                    <></>
                }
                {manageMode === 'edit' &&
                    <div className='screen-header'>
                        <h3>Tag Management</h3>
                        <h5>Edit Tag</h5>
                    </div>
                }
                {manageMode === 'add' &&
                    <div className='screen-header'>
                        <h3>Tag Management</h3>
                        <h5>Add New Tag</h5>
                    </div>
                }
                {manageMode === 'list' ?
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <TagFilter avaliableFilters={{ ...tagMgr?.AvaliableFilters }} onFilterChange={handleOnFilterChange} />
                            <TagTable filteredItems={filteredTags} onDelete={handleOnDelete} onEdit={handleOnEdit} />
                        </div>
                        <div className='screen-footer list'>
                            {tagMgr?.CanWrite && <>< ButtonControl type={'create'} onClick={() => { navigate('/admin/tag/addnew') }}>Create Tag</ButtonControl>&nbsp;</>}
                            {tagMgr?.CanView && appUserMgr?.canView(appUserMgr?.AppUserState?.permissions.ExportTags) && <><ButtonControl loading={exportLoading} type={'cancel'}
                                onClick={handleOnExport}>
                                Export Tags
                            </ButtonControl>&nbsp;&nbsp;</>}
                            {tagMgr?.CanWrite && appUserMgr?.canView(appUserMgr?.AppUserState?.permissions.ImportTags) && <Import importDisplayName={'Tag'} setErrors={setErrors} setMessages={setMessages} onComplete={handleOnCompleteImport} importExportType={SystemTypes.ImportExportType.ImportTags} />}
                        </div>
                    </div>
                    :
                    <TagDetails parentTags={tagMgr?.ParentTags} tagId={tagId} onSave={handleOnSave} onCancel={handleOnCancel} onValidationErrors={setValidationErrors} onLoading={setLoading} onErrors={setErrors} onLoadingError={setLoadingError} />
                }

            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default TagMgmt;