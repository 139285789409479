import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { ReactComponent as GroupOrganization } from '../assets/images/GroupOrganization.svg';
import { ReactComponent as GroupDistrict } from '../assets/images/GroupDistrict.svg';
import { ReactComponent as GroupSchool } from '../assets/images/GroupSchool.svg';
import { faArrowsUpDownLeftRight } from '@fortawesome/free-solid-svg-icons';
import ChartControl from "../../../components/controls/ChartControl/ChartControl";
import { useRef } from 'react';
import { useEffect } from 'react';
import _ from 'lodash';
import { useMemo } from 'react';
import SystemTypes from '../../../SystemTypes';
import { useImportExportManager } from '../../../hooks/useManagers';
import Loading from '../../../components/Loading';
import { SelectListControl } from '../../../components/controls';
import { InputField } from '../../../components/controls/InputField';

const AnalyticsChart = ({ id, size, type, styles, updateGrid, data, editingLayout, handleEdit, handleDelete, setErrors, failToLoad }) => {
    const [actionOpen, setActionOpen] = useState(false);
    const [draggedItem, setDraggedItem] = useState({
        id: null,
        middleX: null,
        middleY: null,
    })
    const [dragStyle, setDragStyle] = useState(null)
    const [offsetX, setOffsetX] = useState(null)
    const [offsetY, setOffsetY] = useState(null)
    const [exportLoading, setExportLoading] = useState(false)
    const [chartKeys, setChartKeys] = useState(null)
    const [selectedKey, setSelectedKey] = useState(null)
    const itemRef = useRef(null)

    const importExportMgr = useImportExportManager();

    useEffect(() => {
        if (data && data.keys) {
            setChartKeys(_.sortBy(data.keys, x => x.text))
            setSelectedKey(data.selectedId)
        }
    }, [data])
    const handleActionOpen = () => {
        setActionOpen(!actionOpen);
    }

    const getGroupByTitle = (title) => {
        return oxford(data?.[`${title.toLowerCase()}`]);
    }

    const oxford = (arr) => {
        arr = arr.filter(x => x !== null || x !== undefined);
        let l = arr.length;
        if (!arr || arr.length == 0)
            return "";
        else if (l === 1)
            return arr[0];
        else if (l == 2)
            return arr.join(` and `);
        else if (l > 2) {
            arr = arr.slice(0, 1);
            arr.push(`and ${l - 1} others`);
            return arr.join(", ");
        }
    }

    const handleDragDetails = () => {
        const itemBox = itemRef.current.getBoundingClientRect();
        let parentTopScroll = itemRef.current.parentNode
        while (parentTopScroll.className !== 'analytics') {
            parentTopScroll = parentTopScroll.parentNode;
        }
        //72 is header height.
        //Account for 2x2 since it consumes gap as its height.
        let itemBoxHeight = itemBox.height
        let itemBoxWidth = itemBox.width
        if (size === 3) {
            itemBoxHeight -= 36
        }
        if (size === 3 || size === 2) {
            itemBoxWidth -= 36
        }
        const itemMiddleX = (itemBoxWidth / 2) + itemBox.x - itemRef.current.offsetParent.offsetLeft
        const itemMiddleY = (itemBoxHeight / 2) + (itemBox.y + parentTopScroll.scrollTop) - (itemRef.current.offsetParent.offsetTop + 72)
        const draggedItemCopy = { ...draggedItem }
        draggedItemCopy.middleX = itemMiddleX
        draggedItemCopy.middleY = itemMiddleY
        //Handles if middle of item passes intersections.
        //24 is middle of gap
        const xIntersect = 336;
        const yIntersect = 376 + 24;
        return {
            itemBox: itemBox,
            itemRef: itemRef,
            parentTopScroll: parentTopScroll.scrollTop,
            initalX: itemBox.left - itemRef.current.offsetParent.offsetLeft,
            initalY: (itemBox.top + parentTopScroll.scrollTop) - (itemRef.current.offsetParent.offsetTop + 72),
            rowIndex: Math.floor(itemMiddleY / xIntersect),
            colIndex: Math.floor(itemMiddleX / yIntersect)
        }
    }

    const handleDragStart = (e) => {
        if (editingLayout) {
            e.dataTransfer.setDragImage(new Image(), 0, 0)
            const { clientX, clientY } = e;
            const { rowIndex, colIndex, itemBox, initalX, initalY, itemRef, parentTopScroll } = handleDragDetails();
            setOffsetX(clientX - (itemBox.left - itemRef.current.offsetParent.offsetLeft))
            setOffsetY(clientY - ((itemBox.top + parentTopScroll) - itemRef.current.offsetParent.offsetTop - 72))
            setDraggedItem({ id: id })
            setActionOpen(null)
            setDragStyle({
                position: "absolute",
                width: itemBox.width,
                height: itemBox.height,
                top: initalY,
                left: initalX,
                cursor: 'grab',
                zIndex: 100,
            })
        }
    }

    const handleDrag = (e) => {
        if (editingLayout) {
            const { rowIndex, colIndex } = handleDragDetails();
            updateGrid(rowIndex, colIndex, data)
            if (e.clientX > 0 && e.clientY > 0) {
                setDragStyle({ ...dragStyle, left: e.clientX - offsetX, top: e.clientY - offsetY, transition: 'unset', })
            }
        }
    }
    const handleDragEnd = (e) => {
        if (editingLayout) {
            e.preventDefault()
            const { rowIndex, colIndex } = handleDragDetails();
            updateGrid(rowIndex, colIndex, data, true)
            setDragStyle(null)
        }
    }

    const memoizedChart = useMemo(() => <ChartControl
        type={type}
        data={data}
        selectedId={selectedKey}
        fixedHeight={"100%"}
        fixedWidth={"100%"}
    />, [data, selectedKey])


    const handleOnClick = (e) => {
        const { rowIndex, colIndex } = handleDragDetails();
        updateGrid(rowIndex, colIndex, data, true)
    }

    const dropdownRefs = useRef([]);

    const handleDocumentClick = (e, setState) => {
        if (dropdownRefs?.current && dropdownRefs?.current?.getBoundingClientRect) {
            const { clientX, clientY } = e;
            const box = dropdownRefs?.current?.getBoundingClientRect();
            if (box) {
                if (box.left !== 0 && box.right !== 0 && box.top !== 0 && box.bottom !== 0) {
                    const insideBox = (clientX > box.left && clientX < box.right && clientY > box.top - 31 && clientY < box.bottom);
                    if (!insideBox) {
                        setState(null);
                    }
                }
            }

        }
    }
    const handleExport = async (data) => {
        const chartData = {
            SchoolYearIdentifier: data.SchoolYearIdentifier,
            BeginDate: data.BeginDate,
            EndDate: data.EndDate,
            AnalyticType: data.AnalyticType,
            ClientIds: data.ClientIds,
            DistrictIds: data.DistrictIds,
            SchoolIds: data.SchoolIds,
            ClusterIds: data.ClusterIds,
            GroupBy: data.GroupBy,
        }
        setExportLoading(true);
        const result = await importExportMgr.ExportDataAnalytics(JSON.stringify(chartData));
        if (!result.Success) {
            setExportLoading(null);
            setErrors(["Failed to export Chart data, if this issue continues please contact NIET support."]);
        }
        else {
            importExportMgr.downloadFile(result.Items.first()).then(r => {
                setExportLoading(false);
            }).catch(e => {
                setExportLoading(false);
                setErrors(["Chart data export has been created and failed to download."]);
            });
        }
        return result;
    }

    useEffect(() => {
        document.addEventListener('click', (e) => handleDocumentClick(e, setActionOpen));
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        }
    }, []);

    const updatePreviewChart = (value) => {
        setSelectedKey(value)
    }
    return (
        <div
            ref={itemRef}
            draggable
            onDragStart={(e) => handleDragStart(e)}
            onDrag={(e) => handleDrag(e)}
            onDragOver={(e) => e.preventDefault()}
            onDragEnd={handleDragEnd}
            onClick={handleOnClick}
            className={"analytics-item " + (size === 3 ? " size-3 " : size === 2 ? " size-2 " : " size-1 ") + (editingLayout ? ' draggable ' : '') + (actionOpen ? " analytics-item-dropdown-active " : "")}
            data-key={id}
            key={id}
            style={dragStyle ? dragStyle : _.find(styles, x => x.Id === id)?.style}>
            <div className="analytics-item-header">
                {/* REVISIT */}
                <h4 className="analytics-item-title">{data?.Name}</h4>
                <div className={"analytics-item-actions" + (!editingLayout ? " analytics-item-actions-editing" : "")}>
                    <div
                        className="analytics-item-icon-button"
                        onClick={handleActionOpen}
                    >
                        <FontAwesomeIcon icon={faEllipsis} />
                    </div>
                    <div
                        ref={dropdownRefs}
                        className={`analytics-item-dropdown ${actionOpen ? "active" : ""}`}
                    >
                        {editingLayout && <div className="analytics-item-dropdown-item" onClick={() => handleEdit(data)}>Edit</div>}
                        {exportLoading ? <Loading size={'1.5rem'} /> : <div className="analytics-item-dropdown-item" onClick={() => handleExport(data)}>Export</div>}
                        {editingLayout && <div className="analytics-item-dropdown-item" onClick={() => handleDelete(data)}>Delete</div>}
                    </div>
                </div>
            </div>
            <div className="analytics-item-groups">
                <div className="analytics-item-group-top-container">
                    {data?.organizations?.length > 0 &&
                        <div className="analytics-item-group">
                            <GroupOrganization />
                            <div className="analytics-item-group-title">{getGroupByTitle("organizations")}</div>
                        </div>
                    }
                    {data?.districts?.length > 0 &&
                        <div className="analytics-item-group">
                            <GroupDistrict />
                            <div className="analytics-item-group-title">{getGroupByTitle("districts")}</div>
                        </div>
                    }
                </div>
                {data?.schools?.length > 0 &&
                    <div className="analytics-item-group">
                        <GroupSchool />
                        <div className="analytics-item-group-title">{getGroupByTitle("schools")}</div>
                    </div>
                }
            </div>
            <div className="analytics-item-chart">
                {((type === "gauge" || type == "pie") && !failToLoad) && <InputField
                    fieldName={'preview-chart'}
                    disableError={true}
                    value={selectedKey}
                    onChange={(value) => { updatePreviewChart(value) }}
                >
                    <SelectListControl hidePleaseSelect={true} textValuePairs={_.sortBy(chartKeys, x => x.text)} />
                </InputField>}
                {
                    failToLoad ? <div className="chart-error">
                        {failToLoad === 'No Data' ? `No data for ${type} chart` :
                            failToLoad === "Timeout" ? `Retrieving data timed out for ${type} chart ` :
                                `Error while retrieving ${type} chart`
                        }
                    </div>
                        :
                        type !== "gauge" && type !== "pie" ? memoizedChart : <>
                            <div className="analytics-item-charts-wrapper">
                                {memoizedChart}
                            </div>
                        </>
                }
            </div>
            {editingLayout && <FontAwesomeIcon className={'draggable-icon'} icon={faArrowsUpDownLeftRight} />}
        </div>
    );
};



export default AnalyticsChart;
