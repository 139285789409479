   
import _ from "lodash";
import { ItemServiceFactory } from "../services/ItemServiceFactory";
import ItemManager from "./ItemManager";
import Manager from "./Manager";


export default class ContentLibraryManager extends Manager {

    constructor(globalAuthState, globalAppUserState) {
        super(globalAuthState, globalAppUserState);
        this._globalAuthState = globalAuthState;

        this._contentItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Content, globalAuthState, globalAppUserState);
        this._contentRatingItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.ContentRating, globalAuthState, globalAppUserState);
        this._contentShareItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.ContentShare, globalAuthState, globalAppUserState);
        this._contentAssItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.ContentAssociation, globalAuthState, globalAppUserState);
        this._tagMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Tag, globalAuthState, globalAppUserState);
    }

    get ItemManager() {
        return this._contentItemMgr;
    }

    get RatingItemManager() {
        return this._contentRatingItemMgr;
    }

    get ShareItemManager() {
        return this._contentShareItemMgr;
    }

    get ContentAssItemManager() {
        return this._contentAssItemMgr;
    }

    async syncAssocatedContent(currentContentId, currentAssocatedContents) {
        const result = await this._contentAssItemMgr.list({
            "RootFilterNode": {
                "Variable": "x",
                "Name": "ContentId",
                "Method": "==",
                "Value": currentContentId,
                "IsConjunction": false
            }
        });
        const allPrm = [];
        if (result?.Success) {
            const assocatedContents = result.Items
            _.each(currentAssocatedContents, async ac => {
                const associatedContent = _.find(assocatedContents, x => x.AssociatedContentId === ac.AssociatedContentId);
                if (!associatedContent) {
                    allPrm.push(this._contentAssItemMgr.save({ ...ac, ContentId: currentContentId }));
                }
            });

            _.each(assocatedContents, async ac => {
                const currentAssocatedContent = _.find(currentAssocatedContents, x => x.AssociatedContentId === ac.AssociatedContentId);
                if (!currentAssocatedContent) {
                    allPrm.push(await this._contentAssItemMgr.delete(ac.Id, true));
                }
            });
        }

        return Promise.all(allPrm);
    }

    DefaultSearchContentModel() {
        return {
            ContentId: undefined,
            ContentTypeId: undefined,
            Title: null,
            SelectedTags: [],
            Description: null
        }
    }

    DefaultShareContentModel() {
        return {
            ContentId: 0,
            UserIds: [],
            NotifiyWhenComplete: false
        }
    }

    async TrendingContent() {
        return await this.ItemManager.runOperation("TrendingContent");
    }

    async SearchContent(searchContentModel, admin) {
        //Note: searchContentModel only content id is used, server side filter is not yet required.
        const contentId = searchContentModel?.ContentId;
        const result = await this.ItemManager.runOperation("SearchContent", contentId, undefined, admin === true ? { admin } : undefined, false);
        return result;
    }

    async SearchSharedContent(searchContentModel, admin) {
        //const contentId = searchContentModel?.ContentId;
        const result = await this._contentShareItemMgr.runOperation("SearchShareContent", undefined, undefined, admin === true ? { admin } : undefined, false);
        return result;
    }

    async SetContentViewed(ContentId, allContent) {
        const UserId = this._globalAppUserState.get({ noproxy: true }).userProfile.UserId;
        const content = _.find(allContent, (x) => x.ContentId === ContentId);
        //get/save in the background to increase performance of UI
        var item = null;
        if (!content?.HasViewed) {
            if (!content.ContentRatingIdForUser) {
                item = {
                    ContentId,
                    UserId,
                    HasViewed: true
                }

                if (content.SharedWithUser) {
                    this._contentShareItemMgr.runOperation('ShareContentComplete', content.Id);
                }
                return await this._contentRatingItemMgr.save(item);
            }
            else {
                item = {
                    Id: content.ContentRatingIdForUser,
                    ContentId,
                    UserId,
                    Rating: content.Rating,
                    StarRating: content.StarRating,
                    Recommended: content.RecommendedForUser,
                    HasViewed: true
                };

                if (content.SharedWithUser) {
                    this._contentShareItemMgr.runOperation('ShareContentComplete', content.Id);
                }
                return await this._contentRatingItemMgr.save(item);
            }
        }
        else {

            if (content.SharedWithUser) {
                this._contentShareItemMgr.runOperation('ShareContentComplete', content.Id);
            }
            return {
                Success: true
            };
        }
    }

    async ChangeContentRating(ContentId, allContent) {

        const UserId = this._globalAppUserState.get({ noproxy: true }).userProfile.UserId;
        const content = _.find(allContent, (x) => x.ContentId === ContentId);
        const Rating = content.HasUserRating === true ? 0 : 1;
        //get/save in the background to increase performance of UI
        var item = null;
        if (!content.ContentRatingIdForUser) {
            item = {
                ContentId,
                UserId,
                Rating,
            }
            return await this._contentRatingItemMgr.save(item);
        }
        else {

            item = {
                Id: content.ContentRatingIdForUser,
                ContentId,
                UserId,
                Rating,
                StarRating: content.StarRating,
                Recommended: content.RecommendedForUser,
                HasViewed: true
            };

            this._contentRatingItemMgr.save(item);
            return {
                Success: true,
                Items: [item]
            };
        }
    }

    async ChangeContentStarRating(ContentId, allContent, StarRating) {
        const UserId = this._globalAppUserState.get({ noproxy: true }).userProfile.UserId;
        const content = _.find(allContent, (x) => x.ContentId === ContentId);
        var item = null;
        if (!content.ContentRatingIdForUser) {
            item = {
                ContentId,
                UserId,
                StarRating
            }
            return await this._contentRatingItemMgr.save(item);
        }
        else {

            item = {
                Id: content.ContentRatingIdForUser,
                ContentId,
                UserId,
                Rating: content.Rating,
                StarRating: StarRating,
                Recommended: content.RecommendedForUser,
                HasViewed: true
            };

            this._contentRatingItemMgr.save(item);
            return {
                Success: true,
                Items: [item]
            };
        }
    }

    async ShareContent(shareContentConfig) {
        return this._contentShareItemMgr.runOperation('ShareContent', shareContentConfig.ContentId, shareContentConfig);
    }

    async RemoveShareContent(id) {
        return this._contentShareItemMgr.delete(id);
    }

    async GetShareContentConfig(contentId) {
        return this._contentShareItemMgr.runOperation('ShareContentConfig', contentId);
    }

    async GetRelatedContent(contentId) {
        return this._contentItemMgr.runOperation('ReleatedContent', contentId);
    }

    async GetContentPreferences(parentTagId, parentTagOther) {
        if (parentTagId || parentTagOther) {
            return await this._tagMgr.query("x => x.IsActive == true && x.IsDeleted == false && x.ParentTagId == parentTagId || x.ParentTagId == parentTagOther", { parentTagId, parentTagOther });
        }
        else {
            return await this._tagMgr.query("x => x.IsActive == true && x.IsDeleted == false && x.IsPreferenceRoot == true");
        }
    }

    GetViewUrl(keyId) {
        return "/content?k=" + keyId;
    }

}