import React, { useState } from 'react';
import MainLayout from '../../../components/layout/MainLayout';
import { useRubricManager } from '../../../hooks/useManagers';
import '../rubricList.scss';
import '../rubricDialog.scss';
import { useEffect } from 'react';
import ButtonControl from '../../../components/controls/ButtonControl';
import LabelControl from '../../../components/controls/LabelControl';
import RubricLayoutDialog from '../components/rubric/RubricLayoutDialog';
import RubricIndicatorSettingsDialog from '../components/rubric/RubricIndicatorSettingsDialog';
import RubricPerformanceLevelDialog from '../components/rubric/RubricPerformanceLevelDialog';
import RubricIndicatorTable from '../components/rubric/RubricIndicatorTable';
import RubricDialog from '../components/rubric/RubricDialog';
import evaluationTypeSet from '../../dev/data/evaluationTypeSet';
import { faPencil, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import RubricStatus from '../../dev/data/RubricStatus';
import Loading from '../../../components/Loading';
import { text } from '@fortawesome/fontawesome-svg-core';
import { textAlign } from '@mui/system';

const RubricIndicator = () => {
    const [rubricData, setRubricData] = useState();
    const [rubricRatingData, setRubricRatingData] = useState();
    const [evalData, setEvalData] = useState(evaluationTypeSet);
    const [rubricIndicatorData, setRubricIndicatorData] = useState();
    const [loading, setLoading] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openIndicatorDialog, setOpenIndicatorDialog] = useState(false);
    const [openPerformanceLevelDialog, setOpenPerformanceLevelDialog] = useState(false);
    const [openRubricLayoutDialog, setOpenRubricLayoutDialog] = useState(false);
    const [rubricType, setRubricType] = useState();
    const [rubricName, setRubricName] = useState();
    const [rubricStatus, setRubricStatus] = useState();
    const [rubricDomainData, setRubricDomainData] = useState();
    const [organization, setOrganization] = useState()
    const [dateUpdated, setDateUpdated] = useState();
    const [selectedIndicator, setSelectedIndicator] = useState();
    const [selectedDomain, setSelectedDomain] = useState('No domain assigned');
    const [selectedDomainForIndicatorSettings, setSelectedDomainForIndicatorSettings] = useState();
    const [performanceLevelData, setPerformanceLevelData] = useState();
    const [editPerformanceTitleInfo, setEditPerformanceTitleInfo] = useState();
    const [onLoading, setOnLoading] = useState(true);
    const [onLoadingError, setOnLoadingError] = useState();
    const [isEditRubric, setIsEditRubric] = useState(false);
    const [schoolYearData, setSchoolYearData] = useState();
    const [currentYear, setCurrentYear] = useState();
    const [organizations, setOrganizations] = useState();
    const [saveComplete, setSaveComplete] = useState();
    const [updatedRubricData, setUpdatedRubricData] = useState();
    const rubricMgr = useRubricManager();
    const navigate = useNavigate();
    const params = useParams();
    const [isPublished, setIsPublished] = useState(false);
    const [rubricId, setRubricId] = new useState(params?.rubricId === undefined ? null : params?.rubricId);
    const [domainId, setDomainId] = new useState(params?.domainId === undefined ? null : params?.domainId);

    useEffect(() => {
        if (rubricMgr) {
            let ignore = false;
            async function fetchData() {
                rubricMgr.SearchRubrics(rubricId).then((r) => {
                    var item = r?.first();
                    setRubricData(item);
                    //if not domain, open layout dialog
                    if (item.RubricDomainMap?.length === 0) {
                        handleOnLayout();
                    } else {
                        if (domainId) {
                            setSelectedDomain(domainId);
                        }
                        else {
                            let domainSequenceList = item.RubricDomainMap.map(x => x.Sequence);
                            let firstSequence = Math.min(...domainSequenceList);
                            let firstDomain = item.RubricDomainMap.find(x => x.Sequence === firstSequence);
                            setSelectedDomain(firstDomain.Id);
                        }
                    }

                    if (onLoadingError) {
                        setOnLoadingError(r?.Message.DisplayMessage);
                    }
                });
            }

            fetchData();
            return () => { ignore = true }; //cleanup
        }
    }, [rubricMgr]);

    useEffect(() => {
        if (rubricMgr?.RubricMetadata) {
            setRubricRatingData(rubricMgr?.RubricMetadata.Ratings);
            setRubricDomainData(rubricMgr?.RubricMetadata.Domains);
            setRubricIndicatorData(rubricMgr?.RubricMetadata.Indicators);
            let orgs = rubricMgr?.RubricMetadata.Clients.map(item => ({ label: item.DisplayName, value: item.ClientId }))
            let schoolYears = rubricMgr?.RubricMetadata.SchoolYears.sort(function (a, b) {
                return new Date(a.SchoolYearStart) - new Date(b.SchoolYearStart);
            });
            const schoolYearDataMap = schoolYears.map(item => ({ ClientId: item.Organization?.Id, label: getFormatedDate(item.SchoolYearStart) + (item.Organization ? ` - ${item.Organization.Name}` : ''), value: item.Id, identifier: item.SchoolYearIdentifier }));
            var currentSchoolYear = schoolYearDataMap.find(x => x.label.endsWith('(Current Year)'));
            setCurrentYear(currentSchoolYear);
            setSchoolYearData(schoolYearDataMap);
            setOrganizations(orgs);

        }
    }, [rubricMgr, rubricMgr?.RubricMetadata])

    useEffect(() => {
        if (rubricData && organizations && schoolYearData && rubricDomainData && rubricIndicatorData && rubricRatingData) {
            setOnLoading(false);
        }
    }, [rubricData, organizations, schoolYearData, rubricDomainData, rubricIndicatorData, rubricRatingData])

    useEffect(() => {
        if (params?.rubricId) {
            setRubricId(params?.rubricId);
        }
        else {
            setRubricId(undefined);
        }

    }, [params?.rubricId])

    useEffect(() => {
        if (rubricData) {
            let evalType = evalData.find(x => x.value === rubricData?.RubricType);
            let date = formatUpdatedDate(rubricData.UpdatedDate);
            let status = RubricStatus.find(status => status.Value === rubricData?.Status);
            setRubricStatus(status.Text.toUpperCase());
            setRubricType(evalType?.label);
            setRubricName(rubricData.Name)
            setDateUpdated(date);
            //TODO: where is Org saved to? may need to replace this
            if (!isEditRubric) {
                setOrganization(rubricData.CustomFields);
            }
            if (rubricData.CustomFields !== null) {
                setOrganization(rubricData.CustomFields);
            }
            setIsEditRubric(false);
        }
    }, [rubricData]);

    const getFormatedDate = (startDate) => {
        let date = new Date(startDate);
        var year = date.getFullYear();
        let isCurrentYear = year + 1 == rubricMgr?.AppUserState.currentSchoolYear;
        return year + '-' + (year + 1 + (isCurrentYear ? " (Current Year)" : ''));
    }

    const handleOnEditRubricDetails = () => {
        //Opens create rubric dialog, passing row as data source
        setOpenEditDialog(true);
    }

    const formatUpdatedDate = (date) => {
        var options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        var newDate = new Date(date);
        newDate = newDate.toLocaleDateString('en-US', options);

        return newDate;
    }

    const handleOnEdit = (row) => {
        if (row.Domain) {
            setOpenIndicatorDialog(true);
            setSelectedIndicator({ label: row.IndicatorId, value: row.Indicator, IndicatorAbbr: row.IndicatorAbbr });
            setSelectedDomainForIndicatorSettings({ Name: row.Domain, Id: row.DomainId });
        }
        else {
            //set opendialog for performance Level Description Dialog to TRUE
            let titleInfo = {
                Domain: row.rowTitle.Domain,
                Indicator: row.rowTitle.Indicator,
                IndicatorId: row.rowTitle.IndicatorId,
                RatingTitle: row.row.ratingTitle,
                RatingId: row.row.ratingId,
                Description: row.row.description,
                RubricIndicatorRatingId: row.row.rubricIndicatorRatingItemId
            };
            setOpenPerformanceLevelDialog(true);
            setPerformanceLevelData(titleInfo);
        }
    }

    const handleOnSave = async (indicator, domainId, shortName) => {
        setSaveComplete(false);

        let currentIndicator = rubricIndicatorData.find(x => x.Id === indicator);

        if (!currentIndicator) {
            let indicatorToSave = {
                Name: indicator,
                ShortName: shortName,
                RatingCount: rubricData.RubricRatingMap.length,
                ClientId: rubricData.ClientId,
            }
            const result = await rubricMgr.IndicatorItemManager.save(indicatorToSave);

            if (result.Success && result.Items) {
                let updatedRubricIndicatorData = [...rubricIndicatorData];
                updatedRubricIndicatorData.push(result.Items[0]);
                setRubricIndicatorData(updatedRubricIndicatorData);
                currentIndicator = result.Items[0];
            }
        }

        if (currentIndicator) {
            const mappedRubricIndicator = rubricData.RubricIndicatorMap.find(x => x.RubricIndicatorId === selectedIndicator.label);
            let mapResult;
            if (mappedRubricIndicator) {
                let indicatorMapItem = {
                    ...mappedRubricIndicator,
                    RubricIndicatorId: currentIndicator.Id,
                    RubricIndicator: null
                }
                mapResult = await rubricMgr.IndicatorMapItemManager.save(indicatorMapItem);
                if (mapResult?.Success) {
                    let newRubricData = { ...rubricData };
                    let newItem = mapResult.Items.first();
                    newRubricData.RubricIndicatorMap = newRubricData.RubricIndicatorMap.reduce((r, cv) => {
                        if (cv.Id === newItem.Id) {
                            r.push(newItem)
                        }
                        else {
                            r.push(cv);
                        }
                        return r;
                    }, [])
                    setRubricData(newRubricData);
                }
            }
            else {
                //find current set of mapIndicators
                let sequenceNumber = 1;
                let sequenceSet = rubricData.RubricIndicatorMap;

                if (sequenceSet.length > 0) {
                    let sequences = rubricData.RubricIndicatorMap.map(x => x.Sequence);
                    let maxSequence = Math.max(...sequences);

                    sequenceNumber = maxSequence + 1;
                }

                let indicatorMapItem = {
                    RubricId: rubricData.Id,
                    RubricIndicatorId: currentIndicator.Id,
                    RubricDomainId: domainId,
                    Sequence: sequenceNumber,
                    ClientId: rubricData.ClientId
                }
                mapResult = await rubricMgr.IndicatorMapItemManager.save(indicatorMapItem);
                if (mapResult?.Success) {
                    let newRubricData = { ...rubricData };
                    newRubricData.RubricIndicatorMap.push(mapResult.Items[0]);
                    setRubricData(newRubricData);
                }
            }

            setSaveComplete(true);
            setOpenPerformanceLevelDialog(false);
        }
    }

    const handleOnDescriptionSave = async (description, titleInfo) => {
        setSaveComplete(false);
        let indicatorRatingMapToSave = {
            Id: titleInfo.RubricIndicatorRatingId === "" ? undefined : titleInfo.RubricIndicatorRatingId,
            RubricId: rubricData.Id,
            RubricIndicatorId: titleInfo.IndicatorId,
            RubricRatingId: titleInfo.RatingId,
            Description: description,
            ClientId: rubricData.ClientId
        }

        const result = await rubricMgr.IndicatorRatingMapItemMgr.save(indicatorRatingMapToSave);
        if (result.Success && result.Items[0]) {
            //set new data into coorpsonding row
            rubricData.RubricIndicatorRatingMap.push(result.Items[0]);
            let newRubricData = { ...rubricData };
            setRubricData(newRubricData);
            setSaveComplete(true);
        }
    }

    const handleOnLayout = () => {
        setOpenRubricLayoutDialog(true);
    }

    const handleOnExit = () => {
        sessionStorage.removeItem('rubric-data');
        navigate('/admin/Rubric');
    }

    const handleDialogClose = () => {
        setOpenIndicatorDialog(false);
        setOpenPerformanceLevelDialog(false);
        setOpenRubricLayoutDialog(false);
        setOpenEditDialog(false);
    }

    const handleOnNavigate = (row) => {
        let domainToSendId = '';
        if (row.dataRecord) {
            domainToSendId = row.dataRecord.Id;
        }
        else {
            domainToSendId = row;
        }

        setSelectedDomain(domainToSendId);
        navigate(`/admin/RubricIndicator/${rubricData.Id}/${domainToSendId}`);
        handleDialogClose();
    }

    const handleOnRubricEdit = (rubric) => {
        setRubricData(rubric);
        setIsEditRubric(true);
    }

    const handleLayoutUpdate = (updatedRubricData) => {
        setRubricData(updatedRubricData);
    }

    const handleLayoutSaveRubricData = (updatedRubricData) => {
        setUpdatedRubricData({ ...updatedRubricData });
    }

    return (<>
        {rubricDomainData && <RubricLayoutDialog dialogState={openRubricLayoutDialog} dialogClose={handleDialogClose} data={rubricData} updateRubricData={handleLayoutSaveRubricData} dataUpdate={handleLayoutUpdate} domainDbData={rubricDomainData} excludeCancel={true} onNavigateRow={handleOnNavigate} currentDomain={selectedDomain} setSelectedDomain={setSelectedDomain} setRubricData={setRubricData} />}
        {performanceLevelData && <RubricPerformanceLevelDialog dialogState={openPerformanceLevelDialog} dialogClose={handleDialogClose} titleInfo={editPerformanceTitleInfo} data={performanceLevelData} onSave={handleOnDescriptionSave} saveComplete={saveComplete} />}
        {rubricIndicatorData && rubricData && <RubricIndicatorSettingsDialog dialogState={openIndicatorDialog} dialogClose={handleDialogClose} onSave={handleOnSave} rubricData={rubricData} indicator={selectedIndicator} domain={selectedDomainForIndicatorSettings}
            indicatorData={rubricIndicatorData}
            takenIndicators={
                rubricData?.RubricIndicatorMap?.reduce((r, cv) => {

                    if (cv.RubricIndicator.Name !== selectedIndicator?.value) {
                        r.push(cv.RubricIndicator.Name);
                    }
                    rubricIndicatorData.forEach(inD => {
                        if (rubricData?.RubricIndicatorMap?.reduce((r, cv) => {
                            if (cv.RubricIndicator.ShortName !== selectedIndicator?.IndicatorAbbr) {
                                r.push(cv.RubricIndicator.ShortName);
                            }
                            return r;
                        }, []).includes(inD.ShortName)) {
                            if (!r.includes(inD.Name)) {
                                if (inD.Name !== selectedIndicator?.value) {
                                    r.push(inD.Name);
                                }
                            }
                        }
                    })

                    return r;
                }, [])
            }
            takenIndicatorAbbreviations={
                rubricData?.RubricIndicatorMap?.reduce((r, cv) => {
                    r.push(cv.RubricIndicator.ShortName);
                    return r;
                }, [])
            }
            saveComplete={saveComplete} onNavigate={handleOnNavigate} />}
        {rubricData && organizations && schoolYearData && <RubricDialog dialogState={openEditDialog} dialogClose={handleDialogClose} onEdit={handleOnRubricEdit} editRubricData={rubricData} editClicked={true} schoolYears={schoolYearData} organizationDataSet={organizations} />}
        <MainLayout>
            {
                !onLoading ?
                    <div className="screen-wrapper">
                        <div className='control-box-wrapper'>
                            <div className='control-box-list'>
                                <div style={{ paddingBottom: '15px' }}>
                                    <div style={{ display: 'inline-block' }}>
                                        <div style={{ marginBottom: '5px' }}>
                                            <h4 style={{ display: 'inline-block' }}>
                                                <LabelControl field="RubricName" value={rubricName}></LabelControl>
                                            </h4>
                                            <LabelControl className={`status-label ${rubricStatus === 'PUBLISHED' ? 'status-label-published' : ''}`} field="Name" value={rubricStatus}></LabelControl>
                                            <FontAwesomeIcon title="Edit Rubric" className={'btn-icon-fa pencil'} icon={faPencil} onClick={() => handleOnEditRubricDetails()} />
                                        </div>
                                        <p>
                                            Evaluation Type:
                                            <LabelControl className="rubric-label" field="RubricType" value={rubricType}></LabelControl>
                                        </p>
                                        <p>
                                            Organization(s):
                                            <LabelControl className="rubric-label" field="Organization" value={organization}></LabelControl>
                                        </p>
                                        <p>
                                            Last updated on
                                            <LabelControl className="rubric-label" field="DateUpdated" value={dateUpdated}></LabelControl>
                                        </p>
                                    </div>
                                    <div style={{ display: 'inline-block', paddingTop: '82px', float: 'right' }}>
                                        <ButtonControl loading={loading} type={'okay'} className={'layout-btn'} onClick={handleOnLayout}>
                                            Layout
                                        </ButtonControl>
                                        <ButtonControl loading={loading} type={'okay'} className={'exit-btn'} onClick={handleOnExit}>
                                            Exit
                                        </ButtonControl>
                                    </div>
                                </div>
                                <div>
                                    {rubricRatingData && rubricIndicatorData && <RubricIndicatorTable data={rubricData} updatedRubricData={updatedRubricData} rubricRatingData={rubricRatingData} rubricIndicatorData={rubricIndicatorData} onEdit={handleOnEdit} selectedDomain={selectedDomain} />}
                                </div>
                            </div>
                        </div>
                    </div>

                    : <>
                        <div className='rubric-page-loading'><Loading type='default' size='4rem' /><div className='loading-font'>Loading...</div></div>
                    </>
            }

        </MainLayout>
    </>
    );
}

export default RubricIndicator;