import React, { useState } from 'react';
import moment from "moment";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { asyncForEach } from "../../../contentLibrary/utils/jsUtil";
import ButtonControl from '../../../../components/controls/ButtonControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { InputField } from '../../../../components/controls/InputField'
import CheckboxControl from '../../../../components/controls/CheckboxControl';
import { ConstructionOutlined } from '@mui/icons-material';
import { Toggle } from 'rsuite';
import RubricRatings from './RubricRatings';

const RubricIndicatorTable = ({ data, updatedRubricData, rubricRatingData, rubricIndicatorData, onEdit, selectedDomain }) => {
    const [loading, setLoading] = useState(false);
    const [indicatorData, setIndicatorData] = useState();
    const [domain, setDomain] = useState();
    const [rows, setRows] = useState([]);
    const [columns, setColoumns] = useState([]);
    const [disableAddIndicator, setDisableAddIndicator] = useState(true);

    useEffect(() => {
        if (updatedRubricData) {
            data = { ...updatedRubricData };
        }
    }, [updatedRubricData]);

    useEffect(() => {
        let newRows = [];
        let colSet = [];
        if ((data && rubricRatingData && rubricIndicatorData && selectedDomain) || updatedRubricData) {
            let domainObj = selectedDomain;
            if (selectedDomain === 'No domain assigned') {
                domainObj = { Id: '', Name: 'No domain assigned' };
                data.RubricDomainMap.forEach((rdm) => {
                    if (rdm.IsActive) {
                        let defaultDomain = rdm.RubricDomain;
                        setDomain({ domainId: defaultDomain.Id, domainName: defaultDomain.Name });
                        domainObj = { Id: defaultDomain.Id, Name: defaultDomain.Name };
                    }
                })
            } else {
                let domainMatch = data.RubricDomainMap.find(x => x.Id === selectedDomain);
                if(!domainMatch) {
                    if(data.RubricDomainMap.length > 0){
                        domainMatch = data.RubricDomainMap[0];
                    }
                }      
                domainObj = { Id: domainMatch.Id, Name: domainMatch.RubricDomain?.Name, domainId: domainMatch.RubricDomain?.Id }
                setDomain(domainObj);
            }

            data.RubricRatingMap.forEach((c) => {
                //add column per Rating that is set to Show in Evaluation
                if (c.RubricRating) {
                    colSet.push({
                        ratingId: c.RubricRating.Id,
                        ratingName: c.RubricRating.ShortDescription + ' (' + c.RubricRating.Value + ') ',
                        subHeader: '(' + c.RubricRating.Title + ')',
                        sequence: c.Sequence
                    });
                }
            })
            sortColumns(colSet);
            //Add a default blank row if no mapped Indicators found
            let currentIndicatorMapItems = data.RubricIndicatorMap.filter(x => x.RubricDomainId === domainObj.domainId);
            if (currentIndicatorMapItems.length === 0) {

                setDisableAddIndicator(true);
                let indicatorRow = {
                    title: { Indicator: 'No indicator assigned', Domain: domainObj.Name, DomainId: domainObj.Id, IndicatorId: '', Sequence: 1 }
                };

                colSet.forEach(col => {
                    indicatorRow[col.ratingId] = { rubricIndicatorId: '', ratingId: col.ratingId, description: '' }; //RubricIndicatorRatingMapItem for each cell
                });
                newRows.push(indicatorRow);

            } else {
                if (data.Status === 2) {
                    setDisableAddIndicator(true);
                }
                else {
                    setDisableAddIndicator(false);
                }
                let domainIndicators = data.RubricIndicatorMap.filter(ri => ri.RubricDomainId === domainObj.domainId);
                domainIndicators.forEach((c) => {

                    //each indicator get's its own row, in each cell of that row, cycle through RubricIndicatorRatingMapItem to find the coorsdponding match to the column header (ratingId)
                    let indicatorItem = rubricIndicatorData.find(x => x.Id === c.RubricIndicatorId); //may need condition on RubricDomainId as well
                    if (indicatorItem === undefined) {
                        indicatorItem = { Name: c.RubricIndicator.Name, Id: c.RubricIndicator.Id };
                    }

                    //sweet, indicatorItem has shortname...precisely what I need!
                    let indicatorRow = {
                        title: { Indicator: indicatorItem.Name, IndicatorId: indicatorItem.Id, IndicatorAbbr: indicatorItem.ShortName, Domain: domainObj.Name, DomainId: domainObj.Id, Sequence: c.Sequence }
                    };

                    colSet.forEach(col => {
                        let ratingItem = rubricRatingData.find(x => x.Id === col.ratingId);
                        indicatorRow[col.ratingId] = { rubricIndicatorId: c.RubricIndicatorId, rubricIndicatorRatingItemId: '', ratingId: col.ratingId, description: '', ratingTitle: ratingItem.Title + ' (' + ratingItem.Value + ')' }; //RubricIndicatorRatingMapItem for each cell
                    });

                    if (data.RubricIndicatorRatingMap.length > 0) {
                        data.RubricIndicatorRatingMap.filter(x => !x.IsDeleted).forEach(rirItem => {

                            if (c.RubricIndicatorId === rirItem.RubricIndicatorId) {
                                //IndicatorRatingMapItem found, find matching column with same rating Id
                                colSet.forEach(col => {
                                    if (col.ratingId === rirItem.RubricRatingId) {
                                        let ratingItem = rubricRatingData.find(x => x.Id === col.ratingId);
                                        indicatorRow[col.ratingId] = { rubricIndicatorId: c.RubricIndicatorId, rubricIndicatorRatingItemId: rirItem.Id, ratingId: col.ratingId, description: rirItem.Description, ratingTitle: ratingItem.Title + ' (' + ratingItem.Value + ')' };
                                    }
                                })
                            }
                        });
                    }
                    newRows.push(indicatorRow);
                })
            }
            //TODO: sort rows method here, based on indicator sequence

            sortRows(newRows);
        }
    }, [data, rubricRatingData, rubricIndicatorData, selectedDomain, updatedRubricData]);

    const sortColumns = (colSet) => {
        let sortedColSet = [];
        let col = {
            header: '',
            dataProp: "title",
            width: '25',
            canSort: false
        }
        sortedColSet.push(col);

        colSet = colSet.sort((a, b) =>
            (a.sequence > b.sequence) ? 1 :
                (a.sequence < b.sequence) ? -1 : 0
        );

        colSet.forEach((c) => {
            col = {
                header: c.ratingName,
                subHeader: c.subHeader,
                dataProp: c.ratingId,
                width: '25',
                canSort: false,
                isIndicatorHeader: true
            }
            sortedColSet.push(col);
        });

        let widthToSet = (100 / sortedColSet.length);

        sortedColSet.forEach(col => {
            col.width = widthToSet.toString();
        });


        setColoumns(sortedColSet)
    }

    const sortRows = (rows) => {
        var currentRowSet = [...rows];

        currentRowSet = currentRowSet.sort((a, b) =>
            (a.title.Sequence > b.title.Sequence) ? 1 :
                (a.title.Sequence < b.title.Sequence) ? -1 : 0
        );
        setRows(currentRowSet);
    }
    const handleOnAddIndicator = () => {
        var currentRowSet = [...rows];
        var currentColumns = [...columns];

        let indicatorRow = {
            title: { Indicator: 'No indicator assigned', IndicatorId: '', Domain: domain.domainName ? domain.domainName : domain.Name, DomainId: domain.domainId ? domain.domainId : domain.Id }
        };
        currentColumns.forEach(col => {
            if (col.dataProp !== 'title') {
                let ratingItem = rubricRatingData.find(x => x.Id === col.dataProp);
                indicatorRow[col.dataProp] = {
                    rubricIndicatorId: '',
                    rubricIndicatorRatingItemId: '',
                    ratingId: col.dataProp,
                    description: '',
                    ratingTitle: ratingItem.Title + ' (' + ratingItem.Value + ')'
                }; //RubricIndicatorRatingMapItem for each cell //RubricIndicatorRatingMapItem for each cell
            }
        });
        currentRowSet.push(indicatorRow);
        setRows(currentRowSet);
    }

    return <>
        <div className="rubric-indicator-table-wrapper">
            <TableControl
                columns={columns}
                data={rows}
                customBorder={true}
                onEdit={(row) => onEdit(row)}
                useColumnWidthCustom={true}
                hideActionColumn={true}
                hideArrowSort={true}
                isIndicatorTable={true}
            />
        </div>
        <div style={{ position: 'relative' }}>
            <br></br>
            {!disableAddIndicator &&
                <FontAwesomeIcon icon={faCirclePlus} className="plus-icon" onClick={handleOnAddIndicator} />
            }
            <ButtonControl disabled={disableAddIndicator} type={'okay'} className={'add-indicator-btn'} onClick={handleOnAddIndicator}>
                Add Indicator
            </ButtonControl>
        </div>
    </>
}

export default RubricIndicatorTable;