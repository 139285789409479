import { faAngleDown, faBoltLightning, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import './SurveyTable.scss';
import ButtonControl from '../../../../components/controls/ButtonControl';
import _ from 'lodash';
import Loading from '../../../../components/Loading';

export default function SurveyTable(
    {
        isSelfReflection,
        indicatorsRated,
        gridData,
        appliedTagCnt,
        ratingSelectOptions = {},
        toggleDialogue,
        toggleRatingOptions,
        selectRating,
        fixedRowWidth,
        customRowHeight,
        globallyFocusedIndicator,
        setGloballyFocusedIndicator,
        disableActOfRating,
        hideRatingBtn,
        hideIndicatorsProgress,
        hideEvidenceButton,
        savingRatings
    }) {


    const ratingRefs = useRef([]);
    if (savingRatings == undefined)
        savingRatings = {};

    const handleDocumentClick = (e, setState) => {
        if (ratingRefs?.current && ratingRefs?.current[0]?.getBoundingClientRect) {
            var closeAllBut = e.target.getAttribute('parentindex');

            if (!closeAllBut) {
                if (!disableActOfRating) {
                    setState(null, true);
                }
            }
            else {
                if (!disableActOfRating) {
                    setState(null, closeAllBut);
                }
            }
        }
    }

    useEffect(() => {

        document.addEventListener('click', (e) => handleDocumentClick(e, toggleRatingOptions));

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        }

    }, []);

    return gridData?.data?.length > 0 &&
        <div className={'observation-table-wrapper' + (!hideRatingBtn && !disableActOfRating ? ' table-padding' : '')}>
            <div className={'observation-table'}>
                <div className={'table-header'} >
                    <div className={'row'}>
                        <div className={'col fixed-width'}>
                            <div>{gridData?.data?.length === 1 ? 'Indicator' : 'Indicators'}</div>
                            {
                                !hideIndicatorsProgress &&
                                <>
                                    <div>{`${indicatorsRated}/${gridData.data.length} rated`}{indicatorsRated === gridData.data.length && <FontAwesomeIcon className={'fa-icon'} icon={faCircleCheck} />}</div>
                                    <div className={'progress-track'}>
                                        <div className={'progress-bar'} style={{ width: `${100 * indicatorsRated / gridData.data.length}%` }}></div>
                                    </div>
                                </>
                            }
                        </div>
                        {
                            _.orderBy(gridData.metaData, m => m.ratingSequence).map((header, i) => {
                                if (header.ratingShowInRubric) {
                                    return (<div key={'rating-' + header.rating} className={'col text-align-center'} style={{ width: `calc(calc(100% - ${fixedRowWidth}) / ${gridData.metaData.filter(r => r.ratingShowInRubric).length})` }}>
                                        {/* {i === 0 && <div className={'invisible'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc. Curabitur tortor. Pellentesque nibh. Aenean quam. In scelerisque sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin ut ligula ve.</div>} */}
                                        <div className={'actual-content'}>
                                            <div>{header.ratingHeader}&nbsp;({header.rating})</div>
                                            <div>({header.subHeader})</div>
                                        </div>
                                    </div>)
                                }
                            })
                        }
                    </div>
                </div>
                {gridData.data.map((row, i) => {
                    return (
                        <div key={row.tag?.name ?? ('row' + i)} className={'table-row'} style={customRowHeight ?? {}}>
                            <div className={'survey-rubric-cell fixed-width'}>
                                <div className={'indicators-wrapper'}>
                                    <div className={'indicator'}>
                                        <div className={'title-icon-wrapper'} style={globallyFocusedIndicator === row.tag?.abbr ? { fontWeight: '800' } : {}}>{row.tag?.name}</div>
                                        {!isSelfReflection &&
                                            <div className='lighting-icon-wrapper'>
                                                <FontAwesomeIcon onClick={() => {
                                                    if ((appliedTagCnt[row.tag?.abbr] ?? 0) > 0) {
                                                        if (!globallyFocusedIndicator) {
                                                            setGloballyFocusedIndicator(row.tag?.abbr);
                                                        }
                                                        else {
                                                            if (globallyFocusedIndicator === row.tag?.abbr) {
                                                                setGloballyFocusedIndicator(null);
                                                            }
                                                            else {
                                                                setGloballyFocusedIndicator(row.tag?.abbr);
                                                            }
                                                        }
                                                    }
                                                }}
                                                    icon={faBoltLightning}
                                                    className={`lighting${(appliedTagCnt[row.tag?.abbr] ?? 0) > 0 ? ` active${globallyFocusedIndicator === row.tag?.abbr ? ' focused' : ''}` : ''}`} />
                                                <div className={'icon-subscript-number'}>{appliedTagCnt[row.tag?.abbr] ?? 0}</div>
                                            </div>
                                        }
                                    </div>
                                    <div className={'rating-btn-wrapper'}>
                                        {!isSelfReflection && !hideEvidenceButton &&
                                            <ButtonControl className={'evidence-btn'} onClick={() => {
                                                toggleDialogue(row.tag?.name);
                                            }}>
                                                EVIDENCE
                                            </ButtonControl>
                                        }
                                        {!hideRatingBtn &&
                                            <div
                                                parentindex={row.tag?.name}
                                                ref={(el) => { ratingRefs.current[i] = el }}
                                                className={`rating-btn ${(disableActOfRating || savingRatings[row.tag?.name]) ? 'disable' : ''} ${ratingSelectOptions[row.tag?.name]?.selectedRating ? ' selected' : ''} ${ratingSelectOptions[row.tag?.name]?.isExpanded ? 'expanded' : ''}`}
                                                onClick={() => {
                                                    if (!disableActOfRating && row.tag?.name) {
                                                        if (!savingRatings[row.tag?.name]) {
                                                            toggleRatingOptions(row.tag?.name)
                                                        }
                                                    }
                                                }}>
                                                {ratingSelectOptions[row.tag?.name]?.selectedRating ?
                                                    <>{!savingRatings[row.tag?.name] ? <>
                                                        <div parentindex={row.tag?.name} className={'magnitude'}>{ratingSelectOptions[row.tag?.name]?.selectedRating}</div>
                                                    </> : <><div parentindex={row.tag?.name} className={'rating-loading'}><Loading /></div></>}
                                                        <div parentindex={row.tag?.name} className={'rating-container'}>
                                                            {gridData.metaData.map((item, i) => {
                                                                return <div parentindex={row.tag?.name} key={`data-rating-${i}`} className={`data-rating${ratingSelectOptions[row.tag?.name]?.selectedRating == (item.rating) ? ' active' : ''}`}></div>
                                                            })}
                                                        </div>
                                                    </>
                                                    : <div parentindex={row.tag?.name}>RATE</div>}
                                                <FontAwesomeIcon id={`fa-${row.tag?.name}`} parentindex={row.tag?.name} icon={faAngleDown} className={`rating-caret ${ratingSelectOptions[row.tag?.name]?.isExpanded ? 'flip' : ''}`} />
                                                <div parentindex={row.tag?.name} className={'invisible-overlay'}></div>
                                            </div>
                                        }
                                        {!disableActOfRating && ratingSelectOptions[row.tag?.name]?.isExpanded ?
                                            <div className={'rating-list'}>
                                                {_.orderBy(Object.keys(row.rubric ?? {}), (x) => x, ['desc']).map((rating, i) => {
                                                    return (
                                                        <div key={`data-rating-option-${i}`}
                                                            className={'data-rating-option'}
                                                            onClick={() => {
                                                                if (!savingRatings[row.tag?.name]) {
                                                                    selectRating(row.tag?.name, rating);
                                                                }
                                                            }}
                                                        >
                                                            {!savingRatings[row.tag?.name] ? <>
                                                                <div className={'magnitude'}>{rating}  </div>
                                                                <div className={'rating-container'}>
                                                                    {gridData.metaData.map((item, j) => {
                                                                        return <div key={`data-rating-${j}`} className={`data-rating${rating == (item.rating) ? ' active' : ''}`}></div>
                                                                    })}
                                                                </div>
                                                            </> : <Loading />
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            {_.orderBy(gridData.metaData, m => m.ratingSequence).map((header, i) => {
                                if (header.ratingShowInRubric) {
                                    return (
                                        <div key={`${row.tag?.name ?? i}-${header.rating}`} className={'survey-rubric-cell text-align-center'} style={{ width: `calc(calc(100% - ${fixedRowWidth}) / ${gridData.metaData.filter(r => r.ratingShowInRubric).length})` }}>
                                            {/* {i === 0 && <div className={'invisible'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc. Curabitur tortor. Pellentesque nibh. Aenean quam. In scelerisque sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin ut ligula ve.</div>} */}
                                            <div className={'actual-content'} dangerouslySetInnerHTML={{ __html: row.rubric[header.rating] }} />
                                            {/* <div className="actual-content">{row.rubric[header.rating]}</div> */}
                                        </div>
                                    )
                                }
                            })
                            }
                        </div>
                    )
                })}
            </div>
        </div>
}