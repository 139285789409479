import { useEffect, useState } from 'react';
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import { useImportExportManager } from '../../../../hooks/useManagers';
import _ from 'lodash';

const ImportExportDetails = ({ item, onCancel, onDownload, onErrors }) => {
    const [importExportItem, setImportExportItem] = useState(item);
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
        if (item) {
            setImportExportItem({
                ...item,
                FileName: item.File?.FileName ?? "No File Found",
                LogMessageDetails: item.Log?.MessageDetails,
                LogMessage: item.Log?.Message
            });
        }
    }, [item])

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    const handleOnDownload = async () => {
        if (onDownload) {
            setDownloading(true);
            onDownload(importExportItem).then(r => {
                setDownloading(false);
            });
        }
    }

    return (<>
        <div className='control-box-wrapper'>
            {importExportItem &&
                <div className='control-box box-column'>
                    <InputField type="label" title={`${importExportItem.IsExport ? 'Export' : 'Import'} Name`} value={importExportItem.ImportExportTypeName} fieldName="ImportExportTypeName" />
                    <InputField type="label" title="Status" value={importExportItem?.ImportExportStatusName} fieldName="ImportExportStatusName" />
                    <InputField type="label" title="Start Date" value={importExportItem?.StartedDate} fieldName="StartedDate" />
                    <InputField type="label" title="Completed Date" value={importExportItem?.CompletedDate} fieldName="CompletedDate" />
                    <InputField type="label" title="Message" value={importExportItem?.Log?.Message} fieldName="LogMessage" />
                    <InputField type="label" title="Message Details" value={importExportItem?.Log?.MessageDetails} fieldName="LogMessageDetails" />
                </div>
            }
        </div>
        <div className='screen-footer screen-footer-right'>
            <div className='btn-wrapper-left screen-footer__btn'><ButtonControl disabled={downloading} loading={downloading} type={'okay'} onClick={handleOnDownload}>Download</ButtonControl></div>
            <div className='btn-wrapper-left screen-footer__btn'><ButtonControl type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl></div>
        </div>
    </>
    )
}

export default ImportExportDetails;


