import React from 'react';
import { useHookstate } from '@hookstate/core';
import globalAppUserStore from '../../stores/globalAppUserStore';


const AuthError = () => {
    const globalAppUserState = useHookstate(globalAppUserStore);
    const { initFailed } = globalAppUserState.get();
    return initFailed && <div><p>An Error has occurred during the autherization and initalization process. This occurs when a user is not configured correctly in the system. Please see the logs for more details.</p></div>;
};

export default AuthError;
