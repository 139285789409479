import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import GeneralSurveyDetails from '../components/survey/details';
import GeneralSurveyFilter from '../components/survey/filter';
import GeneralSurveyTable from '../components/survey/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useGeneralSurveyConfigManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';

const GeneralSurveyMgmt = ({ authMgr }) => {
    const generalSurveyConfigMgr = useGeneralSurveyConfigManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [openSendMailDialog, setOpenSendMailDialog] = useState(false)
    const user = authMgr?.GetGlobalState()
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (generalSurveyConfigMgr && (!params?.generalSurveyId || (manageMode == 'list' && (!generalSurveyConfigMgr?.FilteredData || generalSurveyConfigMgr?.FilteredData?.length === 0)))) {
            setLoading(true);
            generalSurveyConfigMgr.search().then(fb => {
                setManageMode('list');
                setLoading(false);
            });
        }
        else if (!selectedSurvey && params?.generalSurveyId && generalSurveyConfigMgr) {
            setLoading(true);
            if (params?.generalSurveyId.toLowerCase() === 'addnew') {
                setSelectedSurvey(generalSurveyConfigMgr.DefaultItem);
                setManageMode('view');
                setLoading(false);
            }
            else {
                generalSurveyConfigMgr.get(params?.generalSurveyId).then((r) => {
                    if (r.Success) {
                        setManageMode('view');
                        setSelectedSurvey(r.Items.first());
                    }
                    setLoading(false);
                });
            }
        }

    }, [generalSurveyConfigMgr, params?.surveyId, manageMode]);

    const onAddNew = () => {
        setSelectedSurvey(generalSurveyConfigMgr.DefaultItem);
        setManageMode('view');
        navigate('/admin/generalsurvey/addnew');
    }

    const handleOnFilterChange = async (field, value) => {
        const surveyFilter = generalSurveyConfigMgr.Filter;
        const updatedSurveyFilter = { ...surveyFilter };
        updatedSurveyFilter[field] = value !== '' ? value : null;
        await generalSurveyConfigMgr.filterData(updatedSurveyFilter);
    }

    const handleOnSave = async (item, condition) => {
        const spreadItem = { ...item }
        spreadItem.SurveyTemplate = null
        spreadItem.UserGeneralSurveys = null
        spreadItem.AssignedByUserId = user?.userProfile?.Id
        var result = await generalSurveyConfigMgr.saveItem(spreadItem);
        return result;

    }


    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        generalSurveyConfigMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.Survey}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnEdit = (item) => {
        item['UserGeneralSurveys'] = JSON.parse(JSON.stringify(item.UserGeneralSurveys))
        setSelectedSurvey(item);
        setManageMode('view');
        navigate(`/admin/generalsurvey/${item.Id}`);
    }

    const handleOnCancel = () => {
        navigate('/admin/generalsurvey');
        //if (generalSurveyConfigMgr.FilteredData)  
        setManageMode('list');
        //else setManageMode(null);

        setSelectedSurvey(null);
        setLoadingError(null);
        setErrors(null);
    }

    const sendEmail = (item) => {
        setOpenSendMailDialog(item.Id)
    }

    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete '${itemToDelete?.Name}' ? `} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <DialogControl
            openDialog={openSendMailDialog}
            title={'Send Email'}
            subTitle={`Are you sure you want to request all assigned users to complete the general survey?`}
            onCancel={() => setOpenSendMailDialog(false)}
            onOk={() => {
                generalSurveyConfigMgr.sendEmailToUsers(openSendMailDialog)
                setOpenSendMailDialog(false)
            }}
            okText={'Confirm'}>
        </DialogControl>
        <MainLayout errors={errors} validationErrors={validationErrors} >
            {manageMode === 'view' &&
                <div className='screen-header'>
                    <h3>General Survey Management</h3>
                    <h5>{`${selectedSurvey?.Id ? 'Edit' : 'Create'}`} Survey </h5>
                </div>
            }
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <GeneralSurveyFilter generalSurveyConfigMgr={generalSurveyConfigMgr} onFilterChange={handleOnFilterChange} />
                            <GeneralSurveyTable
                                canWrite={generalSurveyConfigMgr.CanWrite}
                                canDelete={generalSurveyConfigMgr.CanDelete}
                                sendEmail={sendEmail}
                                generalSurveyConfigMgr={generalSurveyConfigMgr}
                                filteredItems={generalSurveyConfigMgr?.FilteredData ?? []}
                                onEdit={handleOnEdit}
                                onDelete={(item) => { setItemToDelete(item); }} />
                        </div>
                        <div className='screen-footer list'>
                            {generalSurveyConfigMgr.CanWrite && <ButtonControl type={'create'} onClick={onAddNew}>Create Survey</ButtonControl>}
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&
                    <GeneralSurveyDetails params={params} authMgr={authMgr} canWrite={generalSurveyConfigMgr.CanWrite} generalSurveyConfigMgr={generalSurveyConfigMgr} survey={selectedSurvey} onCancel={handleOnCancel} onSave={handleOnSave} onErrors={setErrors} onValidationErrors={setValidationErrors} />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default GeneralSurveyMgmt;