import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PublicLayout from '../../../components/layout/PublicLayout';
import './../home.scss';
import nietTeamImage from './../assets/hero_images/niet-team.jpg';
import TrendingContent from './../components/TrendingContent';
import MemberTestimonials from './../components/MemberTestimonials';
import GrowthTracking from './../components/GrowthTracking';
import VideoPreview from './../components/VideoPreview';
import FAQ from './../components/FAQ';
import ScreenWrapper from '../../../components/layout/screenWrapper';

const Home = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
      return () => {
        window.removeEventListener('load', handleLoad);
      };
    }
  }, []);

  return (
    <PublicLayout>
      <ScreenWrapper loading={isLoading}>
        <div className='home-container'>
          {/* <div className='rollover-banner'>ATTENTION PARTNERS!<br></br>
             On July 1 NIET will be processing the data rollover from the 2023 - 2024 academic year.<br></br>
             EE PASS will be down temporarily for a few hours on July 1.
          </div> */}
          <div className='hero-container'>
            <div className='hero-column'>
              <h1>Engage.</h1>
              <h1>Equip.</h1>
              <h1>Empower.</h1>
              <div className='header-content-desc'>
                Evolve as an educator with real-time access to individualized training and the support to improve instruction and evaluation.
              </div>
            </div>
            <div className='hero-column'>
              <img src={nietTeamImage} className='hero-image' alt="NIET Team" />
            </div>
          </div>

          <br /><br />
          <VideoPreview />
          <br />
          <TrendingContent />
          <br /><br />
          <MemberTestimonials />
          <GrowthTracking />
          <br /><br />
          <FAQ />
        </div>
      </ScreenWrapper>
    </PublicLayout>
  );
};

export default Home;
