import React, { useState, useEffect} from 'react';

export const ThemeProviderContext = React.createContext();

export function ThemeProvider({ children }) {

    const [colorTheme, setColorTheme] = useState('blue');
    const [isDarkMode, toggleDarkMode] = useState(false);
    const validColorThemes = ['purple', 'green', 'blue', 'red'];

    const lightDarkMode = {
        light: {
            backgroundColor: "#f4f5fa",
            color: 'black'
        },
        dark: {
            backgroundColor: "var(--color-left-nav-selection-active)",//"rgb(27 36 40)",//"#131216",//"#1b1a19 - an azure background black...unsure about so keeping it here for now ",
            color: '#f4f5fa'
        }
    }

    //The idea here is to pull this from somewhere persisted so that users can save their themes
    //for now it will simply look at localstorage
    useEffect(() => {
        if(localStorage['colorTheme'] !== undefined && validColorThemes.includes(localStorage['colorTheme'])){
            setColorTheme(localStorage['colorTheme']);
        }
        else {
            localStorage['colorTheme'] = 'blue';
        }

        if(localStorage['darkMode'] !== undefined){
            toggleDarkMode((localStorage['darkMode'] === 'true'));
        }

    },[]);

    function persistColorTheme(color) {
        if(validColorThemes.includes(localStorage['colorTheme'])){
            localStorage['colorTheme'] = color;
            setColorTheme(color);
        }
    }

    function persistDarkLightMode() {
        var isDark = !isDarkMode;
        toggleDarkMode(isDark);
        localStorage['darkMode'] = isDark;
    }

    return(
        <ThemeProviderContext.Provider value={{colorThemeContext: [colorTheme, persistColorTheme], darkModeContext: [isDarkMode, persistDarkLightMode], modes: lightDarkMode}}>
            {children}
        </ThemeProviderContext.Provider>
    );
}