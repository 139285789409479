import { useEffect, useState } from 'react';
import { TreePicker } from 'rsuite';
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import { CheckboxControl } from '../../../../components/controls';
import { useValidationManager } from '../../../../hooks/useValidation';
import _ from 'lodash';
import Loading from '../../../../components/Loading';
import { ObservationSection, SaveObservationQuestions } from '../certificationQuestion/ObservationComponents/ObservationComponents';
import AppUserManager from '../../../../managers/AppUserManager';
import { useAppUserManager } from '../../../../hooks/useManagers';


const SchoolDetails = ({ certificationQuestions, orgTreeUsers, grabObservationDetails, canWrite, orgMgr, org, preSelectedDistrictId, teacherRubricList, adminRubricList, teacherSurveyList, adminSurveyList, observationConfigs, loadingConfigs, schoolMgr, school, onCancel, onSave, onErrors, onValidationErrors }) => {
    const [item, setItem] = useState(school ?? schoolMgr.DefaultItem);
    const [saving, setSaving] = useState(false);
    const [useDefaultCertSettings, setUseDefaultCertSettings] = useState(school ? false : true);
    const [forceValidate, setForceValidate] = useState(false);
    const [observationConfig, setObservationConfig] = useState(null)
    const [districts, setDistricts] = useState(null)
    const [override, setOverride] = useState(false)
    const [activeObservationConfigTab, setActiveObservationConfigTab] = useState('Admin')
    const [schoolNumber, setSchoolNumber] = useState(school?.SchoolNumber ? null : "")

    const [formattedTeacherObservationRubrics, setFormattedTeacherObservationRubrics] = useState(null)
    const [formattedTeacherCertificationRubrics, setFormattedTeacherCertificationRubrics] = useState(null)

    const [formattedAdminObservationRubrics, setFormattedAdminObservationRubrics] = useState(null)
    const [formattedAdminCertificationRubrics, setFormattedAdminCertificationRubrics] = useState(null)

    const validationMgr = useValidationManager(false, 'ALL');
    const validationSchoolMgr = useValidationManager(false, 'SCHOOL');

    const appUserMgr = useAppUserManager();

    useEffect(() => {
        if (item?.ClientId) {
            setDistricts(orgMgr?.DistrictsOnly(item.ClientId));
        }
    }, [item, org]);

    useEffect(() => {
        if (!loadingConfigs && observationConfigs !== null && (useDefaultCertSettings || observationConfig === null)) {
            setObservationConfig(observationConfigs)
            if (observationConfigs.IsActive && item.Id && item.Id === observationConfigs.SchoolId) {
                setOverride(true)
            }
            setFormattedTeacherCertificationRubrics(_.map(_.filter(teacherRubricList, x => (x.RubricType === 3 || x.RubricType === 1) && x.RubricDomainMap?.some(z => z.UseForCertification === true)), y => { return { text: y.Name, value: y.Id } }))
            setFormattedTeacherObservationRubrics(_.map(_.filter(teacherRubricList, x => x.RubricType === 1), y => { return { text: y.Name, value: y.Id } }))
            setFormattedAdminCertificationRubrics(_.map(_.filter(adminRubricList, x => (x.RubricType === 7 || x.RubricType === 4) && x.RubricDomainMap?.some(z => z.UseForCertification === true)), y => { return { text: y.Name, value: y.Id } }))
            setFormattedAdminObservationRubrics(_.map(_.filter(adminRubricList, x => x.RubricType === 4), y => { return { text: y.Name, value: y.Id } }))
        }
    }, [loadingConfigs, observationConfigs])

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    const handleOnSave = async (e) => {
        const validateAll = validationMgr.checkIsGroupValid(item);
        const validateSchool = validationSchoolMgr.checkIsGroupValid(item)
        const validateResult = [...validateAll, ...validateSchool]
        if (validateResult?.length === 0) {
            setForceValidate(false);
            if (onSave) {
                setSaving(true);

                // converts each list of file ids to a string that can be read by C#
                const completeConfig = SaveObservationQuestions(['Teacher', 'Admin'], observationConfig, updateObservationConfigs);
                if (completeConfig) {
                    var result = await onSave(item, completeConfig);
                    if (result?.Success) {
                        handleOnCancel();
                    }
                    else if (onErrors) {
                        onErrors([result?.Message?.DisplayMessage ?? [...(result?.MessageDetails ?? ['Unknown Error'])]])
                    }

                    setSaving(false);
                    return result;
                }
                else {
                    setSaving(false);
                    onErrors(['Onbservation config not loaded, please try again when observations configuraitons have completed loading.']);
                    return { Success: false };
                }
            }
        }
        else if (onErrors) {
            setForceValidate(true);
            onValidationErrors(validateResult);
        }

    }

    const handleOnChange = (value, field) => {
        let updatedItem = { ...item };
        updatedItem[field] = value;
        if (field === 'DistrictId' && updatedItem.DistrictId && !updatedItem.Id) {
            grabObservationDetails(updatedItem)
        }


        if (field == 'Name' && schoolNumber !== null) {
            const name = updatedItem["Name"];
            const nameArray = name?.split(' ') ?? [];
            const schoolNumber = nameArray.map(x => x.length > 0 ? x[0].toUpperCase() : "").toString().replaceAll(",", "");
            setSchoolNumber(schoolNumber);
            updatedItem.SchoolNumber = schoolNumber;
        }

        if (field == 'SchoolNumber') {
            setSchoolNumber(null);
        }

        setItem(updatedItem);
    }

    const updateObservationConfigs = (value, field) => {
        let updatedConfig = { ...observationConfig };
        if (!updatedConfig.SchoolId) {
            updatedConfig.DistrictId = item.DistrictId
            updatedConfig.OrganizationId = item.ClientId
            updatedConfig.ClientId = item.ClientId
            updatedConfig.SchoolId = item.Id
            updatedConfig.Id = undefined
            updatedConfig.ObservationConfigId = undefined
            updatedConfig._id = undefined
            updatedConfig.UniqueId = undefined
        }
        updatedConfig.wasEdited = true
        if (value?.FileId) {
            updatedConfig[field] = value?.FileId;
            if (field === 'CertificationLogTeacherFileId') {
                updatedConfig.CertificationLogTeacherFile = value;
            }
        } else {
            updatedConfig[field] = value;
        }
        setUseDefaultCertSettings(false);
        setObservationConfig(updatedConfig);
    }

    const onTreeClean = (id) => {
        handleOnChange(null, id);
    }

    const updateUserPositionRubricMap = (value, userPosition, rubricType) => {
        let updatedConfig = { ...observationConfig };
        if (value === 'UNK') {
            value = null
        }
        if (!updatedConfig.SchoolId) {
            updatedConfig.OrganizationId = item.ClientId
            updatedConfig.ClientId = item.ClientId
            updatedConfig.SchoolId = item.SchoolId;
            updatedConfig.DistrictId = item.DistrictId;
            updatedConfig.Id = undefined
            updatedConfig.ObservationConfigId = undefined
            updatedConfig._id = undefined
            updatedConfig.UniqueId = undefined
        }
        updatedConfig.wasEdited = true
        let updatedUserPositionRubricMap = [...observationConfig.UserPositionRubricMap]
        let userPositionToUpdate = _.find(updatedUserPositionRubricMap, x => x.UserPosition == userPosition && x.RubricType == rubricType)
        if (userPositionToUpdate) {
            let userPositionToUpdateIndex = _.findIndex(updatedUserPositionRubricMap, x => x.UserPosition == userPosition && x.RubricType == rubricType)
            userPositionToUpdate.RubricId = value
            updatedUserPositionRubricMap.splice(userPositionToUpdateIndex, 1, userPositionToUpdate)
        } else {
            userPositionToUpdate = {
                RubricId: value,
                UserPosition: userPosition,
                RubricType: rubricType
            }
            updatedUserPositionRubricMap.push(userPositionToUpdate)
        }
        updatedConfig.UserPositionRubricMap = updatedUserPositionRubricMap
        setObservationConfig(updatedConfig)
    }
    const updateUserPositionSurveyTemplateMap = (value, userPosition, surveyType) => {
        let updatedConfig = { ...observationConfig };
        if (value === 'UNK') {
            value = null
        }
        updatedConfig.wasEdited = true
        let updatedUserPositionSurveyTemplateMap = [...observationConfig.UserPositionSurveyTemplateMap]
        let userPositionToUpdate = _.find(updatedUserPositionSurveyTemplateMap, x => x.UserPosition == userPosition && x.SurveyType == surveyType)
        if (userPositionToUpdate) {
            let userPositionToUpdateIndex = _.findIndex(updatedUserPositionSurveyTemplateMap, x => x.UserPosition == userPosition && x.SurveyType == surveyType)
            userPositionToUpdate.SurveyTemplateId = value
            updatedUserPositionSurveyTemplateMap.splice(userPositionToUpdateIndex, 1, userPositionToUpdate)
        } else {
            userPositionToUpdate = {
                SurveyTemplateId: value,
                UserPosition: userPosition,
                SurveyType: surveyType
            }
            updatedUserPositionSurveyTemplateMap.push(userPositionToUpdate)
        }
        updatedConfig.UserPositionSurveyTemplateMap = updatedUserPositionSurveyTemplateMap
        setObservationConfig(updatedConfig)
    }

    const updateUserPositionAlternativeMap = (value, userPosition) => {
        let updatedConfig = { ...observationConfig };
        updatedConfig.wasEdited = true
        let updatedUserPositionAlternativeMap = [...observationConfig.UserPositionAlternativeMap]
        let userPositionToUpdate = _.find(updatedUserPositionAlternativeMap, x => x.UserPosition == userPosition)
        if (userPositionToUpdate) {
            let userPositionToUpdateIndex = _.findIndex(updatedUserPositionAlternativeMap, x => x.UserPosition == userPosition)
            userPositionToUpdate.Name = value
            updatedUserPositionAlternativeMap.splice(userPositionToUpdateIndex, 1, userPositionToUpdate)
        } else {
            userPositionToUpdate = {
                Name: value,
                UserPosition: userPosition,
            }
            updatedUserPositionAlternativeMap.push(userPositionToUpdate)
        }
        updatedConfig.UserPositionAlternativeMap = updatedUserPositionAlternativeMap
        setObservationConfig(updatedConfig)
    }

    return (<>
        <div className='control-box-wrapper'>
            {item && <> <div className='control-box box-three-column'>
                <div className='role-perm-editor__header'>
                    <h4>Configuration</h4>
                </div>
                {org && <InputField
                    forceValidate={forceValidate}
                    value={item?.ClientId}
                    title="Organization"
                    fieldName="ClientId"
                    isDisabled={preSelectedDistrictId}
                    validationName={'school_ClientId'}
                >
                    <TreePicker
                        height={320}
                        width={400}
                        data={org ?? []}
                        valueKey={'OrgId'}
                        labelKey={'Name'}
                        onClean={() => onTreeClean("ClientId")}
                        onSelect={(a, val) => handleOnChange(val, 'ClientId')}
                        placement="autoVerticalStart"
                    />
                </InputField>}
                {org && districts ? <InputField
                    forceValidate={forceValidate}
                    value={item?.DistrictId}
                    title="District"
                    fieldName="DistrictId"
                    validationName={'school_DistrictId'}
                    isDisabled={preSelectedDistrictId}
                    groupId={'SCHOOL'}
                >
                    <TreePicker
                        height={320}
                        width={400}
                        data={districts ?? []}
                        valueKey={'DistrictId'}
                        labelKey={'Name'}
                        onClean={() => onTreeClean("DistrictId")}
                        onSelect={(a, val) => handleOnChange(val, 'DistrictId')}
                        placement="autoVerticalStart"
                    />
                </InputField> :
                    <InputField
                        forceValidate={forceValidate}
                        value={item?.DistrictId}
                        title="District"
                        fieldName="DistrictId"
                        subTitle="Select an Organization First."
                        validationName={'school_DistrictId'}
                    >
                        <TreePicker
                            height={320}
                            width={400}
                            disabled={true}
                            data={[]}
                            placement="autoVerticalStart"
                        />
                    </InputField>}
                <InputField forceValidate={forceValidate} type="text" subTitle={'Once set, the name cannot be changed.'}
                    value={item?.Name} isDisabled={school?.Id} title="School"
                    fieldName="Name" validationName={'ALL_name'} groupId={'ALL'} onChange={handleOnChange} />
                <InputField forceValidate={forceValidate} type="text"
                    value={item?.SchoolNumber} title="School Number"
                    fieldName="SchoolNumber" validationName={'school_number'} groupId={'SCHOOL'} onChange={handleOnChange} />
                {appUserMgr?.AccessCheck('NavApplicationAdminMgmt') && <>
                    <InputField title="Include in Payout?" value={item?.HasPayout} fieldName="HasPayout" onChange={handleOnChange} >
                        <CheckboxControl />
                    </InputField>
                        <InputField title="Use Compass Equivalent?" value={item?.UseCompassEquivalent} fieldName="UseCompassEquivalent" onChange={handleOnChange} >
                        <CheckboxControl />
                    </InputField></>}
                <InputField title="Active?" value={item?.IsActive} fieldName="IsActive" onChange={handleOnChange} >
                    <CheckboxControl />
                </InputField>


            </div>
                <div className={"observation-config-container"}>
                    <div className="observation-config-header">
                        <h4>Configuration Management</h4>
                    </div>
                    {
                        !loadingConfigs ? <>
                            <div className="tab-container">
                                <div className={"tab" + (activeObservationConfigTab === 'Admin' ? ' active' : '')} onClick={() => setActiveObservationConfigTab('Admin')}><span>School Admin</span></div>
                                <div className={"tab" + (activeObservationConfigTab === 'Teacher' ? ' active teacher-tab' : '')} onClick={() => setActiveObservationConfigTab('Teacher')}><span>Teacher</span></div>
                                <div className={"tab" + (activeObservationConfigTab === 'Display' ? ' active teacher-tab' : '')} onClick={() => setActiveObservationConfigTab('Display')}><span>Display</span></div>
                                <div className={"tab" + (activeObservationConfigTab === 'Metrics' ? ' active teacher-tab' : '')} onClick={() => setActiveObservationConfigTab('Metrics')}><span>Metrics</span></div>
                                <div className={"tab hidden-tab"} >
                                    <div className="toggle-wrapper">
                                        <span>Override?</span>
                                        <label className={"toggle-checkbox"}>
                                            <input type="checkbox" className='switch-input' checked={override} onChange={(e) => {
                                                setOverride(e.target.checked)
                                                updateObservationConfigs(e.target.checked, 'IsActive')
                                            }} />
                                            <span htmlFor="checkbox" className='switch-circle'></span>
                                        </label>
                                    </div></div>
                            </div>
                            <div className={"observation-config" + (override || activeObservationConfigTab === 'Metrics' ? "" : " disabled")} >
                                <ObservationSection
                                    observationConfig={observationConfig}
                                    activeObservationConfigTab={activeObservationConfigTab}
                                    updateObservationConfigs={updateObservationConfigs}
                                    updateUserPositionRubricMap={updateUserPositionRubricMap}
                                    updateUserPositionSurveyTemplateMap={updateUserPositionSurveyTemplateMap}
                                    forceValidate={forceValidate}
                                    adminSurveyList={adminSurveyList}
                                    teacherSurveyList={teacherSurveyList}
                                    formattedTeacherObservationRubrics={formattedTeacherObservationRubrics}
                                    formattedTeacherCertificationRubrics={formattedTeacherCertificationRubrics}
                                    formattedAdminObservationRubrics={formattedAdminObservationRubrics}
                                    formattedAdminCertificationRubrics={formattedAdminCertificationRubrics}
                                    orgTreeUsers={orgTreeUsers}
                                    updateUserPositionAlternativeMap={updateUserPositionAlternativeMap}
                                    certificationQuestions={certificationQuestions}
                                    school={school}
                                />

                            </div>
                        </> : <div className="loading-wrapper"><Loading size={'3rem'} /></div>
                    }
                </div>
            </>}
        </div>
        <div className='screen-footer screen-footer-right'>
            <div className='btn-wrapper-left screen-footer__btn'><ButtonControl disabled={saving} type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl></div>
            {canWrite && <div className='btn-wrapper-right screen-footer__btn'><ButtonControl loading={saving} type={'okay'} disabled={!item} onClick={handleOnSave}>Save</ButtonControl></div>}
        </div>
    </>)
}

export default SchoolDetails;


