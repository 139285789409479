import React from 'react';
import { useNavigate } from 'react-router-dom';
import './../growth-tracking.scss';
import ContentLibraryImg from './../assets/feature showcase/1 Content Library Mock.png';
import AnalyticsImg from './../assets/feature showcase/2 Analytics Mock-Up.png';
import DashboardImg from './../assets/feature showcase/3 My Dashboard Mock.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ButtonControl from '../../../components/controls/ButtonControl';

const GrowthTracking = () => {
  const navigate = useNavigate();
  return (
    <div className='growth-tracking'>
      <div className='growth-tracking-container'>

        <div className='growth-info-container'>
          <div className='growth-header'>
            Personalized Growth Tracking
          </div>
          <div className='growth-intro'>
            Visual analytics that allow for quick identification of progress, performance, and professional development areas.
          </div>
          <div>
            <ul className='list-container'>
              <li><FontAwesomeIcon icon={faCheck} className='check' />Observation Process Feedback</li>
              <li><FontAwesomeIcon icon={faCheck} className='check' />Interactive Charts</li>
              <li><FontAwesomeIcon icon={faCheck} className='check' />Progress Toward Certification</li>
              <li><FontAwesomeIcon icon={faCheck} className='check' />Export Real-Time Data</li>
            </ul>
          </div>

        </div>
        <div className='image-wrapper'>
          <img src={ContentLibraryImg} className="image-1" alt="Content Library" />
          <img src={AnalyticsImg} className="image-2" alt="Analytics" />
          <img src={DashboardImg} className="image-3" alt="Dashboard" />
        </div>
      </div>
    </div>
  );
}

export default GrowthTracking;