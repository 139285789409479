import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { useAppUserManager, useOrgManager } from "../../../../hooks/useManagers";
import { faDownload, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../../components/Loading";
import SystemTypes from "../../../../services/SystemTypes";



const ImportExportTable = ({ filteredItems, onView, onDownload, onReload, onDelete }) => {
    const orgMgr = useOrgManager();
    const [organizations, setOrganizations] = useState(null);
    const [downloading, setDownloading] = useState(null);
    const [reloading, setReloading] = useState(null);
    useEffect(() => {
        if (orgMgr) {
            orgMgr.loadOrgTree().then(r => {
                setOrganizations(orgMgr.OrgsOnly());
            });
        }
    }, [orgMgr]);


    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Name',
            dataProp: 'ImportExportTypeName',
            width: '20%',
            canSort: true
        },
        {
            header: 'File Name',
            dataProp: 'FileName',
            width: '30%',
            canSort: true
        },
        {
            header: 'Message',
            dataProp: 'Message',
            width: '40%',
            canSort: false
        },
        {
            header: 'Completed',
            dataProp: "CompletedDate",
            width: '15%',
            datatype: 'dateTime',
            canSort: true
        },
        {
            header: 'Status',
            dataProp: 'ImportExportStatusName',
            width: '10%',
            canSort: true
        }
    ]);

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (f) => {
                newRows.push({
                    ...f,
                    Message: <div>
                        <div>{f.Log?.Message}</div>
                        <div>{f.Log?.MessageDetails}</div>
                    </div>,
                    FileName: `${f.File?.DisplayFileName}.${f.File?.FileExtention}`
                });
            });
            setRows(newRows);
        }
    }, [filteredItems, organizations]);

    const handleCheckDisableAction = (dataRecord, action) => {
        if (action.toLowerCase() === "custom1") {
            return dataRecord.ImportExportStatus == SystemTypes.ImportExportStatusType.Failed;
        }
        return false;
    }



    const handleOnDownload = async (rowItem) => {
        if (onDownload) {
            if (rowItem.ImportExportStatus != SystemTypes.ImportExportStatusType.Failed) {
                setDownloading(rowItem.Id);
                onDownload(rowItem).then(r => {
                    setDownloading(null);
                });
            }
        }
    }

    const handleOnReload = async (rowItem) => {
        if (onReload) {
            setReloading(rowItem.Id);
            onReload(rowItem).then(r => {
                setReloading(null);
            });
        }
    }

    return <>
        <div className="contentDisplay-list">
            <TableControl
                permission='FileImportExport'
                columns={columns}
                data={rows}
                useIntegratedPaging={['10', '25', '50', '100']}
                onView={onView}
                onDelete={onDelete}
                onCustom1={{ action: (row) => (row.Id == downloading && row.ImportExportStatus != SystemTypes.ImportExportStatusType.Failed) ? <Loading /> : <FontAwesomeIcon title={`Download ${row.IsExport ? 'Export' : 'Import'}`} className={'btn-icon-fa'} icon={faDownload} onClick={() => handleOnDownload(row)} /> }}
                onCustom2={{ action: (row) => row.Id == reloading ? <Loading /> : <FontAwesomeIcon title={`Rerun ${row.IsExport ? 'Export' : 'Import'}`} className={'btn-icon-fa'} icon={faRedo} onClick={() => handleOnReload(row)} /> }}
                checkDisableAction={handleCheckDisableAction}
            />
        </div>
    </>
}

export default ImportExportTable;