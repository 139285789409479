import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { useAppUserManager, useOrgManager } from "../../../../hooks/useManagers";
import { FormatUTCDate } from "../../../../utilities/DateFormater";



const RoleTable = ({ filteredItems, onEdit, onDelete }) => {
    const orgMgr = useOrgManager();
    const [organizations, setOrganizations] = useState(null);
    const appUserMgr = useAppUserManager();
    useEffect(() => {
        if (orgMgr) {
            orgMgr.loadOrgTree().then(r => {
                setOrganizations(orgMgr.OrgsOnly());
            });
        }
    }, [orgMgr]);


    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Organization',
            dataProp: 'OrganizationName',
            width: '20%',
            canSort: true
        },
        {
            header: 'Role',
            dataProp: "Role",
            width: '20%',
            canSort: true
        },
        {
            header: 'Name',
            dataProp: 'Name',
            width: '20%',
            canSort: true
        },
        {
            header: 'Precedence',
            dataProp: 'Sequence',
            width: '8%',
            canSort: true,
            sortBy: 'Sequence'
        },
        {
            header: 'Active?',
            dataProp: 'IsActive',
            width: '10%',
            canSort: true,
        },
        {
            header: 'Create Date',
            dataProp: 'CreatedDate',
            datatype: 'dateTime',
            width: '20%',
            canSort: true
        }
    ]);

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (c) => {
                newRows.push({
                    ...c,
                    OrganizationName: c.ClientId ? (organizations ? (_.find(organizations, x => x.OrgId === c.ClientId)?.Name ?? 'None') : '') : 'None'
                });
            });
            setRows(newRows);
        }
    }, [filteredItems, organizations]);

    const handleCheckDisableAction = (dataRecord, action) => {
        if (action === 'delete') {
            if (dataRecord.Role === 'SysAdmin' || dataRecord.Role === 'Basic' || dataRecord.Role === 'Anonymous' || dataRecord.Role === 'CertifiedTeacher' || dataRecord.Role === 'CertifiedSchoolAdmin') {
                return true;
            }
            else {
                return appUserMgr.GetGlobalState().rolePrecedence > dataRecord.Sequence;
            }
        }
        else if (action === "edit") {
            return appUserMgr.GetGlobalState().rolePrecedence > dataRecord.Sequence;
        }
        else {
            return false;
        }
    }

    return <>
        <div className="contentDisplay-list">
            <TableControl
                permission='Role'
                columns={columns}
                data={rows}
                useIntegratedPaging={['10', '25', '50', '100']}
                onEdit={onEdit}
                onDelete={onDelete}
                checkDisableAction={handleCheckDisableAction}
            />
        </div>
    </>
}

export default RoleTable;