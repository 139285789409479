import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APIStatusType } from '../../stores/globalAppUserStore';
import PublicHeader from '../layout/PublicHeader';
import { useAppUserManager } from '../../hooks/useManagers';
import { useHookstate } from '@hookstate/core';
import globalAuthStateStore from '../../stores/globalAuthStateStore';
import Login from './Login';

const AuthCallback = () => {
  const appUserMgr = useAppUserManager();
  const navigate = useNavigate();
  const [doLogin, setDoLogin] = useState(false);
  const authStore = useHookstate(globalAuthStateStore);
  const authState = authStore.get();
  useEffect(() => {
    if (appUserMgr != null && authState?.isAuthenticated) {
      const redirectUrl = localStorage.getItem("LastUri");
      if (appUserMgr?.AppUserState?.apiStatus == APIStatusType?.OnlineSecure) {
        console.log('redirectUrl',redirectUrl)
        if (redirectUrl)
          navigate(redirectUrl);
        else
          navigate(appUserMgr.defaultRoute());
      }
    }
    else if (appUserMgr == null && !authState?.isAuthenticated) {
      setDoLogin(true);
    }
  }, [authStore, appUserMgr]);

  return doLogin ? <Login /> : <PublicHeader />;
};

export default AuthCallback;
