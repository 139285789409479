import React, { useState } from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { SelectListControl } from '../../../../components/controls';

export default function TOSFilter({ tosMgr, onFilterChange }) {


    const handleFilterChange = (value, field) => {
        if (onFilterChange) {
            onFilterChange(field, value);
        }
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        tosMgr.filterData({ isActive: 'UNK' });
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">TOS Management</div>
                    <div className="filterControl">
                        <InputField id='IsActive'
                            title='Active?'
                            value={tosMgr?.Filter?.isActive ?? 'UNK'}
                            fieldName='isActive'
                            onChange={handleFilterChange}>
                            <SelectListControl textValuePairs={[{ text: 'Yes', value: true }, { text: 'No', value: false }]} />
                        </InputField>
                    </div>
                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}