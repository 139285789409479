import React, { useEffect, useState } from 'react';
import PrimaryDisplay from './primaryDisplay';
import ListDisplay from './listDisplay';
import { ContentTypeEnum } from '../../../services/ContentService';
import ContentManager from '../../../managers/ContentManager';
import _ from 'lodash'
import contentStore from '../../../stores/contentStore';
import { useHookstate } from '@hookstate/core';
import { useContentManager } from '../../../../../hooks/useManagers';

export default function ContentDisplay({ authMgr, updateContent, parentTags, displayMode, onLike, onDownload, onView, onShare }) {

    const [currentParentTags, setCurrentParentTags] = useState(parentTags);
    useEffect(() => {
        setCurrentParentTags(_.filter(parentTags, x => x.IsHidden === false));
    }, [parentTags])

    const contentMgr = useContentManager();

    const handleFullScreen = (content) => {
        if (content.ContentTypeId == ContentTypeEnum.LearningModule) {
            const url = `/learningmodules/${content.FileId}`;
            let articulateUrl;
            if (content.Metadata)
                articulateUrl = (url + "/" + content.Metadata);
            else
                articulateUrl = (url + "/story_html5.html");

            contentMgr?.SetContentViewed(content.ContentId);

            if (articulateUrl)
                window.open(articulateUrl, '');
        }
    };

    return (
        <>
            {displayMode == "primary" ?
                <PrimaryDisplay authMgr={authMgr} updateContent={updateContent} parentTags={currentParentTags} onLike={onLike} onDownload={onDownload} onView={onView} onShare={onShare} onFullScreen={handleFullScreen} /> :
                <ListDisplay authMgr={authMgr} updateContent={updateContent} onLike={onLike} onDownload={onDownload} onView={onView} onShare={onShare} onFullScreen={handleFullScreen} />}
        </>
    );
}
