import _, { set } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import "../MyDashboard.scss";
import ChartControl from "../../../../components/controls/ChartControl/ChartControl";
import { ReactComponent as QueryStatsIcon } from "../../../../content/icons/query-stats.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';



const ObservationDashboard = ({ dashboard, observationMgr }) => {
    const [loading, setLoading] = useState(true);
    const [observationCurrent, setObservationCurrent] = useState([]);
    const [observationHistroy, setObservationHistroy] = useState([]);
    const [refinementIndicators, setRefinementIndicators] = useState([]);
    const [reinforcementIndicators, setReinforcementIndicators] = useState([]);
    const [canSeeObservations, setCanSeeObservations] = useState(false);
    const [observationStatus, setObservationStatus] = useState("");

    useEffect(() => {
        if (dashboard && observationMgr) {
            setObservationCurrent(dashboard?.ObservationCurrent);
            setObservationHistroy(dashboard?.ObservationHistory);
            setCanSeeObservations(dashboard?.CanSeeObservations);
            let numCompleted = 0;
            let total = observationMgr.AppUserState.observationConfig.NumberOfTeacherEvals
            dashboard?.ObservationCurrent?.forEach(obs => { if (obs.Completed) numCompleted++ });
            setObservationStatus(`${numCompleted}/${total}`);
            setRefinementIndicators(dashboard?.RefinementIndicators?.map((i) => {
                return {
                    name: i?.IndicatorShortName,
                    value: i?.Count,
                }
            }));
            setReinforcementIndicators(dashboard?.ReinforcementIndicators?.map((i) => {
                return {
                    name: i?.IndicatorShortName,
                    value: i?.Count,
                }
            }));
            setLoading(false);
        }
    }, [dashboard, observationMgr]);


    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Date',
            dataProp: 'Date',
            width: '33%',
            datatype: 'date',
            canSort: true,
        },
        {
            header: 'Observer',
            dataProp: "Observer",
            width: '33%',
            canSort: true,
        },
        {
            header: 'Status',
            dataProp: 'Status',
            width: '34%',
            canSort: true,
        }
    ]);

    const [historyRows, setHistoryRows] = useState([]);
    const [historyColumns] = useState([
        {
            header: 'School Year',
            dataProp: 'SchoolYearName',
            width: '33%',
            canSort: true,
        },
        {
            header: 'Avg. Rating',
            dataProp: "AverageRating",
            width: '33%',
            canSort: true,
        },
        {
            header: 'Status',
            dataProp: 'Status',
            width: '34%',
            canSort: true,
        }
    ]);

    useEffect(() => {
        let newRows = [];
        if (observationCurrent) {
            _.each(observationCurrent, (c) => {
                newRows.push({
                    ...c,
                });
            });
            setRows(newRows);
        }
        let newHistoryRows = [];


        if (observationHistroy) {
            _.each(observationHistroy, (c) => {
                newHistoryRows.push({
                    ...c,
                });
            });
            setHistoryRows(newHistoryRows);
        }
    }, [observationCurrent, observationHistroy]);


    const handleObservationHistoryLineChart = () => {
        // TODO: handle what happens when the user clicks on the observation history line chart button
    }

    const handleObservationHistoryPrint = () => {
        // TODO: handle what happens when the user clicks on the observation history print button
    }

    return <>
        <div className="dashboard-section-wrapper">

            <div className="title">My Observations</div>
            <div className="dashboard-table">
                <TableControl
                    columns={columns}
                    data={rows}
                    defaultNumRows={3}
                    useIntegratedPaging={['3']}
                    hideActionColumn={true}
                    hideArrowSort={true}
                    useCustomCells={true}
                    useCustomHeader={true}
                    customBorder={true}
                    noDataText="No Observations Found."
                />
                {rows && rows.length > 0 && <>
                    <div className="status margin-right">{`Current Status: ${observationStatus}`}</div>
                    <div className="mt-4" />
                </>}

            </div>
        </div>
        <div className="dashboard-section-wrapper">
            <div className="title-and-icon">
                <div className="title mt-3">My Observation History</div>
                {/* <FontAwesomeIcon className={historyRows?.length > 0 ? '' : 'disabled'} icon={faPrint} style={{ fontSize: '14px' }} onClick={handleObservationHistoryPrint} />
                <QueryStatsIcon className={`query-stats-icon ${historyRows?.length > 0 ? '' : 'disabled'}`} onClick={handleObservationHistoryLineChart} /> */}
            </div>
            <div className="dashboard-table mb-3">
                <TableControl
                    columns={historyColumns}
                    data={historyRows}
                    defaultNumRows={3}
                    useIntegratedPaging={['3']}
                    hideActionColumn={true}
                    hideArrowSort={true}
                    useCustomCells={true}
                    useCustomHeader={true}
                    useCustomFooter={true}
                    customBorder={true}
                    noDataText="No Observation History Found."
                />
            </div>
        </div>
        <div className="dashboard-section-wrapper chart-wrapper">
            <div className="title mt-3">My Reinforcement History</div>
            <div className="charts">
                {
                    !loading && reinforcementIndicators && reinforcementIndicators.length > 0 ?
                        <ChartControl
                            title="Observation History"
                            type="dashboardPie"
                            data={reinforcementIndicators}
                            fixedHeight={"100%"}
                            fixedWidth={'100%'}
                            style={{
                                sorted: true,
                                color: "#003c5b",
                                legend: {
                                    left: '55%'
                                }
                            }}
                            legend="left"
                        />
                        :
                        <div className="no-records">
                            <div>
                                <h5>No Reinforcement History Found.</h5>
                            </div>
                        </div>
                }
            </div>
            <div className="title mt-3">My Refinement History</div>
            <div className='charts'>
                {
                    !loading && refinementIndicators && refinementIndicators.length > 0 ?
                        <ChartControl
                            title="Observation History"
                            type="dashboardPie"
                            data={refinementIndicators}
                            fixedHeight={"100%"}
                            fixedWidth={'100%'}
                            style={{
                                sorted: true,
                                color: "#003c5b",
                                legend: {
                                    left: '55%'
                                }
                            }}
                            legend="left"
                        />
                        :
                        <div className="no-records">
                            <div>
                                <h5>No Refinement History Found.</h5>
                            </div>
                        </div>
                }
            </div>
        </div>
    </>
}

export default ObservationDashboard;