import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import SurveyTemplateDetails from '../components/surveyTemplate/details';
import SurveyTemplateFilter from '../components/surveyTemplate/filter';
import SurveyTemplateTable from '../components/surveyTemplate/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useOrgManager, useSurveyTemplateManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';

const SurveyTemplateMgmt = () => {
    const surveyTemplateMgr = useSurveyTemplateManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedSurveyTemplate, setSelectedSurveyTemplate] = useState(null);
    const [isSurveyTemplateActive, setIsSurveyTemplateActive] = useState(false)
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);

    const params = useParams();
    const navigate = useNavigate();
    const orgMgr = useOrgManager();
    const [organizations, setOrganizations] = useState(null);

    useEffect(() => {
        if (orgMgr) {
            orgMgr.loadOrgTree().then(() => {
                setOrganizations(orgMgr.OrgsOnly());
            });
        }
    }, [orgMgr]);



    useEffect(() => {
        if (surveyTemplateMgr && !params?.surveyTemplateId) {
            setLoading(true);
            surveyTemplateMgr.search().then(fb => {
                setManageMode('list');
                setLoading(false);
                setIsSurveyTemplateActive(false)
            });
        }
        else if (!selectedSurveyTemplate && params?.surveyTemplateId && surveyTemplateMgr) {
            setLoading(true);
            if (params?.surveyTemplateId.toLowerCase() === 'addnew') {
                setSelectedSurveyTemplate(surveyTemplateMgr.DefaultItem);
                setManageMode('view');
                setLoading(false);
            }
            else {
                fetchExistingSurveyTemplate()
            }
        }
    }, [surveyTemplateMgr, params?.surveyTemplateId]);

    const fetchExistingSurveyTemplate = async () => {
        const promise1 = surveyTemplateMgr.isSurveyTemplateActive(params?.surveyTemplateId).then((r) => {
            if (!_.isEmpty(r)) {
                setIsSurveyTemplateActive(true)
            }
        })
        const promise2 = surveyTemplateMgr.get(params?.surveyTemplateId).then((r) => {
            if (r.Success) {
                setManageMode('view');
                setSelectedSurveyTemplate(r.Items.first());
            }
        });
        await Promise.all([promise1, promise2])
        setLoading(false);
    }

    const onAddNew = () => {
        setSelectedSurveyTemplate(surveyTemplateMgr.DefaultItem);
        setManageMode('view');
        navigate('/admin/surveyTemplate/addnew');
    }

    const handleOnFilterChange = async (field, value) => {
        const surveyTemplateFilter = surveyTemplateMgr.Filter;
        const updatedSurveyTemplateFilter = { ...surveyTemplateFilter };
        updatedSurveyTemplateFilter[field] = value !== '' ? value : null;
        await surveyTemplateMgr.filterData(updatedSurveyTemplateFilter);
    }

    const handleOnSave = async (item) => {
        var result = await surveyTemplateMgr.saveItem(item);
        return result;
    }

    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        surveyTemplateMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.SurveyTemplate}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnEdit = (item) => {
        setLoading(true)
        setSelectedSurveyTemplate(item);
        setManageMode('view');
        navigate(`/admin/surveyTemplate/${item.Id}`);
        surveyTemplateMgr.isSurveyTemplateActive(item.Id).then((r) => {
            if (!_.isEmpty(r)) {
                setIsSurveyTemplateActive(true)
            }
            setLoading(false)
        })
    }


    const handleOnCancel = () => {
        navigate('/admin/surveyTemplate');
        if (surveyTemplateMgr.FilteredData) {
            setManageMode('list');
        }
        else {
            setManageMode(null);
        }
        setIsSurveyTemplateActive(false)
        setLoadingError(null);
        setErrors(null);
    }

    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete the survey you are building named '${itemToDelete?.Title}' ? `} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <MainLayout errors={errors} validationErrors={validationErrors} >
            {manageMode === 'view' &&
                <div className='screen-header'>
                    <h3>Survey Builder</h3>
                    <h5>{`${selectedSurveyTemplate?.Id ? 'Edit' : 'Build a new'}`} Survey </h5>
                </div>
            }
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <SurveyTemplateFilter organizations={organizations} surveyTemplateMgr={surveyTemplateMgr} onFilterChange={handleOnFilterChange} />
                            <SurveyTemplateTable organizations={organizations} surveyTemplateMgr={surveyTemplateMgr} filteredItems={surveyTemplateMgr?.FilteredData ?? []} onEdit={handleOnEdit} onDelete={(item) => { setItemToDelete(item); }} />
                        </div>
                        <div className='screen-footer list'>
                            {surveyTemplateMgr.CanWrite && <ButtonControl type={'create'} onClick={onAddNew}>Build Survey</ButtonControl>}
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&
                    <SurveyTemplateDetails isSurveyTemplateActive={isSurveyTemplateActive} canWrite={surveyTemplateMgr.CanWrite} organizations={organizations} surveyTemplateMgr={surveyTemplateMgr} surveyTemplate={selectedSurveyTemplate} onCancel={handleOnCancel} onSave={handleOnSave} onLoading={setLoading} onErrors={setErrors} onValidationErrors={setValidationErrors} onLoadingError={setLoadingError} />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default SurveyTemplateMgmt;