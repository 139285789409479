
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './extentions.js';
import App from './App';
import reportWebVitals from './utilities/reportWebVitals';
import { ThemeProvider } from './contexts/ThemeProvider';
import { AuthenticationProvider } from './contexts/AuthProvider';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AuthenticationProvider>
      <ThemeProvider>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </AuthenticationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//Extentions


