

//https://blog.logrocket.com/simplify-react-state-management-with-hookstate/
import { hookstate } from '@hookstate/core'

const contentStore = hookstate({
    allContent: null,
    contentFilter: { tagIds: [], contentTypeIds: [], search: null },
    filteredContent: [],
    avaliableContentFilters: null,
    selectedContent: null,
    forceContentUpdate: false,
    forceSharedContentUpdate: false,
    sharedContent: null,
    selectedSharedContent: null,
    avaliableShareContentFilters: null,
    shareContentFilter: { tagIds: [], contentTypeIds: [], search: null },
    shareFilteredContent: null,
});
export default contentStore;