import { useEffect, useState } from 'react';
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import { LabelControl, SelectListControl } from '../../../../components/controls';
import Loading from '../../../../components/Loading';
import '../../payment.scss';
import './styles/payoutSettings.scss';
import AdminObservationDomainWeights from './settings/AdminObservationDomainWeights';
import AdminObserverWeights from './settings/AdminObserverWeights';
import AdminWeightedPerformanceMetrics from './settings/AdminWeightedPerformanceMetrics';
import _ from 'lodash';
import { SelectPicker } from 'rsuite';
import { useNavigate, useLocation } from 'react-router-dom';
import { CheckboxControl } from '../../../../components/controls';
import { DialogControl } from '../../../../components/controls/DialogControl';

const PayoutAdminSettings = ({ setPayoutAdminConfigMetricsData, recipientResults, handleOnComplete, setPayoutConfig, payoutMgr, appUserMgr, payoutInfo, payoutId, payoutConfig, savePayoutUsers, setValidationErrors, onPrevious, onCaluclate }) => {
    const [loading, setLoading] = useState(true);
    const [selectedDistrict, setSelectedDistrict] = useState();
    const [payoutConfigSettings, setPayoutConfigSettings] = useState();
    const [adminObserverWeightData, setAdminObserverWeightData] = useState();
    const [adminObservationDomainWeightData, setAdminObservationDomainWeightData] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [formValues, setFormValues] = useState();
    const [forceValidation, setForceValidation] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedPayoutConfigId, setSelectedPayoutConfigId] = useState();
    const [selectedSchoolConfig, setSelectedSchoolConfig] = useState();
    const [schoolPayoutConfigDataSet, setSchoolPayoutConfigDataSet] = useState([]);
    const [customMetricWasDeleted, setCustomMetricWasDeleted] = useState(false)
    const [applySettingsToDistrict, setApplySettingsToDistrict] = useState(false);
    const [openSaveCompleteDialog, setOpenSaveCompleteDialog] = useState(false);
    const location = useLocation();
    const isMetricsPage = location.pathname.includes('Metrics');
    const navigate = useNavigate();

    const [payoutConfigMetrics, setPayoutConfigMetrics] = useState(); //panels that can they can add custom metrics
    const [payoutWeightedPerformanceRatios, setPayoutWeightedPerformanceRatios] = useState(); //associated with top part of dollar allocation

    // FOR VALIDATIONS //
    useEffect(() => {
        if (payoutMgr && payoutConfig) {
            let ignore = false;
            async function fetchData() {
                if (!ignore) {
                    payoutMgr._payoutAdminConfigurationItemMgr.query("x => x.SchoolYearIdentifier == sy && x.IsActive == true && x.IsDeleted == false && x.ClientId == clientId && x.DistrictId != districtId", { sy: payoutMgr.AppUserState.selectedSchoolYear, clientId: payoutConfig.PayoutAdminConfiguration.ClientId, districtId: payoutConfig.PayoutAdminConfiguration.DistrictId }).then(configs => {
                        if (configs.Items.length > 0) {
                            //sort through and pull out Id, clientId, schoolId, schoolYearId
                            let arr = []
                            configs.Items.forEach(c => {
                                let newConfig = {
                                    ...c,
                                    label: c.District?.Name,
                                    value: c.Id
                                }
                                arr.push(newConfig);
                            })
                            //push into data set of drop down
                            setSchoolPayoutConfigDataSet(arr);
                            setIsLoading(false);
                        } else {
                            setSchoolPayoutConfigDataSet(configs.Items);
                            setIsLoading(false)
                        }
                    });
                    setSelectedPayoutConfigId(payoutConfig.PayoutAdminConfiguration?.Id);
                    setPayoutConfigSettings(payoutConfig.PayoutAdminConfiguration);
                    setAdminObserverWeightData(payoutConfig.PayoutAdminObserverWeights);
                    setAdminObservationDomainWeightData(payoutConfig.PayoutAdminObservationDomainWeights);
                    setPayoutConfigMetrics(_.orderBy(payoutConfig.PayoutAdminConfigurationMetric, ['Sequence'], ['Desc']));
                    setPayoutWeightedPerformanceRatios(payoutConfig.PayoutAdminWeightedPerformanceRatio);
                }
            }
            fetchData();
            return () => { ignore = true }; //cleanup
        }
    }, [payoutMgr, payoutConfig])

    useEffect(() => {
        if (selectedSchoolConfig) {
            let configObj = schoolPayoutConfigDataSet.find(x => x.Id === selectedSchoolConfig);
            loadPayoutConfig(configObj.ClientId, configObj.DistrictId, configObj.SchoolId);
        }
    }, [selectedSchoolConfig])

    const loadPayoutConfig = (clientId, districtId, schoolId) => {
        setIsLoading(true)
        payoutMgr.GetAdminPayoutConfiguration(clientId, districtId, schoolId).then((response) => {
            if (response.Items?.length > 0) {
                setIsLoading(false);
                let newPayoutConfig = response.Items[0];

                setPayoutConfigSettings({ ...newPayoutConfig.PayoutAdminConfiguration, Id: payoutConfig.Id, UniqueId: payoutConfig.Id, _id: payoutConfig.Id });
                setAdminObserverWeightData(newPayoutConfig.PayoutAdminObserverWeights.map(x => { return { ...x, PayoutAdminConfigurationId: payoutConfig.Id } }));
                // setAdminObservationDomainWeightData(newPayoutConfig.PayoutAdminObservationDomainWeights.map(x => { return { ...x, PayoutAdminConfigurationId: payoutConfig.Id } }));
                setPayoutConfigMetrics(_.orderBy(newPayoutConfig.PayoutAdminConfigurationMetric.map(x => {
                    return {
                        ...x,
                        PayoutAdminConfigurationId: payoutConfig.Id,
                        PayoutAdminPrincipalScoreRatios: x.PayoutAdminPrincipalScoreRatios.map(y => { return { ...y, PayoutAdminConfigurationId: payoutConfig.Id } }),
                        PayoutAdminAssistantPrincipalScoreRatios: x.PayoutAdminAssistantPrincipalScoreRatios.map(y => { return { ...y, PayoutAdminConfigurationId: payoutConfig.Id } })
                    }
                }), ['Sequence'], ['Desc']));
                setPayoutWeightedPerformanceRatios({ ...newPayoutConfig.PayoutAdminWeightedPerformanceRatio, Id: payoutConfig.Id, UniqueId: payoutConfig.Id, _id: payoutConfig.Id });
            }
        });
    }
    const handleOnSave = async (isCalculate) => {
        const errors = validationCheck();

        if (errors.length === 0) {
            setSaving(true);
            const payoutAdminConfig = {
                Id: payoutConfigSettings?.Id,
                PayoutAdminConfiguration: payoutConfigSettings,
                PayoutAdminObserverWeights: adminObserverWeightData,
                PayoutAdminObservationDomainWeights: adminObservationDomainWeightData,
                PayoutAdminConfigurationMetric: payoutConfigMetrics,
                PayoutAdminWeightedPerformanceRatio: payoutWeightedPerformanceRatios,
                ApplySettingsToAllSchoolsInDistrict: applySettingsToDistrict
            }
            await updatePayoutUsers();
            const result = await payoutMgr.SavePayoutAdminConfiguration(payoutAdminConfig);
            if (result.Success && !isCalculate) {
                setSaving(false);
                setOpenSaveCompleteDialog(true);
                setPayoutConfig(result.Items.first())
                setPayoutAdminConfigMetricsData(result.Items.first().PayoutAdminConfigurationMetric)
                return true
            } else if (result.Success) {
                return true
            }
            else {
                setErrorMessage([result.Message.DisplayMessage]);
                setSaving(false);
            }
        }
        else {
            setValidationErrors(errors)
        }
    }


    const validationCheck = () => {
        let errors = []
        if (_.sumBy(adminObserverWeightData, x => parseInt(x.SurveyWeight)) !== 100) {
            errors.push('Observer Weights for surveys does not total to 100')
        }
        if (_.sumBy(adminObserverWeightData, x => parseInt(x.NonSurveyWeight)) !== 100) {
            errors.push('Observer Weights for non-surveys does not total to 100')
        }
        if (_.sumBy(_.flatten(Object.values(adminObservationDomainWeightData)), x => parseInt(x.Weight)) !== 100) {
            errors.push("Observer Domain Weights does not total to 100")
        }
        return errors
    }
    const handleOnCalculate = async () => {
        const errors = validationCheck();
        //Update all payout users if we deleted a customMetric.
        if (customMetricWasDeleted) {
            await updatePayoutUsers()
        }
        if (errors.length === 0) {
            const result = await onCaluclate(formValues);
            setSaving(false)
        }
        else {
            setSaving(false)
            setValidationErrors(['Unable to calculate payout.'])
        }
    }
    const updatePayoutUsers = async () => {
        const updatedPayoutUsers = recipientResults.map(x => {
            const objEntries = Object.entries(x).map(([key, value], i) => {
                if (payoutConfigMetrics.find(y => key === `CustomMetric${y.Sequence}`)?.IsActive === false) {
                    return [key, null]
                } else return [key, value]
            })
            return Object.fromEntries(objEntries)
        })
        return await savePayoutUsers(updatedPayoutUsers, true)
    }

    const handleSchoolConfigSelect = (value) => {
        setSelectedSchoolConfig(value)
        if (!value) {
            loadPayoutConfig(payoutInfo.ClientId, payoutInfo.DistrictId)
        }
    }

    const handleMetricPrevious = () => {
        navigate(`/admin/school/${payoutInfo?.School?.Id}`);
    }

    const handleOnCancel = () => {
        if(isMetricsPage) {
            navigate(`/admin/school/${payoutInfo?.School?.Id}`);
        } else {
            navigate(`/admin/Payout/Admin`);
        }
    }

    return <>
        <div className='payout-settings'>
            <div className='payout-admin-container'>
                <DialogControl title={`Configuration Saved`}
                    subTitle={"The Metric Coniguration has been saved successfully. Click OK to return to the previous page."}
                    openDialog={openSaveCompleteDialog}
                    okText={`OK`}
                    onCancel={() => {
                        setOpenSaveCompleteDialog(false)
                    }}
                    onOk={() => handleOnCancel()}>
                </DialogControl>
                <div className='title-container'>
                    <div className='payout-title'>
                        <h2>School Administrator Metrics Configuration</h2>
                        <h5>{payoutInfo?.Client?.Name} &gt; {payoutInfo?.District?.Name} &gt; {payoutInfo?.School?.Name}</h5>
                    </div>
                    <div className='school-copy-dropdown'>
                        <div className='school-copy-title'>Use Existing School Configuration: </div>
                        <SelectPicker data={schoolPayoutConfigDataSet} searchable={false} value={selectedSchoolConfig} onChange={handleSchoolConfigSelect} className={'picker-style'} />
                    </div>
                </div>

                {!isLoading ?
                    <>
                        <div className='observer-container'>
                            <AdminObserverWeights data={adminObserverWeightData} setData={updatedData => setAdminObserverWeightData(updatedData)} />
                        </div>
                        <div className='domain-container'>
                            <AdminObservationDomainWeights data={adminObservationDomainWeightData} setData={updatedData => setAdminObservationDomainWeightData(updatedData)} />
                        </div>
                        <div className='weighted-ratios-container'>
                            <AdminWeightedPerformanceMetrics
                                payoutConfigMetrics={payoutConfigMetrics}
                                payoutWeightedPerformanceRatios={payoutWeightedPerformanceRatios}
                                configId={selectedPayoutConfigId}
                                setPayoutConfigMetrics={updatedData => setPayoutConfigMetrics(updatedData)}
                                setWeightedPerformanceRatios={updatedData => setPayoutWeightedPerformanceRatios(updatedData)}
                                setCustomMetricWasDeleted={setCustomMetricWasDeleted}
                            />
                        </div>
                        {error &&
                            <div className='error-msg'>*{errorMessage}</div>
                        }
                        <div className='button-container'>
                            {isMetricsPage &&
                                <>
                                <div className='apply-all-settings-container'>
                                    <InputField
                                        title={`Apply Settings to All Schools in ${payoutInfo?.District?.Name}?`}
                                        value={applySettingsToDistrict}
                                        disableError={true}
                                        fieldName="applySettingsToDistrict"
                                        onChange={() => setApplySettingsToDistrict(!applySettingsToDistrict)}
                                    >
                                        <CheckboxControl />
                                    </InputField>
                                </div>
                                </>
                            }
                            <div className='btn-wrapper-left screen-footer__btn'>
                                <ButtonControl disabled={saving} type={'cancel'} onClick={isMetricsPage ? handleMetricPrevious : onPrevious}>Previous</ButtonControl>
                            </div>
                            <div className='btn-wrapper-left screen-footer__btn'>
                                <ButtonControl loading={saving} type={'okay'} onClick={() => handleOnSave()}>Save</ButtonControl>
                            </div>
                            {!isMetricsPage &&
                                <>
                                <div className='btn-wrapper-left screen-footer__btn'>
                                    <ButtonControl loading={saving} type={'cancel'} onClick={async () => {
                                        let result = await handleOnSave(true);
                                        if (result) {
                                            handleOnCalculate();
                                        }
                                    }}>Calculate</ButtonControl>
                                </div>
                                {appUserMgr?.canExecute('CompletePayoutAdmin') && <div className='btn-wrapper-left screen-footer__btn'>
                                    <ButtonControl loading={saving} disabled={(payoutInfo.PayoutStatus == "Completed" || payoutInfo.PayoutStatus == 3)} type={'okay'} onClick={async () => {
                                        setSaving(true)
                                        await handleOnComplete();
                                        setSaving(false)

                                    }}>Complete</ButtonControl>
                                    </div>
                                }
                                </>
                            }

                        </div>
                    </>
                    :
                    <>
                        <div style={{ textAlign: 'center', marginTop: '24rem' }}>
                            <Loading type='default' size={'5rem'} /><h5 style={{ paddingTop: '1rem' }}>Loading data...</h5>
                        </div>
                    </>
                }
            </div>
        </div>
    </>
}

export default PayoutAdminSettings;