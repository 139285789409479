import { React, useEffect, useState } from 'react';
import { Panel } from 'rsuite';
import { InputField } from '../../../../../components/controls/InputField';
import { SelectPicker } from 'rsuite';
import './../styles/observerWeights.scss';
import { width } from '@mui/system';
import groupBySet from '../../../../dev/data/groupByPayout';
import _ from 'lodash';

const ObserverWeights = ({ data, setData, setTotalObservedWeights, totalObservedWeights }) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [observerWeightData, setObserverWeightData] = useState();
    const [administratorWeightData, setAdministratorWeightData] = useState();
    const [masterTeacherWeightData, setMasterTeacherWeightData] = useState();
    const [mentorTeacherWeightData, setMentorTeacherWeightData] = useState();
    const [selfEvalWeightData, setSelfEvalWeightData] = useState();

    useEffect(() => {
        if (data) {
            let adminRows = data.filter(x => x.ObserverPositionTypeName === 'Administration');
            adminRows = sortObserverData(adminRows);
            setAdministratorWeightData(adminRows);

            let masterTeacherRows = data.filter(x => x.ObserverPositionTypeName === 'Master Teacher');
            masterTeacherRows = sortObserverData(masterTeacherRows);
            setMasterTeacherWeightData(masterTeacherRows);

            let mentorTeacherRows = data.filter(x => x.ObserverPositionTypeName === 'Mentor Teacher');
            mentorTeacherRows = sortObserverData(mentorTeacherRows);
            setMentorTeacherWeightData(mentorTeacherRows);

            let selfEvalRows = data.filter(x => x.ObserverPositionTypeName === 'Self Evaluation');
            selfEvalRows = sortObserverData(selfEvalRows);
            setSelfEvalWeightData(selfEvalRows);


            let groupedByObserved = _.groupBy(data, x => x.ObservedPositionTypeName)
            for (let observed in groupedByObserved) {
                let groupByList = []
                for (let item of _.orderBy(groupedByObserved[observed], ['ObserverPositionTypeName'], ['asc'])) {
                    if (!groupByList.includes(item.GroupedBy)) {
                        groupByList.push(item.GroupedBy)
                        item.isDisabled = false;
                    } else {
                        item.isDisabled = true;
                    }
                }
            }

            const careerTeacherTotal = _.sumBy(_.filter(groupedByObserved['Career Teacher'], y => !y.isDisabled), x => x.Weight)
            const mentorTeacherTotal = _.sumBy(_.filter(groupedByObserved['Mentor Teacher'], y => !y.isDisabled), x => x.Weight)
            const masterTeacherTotal = _.sumBy(_.filter(groupedByObserved['Master Teacher'], y => !y.isDisabled), x => x.Weight)

            setTotalObservedWeights([careerTeacherTotal, mentorTeacherTotal, masterTeacherTotal])
            setObserverWeightData(data);
        }
    }, [data]);


    const sortObserverData = (dataSet) => {
        const order = ["Career Teacher", "Mentor Teacher", "Master Teacher"];

        dataSet.sort((a, b) => {
            const positionA = order.indexOf(a.ObservedPositionTypeName);
            const positionB = order.indexOf(b.ObservedPositionTypeName);

            if (positionA === -1 && positionB === -1) {
                return 0;
            } else if (positionA === -1) {
                return 1;
            } else if (positionB === -1) {
                return -1;
            } else {
                return positionA - positionB;
            }
        });

        return dataSet;
    }

    const handleOnChange = (value, observedPosition, observerPosition) => {
        if (_.isNil(value) || value === '') {
            value = 0
        }
        const updatedObserverWeightData = [...data];
        let updatedPosition = updatedObserverWeightData.find(x => x.ObservedPositionTypeName == observedPosition && x.ObserverPositionTypeName == observerPosition);
        updatedPosition["Weight"] = parseInt(value);
        setData(updatedObserverWeightData);
    }

    const handleGroupBySelect = (value, observedPosition, observerPosition) => {
        const updatedObserverWeightData = [...data];
        let updatedPosition = updatedObserverWeightData.find(x => x.ObservedPositionTypeName === observedPosition && x.ObserverPositionTypeName === observerPosition);

        updatedPosition["GroupedBy"] = value;

        setData(updatedObserverWeightData);
    }
    return (
        <>
            <Panel header="Observer Weights" bordered>
                <div className='observer-table'>
                    <table className="observer-weights-table">
                        <thead>
                            <tr className='main-header'>
                                <th className='blank-header'></th>
                                <th>Career Teacher</th>
                                <th>Mentor Teacher</th>
                                <th>Master Teacher</th>
                            </tr>
                            <tr className='sub-header'>
                                <th className='blank-header-titles'></th>
                                <th style={{marginRight: ".6rem"}}>Weight</th>
                                <th style={{marginRight: "2rem"}}>Group By</th>
                                <th style={{marginRight: ".5rem"}}>Weight</th>
                                <th style={{marginRight: "2rem"}}>Group By</th>
                                <th style={{marginRight: ".6rem"}}>Weight</th>
                                <th>Group By</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='row-header'>Administrator</td>
                                {administratorWeightData?.map((item, i) => {
                                    return <>
                                        <td>
                                            <InputField
                                                className={item.isDisabled ? 'isDisabled' : ''}
                                                id={`admin-${i}`}
                                                value={item?.Weight}
                                                placeholder={'0'}
                                                type={'number'}
                                                isDisabled={item.isDisabled}
                                                onChange={(value) => handleOnChange(value, item.ObservedPositionTypeName, item.ObserverPositionTypeName)}
                                                disableError={true} />
                                        </td>
                                        <td>
                                            <div className='group-by-picker'>
                                                <SelectPicker
                                                    id={`admin-${i}`}
                                                    data={groupBySet}
                                                    searchable={false}
                                                    value={item?.GroupedBy}
                                                    onChange={(value) => handleGroupBySelect(value, item.ObservedPositionTypeName, item.ObserverPositionTypeName)}
                                                    className={'picker-style'} />
                                            </div>
                                        </td>
                                    </>
                                }
                                )}
                            </tr>
                            <tr>
                                <td className='row-header'>Master Teacher</td>
                                {masterTeacherWeightData?.map((item, i) => (
                                    <>
                                        <td>
                                            <InputField
                                                id={`masterteacher-${i}`}
                                                className={item.isDisabled ? 'isDisabled' : ''}
                                                value={item?.Weight}
                                                placeholder={'0'}
                                                type={'number'}
                                                isDisabled={item.isDisabled}
                                                onChange={(value) => handleOnChange(value, item.ObservedPositionTypeName, item.ObserverPositionTypeName)}
                                                disableError={true} />
                                        </td>
                                        <td>
                                            <div className='group-by-picker'>
                                                <SelectPicker
                                                    id={`masterteacher-${i}`}
                                                    data={groupBySet}
                                                    searchable={false}
                                                    value={item?.GroupedBy}
                                                    onChange={(value) => handleGroupBySelect(value, item.ObservedPositionTypeName, item.ObserverPositionTypeName)}
                                                    className={'picker-style'} />
                                            </div>
                                        </td>
                                    </>
                                ))}
                            </tr>
                            <tr>
                                <td className='row-header'>Mentor Teacher</td>
                                {mentorTeacherWeightData?.map((item, i) => (
                                    <>
                                        <td>
                                            <InputField
                                                id={`mentorteacher-${i}`}
                                                className={item.isDisabled ? 'isDisabled' : ''}
                                                value={item?.Weight}
                                                placeholder={'0'}
                                                type={'number'}
                                                isDisabled={item.isDisabled}
                                                onChange={(value) => handleOnChange(value, item.ObservedPositionTypeName, item.ObserverPositionTypeName)}
                                                disableError={true} />
                                        </td>
                                        <td>
                                            <div className='group-by-picker'>
                                                <SelectPicker
                                                    id={`mentorteacher-${i}`}
                                                    data={groupBySet}
                                                    searchable={false}
                                                    value={item?.GroupedBy}
                                                    onChange={(value) => handleGroupBySelect(value, item.ObservedPositionTypeName, item.ObserverPositionTypeName)}
                                                    className={'picker-style'} />
                                            </div>
                                        </td>
                                    </>
                                ))}
                            </tr>
                            <tr>
                                <td className='row-header'>Self Evaluation</td>
                                {selfEvalWeightData?.map((item, i) => (
                                    <>
                                        <td>
                                            <InputField
                                                id={`self-eval-${i}`}
                                                className={item.isDisabled ? 'isDisabled' : ''}
                                                value={item?.Weight}
                                                placeholder={'0'}
                                                type={'number'}
                                                isDisabled={item.isDisabled}
                                                onChange={(value) => handleOnChange(value, item.ObservedPositionTypeName, item.ObserverPositionTypeName)}
                                                disableError={true} />
                                        </td>
                                        <td>
                                            <div className='group-by-picker'>
                                                <SelectPicker
                                                    id={`self-eval-${i}`}
                                                    data={groupBySet}
                                                    searchable={false}
                                                    value={item?.GroupedBy}
                                                    onChange={(value) => handleGroupBySelect(value, item.ObservedPositionTypeName, item.ObserverPositionTypeName)}
                                                    className={'picker-style'} />
                                            </div>
                                        </td>
                                    </>
                                ))}
                            </tr>
                            <tr>
                                <td className='row-header'></td>
                                {totalObservedWeights.map((item, i) => {
                                    return <>
                                        <td className='total-weight' key={`total-${i}}`}>
                                            Total: {item}
                                        </td>
                                        <td />
                                    </>
                                })}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Panel>
        </>
    );
}

export default ObserverWeights;