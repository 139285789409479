import React from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { SelectListControl } from '../../../../components/controls';

export default function PermissionFilter({ permissionMgr, onFilterChange }) {
    const handleSearchChange = (value) => {
        if (onFilterChange)
            onFilterChange("search", value);
    }

    const handlePermissionTypeChange = (value) => {
        if (onFilterChange)
            onFilterChange("permissionType", value);
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        permissionMgr.filterData({ search: null, permissionType: null });
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Permission Management</div>
                    <div className="filterControl">
                        <InputField
                            id='permission'
                            title='Search'
                            value={permissionMgr?.Filter?.search ?? ''}
                            fieldName='search'
                            disableError={true}
                            placeholder='Permission'
                            onChange={handleSearchChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField id='permissionType'
                            title='Permission Type'
                            value={permissionMgr?.Filter?.permissionType ?? 'UNK'}
                            fieldName='permissionType'
                            disableError={true}
                            onChange={handlePermissionTypeChange}>
                            <SelectListControl textValuePairs={[{ text: 'Data', value: 0 }, { text: 'API', value: 1 }, { text: 'UX', value: 2 }]} />
                        </InputField>
                    </div>
                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}