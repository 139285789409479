import { useEffect, useState } from 'react';
import _ from 'lodash';
import Loading from '../../../../components/Loading';
import BitmovinPlayer from '../../../contentLibrary/app-components/Content/Player/BitmovinPlayer';

const GeneralSurveyResults = ({ data, rubricData, rubricLoading }) => {
    const [surveyQnA, setSurveyQnA] = useState(null)
    const [rubricResults, setRubricResults] = useState(null)
    useEffect(() => {
        if (data) {

            const formattedSurveyQnA = _.reduce(_.orderBy(data.SurveyTemplateFields, ['Sequence'], ['asc']), (newMap, x) => {
                const surveyItemDetails = _.find(data.Sections, ds => ds.SurveyTemplateFieldId === x.Id)
                const surveyItem = {
                    FieldDisplayText: x.DisplayText,
                    Value: x.SurveyFieldType == 3 ? surveyItemDetails?.Value?.split(',') : surveyItemDetails?.Value,
                    SurveyContents: surveyItemDetails?.SurveyContents?.first()
                }
                newMap.push(surveyItem)
                return newMap
            }, [])
            setSurveyQnA(formattedSurveyQnA)
        }
    }, [data])

    useEffect(() => {
        if (rubricData) {
            setRubricResults(_.map(rubricData.data, x => {
                return {
                    FieldDisplayText: x.tag.name,
                    Value: x.selectedRating,
                    Description: x.rubric[x.selectedRating] ?? 'No Description Found'
                }
            }))
        }
    }, [rubricData])

    const checkIsVideo = (fileUrl) => {
        return (fileUrl?.toLowerCase()?.includes('.mpeg') ||
            fileUrl?.toLowerCase()?.includes('.mpg') ||
            fileUrl?.toLowerCase()?.includes('.mpga') ||
            fileUrl?.toLowerCase()?.includes('.avi') ||
            fileUrl?.toLowerCase()?.includes('.mp4') ||
            fileUrl?.toLowerCase()?.includes("streaming.media.azure.net") || 
            fileUrl?.toLowerCase()?.includes("bitmovin-media-files")
        );
    }


    const getFileUri = (fileUri) => {
        if (!fileUri.toLowerCase().includes(".png") &&
            !fileUri.toLowerCase().includes(".jpeg") &&
            !fileUri.toLowerCase().includes(".jpg") &&
            !fileUri.toLowerCase().includes(".gif") &&
            !fileUri.toLowerCase().includes(".tiff") &&
            !fileUri.toLowerCase().includes(".mp4") &&
            !fileUri.toLowerCase().includes(".mpeg") &&
            !fileUri.toLowerCase().includes(".avi") &&
            !fileUri.toLowerCase().includes(".mov") &&
            !fileUri.toLowerCase().includes("streaming.media.azure.net") &&
            !fileUri.toLowerCase().includes("bitmovin-media-files")
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    const renderSurveyFields = (field, i) => {
        return <div className="review-row" key={i}>
            <div className="item-question name">
                {field?.FieldDisplayText}
            </div>

            {!_.isNil(field?.SurveyContents) ?
                <div className="item-value-wrapper rating">
                    {checkIsVideo(field?.SurveyContents?.File?.FileUrl) ?
                        <BitmovinPlayer content={field?.SurveyContents?.File} />                        
                         :
                        <figure className='img-wrapper' >
                            {getFileUri(field?.SurveyContents?.File?.FileUrl) ? <>{field?.Value}</> : <img src={field?.SurveyContents?.File?.FileUrl} alt="" />}
                        </figure>
                    }
                </div>
                :
                <div className="item-value-wrapper rating">
                    {Array.isArray(field?.Value) ? field?.Value?.first().replace(';', ', ') : field.Value}
                </div>}
        </div>
    }

    const renderRubricFields = (field, i) => {
        return <div className="review-row" key={i}>
            <div className="item-question name rubric-name ">
                {field?.FieldDisplayText}
            </div>
            <div className="item-value-wrapper rating rubric-rating" dangerouslySetInnerHTML={{ __html: field.Description }}>
            </div>
            <div className="item-value-wrapper rating rubric-rating">
                {field.Value}
            </div>
        </div>
    }
    //Document or Video player
    return <>
        {data && <>
            <div className="survey-container">
                {!_.isEmpty(surveyQnA) && <div className="survey">
                    <div className="review-header">
                        <div className=" review-header name ">
                            Survey Questions
                        </div>
                        <div className="rating">
                            Responses
                        </div>
                    </div>
                    {_.map(surveyQnA, (field, i) => {
                        return renderSurveyFields(field, i)
                    })}
                </div>}
                {data?.ShowRubric && rubricLoading !== 'NoRubric' && <div className="rubric">
                    <div className="review-header">
                        <div className=" review-header name rubric-name">
                            Rubric Indicators
                        </div>
                        <div className="rating  rubric-rating">
                            Descriptions
                        </div>
                        <div className="rating rubric-rating">
                            Ratings
                        </div>
                    </div>
                    {
                        rubricLoading === true ? <div className="loading-wrapper"><Loading type='default' size='5rem' /></div> : _.map(rubricResults, (field, i) => {
                            return renderRubricFields(field, i)
                        })
                    }
                </div>}
            </div>
        </>
        }
    </>
}

export default GeneralSurveyResults;


