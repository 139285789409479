import _ from 'lodash';
import React from 'react';

const RadioButtonControl = ({ field,
    textValuePairs,
    value,
    onChange,
    onBlur,
    className,
    disabled,
    loading
}) => {

    let idLabel = "field_label_" + field.FieldName;
    let textValues = textValuePairs;
    return (
        <>
            {/* <FormControl>
        <FormLabel {...(field.IsRequired && {required:true})} id={idLabel}>{field.DisplayName}</FormLabel>
        <RadioGroup
        className={className}
        aria-labelledby={idLabel}
        value={value}
        {...(field.IsRequired && {required:true})}
        name={"radio-buttons-group-" + field._id}
        onChange={(context)=>onChange(context,field)}
        onBlur={(context)=>onBlur(context,field)}
        >
            {_.map(textValues,(x=>{return (<FormControlLabel  key={"field_" + field.FieldName + "_" + x.Value}  value={x.Value} control={<Radio />} label={x.Text} />);})) }
        </RadioGroup>
    </FormControl> */}
        </>
    );
}

export default RadioButtonControl;