import React, { useEffect, useState } from 'react';
import '../../assets/base.scss';
import { useParams } from 'react-router-dom';
import { ContentTypeEnum } from '../../services/ContentService'
import ContentDisplay from '../../app-components/Content/Display'
import ContentFilter from '../../app-components/Content/Filter'
import Viewer from '../../app-components/Content/Viewer'
import ShareDialog from '../../app-components/Content/ShareDialog'
import { useNavigate, useLocation } from 'react-router';
import { EventTypeEnum } from '../../../../managers/GoogleAnalyticsManager';
import InPageLoading from '../../app-components/Loading/InPageLoading'
import { useContentManager, useTagManager } from '../../../../hooks/useManagers';
import * as gaMgr from '../../../../managers/GoogleAnalyticsManager';
import MainLayout from '../../../../components/layout/MainLayout';
import _ from 'lodash';
import StarRatingDialog from '../../app-components/Content/StarRatingDialog';
import PreferencesDialog from '../../app-components/Content/PreferencesDialog';
import RelatedDialog from '../../app-components/Content/RelatedDialog';


export default function Content({ authMgr, parentTagName }) {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const redirect = (path) => navigate(path);
    const contentMgr = useContentManager();
    const tagMgr = useTagManager();
    const globalState = contentMgr?.GetGlobalState();
    const [theKeyId, setTheKeyId] = useState(null);
    const [listView, setListView] = useState(false);
    const [updateContent, setUpdateContent] = useState(null);
    const [isViewer, setIsViewer] = useState(false);
    const [showRelatedContent, setShowRelatedContent] = useState(null);
    const [contentTypeId, setContentTypeId] = useState(0);
    const [contentIdToStarRate, setContentIdToStarRate] = useState(null);
    const [fullTreeTags, setFullTreeTags] = useState();

    const getKeyId = () => {
        let keyId = null;
        if (params?.KeyId) {
            keyId = params?.KeyId;
        }
        else if (location?.search) {
            const searchParam = new URLSearchParams(location.search);
            keyId = searchParam.get('k');
            if (!keyId) {
                keyId = searchParam.get('keyId');
            }

            if (!keyId) {
                keyId = searchParam.get('keyid');
            }

            if (!keyId) {
                keyId = searchParam.get('KeyId');
            }
        }

        return keyId;
    }

    useEffect(() => {
        const keyId = getKeyId();
        if (keyId && theKeyId !== keyId) {
            setTheKeyId(keyId);
        }
        else {
            setTheKeyId(null);
            setIsViewer(false);
        }
    }, [location]);


    useEffect(() => {
        if (location && contentMgr) {

            if (location.pathname.toLowerCase() === '/resources') {
                setContentTypeId(ContentTypeEnum.Resource)
                onFilterChange('contentTypeIds', { resources: true, learningModules: false, videos: false });
            }
            else if (location.pathname.toLowerCase() === '/video-library') {
                setContentTypeId(ContentTypeEnum.Video)
                onFilterChange('contentTypeIds', { resources: false, learningModules: false, videos: true });
            }
            else if (location.pathname.toLowerCase() === '/learning-modules') {

                setContentTypeId(ContentTypeEnum.LearningModule)
                onFilterChange('contentTypeIds', { resources: false, learningModules: true, videos: false });

            }
            else {
                setContentTypeId(undefined);
            }
        }
    }, [location, contentMgr]);


    useEffect(() => {
        if (contentMgr && getKeyId() === null) {
            contentMgr.SearchContent();
        }

    }, [contentMgr, contentMgr?._forceContentUpdate]);

    useEffect(() => {
        if (contentMgr) {
            if (theKeyId) {
                contentMgr.GetSingleContent(theKeyId).then((data) => {
                    contentMgr.SetSelectedContent(data);
                    setIsViewer(true);
                });
            }
            else {
                contentMgr._setSelectedContent(null);
            }
        }
    }, [theKeyId]);


    useEffect(() => {
        const keyId = getKeyId();
        if (globalState?.selectedContent != null && keyId != undefined) {
            if (keyId != theKeyId) {
                setTheKeyId(keyId);
            }

            const theViewer = (globalState.selectedContent.ContentTypeId == ContentTypeEnum.Resource) ||
                (globalState.selectedContent != null && globalState.selectedContent.ContentTypeId == ContentTypeEnum.LearningModule) ||
                (globalState.selectedContent.ContentTypeId == ContentTypeEnum.Video)

            setIsViewer(theViewer);
        }
    }, [contentMgr, globalState]);




    const handleOnView = (tempKeyId) => {
        contentMgr.SetSelectedContent(null);
        if (globalState.selectedContent == null || (globalState.selectedContent != null && globalState.selectedContent.KeyId !== tempKeyId)) {
            contentMgr.GetSingleContent(tempKeyId).then((c) => {
                if (c != null) {
                    gaMgr.AddEvent(EventTypeEnum.Content_Accessed, tempKeyId, c, contentMgr);
                    contentMgr.SetSelectedContent(c);
                }
            });
        }
        redirect(contentMgr.GetViewUrl(tempKeyId));
    }

    const handleOnLike = async (tempKeyId) => {
        await contentMgr.ChangeContentRating(tempKeyId);
    }



    const handleOnDownload = async (tempKeyId) => {
        if (contentMgr?.SelectedContent && contentMgr?.SelectedContent.KeyId === tempKeyId) {
            gaMgr.AddEvent(EventTypeEnum.Content_Download, tempKeyId, contentMgr?.SelectedContent);
            const result = await contentMgr.ItemManager.getFile(contentMgr?.SelectedContent.FileId, contentMgr?.SelectedContent.DisplayFileName + '.' + contentMgr?.SelectedContent.FileExt);
            //RZ: Async intended
            contentMgr?.SetContentViewed(tempKeyId);
            return result;
        }
        else {
            return contentMgr.GetSingleContent(tempKeyId).then(async (c) => {
                gaMgr.AddEvent(EventTypeEnum.Content_Download, tempKeyId, c);
                const result = await contentMgr.ItemManager.getFile(c.FileId, c.DisplayFileName + '.' + c.FileExt);
                //RZ: Async intended
                contentMgr?.SetContentViewed(tempKeyId);
                return result;
            });
        }
    }

    const handleOnShare = (contentId) => {
        if (globalState.selectedContent != null && globalState.selectedContent.KeyId === contentId) {
            gaMgr.AddEvent(EventTypeEnum.Content_Shared, contentId, globalState.selectedContent);
            contentMgr.SetSelectedSharedContent(globalState.selectedContent);
        }
        else {
            const content = _.find(contentMgr.FilteredContent, x => x.ContentId === contentId);
            if (content) {
                contentMgr.SetSelectedSharedContent(content);
            }
            else {
                contentMgr.GetSingleContent(contentId).then((c) => {
                    gaMgr.AddEvent(EventTypeEnum.Content_Shared, contentId, c);
                    contentMgr.SetSelectedSharedContent(c);
                });
            }
        }
    }

    const onFilterChange = (field, value) => {
        const contentFilter = contentMgr._contentFilter;
        const updatedContentFilter = { ...contentFilter };
        if (field === 'contentTypeIds') {
            let contentTypeIds = [];
            if (value.resources)
                contentTypeIds.push(ContentTypeEnum.Resource)
            if (value.learningModules)
                contentTypeIds.push(ContentTypeEnum.LearningModule)
            if (value.videos)
                contentTypeIds.push(ContentTypeEnum.Video)

            updatedContentFilter.contentTypeIds = contentTypeIds;
        }
        else {
            updatedContentFilter[field] = value;
        }

        contentMgr.FilterContent(updatedContentFilter);
    }


    useEffect(() => {
        if (contentMgr?.FilteredContent) {
            setUpdateContent(new Date());
        }
    }, [contentMgr?.FilteredContent]);

    const handleOnStarRate = async () => {
        if (contentMgr?.SelectedContent) {
            setContentIdToStarRate(contentMgr?.SelectedContent.ContentId);
        }
    }

    const handleOnLoadRelated = () => {
        if (contentMgr?.SelectedContent) {
            contentMgr?.SetContentViewed(contentMgr?.SelectedContent.ContentId);
        }
        setShowRelatedContent(true);
    }

    const isGradeLevel = (itemChildren) => {
        const arr = _.find(itemChildren, x => x.label.includes("Grade Levels"))?.children
        if (arr) {
            arr.sort((x) => x._id === 'K' ? -1 : 0);
            arr.sort((x) => x._id === 'Pre-K' ? -1 : 0);
        }
    }

    useEffect(() => {
        if (tagMgr) {
            tagMgr.FullTagTree().then(r => {
                if (r?.Success) {
                    _.forEach(isGradeLevel(r.Items))
                    setFullTreeTags(r.Items.filter(x => x.ShowInContentLibrary === true));
                }
            });
        }
    }, [tagMgr])

    return (
        <>
            <ShareDialog />
            <StarRatingDialog contentTypeId={contentMgr?.SelectedContent?.ContentTypeId} contentId={contentIdToStarRate} currentStarRating={contentMgr?.SelectedContent?.StarRatingForUser} onClose={() => setContentIdToStarRate(null)} />
            <PreferencesDialog />
            <RelatedDialog open={showRelatedContent} content={contentMgr?.SelectedContent} onClose={() => setShowRelatedContent(false)} />
            <MainLayout>
                <div className="control-box-wrapper">
                    <div className="control-box-list">
                        {
                            (!theKeyId && globalState?.avaliableContentFilters != null && globalState?.filteredContent != null && fullTreeTags) ?
                                <>
                                    <ContentFilter avaliableContentFilters={globalState?.avaliableContentFilters} contentFilter={globalState?.contentFilter} onFilterChange={onFilterChange} onListView={setListView} defaultContentTypeId={contentTypeId} defaultParentTagName={parentTagName} fullTreeTags={fullTreeTags} />
                                    <ContentDisplay authMgr={authMgr} updateContent={updateContent} onLike={handleOnLike} onDownload={handleOnDownload} onView={handleOnView} onShare={handleOnShare} parentTags={globalState.avaliableContentFilters?.tags} displayMode={listView ? "list" : "primary"} />
                                </>
                                : (isViewer && contentMgr?.SelectedContent ? 
                                    <Viewer authMgr={authMgr} contentMgr={contentMgr} onLoadRelated={handleOnLoadRelated} onStarRate={handleOnStarRate} onLike={handleOnLike} onDownload={handleOnDownload} onShare={handleOnShare} onView={handleOnView} /> 
                                        : <InPageLoading />)
                        }
                    </div>
                </div>
            </MainLayout >
        </>
    );
}
