const SurveyTemplateFieldValidateObject = () => {
    return {
        Title: {
            fieldName: 'Title',
            messageTitle: 'title',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'SURVEYTEMPLATE_FIELD',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
        DisplayText: {
            fieldName: 'DisplayText',
            messageTitle: 'text for your survey field',
            isRequired: true,
            dataType: 'STRING-NA',
            groupId: 'SURVEYTEMPLATE_FIELD',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
        SurveyFieldType: {
            fieldName: 'SurveyFieldType',
            messageTitle: 'survey field type',
            isRequired: true,
            dataType: 'SELECT',
            groupId: 'SURVEYTEMPLATE_FIELD',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        }
    }
}

export default SurveyTemplateFieldValidateObject;