import React from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';

export default function FeedbackFilter({ feedbackMgr, onFilterChange }) {
    const handleSearchChange = (value) => {
        if (onFilterChange)
            onFilterChange("search", value);
    }

    const handleEmailChange = (value) => {
        if (onFilterChange)
            onFilterChange("email", value);
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        feedbackMgr.filterData({ search: null, email: null });
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Feedback Management</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search'
                            value={feedbackMgr?.Filter?.search ?? ''}
                            fieldName='Search'
                            disableError={true}
                            placeholder='Name'
                            onChange={handleSearchChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='email'
                            title='Email'
                            value={feedbackMgr?.Filter?.email ?? ''}
                            fieldName='email'
                            disableError={true}
                            placeholder='Email Address'
                            onChange={handleEmailChange}>
                        </InputField>
                    </div>
                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}