import { useEffect, useState } from 'react';
import './details.scss'
import { SelectPicker, TagPicker, TreePicker } from 'rsuite';
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import { CheckboxControl, SelectListControl } from '../../../../components/controls';
import { useValidationManager } from '../../../../hooks/useValidation';
import { DialogControl } from '../../../../components/controls/DialogControl';
import Loading from '../../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid';
import { usePermissionManager } from '../../../../hooks/useManagers';
import _, { filter } from 'lodash';
import { FormatUTCDate } from '../../../../utilities/DateFormater';


const ClusterDetails = ({ canWrite, preSelectedSchoolId, orgMgr, org, clusterMgr, cluster, onCancel, onSave, onErrors, onValidationErrors }) => {
    const [item, setItem] = useState(cluster ?? clusterMgr.DefaultItem);
    const [saving, setSaving] = useState(false);
    const [districts, setDistricts] = useState(null)
    const [schools, setSchools] = useState(null)
    const [forceValidate, setForceValidate] = useState(false);
    const [schoolUsers, setSchoolUsers] = useState(null)
    const [clusterUsers, setClusterUsers] = useState(null)
    const [openUserDialog, setOpenUserDialog] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [filterInput, setFilterInput] = useState('')
    const validationMgr = useValidationManager(false, 'ALL');
    const validationClusterMgr = useValidationManager(false, 'CLUSTER');
    const [oldSchoolId, setOldSchoolId] = useState(cluster?.SchoolId);

    useEffect(() => {
        if (item?.ClientId) {
            setDistricts(orgMgr?.DistrictsOnly(item.ClientId));
        }
        if (item?.DistrictId) {
            setSchools(orgMgr?.SchoolsOnly(item.DistrictId));
        }

        if (item?.SchoolId && clusterMgr && (schoolUsers === null || item?.SchoolId != oldSchoolId)) {
            setOldSchoolId(item?.SchoolId);
            setClusterUsers(null);
            setSchoolUsers(null);
            clusterMgr.SchoolUsers(item?.SchoolId).then(r => {
                setSchoolUsers(r);
                if (item.UserClusters == null) {
                    clusterMgr.get(item.Id).then(userMapResults => {
                        if (userMapResults.Success) {
                            item.UserClusters = userMapResults.Items.first().UserClusters;
                            setClusterUsers(clusterMgr?.MappedUsers(item, r) ?? [])
                        }
                    });
                }
                else {
                    setClusterUsers(clusterMgr?.MappedUsers(item, r) ?? [])
                }
            });

        }
        else if (item && clusterMgr && schoolUsers && schoolUsers?.length > 0) {
            setClusterUsers(clusterMgr?.MappedUsers(item, schoolUsers) ?? [])
        }
        else if (clusterMgr && schoolUsers || item.Id == undefined) {
            setClusterUsers([]);
        }
    }, [clusterMgr, item]);

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }


    const handleOnSave = async (e) => {
        const validateAll = validationMgr.checkIsGroupValid(item);
        const validateCluster = validationClusterMgr.checkIsGroupValid(item)
        let validateResult = [...validateAll, ...validateCluster]
        if (e === 'UserClusters') {
            validateResult = _.map(validateResult, x => x.replace('.', ' ') + "prior to adding users.")
        }
        if (validateResult?.length === 0) {
            setForceValidate(false);
            if (onSave) {
                setSaving(true);
                let result;
                let saveUserResults
                if (e === "UserClusters") {
                    result = await onSave(item, true);
                } else {
                    result = await onSave(item, false);
                    if (result.Success)
                        saveUserResults = await clusterMgr.UpdateUserClusters(item?.UserClusters);
                }

                if (result?.Success && e === "UserClusters") {
                    handleOnChange(result?.Items.first()?.Id, "Id");
                    setOpenUserDialog(true);
                }
                else if (onErrors && !result.Success) {
                    onErrors([result?.Message?.DisplayMessage ?? [...(result?.MessageDetails ?? ['Unknown Error'])]])
                }
                setSaving(false);
                return result;
            }
        }
        else if (onErrors) {
            setForceValidate(true);
            onValidationErrors(validateResult);
            setSaving(false);
        }
    }

    const handleOnChange = (value, field) => {
        let updatedItem = { ...item };
        if (field === "DELETE") {
            const removedValueIndex = _.findIndex(updatedItem.UserClusters, (v) => v.UserId === value)
            const removedValue = _.find(updatedItem.UserClusters, (v) => v.UserId === value)
            if (removedValue.IsDeleted === undefined) {
                updatedItem.UserClusters?.splice(removedValueIndex, 1)
            } else {
                removedValue['IsDeleted'] = true
                updatedItem.UserClusters?.splice(removedValueIndex, 1, removedValue)
            }
        }
        else if (field === 'UserClusters' || field === 'AddAllUserClusters') {
            if (field === 'AddAllUserClusters') {
                value = _.map(schoolUsers, schoolUser => schoolUser.UserId)
            }
            const formattedUsers = value.reduce((users, v) => {
                const userExist = _.find(updatedItem.UserClusters, (x) => x.UserId === v)
                if (userExist) {
                    const removedValueIndex = _.findIndex(updatedItem.UserClusters, (x) => x.UserId === v)
                    userExist['IsDeleted'] = false
                    updatedItem.UserClusters?.splice(removedValueIndex, 1, userExist)
                } else users.push({
                    UserId: v,
                    ClusterId: item?.Id,
                    Id: _.find(updatedItem.UserClusters, (x) => x.UserId === v)?.Id ?? uuidv4(),
                })
                return users
            }, [])
            updatedItem['UserClusters'] = updatedItem['UserClusters']?.concat(formattedUsers)
            setOpenUserDialog(false)
        } else {
            updatedItem[field] = value;
        }
        setItem(updatedItem);
    }

    const onTreeClean = (id) => {
        handleOnChange(null, id);
    }

    const unAddedSchoolUsers = _.map(_.filter(schoolUsers, schoolUser => {
        if (_.isNil(_.find(clusterUsers, clusterUser => clusterUser.UserId === schoolUser.UserId))) {
            return schoolUser
        }
    }))

    return (<>
        <DialogControl
            openDialog={openUserDialog}
            title={'Add Users'}
            onCancel={() => setOpenUserDialog(false)}
            onOk={() => handleOnChange(selectedUsers, "UserClusters")}
            okText={'add'}
            enableNext
            nextText={'add all'}
            onNext={() => handleOnChange('lollll', 'AddAllUserClusters')}
        >
            <InputField forceValidate={forceValidate} title="Cluster User">
                <TagPicker
                    className='tag-picker'
                    data={unAddedSchoolUsers ?? []}
                    valueKey={'value'}
                    onChange={setSelectedUsers}
                    block
                    preventOverflow
                />
            </InputField>
        </DialogControl>

        <div className='control-box-wrapper'>
            <>
                <div className='control-box box-two-column'>

                    {org && <InputField
                        forceValidate={forceValidate}
                        title="Organization"
                        fieldName="ClientId"
                        isDisabled={preSelectedSchoolId}
                        value={item?.ClientId}
                    >
                        <TreePicker
                            height={320}
                            width={400}
                            data={org}
                            valueKey={'OrgId'}
                            labelKey={'Name'}
                            onClean={() => onTreeClean("ClientId")}
                            onSelect={(a, val) => handleOnChange(val, 'ClientId')}
                            placement="autoVerticalStart"
                        />
                    </InputField>}
                    {districts ? <InputField
                        forceValidate={forceValidate}
                        value={item?.DistrictId}
                        isDisabled={preSelectedSchoolId}
                        title="District"
                        fieldName="DistrictId"
                        validationName={'school_DistrictId'}
                        groupId={'SCHOOL'}
                    >
                        <TreePicker
                            height={320}
                            width={400}
                            data={districts ?? []}
                            isDisabled={preSelectedSchoolId}
                            valueKey={'DistrictId'}
                            labelKey={'Name'}
                            onClean={() => onTreeClean("DistrictId")}
                            onSelect={(a, val) => handleOnChange(val, 'DistrictId')}
                            placement="autoVerticalStart"
                        />
                    </InputField> :
                        <InputField
                            forceValidate={forceValidate}
                            value={item?.DistrictId}
                            title="District"
                            isDisabled={preSelectedSchoolId}
                            fieldName="DistrictId"
                            subTitle="Select an Organization First."
                            validationName={'school_DistrictId'}
                        >
                            <TreePicker
                                height={320}
                                width={400}
                                disabled={true}
                                data={[]}
                                placement="autoVerticalStart"
                            />
                        </InputField>}
                    {schools ? <InputField
                        forceValidate={forceValidate}
                        value={item?.SchoolId}
                        isDisabled={preSelectedSchoolId}
                        title="School"
                        fieldName="SchoolId"
                        validationName={'cluster_SchoolId'}
                    >
                        <TreePicker
                            height={320}
                            width={400}
                            valueKey={'SchoolId'}
                            labelKey={'Name'}
                            data={schools ?? []}
                            onClean={() => onTreeClean("SchoolId")}
                            onSelect={(a, val) => handleOnChange(val, "SchoolId")}
                            placement="autoVerticalStart"
                        />
                    </InputField> : <InputField
                        forceValidate={forceValidate}
                        value={item?.SchoolId}
                        isDisabled={preSelectedSchoolId}
                        subTitle="Select a District First."
                        title="School"
                        fieldName="SchoolId"
                    >
                        <TreePicker
                            height={320}
                            width={400}
                            disabled={true}
                            data={[]}
                            placement="autoVerticalStart"
                        />
                    </InputField>}
                    <InputField forceValidate={forceValidate} type="text" subTitle={'Once set, the name cannot be changed.'} value={item?.Name} isDisabled={cluster?.Id} title="Cluster Name" fieldName="Name" groupId={'ALL'} onChange={handleOnChange} />
                    <InputField title="Active?" value={item?.IsActive} onChange={handleOnChange} maxLength={100} fieldName='IsActive'>
                        <CheckboxControl fieldName='IsActive' />
                    </InputField>
                </div>
                <div className='control-box box-two-column role-perm-editor__wrapper'>
                    <div className='role-perm-editor__header'>
                        <div>
                            <h4>Cluster Members</h4>
                            <h6><i>Please note, clicking "Add New" for the first time will save the record.</i></h6>
                        </div>
                        <ButtonControl disabled={!unAddedSchoolUsers || unAddedSchoolUsers?.length == 0} onClick={() => item?.Id ? setOpenUserDialog(true) : handleOnSave('UserClusters')}>Add New</ButtonControl>
                    </div>
                    <div className='role-perm-editor__search'>
                        <InputField
                            type="text"
                            value={filterInput}
                            fieldName='search'
                            disableError={true}
                            placeholder='Search User Name'
                            onChange={(value) => setFilterInput(value?.toLowerCase())}>
                        </InputField>
                    </div>
                    <div className='detail-table-wrapper'>
                        <table className="__table">
                            <thead className="__head">
                                <tr>
                                    <th className="__name">First Name</th>
                                    <th className="__flex">Last Name</th>
                                    <th className="__flex added__date">Added Date</th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    (clusterMgr && clusterUsers) ?
                                        _.map(_.filter(clusterUsers, user => user.FirstName.toLowerCase().includes(filterInput)), (clusterUser, i) =>
                                            <tr key={'cluster_user_' + clusterUser.Id}>
                                                <td className="__name">{clusterUser.FirstName}</td>
                                                <td className="__flex">{clusterUser.LastName}</td>
                                                <td className="__flex">{FormatUTCDate(clusterUser.CreatedDate)}</td>
                                                <td className="__icon">
                                                    {<FontAwesomeIcon className={'icon btn-icon-fa'} icon={faTrashCan} onClick={() => handleOnChange(clusterUser.UserId, 'DELETE')} />}
                                                </td>
                                            </tr>)

                                        : <tr className='role-detail-loading-wrapper'><td><Loading type='default' size='3rem' /></td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        </div >
        <div className='screen-footer screen-footer-right'>
            <div className='btn-wrapper-left screen-footer__btn'><ButtonControl disabled={saving} type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl></div>
            {canWrite && <div className='btn-wrapper-right screen-footer__btn'><ButtonControl loading={saving} type={'okay'} disabled={!item} onClick={handleOnSave}>Save</ButtonControl></div>}
        </div>
    </>)
}

export default ClusterDetails;


