//https://blog.logrocket.com/simplify-react-state-management-with-hookstate/
import { hookstate } from '@hookstate/core'

const globalPayoutStore = hookstate({
    allPayouts: null,
    filteredPayouts: [],
    forceUpdate: false,
    payoutFilter: { School: '', District: '', Status: [], Group: [] }
});

export default globalPayoutStore;