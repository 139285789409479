import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import PermissionDetails from '../components/permission/details';
import PermissionFilter from '../components/permission/filter';
import PermissionTable from '../components/permission/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { usePermissionManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';

const PermissionMgmt = () => {
    const permissionMgr = usePermissionManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedPermission, setSelectedPermission] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (permissionMgr && !params?.permissionId) {
            setLoading(true);
            permissionMgr.search().then(fb => {
                setManageMode('list');
                setLoading(false);
            });
        }
        else if (!selectedPermission && params?.permissionId && permissionMgr) {
            setLoading(true);
            if (params?.permissionId.toLowerCase() === 'addnew') {
                setSelectedPermission(permissionMgr.DefaultItem);
                setManageMode('view');
                setLoading(false);
            }
            else {
                permissionMgr.get(params?.permissionId).then((r) => {
                    if (r.Success) {
                        setManageMode('view');
                        setSelectedPermission(r.Items.first());
                    }
                    setLoading(false);
                });
            }
        }
    }, [permissionMgr, params?.permissionId]);

    const onAddNew = () => {
        setSelectedPermission(permissionMgr.DefaultItem);
        setManageMode('view');
        navigate('/admin/permission/addnew');
    }

    const handleOnFilterChange = async (field, value) => {

        const permissionFilter = permissionMgr.Filter;
        const updatedPermissionFilter = { ...permissionFilter };
        updatedPermissionFilter[field] = value !== '' ? value : null;
        await permissionMgr.filterData(updatedPermissionFilter);
    }

    const handleOnSave = async (item) => {

        var result = await permissionMgr.saveItem(item);
        if (result.Success) {
            setSelectedPermission(null);
            navigate('/admin/permission')
        }

        return result;

    }

    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        permissionMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.Permission}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnEdit = (item) => {
        setSelectedPermission(item);
        setManageMode('view');
        navigate(`/admin/permission/${item.Id}`);
    }


    const handleOnCancel = () => {
        navigate('/admin/permission');
        if (permissionMgr.FilteredData) {
            setManageMode('list');
        }
        else {
            setManageMode(null);
        }
        setLoadingError(null);
        setErrors(null);
    }

    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete the permission '${itemToDelete?.Permission}' ? `} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <MainLayout errors={errors} validationErrors={validationErrors} >
            {manageMode === 'view' &&
                <div className='screen-header'>
                    <h3>Permission Management</h3>
                    <h5>View Permission</h5>
                </div>
            }
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <PermissionFilter permissionMgr={permissionMgr} onFilterChange={handleOnFilterChange} />
                            <PermissionTable canWrite={permissionMgr.CanWrite} filteredItems={permissionMgr?.FilteredData ?? []} onEdit={handleOnEdit} onDelete={(item) => { setItemToDelete(item); }} />
                        </div>
                        <div className='screen-footer list'>
                            {permissionMgr.CanWrite && <ButtonControl type={'create'} onClick={onAddNew}>Create Permission</ButtonControl>}
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&
                    <PermissionDetails canWrite={permissionMgr.CanWrite} permission={selectedPermission} onCancel={handleOnCancel} onSave={handleOnSave} onLoading={setLoading} onErrors={setErrors} onValidationErrors={setValidationErrors} onLoadingError={setLoadingError} />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default PermissionMgmt;