import _ from 'lodash';

export function getGaugeOption(data, selectedId) {
    const idToshow = selectedId ?? data.selectedId
    const chartData = _.find(data.Data, x => x.GroupById === idToshow)
    //console.log(chartData, 'what si chartDat?')
    let chartValue = chartData?.Value1 ?? 0
    let submitted = chartData?.Value2 ?? 0
    let total = chartData?.Value3 ?? 0
    return {
        title: {
            left: "center",
            text: chartData?.GroupByName,
            top: '90%',
            width: 70,
            overflow: 'truncate',
            textStyle: {
                color: '#003c5b',
                fontSize: '12',
                fontWeight: 'normal',
            }
        },

        series: [
            {
                type: 'gauge',
                startAngle: 180,
                endAngle: 0,
                center: ['50%', '75%'],
                radius: '90%',
                min: 0,
                max: 1,
                progress: {
                    show: true,
                    width: 50,
                    itemStyle: {
                        color: '#49E287'
                    }
                },
                axisLine: {
                    lineStyle: {
                        width: 50,
                        color: [
                            [1, '#f4f1ed'],
                        ]
                    }
                },
                axisTick: {
                    distance: 0,
                    length: 0
                },
                splitLine: {
                    distance: 0,
                    length: 0
                },
                axisLabel: {
                    color: 'auto',
                    distance: 0,
                    fontSize: 0
                },
                pointer: {
                    length: 0,
                    width: 0,
                },
                title: {
                    fontSize: 16,
                    color: '#003c5b'
                },
                detail: {
                    valueAnimation: true,
                    formatter: (value) => { return (value * 100).toFixed(2) + '%' },
                    color: '#003c5b',
                    fontSize: 13,
                    offsetCenter: [0, '-15%']
                },
                data: [
                    {
                        value: chartValue,
                        name: `${submitted} Submitted out of ${total}`
                    }
                ],

            },

        ]
    };
}