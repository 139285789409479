
import Player from '../Player'
import Articulate from '../Articulate'
import Resource from '../Resource'
import { Stack, SwipeableDrawer } from '@mui/material';
import './index.scss';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ContentIcon from '@mui/icons-material/PhotoLibrary';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import VideoIcon from '@mui/icons-material/VideoLibrary'
import ShareIcon from '@mui/icons-material/Share';
import StarIcon from '@mui/icons-material/Star';
import StarEmptyIcon from '@mui/icons-material/StarBorder';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloseIcon from '@mui/icons-material/Close';
import MultipleIcon from '@mui/icons-material/WebStories';
import CircularProgress from '@mui/material/CircularProgress';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';

import React, { useState, useContext, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { ContentTypeEnum } from '../../../services/ContentService';
import { useNavigate } from 'react-router';
import InPageLoading from '../../../app-components/Loading/InPageLoading'
import Loading from '../../../../../components/Loading';
import ButtonControl from '../../../../../components/controls/ButtonControl';
import { useAppUserManager } from '../../../../../hooks/useManagers';


export default function Viewer({ authMgr, contentMgr, onLike, onDownload, onStarRate, onShare, onView, onLoadRelated }) {
  const navigate = useNavigate();
  const [useBitmovinPlayer, setUseBitmovinPlayer] = useState(false);

  useEffect(() => {
    if (contentMgr && contentMgr?.SelectedContent) {
      contentMgr.ContentViewed(contentMgr.SelectedContent.ContentId);
    }
  }, [contentMgr, contentMgr?.SelectedContent]);

  const handleBack = () => {
    contentMgr.SetSelectedContent(null);
    contentMgr.ForceContentUpdate();
    navigate(-1);
  }

  const [downloading, setDownloading] = useState(false);
  const [anchor, setAnchor] = useState('right');
  const [like, setLike] = useState(contentMgr.SelectedContent.HasUserRating);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleOnDownload = async (id) => {
    if (onDownload) {
      setDownloading(true);
      await onDownload(id);
      setDownloading(false);
    }
  }

  const handleOnLike = () => {
    onLike(contentMgr.SelectedContent.KeyId);
    setLike(!like);
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const fullScreenArticulate = () => {
    const url = `/learningmodules/${contentMgr?.SelectedContent?.FileId}`;
    let articulateUrl;
    if (contentMgr?.SelectedContent?.Metadata)
      articulateUrl = (url + "/" + contentMgr?.SelectedContent?.Metadata);
    else
      articulateUrl = (url + "/story_html5.html");

    if (articulateUrl)
      window.open(articulateUrl, '');
  };


  const AssociatedContent = ({ anchor }) => {
    return (
      <div
        className="contentViewerAssociatedContent"
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div className="contentViewerHeader mb-2">
          <h2>Associated Content</h2>
        </div>
        {
          contentMgr.SelectedContent.AssociatedContent.map((ac) =>
            <div className="innerAssociatedContent mb-4" key={ac.KeyId}>
              <div className="contentHeader" onClick={(e) => { onView(ac.KeyId) }}>
                <div><img src={ac.ThumbnailFileUrl} /></div>
                <div className="contentDetails" >
                  <div className="contentSubTitle">{ac.Title}</div>
                  <div className="contentViewerInfo">{ac.Description}</div>
                </div>
                <div className="buttonFrame associated-content-buttons">

                </div>
              </div>
              {ac.ContentTypeId == ContentTypeEnum.Resource && (<div className='dl-btn'>
                <Button className="viewerActionButtons ml-3" onClick={(e) => handleOnDownload(ac.KeyId)}>
                  <div className="contentIcons upperCase">{!downloading ? <><CloudDownloadIcon /></> : <div className='loading-icon-wrapper'><Loading /></div>}</div>
                </Button></div>)}
            </div>
          )
        }
      </div >);
  }

  const handleOnStarRate = () => {
    onStarRate(contentMgr.SelectedContent.KeyId);
  }

  const onBitmovinPlayer = () => {
    setUseBitmovinPlayer(!useBitmovinPlayer);
  }

  return (
    <>
      {contentMgr.SelectedContent != null ?
        <div className="contentViewerContainer">
          <div className="contentViewerHeader">
            <div>
              <div className="contentViewerHeader mb-2">
                <h2 className="mt-2 mr-3">{contentMgr.SelectedContent.Title}</h2>

              </div>
              <div className="contentViewerInfo">
                <div>{contentMgr.SelectedContent.Description}</div>
                {contentMgr.SelectedContent.RunningTime ? <div className="contentViewerRunningTime">{contentMgr.SelectedContent.RunningTime}</div> : ""}
              </div>
            </div>
            <div className="contentViewerHeader-action">
              <ButtonControl onClick={handleBack} type='default'>BACK</ButtonControl>
            </div>
          </div>
          <div className={"contentViewerBody" + (contentMgr.SelectedContent.ContentTypeId == ContentTypeEnum.Video ? " videoLayout" : "")}>
            {contentMgr.SelectedContent.ContentTypeId == ContentTypeEnum.Video ? 
              <Player content={contentMgr.SelectedContent} onVideoEnded={onLoadRelated} useBitmovinPlayer={useBitmovinPlayer} /> 
              : 
                (contentMgr.SelectedContent.ContentTypeId == ContentTypeEnum.LearningModule ? 
                  <Articulate /> 
                : 
                  <Resource />
                )
            }

            <div className="buttonFrame">
              <Button title="Related Content" className="viewerActionButtons" onClick={onLoadRelated}><div className="contentIcons"><MultipleIcon /> Related</div></Button>
              {(contentMgr.SelectedContent.AssociatedContent && contentMgr.SelectedContent.AssociatedContent.length > 0) &&
                <Button title="Associated Content" className="viewerActionButtons" onClick={toggleDrawer(anchor, true)}><div className="contentIcons"><ContentIcon /> Associated</div></Button>
              }
              {authMgr.canView("RateContent") && <Button title="Rate Content" className="viewerActionButtons  mr-3" onClick={handleOnStarRate}>
                <div className="contentIcons">{contentMgr.SelectedContent.StarRatingForUser ? <StarIcon /> : <StarEmptyIcon />}Rate</div>
                <div className='rating-super-script'>{(contentMgr.SelectedContent.StarRatingForUser ? `${contentMgr.SelectedContent.StarRatingForUser}` : '')}</div>
              </Button>}
              <Button title="Like Content" className="viewerActionButtons mr-3" onClick={handleOnLike}>
                {!contentMgr?.RatingLoading && <>{!like ? <div className="contentIcons"><ThumbUpAltOutlinedIcon />Like</div> : <div className="contentIcons"><ThumbUpIcon />Like</div>}</>}
                {contentMgr?.RatingLoading && <div className="contentIcons"><CircularProgress color="inherit" size={24} /></div>}
              </Button>
              {authMgr.canView("ContentShare") &&
                <Button title="Share Content" className="viewerActionButtons" onClick={(e) => onShare(contentMgr.SelectedContent.KeyId)}>
                  <div className="contentIcons"><ShareIcon />Share</div>
                </Button>
              }
              {contentMgr.SelectedContent.ContentTypeId == ContentTypeEnum.Resource && (
                <><Button title="Download Content" className="viewerActionButtons ml-3" onClick={(e) => handleOnDownload(contentMgr.SelectedContent.KeyId)}>
                  <div className="contentIcons">{!downloading ? <><CloudDownloadIcon />Download</> : <div className='loading-icon-wrapper'><Loading /></div >}</div>
                </Button></>)}

              {contentMgr.SelectedContent.ContentTypeId == ContentTypeEnum.LearningModule &&
                <Button className="viewerActionButtons ml-3" onClick={(e) => fullScreenArticulate()}>
                  <div className="contentIcons"><ScreenShareOutlinedIcon />Fullscreen</div>
                </Button>}
            </div>
          </div>
          {contentMgr?.SelectedContent?.AssociatedContent &&
            <React.Fragment key={anchor}>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <AssociatedContent anchor={anchor} />
              </SwipeableDrawer>
            </React.Fragment>}
        </div> : <InPageLoading />
      }
    </>
  );
}
