const SurveyTemplateValidateObject = () => {
    return {
        Title: {
            fieldName: 'Title',
            messageTitle: 'title',
            isRequired: true,
            dataType: 'STRING',//IMAGE, INT, DEC, SELECT,BOOL,FILE
            groupId: 'SURVEYTEMPLATE',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
        Description: {
            fieldName: 'Description',
            messageTitle: 'survey instructions',
            isRequired: false,
            dataType: 'STRING-NA',
            groupId: 'SURVEYTEMPLATE',
            regEx: undefined,
            requiredDependency: undefined
        },
        SurveyType: {
            fieldName: 'SurveyType',
            messageTitle: 'survey type',
            isRequired: true,
            dataType: 'SELECT',
            groupId: 'SURVEYTEMPLATE',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
    }
}

export default SurveyTemplateValidateObject;