import React, { useEffect, useState } from 'react';
import { useAppUserManager, useContentLibraryManager } from '../../../../../hooks/useManagers';
import { DialogControl } from '../../../../../components/controls/DialogControl';
import _ from 'lodash';
import './index.scss';
import Loading from '../../../../../components/Loading';
import { CheckboxControl } from '../../../../../components/controls';
import { gridVisibleColumnDefinitionsSelector } from '@mui/x-data-grid';

export default function PreferencesDialog({ openDialog, onClose }) {
    const contentLibraryMgr = useContentLibraryManager()
    const appUserMgr = useAppUserManager();
    const userProfile = appUserMgr?.GetGlobalState()?.userProfile;
    const [open, setOpen] = useState(false);
    const [loadingRoot, setLoadingRoot] = useState(true);
    const [loadingTags, setLoadingTags] = useState(false);
    const [data, setData] = useState(null);
    const [childrenData, setChildrenData] = useState(null);
    const [nextText, setNextText] = useState('Next');
    const [cancelText, setCancelText] = useState('Skip');
    const [phase, setPhase] = useState(0);
    const [selectedTags, setSelectedTags] = useState({});

    useEffect(() => {

        if (userProfile && userProfile.RecommendedContentTags == null) {
            setOpen(true);
        }
        else if (openDialog) {
            var tags = {};
            _.each(userProfile?.RecommendedContentTags, key => {
                tags[key] = true;
            });
            setSelectedTags(tags);
            setOpen(true);
        }
    }, [userProfile, openDialog])

    useEffect(() => {
        if (contentLibraryMgr && !data && !childrenData && open) {
            contentLibraryMgr.GetContentPreferences().then(r => {
                setLoadingRoot(false);
                if (r.Success && r.Items.length > 0) {
                    var items = [...r.Items];
                    setData(items);
                    setLoadingTags(true)
                    const prms = [contentLibraryMgr.GetContentPreferences(items[0]?.Id ?? 'unknown', items[1]?.Id ?? 'unknown')];
                    setData(items);
                    Promise.all(prms).then(r => {
                        const newChildrenData = {};
                        _.map(prms, p => p.then(childrenResult => {
                            if (childrenResult.Success) {
                                newChildrenData[items[0].Id] = childrenResult.Items.filter(x => x.ParentTagId === items[0].Id);
                                newChildrenData[items[1].Id] = childrenResult.Items.filter(x => x.ParentTagId === items[1].Id);
                            }
                            return childrenResult;
                        }));
                        setChildrenData(newChildrenData);
                        setLoadingTags(false);
                    });
                }
                else {
                    setLoadingTags(false);
                    setData([]);
                }
            });
        }
    }, [contentLibraryMgr, open]);

    const handleOnSave = async () => {
        if (phase < data.length - 1) {
            const nextPhase = phase + 1;
            setPhase(nextPhase);
            if (nextPhase == data.length - 1) {
                setNextText("Save");
                setCancelText("Previous");
            }
            else {
                setNextText("Next");
                setCancelText("Skip");
            }
            return { Success: false, skipError: true };
        }
        else {
            const selectedTagIds = [];
            for (let key in selectedTags) {
                if (selectedTags[key] === true && key != 'check-all') {
                    selectedTagIds.push(key)
                }
            }

            if (selectedTagIds.length == 0) {
                selectedTagIds.push("None");
            }
            appUserMgr.SaveContentPreferances(selectedTagIds);
            setNextText("Next");
            setCancelText("Skip");
            setPhase(0);
            if (onClose)
                onClose();
            return { Success: true }
        }
    }

    const handleOnSkip = async () => {

        if (cancelText == "Skip") {
            if (Object.keys(selectedTags).length == 0) {
                appUserMgr.SaveContentPreferances(['None']);
                setSelectedTags({});
            }

            if (onClose)
                onClose();
            else
                return { Success: true }
        }
        else {
            const nextPhase = phase - 1;
            setPhase(nextPhase);
            setNextText("Next");
            setCancelText("Skip");
        }

        return { Success: false, skipError: true }
    }

    const handleOnTagSelected = (e, tagId) => {
        const updatedSelectedTags = { ...selectedTags };
        if (tagId === 'check-all' && e.target.value) {
            _.each(childrenData[data[phase]?.Id], x => {
                updatedSelectedTags[x.Id] = true;
            });
        }
        else if (tagId !== 'check-all' && !e.target.value && data[phase]?.Id.toLowerCase() === 'preference_bringsyou') {
            updatedSelectedTags['check-all'] = false;
        }

        updatedSelectedTags[tagId] = e.target.value;
        setSelectedTags(updatedSelectedTags);
    }

    return (
        <DialogControl className={'preferences-dialog'} openDialog={open} title={data ? data[phase]?.Description : ""} disableOk={loadingRoot || loadingTags} onOk={handleOnSave} okText={nextText} onCancel={handleOnSkip} cancelText={cancelText} >
            {(!loadingRoot && !loadingTags && data && childrenData) ? <div className='preferences'>
                {_.map(childrenData[data[phase]?.Id], child =>
                    <div className='list' key={`tagId_${child.Id}`}>
                        <div ><CheckboxControl value={selectedTags[child.Id]} onChange={(e) => handleOnTagSelected(e, child.Id)} /></div><div className='list-text'>{child.Description}</div>
                    </div>
                )}
                {data[phase]?.Id.toLowerCase() === 'preference_bringsyou' && <div className='list'>
                    <div><CheckboxControl value={selectedTags["check-all"]} disabled={selectedTags["check-all"]} onChange={(e) => handleOnTagSelected(e, "check-all")} /></div>
                    <div className='list-text'>All of the Above</div>
                </div>}
            </div> : <div className='preferences-loading'>
                <Loading type="Default" size={'3rem'} />
            </div>
            }
        </DialogControl>
    );
}
