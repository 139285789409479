import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _ from 'lodash';

class SurveyTemplateManager extends ItemManager {
  constructor(surveyMgr, globalItemState, globalAuthState, globalAppUserState) {


    super(ItemServiceFactory.ItemServiceEnum.SurveyTemplate, globalAuthState, globalAppUserState, globalItemState,
      (filter, item) => {
        var hasSearch = item?.Title?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
        var IsActive = item?.IsActive === filter?.IsActive || (filter?.IsActive === undefined || filter?.IsActive === null || filter?.IsActive === 'UNK')
        var hasSurveyType = item?.SurveyType == filter?.surveyType || (filter?.surveyType === undefined || filter?.surveyType === null || filter?.surveyType === 'UNK')
        var hasClientId = filter?.orgIds?.includes(item?.ClientId) || (filter?.orgIds === undefined || filter?.orgIds === null || filter?.orgIds.length === 0)
        return hasSearch && IsActive && hasClientId && hasSurveyType;
      });
    this._errorUtil = undefined;

    this._fieldSvc = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.SurveyTemplateField, globalAuthState);
    this._surveyLikertMapSvc = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.SurveyLikertMap, globalAuthState);
    this._observationConfigSvc = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.ObservationConfig, globalAuthState);
    this._surveyMgr = surveyMgr;
  }


  MapSurveyTypeName(surveyType) {
    return _.find(this.SurveyTypes, x => x.Value == surveyType)?.Text ?? "Unknown";
  }

  async getObservationConfig(orgId) {
    return await this._observationConfigSvc.list(this.buildJSONObjFilter('x => x.ClientId == orgId && x.IsActive == true && x.IsDeleted == false', { orgId }))
  }

  async isSurveyTemplateActive(id) {
    return await this._surveyMgr.list(this.buildJSONObjFilter('x => x.SurveyTemplateId == id', { id }, 1)).then(r => { return r.Items })
  }

  async saveFields(fieldsToSave) {
    const results = [];
    this.WriteDebugLog("SurveyTemplateManager.saveFields()", "Save '" + this._fieldSvc.APIName + " Items.'");
    for (var i = 0; i < fieldsToSave.length; i++) {
      results.push(await this._fieldSvc.save(fieldsToSave[i]));
    }
    return results;
  }

  async deleteFields(fieldsToDelete) {
    this.WriteDebugLog("SurveyTemplateManager.deleteFields()", "Delete '" + this._fieldSvc.APIName + " Items.'");
    const prms = [];
    _.each(fieldsToDelete, field => {
      if (field.Id)
        prms.push(this._fieldSvc.delete(field.Id));
    });

    return await Promise.all(prms);
  }

  async updateLikert(items, idToDelete) {
    const saveItem = items.reduce((saveBox, item) => {
      if (!item.IsDeleted) {
        saveBox.push(this._surveyLikertMapSvc.save(item))
      }
      return saveBox
    }, [])

    const resolvedSaved = await Promise.all(saveItem);
    const resolvedSaveMap = _.map(resolvedSaved, x => x?.first()?.Items?.first())
    const deleteItems = _.map(idToDelete, i => this._surveyLikertMapSvc.hardDelete(i))
    const resolvedDelete = await Promise.all(deleteItems)

    return resolvedSaveMap;
  }

  async getSurveyTemplate(id) {
    return await this.list(this.buildJSONObjFilter("x => x.Id == id && x.IsDeleted == false && x.IsActive == true", { id }))
  }

  async getFields(id) {
    this.WriteDebugLog("SurveyTemplateManager.getFields()", "Get '" + this._fieldSvc.APIName + " Items.'");
    const results = await this._fieldSvc.list({
      "RootFilterNode": {
        "Variable": "x",
        "Method": "&&",
        "IsConjunction": true,
        "Left": {
          "Variable": "x",
          "Name": "SurveyTemplateId",
          "Method": "==",
          "Value": id,
          "IsConjunction": false
        },
        "Right": {
          "Variable": "x",
          "Method": "&&",
          "IsConjunction": true,
          "Left": {
            "Variable": "x",
            "Name": "IsDeleted",
            "Method": "==",
            "Value": false,
            "IsConjunction": false
          },
          "Right": {
            "Variable": "x",
            "Name": "IsActive",
            "Method": "==",
            "Value": true,
            "IsConjunction": false

          }
        }
      }
    });

    const result = results?.first();
    if (result?.Success) {
      this.WriteDebugLog("SurveyTemplateManager.getFields()", "Get Fields List '" + this._fieldSvc.APIName + "' Items returned Successfully (" + id + ").");
    }
    else {
      this.WriteErrorLog("SurveyTemplateManager.getFields()", "Get Fields List '" + this._fieldSvc.APIName + "' Items returned Failed to return (" + id + ").");
    }

    return result;
  }

  get SurveyTypes() {
    return [
      { Text: 'General Survey', Value: 2 },
      { Text: 'Teacher Survey', Value: 4 },
      { Text: 'School Administrator Survey', Value: 5 }
    ];
  }

  get DefaultItem() {
    return {
    };
  }
}

export default SurveyTemplateManager;