import { useEffect, useState } from 'react';
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import { CheckboxControl, DatePickerControl, SelectListControl } from '../../../../components/controls';
import { useValidationManager } from '../../../../hooks/useValidation';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import Loading from '../../../../components/Loading';
import { ObservationSection, SaveObservationQuestions } from '../certificationQuestion/ObservationComponents/ObservationComponents';
import { DialogControl } from '../../../../components/controls/DialogControl';


const OrganizationDetails = ({ certificationQuestions, orgTreeUsers, canWrite, orgMgr, org, teacherRubricList, adminRubricList, teacherSurveyList, adminSurveyList, observationConfigs, loadingConfigs, onCancel, onSave, onErrors, onValidationErrors }) => {
    const [item, setItem] = useState(org ?? orgMgr.DefaultOrgItem);
    const [saving, setSaving] = useState(false);
    const [observationConfig, setObservationConfig] = useState(observationConfigs)
    const [forceValidate, setForceValidate] = useState(false);
    const [override, setOverride] = useState(false)
    const [activeObservationConfigTab, setActiveObservationConfigTab] = useState("Admin")

    const [formattedTeacherObservationRubrics, setFormattedTeacherObservationRubrics] = useState(null)
    const [formattedTeacherCertificationRubrics, setFormattedTeacherCertificationRubrics] = useState(null)

    const [formattedAdminObservationRubrics, setFormattedAdminObservationRubrics] = useState(null)
    const [formattedAdminCertificationRubrics, setFormattedAdminCertificationRubrics] = useState(null)

    const validationMgr = useValidationManager(false, 'ALL');
    const validationOrgMgr = useValidationManager(false, 'ORGANIZATION');
    const params = useParams();

    useEffect(() => {
        if (!loadingConfigs) {
            setObservationConfig(observationConfigs)

            if (observationConfigs.IsActive && observationConfigs.ClientId && item.Id === observationConfigs.ClientId) {
                setOverride(true)
            }

            setFormattedTeacherCertificationRubrics(_.map(_.filter(teacherRubricList, x => (x.RubricType === 3 || x.RubricType === 1) && x.RubricDomainMap?.some(z => z.UseForCertification === true)), y => { return { text: y.Name, value: y.Id } }))
            setFormattedTeacherObservationRubrics(_.map(_.filter(teacherRubricList, x => x.RubricType === 1), y => { return { text: y.Name, value: y.Id } }))
            setFormattedAdminCertificationRubrics(_.map(_.filter(adminRubricList, x => (x.RubricType === 7 || x.RubricType === 4) && x.RubricDomainMap?.some(z => z.UseForCertification === true)), y => { return { text: y.Name, value: y.Id } }))
            setFormattedAdminObservationRubrics(_.map(_.filter(adminRubricList, x => x.RubricType === 4), y => { return { text: y.Name, value: y.Id } }))
        }
    }, [loadingConfigs])


    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    const handleOnSave = async (e) => {

        const validateAll = validationMgr.checkIsGroupValid(item);
        const validateOrg = validationOrgMgr.checkIsGroupValid(item)
        const validateResult = [...validateAll, ...validateOrg]
        if (validateResult?.length === 0) {
            setForceValidate(false);
            if (onSave) {
                setSaving(true);
                const completeConfig = SaveObservationQuestions(['Teacher', 'Admin'], observationConfig, updateObservationConfigs);
                if (completeConfig) {
                    var result = await onSave(item, completeConfig);

                    if (result?.Success) {
                        handleOnCancel();
                    }
                    else if (onErrors) {
                        onErrors([result?.Message?.DisplayMessage ?? [...(result?.MessageDetails ?? ['Unknown Error'])]])
                    }

                    setSaving(false);
                    return result;
                }
                else {
                    setSaving(false);
                    onErrors(['Onbservation config not loaded, please try again when observations configuraitons have completed loading.']);
                    return { Success: false };
                }
            }

        }
        else if (onErrors) {
            setForceValidate(true);
            onValidationErrors(validateResult);
        }

    }

    const handleOnChange = (value, field) => {
        let updatedItem = { ...item };
        updatedItem[field] = value;
        setItem(updatedItem);
    }

    const updateObservationConfigs = (value, field) => {
        //Provided a flag called was Edited to avoid saving if it wasn't edited.
        let updatedConfig = { ...observationConfig };
        if (!updatedConfig.ClientId) {
            updatedConfig.OrganizationId = item.ClientId
            updatedConfig.ClientId = item.ClientId
            updatedConfig.Id = undefined
            updatedConfig.ObservationConfigId = undefined
            updatedConfig._id = undefined
            updatedConfig.UniqueId = undefined
        }
        updatedConfig.wasEdited = true
        if (value?.FileId) {
            updatedConfig[field] = value?.FileId;
            if (field === 'CertificationLogTeacherFileId') {
                updatedConfig.CertificationLogTeacherFile = value;
            }
        } else {
            updatedConfig[field] = value;
        }

        setObservationConfig(updatedConfig);
    }

    const updateUserPositionRubricMap = (value, userPosition, rubricType) => {
        let updatedConfig = { ...observationConfig };
        if (value === 'UNK') {
            value = null
        }
        if (!updatedConfig.ClientId) {
            updatedConfig.OrganizationId = item.ClientId
            updatedConfig.ClientId = item.ClientId
            updatedConfig.Id = undefined
            updatedConfig.ObservationConfigId = undefined
            updatedConfig._id = undefined
            updatedConfig.UniqueId = undefined
        }
        updatedConfig.wasEdited = true
        let updatedUserPositionRubricMap = [...observationConfig.UserPositionRubricMap]
        let userPositionToUpdate = _.find(updatedUserPositionRubricMap, x => x.UserPosition == userPosition && x.RubricType == rubricType)
        if (userPositionToUpdate) {
            let userPositionToUpdateIndex = _.findIndex(updatedUserPositionRubricMap, x => x.UserPosition == userPosition && x.RubricType == rubricType)
            userPositionToUpdate.RubricId = value
            updatedUserPositionRubricMap.splice(userPositionToUpdateIndex, 1, userPositionToUpdate)
        } else {
            userPositionToUpdate = {
                RubricId: value,
                UserPosition: userPosition,
                RubricType: rubricType
            }
            updatedUserPositionRubricMap.push(userPositionToUpdate)
        }
        updatedConfig.UserPositionRubricMap = updatedUserPositionRubricMap
        setObservationConfig(updatedConfig)
    }
    const updateUserPositionSurveyTemplateMap = (value, userPosition, surveyType) => {
        let updatedConfig = { ...observationConfig };
        if (value === 'UNK') {
            value = null
        }
        updatedConfig.wasEdited = true
        let updatedUserPositionSurveyTemplateMap = [...observationConfig.UserPositionSurveyTemplateMap]
        let userPositionToUpdate = _.find(updatedUserPositionSurveyTemplateMap, x => x.UserPosition == userPosition && x.SurveyType == surveyType)
        if (userPositionToUpdate) {
            let userPositionToUpdateIndex = _.findIndex(updatedUserPositionSurveyTemplateMap, x => x.UserPosition == userPosition && x.SurveyType == surveyType)
            userPositionToUpdate.SurveyTemplateId = value
            updatedUserPositionSurveyTemplateMap.splice(userPositionToUpdateIndex, 1, userPositionToUpdate)
        } else {
            userPositionToUpdate = {
                SurveyTemplateId: value,
                UserPosition: userPosition,
                SurveyType: surveyType
            }
            updatedUserPositionSurveyTemplateMap.push(userPositionToUpdate)
        }
        updatedConfig.UserPositionSurveyTemplateMap = updatedUserPositionSurveyTemplateMap
        setObservationConfig(updatedConfig)
    }
    const updateUserPositionAlternativeMap = (value, userPosition) => {
        let updatedConfig = { ...observationConfig };
        updatedConfig.wasEdited = true
        let updatedUserPositionAlternativeMap = [...observationConfig.UserPositionAlternativeMap]
        let userPositionToUpdate = _.find(updatedUserPositionAlternativeMap, x => x.UserPosition == userPosition)
        if (userPositionToUpdate) {
            let userPositionToUpdateIndex = _.findIndex(updatedUserPositionAlternativeMap, x => x.UserPosition == userPosition)
            userPositionToUpdate.Name = value
            updatedUserPositionAlternativeMap.splice(userPositionToUpdateIndex, 1, userPositionToUpdate)
        } else {
            userPositionToUpdate = {
                Name: value,
                UserPosition: userPosition,
            }
            updatedUserPositionAlternativeMap.push(userPositionToUpdate)
        }
        updatedConfig.UserPositionAlternativeMap = updatedUserPositionAlternativeMap
        setObservationConfig(updatedConfig)
    }
    return (<>
        <div className='control-box-wrapper'>
            {item && <> <div className='control-box box-three-column'>
                <div className='role-perm-editor__header'>
                    <h4>Configuration</h4>
                </div>
                <InputField forceValidate={forceValidate} type="text" subTitle={'Once set, the name cannot be changed.'} value={item?.Name} isDisabled={org} title="Organization Name" fieldName="Name" groupId={'ORG'} onChange={handleOnChange} />
                <InputField forceValidate={forceValidate} type="text" value={item?.DisplayName} title="Display Name" fieldName="DisplayName" groupId={'ORG'} onChange={handleOnChange} />
                <InputField forceValidate={forceValidate} type="text" value={item?.Email} title="Contact Email Address" fieldName="Email" groupId={'ORG'} onChange={handleOnChange} />

                <InputField title="Active?" value={item?.IsActive} fieldName="IsActive" groupId={'ORGANIZATION'} onChange={handleOnChange} >
                    <CheckboxControl />
                </InputField>
            </div>
                <div className="observation-config-container" >
                    <div className="observation-config-header">
                        <h4>Configurations Management</h4>
                    </div>
                    {
                        observationConfig ? <>
                            <div className="tab-container">
                                <div className={"tab" + (activeObservationConfigTab === 'Admin' ? ' active' : '')} onClick={() => setActiveObservationConfigTab('Admin')}><span>School Admin</span></div>
                                <div className={"tab" + (activeObservationConfigTab === 'Teacher' ? ' active teacher-tab' : '')} onClick={() => setActiveObservationConfigTab('Teacher')}><span>Teacher</span></div>
                                <div className={"tab" + (activeObservationConfigTab === 'Display' ? ' active teacher-tab' : '')} onClick={() => setActiveObservationConfigTab('Display')}><span>Display</span></div>
                                <div className={"tab hidden-tab"} >
                                    <div className="toggle-wrapper">
                                        <span>Override?</span>
                                        <label className={"toggle-checkbox"}>
                                            <input type="checkbox" className='switch-input' checked={override} onClick={(e) => {
                                                setOverride(e.target.checked)
                                                updateObservationConfigs(e.target.checked, 'IsActive')
                                            }} />
                                            <span htmlFor="checkbox" className='switch-circle'></span>
                                        </label>
                                    </div></div>
                            </div>
                            <div className={"observation-config" + (override ? "" : " disabled")} >
                                <ObservationSection
                                    downloadMgr={orgMgr?.OrganizationManager}
                                    observationConfig={observationConfig}
                                    activeObservationConfigTab={activeObservationConfigTab}
                                    updateObservationConfigs={updateObservationConfigs}
                                    updateUserPositionRubricMap={updateUserPositionRubricMap}
                                    updateUserPositionSurveyTemplateMap={updateUserPositionSurveyTemplateMap}
                                    showCertPositions={true}
                                    forceValidate={forceValidate}
                                    adminSurveyList={adminSurveyList}
                                    teacherSurveyList={teacherSurveyList}
                                    formattedTeacherObservationRubrics={formattedTeacherObservationRubrics}
                                    formattedTeacherCertificationRubrics={formattedTeacherCertificationRubrics}
                                    formattedAdminObservationRubrics={formattedAdminObservationRubrics}
                                    formattedAdminCertificationRubrics={formattedAdminCertificationRubrics}
                                    orgTreeUsers={orgTreeUsers}
                                    updateUserPositionAlternativeMap={updateUserPositionAlternativeMap}
                                    certificationQuestions={certificationQuestions}
                                />

                            </div>
                        </> : <div className="loading-wrapper"><Loading size={'3rem'} /></div>
                    }

                </div>
            </>}
        </div>
        <div className='screen-footer screen-footer-right'>
            <div className='btn-wrapper-left screen-footer__btn'><ButtonControl disabled={saving} type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl></div>
            {canWrite && <div className='btn-wrapper-right screen-footer__btn'><ButtonControl loading={saving} type={'okay'} disabled={!item} onClick={handleOnSave}>Save</ButtonControl></div>}

        </div>
    </>)
}

export default OrganizationDetails;


