

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss'
import { useAppUserManager, useContentLibraryManager, useContentManager } from '../../../../../hooks/useManagers';
import { DialogControl } from '../../../../../components/controls/DialogControl';
import _ from 'lodash';
import Loading from '../../../../../components/Loading';
import ShowDetailsButton from '../../Slider/components/NetflixSlider/ShowDetailsButton';
import { ThumbUp as ThumbsUpIcon } from '@mui/icons-material';
import recommendedIcon from '../../../../../content/icons/recommended-white.svg'

export default function RelatedDialog({ open, content, onClose }) {
    const contentLibraryMgr = useContentLibraryManager();
    const contentMgr = useContentManager();
    const appUserMgr = useAppUserManager();
    const navigate = useNavigate();
    const [relatedContent, setRelatedContent] = useState(null);

    useEffect(() => {
        if (content && appUserMgr && contentLibraryMgr) {
            if (relatedContent == null) {
                contentLibraryMgr.GetRelatedContent(content.ContentId).then(r => {
                    if (r?.Success) {
                        setRelatedContent(r.Items);
                    }
                    else {
                        setRelatedContent([]);
                    }
                });
            }
        }
    }, [contentLibraryMgr, appUserMgr, content]);


    const handleOnSelected = async (selectedContent) => {
        contentMgr.SetSelectedContent(selectedContent);
        setRelatedContent(null);
        navigate(`/content?k=${selectedContent.KeyId}`);
        onClose();
    }

    const closeDialog = () => {
        onClose();
    }

    return (
        <>
            <DialogControl hideButtonRow={true} openDialog={open} cancelText={'Skip'} okText={'Save'} title={'Check out this related content...'} onCancel={closeDialog}>
                <div className='recom-dialog'>
                    {(relatedContent !== null || relatedContent?.length === 0) ? <>
                        <div className='recom-dialog-content'>
                            {relatedContent?.length === 0 && <h3>No related content found.</h3>}
                            {relatedContent?.length > 0 && <>
                                {_.map(relatedContent, rc => <div
                                    key={rc.KeyId}
                                    style={{
                                        backgroundImage: `url(${rc.ThumbnailFileUrl})`
                                    }}
                                    className={'item'}
                                >
                                    <span className="itemTitle" onClick={() => handleOnSelected(rc)} >{rc.Title} {rc.RecommendedForUser ? <img src={recommendedIcon} className={'title-icon'} /> : null} {rc.HasUserRating ? <ThumbsUpIcon className={'title-icon'} /> : null}</span>
                                    <ShowDetailsButton onClick={() => handleOnSelected(rc)} />
                                    {rc.RunningTime ? <span className="itemTime">{rc.RunningTime}</span> : <span></span>}
                                </div>
                                )}
                            </>}
                        </div>
                    </> : <div className="recom-dialog-loader"><Loading size={'4rem'} type="default" /></div>
                    }
                </div>
            </DialogControl>
        </>
    );
}
