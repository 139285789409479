import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _, { has } from 'lodash';
import AppUserManager from './AppUserManager';
class AnalyticManager extends ItemManager {
  constructor(orgMgr, globalItemState, globalAuthState, globalAppUserState) {
    super(ItemServiceFactory.ItemServiceEnum.Observation, globalAuthState, globalAppUserState, globalItemState,
      (filter, item) => {
        var hasSearch = item.QuestionText?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
        return hasSearch;
      });
    this._orgMgr = orgMgr
    this._userMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.UserProfile, globalAuthState, globalAppUserState);
    this._clusterMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Cluster, globalAuthState, globalAppUserState);
    this._schoolMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.School, globalAuthState, globalAppUserState);
    this._districtMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.District, globalAuthState, globalAppUserState);
    this._observationConfig = new ItemManager(ItemServiceFactory.ItemServiceEnum.ObservationConfig, globalAuthState, globalAppUserState);
    this._errorUtil = undefined;
    this._orgTreeLoaded = false;
  }

  async grabDefaultConfigByGroup(group, id) {
    let result;
    if (group === 'org') {
      result = await this._orgMgr.get(id)
    } else if (group === 'district') {
      result = await this._districtMgr.get(id)
    } else if (group === 'school') {
      result = await this._schoolMgr.get(id)
    } else if (group === 'observationConfig') {
      result = await this._observationConfig.get(id)
    }

    if (result.Success) {
      return result.Items.first()
    } else return 'Can not get group'
  }

  async saveDefaultConfigByGroup(group, item) {
    let result;
    if (group === 'org') {
      result = await this._orgMgr.save(item)
    } else if (group === 'district') {
      result = await this._districtMgr.save(item)
    } else if (group === 'school') {
      result = await this._schoolMgr.save(item)
    } else if (group === 'observationConfig') {
      result = await this._observationConfig.save(item)
    }

    if (result.Success) {
      return result
    } else return 'Can not save group'
  }


  async grabGroup(group, id) {
    let result;
    if (!this._orgTreeLoaded) {
      await this._orgMgr.loadOrgTree(true, false);
      this._orgTreeLoaded = true;
    }
    if (group === 'Org') {
      result = await this._orgMgr.OrgsOnly()
    } else if (group === 'District') {
      result = this._orgMgr.DistrictsOnly(id)
    } else if (group === 'School') {
      result = this._orgMgr.SchoolsOnly(id)
    } else if (group === 'Cluster') {
      if (id?.length > 0) {
        const tempQuery = _.map(id, x => `SchoolId == "${x}"`)
        result = this._clusterMgr.query(`x => x.IsActive == true && x.IsDeleted == false && tempQuery`, { tempQuery }).then(r => {
          if (r.Success) {
            return r.Items
          }
        })
      }
    }
    return result
  }
  hasConfigureDefaultsPermissions() {
    return AppUserManager.checkPermission('AnalyticEditDefaults', 'Write', this.AppUserState);
  }

  transformDataIntoChart(analyticData) {
    const chartData = { ...analyticData }

    if (chartData.Data.length === 0 || !(chartData.Data.some(x => !_.isNil(x.Value1)) || chartData.Data.some(x => !_.isNil(x.Value2)) || chartData.Data.some(x => !_.isNil(x.Value3)) || chartData.Data.some(x => !_.isNil(x.Value4)))) {
      return "No Data"
    }

    if (chartData.AnalyticType == 7) {
      chartData.chartType = "gauge"

      chartData.keys = _.map(analyticData.Data, x => {
        return { text: x.GroupByName, value: x.GroupById }
      })
      chartData.selectedId = analyticData.selectedId ?? chartData.Data.first().GroupById
    }
    else if (chartData.AnalyticType == 1 || chartData.AnalyticType == 4 || chartData.AnalyticType == 0 || chartData.AnalyticType == 2 || chartData.AnalyticType == 3 || chartData.AnalyticType == 8) {
      chartData.chartType = "bar"
    }
    else if (chartData.AnalyticType == 5 || chartData.AnalyticType == 6) {
      chartData.chartType = "pie"
      const groupBy = _.groupBy(analyticData.Data, x => x.GroupById)
      chartData.Data = _.map(groupBy, y => y)
      chartData.keys = _.map(Object.entries(groupBy), ([key, value]) => {
        return { text: value.first().GroupByName, value: key }
      })
      chartData.selectedId = analyticData.selectedId ?? chartData.keys.first().value
    }
    return chartData
  }

  async retrieveChartData(item, editingId, isUpdate) {
    const analyticConfig = {
      ClientIds: item.ClientIds?.length > 0 ? item.ClientIds : null,
      DistrictIds: item.DistrictIds?.length > 0 ? item.DistrictIds : null,
      SchoolIds: item.SchoolIds?.length > 0 ? item.SchoolIds : null,
      ClusterIds: item.ClusterIds?.length > 0 ? item.ClusterIds : null,
      GroupBy: item.GroupBy,
      BeginDate: item.BeginDate ?? null,
      EndDate: item.EndDate ?? null,
      SchoolYearIdentifier: !item.useSpecificDates ? item.SchoolYearIdentifier == 0 ? this.AppUserState.selectedSchoolYear : item.SchoolYearIdentifier : null,
      AnalyticType: item.AnalyticType,
    }

    const result = await this.runOperation('ObservationAnalytics', undefined, { analyticsrequest: analyticConfig }).then(r => {
      if (r.Success && r.Items.length > 0) {
        const analyticData = { ...item }
        analyticData.Data = r.Items.first().Data
        analyticData.Id = editingId ?? r.Items.first().Id
        analyticData.editingMode = isUpdate ? true : undefined;
        analyticData.AnalyticType = r.Items.first().AnalyticType
        analyticData.failToLoad = undefined
        return this.transformDataIntoChart(analyticData)
      } else if (r.MessageDetails.first().toLowerCase().includes('timeout')) {
        return "Timeout"
      } else return "Error"
    })
    return result
  }

  async saveChartLayout(item) {
    const userProfile = { ...this.AppUserState.userProfile }
    userProfile.AnalyticsConfig = JSON.stringify(item)
    let result = await this._userMgr.save(userProfile)
    if (result.Success) {
      this._globalAppUserState.merge({ userProfile: result.Items.first() })
    }
    return result
  }
  get DefaultItem() {
    return {
      Size: 1,
      ClientIds: [],
      DistrictIds: [],
      SchoolIds: [],
      ClusterIds: [],
      organizations: [],
      districts: [],
      schools: [],
      clusters: [],
      useClusters: false,
      useSpecificDates: false,
      GroupBy: "Client",
      BeginDate: "",
      EndDate: "",
      SchoolYearIdentifier: this.AppUserState?.selectedSchoolYear
    };
  }
}

export default AnalyticManager;