import { BaseService } from './BaseService';


export class ItemService extends BaseService {

    constructor(apiName, authState, uriType, timeout) {
        super(apiName, authState, uriType, timeout);
    }

    async save(item) {
        const axiosInstance = super._createAxiosInstance();
        return axiosInstance.put(this._url, item)
            .then(r => { return r.data; })
            .catch(e => { return super._createAxiosError(e); });
    }

    async single(id) {
        const axiosInstance = super._createAxiosInstance();
        return axiosInstance.get(this._url + "/" + id)
            .then(r => { return r.data; })
            .catch(e => { return super._createAxiosError(e); });
    }

    async list(itemFilter, ignoreSqlInclude, itemIncludes) {
        const axiosInstance = super._createAxiosInstance(ignoreSqlInclude, itemIncludes);
        return axiosInstance.post(this._url, itemFilter ? itemFilter : {})
            .then(r => { return r.data; })
            .catch(e => { return super._createAxiosError(e); });
    }

    async delete(id) {
        const axiosInstance = super._createAxiosInstance();
        return axiosInstance.delete(this._url + "/" + id)
            .then(r => { return r.data; })
            .catch(e => { return super._createAxiosError(e); });
    }

    async hardDelete(id) {
        const axiosInstance = super._createAxiosInstance();
        axiosInstance.defaults.headers.common["c-dx-operation"] = "HardDelete";
        return axiosInstance.delete(this._url + "/" + id)
            .then(r => { return r.data; })
            .catch(e => { return super._createAxiosError(e); });
    }

    uiMetadata() {
        const axiosInstance = super._createAxiosInstance();
        const config = { headers: { "c-dx-operation": "UIMetadata" } };
        return axiosInstance.get(this._url, config)
            .then(r => { return r.data; })
            .catch(e => { return super._createAxiosError(e); });
    }
}