import React, { useEffect } from 'react';
import globalErrorStore from '../stores/globalErrorStore';
import { useHookstate } from '@hookstate/core';

const Error = () => {

    const globalErrorState = useHookstate(globalErrorStore);
    let currentError;
    useEffect(() => {
        const errors = globalErrorState.get();
        currentError = "number of errors: " + errors.length;
    }, [globalErrorState]);

    const renderError = <>{
        currentError && <div>
            {currentError}
        </div>
    }</>

    return renderError;
}

export default Error;