import React, { useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { SurveyDataProvider } from './components/SurveyDataProvider';
import GeneralSurveys from './GeneralSurveys';
import TeacherSurveys from './TeacherSurveys';
import PrincipalSurveys from './PrincipalSurveys';
import { useAppUserManager } from '../../hooks/useManagers';
import AccessDeny from '../../components/security/AccessDeny';

const SurveyScreen = () => {
    const params = useParams();
    const appUserMgr = useAppUserManager();
    const renderSurvey = () => {
        if (params?.surveytype === 'generalsurvey' && appUserMgr?.AccessCheck(['GeneralSurvey', 'NavGeneralSurvey'])) {
            return <GeneralSurveys />
        } else if (params?.surveytype === 'principalsurvey' && appUserMgr?.AccessCheck(['NavAdministratorSurvey'])) {
            return <PrincipalSurveys />
        } else if (params?.surveytype === 'teachersurvey' && appUserMgr?.AccessCheck(['NavTeacherSurvey'])) {
            return <TeacherSurveys />
        } else return <AccessDeny />
    }
    return (<>
        {appUserMgr && <SurveyDataProvider>
            <>
                {renderSurvey()}
            </>
        </SurveyDataProvider>}
    </>
    );
}

export default SurveyScreen;