import React from 'react';

const LabelControl = ({ field,
   value,
   type,
   className,
   style,
   disabled,
   loading,
   children,
   props
}) => {

   if (value === "" && (field.FieldName === "CreatedDate" || field.FieldName === "CreatedBy" || field.FieldName === "UpdatedDate" || field.FieldName === "UpdatedBy")) {
      return null;
   }
   else if (children) {
      return <div className={className ? className : 'default-label'} style={style ? style : null}>{children}</div>
   }
   else {
      return (<div className={className ? className : null} key={"field_label_" + field.FieldName} {...props}>
         <div>{field.DisplayName}</div>
         <div>{value}</div>
      </div>
      );
   }
}

export default LabelControl;