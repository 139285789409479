
import _ from 'lodash'
import BaseService from './BaseService'
const ContentTypeEnum = {
    Video: 1,
    Resource: 2,
    LearningModule: 3,
    Thumbnail: 4,
    FullImage: 5
}

class ContentService extends BaseService {

    constructor() {
        super();
        this.defaultFailResult = super.defaultFailResult;
    }

    DefaultSearchContentModel() {
        return {
            ContentId: undefined,
            ContentTypeId: undefined,
            Title: null,
            SelectedTags: [],
            Description: null
        }
    }

    DefaultShareContentModel() {
        return {
            ContentId: 0,
            UserIds: [],
            NotifiyWhenComplete: false
        }
    }

    async SharedContent() {
        let result = {
            "Items": [{
                "SharedWithUser": [],
                "UserHasShared": [
                    {
                        "ContentShareId": 0,
                        "UserId": 25597,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Gandalph TheGray",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-09-27T15:12:24.563Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 46,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "AR test size Artic",
                        "Description": "AR test size Artic",
                        "KeyId": "332b2ef4-64dc-4ee2-a421-142a976cf576",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "ASU,Modules,Training Materials",
                        "TagIds": [
                            20,
                            19,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 20,
                                "Name": "ASU",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": true
                            },
                            {
                                "Sequence": 0,
                                "TagId": 19,
                                "Name": "Modules",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25621,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Dev DistrictC",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-10-11T08:47:10.420Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 46,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "AR test size Artic",
                        "Description": "AR test size Artic",
                        "KeyId": "332b2ef4-64dc-4ee2-a421-142a976cf576",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "ASU,Modules,Training Materials",
                        "TagIds": [
                            20,
                            19,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 20,
                                "Name": "ASU",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": true
                            },
                            {
                                "Sequence": 0,
                                "TagId": 19,
                                "Name": "Modules",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25620,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Dev DistrictB",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-10-12T15:51:10.363Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 46,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "AR test size Artic",
                        "Description": "AR test size Artic",
                        "KeyId": "332b2ef4-64dc-4ee2-a421-142a976cf576",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "ASU,Modules,Training Materials",
                        "TagIds": [
                            20,
                            19,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 20,
                                "Name": "ASU",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": true
                            },
                            {
                                "Sequence": 0,
                                "TagId": 19,
                                "Name": "Modules",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25619,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Dev DistrictAOne",
                        "HasAccessed": true,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-09-30T06:41:38.597Z",
                        "DateCompleted": "2021-10-05T10:52:52.113Z",
                        "ContentId": 17,
                        "DepartmentId": 0,
                        "ContentTypeId": 2,
                        "ContentTypeName": null,
                        "ContentType": "Resource",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Blue Lizard PPT",
                        "Description": "Super awesome blue lizard ppt file",
                        "KeyId": "fb6392c5-0254-4a83-b78a-a4587e5a67d9",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Document Library,Presentations",
                        "TagIds": [
                            10,
                            12
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 10,
                                "Name": "Document Library",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 12,
                                "Name": "Presentations",
                                "ParentTagName": "Document Library",
                                "ParentTagId": 10,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 9402,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "UserTest VerificationTestUser",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-08-16T09:58:51.730Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 3,
                        "DepartmentId": 0,
                        "ContentTypeId": 1,
                        "ContentTypeName": null,
                        "ContentType": "Video",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Calming Earth",
                        "Description": "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.",
                        "KeyId": "be75e421-7cb0-44e3-89e4-9efee1c12d2f",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Student Engagement,Training Materials,Videos",
                        "TagIds": [
                            14,
                            7,
                            13
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 14,
                                "Name": "Student Engagement",
                                "ParentTagName": "Videos",
                                "ParentTagId": 13,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 13,
                                "Name": "Videos",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25547,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "TeacherA Zaffer",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-08-16T09:58:51.750Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 3,
                        "DepartmentId": 0,
                        "ContentTypeId": 1,
                        "ContentTypeName": null,
                        "ContentType": "Video",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Calming Earth",
                        "Description": "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.",
                        "KeyId": "be75e421-7cb0-44e3-89e4-9efee1c12d2f",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Student Engagement,Training Materials,Videos",
                        "TagIds": [
                            14,
                            7,
                            13
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 14,
                                "Name": "Student Engagement",
                                "ParentTagName": "Videos",
                                "ParentTagId": 13,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 13,
                                "Name": "Videos",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25549,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "TeacherB Zaffer",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-08-16T09:58:51.770Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 3,
                        "DepartmentId": 0,
                        "ContentTypeId": 1,
                        "ContentTypeName": null,
                        "ContentType": "Video",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Calming Earth",
                        "Description": "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.",
                        "KeyId": "be75e421-7cb0-44e3-89e4-9efee1c12d2f",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Student Engagement,Training Materials,Videos",
                        "TagIds": [
                            14,
                            7,
                            13
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 14,
                                "Name": "Student Engagement",
                                "ParentTagName": "Videos",
                                "ParentTagId": 13,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 13,
                                "Name": "Videos",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25548,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Teacher Zaffer",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-08-16T09:58:51.780Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 3,
                        "DepartmentId": 0,
                        "ContentTypeId": 1,
                        "ContentTypeName": null,
                        "ContentType": "Video",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Calming Earth",
                        "Description": "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.",
                        "KeyId": "be75e421-7cb0-44e3-89e4-9efee1c12d2f",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Student Engagement,Training Materials,Videos",
                        "TagIds": [
                            14,
                            7,
                            13
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 14,
                                "Name": "Student Engagement",
                                "ParentTagName": "Videos",
                                "ParentTagId": 13,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 13,
                                "Name": "Videos",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25597,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Gandalph TheGray",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-09-30T13:50:33.273Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 18,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Hippo Articulate",
                        "Description": "Adri\\u0027s Articulate",
                        "KeyId": "825ed678-8b5d-4055-a2ba-8adec5a7a9ca",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Feedback,Training Materials",
                        "TagIds": [
                            9,
                            8,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 8,
                                "Name": "Feedback",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25658,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Elf Test",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-09-30T13:50:33.453Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 18,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Hippo Articulate",
                        "Description": "Adri\\u0027s Articulate",
                        "KeyId": "825ed678-8b5d-4055-a2ba-8adec5a7a9ca",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Feedback,Training Materials",
                        "TagIds": [
                            9,
                            8,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 8,
                                "Name": "Feedback",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25659,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Hobbit Test",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-09-30T13:50:33.473Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 18,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Hippo Articulate",
                        "Description": "Adri\\u0027s Articulate",
                        "KeyId": "825ed678-8b5d-4055-a2ba-8adec5a7a9ca",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Feedback,Training Materials",
                        "TagIds": [
                            9,
                            8,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 8,
                                "Name": "Feedback",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25618,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Dev DistrictA",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-10-12T15:50:02.897Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 18,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Hippo Articulate",
                        "Description": "Adri\\u0027s Articulate",
                        "KeyId": "825ed678-8b5d-4055-a2ba-8adec5a7a9ca",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Feedback,Training Materials",
                        "TagIds": [
                            9,
                            8,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 8,
                                "Name": "Feedback",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25597,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Gandalph TheGray",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-09-30T13:57:01.753Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 43,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "LW Articulate",
                        "Description": "Testing Articulate file upload",
                        "KeyId": "f0d452d4-6e35-4f0e-809e-1e4fe8305963",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Modules,Training Materials",
                        "TagIds": [
                            9,
                            19,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 19,
                                "Name": "Modules",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25658,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Elf Test",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-09-30T13:57:01.773Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 43,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "LW Articulate",
                        "Description": "Testing Articulate file upload",
                        "KeyId": "f0d452d4-6e35-4f0e-809e-1e4fe8305963",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Modules,Training Materials",
                        "TagIds": [
                            9,
                            19,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 19,
                                "Name": "Modules",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25659,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Hobbit Test",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-09-30T13:57:01.793Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 43,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "LW Articulate",
                        "Description": "Testing Articulate file upload",
                        "KeyId": "f0d452d4-6e35-4f0e-809e-1e4fe8305963",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Modules,Training Materials",
                        "TagIds": [
                            9,
                            19,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 19,
                                "Name": "Modules",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25589,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Ryan Zaffer",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-10-07T09:46:06.287Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 43,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "LW Articulate",
                        "Description": "Testing Articulate file upload",
                        "KeyId": "f0d452d4-6e35-4f0e-809e-1e4fe8305963",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Modules,Training Materials",
                        "TagIds": [
                            9,
                            19,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 19,
                                "Name": "Modules",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25590,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Ryan Zaffer",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-10-07T09:46:06.403Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 43,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "LW Articulate",
                        "Description": "Testing Articulate file upload",
                        "KeyId": "f0d452d4-6e35-4f0e-809e-1e4fe8305963",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Modules,Training Materials",
                        "TagIds": [
                            9,
                            19,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 19,
                                "Name": "Modules",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25539,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "adriana riedel",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-10-07T09:46:06.423Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 43,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "LW Articulate",
                        "Description": "Testing Articulate file upload",
                        "KeyId": "f0d452d4-6e35-4f0e-809e-1e4fe8305963",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Modules,Training Materials",
                        "TagIds": [
                            9,
                            19,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 19,
                                "Name": "Modules",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25546,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Ryan Zaffer",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-10-07T09:46:06.443Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 43,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "LW Articulate",
                        "Description": "Testing Articulate file upload",
                        "KeyId": "f0d452d4-6e35-4f0e-809e-1e4fe8305963",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Modules,Training Materials",
                        "TagIds": [
                            9,
                            19,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 19,
                                "Name": "Modules",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25614,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "adriana riedel",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-10-07T09:46:06.463Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 43,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "LW Articulate",
                        "Description": "Testing Articulate file upload",
                        "KeyId": "f0d452d4-6e35-4f0e-809e-1e4fe8305963",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Modules,Training Materials",
                        "TagIds": [
                            9,
                            19,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 19,
                                "Name": "Modules",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25585,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Toby McTesterson",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-10-07T09:46:06.477Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 43,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "LW Articulate",
                        "Description": "Testing Articulate file upload",
                        "KeyId": "f0d452d4-6e35-4f0e-809e-1e4fe8305963",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Modules,Training Materials",
                        "TagIds": [
                            9,
                            19,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 19,
                                "Name": "Modules",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25587,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Alan Dandar",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-10-07T09:46:06.513Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 43,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "LW Articulate",
                        "Description": "Testing Articulate file upload",
                        "KeyId": "f0d452d4-6e35-4f0e-809e-1e4fe8305963",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Activities,Modules,Training Materials",
                        "TagIds": [
                            9,
                            19,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 9,
                                "Name": "Activities",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 19,
                                "Name": "Modules",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25545,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Teacher LevelTwo",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-08-12T11:52:22.810Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 2,
                        "DepartmentId": 0,
                        "ContentTypeId": 2,
                        "ContentTypeName": null,
                        "ContentType": "Resource",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Requirements",
                        "Description": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.",
                        "KeyId": "4d6213a2-17a4-41b5-ad9b-e5e6fb919fed",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Feedback,Training Materials",
                        "TagIds": [
                            8,
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 8,
                                "Name": "Feedback",
                                "ParentTagName": "Training Materials",
                                "ParentTagId": 7,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25619,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Dev DistrictAOne",
                        "HasAccessed": true,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-09-30T06:26:34.033Z",
                        "DateCompleted": "2021-10-14T13:35:01.630Z",
                        "ContentId": 50,
                        "DepartmentId": 0,
                        "ContentTypeId": 2,
                        "ContentTypeName": null,
                        "ContentType": "Resource",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Test Doc 4",
                        "Description": "Test Document - Resource",
                        "KeyId": "99d2b3ed-4339-4069-a13c-6ccb282a9223",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Articles,Document Library",
                        "TagIds": [
                            16,
                            10
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 16,
                                "Name": "Articles",
                                "ParentTagName": "Document Library",
                                "ParentTagId": 10,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            },
                            {
                                "Sequence": 0,
                                "TagId": 10,
                                "Name": "Document Library",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25548,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Teacher Zaffer",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-08-16T11:46:01.053Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 37,
                        "DepartmentId": 0,
                        "ContentTypeId": 3,
                        "ContentTypeName": null,
                        "ContentType": "Learning Module",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Test Module",
                        "Description": "The description of the module will go here",
                        "KeyId": "6f735d98-510d-4cea-b59e-9c1d6415395e",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Training Materials",
                        "TagIds": [
                            7
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 7,
                                "Name": "Training Materials",
                                "ParentTagName": null,
                                "ParentTagId": null,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25622,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Dev SchoolAOne",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-09-30T06:19:07.917Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 55,
                        "DepartmentId": 0,
                        "ContentTypeId": 1,
                        "ContentTypeName": null,
                        "ContentType": "Video",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Test River Video",
                        "Description": "Test",
                        "KeyId": "09e4d610-3b95-4d6f-a9c0-cab90c6769e9",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Student Engagement",
                        "TagIds": [
                            14
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 14,
                                "Name": "Student Engagement",
                                "ParentTagName": "Videos",
                                "ParentTagId": 13,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25623,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Dev SchoolAOneOne",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-09-30T06:20:06.520Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 55,
                        "DepartmentId": 0,
                        "ContentTypeId": 1,
                        "ContentTypeName": null,
                        "ContentType": "Video",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Test River Video",
                        "Description": "Test",
                        "KeyId": "09e4d610-3b95-4d6f-a9c0-cab90c6769e9",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Student Engagement",
                        "TagIds": [
                            14
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 14,
                                "Name": "Student Engagement",
                                "ParentTagName": "Videos",
                                "ParentTagId": 13,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    },
                    {
                        "ContentShareId": 0,
                        "UserId": 25624,
                        "OwnerUserId": 96,
                        "UserHasShared": true,
                        "UserFullName": "Dev SchoolAOneTwo",
                        "HasAccessed": false,
                        "NotifiyWhenComplete": false,
                        "DateShared": "2021-09-30T06:25:12.843Z",
                        "DateCompleted": "1/1/1900",
                        "ContentId": 55,
                        "DepartmentId": 0,
                        "ContentTypeId": 1,
                        "ContentTypeName": null,
                        "ContentType": "Video",
                        "MimeType": null,
                        "FileId": 0,
                        "ThumbnailFileId": 0,
                        "RunningTime": null,
                        "File": null,
                        "ThumbnailFile": null,
                        "DisplayFileName": null,
                        "Title": "Test River Video",
                        "Description": "Test",
                        "KeyId": "09e4d610-3b95-4d6f-a9c0-cab90c6769e9",
                        "Reference": null,
                        "Metadata": null,
                        "Sequence": 0,
                        "Tags": "Student Engagement",
                        "TagIds": [
                            14
                        ],
                        "TagList": [
                            {
                                "Sequence": 0,
                                "TagId": 14,
                                "Name": "Student Engagement",
                                "ParentTagName": "Videos",
                                "ParentTagId": 13,
                                "IsEnabled": true,
                                "CreatedOn": "\\/Date(-62135578800000)\\/",
                                "IsHidden": false
                            }
                        ],
                        "HasUserRating": false,
                        "OverallRating": 0,
                        "IsEnabled": true,
                        "MediaReady": 0,
                        "MediaReadyError": null,
                        "AssocatedContent": null,
                        "SharedWithUser": false,
                        "CanEditFull": false,
                        "CanView": true,
                        "CreatedOn": "1/1/0001"
                    }
                ]
            }], Success: true
        };//await super.AxiosGet(super.fullPath("sharedContent"), null);
        //if (result.isError && process.env.NODE_ENV === "development") {
        //   result = { "data": [], "errorMessages": [], "errorMessage": null, "isSuccessful": true, "isError": false };
        //}
        return result;
    }

    async ContentViewed(contentId) {
        //let uri = super.fullPath("contentViewed/" + contentId);
        //return await super.AxiosGet(uri, null);

        return { Success: true }
    }
}

export { ContentService, ContentTypeEnum };