import React, { useState, useEffect } from "react";
import Header from "./images/Header.svg";
import NIETLogo from "./images/NIETLogo.png";
import NIETSignature from "./images/NIETSignature.png";
import "./PrintCertification.scss";
import moment from "moment";
import PrintLayout from "../../../../components/layout/PrintLayout";
import { FormatUTCDateM } from "../../../../utilities/DateFormater";


function PrintCertification({ close, data, observationConfig, certType }) {
  const [loaded, setLoaded] = useState({ allLoaded: false, NIETLogoFile: false, CertificationLogoFile: false });

  useEffect(() => {
    if (!loaded.allLoaded && observationConfig && data && loaded.NIETLogoFile) {
      const isAdminCertType = certType === 1;
      const isTeacherCertType = certType === 2;
  
      const adminCertFileLoaded = observationConfig.CertificationLogAdminFileId && loaded.CertificationLogoFile;
      const adminCertFileNotRequired = !observationConfig.CertificationLogAdminFileId && !loaded.CertificationLogoFile;
  
      const teacherCertFileLoaded = observationConfig.CertificationLogTeacherFileId && loaded.CertificationLogoFile;
      const teacherCertFileNotRequired = !observationConfig.CertificationLogTeacherFileId && !loaded.CertificationLogoFile;
  
      const shouldLoadAdminCert = isAdminCertType && (adminCertFileLoaded || adminCertFileNotRequired);
      const shouldLoadTeacherCert = isTeacherCertType && (teacherCertFileLoaded || teacherCertFileNotRequired);
  
      if (shouldLoadAdminCert || shouldLoadTeacherCert) {
        setLoaded({ allLoaded: true });
      }
    }
  }, [observationConfig, data, loaded, certType]);

  const preloadImage = (src, field) => {
    const img = new Image();
    img.onload = () => handleLoad(field);
    img.src = src;
  };

  useEffect(() => {
    setLoaded({ allLoaded: false, NIETLogoFile: false, CertificationLogoFile: false });

    if (certType === 1 && observationConfig?.CertificationLogAdminFile?.FileUrl) {
      preloadImage(observationConfig.CertificationLogAdminFile.FileUrl, "CertificationLogoFile");
    } else if (certType === 2 && observationConfig?.CertificationLogTeacherFile?.FileUrl) {
      preloadImage(observationConfig.CertificationLogTeacherFile.FileUrl, "CertificationLogoFile");
    }

    preloadImage(NIETLogo, "NIETLogoFile");
  }, [data, observationConfig, certType]);

  const handleClose = () => {
    setLoaded(false)
    close();
  }

  const handleLoad = (field) => {
    setLoaded({ ...loaded, [field]: true });
  }
  return (<>
    {
      <PrintLayout
        print={loaded?.allLoaded}
        cleanUp={handleClose}
        className={'print-wrapper-layout'}
      >
        <div className="print-modal-wrapper">
          <div className="print-modal">
            <div className="print-modal-header">
              <img src={Header} alt="Header" />
              <div className="print-modal-header-descriptor">
                <h3>This certifies that</h3>
              </div>
              <h2>{data?.User?.FirstName}{' '}{data?.User?.LastName}</h2>
            </div>
            <div className="print-modal-body">
              <div className="print-modal-body-descriptor">
                <h3>
                  has demonstrated proficiency in the{" "}
                  {certType == 1 ? observationConfig?.CertificationNameAdmin : observationConfig?.CertificationNameTeacher}
                  {" "}
                  and is a
                </h3>
              </div>
              <div className="print-modal-body-year">
                <h2>{data?.SchoolYearIdentifier - 1}-{data?.SchoolYearIdentifier}</h2>
              </div>
              <div className="print-modal-body-title">
                <h2>
                  {(observationConfig?.OrganizationId?.toUpperCase() == '3EC1CB94-BB53-4D34-BA4D-C2BA4CE6F4AA') ? 'Certified ATR Evaluator' : 
                    certType == 1 ? observationConfig?.CertificationTitleAdmin : observationConfig?.CertificationTitleTeacher}
                </h2>
              </div>
            </div>
            <div className="print-modal-bottom">
              <div className="print-modal-bottom-descriptor">
                <h5>
                  An initiative of the National Institute for Excellence in
                  Teaching
                </h5>
              </div>
              <div className="print-modal-bottom-logos">
                <img
                  src={NIETLogo}
                  alt="Niet Logo"
                  onLoad={() => handleLoad("NIETLogoFile")}
                />
                <div className="print-modal-bottom-logos-box">
                  {certType == 1 && observationConfig?.CertificationLogAdminFileId ? (
                    <img
                      src={observationConfig?.CertificationLogAdminFile?.FileUrl}
                      alt="Certification Logo"
                      onLoad={() => handleLoad("CertificationLogoFile")}
                    />
                  )
                    : certType == 2 && observationConfig?.CertificationLogTeacherFileId && (
                      <img
                        src={observationConfig?.CertificationLogTeacherFile?.FileUrl}
                        alt="Certification Logo"
                        onLoad={() => handleLoad("CertificationLogoFile")}
                      />
                    )
                  }
                </div>
              </div>
            </div>
            <div className="print-modal-footer-wrapper">
              <div className="print-modal-footer">
                <div className="print-modal-footer-signature">
                  <img src={NIETSignature} alt="Signature" />
                  <span className="print-modal-footer-signline" />
                  <h5>Dr. Joshua Barnett, Chief Executive Officer</h5>
                </div>
                <div className="print-modal-footer-date">
                  <h4>
                    {FormatUTCDateM(data?.CompletedDate, "MM/DD/YYYY")}
                  </h4>
                  <span className="print-modal-footer-signline" />
                  <h5>Date</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PrintLayout>
    }
  </>);
}

export default PrintCertification;
