   
import { Filter } from "@mui/icons-material";
import _ from "lodash";
import { ItemServiceFactory } from "../services/ItemServiceFactory";
import ItemManager from "./ItemManager";

export default class UserProfileEmailManager extends ItemManager {

    constructor(userState, globalAuthState, globalAppUserState) {
        super(ItemServiceFactory.ItemServiceEnum.UserProfileEmail, globalAuthState, globalAppUserState)
        this._userState = userState;
    }

    get ItemManager() {
        return this;
    }
}