import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { FormatUTCDate } from "../../../../utilities/DateFormater";



const SchoolTable = ({ filteredItems, onEdit, onDelete, onDrillDown, isDrilledDown }) => {
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: "Organization Name",
            dataProp: "OrganizationName",
            width: '30%',
            canSort: true,
            hideData: isDrilledDown && true
        },
        {
            header: "District Name",
            dataProp: "DistrictName",
            width: '30%',
            canSort: true,
            hideData: isDrilledDown && true
        },
        {
            header: 'School Name',
            dataProp: "Name",
            width: '30%',
            canSort: true
        },
        {
            header: 'School Number',
            dataProp: 'SchoolNumber',
            width: '15%',
            canSort: true
        },

        {
            header: 'Active',
            dataProp: 'IsActive',
            width: '10%',
            datatype: 'bool',
            canSort: true
        },
        {
            header: 'Created Date',
            dataProp: 'CreatedDate',
            width: '18%',
            datatype: 'dateTime',
            canSort: true
        }
    ]);

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (c) => {
                newRows.push({
                    ...c,
                    CreatedDate: FormatUTCDate(c.CreatedDate),
                    OrganizationName: c?.Organization?.Name,
                    DistrictName: c?.District?.Name,
                });
            });
            setRows(newRows);
        }
    }, [filteredItems]);

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                permission='School'
                useIntegratedPaging={['10', '25', '50', '100']}
                onEdit={(row) => onEdit(row)}
                onDelete={onDelete}
                onCustom1={{ action: (row) => <FontAwesomeIcon className={'btn-icon-fa'} title="View Associated Clusters" icon={faArrowDown} onClick={() => onDrillDown(row)} /> }}
            />
        </div>
    </>
}

export default SchoolTable;