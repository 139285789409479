import React, { useState, useEffect } from 'react';
import * as gaMgr from '../../../managers/GoogleAnalyticsManager';
import { EventTypeEnum } from '../../../managers/GoogleAnalyticsManager';
import './../video-preview.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faTimes } from '@fortawesome/free-solid-svg-icons';
import videos from './../data/sampleVideoData.json';
import BitmovinPlayer from '../../contentLibrary/app-components/Content/Player/BitmovinPlayer';

const VideoPreview = ({ data }) => {
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [thumbnailURL, setThumbnailURL] = useState('');
  const [sampleURL, setSampleURL] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState();

  useEffect(() => {
    if (videos) {
      const video = videos;
      setThumbnailURL(video.thumbnailVideoURL);
      setContent({DashUrl: video.DashUrl, HlsUrl: video.HlsUrl});
      setIsLoading(false);
    }
  }, [videos]);

  const handlePlay = () => {
    setOpenVideoDialog(true);
    gaMgr.AddEvent(EventTypeEnum.Content_Accessed, 'sample_video');
  };

  const handleCloseClick = () => {
    setOpenVideoDialog(false);
  };

  return (
    <div className="video-preview-container">
      <h3 className="video-header">
        Unlimited access to content and training. Interact with the growing NIET community.
      </h3>
      <div className="video-container">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <video id="previewVideo" width="820" height="460" loop muted autoPlay>
              <source src={thumbnailURL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="overlay">
              <div className="overlay-info">
                <div className="video-title">10,000 Hours of Learning</div>
                <p>
                  Evolve as an educator with real-time access to individualized training and the
                  support to improve instruction and evaluation.
                </p>
                <button className="play-btn" onClick={handlePlay}>
                  <FontAwesomeIcon className="play-icon" icon={faPlay} />
                  Watch Sample
                </button>
              </div>
            </div>
          </>
        )}
        {openVideoDialog && (
          <div id="ams-modal" className="video-modal" style={{ overflow: 'auto', visibility: 'visible' }}>
            <div className="modal-video-content">
              <div className="video-player">
                <div id="player-wrapper">
                  <BitmovinPlayer content={content} />
                </div>
              </div>
              <button className="close-btn" onClick={handleCloseClick}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPreview;