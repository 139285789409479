import _ from "lodash";
import moment from "moment";
import react, { useState } from "react";
import { useEffect } from "react";
import { faClose as completeIcon } from '@fortawesome/free-solid-svg-icons'
import { TableControl } from "../../../../components/controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormatUTCDate } from "../../../../utilities/DateFormater";
import ObservationManager from "../../../../managers/ObservationManager";
import './table.scss';

import userPositionData from '../../../dev/data/userPositionTypes';
import { useAppUserManager, useObservationManager } from "../../../../hooks/useManagers";

const ObservationTable = ({ filteredItems, printing, onEdit, onView, onPrint, onDelete }) => {
    const [rows, setRows] = useState([]);
    const [actionIsLoading, setActionIsLoading] = useState({ action: '', id: '' })
    const observationMgr = useObservationManager();
    const appUserManager = useAppUserManager();

    const [columns] = useState([
        {
            header: '',
            dataProp: 'HasChange',
            width: '3%',
            datatype: 'flag',
            hideDataMissing: true,
            canSort: true
        },
        {
            header: 'EE PASS #',
            dataProp: 'eepassid',
            width: '6%',
            canSort: true
        },
        {
            header: 'Name',
            dataProp: 'Name',
            sortBy: 'FullName',
            width: '14%',
            canSort: true
        },
        {
            header: 'Organization, District and School',
            dataProp: 'fullOrg',
            width: '16%',
            canSort: false
        },
        {
            header: 'Observer',
            dataProp: 'Observer',
            sortBy: 'ObserverFullName',
            width: '16%',
            canSort: true
        },
        {
            header: 'Observer Job Title',
            dataProp: 'UserPosition',
            width: '10%',
            canSort: true
        },
        {
            header: 'Date',
            dataProp: 'observationDate',
            width: '10%',
            canSort: true
        },
        {
            header: 'Announced',
            dataProp: 'announced',
            width: '7%',
            canSort: true,
            isBool: true,
            isExcludeFromScoring : true
        },
        {
            header: 'Status',
            dataProp: 'statusJSX',
            width: '7%',
            sortBy: 'status',
            canSort: true
        }
    ]);
    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (c) => {
                let observedDate = c.PlannedObservationDateStart;
                newRows.push({
                    ...c,
                    eepassid: c.ObservedSystemId ?? c.Observed?.SystemId,
                    announced: ObservationManager.formatAnannounced(c.ObservationType),
                    FullName: c?.ObservedFullName ?? c?.Observed?.FullName,
                    Email: c?.ObservedEmail ?? c?.Observed?.Email,
                    Name: <div>{c?.ObservedFullName ?? c.Observed?.FullName}<br /><span className='table-sub-email'>{c.ObservedEmail ?? c.Observed?.Email}</span></div>,
                    Observer: <div>{c?.ObserverFullName ?? c.Observer?.FullName}<br /><span className='table-sub-email'>{c.ObserverEmail ?? c.Observer?.Email}</span></div>,
                    ObserverFullName: c?.ObserverFullName ?? c?.Observer?.FullName,
                    statusJSX: ObservationManager.formatStatusJSX(c.ObservationStatusType),
                    status: ObservationManager.formatStatus(c.ObservationStatusType),
                    schoolName: c.SchoolName ?? c.School?.Name,
                    fullOrg: <>{`${c.OrganizationName ?? c.Organization?.Name}`}<br />{`${c.DistrictName ?? c.District?.Name}`}<br /> {`${c.SchoolName ?? c.School?.Name}`}</>,
                    UserPosition: _.find(observationMgr?.AppUserState?.observationConfig.UserPositionAlternativeMap, y => y.UserPosition == (c.ObserverUserPositionType ?? c.Observer?.UserPositionType) && y?.Name)?.Name ?? userPositionData.find(x => x.Id === (c?.ObserverUserPositionType ?? c?.Observer?.UserPositionType))?.Text,
                    observationDate: moment(observedDate).format('M/D/YYYY'),
                    excludeFromScoring: c?.ExculdeFromOverallScoring
                });
            });
            setRows(newRows);
        }
    }, [filteredItems]);

    const handleCheckDisableAction = (row, action) => {
        if (action == 'view') {
            return row.ObservationStatusType == 0 || !observationMgr.canAccessReview(row);
        }
        else if (action == 'edit') {
            return !observationMgr.canAccessEdit(row);
        }
        else if (action.toLowerCase() === 'print') {
            return !(row.ObservationStatusType > 0 && observationMgr.canAccessReview(row));
        }
        else
            return false;
    }
    const checkLoadingAction = (action, id) => {
        if (action === 'print' && printing) {
            return action
        } else return id
    }
    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                checkDisableAction={handleCheckDisableAction}
                checkLoadingAction={checkLoadingAction}
                useIntegratedPaging={['10', '25', '50', '100']}
                permission={'Observation'}
                onEdit={onEdit}
                onDelete={onDelete}
                onView={onView}
                printing={printing}
                onPrint={async (row) => await onPrint(row)}
            />
        </div>
    </>
}

export default ObservationTable;