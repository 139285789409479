import React, { useState } from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { SelectListControl } from '../../../../components/controls';

export default function RoleFilter({ roleMgr, onFilterChange }) {
    const handleSearchChange = (value) => {
        if (onFilterChange)
            onFilterChange("search", value);
    }

    const handleRoleTypeChange = (value) => {
        if (onFilterChange) {
            onFilterChange("IsSystemAdmin", value);
        }
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        roleMgr.filterData({ search: null, IsSystemAdmin: null });
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Role Management</div>
                    <div className="filterControl">
                        <InputField
                            id='role'
                            title='Search'
                            value={roleMgr?.Filter?.search ?? ''}
                            fieldName='search'
                            disableError={true}
                            placeholder='Role'
                            onChange={handleSearchChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField id='IsSystemAdmin'
                            title='Role Type'
                            value={roleMgr?.Filter?.IsSystemAdmin ?? '0'}
                            fieldName='IsSystemAdmin'
                            disableError={true}
                            onChange={handleRoleTypeChange}>
                            <SelectListControl textValuePairs={[{ text: 'Non-System Roles', value: 0 }, { text: 'System Roles', value: 1 }]} />
                        </InputField>
                    </div>
                    <div className="filterControl  btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}