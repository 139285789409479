import React, { useState } from 'react';
import { useEffect } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useAppUserManager, useCertificationQuestionManager, useImportExportManager, useOrgManager } from '../../../hooks/useManagers';
import _, { indexOf } from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';
import OrgTable from '../components/organization/table';
import OrgFilter from '../components/organization/filter';
import OrganizationDetails from '../components/organization/details';
import '../components/observation/observationconfig.scss'
import SystemTypes from '../../../services/SystemTypes';
import Import from '../components/importExport/import';

const OrganizationMgmt = () => {
    const orgMgr = useOrgManager();
    const certificationQuestionMgr = useCertificationQuestionManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [teacherRubricList, setTeacherRubricList] = useState(null)
    const [adminRubricList, setAdminRubricList] = useState(null)
    const [teacherSurveyList, setTeacherSurveyList] = useState(null)
    const [adminSurveyList, setAdminSurveyList] = useState(null)
    const [observationConfig, setObservationConfig] = useState(null)
    const [loadingConfigs, setLoadingConfigs] = useState(false)
    const [orgTreeUsers, setOrgTreeUsers] = useState(null)
    const [certificationQuestions, setCertificationQuestions] = useState(null)
    const [messages, setMessages] = useState(null);
    const params = useParams();
    const navigate = useNavigate();
    const [deleting, setDeleting] = useState(false);

    const appUserMgr = useAppUserManager();
    const importExportMgr = useImportExportManager();
    const [exportLoading, setExportLoading] = useState(false);


    useEffect(() => {
        setLoading(true)
    }, [])

    useEffect(() => {
        if (params?.organizationId && orgMgr && certificationQuestionMgr) {
            setLoading(true)
            grabOrgDetails()
            setLoadingConfigs(true)
        } else if (orgMgr) {
            setLoading(true)
            setSelectedItem(null)
            orgMgr.search("x => x.IsDeleted == false", true).then(fb => {
                setManageMode('list');
                setLoading(false);
            });
        }
    }, [orgMgr, params, certificationQuestionMgr]);



    const grabOrgDetails = async () => {
        if (params?.organizationId && params?.organizationId !== 'create') {
            orgMgr.get(params?.organizationId).then((r) => {
                if (r.Success) {
                    setManageMode('view');
                    setLoading(false)
                    setSelectedItem(r.Items.first());
                }
            });
        } else {
            setLoading(false)
            setManageMode('view')
        }

        const orgIdOrNull = params?.organizationId && params?.organizationId !== 'create' ? params?.organizationId : null

        const orgObsConfig = orgMgr.getObservationConfigByOrg(orgIdOrNull).then((r) => {
            if (r.Success) {
                const observationConfig = r.Items.first();
                setObservationConfig(observationConfig)
            }
        })
        const teacherRubric = orgMgr.getTeacherRubrics(orgIdOrNull, false, ['RubricDomainMap']).then(r => {
            if (r.first().Success) {
                setTeacherRubricList(r.first().Items.filter(x => x.SchoolYearIdentifier == orgMgr.AppUserState.selectedSchoolYear && x.Status != 1))
            }
        })
        const adminRubric = orgMgr.getAdminRubrics(orgIdOrNull, false, ['RubricDomainMap']).then(r => {
            if (r.first().Success) {
                setAdminRubricList(r.first().Items.filter(x => x.SchoolYearIdentifier == orgMgr.AppUserState.selectedSchoolYear && x.Status !== 1))
            }
        })
        const teacherSurveyTemplate = orgMgr.getSurveyTemplates(orgIdOrNull, 4).then(r => {
            if (r.first().Success) {
                setTeacherSurveyList(r.first().Items)
            }
        })

        const adminSurveyTemplate = orgMgr.getSurveyTemplates(orgIdOrNull, 5).then(r => {
            if (r.first().Success) {
                setAdminSurveyList(r.first().Items)
            }
        })
        const orgUsers = orgMgr.loadOrgUserTree().then((r) => {
            setOrgTreeUsers(orgMgr.UsersOnly('org', orgIdOrNull))
        })
        const grabCertificationQuestions = certificationQuestionMgr.list(certificationQuestionMgr.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false")).then(r => setCertificationQuestions(r.Items))

        const result = Promise.all([orgObsConfig, teacherRubric, adminRubric, teacherSurveyTemplate, adminSurveyTemplate, grabCertificationQuestions]).then((r) => setLoadingConfigs(false))
    }


    const onAddNew = () => {
        setManageMode('view');
        grabOrgDetails()
        setLoadingConfigs(true)
        navigate('/admin/organization/create')
    }

    const handleOnFilterChange = async (field, value) => {
        const dataFilter = orgMgr.Filter;
        const updatedDataFilter = { ...dataFilter };
        updatedDataFilter[field] = value !== '' ? value : null;
        await orgMgr.filterData(updatedDataFilter);
    }

    const handleOnSave = async (item, config) => {
        var result = await orgMgr.saveItem(item);
        var configResult = orgMgr.saveObservationConfig(config)
        if (result.Success) {
            setSelectedItem(null);
            navigate('/admin/organization')
        }

        return result;
    }

    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        setDeleting(true);
        orgMgr.deleteOrg(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.Name}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')} `)
                setDeleting(false);
            }
            else {
                setDeleting(false);
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnEdit = (item) => {
        setSelectedItem(item);
        navigate(`/admin/organization/${item.Id} `);
    }

    const handleOnDrillDown = (item) => {
        navigate({
            pathname: '/admin/district',
            search: `?${createSearchParams({ orgid: item.Id })}`,
        });
    }

    const handleOnCompleteImport = async (success) => {
        if (success) {
            const orgs = await orgMgr.search()
            await orgMgr.filterData(undefined, orgs);
            setMessages([<h5>One or more Organization, Distrcts, Schools and/or Clusters Successfully loaded.</h5>]);
        }
    }

    const handleOnExport = async () => {
        const idList = orgMgr.FilteredData.map(x => x.Id);
        setExportLoading(true);
        const result = await importExportMgr.ExportData(SystemTypes.ImportExportType.ExportOrganization, idList);
        if (!result.Success) {
            setExportLoading(null);
            setErrors(["Failed to export Organization, if this issue continues please contact NIET support."]);
        }
        else {
            importExportMgr.downloadFile(result.Items.first()).then(r => {
                setExportLoading(false);
            }).catch(e => {
                setExportLoading(false);
                setErrors(["Organization export has been created and failed to download. Please check Import/Export Admin."]);
            });
        }
        return result;
    }

    const handleOnCancel = () => {
        navigate('/admin/organization');
        setManageMode('list');
        setLoadingError(null);
        setErrors(null);
        setSelectedItem(null)
        setObservationConfig(null)
        setTeacherRubricList(null)
        setAdminRubricList(null)
        setTeacherSurveyList(null)
        setAdminSurveyList(null)
    }
    return (<>
        <DialogControl openDialog={itemToDelete} onLoading={deleting} title={'Confirm Delete'} subTitle={`Are you sure you want to delete '${itemToDelete?.Name}' ? `} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <MainLayout errors={errors} validationErrors={validationErrors} >
            {manageMode === 'view' &&
                <div className='screen-header'>
                    <h3>Organization Management</h3>
                    {params?.organizationId === 'create' ? <h5>Add Organization</h5> : <h5>Edit Organization</h5>}
                </div>
            }
            <ScreenWrapper className={manageMode === 'view' ? ' no-height' : ''} loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <OrgFilter orgMgr={orgMgr} onFilterChange={handleOnFilterChange} />
                            <OrgTable filteredItems={orgMgr?.FilteredData ?? []} onEdit={handleOnEdit} onDelete={(item) => { setItemToDelete(item); }} onDrillDown={handleOnDrillDown} />
                        </div>
                        <div className='screen-footer list'>
                            {orgMgr.CanWrite && <><ButtonControl type={"create"} onClick={onAddNew}>Create Organization</ButtonControl>&nbsp;&nbsp;</>}
                            {orgMgr?.CanView && appUserMgr?.canView(appUserMgr?.AppUserState?.permissions.ExportOrganization) && <><ButtonControl loading={exportLoading} type={'cancel'}
                                onClick={handleOnExport}>
                                Export Organizations
                            </ButtonControl>&nbsp;&nbsp;</>}
                            {orgMgr?.CanWrite && appUserMgr?.canView(appUserMgr?.AppUserState?.permissions.ImportStructure) && <Import importDisplayName={'Organization'} setErrors={setErrors} setMessages={setMessages} onComplete={handleOnCompleteImport} importExportType={SystemTypes.ImportExportType.ImportStructure} />}
                        </div>
                    </div>
                </>}
                {(manageMode === 'view' && orgMgr) &&
                    <OrganizationDetails
                        canWrite={orgMgr.CanWrite}
                        orgMgr={orgMgr}
                        org={selectedItem}
                        teacherRubricList={teacherRubricList}
                        adminRubricList={adminRubricList}
                        teacherSurveyList={teacherSurveyList}
                        adminSurveyList={adminSurveyList}
                        observationConfigs={observationConfig}
                        loadingConfigs={loadingConfigs}
                        onCancel={handleOnCancel}
                        onSave={handleOnSave}
                        onLoading={setLoading}
                        onErrors={setErrors}
                        onValidationErrors={setValidationErrors}
                        orgTreeUsers={orgTreeUsers}
                        certificationQuestions={certificationQuestions}
                        onLoadingError={setLoadingError} />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default OrganizationMgmt;