import React, { useState } from 'react';
import _ from 'lodash';
import { useSurveyContext } from './components/SurveyDataProvider';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ScreenWrapper from '../../components/layout/screenWrapper';
import GeneralSurveyDetails from './components/generalsurvey/details';
import GeneralSurveyList from './components/generalsurvey/table';
import GeneralSurveyFilter from './components/generalsurvey/filter';
import GeneralSurveyResults from './components/generalsurvey/results';
import ButtonControl from '../../components/controls/ButtonControl';
import moment from 'moment';
import Print from '../observations/components/print';
import MainLayout from '../../components/layout/MainLayout';
import GeneralSurveyPrintScreen from './components/generalsurvey/printscreen';
import Loading from '../../components/Loading';
import GeneralSurveyPrintEmptyScreen from './components/generalsurvey/printemptysurvey';
import { useDistrictManager, useSchoolManager } from '../../hooks/useManagers';

const GeneralSurveys = () => {
    const { managerIsReady, generalSurveyMgr, surveyMgr, orgMgr, appUserMgr } = useSurveyContext();
    const districtMgr = useDistrictManager();
    const schoolMgr = useSchoolManager();
    const [manageMode, setManageMode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [orgTree, setOrgTree] = useState(null);
    const [districts, setDistricts] = useState(null);
    const [schools, setSchools] = useState(null);
    const [data, setData] = useState(null)
    const [printing, setPrinting] = useState(false);
    const [printData, setPrintData] = useState(null)
    const [saving, setSaving] = useState(false)
    const [gridData, setGridData] = useState(null)
    const [ratingSelectOptions, setRatingSelectOptions] = useState(null)
    const [rubricLoading, setRubricLoading] = useState(true)
    const [printEmpty, setPrintEmpty] = useState(false)
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (managerIsReady && generalSurveyMgr && surveyMgr && orgMgr && appUserMgr && schoolMgr && districtMgr) {
            let ignore = false;
            setLoading(true);
            async function fetchData() { 
                await orgMgr.loadOrgUserTree().then(r => {
                    setOrgTree(r)
                });
                await schoolMgr.list().then(schoolResult => {
                    if (schoolResult.Items.length > 0) {
                        if (schoolResult.Success) {
                            let schoolsMapped = schoolResult.Items.map(item => ({ Name: item.Name, Id: item.Id, DistrictId: item.DistrictId, ClientId: item.ClientId }))
                            setSchools(schoolsMapped);
                        }
                    }
                });
                await districtMgr.list().then(districtResult => {
                    if (districtResult.Items.length > 0) {
                        if (districtResult.Success) {
                            let validDistricts = districtResult.Items.filter(item => item.ClientId !== null);
                            let districtsMapped = validDistricts.map(item => ({ Name: item.Name, Id: item.Id, ClientId: item.ClientId}))
                            setDistricts(districtsMapped);
                        }
                    }
                });
    
                if (params?.surveyId) {
                    handleParamChange()
                }
                else {
                    await generalSurveyMgr.search(null, true).then(r => {
                        if (sessionStorage.getItem("FileUpload-content")) {
                            sessionStorage.removeItem("FileUpload-content")
                        }
                        setLoading(false);
                        setData(dataMassager(generalSurveyMgr?.FilteredData))
                        setRatingSelectOptions(null)
                        setManageMode('list')
                    })
                }
            }
            fetchData();

            return () => { ignore = true }; //cleanup
        }
    }, [managerIsReady, params, generalSurveyMgr, surveyMgr, orgMgr, appUserMgr, schoolMgr, districtMgr]);

    useEffect(() => {
        if ((generalSurveyMgr?.FilteredData)) {
            setData(dataMassager(generalSurveyMgr?.FilteredData))
        }
    }, [generalSurveyMgr?.FilteredData])


    const handleParamChange = async (item) => {
        if (_.isNil(item?.Survey) && item) {
            await surveyMgr.saveGeneralSurvey({
                SurveyTemplateId: item.SurveyTemplateId,
                Name: item.GeneralSurveyName,
                RubricId: item.RubricId,
                SurveyType: 2,
                SurveyStatusType: 1,
            }, item.Id).then(r => navigate(`/survey/generalsurvey/${item.Id}`))
        } else {

            await generalSurveyMgr.RetrieveUserGeneralSurvey(params?.surveyId).then(r => {
                const surveyItem = dataMassager(r[0].Items[0])
                if (location?.pathname.includes('review')) {
                    if (surveyItem.SurveyStatusType == 2 || surveyItem.SurveyStatusType == 3) {
                        setManageMode('viewResult')
                    } else navigate(`/survey/generalsurvey/${surveyItem.Id}`)
                } else if (surveyItem.SurveyStatusType == 3) {
                    navigate(`/survey/generalsurvey/review/${surveyItem.Id}`)
                } else setManageMode('view')
                setData(surveyItem)
                setLoading(false)
            })
        }
    }

    const dataMassager = (items) => {
        if (Array.isArray(items)) {
            items = _.map(items, item => {
                if (item.GeneralSurvey) {
                    return {
                        ...item,
                        GeneralSurveyName: item.GeneralSurvey.Name,
                        StartDate: item.GeneralSurvey.StartDate,
                        AssignedTo: item.UserSurveyMap.User.FullName,
                        AssignedByUserId: item.AssignedByUser.FullName,
                        RubricName: item.GeneralSurvey.SurveyTemplate?.Rubric?.Name,
                        RubricId: item.GeneralSurvey.SurveyTemplate?.Rubric?.Id,
                        SurveyType: item.GeneralSurvey.SurveyTemplate.SurveyType,
                        Survey: item.UserSurveyMap.Survey ?? null,
                        SurveyTemplateId: item.GeneralSurvey.SurveyTemplate.SurveyTemplateId,
                        IsActive: item.GeneralSurvey.IsActive,
                        IsDeleted: item.GeneralSurvey.IsDeleted
                    }
                }
                else {
                    return { AssignedTo: item.UserSurveyMap.User.FullName, AssignedBy: 'Unknown', IsActive: false }
                }
            })
        } else {
            if (items.GeneralSurvey) {
                items = {
                    ...items,
                    GeneralSurveyName: items.GeneralSurvey.Name,
                    StartDate: items.GeneralSurvey.StartDate,
                    AssignedTo: items.UserSurveyMap.User.FullName,
                    AssignedByUserId: items.GeneralSurvey.AssignedByUserId,
                    ShowRubric: items.GeneralSurvey.SurveyTemplate.ShowRubric,
                    RubricName: items.GeneralSurvey.SurveyTemplate.Rubric?.Name,
                    RubricId: items.GeneralSurvey.SurveyTemplate.Rubric?.Id,
                    IncludeLikertNA: items.GeneralSurvey.SurveyTemplate.IncludeLikertNA,
                    Survey: items.UserSurveyMap.Survey ?? null,
                    SurveyType: items.GeneralSurvey.SurveyTemplate.SurveyType,
                    SurveyTemplateId: items.GeneralSurvey.SurveyTemplate.SurveyTemplateId,
                    SurveyTemplateFields: _.orderBy(items.GeneralSurvey.SurveyTemplate.SurveyTemplateFields, ['Sequence'], ['asc']),
                    SurveyLikertMap: _.orderBy(items.GeneralSurvey.SurveyTemplate.SurveyLikertMap, ['Value'], ['asc']),
                    SurveyStatusType: items.UserSurveyMap.Survey?.SurveyStatusType,
                    SurveyDescription: items.GeneralSurvey?.Description,
                    IsActive: items.GeneralSurvey.IsActive,
                    IsDeleted: items.GeneralSurvey.IsDeleted
                }
            }
            else {
                return { AssignedTo: items.UserSurveyMap.User.FullName, AssignedByUser: { FullName: 'Unknown' }, IsActive: false }
            }
        }
        return items
    }


    useEffect(() => {
        if ((data?.ShowRubric || printData?.ShowRubric) && !ratingSelectOptions) {
            setRubricLoading(true)
            generalSurveyMgr.getSurveyAndRubric(data?.Survey?.SurveyId ?? printData?.Survey?.SurveyId).then(res => {
                if (!res) {
                    setRubricLoading("NoRubric")
                }
                else {
                    setGridData(res.gridData);
                    if (res.tagMap) {

                        let ratingSelectOpts = {};

                        Object.keys(res.tagMap).forEach(t => {
                            let selectedRatingValue = res.gridData.data?.reduce((r, cv) => {
                                if (res.tagMap[t].name === cv.tag?.name && cv.selectedRating !== '') {
                                    r = cv.selectedRating;
                                }
                                return r;
                            }, null)

                            ratingSelectOpts[res.tagMap[t].name] = {
                                isExpanded: false, surveyRatingId: res.gridData.data?.reduce((r, cv) => {
                                    if (res.tagMap[t].name === cv.tag?.name) {
                                        r = cv.surveyRatingId
                                    }
                                    return r;
                                }, null),
                                selectedRating: selectedRatingValue,
                                rubricRatingId: _.find(res.gridData.metaData, y => y.rating === selectedRatingValue)?.rubricRatingId,
                                rubricIndicatorId: res.tagMap[t].id
                            };
                        })
                        setRatingSelectOptions(ratingSelectOpts);
                        setRubricLoading(false)
                    }
                }
            })
        } else if ((data && !data.ShowRubric) || (printData && !printData.ShowRubric)) {
            setRubricLoading("NoRubric")
        }
    }, [data, printData])

    const handleOnFilterChange = async (field, value) => {
        const surveyFilter = generalSurveyMgr.Filter;
        const updatedSurveyFilter = { ...surveyFilter };
        updatedSurveyFilter[field] = value !== '' ? value : null;
        await generalSurveyMgr.filterData(updatedSurveyFilter);
    }

    const handleOnSave = async (item, condition) => {
        const spreadItem = {
            ...item,
            RubricId: data.RubricId,
            SurveySections: _.map(item.SurveySections, ss => {
                return {
                    ...ss,
                    Value: Array.isArray(ss.Value) ? ss?.Value?.join(';') : ss?.Value,
                    Options: undefined,
                    SurveyFieldType: undefined,
                }
            })
        }

        var result = await surveyMgr.saveGeneralSurvey(spreadItem, data.Id);
        if (result.first() && !condition) {
            navigate(-1)
        } else if (result.first() && condition === 'NEXT') {
            navigate(`/survey/generalsurvey/review/${data?.Id}`)
        } else if (result.first() && condition === 'SUBMIT') {
            navigate(`/survey/generalsurvey`)
        }
        setRatingSelectOptions(null)
        return result;
    }
    const handleOnEdit = (item) => {
        //We have yet to create a survey for the general survey DTI.
        if (_.isNil(item?.Survey)) {
            setLoading(true)
            handleParamChange(item)
        } else {
            navigate(`/survey/generalsurvey/${item.Id}`)
        }
    }

    const handleOnView = (item) => {
        navigate(`/survey/generalsurvey/review/${item?.Id}`)
    }

    const handleOnPrintReport = async (survey, location) => {
        setPrinting(survey.Id);
        if (location === 'review') {
            setPrintData(survey)
        } else {
            const r = await generalSurveyMgr.RetrieveUserGeneralSurvey(survey?.Id);
            setPrintData(dataMassager(r[0].Items[0]))
        }
    }

    const printingCompleted = () => {
        setPrinting(false)
        setPrintData(null)
        setPrintEmpty(false)
        setRubricLoading(false)
        if (!params.surveyId) {
            setRatingSelectOptions(null)
        }
    }

    const handlePrintEmpty = async (survey) => {
        const r = await generalSurveyMgr.RetrieveUserGeneralSurvey(survey?.Id);
        setPrintData(dataMassager(r[0].Items[0]))
        setPrintEmpty(true)
    }
    return (<>
        {(printing && printData && (!printData.ShowRubric || printData.ShowRubric && rubricLoading !== true)) && <GeneralSurveyPrintScreen data={printData} rubricData={gridData} rubricLoading={rubricLoading} printingCompleted={printingCompleted} />}
        {printEmpty && printData && (!printData.ShowRubric || printData.ShowRubric && rubricLoading !== true) && <GeneralSurveyPrintEmptyScreen
            printingCompleted={printingCompleted}
            generalSurveyMgr={generalSurveyMgr}
            data={printData}
            rubricLoading={rubricLoading}
            rubricData={gridData}
            ratingSelectOptions={ratingSelectOptions}
            setRatingSelectOptions={setRatingSelectOptions} />}
        <MainLayout>
            <ScreenWrapper loading={loading} className={' survey-wrapper'} >

                {(manageMode === 'view' || manageMode === 'viewResult')
                    && <>
                        <div className='survey-screen-header'>
                            <div className="survey-header-row">
                                <div className="survey-header-wrapper">
                                    <h1>{data?.GeneralSurveyName}</h1>
                                    {data.SurveyStatusType == 3 && <div className="survey-status">SUBMITTED</div>}
                                </div>
                                <div className="survey-subheader-wrapper">
                                    <div className="survey-subheader-left">Survey Assigned By:</div>
                                    <div className="survey-subheader-right">{data?.AssignedByUser?.FullName}</div>
                                </div>
                                <div className="survey-subheader-wrapper">
                                    <div className="survey-subheader-left">Survey Assigned To:</div>
                                    <div className="survey-subheader-right">{data?.AssignedTo}</div>
                                </div>
                                {/* <div className="survey-subheader-wrapper">
                                    <div className="survey-subheader-left">Start Date:</div>
                                    <div className="survey-subheader-right"> {moment.utc(data?.StartDate).local().format("MMMM D, YYYY")}</div>
                                </div> */}
                            </div>
                            {manageMode === 'viewResult' && <div className="survey-header-button-wrapper">
                                <ButtonControl type={'cancel'} loading={printing} onClick={() => handleOnPrintReport(data, 'review')}>Print Report</ButtonControl>
                                {data?.SurveyStatusType != 3 && <ButtonControl type={'cancel'} onClick={() => {
                                    navigate(`/survey/generalsurvey/${data.Id}`)
                                }}>EDIT SURVEY</ButtonControl>}
                                <ButtonControl type={'cancel'} onClick={() => {
                                    navigate('/survey/generalsurvey')
                                    setRatingSelectOptions(null)
                                }}>{data?.Survey?.SurveyStatusType == 3 ? "EXIT" : "CONTINUE LATER"}</ButtonControl>
                                {data?.Survey?.SurveyStatusType == 2 && <ButtonControl type={'okay'} loading={saving} onClick={() => {
                                    const copySurvey = { ...data.Survey }
                                    copySurvey.SurveyStatusType = 3
                                    setSaving(true)
                                    const result = handleOnSave(copySurvey, 'SUBMIT')
                                    if (result) {
                                        setSaving(false)
                                    }
                                }}>SUBMIT</ButtonControl>}
                            </div>}

                        </div>
                        {(data && manageMode === 'view' && !printEmpty) && <GeneralSurveyDetails generalSurveyMgr={generalSurveyMgr} data={data} saveSurvey={handleOnSave} rubricLoading={rubricLoading} rubricData={gridData} ratingSelectOptions={ratingSelectOptions} setRatingSelectOptions={setRatingSelectOptions} />}
                    </>
                }
                {manageMode === 'list' &&
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <GeneralSurveyFilter generalSurveyMgr={generalSurveyMgr} orgMgr={orgMgr} orgTree={orgTree} onFilterChange={handleOnFilterChange} />
                            <GeneralSurveyList printing={printing} handlePrintEmpty={handlePrintEmpty} appUserMgr={appUserMgr} generalSurveyMgr={generalSurveyMgr} filteredItems={data ?? []} orgs={orgTree} districts={districts} schools={schools} onEdit={handleOnEdit} onPrint={handleOnPrintReport} onView={handleOnView} />
                        </div>
                        <div className='screen-footer list'>
                        </div>
                    </div>
                }
                {
                    manageMode === 'viewResult' && <GeneralSurveyResults data={data} rubricData={gridData} rubricLoading={rubricLoading} />
                }
            </ScreenWrapper>
        </MainLayout>

    </>
    );
}

export default GeneralSurveys;