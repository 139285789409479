const mimeTypeMap = null;
const MimeTypeMap = () => {
    if (mimeTypeMap != null)
        return mimeTypeMap;
    else {
        mimeTypeMap = {};
        mimeTypeMap["application/vnd.hzn-3d-crossword"] = "3D Crossword Plugin";
        mimeTypeMap["video/3gpp"] = "3GP";
        mimeTypeMap["video/3gpp2"] = "3GP2";
        mimeTypeMap["application/vnd.mseq"] = "3GPP MSEQ File";
        mimeTypeMap["application/vnd.3m.post-it-notes"] = "3M Post It Notes";
        mimeTypeMap["application/vnd.3gpp.pic-bw-large"] = "3rd Generation Partnership Project - Pic Large";
        mimeTypeMap["application/vnd.3gpp.pic-bw-small"] = "3rd Generation Partnership Project - Pic Small";
        mimeTypeMap["application/vnd.3gpp.pic-bw-var"] = "3rd Generation Partnership Project - Pic Var";
        mimeTypeMap["application/vnd.3gpp2.tcap"] = "3rd Generation Partnership Project - Transaction Capabilities Application Part";
        mimeTypeMap["application/x-7z-compressed"] = "7-Zip";
        mimeTypeMap["application/x-abiword"] = "AbiWord";
        mimeTypeMap["application/x-ace-compressed"] = "Ace Archive";
        mimeTypeMap["application/vnd.americandynamics.acc"] = "Active Content Compression";
        mimeTypeMap["application/vnd.acucobol"] = "ACU Cobol";
        mimeTypeMap["application/vnd.acucorp"] = "ACU Cobol";
        mimeTypeMap["audio/adpcm"] = "Adaptive differential pulse-code modulation";
        mimeTypeMap["application/x-authorware-bin"] = "Adobe (Macropedia) Authorware - Binary File";
        mimeTypeMap["application/x-authorware-map"] = "Adobe (Macropedia) Authorware - Map";
        mimeTypeMap["application/x-authorware-seg"] = "Adobe (Macropedia) Authorware - Segment File";
        mimeTypeMap["application/vnd.adobe.air-application-installer-package+zip"] = "Adobe AIR Application";
        mimeTypeMap["application/x-shockwave-flash"] = "Adobe Flash";
        mimeTypeMap["application/vnd.adobe.fxp"] = "Adobe Flex Project";
        mimeTypeMap["application/pdf"] = "Adobe Portable Document Format";
        mimeTypeMap["application/vnd.cups-ppd"] = "Adobe PostScript Printer Description File Format";
        mimeTypeMap["application/x-director"] = "Adobe Shockwave Player";
        mimeTypeMap["application/vnd.adobe.xdp+xml"] = "Adobe XML Data Package";
        mimeTypeMap["application/vnd.adobe.xfdf"] = "Adobe XML Forms Data Format";
        mimeTypeMap["audio/x-aac"] = "Advanced Audio Coding (AAC)";
        mimeTypeMap["application/vnd.ahead.space"] = "Ahead AIR Application";
        mimeTypeMap["application/vnd.airzip.filesecure.azf"] = "AirZip FileSECURE";
        mimeTypeMap["application/vnd.airzip.filesecure.azs"] = "AirZip FileSECURE";
        mimeTypeMap["application/vnd.amazon.ebook"] = "Amazon Kindle eBook format";
        mimeTypeMap["application/vnd.amiga.ami"] = "AmigaDE";
        mimeTypeMap["application/andrew-inset"] = "Andrew Toolkit";
        mimeTypeMap["application/vnd.android.package-archive"] = "Android Package Archive";
        mimeTypeMap["application/vnd.anser-web-certificate-issue-initiation"] = "ANSER-WEB Terminal Client - Certificate Issue";
        mimeTypeMap["application/vnd.anser-web-funds-transfer-initiation"] = "ANSER-WEB Terminal Client - Web Funds Transfer";
        mimeTypeMap["application/vnd.antix.game-component"] = "Antix Game Player";
        mimeTypeMap["application/x-apple-diskimage"] = "Apple Disk Image";
        mimeTypeMap["application/vnd.apple.installer+xml"] = "Apple Installer Package";
        mimeTypeMap["application/applixware"] = "Applixware";
        mimeTypeMap["application/vnd.hhe.lesson-player"] = "Archipelago Lesson Player";
        mimeTypeMap["application/vnd.aristanetworks.swi"] = "Arista Networks Software Image";
        mimeTypeMap["text/x-asm"] = "Assembler Source File";
        mimeTypeMap["application/atomcat+xml"] = "Atom Publishing Protocol";
        mimeTypeMap["application/atomsvc+xml"] = "Atom Publishing Protocol Service Document";
        mimeTypeMap["application/atom+xml"] = "Atom Syndication Format";
        mimeTypeMap["application/pkix-attr-cert"] = "Attribute Certificate";
        mimeTypeMap["audio/x-aiff"] = "Audio Interchange File Format";
        mimeTypeMap["video/x-msvideo"] = "Audio Video Interleave (AVI)";
        mimeTypeMap["application/vnd.audiograph"] = "Audiograph";
        mimeTypeMap["image/vnd.dxf"] = "AutoCAD DXF";
        mimeTypeMap["model/vnd.dwf"] = "Autodesk Design Web Format (DWF)";
        mimeTypeMap["text/plain-bas"] = "BAS Partitur Format";
        mimeTypeMap["application/x-bcpio"] = "Binary CPIO Archive";
        mimeTypeMap["application/octet-stream"] = "Binary Data";
        mimeTypeMap["image/bmp"] = "Bitmap Image File";
        mimeTypeMap["application/x-bittorrent"] = "BitTorrent";
        mimeTypeMap["application/vnd.rim.cod"] = "Blackberry COD File";
        mimeTypeMap["application/vnd.blueice.multipass"] = "Blueice Research Multipass";
        mimeTypeMap["application/vnd.bmi"] = "BMI Drawing Data Interchange";
        mimeTypeMap["application/x-sh"] = "Bourne Shell Script";
        mimeTypeMap["image/prs.btif"] = "BTIF";
        mimeTypeMap["application/vnd.businessobjects"] = "BusinessObjects";
        mimeTypeMap["application/x-bzip"] = "Bzip Archive";
        mimeTypeMap["application/x-bzip2"] = "Bzip2 Archive";
        mimeTypeMap["application/x-csh"] = "C Shell Script";
        mimeTypeMap["text/x-c"] = "C Source File";
        mimeTypeMap["application/vnd.chemdraw+xml"] = "CambridgeSoft Chem Draw";
        mimeTypeMap["text/css"] = "Cascading Style Sheets (CSS)";
        mimeTypeMap["chemical/x-cdx"] = "ChemDraw eXchange file";
        mimeTypeMap["chemical/x-cml"] = "Chemical Markup Language";
        mimeTypeMap["chemical/x-csml"] = "Chemical Style Markup Language";
        mimeTypeMap["application/vnd.contact.cmsg"] = "CIM Database";
        mimeTypeMap["application/vnd.claymore"] = "Claymore Data Files";
        mimeTypeMap["application/vnd.clonk.c4group"] = "Clonk Game";
        mimeTypeMap["image/vnd.dvb.subtitle"] = "Close Captioning - Subtitle";
        mimeTypeMap["application/cdmi-capability"] = "Cloud Data Management Interface (CDMI) - Capability";
        mimeTypeMap["application/cdmi-container"] = "Cloud Data Management Interface (CDMI) - Contaimer";
        mimeTypeMap["application/cdmi-domain"] = "Cloud Data Management Interface (CDMI) - Domain";
        mimeTypeMap["application/cdmi-object"] = "Cloud Data Management Interface (CDMI) - Object";
        mimeTypeMap["application/cdmi-queue"] = "Cloud Data Management Interface (CDMI) - Queue";
        mimeTypeMap["application/vnd.cluetrust.cartomobile-config"] = "ClueTrust CartoMobile - Config";
        mimeTypeMap["application/vnd.cluetrust.cartomobile-config-pkg"] = "ClueTrust CartoMobile - Config Package";
        mimeTypeMap["image/x-cmu-raster"] = "CMU Image";
        mimeTypeMap["model/vnd.collada+xml"] = "COLLADA";
        mimeTypeMap["text/csv"] = "Comma-Seperated Values";
        mimeTypeMap["application/mac-compactpro"] = "Compact Pro";
        mimeTypeMap["application/vnd.wap.wmlc"] = "Compiled Wireless Markup Language (WMLC)";
        mimeTypeMap["image/cgm"] = "Computer Graphics Metafile";
        mimeTypeMap["x-conference/x-cooltalk"] = "CoolTalk";
        mimeTypeMap["image/x-cmx"] = "Corel Metafile Exchange (CMX)";
        mimeTypeMap["application/vnd.xara"] = "CorelXARA";
        mimeTypeMap["application/vnd.cosmocaller"] = "CosmoCaller";
        mimeTypeMap["application/x-cpio"] = "CPIO Archive";
        mimeTypeMap["application/vnd.crick.clicker"] = "CrickSoftware - Clicker";
        mimeTypeMap["application/vnd.crick.clicker.keyboard"] = "CrickSoftware - Clicker - Keyboard";
        mimeTypeMap["application/vnd.crick.clicker.palette"] = "CrickSoftware - Clicker - Palette";
        mimeTypeMap["application/vnd.crick.clicker.template"] = "CrickSoftware - Clicker - Template";
        mimeTypeMap["application/vnd.crick.clicker.wordbank"] = "CrickSoftware - Clicker - Wordbank";
        mimeTypeMap["application/vnd.criticaltools.wbs+xml"] = "Critical Tools - PERT Chart EXPERT";
        mimeTypeMap["application/vnd.rig.cryptonote"] = "CryptoNote";
        mimeTypeMap["chemical/x-cif"] = "Crystallographic Interchange Format";
        mimeTypeMap["chemical/x-cmdf"] = "CrystalMaker Data Format";
        mimeTypeMap["application/cu-seeme"] = "CU-SeeMe";
        mimeTypeMap["application/prs.cww"] = "CU-Writer";
        mimeTypeMap["text/vnd.curl"] = "Curl - Applet";
        mimeTypeMap["text/vnd.curl.dcurl"] = "Curl - Detached Applet";
        mimeTypeMap["text/vnd.curl.mcurl"] = "Curl - Manifest File";
        mimeTypeMap["text/vnd.curl.scurl"] = "Curl - Source Code";
        mimeTypeMap["application/vnd.curl.car"] = "CURL Applet";
        mimeTypeMap["application/vnd.curl.pcurl"] = "CURL Applet";
        mimeTypeMap["application/vnd.yellowriver-custom-menu"] = "CustomMenu";
        mimeTypeMap["application/dssc+der"] = "Data Structure for the Security Suitability of Cryptographic Algorithms";
        mimeTypeMap["application/dssc+xml"] = "Data Structure for the Security Suitability of Cryptographic Algorithms";
        mimeTypeMap["application/x-debian-package"] = "Debian Package";
        mimeTypeMap["audio/vnd.dece.audio"] = "DECE Audio";
        mimeTypeMap["image/vnd.dece.graphic"] = "DECE Graphic";
        mimeTypeMap["video/vnd.dece.hd"] = "DECE High Definition Video";
        mimeTypeMap["video/vnd.dece.mobile"] = "DECE Mobile Video";
        mimeTypeMap["video/vnd.uvvu.mp4"] = "DECE MP4";
        mimeTypeMap["video/vnd.dece.pd"] = "DECE PD Video";
        mimeTypeMap["video/vnd.dece.sd"] = "DECE SD Video";
        mimeTypeMap["video/vnd.dece.video"] = "DECE Video";
        mimeTypeMap["application/x-dvi"] = "Device Independent File Format (DVI)";
        mimeTypeMap["application/vnd.fdsn.seed"] = "Digital Siesmograph Networks - SEED Datafiles";
        mimeTypeMap["application/x-dtbook+xml"] = "Digital Talking Book";
        mimeTypeMap["application/x-dtbresource+xml"] = "Digital Talking Book - Resource File";
        mimeTypeMap["application/vnd.dvb.ait"] = "Digital Video Broadcasting";
        mimeTypeMap["application/vnd.dvb.service"] = "Digital Video Broadcasting";
        mimeTypeMap["audio/vnd.digital-winds"] = "Digital Winds Music";
        mimeTypeMap["image/vnd.djvu"] = "DjVu";
        mimeTypeMap["application/xml-dtd"] = "Document Type Definition";
        mimeTypeMap["application/vnd.dolby.mlp"] = "Dolby Meridian Lossless Packing";
        mimeTypeMap["application/x-doom"] = "Doom Video Game";
        mimeTypeMap["application/vnd.dpgraph"] = "DPGraph";
        mimeTypeMap["audio/vnd.dra"] = "DRA Audio";
        mimeTypeMap["application/vnd.dreamfactory"] = "DreamFactory";
        mimeTypeMap["audio/vnd.dts"] = "DTS Audio";
        mimeTypeMap["audio/vnd.dts.hd"] = "DTS High Definition Audio";
        mimeTypeMap["image/vnd.dwg"] = "DWG Drawing";
        mimeTypeMap["application/vnd.dynageo"] = "DynaGeo";
        mimeTypeMap["application/ecmascript"] = "ECMAScript";
        mimeTypeMap["application/vnd.ecowin.chart"] = "EcoWin Chart";
        mimeTypeMap["image/vnd.fujixerox.edmics-mmr"] = "EDMICS 2000";
        mimeTypeMap["image/vnd.fujixerox.edmics-rlc"] = "EDMICS 2000";
        mimeTypeMap["application/exi"] = "Efficient XML Interchange";
        mimeTypeMap["application/vnd.proteus.magazine"] = "EFI Proteus";
        mimeTypeMap["application/epub+zip"] = "Electronic Publication";
        mimeTypeMap["message/rfc822"] = "Email Message";
        mimeTypeMap["application/vnd.enliven"] = "Enliven Viewer";
        mimeTypeMap["application/vnd.is-xpr"] = "Express by Infoseek";
        mimeTypeMap["image/vnd.xiff"] = "eXtended Image File Format (XIFF)";
        mimeTypeMap["application/vnd.xfdl"] = "Extensible Forms Description Language";
        mimeTypeMap["application/emma+xml"] = "Extensible MultiModal Annotation";
        mimeTypeMap["application/vnd.ezpix-album"] = "EZPix Secure Photo Album";
        mimeTypeMap["application/vnd.ezpix-package"] = "EZPix Secure Photo Album";
        mimeTypeMap["image/vnd.fst"] = "FAST Search & Transfer ASA";
        mimeTypeMap["video/vnd.fvt"] = "FAST Search & Transfer ASA";
        mimeTypeMap["image/vnd.fastbidsheet"] = "FastBid Sheet";
        mimeTypeMap["application/vnd.denovo.fcselayout-link"] = "FCS Express Layout Link";
        mimeTypeMap["video/x-f4v"] = "Flash Video";
        mimeTypeMap["video/x-flv"] = "Flash Video";
        mimeTypeMap["image/vnd.fpx"] = "FlashPix";
        mimeTypeMap["image/vnd.net-fpx"] = "FlashPix";
        mimeTypeMap["text/vnd.fmi.flexstor"] = "FLEXSTOR";
        mimeTypeMap["video/x-fli"] = "FLI/FLC Animation Format";
        mimeTypeMap["application/vnd.fluxtime.clip"] = "FluxTime Clip";
        mimeTypeMap["application/vnd.fdf"] = "Forms Data Format";
        mimeTypeMap["text/x-fortran"] = "Fortran Source File";
        mimeTypeMap["application/vnd.mif"] = "FrameMaker Interchange Format";
        mimeTypeMap["application/vnd.framemaker"] = "FrameMaker Normal Format";
        mimeTypeMap["image/x-freehand"] = "FreeHand MX";
        mimeTypeMap["application/vnd.fsc.weblaunch"] = "Friendly Software Corporation";
        mimeTypeMap["application/vnd.frogans.fnc"] = "Frogans Player";
        mimeTypeMap["application/vnd.frogans.ltf"] = "Frogans Player";
        mimeTypeMap["application/vnd.fujixerox.ddd"] = "Fujitsu - Xerox 2D CAD Data";
        mimeTypeMap["application/vnd.fujixerox.docuworks"] = "Fujitsu - Xerox DocuWorks";
        mimeTypeMap["application/vnd.fujixerox.docuworks.binder"] = "Fujitsu - Xerox DocuWorks Binder";
        mimeTypeMap["application/vnd.fujitsu.oasys"] = "Fujitsu Oasys";
        mimeTypeMap["application/vnd.fujitsu.oasys2"] = "Fujitsu Oasys";
        mimeTypeMap["application/vnd.fujitsu.oasys3"] = "Fujitsu Oasys";
        mimeTypeMap["application/vnd.fujitsu.oasysgp"] = "Fujitsu Oasys";
        mimeTypeMap["application/vnd.fujitsu.oasysprs"] = "Fujitsu Oasys";
        mimeTypeMap["application/x-futuresplash"] = "FutureSplash Animator";
        mimeTypeMap["application/vnd.fuzzysheet"] = "FuzzySheet";
        mimeTypeMap["image/g3fax"] = "G3 Fax Image";
        mimeTypeMap["application/vnd.gmx"] = "GameMaker ActiveX";
        mimeTypeMap["model/vnd.gtw"] = "Gen-Trix Studio";
        mimeTypeMap["application/vnd.genomatix.tuxedo"] = "Genomatix Tuxedo Framework";
        mimeTypeMap["application/vnd.geogebra.file"] = "GeoGebra";
        mimeTypeMap["application/vnd.geogebra.tool"] = "GeoGebra";
        mimeTypeMap["model/vnd.gdl"] = "Geometric Description Language (GDL)";
        mimeTypeMap["application/vnd.geometry-explorer"] = "GeoMetry Explorer";
        mimeTypeMap["application/vnd.geonext"] = "GEONExT and JSXGraph";
        mimeTypeMap["application/vnd.geoplan"] = "GeoplanW";
        mimeTypeMap["application/vnd.geospace"] = "GeospacW";
        mimeTypeMap["application/x-font-ghostscript"] = "Ghostscript Font";
        mimeTypeMap["application/x-font-bdf"] = "Glyph Bitmap Distribution Format";
        mimeTypeMap["application/x-gtar"] = "GNU Tar Files";
        mimeTypeMap["application/x-texinfo"] = "GNU Texinfo Document";
        mimeTypeMap["application/x-gnumeric"] = "Gnumeric";
        mimeTypeMap["application/vnd.google-earth.kml+xml"] = "Google Earth - KML";
        mimeTypeMap["application/vnd.google-earth.kmz"] = "Google Earth - Zipped KML";
        mimeTypeMap["application/gpx+xml"] = "GPS eXchange Format";
        mimeTypeMap["application/vnd.grafeq"] = "GrafEq";
        mimeTypeMap["image/gif"] = "Graphics Interchange Format";
        mimeTypeMap["text/vnd.graphviz"] = "Graphviz";
        mimeTypeMap["application/vnd.groove-account"] = "Groove - Account";
        mimeTypeMap["application/vnd.groove-help"] = "Groove - Help";
        mimeTypeMap["application/vnd.groove-identity-message"] = "Groove - Identity Message";
        mimeTypeMap["application/vnd.groove-injector"] = "Groove - Injector";
        mimeTypeMap["application/vnd.groove-tool-message"] = "Groove - Tool Message";
        mimeTypeMap["application/vnd.groove-tool-template"] = "Groove - Tool Template";
        mimeTypeMap["application/vnd.groove-vcard"] = "Groove - Vcard";
        mimeTypeMap["video/h261"] = "H.261";
        mimeTypeMap["video/h263"] = "H.263";
        mimeTypeMap["video/h264"] = "H.264";
        mimeTypeMap["application/vnd.hp-hpid"] = "Hewlett Packard Instant Delivery";
        mimeTypeMap["application/vnd.hp-hps"] = "Hewlett-Packard's WebPrintSmart";
        mimeTypeMap["application/x-hdf"] = "Hierarchical Data Format";
        mimeTypeMap["audio/vnd.rip"] = "Hit'n'Mix";
        mimeTypeMap["application/vnd.hbci"] = "Homebanking Computer Interface (HBCI)";
        mimeTypeMap["application/vnd.hp-jlyt"] = "HP Indigo Digital Press - Job Layout Languate";
        mimeTypeMap["application/vnd.hp-pcl"] = "HP Printer Command Language";
        mimeTypeMap["application/vnd.hp-hpgl"] = "HP-GL/2 and HP RTL";
        mimeTypeMap["application/vnd.yamaha.hv-script"] = "HV Script";
        mimeTypeMap["application/vnd.yamaha.hv-dic"] = "HV Voice Dictionary";
        mimeTypeMap["application/vnd.yamaha.hv-voice"] = "HV Voice Parameter";
        mimeTypeMap["application/vnd.hydrostatix.sof-data"] = "Hydrostatix Master Suite";
        mimeTypeMap["application/hyperstudio"] = "Hyperstudio";
        mimeTypeMap["application/vnd.hal+xml"] = "Hypertext Application Language";
        mimeTypeMap["text/html"] = "HyperText Markup Language (HTML)";
        mimeTypeMap["application/vnd.ibm.rights-management"] = "IBM DB2 Rights Manager";
        mimeTypeMap["application/vnd.ibm.secure-container"] = "IBM Electronic Media Management System - Secure Container";
        mimeTypeMap["text/calendar"] = "iCalendar";
        mimeTypeMap["application/vnd.iccprofile"] = "ICC profile";
        mimeTypeMap["image/x-icon"] = "Icon Image";
        mimeTypeMap["application/vnd.igloader"] = "igLoader";
        mimeTypeMap["image/ief"] = "Image Exchange Format";
        mimeTypeMap["application/vnd.immervision-ivp"] = "ImmerVision PURE Players";
        mimeTypeMap["application/vnd.immervision-ivu"] = "ImmerVision PURE Players";
        mimeTypeMap["application/reginfo+xml"] = "IMS Networks";
        mimeTypeMap["text/vnd.in3d.3dml"] = "In3D - 3DML";
        mimeTypeMap["text/vnd.in3d.spot"] = "In3D - 3DML";
        mimeTypeMap["model/iges"] = "Initial Graphics Exchange Specification (IGES)";
        mimeTypeMap["application/vnd.intergeo"] = "Interactive Geometry Software";
        mimeTypeMap["application/vnd.cinderella"] = "Interactive Geometry Software Cinderella";
        mimeTypeMap["application/vnd.intercon.formnet"] = "Intercon FormNet";
        mimeTypeMap["application/vnd.isac.fcs"] = "International Society for Advancement of Cytometry";
        mimeTypeMap["application/ipfix"] = "Internet Protocol Flow Information Export";
        mimeTypeMap["application/pkix-cert"] = "Internet Public Key Infrastructure - Certificate";
        mimeTypeMap["application/pkixcmp"] = "Internet Public Key Infrastructure - Certificate Management Protocole";
        mimeTypeMap["application/pkix-crl"] = "Internet Public Key Infrastructure - Certificate Revocation Lists";
        mimeTypeMap["application/pkix-pkipath"] = "Internet Public Key Infrastructure - Certification Path";
        mimeTypeMap["application/vnd.insors.igm"] = "IOCOM Visimeet";
        mimeTypeMap["application/vnd.ipunplugged.rcprofile"] = "IP Unplugged Roaming Client";
        mimeTypeMap["application/vnd.irepository.package+xml"] = "iRepository / Lucidoc Editor";
        mimeTypeMap["text/vnd.sun.j2me.app-descriptor"] = "J2ME App Descriptor";
        mimeTypeMap["application/java-archive"] = "Java Archive";
        mimeTypeMap["application/java-vm"] = "Java Bytecode File";
        mimeTypeMap["application/x-java-jnlp-file"] = "Java Network Launching Protocol";
        mimeTypeMap["application/java-serialized-object"] = "Java Serialized Object";
        mimeTypeMap["text/x-java-source,java"] = "Java Source File";
        mimeTypeMap["application/javascript"] = "JavaScript";
        mimeTypeMap["application/json"] = "JavaScript Object Notation (JSON)";
        mimeTypeMap["application/vnd.joost.joda-archive"] = "Joda Archive";
        mimeTypeMap["video/jpm"] = "JPEG 2000 Compound Image File Format";
        mimeTypeMap["image/jpeg"] = "JPEG Image";
        mimeTypeMap["image/x-citrix-jpeg"] = "JPEG Image (Citrix client)";
        mimeTypeMap["image/pjpeg"] = "JPEG Image (Progressive)";
        mimeTypeMap["video/jpeg"] = "JPGVideo";
        mimeTypeMap["application/vnd.kahootz"] = "Kahootz";
        mimeTypeMap["application/vnd.chipnuts.karaoke-mmd"] = "Karaoke on Chipnuts Chipsets";
        mimeTypeMap["application/vnd.kde.karbon"] = "KDE KOffice Office Suite - Karbon";
        mimeTypeMap["application/vnd.kde.kchart"] = "KDE KOffice Office Suite - KChart";
        mimeTypeMap["application/vnd.kde.kformula"] = "KDE KOffice Office Suite - Kformula";
        mimeTypeMap["application/vnd.kde.kivio"] = "KDE KOffice Office Suite - Kivio";
        mimeTypeMap["application/vnd.kde.kontour"] = "KDE KOffice Office Suite - Kontour";
        mimeTypeMap["application/vnd.kde.kpresenter"] = "KDE KOffice Office Suite - Kpresenter";
        mimeTypeMap["application/vnd.kde.kspread"] = "KDE KOffice Office Suite - Kspread";
        mimeTypeMap["application/vnd.kde.kword"] = "KDE KOffice Office Suite - Kword";
        mimeTypeMap["application/vnd.kenameaapp"] = "Kenamea App";
        mimeTypeMap["application/vnd.kidspiration"] = "Kidspiration";
        mimeTypeMap["application/vnd.kinar"] = "Kinar Applications";
        mimeTypeMap["application/vnd.kodak-descriptor"] = "Kodak Storyshare";
        mimeTypeMap["application/vnd.las.las+xml"] = "Laser App Enterprise";
        mimeTypeMap["application/x-latex"] = "LaTeX";
        mimeTypeMap["application/vnd.llamagraphics.life-balance.desktop"] = "Life Balance - Desktop Edition";
        mimeTypeMap["application/vnd.llamagraphics.life-balance.exchange+xml"] = "Life Balance - Exchange Format";
        mimeTypeMap["application/vnd.jam"] = "Lightspeed Audio Lab";
        mimeTypeMap["application/vnd.lotus-1-2-3"] = "Lotus 1-2-3";
        mimeTypeMap["application/vnd.lotus-approach"] = "Lotus Approach";
        mimeTypeMap["application/vnd.lotus-freelance"] = "Lotus Freelance";
        mimeTypeMap["application/vnd.lotus-notes"] = "Lotus Notes";
        mimeTypeMap["application/vnd.lotus-organizer"] = "Lotus Organizer";
        mimeTypeMap["application/vnd.lotus-screencam"] = "Lotus Screencam";
        mimeTypeMap["application/vnd.lotus-wordpro"] = "Lotus Wordpro";
        mimeTypeMap["audio/vnd.lucent.voice"] = "Lucent Voice";
        mimeTypeMap["audio/x-mpegurl"] = "M3U (Multimedia Playlist)";
        mimeTypeMap["video/x-m4v"] = "M4v";
        mimeTypeMap["application/mac-binhex40"] = "Macintosh BinHex 4.0";
        mimeTypeMap["application/vnd.macports.portpkg"] = "MacPorts Port System";
        mimeTypeMap["application/vnd.osgeo.mapguide.package"] = "MapGuide DBXML";
        mimeTypeMap["application/marc"] = "MARC Formats";
        mimeTypeMap["application/marcxml+xml"] = "MARC21 XML Schema";
        mimeTypeMap["application/mxf"] = "Material Exchange Format";
        mimeTypeMap["application/vnd.wolfram.player"] = "Mathematica Notebook Player";
        mimeTypeMap["application/mathematica"] = "Mathematica Notebooks";
        mimeTypeMap["application/mathml+xml"] = "Mathematical Markup Language";
        mimeTypeMap["application/mbox"] = "Mbox database files";
        mimeTypeMap["application/vnd.medcalcdata"] = "MedCalc";
        mimeTypeMap["application/mediaservercontrol+xml"] = "Media Server Control Markup Language";
        mimeTypeMap["application/vnd.mediastation.cdkey"] = "MediaRemote";
        mimeTypeMap["application/vnd.mfer"] = "Medical Waveform Encoding Format";
        mimeTypeMap["application/vnd.mfmp"] = "Melody Format for Mobile Platform";
        mimeTypeMap["model/mesh"] = "Mesh Data Type";
        mimeTypeMap["application/mads+xml"] = "Metadata Authority Description Schema";
        mimeTypeMap["application/mets+xml"] = "Metadata Encoding and Transmission Standard";
        mimeTypeMap["application/mods+xml"] = "Metadata Object Description Schema";
        mimeTypeMap["application/metalink4+xml"] = "Metalink";
        mimeTypeMap["application/vnd.mcd"] = "Micro CADAM Helix D&D";
        mimeTypeMap["application/vnd.micrografx.flo"] = "Micrografx";
        mimeTypeMap["application/vnd.micrografx.igx"] = "Micrografx iGrafx Professional";
        mimeTypeMap["application/vnd.eszigno3+xml"] = "MICROSEC e-Szignï¿½";
        mimeTypeMap["application/x-msaccess"] = "Microsoft Access";
        mimeTypeMap["video/x-ms-asf"] = "Microsoft Advanced Systems Format (ASF)";
        mimeTypeMap["application/x-msdownload"] = "Microsoft Application";
        mimeTypeMap["application/vnd.ms-artgalry"] = "Microsoft Artgalry";
        mimeTypeMap["application/vnd.ms-cab-compressed"] = "Microsoft Cabinet File";
        mimeTypeMap["application/vnd.ms-ims"] = "Microsoft Class Server";
        mimeTypeMap["application/x-ms-application"] = "Microsoft ClickOnce";
        mimeTypeMap["application/x-msclip"] = "Microsoft Clipboard Clip";
        mimeTypeMap["image/vnd.ms-modi"] = "Microsoft Document Imaging Format";
        mimeTypeMap["application/vnd.ms-fontobject"] = "Microsoft Embedded OpenType";
        mimeTypeMap["application/vnd.ms-excel"] = "Microsoft Excel";
        mimeTypeMap["application/vnd.ms-excel.addin.macroenabled.12"] = "Microsoft Excel - Add-In File";
        mimeTypeMap["application/vnd.ms-excel.sheet.binary.macroenabled.12"] = "Microsoft Excel - Binary Workbook";
        mimeTypeMap["application/vnd.ms-excel.template.macroenabled.12"] = "Microsoft Excel - Macro-Enabled Template File";
        mimeTypeMap["application/vnd.ms-excel.sheet.macroenabled.12"] = "Microsoft Excel - Macro-Enabled Workbook";
        mimeTypeMap["application/vnd.ms-htmlhelp"] = "Microsoft Html Help File";
        mimeTypeMap["application/x-mscardfile"] = "Microsoft Information Card";
        mimeTypeMap["application/vnd.ms-lrm"] = "Microsoft Learning Resource Module";
        mimeTypeMap["application/x-msmediaview"] = "Microsoft MediaView";
        mimeTypeMap["application/x-msmoney"] = "Microsoft Money";
        mimeTypeMap["application/vnd.openxmlformats-officedocument.presentationml.presentation"] = "Microsoft Office - OOXML - Presentation";
        mimeTypeMap["application/vnd.openxmlformats-officedocument.presentationml.slide"] = "Microsoft Office - OOXML - Presentation (Slide)";
        mimeTypeMap["application/vnd.openxmlformats-officedocument.presentationml.slideshow"] = "Microsoft Office - OOXML - Presentation (Slideshow)";
        mimeTypeMap["application/vnd.openxmlformats-officedocument.presentationml.template"] = "Microsoft Office - OOXML - Presentation Template";
        mimeTypeMap["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"] = "Microsoft Office - OOXML - Spreadsheet";
        mimeTypeMap["application/vnd.openxmlformats-officedocument.spreadsheetml.template"] = "Microsoft Office - OOXML - Spreadsheet Template";
        mimeTypeMap["application/vnd.openxmlformats-officedocument.wordprocessingml.document"] = "Microsoft Office - OOXML - Word Document";
        mimeTypeMap["application/vnd.openxmlformats-officedocument.wordprocessingml.template"] = "Microsoft Office - OOXML - Word Document Template";
        mimeTypeMap["application/x-msbinder"] = "Microsoft Office Binder";
        mimeTypeMap["application/vnd.ms-officetheme"] = "Microsoft Office System Release Theme";
        mimeTypeMap["application/onenote"] = "Microsoft OneNote";
        mimeTypeMap["audio/vnd.ms-playready.media.pya"] = "Microsoft PlayReady Ecosystem";
        mimeTypeMap["video/vnd.ms-playready.media.pyv"] = "Microsoft PlayReady Ecosystem Video";
        mimeTypeMap["application/vnd.ms-powerpoint"] = "Microsoft PowerPoint";
        mimeTypeMap["application/vnd.ms-powerpoint.addin.macroenabled.12"] = "Microsoft PowerPoint - Add-in file";
        mimeTypeMap["application/vnd.ms-powerpoint.slide.macroenabled.12"] = "Microsoft PowerPoint - Macro-Enabled Open XML Slide";
        mimeTypeMap["application/vnd.ms-powerpoint.presentation.macroenabled.12"] = "Microsoft PowerPoint - Macro-Enabled Presentation File";
        mimeTypeMap["application/vnd.ms-powerpoint.slideshow.macroenabled.12"] = "Microsoft PowerPoint - Macro-Enabled Slide Show File";
        mimeTypeMap["application/vnd.ms-powerpoint.template.macroenabled.12"] = "Microsoft PowerPoint - Macro-Enabled Template File";
        mimeTypeMap["application/vnd.ms-project"] = "Microsoft Project";
        mimeTypeMap["application/x-mspublisher"] = "Microsoft Publisher";
        mimeTypeMap["application/x-msschedule"] = "Microsoft Schedule+";
        mimeTypeMap["application/x-silverlight-app"] = "Microsoft Silverlight";
        mimeTypeMap["application/vnd.ms-pki.stl"] = "Microsoft Trust UI Provider - Certificate Trust Link";
        mimeTypeMap["application/vnd.ms-pki.seccat"] = "Microsoft Trust UI Provider - Security Catalog";
        mimeTypeMap["application/vnd.visio"] = "Microsoft Visio";
        mimeTypeMap["application/vnd.visio2013"] = "Microsoft Visio 2013";
        mimeTypeMap["video/x-ms-wm"] = "Microsoft Windows Media";
        mimeTypeMap["audio/x-ms-wma"] = "Microsoft Windows Media Audio";
        mimeTypeMap["audio/x-ms-wax"] = "Microsoft Windows Media Audio Redirector";
        mimeTypeMap["video/x-ms-wmx"] = "Microsoft Windows Media Audio/Video Playlist";
        mimeTypeMap["application/x-ms-wmd"] = "Microsoft Windows Media Player Download Package";
        mimeTypeMap["application/vnd.ms-wpl"] = "Microsoft Windows Media Player Playlist";
        mimeTypeMap["application/x-ms-wmz"] = "Microsoft Windows Media Player Skin Package";
        mimeTypeMap["video/x-ms-wmv"] = "Microsoft Windows Media Video";
        mimeTypeMap["video/x-ms-wvx"] = "Microsoft Windows Media Video Playlist";
        mimeTypeMap["application/x-msmetafile"] = "Microsoft Windows Metafile";
        mimeTypeMap["application/x-msterminal"] = "Microsoft Windows Terminal Services";
        mimeTypeMap["application/msword"] = "Microsoft Word";
        mimeTypeMap["application/vnd.ms-word.document.macroenabled.12"] = "Microsoft Word - Macro-Enabled Document";
        mimeTypeMap["application/vnd.ms-word.template.macroenabled.12"] = "Microsoft Word - Macro-Enabled Template";
        mimeTypeMap["application/x-mswrite"] = "Microsoft Wordpad";
        mimeTypeMap["application/vnd.ms-works"] = "Microsoft Works";
        mimeTypeMap["application/x-ms-xbap"] = "Microsoft XAML Browser Application";
        mimeTypeMap["application/vnd.ms-xpsdocument"] = "Microsoft XML Paper Specification";
        mimeTypeMap["audio/midi"] = "MIDI - Musical Instrument Digital Interface";
        mimeTypeMap["application/vnd.ibm.minipay"] = "MiniPay";
        mimeTypeMap["application/vnd.ibm.modcap"] = "MO:DCA-P";
        mimeTypeMap["application/vnd.jcp.javame.midlet-rms"] = "Mobile Information Device Profile";
        mimeTypeMap["application/vnd.tmobile-livetv"] = "MobileTV";
        mimeTypeMap["application/x-mobipocket-ebook"] = "Mobipocket";
        mimeTypeMap["application/vnd.mobius.mbk"] = "Mobius Management Systems - Basket file";
        mimeTypeMap["application/vnd.mobius.dis"] = "Mobius Management Systems - Distribution Database";
        mimeTypeMap["application/vnd.mobius.plc"] = "Mobius Management Systems - Policy Definition Language File";
        mimeTypeMap["application/vnd.mobius.mqy"] = "Mobius Management Systems - Query File";
        mimeTypeMap["application/vnd.mobius.msl"] = "Mobius Management Systems - Script Language";
        mimeTypeMap["application/vnd.mobius.txf"] = "Mobius Management Systems - Topic Index File";
        mimeTypeMap["application/vnd.mobius.daf"] = "Mobius Management Systems - UniversalArchive";
        mimeTypeMap["text/vnd.fly"] = "mod_fly / fly.cgi";
        mimeTypeMap["application/vnd.mophun.certificate"] = "Mophun Certificate";
        mimeTypeMap["application/vnd.mophun.application"] = "Mophun VM";
        mimeTypeMap["video/mj2"] = "Motion JPEG 2000";
        mimeTypeMap["audio/mpeg"] = "MPEG Audio";
        mimeTypeMap["video/vnd.mpegurl"] = "MPEG Url";
        mimeTypeMap["video/mpeg"] = "MPEG Video";
        mimeTypeMap["application/mp21"] = "MPEG-21";
        mimeTypeMap["audio/mp4"] = "MPEG-4 Audio";
        mimeTypeMap["video/mp4"] = "MPEG-4 Video";
        mimeTypeMap["application/mp4"] = "MPEG4";
        mimeTypeMap["application/vnd.apple.mpegurl"] = "Multimedia Playlist Unicode";
        mimeTypeMap["application/vnd.musician"] = "MUsical Score Interpreted Code Invented for the ASCII designation of Notation";
        mimeTypeMap["application/vnd.muvee.style"] = "Muvee Automatic Video Editing";
        mimeTypeMap["application/xv+xml"] = "MXML";
        mimeTypeMap["application/vnd.nokia.n-gage.data"] = "N-Gage Game Data";
        mimeTypeMap["application/vnd.nokia.n-gage.symbian.install"] = "N-Gage Game Installer";
        mimeTypeMap["application/x-dtbncx+xml"] = "Navigation Control file for XML (for ePub)";
        mimeTypeMap["application/x-netcdf"] = "Network Common Data Form (NetCDF)";
        mimeTypeMap["application/vnd.neurolanguage.nlu"] = "neuroLanguage";
        mimeTypeMap["application/vnd.dna"] = "New Moon Liftoff/DNA";
        mimeTypeMap["application/vnd.noblenet-directory"] = "NobleNet Directory";
        mimeTypeMap["application/vnd.noblenet-sealer"] = "NobleNet Sealer";
        mimeTypeMap["application/vnd.noblenet-web"] = "NobleNet Web";
        mimeTypeMap["application/vnd.nokia.radio-preset"] = "Nokia Radio Application - Preset";
        mimeTypeMap["application/vnd.nokia.radio-presets"] = "Nokia Radio Application - Preset";
        mimeTypeMap["text/n3"] = "Notation3";
        mimeTypeMap["application/vnd.novadigm.edm"] = "Novadigm's RADIA and EDM products";
        mimeTypeMap["application/vnd.novadigm.edx"] = "Novadigm's RADIA and EDM products";
        mimeTypeMap["application/vnd.novadigm.ext"] = "Novadigm's RADIA and EDM products";
        mimeTypeMap["application/vnd.flographit"] = "NpGraphIt";
        mimeTypeMap["audio/vnd.nuera.ecelp4800"] = "Nuera ECELP 4800";
        mimeTypeMap["audio/vnd.nuera.ecelp7470"] = "Nuera ECELP 7470";
        mimeTypeMap["audio/vnd.nuera.ecelp9600"] = "Nuera ECELP 9600";
        mimeTypeMap["application/oda"] = "Office Document Architecture";
        mimeTypeMap["application/ogg"] = "Ogg";
        mimeTypeMap["audio/ogg"] = "Ogg Audio";
        mimeTypeMap["video/ogg"] = "Ogg Video";
        mimeTypeMap["application/vnd.oma.dd2+xml"] = "OMA Download Agents";
        mimeTypeMap["application/vnd.oasis.opendocument.text-web"] = "Open Document Text Web";
        mimeTypeMap["application/oebps-package+xml"] = "Open eBook Publication Structure";
        mimeTypeMap["application/vnd.intu.qbo"] = "Open Financial Exchange";
        mimeTypeMap["application/vnd.openofficeorg.extension"] = "Open Office Extension";
        mimeTypeMap["application/vnd.yamaha.openscoreformat"] = "Open Score Format";
        mimeTypeMap["audio/webm"] = "Open Web Media Project - Audio";
        mimeTypeMap["video/webm"] = "Open Web Media Project - Video";
        mimeTypeMap["application/vnd.oasis.opendocument.chart"] = "OpenDocument Chart";
        mimeTypeMap["application/vnd.oasis.opendocument.chart-template"] = "OpenDocument Chart Template";
        mimeTypeMap["application/vnd.oasis.opendocument.database"] = "OpenDocument Database";
        mimeTypeMap["application/vnd.oasis.opendocument.formula"] = "OpenDocument Formula";
        mimeTypeMap["application/vnd.oasis.opendocument.formula-template"] = "OpenDocument Formula Template";
        mimeTypeMap["application/vnd.oasis.opendocument.graphics"] = "OpenDocument Graphics";
        mimeTypeMap["application/vnd.oasis.opendocument.graphics-template"] = "OpenDocument Graphics Template";
        mimeTypeMap["application/vnd.oasis.opendocument.image"] = "OpenDocument Image";
        mimeTypeMap["application/vnd.oasis.opendocument.image-template"] = "OpenDocument Image Template";
        mimeTypeMap["application/vnd.oasis.opendocument.presentation"] = "OpenDocument Presentation";
        mimeTypeMap["application/vnd.oasis.opendocument.presentation-template"] = "OpenDocument Presentation Template";
        mimeTypeMap["application/vnd.oasis.opendocument.spreadsheet"] = "OpenDocument Spreadsheet";
        mimeTypeMap["application/vnd.oasis.opendocument.spreadsheet-template"] = "OpenDocument Spreadsheet Template";
        mimeTypeMap["application/vnd.oasis.opendocument.text"] = "OpenDocument Text";
        mimeTypeMap["application/vnd.oasis.opendocument.text-master"] = "OpenDocument Text Master";
        mimeTypeMap["application/vnd.oasis.opendocument.text-template"] = "OpenDocument Text Template";
        mimeTypeMap["image/ktx"] = "OpenGL Textures (KTX)";
        mimeTypeMap["application/vnd.sun.xml.calc"] = "OpenOffice - Calc (Spreadsheet)";
        mimeTypeMap["application/vnd.sun.xml.calc.template"] = "OpenOffice - Calc Template (Spreadsheet)";
        mimeTypeMap["application/vnd.sun.xml.draw"] = "OpenOffice - Draw (Graphics)";
        mimeTypeMap["application/vnd.sun.xml.draw.template"] = "OpenOffice - Draw Template (Graphics)";
        mimeTypeMap["application/vnd.sun.xml.impress"] = "OpenOffice - Impress (Presentation)";
        mimeTypeMap["application/vnd.sun.xml.impress.template"] = "OpenOffice - Impress Template (Presentation)";
        mimeTypeMap["application/vnd.sun.xml.math"] = "OpenOffice - Math (Formula)";
        mimeTypeMap["application/vnd.sun.xml.writer"] = "OpenOffice - Writer (Text - HTML)";
        mimeTypeMap["application/vnd.sun.xml.writer.global"] = "OpenOffice - Writer (Text - HTML)";
        mimeTypeMap["application/vnd.sun.xml.writer.template"] = "OpenOffice - Writer Template (Text - HTML)";
        mimeTypeMap["application/x-font-otf"] = "OpenType Font File";
        mimeTypeMap["application/vnd.yamaha.openscoreformat.osfpvg+xml"] = "OSFPVG";
        mimeTypeMap["application/vnd.osgi.dp"] = "OSGi Deployment Package";
        mimeTypeMap["application/vnd.palm"] = "PalmOS Data";
        mimeTypeMap["text/x-pascal"] = "Pascal Source File";
        mimeTypeMap["application/vnd.pawaafile"] = "PawaaFILE";
        mimeTypeMap["application/vnd.hp-pclxl"] = "PCL 6 Enhanced (Formely PCL XL)";
        mimeTypeMap["application/vnd.picsel"] = "Pcsel eFIF File";
        mimeTypeMap["image/x-pcx"] = "PCX Image";
        mimeTypeMap["image/vnd.adobe.photoshop"] = "Photoshop Document";
        mimeTypeMap["application/pics-rules"] = "PICSRules";
        mimeTypeMap["image/x-pict"] = "PICT Image";
        mimeTypeMap["application/x-chat"] = "pIRCh";
        mimeTypeMap["application/pkcs10"] = "PKCS #10 - Certification Request Standard";
        mimeTypeMap["application/x-pkcs12"] = "PKCS #12 - Personal Information Exchange Syntax Standard";
        mimeTypeMap["application/pkcs7-mime"] = "PKCS #7 - Cryptographic Message Syntax Standard";
        mimeTypeMap["application/pkcs7-signature"] = "PKCS #7 - Cryptographic Message Syntax Standard";
        mimeTypeMap["application/x-pkcs7-certreqresp"] = "PKCS #7 - Cryptographic Message Syntax Standard (Certificate Request Response)";
        mimeTypeMap["application/x-pkcs7-certificates"] = "PKCS #7 - Cryptographic Message Syntax Standard (Certificates)";
        mimeTypeMap["application/pkcs8"] = "PKCS #8 - Private-Key Information Syntax Standard";
        mimeTypeMap["application/vnd.pocketlearn"] = "PocketLearn Viewers";
        mimeTypeMap["image/x-portable-anymap"] = "Portable Anymap Image";
        mimeTypeMap["image/x-portable-bitmap"] = "Portable Bitmap Format";
        mimeTypeMap["application/x-font-pcf"] = "Portable Compiled Format";
        mimeTypeMap["application/font-tdpfr"] = "Portable Font Resource";
        mimeTypeMap["application/x-chess-pgn"] = "Portable Game Notation (Chess Games)";
        mimeTypeMap["image/x-portable-graymap"] = "Portable Graymap Format";
        mimeTypeMap["image/png"] = "Portable Network Graphics (PNG)";
        mimeTypeMap["image/x-citrix-png"] = "Portable Network Graphics (PNG) (Citrix client)";
        mimeTypeMap["image/x-png"] = "Portable Network Graphics (PNG) (x-token)";
        mimeTypeMap["image/x-portable-pixmap"] = "Portable Pixmap Format";
        mimeTypeMap["application/pskc+xml"] = "Portable Symmetric Key Container";
        mimeTypeMap["application/vnd.ctc-posml"] = "PosML";
        mimeTypeMap["application/postscript"] = "PostScript";
        mimeTypeMap["application/x-font-type1"] = "PostScript Fonts";
        mimeTypeMap["application/vnd.powerbuilder6"] = "PowerBuilder";
        mimeTypeMap["application/pgp-encrypted"] = "Pretty Good Privacy";
        mimeTypeMap["application/pgp-signature"] = "Pretty Good Privacy - Signature";
        mimeTypeMap["application/vnd.previewsystems.box"] = "Preview Systems ZipLock/VBox";
        mimeTypeMap["application/vnd.pvi.ptid1"] = "Princeton Video Image";
        mimeTypeMap["application/pls+xml"] = "Pronunciation Lexicon Specification";
        mimeTypeMap["application/vnd.pg.format"] = "Proprietary P&G Standard Reporting System";
        mimeTypeMap["application/vnd.pg.osasli"] = "Proprietary P&G Standard Reporting System";
        mimeTypeMap["text/prs.lines.tag"] = "PRS Lines Tag";
        mimeTypeMap["application/x-font-linux-psf"] = "PSF Fonts";
        mimeTypeMap["application/vnd.publishare-delta-tree"] = "PubliShare Objects";
        mimeTypeMap["application/vnd.pmi.widget"] = "Qualcomm's Plaza Mobile Internet";
        mimeTypeMap["application/vnd.quark.quarkxpress"] = "QuarkXpress";
        mimeTypeMap["application/vnd.epson.esf"] = "QUASS Stream Player";
        mimeTypeMap["application/vnd.epson.msf"] = "QUASS Stream Player";
        mimeTypeMap["application/vnd.epson.ssf"] = "QUASS Stream Player";
        mimeTypeMap["application/vnd.epson.quickanime"] = "QuickAnime Player";
        mimeTypeMap["application/vnd.intu.qfx"] = "Quicken";
        mimeTypeMap["video/quicktime"] = "Quicktime Video";
        mimeTypeMap["application/x-rar-compressed"] = "RAR Archive";
        mimeTypeMap["audio/x-pn-realaudio"] = "Real Audio Sound";
        mimeTypeMap["audio/x-pn-realaudio-plugin"] = "Real Audio Sound";
        mimeTypeMap["application/rsd+xml"] = "Really Simple Discovery";
        mimeTypeMap["application/vnd.rn-realmedia"] = "RealMedia";
        mimeTypeMap["application/vnd.realvnc.bed"] = "RealVNC";
        mimeTypeMap["application/vnd.recordare.musicxml"] = "Recordare Applications";
        mimeTypeMap["application/vnd.recordare.musicxml+xml"] = "Recordare Applications";
        mimeTypeMap["application/relax-ng-compact-syntax"] = "Relax NG Compact Syntax";
        mimeTypeMap["application/vnd.data-vision.rdz"] = "RemoteDocs R-Viewer";
        mimeTypeMap["application/rdf+xml"] = "Resource Description Framework";
        mimeTypeMap["application/vnd.cloanto.rp9"] = "RetroPlatform Player";
        mimeTypeMap["application/vnd.jisp"] = "RhymBox";
        mimeTypeMap["application/rtf"] = "Rich Text Format";
        mimeTypeMap["text/richtext"] = "Rich Text Format (RTF)";
        mimeTypeMap["application/vnd.route66.link66+xml"] = "ROUTE 66 Location Based Services";
        mimeTypeMap["application/rss+xml"] = "RSS - Really Simple Syndication";
        mimeTypeMap["application/shf+xml"] = "S Hexdump Format";
        mimeTypeMap["application/vnd.sailingtracker.track"] = "SailingTracker";
        mimeTypeMap["image/svg+xml"] = "Scalable Vector Graphics (SVG)";
        mimeTypeMap["application/vnd.sus-calendar"] = "ScheduleUs";
        mimeTypeMap["application/sru+xml"] = "Search/Retrieve via URL Response Format";
        mimeTypeMap["application/set-payment-initiation"] = "Secure Electronic Transaction - Payment";
        mimeTypeMap["application/set-registration-initiation"] = "Secure Electronic Transaction - Registration";
        mimeTypeMap["application/vnd.sema"] = "Secured eMail";
        mimeTypeMap["application/vnd.semd"] = "Secured eMail";
        mimeTypeMap["application/vnd.semf"] = "Secured eMail";
        mimeTypeMap["application/vnd.seemail"] = "SeeMail";
        mimeTypeMap["application/x-font-snf"] = "Server Normal Format";
        mimeTypeMap["application/scvp-vp-request"] = "Server-Based Certificate Validation Protocol - Validation Policies - Request";
        mimeTypeMap["application/scvp-vp-response"] = "Server-Based Certificate Validation Protocol - Validation Policies - Response";
        mimeTypeMap["application/scvp-cv-request"] = "Server-Based Certificate Validation Protocol - Validation Request";
        mimeTypeMap["application/scvp-cv-response"] = "Server-Based Certificate Validation Protocol - Validation Response";
        mimeTypeMap["application/sdp"] = "Session Description Protocol";
        mimeTypeMap["text/x-setext"] = "Setext";
        mimeTypeMap["video/x-sgi-movie"] = "SGI Movie";
        mimeTypeMap["application/vnd.shana.informed.formdata"] = "Shana Informed Filler";
        mimeTypeMap["application/vnd.shana.informed.formtemplate"] = "Shana Informed Filler";
        mimeTypeMap["application/vnd.shana.informed.interchange"] = "Shana Informed Filler";
        mimeTypeMap["application/vnd.shana.informed.package"] = "Shana Informed Filler";
        mimeTypeMap["application/thraud+xml"] = "Sharing Transaction Fraud Data";
        mimeTypeMap["application/x-shar"] = "Shell Archive";
        mimeTypeMap["image/x-rgb"] = "Silicon Graphics RGB Bitmap";
        mimeTypeMap["application/vnd.epson.salt"] = "SimpleAnimeLite Player";
        mimeTypeMap["application/vnd.accpac.simply.aso"] = "Simply Accounting";
        mimeTypeMap["application/vnd.accpac.simply.imp"] = "Simply Accounting - Data Import";
        mimeTypeMap["application/vnd.simtech-mindmapper"] = "SimTech MindMapper";
        mimeTypeMap["application/vnd.commonspace"] = "Sixth Floor Media - CommonSpace";
        mimeTypeMap["application/vnd.yamaha.smaf-audio"] = "SMAF Audio";
        mimeTypeMap["application/vnd.smaf"] = "SMAF File";
        mimeTypeMap["application/vnd.yamaha.smaf-phrase"] = "SMAF Phrase";
        mimeTypeMap["application/vnd.smart.teacher"] = "SMART Technologies Apps";
        mimeTypeMap["application/vnd.svd"] = "SourceView Document";
        mimeTypeMap["application/sparql-query"] = "SPARQL - Query";
        mimeTypeMap["application/sparql-results+xml"] = "SPARQL - Results";
        mimeTypeMap["application/srgs"] = "Speech Recognition Grammar Specification";
        mimeTypeMap["application/srgs+xml"] = "Speech Recognition Grammar Specification - XML";
        mimeTypeMap["application/ssml+xml"] = "Speech Synthesis Markup Language";
        mimeTypeMap["application/vnd.koan"] = "SSEYO Koan Play File";
        mimeTypeMap["text/sgml"] = "Standard Generalized Markup Language (SGML)";
        mimeTypeMap["application/vnd.stardivision.calc"] = "StarOffice - Calc";
        mimeTypeMap["application/vnd.stardivision.draw"] = "StarOffice - Draw";
        mimeTypeMap["application/vnd.stardivision.impress"] = "StarOffice - Impress";
        mimeTypeMap["application/vnd.stardivision.math"] = "StarOffice - Math";
        mimeTypeMap["application/vnd.stardivision.writer"] = "StarOffice - Writer";
        mimeTypeMap["application/vnd.stardivision.writer-global"] = "StarOffice - Writer (Global)";
        mimeTypeMap["application/vnd.stepmania.stepchart"] = "StepMania";
        mimeTypeMap["application/x-stuffit"] = "Stuffit Archive";
        mimeTypeMap["application/x-stuffitx"] = "Stuffit Archive";
        mimeTypeMap["application/vnd.solent.sdkm+xml"] = "SudokuMagic";
        mimeTypeMap["application/vnd.olpc-sugar"] = "Sugar Linux Application Bundle";
        mimeTypeMap["audio/basic"] = "Sun Audio - Au file format";
        mimeTypeMap["application/vnd.wqd"] = "SundaHus WQ";
        mimeTypeMap["application/vnd.symbian.install"] = "Symbian Install Package";
        mimeTypeMap["application/smil+xml"] = "Synchronized Multimedia Integration Language";
        mimeTypeMap["application/vnd.syncml+xml"] = "SyncML";
        mimeTypeMap["application/vnd.syncml.dm+wbxml"] = "SyncML - Device Management";
        mimeTypeMap["application/vnd.syncml.dm+xml"] = "SyncML - Device Management";
        mimeTypeMap["application/x-sv4cpio"] = "System V Release 4 CPIO Archive";
        mimeTypeMap["application/x-sv4crc"] = "System V Release 4 CPIO Checksum Data";
        mimeTypeMap["application/sbml+xml"] = "Systems Biology Markup Language";
        mimeTypeMap["text/tab-separated-values"] = "Tab Seperated Values";
        mimeTypeMap["image/tiff"] = "Tagged Image File Format";
        mimeTypeMap["application/vnd.tao.intent-module-archive"] = "Tao Intent";
        mimeTypeMap["application/x-tar"] = "Tar File (Tape Archive)";
        mimeTypeMap["application/x-tcl"] = "Tcl Script";
        mimeTypeMap["application/x-tex"] = "TeX";
        mimeTypeMap["application/x-tex-tfm"] = "TeX Font Metric";
        mimeTypeMap["application/tei+xml"] = "Text Encoding and Interchange";
        mimeTypeMap["text/plain"] = "Text File";
        mimeTypeMap["application/vnd.spotfire.dxp"] = "TIBCO Spotfire";
        mimeTypeMap["application/vnd.spotfire.sfs"] = "TIBCO Spotfire";
        mimeTypeMap["application/timestamped-data"] = "Time Stamped Data Envelope";
        mimeTypeMap["application/vnd.trid.tpt"] = "TRI Systems Config";
        mimeTypeMap["application/vnd.triscape.mxs"] = "Triscape Map Explorer";
        mimeTypeMap["text/troff"] = "troff";
        mimeTypeMap["application/vnd.trueapp"] = "True BASIC";
        mimeTypeMap["application/x-font-ttf"] = "TrueType Font";
        mimeTypeMap["text/turtle"] = "Turtle (Terse RDF Triple Language)";
        mimeTypeMap["application/vnd.umajin"] = "UMAJIN";
        mimeTypeMap["application/vnd.uoml+xml"] = "Unique Object Markup Language";
        mimeTypeMap["application/vnd.unity"] = "Unity 3d";
        mimeTypeMap["application/vnd.ufdl"] = "Universal Forms Description Language";
        mimeTypeMap["text/uri-list"] = "URI Resolution Services";
        mimeTypeMap["application/vnd.uiq.theme"] = "User Interface Quartz - Theme (Symbian)";
        mimeTypeMap["application/x-ustar"] = "Ustar (Uniform Standard Tape Archive)";
        mimeTypeMap["text/x-uuencode"] = "UUEncode";
        mimeTypeMap["text/x-vcalendar"] = "vCalendar";
        mimeTypeMap["text/x-vcard"] = "vCard";
        mimeTypeMap["application/x-cdlink"] = "Video CD";
        mimeTypeMap["application/vnd.vsf"] = "Viewport+";
        mimeTypeMap["model/vrml"] = "Virtual Reality Modeling Language";
        mimeTypeMap["application/vnd.vcx"] = "VirtualCatalog";
        mimeTypeMap["model/vnd.mts"] = "Virtue MTS";
        mimeTypeMap["model/vnd.vtu"] = "Virtue VTU";
        mimeTypeMap["application/vnd.visionary"] = "Visionary";
        mimeTypeMap["video/vnd.vivo"] = "Vivo";
        mimeTypeMap["application/ccxml+xml,"] = "Voice Browser Call Control";
        mimeTypeMap["application/voicexml+xml"] = "VoiceXML";
        mimeTypeMap["application/x-wais-source"] = "WAIS Source";
        mimeTypeMap["application/vnd.wap.wbxml"] = "WAP Binary XML (WBXML)";
        mimeTypeMap["image/vnd.wap.wbmp"] = "WAP Bitamp (WBMP)";
        mimeTypeMap["audio/x-wav"] = "Waveform Audio File Format (WAV)";
        mimeTypeMap["application/davmount+xml"] = "Web Distributed Authoring and Versioning";
        mimeTypeMap["application/x-font-woff"] = "Web Open Font Format";
        mimeTypeMap["application/wspolicy+xml"] = "Web Services Policy";
        mimeTypeMap["image/webp"] = "WebP Image";
        mimeTypeMap["application/vnd.webturbo"] = "WebTurbo";
        mimeTypeMap["application/widget"] = "Widget Packaging and XML Configuration";
        mimeTypeMap["application/winhlp"] = "WinHelp";
        mimeTypeMap["text/vnd.wap.wml"] = "Wireless Markup Language (WML)";
        mimeTypeMap["text/vnd.wap.wmlscript"] = "Wireless Markup Language Script (WMLScript)";
        mimeTypeMap["application/vnd.wap.wmlscriptc"] = "WMLScript";
        mimeTypeMap["application/vnd.wordperfect"] = "Wordperfect";
        mimeTypeMap["application/vnd.wt.stf"] = "Worldtalk";
        mimeTypeMap["application/wsdl+xml"] = "WSDL - Web Services Description Language";
        mimeTypeMap["image/x-xbitmap"] = "X BitMap";
        mimeTypeMap["image/x-xpixmap"] = "X PixMap";
        mimeTypeMap["image/x-xwindowdump"] = "X Window Dump";
        mimeTypeMap["application/x-x509-ca-cert"] = "X.509 Certificate";
        mimeTypeMap["application/x-xfig"] = "Xfig";
        mimeTypeMap["application/xhtml+xml"] = "XHTML - The Extensible HyperText Markup Language";
        mimeTypeMap["application/xml"] = "XML - Extensible Markup Language";
        mimeTypeMap["application/xcap-diff+xml"] = "XML Configuration Access Protocol - XCAP Diff";
        mimeTypeMap["application/xenc+xml"] = "XML Encryption Syntax and Processing";
        mimeTypeMap["application/patch-ops-error+xml"] = "XML Patch Framework";
        mimeTypeMap["application/resource-lists+xml"] = "XML Resource Lists";
        mimeTypeMap["application/rls-services+xml"] = "XML Resource Lists";
        mimeTypeMap["application/resource-lists-diff+xml"] = "XML Resource Lists Diff";
        mimeTypeMap["application/xslt+xml"] = "XML Transformations";
        mimeTypeMap["application/xop+xml"] = "XML-Binary Optimized Packaging";
        mimeTypeMap["application/x-xpinstall"] = "XPInstall - Mozilla";
        mimeTypeMap["application/xspf+xml"] = "XSPF - XML Shareable Playlist Format";
        mimeTypeMap["application/vnd.mozilla.xul+xml"] = "XUL - XML User Interface Language";
        mimeTypeMap["chemical/x-xyz"] = "XYZ File Format";
        mimeTypeMap["text/yaml"] = "YAML Ain't Markup Language / Yet Another Markup Language";
        mimeTypeMap["application/yang"] = "YANG Data Modeling Language";
        mimeTypeMap["application/yin+xml"] = "YIN (YANG - XML)";
        mimeTypeMap["application/vnd.zul"] = "Z.U.L. Geometry";
        mimeTypeMap["application/zip"] = "Zip Archive";
        mimeTypeMap["application/vnd.handheld-entertainment+xml"] = "ZVUE Media Manager";
        mimeTypeMap["application/vnd.zzazz.deck+xml"] = "Zzazz Deck";
    }
}

export default MimeTypeMap;