import ga4 from 'react-ga4';

let TRACKING_ID = 'G-J4T5WWDLBM'; //local tag Id
const isProduction = process.env.NODE_ENV === 'production';
let isLocal = false;

if (typeof window !== 'undefined') {
  const hostName = window.location.hostname.toLowerCase();
  if (hostName.includes('local')) {
    TRACKING_ID = 'G-J4T5WWDLBM';
    isLocal = true;
  } else if (hostName.includes('dev')) {
    TRACKING_ID = 'G-VH22LGZMB8';
  } else if (hostName.includes('qa')) {
    TRACKING_ID = 'G-NFK6TF1NK4';
  } else if (hostName.includes('next.eepass.org')) {
    TRACKING_ID = 'G-MHYM2Q89HN';
  }
}

export const init = () => ga4.initialize([
  { 
  //testMode: true //!isProduction 
  trackingId: TRACKING_ID,
  gaOptions: {
    debug_mode: isLocal,
  },
  gtagOptions: {
    debug_mode: isLocal,
  },
  }
])

export const AddEvent = (eventTypeEnum, value, content, mgr) => {
  const userInfo = mgr?._globalAppUserState?.userProfile;

  let eventData = eventTypeObjects[eventTypeEnum];
  if (!eventData) {
    return;
  }

  eventData.value = value;

  if (content) {
    if (eventData.category.toLowerCase() == "content") {
      eventData.content_type_name = content.ContentTypeName;
      eventData.label = content.Title;
    }
    if (eventTypeEnum == EventTypeEnum.Content_Accessed) {
      eventData.content_path = getContentPath(content);
      eventData.content_type_name = content.ContentTypeName;
      eventData.title = content.Title;
      eventData.mime_type = content.MimeType;
      eventData.file_type = content.FileExt;
    }
  }

  if (userInfo) {
    const userOrg = mgr?._globalAppUserState?.OrganizationNames.get();
    const userDist = mgr?._globalAppUserState?.DistrictNames.get();
    const userSchool = mgr?._globalAppUserState?.SchoolNames.get();

    ga4.gtag('set', 'user_properties', mapUserProperties(userInfo, userOrg, userDist, userSchool));
  }

  ga4.event(eventData.category, eventData);
}

const mapUserProperties = (userInfo, userOrg, userDist, userSchool) => ({
  user_type_name: userInfo.UserTypeName.get(),
  user_roles: userInfo.Roles.get(),
  user_title: userInfo.Title.get(),
  org_name: userOrg,
  district_name: userDist,
  school_name: userSchool
});

const getContentPath = (content) => {
  const path = window.location.protocol + "//" + window.location.host;
  return path + "/content/?k=" + content.KeyId;
}

export const EventTypeEnum = {
    Auth_Login: 1,
    Auth_Logout: 2,
    Content_Download: 3,
    Content_Accessed: 4,
    Content_Shared: 5,
    View_Report: 6,
};

export const eventTypeObjects = {
  [EventTypeEnum.Auth_Login]: { category: "auth", action: "User Logged In" },
  [EventTypeEnum.Auth_Logout]: { category: "auth", action: "User Logged Out" },
  [EventTypeEnum.Content_Download]: { category: "content", action: "Download" },
  [EventTypeEnum.Content_Accessed]: { category: "content", action: "View" },
  [EventTypeEnum.Content_Shared]: { category: "content", action: "Shared" }
};

export { ga4 };