import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _ from 'lodash';
import moment from 'moment';
import SystemTypes from '../SystemTypes';
import configData from "../config.json";
import { fileUploadWorkerScript } from '../components/controls/FileUploaderControl/FileUploadApi';
import { BaseService } from '../services/base/BaseService';


class ImportExportManager extends ItemManager {
  constructor(globalItemState, globalAuthState, globalAppUserState) {

    super(ItemServiceFactory.ItemServiceEnum.FileImportExport, globalAuthState, globalAppUserState, globalItemState,
      (filter, item) => {
        const hasSearch = item.ImportExportTypeName?.toLowerCase()?.includes(filter?.search?.toLowerCase()) ||
          item.File?.DisplayFileName?.toLowerCase()?.includes(filter?.search?.toLowerCase()) ||
          (filter?.search === undefined || filter?.search === null || filter?.search === '');
        const hasStatus = item.ImportExportStatus == filter?.ImportExportStatus || (filter?.ImportExportStatus === 'UNK' || filter?.ImportExportStatus === '' || filter?.ImportExportStatus === undefined);
        return hasSearch && hasStatus;
      });

    this._errorUtil = undefined;
  }

  async downloadFile(item) {
    return await this._itemService.getFile(item.FileId, `${item.File.DisplayFileName}.${item.File.FileExtention}`);
  }

  uploadFile(file, currentFileId, callback) {
    const uploadWorker = new Worker(fileUploadWorkerScript);
    uploadWorker.onmessage = (postResult) => {
      callback(postResult?.data?.first() ?? { Success: false, MessageDetails: ["Unknown file upload error has occurred."] });
    }

    const gas = this._globalAuthState.get({ noproxy: true });
    const service = new BaseService(`file/upload${currentFileId ? `/${currentFileId}` : ''}`, this._globalAuthState, undefined, 20 * 60 * 60);
    const serviceConfig = {
      accessToken: gas.accessToken,
      timeout: service._timeout,
      url: service._url,
      clientId: service._clientId,
      appId: configData.APP_ID
    };

    uploadWorker.postMessage({ file, currentFileId, serviceConfig, isPublic: false });
  }

  async RedoImportData(id) {
    return await this.runOperation('FileImport', id);
  }

  async ImportData(importExportType, fileId) {
    const fileImportData = {
      ExportItemQuery: null,
      ImportExportType: importExportType,
      StartedDate: moment().utc(),
      ImportExportStatus: SystemTypes.ImportExportStatusType.Created,
      CompletedDate: null,
      LogId: null,
      FileId: fileId
    };
    const currentSchoolYear = this.AppUserState.selectedSchoolYear;
    return await this.runOperation('FileImport', undefined, fileImportData, {sy: currentSchoolYear}, undefined, true);
  }

  async RedoExportData(id) {
    return await this.runOperation('FileExport', id, undefined, undefined, undefined, false);
  }

  async ExportDataAnalytics(filterStr) {
    const fileExportData = {
      ExportItemQuery: filterStr,
      ImportExportType: SystemTypes.ImportExportType.ExportAnalytics,
      StartedDate: moment().utc(),
      ImportExportStatus: SystemTypes.ImportExportStatusType.Created,
      CompletedDate: null,
      LogId: null,
      FileId: null
    };

    return await this.runOperation('FileExport', undefined, fileExportData, undefined, undefined, true);
  }

  async ExportData(importExportType, idList) {
    let itemQueryStr = `{"RootFilterNode": {"Variable": "x", "Method": "==", "Name": "IsDeleted", "Value":false, "IsConjunction": false}}`;;
    if (idList) {
      itemQueryStr = `{"RootFilterNode": {"Variable": "x", "Method": "Lookup", "Name": "Id", "Values": ${JSON.stringify(idList)}, "IsConjunction": false}}`;
    }

    const fileExportData = {
      ExportItemQuery: itemQueryStr,
      ImportExportType: importExportType,
      StartedDate: moment().utc(),
      ImportExportStatus: SystemTypes.ImportExportStatusType.Created,
      CompletedDate: null,
      LogId: null,
      FileId: null
    };
    const currentSchoolYear = this.AppUserState.selectedSchoolYear;
    return await this.runOperation('FileExport', undefined, fileExportData, {sy: currentSchoolYear}, undefined, true);
  }
}

export default ImportExportManager;