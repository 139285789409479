import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import FeedbackDetails from '../components/feedback/details';
import FeedbackFilter from '../components/feedback/filter';
import FeedbackTable from '../components/feedback/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useFeedbackManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';

const FeedbackMgmt = () => {
    const feedbackMgr = useFeedbackManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (feedbackMgr && !params?.feedbackId) {
            setLoading(true);
            feedbackMgr.search().then(fb => {
                setManageMode('list');
                setLoading(false);
            });
        }
        else if (!selectedFeedback && params?.feedbackId && feedbackMgr) {
            setLoading(true);
            feedbackMgr.get(params?.feedbackId).then((r) => {
                if (r.Success) {
                    setManageMode('view');
                    setSelectedFeedback(r.Items.first());
                }
                setLoading(false);
            });
        }
    }, [feedbackMgr, params?.feedbackId]);

    const handleOnFilterChange = async (field, value) => {

        const feedbackFilter = feedbackMgr.Filter;
        const updatedFeedbackFilter = { ...feedbackFilter };

        if (field.toLowerCase() === 'search') {
            updatedFeedbackFilter.search = value !== '' ? value : null;
        }
        else if (field.toLowerCase() === 'email') {
            updatedFeedbackFilter.email = value;
        }

        await feedbackMgr.filterData(updatedFeedbackFilter);
    }

    const handleOnComplete = async () => {
        const item = { ...itemToDelete };
        feedbackMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.Name}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnView = (item) => {
        setSelectedFeedback(item);
        setManageMode('view');
        navigate(`/admin/feedback/${item.Id}`);
    }


    const handleOnCancel = () => {
        navigate('/admin/feedback');
        if (feedbackMgr.FilteredData) {
            setManageMode('list');
        }
        else {
            setManageMode(null);
        }
        setLoadingError(null);
        setErrors(null);
    }

    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to dismiss the feedback from '${itemToDelete?.Name}' ? `} onCancel={() => setItemToDelete(null)} onOk={handleOnComplete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <MainLayout errors={errors} >
            {manageMode === 'view' &&
                <div className='screen-header'>
                    <h3>Feedback Management</h3>
                    <h5>View Feedback</h5>
                </div>
            }
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <FeedbackFilter feedbackMgr={feedbackMgr} onFilterChange={handleOnFilterChange} />
                            <FeedbackTable filteredItems={feedbackMgr?.FilteredData ?? []} onDelete={(item) => setItemToDelete(item)} onView={handleOnView} />
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&
                    <FeedbackDetails feedback={selectedFeedback} onCancel={handleOnCancel} onLoading={setLoading} onErrors={setErrors} onLoadingError={setLoadingError} />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default FeedbackMgmt;