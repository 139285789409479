import { ContentService, ContentTypeEnum } from '../services/ContentService';
import ErrorUtil from '../utils/ErrorUtil';
import { asyncForEach } from '../utils/jsUtil'
import MimeTypeMap from '../utils/mimeTypes'
import _ from 'lodash'
  
import ContentLibraryManager from '../../../managers/ContentLibaryManager';


class ContentManager {

    constructor(contentState, globalAuthState, globalAppUserState) {

        //update to use content manager from core...
        this._contentItemMgr = new ContentLibraryManager(globalAuthState, globalAppUserState);
        this._contentState = contentState;
        this._globalAppUserState = globalAppUserState
        this._setShareContentFilter = (value) => contentState.merge({ shareContentFilter: value });
        this._setAllContent = (value) => contentState.merge({ allContent: value });
        this._setSharedContent = (value) => contentState.merge({ sharedContent: value });
        this._setForceSharedContentUpdate = (value) => contentState.merge({ forceSharedContentUpdate: value });
        this._setAvaliableContentFilters = (value) => contentState.merge({ avaliableContentFilters: value });
        this._setAvaliableShareContentFilters = (value) => contentState.merge({ avaliableShareContentFilters: value });
        this._setForceContentUpdate = (value) => contentState.merge({ forceContentUpdate: value });
        this._setFilteredContent = (value, filter) => contentState.merge({ filteredContent: value, contentFilter: filter });
        this._setShareFilteredContent = (value) => contentState.merge({ shareFilteredContent: value });
        this._setSelectedSharedContent = (value) => contentState.merge({ selectedSharedContent: value });
        this._setSelectedContent = (value) => contentState.merge({ selectedContent: value });
        this._setRatingLoading = (value) => contentState.merge({ ratingLoading: value });
        this._tags = [];
        this._errorUtil = new ErrorUtil();
    }

    get _allContent() { return this._contentState.get({ noproxy: true }).allContent; }
    get AllContent() { return this._allContent }
    get _forceContentUpdate() { return this._contentState.get().forceContentUpdate; }
    get _contentFilter() { return this._contentState.get({ noproxy: true }).contentFilter; }
    get RatingLoading() { return this._contentState.get({ noproxy: true }).ratingLoading; }

    get FilteredContent() { return this._contentState?.get()?.filteredContent ?? []; }
    get AvaliableContentFilters() { return this._contentState?.get({ noproxy: true })?.avaliableContentFilters; }

    get _forceSharedContentUpdate() { return this._contentState.get().forceSharedContentUpdate; }
    get _sharedContent() { return this._contentState.get().sharedContent; }
    get _shareContentFilter() { return this._contentState.get().shareContentFilter; }

    get SelectedSharedContent() { return this._contentState.get().selectedSharedContent; }

    get SelectedContent() {
        return this._contentState.get({ noproxy: true }).selectedContent;
    }

    get ItemManager() {
        return this._contentItemMgr.ItemManager;
    }

    async deleteItem(id) {
        const result = await this._contentItemMgr.ItemManager.delete(id);
        if (result?.Success) {
            const item = result.Items?.first()
            const allData = _.filter([...this.AllContent], x => x.Id !== item.Id);
            this._setAllContent(allData);
            this._setFilteredContent(
                await this._doFilterContent({ ...this._contentFilter }, allData),
                { ...this._contentFilter }
            );
        }
        return result;
    }

    _getAvaliableFilters(contents) {
        let tags = [];
        let mimeTypes = [];
        let contentTypes = [];

        const run = async () => {
            let tagIds = [];

            contents.forEach((c) => {
                if (c.TagList != null && c.TagList != undefined) {
                    let parentTagList = c.TagList.filter((x) => { return x.ParentTagId === null });
                    parentTagList.forEach(t => {
                        if (tagIds.indexOf(t.TagId) == -1) {
                            tagIds.push(t.TagId);
                            t.label = t.Name;
                            t.value = t.TagId;
                            tags.push(t);
                        }
                    });
                }
            });

            contents.forEach((c) => {
                if (c.TagList != null && c.TagList != undefined) {
                    let tagList = c.TagList.filter((x) => { return x.ParentTagId !== null });
                    tagList.forEach(t => {
                        if (tagIds.indexOf(t.TagId) == -1) {
                            tagIds.push(t.TagId);
                            let parentTags = tags.filter((x) => { return x.TagId == t.ParentTagId });
                            if (parentTags.length > 0) {
                                let parentTag = parentTags[0];

                                if (parentTag.children == undefined)
                                    parentTag.children = [];
                                t.label = t.Name;
                                t.value = t.TagId;
                                parentTag.children.push(t);
                            }
                        }
                    });
                }
            });


            tags = this.SortTags(tags);
            this._tags = tags;

            let contentTypeIds = [];
            let contentPromise = asyncForEach(contents, async (c) => {
                if (contentTypeIds.indexOf(c.ContentTypeId) == -1) {
                    contentTypeIds.push(c.ContentTypeId);
                    contentTypes.push({ ContentTypeId: c.ContentTypeId, ContentTypeName: c.ContentTypeName });
                }
            });

            let mimeTypeKeys = [];
            let mimeTypePromise = asyncForEach(contents, async (c) => {
                if (c.MimeType != null && c.MimeType != undefined) {

                    if (mimeTypeKeys.indexOf(c.MimeType) == -1) {
                        mimeTypeKeys.push(c.MimeType);

                        mimeTypes.push({ MimeType: c.MimeType, MimeTypeName: MimeTypeMap[c.MimeType] != undefined ? MimeTypeMap[c.MimeType] : c.MimeType });
                    }
                }
            });
            await Promise.all([contentPromise, mimeTypePromise]);
        }
        run();
        return { tags: this._tags, contentTypes, mimeTypes };
    }

    async SearchContent(searchContentModel, admin) {
        if (this._allContent == null || this._forceContentUpdate) {
            if (searchContentModel === undefined)
                searchContentModel = this._contentItemMgr.DefaultSearchContentModel();
            return this._contentItemMgr.SearchContent(searchContentModel, admin).then((r) => {
                this._globalAppUserState.merge({ loadingData: false })
                if (r.Success) {
                    this._setAllContent(r.Items);
                    this._setAvaliableContentFilters(this._getAvaliableFilters(r.Items));
                    if (this._forceContentUpdate)
                        this._setForceContentUpdate(false);
                    this._doFilterContent(this._contentFilter, r.Items).then(fc => {
                        this._setFilteredContent([...fc], { ...this._contentFilter });
                    });
                }
                else {
                    this._errorUtil.AddError(r);
                }
            });
        }
    }

    async SearchContentNoFilter(searchContentModel) {
        if (this._allContent == null || this._forceContentUpdate) {
            if (searchContentModel === undefined)
                searchContentModel = this._contentItemMgr.DefaultSearchContentModel();
            return this._contentItemMgr.SearchContent(searchContentModel).then((r) => {
                if (r.Success) {
                    this._setAllContent(r.Items);
                    return r.Items;
                }
                else {
                    this._errorUtil.AddError(r);
                }
            });
        }
    }


    GetGlobalState() {
        return this._contentState.get({ noproxy: true });
    }

    ForceContentUpdate() {
        this._setForceContentUpdate(true);
    }

    ForceSharedContentUpdate() {
        this._setForceSharedContentUpdate(true);
    }

    SetSelectedSharedContent(selectedSharedContent) {
        this._setSelectedSharedContent(selectedSharedContent);
    }

    async ChangeContentStarRating(keyId, starRating) {
        const allContent = this._allContent ?? [this.SelectedContent];
        var r = await this._contentItemMgr.ChangeContentStarRating(keyId, allContent, starRating);
        if (r.Success) {
            var contents = allContent.filter(c => {
                return c.KeyId === keyId
            });

            if (r.Items && contents != null || contents != undefined && contents.length > 0) {
                const item = r.Items.first();
                contents[0].StarRatingForUser = item.StarRating;
                contents[0].ContentRatingIdForUser = item.Id;

                if (!contents[0].TagIds)
                    contents[0].TagIds = [];
                if (!contents[0].TagList)
                    contents[0].TagList = [];

                var c = _.find(allContent, c => {
                    return c.KeyId === keyId
                });

                if (this.SelectedContent) {
                    this._setSelectedContent({ ...this.SelectedContent, StarRatingForUser: starRating });
                }

                if (this._allContent) {

                    this._setAllContent(allContent);
                    this.FilterContent();
                }

                return r;
            }
        }
        else {
            this._errorUtil?.AddError(r);
            return;
        }
    }

    async SetContentViewed(keyId) {
        const allContent = this._allContent ? [...this._allContent] : [this.SelectedContent];
        return this._contentItemMgr.SetContentViewed(keyId, allContent).then((r) => {
            if (r.Success) {

                var contents = allContent.filter(c => {
                    return c.KeyId === keyId
                });

                if (r.Items && (contents != null || contents != undefined && contents.length > 0)) {

                    if (r.Items?.length > 0) {
                        const item = r.Items?.first();
                        if (item) {
                            contents[0].ContentRatingIdForUser = item.Id;
                            if (this._allContent) {
                                this._setAllContent(allContent);
                                this.FilterContent();
                            }
                        }
                    }
                    return;
                }
            }
            else {
                this._errorUtil?.AddError(r);
                return;
            }

        });
    }


    async ChangeContentRating(keyId) {

        this._setRatingLoading(true);
        const allContent = this._allContent ? [...this._allContent] : [this.SelectedContent];
        return this._contentItemMgr.ChangeContentRating(keyId, allContent).then((r) => {
            if (r.Success) {

                var contents = allContent.filter(c => {
                    return c.KeyId === keyId
                });

                if (r.Items && contents != null || contents != undefined && contents.length > 0) {
                    const item = r.Items.first();
                    contents[0].HasUserRating = item.Rating > 0;
                    contents[0].ContentRatingIdForUser = item.Id;
                    if (contents[0].HasUserRating) {
                        contents[0].OverallRating = contents[0].OverallRating + 1;
                    }
                    else if (contents[0].OverallRating > 0) {
                        contents[0].OverallRating = contents[0].OverallRating - 1;
                    }
                    if (!contents[0].TagIds)
                        contents[0].TagIds = [];
                    if (!contents[0].TagList)
                        contents[0].TagList = [];

                    var c = _.find(allContent, c => {
                        return c.KeyId === keyId
                    });

                    if (this._allContent) {
                        this._setAllContent(allContent);
                        this.FilterContent();
                    }

                    this._setRatingLoading(false);
                    return;
                }
            }
            else {
                this._errorUtil?.AddError(r);
                return;
            }

        });
    }


    async _doFilterContent(filter, allContent) {
        let filteredContent = [];
        const tagIds = filter?.tagIds ?? [];
        const contentTypeIds = filter?.contentTypeIds ?? [];
        const search = filter?.search;
        let tagList = [...tagIds]; //copying tagIds array

        tagIds.forEach(tagId => {
            const tag = this._tags.find(t => t.TagId === tagId);
            //check if the tag is a parent tag and has children
            if (tag && tag.children && tag.children.length > 0) {
                tag.children.forEach(child => {
                    if (tagList.indexOf(child.TagId) == -1) { 
                        tagList.push(child.TagId);
                    }
                });
            }
        });

        if (allContent != null) {
            await asyncForEach(allContent, async (c) => {
                let found = tagList.length === 0 && contentTypeIds.length === 0 && (search === null || search === '');
                if (!found) {
                    if (contentTypeIds.indexOf(c.ContentTypeId) > -1) {
                        found = true;
                    }

                    tagList?.forEach(tag => {
                        if(c.TagIds?.includes(tag)) {
                            found = true;
                        }
                    })

                    if ((search != null && search != '' && c.Title) &&
                        (c.Title?.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                            c.TagIds.some(x => x?.toLowerCase()?.includes(search.toLowerCase()))
                        )) {
                        found = true;
                    }
                    else if (search != null && search != '') {
                        found = false;
                    }
                }

                if ((filter?.isActive !== undefined && filter?.isActive !== null) && filter?.isActive !== c.IsEnabled) {
                    found = false;
                }

                if ((filter?.useForCertification !== undefined && filter?.useForCertification !== null || filter?.useForCertification === 'UNK') && filter?.useForCertification !== c.UseForCertification) {
                    found = false;
                }

                if (found) {
                    let newContent = { ...c };
                    newContent.thumbUrl = newContent.ThumbnailFileUrl;
                    newContent.imgUrlLarge = newContent.ThumbnailFileUrl;
                    filteredContent.push(newContent);
                }
            });
        }
        return filteredContent;
    }

    async ShareContent(shareContentConfig) {
        return this._contentItemMgr.ShareContent(shareContentConfig);
    }

    async RemoveShareContent(id) {
        return await this._contentItemMgr.RemoveShareContent(id);
    }


    async GetShareContentConfig(keyId) {
        let result;
        return this._contentItemMgr.GetShareContentConfig(keyId).then((r) => {
            if (r.Success) {
                result = r;
            }
            else {
                this._errorUtil.AddError(r);
                result = r;
            }

            return result;
        });
    }

    SortTags(tags) {
        return _.sortBy(tags, (tag) => {
            if (tag.Sequence < 0) return "AAA";
            if (tag.Sequence > 999) return "ZZZ";
            else return tag.Name;
        });
    }

    async GetSharedContent(shareContentModel) {
        if (this._sharedContent == null || this._forceSharedContentUpdate) {
            if (shareContentModel == undefined)
                shareContentModel = this._contentItemMgr.DefaultShareContentModel();
            return this._contentItemMgr.SearchSharedContent(shareContentModel).then((r) => {
                if (r.Success) {
                    this._setSharedContent(r.Items.first());
                    const avaliableFilters = this._getAvaliableFilters(r?.Items?.first()?.SharedWithUser?.concat(r?.Items?.first()?.UserHasShared ?? []) ?? []);
                    this._setAvaliableShareContentFilters(avaliableFilters);

                    if (this._forceSharedContentUpdate)
                        this._setForceSharedContentUpdate(false);

                    this._doFilterContent(this._shareContentFilter, r.Items?.first()?.SharedWithUser).then(fc1 => {
                        let filteredContent = { SharedWithUser: fc1 };
                        this._doFilterContent(this._shareContentFilter, r.Items?.first()?.UserHasShared).then(fc2 => {
                            filteredContent.UserHasShared = fc2;
                            this._setShareFilteredContent(filteredContent);
                        });
                    });
                }
                else {
                    this._errorUtil.AddError(r);
                }
            });
        }
    }

    FilterSharedContent(filter) {
        if (!filter) {
            filter = this._shareContentFilter;
        }


        this._setShareContentFilter(filter);
        if (this._sharedContent != null) {
            this._doFilterContent(filter, this._sharedContent.SharedWithUser).then(fc1 => {
                let filteredContent = { SharedWithUser: fc1 };
                this._doFilterContent(filter, this._sharedContent.UserHasShared).then(fc2 => {
                    filteredContent.UserHasShared = fc2;
                    this._setShareFilteredContent(filteredContent);
                });
            });
        }
    }

    FilterContent(filter) {
        if (!filter)
            filter = this._contentFilter;

        this._doFilterContent(filter, this._allContent).then(fc => {
            this._setFilteredContent(fc, filter);
        });
    }

    async GetFilteredContent(filter) {
        return this._doFilterContent(filter ?? this._contentFilter, this._allContent).then(fc => {
            return fc;
        });
    }

    async ContentViewed() {

        if (this.SelectedContent != null) {
            const contentId = this.SelectedContent.ContentId;
            this._contentItemMgr.SetContentViewed(contentId, [this.SelectedContent]).then((r) => {
                if (!r.Success) {
                    this._errorUtil.AddError(r);
                }
            });
        }

    }

    async GetSingleContent(keyId) {
        var result;
        let searchContentModel = this._contentItemMgr.DefaultSearchContentModel();
        searchContentModel.ContentId = keyId;
        const r = await this._contentItemMgr.SearchContent(searchContentModel);
        if (r.Success) {
            if (r.Items != null && r.Items.length > 0) {
                result = r.Items.first();
            }
        }
        else {
            this._errorUtil.AddError(r);
            result = null;
        }

        if (result != null) {
            result.thumbUrl = result.ThumbnailFileUrl;
            result.imgUrlLarge = result.ThumbnailFileUrl;
            if (result.AssocatedContent != null && result.AssocatedContent.length > 0) {
                await asyncForEach(result.AssocatedContent, async (ac) => {
                    ac.thumbUrl = result.ThumbnailFileUrl;
                    ac.imgUrlLarge = result.ThumbnailFileUrl;
                });
            }
        }
        return result;
    }

    SetSelectedContent(content) {
        this._setSelectedContent(content);
    }

    GetViewUrl(keyId) {
        return "/content?k=" + keyId;
    }
}

export default ContentManager;