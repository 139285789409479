import { useEffect, useState } from 'react';
import { InputField } from "../../../../components/controls/InputField";
import '../../../../styles/rsuite_override.css';
import { useValidationManager } from "../../../../hooks/useValidation";
import { useAppUserManager } from "../../../../hooks/useManagers";
import { useUserProfileEmailManager } from "../../../../hooks/useManagers";
import ButtonControl from "../../../../components/controls/ButtonControl";
import SelectListControl from '../../../../components/controls/SelectListControl';
import _ from 'lodash';
import Loading from '../../../../components/Loading';
import { CheckPicker, Checkbox, SelectPicker } from 'rsuite';
import '../../userDetails.scss';
import { Toggle } from 'rsuite';
import timeZoneData from '../../../dev/data/timezones';
import userTypeData from '../../../dev/data/userTypes';
import userPositionData from '../../../dev/data/userPositionTypes';
import { Message, useToaster } from 'rsuite';
import { CheckboxControl } from '../../../../components/controls';
import SystemTypes from '../../../../SystemTypes';

const UserDetails = ({ onMergeUser, grabClusters, userMgr, userId, onSave, onCancel, isEdit, organizations, schools, districts, clusters, roles, onValidationErrors, onErrors, onLoadingError, tableData, setTableData }) => {

    const [userData, setUserData] = useState();
    const validationManager = useValidationManager(false, ['USER']);
    const [forceValidation, setForceValidation] = useState(false);
    const [saving, setSaving] = useState(false);
    const [districtDataSet, setDistrictDataSet] = useState();
    const [schoolDataSet, setSchoolDataSet] = useState();
    const [clusterDataSet, setClusterDataSet] = useState()
    const [currentOrgDataSet, setCurrentOrgDataSet] = useState();
    const [currentDistrictDataSet, setCurrentDistrictDataSet] = useState();
    const [currentSchoolDataSet, setCurrentSchoolDataSet] = useState();
    const [currentClusterDataSet, setCurrentClusterDataSet] = useState();
    const [roleDataSet, setRoleDataSet] = useState([]);
    const [disabledValues, setDisabledValues] = useState();
    const [dateSignedTOS, setDateSignedTOS] = useState();
    const [selectedTimeZone, setSelectedTimeZone] = useState('EST (Eastern Standard Time)');
    const [selectedUserType, setSelectedUserType] = useState();
    const [selectedUserPosition, setSelectedUserPosition] = useState(50);
    const [selectedOrgs, setSelectedOrgs] = useState(null);
    const [selectedDistricts, setSelectedDistricts] = useState(null);
    const [selectedSchools, setSelectedSchools] = useState(null);
    const [selectedClusters, setSelectedClusters] = useState(null)
    const [showSuccessMessage, setShowSuccessMessage] = useState();
    const [showEmailSentSuccess, setShowEmailSentSuccess] = useState();
    const [loading, setLoading] = useState(true);
    const [selectedRoleValues, setSelectedRoleValues] = useState([]);
    const [defaultRole, setDefaultRole] = useState();
    const appUserMgr = useAppUserManager();
    const userEmailMgr = useUserProfileEmailManager();
    const styles = { width: '100%', display: 'block', marginBottom: 10 };
    const appUserState = appUserMgr?.GetGlobalState();
    const [schoolYearUser, setSchoolYearUser] = useState(null);
    const [canViewObservationReqDropdown, setCanViewObservationReqDropdown] = useState(false);
    const minNumObservationsOptions = [];

    for (let i = 1; i <= 10; i++) {
        minNumObservationsOptions.push({ "Value": i });
    }
    useEffect(() => {
        if (userMgr && userId && userId !== 0) {
            let ignore = false;
            async function fetchUserData() {
                setLoading(true);
                await userMgr.ItemManager.get(userId).then(async (r) => {
                    if (r?.Success && r?.Items?.length > 0) {
                        var item = r?.Items?.first();
                        const updatedDefaultUserPositionTypes = []
                        for (const [key, value] of Object.entries(SystemTypes.UserPositionType)) {
                            const userPostionAlternative = _.find(userMgr?.AppUserState?.observationConfig.UserPositionAlternativeMap, x => x.UserPosition === value)
                            if (userPostionAlternative && userPostionAlternative.Name) {
                                updatedDefaultUserPositionTypes.push({ Id: value, Value: value, Text: userPostionAlternative.Name, })
                            } else {
                                updatedDefaultUserPositionTypes.push({ Id: value, Value: value, Text: key, })
                            }
                        }
                        item.userPositionsData = updatedDefaultUserPositionTypes;

                        if (typeof userMgr.getSchoolYearUser === 'function') {
                            await userMgr.getSchoolYearUser(userId).then(async (u) => {
                                if (u?.first() !== null); {
                                    let response = u?.first();
                                    let syuser = response?.Items?.first();
                                    if (syuser !== null) {
                                        setSchoolYearUser(syuser);
                                        item.NumberOfTeacherEvals = syuser.NumberOfTeacherEvals;
                                        item.NumberOfAdminEvals = syuser.NumberOfAdminEvals;
                                    }
                                }
                            });
                        }

                        setUserData(item);
                        saveItemInStorage(item);
                        setSelectedTimeZone(item.TimeZone);

                        if (item.SignedTOSDate) {
                            let date = new Date(item.SignedTOSDate);
                            let formatedTOS = date.toLocaleDateString("en-US");
                            setDateSignedTOS(formatedTOS);
                        }

                        if (item.UserPositionType) {
                            setSelectedUserPosition(item.UserPositionType);
                        }
                        else {
                            setSelectedUserPosition(100);
                        }

                        if (item.UserTypes.length > 0) {
                            item.UserTypes.forEach(u => {
                                setSelectedUserType(u);
                            })
                        }



                        if (item.Roles.length > 0) {
                            let rArr = [];
                            item.Roles.forEach(r => {
                                let matchingRole = roles.find(x => x.role === r);
                                if (matchingRole) {
                                    rArr.push(matchingRole.value);
                                }
                            })
                            setSelectedRoleValues(rArr);
                        }
                        else {
                            setSelectedRoleValues([roles.find(x => x.role?.toLowerCase() === 'basic')?.value]);
                        }

                        setLoading(false);
                    }
                    else if (onLoadingError) {
                        onLoadingError(r?.Message.DisplayMessage);
                    }
                });
            }

            fetchUserData();
            return () => { ignore = true }; //cleanup
        }
        else if (userMgr && roles && (userId == null || userId == undefined)) {
            setLoading(false);

            setUserData({
                Email: '',
                FirstName: '',
                LastName: '',
                userPositionsData: _.map(Object.entries(SystemTypes.UserPositionType), x => {
                    const userPostionAlternative = _.find(userMgr?.AppUserState?.observationConfig.UserPositionAlternativeMap, y => y.UserPosition === x[1] && y.Name)?.Name
                    return {
                        Id: x[1],
                        Value: x[1],
                        Text: userPostionAlternative ?? x[0],
                    }
                })
            });
            setSelectedUserType(99);
            setSelectedTimeZone(0);
            setSelectedRoleValues([roles.find(x => x.role?.toLowerCase() === 'basic')?.value]);
        }

    }, [userMgr, roles, userId]);
    useEffect(() => {
        if (organizations && organizations.length > 0 && roles && districts && districts.length > 0 && schools && schools.length > 0 && clusters && userData) {
            setCurrentOrgDataSet(organizations.filter(x => !x.label?.toLowerCase().includes('inactive') || userData.OrganizationIds?.includes(x.value)));
            if (!currentDistrictDataSet) {
                setCurrentDistrictDataSet(districts.filter(x => !x.label?.toLowerCase().includes('inactive') || userData.DistrictIds?.includes(x.value)));
            }
            if (!currentSchoolDataSet) {
                setCurrentSchoolDataSet(schools.filter(x => !x.label?.toLowerCase().includes('inactive') || userData.SchoolIds?.includes(x.value)));
            }
            if (!currentClusterDataSet || currentClusterDataSet.length === 0) {
                setCurrentClusterDataSet(clusters.filter(x => !x.label?.toLowerCase().includes('inactive') || userData.ClusterIds?.includes(x.value)))
            }
            setDistrictDataSet(districts.filter(x => !x.label?.toLowerCase().includes('inactive') || userData.DistrictIds?.includes(x.value)));
            setSchoolDataSet(schools.filter(x => !x.label?.toLowerCase().includes('inactive') || userData.SchoolIds?.includes(x.value)));
            setClusterDataSet(clusters.filter(x => !x.label?.toLowerCase().includes('inactive') || userData.ClusterIds?.includes(x.value)))

            setRoleDataSet(roles);
        }
    }, [organizations, roles, districts, schools, clusters, userData])

    useEffect(() => {
        if (
            clusters?.length > 0 &&
            userData?.Id &&
            userData.Schools?.length > 0 &&
            clusterDataSet?.length > 0
        ) {
            let sArr = userData.Schools.map(d => d.SchoolId);
            onMultiCheckSelect(sArr, { dataType: "School", label: "", value: sArr });
        }
    }, [clusters, userData?.Id, clusterDataSet, userData?.Schools]); //add specificity to the query to reduce UI lag.

    useEffect(() => {
        if (districtDataSet && schoolDataSet && clusterDataSet && userData) {
            //set name
            if (userData.Organizations?.length > 0 && !selectedOrgs) {
                let oArr = [];
                userData.Organizations.forEach(x => {
                    if (!oArr.includes(x.ClientId)) {
                        oArr.push(x.ClientId);
                        onMultiCheckSelect(oArr, { dataType: "Organization", label: "", value: x.ClientId })
                    }
                })
                grabClusters(oArr.first())
                setSelectedOrgs(oArr);
            }

            if (userData.Districts?.length > 0 && !selectedDistricts) {
                let dArr = [];
                userData.Districts.forEach(d => {
                    dArr.push(d.DistrictId);
                    onMultiCheckSelect(dArr, { dataType: "District", label: "", value: d.DistrictId })
                })
                setSelectedDistricts(dArr);
            }
            if (userData.Schools?.length > 0 && !selectedSchools) {
                let sArr = [];
                userData.Schools.forEach(d => {
                    sArr.push(d.SchoolId);
                    onMultiCheckSelect(sArr, { dataType: "School", label: "", value: d.SchoolId })
                })
                setSelectedSchools(sArr);
            }

            setSelectedClusters(userData.ClusterIds);



            let basicUser = roles.find(x => x.label === 'Basic User');
            if (basicUser) {
                let defaultRole = [basicUser.value];
                setDefaultRole(defaultRole);
            }

            const disabledRoleValues = roles.filter(x => x.role === 'Basic' || ((x.role === 'CertifiedSchoolAdmin' || x.role === 'CertifiedTeacher') && appUserMgr.AppUserState.userProfile.UserPositionType !== SystemTypes.UserPositionType.Admin));
            setDisabledValues(disabledRoleValues.map(x => x.value));
        }
    }, [districtDataSet, schoolDataSet, clusterDataSet, userData]);

    const saveItemInStorage = (item) => {
        sessionStorage.setItem('screen-data', JSON.stringify(item));
    };

    const handleOnChange = (value, field) => {
        const updatedItemData = { ...userData };
        updatedItemData[field] = value;

        if (field === 'NumberOfTeacherEvals' || field === 'NumberOfAdminEvals') {
            updatedItemData['NumRequiredObservations'] = value;
        }

        saveItemInStorage(updatedItemData);
        setUserData(updatedItemData);
    }
    const handleOnSave = async () => {
        userData.PrimaryPhone = userData.PrimaryPhone?.replace('+1', '');
        const errors = validationManager.checkIsGroupValid(userData);
        if (errors.length === 0) {
            if (onSave) {
                setSaving(true);

                var selectedRoleSet = roleDataSet.filter(role => selectedRoleValues.includes(role.value)).map(role => role.role).join(",");
                userData['PrimaryPhone'] = userData.PrimaryPhone;
                userData['DisplayName'] = userData.LastName + ', ' + userData.FirstName;
                userData['Locale'] = 'en-us';
                userData['RoleData'] = selectedRoleSet;
                userData['UserTypesData'] = selectedUserType;
                userData['UserPositionType'] = selectedUserPosition;
                userData['TimeZone'] = !userData['TimeZone'] ? 'EST' : userData['TimeZone'];

                if (+userData['UserPositionType'] <= 20) {
                    userData['Schools'] = [];
                    userData['SchoolIds'] = [];
                    userData['Clusters'] = [];
                    userData['CliusterIds'] = [];
                    setSelectedSchools([]);
                    setSelectedClusters([]);
                }


                if (selectedOrgs?.length > 0) {
                    let orgmapArr = []
                    selectedOrgs.forEach(ClientId => {
                        orgmapArr.push({ ClientId, UserId: userData.Id });
                    })

                    userData['Organizations'] = orgmapArr;
                    if (orgmapArr.length !== 0) {
                        userData['ClientID'] = orgmapArr.first().ClientId;
                    }
                }

                if (selectedDistricts?.length > 0) {
                    let districtMapArr = []
                    selectedDistricts.forEach(DistrictId => {
                        let matchingDistrict = districts.find(x => x.value === DistrictId);
                        if (matchingDistrict) {
                            const districtToSave = { DistrictId, UserId: userData.Id, ClientId: matchingDistrict.ClientId };
                            districtMapArr.push(districtToSave);
                        }
                    });

                    userData['Districts'] = districtMapArr;
                }

                if (selectedSchools?.length > 0) {
                    let schoolMapArr = []
                    selectedSchools.forEach(SchoolId => {
                        let matchingSchool = schools.find(x => x.value === SchoolId);
                        if (matchingSchool) {
                            const schoolToSave = { SchoolId, UserId: userData.Id, ClientId: matchingSchool.ClientId, DistrictId: matchingSchool.DistrictId };
                            schoolMapArr.push(schoolToSave);
                        }
                    });

                    userData['Schools'] = schoolMapArr;
                }

                if (selectedClusters?.length > 0) {
                    let clusterMapArr = []
                    selectedClusters.forEach(ClusterId => {
                        let matchingCluster = clusters.find(x => x.value === ClusterId);
                        if (matchingCluster) {
                            const clusterToSave = { ClusterId, UserId: userData.Id, ClientId: matchingCluster.ClientId, DistrictId: matchingCluster.DistrictId, SchoolId: matchingCluster.SchoolId };
                            clusterMapArr.push(clusterToSave);
                        }
                    });

                    userData['Clusters'] = clusterMapArr;
                }


                const sendNewUserEmail = !userData.Id

                if (!isEdit && sendNewUserEmail) {
                    userData['IsActive'] = true;
                    if (!userData['ClientId'])
                        userData['ClientId'] = 'c1684004-15ec-4667-abf7-29e36e2e9070';
                }

                const result = await userMgr.ItemManager.runOperation('AzureB2CSaveUserProfile', undefined, userData, { sy: appUserMgr.AppUserState.selectedSchoolYear }, undefined, true);
                if (result.Success && result.Items && result.Items.length > 0) {

                    setUserData(result.Items.first());
                    const formatedUserData = {
                        ...result.Items.first(),
                        Organizations: currentOrgDataSet?.filter(x => selectedOrgs?.includes(x.value))?.map(y => y.label)?.join(','),
                        Districts: currentDistrictDataSet?.filter(x => selectedDistricts?.includes(x.value)).map(y => y.label)?.join(','),
                        Schools: currentSchoolDataSet?.filter(x => selectedSchools?.includes(x.value))?.map(y => y.label)?.join(','),
                        // Clusters: currentClusterDataSet?.filter(x => selectedClusters?.includes(x.value))?.map(y => y.label)?.join(',')
                    }
                    if (!isEdit && sendNewUserEmail) {
                        if (tableData) {
                            let set = [...tableData];
                            set.unshift(formatedUserData);
                            setTableData(set);
                        }

                        let userIdObjArr = { UserIds: [result.Items[0].Id] };
                        await userEmailMgr.ItemManager.runOperation('AzureB2CSendWelcomeEmail', undefined, userIdObjArr, undefined, undefined, false);
                    }
                    else {
                        if (tableData) {
                            let set = [...tableData];
                            if (result.Items && result.Items.length > 0) {
                                let index = set.findIndex(x => x.Id === formatedUserData.Id);
                                set.splice(index, 1, formatedUserData)
                                setTableData(set);
                            }
                        }
                    }

                    setSaving(false);
                    onSave(result);
                }
                else {
                    setUserData(userData);
                    if (onErrors) {
                        onErrors([result.Message.DisplayMessage]);
                    }
                    setSaving(false);
                }
            }
        }
        else {
            if (onValidationErrors)
                onValidationErrors(errors);
            validationManager.setForceValidate(true);
            setForceValidation(true);
        }
    }

    const handleOnPasswordReset = async () => {
        setSaving(true);

        let userIdObjArr = { UserIds: [userData.Id] };

        const result = await userEmailMgr.ItemManager.runOperation('AzureB2CSendResetPasswordEmail', undefined, userIdObjArr, undefined, undefined, false);
        if (result.Success) {
            //setShowEmailSentSuccess(true);
            setSaving(false);
            setTimeout(function () {
                setShowEmailSentSuccess(false)
            }, 3000);
        }
        else if (onErrors) {
            onErrors([result.Message.DisplayMessage]);
            setSaving(false);
        }
    }

    const handleOnSendWelcomeEmail = async () => {
        setSaving(true);

        let userIdObjArr = { UserIds: [userData.Id] };

        const result = await userEmailMgr.ItemManager.runOperation('AzureB2CSendWelcomeEmail', undefined, userIdObjArr, undefined, undefined, false);
        if (result.Success) {
            //setShowEmailSentSuccess(true);
            setSaving(false);
            setTimeout(function () {
                setShowEmailSentSuccess(false)
            }, 3000);
        }
        else if (onErrors) {
            onErrors([result.Message.DisplayMessage]);
            setSaving(false);
        }

    }
    const onMultiCheckSelect = (value, item) => {
        switch (item.dataType) {
            case 'Organization':
                setSelectedOrgs(value);
                if (value.length === 0) {
                    onMultiCheckSelect([], { dataType: 'District' });
                } else {
                    let matchingDistricts = districtDataSet?.filter(x => value.includes(x.clientId));
                    if (currentClusterDataSet.length === 0) {
                        grabClusters(value.first())
                    }
                    setCurrentDistrictDataSet(matchingDistricts);
                }
                break;
            case 'District':
                setSelectedDistricts(value);
                if (value.length === 0) {
                    onMultiCheckSelect([], { dataType: 'School' });
                } else {
                    let matchingSchools = schoolDataSet?.filter(x => value.includes(x.districtId));
                    setCurrentSchoolDataSet(matchingSchools);
                }
                break;
            case 'School':
                setSelectedSchools(value);
                if (value.length === 0) {
                    onMultiCheckSelect([], { dataType: 'Cluster' });
                } else {
                    let matchingClusters = clusterDataSet?.filter(x => value.includes(x.schoolId));
                    setCurrentClusterDataSet(matchingClusters);
                }
                break;
            case 'Cluster':
                setSelectedClusters(value)
                break;
            case 'Role':
                setSelectedRoleValues(value);
                break;
            default:
        }
    }

    const onTreeClean = (id) => {
        //handleOnChange(null, id);

        onMultiCheckSelect([], { dataType: id });
    }

    const handleOnUserTypeChange = (value) => {
        setSelectedUserType(value);
        handleOnChange(value, 'UserTypesData')
    }

    const handleOnTimezoneChange = (value) => {
        setSelectedTimeZone(value);
        handleOnChange(value, 'TimeZone')
    }

    const handleOnUserPositionChange = (value) => {
        setSelectedUserPosition(value);
        handleOnChange(value, 'UserPosition')
    }

    useEffect(() => {
        if (selectedUserPosition) {
            //Admin Users
            if (selectedUserPosition < 10) {
                setSelectedOrgs([]);
            }

            if (selectedUserPosition < 20) {
                setSelectedDistricts([]);
            }

            else if (selectedUserPosition < 30) {
                setSelectedSchools([]);
            }

        }
    }, [selectedUserPosition]);

    const filterUserTypes = () => {
        let userTypes = appUserState?.userProfile?.UserTypes ? userTypeData.filter(x => x.Id >= _.min(appUserState?.userProfile?.UserTypes)) : [];
        if (selectedUserPosition >= SystemTypes.UserPositionType.Teacher) {
            userTypes = userTypes.filter(x => x.Id >= SystemTypes.UserType.UserData_Assocated);
        }
        else if (selectedUserPosition >= SystemTypes.UserPositionType.MasterTeacher) {
            userTypes = userTypes.filter(x => x.Id >= SystemTypes.UserType.AllData_Schools);
        }
        else if (selectedUserPosition >= SystemTypes.UserPositionType.SchoolAdmin) {
            userTypes = userTypes.filter(x => x.Id >= SystemTypes.UserType.AllData_Schools);
        }
        else if (selectedUserPosition >= SystemTypes.UserPositionType.DistrictAdmin) {
            userTypes = userTypes.filter(x => x.Id >= SystemTypes.UserType.AllData_Districts);
        }
        else if (selectedUserPosition >= SystemTypes.UserPositionType.OrganizationAdmin) {
            userTypes = userTypes.filter(x => x.Id >= SystemTypes.UserType.AllData_Organizations);
        }
        userTypes.sort((a, b) => a.Text.localeCompare(b.Text));
        return userTypes;
    }

    const filterRoleDataSet = () => {
        return roleDataSet;
    }

    const handleOnMergeIntoUser = () => {
        if (onMergeUser) {
            onMergeUser();
        }
    }

    return <div className='control-box-wrapper'>
        {showSuccessMessage &&
            <Message className='alert-message' type="success">User was Successfully Saved</Message>
        }
        {showEmailSentSuccess &&
            <Message className='alert-message' type="success">Email Successfully Sent</Message>
        }
        {!loading ?
            <>
                <div className='control-box box-three-column'>
                    <InputField title="Email (Username)" isDisabled={isEdit} forceValidate={forceValidation} value={userData?.Email} onChange={handleOnChange}
                        groupId={'USER'} placeholder={'Email'} fieldName="Email" validationName="User_Email" />
                    <InputField title="Prefix" forceValidate={forceValidation} value={userData?.HonorificPrefix}
                        onChange={handleOnChange} groupId={'USER'} placeholder={'Prefix (Mr./Ms./Dr.)'} type="text" fieldName="HonorificPrefix" />
                    <InputField title="First Name" forceValidate={forceValidation} value={userData?.FirstName}
                        onChange={handleOnChange} groupId={'USER'} placeholder={'First Name'} type="text" fieldName="FirstName" validationName="User_FirstName" />
                    <InputField title="Last Name" forceValidate={forceValidation} value={userData?.LastName}
                        onChange={handleOnChange} groupId={'USER'} placeholder={'Last Name'} type="text" fieldName="LastName" validationName="User_LastName" />

                    {/*<InputField title="Title" forceValidate={forceValidation} value={userData?.Title} onChange={handleOnChange} groupId={'USER'} placeholder={'Title'} type="text" fieldName="Title" />*/}
                    <InputField title="Phone Number" maxLength={10} forceValidate={forceValidation} value={userData?.PrimaryPhone?.replace('+1', '')}
                        onChange={handleOnChange} groupId={'USER'} placeholder={'Phone Number'} type="text" fieldName="PrimaryPhone" validationName="User_PrimaryPhone" />
                    <InputField title="State ID" forceValidate={forceValidation} value={userData?.StateIdentifier}
                        onChange={handleOnChange} groupId={'USER'} placeholder={'State ID'} type="text" fieldName="StateIdentifier" validationName="User_StateId" />
                    <div style={{ paddingBottom: '2.12em' }}>
                        <div className='title-header'>
                            <div className={`title`}><div>Time Zone</div><div></div>
                            </div>
                        </div>
                        <SelectPicker className={'narrow-dropdown'} searchable={false} onChange={handleOnTimezoneChange}
                            placeholder={'Select a Time Zone'} size='md' styles={styles} value={selectedTimeZone} data={timeZoneData} />
                    </div>
                </div>

                <div className='control-box box-three-column'>
                    <InputField title="Job Title" subTitle={<>This field allows the system to identify the user's job position within the district.</>} className={'narrow-dropdown'} fieldName="UserPosition" onChange={handleOnUserPositionChange} value={selectedUserPosition}>
                        <SelectListControl styles={{ width: '22rem' }} hidePleaseSelect={true} value={selectedUserPosition} textValuePairs={
                            userData.userPositionsData?.filter(x => {
                                if (appUserState?.userProfile?.UserPositionType !== SystemTypes.UserPositionType.Admin) {
                                    return (x.Id >= appUserState?.userProfile?.UserPositionType ?? 999) && x.Id !== SystemTypes.UserPositionType.None && x.Id !== SystemTypes.UserPositionType.Individual;
                                }
                                else {
                                    return x.Id !== SystemTypes.UserPositionType.None;
                                }
                            }) ?? []} />
                    </InputField>
                    {selectedUserPosition >= 10 && <>

                        <div className='title-header'>
                            <div className={`title`}><div>Organization</div></div>
                        </div>
                        <CheckPicker
                            placeholder="Organization"
                            title='Organization'
                            id='Organization'
                            height={320}
                            data={currentOrgDataSet ?? []}
                            onClean={() => onTreeClean('Organization')}
                            onSelect={onMultiCheckSelect}
                            defaultValue={selectedOrgs ?? []}
                            value={selectedOrgs ?? []}
                            placement="rightStart"
                            className="searchWidthFilter multi-select-col"
                        /></>
                    }
                    {/* //    <CheckPicker data={data} groupBy="role" style={{ width: 224 }} /> */}
                    {/* TODO: combine the follow three as a group picker and have the onChange selection update the list.  */}
                    {selectedUserPosition >= 20 && <>
                        <div className='title-header'>
                            <div className={`title`}><div>District</div></div>
                        </div>
                        <CheckPicker
                            placeholder="District"
                            title='District'
                            id='District'
                            height={320}
                            disabled={!selectedOrgs || selectedOrgs?.length === 0}
                            data={currentDistrictDataSet ? [...currentDistrictDataSet, ...districts?.filter(x => selectedDistricts?.includes(x.value) && !selectedOrgs?.includes(x.clientId))] : []}
                            onClean={() => onTreeClean('District')}
                            onSelect={onMultiCheckSelect}
                            defaultValue={selectedDistricts ?? []}
                            value={selectedDistricts ?? []}
                            placement="rightStart"
                            className="searchWidthFilter multi-select-col"
                        /></>}
                    {selectedUserPosition >= 30 && <>
                        <div className='title-header'>
                            <div className={`title`}><div>School</div></div>
                        </div>
                        <CheckPicker
                            placeholder="School"
                            title='School'
                            id='School'
                            height={320}
                            disabled={!selectedDistricts || selectedDistricts?.length === 0}
                            data={currentSchoolDataSet ? [...currentSchoolDataSet, ...schools?.filter(x => selectedSchools?.includes(x.value) && (!selectedOrgs?.includes(x.clientId) || !selectedDistricts?.includes(x.districtId)))] : []}
                            onClean={() => onTreeClean('School')}
                            onSelect={onMultiCheckSelect}
                            defaultValue={selectedSchools ?? []}
                            value={selectedSchools ?? []}
                            placement="rightStart"
                            className="searchWidthFilter multi-select-col"
                        /></>}
                    {selectedUserPosition >= 30 && <>
                        <div className='title-header'>
                            <div className={`title`}><div>Cluster</div></div>
                        </div>

                        <CheckPicker
                            placeholder="Cluster"
                            title='Cluster'
                            id='Cluster'
                            key='Cluster'
                            height={320}
                            disabled={!selectedSchools || selectedSchools?.length === 0}
                            data={currentClusterDataSet ?? []}
                            onClean={() => onTreeClean('Cluster')}
                            onSelect={onMultiCheckSelect}
                            defaultValue={selectedClusters ?? []}
                            value={selectedClusters ?? []}
                            placement="rightStart"
                            className="searchWidthFilter multi-select-col"
                        /></>}

                    {userMgr?.AppUserState?.userProfile?.Roles?.some(x => x == 'SysAdmin' || x == 'NIETAdmin') && <>
                        <InputField title="User Data Access" subTitle={'What level of data acess would you like this user to have?'} className={'narrow-dropdown'} fieldName="UserType" onChange={handleOnUserTypeChange} value={selectedUserType}>
                            <SelectListControl styles={{ width: '22rem' }} hidePleaseSelect={true} value={selectedUserType} textValuePairs={filterUserTypes()} />
                        </InputField>
                        <InputField title="User Role" subTitle={'The system provides acccess to features and functionality based on the selected one or more roles.'} className={'narrow-dropdown'} fieldName="RoleId" onChange={handleOnUserTypeChange}>
                            {(roleDataSet.length > 0 && selectedRoleValues?.length > 0) ? <CheckPicker
                                placeholder="Role Selection"
                                title='Roles'
                                id='RoleId'
                                height={320}
                                data={filterRoleDataSet() ?? []}
                                defaultValue={selectedRoleValues ?? defaultRole}
                                onClean={() => onTreeClean('RoleId')}
                                onSelect={onMultiCheckSelect}
                                placement="autoVerticalStart"
                                className="searchWidthFilter multi-select-col"
                                disabledItemValues={disabledValues}
                            /> : <Loading className='Default' />}
                        </InputField>
                    </>
                    }
                </div>
                <div className='control-box box-three-column'>
                    <InputField title="EE PASS Identifier" subTitle={!userData?.SystemId ? 'EE PASS Identifier will be generated during the creation of the user.' : ''} type={'label'} forceValidate={forceValidation} value={userData?.SystemId}
                        onChange={handleOnChange} groupId={'USER'} placeholder={'EEPassID'} fieldName="EEPassID" validationName="User_EEPassId" />

                    <InputField title="Date Signed TOS" forceValidate={forceValidation} value={dateSignedTOS}
                        onChange={handleOnChange} groupId={'USER'} subTitle={!dateSignedTOS ? 'Digital Signature Required on initial login.' : ''} type="label" fieldName="DateSignedTOS" validationName="User_DateSignedTOS" />
                    <InputField title="Authentication Status" forceValidate={forceValidation} value={userData?.Status ?? (userData?.IsActive ? 'Enabled' : 'Disabled')}
                        onChange={handleOnChange} groupId={'USER'} type="label" fieldName="Status" validationName="User_Status" />

                    {schoolYearUser &&
                        <>
                            <div className='min-num-observ-container'>
                                {selectedUserPosition >= 40 ?
                                    <>
                                        <InputField title="Minimum Number of Teacher Observations Required" forceValidate={forceValidation} value={userData?.NumberOfTeacherEvals}
                                            onChange={handleOnChange} groupId={'USER'} type="label" fieldName="NumberOfTeacherEvals" validationName="User_MinNumTeacherObservations">
                                            <SelectListControl styles={{ width: '22rem' }} hidePleaseSelect={true} value={userData?.NumberOfTeacherEvals} textValuePairs={minNumObservationsOptions} />
                                        </InputField>
                                    </>
                                    :
                                    <>
                                        <InputField title="Minimum Number of Admin Observations Required" forceValidate={forceValidation} value={userData?.NumberOfAdminEvals}
                                            onChange={handleOnChange} groupId={'USER'} type="label" fieldName="NumberOfAdminEvals" validationName="User_MinNumAdminObservations">
                                            <SelectListControl styles={{ width: '22rem' }} hidePleaseSelect={true} value={userData?.NumberOfAdminEvals} textValuePairs={minNumObservationsOptions} />
                                        </InputField>
                                    </>
                                }
                            </div>
                        </>
                    }

                    {(!userData.IsStaged && userMgr?.AppUserState?.userProfile.UserPositionType == SystemTypes.UserPositionType.Admin) && <>
                        <InputField title="Force included in Teacher Payout" subTitle={'Forces a user into the Teacher Payout during the rollover process regardless of the users job title.'} value={(userData?.ForceIncludeInTeacherPayout === undefined || userData?.ForceIncludeInTeacherPayout === null) ? false : userData?.ForceIncludeInTeacherPayout} fieldName="ForceIncludeInTeacherPayout" onChange={handleOnChange} >
                            <CheckboxControl />
                        </InputField>
                        <InputField title="Force included in Administrator Payout" subTitle={'Forces a user into the Administrator Payout during the rollover process regardless of the users job title.'} value={(userData?.ForceIncludeInAdminPayout === undefined || userData?.ForceIncludeInAdminPayout === null) ? false : userData?.ForceIncludeInAdminPayout} fieldName="ForceIncludeInAdminPayout" onChange={handleOnChange} >
                            <CheckboxControl />
                        </InputField>
                    </>
                    }
                    {userMgr?.AppUserState?.userProfile?.Roles?.some(x => x == 'SysAdmin') &&
                        <InputField
                            title="Disable All Emails" value={(userData?.DisableEmails === undefined || userData?.DisableEmails === null) ? false : userData?.DisableEmails}
                            fieldName="DisableEmails"
                            onChange={handleOnChange} >
                            <CheckboxControl />
                        </InputField>
                    }
                    {userData.IsStaged &&
                        <InputField title="Archived?" value={(userData?.IsArchived === undefined || userData?.IsArchived === null) ? true : userData?.IsArchived} fieldName="IsArchived" onChange={handleOnChange} >
                            <CheckboxControl />
                        </InputField>
                    }
                    {!userData.IsStaged &&
                        <InputField title="Active?" value={(userData?.IsActive === undefined || userData?.IsActive === null) ? true : userData?.IsActive} fieldName="IsActive" onChange={handleOnChange} >
                            <CheckboxControl />
                        </InputField>
                    }


                </div>
                <div className={`screen-footer ${isEdit ? 'btn-row' : 'btn-row-create'}`}>
                    {userId && userData && userMgr?.AppUserState?.userProfile.UserPositionType == SystemTypes.UserPositionType.Admin && <div className='screen-footer__btn btn-fit multi-select-col'><ButtonControl loading={saving} type={'cancel'} onClick={handleOnMergeIntoUser}>Merge User</ButtonControl></div>}
                    {userId && !userData.IsStaged &&
                        <>
                            <div className='screen-footer__btn btn-fit multi-select-col'><ButtonControl loading={saving} type={'cancel'} className={'send-password-reset-btn'} onClick={handleOnPasswordReset}>Send Password Reset</ButtonControl></div>
                            <div className='screen-footer__btn btn-fit multi-select-col'><ButtonControl loading={saving} type={'cancel'} onClick={handleOnSendWelcomeEmail}>Send New User Email</ButtonControl></div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                    }

                    <div className='btn-wrapper-right screen-footer__btn multi-select-col'><ButtonControl disabled={saving} type={'cancel'} onClick={onCancel}>Cancel</ButtonControl></div>&nbsp;&nbsp;
                    <div className='btn-wrapper-left screen-footer__btn multi-select-col'><ButtonControl loading={saving} type={'okay'} onClick={handleOnSave}>Save</ButtonControl></div>
                </div>
            </>
            :
            <>
                <div className='user-details-page-loading'><Loading type='default' size={'5rem'} /><h5 style={{ paddingTop: '1.3em' }}>Loading User Profile...</h5></div>
            </>
        }
    </div>
}

export default UserDetails;