import { DialogControl } from '../../../../components/controls/DialogControl';
import React, { useEffect, useState } from 'react';
import { CheckboxControl, TableControl } from '../../../../components/controls';
import ButtonControl from '../../../../components/controls/ButtonControl';
import _ from 'lodash';
import { InputField } from '../../../../components/controls/InputField';

function Likert({ isSurveyTemplateActive, setOpenDialog, openDialog, data, saveLikert, handleOnChange, surveyTemplateItem, deleteId }) {
    const [likertData, setLikertData] = useState(null)
    const [idToDelete, setIdToDelete] = useState([])
    const [editMode, setEditMode] = useState(false)

    useEffect(() => {
        const copyData = [...data]
        const dataValues = _.map(data, x => x.Value)
        for (let i = 0; i < _.max(dataValues); i++) {
            if (!dataValues.includes(i + 1)) {
                copyData.push({
                    Value: i + 1,
                    Title: ""
                })
            }
        }
        setLikertData(_.orderBy(copyData, ['Value'], ['desc']))
    }, [data])

    const columns = [
        {
            header: 'Value',
            dataProp: "Value",
            canSort: true,
            width: '30%'
        },
        {
            header: 'Title  ',
            subHeader: '(Optional)',
            dataProp: 'Title',
            canSort: false,
            width: '40%',
            IsInputField: true
        },
    ]


    const handleLikertState = (value, action, e) => {
        const copyLikertData = [...likertData]
        const likertItem = _.find(likertData, x => x.Value === value.Value)
        const likertItemIndex = _.findIndex(likertData, x => x.Value === value.Value)

        if (action === "INPUT") {
            likertItem['Title'] = e
        }


        if (action === "DELETE") {
            copyLikertData.splice(likertItemIndex, 1)
            if (likertItem?.Id) {
                setIdToDelete([...idToDelete, likertItem.Id])
            }
        } else {
            copyLikertData.splice(likertItemIndex, 1, likertItem)
            likertItem['isEditMode'] = !likertItem.isEditMode
            likertItem['Editing'] = !likertItem.Editing
        }

        const reducedLikert = _.reduce(copyLikertData, (likertList, likert, i) => {
            if (likertItem.Editing && likertItem.isEditMode) {
                likert['Value'] = i + 1
                if (!likert.isEditMode && !likert.Editing) {
                    likert['GrayOutAction'] = true
                }
            } else {
                likert['Value'] = i + 1
                likert['GrayOutAction'] = false
            }
            likertList.push(likert)
            return likertList
        }, [])
        setEditMode(true)
        setLikertData(reducedLikert)
    }

    const disableTableActions = (row, action) => {
        if (action.toLowerCase() == 'edit') {
            return isSurveyTemplateActive;
        }
        else if (action.toLowerCase() == 'delete') {
            return isSurveyTemplateActive;
        }
        else {
            return false;
        }
    }

    return (
        <DialogControl
            openDialog={openDialog}
            title={'Create Likert Scale'}
            onCancel={() => {
                setOpenDialog(false)
                saveLikert(data)
            }}
            onOk={() => {
                saveLikert(likertData)
                setOpenDialog(false)
                setIdToDelete([])
                deleteId(idToDelete)
            }}
            disableOk={editMode || isSurveyTemplateActive}
            disableCancel={editMode}
            okText={'Save'}>

            <div className="survey-template-dialog-content">
                <div className="dialog-content">
                    <TableControl
                        columns={columns}
                        data={likertData}
                        checkDisableAction={disableTableActions}
                        noDataText={"No Likert Scale Found"}
                        onEdit={handleLikertState}
                        onSave={(value) => {
                            handleLikertState(value, "SAVE")
                            setEditMode(false)
                        }}
                        onChangeInput={(dataRecord, action, value) => {
                            handleLikertState(dataRecord, action, value)
                            setEditMode(false)
                        }}
                        onDelete={(value) => {
                            handleLikertState(value, "DELETE")
                            setEditMode(false)
                        }}
                    />
                </div>
                <div className="dialog-footer-wrapper">
                    <ButtonControl disabled={editMode || isSurveyTemplateActive} type={'create'} onClick={() => setLikertData([...likertData, { Value: likertData.length + 1, Title: "" }])}>Add Value</ButtonControl>
                    <div className={"checkbox-wrapper" + (editMode ? " disable" : "")}>
                        <InputField isDisabled={editMode || isSurveyTemplateActive} value={surveyTemplateItem?.IncludeLikertNA} fieldName="IncludeLikertNA" groupId={'SURVEYTEMPLATE'} onChange={handleOnChange} >
                            <CheckboxControl />
                        </InputField>
                        Allow Not Applicable (N/A) Rating
                    </div>
                </div>
            </div>
        </DialogControl>
    );
}
export default React.memo(Likert);