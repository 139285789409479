import { ItemServiceFactory } from '../services/ItemServiceFactory';

import ItemManager from './ItemManager';
import _ from 'lodash';
import { ConstructionOutlined } from '@mui/icons-material';
import SystemTypes from '../SystemTypes';

class OrganizationManager extends ItemManager {
  constructor(schoolYear, globalItemState, globalAuthState, globalAppUserState) {
    super(ItemServiceFactory.ItemServiceEnum.Client, globalAuthState, globalAppUserState, globalItemState,
      (filter, item) => {
        var hasSearch = item.Name?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
        var IsActive = filter?.IsActive === 'UNK' || (filter?.IsActive === undefined || filter?.IsActive === null ? item.IsActive == true : item.IsActive === filter?.IsActive)
        return hasSearch && IsActive;
      });


    this._errorUtil = undefined;
    this._obsConfigMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.ObservationConfig, globalAuthState, globalAppUserState);
    this._observationConfigSvc = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.ObservationConfig, globalAuthState);
    this._rubricSvc = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.Rubric, globalAuthState);
    this._surveyTemplateSvc = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.SurveyTemplate, globalAuthState);
    this._organizationMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Client, globalAuthState, globalAppUserState);
    this._userPositionRubricMap = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.UserPositionRubricMap, globalAuthState);
    this._userPositionSurveyTemplateMap = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.UserPositionSurveyTemplateMap, globalAuthState);
    this._userPositionAlternativeMap = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.UserPositionAlternativeMap, globalAuthState);
    this._schoolYear = schoolYear
    this._setOrgTree = (value) => globalItemState.merge({ OrgTree: value });
    this._setOrgUserTree = (value) => globalItemState.merge({ OrgUserTree: value });
    this._setRubricList = (value) => globalItemState.merge({ RubricList: value });
    this._setObsRubricList = (value) => globalItemState.merge({ ObsRubricList: value });
  }

  get OrganizationManager() {
    return this._organizationMgr;
  }

  get FullOrgUserTree() {
    return this._globalItemState?.get({ noproxy: true })?.OrgUserTree;
  }

  get FullOrgTree() {
    return this._globalItemState?.get({ noproxy: true })?.OrgTree;
  }

  get RubricList() {
    return this._globalItemState.get({ noproxy: true })?.RubricList
  }

  get ObsRubricList() {
    return this._globalItemState.get({ noproxy: true })?.ObsRubricList
  }

  findSelected(vals) {
    let newTreeIds = [];
    vals.forEach(val => {
      newTreeIds.push(val);
      let branch = this.FullOrgUserTree ? this?.FullOrgUserTree?.find(t => t.Id == val) : this.FullOrgTree?.find(t => t.Id == val)
      if (!branch && val.indexOf('u-') === -1) {
        branch = this?.findInTree(val);
      }
      if (branch && branch.Children) {
        branch.Children.forEach(c => {
          newTreeIds.push(c.Id);
          if (c.Children) {
            c.Children?.forEach(c1 => {
              newTreeIds.push(c1.Id);
              if (c1.Children) {
                c1.Children?.forEach(c2 => {
                  newTreeIds.push(c2.Id);
                });
              }
            });
          }
        });
      }
    });

    return newTreeIds;
  }

  findInTree(id) {
    let result;
    (this.FullOrgUserTree ?? this.FullOrgTree).forEach(branch => {
      if (!result && branch && branch.Id !== id && branch.Children) {
        result = branch.Children.find(x => x.Id == id);
        if (!result) {
          branch.Children.forEach(c => {
            if (c.Children) {
              result = c.Children.find(x => x.Id == id);
              if (!result) {
                c.Children.forEach(c1 => {
                  if (c1.Children) {
                    result = c1.Children.find(x => x.Id == id);
                  }
                });
              }
            }
          });
        }
      }
      else if (branch.Id == id) {
        result = branch;
      }
    });

    return result;
  }

  async getTeacherRubrics(clientId, ignoreSqlInclude = false, sqlIncludes, currentRubricId) {
    const schoolYear = this._schoolYear;
    const listResult = await this._rubricSvc.list(
      this.buildJSONObjFilter("x => x.RubricType == 1 || x.RubricType == 2 || x.RubricType == 3 && (x.SchoolYearIdentifier == schoolYear || x.SchoolYearIdentifier == null) && (x.ClientId == clientId || x.ClientId == null) && x.IsDeleted == false && x.IsActive == true", { schoolYear, clientId }),
      ignoreSqlInclude, sqlIncludes);

    if (currentRubricId && listResult.first().Success && !listResult.first().Items.some(x => x.Id == currentRubricId)) {
      const currentRubricResult = await this._rubricSvc.list(
        this.buildJSONObjFilter("x => x.Id == currentRubricId", { currentRubricId }),
        ignoreSqlInclude, sqlIncludes)

      if (currentRubricResult.first().Success && currentRubricResult.first().Items?.first()) {
        listResult.first().Items.unshift(currentRubricResult.first().Items?.first());
      }
    }

    return listResult;
  }

  async getAdminRubrics(clientId, ignoreSqlInclude, sqlIncludes) {
    const schoolYear = this._schoolYear;

    return await this._rubricSvc.list(
      this.buildJSONObjFilter("x => x.RubricType == 4 || x.RubricType == 5 || x.RubricType == 7 && (x.SchoolYearIdentifier == schoolYear || x.SchoolYearIdentifier == null) && (x.ClientId == clientId || x.ClientId == null) && x.IsDeleted == false && x.IsActive == true", { schoolYear, clientId }),
      ignoreSqlInclude, sqlIncludes)
  }

  async saveObservationConfig(item) {
    if (item.wasEdited) {
      const observationConfigCopy = { ...item }
      observationConfigCopy.UserPositionRubricMap = undefined
      observationConfigCopy.UserPositionSurveyTemplateMap = undefined
      observationConfigCopy.UserPositionAlternativeMap = undefined
      observationConfigCopy.wasEdited = undefined
      observationConfigCopy.SchoolYearIdentifier = this._schoolYear
      observationConfigCopy.CertificationUserPositionsAdminData = undefined
      observationConfigCopy.CertificationUserPositionsTeacherData = undefined
      observationConfigCopy.CertificationAdminEmailsData = undefined
      observationConfigCopy.CertificationTeacherEmailsData = undefined
      return await this._observationConfigSvc.save(observationConfigCopy).then(async (r) => {
        const saveUserPositionRubricMap = item.UserPositionRubricMap.reduce((saveBox, x) => {
          x.ObservationConfigId = r.first().Items.first().Id
          if (!item.Id) {
            x.Id = undefined
            x.UniqueId = undefined
            x._id = undefined
          }
          saveBox.push(this._userPositionRubricMap.save(x))
          return saveBox
        }, [])
        const saveUserPositionSurveyTemplateMap = item.UserPositionSurveyTemplateMap.reduce((saveBox, x) => {
          x.ObservationConfigId = r.first().Items.first().Id
          if (!item.Id) {
            x.Id = undefined
            x.UniqueId = undefined
            x._id = undefined
          }
          saveBox.push(this._userPositionSurveyTemplateMap.save(x))
          return saveBox
        }, [])
        const saveUserPositionAlternativeMap = item.UserPositionAlternativeMap.reduce((saveBox, x) => {
          x.ObservationConfigId = r.first().Items.first().Id
          if (!item.Id) {
            x.Id = undefined
            x.UniqueId = undefined
            x._id = undefined
          }
          saveBox.push(this._userPositionAlternativeMap.save(x))
          return saveBox
        }, [])
        const result = await Promise.all([saveUserPositionRubricMap, saveUserPositionSurveyTemplateMap, saveUserPositionAlternativeMap]).then(async (userResults) => {
          const saveUserPositionRubricMapResult = await Promise.all(userResults[0]).then(userRes => { return _.map(userRes, y => { return y?.first().Items.first() }) })
          const saveUserPositionSurveyTemplateMapResult = await Promise.all(userResults[1]).then(userRes => { return _.map(userRes, y => { return y?.first().Items.first() }) })
          const saveUserPositionAlternativeMapResult = await Promise.all(userResults[2]).then(userRes => { return _.map(userRes, y => { return y?.first().Items.first() }) })
          return [saveUserPositionRubricMapResult, saveUserPositionSurveyTemplateMapResult, saveUserPositionAlternativeMapResult]
        })
        if (r.first().Success) {
          const copiedSaveResults = { ...r.first().Items.first() }
          copiedSaveResults.UserPositionRubricMap = result[0]
          copiedSaveResults.UserPositionSurveyTemplateMap = result[1]
          copiedSaveResults.UserPositionAlternativeMap = result[2]
          
          const observationObj = copiedSaveResults.SchoolId ? { oId: copiedSaveResults.ClientId, 
                                                                dId: copiedSaveResults.DistrictId, 
                                                                sId: copiedSaveResults.SchoolId, 
                                                                sy: copiedSaveResults.SchoolYearIdentifier,
                                                                updateusers: true } :

                                copiedSaveResults.DistrictId ? { oId: copiedSaveResults.ClientId, 
                                                                  dId: copiedSaveResults.DistrictId, 
                                                                  sy: copiedSaveResults.SchoolYearIdentifier,
                                                                  updateusers: true } :

                                { oId: copiedSaveResults.ClientId, sy: copiedSaveResults.SchoolYearIdentifier, updateusers: true };

          await this._obsConfigMgr.runOperation('ObservationConfig', undefined, undefined, observationObj);
          
          return copiedSaveResults
        } else {
          console.log('failed to save observationConfig.')
        }
      })
    }
  }

  async deleteOrg(item) {
    var id = item.Id;
    await this.runOperation("DeleteOrgTree", id, null, { depth: 'org' }, false, false);
    return await this.deleteItem(item);
  }

  async deleteDistrict(item) {
    var id = item.Id;
    return await this.runOperation("DeleteOrgTree", id, null, { depth: 'district' }, false, false);
  }

  async deleteSchool(item) {
    var id = item.Id;
    return await this.runOperation("DeleteOrgTree", id, null, { depth: 'school' }, false, false);
  }

  async getObservationConfigByOrg(orgId) {
    const schoolId = null;
    const districtId = null;
    const observationObj = schoolId ? { oId: orgId, dId: districtId, sId: schoolId, sy: this.AppUserState.selectedSchoolYear } :
      districtId ? { oId: orgId, dId: districtId, sy: this.AppUserState.selectedSchoolYear } :
        orgId ? { oId: orgId, sy: this.AppUserState.selectedSchoolYear } :
          { sy: this.AppUserState.selectedSchoolYear }
    return await this._obsConfigMgr.runOperation('ObservationConfig', undefined, undefined, observationObj);
  }


  async getSurveyTemplates(clientId, surveyType) {
    return await this._surveyTemplateSvc.list(
      this.buildJSONObjFilter("x => x.SurveyType == surveyType && (x.ClientId == clientId || x.ClientId == null) && x.IsDeleted == false && x.IsActive == true", { surveyType, clientId }), true
    )
  }

  UsersOnly(groupType, groupId) {
    const users = [];

    _.each(groupType === 'org' ? _.filter([...this.FullOrgUserTree], org => org.OrgId === groupId) : [...this.FullOrgUserTree] ?? [], o => {
      _.each(groupType === 'district' ? _.filter(o.Children, district => district.DistrictId === groupId) : o.Children, d => {
        if (d.NodeType.toLowerCase() === "user") {
          users.push(d);
        }
        _.each(groupType === 'school' ? _.filter(d.Children, school => school.SchoolId === groupId) : d.Children ?? [], s => {
          if (s.NodeType.toLowerCase() === "user") {
            users.push(s);
          }
          _.each(_.sortBy(s.Children, x => x.LastName) ?? [], c => {
            if (c.NodeType.toLowerCase() === "user") {
              users.push(c);
            }
          });
        });
      });
    });
    return users;
  }

  getAllSchools() {
    const schools = [];
    _.each(_.sortBy(this.FullOrgUserTree, x => x.Name) ?? [], o => {
      _.each(_.sortBy(o.Children, x => x.Name), d => {
        if (d.NodeType.toLowerCase() === "district") {
          _.each(_.sortBy(d.Children, x => x.Name) ?? [], s => {
            if (s.NodeType.toLowerCase() === "school") {
              schools.push(s)
            }
          });
        }
      });
    });
    return schools;
  }

  SchoolUsersOnly(schoolId, userPositionStart, userPositionEnd) {
    return this.GetSchoolUsersOnly(this.FullOrgUserTree, schoolId, userPositionStart, userPositionEnd);
  }

  GetSchoolUsersOnly(tree, schoolId, userPositionStart, userPositionEnd) {
    const users = [];
    _.each(_.sortBy(tree, x => x.Name) ?? [], o => {
      _.each(o.Children, d => {
        _.each(_.sortBy(_.filter(d.Children ?? [], dc => dc.SchoolId === schoolId), x => x.Name), s => {
          _.each(_.sortBy(s.Children, x => x.LastName) ?? [], c => {
            if (c.NodeType.toLowerCase() === "user") {
              if (c.UserPositionType >= userPositionStart && c.UserPositionType <= userPositionEnd) {
                users.push(c);
              }
            }
          });
        });
      });
    });
    return users;
  }

  LoadObserverOnly(orgId, districtId, schoolId, isTeacher) {
    const users = [];
    _.each(_.sortBy(_.filter(this.FullOrgUserTree ?? [], ou => ou.OrgId === orgId), x => x.Name), o => {
      _.each(_.sortBy(_.filter(o.Children ?? [], oc => oc.DistrictId === districtId), x => x.Name), d => {
        _.each(_.sortBy(_.filter(d.Children ?? [], du => du.UserId !== null && du.NodeType.toLowerCase() === "user"), x => x.Name), du => {
          if ((isTeacher && (du.IsCertifiedTeacher)) || (!isTeacher && (du.IsCertifiedSchoolAdmin))) {
            users.push(du);
          }
        })

        _.each(_.sortBy(_.filter(d.Children ?? [], dc => dc.SchoolId === schoolId), x => x.Name), s => {
          if (s.NodeType.toLowerCase() === "user") {
            if ((isTeacher && (s.IsCertifiedTeacher)) || (!isTeacher && (s.IsCertifiedSchoolAdmin))) {
              users.push(s);
            }
          }
          _.each(_.sortBy(s.Children, x => x.LastName) ?? [], c => {

            if (c.NodeType.toLowerCase() === "user") {
              if ((isTeacher && (c.IsCertifiedTeacher)) || (!isTeacher && (c.IsCertifiedSchoolAdmin))) {
                users.push(c);
              }
            }
          });
        });
      });
    });
    return users;
  }

  OrgsOnly() {
    const allOrgs = [];
    _.each(this.FullOrgTree ?? [], o => {
      if (o.Name) {
        allOrgs.push({ ...o, Children: null });
      }
    });

    return _.sortBy(allOrgs, x => x.Name);
  }

  DistrictTree(orgId) {
    let districts = [];
    if (orgId) {
      districts = _.find([...this.FullOrgTree ?? []], x => x.Id === `o-${orgId}`)?.Children;
    }
    else {
      _.each(_.sortBy([...this.FullOrgTree ?? []], x => x.Name) ?? [], o => {
        _.each(_.sortBy(o.Children, x => x.Name) ?? [], c => {
          districts.push(c)
        });
      });
    }

    return districts;
  }

  DistrictsOnly(orgId) {
    const districts = this.DistrictTree(orgId);
    return _.map(districts ?? [], d => { return { ...d, Children: null } });
  }

  SchoolsTree(districtId) {
    const schools = [];
    if (districtId) {
      _.each(this.FullOrgTree ?? [], o => {
        const districts = _.filter(_.sortBy(o.Children, x => x.Name) ?? [], d => d.Id === `d-${districtId}`);
        if (districts?.length > 0) {

          _.each(_.sortBy(districts, x => x.Name) ?? [], d => _.each(_.sortBy(d.Children, x => x.Name) ?? [], s => schools.push(s)));
        }
      });
    }
    else {
      _.each(this.FullOrgTree ?? [], o => {
        _.each(_.sortBy(o.Children, x => x.Name), d => _.each(_.sortBy(d.Children, x => x.Name) ?? [], s => schools.push(s)));
      });
    }

    return schools;
  }

  SchoolsOnly(districtId) {
    const schools = this.SchoolsTree(districtId);
    return _.sortBy(_.map(schools ?? [], o => { return { ...o, Children: undefined } }), x => x.Name);
  }
  async loadOnlyOrg() {
    let orgTree;
    if (!this.FullOrgTree) {
      const results = await this._itemService.getOperation('UserOrgTree', undefined);

      const r = results?.first();
      if (r?.Success) {
        this._setOrgTree(r.Items);
        orgTree = r.Items;
      }
    }
    else {
      orgTree = this.FullOrgTree;
    }

    return orgTree;
  }

  async loadOrgTree(isActive, includeEmpty) {
    let orgTree;
    const emptyO = {
      "OrgId": null,
      "DistrictId": null,
      "SchoolId": null,
      "UserId": null,
      "Name": "--No Organization--",
      "Email": null,
      "FullName": "No Organization",
      "NodeType": "Org",
      "UserPositionType": 0,
      "IsCertifiedTeacher": false,
      "IsCertifiedSchoolAdmin": false,
      "Children": null,
      "_id": "o-",
      "Id": "o-",
      "UniqueId": "o-",
      "ClientId": null,
      "IsDeleted": false,
      "visible": true,
      "refKey": "String_o-"
    };

    const emptyD = {
      "OrgId": null,
      "DistrictId": null,
      "SchoolId": null,
      "UserId": null,
      "Name": "--No District--",
      "Email": null,
      "FullName": "No District",
      "NodeType": "District",
      "UserPositionType": 0,
      "IsCertifiedTeacher": false,
      "IsCertifiedSchoolAdmin": false,
      "Children": null,
      "_id": "d-",
      "Id": "d-",
      "UniqueId": "d-",
      "ClientId": null,
      "IsDeleted": false,
      "visible": true,
      "refKey": "String_d-"
    };

    const emptyS = {
      "OrgId": null,
      "DistrictId": null,
      "SchoolId": null,
      "UserId": null,
      "Name": "--No School--",
      "Email": null,
      "FullName": "No School",
      "NodeType": "School",
      "UserPositionType": 0,
      "IsCertifiedTeacher": false,
      "IsCertifiedSchoolAdmin": false,
      "Children": null,
      "_id": "s-",
      "Id": "s-",
      "UniqueId": "s-",
      "ClientId": null,
      "IsDeleted": false,
      "visible": true,
      "refKey": "String_s-"
    };

    isActive = (isActive == undefined || isActive == null) ? 1 : ((isActive == 1 || isActive == true) ? 1 : 0);
    const results = await this._itemService.getOperation('UserOrgTree', undefined, { depth: "school", isActive });
    const r = results?.first();
    if (r?.Success) {
      orgTree = r.Items;
      if (includeEmpty) {
        orgTree.unshift(emptyS);
        orgTree.unshift(emptyD);
        orgTree.unshift(emptyO);
      }
      this._setOrgTree(orgTree);
    }

    return orgTree;
  }

  async loadOrgTreeWithPayouts(includePayout) {
    let orgTree;
    const results = await this._itemService.getOperation('UserOrgTree', undefined, { depth: includePayout, isActive: 1, includePayout });
    const r = results?.first();
    if (r?.Success) {
      orgTree = r.Items;
      this._setOrgTree(orgTree);
    }

    return orgTree;
  }

  async loadOrgDistrictTree(isActive) {
    let orgTree;
    isActive = (isActive == undefined || isActive == null) ? 1 : ((isActive == 1 || isActive == true) ? 1 : 0);
    const results = await this._itemService.getOperation('UserOrgTree', undefined, { depth: "district", isActive });
    const r = results?.first();
    if (r.Success) {
      return r.Items;
    }
    else {
      return null;
    }
  }

  async loadOrgUserTree(sid, forceUpdate) {
    let orgTree;
    if (!this.FullOrgUserTree || forceUpdate) {
      const payload = sid ? { depth: "user", sid } : { depth: "user" }
      const results = await this._itemService.getOperation('UserOrgTree', undefined, payload);
      const r = results?.first();
      if (r?.Success) {
        this._setOrgUserTree(r.Items);
        orgTree = r.Items;
      }
    }
    else {
      orgTree = this.FullOrgUserTree;
    }

    return orgTree;
  }

  async loadOrgUserSubTree(id, idDepth) {
    let orgTree = [];
    const results = await this._itemService.getOperation('UserOrgTree', undefined, { depth: "user", idDepth, id });
    const r = results?.first();
    if (r?.Success) {
      this._setOrgUserTree(r.Items);
      orgTree = r.Items;
    }
    return orgTree;
  }

  clearRubricLists() {
    this._setObsRubricList(null);
    this._setRubricList(null);
  }

  async loadRubricList(orgId, did, sid, obsOnly) {
    let params = { did, sid, sy: this.AppUserState.selectedSchoolYear };
    let rubricList = [];

    if (obsOnly) {
      params.g = 'observation';
      rubricList = await this.ObsRubricList;
    }
    else {
      rubricList = await this.RubricList;
    }

    if (!rubricList) {
      if (did && sid && orgId) {
        const results = await this._itemService.getOperation('ClientRubricList', orgId, params);
        const r = results?.first();
        if (r?.Success) {
          if (obsOnly)
            this._setObsRubricList(r.Items);
          else
            this._setRubricList(r.Items);

          rubricList = r.Items;
        }
      }
    }

    return rubricList;
  }



  get DefaultOrgItem() {
    return {

    };
  }

  get DefaultDistrictItem() {
    return {
    };
  }

  get DefaultSchoolItem() {
    return {
    };
  }
}

export default OrganizationManager;