import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import NotificationDetails from '../components/notification/details';
import NotificationFilter from '../components/notification/filter';
import NotificationTable from '../components/notification/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useNotificationManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';

const NotificationMgmt = () => {
    const notificationMgr = useNotificationManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);

    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (notificationMgr && !params?.notificationId) {
            setLoading(true);
            notificationMgr.search().then(fb => {
                setManageMode('list');
                setLoading(false);
            });

        }
        else if (!selectedNotification && params?.notificationId && notificationMgr) {
            setLoading(true);
            if (params?.notificationId.toLowerCase() === 'addnew') {
                setSelectedNotification(notificationMgr.DefaultItem);
                setManageMode('view');
                setLoading(false);
            }
            else {
                notificationMgr.get(params?.notificationId).then((r) => {
                    if (r.Success) {
                        setManageMode('view');
                        setSelectedNotification(r.Items.first());
                    }
                    setLoading(false);
                });
            }
        }
    }, [notificationMgr, params?.notificationId]);

    const onAddNew = () => {
        setSelectedNotification(notificationMgr.DefaultItem);
        setManageMode('view');
        navigate('/admin/notification/addnew');
    }

    const handleOnFilterChange = async (field, value) => {

        const notificationFilter = notificationMgr.Filter;
        const updatedNotificationFilter = { ...notificationFilter };
        updatedNotificationFilter[field] = value !== '' ? value : null;
        await notificationMgr.filterData(updatedNotificationFilter);
    }

    const handleOnSave = async (item) => {
        var result = await notificationMgr.saveItem(item);
        if (result.Success) {
            setSelectedNotification(null);
            navigate('/admin/notification')
        }
        return result;

    }

    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        notificationMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.Notification}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnEdit = (item) => {
        setSelectedNotification(item);
        setManageMode('view');
        navigate(`/admin/notification/${item.Id}`);
    }


    const handleOnCancel = () => {
        navigate('/admin/notification');
        if (notificationMgr.FilteredData) {
            setManageMode('list');
        }
        else {
            setManageMode(null);
        }
        setLoadingError(null);
        setErrors(null);
    }

    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete ${itemToDelete?.Title}? `} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <MainLayout errors={errors} validationErrors={validationErrors} >
            {manageMode === 'view' &&
                <div className='screen-header'>
                    <h3>Notification Management</h3>
                    <h5>{params.notificationId === 'addnew' ? 'Add Notification' : 'Edit Notification'}</h5>
                </div>
            }
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <NotificationFilter notificationMgr={notificationMgr} onFilterChange={handleOnFilterChange} />
                            <NotificationTable filteredItems={notificationMgr?.FilteredData ?? []} onEdit={handleOnEdit} onDelete={(item) => { setItemToDelete(item); }} />
                        </div>
                        <div className='screen-footer list'>
                            <ButtonControl type={"create"} onClick={onAddNew}>Create Notification</ButtonControl>
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&
                    <NotificationDetails notificationMgr={notificationMgr} notification={selectedNotification} onCancel={handleOnCancel} onSave={handleOnSave} onLoading={setLoading} onErrors={setErrors} onValidationErrors={setValidationErrors} onLoadingError={setLoadingError} />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default NotificationMgmt;