import React, { useState, useRef, useEffect } from 'react';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppUserManager, useClusterManager, useDistrictManager, useOrgManager, useSchoolManager } from '../../../hooks/useManagers';
import './sidebarMenu.scss';
import { useCallback } from 'react';
import { Input } from 'rsuite';

const SidebarMenu = ({ onSidebarToggle }) => {
  const menuOne = useRef();
  const menuTwo = useRef();
  const menuThree = useRef();
  const menuFour = useRef();
  const menuFive = useRef();
  const menuSix = useRef();
  const menuSeven = useRef();
  const authMgr = useAppUserManager();
  const permissions = authMgr?.GetGlobalState()?.permissions ?? [];
  const userProfile = authMgr?.GetGlobalState()?.userProfile;
  const observationConfig = authMgr?.GetGlobalState()?.observationConfig;
  const navigate = useNavigate();

  // default open/close state of the menus inside the sidebar
  const [activeMenu, setActiveMenu] = useState(null);
  const [contentLibraryMenu, setContentLibraryMenu] = useState(false);
  const [observationMenu, setObservationMenu] = useState(false);
  const [surveyMenu, setSurveyMenu] = useState(false)
  const [certificationMenu, setCertificationMenu] = useState(false)
  const [adminMenu, setAdminMenu] = useState(false);
  const [devMenu, setDevMenu] = useState(false);
  const [reportsMenu, setReportsMenu] = useState(false);
  const [contentLibraryHeight, setContentLibaryHeight] = useState(999);
  const [surveyHeight, setSurveyHeight] = useState(999);
  const [observationHeight, setObservationHeight] = useState(999);
  const [certificationHeight, setCertificationHeight] = useState(999);
  const [reportsHeight, setReportsHeight] = useState(999);
  const [adminHeight, setAdminHeight] = useState(999);
  const [devHeight, setDevHeight] = useState(999);

  const orgMgr = useOrgManager();
  const districtMgr = useDistrictManager();
  const schoolMgr = useSchoolManager();
  const clusterMgr = useClusterManager();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname?.toLowerCase().includes('/admin')) {
      setAdminMenu(true);
      setContentLibraryMenu(false);
      setCertificationMenu(false);
      setObservationMenu(false);
      setDevMenu(false)
      setSurveyMenu(false);
      setReportsMenu(false);

    }
    else if (pathname?.toLowerCase().includes('/observation')) {
      setAdminMenu(false);
      setContentLibraryMenu(false);
      setCertificationMenu(false);
      setObservationMenu(true);
      setDevMenu(false);
      setSurveyMenu(false);
      setReportsMenu(false);
    }
    else if (pathname?.toLowerCase().includes('/survey')) {
      setAdminMenu(false);
      setContentLibraryMenu(false);
      setObservationMenu(false);
      setDevMenu(false);
      setSurveyMenu(true);
      setReportsMenu(false);
    } else if (pathname?.toLowerCase().includes('/certifications')) {
      setAdminMenu(false);
      setContentLibraryMenu(false);
      setCertificationMenu(true)
      setObservationMenu(false);
      setDevMenu(false);
      setSurveyMenu(false);
      setReportsMenu(false);
    }
    else if (pathname?.toLowerCase().includes('/dev')) {
      setAdminMenu(false);
      setContentLibraryMenu(false);
      setCertificationMenu(true)
      setObservationMenu(false);
      setDevMenu(true);
      setSurveyMenu(false);
      setReportsMenu(false);
    }
    else if (pathname?.toLowerCase().includes('/reports')) {
      setAdminMenu(false);
      setContentLibraryMenu(false);
      setCertificationMenu(true)
      setObservationMenu(false);
      setDevMenu(false);
      setSurveyMenu(false);
      setReportsMenu(true);
    }
    else {
      setContentLibraryMenu(true);
    }

  }, [pathname]);

  useEffect(() => {
    if (pathname.toLowerCase().includes("/organization") || pathname.toLowerCase().includes("/district") || pathname.toLowerCase().includes("/school") || pathname.toLowerCase().includes("/cluster")) {
      orgMgr?.filterData({});
      districtMgr?.filterData({});
      schoolMgr?.filterData({});
      clusterMgr?.filterData({});
    }
  }, [orgMgr, districtMgr, schoolMgr, clusterMgr, pathname]);

  useEffect(() => {
    handleResize();
  }, [menuOne.current, menuTwo.current, menuThree.current, menuFour.current, menuFive.current, menuSeven.current]);

  const handleSidebarToggle = (path) => (e) => {
    navigate(path);
  };

  const handleResize = () => {
    if (menuOne.current) {
      setContentLibaryHeight(menuOne.current.scrollHeight);
    }
    if (menuTwo.current) {
      setCertificationHeight(menuTwo.current.scrollHeight);
    }
    if (menuThree.current) {
      setObservationHeight(menuThree.current.scrollHeight);
    }
    if (menuFour.current) {
      setSurveyHeight(menuFour.current.scrollHeight);
    }
    if (menuFive.current) {
      setReportsHeight(menuFive.current.scrollHeight);
    }
    if (menuSix.current) {
      setAdminHeight(menuSix.current.scrollHeight);
    }
    if (menuSeven.current) {
      setDevHeight(menuSeven.current.scrollHeight);
    }
  }

  const getOptionClass = (optionName, defaultClass) => {
    if ((pathname.toLowerCase() === '/admin/content' || pathname.toLowerCase().startsWith('/admin/content/')) && optionName.toLowerCase() == '/admin/content') {
      return `${defaultClass} active`;
    }
    else if ((pathname.toLowerCase() === '/admin/school' || pathname.toLowerCase().startsWith('/admin/school/')) && optionName.toLowerCase() == '/admin/school') {
      return `${defaultClass} active`;
    }
    else if (pathname.toLowerCase() !== '/' &&
      !(pathname.toLowerCase() === '/admin/content' || pathname.toLowerCase().startsWith('/admin/content/')) &&
      !(pathname.toLowerCase() === '/admin/school' || pathname.toLowerCase().startsWith('/admin/school/'))
    ) {
      if (optionName.toLowerCase().startsWith(pathname.toLowerCase())) {
        return `${defaultClass} active`;
      }
    }
    return defaultClass;
  };
  return (
    <>
      <div className="sidebar-navigation">
        <h1 className={'nav-link'} onClick={() => { navigate('/') }}>Home</h1>
        {authMgr?.AccessCheck([permissions?.MyDashboard]) &&
          <h1 className={getOptionClass('/my-dashboard', 'nav-link')} onClick={() => { navigate('/my-dashboard') }}>Dashboard</h1>
        }
        {authMgr?.AccessCheck([permissions?.Administration, permissions?.Subscription, permissions?.Transaction, permissions?.NavApplicationAdminMgmt]) &&
          <h1 className={getOptionClass('/admin/Payment', 'nav-link')} onClick={() => navigate('/admin/Payment')}>Subscription Examples</h1>
        }
        {authMgr?.AccessCheck([permissions?.NavAnalytics]) &&
          <h1 className={getOptionClass('/analytics', 'nav-link')} onClick={() => navigate('/analytics')}>Analytics</h1>
        }
        {permissions && authMgr &&
          <ul>
            {authMgr.AccessCheck([permissions?.ContentLibrary]) && <>
              <li>
                <div className={'groupOption'}>
                  <span onClick={() => { setContentLibraryMenu(!contentLibraryMenu); setActiveMenu('contentlibrary'); }} className="menuOptions hasHover nav-link">Content Library</span>
                  <span onClick={() => { setContentLibraryMenu(!contentLibraryMenu); setActiveMenu('contentlibrary'); }} className={contentLibraryMenu ? "sidebar-icon-indicator" : "sidebar-icon-indicator active"}>
                    <ChevronRightTwoToneIcon />
                  </span>
                </div>
              </li>
              <li ref={menuOne} style={{ maxHeight: contentLibraryMenu ? `${contentLibraryHeight}px` : "0px" }}>
                {authMgr?.AccessCheck([permissions?.SearchContent, permissions?.ContentLibrary]) &&
                  <div
                    onClick={handleSidebarToggle('/content')}
                    className={'/content' == pathname.toLowerCase() ? 'groupSubOption active' : 'groupSubOption'}
                  >
                    <span className="menuSubOptions">Search Content</span>
                  </div>
                }
                {authMgr?.AccessCheck([permissions?.SearchShareContent, permissions?.ContentLibrary]) &&
                  <div
                    onClick={handleSidebarToggle('/share')}
                    className={getOptionClass('/share', 'groupSubOption')}
                  >
                    <span className="menuSubOptions">Shared Content</span>
                  </div>
                }
              </li>
            </>
            }
            {
              //add perms bakc
              (authMgr?.AccessCheck([permissions?.Certification, permissions?.Certifications, permissions?.NavTeacherCertification]) && observationConfig.CertificationUserPositionsTeacher.includes(userProfile.UserPositionType) ||
                authMgr?.AccessCheck([permissions?.Certification, permissions?.Certifications, permissions?.NavAdminCertification]) && observationConfig.CertificationUserPositionsAdmin.includes(userProfile.UserPositionType)) && <>
                <li>
                  <div className={'groupOption'}>
                    <span onClick={() => setCertificationMenu(!certificationMenu)} className="menuOptions hasHover nav-link">
                      Certifications
                    </span>
                    <span onClick={() => setCertificationMenu(!certificationMenu)} className={certificationMenu ? "sidebar-icon-indicator" : "sidebar-icon-indicator active"}>
                      <ChevronRightTwoToneIcon />
                    </span>
                  </div>
                </li>

                <li ref={menuTwo} style={{ maxHeight: certificationMenu ? `${certificationHeight}px` : "0px" }}>
                  {(authMgr?.AccessCheck([permissions?.Certification, permissions?.NavTeacherCertification, permissions?.Certifications]) && observationConfig.CertificationUserPositionsTeacher.includes(userProfile.UserPositionType) || authMgr?.AccessCheck([permissions?.NavApplicationAdminMgmt, permissions?.Certification, permissions?.NavTeacherCertification, permissions?.Certifications])) &&
                    //Add a check for permissions to either return no userProfileId if you're an admin.
                    <div
                      onClick={handleSidebarToggle(`/certifications/teacher/`)}
                      className={getOptionClass('/certifications/teacher', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Teacher Evaluator</span>
                    </div>
                  }
                  {(authMgr?.AccessCheck([permissions?.Certification, permissions?.NavAdminCertification, permissions?.Certifications]) && observationConfig.CertificationUserPositionsAdmin.includes(userProfile.UserPositionType) || authMgr?.AccessCheck([permissions?.NavApplicationAdminMgmt, permissions?.Certification, permissions?.NavAdminCertification, permissions?.Certifications])) &&
                    <div
                      onClick={handleSidebarToggle(`/certifications/administrator/`)}
                      className={getOptionClass('/certifications/administrator', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Administrator Evaluator</span>
                    </div>
                  }
                </li>
              </>
            }

            {
              (authMgr?.AccessCheck([permissions?.Observation, permissions?.Survey, permissions?.NavPrincipalObservation]) ||
                authMgr?.AccessCheck([permissions?.Observation, permissions?.Survey, permissions?.NavTeacherObservation])) && <>
                <li>
                  <div className={'groupOption'}>
                    <span onClick={() => setObservationMenu(!observationMenu)} className="menuOptions hasHover">
                      Observations
                    </span>
                    <span onClick={() => setObservationMenu(!observationMenu)} className={observationMenu ? "sidebar-icon-indicator" : "sidebar-icon-indicator active"}>
                      <ChevronRightTwoToneIcon />
                    </span>
                  </div>
                </li>
                <li ref={menuThree} style={{ maxHeight: observationMenu ? `${observationHeight}px` : "0px" }}>
                  {authMgr?.AccessCheck([permissions?.Observation, permissions?.Survey, permissions?.NavTeacherObservation]) &&
                    <div
                      onClick={handleSidebarToggle('/Observation/teacher')}
                      className={getOptionClass('/observation/teacher', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Teacher Observations</span>
                    </div>
                  }
                  {authMgr?.AccessCheck([permissions?.Observation, permissions?.Survey, permissions?.NavPrincipalObservation]) &&
                    <div
                      onClick={handleSidebarToggle('/Observation/SchoolAdmin')}
                      className={getOptionClass('/observation/SchoolAdmin', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">School Admin Observations</span>
                    </div>
                  }
                </li>
              </>
            }


            {
              (authMgr?.AccessCheck([permissions?.Survey, permissions?.NavGeneralSurvey]) ||
                authMgr?.AccessCheck([permissions?.Survey, permissions?.NavAdministratorSurvey]) ||
                authMgr?.AccessCheck([permissions?.Survey, permissions?.NavTeacherSurvey])) && <>
                <li>
                  <div className={'groupOption'}>
                    <span onClick={() => setSurveyMenu(!surveyMenu)} className="menuOptions hasHover">
                      Surveys
                    </span>
                    <span onClick={() => setSurveyMenu(!surveyMenu)} className={surveyMenu ? "sidebar-icon-indicator" : "sidebar-icon-indicator active"}>
                      <ChevronRightTwoToneIcon />
                    </span>
                  </div>
                </li>
                <li ref={menuFour} style={{ maxHeight: surveyMenu ? `${surveyHeight}px` : "0px" }}>
                  {authMgr?.AppUserState?.HasGeneralSurvey && authMgr?.AccessCheck([permissions?.GeneralSurvey, permissions?.Survey, permissions?.NavGeneralSurvey]) &&
                    <div
                      onClick={handleSidebarToggle('/survey/generalsurvey')}
                      className={getOptionClass('/survey/generalsurvey', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">General Surveys</span>
                    </div>
                  }
                  {authMgr?.AccessCheck([permissions?.Survey, permissions?.NavTeacherSurvey]) &&
                    <div
                      onClick={handleSidebarToggle('/survey/teachersurvey')}
                      className={getOptionClass('/survey/teachersurvey', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Teacher Surveys</span>
                    </div>
                  }
                  {authMgr?.AccessCheck([permissions?.Survey, permissions?.NavAdministratorSurvey]) &&
                    <div
                      onClick={handleSidebarToggle('/survey/principalsurvey')}
                      className={getOptionClass('/survey/principalsurvey', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">School Admin Surveys</span>
                    </div>
                  }
                </li>
              </>
            }

            {(authMgr?.AccessCheck([permissions?.NavTeacherReports]) ||
              authMgr?.AccessCheck([permissions?.NavAdminReports]) ||
              authMgr?.AccessCheck([permissions?.NavNIETReports])) && <>
                <li>
                  <div className={'groupOption'}>
                    <span onClick={() => setReportsMenu(!reportsMenu)} className="menuOptions hasHover">
                      Reports
                    </span>
                    <span onClick={() => setReportsMenu(!reportsMenu)} className={surveyMenu ? "sidebar-icon-indicator" : "sidebar-icon-indicator active"}>
                      <ChevronRightTwoToneIcon />
                    </span>
                  </div>
                </li>
                <li ref={menuFive} style={{ maxHeight: reportsMenu ? `${reportsHeight}px` : "0px" }}>
                  {authMgr?.AccessCheck([permissions?.NavTeacherReports]) &&
                    <div
                      onClick={handleSidebarToggle('/reports/teacher')}
                      className={getOptionClass('/reports/teacher', 'groupSubOption')}
                    >
                      <span className={"menuSubOptions"}>Teacher Reports</span>
                    </div>}
                  {authMgr?.AccessCheck([permissions?.NavAdminReports]) &&
                    <div
                      onClick={handleSidebarToggle('/reports/school-administrator')}
                      className={getOptionClass('/reports/school-administrator', 'groupSubOption')}
                    >
                      <span className={"menuSubOptions"}>School Admin Reports</span>
                    </div>}
                  {authMgr?.AccessCheck([permissions?.NavNIETReports]) &&
                    <div
                      onClick={handleSidebarToggle('/reports/niet')}
                      className={getOptionClass('/reports/niet', 'groupSubOption')}
                    >
                      <span className={"menuSubOptions"}>NIET Reports</span>
                    </div>}
                </li>
              </>}
            {authMgr?.AccessCheck([permissions?.Administration]) &&
              <>
                <li>
                  <div
                    className={'groupOption'}
                  >
                    <span onClick={() => setAdminMenu(!adminMenu)} className="menuOptions hasHover">Administration</span>
                    <span onClick={() => setAdminMenu(!adminMenu)} className={adminMenu ? "sidebar-icon-indicator" : "sidebar-icon-indicator active"}>
                      <ChevronRightTwoToneIcon />
                    </span>
                  </div>
                </li>
                <li ref={menuSix} style={{ maxHeight: adminMenu ? `${adminHeight}px` : "0px" }}>
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.Content, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/content')}
                      className={getOptionClass('/admin/content', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Content Management</span>
                    </div>
                  }
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.FolderSyncFile, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/contentSync')}
                      className={getOptionClass('/admin/contentSync', 'groupSubOption')}
                    >
                      <span className="menuSubOptions sub">Content Sync Management</span>
                    </div>
                  }
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.Tag, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/tag')}
                      className={getOptionClass('/admin/tag', 'groupSubOption')}
                    >
                      <span className="menuSubOptions sub">Tag Management</span>
                    </div>}

                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.Client, permissions?.NavOrganizationMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/Organization')}
                      className={getOptionClass('/admin/organization', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Organization Management</span>
                    </div>}
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.District, permissions?.NavDistrictMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/District')}
                      className={getOptionClass('/admin/district', 'groupSubOption')}
                    >
                      <span className={`menuSubOptions ${!authMgr?.AccessCheck([permissions?.NavOrganizationMgmt]) ? '' : 'sub'}`}>District Management</span>
                    </div>}
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.School, permissions?.NavSchoolMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/School')}
                      className={getOptionClass('/admin/school', 'groupSubOption')}
                    >
                      <span className={`menuSubOptions ${(!authMgr?.AccessCheck([permissions?.NavDistrictMgmt]) && !authMgr?.AccessCheck([permissions?.NavOrganizationMgmt])) ? '' : 'sub'}`}>School Management</span>
                    </div>}

                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.Cluster, permissions?.NavClusterMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/Cluster')}
                      className={getOptionClass('/admin/cluster', 'groupSubOption')}
                    >
                      <span className={`menuSubOptions  ${(!authMgr?.AccessCheck([permissions?.NavSchoolMgmt]) && !authMgr?.AccessCheck([permissions?.NavOrganizationMgmt]) && !authMgr?.AccessCheck([permissions?.NavDistrictMgmt])) ? '' : 'sub'}`}>Cluster Management</span>
                    </div>}

                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.UserProfile, permissions?.NavUserMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/User')}
                      className={getOptionClass('/admin/user', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">User Management</span>
                    </div>}
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.UserProfile, permissions?.NavUserMgmt, permissions?.AzureB2CSaveUserProfiles]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/StagedUser')}
                      className={getOptionClass('/admin/StagedUser', 'groupSubOption')}
                    >
                      <span className="menuSubOptions sub">Staged User Management</span>
                    </div>}
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.Role, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/Role')}
                      className={getOptionClass('/admin/role', 'groupSubOption')}
                    >
                      <span className="menuSubOptions sub">Role Management</span>
                    </div>}
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.Permission, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/Permission')}
                      className={getOptionClass('/admin/permission', 'groupSubOption')}
                    >
                      <span className="menuSubOptions sub">Permission Management</span>
                    </div>}


                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.SchoolYear, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/SchoolYear')}
                      className={getOptionClass('/admin/schoolyear', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">School Year Management</span>
                    </div>}
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.Rubric, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/Rubric')}
                      className={getOptionClass('/admin/rubric', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Rubric Management</span>
                    </div>}
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.SurveyTemplate, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/SurveyTemplate')}
                      className={getOptionClass('/admin/surveytemplate', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Survey Builder</span>
                    </div>}
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.GeneralSurvey, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/generalSurvey')}
                      className={getOptionClass('/admin/generalSurvey', 'groupSubOption')}
                    >
                      <span className="menuSubOptions sub">General Survey Management</span>
                    </div>}
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.FileImportExport, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/ImportExport')}
                      className={getOptionClass('/admin/ImportExport', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Import/Export Management</span>
                    </div>}
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.Notification, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/notification')}
                      className={getOptionClass('/admin/notification', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Notification Management</span>
                    </div>
                  }
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.ObservationConfig, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/Configuration')}
                      className={getOptionClass('/admin/Configuration', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Configuration Management</span>
                    </div>
                  }
                  {authMgr?.AccessCheck([permissions?.ObservationConfig, permissions?.Administration, permissions?.TOS, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/TOS')}
                      className={getOptionClass('/admin/tos', 'groupSubOption')}
                    >
                      <span className="menuSubOptions sub">Terms of Service Management</span>
                    </div>
                  }
                  {authMgr?.AccessCheck([permissions?.ObservationConfig, permissions?.Administration, permissions?.NavApplicationAdminMgmt, permissions?.NavCertMgmt, permissions?.CertificationQuestion]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/CertificationQuestion/teacher')}
                      className={getOptionClass('/admin/certificationquestion/teacher', 'groupSubOption')}
                    >
                      <span className="menuSubOptions  sub">Teacher Eval Question Bank </span>
                    </div>
                  }
                  {authMgr?.AccessCheck([permissions?.ObservationConfig, permissions?.Administration, permissions?.NavApplicationAdminMgmt, permissions?.NavCertMgmt, permissions?.CertificationQuestion]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/CertificationQuestion/schooladmin')}
                      className={getOptionClass('/admin/certificationquestion/schooladmin', 'groupSubOption')}
                    >
                      <span className="menuSubOptions  sub">Admin Eval Question Bank</span>
                    </div>
                  }
                  {authMgr?.AccessCheck([permissions?.ObservationConfig, permissions?.Administration, permissions?.Transaction, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/Transaction')}
                      className={getOptionClass('/admin/transaction', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Transaction Management</span>
                    </div>}

                  {authMgr?.AccessCheck([permissions?.Administration,permissions?.PayoutTeacher]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/Payout/Teacher')}
                      className={getOptionClass('/admin/payout/teacher', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Teacher Payout</span>
                    </div>}
                    
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.PayoutAdmin]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/Payout/Admin')}
                      className={getOptionClass('/admin/payout/admin', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Admin Payout</span>
                    </div>}
                  
                  {authMgr?.AccessCheck([permissions?.Administration, permissions?.Feedback, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/Feedback')}
                      className={getOptionClass('/admin/feedback', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Feedback Management</span>
                    </div>}
                  {
                    authMgr?.AccessCheck([permissions?.Administration, permissions?.Log, permissions?.NavApplicationAdminMgmt]) &&
                    <div
                      onClick={handleSidebarToggle('/admin/Log')}
                      className={getOptionClass('/admin/log', 'groupSubOption')}
                    >
                      <span className="menuSubOptions">Log Management</span>
                    </div>
                  }
                </li >
                {authMgr?.AccessCheck([permissions?.Development]) &&
                  <>
                    <li>
                      <div
                        className={'groupOption'}
                      >
                        <span onClick={() => setDevMenu(!devMenu)} className="menuOptions hasHover">Development</span>
                        <span onClick={() => setDevMenu(!devMenu)} className={devMenu ? "sidebar-icon-indicator" : "sidebar-icon-indicator active"}>
                          <ChevronRightTwoToneIcon />
                        </span>
                      </div>
                    </li>
                    <li ref={menuSeven} style={{ maxHeight: devMenu ? `${devHeight}px` : "0px" }}>
                      <div
                        onClick={handleSidebarToggle('/dev/controlTest')}
                        className={getOptionClass('/dev/controltest', 'groupSubOption')}
                      >
                        <span className="menuSubOptions">Control Test</span>
                      </div>


                      <div
                        onClick={handleSidebarToggle('/dev/tableTest')}
                        className={getOptionClass('/dev/tabletest', 'groupSubOption')}
                      >
                        <span className="menuSubOptions">Table Test</span>
                      </div>
                      <div
                        onClick={handleSidebarToggle('/dev/Token')}
                        className={getOptionClass('/dev/token', 'groupSubOption')}
                      >
                        <span className="menuSubOptions">Token</span>
                      </div>

                    </li>
                  </>
                }
              </>
            }
          </ul >
        }
      </div >
    </>
  );
};

export default SidebarMenu;
