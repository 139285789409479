import {
    faDownload,
    faEye,
    faPencil,
    faTrash,
    faTrashAlt,
    faTrashCan,
    faX,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _, { set } from "lodash";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Loading from "../../../../../components/Loading";
import { useCertificationQuestionManager } from "../../../../../hooks/useManagers";
import ContentModal from "../../certificationQuestion/ContentModal";
import { useHref } from 'react-router-dom';
import { InputField } from "../../../../../components/controls/InputField";
import ButtonControl from "../../../../../components/controls/ButtonControl";
import { DialogControl } from "../../../../../components/controls/DialogControl";
import { Tag, TagPicker } from "rsuite";
import '../../certificationQuestion/details.scss';


function CertificationTable({ name, addFilter, defaultFilter, handleIds, ids, certificationQuestions }) {
    const [openContentView, setOpenContentView] = useState(false);
    const [certifications, setCertifications] = useState(null);
    const [selectedIds, setSelectedIds] = useState(ids);
    const [allCertifications, setAllCertifications] = useState(null);
    const [certificationItem, setCertificationItem] = useState(null);
    const [addCertDialog, setAddCertDialog] = useState(false);
    const [idsToAdd, setIdsToAdd] = useState([]);
    const [search, setSearch] = useState("");
    const [active, setActive] = useState(ids ? true : false);
    const [downloading, setDownloading] = useState(false);
    const certificationQuestionMgr = useCertificationQuestionManager();

    useEffect(() => {
        if (!allCertifications && certificationQuestions) {
            let currCertifications = defaultFilter(certificationQuestions);
            setCertifications(ids ? certificationQuestions.filter((x) => ids.includes(x.Id)) : currCertifications);
            setActive(ids ? true : false);
            setAllCertifications(addFilter(certificationQuestions));
        }
    }, [certificationQuestions, search]);

    useEffect(() => {
        if (allCertifications) {

            let currCertifications = defaultFilter(allCertifications);
            setCertifications(ids ? allCertifications.filter((x) => ids.includes(x.Id)) : currCertifications);
            setActive(ids ? true : false);
        }
    }, [name, ids])

    useEffect(() => {
        if (active)
            handleIds(selectedIds);
    }, [selectedIds]);

    const handleEdit = (item) => (e) => {
        setCertificationItem(item);
        window.open(`/admin/certificationQuestion/${certificationItem?.Id}`, '_blank');
    };

    const checkIsImage = (fileExtension) => {
        return (
            fileExtension === "apng" ||
            fileExtension === "gif" ||
            fileExtension === "ico" ||
            fileExtension === "jpg" ||
            fileExtension === "jpeg" ||
            fileExtension === "jfif" ||
            fileExtension === "pjpeg" ||
            fileExtension === "pjp" ||
            fileExtension === "png" ||
            fileExtension === "svg"
        );
    };

    const checkIsVideo = (fileExtension) => {
        return (
            fileExtension === "mp4" ||
            fileExtension === "avi" ||
            fileExtension === "mpg" ||
            fileExtension === "mpga" ||
            fileExtension === "streaming.media.azure.net"
        );
    };

    const handleViewQuestionContent = (question) => (e) => {
        setCertificationItem(question);
        setOpenContentView(true);
    };

    const handleDownloadQuestionFile = async (question) => {
        setDownloading(true);
        await certificationQuestionMgr.QuestionItemManager.getFile(
            question?.File?.Id,
            `${question.File?.DisplayFileName}.${question.File?.FileExtention}`
        ).then((r) => {
            setDownloading(false);
        });
    };

    const handleTableQuestionContent = (question) => {
        return question.File ? (
            checkIsImage(question.File.FileExtention) ||
                checkIsVideo(question.File.FileExtention) ? (
                <FontAwesomeIcon
                    className={"icon btn-icon-fa"}
                    icon={faEye}
                    onClick={handleViewQuestionContent(question)}
                />
            ) : (
                <FontAwesomeIcon
                    className={"icon btn-icon-fa"}
                    icon={faDownload}
                    onClick={() => {
                        if (!downloading)
                            handleDownloadQuestionFile(question);
                    }}
                />
            )
        ) : (
            <div>&nbsp;</div>
        );
    };

    const handleSearch = (value) => {
        setSearch(value.toLowerCase());
    }

    const handleAdd = (idsToAdd) => {
        if (idsToAdd !== undefined) {
            let newCertifications = [...certifications];
            idsToAdd?.forEach((id) => {
                let item = allCertifications.find((c) => c.Id === id);
                if (item) {
                    newCertifications.push(item);
                }
            });
            setCertifications(newCertifications);
            setSelectedIds((prev) => [...prev, ...idsToAdd]);
        }
        setIdsToAdd([]);
        setAddCertDialog(false);
    }

    const handleDelete = (id) => (e) => {
        setCertifications((prev) => prev.filter((x) => x.Id !== id));
        setSelectedIds((prev) => prev.filter((x) => x !== id));
    };

    const handleOverride = (value) => {
        setActive(value);
        // reset the selected ids to the default filter
        setCertifications(defaultFilter(allCertifications));
        setSelectedIds(defaultFilter(allCertifications).map((x) => x.Id));
        if (value === false) { // if we are turning off the override, then we want to reset the selected ids to empty
            handleIds([]);
        }
    };
    const certificationTagData = allCertifications?.filter(
        (c) => {
            if (name === "Admin" && !certifications.some((existing) => existing.Id === c.Id) && c.CertificationType === 1) {
                return true
            } else if (name === "Teacher" && !certifications.some((existing) => existing.Id === c.Id) && c.CertificationType === 2) {
                return true
            }
        }).map(
            (c) => {
                return {
                    label: c.QuestionText,
                    value: c.Id,
                    html: <div dangerouslySetInnerHTML={{ __html: c.QuestionText.replace(/\n/g, '<br />') }} />
                }
            }
        );

    return (
        <>
            <DialogControl
                openDialog={addCertDialog}
                title={'Add Certification Question'}
                onCancel={() => handleAdd(undefined)}
                onOk={() => handleAdd(idsToAdd)}
                okText={'add'}
            >
                <InputField title="Add Certification Questions" fieldName="AddCertificationSelect">
                    <TagPicker
                        className='tag-picker'
                        data={certificationTagData === undefined ? [] : certificationTagData}
                        onChange={setIdsToAdd}
                        loading={certificationTagData === undefined}
                        block
                        preventOverflow
                        renderMenuItem={(label, item) => {
                            return (
                                <>
                                    {item?.html}
                                </>
                            );
                        }}
                        renderValue={(values, items, tags) => {
                            return items.map((item, index) => (
                                <Tag key={index}>
                                    {item.html}
                                </Tag>
                            ));
                        }}
                    />
                </InputField>
            </DialogControl>
            <ContentModal
                open={openContentView}
                title={`Question Content`}
                setOpen={setOpenContentView}
                okText={"EDIT"}
                handleOk={(e) => handleEdit(certificationItem)(e)}
                item={certificationItem}
            />
            <div className="certificationQuestion-answer-editor__wrapper">
                <div className='certificationQuestion-answer-editor__header'>
                    <div className='certificationQuestion-answer-editor__header__text'>
                        {active ? <ButtonControl type={"okay"} onClick={() => handleOverride(false)}>Reset To Default</ButtonControl> :
                            <ButtonControl type={"okay"} onClick={() => handleOverride(true)}>Override</ButtonControl>}
                    </div>
                    <ButtonControl disabled={!active} onClick={() => setAddCertDialog(true)}>Add Question</ButtonControl>
                </div>
                <span className={active ? "" : "overlay"}>
                    <div className='certificationQuestion-answer-editor__search'>
                        <InputField
                            type="text"
                            value={search}
                            fieldName='search'
                            disableError={true}
                            placeholder='Search Answer Name'
                            onChange={handleSearch}>
                        </InputField>
                    </div>
                    <div className="certificationQuestion-answer-editor">
                        {certifications ? (
                            <>
                                <table className="__table">
                                    <thead className="__head">
                                        <tr>
                                            <th className="__content">Question Text</th>
                                            <th className="__flex">Content</th>
                                            <th className="__icon" />
                                            <th className="__icon">Edit</th>
                                            <th className="__icon">Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ overflowY: certifications ? "auto" : "clip" }}>
                                        {certifications
                                            ?.filter(
                                                (cert) =>
                                                    cert.QuestionText.toLowerCase().includes(search) ||
                                                    search === ""
                                            )
                                            .map((c, index) => {

                                                return (
                                                    <tr key={c.Id}>
                                                        <td
                                                            className="__content"
                                                            dangerouslySetInnerHTML={{
                                                                __html: c.QuestionText.replace(/\n/g, "<br />"),
                                                            }}
                                                        />
                                                        <td className="__flex">
                                                            {c.File ? (
                                                                <div className="certq-filename">
                                                                    <span>{c?.File?.DisplayFileName}</span>
                                                                    <span>{"." + c?.File?.FileExtention}</span>
                                                                </div>
                                                            ) : null}
                                                        </td>
                                                        <td className="__icon">
                                                            {handleTableQuestionContent(c)}
                                                        </td>
                                                        <td className="__icon">
                                                            <FontAwesomeIcon
                                                                className={"icon btn-icon-fa"}
                                                                icon={faPencil}
                                                                onClick={handleEdit(c.Id)}
                                                            />
                                                        </td>
                                                        <td className="__icon">
                                                            <FontAwesomeIcon
                                                                className={"icon btn-icon-fa"}
                                                                icon={faTrash}
                                                                onClick={handleDelete(c.Id)}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <div className="loading-wrapper">
                                <Loading type="default" size="3rem" />
                            </div>
                        )}
                    </div>
                </span>
            </div>
        </>
    );
}

// default props
CertificationTable.defaultProps = {
    defaultFilter: (items) => { return items?.filter((item) => item.IsDefault) },
    addFilter: (items) => items,
};

export default CertificationTable;
