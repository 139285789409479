import { React, useEffect, useState } from 'react';
import { Panel } from 'rsuite';
import { InputField } from '../../../../../components/controls/InputField';
import { LabelControl } from '../../../../../components/controls';
import './../styles/adminObserverWeights.scss';
import _ from 'lodash';

const AdminObserverWeights = ({ data, setData }) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [observerWeightData, setObserverWeightData] = useState();
    const [principalEvaluator, setPrincipalEvaluator] = useState();
    const [principalSurvey, setPrincipalSurvey] = useState();
    const [selfAssessment, setSelfAssessment] = useState();

    useEffect(() => {
        if (data) {
            setObserverWeightData(data);
            //filter for each, set the state 
            let prinEvalData = data.find(x => x.ObserverPositionTypeId === 59);
            let prinSurveyData = data.find(x => x.ObserverPositionTypeId === 60);
            let selfAssessmentData = data.find(x => x.ObserverPositionTypeId === 61);

            setPrincipalEvaluator(prinEvalData ? { ...prinEvalData } : null);
            setPrincipalSurvey(prinSurveyData ? { ...prinSurveyData } : null);
            setSelfAssessment(selfAssessmentData ? { ...selfAssessmentData } : null);
        }
    }, [data]);
    const handleOnChange = (ObserverPositionTypeId, isSurveyValue, value) => {
        const updatedObserverWeightData = [...observerWeightData];
        const observerEntry = updatedObserverWeightData.find(entry => entry.ObserverPositionTypeId === ObserverPositionTypeId);

        if (value === '' || value === null || value === undefined) {
            value = 0;
        }
        if (observerEntry) {
            if (isSurveyValue) {
                observerEntry.SurveyWeight = value;
            } else {
                observerEntry.NonSurveyWeight = value;
            }
            setData(updatedObserverWeightData);
        }
    }

    return (
        <>
            <Panel header="Observer Weights" bordered>
                <div className='admin-observer-table'>
                    <table className="admin-observer-weights-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Survey</th>
                                <th>Non-Survey</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='row-header'>Principal Evaluator</td>
                                <td>
                                    <InputField placeholder={'0'} value={principalEvaluator?.SurveyWeight}
                                        onChange={(e) => handleOnChange(principalEvaluator?.ObserverPositionTypeId, true, e)} type="text" disableError={true} />
                                </td>
                                <td>
                                    <InputField placeholder={'0'} value={principalEvaluator?.NonSurveyWeight}
                                        onChange={(e) => handleOnChange(principalEvaluator?.ObserverPositionTypeId, false, e)} type="text" disableError={true} />
                                </td>
                            </tr>
                            <tr>
                                <td className='row-header'>Principal Survey (by Teachers)</td>
                                <td>
                                    <InputField placeholder={'0'} value={principalSurvey?.SurveyWeight}
                                        onChange={(e) => handleOnChange(principalSurvey?.ObserverPositionTypeId, true, e)} type="text" disableError={true} />
                                </td>
                            </tr>
                            <tr>
                                <td className='row-header'>Self-Assessment</td>
                                <td>
                                    <InputField placeholder={'0'} value={selfAssessment?.SurveyWeight}
                                        onChange={(e) => handleOnChange(selfAssessment?.ObserverPositionTypeId, true, e)} type="text" disableError={true} />
                                </td>
                                <td>
                                    <InputField placeholder={'0'} value={selfAssessment?.NonSurveyWeight}
                                        onChange={(e) => handleOnChange(selfAssessment?.ObserverPositionTypeId, false, e)} type="text" disableError={true} />
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <th className='align-center'>Total:{_.sumBy(data, x => parseInt(x.SurveyWeight))}</th>
                                <th className='align-center'>Total: {_.sumBy(data, x => parseInt(x.NonSurveyWeight))}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Panel>
        </>
    );
}

export default AdminObserverWeights;