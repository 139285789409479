import MainLayout from '../../../components/layout/MainLayout'
import ScreenWrapper from '../../../components/layout/screenWrapper'
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../Analytics.scss';
import '../../../components/layout/layouts.scss';
import ButtonControl from '../../../components/controls/ButtonControl';
import AnalyticsChart from '../components/AnalyticsChart';
import inactiveSize1 from '../../../content/icons/inactiveChartSize1x1.svg'
import inactiveSize2 from '../../../content/icons/inactiveChartSize2x1.svg'
import inactiveSize3 from '../../../content/icons/inactiveChartSize2x2.svg'
import activeSize1 from '../../../content/icons/activeChartSize1x1.svg'
import activeSize2 from '../../../content/icons/activeChartSize2x1.svg'
import activeSize3 from '../../../content/icons/activeChartSize2x2.svg'
import refreshIcon from '../../../content/icons/whiteRefreshIcon.svg'
import refreshIconDisabled from '../../../content/icons/disabledRefreshIcon.svg'
import { DialogControl } from '../../../components/controls/DialogControl';
import { InputField } from '../../../components/controls/InputField';
import { CheckboxControl, DatePickerControl, SelectListControl } from '../../../components/controls';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { CheckPicker, SelectPicker } from 'rsuite';
import GroupOrganizationIcon from '../assets/images/GroupOrganization.svg';
import GroupDistrictIcon from '../assets/images/GroupDistrict.svg';
import GroupSchoolIcon from '../assets/images/GroupSchool.svg';
import { useEffect } from 'react';
import _, { forEach } from 'lodash';
import { useAnalyticManager } from '../../../hooks/useManagers';
import SystemTypes from '../../../SystemTypes';
import Loading from '../../../components/Loading';
import moment from 'moment';
import ChartControl from '../../../components/controls/ChartControl/ChartControl';
import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const Analytics = () => {
  const analyticMgr = useAnalyticManager();
  const [analyticsTypeMap] = useState({
    AverageScoreByObserver: 'Average Observer Rating',
    AverageScore: 'Average Self-Reflection Rating',
    TeacherCertificationStatus: "Teacher Certification Status",
    ObservationStatus: 'Observation Status',
    ObserverVsSelfScore: 'Observer Rating vs. Self-Reflection Rating',
    RefinementGoal: 'Refinement Goals',
    ReinforcementGoal: 'Reinforcement Goals',
    ObservationCompleted: 'Observations Completed',
    RequiredVsSubmittedObservation: 'Required vs. Completed Observations'
  });
  const [errors, setErrors] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null)
  //LOADINGS
  const [loading, setLoading] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [loadingObservationConfigs, setLoadingObservationConfigs] = useState(false)
  const [saving, setSaving] = useState(false)
  //Layout modifications
  const [editingLayout, setEditingLayout] = useState(false)
  const [gridData, setGridData] = useState([])
  const [ungriddedCharts, setUngriddedCharts] = useState([])
  const [charts, setCharts] = useState(null);
  const [styles, setStyles] = useState(null)
  const [screenHeight, setScreenHeight] = useState(0)
  const [originalCharts, setOriginalCharts] = useState(null)
  const [originalGridData, setOriginalGridData] = useState([])
  const [originalStyles, setOriginalStyles] = useState(null)

  //Chart Modifications
  const [chartConfig, setChartConfig] = useState(null)
  const [openAddChartDialog, setOpenAddChartDialog] = useState(false);
  const [orgs, setOrgs] = useState(null)
  const [districts, setDistricts] = useState(null)
  const [schools, setSchools] = useState(null)
  const [schoolYears, setSchoolYears] = useState(null)
  const [clusters, setClusters] = useState(null)
  const [chartToDelete, setChartToDelete] = useState(null)
  const [previewChartData, setPreviewChartData] = useState(null)
  const [previewChartKeys, setPreviewChartKeys] = useState(null)
  const [previewSelectedKey, setPreviewSelectedKey] = useState(null)
  //
  const [updatedAnalyticsConfig, setUpdatedAnalyticsConfig] = useState(null)
  const [landing, setLanding] = useState(true)
  const [openEditingDefault, setOpenEditingDefault] = useState(false)
  const [analyticConfig, setAnalyticConfig] = useState(null)
  const [orgTree, setOrgTree] = useState(null)
  useEffect(() => {
    if (analyticMgr && analyticMgr.AppUserState) {

      analyticMgr.grabGroup('Org').then((r) => {
        setOrgTree(r)
        setOrgs(r)
      })
      let tmpSchoolYears = [];
      Object.keys(analyticMgr.AppUserState.schoolYearMap).forEach(schoolYear => {
        tmpSchoolYears.push({
          Text: (analyticMgr.AppUserState.schoolYearMap[schoolYear]?.Title ?? schoolYear),
          Value: schoolYear
        });
      })
      tmpSchoolYears.unshift({ Text: "Selected School Year", Value: 0 })
      if (!chartConfig) {
        setChartConfig(analyticMgr.DefaultItem)
      }
      //If they dont have the permission just go ahead and convert data into charts
      if (!analyticMgr.hasConfigureDefaultsPermissions()) {
        convertDataToCharts()
        setLanding(false)
      }
      setSchoolYears(tmpSchoolYears)
    }
  }, [analyticMgr])

  useEffect(() => {
    if (ungriddedCharts.length > 0) {
      const grid = convertChartsToGrid(ungriddedCharts, false, true)
      setGridData(grid)
      setUngriddedCharts([])
      updateChartPositions(grid)
    }
  }, [ungriddedCharts])
  const convertDataToCharts = async (defaultConfig) => {
    if (!charts) {
      if (analyticMgr.AppUserState.userProfile.AnalyticsConfig || analyticMgr.AppUserState.DefaultAnalyticsLayout || defaultConfig) {
        setLoading(true)
        //Global state does not update so we use a state to store our newly made changes.
        const chartList = _.map(JSON.parse(defaultConfig ?? analyticMgr.AppUserState.userProfile.AnalyticsConfig ?? analyticMgr.AppUserState.DefaultAnalyticsLayout), x => {
          return analyticMgr.retrieveChartData(x).then(r => {
            if (typeof (r) === 'object') {
              return r
            } else {
              return { ...x, failToLoad: r }
            }
          })
        })
        //  if (!defaultConfig && analyticMgr?.AppUserState?.userProfile.AnalyticsConfig) {
        //   setUpdatedAnalyticsConfig(analyticMgr.AppUserState.userProfile.AnalyticsConfig)
        // } else if (!defaultConfig && analyticMgr.AppUserState.userProfile.DefaultAnalyticsLayout) {
        //   setUpdatedAnalyticsConfig(analyticMgr.AppUserState.userProfile.DefaultAnalyticsLayout)
        // }
        const result = await Promise.all(chartList)
        const grid = convertChartsToGrid(result)
        setGridData(grid)
        setCharts(updateChartPositions(grid, result))
        setLoading(false)
      }
    }
  }
  const addChartToLayout = async () => {
    let updatedChartConfig;
    let editingId = chartConfig.editingMode && chartConfig.Id
    // setSaving(true)
    if (!previewChartData) {
      await analyticMgr.retrieveChartData(chartConfig, editingId).then(r => {
        if (typeof (r) === 'object') {
          updatedChartConfig = { ...r }
        } else {
          updatedChartConfig = false
        }
      })
    } else updatedChartConfig = { ...chartConfig }

    /*if (!updatedChartConfig || previewChartData === "No Data") {
      setErrors(["Unable to configure chart, no data was found."])
      return
    }*/
    const spreadCharts = charts && gridData.length > 0 ? orderCharts() : []
    const chartIndex = _.findIndex(charts, x => x.Id === updatedChartConfig.Id)
    if (chartIndex !== -1) {
      spreadCharts.splice(chartIndex, 1, updatedChartConfig)
    } else spreadCharts.push(updatedChartConfig)

    let chartNullFields = _.map(spreadCharts, x => {
      return {
        ...x,
        Data: undefined,
        editingMode: undefined,
        failToLoad: undefined,
      }
    })
    if (analyticConfig) {
      const defaultLayoutConfig = { ...analyticConfig.defaultGroup }
      defaultLayoutConfig[analyticConfig.viewingMode] = JSON.stringify(chartNullFields)
      analyticMgr.saveDefaultConfigByGroup(analyticConfig.defaultGroupType, defaultLayoutConfig)
    } else {
      analyticMgr.saveChartLayout(chartNullFields)
      // setUpdatedAnalyticsConfig(JSON.stringify(chartNullFields))
    }

    if (analyticConfig) {
      setChartConfig({
        ...analyticMgr.DefaultItem,
        ClientIds: chartConfig.ClientIds,
        DistrictIds: chartConfig.DistrictIds,
        SchoolIds: chartConfig.SchoolIds,
        organizations: chartConfig.organizations,
        districts: chartConfig.districts,
        schools: chartConfig.schools,
        SchoolYearIdentifier: 0,
        defaultGroupType: chartConfig.defaultGroupType,
        defaultGroup: chartConfig.defaultGroup,
      })
    } else setChartConfig(analyticMgr.DefaultItem)

    setPreviewChartData(null)
    const grid = convertChartsToGrid(spreadCharts, true)
    setGridData(grid)
    setCharts(updateChartPositions(grid, spreadCharts, true))
    setOriginalCharts(spreadCharts)
    setOriginalGridData(grid)
    setOpenAddChartDialog(false)
    //TESTING: async save of layout in background hence comments
    // setSaving(false)
  }
  const createChart = async (isUpdate) => {
    if (isChartValid()) {

      setLoadingChart(true)
      await analyticMgr.retrieveChartData(chartConfig, chartConfig.editingMode && chartConfig.Id, isUpdate).then(r => {
        if (typeof (r) === 'object') {
          const previewChart = { ...r }
          setChartConfig(r)
          if (r.keys) {
            var selectedIdValue = _.sortBy(r.keys ?? [], x => x.text).first().value;
            setPreviewSelectedKey(selectedIdValue)
            setPreviewChartKeys(_.sortBy(r.keys, x => x.text))
            setPreviewChartData({ ...previewChart, selectedId: selectedIdValue })
          }
          else {
            setPreviewChartData(previewChart)
          }
        } else {
          setPreviewChartData("No Data")
        }
      }
      )
      setLoadingChart(false)
    }
  }

  const updatePreviewChart = (value) => {
    setPreviewSelectedKey(value)
    setPreviewChartData({ ...previewChartData, selectedId: value })
    setChartConfig({ ...chartConfig, selectedId: value })
  }

  const convertChartsToGrid = (updatedCharts, reorganize) => {
    const grid = gridData.length > 0 && !reorganize ? _.cloneDeep(gridData) : [[false, false, false]]
    let currentRow = 0;
    _.forEach(updatedCharts ?? charts, (x, i) => {
      //Locates row with 2 empty cells.
      const rowWith2Spaces = _.findIndex(grid, row => {
        let count = 0;
        for (const cell of row) {
          if (!cell) {
            count += 1
          }
        }
        if (count >= 2) {
          return true
        }
      });
      const rowWithSpace = _.findIndex(grid, row => row?.includes(false))
      const emptyCellIndex = _.findIndex(grid[rowWithSpace], col => !col)
      if (rowWith2Spaces === -1) {
        //changed current row to gridLength,
        if (x.Size === 3) {
          grid[grid.length] = [
            x.Id,
            x.Id,
            false,
          ];
          grid[grid.length] = [
            x.Id,
            x.Id,
            false,
          ];
          currentRow += 2
        } else if (x.Size === 2) {

          grid[grid.length] = [
            x.Id,
            x.Id,
            false,
          ];
          currentRow += 1
        } else {
          if (rowWithSpace === -1) {
            grid[grid.length] = [x.Id, false, false];
            currentRow += 1
          } else {
            grid[rowWithSpace][emptyCellIndex] = x.Id
          }
        }
      } else {
        const twoEmptyCellIndex = _.findIndex(grid[rowWith2Spaces], col => !col)
        if (x.Size === 3) {

          if (twoEmptyCellIndex === 0) {
            grid[rowWith2Spaces][0] = x.Id
            grid[rowWith2Spaces][1] = x.Id
            grid[rowWith2Spaces + 1] = [x.Id, x.Id, grid[rowWith2Spaces + 1] ? grid[rowWith2Spaces + 1][2] ?? false : false]
          } else {
            grid[rowWith2Spaces][1] = x.Id
            grid[rowWith2Spaces][2] = x.Id
            grid[rowWith2Spaces + 1] = [grid[rowWith2Spaces + 1] ? grid[rowWith2Spaces + 1][0] ?? false : false, x.Id, x.Id]
          }
          currentRow += 1
        } else if (x.Size === 2) {
          if (twoEmptyCellIndex === 0) {
            grid[rowWith2Spaces] = [x.Id, x.Id, grid[rowWith2Spaces][2] ?? false,]
          } else {
            grid[rowWith2Spaces] = [grid[rowWith2Spaces][0] ?? false, x.Id, x.Id]
          }
        } else {
          grid[rowWithSpace][emptyCellIndex] = x.Id
        }
      }
    })
    _.remove(grid, x => x.every(value => value === false))
    return grid
  }

  const updateChartPositions = (grid, chartsData, updateOriginal) => {
    const itemPositions = []
    const chartStyles = []
    let calculateScreenHeight = 0;
    for (let row = 0; row < grid.length; row++) {
      for (let col = 0; col < 3; col++) {
        const id = grid[row][col];
        const alreadyExist = _.find(itemPositions, x => x.Id === id)
        //I'm using false as a placeholder to see if the position is filled already. So Id must be present to push its position.
        if (!alreadyExist && id) {
          const chart = _.find(chartsData ?? charts, x => x.Id === id)
          itemPositions.push({
            ...chart,
            position: {
              position: 'absolute',
              width: chart.Size !== 1 ? '788px' : '376px',
              height: chart.Size === 3 ? '636px' : '300px',
              top: (300 * row) + (36 * row),
              left: (376 * col) + (36 * col),
            },
          });
          if (col === 0) {
            calculateScreenHeight += chart.Size === 3 ? 636 : 300
            calculateScreenHeight += 36
          }
          chartStyles.push({
            Id: chart.Id,
            style: {
              position: 'absolute',
              width: chart.Size !== 1 ? '788px' : '376px',
              height: chart.Size === 3 ? '636px' : '300px',
              top: (300 * row) + (36 * row),
              left: (376 * col) + (36 * col),
            }
          })
        }
      }
    }

    setScreenHeight(calculateScreenHeight)
    setStyles(chartStyles)
    if (updateOriginal) {
      setOriginalStyles(chartStyles)
    }

    return itemPositions
  }
  const updateGrid = (rowIndex, colIndex, chart, isEnd) => {
    if (rowIndex >= gridData.length || rowIndex === -1) {
      return
    }
    const grid = _.cloneDeep(gridData)
    let chartsPosToUpdate = []
    if ((chart.Size == 3 || chart.Size == 2) && colIndex == 2) {
      colIndex = 1
    }
    const checkForCollision = () => {
      let rowToCheck = chart.Size === 3 ? rowIndex + 1 : rowIndex
      let colTocheck = chart.Size === 1 ? colIndex : colIndex + 1
      if (rowToCheck < gridData.length) {
        for (let row = rowIndex; row <= rowToCheck; row++) {
          for (let col = colIndex; col <= colTocheck; col++) {
            const foundChart = _.find(charts, x => x.Id === gridData[row][col])
            if (foundChart?.Size > chart.Size) {
              chartsPosToUpdate = []
              break;
            } else if (gridData[row][col] !== chart.Id && gridData[row][col] && !chartsPosToUpdate.includes(gridData[row][col])) {
              chartsPosToUpdate.push(gridData[row][col])
            }
            grid[row][col] = chart.Id
          }
        }
      }
    }

    checkForCollision()

    const voidPreviousFields = () => {
      let topLeftCorner;
      let bottomRightCorner;
      let previousSpaces = [];

      for (let row = 0; row < grid.length; row++) {
        for (let col = 0; col < 3; col++) {
          if (grid[row][col] === chart.Id) {
            if ((row - 1 === -1) || (!grid[row][col - 1] || grid[row][col - 1] !== chart.Id) && (!grid[row - 1][col] || grid[row - 1][col] !== chart.Id)) {
              if (!topLeftCorner) {
                topLeftCorner = { row: row, col: col }
              }
            }
            if ((!grid[row + 1]) || (!grid[row][col + 1] || grid[row][col + 1] !== chart.Id) && (!grid[row + 1][col] || grid[row + 1][col] !== chart.Id)) {
              bottomRightCorner = { row: row, col: col }
            }
          }
          if (gridData[row][col] !== grid[row][col]) {
            previousSpaces.push({
              row: row,
              col: col,
            })
          }
        }
      }

      //Item moved top or left.
      const topOrLeft = _.find(previousSpaces, space => space.row == topLeftCorner?.row && space.col == topLeftCorner?.col)
      //Item moved bottom or right.
      const bottomOrRight = _.find(previousSpaces, space => space.row == bottomRightCorner?.row && space.col == bottomRightCorner?.col)
      if (topOrLeft) {
        if (chart.Size === 3) {
          if (topOrLeft.row + 1 === bottomRightCorner.row) {
            // Item moved left
            grid[bottomRightCorner.row - 1][bottomRightCorner.col] = false
            grid[bottomRightCorner.row][bottomRightCorner.col] = false
          } else if (topOrLeft.col + 1 === bottomRightCorner.col) {
            //Item moved up
            grid[bottomRightCorner.row][bottomRightCorner.col - 1] = false
            grid[bottomRightCorner.row][bottomRightCorner.col] = false
            if (Math.abs(topLeftCorner.row - bottomRightCorner.row) === 3) {
              grid[bottomRightCorner.row - 1][bottomRightCorner.col - 1] = false
              grid[bottomRightCorner.row - 1][bottomRightCorner.col] = false
            }
          } else {
            //Item moved diagonaly top right
            if (topOrLeft.col === bottomRightCorner.col) {
              let count = 0;
              let maxCount = chart.Size == 3 ? 4 : chart.Size == 2 ? 2 : 1
              for (let row = 0; row < gridData.length; row++) {
                for (let col = 0; col < 3; col++) {
                  if (grid[row][col] == chart.Id && count !== maxCount && col !== 0) {
                    count += 1
                  } else if (grid[row][col] == chart.Id) {
                    grid[row][col] = false
                  }
                }
              }
            } else {
              let count = 0;
              let maxCount = chart.Size == 3 ? 4 : chart.Size == 2 ? 2 : 1
              for (let row = 0; row < gridData.length; row++) {
                for (let col = 0; col < 3; col++) {
                  if (grid[row][col] == chart.Id && count !== maxCount) {
                    count += 1
                  } else if (count === maxCount && grid[row][col] == chart.Id) {
                    grid[row][col] = false
                  }
                }
              }
            }
          }
        } else if (chart.Size === 2) {
          if (topOrLeft.row === bottomRightCorner.row) {
            //Item moved left
            grid[bottomRightCorner.row][bottomRightCorner.col] = false
          } else if (topOrLeft.col + 1 === bottomRightCorner.col) {
            //Item moved up
            grid[bottomRightCorner.row][bottomRightCorner.col - 1] = false
            grid[bottomRightCorner.row][bottomRightCorner.col] = false

          } else {
            //Item moved diagonaly top left
            let count = 0;
            let maxCount = chart.Size == 3 ? 4 : chart.Size == 2 ? 2 : 1
            for (let row = 0; row < gridData.length; row++) {
              for (let col = 0; col < 3; col++) {
                if (grid[row][col] == chart.Id && count !== maxCount) {
                  count += 1
                } else if (count === maxCount && grid[row][col] == chart.Id) {
                  grid[row][col] = false
                }
              }
            }
          }
        } else if (chart.Size === 1) {
          if (topOrLeft.row === bottomRightCorner.row) {
            //Item moved left
            grid[bottomRightCorner.row][bottomRightCorner.col] = false
          } else if (topOrLeft.col === bottomRightCorner.col) {
            //Item moved up
            grid[bottomRightCorner.row][bottomRightCorner.col] = false
          } else {
            //Item moved diagonaly top left
            let count = 0;
            for (let row = 0; row < gridData.length; row++) {
              for (let col = 0; col < 3; col++) {
                if (grid[row][col] == chart.Id && count !== 1) {
                  count += 1
                } else if (count === 1 && grid[row][col] == chart.Id) {
                  grid[row][col] = false
                }
              }
            }
          }
        }
      }
      if (bottomOrRight) {
        if (chart.Size === 3) {
          if (bottomOrRight.row - 1 === topLeftCorner.row) {
            // item moved right
            grid[topLeftCorner.row + 1][topLeftCorner.col] = false
            grid[topLeftCorner.row][topLeftCorner.col] = false
          } else if (bottomOrRight.col - 1 === topLeftCorner.col) {
            // item moved down.{}
            grid[topLeftCorner.row][topLeftCorner.col] = false
            grid[topLeftCorner.row][topLeftCorner.col + 1] = false
            if (Math.abs(topLeftCorner.row - bottomRightCorner.row) === 3) {
              grid[topLeftCorner.row + 1][topLeftCorner.col] = false
              grid[topLeftCorner.row + 1][topLeftCorner.col + 1] = false
            }
          } else {
            //item moved diagonally bottom right
            let count = 0;
            let maxCount = chart.Size == 3 ? 4 : chart.Size == 2 ? 2 : 1
            for (let row = gridData.length - 1; row >= 0; row--) {
              for (let col = 2; col >= 0; col--) {
                if (grid[row][col] == chart.Id && count !== maxCount) {
                  count += 1
                } else if (count === maxCount && grid[row][col] == chart.Id) {
                  grid[row][col] = false
                }
              }
            }
          }
        } else if (chart.Size === 2) {
          if (bottomOrRight.row === topLeftCorner.row) {
            // item moved right
            grid[topLeftCorner.row][topLeftCorner.col] = false
          } else if (bottomOrRight.col - 1 === topLeftCorner.col) {
            // item moved down.
            grid[topLeftCorner.row][topLeftCorner.col + 1] = false
            grid[topLeftCorner.row][topLeftCorner.col] = false
          } else {
            //item moved diagonally bottom right
            let count = 0;
            let maxCount = chart.Size == 3 ? 4 : chart.Size == 2 ? 2 : 1
            for (let row = gridData.length - 1; row >= 0; row--) {
              for (let col = 2; col >= 0; col--) {
                if (grid[row][col] == chart.Id && count !== maxCount) {
                  count += 1
                } else if (count === maxCount && grid[row][col] == chart.Id) {
                  grid[row][col] = false
                }
              }
            }
          }
        } else if (chart.Size === 1) {
          if (bottomOrRight.row === topLeftCorner.row) {
            // item moved right
            grid[topLeftCorner.row][topLeftCorner.col] = false
          } else if (bottomOrRight.col === topLeftCorner.col) {
            // item moved down.
            grid[topLeftCorner.row][topLeftCorner.col] = false
          } else {
            //item moved diagonally bottom right
            let count = 0;
            for (let row = gridData.length - 1; row >= 0; row--) {
              for (let col = 2; col >= 0; col--) {
                if (grid[row][col] == chart.Id && count !== 1) {
                  count += 1
                } else if (count === 1 && grid[row][col] == chart.Id) {
                  grid[row][col] = false
                }
              }
            }
          }
        }

      }
    }
    voidPreviousFields()
    let newGridCount = 0;
    if (chartsPosToUpdate.length > 0 || isEnd) {
      for (let row = 0; row < grid.length; row++) {
        for (let col = 0; col < 3; col++) {
          if (grid[row][col] !== chart.Id && chartsPosToUpdate.includes(grid[row][col]) && !isEnd) {
            grid[row][col] = false
          }
          if (grid[row][col]) {
            newGridCount += 1
          }
        }
      }
    }
    const mapUngriddedCharts = () => {
      return _.reduce(chartsPosToUpdate, (chartsToUpdate, chartId) => {
        const updateChart = _.find(charts, x => x.Id === chartId)
        // check to make sure 2x2s or 1x2s don't get gridded again.
        //dragged chart is not added && existing chart isnt readded


        if (updateChart && (chartId !== chart.Id) && !_.find(chartsToUpdate, y => y.Id === chartId)) {
          chartsToUpdate.push(updateChart)
        }
        return chartsToUpdate
      }, [])
    }
    const isTwoByTwo = () => {
      let size3Present = false;
      _.forEach(chartsPosToUpdate, x => {
        if (x) {
          if (_.find(charts, y => y.Id === x).Size == 3) {
            size3Present = true
          }
        }
      })
      if (size3Present) {
        for (let row = 0; row < grid.length; row++) {
          for (let col = 0; col < 2; col++) {
            if (grid[row + 1] === null || grid[row + 1] === undefined) {
              break;
            }
            const subArray = [
              grid[row][col], grid[row][col + 1],
              grid[row + 1][col], grid[row + 1][col + 1]
            ]
            const falseCount = subArray.filter(value => value === false).length;
            if (falseCount === 4) {
              return true
            }
          }
        }
      } else return true
    }
    if (mapUngriddedCharts().length > 0 && ((chart.Size == 3 && isTwoByTwo()) || chart.Size != 3)) {
      setGridData(grid)
      //Prioritize regridding charts with higher sizeTypes
      setUngriddedCharts(_.orderBy(mapUngriddedCharts(), 'Size', 'desc'))
    } else if (isEnd) {
      let oldGridCount = 0;
      for (let row = 0; row < gridData.length; row++) {
        for (let col = 0; col < 3; col++) {
          if (gridData[row][col]) {
            oldGridCount += 1
          }
        }
      }
      //bandage 1 2 2
      //        2 3 3
      //        1 3 3
      if (oldGridCount === newGridCount)
        updateChartPositions(grid)
    }
  }

  const updateChartConfig = (value, field, label) => {
    const updatedChartConfig = { ...chartConfig }
    if (field === "useSpecificDates") {
      if (!value) {
        updatedChartConfig['BeginDate'] = ""
        updatedChartConfig['EndDate'] = ""
      }
    } else if (field === 'ClientIds') {
      if (value.length === 0) {
        updatedChartConfig["SchoolIds"] = []
        updatedChartConfig["schools"] = []
        updatedChartConfig["DistrictIds"] = []
        updatedChartConfig["districts"] = []
        updatedChartConfig["ClusterIds"] = []
        updatedChartConfig["clusters"] = []
      }

      updatedChartConfig['selectedId'] = undefined
      if (updatedChartConfig.organizations.includes(label)) {
        if(updatedChartConfig.organizations.length > 1) {
          updatedChartConfig["SchoolIds"] = []
          updatedChartConfig["schools"] = []
          updatedChartConfig["DistrictIds"] = []
          updatedChartConfig["districts"] = []
          updatedChartConfig["ClusterIds"] = []
          updatedChartConfig["clusters"] = []
        }
        _.remove(updatedChartConfig.organizations, x => x === label)
        
      } else updatedChartConfig.organizations.push(label)

    } else if (field === 'DistrictIds') {
      if (value.length === 0) {
        updatedChartConfig["SchoolIds"] = []
        updatedChartConfig["schools"] = []
        updatedChartConfig["ClusterIds"] = []
        updatedChartConfig["clusters"] = []
      }
      updatedChartConfig['selectedId'] = undefined
      if (updatedChartConfig.districts.includes(label)) {
        if(updatedChartConfig.districts.length > 1) {
          updatedChartConfig["SchoolIds"] = []
          updatedChartConfig["schools"] = []
          updatedChartConfig["ClusterIds"] = []
          updatedChartConfig["clusters"] = []
        }
        _.remove(updatedChartConfig.districts, x => x === label)
      } else updatedChartConfig.districts.push(label)
    } else if (field === 'SchoolIds') {
      if (value.length === 0) {
        updatedChartConfig["ClusterIds"] = []
        updatedChartConfig["clusters"] = []
      }
      updatedChartConfig['selectedId'] = undefined
      analyticMgr.grabGroup("Cluster", value).then(r => setClusters(r))
      if (updatedChartConfig.schools.includes(label)) {
        if(updatedChartConfig.schools.length > 1) {
          updatedChartConfig["ClusterIds"] = []
          updatedChartConfig["clusters"] = []
        }
        _.remove(updatedChartConfig.schools, x => x === label)
      } else updatedChartConfig.schools.push(label)
    }
    updatedChartConfig[field] = value

    //update org, district and school labels to match ids
    updatedChartConfig['schools'] = [];
    updatedChartConfig['districts'] = [];
    updatedChartConfig['organizations'] = [];

    updatedChartConfig.SchoolIds?.forEach(s => {
      let school = schools.find(x => x.SchoolId === s);
      if (school) {
        updatedChartConfig['schools'].push(school.Name);
      }
    });

    updatedChartConfig.DistrictIds?.forEach(d => {
      let district = districts.find(x => x.DistrictId === d);
      if (district) {
        updatedChartConfig['districts'].push(district.Name);
      }
    });

    updatedChartConfig.ClientIds?.forEach(c => {
      let org = orgs.find(x => x.ClientId === c);
      if (org) {
        updatedChartConfig['organizations'].push(org.Name);
      }
    });

    setChartConfig(updatedChartConfig)
  }
  const updateAnalyticConfig = (value, field, label) => {
    const updatedAnalyticConfig = { ...analyticConfig }
    updatedAnalyticConfig[field] = value
    if (field === "selectedOrg") {
      updatedAnalyticConfig['organizationName'] = label
      if (!value) {
        updatedAnalyticConfig['selectedDistrict'] = null
      }
      updateDistricts([value])
    } else if (field === 'selectedDistrict') {
      updatedAnalyticConfig['districtName'] = label
      if (!value) {
        updatedAnalyticConfig['selectedSchool'] = null
      }
      updateSchools([value])
    } else if (field === 'selectedSchool') {
      updatedAnalyticConfig['schoolName'] = label
    }

    setAnalyticConfig(updatedAnalyticConfig)
  }
  const isChartValid = () => {
    if (!chartConfig?.Name) {
      return false
    } else if (chartConfig?.AnalyticType === false || chartConfig?.AnalyticType === null || chartConfig?.AnalyticType === undefined || chartConfig?.AnalyticType === "UNK") {
      return false
    } else if (chartConfig?.useSpecificDates && (!chartConfig?.BeginDate || !chartConfig?.EndDate)) {
      return false
    }
    else return true
  }
  const retrieveAnalyticData = async () => {
    const updateChartConfig = { ...chartConfig }
    const updateAnalyticConfig = { ...analyticConfig }
    updateChartConfig.ClientIds = analyticConfig.selectedOrg ? [analyticConfig.selectedOrg] : []
    updateChartConfig.DistrictIds = analyticConfig.selectedDistrict ? [analyticConfig.selectedDistrict] : []
    updateChartConfig.SchoolIds = analyticConfig.selectedSchool ? [analyticConfig.selectedSchool] : []
    updateChartConfig.organizations = analyticConfig.organizationName ? [analyticConfig.organizationName] : []
    updateChartConfig.districts = analyticConfig.districtName ? [analyticConfig.districtName] : []
    updateChartConfig.schools = analyticConfig.schoolName ? [analyticConfig.schoolName] : []
    updateChartConfig.SchoolYearIdentifier = 0;
    let config;
    let result;
    if (analyticConfig.selectedSchool) {
      result = await analyticMgr.grabDefaultConfigByGroup('school', analyticConfig.selectedSchool)
      config = result[analyticConfig.viewingMode]
      updateAnalyticConfig.defaultGroupType = "school"
    }
    else if (analyticConfig.selectedDistrict) {

      result = await analyticMgr.grabDefaultConfigByGroup('district', analyticConfig.selectedDistrict)
      config = result[analyticConfig.viewingMode]
      updateAnalyticConfig.defaultGroupType = "district"
    }
    else if (analyticConfig.selectedOrg) {
      result = await analyticMgr.grabDefaultConfigByGroup('org', analyticConfig.selectedOrg)
      config = result[analyticConfig.viewingMode]
      updateAnalyticConfig.defaultGroupType = "org"
    }
    else if (analyticConfig.observationConfig) {
      result = await analyticMgr.grabDefaultConfigByGroup('observationConfig', analyticConfig.observationConfig.Id)
      config = result[analyticConfig.viewingMode]
      updateAnalyticConfig.defaultGroupType = "observationConfig"
    }
    if (result) {
      updateAnalyticConfig.defaultGroup = result
      setAnalyticConfig(updateAnalyticConfig)
      if (config)
        convertDataToCharts(config)
    } else if (result === 'Can not get group') {
      setErrors([result])
    }

    setChartConfig(updateChartConfig)
    setOpenEditingDefault(false)
    setLanding(false)
  }
  const orderCharts = () => {
    let reOrderedCharts = [];
    for (let row = 0; row < gridData.length; row++) {
      for (let col = 0; col < 3; col++) {
        const chart = _.find(charts, x => x.Id === gridData[row][col])
        if (chart && !_.find(reOrderedCharts, x => x.Id === gridData[row][col])) {
          reOrderedCharts.push(chart)
        }
      }
    }

    return reOrderedCharts
  }
  const saveLayout = async () => {
    setLoading(true)
    const reorderedCharts = orderCharts()
    let chartNullFields = _.map(reorderedCharts, x => {
      return {
        ...x,
        Data: undefined,
        editingMode: undefined,
        failToLoad: undefined,
      }
    })
    if (analyticConfig) {
      const defaultLayoutConfig = { ...analyticConfig.defaultGroup }
      defaultLayoutConfig[analyticConfig.viewingMode] = JSON.stringify(chartNullFields)
      analyticMgr.saveDefaultConfigByGroup(analyticConfig.defaultGroupType, defaultLayoutConfig)
    } else {
      await analyticMgr.saveChartLayout(chartNullFields)
      // setUpdatedAnalyticsConfig(JSON.stringify(chartNullFields))
    }

    setCharts(reorderedCharts)
    setLoading(false)
    setEditingLayout(false)
  }
  const handleDeleteChart = async (data) => {
    const updatedCharts = [...charts]
    const chartIndex = _.findIndex(charts, x => x.Id === data.Id)
    let result;
    if (chartIndex !== -1) {
      updatedCharts.splice(chartIndex, 1)
    }

    const grid = convertChartsToGrid(updatedCharts, true)
    setGridData(grid)
    setChartToDelete(null)
    setCharts(updateChartPositions(grid))
  }

  const handleEditChart = (data) => {
    setChartConfig({ ...data, editingMode: true })
    setPreviewChartData({ ...data, editingMode: true })
    setPreviewChartKeys(_.sortBy(data.keys, x => x.text))
    setOpenAddChartDialog(true)
    if (data.ClientIds && data.ClientIds.length > 0) {
      updateDistricts(data.ClientIds)
    }
    if (data.DistrictIds && data.DistrictIds.length > 0) {
      updateSchools(data.DistrictIds)
    }
    if (data.SchoolIds && data.SchoolIds.length > 0) {
      updateClusters(data.schoolIds)
    }
  }

  const updateDistricts = async (organizations) => {
    let districtList = []
    for (let i = 0; i < organizations.length; i++) {
      var r = await analyticMgr.grabGroup('District', organizations[i]);
      districtList = [...districtList, ...r]
    }

    setDistricts(districtList);
  }

  const updateSchools = async (districts) => {
    let schoolList = []
    for (let i = 0; i < districts.length; i++) {
      var r = await analyticMgr.grabGroup('School', districts[i]);
      schoolList = [...schoolList, ...r]
    }
    setSchools(schoolList)
  }
  const updateClusters = async (schools) => {
    analyticMgr.grabGroup("Cluster", schools).then(r => setClusters(r))
  }

  const memoizedChart = useMemo(() => <ChartControl
    type={previewChartData?.chartType}
    data={previewChartData}
    fixedHeight={"100%"}
    fixedWidth={"100%"}
  />, [previewChartData])
  return (
    <>
      <DialogControl className={'analytic-create-dialog-outer'} innerclassName={'analytic-create-dialog'}
        openDialog={openAddChartDialog} title={'Chart Configuration'}
        disableOk={!isChartValid()}
        disableXButton
        onCancel={() => {
          setOpenAddChartDialog(false)
          setChartConfig(!analyticConfig ? analyticMgr.DefaultItem : {
            ...analyticMgr.DefaultItem,
            ClientIds: chartConfig.ClientIds,
            DistrictIds: chartConfig.DistrictIds,
            SchoolIds: chartConfig.SchoolIds,
            organizations: chartConfig.organizations,
            districts: chartConfig.districts,
            schools: chartConfig.schools
          })
          setPreviewChartData(null)
          setPreviewSelectedKey(null)
          setPreviewChartKeys(null)
        }}
        okText={chartConfig?.editingMode ? "Save" : "Add"}
        onOk={() => addChartToLayout()}
      >
        <div className='analytic-left-col'>
          <InputField id='type'
            title='Analytic Type'
            value={chartConfig?.AnalyticType}
            fieldName='AnalyticType'
            disableError={true}
            onChange={(value, field) => updateChartConfig(value, field)}>
            <SelectListControl textValuePairs={Object.entries(SystemTypes.AnalyticType).map(([type, value]) => { return { text: analyticsTypeMap[type], value: value } })} />
          </InputField>
          <InputField
            title={'Analytic Name'}
            type="text"
            value={chartConfig?.Name}
            fieldName='Name'
            disableError={true}
            placeholder='Name'
            onChange={(value, field) => updateChartConfig(value, field)}>
          </InputField>
          <div className="size-selector-container">
            <h3>Size</h3>
            <div className="size-selectors">
              <div className="size-1" onClick={() => { updateChartConfig(1, 'Size') }}>
                <img src={chartConfig?.Size === 1 ? activeSize1 : inactiveSize1} alt="" />
              </div>
              <div className="size-2" onClick={() => { updateChartConfig(2, 'Size') }}>
                <img src={chartConfig?.Size === 2 ? activeSize2 : inactiveSize2} alt="" />
              </div>
              <div className="size-3" onClick={() => { updateChartConfig(3, 'Size') }}>
                <img src={chartConfig?.Size === 3 ? activeSize3 : inactiveSize3} alt="" />
              </div>
            </div>
          </div>
          <div className={"chart-preview-container" + (!previewChartData ? ' chart-preview-container-center' : '')}>
            {!previewChartData ?
              <ButtonControl type={'okay'} loading={loadingChart} disabled={!isChartValid()}
                onClick={createChart}>
                Preview Chart
              </ButtonControl> :
              !loadingChart ?
                <>
                  <div className="chart-preview-header-wrapper">
                    <h3 className="chart-preview-header">Chart Preview</h3>
                    <div className={"chart-update-btn " + (!isChartValid() ? 'chart-update-btn-disable' : '')} onClick={() => createChart(true)}> <img src={!isChartValid() ? refreshIconDisabled : refreshIcon} alt="RefreshIcon" /> Update</div>
                  </div>
                  <div className="chart-preview-chart-wrapper">
                    {previewChartData === "No Data" ? <div className='center-text'>{previewChartData}</div> :
                      <>
                        {((previewChartData.AnalyticType == 7 || previewChartData.AnalyticType == 5 || previewChartData.AnalyticType == 6) && previewChartKeys?.length > 0) &&
                          <InputField
                            fieldName={'preview-chart'}
                            disableError={true}
                            value={previewSelectedKey ?? previewChartKeys?.first()?.value}
                            onChange={(value) => { updatePreviewChart(value); }}
                          >
                            <SelectListControl hidePleaseSelect={true} textValuePairs={previewChartKeys} />
                          </InputField>}
                        {memoizedChart}
                      </>
                    }
                  </div>

                </>
                :
                <div className="center-text"> <Loading size={'3rem'} /></div>
            }

          </div>
        </div>
        <div className='analytic-right-col'>
          <div className="chart-config-row">
            <h3>Display Data By</h3>
            <div className="group-btn-wrapper">
              <div className={'group-btn' + (chartConfig?.GroupBy === "Client" ? ' group-btn-active' : '')} onClick={() => updateChartConfig("Client", "GroupBy")}><img src={GroupOrganizationIcon} alt="OrgIcon" />Organization</div>
              <div className={'group-btn' + (chartConfig?.GroupBy === "District" ? ' group-btn-active' : '')} onClick={() => updateChartConfig("District", "GroupBy")}><img src={GroupDistrictIcon} alt="DistrictIcon" />District</div>
              <div className={'group-btn' + (chartConfig?.GroupBy === "School" ? ' group-btn-active' : '')} onClick={() => updateChartConfig("School", "GroupBy")}><img src={GroupSchoolIcon} alt="SchoolIcon" />School</div>
            </div>
          </div>
          <div className="chart-config-row">
            <h3>Filters</h3>
            {
              orgs ? <InputField
                title='Organization'
                disableError={true}
                value={chartConfig?.ClientIds ?? []}
                fieldName={'OrGS'}
                key={'organizations'}
                isDisabled={analyticConfig && true}
              >
                <CheckPicker
                  id='organizations'
                  key="organizations"
                  labelKey='FullName'
                  valueKey='OrgId'
                  onClean={() => updateChartConfig([], 'ClientIds')}
                  onSelect={(value, label) => {
                    updateChartConfig(value, 'ClientIds', label.FullName)
                    updateDistricts(value)
                  }}
                  value={chartConfig?.ClientIds ?? []}
                  data={orgs ?? []}
                />
              </InputField> : <Loading />
            }
            <InputField
              title="District"
              key='districts'
              isDisabled={chartConfig?.ClientIds?.length === 0 || (analyticConfig && true)}
              value={chartConfig?.DistrictIds ?? []}
              disableError={true}
            >
              <CheckPicker
                id='districts'
                title='District'
                labelKey='FullName'
                onClean={() => updateChartConfig([], 'DistrictIds')}
                valueKey='DistrictId'
                value={chartConfig?.DistrictIds ?? []}
                onSelect={async (value, label) => {
                  updateChartConfig(value, 'DistrictIds', label.FullName)
                  updateSchools(value)
                }}
                data={districts ?? []}
              />
            </InputField>
            <InputField
              title='School'
              key='schools'
              isDisabled={chartConfig?.DistrictIds?.length === 0 || (analyticConfig && true)}
              value={chartConfig?.SchoolIds ?? []}
              disableError={true}
            >
              <CheckPicker
                id='schools'
                title='School'
                onClean={() => updateChartConfig([], 'SchoolIds')}
                key='schools'
                labelKey='FullName'
                valueKey='SchoolId'
                value={chartConfig?.SchoolIds ?? []}
                onSelect={async (value, label) => {
                  updateChartConfig(value, 'SchoolIds', label.FullName)
                }}
                data={schools ?? []}
              />
            </InputField>
            <InputField
              value={chartConfig?.useClusters}
              isDisabled={chartConfig?.SchoolIds?.length === 0 || analyticConfig}
              disableError={true}
            >
              <CheckboxControl
                className={'cluster-checkbox'}
                checked={chartConfig?.useClusters}
                onChange={(e) => { updateChartConfig(e.target.value, "useClusters") }}
              />
            </InputField>
            <InputField
              title='Clusters'
              key='clusters'
              value={chartConfig?.ClusterIds ?? []}
              isDisabled={!chartConfig?.useClusters}
              disableError
            >
              <CheckPicker
                id='clusters'
                key='clusters'
                labelKey='Name'
                onClean={() => updateChartConfig([], 'ClusterIds')}
                valueKey='Id'
                value={chartConfig?.ClusterIds ?? []}
                onSelect={(value) => {
                  updateChartConfig(value, 'ClusterIds')
                }}
                data={clusters ?? []}
              />
            </InputField>
          </div>
          {!analyticConfig && <div className="chart-config-row">
            <h3>Time</h3>
            <InputField
              title='School Year'
              fieldName={'SchoolYearIdentifier'}
              isDisabled={chartConfig?.useSpecificDates}
              value={chartConfig?.SchoolYearIdentifier}
              onChange={(value, fieldName) => updateChartConfig(value, fieldName)}
              disableError={true}
            >
              <SelectListControl
                textValuePairs={schoolYears}
              />
            </InputField>
            <InputField
              value={chartConfig?.useSpecificDates}
              disableError={true}
            >
              <CheckboxControl
                className={'date-checkbox'}
                checked={chartConfig?.useSpecificDates}
                onChange={(e) => { updateChartConfig(e.target.value, "useSpecificDates") }}
              />
            </InputField>
            <div className="date-wrapper">
              <InputField
                disableError={true}
                fieldName={"BeginDate"}
                isDisabled={!chartConfig?.useSpecificDates}
                value={moment(chartConfig?.BeginDate).local().format("YYYY-MM-DD") ?? ""}
                onChange={(value, fieldName) => updateChartConfig(value, fieldName)}
              >
                <DatePickerControl />
              </InputField>
              <InputField
                disableError={true}
                fieldName={"EndDate"}
                isDisabled={!chartConfig?.useSpecificDates}
                value={moment(chartConfig?.EndDate).local().format("YYYY-MM-DD") ?? ""}
                onChange={(value, fieldName) => updateChartConfig(value, fieldName)}
              >
                <DatePickerControl />
              </InputField>
            </div>
          </div>}
        </div>

      </DialogControl>

      <DialogControl
        title={`View/Edit Default Layout`}
        className={'analytic-create-dialog-outer'} innerclassName={'analytic-create-dialog analytic-default-dialog'}
        disableXButton
        openDialog={openEditingDefault}
        onCancel={() => {
          setOpenEditingDefault(false)
          setOrgs(orgTree)
          setAnalyticConfig(null)
        }}
        onOk={() => retrieveAnalyticData()}
      >
        <InputField
          title='Organization'
          key='organizationsDefault'
          value={analyticConfig?.selectedOrg}
          disableError={true}
        >
          <SelectPicker
            id='organizations'
            key='organizationsDefault'
            onClean={() => updateAnalyticConfig(null, 'selectedOrg')}
            labelKey='FullName'
            valueKey='OrgId'
            onSelect={(value, label) => {
              updateAnalyticConfig(value, 'selectedOrg', label.FullName)
            }}
            data={orgs ?? []}
          />
        </InputField>
        <InputField
          title="District"
          key='districtsDefault'
          isDisabled={!analyticConfig?.selectedOrg}
          value={analyticConfig?.selectedDistrict}
          disableError={true}
        >
          <SelectPicker
            id='districts'
            title='District'
            labelKey='FullName'
            onClean={() => updateAnalyticConfig(null, 'selectedDistrict')}
            valueKey='DistrictId'
            checkable='radio'
            onSelect={(value, label) => {
              updateAnalyticConfig(value, 'selectedDistrict', label.FullName)
            }}
            data={districts ?? []}
          />
        </InputField>
        <InputField
          title='School'
          key='schoolsDefault'
          isDisabled={!analyticConfig?.selectedDistrict}
          value={analyticConfig?.selectedSchool}
          disableError={true}
        >
          <SelectPicker
            id='schools'
            title='School'
            key='schools'
            onClean={() => updateAnalyticConfig(null, 'selectedSchool')}
            labelKey='FullName'
            valueKey='SchoolId'
            onSelect={(value, label) => {
              updateAnalyticConfig(value, 'selectedSchool', label.FullName)
            }}
            data={schools ?? []}
          />
        </InputField>
        <InputField
          title={"Default Layout for"}
          onChange={(value) => {
            updateAnalyticConfig(value, "viewingMode")
          }}
          value={analyticConfig?.viewingMode ?? "AnalyticDefaultLayoutTeacher"}
          disableError={true}
        >
          <SelectListControl
            hidePleaseSelect
            textValuePairs={[
              { text: "District Admin", value: "AnalyticDefaultLayoutDistrictAdmin" },
              { text: "School Admin", value: "AnalyticDefaultLayoutSchoolAdmin" },
              { text: "Teacher", value: "AnalyticDefaultLayoutTeacher" }]} />
        </InputField>
      </DialogControl>

      <DialogControl
        title={`Are you sure you want to delete ${chartToDelete?.Name}?`}
        disableXButton
        openDialog={chartToDelete}
        loadingOk={loading}
        onCancel={() => {
          setChartToDelete(false)
        }}
        onOk={() => {
          handleDeleteChart(chartToDelete)
        }}
      />

      <MainLayout className={'analytics'} errors={errors} validationErrors={validationErrors}>
        <ScreenWrapper
          loading={loading}
        >
          {
            !landing ? <>
              <div className="analytics-header-wrapper" >
                <h3 className="categoryTitle">
                  {!editingLayout && analyticMgr?.hasConfigureDefaultsPermissions() && <FontAwesomeIcon icon={faArrowLeft} className='left-arrow-icon'
                    onClick={() => {
                      setLanding(true)
                      setCharts(null)
                      setGridData([])
                      setChartConfig(analyticMgr?.DefaultItem)
                      setAnalyticConfig(null)
                    }} />}
                  Analytics
                </h3>
                {!editingLayout ?
                  <div className="button-wrapper">
                    <ButtonControl type={"okay"}
                      onClick={() => {
                        setEditingLayout(true)
                        setOriginalCharts(charts)
                        setOriginalGridData(_.cloneDeep(gridData))
                        setOriginalStyles(styles)
                      }}>
                      Edit Layout
                    </ButtonControl>
                  </div>
                  :
                  <div className="button-wrapper">
                    <ButtonControl type={"cancel"} onClick={() => {
                      setEditingLayout(false)
                      setCharts(originalCharts)
                      setGridData(originalGridData)
                      setStyles(originalStyles)
                    }}>Cancel</ButtonControl>
                    <ButtonControl type={"cancel"} onClick={() => saveLayout()}>{!analyticConfig ? "Save Layout" : "Save Default Layout"}</ButtonControl>
                    <ButtonControl type={"create"} onClick={() => setOpenAddChartDialog(true)}>Add Chart</ButtonControl>

                  </div>
                }

              </div>
              {
                charts && charts.length > 0 ? <div className="analytics-grid-container" style={{ height: `${screenHeight}px` }} >
                  {
                    charts?.map((chart, index) => {
                      return <AnalyticsChart
                        key={index}
                        editingLayout={editingLayout}
                        id={chart.Id}
                        data={chart}
                        size={chart.Size}
                        type={chart.chartType}
                        failToLoad={chart.failToLoad}
                        styles={styles}
                        updateGrid={updateGrid}
                        handleEdit={handleEditChart}
                        handleDelete={setChartToDelete}
                        setErrors={setErrors}
                      />
                    })
                  }
                </div> :
                  <div className="analytic-instructions">
                    You currently do not have any charts configured. Select "Edit Layout" and then "Add Chart" to begin adding charts to your Analytics Dashboard.
                  </div>
              }
            </>
              :
              <div className="landing-btn-wrapper">
                {orgs ? <>
                  <ButtonControl
                    type={'okay'}
                    loading={loadingObservationConfigs}
                    onClick={() => {
                      setLanding(false)
                      convertDataToCharts()
                    }}>
                    View Your Analytics
                  </ButtonControl>
                  <ButtonControl
                    type={'okay'}
                    loading={loadingObservationConfigs}
                    onClick={() => {
                      setOpenEditingDefault(true)
                      setAnalyticConfig({
                        ...analyticConfig,
                        viewingMode: 'AnalyticDefaultLayoutTeacher',
                        observationConfig: analyticMgr.AppUserState.observationConfig
                      })
                    }}>
                    View Default Analytics
                  </ButtonControl>
                </> : <Loading size={'5rem'} />}
              </div>
          }
        </ScreenWrapper>
      </MainLayout >
    </>

  )
}

export default Analytics