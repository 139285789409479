
import { useEffect, useState } from 'react';
import { InputField } from "../../../../components/controls/InputField";
import { SelectPicker, Checkbox } from 'rsuite';
import ButtonControl from "../../../../components/controls/ButtonControl";
import { asyncForEach } from "../../../contentLibrary/utils/jsUtil";
import DropdownMenu from 'rsuite/esm/Dropdown/DropdownMenu';
import './../../payout.scss';
import achievementTypes from '../../../dev/data/achievementTypes';
import triangle from './assets/triangle_icon.jpg';
import { faFlag, faCircleExclamation, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './../../../admin/components/payout/styles/adminRecipients.scss';
import _ from 'lodash';
import moment from 'moment/moment';
import Loading from '../../../../components/Loading';

const PayoutAdminRecipients = ({ data, metricData, payoutInfo, appUserMgr, onNext, onPrevious, onExport }) => {
    const [recipeients, setRecipients] = useState();
    const [rows, setRows] = useState([]);
    const [updatedRows, setUpdatedRows] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [showPaymentSettings, setShowPaymentSettings] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState();
    const [selectedDistrict, setSelectedDistrict] = useState();
    const [selectedSchool, setSelectedSchool] = useState();
    const [selectedPayoutRole, setSelectedPayoutRole] = useState('');
    const [selectedAchievementType, setSelectedAchievementType] = useState('');
    const [columns, setColumns] = useState([
        {
            header: 'School Administrator',
            dataProp: "EligiblePayee",
            width: '17%',
            canSort: true,
            boldText: true,
            leftSide: true
        },
        {
            header: 'School Name',
            dataProp: 'SchoolName',
            width: '18%',
            isBool: true,
            canSort: true
        },
        {
            header: 'Assistant Principal',
            dataProp: "IsAssistantPrincipal",
            width: '5%',
            isBool: true,
            canSort: true
        },
        {
            header: 'Exclude',
            dataProp: "IsExclude",
            width: '5%',
            isBool: true,
            leftAlign: true,
            canSort: true
        },
        {
            header: 'Apply % Payout',
            dataProp: 'PayoutPct',
            width: '8%',
            canSort: true,
            centerInput: true,
        },
        {
            header: 'Comment',
            dataProp: 'Comment',
            width: '10%',
            canSort: true,
            lastCol: true
        },
    ])

    useEffect(() => {
        let newRows = [];
        if (data && metricData && (!rows || rows.length === 0)) {
            updateColumns(metricData.filter(x => x.IsActive));
            asyncForEach(data, (u) => {
                populateCustomWithOriginals(u)
                newRows.push({
                    ...u,
                    SchoolName: u.School?.Name,
                    IsExclude: !u.IsActive,
                    PayoutAdminConfigurationId: u.PayoutAdminConfigurationId ?? metricData?.first().PayoutAdminConfigurationId,
                    isEdited: !u.PayoutAdminConfigurationId
                });
            }).then(() => {
                setRows(newRows);
                setUpdatedRows(newRows);
                setLoading(false);
            });
        }
    }, [data, metricData]);

    const populateCustomWithOriginals = (row) => {
        for (const key in row) {
            if (key.startsWith("Original") && row[key] !== null && row[key] !== undefined && (row[key.replace("Original", "")] === null || row[key.replace("Original", "")] === undefined)) {
                const currentValue = key.replace("Original", "");
                row[currentValue] = row[key];
            }
        }
    }
    const updateColumns = (metrics) => {
        //find index of Exclude, add metrics right after
        const excludeColumnIndex = columns.findIndex((col) => col.dataProp === 'IsExclude');
        const sortedMetrics = _.orderBy(metrics, ['Sequence'], ['Desc'])
        if (excludeColumnIndex !== -1) {
            const newColumns = [...columns];

            sortedMetrics.forEach((metric, i) => {
                const newMetricColumn = {
                    header: metric.Name + ' (' + metric.PrincipalAllocation + '/' + metric.AssistantPrincipalAllocation + '%)',
                    dataProp: `CustomMetric${metric.Sequence}`,
                    width: `${58 / (metrics?.length + 2)}%`,
                    centerInput: true,
                };
                newColumns.splice(excludeColumnIndex + metric.Sequence, 0, newMetricColumn);
            });
            setColumns(newColumns);
        }
    }


    const handleOnChange = (rowId, selectedValue, dataProp) => {
        if (dataProp === 'PayoutPct' && selectedValue === '') {
            selectedValue = 100
        }
        setUpdatedRows(updatedRows.map((row) => {
            if (row.Id === rowId) {
                return {
                    ...row,
                    [dataProp]: selectedValue,
                    isEdited: true,
                };
            }
            return row;
        }));

    }
    const handleOnCheckBox = (rowId, dataProp) => {
        if (dataProp === 'IsExclude')
            dataProp = 'IsActive'
        setUpdatedRows(updatedRows.map((row) => {
            if (row.Id === rowId) {
                return {
                    ...row,
                    [dataProp]: !row[dataProp],
                    isEdited: true,
                };
            }
            return row;
        }));
    };


    const renderHeader = () => {
        let jsxCols = columns.reduce((r, col) => {
            r.push(
                <div key={col.header} className={`col ${col.leftSide ? 'left-side' : ''} ${col.lastCol ? 'last-col' : ''}`}
                    style={{ width: col.width }}
                >
                    {col.header}
                </div>
            )
            return r;
        }, []);

        return <div className={'header-row'}>{jsxCols}</div>
    };
    const renderRows = () => {
        const dataRows = _.orderBy(rows, ['EligiblePayee'], ['asc']).map((dataRecord, i) => {
            //If CompletedDate < UpdatedDate, then show the flag

            const flagIcon = moment(dataRecord.CompletedDate).format('YYYY-MM-DDTHH:mm') < moment(dataRecord.UpdatedDate).format('YYYY-MM-DDTHH:mm') ? (
                <FontAwesomeIcon icon={faFlag} className="flag-icon" />
            ) : null;

            const minObsIndicator = (dataRecord['HasMinimumObservations'] === false) ? true : false;

            const columnsElements = columns.map((col, colIndex) => {
                const dataRowValue = dataRecord[col.dataProp];
                const originalValue = dataRecord[`Original${col.dataProp}`];
                let colElement;

                //check if the original value is diff from pair
                //if so, assign a css class style to that control
                if (col.dataProp === 'EligiblePayee') {
                    colElement = <>
                        {flagIcon}
                        <div className='col-name' title={dataRowValue}>{dataRowValue} {minObsIndicator ? <FontAwesomeIcon icon={faCircleExclamation} className="obs-icon" /> : ''}</div>
                    </>

                } else if (col.dataProp === 'CustomMetric2') {

                    let showSkrScore =  (dataRecord['HasMinimumObservations'] === false && appUserMgr?.AppUserState?.userProfile?.Roles?.some(x => x == 'SysAdmin' || x == 'NIETAdmin')) ? true : 
                    dataRecord['HasMinimumObservations'] === true ? true : false;

                    let originalValue = _.isNumber(dataRecord[`Original${col.dataProp}`]) && dataRecord[`Original${col.dataProp}`] < 0 ? null : dataRecord[`Original${col.dataProp}`];
                    const isChangedInputIcon = <img src={triangle} alt="Changed Icon" className="is-changed-input-icon" />
                    let currentValue = rows.find(x => x.Id === dataRecord.Id)[col.dataProp]

                    const inputValue = (_.isNil(currentValue) || !showSkrScore) ? null :
                                    (_.isNumber(currentValue) && currentValue < 0 ? null : currentValue.toString());

                    const isDisabled = (_.isNil(originalValue) || !showSkrScore);
                    
                    colElement = (
                        <>
                            <div className="tooltip-container">
                                <InputField
                                    id={`${dataRecord.Id}+${colIndex}`}
                                    disableError={true}
                                    isDisabled={isDisabled}
                                    type={'number'}
                                    value={inputValue}
                                    onChange={(e) => {
                                        handleOnChange(dataRecord.Id, e, col.dataProp);
                                    }} />
                                {rows.find(x => x.Id === dataRecord.Id)[col.dataProp] != originalValue && !_.isNil(originalValue) && originalValue > 0 && <div className="tooltip">{originalValue}</div>}
                                {rows.find(x => x.Id === dataRecord.Id)[col.dataProp] != originalValue && !_.isNil(originalValue) && originalValue > 0 && isChangedInputIcon}
                            </div>
                        </>); 
                }
                else if (
                    col.dataProp.includes('CustomMetric') ||
                    col.dataProp === 'PayoutPct' ||
                    col.dataProp === 'SurveyScore' ||
                    col.dataProp === 'Comment') {
                    const isChangedInputIcon = <img src={triangle} alt="Changed Icon" className="is-changed-input-icon" />

                    let originalValue = _.isNumber(dataRecord[`Original${col.dataProp}`]) && dataRecord[`Original${col.dataProp}`] < 0 ? null : dataRecord[`Original${col.dataProp}`];
                    let currentValue = updatedRows.find(x => x.Id === dataRecord.Id)[col.dataProp]
                    colElement = (
                        <div className="tooltip-container">
                            <InputField
                                id={`${dataRecord.Id}+${colIndex}`}
                                disableError={true}
                                isDisabled={col.dataProp === "CustomMetric1"}
                                value={_.isNumber(currentValue) && currentValue < 0 ? null : currentValue}
                                onChange={(e) => {
                                    handleOnChange(dataRecord.Id, e, col.dataProp);
                                }}
                            />
                            {currentValue != originalValue && !_.isNil(originalValue) && originalValue >= 0 && <div className="tooltip">{originalValue}</div>}
                            {currentValue != originalValue && !_.isNil(originalValue) && originalValue >= 0 && isChangedInputIcon}
                        </div>
                    );

                } else if (col.dataProp === 'IsExclude' || col.dataProp === 'IsAssistantPrincipal') {
                    colElement = (
                        <>
                            <Checkbox
                                id={`${dataRecord.Id}+${colIndex}`}
                                checked={col.dataProp === 'IsExclude' ? (!updatedRows.find(x => x.Id === dataRecord.Id)["IsActive"]) : updatedRows.find(x => x.Id === dataRecord.Id)[col.dataProp]}
                                onChange={() => handleOnCheckBox(dataRecord.Id, col.dataProp)}
                            />
                            {/* {col.dataProp === 'IsExclude' ? updatedRows.find(x => x.Id === dataRecord.Id)["IsActive"] != dataRecord['OriginalIsActive'] && isChangedIcon
                                :
                                updatedRows.find(x => x.Id === dataRecord.Id)[col.dataProp] != dataRecord['Original' + col.dataProp] && isChangedIcon} */}
                        </>
                    );

                } else {
                    colElement = <div className='wrap-text'>{dataRowValue}</div>;
                }

                return (
                    <div
                        key={`${col.header}_${dataRowValue}`}
                        style={{ width: col.width }}
                        className={`col ${col.boldText ? 'bold-text' : ''} ${col.lastCol ? 'last-col' : ''} ${col.isBool ? 'center-item' : ''} ${col.centerInput ? 'center-input' : ''}`}>
                        {colElement}
                    </div>
                );
            });

            return (
                <div key={i} className="data-row">
                    {columnsElements}
                </div>
            );
        });

        return <div className="row-area">{dataRows}</div>;
    };
    return (
        <>
        {!loading ?
            <>
            <div className='payout-teacher-table'>
                <div className='payout-recipients-title'>
                    <h2>Edit School Administrator Data</h2>
                    <h5>{payoutInfo?.Client?.Name} &gt; {payoutInfo?.District?.Name}</h5>
                </div>
                <div className='recipientTable'>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <div className='wrapper'>
                                {renderHeader()}
                                {renderRows()}
                            </div>
                            {error &&
                                <div className='error-msg'>*{errorMessage}</div>
                            }
                            <div className="button-row admin-payout-button-row">
                                <div className='min-obs-indicator' ><FontAwesomeIcon icon={faCircleExclamation} className="obs-icon" /> Indicates Minimum Observations Not Met</div>
                                <div className='button-wrapper'>

                                    <div className='btn-wrapper-left screen-footer__btn'>
                                        <ButtonControl disabled={saving} type={'cancel'} onClick={onPrevious}>Cancel</ButtonControl>
                                    </div>
                                    <div className='btn-wrapper-left screen-footer__btn'>
                                        <ButtonControl loading={saving} type={'cancel'} onClick={onExport}>Export Data</ButtonControl>
                                    </div>
                                    <div className='btn-wrapper-left screen-footer__btn'>
                                        <ButtonControl disabled={saving} type={'okay'} onClick={() => onNext(updatedRows)}>{appUserMgr.canWrite('ConfigurePayoutAdminConfiguration') ? 'Save & Continue' : 'Save'}</ButtonControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            :
            <>
            <div className='loading-container-admin'>
                <Loading type='default' size='5rem' /><div className='loading-font'>Loading...</div>
            </div>            
            </>
        }
        </>
    )
}

export default PayoutAdminRecipients