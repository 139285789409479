import { useEffect, useState } from 'react';
import './details.scss';
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import _ from 'lodash';
import { CheckboxControl, SelectListControl } from '../../../../components/controls';
import { useValidationManagers } from '../../../../hooks/useValidation';
import Loading from '../../../../components/Loading';
import { useRubricManager } from '../../../../hooks/useManagers';
import { DialogControl } from '../../../../components/controls/DialogControl';
import { faCaretDown, faCaretUp, faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Likert from './Likert';

const SurveyTemplateDetails = ({ isSurveyTemplateActive, canWrite, organizations, surveyTemplateMgr, surveyTemplate, onCancel, onSave, onErrors, onValidationErrors }) => {

    const rubricMgr = useRubricManager();
    const [surveyTemplateItem, setSurveyTemplateItem] = useState(surveyTemplate ?? surveyTemplateMgr?.DefaultItem);
    const [saving, setSaving] = useState(false);
    const [fieldItem, setFieldItem] = useState([]);
    const [filterInput, setFilterInput] = useState('');
    const [surveyFields, setSurveyFields] = useState(null);
    const [openFieldDialog, setOpenFieldDialog] = useState(false);
    const [rubricList, setRubricList] = useState(null);
    const [forceValidate, setForceValidate] = useState(false);
    const [forceFieldValidate, setForceFieldValidate] = useState(false);
    const [openLikertDialog, setOpenLikertDialog] = useState(false)
    const [openUseRubricLikert, setOpenUseRubricLikert] = useState(false)
    const [observationConfig, setObservationConfig] = useState(null)
    const [rubricLoading, setRubricLoading] = useState(false)
    const [likertData, setLikertData] = useState([])
    const [idtoDelete, setIdToDelete] = useState([])
    const validationMgr = useValidationManagers(false, 'SURVEYTEMPLATE');
    const fieldValidationMgr = useValidationManagers(false, 'SURVEYTEMPLATE_FIELD');
    const [titleToDelete, setTitleToDelete] = useState(null);

    useEffect(() => {
        if (rubricMgr && !rubricList) {
            const nextSchoolYear = rubricMgr.AppUserState.currentSchoolYear + 1;
            const nowSchoolYear = rubricMgr.AppUserState.currentSchoolYear;
            rubricMgr.ItemManager.query("x => x.IsActive == true && x.IsDeleted == false && x.Status != 1 && x.SchoolYearIdentifier == nowSchoolYear || x.SchoolYearIdentifier == nextSchoolYear", { nextSchoolYear, nowSchoolYear }, false, ["RubricRatingMap", "SchoolYear"]).then(r => {
                if (r?.Success) {
                    setRubricList(r?.Items);
                }
            });
        }
    }, [rubricMgr])

    useEffect(() => {
        if (surveyTemplateMgr && !surveyFields && surveyTemplateItem) {
            setSurveyFields(_.orderBy(resequenceFields(_.filter(surveyTemplateItem.SurveyTemplateFields ?? [], y => !y.IsDeleted), x => x.Sequence)));
            if (surveyTemplateItem.SurveyLikertMap) {
                setLikertData(JSON.parse(JSON.stringify(surveyTemplateItem?.SurveyLikertMap)))
            }
        }
    }, [surveyTemplateMgr, surveyTemplateItem])

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    const handleOnSave = async (saveAndContinue) => {
        const validateResult = validationMgr.checkIsGroupValid(surveyTemplateItem);
        if (validateResult?.length === 0) {
            setForceValidate(false);
            if (onSave) {
                setSaving(true);

                surveyTemplateItem.SurveyTemplateFields = null;
                surveyTemplateItem.Rubric = null;
                surveyTemplateItem.SurveyLikertMap = null;
                var result = await onSave(surveyTemplateItem);
                if (result?.Success) {
                    const fieldsToSave = _.map(surveyFields, (field) => { return { ...field, SurveyTemplateId: result.Items.first().Id } });
                    var fieldResultSave = await surveyTemplateMgr.saveFields(_.filter(fieldsToSave, x => !x.IsDeleted));
                    var fieldResultDelete = await surveyTemplateMgr.deleteFields(_.filter(surveyFields, x => x.IsDeleted && x.Id));
                    var likertResults = await surveyTemplateMgr.updateLikert(_.map(likertData, x => { return { ...x, SurveyTemplateId: result.Items.first().Id } }), idtoDelete)
                    if (result.Success) {
                        result.Success = false;
                        let updatedSurveyFields = [...surveyFields];
                        _.each(fieldResultSave, (r) => {
                            if (r?.first().Success) {
                                const savedItem = r?.first()?.Items?.first();
                                const item = _.find(updatedSurveyFields, x => x.Title.toLowerCase() === savedItem.Title.toLowerCase());
                                if (!item?.Id) {
                                    item.Id = savedItem.Id;
                                }
                            }
                        });

                        _.each(fieldResultDelete, (r) => {
                            const deletedItem = r?.first().Items.first();
                            updatedSurveyFields = _.filter(updatedSurveyFields, x => x.Id !== deletedItem.Id);
                        });


                        const fieldsUpdated = _.orderBy(updatedSurveyFields, x => x.Sequence);
                        setSurveyFields(fieldsUpdated);
                        if (surveyTemplateMgr._allData) {
                            const allData = [...surveyTemplateMgr._allData];
                            const item = _.find(allData, x => x.Id === result.Items.first().Id);
                            item.SurveyTemplateFields = fieldsUpdated;
                            item.Rubric = result.Items.first().Rubric;
                            surveyTemplateMgr.filterData(undefined, allData);
                        }

                        if (!saveAndContinue) {
                            setSaving(false);
                            handleOnCancel();
                            return result;
                        }
                        else {
                            setSaving(false);
                            return { Success: false };
                        }
                    }
                }
                else if (onErrors) {
                    setSaving(false);
                    onErrors([result?.Message?.DisplayMessage ?? [...(result?.MessageDetails ?? ['Unknown Error'])]])
                }


            }

            return result;
        }
        else if (onErrors) {
            setForceValidate(true);
            onValidationErrors(validateResult);
        }
    }

    const handleOnChange = (value, field) => {
        if (value === 'UNK') {
            value = null;
        }
        // if (field === 'ClientId') {
        //     setRubricLoading(true)
        //     surveyTemplateMgr.getObservationConfig(value).then((r) => {
        //         if (r.Success && r.Items.first()) {
        //             setObservationConfig(r.Items.first())
        //             grabRubric(r.Items.first().)
        //         } else {
        //             setRubricLoading(false)

        //         }
        //     })
        // }
        let updatedSurveyTemplate = { ...surveyTemplateItem };
        updatedSurveyTemplate[field] = value;
        setSurveyTemplateItem(updatedSurveyTemplate);
    }

    const onTreeClean = (id) => {
        handleOnChange(null, id);
    }

    const handleOnOpenFieldDialog = (field) => {
        if (!saving) {
            setFieldItem(field);
            setOpenFieldDialog(true);
        }
    }

    const saveField = () => {
        const validateResult = fieldValidationMgr.checkIsGroupValid(fieldItem);
        const moreThenOneField = _.filter(surveyFields, x => x.Title.toLowerCase() === fieldItem.Title?.toLowerCase()).length > (fieldItem.IsNew ? 0 : 1);
        if (validateResult?.length === 0 && !moreThenOneField) {
            setForceFieldValidate(false);
            let updatedSurveyFields = [...surveyFields ?? []];
            const updateFieldItem = _.find(updatedSurveyFields, x => x.Title.toLowerCase() === fieldItem.Title.toLowerCase());
            if (updateFieldItem) {
                updateFieldItem.IsDeleted = false;
                updatedSurveyFields = _.map(updatedSurveyFields, field => {
                    if (field.Title.toLowerCase() === fieldItem.Title.toLowerCase()) {
                        return fieldItem;
                    }
                    else
                        return field;
                });
            }
            else {
                fieldItem.IsNew = undefined;
                fieldItem.Sequence = 99999;
                updatedSurveyFields.unshift({ ...fieldItem });
            }

            setSurveyFields(resequenceFields(updatedSurveyFields));
            cancelField();
            return { Success: true };
        }
        else {
            setForceFieldValidate(true);
            if (moreThenOneField) {
                return { Success: false, Message: { DisplayMessage: ['Please select a different field title. The entered title already exists.'] } };
            }
            else
                return { Success: false };
        }


    }

    const cancelField = () => {
        setForceFieldValidate(false);
        setFieldItem(null);
        setOpenFieldDialog(false);
    }

    const onChangeAddField = (value, field) => {
        let updateFieldItem = { ...fieldItem };
        updateFieldItem[field] = value;
        setFieldItem(updateFieldItem);
    }


    const handleOnDeleteField = () => {
        const title = titleToDelete;
        const updatedSurveyFields = [...surveyFields];
        const fieldToDelete = _.find(updatedSurveyFields, x => x.Title.toLowerCase() === title.toLowerCase());
        fieldToDelete.IsDeleted = true;
        setSurveyFields(resequenceFields(updatedSurveyFields));
        setTitleToDelete(null);
    }

    const handleDeleteLikertItem = (value) => {
        setIdToDelete([...idtoDelete, ...value])
    }

    const openConfirmDialog = (title) => {
        if (!saving) {
            setTitleToDelete(title);
        }
    }

    const resequenceFields = (updatedSurveyFields) => {
        let newUpdatedSurveyFields = [...updatedSurveyFields];
        newUpdatedSurveyFields = _.orderBy(newUpdatedSurveyFields, x => x.Sequence);
        const notDeletedFields = _.map(_.filter(newUpdatedSurveyFields, x => !x.IsDeleted), (field, i) => { return { ...field, Sequence: i + 1 } });
        const deletedFields = _.map(_.filter(newUpdatedSurveyFields, x => x.IsDeleted), (field, i) => { return { ...field, Sequence: 99999 } });
        return [...notDeletedFields, ...deletedFields];
    }

    const updateFieldSequence = (currentSequence, newSequence) => {
        if (!saving) {
            if (newSequence > 0 && newSequence <= surveyFields.length) {
                const updatedSurveyFields = [...surveyFields];
                const fieldToSequenceCurrent = _.find(updatedSurveyFields, x => x.Sequence === currentSequence);
                const fieldToSequenceNew = _.find(updatedSurveyFields, x => x.Sequence === newSequence);
                fieldToSequenceCurrent.Sequence = newSequence;
                fieldToSequenceNew.Sequence = currentSequence;
                setSurveyFields(_.orderBy(updatedSurveyFields, x => x.Sequence));
            }
        }
    }

    const getFieldTypes = () => {

        if (surveyTemplateItem.SurveyType != 2) {
            return [
                { Text: 'Text Area', Value: 1 },
                { Text: 'Likert', Value: 5 },
            ];
        }
        else {
            return [
                { Text: 'Text Area', Value: 1 },
                { Text: 'Single Select', Value: 2 },
                { Text: 'Multi Select ', Value: 3 },
                { Text: 'File Uploader', Value: 4 },
                { Text: 'Likert', Value: 5 },
            ];
        }
    }


    const updateLikertData = async () => {
        const rubricItem = _.find(rubricList, x => x.Id === surveyTemplateItem.RubricId)
        const grabRubricLikertData = _.map(rubricItem.RubricRatingMap, x => rubricMgr.RatingItemManager.get(x.RubricRatingId).then(r => { return r.Items.first() }))
        const result = await Promise.all(grabRubricLikertData)
        setIdToDelete([...idtoDelete, ...likertData])
        if (!_.isEmpty(result)) {
            setLikertData(_.map(result, x => {
                return {
                    Title: x.Title,
                    Value: x.Value,
                }
            }))
        }
        setOpenUseRubricLikert(false)
    }
    return <>
        <DialogControl
            openDialog={titleToDelete}
            title={'Confirm Delete'}
            subTitle={`Are you sure you would like to delete '${titleToDelete}'?`}
            onCancel={() => setTitleToDelete(null)}
            onOk={handleOnDeleteField}
            okText={'Ok'}>
        </DialogControl>
        <div className="custom-dialog">
            <DialogControl
                openDialog={openUseRubricLikert}
                title={'Would you like to prepopulate the likert scale?'}
                subTitle={`This will override any changes made to the existing scale.`}
                onCancel={() => setOpenUseRubricLikert(false)}
                onOk={updateLikertData}
                disableXButton={true}
                cancelText={'No'}
                okText={'Yes'}>
            </DialogControl>
        </div>
        <DialogControl
            openDialog={openFieldDialog}
            title={'Add Field'}
            subTitle={'Please enter field information below.'}
            onCancel={cancelField}
            onOk={saveField}
            okText={fieldItem?.IsNew ? 'Add Field' : 'Save Field'}
        >
            <>
                <InputField forceValidate={forceFieldValidate} type="text" isDisabled={!fieldItem?.IsNew} value={fieldItem?.Title} title="Field Title" subTitle={'Title must be unique and cannot be editied once the field has been created. "Field Title" is used for reporting only.'} fieldName="Title" groupId={'SURVEYTEMPLATE_FIELD'} onChange={onChangeAddField} />
                <InputField forceValidate={forceFieldValidate} type="textarea" maxLength={500} value={fieldItem?.DisplayText} title="Display Text or Question" fieldName="DisplayText" groupId={'SURVEYTEMPLATE_FIELD'} onChange={onChangeAddField} />
                <InputField forceValidate={forceFieldValidate} isDisabled={isSurveyTemplateActive} value={fieldItem?.SurveyFieldType} subTitle={'Selecting Indicators or Performance Levels "Field Types" will populate the options based on the data within the selected rubric.'} title="Field Type" fieldName="SurveyFieldType" groupId={'SURVEYTEMPLATE_FIELD'} onChange={onChangeAddField} >
                    <SelectListControl textValuePairs={getFieldTypes()} />
                </InputField>
                {fieldItem?.SurveyFieldType == 1 && <InputField value={fieldItem?.IsOptional} title="Optional" fieldName="IsOptional" onChange={onChangeAddField} >
                    <CheckboxControl />
                </InputField>}
                {(fieldItem?.SurveyFieldType == 2 || fieldItem?.SurveyFieldType == 3) && <InputField type="textarea" subTitle={'Please place new options on a separate line.'} maxLength={8000} value={fieldItem?.OptionsData} title="Field Options" fieldName="OptionsData" onChange={onChangeAddField} />}
            </>
        </DialogControl>
        {
            <Likert isSurveyTemplateActive={isSurveyTemplateActive} setOpenDialog={setOpenLikertDialog} openDialog={openLikertDialog} data={likertData} saveLikert={setLikertData} handleOnChange={handleOnChange} surveyTemplateItem={surveyTemplateItem} deleteId={handleDeleteLikertItem} />
        }

        <div className='control-box-wrapper survey-builder-detail-wrappers'>
            {surveyTemplateItem && <>
                <div className='control-box box-four-column'>
                    <InputField forceValidate={forceValidate} type="text" value={surveyTemplateItem?.Title} title="Title" fieldName="Title" groupId={'SURVEYTEMPLATE'} onChange={handleOnChange} />
                    {surveyTemplateMgr && <InputField forceValidate={forceValidate} value={surveyTemplateItem?.SurveyType} subTitle={'Survey type cannot be changed once the survey has been created.'} isDisabled={surveyTemplateItem?.Id} title="Survey Type" fieldName="SurveyType" groupId={'SURVEYTEMPLATE'} onChange={handleOnChange} >
                        <SelectListControl textValuePairs={surveyTemplateMgr?.SurveyTypes ?? []} disabled={surveyTemplateItem?.Id} />
                    </InputField>}
                    {(surveyTemplateItem.SurveyType == 4 || surveyTemplateItem.SurveyType == 5) && <><InputField forceValidate={forceValidate} type="textarea" maxLength={500} value={surveyTemplateItem?.Description} title="Survey Instructions" fieldName="Description" groupId={'SURVEYTEMPLATE'} onChange={handleOnChange} />
                        {organizations ? <InputField
                            forceValidate={forceValidate}
                            value={surveyTemplateItem?.ClientId}
                            subTitle={'By selecting an organization this template will only be avaliable for the selected organization.'}
                            title="Organization"
                            fieldName="ClientId"
                            groupId={'SURVEYTEMPLATE'}
                            isDisabled={isSurveyTemplateActive}
                            onChange={handleOnChange}
                        >
                            <SelectListControl textValuePairs={_.map(organizations, o => { return { text: o.Name, value: o.OrgId } })} />
                        </InputField> : <Loading />
                        }</>}
                    <InputField title="Active?" value={(surveyTemplateItem?.IsActive === undefined || surveyTemplateItem?.IsActive === null) ? true : surveyTemplateItem?.IsActive} fieldName="IsActive" groupId={'SURVEYTEMPLATE'} onChange={handleOnChange} >
                        <CheckboxControl />
                    </InputField>
                </div>
                <div className='control-box box-four-column'>
                    {(surveyTemplateItem?.SurveyType !== "UNK" && surveyTemplateItem?.SurveyType) && <>
                        {rubricMgr && rubricList ? <InputField forceValidate={forceValidate} value={surveyTemplateItem?.RubricId ?? 'UNK'} isDisabled={isSurveyTemplateActive} subTitle={'Select a rubric if required for populating fields.'} title="Rubric" fieldName="RubricId" groupId={'SURVEYTEMPLATE'}
                            onChange={(value, fieldName) => {
                                handleOnChange(value, fieldName)
                                if (value !== "UNK") {
                                    setOpenUseRubricLikert(true)
                                }
                            }} >
                            <SelectListControl textValuePairs={_.map(rubricList ?? [], x => { return { Text: x.Name + ` (${x.SchoolYear?.Title})`, Value: x.Id } })} />
                        </InputField> : <div className='loading-wrapper'>  <Loading /> </div>}
                        {(surveyTemplateItem.RubricId && surveyTemplateItem?.SurveyType == 2) && <InputField title="Show Rubric?" value={surveyTemplateItem?.ShowRubric} fieldName="ShowRubric" groupId={'SURVEYTEMPLATE'} onChange={handleOnChange} >
                            <CheckboxControl />
                        </InputField>}
                        <div className="detail-field">
                            <ButtonControl onClick={() => setOpenLikertDialog(true)}>Manage Likert</ButtonControl>
                        </div>
                    </>}
                </div>
                <div className='control-box box-two-column role-perm-editor__wrapper survey-field-table'>
                    <div className='role-perm-editor__header'>
                        <div>
                            <h3>Survey Fields</h3>
                            <h5>Pressing cancel without saving will remove any newly added Survey Fields.</h5>
                        </div>
                        <ButtonControl disabled={isSurveyTemplateActive} onClick={() => handleOnOpenFieldDialog({ Sequence: 1, IsNew: true })}>Add Field</ButtonControl>
                    </div>
                    <div className='role-perm-editor__search'>
                        <InputField
                            type="text"
                            value={filterInput}
                            fieldName='search'
                            disableError={true}
                            placeholder='Search Fields'
                            onChange={(value) => setFilterInput(value?.toLowerCase())}>
                        </InputField>
                    </div>
                    <div className='detail-table-wrapper'>
                        <table className="__table">
                            <thead className="__head">
                                <tr>
                                    <th className="__name">Title</th>
                                    <th className="__flex">Type</th>
                                    <th className="__flex">Order</th>
                                    <th className="__action">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    surveyFields ?
                                        _.map(_.filter(surveyFields, surveyField => !surveyField.IsDeleted && (filterInput === '' || surveyField?.Title?.toLowerCase().includes(filterInput))), (field, i) =>
                                            <tr key={i}>
                                                <td className="__name">{field?.Title}</td>
                                                <td className="__flex">{_.find(getFieldTypes(), x => x.Value == field?.SurveyFieldType)?.Text ?? field?.SurveyFieldType}</td>
                                                <td className="__updown">
                                                    {<FontAwesomeIcon title="Ascending" className={`icon btn-icon-fa ${(field?.Sequence === 1 || saving) ? 'disabled' : ''}`} icon={faCaretUp} onClick={() => updateFieldSequence(field?.Sequence, field?.Sequence - 1)} />}&nbsp;&nbsp;
                                                    {<FontAwesomeIcon title="Descending" className={`icon btn-icon-fa ${(field?.Sequence === _.filter(surveyFields ?? [], x => !x.IsDeleted).length || saving) ? 'disabled' : ''}`} icon={faCaretDown} onClick={() => updateFieldSequence(field?.Sequence, field?.Sequence + 1)} />}
                                                </td>
                                                <td className="__action">
                                                    {<FontAwesomeIcon className={'icon btn-icon-fa'} title="Edit Field" icon={faPencil} onClick={() => handleOnOpenFieldDialog(field)} />}
                                                    {!isSurveyTemplateActive ? <FontAwesomeIcon title="Delete Field" className={'icon btn-icon-fa'} icon={faTrashCan} onClick={() => openConfirmDialog(field.Title)} /> : <FontAwesomeIcon className={'icon disabled'} icon={faTrashCan} />}
                                                </td>
                                            </tr>)

                                        : <tr><td colSpan='4'><div className='loading-wrapper'><Loading type='default' size='3rem' /></div></td></tr>
                                }
                                {
                                    _.filter(surveyFields ?? [], x => !x.IsDeleted)?.length === 0 && <tr><td colSpan='4'><div className='loading-wrapper'>Please add a survey field.</div></td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
            }
            <div className='screen-footer right'>
                <div className='btn-wrapper-left screen-footer__btn'><ButtonControl disabled={saving} type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl></div>
                {canWrite && <div className='btn-wrapper-right'><ButtonControl loading={saving} type={'cancel'} disabled={!surveyTemplateItem} onClick={() => handleOnSave(true)}>Save & Continue</ButtonControl></div>}
                {canWrite && <div className='btn-wrapper-right screen-footer__btn'><ButtonControl loading={saving} type={'okay'} disabled={!surveyTemplateItem} onClick={() => handleOnSave(false)}>Save</ButtonControl></div>}
            </div>
        </div>
    </>
}

export default SurveyTemplateDetails;


