import React, { useEffect, useState } from 'react';
import { InputField } from '../components/controls/InputField';
import CheckboxControl from '../components/controls/CheckboxControl';
import ButtonControl from './controls/ButtonControl';
import { useTOSManager } from '../hooks/useManagers';
import '../styles/quill.core_override.css';
import './TOS.scss';
import Loading from './Loading';

const TOS = ({ onSubmit, onCancel }) => {

    const [forceValidate, setForceValidate] = useState(false);
    const [acceptedChecked, setAccptedChecked] = useState(false);
    const [currentTOS, setCurrentTOS] = useState(null);
    const tosMgr = useTOSManager();

    useEffect(() => {
        if (tosMgr) {
            tosMgr.getActiveTOS().then((item) => {
                setCurrentTOS(item);
            });
        }
    }, [tosMgr]);


    const validateAccepted = (value) => {
        const messages = [];
        if (!value)
            messages.push("Please accept the terms of service.");
        return {
            isValid: messages.length === 0,
            messages,
            validationName: 'AcceptTerms'
        };
    }

    const handleOnSubmit = () => {
        if (acceptedChecked && onSubmit) {
            if (onSubmit) {
                onSubmit(currentTOS.Id);
            }
        }
        else {
            setForceValidate(true);
        }
    }

    const onAcceptedChecked = (value) => {
        setAccptedChecked(value);
        if (value === false)
            setForceValidate(false);
    }

    return <div className='tos-wrapper'>
        <div className='tos-header'>
            <h3>Terms of Service</h3>
            <h5>Please review and accept the terms of service below to complete the registration process.</h5>
        </div>
        <div className='tos-content-wrapper'>
            {currentTOS ? <div className='tos-content'>
                <div dangerouslySetInnerHTML={{ __html: currentTOS.TermsHtml }}></div>
            </div> : <div className="loading-tos"><Loading size={'5rem'} /></div>}
        </div>
        <div className='tos-footer'>
            <InputField customValidate={validateAccepted} value={acceptedChecked} onChange={onAcceptedChecked} validateOnBlur={false} forceValidate={forceValidate} title='Accept Terms of Service' subTitle='By checking below and clicking submit you accept the above NIET Terms of Service.' >
                <CheckboxControl />
            </InputField>
            <ButtonControl onClick={onCancel} type={'cancel'}>Reject</ButtonControl>
            <ButtonControl onClick={handleOnSubmit} type={'okay'}>Submit</ButtonControl>
        </div>
    </div>;
}

export default TOS;