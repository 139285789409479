import ContentManager from '../../../managers/ContentManager';
import React, { useEffect, useState } from 'react';
import InPageLoading from '../../../app-components/Loading/InPageLoading'
import contentStore from '../../../stores/contentStore';
import { useHookstate } from '@hookstate/core';
import { useContentManager } from '../../../../../hooks/useManagers';

export default function Resource() {
    const contentMgr = useContentManager()
    return (
        <>
            {contentMgr?.SelectedContent ?
                <div className="resourceFrame" >
                    <img src={contentMgr.SelectedContent.ThumbnailFileUrl} id={contentMgr.SelectedContent.Title} alt={contentMgr.SelectedContent.Title}/>
                </div> : <InPageLoading />}
        </>
    );
}
