import _ from "lodash";
import react, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { useAppUserManager } from "../../../../hooks/useManagers";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const ContentSyncRawDataTable = ({ data }) => {
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const [columns] = useState([
        {
            header: '',
            dataProp: 'ContentLink',
            width: '3%',
            canSort: false
        },
        {
            header: 'Row #',
            dataProp: 'row',
            width: '5%',
            dataType: 'int',
            canSort: true
        },

        {
            header: 'Thumbnail',
            dataProp: 'thumbnail',
            width: '5%',
            canSort: true
        },


        {
            header: 'File Name',
            dataProp: 'filename',
            canSort: true
        },
        {
            header: 'Display File Name',
            dataProp: 'displayFileName',
            canSort: true
        },
        {
            header: 'Linked Thumbnail File Name',
            dataProp: 'thumbnailfilename',
            canSort: true
        },
        {
            header: 'Title',
            dataProp: 'title',
            canSort: true
        },
        {
            header: 'Description',
            dataProp: 'description',
            canSort: true
        },
        {
            header: 'Rating',
            dataProp: 'systemrating',
            width: '5%',
            canSort: true
        },
        {
            header: 'Public',
            dataProp: 'publiccontent',
            width: '5%',
            canSort: true
        },

        {
            header: 'Link Content If File Exists',
            dataProp: 'linkcontentiffileexists',
            canSort: true
        },
        {
            header: 'Content Assocations',
            dataProp: 'contentassocations',
            datatype: 'html',
            canSort: true
        },
        {
            header: 'Districts',
            dataProp: 'districts',
            datatype: 'html',
            canSort: true
        },
        {
            header: 'Enabled',
            dataProp: 'enabled',
            width: '5%',
            canSort: true
        },
    ]);



    useEffect(() => {
        if (data) {
            try {
                let parsedData = data ? data : [];
                const newRows = [];
                _.each(parsedData, (d) => {
                    newRows.push({
                        ...d,
                        ContentLink: <>{d.contentid && <div className="content-link" onClick={() => navigate(`/admin/content/${d.contentid}`)}><FontAwesomeIcon className={'btn-icon-fa'} icon={faEye} /></div>}</>
                    });
                });
                setRows(newRows);
            }
            catch {
            }
        }
    }, [data]);

    return <div className='contentSync-table-wrapper'>
        <div className='contentSync-table'>
            <TableControl
                columns={columns}
                data={rows}
                permission='ContentSync'
                checkHideAction={() => { return true; }}
                useIntegratedPaging={['50,100']}
            />
        </div>
    </div>
}

export default ContentSyncRawDataTable;