import { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PrintLayout from '../../../components/layout/PrintLayout';
import SurveyTable from '../../admin/components/observation/SurveyTable';
import '../components/RubricPrintScreen.scss'


const RubricPrintScreen = ({ rubricData, handlePrintCompleted }) => {
    const [print, setPrint] = useState(false)
    const [gridData, setGridData] = useState(null)
    useEffect(() => {
        if (rubricData) {
            setGridData(rubricData)
            setPrint(true)
        }
    }, [rubricData])

    return <PrintLayout print={print} cleanUp={handlePrintCompleted}>
        <SurveyTable
            isSelfReflection={true}
            disableActOfRating={true}
            gridData={gridData}
            fixedRowWidth={'9rem'}
            hideRatingBtn={true}
            hideIndicatorsProgress={true}
        />
    </PrintLayout >

}

export default RubricPrintScreen;


