import React from 'react';
import { ReportingService } from '../../../services/ReportingService';
import { useHookstate } from '@hookstate/core';
import globalAuthStateStore from '../../../stores/globalAuthStateStore';

const ExportDemoExample = () => {

    const authStore = useHookstate(globalAuthStateStore);
    var reportingSvc = new ReportingService(authStore);
    
    const exportReport = () => {
        console.log('exporting report!');

        var reportParams = {
            "report":"TeacherOverallAverageByRubricIndicator.trdp",
            "parameterValues":
                {
                    "ClusterId":null,
                    "DateRangeEnd":null,
                    "DateRangeStart":null,
                    "SchoolYear":2022,
                    "DistrictId":null,
                    "Individually":true,
                    "OrganizationId":"4089206b-105e-419b-a50c-c3e67444c959",
                    "SchoolId":null,
                    "Uids":null
                }
        }

        reportingSvc.getExport(reportParams);
    }
    
    return (
        <>
            <div>TODO: Delete this route and page once implemented!</div>
            {reportingSvc && <button onClick={() => { exportReport() }}>Export Report</button>}
        </>
    )
}

export default ExportDemoExample;