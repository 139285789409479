   
import { Filter } from "@mui/icons-material";
import _ from "lodash";
import { ItemServiceFactory } from "../services/ItemServiceFactory";
import ItemManager from "./ItemManager";

export default class TagManager extends ItemManager {

    constructor(tagState, globalAuthState, globalAppUserState) {
        super(ItemServiceFactory.ItemServiceEnum.Tag, globalAuthState, globalAppUserState, tagState)
        this._tagState = tagState;
        this._clientMgr = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.Client, globalAuthState, globalAppUserState);

        this._setAllTags = (value) => this._tagState.merge({ allTags: value });
        this._setForceTagUpdate = (value) => this._tagState.merge({ forceUpdate: value });
        this._setFilteredTags = (value, filter) => this._tagState.merge({ filteredTags: value, tagFilter: filter });
        this._setParentTags = (value) => this._tagState.merge({ parentTags: value });
    }

    get AllTags() { return this._tagState.get({ noproxy: true }).allTags; }
    get _forceTagUpdate() { return this._tagState.get({ noproxy: true }).forceUpdate; }
    get TagFilter() { return this._tagState.get({ noproxy: true }).tagFilter; }
    get FilteredTags() { return this._tagState.get({ noproxy: true }).filteredTags; }
    get ParentTags() { return this._tagState.get({ noproxy: true }).parentTags; }

    get AvaliableFilters() {
        return { parentTags: this.ParentTags }
    }

    get ItemManager() {
        return this;
    }

    async AllParentTags() {
        const tags = [];
        const parentTagResult = await this.query("x => x.ParentTagId == null && x.IsDeleted == false", undefined, true);
        if (parentTagResult.Success) {
            _.each(parentTagResult.Items, (t) => {
                t.label = t.Name + (t.IsActive ? '' : ' (Inactive)');
                t.value = t.TagId;
                tags.push(t);
            });
        }
        return tags;
    }

    _loadParentTags(allTags) {
        const tagIds = [];
        const tags = [];
        const parentTags = [..._.filter(allTags, t => t.ParentTagId === null && t.IsActive === true)];
        _.each(parentTags, (t) => {
            tagIds.push(t.TagId);
            t.label = t.Name;
            t.value = t.TagId;

            const childTagList = _.filter(allTags, (x) => { return x.TagId === t.TagId && x.ParentTagId !== null && t.IsActive === true });
            childTagList.forEach(ct => {
                tagIds.push(ct.TagId);
                if (t.children === undefined)
                    t.children = [];
                ct.label = ct.Name;
                ct.value = ct.TagId;
                t.children.push(ct);
            });
            tags.push(t);
        });
        this._setParentTags(parentTags);
    }

    async _doFilter(filter, allTags) {
        if (!allTags) {
            allTags = this.AllTags;
        }
        else {
            this._loadParentTags(allTags);
        }

        const filteredTags = _.filter([...allTags], t => {
            let found = false;
            if (t.Name?.toLowerCase()?.startsWith(filter?.Search?.toLowerCase() ?? '') ||
                t.Description?.toLowerCase()?.startsWith(filter?.Search?.toLowerCase() ?? '') ||
                filter.Search === undefined ||
                filter.Search === null ||
                filter.Search === '') {
                found = true;
            }

            if (filter.IsActive !== undefined && filter.IsActive !== t.IsActive) {
                found = false;
            }
            else if (filter.IsSecure !== undefined && filter.IsSecure === t.IsSecure) {
                found = false;
            }
            else if (filter.ParentTagIds && filter.ParentTagIds.length > 0) {
                if (!filter.ParentTagIds.includes(t.ParentTagId)) {
                    found = false;
                }
            }

            return found;
        });

        this._setFilteredTags(filteredTags, filter);
        return filteredTags;
    }

    async DeleteItem(id) {
        const result = await this.delete(id);
        if (result.Success) {
            let allTags = [...this.AllTags];
            allTags = _.filter(allTags, x => x.Id !== id);
            this._loadParentTags(allTags);
            this._setAllTags(allTags);
            return {
                Success: true,
                Items: await this._doFilter(this.TagFilter, allTags)
            };
        }
        else {
            return result;
        }
    }

    async SearchTags() {
        var result = await this.list();

        if (result.Success) {
            this._loadParentTags(result.Items);
            this._setAllTags(result.Items);
            const filteredTags = await this._doFilter(this.TagFilter, result.Items);
            return filteredTags;
        }
        else
            return [];
    }

    async FilterTags(filter, tagToAdd) {
        if (!filter) {
            filter = this.TagFilter;
        }

        let allTags = [...this.AllTags];
        if (tagToAdd) {
            allTags = _.filter(allTags, x => x.Id !== tagToAdd.Id);
            allTags = [...allTags, tagToAdd];
            this._setAllTags(allTags);
        }
        return this._doFilter(filter, allTags);
    }

    async FullTagTree() {
        return this.runOperation("SelectDataTagTree");
    }
}