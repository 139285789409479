import { useEffect, useRef, useState } from 'react';

import _ from 'lodash';
import SystemTypes from '../../../../services/SystemTypes';
import { useImportExportManager } from '../../../../hooks/useManagers';
import ButtonControl from '../../../../components/controls/ButtonControl';

const Import = ({ importDisplayName, importExportType, setErrors, onComplete }) => {

    const [importExportLoading, setImportExportLoading] = useState(undefined);
    const fileInputRef = useRef();
    const importExportMgr = useImportExportManager();

    const handleOnImport = async () => {
        fileInputRef.current.click();
    }

    const onImportFileChange = async (e) => {
        const file = e.target.files[0];
        if (file.type !== 'text/csv') {
            setErrors(["Please upload a CSV import file."]);
            fileInputRef.current.value = ''
        }
        else {
            setImportExportLoading(true);
            importExportMgr.uploadFile(file, undefined, (result) => {
                if (result.Success) {
                    fileInputRef.current.value = ''
                    const fileId = result.Items.first().Id;
                    importExportMgr.ImportData(importExportType, fileId).then(r => {
                        if (r.Success) {
                            if (r.Items.first().ImportExportStatus === SystemTypes.ImportExportStatusType.Failed) {
                                if (r.Items.first().Log) {
                                    setErrors([`Failed to import ${importDisplayName}: `, ...r.Items.first().Log?.MessageDetails.split(',')]);
                                }
                                else {
                                    setErrors([`Failed to import ${importDisplayName}s.`]);
                                }
                                if (onComplete)
                                    onComplete(false);
                                setImportExportLoading(false);
                            }
                            else if (r.Items.first().ImportExportStatus === SystemTypes.ImportExportStatusType.InProgress ||
                                r.Items.first().ImportExportStatus === SystemTypes.ImportExportStatusType.Created) {
                                setErrors(["Import process is running in the background, please check back later or navigate to import/export for more information."]);
                                if (onComplete)
                                    onComplete(false);
                                setImportExportLoading(false);
                            }
                            else if (r.Items.first().ImportExportStatus === SystemTypes.ImportExportStatusType.PartialSuccess) {
                                const messageDetails = r.Items.first().Log?.MessageDetails.split(',').map(md => {
                                    return md.indexOf('Success') > -1 ? <span style={{ color: 'var(--success)' }}>{md}</span> : md
                                });
                                setErrors([<b>{`One or more of the ${importDisplayName} records failed to import:`}</b>, ...messageDetails]);
                                if (onComplete)
                                    onComplete(false);
                                setImportExportLoading(false);

                            }
                            else if (onComplete) {
                                onComplete(true).then(x => {
                                    setImportExportLoading(false);
                                })
                            }
                        }
                        else {
                            setErrors(["File Uploaded, but one or more records failed to import.", result.MessageDetails]);
                            if (onComplete)
                                onComplete(false);
                            setImportExportLoading(false);
                        }

                    }).catch(e => {
                        setImportExportLoading(null);
                        setImportExportLoading(false);
                        if (onComplete)
                            onComplete(false);
                    });
                }
                else {
                    setImportExportLoading(false);
                    fileInputRef.current.value = ''
                    setErrors(["Failed to upload file, if this issue continues please contact NIET Support.", result.MessageDetails]);
                    if (onComplete)
                        onComplete(false);
                }
            });
        }
    }

    const content = 'Import' + (!importDisplayName ? '' : ` ${importDisplayName}s`);
    return (<>
        <input ref={fileInputRef} type="file" onChange={onImportFileChange} className='hidden-file-input' />
        <ButtonControl loading={importExportLoading} disabled={importExportLoading} type={'cancel'}
            onClick={handleOnImport}>
            {content}
        </ButtonControl>
    </>
    )
}

export default Import;


