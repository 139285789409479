

import { CircularProgress } from '@mui/material';
import React from 'react';
import './loading.scss';

const Loading = ({ type, size }) => {
    const key = `loading_spinner_${type}_${Math.random().toString()}`;
    size = size ?? '1.5rem';
    const loadingSpinner2 = (
        <div className='loading loading__spinner_1' key={key}>
            <svg className="svg-spinner" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
                <circle cx="24" cy="4" r="4" fill="#fff" />
                <circle cx="12.19" cy="7.86" r="3.7" fill="#fffbf2" />
                <circle cx="5.02" cy="17.68" r="3.4" fill="#fef7e4" />
                <circle cx="5.02" cy="30.32" r="3.1" fill="#fef3d7" />
                <circle cx="12.19" cy="40.14" r="2.8" fill="#feefc9" />
                <circle cx="24" cy="44" r="2.5" fill="#feebbc" />
                <circle cx="35.81" cy="40.14" r="2.2" fill="#fde7af" />
                <circle cx="42.98" cy="30.32" r="1.9" fill="#fde3a1" />
                <circle cx="42.98" cy="17.68" r="1.6" fill="#fddf94" />
                <circle cx="35.81" cy="7.86" r="1.3" fill="#fcdb86" />
            </svg>
        </div>
    );

    if (type === 'table-spinner') {
        return <div className='loading table-spinner' key={key}>
            <CircularProgress color="inherit" size={size} thickness={6} />
        </div>;
    }
    else if (type === 'spinner-2') {
        return loadingSpinner2;
    }
    else {
        return <div key={key}>
            <CircularProgress color="inherit" size={size} />
        </div>;
    }
}

export default Loading;

