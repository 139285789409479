import React from 'react';
import './controls.scss';
import Loading from '../Loading';
import { useNavigate } from 'react-router-dom';

export const ButtonControl = ({
    to,
    field,
    value,
    type,
    onClick,
    onDoubleClick,
    className,
    disabled,
    loading,
    children,
    disabledTitle,
    props,
}) => {

    const navigate = useNavigate();
    const idName = `field_btn_${(field?.FieldName ?? (value ?? (children ?? 'unknown')).replace(' ', '_'))}`;
    const buttonClassName = `btn_control__${type ?? 'standard'}`;
    const displayName = value ?? (field?.displayName ?? (value ?? (children ?? 'Submit')));
    const handleOnClick = (e) => {
        switch (e.detail) {
            case 1: {
                if (onClick) {
                    onClick(e, field?.FieldName)
                }
                else if (to) {
                    navigate(to);
                }
                break;
            }
            case 2: {
                if (onDoubleClick) {
                    onDoubleClick(e, field?.FieldName)
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    const disabledState = (disabled === true && !loading &&
        <div key={idName} title={disabledTitle ?? ''}
            className={`btn_control ${buttonClassName} ${className ?? ''} disabled`}>
            {displayName}
        </div>);

    const activeState = (!disabled && !loading && <div key={idName} onClick={handleOnClick} className={`btn_control ${buttonClassName} ${className ?? ''}`}>{displayName}</div>);
    const loadingState = (loading === true &&
        <div key={idName} className={`btn_control ${buttonClassName} ${className ?? ''}`}>
            <div className='inner_loading'><Loading /></div>
            <div className='inner_loading_text'>{displayName}</div>
        </div>);

    return <>
        {activeState}
        {disabledState}
        {loadingState}
    </>
}

export default ButtonControl;