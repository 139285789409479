const CertificationAnswerValidateObject = () => {
    return {
        AnswerText: {
            fieldName: 'AnswerText',
            messageTitle: 'possible answer to the question.',
            isRequired: true,
            dataType: 'HTML',//IMAGE, INT, DEC, SELECT,BOOL
            groupId: 'CERTIFICATIONANSWER',
            regEx: undefined,
            requiredDependency: undefined
        },
    }
}

export default CertificationAnswerValidateObject;