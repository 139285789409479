class ErrorUtil {
    constructor(globalErrorsStore) {

        this._globalErrors = globalErrorsStore;
    }

    GetGlobalErrors() {
        return this._globalErrors;
    }

    GetErrorMessages() {
        let errorMsgs = [];
        if (this._globalErrors != null) {
            for (const error in this._globalErrors.get()) {
                errorMsgs = errorMsgs.concat(this._globalErrors.get()[error].errorMessages);
            }
        }
        return errorMsgs;
    }

    AddError(errorResult) {
        let newGlobalErrors = {};
        if (this._globalErrors !== null) {
            newGlobalErrors = JSON.parse(JSON.stringify(this._globalErrors))
        }

        if (errorResult.UniqueId != undefined && errorResult.UniqueId != null) {
            newGlobalErrors[errorResult.UniqueId] = errorResult;
            this._globalErrors.set(newGlobalErrors);
        }
    }

    RemoveError(uniqueId) {
        if (this._globalErrors != null) {
            let globalErrorsCopy = JSON.parse(JSON.stringify(this._globalErrors))
            delete globalErrorsCopy[uniqueId];
            this._globalErrors.set(globalErrorsCopy)
        }
    }

    ClearErrors() {
        this._setGlobalErrors(null);
    }
}

export default ErrorUtil;