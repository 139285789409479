import React, { useEffect, useState } from 'react';
import { ContentTypeEnum } from '../../../services/ContentService';

import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import moment from 'moment';
import TableControl from '../../../../../components/controls/TableControl/TableControl';
import { asyncForEach } from '../../../utils/jsUtil';
import { useAppUserManager, useContentManager } from '../../../../../hooks/useManagers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

export default function ShareListDisplay({ updateContent, shareMode, onDownload, onView, onShare, onRemoveShare }) {
    const authMgr = useAppUserManager();
    const contentMgr = useContentManager();
    const shareFilteredContent = contentMgr?.GetGlobalState()?.shareFilteredContent;
    const permissions = authMgr?.GetGlobalState()?.permissions;
    const [rows, setRows] = useState([]);

    const [columns, setColumns] = useState([])

    useEffect(() => {
        setColumns([{
            header: 'Thumb',
            dataProp: "thumbnail",
            datatype: 'image',
            width: '5%',
            canSort: false
        },
        {
            header: 'Title',
            dataProp: "title",
            width: '16.5%',
            canSort: true
        },
        {
            header: 'Content Type',
            dataProp: "contentType",
            width: '16.5%',
            canSort: true
        },
        {
            header: shareMode == "sharedWithUser" ? 'Description' : 'Shared With',
            dataProp: shareMode == "sharedWithUser" ? 'description' : 'sharedWithName',
            width: '16.5%',
            canSort: true
        },
        {
            header: 'Date Shared',
            dataProp: 'DateShared',
            width: '16.5%',
            canSort: true,
            datatype: 'dateTime'
        },
        {
            header: shareMode == "sharedWithUser" ? 'Date Viewed' : 'Date Completed',
            dataProp: "dateCompleted",
            width: '16.5%',
            canSort: true,
            datatype: 'dateTime'
        }
        ]);
    }, [shareMode]);


    useEffect(() => {
        let newRows = [];
        if (shareMode != undefined && shareFilteredContent) {
            let listModeType = "UserHasShared";

            if (shareMode.toLowerCase() == "sharedwithuser") {
                listModeType = "SharedWithUser";
            }
            asyncForEach(shareFilteredContent[listModeType], (c) => {
                if (!newRows.some(x => x.Title == c.Title)) {
                    newRows.push({
                        ...c,
                        Id: c.ContentShareId,
                        contentId: c.ContentId,
                        keyId: c.KeyId,
                        userId: c.UserId,
                        contentTypeId: c.ContentTypeId,
                        thumbnail: c.thumbUrl,
                        title: c.Title,
                        sharedWithName: c.UserFullName,
                        contentType: c.ContentType,
                        description: c.Description,
                        dateCompleted: c.DateCompleted,
                        mimeType: c.MimeType,
                        sharedWithUser: c.SharedWithUser === undefined || c.SharedWithUser === null ? false : c.SharedWithUser
                    });
                }
            }).then(() => {
                setRows(newRows);
            });
        }
    }, [updateContent, shareMode, shareFilteredContent]);
    return (
        <>
            {authMgr?.AccessCheck([permissions?.ViewSharedContent]) &&
                <div className="contentDisplay contentDisplay-list">
                    <TableControl
                        columns={columns}
                        data={rows}
                        useIntegratedPaging={['10', '25', '50', '100']}
                        onView={(row) => onView(row.keyId)}
                        onCustom1={shareMode === "sharedWithUser" ?
                            {
                                title: 'Share',
                                action: (row) => { return authMgr?.AccessCheck([permissions?.Share]) && <ShareIcon title="Share" className={'btn-icon'} onClick={(e) => onShare(row.keyId)} /> }
                            } :
                            {
                                title: 'Delete',
                                action: (row) => { return (authMgr.canDelete("ContentShare") && authMgr?.canDelete(permissions?.Share)) && <FontAwesomeIcon title="Delete" className={'btn-icon-fa'} icon={faTrashCan} onClick={(e) => onRemoveShare(row.Id)} /> }
                            }
                        }
                        onCustom2={{
                            title: 'Download',
                            action: (row) => { return row.contentTypeId == ContentTypeEnum.Resource && <CloudDownloadIcon title="Download" className={'btn-icon-fa'} onClick={(e) => onDownload(row.keyId)} /> }
                        }}
                    />
                </div>
            }
        </>
    );
}
