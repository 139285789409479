import React from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { SelectListControl } from '../../../../components/controls';
import { CheckTreePicker } from 'rsuite';
import Loading from '../../../../components/Loading';

export default function GeneralSurveyFilter({ organizations, generalSurveyConfigMgr, onFilterChange }) {

    const onTreeClean = (id) => {
        handleOnChange(null, id);
    }

    const handleOnChange = (value, field,) => {
        if (value === "true") {
            value = true
        } else if (value === 'false') {
            value = false
        }
        if (onFilterChange)
            onFilterChange(field, value);
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        generalSurveyConfigMgr.filterData({ search: null, surveyType: 2, orgIds: [], IsActive: 'UNK' });
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">General Survey Management</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search'
                            value={generalSurveyConfigMgr?.Filter?.search ?? ''}
                            fieldName='search'
                            disableError={true}
                            placeholder='Survey'
                            onChange={handleOnChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        {generalSurveyConfigMgr && <InputField id='surveyType'
                            title='Active?'
                            value={generalSurveyConfigMgr?.Filter?.IsActive ?? 'UNK'}
                            fieldName='IsActive'
                            disableError={true}
                            onChange={handleOnChange}>
                            <SelectListControl textValuePairs={[{ Text: 'Yes', Value: true }, { Text: 'No', Value: false }]} />
                        </InputField>}
                    </div>

                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}