import React from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import Navigation from './Navigation';
import ProfileNav from './ProfileNav';
import { useLocation } from 'react-router-dom';
import { useAppUserManager } from '../../hooks/useManagers';
import NotificationBanner from './NotificationBanner';

const Header = () => {
  const appUserMgr = useAppUserManager();
  const { pathname } = useLocation();
  return (
    <div className={`header header-box-shadow`}>
      <div className={'eepass-banner'} onClick={() => { window.location.href = '/' }}></div>
      <Navigation />
      <NotificationBanner />
      {appUserMgr?.AppUserState?.selectedSchoolYear != appUserMgr?.AppUserState?.currentSchoolYear && <div className="schoolYear-message" >Selected School Year: <b>{appUserMgr.AppUserState.selectedSchoolYear}</b></div>}
      {!appUserMgr?.AppUserState?.canWriteSchoolYearData && appUserMgr?.AppUserState && appUserMgr?.AppUserState?.selectedSchoolYear == appUserMgr?.AppUserState?.currentSchoolYear && <div className="schoolYear-message" >School Year Completed: Editing Disabled</div>}
      <ProfileNav />
    </div>
  );
}

export default Header;