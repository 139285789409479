import React from 'react';

const TimePickerControl = ({ field,
  value,
  onChange,
  onBlur,
  className,
  disabled,
  loading,
  props
}) => {

  return (<>
    <input id={"field_tp_" + field.FieldName}
      placeholder={field.PlaceHolder}
      onBlur={(context) => onBlur(context, field)}
      value={value}
      className={className}
      disabled={disabled}
      type={'time'}
      onChange={(e) => onChange(e, field)}
      {...props} />
  </>
  );
}

export default TimePickerControl;