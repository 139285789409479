import { faCheck, faDownload, faExpand, faInfo, faInfoCircle, faLock, faMagnifyingGlass, faPencil, faTag, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import './ObservationNote.scss';
import reactStringReplace from 'react-string-replace';
import _ from 'lodash';
import ButtonControl from '../../../../components/controls/ButtonControl';
import FileUploaderControl from '../../../../components/controls/FileUploaderControl';
import { fileUploadWorkerScript } from '../../../../components/controls/FileUploaderControl/FileUploadApi';
import configData from '../../../../config.json';
import { useAppUserManager } from '../../../../hooks/useManagers';
  
import { BaseService } from '../../../../services/base/BaseService';
import { DialogControl } from '../../../../components/controls/DialogControl';
import { v4 as uuidv4 } from 'uuid';


export default function ObservationNote({
    id,
    surveySectionId,
    time,
    sectionTitle,
    evidence,
    inProgressEvidence,
    newNote,
    cancelNewNote,
    greyOutMostNotes,
    tags,
    tagMap,
    onUpdate,
    media,
    globallyFocusedIndicator,
    setGloballyFocusedIndicator,
    observationMgr,
    canEdit,
    setIsNextDisabled
}) {

    const [note, setNote] = useState({
        id: id,
        surveySectionId: surveySectionId,
        time: time,
        sectionTitle: sectionTitle,
        evidence: evidence,
        tags: tags,
        isNewNote: newNote,
        media: media
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isTagMode, setIsTagMode] = useState(false);
    const [tagModeMedia, setTagModeMedia] = useState({ isActive: false, index: null });
    const [highlightedTxt, sethightlightedTxt] = useState('');
    //const [lastHighlightedTxt, setLastHighlightedTxt] = useState('');
    const [activeTag, setActiveTag] = useState('');
    const [locallyFocusedTag, setLocallyFocusedTag] = useState(null);
    const [showTagMenu, setShowTagMenu] = useState(false);
    const [dialogue, setDialogue] = useState({ isOpen: false });
    const [searchTags, setSearchTags] = useState('');
    const [mouseCoordinates, setMouseCoordinates] = useState({ x: 0, y: 0 });
    const [initialMouseCoordinates, setInitialMouseCoordinates] = useState({ x: 0, y: 0 });
    const [tagMenuCoordinates, setTagMenuCoordinates] = useState({ x: 0, y: 0 });
    
    //refs
    const noteTextRef = useRef();
    const inputFile = useRef();
    const itemsEls = useRef([]);

    //managers
    const authStateManager = useAppUserManager();

    const titleRef = useCallback((inputElement) => {
        if (inputElement && newNote) {
            inputElement.focus();
        }
    }, []);

    useEffect(() => {
        initNote();
    }, [surveySectionId, time, sectionTitle, evidence, newNote, tags, media]);

    const initNote = () => {
        let noteToUpdate = JSON.parse(JSON.stringify(note));
        noteToUpdate.id = id;
        noteToUpdate.surveySectionId = surveySectionId;
        noteToUpdate.time = time;
        noteToUpdate.sectionTitle = sectionTitle;
        noteToUpdate.evidence = evidence;
        noteToUpdate.inProgressEvidence = inProgressEvidence;
        noteToUpdate.newNote = newNote;
        noteToUpdate.tags = tags;
        noteToUpdate.media = media;
        setNote(noteToUpdate);
    }

    useEffect(() => {
        if (isTagMode) {
            if (highlightedTxt !== '' && highlightedTxt !== ' ') {
                setTagModeMedia({ isActive: false, index: null })
                setActiveTag(highlightedTxt);
                setShowTagMenu(true);
                greyOutMostNotes(true);
            }
        }
    }, [highlightedTxt])

    const handleMouseMove = (event) => {
        setMouseCoordinates({ x: event.clientX, y: event.clientY });
      };
    
      useEffect(() => {
        document.body.addEventListener('mousemove', handleMouseMove);
    
        return () => {
          document.body.removeEventListener('mousemove', handleMouseMove);
        };
      }, []);

    useEffect(() => {
        setInitialMouseCoordinates({ x: mouseCoordinates.x, y: mouseCoordinates.y });
        setTagMenuCoordinates({ x: mouseCoordinates.x, y: mouseCoordinates.y });

    }, [showTagMenu]);  

    const mouseDownHandler = (mouseDownEvent) => {
        function onMouseUp() {
            sethightlightedTxt(window.getSelection().toString());
        }
        document.body.addEventListener('mouseup', onMouseUp, { once: true });
    }

    const renderEvidence = () => {
        //if (isEditMode || newNote) { //TODO: Got to handle these cases as well
        //ALSO TODO: Check the bounds (perhaps use i) to make sure you only tag the 1 instance that was highlighted
        if (isTagMode) {
            let annotatedTxt = (isEditMode || note.isNewNote) ? inProgressEvidence : evidence;
            if (note?.tags) {
                var manualKeyCounter1 = 0;
                Object.keys(note.tags).forEach((tag, j) => {
                    annotatedTxt = (
                        reactStringReplace(annotatedTxt, tag, (match, i) => (
                            <span key={`render_evidence${j}_${i}_${manualKeyCounter1++}_${note.id}`} className={`tagged${activeTag === tag ? ' active' : ''}`} contentEditable={false} onClick={() => { setActiveTag(tag); setShowTagMenu(true); greyOutMostNotes(true); }}>
                                {match}
                                <sub contentEditable={false}>
                                    {note.tags[tag].join()}
                                </sub>
                            </span>
                        ))
                    )
                });
                if (activeTag !== '' && !note.tags[activeTag]) {
                    var manualKeyCounter2 = 0;
                    annotatedTxt = reactStringReplace(annotatedTxt, activeTag, (match, i) => {
                        return (<span key={`render_evidence${i}_${manualKeyCounter2++}_${note.id}`} className='highlighted' contentEditable={false}>
                            {match}
                        </span>)
                    })
                }
            }
            return annotatedTxt;
        }
        else {
            let annotatedTxt = (isEditMode || note.isNewNote) ? inProgressEvidence : evidence;
            if (note?.tags) {
                Object.keys(note?.tags ?? {}).forEach((tag, j) => {
                    let isTagFocused = note.tags[tag].includes(locallyFocusedTag) || note.tags[tag].includes(globallyFocusedIndicator);
                    var manualKeyCounter3 = 0;
                    annotatedTxt = (
                        reactStringReplace(annotatedTxt, tag, (match, i) => (
                            <span key={`render_evidence_non_tag${j}_${i}_${manualKeyCounter3++}_${note.id}`} className={isTagFocused ? `tagged-and-focused` : `tagged-no-focus`}>{match}<sub>{note.tags[tag].join()}</sub></span>
                        ))
                    )
                });
            }
            return annotatedTxt;
        }
    }

    const handleCancel = () => {
        setSearchTags('');
        if (newNote) {
            cancelNewNote(id);
            setIsNextDisabled(false);
        }
        else if (isEditMode) {
            setIsEditMode(false);
            setIsNextDisabled(false);
            initNote();
        }
        else if (isTagMode) {
            if (showTagMenu || activeTag !== '') {
                setShowTagMenu(false);
                greyOutMostNotes(false);
                initNote();
            } else {
                setIsTagMode(false);
                initNote();
            }
            setTagModeMedia({ isActive: false, index: null, fileId: null })
        }
        setActiveTag('');
    }

    const onInput = (e) => {
        let updatedEvidence = e?.target?.innerText;
        if (updatedEvidence) {
            Object.keys(tags).forEach(tag => {
                updatedEvidence = updatedEvidence.replace(tags[tag], '');
            })
        }
        let noteToUpdate = JSON.parse(JSON.stringify(note));
        noteToUpdate.inProgressEvidence = updatedEvidence;
        setNote(noteToUpdate);
        setIsNextDisabled(true);
    }

    const handleSave = () => {
        setSearchTags('');
        let updatedEvidence = note.inProgressEvidence;
        if (updatedEvidence) {
            Object.keys(note.tags).forEach(tag => {
                updatedEvidence = updatedEvidence.replace(tags[tag], '');
            })
        }
        let noteToUpdate = note;//JSON.parse(JSON.stringify(note));
        noteToUpdate.evidence = updatedEvidence;
        noteToUpdate.tags = { ...note.tags }
        noteToUpdate.inProgressEvidence = updatedEvidence;
        noteToUpdate.newNote = false;
        setNote(noteToUpdate);

        onUpdate('save', noteToUpdate);
        setIsEditMode(false);
        setIsNextDisabled(false);
        if (isTagMode && activeTag === '' && !showTagMenu) {
            setIsTagMode(false);
            greyOutMostNotes(false);
        } else if (isTagMode) {
            setShowTagMenu(false);
            setActiveTag('');
            greyOutMostNotes(false);
        }
        setTagModeMedia({ isActive: false, index: null, fileId: null })
    }

    const handleDelete = () => {
        onUpdate('delete', note);
    }

    const handleEdit = (target, e) => {
        let n = { ...note };
        if (target === 'sectionTitle') {
            n.sectionTitle = e.target.value;
        }
        else if (target === 'time') {
            n.time = e.target.value;
        }
        else if (target === 'evidence') {
            n.evidence = e.target.value;
        }
        setNote(n);
    }

    const applyTag = (tag) => {
        if (!tagModeMedia.isActive) {
            let tagsToUpdate = { ...note.tags };
            if (note?.tags[activeTag]) {
                if (tagsToUpdate[activeTag].includes(tag)) {
                    tagsToUpdate[activeTag] = tagsToUpdate[activeTag].reduce((r, cv) => {
                        if (cv !== tag)
                            r.push(cv);
                        return r;
                    }, []);
                    if (tagsToUpdate[activeTag].length === 0) {
                        delete tagsToUpdate[activeTag];
                    }
                }
                else {
                    tagsToUpdate[activeTag].push(tag);
                }
            } else {
                tagsToUpdate[activeTag] = [tag];
            }
            let noteToUpdate = { ...note };
            noteToUpdate.tags = tagsToUpdate;
            setNote(noteToUpdate);
        }
        else {
            let mediaToUpdate = [...note.media];
            mediaToUpdate = mediaToUpdate?.reduce((r, cv) => {
                if (cv.FileId === tagModeMedia.fileId) {
                    if (!cv.tags) {
                        cv.tags = [];
                    }
                    if (cv.tags.includes(tag)) {
                        cv.tags = cv.tags.filter(t => t !== tag);
                    }
                    else {
                        cv.tags.push(tag);
                    }
                }
                r.push(cv);
                return r;
            }, []);
            setNote({ ...note, media: mediaToUpdate });
        }
    }

    const clearTagsForSelection = () => {
        if (!tagModeMedia.isActive) {
            let noteToUpdate = { ...note };
            let tagsToUpdate = { ...noteToUpdate.tags };
            delete tagsToUpdate[activeTag];
            noteToUpdate.tags = tagsToUpdate;
            setNote(noteToUpdate);
        }
        else {
            let noteToUpdate = { ...note };
            let mediaToUpdate = [...noteToUpdate.media];
            mediaToUpdate = mediaToUpdate.reduce((r, cv) => {
                if (cv.FileId === tagModeMedia.fileId) {
                    cv.tags = [];
                }
                r.push(cv);
                return r;
            }, []);
            noteToUpdate.media = mediaToUpdate;
            setNote(noteToUpdate);
        }
    }

    const handleSearchTag = (e) => {
        setSearchTags(e.target.value);
        e.preventDefault();
        e.stopPropagation();
    }

    const renderTagMenu = () => {
        if (tagModeMedia.isActive) {
            let x = tagMenuCoordinates.x;
            let y = tagMenuCoordinates.y;       

            return <div className={'tag-menu-select'} style={{ left: `${x}px`, top: `calc(${y}px - 17rem)` }}>
                <div className={'search-bar-wrapper'}>
                    <input type={'text'} placeholder='Search' value={searchTags} onChange={handleSearchTag} />
                    <FontAwesomeIcon icon={faMagnifyingGlass} className={'mag-icon'} />
                </div>
                <div className={'search-items-wrapper'}>
                    {_.map(Object.keys(tagMap), t => {
                        if (searchTags?.length !== 0) {
                            if (t.toLowerCase().startsWith(searchTags.toLowerCase()) || tagMap[t].name.toLowerCase().startsWith(searchTags.toLowerCase())) {
                                return (<div key={t} className={'item'} onClick={() => { applyTag(t) }}>
                                    <div className={'checkbox'} >
                                        {
                                            note.media?.reduce((r, cv) => {
                                                if (cv.FileId === tagModeMedia.fileId) {
                                                    if (cv.tags.includes(t)) {
                                                        r.push(t);
                                                    }
                                                }
                                                return r;
                                            }, []).length > 0 ?
                                                <FontAwesomeIcon icon={faCheck} />
                                                :
                                                null
                                        }
                                    </div>
                                    <div className={'text'}>{t} - {tagMap[t].name}</div>
                                </div>)
                            }
                        } else {
                            return (<div key={t} className={'item'} onClick={() => { applyTag(t) }}>
                                <div className={'checkbox'} >
                                    {
                                        note.media?.reduce((r, cv) => {
                                            if (cv.FileId === tagModeMedia.fileId) {
                                                if (cv.tags.includes(t)) {
                                                    r.push(t);
                                                }
                                            }
                                            return r;
                                        }, []).length > 0 ?
                                            <FontAwesomeIcon icon={faCheck} />
                                            :
                                            null
                                    }
                                </div>
                                <div className={'text'}>{t} - {tagMap[t].name}</div>
                            </div>)
                        }
                    })}
                </div>
                <div className={'tag-menu-footer'}>
                    <div className={'clear-all'} onClick={() => { clearTagsForSelection() }}>
                        Clear Tags
                    </div>
                    <div className={'btns-wrapper'}>
                        <div className={'control-btn'} onClick={() => { handleCancel() }}>CANCEL</div>
                        <div className={`control-btn able-to-save`} onClick={() => { handleSave() }}>SAVE</div>
                    </div>
                </div>
            </div>
        }
        else {
            let x = tagMenuCoordinates.x;
            let y = tagMenuCoordinates.y;   

            return <div className={'tag-menu-select'} style={{ left: `calc(${x}px)`, top: `calc(${y}px - 17rem)` }}>
                <div className={'search-bar-wrapper'}>
                    <input type={'text'} placeholder='Search' value={searchTags} onChange={handleSearchTag} />
                    <FontAwesomeIcon icon={faMagnifyingGlass} className={'mag-icon'} />
                </div>
                <div className={'search-items-wrapper'}>
                    {_.map(Object.keys(tagMap), t => {
                        if (searchTags?.length !== 0) {
                            if (t.toLowerCase().startsWith(searchTags.toLowerCase()) || tagMap[t].name.toLowerCase().startsWith(searchTags.toLowerCase())) {
                                return (<div key={t} className={'item'} onClick={() => { applyTag(t) }}>
                                    <div className={'checkbox'} >
                                        {
                                            Object.keys({ ...note.tags }).reduce((r, cv) => {
                                                if (cv === activeTag) {
                                                    if (note?.tags[cv].includes(t))
                                                        r.push(t);
                                                }
                                                return r;
                                            }, []).length > 0 ?
                                                <FontAwesomeIcon icon={faCheck} />
                                                :
                                                null
                                        }
                                    </div>
                                    <div className={'text'}>{t} - {tagMap[t].name}</div>
                                </div>)
                            }
                        }
                        else {
                            return (<div key={t} className={'item'} onClick={() => { applyTag(t) }}>
                                <div className={'checkbox'} >
                                    {
                                        Object.keys(note.tags).reduce((r, cv) => {
                                            if (cv === activeTag) {
                                                if (note?.tags[cv].includes(t))
                                                    r.push(t);
                                            }
                                            return r;
                                        }, []).length > 0 ?
                                            <FontAwesomeIcon icon={faCheck} />
                                            :
                                            null
                                    }
                                </div>
                                <div className={'text'}>{t} - {tagMap[t].name}</div>
                            </div>)
                        }
                    })}
                </div>
                <div className={'tag-menu-footer'}>
                    <div className={'clear-all'} onClick={() => { clearTagsForSelection() }}>
                        Clear Tags
                    </div>
                    <div className={'btns-wrapper'}>
                        <div className={'control-btn'} onClick={() => { handleCancel() }}>CANCEL</div>
                        <div className={`control-btn able-to-save`} onClick={() => { handleSave() }}>SAVE</div>
                    </div>
                </div>
            </div>
        }
    }

    const doesNoteContainTag = (tag) => {
        let noteContainsTag = false;
        if (note?.tags) {
            Object.keys(note.tags).forEach(phrase => {
                if (note.tags[phrase]?.includes(tag))
                    noteContainsTag = true;
            })
        }
        if (note?.media) {
            note.media.forEach(m => {
                if (m.tags?.length > 0) {
                    if (m.tags.includes(tag)) {
                        noteContainsTag = true;
                    }
                }
            })
        }
        return noteContainsTag;
    }

    const renderTags = () => {
        return (<>
            <div className={'tag-token-area'}>
                {Object.keys(tagMap).map(tag => {
                    return doesNoteContainTag(tag) ?
                        <div key={`${tag}-${note.id}`}
                            className={`tag-token${locallyFocusedTag === tag ? ' active' : ''} ${isTagMode ? ' remove-cursor' : ''}`}
                            onClick={() => {
                                if (!isTagMode) {
                                    if (locallyFocusedTag === tag) {
                                        setLocallyFocusedTag(null);
                                    }
                                    else {
                                        setLocallyFocusedTag(tag);
                                    }
                                }
                            }}
                        >
                            {tagMap[tag].name}
                        </div>
                        : null
                })}
            </div>
        </>)
    }

    const handleSelectFile = (event) => {
        inputFile.current.click();
    }

    const handleOnFileChange = (dt, isInitialFile) => {
        if (isInitialFile) {
            setInitialMediaLoading(true);
            let currentFileId = null;

            const uploadWorker = new Worker(fileUploadWorkerScript);
            uploadWorker.onmessage = (postResult) => {
                const result = postResult?.data?.first();
                if (result?.Success) {
                    const item = result.Items?.first();
                    if (item) {
                        let media = JSON.parse(JSON.stringify(note.media));
                        let newMedia = {
                            SurveyContentId: uuidv4(),
                            FileId: item.FileId,
                            FileUrl: item.FileUrl,
                            tags: []
                        }
                        media.push(newMedia);
                        let noteToUpdate = { ...note, media };
                        setNote(noteToUpdate);
                        onUpdate('save-media', noteToUpdate);
                        sessionStorage.removeItem('FileUpload-new');
                    }
                }
                else {
                    console.error(`Failed to upload ${inputFile.current.files[0].name}: ${result?.Message?.DisplayMessage}`);
                    inputFile.current.value = "";
                }
                setInitialMediaLoading(false);
            }

            const gas = authStateManager._globalAuthState.get({ noproxy: true });
            const service = new BaseService(`file/upload${currentFileId ? `/${currentFileId}` : ''}`, authStateManager._globalAuthState, undefined, 20 * 60 * 60);
            const serviceConfig = {
                accessToken: gas.accessToken,
                timeout: service._timeout,
                url: service._url,
                clientId: service._clientId,
                appId: configData.APP_ID
            };
            uploadWorker.postMessage({ file: inputFile.current.files[0], currentFileId, serviceConfig, isPublic: false });
        }
        else {
            let media = JSON.parse(JSON.stringify(note.media));
            let newMedia = {
                SurveyContentId: uuidv4(),
                FileId: dt.value?.FileId,
                FileUrl: dt.value?.FileUrl,
                tags: []
            }
            media.push(newMedia);
            let noteToUpdate = { ...note, media };
            setNote(noteToUpdate);
            onUpdate('save-media', noteToUpdate);
            sessionStorage.removeItem('FileUpload-new');
        }

    }

    const checkIsVideo = (fileUrl) => {
        return ((fileUrl?.toLowerCase()?.includes('.mpeg') ||
            fileUrl?.toLowerCase()?.includes('.mpg') ||
            fileUrl?.toLowerCase()?.includes('.mpga') ||
            fileUrl?.toLowerCase()?.includes('.avi') ||
            fileUrl?.toLowerCase()?.includes('.mp4') ||
            fileUrl?.toLowerCase()?.includes("streaming.media.azure.net")
        ));
    }

    const [initialMediaUploading, setInitialMediaLoading] = useState(false);

    const renderMediaArea = () => {
        return (
            <div className={'media-area'}>
                {(!note.media || note.media.length === 0) ?
                    <>
                        <ButtonControl
                            className={`upload-media-btn${!canEdit || newNote ? ' disable' : ''}`}
                            value={'UPLOAD MEDIA'}
                            onClick={(e) => {
                                handleSelectFile(e);
                            }}
                            disabled={!canEdit || newNote || initialMediaUploading}
                            loading={initialMediaUploading}
                            disabledTitle={newNote && 'Please enter a title & save your note in order to add media evidence.'}
                        />
                        <input ref={inputFile} type={'file'} className={'upload-media-btn'} style={{ display: 'none' }} onChange={(e) => handleOnFileChange(e.target, true)} />
                    </>
                    :
                    <>
                        {
                            note.media?.map((m, i) => {
                                if (initialMediaUploading) {
                                    setInitialMediaLoading(false);
                                }
                                return (
                                    <div key={`${m.FileId}-uploaded`}
                                        ref={el => itemsEls.current[i] = el}
                                        className={`upload-media-pane${(tagModeMedia.isActive && tagModeMedia.index === i) || (m.tags?.includes(locallyFocusedTag) || m.tags?.includes(globallyFocusedIndicator)) ? ' focused' : ''}`} onClick={() => {
                                            if (isTagMode) {
                                                greyOutMostNotes(true);
                                                setTagModeMedia({ isActive: true, index: i, fileId: m.FileId });
                                                setIsTagMode(true);
                                                setShowTagMenu(true);
                                            }
                                        }}>
                                        <FileUploaderControl
                                            hideControls={true}
                                            disabled={isTagMode || !canEdit}
                                            controlId={m.FileId}
                                            maxFileSize={52428800}
                                            fileUrl={m.FileUrl}
                                            canUploadAnyFile={true}
                                            overrideMaxWidth={50}
                                            widthRes={190}
                                            heightRes={90}
                                            forceDim={true}
                                            dontDisplayFileName={true}
                                        />
                                        {isTagMode || (m.tags?.includes(locallyFocusedTag) || m.tags?.includes(globallyFocusedIndicator)) ? <div className={`tagmode${(m.tags?.includes(locallyFocusedTag) || m.tags?.includes(globallyFocusedIndicator)) ? ' without-cursor' : ''}`}></div> : null}
                                        <div className={`control-panel${(tagModeMedia.isActive && tagModeMedia.index === i) || (m.tags?.includes(locallyFocusedTag) || m.tags?.includes(globallyFocusedIndicator)) ? ' active' : ''}`}>
                                            <div className={'control-btns'}>
                                                {!checkIsVideo(m.FileUrl) && <a href={m.FileUrl} onClick={(e) => e.stopPropagation()} download><FontAwesomeIcon icon={faDownload} className={'fa-icon'} /></a>}

                                                <FontAwesomeIcon
                                                    icon={faExpand}
                                                    className={'fa-icon'}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDialogue({
                                                            isOpen: true,
                                                            onOk: undefined,
                                                            cancelText: 'CLOSE',
                                                            children: <div className={'media-dialogue-wrapper'}>
                                                                {
                                                                    checkIsVideo(m.FileUrl) ?
                                                                        <FileUploaderControl
                                                                            hideControls={false}
                                                                            disabled={true}
                                                                            controlId={`${m.FileId}-expanded`}
                                                                            maxFileSize={52428800}
                                                                            fileUrl={m.FileUrl}
                                                                            canUploadAnyFile={true}
                                                                            overrideMaxWidth={100}
                                                                            widthRes={480}
                                                                            heightRes={240}
                                                                            forceDim={true}
                                                                            dontDisplayFileName={true}
                                                                        />
                                                                        :
                                                                        <img src={m.FileUrl} alt='' />
                                                                }
                                                            </div>
                                                        })
                                                    }}
                                                />
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className={`fa-icon${!canEdit ? ' disable' : ''}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (canEdit) {
                                                            setDialogue({
                                                                isOpen: true,
                                                                subTitle: 'Are you sure you want to delete this media?',
                                                                title: 'Confirm Delete?',
                                                                onOk: () => {
                                                                    let noteToUpdate = JSON.parse(JSON.stringify(note));
                                                                    let mediaToUpdate = JSON.parse(JSON.stringify(note.media));
                                                                    mediaToUpdate = mediaToUpdate.reduce((r, cv) => {
                                                                        if (cv.FileId !== m.FileId) {
                                                                            r.push(cv);
                                                                        }
                                                                        return r;
                                                                    }, []);
                                                                    noteToUpdate.media = mediaToUpdate;
                                                                    setNote(noteToUpdate);
                                                                    setDialogue({ isOpen: false });
                                                                    onUpdate('save-media', noteToUpdate);
                                                                },
                                                                okText: 'DELETE',
                                                                cancelText: 'CANCEL',
                                                                children: <div className={'dialogue-section'}>
                                                                    <div>
                                                                        <FileUploaderControl
                                                                            disabled={true}
                                                                            controlId={m.FileId + '-dialogue'}
                                                                            maxFileSize={52428800}
                                                                            fileUrl={m.FileUrl}
                                                                            canUploadAnyFile={true}
                                                                            overrideMaxWidth={50}
                                                                            widthRes={200}
                                                                            heightRes={100}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {m.tags?.length > 0 && m.tags.map((t, i) => {
                                                return <div className={'tag-abbr'}>{i < m.tags.length - 1 ? `,${t}` : t}</div>
                                            })}
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            !isTagMode && canEdit &&
                            <div className={`upload-media-pane`}>
                                <FileUploaderControl
                                    controlId={'new'}
                                    dontUseSessionStorage={true}
                                    dontShowImage={true}
                                    dontDisplayFileName={true}
                                    maxFileSize={52428800}
                                    onChange={(e) => handleOnFileChange(e.target)}
                                    fileUrl={null}
                                    canUploadAnyFile={true}
                                    overrideMaxWidth={50}
                                    widthRes={200}
                                    heightRes={100}
                                    disabled={!canEdit}
                                />
                            </div>
                        }
                    </>
                }
            </div>
        )
    }

    const handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 's') {
            e.preventDefault();
            handleSave();
        }
    }

    return (<>
        <div className={'observation-note-wrapper'}>
            {
                dialogue.isOpen ?
                    <DialogControl
                        openDialog={dialogue.isOpen}
                        title={dialogue.title ?? ''}
                        subTitle={dialogue.subTitle ?? ''}
                        onOk={dialogue.onOk}
                        onCancel={() => { setDialogue({ isOpen: false }) }}
                        okText={dialogue.onOk && (dialogue.okText ?? 'OKAY')}
                        cancelText={dialogue.cancelText ?? 'CANCEL'}
                    >
                        {dialogue.children}
                    </DialogControl>
                    : null
            }
            <div className={'top-banner'}>
                <div className={`time-and-title${isEditMode || isTagMode || note.newNote ? ' expand-width' : ''}`}>
                    {
                        isEditMode || note.newNote ?
                            <>
                                <input
                                    value={note.time}
                                    className={'time-input'}
                                    onChange={(e) => handleEdit('time', e)}
                                />
                                <div style={{ paddingTop: '0.4rem', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>-</div>
                                <input
                                    placeholder={'Section Title'}
                                    value={note.sectionTitle}
                                    className={'title-input'}
                                    onChange={(e) => handleEdit('sectionTitle', e)}
                                    ref={titleRef}
                                />
                            </>
                            :
                            <>
                                <div>{note.time}</div>
                                <div style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>-</div>
                                <div>{note.sectionTitle}</div>
                            </>
                    }
                </div>
                <div className={'control-btn-area'}>
                    {
                        isEditMode || isTagMode || note.newNote ?
                            <>
                                {isTagMode && <div className='tagmode-message'>TAG MODE</div>}
                                <div className={'control-btn'} onClick={() => { handleCancel() }}>{isTagMode ? 'CLOSE' : 'CANCEL'}</div>
                                {!isTagMode && <div className={`control-btn${note.sectionTitle.length > 0 ? ' able-to-save' : ' disabled'}`} onClick={note.sectionTitle.length > 0 ? handleSave : null}>SAVE</div>}
                            </>
                            :
                            <>
                                <FontAwesomeIcon title='Edit Evidence' icon={faPencil} className={`fa-icon${!canEdit ? ' disable' : ''}`} onClick={() => {
                                    if (canEdit) {
                                        setIsEditMode(!isEditMode)
                                    }
                                }
                                } />
                                <FontAwesomeIcon title='Tag Evidence' icon={faTag} className={`fa-icon${!canEdit ? ' disable' : ''}`} onClick={() => {
                                    if (canEdit) {
                                        setLocallyFocusedTag(null);
                                        setGloballyFocusedIndicator(null);
                                        setIsTagMode(!isTagMode)
                                    }
                                }
                                } />
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    title='Delete Evidence'
                                    className={`fa-icon${!canEdit ? ' disable' : ''}`}
                                    //onClick={handleDelete} 
                                    onClick={() => {
                                        if (canEdit) {
                                            setDialogue({
                                                isOpen: true,
                                                title: `Delete note "${note.sectionTitle}"?`,
                                                onOk: handleDelete,
                                                onCancel: () => { setDialogue({ isOpen: false }) },
                                                okText: 'DELETE'
                                            })
                                        }
                                    }}
                                />
                            </>
                    }
                </div>
            </div>
            <div className={`note-text${(isEditMode || isTagMode || note.newNote) && (isTagMode || Object.keys(note?.tags ?? {}).length === 0) ? ` is-edit${showTagMenu ? ' is-tagging-active' : ''}` : ' no-select'}`}>
                {(isEditMode || isTagMode || note.newNote) && (isTagMode || Object.keys(note?.tags ?? {}).length === 0) ?
                    <div
                        className={`edit-text-area${isTagMode ? highlightedTxt.length === 0 ? ' different-selection-highlight' : ' disable-selection-highlight' : ''}`}
                        contentEditable={(isEditMode || note.newNote) && Object.keys(note?.tags ?? {}).length === 0}
                        suppressContentEditableWarning={true}
                        onInput={(e) => {
                            if (!isTagMode) {
                                onInput(e)
                            }

                        }}
                        onKeyDown={handleKeyDown}
                        onMouseDown={mouseDownHandler}
                        ref={noteTextRef}
                    >
                        {renderEvidence()}
                        {showTagMenu || activeTag !== '' ? renderTagMenu() : null}
                    </div>
                    :
                    <>
                        {renderEvidence()}
                    </>
                }
            </div>
            {(isTagMode) ? <div className={'locked-message'}><FontAwesomeIcon icon={faInfoCircle} className={'fa-lock-style'} />This mode is for highlighting/tagging evidence. Text cannot be edited in Tag Mode and once tags are applied.</div> : null}
            {renderTags()}
            {renderMediaArea()}
        </div>
    </>)
}