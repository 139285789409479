import { useEffect, useState } from 'react';
import './details.scss';
import { TagPicker, TreePicker } from 'rsuite';
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import { CheckboxControl, SelectListControl } from '../../../../components/controls';
import { useValidationManager } from '../../../../hooks/useValidation';
import { DialogControl } from '../../../../components/controls/DialogControl';
import Loading from '../../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useAppUserManager, useOrgManager, usePermissionManager, useRoleManager } from '../../../../hooks/useManagers';
import _ from 'lodash';
import Info from '../../../../components/controls/Info';
import { validate as isValidUUID } from 'uuid';


const RoleDetails = ({ canWrite, role, onCancel, onSave, onErrors, onValidationErrors }) => {
    const roleMgr = useRoleManager();
    const [addPerm, setAddPerm] = useState(false);
    const [currentPerms, setCurrentPerms] = useState(null);
    const [currentPermsSearch, setCurrentPermsSearch] = useState('');
    const [roleItem, setRoleItem] = useState(role ?? roleMgr.DefaultItem);
    const [saving, setSaving] = useState(false);
    const [forceValidate, setForceValidate] = useState(false);
    const [permissions, setPermissions] = useState(null);
    const [permDialogValToAdd, setPermDialogValToAdd] = useState(null);
    const validationMgr = useValidationManager(false, 'ROLE');
    const permissionMgr = usePermissionManager();
    const appUserManager = useAppUserManager();
    const orgMgr = useOrgManager();
    const [organizations, setOrganizations] = useState(null);
    const [canWritePermissionSetting, setCanWritePermissionSetting] = useState(false);
    const [canDeletePermissionSetting, setCanDeletePermissionSetting] = useState(false);
    const [deletingPermission, setDeletingPermission] = useState(null);

    useEffect(() => {
        if (appUserManager) {
            setCanWritePermissionSetting(appUserManager?.canWrite("PermissionSetting"));
            setCanDeletePermissionSetting(appUserManager?.canDelete("PermissionSetting"));
        }
    }, [appUserManager]);

    useEffect(() => {
        if (orgMgr) {
            orgMgr.loadOrgTree().then(r => {
                setOrganizations(orgMgr.OrgsOnly());
            });
        }
    }, [orgMgr]);

    useEffect(() => {
        if (roleMgr && !currentPerms) {
            const roleId = roleItem?.RoleId?.toLowerCase();
            if (roleId) {
                roleMgr.GetPermissionSettings("x => x.IsActive == true && x.IsDeleted == false && x.RoleId == roleId", { roleId }).then((r) => {
                    if (r?.first()?.Success) {
                        let permissions = r.first().Items;
                        permissions = permissions.map(x => { return { ...x, DisplayName: x.PermissionInfo.DisplayName, PermissionTypeName: x.PermissionInfo.PermissionTypeName } });
                        // filters out all the permission settings not tied to the role
                        setCurrentPerms(_.orderBy(permissions, ['PermissionTypeName', 'DisplayName']));
                    }
                });
            }
            else {
                setCurrentPerms([]);
            }
        }
    }, [roleMgr])

    useEffect(() => {
        if (permissionMgr && permissions === null) {
            permissionMgr.list().then((r) => {
                if (r?.Success) {
                    setPermissions(_.orderBy(r.Items, p => (p.Id)));
                }
            });
        }
    }, [permissionMgr])

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    const handleOnSave = async (e) => {
        setSaving(true);
        const validateResult = validationMgr.checkIsGroupValid(roleItem);
        if (validateResult?.length === 0) {
            const roleName = roleItem.Role;
            const roleId = roleItem.Id ?? '00000000-0000-0000-0000-000000000000';
            const roleNameValidate = await roleMgr.query("x => x.Role == roleName && x.Id != roleId", { roleName, roleId });
            if (roleNameValidate.Success && roleNameValidate?.Items?.length > 0) {
                validateResult.push("Role already exists, please choose another role name.");
            }
            else if (!roleNameValidate.Success)
                validateResult.push("Error while checking for duplicate role.");
        }

        if (validateResult?.length === 0) {
            setForceValidate(false);
            if (onSave) {

                if (roleItem.IsSystemAdmin) {
                    roleItem.Sequence = 1;
                }

                var result = await onSave(roleItem);
                if (result?.Success) {
                    await roleMgr.SavePermissionSettings(result?.Items.first(), currentPerms);
                    handleOnCancel();
                    setSaving(false);
                    return result;
                }
                else if (onErrors) {
                    onErrors([result?.Message?.DisplayMessage ?? [...(result?.MessageDetails ?? ['Unknown Error'])]])
                    setSaving(false);
                    return result;
                }
            }
        }
        else if (onErrors) {
            setForceValidate(true);
            onValidationErrors([...validateResult]);
            setSaving(false);
        }

    }

    const handleOnChange = (value, field) => {
        let updatedRole = { ...roleItem };
        if (field === 'IsSystemAdmin' && value === true) {
            updatedRole.ClientId = null;
        }

        updatedRole[field] = value;
        setRoleItem(updatedRole);
    }

    const handlePermDialog = (value) => {
        if (addPerm === true) { // close the dialog and add the permission, if specified
            if (value !== null && value !== undefined) {
                handleAddPerm(value);
                setPermDialogValToAdd(null);
            }
            setAddPerm(false);
        } else { // open the dialog
            setAddPerm(true);
        }
    }

    const handleAddPerm = (values) => {

        if (permissions?.length > 0) {
            // We need to add the permissions to the role
            values.forEach((value) => {
                currentPerms.push(_.find(permissions, x => x.Id === value));
            })
        }
    }

    const handleDeletePerm = (item) => async (e) => {
        const permissionSetting = currentPerms.find(perm => perm.Permission === item.Permission);
        if (isValidUUID(permissionSetting.Id)) {
            setDeletingPermission(permissionSetting.Id);
            const result = await roleMgr.DeletePermSetting(permissionSetting.Id);
            if (result.Success) {
                setCurrentPerms([...currentPerms.filter((perm) => permissionSetting.Permission !== perm.Permission)]);
            }
            else {
                onErrors(["Failed to delete permission."]);
            }
            setDeletingPermission(null);
        }
        else {
            setCurrentPerms([...currentPerms.filter((perm) => permissionSetting.Permission !== perm.Permission)]);
        }
    }

    const handleModifyPermSettings = (Id) => (value, field) => {
        // We need to modify the permission settings
        const updateCurrentPerms = { ...currentPerms }
        const indexOfPerm = _.findIndex(currentPerms, perm => perm.Id === Id)
        setCurrentPerms(currentPerms.map((perm, i) => {
            if (i === indexOfPerm) {
                perm[field] = value;
            }
            return perm;
        }))

    }


    const handleCurrentPermsSearch = (value) => {
        setCurrentPermsSearch(value.toLowerCase());
    }

    const getPrecedence = () => {
        const precedences = [];
        if (roleItem.IsSystemAdmin) {
            precedences.push({ Text: 1, Value: 1 });
        }

        for (let i = 2; i < 20; i++) {
            precedences.push({ Text: i, Value: i });
        }

        precedences.push({ Text: 'Max', Value: 1000 });
        return precedences;
    }

    // Converts the permissions to a list of text value pairs and filters out those already applied to the role
    let permissionTextValuePairs = _.map(_.filter(permissions, x => {
        if (!_.find(currentPerms, y => y.Permission === x.Permission)) {
            return x
        }
    }), (perm, index) => ({ label: perm.DisplayName, value: perm.Id }))

    permissionTextValuePairs = _.orderBy(permissionTextValuePairs, p => (p.label));

    return (<>
        <DialogControl
            openDialog={addPerm}
            title={'Add Role Permissions'}
            onCancel={() => handlePermDialog(undefined)}
            onOk={() => handlePermDialog(permDialogValToAdd)}
            okText={'add'}
            enableNext={permDialogValToAdd?.length === 0 || !permDialogValToAdd}
            nextText={'add all'}
            onNext={() => handlePermDialog(permissionTextValuePairs.filter(x => x.value !== 'Developer').map((pair) => { return pair.value }))}
        >
            {/** We need a list of permissions to add to the role based on a dropdown */}
            <InputField forceValidate={forceValidate} title="Role Permissions" fieldName="RolePermissionSelect" groupId={'ROLE'}>
                <TagPicker
                    className='tag-picker'
                    data={permissionTextValuePairs === undefined ? [] : permissionTextValuePairs}
                    onChange={setPermDialogValToAdd}
                    loading={permissionTextValuePairs === undefined}
                    block
                    preventOverflow
                />
            </InputField>
        </DialogControl>
        <div className='control-box-wrapper'>
            {roleItem && <> <div className='control-box box-three-column role-left-box'>

                <InputField forceValidate={forceValidate} type="text" subTitle={'Once set, the role cannot be changed.'} value={roleItem?.Role} isDisabled={roleItem.CreatedDate} title="Role" fieldName="Role" groupId={'ROLE'} onChange={handleOnChange} />
                <InputField forceValidate={forceValidate} type="text" title="Display Name" value={roleItem?.Name} fieldName="Name" groupId={'ROLE'} onChange={handleOnChange} />
                <InputField forceValidate={forceValidate} type="textarea" title="Description" maxLength={150} value={roleItem?.Description} fieldName="Description" groupId={'ROLE'} onChange={handleOnChange} />
                {organizations ? <InputField
                    forceValidate={forceValidate}
                    value={roleItem?.ClientId}
                    subTitle={`${roleItem?.IsSystemAdmin ? 'Organization roles cannot be set as system administrators.' : 'Create a role for a specific organization. Any permissions set on this role will override all other permissions in other roles assigned to the user.'}`}
                    title="Organization"
                    fieldName="ClientId"
                    validationName={'district_ClientId'}
                >
                    <TreePicker
                        height={320}
                        width={400}
                        disabled={roleItem?.IsSystemAdmin}
                        data={organizations ?? []}
                        valueKey={'OrgId'}
                        labelKey={'Name'}
                        onClean={() => handleOnChange(null, "ClientId")}
                        onSelect={(a, val) => handleOnChange(val, 'ClientId')}
                        placement="autoVerticalStart"
                    />
                </InputField> : <Loading />}
                <InputField forceValidate={forceValidate} title="Precedence" isDisabled={roleItem?.IsSystemAdmin} value={roleItem?.IsSystemAdmin ? 1 : (roleItem?.Sequence ?? "UNK")} fieldName="Sequence" groupId={'ROLE'} onChange={handleOnChange} >
                    <SelectListControl textValuePairs={getPrecedence()} ></SelectListControl>
                </InputField>
                {appUserManager?.GetGlobalState().rolePrecedence === 1 &&
                    <InputField title="System Admin?" value={roleItem?.IsSystemAdmin} fieldName="IsSystemAdmin" groupId={'ROLE'} onChange={handleOnChange} >
                        <CheckboxControl />
                    </InputField>}
                <InputField
                    title="Active?"
                    value={roleItem?.IsActive}
                    disableError={true}
                    fieldName="IsActive"
                    onChange={handleOnChange}
                >
                    <CheckboxControl />
                </InputField>
            </div>{appUserManager?.canView("PermissionSetting") &&
                <div className='role-perm-editor__wrapper control-box box-twothirds-column '>
                    <div className='role-perm-editor__header'>
                        <h3>Role Permissions</h3>
                        <ButtonControl disabled={!canWritePermissionSetting} onClick={() => handlePermDialog(undefined)}>Add New</ButtonControl>
                    </div>
                    <div className='role-perm-editor__search'>
                        <InputField
                            type="text"
                            value={currentPermsSearch}
                            fieldName='search'
                            disableError={true}
                            placeholder='Search Permission Name'
                            onChange={handleCurrentPermsSearch}>
                        </InputField>
                    </div>
                    <div className='role-perm-editor'>
                        {/* We need a list of permission settings items */}
                        <table className="__table">
                            <thead className="__head">
                                <tr>
                                    <th className="__type">Type</th>
                                    <th className="__name-heading">Permission</th>
                                    <th className="__flex">View</th>
                                    <th className="__flex">Write</th>
                                    <th className="__flex">Execute</th>
                                    <th className="__flex">Delete</th>
                                    <th className="__flex">Hard Delete</th>
                                    <th className="__icon" />
                                </tr>
                            </thead>
                            <tbody style={{ overflowY: currentPerms ? "auto" : "clip" }}>
                                {
                                    currentPerms ?
                                        _.orderBy(currentPerms ?? [], x => (x.PermissionInfo?.DisplayName ?? x.DisplayName)).filter((cPerm) => cPerm.PermissionInfo?.DisplayName ? cPerm.DisplayName.toLowerCase().includes(currentPermsSearch.toLowerCase()) : cPerm.Permission.toLowerCase().includes(currentPermsSearch.toLowerCase()) || currentPermsSearch === '').map((cPerm, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="__type"><div>{cPerm?.PermissionInfo?.PermissionTypeName}</div></td>
                                                    <td className="__name">{(cPerm?.PermissionInfo?.Description && cPerm?.PermissionInfo?.Description.toLowerCase() !== cPerm.Permission.toLowerCase()) && <Info inGrid={true}>{cPerm.PermissionInfo.Description}</Info>}
                                                    {!(cPerm?.PermissionInfo?.Description && cPerm?.PermissionInfo?.Description.toLowerCase() !== cPerm.Permission.toLowerCase()) && <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>}
                                                    <div className='displayName'>{cPerm?.PermissionInfo?.DisplayName ?? cPerm.DisplayName}</div></td>
                                                    <td className="__flex">
                                                        <InputField isDisabled={!canWritePermissionSetting} disableError={true} value={cPerm.CanView} fieldName="CanView" groupId={'ROLE'} onChange={handleModifyPermSettings(cPerm.Id)} >
                                                            <CheckboxControl />
                                                        </InputField></td>
                                                    <td className="__flex"><InputField isDisabled={!canWritePermissionSetting} disableError={true} value={cPerm.CanWrite} fieldName="CanWrite" groupId={'ROLE'} onChange={handleModifyPermSettings(cPerm.Id)} >
                                                        <CheckboxControl />
                                                    </InputField></td>
                                                    <td className="__flex"><InputField isDisabled={!canWritePermissionSetting} disableError={true} value={cPerm.CanExecute} fieldName="CanExecute" groupId={'ROLE'} onChange={handleModifyPermSettings(cPerm.Id)} >
                                                        <CheckboxControl />
                                                    </InputField></td>
                                                    <td className="__flex"><InputField isDisabled={!canWritePermissionSetting} disableError={true} value={cPerm.CanDelete} fieldName="CanDelete" groupId={'ROLE'} onChange={handleModifyPermSettings(cPerm.Id)} >
                                                        <CheckboxControl />
                                                    </InputField></td>
                                                    <td className="__flex"><InputField isDisabled={!canWritePermissionSetting} disableError={true} value={cPerm.CanHardDelete} fieldName="CanHardDelete" groupId={'ROLE'} onChange={handleModifyPermSettings(cPerm.Id)} >
                                                        <CheckboxControl />
                                                    </InputField></td>
                                                    <td className="__icon">
                                                        {canDeletePermissionSetting ? <>{cPerm.Id == deletingPermission ? <Loading /> : <FontAwesomeIcon title="Delete Role Permission" className={'icon btn-icon-fa'} icon={faTrashCan} onClick={handleDeletePerm(cPerm)} />} </> : null}
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr><td colSpan={7}><div className='loading-wrapper'><Loading type='default' size='3rem' /></div></td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>}

            </>}
        </div>
        <div className='screen-footer screen-footer-right'>
            <div className='btn-wrapper-left screen-footer__btn'><ButtonControl disabled={saving} type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl></div>
            {canWrite && <div className='btn-wrapper-right screen-footer__btn'><ButtonControl loading={saving} type={'okay'} disabled={!roleItem} onClick={handleOnSave}>Save</ButtonControl></div>}

        </div>
    </>)
}

export default RoleDetails;


