import React, { useEffect, useState } from 'react';
import './styles/app.scss';
import Home from './domains/home/screens/Main';
import Token from './domains/dev/screens/Token';
import Test from './domains/dev/screens/Test';
import Media from './domains/dev/screens/Media';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Init from './components/Init';
import Error from './components/Error';
import SecureRoute from './components/security/SecureRoute';
import PublicRoute from './components/security/PublicRoute';
import Login from './components/security/Login';
import Logout from './components/security/Logout';
import AuthCallback from './components/security/AuthCallback';

import { useManageAuth } from './hooks/useAuth';
import AuthError from './components/security/AuthError';
import Content from './domains/contentLibrary/app-pages/Content';
import ControlTest from './domains/dev/screens/ControlTest';
import ContentShare from './domains/contentLibrary/app-pages/ContentShare';
import RubricList from './domains/admin/screens/RubricMgmt';
import RubricIndicator from './domains/admin/screens/RubricIndicator';
import TableControlTest from './domains/dev/screens/TableControlTest';
import ContentMgmt from './domains/admin/screens/ContentMgmt';
import TagMgmt from './domains/admin/screens/TagMgmt';
import ProfileManagement from './domains/profile/screens/ProfileManagement';
import UserMgmt from './domains/admin/screens/UserMgmt';
import FeedbackMgmt from './domains/admin/screens/FeedbackMgmt';
import LogMgmt from './domains/admin/screens/LogMgmt';
import ReportsMgmt from './domains/admin/screens/ReportsMgmt';
import ReportMgmt from './domains/admin/screens/ReportMgmt';
import PermissionMgmt from './domains/admin/screens/PermissionMgmt';
import RoleMgmt from './domains/admin/screens/RoleMgmt';
import SchoolYearMgmt from './domains/admin/screens/SchoolYearMgmt';
import ObservationScripting from './domains/observations/screens/ObservationScripting';
import ObservationReview from './domains/observations/screens/ObservationReview';
import ObservationReviewPrint from './domains/observations/screens/ObservationReviewPrint';
import ObservationReviewDetailedPrint from './domains/observations/screens/ObservationReviewDetailedPrint';
import OrganizationMgmt from './domains/admin/screens/OrganizationMgmt';
import DistrictMgmt from './domains/admin/screens/DistrictMgmt';
import SchoolMgmt from './domains/admin/screens/SchoolMgmt';
import ClusterMgmt from './domains/admin/screens/ClusterMgmt';
import TosMgmt from './domains/admin/screens/TOSMgmt';
import NotificationMgmt from './domains/admin/screens/NotificationMgmt';
import SurveyTemplateMgmt from './domains/admin/screens/SurveyTemplateMgmt';
import ObservationSelfReflection from './domains/observations/screens/ObservationSelfReflection';
import MyDashboard from './domains/dashboard/screens/MyDashboard';
import SurveyScreen from './domains/surveys/SurveyScreen';
import TransactionMgmt from './domains/admin/screens/TransactionMgmt';
import GeneralSurveyMgmt from './domains/admin/screens/GeneralSurveyMgmt';
import ContentSyncMgmt from './domains/admin/screens/ContentSyncMgmt';
import ObservationSchoolAdminMgmt from './domains/observations/screens/ObservationSchoolAdminMgmt';
import ObservationTeacherMgmt from './domains/observations/screens/ObservationTeacherMgmt';
import CertificationRubricConfigAnswer from './domains/admin/components/contentLibrary/CertificationRubricConfigAnswer';
import CertificationScreen from './domains/certifications/CertificationScreen';
import Four0Four from './components/404';
import StagedUserMgmt from './domains/admin/screens/StagedUserMgmt';
import ObservationConfigMgmt from './domains/admin/screens/ObservationConfigMgmt';
import Analytics from './domains/analytics/screens/Analytics';
import ImportExportMgmt from './domains/admin/screens/ImportExportMgmt';

import { useAnalytics } from './utilities/useAnalytics';
import CertificationQuestionTeacherMgmt from './domains/admin/components/certificationQuestion/CertificationQuestionTeacherMgmt';
import CertificationQuestionAdminMgmt from './domains/admin/components/certificationQuestion/CertificationQuestionAdminMgmt';
import PayoutTeacherMgmt from './domains/admin/components/payout/PayoutTeacherMgmt';
import PayoutAdminMgmt from './domains/admin/components/payout/PayoutAdminMgmt';
import ExportDemoExample from './domains/dev/screens/ExportDemoExample';

//const { darkModeContext, modes } = useContext(ThemeProviderContext);
//const [isDarkMode] = darkModeContext;
//const lightDarkMode = modes;

const App = () => {
  useManageAuth();
  useAnalytics();

  return (
    <>
      <div className={'App'}>
        <Init />
        <Error />
        <Routes>
          <Route path="*" element={<PublicRoute component={Four0Four} />} />
          <Route path='/' exact={true} element={<PublicRoute component={Home} />} />
          <Route path='/login' exact={true} element={<Login />} />
          <Route path='/logout' exact={true} element={<Logout />} />
          <Route path='/AuthError' exact={true} element={<AuthError />} />
          <Route path='/test' element={<SecureRoute component={Test} />} />
          <Route path='/media' element={<SecureRoute component={Media} />} />
          <Route path='/report/:url' element={<SecureRoute permission={'Reports'} component={ReportMgmt} />} />
          <Route path='/content' element={<SecureRoute permission={"Content"} component={Content} />} />
          <Route path='/learning-modules' element={<SecureRoute permission={"Content"} component={Content} />} />
          <Route path='/resources' element={<SecureRoute permission={"Content"} component={Content} />} />
          <Route path='/video-library' element={<SecureRoute permission={"Content"} component={Content} />} />
          <Route path='/share' element={<SecureRoute permission={"ContentShare"} component={ContentShare} />} />
          <Route path='/profile' element={<SecureRoute permission={"UserProfile"} component={ProfileManagement} />} />
          <Route path='/Observation/SchoolAdmin' element={<SecureRoute permission={'Observation'} component={ObservationSchoolAdminMgmt} />} />
          <Route path='/Observation/Teacher' element={<SecureRoute permission={'Observation'} component={ObservationTeacherMgmt} />} />
          <Route path='/Observation/SchoolAdmin/:observationId' element={<SecureRoute permission={'Observation'} component={ObservationScripting} />} />
          <Route path='/Observation/Teacher/:observationId' element={<SecureRoute permission={'Observation'} component={ObservationScripting} />} />
          <Route path='/Observation/SchoolAdmin/SelfReflection/:observationId' element={<SecureRoute permission={'Observation'} component={ObservationSelfReflection} />} />
          <Route path='/Observation/Teacher/SelfReflection/:observationId' element={<SecureRoute permission={'Observation'} component={ObservationSelfReflection} />} />
          <Route path='/Observation/SchoolAdmin/review/:observationId' element={<SecureRoute permission={'Observation'} component={ObservationReview} />} />
          <Route path='/Observation/Teacher/review/:observationId' element={<SecureRoute permission={'Observation'} component={ObservationReview} />} />
          <Route path='/Observation/print/:observationId' element={<SecureRoute permission={'Observation'} component={ObservationReviewPrint} />} />
          <Route path='/Observation/printdetailed/:observationId' element={<SecureRoute permission={'Observation'} component={ObservationReviewDetailedPrint} />} />
          <Route path='/survey' element={<SecureRoute permission={'Survey'} component={SurveyScreen} />} />
          <Route path='/survey/:surveytype' element={<SecureRoute permission={'Survey'} component={SurveyScreen} />} />
          <Route path='/survey/:surveytype/:surveyId' element={<SecureRoute permission={'Survey'} component={SurveyScreen} />} />
          <Route path='/survey/:surveytype/review/:surveyId' element={<SecureRoute permission={'Survey'} component={SurveyScreen} />} />
          <Route path='/admin/content/rubricanswers/:contentId/:rubricId' element={<SecureRoute permission={"CertificationContentRubricRating"} component={CertificationRubricConfigAnswer} />} />
          <Route path='/certifications/teacher/' element={<SecureRoute permission={"NavTeacherCertification"} component={CertificationScreen} />} />
          <Route path='/certifications/teacher/:id' element={<SecureRoute permission={"NavTeacherCertification"} component={CertificationScreen} />} />
          <Route path='/certifications/administrator/' element={<SecureRoute permission={"NavAdminCertification"} component={CertificationScreen} />} />
          <Route path='/certifications/administrator/:id' element={<SecureRoute permission={"NavAdminCertification"} component={CertificationScreen} />} />
          <Route path='/auth' element={<PublicRoute component={AuthCallback} />} />

          <Route path='/dev/token' element={<SecureRoute permission={"Developer"} component={Token} />} />
          <Route path='/dev/ControlTest' element={<SecureRoute permission={"Developer"} component={ControlTest} />} />
          <Route path='/dev/TableTest' element={<SecureRoute permission={"Developer"} component={TableControlTest} />} />
          <Route path='/dev/pdfexport/example' element={<SecureRoute permission={"Developer"} component={ExportDemoExample} />} />

          <Route path='/my-dashboard' element={<SecureRoute permission={"UserProfile"} component={MyDashboard} />} />
          <Route path='/analytics' element={<SecureRoute permission={"UserProfile"} component={Analytics} />} />
          <Route path='/admin/Rubric' element={<SecureRoute permission={"Rubric"} component={RubricList} />} />
          <Route path='/admin/RubricIndicator/:rubricId' element={<SecureRoute permission={"Rubric"} component={RubricIndicator} />} />
          <Route path='/admin/RubricIndicator/:rubricId/:domainId' element={<SecureRoute permission={"Rubric"} component={RubricIndicator} />} />
          <Route path='/admin/Content' element={<SecureRoute permission={"Content"} component={ContentMgmt} />} />
          <Route path='/admin/Content/:contentId' element={<SecureRoute permission={"Content"} component={ContentMgmt} />} />
          <Route path='/admin/Content/edit/:contentId' element={<SecureRoute permission={"Content"} component={ContentMgmt} />} />
          <Route path='/admin/Tag' element={<SecureRoute permission={"Tag"} component={TagMgmt} />} />
          <Route path='/admin/Tag/:tagId' element={<SecureRoute permission={"Tag"} component={TagMgmt} />} />
          <Route path='/admin/Tag/edit/:tagId' element={<SecureRoute permission={"TagCanWrite"} component={TagMgmt} />} />
          <Route path='/admin/Feedback' element={<SecureRoute permission={"Feedback"} component={FeedbackMgmt} />} />
          <Route path='/admin/Feedback/:feedbackId' element={<SecureRoute permission={"Feedback"} component={FeedbackMgmt} />} />
          <Route path='/admin/Permission' element={<SecureRoute permission={"Permission"} component={PermissionMgmt} />} />
          <Route path='/admin/Permission/:permissionId' element={<SecureRoute permission={"PermissionCanWrite"} component={PermissionMgmt} />} />
          <Route path='/admin/Role' element={<SecureRoute permission={"Role"} component={RoleMgmt} />} />
          <Route path='/admin/Role/:roleId' element={<SecureRoute permission={"Role"} component={RoleMgmt} />} />
          <Route path='/admin/Organization' element={<SecureRoute permission={"Client"} component={OrganizationMgmt} />} />
          <Route path='/admin/Organization/:organizationId' element={<SecureRoute permission={"Client"} component={OrganizationMgmt} />} />
          <Route path='/admin/District' element={<SecureRoute permission={"District"} component={DistrictMgmt} />} />
          <Route path='/admin/District/:districtId' element={<SecureRoute permission={"District"} component={DistrictMgmt} />} />
          <Route path='/admin/School' element={<SecureRoute permission={"School"} component={SchoolMgmt} />} />
          <Route path='/admin/School/:schoolId' element={<SecureRoute permission={"School"} component={SchoolMgmt} />} />
          <Route path='/admin/Cluster' element={<SecureRoute permission={"Cluster"} component={ClusterMgmt} />} />
          <Route path='/admin/Cluster/:clusterId' element={<SecureRoute permission={"Cluster"} component={ClusterMgmt} />} />
          <Route path='/admin/tos' element={<SecureRoute permission={"TOS"} component={TosMgmt} />} />
          <Route path='/admin/tos/:tosId' element={<SecureRoute permission={"TOS"} component={TosMgmt} />} />
          <Route path='/admin/generalsurvey' element={<SecureRoute permission={"Survey"} component={GeneralSurveyMgmt} />} />
          <Route path='/admin/generalsurvey/:generalSurveyId' element={<SecureRoute permission={"Survey"} component={GeneralSurveyMgmt} />} />
          <Route path='/admin/surveyTemplate' element={<SecureRoute permission={"Survey"} component={SurveyTemplateMgmt} />} />
          <Route path='/admin/surveyTemplate/:surveyTemplateId' element={<SecureRoute permission={"Survey"} component={SurveyTemplateMgmt} />} />
          <Route path='/admin/Log' element={<SecureRoute permission={"Log"} component={LogMgmt} />} />
          <Route path='/admin/Log/:logId' element={<SecureRoute permission={"Log"} component={LogMgmt} />} />
          <Route path='/reports' element={<SecureRoute permission={"Reports"} component={ReportsMgmt} />} />
          <Route path='/reports/teacher' element={<SecureRoute permission={"Reports"} component={ReportsMgmt} />} />
          <Route path='/reports/school-administrator' element={<SecureRoute permission={"Reports"} component={ReportsMgmt} />} />
          <Route path='/reports/niet' element={<SecureRoute permission={"Reports"} component={ReportsMgmt} />} />
          <Route path='/admin/notification' element={<SecureRoute permission={"Notification"} component={NotificationMgmt} />} />
          <Route path='/admin/notification/:notificationId' element={<SecureRoute permission={"Notification"} component={NotificationMgmt} />} />
          <Route path='/admin/Feedback' element={<SecureRoute permission={"Feedback"} component={FeedbackMgmt} />} />
          {/* uPdate permission for observationCOnfig */}
          <Route path='/admin/Configuration' element={<SecureRoute permission={"NavApplicationAdminMgmt"} component={ObservationConfigMgmt} />} />
          {/* Update permission for observationCOnfig */}
          <Route path='/admin/Observationconfig' element={<SecureRoute permission={"Feedback"} component={ObservationConfigMgmt} />} />
          <Route path='/admin/ImportExport' element={<SecureRoute permission={"ImportExport"} component={ImportExportMgmt} />} />
          <Route path='/admin/ImportExport/:importExportId' element={<SecureRoute permission={"ImportExport"} component={ImportExportMgmt} />} />

          <Route path='/admin/User' element={<SecureRoute permission={"UserProfile"} component={UserMgmt} />} />
          <Route path='/admin/User/:userId' element={<SecureRoute permission={"UserProfile"} component={UserMgmt} />} />

          <Route path='/admin/StagedUser' element={<SecureRoute permission={"AzureB2CSaveUserProfiles"} component={StagedUserMgmt} />} />
          <Route path='/admin/StagedUser/:userId' element={<SecureRoute permission={"AzureB2CSaveUserProfiles"} component={StagedUserMgmt} />} />

          <Route path="/admin/Payout/Teacher" element={<SecureRoute permission={"PayoutTeacher"} component={PayoutTeacherMgmt} />} />
          <Route path="/admin/Payout/Admin" element={<SecureRoute permission={"PayoutAdmin"} component={PayoutAdminMgmt} />} />
          <Route path="/admin/Payout/Teacher/:payoutId" element={<SecureRoute permission={"PayoutTeacher"} component={PayoutTeacherMgmt} />} />
          <Route path="/admin/Payout/Admin/:payoutId" element={<SecureRoute permission={"PayoutAdmin"} component={PayoutAdminMgmt} />} />
          <Route path="/admin/Payout/Teacher/Configuration/:payoutConfigId" element={<SecureRoute permission={["PayoutTeacher", "ConfigurePayoutTeacherConfiguration"]} component={PayoutTeacherMgmt} />} />
          <Route path="/admin/Payout/Teacher/Configuration/Metrics/:payoutConfigId" element={<SecureRoute permission={["PayoutTeacher", "ConfigurePayoutTeacherConfiguration"]} component={PayoutTeacherMgmt} />} />
          <Route path="/admin/Payout/Admin/Configuration/:payoutConfigId" element={<SecureRoute permission={["PayoutAdmin", "ConfigurePayoutAdminConfiguration"]} component={PayoutAdminMgmt} />} />
          <Route path="/admin/Payout/Admin/Configuration/Metrics/:payoutConfigId" element={<SecureRoute permission={["PayoutAdmin", "ConfigurePayoutAdminConfiguration"]} component={PayoutAdminMgmt} />} />
          <Route path='/admin/Transaction' element={<SecureRoute permission={"Transaction"} component={TransactionMgmt} />} />
          <Route path='/admin/Transaction/:transactionId' element={<SecureRoute permission={"Transaction"} component={TransactionMgmt} />} />
          <Route path='/admin/SchoolYear' element={<SecureRoute permission={"SchoolYear"} component={SchoolYearMgmt} />} />
          <Route path='/admin/SchoolYear/:schoolYearId' element={<SecureRoute permission={"SchoolYear"} component={SchoolYearMgmt} />} />
          <Route path='/admin/ContentSync' element={<SecureRoute permission={"ContentSync"} component={ContentSyncMgmt} />} />
          <Route path='/admin/ContentSync/:contentSyncId' element={<SecureRoute permission={"ContentSync"} component={ContentSyncMgmt} />} />
          <Route path='/admin/CertificationQuestion/teacher' element={<SecureRoute permission={"CertificationQuestion"} component={CertificationQuestionTeacherMgmt} />} />
          <Route path='/admin/CertificationQuestion/schooladmin' element={<SecureRoute permission={"CertificationQuestion"} component={CertificationQuestionAdminMgmt} />} />
          <Route path='/admin/CertificationQuestion/teacher/:certificationQuestionId' element={<SecureRoute permission={"CertificationQuestion"} component={CertificationQuestionTeacherMgmt} />} />
          <Route path='/admin/CertificationQuestion/schooladmin/:certificationQuestionId' element={<SecureRoute permission={"CertificationQuestion"} component={CertificationQuestionAdminMgmt} />} />

        </Routes>
      </div>
    </>
  );
}

export default App;
