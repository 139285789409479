import React, { useState } from 'react';
import MainLayout from '../../../components/layout/MainLayout';
import { InputField } from '../../../components/controls/InputField'
import CheckboxControl from '../../../components/controls/CheckboxControl';
import SelectListControl from '../../../components/controls/SelectListControl';
import DatePickerControl from '../../../components/controls/DatePickerControl';
import ColorPickerControl from '../../../components/controls/ColorPickerControl';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';
import { useAppUserManager } from '../../../hooks/useManagers';
import ItemManager from '../../../managers/ItemManager';

const ControlTest = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const authMgr = useAppUserManager();
  const [queryJson, setQueryJson] = useState("");
  const onChange = (value, fieldName) => {
    console.log(`${fieldName}: `, value);
  }

  return (<>
    <DialogControl openDialog={openDialog} title="Test Dialog" onCancel={async () => { setOpenDialog(false) }} onOk={async () => { setOpenDialog(false) }} subTitle={'Input an item'}>
      <InputField title="Name" groupId={'DIALOG'} maxLength={100} fieldName="Name" />
      <InputField title="Description" groupId={'DIALOG'} type='textarea' maxLength={100} fieldName="Description" />
    </DialogControl>
    <MainLayout>
      <div className="screen-wrapper">
        <div className="screen-header">
          <h2>Control Test</h2>
          <h4>This screen is used to test the controls within the application.</h4>
        </div>
        <div className='control-box-wrapper'>
          <div className='control-box box-two-column'>
            <InputField title="Email Address" validateOnBlur={true} groupId={'TEST'} info={'Please enter your email address. This is a place where your email address is entered... a good place to do this.'} placeholder={'your.name@youremail.com'} maxLength={400} subTitle={"this is an email example"} fieldName="Email" validationName="test_Email" />
            <InputField title="Information" maxLength={100} groupId={'TEST'} subTitle={"this is an text area example"} type="textarea" fieldName="info" validationName="textBox" />
            <InputField title="Checkbox Test" maxLength={100} groupId={'TEST'} subTitle={"This is check box example"} >
              <CheckboxControl />
            </InputField>
            <InputField title="Single Select" maxLength={100} onChange={onChange} groupId={'TEST'} subTitle={"This is a single select example"} fieldName="singleSelect" >
              <SelectListControl textValuePairs={
                [{ Value: 'test1', Text: 'Test One' }, { Value: 'test2', Text: 'Test Two' }]
              } />
            </InputField>
            <InputField title="Date Picker" maxLength={100} onChange={onChange} groupId={'USER'} subTitle={"This is a date picker example"} fieldName="datePicker" >
              <DatePickerControl includeTime={false} />
            </InputField>
            <InputField title="Date Time Picker" maxLength={100} onChange={onChange} groupId={'USER'} subTitle={"This is a date time picker example"} fieldName="dateTimePicker" >
              <DatePickerControl includeTime={true} />
            </InputField>
          </div>
          <div className='control-box box-two-column'>
            <InputField title="test" validateOnBlur={true} groupId={'USER'} maxLength={100} subTitle={"this is an test"} fieldName="test" validationName="user_test" />
            <InputField title="color Picker" maxLength={100} onChange={onChange} groupId={'USER'} subTitle={"This is a color picker example"} fieldName="datePicker" >
              <ColorPickerControl />
            </InputField>
            <br />
            <ButtonControl onClick={() => { setOpenDialog(true) }}>Open Dialog</ButtonControl>
            <br />
            <ButtonControl to={'/content'} >Go To Content</ButtonControl>
            <InputField title="Query Builder" onChange={(v, f) => {
              try {
                if (v !== "")
                  setQueryJson(JSON.stringify(ItemManager.JSONObjFilter(v), null, "\t"));
              }
              catch (e) {
                setQueryJson("Invalid Query" + e);
              }
            }
            }></InputField>
            <pre style={{ cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(queryJson.replaceAll('"', `\\"`)); }}>{queryJson}</pre>
          </div>
          <div className='screen-footer'>
            <div className='btn-wrapper-left screen-footer__btn'><ButtonControl type={'okay'} onClick={() => { alert("Save Button") }}>Save</ButtonControl></div>
            <div className='btn-wrapper-right screen-footer__btn'><ButtonControl type={'cancel'} onClick={() => { alert("Cancel Button") }}>Cancel</ButtonControl></div>
          </div>
        </div>

      </div>
    </MainLayout>
  </>
  );
}

export default ControlTest;