import React, { useState } from 'react';
import TableControl from '../../../components/controls/TableControl/TableControl';
import data from '../data/tableSample';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import MainLayout from '../../../components/layout/MainLayout';

export default function NietPage() {

    const [columns] = useState([
        {
            header: 'Thumb',
            dataProp: "thumb",
            datatype: 'image',
            width: '18.5%',
            canSort: false
        },
        {
            header: 'Title',
            dataProp: "title",
            width: '16.5%',
            canSort: true
        },
        {
            header: 'Content Type',
            dataProp: "contentType",
            width: '16.5%',
            canSort: true
        },
        {
            header: 'Description',
            dataProp: "description",
            width: '16.5%',
            canSort: true
        },
        {
            header: 'Run Time',
            dataProp: "runTime",
            width: '16.5%',
            canSort: true
        },
        {
            header: 'Rating',
            dataProp: "rating",
            width: '16.5%',
            canSort: true
        },
        {
            header: 'Shared',
            dataProp: "shared",
            width: '16.5%',
            canSort: true
        },
        {
            header: 'Date Uploaded',
            dataProp: "dateUploaded",
            width: '16.5%',
            canSort: true
        }
    ])


    return <MainLayout>
        <div className="screen-wrapper">
            <div className="screen-header">
                <h2>Table Control Test</h2>
                <h4>This screen is used to test the controls within the application.</h4>
            </div>
            <div className='control-box-wrapper'>
                <div className='control-box'>
                    <div className='admin-table-wrapper'>
                        <TableControl
                            columns={columns}
                            data={data}
                            useIntegratedPaging={['10', '25', '50', '100']}
                            onView={() => console.log('onView called!')}
                            // onPrint={() => console.log('onPrint called!')}
                            // onDelete={() => console.log('onDelete called!')}
                            // onEdit={() => console.log('onEdit called!')}
                            onCustom1={{
                                title: 'Like',
                                action: <FontAwesomeIcon icon={faThumbsUp} onClick={() => { console.log('onCustom1 called!') }} className={'faIcon'} />
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
}