import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import SliderContext from './context'
import Content from './Content'
import SlideButton from './SlideButton'
import SliderWrapper from './SliderWrapper'
import useSliding from './useSliding'
import useSizeElement from './useSizeElement'
import './Slider.scss'
import { ContentTypeEnum } from '../../../../services/ContentService';
import { useAppUserManager, useContentManager } from '../../../../../../hooks/useManagers';


const Slider = ({ children, activeSlide, onLike, onDownload, onView, onShare, onFullScreen }) => {

  const _contentMgr = useContentManager();
  const authMgr = useAppUserManager()
  const permissions = authMgr?.GetGlobalState()?.permissions;
  const filteredContent = _contentMgr?.GetGlobalState()?.filteredContent;

  const [currentSlide, setCurrentSlide] = useState(activeSlide);
  const [lowResBackupPhoto, setLowResBackupPhoto] = useState(activeSlide);
  const { width, elementRef } = useSizeElement();
  const {
    handlePrev,
    handleNext,
    slideProps,
    containerRef,
    hasNext,
    hasPrev
  } = useSliding(width, React.Children.count(children));

  const handleSelect = async (content) => {
    setCurrentSlide(content);
    setLowResBackupPhoto(content.thumbUrl);
    /* await setTimeout(() => {
       _contentMgr.GetContentLargeUrl(content.KeyId, ContentTypeEnum.FullImage).then((res) => {
         var newContent = { ...content };
         if (res) {
           newContent.imgUrlLarge = res;
           setCurrentSlide(newContent);
         }
       });
     }, 250);*/
  };

  const handleClose = () => {
    setCurrentSlide(null);
  };

  const contextValue = {
    onSelectSlide: handleSelect,
    onCloseSlide: handleClose,
    elementRef,
    currentSlide,
  };

  useEffect(() => {
    if (currentSlide != undefined) {
      let newCurrentSlides = filteredContent.filter(x => x.KeyId == currentSlide.KeyId);
      if (newCurrentSlides != undefined && newCurrentSlides.length > 0)
        setCurrentSlide(newCurrentSlides[0]);
    }
  }, [filteredContent]);
  return (
    <SliderContext.Provider value={contextValue} key={`slider_${contextValue?.currentSlide}`}>
      <SliderWrapper>
        <div className={cx('slider', { 'slider--open': currentSlide != null })}>
          <div ref={containerRef} className="slider__container" {...slideProps}>{children}</div>
        </div>
        {hasPrev && <SlideButton onClick={handlePrev} type="prev" />}
        {hasNext && <SlideButton onClick={handleNext} type="next" />}
      </SliderWrapper>
      {currentSlide && <Content authMgr={authMgr} onFullScreen={onFullScreen} lowResBackupPhoto={lowResBackupPhoto} content={currentSlide} onClose={handleClose} onLike={onLike} onDownload={onDownload} onView={onView} onShare={onShare} canShare={authMgr?.canWrite("ContentShare")} />}
    </SliderContext.Provider>
  );
};

export default Slider;
