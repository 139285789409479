import React, { useState, useEffect } from 'react';
import './loading.scss'
import { CircularProgress } from '@mui/material';
import Loading from '../../../../components/Loading';

export default function InPageLoading({ loadingType }) {

  return (
    <div className="InPageLoadingContainer"><Loading size={'5rem'} type="default"></Loading><h5>Loading...</h5></div>
  );
}