import React from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { DatePickerControl, SelectListControl } from '../../../../components/controls';
import { TreePicker } from 'rsuite';

//TODO_R: Only search is implemented for year currently
export default function SchoolYearFilter({ orgs, schoolYearMgr, onFilterChange }) {
    const handleSearchChange = (value) => {
        if (onFilterChange)
            onFilterChange("search", value);
    }

    const handleFilterChange = (value, field) => {
        if (onFilterChange) {
            onFilterChange(field, value);
        }
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        schoolYearMgr.filterData({ search: null, ClientId: null });
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">School Year Management</div>
                    <div className="filterControl">
                        <InputField
                            id='schoolYear'
                            title='School Year'
                            value={schoolYearMgr?.Filter?.search ?? ''}
                            fieldName='search'
                            disableError={true}
                            placeholder='Title'
                            onChange={handleSearchChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">

                        {orgs && <InputField
                            title="Organization"
                            fieldName="ClientId"
                            value={schoolYearMgr?.Filter?.ClientId}
                            disableError={true}
                        >
                            <TreePicker
                                height={320}
                                width={400}
                                data={orgs ?? []}
                                valueKey={'OrgId'}
                                labelKey={'Name'}
                                onClean={() => handleFilterChange("", 'ClientId')}
                                onSelect={(a, val) => handleFilterChange(val, 'ClientId')}
                                placement="autoVerticalStart"
                            />
                        </InputField>}
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='fromDate'
                            title='Date Range'
                            subTitle='Start of date range'
                            value={schoolYearMgr?.Filter?.fromDate ?? ''}
                            fieldName='fromDate'
                            disableError={true}
                            onChange={handleFilterChange}>
                            <DatePickerControl
                                includeTime={false}
                                maxDate={schoolYearMgr?.Filter?.toDate}
                            />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='toDate'
                            subTitle='End of date range'
                            value={schoolYearMgr?.Filter?.toDate ?? ''}
                            fieldName='toDate'
                            onChange={handleFilterChange}>
                            <DatePickerControl
                                includeTime={false}
                                minDate={schoolYearMgr?.Filter?.fromDate}
                            />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <ButtonControl
                            onClick={handleClearFilter}>
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}