import { useEffect, useState } from 'react';
import _ from 'lodash';
import ButtonControl from '../../../../components/controls/ButtonControl';
import certImg from '../../certificationimg.png'
import certImg2 from '../../certificationimg2.png'
import rubricIcon from '../../../../content/icons/grading.svg'
import checkmark from '../../../../content/icons/Vector.svg'
import failIcon from '../../../../content/icons/Close.svg'
import videoIcon from '../../../../content/icons/Video.svg'
import documentIcon from '../../../../content/icons/document.png'
import announcementIcon from '../../../../content/icons/Announcement.svg'
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { DialogControl } from '../../../../components/controls/DialogControl';
import { useMemo } from 'react';
import moment from 'moment';
import SystemTypes from '../../../../SystemTypes';
import { useAppUserManager } from '../../../../hooks/useManagers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../../../../components/Loading';
import ReactQuill from 'react-quill';
import BitmovinPlayer from '../../../contentLibrary/app-components/Content/Player/BitmovinPlayer';

const CertificationAdminDetails = ({ certificationAdminMgr,
    observationConfig,
    data,
    updateUserPermissions,
    createNewCertificate,
    saveCertification,
    certificationHistory,
    handleOpenPrint,
    setCertificationHistory }) => {
    const appUserMgr = useAppUserManager();
    const [certificationData, setCertificationData] = useState(data)
    const [currentPhase, setCurrentPhase] = useState(1)
    const [questions, setQuestions] = useState(null)
    const [currentQuestion, setCurrentQuestion] = useState(null)
    const [printing, setPrinting] = useState(false)
    const [answerVideoUrl, setAnswerVideoUrl] = useState(null)
    const [openAnswerFileDialog, setOpenAnswerFileDialog] = useState(false)
    const [watchedQuestionVideo, setWatchedQuestionVideo] = useState(false)
    const [saving, setSaving] = useState(false)


    const grabCurrentOrNextQuestion = async () => {
        const questionNumber = _.findIndex(certificationData.Questions, x => currentQuestion ? currentQuestion.Id !== x.Id && x.SelectedAnswer === null : x.SelectedAnswer === null)

        if (questionNumber !== -1) {
            const question = _.find(certificationData.Questions, x => currentQuestion !== null ? currentQuestion.Id !== x.Id && x.SelectedAnswer === null : x.SelectedAnswer === null)
            const moveToBottomAnswerIndex = _.findIndex(question.CertificationQuestion.Answers, x => x.MoveToBottom === true)
            if (moveToBottomAnswerIndex !== -1) {
                const answer = question.CertificationQuestion.Answers.splice(moveToBottomAnswerIndex, 1)[0]
                question.CertificationQuestion.Answers.push(answer)
            }
            const questionObj = {
                QuestionText: question.CertificationQuestion.QuestionText,
                Answers: question.CertificationQuestion.Answers,
                CertificationId: question.CertificationQuestion.CertificationId,
                SchoolYearIdentifier: question.CertificationQuestion.SchoolYearIdentifier,
                SelectedAnswer: null,
                File: question.CertificationQuestion.File,
                Id: question.Id,
                FileUrl: question.CertificationQuestion.File?.FileUrl,
                hasFile: question.CertificationQuestion.File && true,
                number: questionNumber + 1,
            }
            setCurrentQuestion(questionObj)
        }
    }


    const updateQuestionAnswer = (value) => {
        const updateCurrentQuestion = { ...currentQuestion }
        updateCurrentQuestion.SelectedAnswer = value
        setCurrentQuestion(updateCurrentQuestion)
    }


    const saveQuestion = async (score) => {
        setSaving(true)
        const updateQuestions = [...questions]
        const questionToUpdate = _.find(updateQuestions, x => x.Id === currentQuestion.Id)
        const questionIndex = _.findIndex(updateQuestions, x => x.Id === currentQuestion.Id)
        questionToUpdate.SelectedAnswer = currentQuestion.SelectedAnswer
        questionToUpdate.QuestionText = currentQuestion.QuestionText
        questionToUpdate.SelectedAnswerText = _.find(currentQuestion.Answers, x => x.Id === currentQuestion.SelectedAnswer)?.AnswerText
        questionToUpdate.IsCorrect = currentQuestion.SelectedAnswer === _.find(currentQuestion.Answers, x => x.IsCorrect)?.Id


        updateQuestions.splice(questionIndex, 1, questionToUpdate)
        setQuestions(updateQuestions)

        let result = await certificationAdminMgr.saveCertificationQuestion(questionToUpdate).then(r => {
            setSaving(false)
            return true
        })
        return result
    }

    const updateCertification = async (action) => {
        setSaving(true)
        const spreadData = { ...certificationData }
        if (action === 'StartQuestions') {
            spreadData.Status = 4
        } else if (action === 'FinishQuestions') {
            if (_.countBy(questions, "IsCorrect")?.true >= observationConfig?.NumberToPassAdminCert) {
                spreadData.Status = 5
                spreadData.DistrictId = spreadData.DistrictIds?.first();
                spreadData.SchoolId = spreadData.SchoolIds?.first();
                spreadData.UserPosition = spreadData.User.UserPositionType;
                spreadData.CompletedDate = moment().utc().toISOString()
                if (!(appUserMgr.canView('CertificationTraining') || appUserMgr.canExecute('CertificationTraining'))) {
                    updateUserPermissions();
                }
            } else {
                let failedCertResult = await certificationAdminMgr.handleCertificationFailed(6, spreadData.Id)
                const copiedCert = { ...failedCertResult.Items.first() }
                copiedCert.RetakeCount = spreadData.RetakeCount
                setCertificationData(copiedCert)
                setSaving(false)
                return failedCertResult
            }
        }

        setCertificationData(spreadData)
        let result = await saveCertification(spreadData).then(() => {
            setSaving(false)
        })
        return result
    }

    const checkIsVideo = (fileUrl) => {
        return (fileUrl?.toLowerCase()?.includes('.mpeg') ||
            fileUrl?.toLowerCase()?.includes('.mpg') ||
            fileUrl?.toLowerCase()?.includes('.mpga') ||
            fileUrl?.toLowerCase()?.includes('.avi') ||
            fileUrl?.toLowerCase()?.includes('.mp4') ||
            fileUrl?.toLowerCase()?.includes("streaming.media.azure.net") ||
            fileUrl?.toLowerCase()?.includes('bitmovin-media-files')
        )
    }

    function handleNextPhase() {
        setCurrentPhase(currentPhase + 1);
    }


    const userCantRetake = () => {
        const differenceInDate = new Date() - new Date(moment.utc(certificationData.CompletedDate).local());
        const differenceInDays = differenceInDate / (1000 * 60 * 60 * 24)
        if (differenceInDays < observationConfig?.DaysUntillRetryAdminCert || certificationData.RetakeCount + 1 >= observationConfig?.CertificationRetryAttemptsAdmin) {
            return true
        }
    }

    const handleRetake = async () => {
        setSaving(true)
        const throwAwayCert = { ...certificationData }
        throwAwayCert.IsActive = false
        await saveCertification(throwAwayCert).then(() => {
            certificationAdminMgr.getSingleCertificationWithUserId(certificationData.UserId).then((newCertResult) => {
                const newCertObject = newCertResult.Items.first()
                newCertObject.Status = 4
                createNewCertificate(newCertObject).then(r => {
                    setSaving(false)
                    setCertificationData(r)
                    handleNextPhase()
                })
            })
        })
    }




    const renderPhaseOne = () => {
        return <div className='certification'>
            <h1>ONLINE CERTIFICATION</h1>
            <div className="certification-content">
                <div className="certification-para-wrapper">
                    <p className="certification-para">Welcome to Evaluator Certification. To become certified to perform Administrator Evaluations, please answer the multiple-choice questions regarding General Knowledge and Case Studies. </p>
                    <p className="certification-para">
                        You must view all documents and watch all videos prior to selecting your answer choice.
                    </p>
                    <p className="certification-para">
                        After you click BEGIN, do NOT use your browser's back arrow button.
                    </p>
                </div>
                <figure className='certification-image-wrapper'><img src={certImg} alt="certImg" className='certification-image' /></figure>
            </div>
            <div className="certification-double-column">
                <div className="double-column-left">
                    <h3>{certificationData?.SchoolYearIdentifier - 1}-{certificationData?.SchoolYearIdentifier} School Year</h3>
                    <div className="row-header">
                        <div className="col">Step</div>
                        <div className="col">Description</div>
                        <div className="col">Status</div>
                        <div className="col">Action</div>
                    </div>
                    <div className="row">
                        <div className="col">1</div>
                        <div className="col">General Knowledge & Case Studies</div>
                        <div className="col">{(certificationData.Status === 0) ? "No Attempt" : (certificationData.Status === 4) ? "In Progress" : (certificationData.Status === 5 || certificationData.Status === 9) ? "Passed" : certificationData.RetakeCount + 1 >= observationConfig?.CertificationRetryAttemptsAdmin ? "Max Attempt" : "Failed"}</div>
                        <div className="col">{((certificationData.Status === 4 || certificationData.Status === 0)) ? <ButtonControl
                            type={'okay'}
                            loading={saving}
                            onClick={async () => {
                                if (_.isNil(certificationData.Questions)) {
                                    setSaving(true)
                                    await createNewCertificate().then((r) => {
                                        handleNextPhase()
                                        setCertificationData(r)
                                    })
                                    setSaving(false)
                                } else if (certificationData?.Status === 4) {
                                    setCurrentPhase(3)
                                    grabCurrentOrNextQuestion()
                                    setQuestions(certificationData.Questions)
                                }
                            }}>{certificationData?.Status === 0 ? "Begin" : "Continue"}</ButtonControl>
                            :
                            (certificationData.Status === 6) && <ButtonControl type={'okay'}
                                disabled={(userCantRetake() || !observationConfig)}
                                loading={saving}
                                onClick={async () => {
                                    await handleRetake()
                                }}>{certificationData.RetakeCount + 1 >= observationConfig?.CertificationRetryAttemptsAdmin ? "Locked" : "Retake"}</ButtonControl>}</div>

                    </div>
                </div>
                <div className="double-column-right">
                    <h3>History</h3>
                    <div className="row-header">
                        <div className="col">School Year</div>
                        <div className="col">Print Certificate</div>
                    </div>
                    {_.map(certificationHistory, x => {
                        return <div className="row">
                            <div className='col'>{x.SchoolYearIdentifier}</div>
                            {
                                printing === x.Id ? <div className="loading-wrapper"><Loading type='default' size='1rem' /></div> :
                                    printing ?
                                        <FontAwesomeIcon className={'fa-icon fa-icon-disabled'} icon={faPrint} />
                                        :
                                        <FontAwesomeIcon className={'fa-icon'} onClick={() => {
                                            setPrinting(x.Id)
                                            handleOpenPrint(x, x).then(r => {
                                                setPrinting(null)
                                            })
                                        }} icon={faPrint} />
                            }
                        </div>
                    })}

                </div>
            </div>
        </div>
    }
    const renderPhaseTwo = (passed) => {
        if (certificationData.Status === 5) {
            passed = true
        } else if (certificationData.Status === 6) {
            passed = false
        }

        const retakeDate = new Date(Date.now(certificationData.CompletedDate) + (observationConfig?.DaysUntillRetryAdminCert * 1000 * 60 * 60 * 24))
        let hours = retakeDate.getHours();
        const minutes = retakeDate.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours || 12;

        const minutesStr = minutes < 10 ? '0' + minutes : minutes;

        return <div className='certification'>
            <div className='phase-two'>
                <h1>ONLINE CERTIFICATION</h1>
                <div className="certification-subheader-wrapper">
                    <h2> General Knowledge and Case Studies</h2>
                </div>
                <div className="certification-content">
                    <figure className='certification-image-wrapper'>
                        <img className='certification-image' src={certImg2} alt="certImg2" />
                    </figure>
                    <div className="certification-para-wrapper">
                        {passed !== undefined ?
                            passed ? <h3><img src={checkmark} alt="" /> Exam Result: <span>Passed</span></h3> : <h3><img src={failIcon} alt="" /> Exam Result: <span>Did not meet the requirements</span></h3>
                            :
                            <h3> Multiple Choice Questions</h3>
                        }
                        <p className="certification-para">
                            {passed !== undefined ? passed ? "Congratulations! You have passed the exam." :
                                "The number of correct questions was not answered to pass this exam. "
                                :
                                "Please answer the following multiple-choice questions. You must view all documents and watch all videos prior to selecting your answer choice. REMEMBER, do NOT use your browser's back arrow button."}
                        </p>
                        {passed === false && <div className="attempt-text-wrapper">
                            <img src={announcementIcon} alt="" />
                            <p>{certificationData.RetakeCount + 1 >= observationConfig?.CertificationRetryAttemptsAdmin ?
                                "You have reached the maximum number of attempts for this session."
                                :
                                `You may retake the exam after ${retakeDate.toLocaleDateString('en-us')} at ${hours}:${minutesStr} ${ampm}.`}</p>
                        </div>}
                        <div className="btn-wrapper">
                            {passed !== undefined ?
                                passed ? <ButtonControl type={'okay'} onClick={() => {
                                    setCertificationHistory([...certificationHistory, certificationData])
                                    setCurrentPhase(1)
                                }}>FINISH</ButtonControl>
                                    :
                                    <ButtonControl type={'cancel'} onClick={() => {
                                        setCurrentPhase(1)
                                    }}>RETURN</ButtonControl>
                                :
                                <ButtonControl type={'okay'} onClick={() => {
                                    grabCurrentOrNextQuestion()
                                    setQuestions(certificationData.Questions)
                                    handleNextPhase()
                                }}>START</ButtonControl>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const memoizedBitmovinVideo = () => {
        return <div className='video-player'>
                <div id='player-wrapper'>
                    <BitmovinPlayer content={currentQuestion?.File} 
                        onEnded={() => {
                            setWatchedQuestionVideo(true)
                        }}
                    />
                </div>
            </div>
    }    

    const renderPhaseThree = () => {
        return <div className='certification'>
            <div className="certification-header-wrapper">
                <h1>ONLINE CERTIFICATION</h1>
                <span className='current-total-question'>{`${currentQuestion.number - 1} questions completed / ${questions.length} total`}</span>
            </div>
            {/* Make dynamic */}
            <div className={"certification-question-wrapper" + (currentQuestion.hasFile ? " two-columns" : "")}>
                <div>
                    <h2>Question #{currentQuestion.number}</h2>
                    <p dangerouslySetInnerHTML={{ __html: currentQuestion.QuestionText.replace(/\n/g, '<br />') }} />
                </div>
                {
                    currentQuestion.hasFile && <div className="file-wrapper">
                        {checkIsVideo(currentQuestion.FileUrl) ? memoizedBitmovinVideo() : <img className='file-img' src={documentIcon} />}
                        <div className="view-file" onClick={() => { setWatchedQuestionVideo(true) }} >{checkIsVideo(currentQuestion.FileUrl) ? "Watch Video" : <a href={currentQuestion.FileUrl}>View Document</a>}</div>
                    </div>
                }
            </div>
            <div className="certification-answer-wrapper">
                <h3>Answers:</h3>
                <div className="answer-options-wrapper">
                    <div className="answer-options">
                        <div className="option">Select</div>
                        <div className="option">Answer</div>
                        <div className="option">Description</div>
                        {_.some(currentQuestion.Answers, x => x.FileId) && <div className="option-video">Video/Document</div>}
                    </div>
                    {
                        _.map(currentQuestion.Answers, (x, i) => <div className="answer-options" key={i}>
                            <div className="option">
                                <input disabled={!watchedQuestionVideo && currentQuestion.FileUrl} onChange={() => updateQuestionAnswer(x.Id)} checked={currentQuestion.SelectedAnswer === x.Id} type="radio" />
                            </div>
                            <div className="option">
                                {String.fromCharCode(65 + i)}
                            </div>
                            <div className="option" >
                                <ReactQuill
                                    readOnly={true}
                                    theme="bubble"
                                    value={x.AnswerText}
                                    className='rq'
                                />
                            </div>
                            {x.FileId && <div className="option-video">{checkIsVideo(x.File.FileUrl) ? <img src={videoIcon} onClick={() => {
                                setAnswerVideoUrl([{
                                    src: x?.File?.FileUrl,
                                    type: "application/vnd.ms-sstr+xml"
                                }])
                                setOpenAnswerFileDialog(`Answer Choice ${String.fromCharCode(65 + i)}`)
                            }} alt="" /> : <a href={x.File.FileUrl} download={"file"}><img className='document-icon' src={documentIcon} alt="" /></a>}</div>}
                        </div>)
                    }
                </div>
            </div>
            <div className="btn-wrapper"><ButtonControl
                loading={saving}
                disabled={!currentQuestion.SelectedAnswer}
                type="okay"
                onClick={async () => {
                    grabCurrentOrNextQuestion()
                    setWatchedQuestionVideo(false)
                    let result = await saveQuestion()
                    if (_.countBy(questions, (question) => question.SelectedAnswer !== null).true === questions.length && result) {
                        await updateCertification('FinishQuestions')
                        handleNextPhase()
                    }
                }}>{_.countBy(questions, (question) => question.SelectedAnswer !== null).true === questions.length - 1 ? "FINISH" : "NEXT"}</ButtonControl></div>
        </div >
    }

    return <>
        {currentPhase === 1 && renderPhaseOne()}
        {currentPhase === 2 && renderPhaseTwo()}
        {currentPhase === 3 && renderPhaseThree()}
        {currentPhase === 4 && renderPhaseTwo(certificationData)}
        {currentPhase === 5 && renderPhaseOne()}
    </>


}

export default CertificationAdminDetails;


