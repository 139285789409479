const PermissionValidateObject = () => {
    return {
        Permission: {
            fieldName: 'Permission',
            messageTitle: 'permission',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'PERMISSION',
            message: 'Permission cannot contain spaces or special charictors.',
            regEx: /^[a-zA-Z0-9_]*$/
        },
        DisplayName: {
            fieldName: 'DisplayName',
            messageTitle: 'display name',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'PERMISSION'
        },
    }
}

export default PermissionValidateObject;