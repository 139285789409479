import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import ImportExportDetails from '../components/importExport/details';
import ImportExportFilter from '../components/importExport/filter';
import ImportExportTable from '../components/importExport/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useImportExportManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';

const ImportExportMgmt = () => {
    const importExportMgr = useImportExportManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedImportExport, setSelectedImportExport] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);


    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (importExportMgr && !params?.importExportId) {
            setLoading(true);
            importExportMgr.search().then(r => {
                setManageMode('list');
                setLoading(false);
            });
        }
        else if (importExportMgr && params?.importExportId) {
            importExportMgr.get(params?.importExportId).then(ie => {
                if (ie.Success) {
                    setManageMode('view');
                    setSelectedImportExport(ie.Items.first());
                }
                setLoading(false);
            });
        }
    }, [importExportMgr, params?.importExportId]);


    const handleOnFilterChange = async (field, value) => {

        const ImportExportFilter = importExportMgr.Filter;
        const updatedImportExportFilter = { ...ImportExportFilter };
        updatedImportExportFilter[field] = value !== '' ? value : null;
        await importExportMgr.filterData(updatedImportExportFilter);
    }

    const handleOnDownload = async (rowItem) => {
        const result = await importExportMgr.downloadFile(rowItem);
        return result;
    }

    const handleOnReload = async (rowItem) => {
        if (rowItem.IsExport) {
            let result = await importExportMgr.RedoExportData(rowItem.Id);
            if (!result.Success) {
                setErrors(["Failed to reload, if this issue continues please contact NIET support."]);
            }
            else {
                result = await importExportMgr.downloadFile(result.Items.first());
            }

            return result;
        }
        else {
            setErrors(["Import reload not yet supported."])
            return { Success: false }
        }
    }

    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        importExportMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.ImportExport}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnView = (item) => {
        setSelectedImportExport(item);
        setManageMode('view');
        navigate(`/admin/ImportExport/${item.Id}`);
    }

    const handleOnCancel = () => {
        navigate('/admin/ImportExport');
        if (importExportMgr.FilteredData) {
            setManageMode('list');
        }
        else {
            setManageMode(null);
        }
        setLoadingError(null);
        setErrors(null);
    }

    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete the selected Import/Export Record?`} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <MainLayout errors={errors} validationErrors={validationErrors} >
            {manageMode === 'view' &&
                <div className='screen-header'>
                    <h3>Import/Export Management</h3>
                    <h5>{selectedImportExport?.ImportExportTypeName}</h5>
                </div>
            }
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <ImportExportFilter importExportMgr={importExportMgr} onFilterChange={handleOnFilterChange} />
                            <ImportExportTable filteredItems={importExportMgr?.FilteredData ?? []} onReload={handleOnReload} onView={handleOnView} onDownload={handleOnDownload} onDelete={(item) => { setItemToDelete(item); }} />
                        </div>
                    </div>
                </>}
                {manageMode === 'view' && <ImportExportDetails item={selectedImportExport} onCancel={handleOnCancel} onDownload={handleOnDownload} />}
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default ImportExportMgmt;