import React, { useState } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { CertificationDataProvider } from './components/CertificationDataProvider';
import CertificationTeachers from './CertificationTeachers';
import CertificationAdmins from './CertificationAdmins';
import { useRef } from 'react';

const CertificationScreen = () => {
    const location = useLocation();
    return (<>
        <CertificationDataProvider>
            <style type='text/css'>
                {
                    `
                    @media print {
                        @page{
                            size: landscape;
                            margin-right: .25in;
                        }
                    }
                    `
                }
            </style>
            {location?.pathname?.toLowerCase()?.indexOf('teacher') > -1 && <CertificationTeachers />}
            {location?.pathname?.toLowerCase()?.indexOf('administrator') > -1 && <CertificationAdmins />}
        </CertificationDataProvider>
    </>
    );
}

export default CertificationScreen;