import ContentManager from '../../../managers/ContentManager';
import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import Slider from '../../Slider'
import { useContentManager } from '../../../../../hooks/useManagers';
import { ca } from 'date-fns/locale';

export default function PrimaryDisplay({ authMgr, updateContent, parentTags, onLike, onDownload, onView, onShare, onFullScreen }) {
    const contentMgr = useContentManager();
    const filteredContent = contentMgr?.GetGlobalState()?.filteredContent;
    const [categoryContent, setCategoryContent] = useState([]);
    const likeName = "My Liked Content";
    const recommendedName = "My Recommended Content";
    const recommendedAndLikedName = "My Recommended & Liked Content";



    const loadCategoryContent = () => {
        let newCategoryContent = [];
        if (filteredContent != null) {
            let contentIds = [];
            parentTags.forEach((pt) => {
                let newContents = filteredContent.filter((x) => {
                    let tagFound = x.TagIds.includes(pt.TagId) || pt.children?.some(child => x.TagIds.includes(child.TagId));
                    if (tagFound && contentIds.indexOf(x.ContentId) == -1)
                        contentIds.push(x.ContentId);
                    return tagFound;
                });

                newCategoryContent.push({
                    Sequence: pt.Sequence,
                    categoryName: pt.Name,
                    contents: newContents
                });
            });

            const recommendedForUser = filteredContent.filter(x => x.RecommendedForUser).length > 0;
            const hasUserRating = filteredContent.filter(x => x.HasUserRating).length > 0;

            let name = hasUserRating && recommendedForUser ? recommendedAndLikedName : (recommendedForUser ? recommendedName : likeName);

            _.each(filteredContent.filter(x => x.HasUserRating || x.RecommendedForUser), content => {
                content.TagIds.push(name);
                content.TagList.push({
                    Name: name,
                    CreatedOn: new Date(),
                    ParentTagId: null,
                    ParentTagName: null,
                    TagId: name,
                    Sequence: null,
                    IsEnabled: true,
                    IsHidden: false,
                    label: name,
                    value: name,
                    visible: true
                });
                content.Tags = content.TagIds.toString();
            });

            var myLikedContent = filteredContent.filter(x => x.Tags.includes(name));
            if (myLikedContent?.length > 0) {
                newCategoryContent.push({
                    Sequence: -1,
                    categoryName: name,
                    contents: myLikedContent
                });
            }

            /*Disabled General content to match ACED request
            let otherContent = filteredContent.filter((x) => { return contentIds.indexOf(x.ContentId) == -1 });
            if (otherContent.length > 0) {
                otherContent.forEach((oc) => {
                    oc.TagIds = [...oc.TagIds, 'General'];
                    oc.TagList = [...oc.TagList, {
                        IsEnabled: true,
                        Name: "General",
                        ParentTagId: null,
                        ParentTagName: null,
                        TagId: 'General',
                        Sequence: 10000
                    }];
                    oc.Tags = oc.TagIds.toString();
                });

                newCategoryContent.push({
                    Sequence: 10000,
                    categoryName: "General",
                    contents: otherContent
                });
            }*/
        }

        newCategoryContent = _.sortBy(newCategoryContent, item => {
            if (item.Sequence < 0) {
                return "AAA";
            }
            if (item.Sequence > 999) {
                return "ZZZ";
            }
            else {
                return item.categoryName;
            }
        });

        return newCategoryContent;
    }

    useEffect(() => {
        setCategoryContent(loadCategoryContent());
    }, [filteredContent, updateContent]);



    const containerRef = React.useRef();
    return (
        <>
            <div className="contentDisplay" >
                {categoryContent.map((cc, i) => (
                    <Slider authMgr={authMgr} key={`primary_display_${i}`} onFullScreen={onFullScreen} contents={cc.contents} categoryName={cc.categoryName} onLike={onLike} onDownload={onDownload} onView={onView} onShare={onShare} containerRef={containerRef} />
                ))}
            </div>
        </>
    );
}
