import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'

import { FormControlLabel, Grid, Checkbox, Switch, Button, TextField } from '@mui/material';

import { InputField } from '../../../../../components/controls/InputField';
import ButtonControl from '../../../../../components/controls/ButtonControl';
import { ContentTypeEnum } from '../../../services/ContentService';
//https://rsuitejs.com/components/check-tree-picker/
import { CheckTreePicker } from 'rsuite';
import '../../../../../styles/rsuite_override.css';
import _ from 'lodash';
import $ from 'jquery';
import { useAppUserManager, useContentManager, useTagManager } from '../../../../../hooks/useManagers';

export default function ShareFilter({ defaultContentTypeId, defaultTags, avaliableShareContentFilters, shareContentFilter, onFilterChange, onIsSharedWithUser }) {
    const authMgr = useAppUserManager();
    const { permissions } = authMgr?.GetGlobalState() ?? {};
    const [search, setSearch] = useState(shareContentFilter?.search);
    const [tagIds, setTagIds] = useState(shareContentFilter?.tagIds);
    const [contentTypesChecked, setContentTypeChecked] = useState({ learningModules: false, videos: false, resources: false });
    const [tags, setTags] = useState([]);
    const tagMgr = useTagManager();

    const handleSearchChange = (value) => {
        setSearch(value);
        if (onFilterChange)
            onFilterChange("search", value);
    }

    const handleListViewChange = (e) => {
        if (onIsSharedWithUser) {
            onIsSharedWithUser(e.target.checked);
        }
    }

    const handleCheckChange = (event, name) => {
        const updatedContentTypesChecked = { ...contentTypesChecked, [name]: event.target.checked };
        setContentTypeChecked(updatedContentTypesChecked);
        if (onFilterChange)
            onFilterChange("contentTypeIds", updatedContentTypesChecked);
    };


    const onTreeClean = (event) => {
        setTagIds([]);
        if (onFilterChange)
            onFilterChange("tagIds", []);
    }

    const isGradeLevel = (itemChildren) => {
        const arr = _.find(itemChildren, x => x.label.includes("Grade Levels"))?.children
        if (arr) {
            arr.sort((x) => x._id === 'K' ? -1 : 0);
            arr.sort((x) => x._id === 'Pre-K' ? -1 : 0);
        }
    }
    useEffect(() => {
        if (tagMgr) {
            tagMgr.FullTagTree().then(r => {
                if (r?.Success) {
                    _.forEach(isGradeLevel(r.Items))
                    setTags(r.Items);
                }
            });
        }
    }, [tagMgr])

    const onTreeSelect = (activeNode, vals) => {
        let tagTree = avaliableShareContentFilters.tags;
        let newTagIds = [];
        vals.forEach(val => {
            newTagIds.push(val);
            const tag = tagTree.find(t => t.TagId == val);
            if (tag && tag.ParentTagId == null && tag.children) {
                tag.children.forEach(c => {
                    newTagIds.push(c.TagId);
                });
            }
        });
        setTagIds(newTagIds);
        if (onFilterChange)
            onFilterChange("tagIds", newTagIds);

    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        $(".rs-picker-toggle-clean").trigger("click");
        setTagIds([]);
        setSearch(null);
        //searchInput.current.value = "";
        if (defaultContentTypeId == undefined) {
            setContentTypeChecked({ resources: false, videos: false, learningModules: false });
        }

        if (onFilterChange) {
            onFilterChange("tagIds", null);
            onFilterChange("search", null);
            onFilterChange("contentTypes", { resources: false, videos: false, learningModules: false });
        }
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Shared Content</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search Content'
                            field={{ FieldName: 'contentSearch' }}
                            disableError={true}
                            placeholder='Search your shared content'
                            value={search ?? ''}
                            onChange={handleSearchChange}>
                        </InputField>
                    </div>
                    {/*
                    <div className="filterControl categoryFilterControl">
                        <div className="filterSubTitle">
                            Content Types
                        </div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="contentTypes"
                                    id="learningModules"
                                    inputRef={learningModuleInput}
                                    checked={contentTypesChecked.learningModules}
                                    disabled={defaultContentTypeId == ContentTypeEnum.LearningModule}
                                    onChange={(e) => handleCheckChange(e, 'learningModules')}
                                />
                            } label="Learning Modules">
                        </FormControlLabel>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="contentTypes"
                                    id="resources"
                                    inputRef={resourceInput}
                                    checked={contentTypesChecked.resources}
                                    disabled={defaultContentTypeId == ContentTypeEnum.Resource}
                                    onChange={(e) => handleCheckChange(e, 'resources')}
                                />
                            } label="Resources">
                        </FormControlLabel>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="contentTypes"
                                    id="videos"
                                    inputRef={videosInput}
                                    checked={contentTypesChecked.videos}
                                    disabled={defaultContentTypeId == ContentTypeEnum.Video}
                                    onChange={(e) => handleCheckChange(e, 'videos')}
                                />
                            } label="Videos">
                        </FormControlLabel>
                        </div>*/}
                    <div className="filterControl">
                        {/*defaultExpandAll*/}
                        <InputField
                            id='tags'
                            title='Categories'
                            placeholder={'Select one or more...'}
                            field={{ FieldName: 'searchTags' }}
                            className={'field-input__checkTreePicker'}
                            disableError={true}>
                            <CheckTreePicker
                                height={320}
                                data={tags ?? []}
                                defaultValue={defaultTags}
                                cascade={false}
                                onClean={onTreeClean}
                                onSelect={onTreeSelect}
                                placement="autoHorizontalStart"
                                className="searchWidth"
                            />
                        </InputField>
                    </div>
                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                    {authMgr?.AccessCheck([permissions?.ViewSharedContent]) && <>
                        <div className="filterTitle pt-5">Share Recipient</div>
                        <div className="filterControl layoutSwitch">
                            <div className="contentIcons mr-2">
                                <FontAwesomeIcon icon={faUser} className="switchIcon upperCase" />
                                Others
                            </div>
                            <FormControlLabel
                                control={<Switch onChange={handleListViewChange} />}
                                className="MuiSwitch-root switch-medium"
                            />
                            <div className="contentIcons ml-3">
                                <FontAwesomeIcon icon={faUsers} className="switchIcon upperCase" />
                                Me
                            </div>
                        </div></>}
                </div>
            </div >
        </>
    );
}