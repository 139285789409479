import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _, { has } from 'lodash';

class RoleManager extends ItemManager {
  constructor(globalItemState, globalAuthState, globalAppUserState) {


    super(ItemServiceFactory.ItemServiceEnum.Role, globalAuthState, globalAppUserState, globalItemState,
      (filter, item) => {
        var hasSearch = item.Role?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
        var hasSystemAdmin = (item.IsSystemAdmin == filter?.IsSystemAdmin)
          || (filter?.IsSystemAdmin == 1 && (item.Role == "Basic" || item.Role == "CertifiedTeacher" || item.Role == "CertifiedSchoolAdmin" || item.Role == "Anonymous"))
          || (filter?.IsSystemAdmin === undefined || filter?.IsSystemAdmin === null || filter?.IsSystemAdmin === 'UNK');
        return hasSearch && hasSystemAdmin;
      });

    this._errorUtil = undefined;

    this._permissionSettingSvc = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.PermissionSetting, globalAuthState);
  }

  async GetPermissionSettings(filter, params) {
    return await this._permissionSettingSvc.list(this.buildJSONObjFilter(filter, params));
  }

  async DeletePermSetting(id) {
    const result = await this._permissionSettingSvc.hardDelete(id);
    if (result?.first()) {
      return result.first();
    }
    else {
      return { Success: false }
    }
  }

  async DeleteAllPermSettings() {
    const allSettings = [];
    this._permissionSettingSvc.list().then((currPermSettings) => {
      currPermSettings?.first()?.Items.forEach((oldPermissionSettings) => {
        allSettings.push(this._permissionSettingSvc.hardDelete(oldPermissionSettings.Id));
      })
    });
    Promise.all(allSettings);
  }

  async SavePermissionSettings(roleItem, newPermSettings) {
    const allSettings = [];

    if (newPermSettings === null) {
      return;
    }

    for (const permissionSetting of newPermSettings) {
      const retItem = {
        Permission: permissionSetting.Permission,
        Id: permissionSetting.Id === permissionSetting.Permission ? undefined : permissionSetting.Id,
        RoleId: roleItem.RoleId,
        UserIdOverride: null,
        CanView: permissionSetting.CanView,
        CanWrite: permissionSetting.CanWrite,
        CanDelete: permissionSetting.CanDelete,
        CanHardDelete: permissionSetting.CanHardDelete,
        CanExecute: permissionSetting.CanExecute,
      }

      allSettings.push(this._permissionSettingSvc.save(retItem));
    }

    const prmsResult = await Promise.all(allSettings)
    return prmsResult;
  }

  get DefaultItem() {
    return {
      IsSystemAdmin: false,
    };
  }
}

export default RoleManager;