import React, { useState, useEffect } from 'react';
import { useAppUserManager, useOrgManager, usePayoutManager, useSchoolManager } from '../../../hooks/useManagers';
import { SelectPicker } from 'rsuite';
import MainLayout from '../../../components/layout/MainLayout';
import ButtonControl from '../../../components/controls/ButtonControl';
import PayoutRecipients from '../components/payout/PayoutRecipients';
import PayoutAdminRecipients from '../components/payout/PayoutAdminRecipients';
import PayoutSettings from '../components/payout/PayoutSettings';
import PayoutAdminSettings from '../components/payout/PayoutAdminSettings';
import PayoutFilter from '../components/payout/PayoutFilter';
import PayoutTable from '../components/payout/PayoutTable';
import samplePayoutTeacher from '../../dev/data/samplePayoutTeacher';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { ReportingService } from '../../../services/ReportingService';
import { useHookstate } from '@hookstate/core';
import globalAuthStateStore from '../../../stores/globalAuthStateStore';
import { DialogControl } from '../../../components/controls/DialogControl';
import { SelectListControl } from '../../../components/controls';

const PayoutMgmt = () => {
  const location = useLocation();
  const params = useParams();
  const schoolMgr = useSchoolManager();
  const navigate = useNavigate();
  const payoutMgr = usePayoutManager();
  const orgMgr = useOrgManager();
  const authStore = useHookstate(globalAuthStateStore);
  var reportingSvc = new ReportingService(authStore);
  const appUserMgr = useAppUserManager();
  const [orgs, setOrgs] = useState(null)
  const [districts, setDistricts] = useState([]);
  const [schools, setSchools] = useState([]);
  const [years, setYears] = useState([]);
  const [validationErrors, setValidationErrors] = useState([])
  const [errors, setErrors] = useState(null)
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedSchool, setSelectedSchool] = useState('');
  const [results, setResults] = useState([]);
  const [showRecipientTable, setShowRecipientTable] = useState(false);
  const [showPayoutSettings, setShowPayoutSettings] = useState(false);
  const [data, setData] = useState();
  const [recipientResults, setRecipientResults] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false)
  const [payoutAdminConfigMetricsData, setPayoutAdminConfigMetricsData] = useState();
  const [payoutAdminConfig, setPayoutAdminConfig] = useState();
  const [payoutTeacherConfig, setPayoutTeacherConfig] = useState();
  const [exportTypeDialog, setExportTypeDialog] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState('PDF');
  const [payoutInfo, setPayoutInfo] = useState(null);
  const [payoutId, setPayoutId] = useState(null);
  const [hideCompassEq, setHideCompassEq] = useState(true);

  useEffect(() => {
    let isAdminInit = location.pathname.includes('/admin/Payout/Admin');
    let isConfigMetrics = location.pathname.includes('Metrics')
    if (isAdminInit) {
      setIsAdmin(isAdminInit);
    } else {
      setIsAdmin(isAdminInit);
    }
    setIsLoading(true)
    if (payoutMgr && appUserMgr && orgMgr) {
      if (params?.payoutConfigId) {
        // Logic for the Configuration page 
        setShowRecipientTable(false);
        setShowPayoutSettings(true);
        if(!isConfigMetrics) {
          loadRecipientData(isAdminInit);
        } else {
          loadPayoutConfig(params.payoutConfigId);
        }
      }
      else if (params?.payoutId) {
        // Logic for the Recipients page
        setShowRecipientTable(true);
        setPayoutId(params.payoutId);
        setShowPayoutSettings(false);
        loadRecipientData(isAdminInit)
      } else {
        // Logic for other pages or default behavior
        setShowRecipientTable(false);
        setShowPayoutSettings(false);
        setPayoutAdminConfigMetricsData(null);
        setPayoutAdminConfig(null);
        setPayoutTeacherConfig(null)

        if (!orgs) {
          orgMgr.loadOrgTreeWithPayouts(isAdminInit ? "district" : 'school').then((items) => {
            setOrgs(items);
          })
        }
        payoutMgr.LoadPayoutSchools(undefined, undefined, undefined, isAdminInit ? 1 : 0).then(r => {
          if (Array.isArray(r)) {
            setData(r);
            setIsLoading(false);
          } else {
            //Set errors here.
          }
        });

      }
    }

  }, [params, location, payoutMgr, appUserMgr]);

  const loadRecipientData = async (isAdmin) => {

    const schoolId = payoutInfo?.SchoolId
    const districtId = payoutInfo?.DistrictId
    let payoutRecipients;
    let errors = false;
    let result;
    const payoutSchoolId = params?.payoutId
    if (isAdmin) {
      //On refresh we wont have payoutInfo so we hit database.
      //On Edit, if its status is 1, its a ghost item.
      //On Next, we should already have payout Info so lets not hit it.
      if (!payoutInfo || payoutInfo.PayoutStatus == "Not Started") {
        result = await payoutMgr.LoadPayoutSchools(payoutSchoolId, schoolId, districtId, 1)
      }
      const isCompleted = result?.first()?.PayoutStatus ? result?.first()?.PayoutStatus == 3 : (payoutInfo?.PayoutStatus == 3 || payoutInfo?.PayoutStatus === "Completed")
      await payoutMgr.GetPayoutAdmins(
        result?.first()?.ClientId ?? payoutInfo.ClientId,
        result?.first()?.DistrictId ?? payoutInfo.DistrictId,
        false,
        isCompleted
      ).then((payoutAdminsResonse) => {
        if (payoutAdminsResonse.Success)
          payoutRecipients = payoutAdminsResonse.Items
        if (payoutAdminsResonse.Items.length === 0) {
          setErrors(["No payout users found, please execute rollover."])
          setIsLoading(false)
          navigate(-1)
          return
        }
        if (!payoutAdminConfig && !payoutAdminConfigMetricsData) {
          payoutMgr.GetAdminPayoutConfiguration(payoutAdminsResonse.Items.first().ClientId,
          payoutAdminsResonse.Items.first().DistrictId, payoutAdminsResonse.Items.first().SchoolId).then((response) => {
              if (response.Items?.length > 0) {
                let metricSet = response.Items[0].PayoutAdminConfigurationMetric;
                setPayoutAdminConfigMetricsData(metricSet);
                setPayoutAdminConfig(response.Items[0]);
              }
            });
        } else if (!payoutAdminsResonse.Success) {
          errors = true
        }
      })

    }
    else {


      if (!payoutInfo || payoutInfo?.PayoutStatus == "Not Started") {
        result = await payoutMgr.LoadPayoutSchools(payoutSchoolId, schoolId)
      }

      const isCompleted = result?.first()?.PayoutStatus ? result?.first()?.PayoutStatus == 3 : (payoutInfo?.PayoutStatus == 3 || payoutInfo?.PayoutStatus === "Completed")
      const payoutTeachers = await payoutMgr.GetPayoutTeachers(
        result?.first()?.ClientId ?? payoutInfo.ClientId,
        result?.first()?.SchoolId ?? payoutInfo.SchoolId,
        false,
        isCompleted);

      if (payoutTeachers.Success)
        payoutRecipients = payoutTeachers.Items

      if (payoutTeachers.Items.length === 0) {
        setErrors(["No payout users found, please execute rollover."])
        setIsLoading(false)
        navigate(-1)
        return
      }

      if (!payoutTeacherConfig) {
        await payoutMgr.GetPayoutConfiguration(payoutTeachers.Items.first().ClientId, payoutTeachers.Items.first().DistrictId, payoutTeachers.Items.first().SchoolId).then((response) => {
          if (response.Items?.length > 0) {
            setPayoutTeacherConfig(response.Items[0])
          }
        });
      } else if (!payoutTeachers.Success) {
        errors = true
      }
    }

    if (!payoutInfo) {
      setPayoutInfo(result.first())
    }

    var school = result?.first()?.School ?? payoutInfo?.School
    setHideCompassEq(!school.UseCompassEquivalent);

    if (!errors) {
      setRecipientResults(payoutRecipients)
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }

  const loadPayoutConfig = async (payoutConfigId) => {

    if(!isAdmin) {
      if (!payoutTeacherConfig) {
        payoutMgr._payoutConfigurationItemMgr.query("x => x.Id == payoutConfigId",
        {
            payoutConfigId: payoutConfigId
        }).then(async config => {
            if (config.Items.length > 0) {
              await payoutMgr.GetPayoutConfiguration(config.Items.first().ClientId, config.Items.first().School.DistrictId, config.Items.first().SchoolId).then((response) => {
                if (response.Items?.length > 0) {
                  setPayoutTeacherConfig(response.Items[0])
                }
              });
              let schoolYear = config.Items.first().SchoolYearIdentifier;
  
              await schoolMgr?.get(config.Items.first().SchoolId).then(r => {
                let schoolData = r.Items.first();
                
                let payoutInfoData = {
                  Client : { Name: schoolData.Organization?.Name },
                  School : { Name: schoolData.Name, Id: schoolData.Id },
                  District : { Name: schoolData.District?.Name, Id: schoolData.District?.Id },
                  PayoutStatus : "Available",
                  SchoolYearIdentifier: schoolYear
                }
                setPayoutInfo(payoutInfoData);
              })
            }
            else {
              errors = true
            }
            setIsLoading(false);
        });
      }
    }
    else {
      if (!payoutAdminConfig) {
        payoutMgr._payoutAdminConfigurationItemMgr.query("x => x.Id == payoutConfigId",
        {
            payoutConfigId: payoutConfigId
        }).then(async config => {
            if (config.Items.length > 0) {
              await payoutMgr.GetAdminPayoutConfiguration(config.Items.first().ClientId, config.Items.first().DistrictId, config.Items.first().SchoolId).then((response) => {
                if (response.Items?.length > 0) {
                  setPayoutAdminConfig(response.Items[0])
                }
              });
              let schoolYear = config.Items.first().SchoolYearIdentifier;
  
              await schoolMgr?.get(config.Items.first().SchoolId).then(r => {
                let schoolData = r.Items.first();
                
                let payoutInfoData = {
                  Client : { Name: schoolData.Organization?.Name },
                  School : { Name: schoolData.Name, Id: schoolData.Id },
                  District : { Name: schoolData.District?.Name, Id: schoolData.District?.Id },
                  PayoutStatus : "Available",
                  SchoolYearIdentifier: schoolYear
                }
                setPayoutInfo(payoutInfoData);
              })
            }
            else {
              errors = true
            }
            setIsLoading(false);
        });
      }
    }   
  }

  const handleCalculatePayout = (fileType) => {
    setLoading(true)
    payoutMgr.CalculatePayout(payoutInfo.School?.Id, payoutInfo.SchoolYearIdentifier, payoutInfo.DistrictId, isAdmin).then((response) => {
      if (response.Success) {
        setResults(response)
        handleOnCalculate(payoutInfo, fileType)
      } else {
        setLoading(false)
        setErrors(['Failed to calculate payout. If this problem persist, please contact customer support.'])
      }
    }
    );
  };

  const handleOnCalculate = async (row, fileType) => {
    setLoading(true)
    let reportParams;
    if (isAdmin) {
      reportParams = {
        "report": "SchoolAdministratorPayout.trdp",
        "parameterValues":
        {
          "CurrentUserId": null,
          "CurrentUserTypeName": "AllData",
          "DateRangeEnd": null,
          "DateRangeStart": null,
          "SchoolYear": parseInt(payoutMgr.AppUserState.selectedSchoolYear),
          "DistrictID": row.DistrictId
        }
      }
    } else {
      reportParams = {
        "report": "TeacherPayout.trdp",
        "parameterValues":
        {
          "CurrentUserId": null,
          "CurrentUserTypeName": "AllData",
          "DateRangeEnd": null,
          "DateRangeStart": null,
          "SchoolYear": parseInt(payoutMgr.AppUserState.selectedSchoolYear),
          "SchoolID": row.SchoolId,
        }
      }
    }

    const result = await reportingSvc.getExport(reportParams, fileType, exportTypeDialog.type);
    //result is undefined then its successful
    setLoading(false)
    if (result) {
      setErrors(['Calculations Succeeded, but unable to generate Payout Export.'])
    }
    setExportTypeDialog(false)

  }

  const handleOnEdit = async (schoolInfo) => {
    setIsLoading(true)
    setPayoutInfo(schoolInfo)
    navigate(isAdmin ? `/admin/Payout/Admin/${schoolInfo.Id}` : `/admin/Payout/Teacher/${schoolInfo.Id}`);
  };
  const handleOnRecipientNext = async (payoutRecipientsToSave) => {
    setIsLoading(true)
    await savePayoutUsers(payoutRecipientsToSave)
    if ((!isAdmin && appUserMgr.canWrite('ConfigurePayoutTeacherConfiguration')) || (isAdmin && appUserMgr.canWrite('ConfigurePayoutAdminConfiguration'))) {
      setShowPayoutSettings(true);
      setShowRecipientTable(false);
      navigate(isAdmin ? `/admin/Payout/Admin/Configuration/${payoutAdminConfig.Id}` : `/admin/Payout/Teacher/Configuration/${payoutTeacherConfig.Id}`);
    } else {
      setShowRecipientTable(true)
      navigate(isAdmin ? `/admin/Payout/Admin` : `/admin/Payout/Teacher`);
    }
  };

  const savePayoutUsers = async (payoutRecipientsToSave, forceSave, isComplete) => {
    if (payoutRecipientsToSave.length > 0) {
      const arrayOfPromises = []
      for (const rowIndex of payoutRecipientsToSave) {
        populateOriginalWithCustom(rowIndex)
        if (rowIndex.isEdited || forceSave) {
          if (isComplete)
            rowIndex.CompletedDate = new Date();
          const recipientSaveResult = isAdmin ? arrayOfPromises.push(payoutMgr._payoutAdminItemMgr.save(rowIndex)) : arrayOfPromises.push(payoutMgr._payoutTeacherItemMgr.save(rowIndex))
          if (recipientSaveResult) {
            console.log('save ' + recipientSaveResult);
          }
        }
      }
      const results = await Promise.all(arrayOfPromises)
      if (results.every(x => x.Success)) {
        return true
      } else {
        setErrors(['Failed To save a recipient'])
      }
    }
  }
  const populateOriginalWithCustom = (row) => {
    for (const key in row) {
      //If the currentValue is not null/undefined but the original value is, make it the original value.
      // Original. Skr score , Compass Equivv, surveyscore populated by backend disable until data..

      const adminOriginalCounterPart = (key.startsWith("Custom") || key === 'Comment' || key === 'IsActive' || key === 'IsAssistantPrincipal' || key === 'PayoutPct' || key === 'PayoutPctReason' || key === 'SKRScore' || key === 'SurveyScore')
      // Override field is not null. 
      // Original / Override field can't be negative.
      // Original Field does not equal Override field. (Handle both being nulls)
      // Original Field must be null/empty/""

      if (
        ((isAdmin && adminOriginalCounterPart) || (!isAdmin && Object.keys(row).includes("Original" + key)))
        && row[key] !== null && row[key] !== undefined
        && row['Original' + key] !== row[key]
        && (row['Original' + key] === null || row['Original' + key] === undefined || row['Original' + key] === "")
        && row['Original' + key] >= 0
        && row[key] >= 0
      ) {
        row['Original' + key] = row[key]
        row.isEdited = true
      }
    }
  }

  const handleOnRecipientPrevious = async () => {
    setIsLoading(true)
    setRecipientResults([])
    setPayoutTeacherConfig(null)
    navigate(isAdmin ? `/admin/Payout/Admin` : `/admin/Payout/Teacher`);
  };

  const handleOnExport = async (row, fileType) => {
    let reportParams;
    setLoading(true)
    if (isAdmin) {
      reportParams = {
        "report": "SchoolAdministratorPayoutUsers.trdp",
        "parameterValues":
        {
          "DateRangeEnd": null,
          "DateRangeStart": null,
          "SchoolYear": row.SchoolYearIdentifier,
          "DistrictId": row.DistrictId
        }
      }
    } else {
      reportParams = {
        "report": "TeacherPayoutUsers.trdp",
        "parameterValues":
        {
          "DateRangeEnd": null,
          "DateRangeStart": null,
          "SchoolYear": row.SchoolYearIdentifier,
          "SchoolID": row.SchoolId
        }
      }
    }

    const result = await reportingSvc.getExport(reportParams, fileType, exportTypeDialog.type);
    setLoading(false)
    setExportTypeDialog(false)
  };

  const handleOnComplete = async () => {
    const copiedPayoutInfo = { ...payoutInfo }
    copiedPayoutInfo.PayoutStatus = 3
    copiedPayoutInfo.CompletedDate = new Date();
    setPayoutInfo(copiedPayoutInfo)
    savePayoutUsers(recipientResults, true, true)
    await payoutMgr.savePayoutSchool(copiedPayoutInfo)
    navigate(isAdmin ? `/admin/Payout/Admin` : `/admin/Payout/Teacher`);
  }

  const handleOnPaymentSettingsPrevious = () => {
    setIsLoading(true)
    navigate(isAdmin ? `/admin/Payout/Admin/${payoutInfo.Id}` : `/admin/Payout/Teacher/${payoutInfo.Id}`);
  };

  const handleOnPaymentSettingsSave = () => {
    payoutMgr.SavePayoutSettings(selectedSchool, selectedYear).then((response) => {
      setShowPayoutSettings(false);
    });
  };

  const handleOnFilterChange = async (field, value) => {

    const filter = payoutMgr._payoutFilter;
    const updatedFilter = { ...filter };
    // const updatedFilter = {
    //   School: payoutMgr._payoutFilter.School, District: payoutMgr._payoutFilter.District, Status: payoutMgr._payoutFilter.Status
    // };

    if (field === 'District') {
      updatedFilter.District = value;
    }
    else if (field === 'School') {
      updatedFilter.School = value;
    }
    else if (field === 'Status') {
      updatedFilter.Status = value;
    } else if (field === 'Group') {
      updatedFilter.Group = value;
    }
    setData(await payoutMgr.FilterPayouts(updatedFilter));
  }

  return (<>
    <DialogControl
      title={`Export as ${exportTypeDialog?.type?.replace('Export', '')}`}
      innerclassName={'dialog__inner__center'}
      openDialog={exportTypeDialog}
      okText={'Generate'}
      loadingOk={loading}
      enableNext={true}
      onCancel={() => {
        setExportTypeDialog(false)
      }}
      onOk={() => {
        //Payout represents calculate button.
        //User represents export button.
        //Params
        if (selectedFileType === "PDF") {
          if (exportTypeDialog.type.includes('Payout')) {
            if (params.payoutConfigId) {
              handleCalculatePayout("PDF")
            } else {
              handleOnCalculate(exportTypeDialog.item, "PDF")
            }
          } else if (exportTypeDialog.type.includes('User')) {
            handleOnExport(exportTypeDialog.item, "PDF")
          }
        } else if (selectedFileType === "EXCEL") {
          if (exportTypeDialog.type.includes('Payout')) {
            if (params.payoutConfigId) {
              handleCalculatePayout("XLSX")
            } else {
              handleOnCalculate(exportTypeDialog.item, "XLSX")
            }
          } else if (exportTypeDialog.type.includes('User')) {
            handleOnExport(exportTypeDialog.item, "XLSX")
          }
        }
      }}
    >
      <SelectListControl
        field={{ fieldName: 'filetype' }}
        value={selectedFileType}
        hidePleaseSelect={true}
        textValuePairs={[{ text: 'PDF', value: 'PDF' }, { text: 'EXCEL', value: 'EXCEL' }]}
        onChange={(e) => {
          setSelectedFileType(e.target.value)
        }}
      />
    </DialogControl>
    <MainLayout errors={errors} validationErrors={validationErrors}>
      <>
        {!showRecipientTable && !showPayoutSettings ?
          <div className="screen-wrapper">
            <h2>{isLoading ? '' : 'Teacher Payout'}</h2>
            <div className='control-box-wrapper'>
              <div className='control-box-list'>
                {isLoading ?
                  <div className='payout-page-loading'>
                    <Loading type='default' size='5rem' /><div className='loading-font'>Loading</div>
                  </div>
                  :
                  <>
                    <PayoutFilter orgs={orgs} orgMgr={orgMgr} isAdmin={isAdmin} onFilterChange={handleOnFilterChange} filterData={data} filter={payoutMgr?._payoutFilter} />
                    <PayoutTable
                      appUserMgr={appUserMgr}
                      isAdmin={isAdmin}
                      filteredItems={data}
                      districts={districts}
                      schools={schools}
                      schoolYears={years}
                      onEdit={handleOnEdit}
                      onExport={(row) => setExportTypeDialog({ item: row, type: `${isAdmin ? 'Admin' : 'Teacher'} User Export` })}
                      onCalculate={(row) => setExportTypeDialog({ item: row, type: `${isAdmin ? 'Admin' : 'Teacher'} Payout Export` })} />
                  </>
                }
              </div>
            </div>
          </div>
          :
          isLoading ?
            <div className="payout-page-loading ">
              <Loading type='default' size='5rem' /><div className='loading-font'>{(showRecipientTable && (payoutInfo?.PayoutStatus === "Complete" || payoutInfo?.PayoutStatus == 3)) ? 'Loading...' : 'Loading...'}</div>
            </div>
            :
            showRecipientTable ?
              isAdmin ?
                <PayoutAdminRecipients
                  isAdmin={isAdmin}
                  data={recipientResults}
                  payoutInfo={payoutInfo}
                  metricData={payoutAdminConfigMetricsData}
                  onNext={handleOnRecipientNext}
                  onPrevious={handleOnRecipientPrevious}
                  appUserMgr={appUserMgr}
                  onExport={() => setExportTypeDialog({ item: payoutInfo, type: 'Admin User Export' })} />

                :
                <PayoutRecipients
                  isAdmin={isAdmin}
                  data={recipientResults}
                  payoutInfo={payoutInfo}
                  appUserMgr={appUserMgr}
                  hideCompassEq={hideCompassEq}
                  payoutTeacherConfig={payoutTeacherConfig}
                  onNext={handleOnRecipientNext}
                  onPrevious={handleOnRecipientPrevious}
                  onExport={() => setExportTypeDialog({ item: payoutInfo, type: 'Teacher User Export' })} />
              :
              showPayoutSettings &&
                isAdmin ? (
                <PayoutAdminSettings
                  recipientResults={recipientResults}
                  payoutMgr={payoutMgr}
                  payoutInfo={payoutInfo}
                  isAdmin={isAdmin}
                  payoutConfig={payoutAdminConfig}
                  setPayoutAdminConfigMetricsData={setPayoutAdminConfigMetricsData}
                  setPayoutConfig={setPayoutAdminConfig}
                  onSave={handleOnPaymentSettingsSave}
                  onPrevious={handleOnPaymentSettingsPrevious}
                  onCaluclate={() => setExportTypeDialog({ item: payoutInfo, type: 'Admin Payout Export' })}
                  // onCaluclate={handleCalculatePayout}
                  handleOnComplete={handleOnComplete}
                  appUserMgr={appUserMgr}
                  savePayoutUsers={savePayoutUsers}
                  setValidationErrors={setValidationErrors}
                />
              ) : (
                <PayoutSettings
                  payoutTeacherConfig={payoutTeacherConfig}
                  payoutMgr={payoutMgr}
                  payoutInfo={payoutInfo}
                  isAdmin={isAdmin}
                  onSave={handleOnPaymentSettingsSave}
                  onPrevious={handleOnPaymentSettingsPrevious}
                  onCaluclate={() => setExportTypeDialog({ item: payoutInfo, type: 'Teacher Payout Export' })}
                  appUserMgr={appUserMgr}
                  // onCaluclate={handleCalculatePayout}
                  setValidationErrors={setValidationErrors}
                  handleOnComplete={handleOnComplete}
                />
              )
        }
      </>
    </MainLayout >
  </>
  );
};

export default PayoutMgmt;