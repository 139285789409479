import React, { useState, useRef } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';

//https://rsuitejs.com/components/check-tree-picker/
import { CheckTreePicker } from 'rsuite';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import $ from 'jquery';
import SelectListControl from '../../../../components/controls/SelectListControl';
import { useEffect } from 'react';

export default function TagFilter({ avaliableFilters, onFilterChange }) {
    const [search, setSearch] = useState(null);
    const [isActive, setIsActive] = useState('');
    const [isSecure, setIsSecure] = useState('');
    const [parentTags, setParentTags] = useState([]);

    useEffect(() => {
        if (avaliableFilters?.parentTags) {
            setParentTags(avaliableFilters?.parentTags ?? []);
        }
    }, [avaliableFilters?.parentTags])

    const handleSearchChange = (value) => {
        setSearch(value);
        if (onFilterChange)
            onFilterChange("Search", value);
    }

    const handleActiveChange = (value) => {
        setIsActive(value);
        if (onFilterChange)
            onFilterChange("IsActive", value);
    }

    const handleSecureChange = (value) => {
        setIsSecure(value);
        if (onFilterChange)
            onFilterChange("IsSecure", value);
    }

    const onTreeClean = (event) => {
        if (onFilterChange)
            onFilterChange("ParentTagIds", []);
    }

    const onTreeSelect = (activeNode, vals) => {

        let tagTree = parentTags;
        let newTagIds = [];
        vals.forEach(val => {
            newTagIds.push(val);
            const tag = tagTree.find(t => t.TagId == val);
            if (tag && tag.ParentTagId == null && tag.children) {
                tag.children.forEach(c => {
                    newTagIds.push(c.TagId);
                });
            }
        });

        if (onFilterChange)
            onFilterChange("ParentTagIds", newTagIds);

    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        $(".rs-picker-toggle-clean").trigger("click");
        setSearch('');
        setIsActive('UNK');
        setIsSecure('UNK');

        if (onFilterChange) {
            onFilterChange("ParentTagIds", []);
            onFilterChange("Search", '');
            onFilterChange("IsActive", 'UNK');
            onFilterChange("IsSecure", 'UNK');
        }
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Tag Management</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search'
                            value={search ?? ''}
                            fieldName='Search'
                            disableError={true}
                            placeholder='Search Tags'
                            onChange={handleSearchChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        {/*defaultExpandAll*/}
                        <InputField
                            id='parentTags'
                            title='Parent Tags'
                            fieldName='ParentTagIds'
                            className={'field-input__checkTreePicker'}
                            disableError={true}>
                            <CheckTreePicker
                                height={320}
                                cascade={false}
                                data={parentTags ?? []}
                                onClean={onTreeClean}
                                onSelect={onTreeSelect}
                                placement="autoHorizontalStart"
                                className="searchWidth"
                            />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        {/*defaultExpandAll*/}
                        <InputField
                            id='isSecure'
                            title='Secure?'
                            value={isSecure ?? ''}
                            fieldName='IsSecure'
                            onChange={handleSecureChange}
                            disableError={true}>
                            <SelectListControl
                                textValuePairs={
                                    [
                                        { text: 'Yes', value: '1' },
                                        { text: 'No', value: '0' },
                                    ]} />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        {/*defaultExpandAll*/}
                        <InputField
                            id='isActive'
                            title='Active?'
                            value={isActive ?? ''}
                            fieldName='IsActive'
                            onChange={handleActiveChange}
                            disableError={true}>
                            <SelectListControl
                                textValuePairs={
                                    [
                                        { text: 'Yes', value: '1' },
                                        { text: 'No', value: '0' },
                                    ]} />
                        </InputField>
                    </div>
                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}