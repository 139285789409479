import React, { useState } from 'react';
import { useEffect } from "react";
import { LabelControl, TableControl } from "../../../../components/controls";
import { DialogControl } from '../../../../components/controls/DialogControl';
import '../../rubricDialog.scss';
import { useRubricManager } from '../../../../hooks/useManagers';
import RubricLayoutDialogTable from './RubricLayoutDialogTable';
import evaluationTypes from '../../../dev/data/evaluationTypeSet';
import { id } from 'date-fns/locale';

const RubricLayoutDialog = ({ dialogState, data, dataUpdate, updateRubricData, domainDbData, dialogClose, onNavigateRow, currentDomain, setSelectedDomain, setRubricData, excludeCancel }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState();
    const [domain, setDomain] = useState();
    const [name, setName] = useState();
    const [schoolYear, setSchoolYear] = useState();
    const [rubricTypeHeaderText, setRubricTypeHeaderText] = useState();
    const [organization, setOrganization] = useState();
    const [clientId, setClientId] = useState();
    const [tableData, setTableData] = useState();
    const [domainData, setDomainData] = useState([]);
    const [domainDataSet, setDomainDataSet] = useState([]);
    const [enableSave, setEnableSave] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const rubricMgr = useRubricManager();

    useEffect(() => {
        setOpenDialog(dialogState);

        if (dialogState && domainDbData) {
            if (data && domainDbData) {
                setDialogTitle(data.Name + ' Rubric Layout');
                setName(data.Name);
                setSchoolYear(data.SchoolYear ? data.SchoolYear.Title : '');

                const evalSet = evaluationTypes;
                var selectedRubricType = evalSet.find(evalType => evalType.value == data.RubricType);
                setRubricTypeHeaderText(selectedRubricType.label);

                setOrganization(data.CustomFields);
                setTableData(data);

                domainDbData = domainDbData.filter(x => x.Name !== 'Default');

                domainDbData.sort((a, b) => {
                    var textA = a.Name.toUpperCase();
                    var textB = b.Name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });


                setDomainDataSet(domainDbData);
            }
        }
    }, [dialogState, data]);


    const handleOnSaveDomain = async (item) => {

        let domainUpdatedItem = domainData.find(x => x.domain === item.Text);

        let domainToSave = {
            Id: item.domainId,
            Name: item.Text,
            ClientId: data.ClientId,
        }
        if (item.domainId === '') {
            domainToSave = {
                Name: item.Text,
                ClientId: data.ClientId,
            }
        }
        const domainSaveResult = await rubricMgr.DomainItemManager.save(domainToSave);

        if (domainSaveResult.Success && domainSaveResult.Items) {

            let newDomainSet = [...domainDataSet];

            newDomainSet.push(domainSaveResult.Items[0]);
            setDomainDataSet(newDomainSet);

            domainUpdatedItem.domainId = domainSaveResult.Items[0].Id;
            let domainMapItemId = '';

            //find the matching domainMap from rubric data, if it exists
            let matchingDomainMap = data.RubricDomainMap.find(m => m.RubricDomainId === domainSaveResult.Items[0].Id);
            if (matchingDomainMap) {
                domainMapItemId = matchingDomainMap.Id;
            }

            let rubricDomainMap = {
                RubricId: data.Id,
                RubricDomainId: domainSaveResult.Items[0].Id,
                Sequence: 1,
                UseForCertification: item.useForCert
            }
            if (domainMapItemId !== '') {
                rubricDomainMap = {
                    Id: domainMapItemId,
                    RubricId: data.Id,
                    RubricDomainId: domainSaveResult.Items[0].Id,
                    Sequence: 1,
                    UseForCertification: item.useForCert
                }
            }
            const rubricDomainMapSaveResult = await rubricMgr.RubricDomainMapItemMgr.save(rubricDomainMap);
            if (rubricDomainMapSaveResult.Success && rubricDomainMapSaveResult.Items.length > 0) {
                data.RubricDomainMap.push(rubricDomainMapSaveResult.Items[0])
                setRubricData(data);
            }
        }

        domainData.forEach(d => {
            if (d.isEditMode) {
                setEnableSave(false);
            }
        });

        return true;
    }

    const handleOnSave = async (isRowSave, domainItem) => {
        //saving RubricDomains
        setEnableSave(false);
        setLoadingSave(true);
        if (isRowSave === 'row' && domainItem.domainId === '') {
            let result = await handleOnSaveDomain(domainItem);
        }
        else {
            let newData = Object.assign({}, data);

            for (const d of domainData) {
                if (d.domain) {
                    let domainMapItemId = '';

                    //find the matching domainMap from rubric data, if it exists
                    let matchingDomainMap = data.RubricDomainMap.find(m => m.Id === d.Id);
                    if (matchingDomainMap) {
                        domainMapItemId = matchingDomainMap.Id;
                    }

                    let rubricDomainMap = {
                        RubricId: data.Id,
                        RubricDomainId: d.domainId,
                        Sequence: domainData.length,
                        UseForCertification: d.useForCert
                    }
                    if (domainMapItemId !== '') {
                        rubricDomainMap = {
                            Id: matchingDomainMap.Id,
                            RubricId: data.Id,
                            RubricDomainId: d.domainId,
                            Sequence: matchingDomainMap.Sequence,
                            UseForCertification: d.useForCert
                        }
                    }
                    let rubricDomainMapSaveResult = await rubricMgr.RubricDomainMapItemMgr.save(rubricDomainMap);

                    if (rubricDomainMapSaveResult.Success) {
                        if (domainMapItemId === '') {

                            const checkIfReplacingExistingDomain = data.RubricDomainMap.filter(x => x.Sequence === rubricDomainMap.Sequence);

                            if (checkIfReplacingExistingDomain.length > 0) {
                                let rubricDomainMapSaveResult = await rubricMgr.RubricDomainMapItemMgr.delete(checkIfReplacingExistingDomain[0].Id);
                                data.RubricDomainMap = data.RubricDomainMap.filter(x => x.Id !== checkIfReplacingExistingDomain[0].Id);
                            }

                            data.RubricDomainMap.push(rubricDomainMapSaveResult.Items[0]);
                            newData.RubricDomainMap = data.RubricDomainMap;
                        }
                        else {
                            let dmapItem = newData.RubricDomainMap.find(x => x.Id === rubricDomainMapSaveResult.Items[0].Id);
                            if (dmapItem) {
                                let updatedItem = { ...dmapItem, ...rubricDomainMapSaveResult.Items[0] };
                                newData.RubricDomainMap[newData.RubricDomainMap.indexOf(dmapItem)] = updatedItem;
                            }
                        }
                    }
                }
            }

            dataUpdate(newData);

            let indicators = data.RubricIndicatorMap;
            if (indicators.length > 0) {
                indicators.forEach(async n => {
                    let rubricIndicatorMapItemToSave = {
                        Id: n.Id,
                        RubricId: n.RubricId,
                        RubricIndicatorId: n.RubricIndicatorId,
                        RubricDomainId: n.RubricDomainId,
                        Sequence: n.Sequence
                    }
                    const rubricIndicatorMapSaveResult = await rubricMgr.IndicatorMapItemManager.save(rubricIndicatorMapItemToSave);
                });
            }
        }

        if (domainData.length > 0) {
            let setdomain = domainData[0];
            if (currentDomain === 'No domain assigned') {
                setSelectedDomain(setdomain.Id);
            }
        }

        if (isRowSave !== 'row') {
            setOpenDialog(false);
            dialogClose();
            setEnableSave(false);
            updateRubricData(data);
        } else {
            setEnableSave(true);
        }
        setLoadingSave(false);
    }
    const handleDeleteDomain = async (row) => {
        if (row.indicator) {
            const indicatorMapToDelete = tableData.RubricIndicatorMap.find(i => i.RubricIndicator.Name === row.indicator);
            if (indicatorMapToDelete) {
                const indMapResult = await rubricMgr.IndicatorMapItemManager.delete(indicatorMapToDelete.Id);

                if (indMapResult.Success) {
                    const index = data.RubricIndicatorMap.indexOf(indicatorMapToDelete);
                    if (index > -1) { // only splice array when item is found
                        data.RubricIndicatorMap.splice(index, 1); // 2nd parameter means remove one item only
                        dataUpdate(data);
                    }
                }
            }
        } else {
            const domainToDelete = tableData.RubricDomainMap.find(d => d.Id === row.Id);
            if (domainToDelete) {
                const rowResult = await rubricMgr.RubricDomainMapItemMgr.delete(domainToDelete.Id);

                if (rowResult.Success) {
                    const index = data.RubricDomainMap.indexOf(domainToDelete);
                    if (index > -1) { // only splice array when item is found
                        data.RubricDomainMap.splice(index, 1); // 2nd parameter means remove one item only
                    }
                }
            }

            const indicatorMapToDelete = tableData.RubricIndicatorMap.find(i => i.RubricDomainId === row.domainId);
            if (indicatorMapToDelete) {
                const indMapResult = await rubricMgr.IndicatorMapItemManager.delete(indicatorMapToDelete.Id);

                if (indMapResult.Success) {
                    const index = data.RubricIndicatorMap.indexOf(domainToDelete);
                    if (index > -1) { // only splice array when item is found
                        data.RubricIndicatorMap.splice(index, 1); // 2nd parameter means remove one item only
                    }
                }
            }
            dataUpdate(data);
        }
    }

    const handleOnCancel = () => {
        setOpenDialog(false);
        dialogClose();
    }

    const handleIsEditMode = (isEditMode, isSaving) => {
        if (isEditMode) {
            setEnableSave(false);
        }
        else {
            setEnableSave(true);
        }
    }

    const handleOnSaveInner = (indicatorDataSet) => {

        for (let i = 0; i < indicatorDataSet.length; i++) {

            let currentIndicator = indicatorDataSet[i];

            let mapItem = data.RubricIndicatorMap.find(x => x.RubricIndicator.Name === currentIndicator.indicator);
            mapItem.Sequence = i + 1;
        }

        //set new data to update table
        dataUpdate(data);
    }

    const handleOnSaveInnerDomains = (domainRows) => {

        let domainSetArr = [];
        domainRows.filter(row => {
            if (row) {
                if (row.domain) {
                    domainSetArr.push(row);
                }
            }
        })

        for (let i = 0; i < domainSetArr.length; i++) {

            let currentDomain = domainSetArr[i];

            let mapItem = data.RubricDomainMap.find(x => x.RubricDomainId === currentDomain.domainId);
            mapItem.Sequence = i + 1;
        }

        //set new data to update table
        dataUpdate(data);
    }

    return <>
        <DialogControl openDialog={openDialog} onCancel={handleOnCancel} excludeCancel={excludeCancel} title={dialogTitle} customSave={true} loadingSave={loadingSave} onSave={(row, item) => handleOnSave(row, item)} enableSave={enableSave}>
            <div className="configureContent">
                <div className="container">
                    <div className="wrapper">
                        <div className="rubric-header-inner"><div className='headerColor'>Name: </div><div className="configureHeaderLabel">{name}</div></div>
                        <div className="rubric-header-inner"><div className='headerColor'>Evaluation Type: </div><div className="configureHeaderLabel">{rubricTypeHeaderText}</div></div>
                    </div>
                    <div className="wrapper">
                        <div className="rubric-header-inner-rightside"><div className='headerColor'>School Year: </div><div className="configureHeaderLabel">{schoolYear}</div></div>
                        <div className="rubric-header-inner-rightside"><div className='headerColor'>Organization: </div><div className="configureHeaderLabel">{organization}</div></div>
                    </div>
                </div>

                <RubricLayoutDialogTable data={tableData} setDomainTableData={setDomainData} domainDataSet={domainDataSet} onSaveInnerDomains={handleOnSaveInnerDomains} onSaveInner={handleOnSaveInner} onNavigateRow={onNavigateRow} saveNewDomainItem={handleOnSaveDomain} onSave={handleOnSave} onDelete={handleDeleteDomain} isEditMode={handleIsEditMode} />
            </div>
        </DialogControl>
    </>
}

export default RubricLayoutDialog;