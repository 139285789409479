import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _ from 'lodash';

class PermissionManager extends ItemManager {
  constructor(globalItemState, globalAuthState, globalAppUserState) {


    super(ItemServiceFactory.ItemServiceEnum.Permission, globalAuthState, globalAppUserState, globalItemState,
      (filter, item) => {
        var hasSearch = item.Permission?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
        var hasPermissionType = item.PermissionType == filter?.permissionType || (filter?.permissionType === undefined || filter?.permissionType === null || filter?.permissionType === 'UNK');
        return hasSearch && hasPermissionType;
      });

    this._errorUtil = undefined;
  }

  get DefaultItem() {
    return {
      CanView: true,
      CanWrite: false,
      CanDelete: false,
      CanHardDelete: false,
      CanExecute: false,
      PermissionType: 2
    };
  }
}

export default PermissionManager;