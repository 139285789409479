import _ from "lodash";
import react, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy as copyIcon } from '@fortawesome/free-solid-svg-icons'
import Loading from "../../../../components/Loading";
import { Navigate, useNavigate } from "react-router-dom";


const SurveyTemplateTable = ({ organizations, surveyTemplateMgr, filteredItems, onEdit, onDelete, onError }) => {
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [copySurveyTemplateLoading, setCopySurveyTemplateLoading] = useState(false);
    const [columns] = useState([

        {
            header: 'Organization',
            dataProp: "OrganizationName",
            width: '15%',
            canSort: true
        },
        {
            header: 'Survey Type',
            dataProp: 'SurveyTypeName',
            width: '15%',
            canSort: true
        },
        {
            header: 'Title',
            dataProp: 'Title',
            width: '25%',
            canSort: true
        },
        {
            header: 'Rubric Name',
            dataProp: 'RubricName',
            width: '15%',
            canSort: true
        },
        {
            header: 'Active?',
            dataProp: "IsActive",
            width: '5%',
            isBool: true,
            canSort: false
        },
        {
            header: 'Create Date',
            dataProp: 'CreatedDate',
            datatype: 'dateTime',
            width: '10%',
            canSort: true
        }
    ]);
    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (st) => {
                newRows.push({
                    ...st,
                    SchoolYearIdentifier: st.SchoolYearIdentifier ?? 'None',
                    SurveyTypeName: surveyTemplateMgr?.MapSurveyTypeName(st.SurveyType) ?? 'Unknown',
                    RubricName: st.Rubric?.Name ?? 'None',
                    OrganizationName: organizations ? _.find(organizations, x => x.OrgId === st.ClientId)?.Name ?? 'None' : '',
                });
            });
            setRows(newRows);
        }
    }, [filteredItems, organizations]);


    const onCopy = async (row) => {
        if (row?.Id) {
            setCopySurveyTemplateLoading(row.Id);
            let result = await surveyTemplateMgr.runOperation('CopySurveyTemplate', row.Id)
            if (result?.Success && result.Items.length > 0) {
                navigate(`/admin/SurveyTemplate/${result.Items.first().Id}`);
            }
            setCopySurveyTemplateLoading(false);
        }
    }

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                permission='SurveyTemplate'
                useIntegratedPaging={['10', '25', '50', '100']}
                onEdit={(row) => onEdit(row)}
                onDelete={onDelete}
                onCustom1={{ action: (row) => (<>{(copySurveyTemplateLoading == false && copySurveyTemplateLoading != row?.Id) ? <FontAwesomeIcon aria-label="Copy" title="Copy Survey" className={'btn-icon-fa'} icon={copyIcon} onClick={() => onCopy(row)} /> : <Loading />}</>) }}
            />
        </div>
    </>
}

export default SurveyTemplateTable;