import _ from "lodash";
import moment from "moment-timezone";
import react, { useState } from "react";
import { useEffect } from "react";
import { faClose as completeIcon } from '@fortawesome/free-solid-svg-icons'
import { TableControl } from "../../../../components/controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormatUTCDateTime } from "../../../../utilities/DateFormater";



const LogTable = ({ filteredItems, onView, onDelete }) => {

    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Event Name',
            dataProp: 'EventName',
            width: '20%',
            canSort: true
        },
        {
            header: 'Message',
            dataProp: 'FullMessage',
            width: '50%',
            datatype: 'html',
            canSort: false
        },
        {
            header: 'Log Level',
            dataProp: 'LogLevel',
            width: '10%',
            canSort: true
        },
        {
            header: 'Created Date',
            dataProp: 'CreatedDate',
            datatype: 'dateTime',
            width: '15%',
            canSort: true
        },
        {
            header: 'Created By',
            dataProp: 'CreatedDate',
            datatype: 'dateTime',
            width: '15%',
            canSort: true
        }
    ]);



    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (c) => {
                let logLevel = '';
                switch (c.LogLevel) {
                    case 1:
                        logLevel = 'Info';
                        break;
                    case 2:
                        logLevel = 'Timing';
                        break;
                    case 3:
                        logLevel = 'Warning';
                        break;
                    case 4:
                        logLevel = 'Error';
                        break;
                    case 5:
                        logLevel = 'Change';
                        break;
                    default:
                        logLevel = 'UNK';
                        break;
                }
                newRows.push({
                    ...c,
                    FullMessage: `(${c.MessageTypeId}) ${c.Message}`,
                    LogLevel: logLevel,
                });
            });
            setRows(newRows);
        }
    }, [filteredItems]);

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                permission='Log'
                useIntegratedPaging={['10', '25', '50', '100']}
                onView={(row) => onView(row)}
            />
        </div>
    </>
}

export default LogTable;