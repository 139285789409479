
import ContentManager from '../../../managers/ContentManager';
import React, { useEffect, useState } from 'react';
//https://rsuitejs.com/components/check-tree-picker/
import { Checkbox, CheckTreePicker, Modal } from 'rsuite';
import '../../../../../styles/rsuite_override.css';
import { treeViewUserSelect } from '../../../utils/componentHelpers';
import CircularProgress from '@mui/material/CircularProgress';
import './index.scss'

import {
    FormControlLabel,
} from '@mui/material';
import { useAppUserManager, useContentManager } from '../../../../../hooks/useManagers';
import { DialogControl } from '../../../../../components/controls/DialogControl';
import { InputField } from '../../../../../components/controls/InputField';
import _ from 'lodash';

export default function ShareDialog() {
    const contentMgr = useContentManager();
    const appUserMgr = useAppUserManager();

    const [modal, setModal] = useState(false);
    const [userData, setUserData] = useState(null);
    const [selectedUserIds, setSelectedUserIds] = useState(null);
    const [defaultSelectedIds, setDefaultSelectedIds] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const [sharedContentConfig, setSharedContentConfig] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [itemData, setItemData] = useState({ notifiyWhenComplete: false });
    const toggleDialog = () => setModal(!modal);

    useEffect(() => {
        setModal(contentMgr?.SelectedSharedContent ? contentMgr?.SelectedSharedContent.NotifiyWhenComplete : false);
    }, [contentMgr, contentMgr?.SelectedSharedContent]);


    useEffect(() => {
        if (contentMgr?.SelectedSharedContent != null) {
            setValidationError(null);
            const allP = [];
            if (sharedContentConfig == null) {
                setIsLoading(true);
                allP.push(contentMgr.GetShareContentConfig(contentMgr?.SelectedSharedContent.KeyId).then((r) => {
                    if (r.Success && r.Items.length > 0) {
                        setSelectedUserIds(r.Items.first().UserIds);
                        const item = r.Items.first();
                        let newDefaultSelectedIds = [];
                        item.UserIds.forEach(x => newDefaultSelectedIds.push(`u-${x}`));
                        setDefaultSelectedIds(newDefaultSelectedIds);
                        setItemData({ ...itemData, notifiyWhenComplete: item.NotifiyWhenComplete });
                        setSharedContentConfig(item);
                    }
                    setIsLoading(false);
                    return;
                }));
            }

            if (userData == null) {
                setIsLoadingUsers(true);
                allP.push(appUserMgr?.GetShareUsersTree().then((r) => {
                    if (r.Success && r?.Items?.length > 0) {
                        return r.Items;
                    }
                    else
                        return null;
                }));
            }

            Promise.all(allP).then(r => {
                if (r[1]) {
                    setUserData(r[1]);
                }
                setIsLoadingUsers(false);
            });

            if (!modal) {
                toggleDialog();
            }
        }
    }, [appUserMgr, contentMgr?.SelectedSharedContent]);

    const handleCheckChange = (name, val) => {
        setItemData({ ...itemData, [name]: val });
    };

    const onTreeClean = () => {
        setSelectedUserIds(_.map(defaultSelectedIds, x => x.replace('u-', '')));
    }

    const onTreeSelect = (activeNode, vals, event) => {
        treeViewUserSelect(activeNode, vals, setSelectedUserIds, selectedUserIds)
    }

    const handleSaveSharedContent = async (e) => {
        let updatedSharedContentConfig = { ...(sharedContentConfig ?? {}) };
        updatedSharedContentConfig.ContentId = contentMgr?.SelectedSharedContent.ContentId;
        updatedSharedContentConfig.UserIds = selectedUserIds;
        updatedSharedContentConfig.NotifiyWhenComplete = itemData.notifiyWhenComplete;

        const defaultIds = _.map(defaultSelectedIds, ds => ds.replace('u-', ''));
        const validationCheck = _.filter(selectedUserIds, x => !defaultIds.includes(x));
        
        if (validationCheck.length > 0) {
            const r = await contentMgr.ShareContent(updatedSharedContentConfig);
            if (r?.Success) {
                closeDialog();
                contentMgr.ForceContentUpdate();
            }
            return r;
        }
        else {
            return { Success: false, messages: ['Please select one or more users.'] };
        }
    }

    const closeDialog = () => {
        setSharedContentConfig(null);
        setValidationError([]);
        contentMgr.SetSelectedSharedContent(null);
    }

    return (
        <>
            <DialogControl openDialog={modal} title={'Share Content'} subTitle={`Select who you would like to share ${contentMgr?.SelectedSharedContent?.Title} with.`} okText="Share" onOk={handleSaveSharedContent} onCancel={closeDialog}>
                <div className='share-dialog' >
                    <div className='share-dialog-image'>
                        <img
                            alt="Thumbnail"
                            className="card-img-top rounded"
                            src={contentMgr?.SelectedSharedContent?.thumbUrl}
                        />
                    </div>
                    <div className='share-dialog-content'>
                        {validationError != null && <div style={{ color: "red" }}>{validationError}</div>}
                        <h3 className="modalHeader">
                            {contentMgr?.SelectedSharedContent?.Title}
                        </h3>
                        <p className="mb-0 modalDescription">
                            {contentMgr?.SelectedSharedContent?.Description}
                        </p>

                        {!isLoading ? <>
                            <div className="d-flex align-items-center mt-4 modalCheckbox">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="notifiyWhenComplete"
                                            id="notifiyWhenComplete"
                                            checked={itemData?.notifiyWhenComplete}
                                            onChange={handleCheckChange}
                                            value="notifiyWhenComplete"
                                        />
                                    } label="Notify when complete">
                                </FormControlLabel>
                            </div>
                            {!isLoadingUsers ?
                                <>
                                    <p className="directions">
                                        Select a user to share with by clicking the dropdown, then use the arrows to expand and navigate users or search by first or last name.
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        {(userData && defaultSelectedIds) ? <InputField>
                                            <CheckTreePicker
                                                height={320}
                                                data={userData ?? []}
                                                valueKey="Id"
                                                labelKey="Name"
                                                childrenKey="Children"
                                                disabledItemValues={defaultSelectedIds ?? []}
                                                defaultValue={defaultSelectedIds ?? []}
                                                onClean={onTreeClean}
                                                onSelect={onTreeSelect}
                                                placement="top"
                                                className="fixed-tree-picker"
                                                searchable={true}
                                            />
                                        </InputField> :
                                            <div>{!userData ? <div>No users found for the your organization.</div> : <div>Unknown Error while loading organizations</div>}</div>
                                        }
                                    </div>
                                </>
                                :
                                <div className="share-dialog-loader"><CircularProgress color={'inherit'} size={25} /></div>}
                        </> : <div className="share-dialog-loader"><CircularProgress color={'inherit'} size={25} /></div>
                        }
                    </div>
                </div>

            </DialogControl>
        </>
    );
}
