import { React, useEffect, useState } from 'react';
import { Panel } from 'rsuite';
import { InputField } from '../../../../../components/controls/InputField';
import './../styles/studentAchievementPayRatios.scss';

const StudentAchievementPayRatios = ({data, setData, selectedPayoutConfigId, settings, onChange }) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [payRatioData, setPayRatioData] = useState();
    const [shownRows, setShownRows] = useState(3); 
    const [payoutConfigId, setPayoutConfigId] = useState();

    useEffect(() => {
        if (data && selectedPayoutConfigId) {
            setPayoutConfigId(selectedPayoutConfigId);

            let sortedData = sortPayRatioData(data);
            setPayRatioData(sortedData);
        }
    }, [data, selectedPayoutConfigId])

    const sortPayRatioData = (dataSet) => {
        //sort the array by score 
        const groupedData = dataSet.reduce((groups, item) => {
            const score = item.Score;
            if (!groups[score]) {
              groups[score] = [];
            }
            groups[score].push(item);
            return groups;
        }, {});

        const sortedGroupedData = Object.entries(groupedData).sort(([scoreA], [scoreB]) => {
            const parsedScoreA = parseFloat(scoreA);
            const parsedScoreB = parseFloat(scoreB);
          
            if (parsedScoreA < parsedScoreB) {
              return -1;
            } else if (parsedScoreA > parsedScoreB) {
              return 1;
            } else {
              return 0;
            }
        });
          
        const sortedDataWithinGroups = sortedGroupedData.reduce((result, [score, group]) => {
            result[score] = group;
            return result;
        }, {});

        const sortedDataArray = Object.entries(sortedDataWithinGroups)
            .sort(([scoreA], [scoreB]) => parseFloat(scoreA) - parseFloat(scoreB))
            .map(([score, group]) => ({ score, group }));


        //sort by positon type
        const sortGroupByObservedPositionName = (a, b) => {
            const positionOrder = {
            "Career Teacher": 1,
            "Mentor Teacher": 2,
            "Master Teacher": 3,
            };
            const positionA = a.ObservedPositionName;
            const positionB = b.ObservedPositionName;
            return positionOrder[positionA] - positionOrder[positionB];
        };
        
        // Sort the group array within each object
        const sortedData = sortedDataArray.map((item) => ({
            ...item,
            group: item.group.sort(sortGroupByObservedPositionName),
        }));

        setShownRows(sortedData.length);
        return sortedData;
    } 

    const handleRatioChange = (score, observedPosition, value) => {
        const updatedData = [ ...data ];
        let updatedScore = updatedData.find(x => x.ObservedPositionName === observedPosition && x.Score === score);

        updatedScore["RatioAmount"] = value;

        setData(updatedData);
    }

    const handleAddRow = () => {
        if (shownRows < 7) {
            setShownRows(shownRows + 1);
        
            const group = [
                   {
                        ObservedPositionName: 'Career Teacher',
                        ObservedPositionTypeId: 50,
                        Score: (payRatioData.length + 1),
                        PayoutConfigurationId: payoutConfigId
                   },
                   {
                        ObservedPositionName: 'Mentor Teacher',
                        ObservedPositionTypeId: 42,
                        Score: (payRatioData.length + 1),
                        PayoutConfigurationId: payoutConfigId
                   },
                   {
                        ObservedPositionName: 'Master Teacher',
                        ObservedPositionTypeId: 41,
                        Score: (payRatioData.length + 1),
                        PayoutConfigurationId: payoutConfigId
                   } 
            ]    

            const updatedData = [ ...data ];

            group.forEach(score => {
                updatedData.push(score);
            });

            setData(updatedData);
        }
    };

    const handleDeleteRow = () => {
        const dataCopy = [ ...data ];
        const lastEntry = payRatioData[payRatioData.length - 1];

        const updatedData = dataCopy.filter(obj => obj.Score.toString() !== lastEntry.score);
        setData(updatedData);
    };

    return (
        <>
        <Panel className='student-panel' header="Student Achievement">
        <div className='student-achievement-pay-ratios-table'>
            {payRatioData ? (
                <table className="student-achievement-pay-ratios-table">
                    <thead>
                        <tr>
                        <th>Score</th>
                        <th>Career Teacher</th>
                        <th>Mentor Teacher</th>
                        <th>Master Teacher</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payRatioData.map(({ score, group }) => (
                            <tr key={score}>
                                <td className='row-header'>{score}</td>
                                {group.map((entry, index) => (
                                    <td key={index}>
                                        <InputField
                                            value={entry.RatioAmount}
                                            placeholder={'0'}
                                            onChange={(value) => handleRatioChange(entry.Score, entry.ObservedPositionName, value)}
                                            type="number"
                                            disableError={true} />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div>Loading...</div>
            )}
            <div className='btn-container'>
                {shownRows < 7 && (
                    <div className="add-row-btn-container">
                        <button className="add-row-btn" onClick={handleAddRow}>
                        Add Row
                        </button>
                    </div>
                )}
                {shownRows > 3 && (
                    <div className="delete-row-btn-container">
                        <button className="delete-row-btn" onClick={handleDeleteRow}>
                        Delete Row
                        </button>
                    </div>
                )}
            </div>
        </div>
        </Panel>
        </>
    );
}

export default StudentAchievementPayRatios;