import moment from "moment-timezone";


const FormatUTCDate = (dateString) => {
    return moment.tz(dateString, 'UTC').local().format('M/D/YYYY')
};

const FormatUTCDateTimeLong = (dateString) => {
    return moment.tz(dateString, 'UTC').local().format('MMMM D, YYYY @ h:mm A')
};

const FormatUTCDateTime = (dateString) => {
    return moment.tz(dateString, 'UTC').local().format('M/D/YYYY h:mm A')
};

const FormatUTCTime = (dateString) => {
    return moment.tz(dateString, 'UTC').local().format('h:mm A')
};

const FormatUTCTimeM = (dateString) => {
    const momentTime = moment.tz(dateString, 'UTC');
  
    // Check if the time is valid
    if (momentTime.isValid()) {
      return momentTime.local().format('HH:mm');
    } else {
      // Return the original input if the time is invalid
      return dateString;
    }
  };

const FormatUTCDateM = (dateString, format) => {
    //return moment.tz(dateString, 'UTC').local().format(format ? format : 'YYYY-MM-DD');
    const momentDate = moment.tz(dateString, 'UTC');
    
    // Check if the date is valid
    if (momentDate.isValid()) {
        return momentDate.local().format(format ? format : 'YYYY-MM-DD');
    } else {
        // Return the original input if the date is invalid
        return dateString;
    }
};

const FormatUTCDateTimeM = (dateString, format) => {
    return moment.tz(dateString, 'UTC').local().format(format ? format : 'YYYY-MM-DD HH:mm');
};


const FormatTimeNumber = (t) => {
    return `${("0" + Math.floor((t / (60000 * 60)) % 60)).slice(-2)}:${("0" + Math.floor((t / 60000) % 60)).slice(-2)}:${("0" + Math.floor((t / 1000) % 60)).slice(-2)}`;
}

const FormatScriptingTime = (t, type) => {
    if (type === 'stop-watch') {
        return (
            <div className="stop-watch">
                <span>
                    {("0" + Math.floor((t / (60000 * 60)) % 60)).slice(-2)} :<span> </span>
                </span>
                <span>
                    {("0" + Math.floor((t / 60000) % 60)).slice(-2)} :<span> </span>
                </span>
                <span>
                    {("0" + Math.floor((t / 1000) % 60)).slice(-2)}
                </span>
            </div>
        )
    }
    else if (type === 'child') {
        return `${("0" + Math.floor((t / (60000 * 60)) % 60)).slice(-2)}:${("0" + Math.floor((t / 60000) % 60)).slice(-2)}:${("0" + Math.floor((t / 1000) % 60)).slice(-2)}`;
    }
    else {
        return (
            <div className="stop-watch">
                <span>
                    {("0" + Math.floor((t / (60000 * 60)) % 60)).slice(-2)}:
                </span>
                <span>
                    {("0" + Math.floor((t / 60000) % 60)).slice(-2)}:
                </span>
                <span>
                    {("0" + Math.floor((t / 1000) % 60)).slice(-2)}
                </span>
            </div>
        )
    }
}

const ConvertScriptingDisplayedTimeToIntTime = (displayedTime) => {
    const timeArr = displayedTime.split(':');

    let hours = parseInt(timeArr[0]) * (60000 * 60);
    let mins = parseInt(timeArr[1]) * 60000;
    let secs = parseInt(timeArr[2]) * 1000;

    return hours + mins + secs;
}


export { FormatUTCDateTimeLong, FormatUTCTimeM, FormatUTCDateM, FormatUTCDateTimeM, FormatUTCDate, FormatUTCDateTime, FormatUTCTime, FormatTimeNumber, FormatScriptingTime, ConvertScriptingDisplayedTimeToIntTime };