import { React, useEffect, useState } from 'react';
import { Button } from 'rsuite';
import ButtonControl from '../../../../../components/controls/ButtonControl';
import { useNavigate } from 'react-router-dom';
import { usePayoutManager } from '../../../../../hooks/useManagers';

const MetricsComponent = ({
    school
}) => {
    const navigate = useNavigate();
    const payoutMgr = usePayoutManager();
    const [isLoading, setIsLoading] = useState(false);

    const handleOnClick = async (value) => {
        if(value === 'Teacher') {
            setIsLoading(true);
            await payoutMgr.GetPayoutConfiguration(school.ClientId, school.DistrictId, school.Id).then((response) => {
                if (response.Items?.length > 0) {
                    setIsLoading(false);
                    let config = response.Items[0];
                    navigate(`/admin/Payout/Teacher/Configuration/Metrics/${config.Id}`);
                }
            });
        }
        if(value === 'Admin') {
            setIsLoading(true);
            await payoutMgr.GetAdminPayoutConfiguration(school.ClientId, school.DistrictId, school.Id).then((response) => {
                if (response.Items?.length > 0) {
                    setIsLoading(false);
                    let config = response.Items[0];
                    navigate(`/admin/Payout/Admin/Configuration/Metrics/${config.Id}`);
                }
            });
        }
    }
    return (
        <>
            <h5 className='metrics-header'>
                Click below to navigate to update the Metrics and Weights of the Surveys and Rubric Domains associated with this School. 
                <br></br>This effects the Payout Calculation and SKR Score (End of Year Rating).
            </h5>
            <div className='metrics-container'>
                <div className='metrics-button'>
                    <ButtonControl loading={isLoading} type={'okay'} onClick={() => handleOnClick('Teacher')}>Update Teacher Metrics</ButtonControl>
                </div>
                <div className='metrics-button'>
                    <ButtonControl loading={isLoading} type={'okay'} onClick={() => handleOnClick('Admin')}>Update Admin Metrics</ButtonControl>                
                </div>
            </div>

        </>
    );
}

export default MetricsComponent;