import React, {useEffect, useRef, useState} from 'react';
import {Player, PlayerEvent, PlayerAPI, TimeRange} from 'bitmovin-player';
import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui';
import 'bitmovin-player/bitmovinplayer-ui.css';
import configData from "../../../../../config.json";

function BitmovinPlayer({content, onEnded, onPause, currentTimeStamp}) {

  const [player, setPlayer] = useState(null);
  const [playerSource, setPlayerSource] = useState(null);
  const [playerState, setPlayerState] = useState(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false); // Add isPlayerReady state
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const playerDiv = useRef();
  const playerConfig = {
    key: configData.BITMOVIN_PLAYER_KEY,
    ui: false,
    playback: {autoplay: true}
  };
  const PlayerState = {
    Idle: 'Idle',
    Prepared: 'Prepared',
    Playing: 'Playing',
    Paused: 'Paused',
    Finished: 'Finished',
  };

  useEffect(() => {
    if (content) {
      setPlayerSource(() => ({
        dash: content.DashUrl,
        hls:  content.HlsUrl, 
        poster: content.thumbUrl
      }));

      //TODO: many other configs can be added here, like start and stop time saving in Certs 
      //TODO: need to add in the start/stop, example:
      //{
      //   "dash": "https://cdn.bitmovin.com/content/assets/art-of-motion-dash-hls-progressive/mpds/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.mpd",
      //   "poster": "https://cdn.bitmovin.com/content/assets/art-of-motion-dash-hls-progressive/poster.jpg",
      //   "options": {
      //     "startTime": 20 //as an example for setting time
      //   }
      // }
      //TODO: more options can be found on the player UI playground https://bitmovin.com/demos/player-playground
      //TODO: more docs https://github.com/bitmovin/bitmovin-player-ui/blob/develop/src/ts/playerutils.ts
      //TODO: more docs https://github.com/bitmovin/bitmovin-player-ui/blob/develop/src/ts/uiconfig.ts

    }
  }, [content]);

  function getState(player) {
    if (player.hasEnded()) {
      return PlayerState.Finished;
    } else if (player.isPlaying()) {
      return PlayerState.Playing;
    } else if (player.isPaused()) {
      return PlayerState.Paused;
    } else if (player.getSource() != null) {
      return PlayerState.Prepared;
    } else {
      return PlayerState.Idle;
    }
  }

  useEffect(() => {
    if(player) {
      const updatePlayerState = () => {
        const currentState = getState(player);
        setPlayerState(currentState);
      };

      // // Add event listeners for player state changes
      //TODO: NEED to detect when player changes state so we can save it like the AMS component does for Certificaiton page
      //player.on(player.EVENT.ON_PLAY, updatePlayerState);
      player.on(PlayerEvent.Paused, handleOnPause);
      player.on(PlayerEvent.PlaybackFinished, handleOnEnded);
      // player.on(player.EVENT.ON_SOURCE_LOADED, updatePlayerState);

      // // Cleanup function to remove event listeners
      return () => {
        // player.off(player.EVENT.ON_PLAY, updatePlayerState);
        player.off(PlayerEvent.Paused, handleOnPause);
        player.off(PlayerEvent.PlaybackFinished, handleOnEnded);
        // player.off(player.EVENT.ON_SOURCE_LOADED, updatePlayerState);
      };
    }
  }, [player]);

  useEffect(() => {
    async function setupPlayer() {
      if (!playerSource) return;

      setIsLoading(true); 
      const playerInstance = new Player(playerDiv.current, playerConfig);
      UIFactory.buildDefaultUI(playerInstance);
      
      try {
        await playerInstance.load(playerSource);
        playerInstance.seek(currentTimeStamp);
        setPlayer(playerInstance);
        setIsPlayerReady(true);
      } catch (error) {
        console.error('Error loading player source:', error);
      } finally {
        setIsLoading(false);
      }
    }

    setupPlayer();

    return () => {
      if (player != null) {
        player.destroy();
        setPlayer(null);
      }
    };
  }, [playerSource]);

  const handleOnEnded = () => {
    onEnded();
  }

  const handleOnPause = () => {
    onPause(player.getCurrentTime());
  }


  return <div id='player' ref={playerDiv} />
}

export default BitmovinPlayer;