/*
    CAUTION: PLEASE READ! This File was auto generated by the system.
    Any changes you make will be removed the next time it's generated.
*/
const SystemTypes = {
	UserType: {
		AllData: 0,
		AllData_Organizations: 1,
		AllData_Districts: 2,
		AllData_Schools: 3,
		AllData_Clusters: 4,
		UserData_Assocated: 99,
		UserData: 100,
		Anonymous: 1000
	},
	UserPositionType: {
		Admin: 1,
		OrganizationAdmin: 10,
		DistrictAdmin: 20,
		SchoolAdmin: 30,
		AssitantSchoolAdmin: 31,
		LeadTeacher: 40,
		MasterTeacher: 41,
		MentorTeacher: 42,
		Teacher: 50,
		Individual: 100,
		None: 1000
	},
	ImportExportStatusType: {
		Created: 1,
		InProgress: 2,
		Failed: 3,
		Success: 4,
		PartialSuccess: 5
	},
	ImportExportType: {
		ImportUsers: 1,
		ExportUsers: 2,
		ImportStructure: 3,
		ExportOrganization: 4,
		ExportDistrict: 5,
		ExportSchool: 6,
		ExportCluster: 7,
		ExportObservationConfig: 8,
		ImportObservationConfig: 9,
		ExportTags: 10,
		ImportTags: 11,
		ExportAnalytics: 12,
		ExportRoleMatrix: 13,
		ExportContent: 14,
		ExportContentAndThumbnails: 15
	},
	ObservationType: {
		UnannouncedTeacherObservation: 1,
		AnnouncedTeacherObservation: 2,
		SchoolLeaderObservation: 3
	},
	ObservationSelfReflectionType: {
		Observed: 1,
		Observer: 2
	},
	SurveyStatusType: {
		NotStarted: 0,
		InProgress: 1,
		Submitted: 2,
		Completed: 3
	},
	ObservationStatusType: {
		NotStarted: 0,
		InProgress: 1,
		Submitted: 2,
		SelfReflectionNotStarted: 3,
		SelfReflectionInPrgress: 4,
		SelfReflectionSubmitted: 5,
		PostConference: 6,
		ObserverSubmitted: 7,
		ObservedSubmitted: 8,
		Completed: 9
	},
}
export default SystemTypes;
