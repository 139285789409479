import './info.scss';
import React, { useEffect, useState } from 'react';
import svgIcon from '../../../content/icons/info.svg'

export default function Info({ infoUrl, valueToConvert, topOffsetRem, inGrid, children }) {
    const [showInfo, setShowInfo] = useState(false);
    const handleOnShowInfo = () => {
        if (infoUrl) {
            window.open(infoUrl, '_blank');
        }
    }
    const convertedValue = valueToConvert?.toFixed(2);

    return <>{(children || valueToConvert !== undefined) &&
        <div className={`info${inGrid ? '-grid' : ''} gray`} style={topOffsetRem ? { top: `${topOffsetRem}rem` } : {}}>
            {valueToConvert === undefined && <div className={'info-symbol'} arial-label="Info Tooltip"    onClick={handleOnShowInfo} onMouseOver={() => setShowInfo(true)} onMouseOut={() => setShowInfo(false)}><object data={svgIcon} width={'13px'} height={'13px'}></object></div>}
            {valueToConvert !== undefined && <div className={'info-icon'} onClick={handleOnShowInfo} onMouseOver={() => setShowInfo(true)} onMouseOut={() => setShowInfo(false)}>
                <object data={svgIcon} width={'13px'} height={'13px'}></object>
            </div>}
            {showInfo && children && valueToConvert === undefined && <div className={'info-wrapper'}>{children}</div>}
        </div>
    }</>
}