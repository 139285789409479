import React, { useState, useEffect } from "react";
import { TreePicker, CheckPicker } from "rsuite";
import {
  useReportManager,
  useAppUserManager,
  useStagedUserManager,
  useObservationManager,
} from "../../../../hooks/useManagers";
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import {
  DatePickerControl,
  SelectListControl,
} from "../../../../components/controls";
import { getDefaultReportParameters } from "./reportParameters";
import { getDefaultReportParametersDisplay } from "./reportParametersDisplay";
import { getDefaultRequiredReportParameters } from "./requiredReportParameters";
import SystemTypes from "../../../../SystemTypes";
import "./filter.scss";

const ReportFilter = ({
  initialReportParameters,
  reportUrl,
  report,
  orgs,
  clusters,
  schoolYears,
  onReportParametersChange,
}) => {
  const reportManager = useReportManager();
  const observationMgr = useObservationManager();
  const appUserManager = useAppUserManager();
  const userMgr = useStagedUserManager();

  const [reportParametersDisplay, setReportParametersDisplay] = useState(
    getDefaultReportParametersDisplay()
  );
  const [requiredReportParameters, setRequiredReportParameters] = useState(
    getDefaultRequiredReportParameters()
  );

  const [loading, setLoading] = useState(true);
  const [
    teacherOrSchoolAdministratorValues,
    setTeacherOrSchoolAdministratorValues,
  ] = useState([]);
  const [refinementOrReinforcementValues, setRefinementOrReinforcementValues] =
    useState([]);

  const [evaluatorCertificationValues, setEvaluatorCertificationValues] =
    useState([]);
  const [teacherOverallAverageValues, setTeacherOverallAverageValues] =
    useState([]);
  const [roundingValues, setRoundingValues] = useState([]);

  const [districts, setDistricts] = useState([]);
  const [schools, setSchools] = useState([]);
  const [filteredClusters, setFilteredClusters] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [admins, setAdmins] = useState([])
  const [reportType, setReportType] = useState(null);
  const [organizationId, setOrganizationId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [clusterId, setClusterId] = useState("");
  const [schoolYear, setSchoolYear] = useState(null);
  const [selectDateRange, setSelectDateRange] = useState("0");
  const [gradeLevels, setGradeLevels] = useState(null);
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [includeInactiveUsers, setIncludeInactiveUsers] = useState("0");
  const [includeNietAdministrators, setIncludeNietAdministrators] =
    useState("0");
  const [includeDirectors, setIncludeDirectors] = useState("0");
  const [isSummarizeAtLevel, setIsSummarizeAtLevel] = useState("0");
  const [teacherUserIds, setTeacherUserIds] = useState([]);

  useEffect(() => {
    if (reportManager?.AppUserState?.userProfile) {
      if (!schoolYear) {
        setSchoolYear(reportManager?.AppUserState?.selectedSchoolYear);
      }

      if (organizationId == "") {
        if (reportManager?.AppUserState?.userProfile?.OrganizationIds?.first()) {
          var orgId = reportManager?.AppUserState?.userProfile?.OrganizationIds?.first();
          setOrganizationId(orgId);
          handleFilterChange("organizationId", orgId)
        }
      }
    }
  }, [reportManager?.AppUserState?.selectedSchoolYear, reportManager?.AppUserState?.userProfile]);

  useEffect(() => {
    if (initialReportParameters && loading === true) {
      //setReportType(initialReportParameters.reportUrl !== reportUrl && initialReportParameters.reportType ? initialReportParameters.reportType : "0");
      setOrganizationId(initialReportParameters.organizationId ?? "");
      setDistrictId(initialReportParameters.districtId ?? "");
      setSchoolId(initialReportParameters.schoolId ?? "");
      setClusterId(initialReportParameters.clusterId ?? "");
      setTeacherUserIds(initialReportParameters.teacherUserIds?.split(",") ?? []);
      setSchoolYear(initialReportParameters.schoolYear ?? null);
      setSelectDateRange(initialReportParameters.selectDateRange === false ? "0" : "1");
      setStartDate(initialReportParameters.startDate ?? "");
      setEndDate(initialReportParameters.endDate ?? "");
      setIsSummarizeAtLevel(initialReportParameters.isSummarizeAtLevel === false ? "0" : "1");
      setIncludeInactiveUsers(initialReportParameters.includeInactiveUsers === false ? "0" : "1");
      setIncludeNietAdministrators(initialReportParameters.includeNietAdministrators === false ? "0" : "1");

      if (initialReportParameters.gradeLevels && initialReportParameters.gradeLevels != 'Null')
        setGradeLevels(initialReportParameters.gradeLevels);
      setIncludeDirectors(initialReportParameters.includeDirectors === false ? "0" : "1");
      setLoading(false);
    }
  }, [initialReportParameters, loading, reportUrl]);

  useEffect(() => {
    if (report) {
      const reportTypeSelected = report.getReportType ? report.getReportType(reportType?.toString()) : undefined;
      const parametersDisplay = report.getReportParametersDisplay(getDefaultReportParametersDisplay(), reportTypeSelected);
      setReportParametersDisplay(parametersDisplay);
      const requiredParameters = report.getRequiredReportParameters(getDefaultRequiredReportParameters(), reportTypeSelected);
      setRequiredReportParameters(requiredParameters);

      if (parametersDisplay.isIncludeGradeLevels && observationMgr) {
        observationMgr.getOptions("gradelevels").then(options => {
          setGradeLevelOptions(options);
        });
      }

      if (reportType) {
        const reportParameters = getReportParametersFromState();
        onReportParametersChange({ ...reportParameters, reportType: reportType.toString() });
      }
    }
  }, [report, observationMgr, reportType]);

  useEffect(() => {
    if (!reportManager) return;

    if (organizationId) {
      setDistricts(reportManager.OrgManager.DistrictsOnly(organizationId));
    }

    if (districtId) {
      setSchools(reportManager.OrgManager.SchoolsOnly(districtId));
    }

    if (schoolId) {
      setFilteredClusters(
        clusters?.filter(
          (c) =>
            c !== undefined &&
            c?.ClientId === organizationId &&
            c?.DistrictId === districtId &&
            c?.SchoolId === schoolId
        )
      );
    }
  }, [
    reportManager,
    reportManager?.OrgManager,
    schoolYears,
    clusters,
    organizationId,
    districtId,
    schoolId,
  ]);

  useEffect(() => {
    if (!appUserManager || !reportUrl) return;

    let teacherOrSchoolAdministratorValues = [];
    if (
      (reportUrl === "evaluator-certification-records-export" &&
        appUserManager.AccessCheck(
          "ReportsTeacherEvaluatorCertificationRecordsExport"
        ) === true) ||
      (reportUrl === "evaluator-certification-statistics" &&
        appUserManager.AccessCheck(
          "ReportsTeacherEvaluatorCertificationStatistics"
        ) === true)
    ) {
      teacherOrSchoolAdministratorValues.push({ text: "Teacher", value: 0 });
    }
    if (
      (reportUrl === "evaluator-certification-records-export" &&
        appUserManager.AccessCheck(
          "ReportsSchoolAdministratorEvaluatorCertificationRecordsExport"
        ) === true) ||
      (reportUrl === "evaluator-certification-statistics" &&
        appUserManager.AccessCheck(
          "ReportsSchoolAdministratorEvaluatorCertificationStatistics"
        ) === true)
    ) {
      teacherOrSchoolAdministratorValues.push({
        text: "School Administrator",
        value: 1,
      });
    }

    setTeacherOrSchoolAdministratorValues(teacherOrSchoolAdministratorValues);
    if (teacherOrSchoolAdministratorValues.length > 0) {
      setReportType(teacherOrSchoolAdministratorValues.first().value);
    }

    let refinementOrReinforcementValues = [];
    if (
      (reportUrl === "teacher-refinement-or-reinforcement-goals" &&
        appUserManager.AccessCheck(
          "ReportsTeacherRefinementAndReinforcementGoalsRefinement"
        ) === true) ||
      (reportUrl ===
        "school-administrator-refinement-and-reinforcement-goals" &&
        appUserManager.AccessCheck(
          "ReportsSchoolAdministratorRefinementAndReinforcementGoalsRefinement"
        ) === true)
    ) {
      refinementOrReinforcementValues.push({ text: "Refinement", value: 0 });
    }
    if (
      (reportUrl === "teacher-refinement-or-reinforcement-goals" &&
        appUserManager.AccessCheck(
          "ReportsTeacherRefinementAndReinforcementGoalsReinforcement"
        ) === true) ||
      (reportUrl ===
        "school-administrator-refinement-and-reinforcement-goals" &&
        appUserManager.AccessCheck(
          "ReportsSchoolAdministratorRefinementAndReinforcementGoalsReinforcement"
        ) === true)
    ) {
      refinementOrReinforcementValues.push({ text: "Reinforcement", value: 1 });
    }

    setRefinementOrReinforcementValues(refinementOrReinforcementValues);
    if (refinementOrReinforcementValues.length > 0) {
      setReportType(refinementOrReinforcementValues.first().value);
    }

    let evaluatorCertificationValues = [];
    if (
      (reportUrl === "school-administrator-evaluator-certification" &&
        appUserManager.AccessCheck(
          "ReportsSchoolAdministratorEvaluatorCertificationStatus"
        ) === true) ||
      (reportUrl === "teacher-evaluator-certification" &&
        appUserManager.AccessCheck(
          "ReportsTeacherEvaluatorCertificationStatus"
        ) === true)
    ) {
      evaluatorCertificationValues.push({ text: "Status", value: 0 });
    }
    if (
      (reportUrl === "school-administrator-evaluator-certification" &&
        appUserManager.AccessCheck(
          "ReportsSchoolAdministratorEvaluatorCertificationResets"
        ) === true) ||
      (reportUrl === "teacher-evaluator-certification" &&
        appUserManager.AccessCheck(
          "ReportsTeacherEvaluatorCertificationResets"
        ) === true)
    ) {
      evaluatorCertificationValues.push({ text: "Resets", value: 1 });
    }
    if (
      (reportUrl === "school-administrator-evaluator-certification" &&
        appUserManager.AccessCheck(
          "ReportsSchoolAdministratorEvaluatorCertificationFailures"
        ) === true) ||
      (reportUrl === "teacher-evaluator-certification" &&
        appUserManager.AccessCheck(
          "ReportsTeacherEvaluatorCertificationFailures"
        ) === true)
    ) {
      evaluatorCertificationValues.push({ text: "Failures", value: 2 });
    }
    if (
      (reportUrl === "school-administrator-evaluator-certification" &&
        appUserManager.AccessCheck(
          "ReportsSchoolAdministratorEvaluatorCertificationResults"
        ) === true) ||
      (reportUrl === "teacher-evaluator-certification" &&
        appUserManager.AccessCheck(
          "ReportsTeacherEvaluatorCertificationResults"
        ) === true)
    ) {
      evaluatorCertificationValues.push({ text: "Results", value: 3 });
    }
    setEvaluatorCertificationValues(evaluatorCertificationValues);
    if (evaluatorCertificationValues.length > 0) {
      setReportType(evaluatorCertificationValues.first().value);
    }

    let teacherOverallAverageValues = [];
    if (
      reportUrl === "teacher-overall-average" &&
      appUserManager.AccessCheck(
        "ReportsTeacherOverallAverageRubricIndicator"
      ) === true
    ) {
      teacherOverallAverageValues.push({ text: "Rubric Indicator", value: 0 });
    }
    if (
      reportUrl === "teacher-overall-average" &&
      appUserManager.AccessCheck("ReportsTeacherOverallAverageObserver") ===
      true
    ) {
      teacherOverallAverageValues.push({ text: "Observer", value: 1 });
    }
    if (
      reportUrl === "teacher-overall-average" &&
      appUserManager.AccessCheck("ReportsTeacherOverallAverageEducator") ===
      true
    ) {
      teacherOverallAverageValues.push({ text: "Educator", value: 2 });
    }
    if (
      reportUrl === "teacher-overall-average" &&
      appUserManager.AccessCheck("ReportsTeacherOverallAverageSubject") === true
    ) {
      teacherOverallAverageValues.push({ text: "Subject", value: 3 });
    }
    setTeacherOverallAverageValues(teacherOverallAverageValues);

    if (teacherOverallAverageValues.length > 0) {
      setReportType(teacherOverallAverageValues.first().value);
    }

    let roundingValues = [];
    if (
      (reportUrl === "school-administrator-end-of-year-rating" &&
        appUserManager.AccessCheck(
          "ReportsSchoolAdministratorEndOfYearRatingRounding"
        ) === true) ||
      (reportUrl === "teacher-skr-summary" &&
        appUserManager.AccessCheck("ReportsTeacherSKRSummaryRounding") === true)
    ) {
      roundingValues.push({ text: "Rounding", value: 0 });
    }
    if (
      (reportUrl === "school-administrator-end-of-year-rating" &&
        appUserManager.AccessCheck(
          "ReportsSchoolAdministratorEndOfYearRatingNoRounding"
        ) === true) ||
      (reportUrl === "teacher-skr-summary" &&
        appUserManager.AccessCheck("ReportsTeacherSKRSummaryNoRounding") ===
        true)
    ) {
      roundingValues.push({ text: "No-Rounding", value: 1 });
    }
    setRoundingValues(roundingValues);
    if (roundingValues.length > 0) {
      setReportType(roundingValues.first().value);
    }
  }, [appUserManager, reportUrl]);

  useEffect(() => {
    if (userMgr && organizationId && districtId && schoolId) {
      const userProfileObj = {
        isActive: true,
        isStaged: false,
        includesStaged: true,
        oId: organizationId.toUpperCase(),
        dId: districtId.toUpperCase(),
        sId: schoolId.toUpperCase(),
        cId: clusterId ? clusterId.toUpperCase() : undefined,
      }
      if (!clusterId)
        delete userProfileObj.cId
      userMgr
        .runOperation("GetUserProfileList", undefined, undefined, userProfileObj)
        .then((r) => {
          if (r.Success) {
            const items = r.Items[0].Data;
            const filteredTeachers = items.filter(
              (item) =>
                item.UserPositionType >= 40 && item.UserPositionType <= 50 && item.OrganizationIds.includes(organizationId) && item.DistrictIds.includes(districtId) && item.SchoolIds.includes(schoolId)
                && ((clusterId && item.ClusterIds.includes(clusterId)) || !clusterId)
            );
            const teachers = filteredTeachers.map((item) => ({
              FullName: `${item.LastName}, ${item.FirstName}`,
              UserId: item.Id,
            }));
            console.log(userProfileObj)
            setTeachers(teachers);
            setAdmins(items.filter(x => x.UserPositionType < 40 && x.OrganizationIds.includes(organizationId) && x.DistrictIds.includes(districtId) && x.SchoolIds.includes(schoolId)).map((x) => ({
              FullName: `${x.LastName}, ${x.FirstName}`,
              UserId: x.Id,
            })))
          } else {
            setTeachers([]);
            setAdmins([])
          }
        });
    } else {
      setTeachers([]);
      setAdmins([])
    }
  }, [userMgr, organizationId, districtId, schoolId, clusterId]);

  useEffect(() => {
    if(schoolId) {
      setIsSummarizeAtLevel("1");
      const reportParameters = getReportParametersFromState();
      reportParameters.isSummarizeAtLevel = true;
      onReportParametersChange(reportParameters);
    }
  }, [schoolId])


  const getReportParametersFromState = () => {
    const reportParameters = getDefaultReportParameters();
    reportParameters.reportType = reportType;
    reportParameters.organizationId = organizationId === "" ? null : organizationId;
    reportParameters.districtId = districtId === "" ? null : districtId;
    reportParameters.schoolId = schoolId === "" ? null : schoolId;
    reportParameters.clusterId = clusterId === "" ? null : clusterId;
    reportParameters.teacherUserIds = teacherUserIds.join(",");

    if (selectDateRange == "0")
      reportParameters.schoolYear = schoolYear;
    else
      reportParameters.schoolYear = null;

    reportParameters.selectDateRange = selectDateRange === "1";
    reportParameters.startDate = startDate === "" ? null : startDate;
    reportParameters.endDate = endDate === "" ? null : endDate;
    reportParameters.isSummarizeAtLevel = isSummarizeAtLevel === "1";
    reportParameters.includeInactiveUsers = includeInactiveUsers === "1";
    reportParameters.includeNietAdministrators = includeNietAdministrators === "1";
    reportParameters.includeDirectors = includeDirectors === "1";
    return reportParameters;
  };

  const handleFilterChange = (field, value) => {
    const reportParameters = getReportParametersFromState();

    const runDateUpdates = (syIdentifier) => {

      if (selectDateRange === "0") {
        let selectedSchoolYear = schoolYears.filter(
          (sy) => sy.SchoolYearIdentifier === syIdentifier
        );
        if (selectedSchoolYear.length > 0) {
          const schoolYearStart = selectedSchoolYear[0].SchoolYearStart;
          const schoolYearStartDateFormat = schoolYearStart.substring(
            0,
            schoolYearStart.indexOf("T")
          );

          setStartDate(schoolYearStartDateFormat);
          reportParameters.startDate = schoolYearStartDateFormat;

          const schoolYearStartDate = new Date(schoolYearStart);
          const schoolYearEnd = new Date(
            schoolYearStartDate.getFullYear() + 1,
            schoolYearStartDate.getMonth(),
            schoolYearStartDate.getDate()
          ).toISOString();
          const schoolYearEndDateFormat = schoolYearEnd.substring(
            0,
            schoolYearEnd.indexOf("T")
          );
          setEndDate(schoolYearEndDateFormat);
          reportParameters.endDate = schoolYearEndDateFormat;
        }
      }
    }

    switch (field) {
      case "reportType":
        setReportType(value);
        reportParameters.reportType = value;
        break;
      case "organizationId":
        if (organizationId !== value) {
          clearOrganizationDependentValues(reportParameters);
        }
        setOrganizationId(value);
        reportParameters.organizationId = value === "" ? null : value;
        //if(selectDateRange){
        //  runDateUpdates(reportParameters.schoolYear);
        //}
        break;
      case "districtId":
        if (districtId !== value) {
          clearDistrictDependentValues(reportParameters);
        }
        setDistrictId(value);
        reportParameters.districtId = value === "" ? null : value;
        break;
      case "schoolId":
        if (schoolId !== value) {
          clearSchoolDependentValues(reportParameters);
        }
        setSchoolId(value);
        reportParameters.schoolId = value === "" ? null : value;
        break;
      case "clusterId":
        if (clusterId !== value) {
          clearClusterDependentValues(reportParameters);
        }
        setClusterId(value);
        reportParameters.clusterId = value === "" ? null : value;
        break;
      case "teacherUserIds":
        setTeacherUserIds(value);
        reportParameters.teacherUserIds = value.join(",");
        break;
      case "gradeLevels":
        setGradeLevels(value);
        reportParameters.gradeLevels = value.join(",");
        break;
      case "selectDateRange":
        setSelectDateRange(value);
        if (value == "1") {
          if (endDate && startDate) {
            reportParameters.schoolYear = null;
            reportParameters.startDate = endDate;
            reportParameters.endDate = startDate;
          }
          else {
            runDateUpdates(schoolYear);
          }
        }
        else {
          reportParameters.schoolYear = schoolYear;
          reportParameters.endDate = null;
          reportParameters.startDate = null;
        }
        break;
      case "schoolYear":
        setSchoolYear(value);
        //Set the default
        if (selectDateRange == "1") {
          reportParameters.schoolYear = null;
        }
        else {
          reportParameters.schoolYear = value === "" ? null : value;
        }
        break;
      case "startDate":
        setStartDate(value);
        reportParameters.startDate = value === "" ? null : value;
        break;
      case "endDate":
        setEndDate(value);
        reportParameters.endDate = value === "" ? null : value;
        break;
      case "isSummarizeAtLevel":
        setIsSummarizeAtLevel(value);
        reportParameters.isSummarizeAtLevel = value === "1";
        break;
      case "includeInactiveUsers":
        setIncludeInactiveUsers(value);
        reportParameters.includeInactiveUsers = value === "1";
        break;
      case "includeNietAdministrators":
        setIncludeNietAdministrators(value);
        reportParameters.includeNietAdministrators = value === "1";
        break;
      case "includeDirectors":
        setIncludeDirectors(value);
        reportParameters.includeDirectors = value === "1";
        break;
      default:
        break;
    }
    onReportParametersChange(reportParameters);
  };

  const handleOnClearFilter = () => {
    localStorage.setItem("CurrentPage", 1);
    setOrganizationId("");
    setDistrictId("");
    setSchoolId("");
    setClusterId("");
    setTeacherUserIds([]);
    setSchoolYear(null);
    setSelectDateRange("0");
    setStartDate("");
    setEndDate("");
    setIsSummarizeAtLevel("0");
    setIncludeInactiveUsers("0");
    setIncludeNietAdministrators("0");
    setIncludeDirectors("0");
    setGradeLevels(null);
    onReportParametersChange({ ...getDefaultReportParameters(), reportType });
  };

  const clearOrganizationDependentValues = (reportParameters) => {
    setDistrictId("");
    reportParameters.districtId = null;
    clearDistrictDependentValues(reportParameters);
  };

  const clearDistrictDependentValues = (reportParameters) => {
    setSchoolId("");
    reportParameters.schoolId = null;
    clearSchoolDependentValues(reportParameters);
  };

  const clearSchoolDependentValues = (reportParameters) => {
    setClusterId("");
    reportParameters.clusterId = null;
    clearClusterDependentValues(reportParameters);
  };

  const clearClusterDependentValues = (reportParameters) => {
    setTeacherUserIds([]);
    reportParameters.teacherUserIds = null;
  };

  return (
    <div className="report-filter">
      <div className="d-flex justify-content-center contentFilter">
        <div className="filterTitle">{report?.reportTitle}</div>
        <div className="report-filter-scroll">
          {reportParametersDisplay?.isTeacherOrSchoolAdministratorTypeDisplayed ===
            true && (
              <div className="filterControl">
                <InputField
                  title="Report Type"
                  value={reportType}
                  fieldName="text"
                  disableError={true}
                  onChange={(value) => {
                    handleFilterChange("reportType", value);
                  }}
                >
                  <SelectListControl
                    hidePleaseSelect={true}
                    textValuePairs={teacherOrSchoolAdministratorValues}
                  />
                </InputField>
              </div>
            )}

          {reportParametersDisplay?.isRefinementOrReinforcementTypeDisplayed ===
            true && (
              <div className="filterControl">
                <InputField
                  title="Report Type"
                  value={reportType}
                  fieldName="text"
                  disableError={true}
                  onChange={(value) => {
                    handleFilterChange("reportType", value);
                  }}
                >
                  <SelectListControl
                    hidePleaseSelect={true}
                    textValuePairs={refinementOrReinforcementValues}
                  />
                </InputField>
              </div>
            )}
          {reportParametersDisplay?.isEvaluatorCertificationTypeDisplayed ===
            true && (
              <div className="filterControl">
                <InputField
                  title="Report Type"
                  value={reportType}
                  fieldName="text"
                  disableError={true}
                  onChange={(value) => {
                    handleFilterChange("reportType", value);
                  }}
                >
                  <SelectListControl
                    hidePleaseSelect={true}
                    textValuePairs={evaluatorCertificationValues}
                  />
                </InputField>
              </div>
            )}
          {reportParametersDisplay?.isTeacherOverallAverageTypeDisplayed ===
            true && (
              <div className="filterControl">
                <InputField
                  title="Report Type"
                  value={reportType}
                  fieldName="text"
                  disableError={true}
                  onChange={(value) => {
                    handleFilterChange("reportType", value);
                  }}
                >
                  <SelectListControl
                    hidePleaseSelect={true}
                    textValuePairs={teacherOverallAverageValues}
                  />
                </InputField>
              </div>
            )}
          {reportParametersDisplay?.isRoundingTypeDisplayed === true && (
            <div className="filterControl">
              <InputField
                title="Report Type"
                value={reportType}
                fieldName="text"
                disableError={true}
                onChange={(value) => {
                  handleFilterChange("reportType", value);
                }}
              >
                <SelectListControl
                  hidePleaseSelect={true}
                  textValuePairs={roundingValues}
                />
              </InputField>
            </div>
          )}
          {(reportParametersDisplay?.isSchoolYearDisplayed === true && schoolYears) && (
            <div className="filterControl">
              <InputField
                title={`School Year${requiredReportParameters?.isSchoolYearRequired === true
                  ? "*"
                  : ""
                  }`}
                onChange={(value) => {
                  handleFilterChange("schoolYear", value);
                }}
                value={schoolYear ?? reportManager?.AppUserState?.selectedSchoolYear}
                disableError={true}
              >
                <SelectListControl
                  hidePleaseSelect={true}
                  textValuePairs={schoolYears.map(x => { return { value: x.SchoolYearIdentifier.toString(), text: x.Title } })}
                />

              </InputField>
            </div>
          )}
          {reportParametersDisplay?.isOrganizationDisplayed === true && (
            <div className="filterControl">
              <InputField
                title={`Organization${requiredReportParameters?.isOrganizationIdRequired === true
                  ? "*"
                  : ""
                  }`}
                value={organizationId}
                disableError={true}
              >
                <TreePicker
                  height={320}
                  width={400}
                  data={orgs ?? []}
                  valueKey={"OrgId"}
                  labelKey={"Name"}
                  onClean={() => handleFilterChange("organizationId", "")}
                  onSelect={(a, val) =>
                    handleFilterChange("organizationId", val)
                  }
                  placement="autoVerticalStart"
                />
              </InputField>
            </div>
          )}
          {reportParametersDisplay?.isDistrictDisplayed === true && (
            <div className="filterControl">
              <InputField
                title={`District${requiredReportParameters?.isDistrictIdRequired === true
                  ? "*"
                  : ""
                  }`}
                value={districtId}
                disableError={true}
              >
                <TreePicker
                  height={320}
                  width={400}
                  disabled={!organizationId}
                  data={districts ?? []}
                  valueKey={"DistrictId"}
                  labelKey={"Name"}
                  onClean={() => handleFilterChange("districtId", "")}
                  onSelect={(a, val) => handleFilterChange("districtId", val)}
                  placement="autoVerticalStart"
                />
              </InputField>
            </div>
          )}
          {reportParametersDisplay?.isSchoolDisplayed === true && (
            <div className="filterControl">
              <InputField
                title={`School${requiredReportParameters?.isSchoolIdRequired === true
                  ? "*"
                  : ""
                  }`}
                value={schoolId}
                disableError={true}
              >
                <TreePicker
                  height={320}
                  width={400}
                  disabled={!districtId}
                  data={schools ?? []}
                  valueKey={"SchoolId"}
                  labelKey={"Name"}
                  onClean={() => handleFilterChange("schoolId", "")}
                  onSelect={(a, val) => handleFilterChange("schoolId", val)}
                  placement="autoVerticalStart"
                />
              </InputField>
            </div>
          )}
          {reportParametersDisplay?.isClusterDisplayed === true && (
            <div className="filterControl">
              <InputField
                title={`Cluster${requiredReportParameters?.isClusterIdRequired === true
                  ? "*"
                  : ""
                  }`}
                value={clusterId}
                disableError={true}
              >
                <TreePicker
                  height={320}
                  width={400}
                  disabled={!schoolId}
                  data={filteredClusters ?? []}
                  valueKey={"Id"}
                  labelKey={"Name"}
                  onClean={() => handleFilterChange("clusterId", "")}
                  onSelect={(a, val) => handleFilterChange("clusterId", val)}
                  placement="autoVerticalStart"
                />
              </InputField>
            </div>
          )}
          {reportParametersDisplay?.isTeachersDisplayed === true && (
            <InputField
              title={`Teachers${requiredReportParameters?.isTeacherUserIdsRequired === true
                ? "*"
                : ""
                }`}
              value={teacherUserIds}
              disableError={true}
            >
              <CheckPicker
                title="Select"
                height={320}
                width={400}
                disabled={!schoolId}
                data={
                  teachers
                    .sort((a, b) => a.FullName.localeCompare(b.FullName))
                    .map((item) => ({
                      label: item.FullName,
                      value: item.UserId,
                  })) ?? []
                }
                placement="auto"
                onClean={() => handleFilterChange("teacherUserIds", [])}
                onSelect={(value) => {
                  handleFilterChange(
                    "teacherUserIds",
                    typeof value === "string" ? [value] : value
                  );
                }}
              />
            </InputField>
          )}
          {reportParametersDisplay?.isAdminDisplayed === true && (
            <InputField
              title={`School Admins`}
              value={teacherUserIds}
              disableError={true}
            >
              <CheckPicker
                title="Select"
                height={320}
                width={400}
                disabled={!schoolId}
                data={
                  admins.map((item) => ({
                    label: item.FullName,
                    value: item.UserId,
                  })) ?? []
                }
                placement="auto"
                onClean={() => handleFilterChange("teacherUserIds", [])}
                onSelect={(value) => {
                  handleFilterChange(
                    "teacherUserIds",
                    typeof value === "string" ? [value] : value
                  );
                }}
              />
            </InputField>
          )}
          {(reportParametersDisplay?.isIncludeGradeLevels === true && gradeLevelOptions) && (
            <div className="filterControl">
              <InputField
                title="Grade Levels"
                fieldName="gradeLevels"
                disableError={true}
              >
                <CheckPicker
                  title="Select"
                  height={320}
                  width={400}
                  labelKey='Name'
                  valueKey='Value'
                  defaultValue={typeof (gradeLevels) == 'string' ? gradeLevels?.split(',') : gradeLevels}
                  data={
                    gradeLevelOptions ?? []
                  }
                  placement="auto"
                  onClean={() => handleFilterChange("gradeLevels", [])}
                  onSelect={(value) => {
                    handleFilterChange("gradeLevels", value);
                  }}
                />
              </InputField>
            </div>
          )}
          {reportParametersDisplay?.isSummarizeAtLevelDisplayed === true &&
            organizationId &&
            !districtId &&
            !schoolId &&
            !clusterId && (
              <div className="filterControl">
                <InputField
                  title="Summarize at Organization Level?"
                  value={isSummarizeAtLevel}
                  fieldName="text"
                  disableError={true}
                  onChange={(value) => {
                    handleFilterChange("isSummarizeAtLevel", value);
                  }}
                >
                  <SelectListControl
                    hidePleaseSelect={true}
                    textValuePairs={[
                      { text: "Yes", value: 1 },
                      { text: "No", value: 0 },
                    ]}
                  />
                </InputField>
              </div>
            )}
          {reportParametersDisplay?.isSummarizeAtLevelDisplayed === true &&
            organizationId &&
            districtId &&
            !schoolId &&
            !clusterId && (
              <div className="filterControl">
                <InputField
                  title="Summarize at District Level?"
                  value={isSummarizeAtLevel}
                  fieldName="text"
                  disableError={true}
                  onChange={(value) => {
                    handleFilterChange("isSummarizeAtLevel", value);
                  }}
                >
                  <SelectListControl
                    hidePleaseSelect={true}
                    textValuePairs={[
                      { text: "Yes", value: 1 },
                      { text: "No", value: 0 },
                    ]}
                  />
                </InputField>
              </div>
            )}
          {reportParametersDisplay?.isSummarizeAtLevelDisplayed === true &&
            organizationId &&
            districtId &&
            schoolId &&
            !clusterId && (
              <div className="filterControl">
                <InputField
                  title="Summarize at School Level?"
                  value={isSummarizeAtLevel}
                  fieldName="text"
                  disableError={true}
                  onChange={(value) => {
                    handleFilterChange("isSummarizeAtLevel", value);
                  }}
                >
                  <SelectListControl
                    hidePleaseSelect={true}
                    textValuePairs={[
                      { text: "Yes", value: 1 },
                      { text: "No", value: 0 },
                    ]}
                  />
                </InputField>
              </div>
            )}

          {reportParametersDisplay?.isSelectDateRangeDisplayed === true && (
            <div className="filterControl">
              <InputField
                title="Select Date Range?"
                value={selectDateRange}
                fieldName="text"
                disableError={true}
                onChange={(value) => {
                  handleFilterChange("selectDateRange", value);
                }}
              >
                <SelectListControl
                  hidePleaseSelect={true}
                  textValuePairs={[
                    { text: "Yes", value: 1 },
                    { text: "No", value: 0 },
                  ]}
                />
              </InputField>
            </div>
          )}
          {(reportParametersDisplay?.isStartDateDisplayed === true && selectDateRange === "1") && (
            <div className="filterControl">
              <InputField
                title={`Start Date${requiredReportParameters?.isStartDateRequired === true
                  ? "*"
                  : ""
                  }`}
                subTitle="Start of date range"
                value={startDate}
                disableError={true}
                onChange={(value) => {
                  handleFilterChange("startDate", value);
                }}
              >
                <DatePickerControl
                  includeTime={false}
                  disabled={selectDateRange === "0"}
                />
              </InputField>
            </div>
          )}
          {(reportParametersDisplay?.isEndDateDisplayed === true && selectDateRange === "1") && (
            <div className="filterControl">
              <InputField
                title={`End Date${requiredReportParameters?.isEndDateRequired === true
                  ? "*"
                  : ""
                  }`}
                subTitle="End of date range"
                value={endDate}
                disableError={true}
                onChange={(value) => {
                  handleFilterChange("endDate", value);
                }}
              >
                <DatePickerControl
                  includeTime={false}
                  disabled={selectDateRange === "0"}
                />
              </InputField>
            </div>
          )}

          {reportParametersDisplay?.isIncludeInactiveUsersDisplayed ===
            true && (
              <div className="filterControl">
                <InputField
                  title="Include Inactive Users?"
                  value={includeInactiveUsers}
                  fieldName="text"
                  disableError={true}
                  onChange={(value) => {
                    handleFilterChange("includeInactiveUsers", value);
                  }}
                >
                  <SelectListControl
                    hidePleaseSelect={true}
                    textValuePairs={[
                      { text: "Yes", value: 1 },
                      { text: "No", value: 0 },
                    ]}
                  />
                </InputField>
              </div>
            )}
          {reportParametersDisplay?.isIncludeNietAdministratorsDisplayed === true && userMgr?.AppUserState.userProfile.UserPositionType <= SystemTypes.UserPositionType.OrganizationAdmin && (
            <div className="filterControl">
              <InputField
                title="Include NIET Administrators?"
                value={includeNietAdministrators}
                fieldName="text"
                disableError={true}
                onChange={(value) => {
                  handleFilterChange("includeNietAdministrators", value);
                }}
              >
                <SelectListControl
                  hidePleaseSelect={true}
                  textValuePairs={[
                    { text: "Yes", value: 1 },
                    { text: "No", value: 0 },
                  ]}
                />
              </InputField>
            </div>
          )}
          {reportParametersDisplay?.isIncludeDirectorsDisplayed === true && userMgr?.AppUserState.userProfile.UserPositionType <= SystemTypes.UserPositionType.OrganizationAdmin && (
            <div className="filterControl">
              <InputField
                title="Include Directors?"
                value={includeDirectors}
                fieldName="text"
                disableError={true}
                onChange={(value) => {
                  handleFilterChange("includeDirectors", value);
                }}
              >
                <SelectListControl
                  hidePleaseSelect={true}
                  textValuePairs={[
                    { text: "Yes", value: 1 },
                    { text: "No", value: 0 },
                  ]}
                />
              </InputField>
            </div>
          )}

          <div className="filterControl btn-clear" type="cancel">
            <ButtonControl onClick={handleOnClearFilter} type="cancel">Clear</ButtonControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportFilter;
