import React, { useState } from 'react';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { CheckTreePicker } from 'rsuite';

import { useEffect } from 'react';
import { CheckPicker } from 'rsuite';
import statusData from '../../../dev/data/payoutStatus';
import { InputField } from '../../../../components/controls/InputField';
import { useLocation } from 'react-router-dom';

export default function PayoutFilter({ orgs, orgMgr, isAdmin, filterData, onFilterChange, filter }) {
    const [selectedSchools, setSelectedSchools] = useState();
    const [selectedDistricts, setSelectedDistricts] = useState();
    const [selectedPayoutStatus, setSelectedPayoutStatus] = useState();
    const [schools, setSchools] = useState();
    const [districts, setDistricts] = useState();
    const [isAdminFilter, setIsAdminFilter] = useState(false);
    const { pathname } = useLocation();
    useEffect(() => {
        if (filterData) {
            var uniqueSchools = [];
            var uniqueDistricts = [];

            filterData.forEach(function (item) {
                var schoolId = item.School.Id;
                var schoolName = item.School.Name;
                var districtId = item.District.Id;
                var districtName = item.District.Name;

                var isSchoolExists = uniqueSchools.some(function (school) {
                    return school.Id === schoolId;
                });

                if (!isSchoolExists) {
                    uniqueSchools.push({ value: schoolId, label: schoolName });
                }

                var isDistrictExists = uniqueDistricts.some(function (district) {
                    return district.Id === districtId;
                });

                if (!isDistrictExists) {
                    uniqueDistricts.push({ value: districtId, label: districtName });
                }
            });
            var sortedSchools = uniqueSchools.slice().sort((a, b) => a.label.localeCompare(b.label));
            var sortedDistricts = uniqueDistricts.slice().sort((a, b) => a.label.localeCompare(b.label));

            setSchools(sortedSchools);
            setDistricts(sortedDistricts);
        }
    }, [filterData]);
    useEffect(() => {
        if (isAdmin) {
            setIsAdminFilter(true);
        }
    }, [isAdmin]);

    const handleFilterChange = (value, fieldName) => {
        if (onFilterChange) {
            onFilterChange(fieldName, value)
        }
    }

    const onStatusCheckSelect = (activeNodes) => {
        setSelectedPayoutStatus(activeNodes);

        if (onFilterChange)
            onFilterChange("Status", activeNodes);
    }

    const onTreeClean = (fieldName) => {
        switch (fieldName) {
            case 'School':
                setSelectedSchools();
                break;
            case 'District':
                setSelectedDistricts();
                break;
            default:
        }
        if (onFilterChange)
            onFilterChange(fieldName, []);
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        if (onFilterChange) {
            setSelectedSchools([]);
            setSelectedDistricts([]);
            setSelectedPayoutStatus([]);

            onFilterChange("School", '');
            onFilterChange("District", '');
            onFilterChange("Status", []);
            onFilterChange("Group", []);
        }
    }
    const onTreeCleanOrg = () => {
        handleFilterChange([], "Group");
    }
    const onTreeSelect = (vals, field) => {
        let newTreeIds = orgMgr?.findSelected(vals);
        if (handleFilterChange)
            handleFilterChange(newTreeIds, field);
    }
    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">{isAdminFilter ? 'Admin Payout' : 'Teacher Payout'}</div>
                    <div className="filterControl payout-filter">
                        <InputField
                            id='Group'
                            title='Organization'
                            value={filter?.Group ?? []}
                            fieldName='Group'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleFilterChange}>
                            <CheckTreePicker
                                height={320}
                                width={400}
                                className={'filter-control-input'}
                                childrenKey="Children"
                                labelKey="Name"
                                valueKey="Id"
                                data={orgs ?? []}
                                onClean={onTreeCleanOrg}
                                onSelect={(a, v) => onTreeSelect(v, 'Group')}
                                placement="autoHorizontalStart"
                            />
                        </InputField>
                    </div>
                    <div className="title-header">
                        <div className="title">
                            Status
                        </div>
                    </div>
                    <CheckPicker
                        className="searchWidthFilter"
                        placeholder="Status"
                        searchable={false}
                        title='Status'
                        id='status'
                        height={320}
                        value={selectedPayoutStatus}
                        data={statusData ?? []}
                        onClean={() => handleFilterChange('PayoutStatus', '')}
                        onSelect={onStatusCheckSelect}
                    />
                    <div className="filterControl  btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}