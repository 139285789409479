import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import moment from 'moment';
import { faPlay } from "@fortawesome/free-solid-svg-icons";


const CertificationAdminList = ({ filteredItems, onEdit, onPrint, resetCertification, handlePassingCertification, appUserMgr }) => {
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Organization,District,School',
            dataProp: 'fullOrg',
            width: '25%',
            canSort: true
        },
        {
            header: 'Certification Candidate',
            dataProp: 'userFullName',
            width: '20%',
            canSort: true
        },
        {
            header: 'Start Date',
            dataProp: 'StartedDate',
            datatype: 'dateTime',
            width: '12%',
            canSort: true
        },
        {
            header: 'Status',
            dataProp: 'Status',
            width: '10%',
            canSort: true
        },
    ]);

    const statusIdentifer = (item) => {
        if (item?.Status == 9) {
            return "Manually Passed"
        }
        else if (item?.Status == 0 || item?.Status == 7) {
            return "Not Started"
        } else if (item?.Status == 1) {
            return "In Progress Part 1"
        } else if (item?.Status == 2) {
            return "Pass Part 1"
        } else if (item?.Status == 3) {
            return "Fail Part 1"
        } else if (item?.Status == 4) {
            return "In Progress"
        } else if (item?.Status == 5) {
            return "Passed"
        } else if (item?.Status == 6) {
            return "Failed"
        }
    }

    // const userPositionIdentifer = (value) => {
    //     if (value === 40) {
    //         return "Master Teacher"
    //     } else if (value === 41) {
    //         return "Mentor Teacher"
    //     } else return "Teacher"
    // }

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (st) => {
                newRows.push({
                    ...st,
                    Status: statusIdentifer(st),
                    userFullName: st.User.FullName,
                    fullOrg: <>{`${st.OrganizationName ? st.OrganizationName : ''}`}<br />{`${st.DistrictName ? st.DistrictName : ''}`}<br /> {`${st.SchoolName ? st.SchoolName : ''}`}</>,
                    StartedDate: moment.utc(st.StartedDate).local()._isValid ? moment.utc(st.StartedDate).local() : ""
                });
            });
            setRows(newRows);
        }
    }, [filteredItems]);

    //Update this
    const handleCheckDisableAction = (row, action) => {
        if (action.toLowerCase() == 'custom2') {
            return row.Status === 'Manually Passed' || row.Status === 'Passed' && true
        } else if (action.toLowerCase() === 'print') {
            if (row.Status === "Passed" || row.Status === "Manually Passed") {
                return false
            } else return true
        } else if (action.toLowerCase() == 'edit') {
            if (appUserMgr?.canWrite("CertificationTakeAny") ||
                appUserMgr?.GetGlobalState()?.userProfile.Id === row.UserId) {
                return false
            } else return true
        }
        else {
            return false;
        }
    }
    const checkLoadingAction = (action) => {
        if (action === 'print') {
            return action
        }
    }

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                checkDisableAction={handleCheckDisableAction}
                checkLoadingAction={checkLoadingAction}
                useIntegratedPaging={['10', '25', '50', '100']}
                onPrint={(row) => onPrint(row)}
                onEdit={(row) => onEdit(row)}
                defaultSortBy={'userFullName'}
                printing={true}
                defaultSortDesc={'asc'}
                editIcon={faPlay}
                editTitle={'Take Certification'}
                onCustom1={{
                    action: appUserMgr.canExecute('CertificationReset') ? (row) => <div className='icon-wrapper' title="Reset Certification">
                        <RestartAltIcon className='icon' onClick={() => {
                            if (!handleCheckDisableAction(row, 'custom1')) {
                                resetCertification(row)
                            }
                        }} />
                    </div> : null
                }}
                onCustom2={{
                    action: appUserMgr.canExecute('CertificationManualPass') && appUserMgr.canExecute('AzureB2CSaveUserProfile') ? (row) => <div className='icon-wrapper' title="Manually Pass Certification" >
                        <DoneAllIcon className='icon' onClick={() => {
                            if (!handleCheckDisableAction(row, 'custom2')) {
                                handlePassingCertification(row)
                            }
                        }} />
                    </div> : null
                }}
            />
        </div>
    </>
}

export default CertificationAdminList;