import React, { useEffect, useState } from 'react';
import './../home.scss';
import './../trending.scss';
import Loading from '../../../components/Loading';
import { useContentLibraryManager } from "../../../hooks/useManagers";

const TrendingContent = () => {
  const contentMgr = useContentLibraryManager();
  const [trendingContent, setTrendingContent] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTrendingContent = async () => {
      try {
        if (contentMgr) {
          const result = await contentMgr.TrendingContent();
          if (result.Success) {
            setTrendingContent(result);
          } else {
            setError('Failed to fetch trending content.');
          }
        }
      } catch (err) {
        setError(`Error fetching trending content: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchTrendingContent();
  }, [contentMgr]);

  useEffect(() => {
    if (trendingContent) {
      const imageSet = trendingContent.Items?.slice(0, 4);
      setImages(imageSet);
    }
  }, [trendingContent]);

  return (
    <div className='trending-content'>
      <div className='trending-content-header'>
        Trending EE PASS Content
      </div>
      {loading ? (
        <div className='loading-icon-container'>
          <Loading size={'2.25rem'} />
        </div>
      ) : error ? (
        <div className='error-message'>
          {error}
        </div>
      ) : (
        <div className="trending-img-container">
          {images.map((image) => (
            <div key={image.Id} className='img-div-header'>
              <img src={image.ThumbnailFile?.FileUrl} alt={`Image ${image.Title}`} />
              <div className='trending-img-title'>{image.Title}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TrendingContent;
