const CertificationQuestionValidateObject = () => {
    return {
        QuestionText: {
            fieldName: 'QuestionText',
            messageTitle: 'Question',
            isRequired: true,
            dataType: 'HTML',//IMAGE, INT, DEC, SELECT,BOOL
            groupId: 'CERTIFICATIONQUESTION',
            regEx: undefined,
            requiredDependency: undefined
        },
    }
}

export default CertificationQuestionValidateObject;