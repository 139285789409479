import { useEffect, useState } from 'react';
import './details.scss';
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import { CheckboxControl } from '../../../../components/controls';
import { useValidationManager } from '../../../../hooks/useValidation';
import ReactQuill from 'react-quill';


const TOSDetails = ({ tosMgr, tos, onCancel, onSave, onErrors, onValidationErrors }) => {

    const [tosItem, setTOSItem] = useState(tos ?? tosMgr.DefaultItem);
    const [saving, setSaving] = useState(false);
    const [forceValidate, setForceValidate] = useState(false);
    const validationMgr = useValidationManager(false, 'TOS');

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    const handleOnSave = async (e) => {
        const validateResult = validationMgr.checkIsGroupValid(tosItem);
        if (validateResult?.length === 0) {
            setForceValidate(false);
            if (onSave) {
                setSaving(true);
                var result = await onSave(tosItem);
                if (result?.Success) {
                    handleOnCancel();
                    setSaving(false);
                }
                else if (onErrors) {
                    onErrors([result?.Message?.DisplayMessage ?? [...(result?.MessageDetails ?? ['Unknown Error'])]])
                }
                return result;
            }
        }
        else if (onErrors) {
            setForceValidate(true);
            onValidationErrors(validateResult);
        }
    }

    const handleOnChange = (value, field) => {

        let updatedTOS = { ...tosItem };
        updatedTOS[field] = value;
        setTOSItem(updatedTOS);
    }

    return (<>
        <div className='control-box-wrapper tos-details'>
            {tosItem && <>
                <div className='control-box box-one-column'>
                    <InputField
                        forceValidate={forceValidate}
                        value={tosItem?.TermsHtml}
                        title="Terms of Service"
                        subTitle={'Please copy and paste your terms of service from the word document below and ajust the layout and formating as you see fit.'}
                        fieldName="TermsHtml"
                        groupId={'TOS'}>
                        <ReactQuill theme="snow" className='rq' onChange={(v) => handleOnChange(v, 'TermsHtml')} />
                    </InputField>
                    <InputField title="Active?" value={tosItem?.IsActive} fieldName="IsActive" groupId={'TOS'} onChange={handleOnChange}  >
                        <CheckboxControl />
                    </InputField>
                </div>
            </>}
        </div>
        <div className='screen-footer screen-footer-right'>
            <div className='btn-wrapper-left screen-footer__btn'><ButtonControl disabled={saving} type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl></div>
            <div className='btn-wrapper-right screen-footer__btn'><ButtonControl loading={saving} type={'okay'} disabled={!tosItem} onClick={handleOnSave}>Save</ButtonControl></div>
        </div>
    </>)
}

export default TOSDetails;


