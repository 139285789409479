/*
    CAUTION: PLEASE READ! This File was auto generated by the system.
    Any changes you make will be removed the next time it's generated.
*/
import {
RubricDomainItemService     
,RubricDomainMapItemService     
,RubricIndicatorItemService     
,RubricIndicatorMapItemService     
,RubricIndicatorRatingMapItemService     
,RubricItemService     
,RubricRatingItemService     
,RubricRatingMapItemService     
,PayoutAchievementWeightsItemService     
,PayoutAdminAssistantPrincipalScoreRatioItemService     
,PayoutAdminConfigurationItemService     
,PayoutAdminConfigurationMetricItemService     
,PayoutAdminItemService     
,PayoutAdminObservationDomainWeightsItemService     
,PayoutAdminObserverWeightsItemService     
,PayoutAdminPrincipalScoreRatioItemService     
,PayoutAdminWeightedPerformanceRatioItemService     
,PayoutConfigurationItemService     
,PayoutObservationAchievementPayRatiosItemService     
,PayoutObservationDomainWeightsItemService     
,PayoutObservationNonAchievementPayRatiosItemService     
,PayoutObserverWeightsItemService     
,PayoutSchoolAchievementPayRatiosItemService     
,PayoutSchoolItemService     
,PayoutStudentAchievementPayRatiosItemService     
,PayoutTeacherItemService     
,DiscountCodeValidItemService     
,DiscountItemService     
,InvoiceItemService     
,InvoiceRefundItemService     
,SalesTaxItemService     
,ScheduledInvoiceCancelItemService     
,ScheduledInvoiceItemService     
,ScheduledInvoiceSyncItemService     
,SubscriptionItemService     
,TransactionDetailItemService     
,TransactionItemService     
,WebhookInvoiceItemService     
,ClusterItemService     
,UserClusterMapItemService     
,DistrictItemService     
,NotificationItemService     
,SchoolItemService     
,SchoolYearItemService     
,SchoolYearUserItemService     
,CertificationAnswerItemService     
,CertificationContentRubricRatingItemService     
,CertificationItemService     
,CertificationQuestionItemService     
,CertificationQuestionMapItemService     
,CertificationRubricRatingItemService     
,GeneralSurveyItemService     
,ObservationConfigItemService     
,UserPositionRubricMapItemService     
,UserPositionAlternativeMapItemService     
,UserPositionSurveyTemplateMapItemService     
,ObservationItemService     
,ObservationOptionItemService     
,SurveyContentItemService     
,SurveyItemService     
,SurveyLikertMapItemService     
,SurveyRatingItemService     
,SurveySectionItemService     
,SurveyTemplateFieldItemService     
,SurveyTemplateItemService     
,UserSurveyMapItemService     
,AppItemService     
,ConfigItemService     
,DynamicFormDataItemService     
,DynamicFormItemService     
,EmailItemService     
,EmailTemplateItemService     
,EventDefinitionItemService     
,EventItemService     
,FeedbackItemService     
,LogItemService     
,TagItemService     
,UIMetadataItemService     
,UITestItemService     
,FileImportExportItemService     
,FileItemService     
,MediaItemService     
,ContentAssociationItemService     
,ContentItemService     
,ContentRatingItemService     
,ContentShareItemService     
,ContentSyncItemService     
,ContentTypeItemService     
,ClientItemService     
,PermissionItemService     
,PermissionSettingItemService     
,RoleItemService     
,TOSItemService     
,UserPermissionItemService     
,UserProfileEmailItemService     
,UserProfileItemService} from './ItemServices';

export class ItemServiceFactory {


static ItemServiceEnum = {
    RubricDomain:"RubricDomain",
    RubricDomainMap:"RubricDomainMap",
    RubricIndicator:"RubricIndicator",
    RubricIndicatorMap:"RubricIndicatorMap",
    RubricIndicatorRatingMap:"RubricIndicatorRatingMap",
    Rubric:"Rubric",
    RubricRating:"RubricRating",
    RubricRatingMap:"RubricRatingMap",
    PayoutAchievementWeights:"PayoutAchievementWeights",
    PayoutAdminAssistantPrincipalScoreRatio:"PayoutAdminAssistantPrincipalScoreRatio",
    PayoutAdminConfiguration:"PayoutAdminConfiguration",
    PayoutAdminConfigurationMetric:"PayoutAdminConfigurationMetric",
    PayoutAdmin:"PayoutAdmin",
    PayoutAdminObservationDomainWeights:"PayoutAdminObservationDomainWeights",
    PayoutAdminObserverWeights:"PayoutAdminObserverWeights",
    PayoutAdminPrincipalScoreRatio:"PayoutAdminPrincipalScoreRatio",
    PayoutAdminWeightedPerformanceRatio:"PayoutAdminWeightedPerformanceRatio",
    PayoutConfiguration:"PayoutConfiguration",
    PayoutObservationAchievementPayRatios:"PayoutObservationAchievementPayRatios",
    PayoutObservationDomainWeights:"PayoutObservationDomainWeights",
    PayoutObservationNonAchievementPayRatios:"PayoutObservationNonAchievementPayRatios",
    PayoutObserverWeights:"PayoutObserverWeights",
    PayoutSchoolAchievementPayRatios:"PayoutSchoolAchievementPayRatios",
    PayoutSchool:"PayoutSchool",
    PayoutStudentAchievementPayRatios:"PayoutStudentAchievementPayRatios",
    PayoutTeacher:"PayoutTeacher",
    DiscountCodeValid:"DiscountCodeValid",
    Discount:"Discount",
    Invoice:"Invoice",
    InvoiceRefund:"InvoiceRefund",
    SalesTax:"SalesTax",
    ScheduledInvoiceCancel:"ScheduledInvoiceCancel",
    ScheduledInvoice:"ScheduledInvoice",
    ScheduledInvoiceSync:"ScheduledInvoiceSync",
    Subscription:"Subscription",
    TransactionDetail:"TransactionDetail",
    Transaction:"Transaction",
    WebhookInvoice:"WebhookInvoice",
    Cluster:"Cluster",
    UserClusterMap:"UserClusterMap",
    District:"District",
    Notification:"Notification",
    School:"School",
    SchoolYear:"SchoolYear",
    SchoolYearUser:"SchoolYearUser",
    CertificationAnswer:"CertificationAnswer",
    CertificationContentRubricRating:"CertificationContentRubricRating",
    Certification:"Certification",
    CertificationQuestion:"CertificationQuestion",
    CertificationQuestionMap:"CertificationQuestionMap",
    CertificationRubricRating:"CertificationRubricRating",
    GeneralSurvey:"GeneralSurvey",
    ObservationConfig:"ObservationConfig",
    UserPositionRubricMap:"UserPositionRubricMap",
    UserPositionAlternativeMap:"UserPositionAlternativeMap",
    UserPositionSurveyTemplateMap:"UserPositionSurveyTemplateMap",
    Observation:"Observation",
    ObservationOption:"ObservationOption",
    SurveyContent:"SurveyContent",
    Survey:"Survey",
    SurveyLikertMap:"SurveyLikertMap",
    SurveyRating:"SurveyRating",
    SurveySection:"SurveySection",
    SurveyTemplateField:"SurveyTemplateField",
    SurveyTemplate:"SurveyTemplate",
    UserSurveyMap:"UserSurveyMap",
    App:"App",
    Config:"Config",
    DynamicFormData:"DynamicFormData",
    DynamicForm:"DynamicForm",
    Email:"Email",
    EmailTemplate:"EmailTemplate",
    EventDefinition:"EventDefinition",
    Event:"Event",
    Feedback:"Feedback",
    Log:"Log",
    Tag:"Tag",
    UIMetadata:"UIMetadata",
    UITest:"UITest",
    FileImportExport:"FileImportExport",
    File:"File",
    Media:"Media",
    ContentAssociation:"ContentAssociation",
    Content:"Content",
    ContentRating:"ContentRating",
    ContentShare:"ContentShare",
    ContentSync:"ContentSync",
    ContentType:"ContentType",
    Client:"Client",
    Permission:"Permission",
    PermissionSetting:"PermissionSetting",
    Role:"Role",
    TOS:"TOS",
    UserPermission:"UserPermission",
    UserProfileEmail:"UserProfileEmail",
    UserProfile:"UserProfile",
}

static GetItemService(itemServiceName, authState){
    let itemService;
    switch(itemServiceName.toLowerCase()){
        case ItemServiceFactory.ItemServiceEnum.RubricDomain.toLowerCase():
             itemService = new RubricDomainItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.RubricDomainMap.toLowerCase():
             itemService = new RubricDomainMapItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.RubricIndicator.toLowerCase():
             itemService = new RubricIndicatorItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.RubricIndicatorMap.toLowerCase():
             itemService = new RubricIndicatorMapItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.RubricIndicatorRatingMap.toLowerCase():
             itemService = new RubricIndicatorRatingMapItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Rubric.toLowerCase():
             itemService = new RubricItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.RubricRating.toLowerCase():
             itemService = new RubricRatingItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.RubricRatingMap.toLowerCase():
             itemService = new RubricRatingMapItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutAchievementWeights.toLowerCase():
             itemService = new PayoutAchievementWeightsItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutAdminAssistantPrincipalScoreRatio.toLowerCase():
             itemService = new PayoutAdminAssistantPrincipalScoreRatioItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutAdminConfiguration.toLowerCase():
             itemService = new PayoutAdminConfigurationItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutAdminConfigurationMetric.toLowerCase():
             itemService = new PayoutAdminConfigurationMetricItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutAdmin.toLowerCase():
             itemService = new PayoutAdminItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutAdminObservationDomainWeights.toLowerCase():
             itemService = new PayoutAdminObservationDomainWeightsItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutAdminObserverWeights.toLowerCase():
             itemService = new PayoutAdminObserverWeightsItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutAdminPrincipalScoreRatio.toLowerCase():
             itemService = new PayoutAdminPrincipalScoreRatioItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutAdminWeightedPerformanceRatio.toLowerCase():
             itemService = new PayoutAdminWeightedPerformanceRatioItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutConfiguration.toLowerCase():
             itemService = new PayoutConfigurationItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutObservationAchievementPayRatios.toLowerCase():
             itemService = new PayoutObservationAchievementPayRatiosItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutObservationDomainWeights.toLowerCase():
             itemService = new PayoutObservationDomainWeightsItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutObservationNonAchievementPayRatios.toLowerCase():
             itemService = new PayoutObservationNonAchievementPayRatiosItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutObserverWeights.toLowerCase():
             itemService = new PayoutObserverWeightsItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutSchoolAchievementPayRatios.toLowerCase():
             itemService = new PayoutSchoolAchievementPayRatiosItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutSchool.toLowerCase():
             itemService = new PayoutSchoolItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutStudentAchievementPayRatios.toLowerCase():
             itemService = new PayoutStudentAchievementPayRatiosItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PayoutTeacher.toLowerCase():
             itemService = new PayoutTeacherItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.DiscountCodeValid.toLowerCase():
             itemService = new DiscountCodeValidItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Discount.toLowerCase():
             itemService = new DiscountItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Invoice.toLowerCase():
             itemService = new InvoiceItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.InvoiceRefund.toLowerCase():
             itemService = new InvoiceRefundItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.SalesTax.toLowerCase():
             itemService = new SalesTaxItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.ScheduledInvoiceCancel.toLowerCase():
             itemService = new ScheduledInvoiceCancelItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.ScheduledInvoice.toLowerCase():
             itemService = new ScheduledInvoiceItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.ScheduledInvoiceSync.toLowerCase():
             itemService = new ScheduledInvoiceSyncItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Subscription.toLowerCase():
             itemService = new SubscriptionItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.TransactionDetail.toLowerCase():
             itemService = new TransactionDetailItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Transaction.toLowerCase():
             itemService = new TransactionItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.WebhookInvoice.toLowerCase():
             itemService = new WebhookInvoiceItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Cluster.toLowerCase():
             itemService = new ClusterItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.UserClusterMap.toLowerCase():
             itemService = new UserClusterMapItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.District.toLowerCase():
             itemService = new DistrictItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Notification.toLowerCase():
             itemService = new NotificationItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.School.toLowerCase():
             itemService = new SchoolItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.SchoolYear.toLowerCase():
             itemService = new SchoolYearItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.SchoolYearUser.toLowerCase():
             itemService = new SchoolYearUserItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.CertificationAnswer.toLowerCase():
             itemService = new CertificationAnswerItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.CertificationContentRubricRating.toLowerCase():
             itemService = new CertificationContentRubricRatingItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Certification.toLowerCase():
             itemService = new CertificationItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.CertificationQuestion.toLowerCase():
             itemService = new CertificationQuestionItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.CertificationQuestionMap.toLowerCase():
             itemService = new CertificationQuestionMapItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.CertificationRubricRating.toLowerCase():
             itemService = new CertificationRubricRatingItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.GeneralSurvey.toLowerCase():
             itemService = new GeneralSurveyItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.ObservationConfig.toLowerCase():
             itemService = new ObservationConfigItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.UserPositionRubricMap.toLowerCase():
             itemService = new UserPositionRubricMapItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.UserPositionAlternativeMap.toLowerCase():
             itemService = new UserPositionAlternativeMapItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.UserPositionSurveyTemplateMap.toLowerCase():
             itemService = new UserPositionSurveyTemplateMapItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Observation.toLowerCase():
             itemService = new ObservationItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.ObservationOption.toLowerCase():
             itemService = new ObservationOptionItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.SurveyContent.toLowerCase():
             itemService = new SurveyContentItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Survey.toLowerCase():
             itemService = new SurveyItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.SurveyLikertMap.toLowerCase():
             itemService = new SurveyLikertMapItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.SurveyRating.toLowerCase():
             itemService = new SurveyRatingItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.SurveySection.toLowerCase():
             itemService = new SurveySectionItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.SurveyTemplateField.toLowerCase():
             itemService = new SurveyTemplateFieldItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.SurveyTemplate.toLowerCase():
             itemService = new SurveyTemplateItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.UserSurveyMap.toLowerCase():
             itemService = new UserSurveyMapItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.App.toLowerCase():
             itemService = new AppItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Config.toLowerCase():
             itemService = new ConfigItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.DynamicFormData.toLowerCase():
             itemService = new DynamicFormDataItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.DynamicForm.toLowerCase():
             itemService = new DynamicFormItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Email.toLowerCase():
             itemService = new EmailItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.EmailTemplate.toLowerCase():
             itemService = new EmailTemplateItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.EventDefinition.toLowerCase():
             itemService = new EventDefinitionItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Event.toLowerCase():
             itemService = new EventItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Feedback.toLowerCase():
             itemService = new FeedbackItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Log.toLowerCase():
             itemService = new LogItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Tag.toLowerCase():
             itemService = new TagItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.UIMetadata.toLowerCase():
             itemService = new UIMetadataItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.UITest.toLowerCase():
             itemService = new UITestItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.FileImportExport.toLowerCase():
             itemService = new FileImportExportItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.File.toLowerCase():
             itemService = new FileItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Media.toLowerCase():
             itemService = new MediaItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.ContentAssociation.toLowerCase():
             itemService = new ContentAssociationItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Content.toLowerCase():
             itemService = new ContentItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.ContentRating.toLowerCase():
             itemService = new ContentRatingItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.ContentShare.toLowerCase():
             itemService = new ContentShareItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.ContentSync.toLowerCase():
             itemService = new ContentSyncItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.ContentType.toLowerCase():
             itemService = new ContentTypeItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Client.toLowerCase():
             itemService = new ClientItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Permission.toLowerCase():
             itemService = new PermissionItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.PermissionSetting.toLowerCase():
             itemService = new PermissionSettingItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.Role.toLowerCase():
             itemService = new RoleItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.TOS.toLowerCase():
             itemService = new TOSItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.UserPermission.toLowerCase():
             itemService = new UserPermissionItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.UserProfileEmail.toLowerCase():
             itemService = new UserProfileEmailItemService(authState);
             break;
        case ItemServiceFactory.ItemServiceEnum.UserProfile.toLowerCase():
             itemService = new UserProfileItemService(authState);
             break;
        default:
            break;
        }
        return itemService;
    }

static ValidateItemName(itemName) {
                                      let isValid = false;
                                        for (const key in ItemServiceFactory.ItemServiceEnum){

                                            if (typeof(ItemServiceFactory.ItemServiceEnum[key]) === "string" &&
                                               ItemServiceFactory.ItemServiceEnum[key].toLowerCase() == itemName.toLowerCase())
                                            {
                                                isValid = true;
                                                break;
                                            }
                                        }
                                        return isValid;
                                    }
}
