import { BaseService } from './base/BaseService';
export class StatusCheckItemService extends BaseService {
    constructor(authState) {
        super("Status/Check", authState);
    }

    CheckStatus() {
        return super.operation(null, "CheckStatus");
    }
}

export class PermissionMapService extends BaseService {
    constructor(globalAuthState) {
        super("Permissions/PermissionsMap", globalAuthState);
    }

    GetPermissionsMap() {
        return super.operation(null, "PermissionsMap");
    }
}

export class AuthPermissionService extends BaseService {
    constructor(authState) {
        super("Permissions/AuthPermissions", authState);
    }

    GetAuthPermissions() {
        return super.operation(null, "AuthPermissions");
    }
}

