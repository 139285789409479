import React, { useEffect, useState } from "react";

import { useAppUserManager, useRoleManager } from "../../../hooks/useManagers";
import { useAuthState } from "../../../hooks/useAuth";
import ProfileDetails from "../components/ProfileDetails";
import PreferencesDetails from "../components/PreferencesDetails";
import AccountSettingsDetails from "../components/AccountSettingsDetails";
import MainLayout from "../../../components/layout/MainLayout";
import ScreenWrapper from "../../../components/layout/screenWrapper";

import "./ProfileManagement.scss";

const ProfileManagement = () => {
  const appUserMgr = useAppUserManager();
  const authState = useAuthState();
  const roleMgr = useRoleManager();

  const [displayName, setDisplayName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [email, setEmail] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [organization, setOrganization] = useState("");
  const [district, setDistrict] = useState("");
  const [school, setSchool] = useState("");
  const [roles, setRoles] = useState(null);
  const [allRoles, setAllRoles] = useState(null);
  const [clusters, setClusters] = useState("");
  const [eePassId, setEEPassId] = useState("");
  const [timeZoneAbbreviation, setTimeZoneAbbreviation] = useState("");
  const [passwordResetOn, setPasswordResetOn] = useState("");
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);
  const [errors, setErrors] = useState(null);
  const [jobTitle, setJobTitle] = useState("");

  const globalConfig = appUserMgr?.GetGlobalState();
  const userProfile = appUserMgr?.GetGlobalState()?.userProfile;
  const idTokenClaims = appUserMgr?.GetGlobalAuthState()?.idTokenClaims;
  const [loading, setLoading] = useState(true);

  const formatPhone = (phoneNumber) => {
    let formattedPhoneNumber = phoneNumber.replace(/^0+/, "");
    formattedPhoneNumber = formattedPhoneNumber.replace(/[^0-9]/, "");

    if (formattedPhoneNumber.length === 11) {
      if (formattedPhoneNumber.substr(0, 1) === "1") {
        return (
          `(${formattedPhoneNumber.substr(1, 3)}) ` +
          formattedPhoneNumber.substr(4, 3) +
          "-" +
          formattedPhoneNumber.substr(7, 4)
        );
      } else {
        return (
          `+${formattedPhoneNumber.substr(0, 2)} ` +
          `(${formattedPhoneNumber.substr(2, 3)}) ` +
          formattedPhoneNumber.substr(5, 3) +
          "-" +
          formattedPhoneNumber.substr(8, 3)
        );
      }
    }

    return phoneNumber;
  };

  useEffect(() => {
    if (
      userProfile &&
      authState &&
      authState?.isAuthenticated &&
      authState?.idTokenClaims
    ) {
      const userPostionAlternative = appUserMgr?.AppUserState?.observationConfig.UserPositionAlternativeMap.find(x => x.UserPosition === userProfile.UserPositionType);
      if (userPostionAlternative?.Name)
        setJobTitle(userPostionAlternative.Name);
      else
        setJobTitle("");

      setDisplayName(idTokenClaims.extension_LastName + ", " + idTokenClaims.extension_FirstName);
      setProfileImage(userProfile?.File ?? "");
      setEmail(idTokenClaims.emails.first());
      setMobilePhone(
        formatPhone(idTokenClaims.extension_Mobile) ?? "(not set)"
      );
      setOrganization(
        userProfile?.Organizations.length === 0 ? "None" : globalConfig?.OrganizationNames
      );
      setDistrict(
        userProfile?.Districts.length === 0 ? "None" : globalConfig?.DistrictNames
      );
      setSchool(
        userProfile?.Schools.length === 0 ? "None" : globalConfig?.SchoolNames
      );

      setClusters(
        userProfile?.Clusters.length === 0 ? "None" : globalConfig?.ClusterNames
      );

      if (!allRoles) {
        const currentRoles = idTokenClaims.extension_Roles
          ? JSON.parse(idTokenClaims.extension_Roles)
          : [];
        roleMgr.query('x => x.IsDeleted == false && x.IsActive == true', undefined, true).then(x => {
          if (x.Success) {
            setRoles(x.Items.filter(r => currentRoles.includes(r.Role)).map(val => val.Name))
          }
          else {
            setRoles(currentRoles);
          }
        });
      }

      setEEPassId(userProfile.SystemId ?? "");
      setTimeZoneAbbreviation(
        userProfile?.TimeZone ?? ""
      );
      setPasswordResetOn(idTokenClaims.extension_PasswordResetOn ?? "");
      setIsMfaEnabled(idTokenClaims.extension_IsMfaEnabled ?? false);
      setLoading(false);
    }
  }, [
    appUserMgr,
    userProfile,
    authState,
    idTokenClaims,
    setDisplayName,
    setEmail,
    globalConfig?.SchoolNames,
    globalConfig?.DistrictNames,
    globalConfig?.OrganizationNames,
    globalConfig?.CulsterNames
  ]);

  const setProfileUpdateType = (profileUpdateType) => {
    sessionStorage.setItem("profile-update-type", profileUpdateType);
  };

  const handleProfileImage = (profileImage) => {
    if (profileImage !== undefined) {
      setProfileImage(profileImage);
      let item = { ...userProfile };
      item.File = profileImage;
      item.FileId = profileImage.FileId;
      handleOnSave(item);
    }
    else {
      setProfileImage(undefined);
      let item = { ...userProfile };
      item.File = null;
      item.FileId = null;
      handleOnSave(item);
    }
  };

  const handleOnSave = async (item) => {
    var result = await appUserMgr._userProfileItemMgr.saveItem(item);
    return result;

  }

  return (
    <>
      <div className="profile">
        <MainLayout errors={errors}>
          <ScreenWrapper loading={loading}>
            <div className="control-box-wrapper">
              <div className="control-box box-two-column first-column">
                <div className="profile__details">
                  <ProfileDetails
                    displayName={displayName}
                    profileImage={profileImage}
                    email={email}
                    mobilePhone={mobilePhone}
                    organization={organization}
                    district={district}
                    school={school}
                    cluster={clusters}
                    setErrors={setErrors}
                    jobTitle={jobTitle}
                    roles={roles}
                    setProfileUpdateType={setProfileUpdateType}
                    handleProfileImage={handleProfileImage}
                  />
                </div>
              </div>
              <div className="control-box box-two-column second-column">
                <div className="account-settings__details">
                  <AccountSettingsDetails
                    eePassId={eePassId}
                    timeZoneAbbreviation={timeZoneAbbreviation}
                    passwordResetOn={passwordResetOn}
                    mobilePhone={mobilePhone}
                    isMfaEnabled={isMfaEnabled}
                    setProfileUpdateType={setProfileUpdateType}
                  />
                  {appUserMgr?.canView("Content") && <PreferencesDetails />}
                </div>
              </div>
            </div>
          </ScreenWrapper>
        </MainLayout>
      </div>
    </>
  );
};

export default ProfileManagement;
