import React, { useState } from 'react';
import { useEffect } from "react";
import '../../../../components/controls/TableControl/TableControl.scss';
import './transactionDetailsTable.scss';
import transactionDetailStatusTypes from '../../../dev/data/transactionDetailStatusType'; 
import ButtonControl from "../../../../components/controls/ButtonControl";

const TransactionDetailsTable = ({ data, subscription, onEdit, onLink, onDelete, onRefund }) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columns] = useState([
        {
            header: 'Item Type',
            dataProp: "itemType",
            width: '19',
        },
        {
            header: 'Item Name',
            dataProp: "itemName",
            width: '22',
        },
        {
            header: 'Total Amount',
            dataProp: "amount",
            width: '17',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'Status',
            dataProp: "status",
            width: '16',
            canSort: true
        },
        {
            header: 'Payment Date',
            dataProp: "paymentDate",
            width: '18',
            canSort: true
        },
        {
            header: 'Actions',
            dataProp: "actions",
            width: '17',
            canSort: true
        }
    ])

    useEffect(() => {
        let row = [];
        if (data && subscription) {  
            if(data.TransactionDetails) {
                let transactionDetails = data.TransactionDetails;
                let paymentDateFormat = transactionDetails?.PaymentDate ? getFormatedDate(transactionDetails.PaymentDate) : "";
                let transactionType = transactionDetails?.TransactionType;
                row.push({
                    itemType: data.ItemType,
                    itemName: subscription.Name,
                    amount: '$' + transactionDetails.TotalAmount.toLocaleString(undefined, { minimumFractionDigits: 2 }),
                    status: transactionType === 2 ? 'Refunded' : transactionDetailStatusTypes.find(x => x.value === transactionDetails?.Status)?.label,
                    paymentDate: paymentDateFormat,
                    id: transactionDetails?.Id
                });

                //Add 'next payment' row
                if(data.Status === 1) { //active
                    row.push({
                        itemType: data.ItemType,
                        itemName: subscription.Name,
                        amount: '$' + transactionDetails.TotalAmount.toLocaleString(undefined, { minimumFractionDigits: 2 }),
                        status: 'Pending',
                        paymentDate: getFormatedDate(transactionDetails.PaymentDate, true)
                    });
                }
                else {
                    row.push({
                        itemType: data.ItemType,
                        itemName: subscription.Name,
                        amount: '',
                        status: 'Inactive',
                        paymentDate: getFormatedDate(transactionDetails.PaymentDate, true)
                    });
                } 
               
            }
            else {
                row.push({
                    itemType: data.ItemType,
                    itemName: subscription.Name,
                    amount: '$' + data.Price,
                    status: 'Pending',
                    paymentDate: getFormatedDate(data.CreatedDate)
                });
            }
            setRows(row);
        }
    }, [data, subscription]);

    const getFormatedDate = (dateInput, addMonth) => {

        let date = new Date(dateInput);
        var year = date.getFullYear();
        var month = (1 + date.getMonth());

        //var month = (1 + date.getMonth()).toString();
        if(addMonth) {
            if(month === 12) {
                month = 1;
                year += 1;
            }
            else {
                month += 1;
            }
        }

        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        
        return month + '/' + day + '/' + year;
    }

    const renderHeader = () => {
        let jsxCols = columns.reduce((r, col, i) => {
                r.push(
                    <div key={`detailsHeader_${i} ` + col.header + '_' + i} className={`col`} style={{ width: col.width + '%'}}>
                        {col.header}
                    </div>
                )
            return r;
        }, []);

        return <div className={'header-row'}>{jsxCols}</div>
    };

    const renderRows = () => {
        let dataRows = [];

        for (let i = 0; i < rows.length; i++) {
            let dataRecord = rows[i];
            dataRows.push(
                <div key={`detailsTable_${i}`} className={`data-row data-row-indicator detail-row`}>
                    {columns.reduce((r, col) => {

                        let dataRowValue = dataRecord[col.dataProp];

                        r.push( 
                        <>
                            {col.dataProp === 'actions' && dataRecord.status === 'Paid' ?
                            <>          
                                <div key={`${col.header}_${dataRowValue}`} style={{ width: `${col.width.length > 0 ? col.width : 100 / (columns.length + 1)}%` }}
                                    className={`col`}>
                                        <ButtonControl className={'refund-button'} onClick={() => onRefund(dataRecord)}>Refund</ButtonControl>
                                </div>
                            </>
                            :
                            <>
                            <div key={`${col.header}_${dataRowValue}`} style={{ width: `${col.width.length > 0 ? col.width : 100 / (columns.length + 1)}%` }}
                                className={`col`}>
                                {dataRowValue}
                            </div>
                            </>
                            }
                        </>
                        )
                            return r;
                    }, [])}
                </div>
            )          
        }

        let rowAreaStyle = {};
        return (
            <div className={'row-area'} style={rowAreaStyle}>
                {dataRows}
            </div>
        );
    };

    return <>
        <div className='title-header'>
            <div className={`title`}><div>Transaction Details</div></div>
        </div>
        <div className='table-wrapper'>
            {renderHeader()}
            {renderRows()}
        </div>
    </>
}

export default TransactionDetailsTable;