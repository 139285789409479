import { ItemServiceFactory } from "../services/ItemServiceFactory";
import ItemManager from "./ItemManager";

class ReportManager extends ItemManager {
  constructor(
    orgManager,
    clusterManager,
    schoolYearManager,
    userManager,
    globalItemState,
    globalAuthState,
    globalAppUserState
  ) {
    super(
      ItemServiceFactory.ItemServiceEnum.Cluster,
      globalAuthState,
      globalAppUserState,
      globalItemState,
      () => {}
    );

    this._errorUtil = undefined;
    this._orgManager = orgManager;
    this._clusterManager = clusterManager;
    this._schoolYearManager = schoolYearManager;
    this._userManager = userManager;
    this._setReportParameters = (value) =>
      globalItemState.merge({ reportParameters: value });
  }

  get ReportParameters() {
    return this._globalItemState?.get({ noproxy: true }).reportParameters;
  }

  get OrgManager() {
    return this._orgManager;
  }

  get ClusterManager() {
    return this._clusterManager;
  }

  get SchoolYearManager() {
    return this._schoolYearManager;
  }

  get UserManager() {
    return this._userManager;
  }

  updateReportParameters = (reportParameters, reportUrl) => {
    if(reportUrl)
      reportParameters.reportUrl = reportUrl;
    this._setReportParameters(reportParameters);
  };
}

export default ReportManager;
