import { useHookstate } from '@hookstate/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Login from '../../components/security/Login';
import { useCheckAuth } from '../../hooks/useAuth';
import { useAppUserManager } from '../../hooks/useManagers';
import globalAppUserStore, { APIStatusType } from '../../stores/globalAppUserStore';
import globalAuthStateStore from '../../stores/globalAuthStateStore';
import AccessDeny from './AccessDeny';


const SecureRoute = ({ permission, component: RouteComponent }) => {
  const isAuthenticated = useCheckAuth();
  const globalAppUserState = useHookstate(globalAppUserStore);
  const appUserState = globalAppUserState.get();
  const authMgr = useAppUserManager();
  const location = useLocation();
  const params = useParams()
  if (!isAuthenticated) {
    return <Login />;
  } else if (appUserState.apiStatus === APIStatusType.OnlineSecure && authMgr) {
    if (authMgr?.checkRoute(permission, location.pathname)) {
      return <RouteComponent globalAppUserState={globalAppUserState} authMgr={authMgr} {...params} />;
    } else return <AccessDeny />
  }

};

export default SecureRoute;
