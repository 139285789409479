import React, { useState } from 'react';
import moment from "moment";
import '../../rubricTable.scss'
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { asyncForEach } from "../../../contentLibrary/utils/jsUtil";
import ButtonControl from '../../../../components/controls/ButtonControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import StatusTypes from './StatusTypes.js';
import EvaluationTypes from './EvaluationTypes.js';

const RubricTable = ({ filteredContent, organizations, onView, onEdit, onDelete, onPrint, onDuplicate }) => {
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Name',
            dataProp: "name",
            width: '21%',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'Evaluation Type',
            dataProp: "evaluationType",
            width: '15%',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'School Year',
            dataProp: "schoolYear",
            width: '10%',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'Organization',
            dataProp: "organization",
            width: '18%',
            canSort: true,
            leftAlign: true
        },
        {
            header: 'Created Date',
            dataProp: 'CreatedDate',
            datatype: 'date',
            width: '12%',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'Status',
            dataProp: "status",
            width: '10%',
            leftAlign: true,
            canSort: true
        }
    ])

    useEffect(() => {
        let newRows = [];
        if (filteredContent && organizations) {
            asyncForEach(filteredContent, (c) => {
                let evalType = getEvalType(c.RubricType);
                let status = getStatus(c.Status);
                let orgName = getOrganizationName(c.ClientId);
                newRows.push({
                    ...c,
                    name: c.Name,
                    evaluationType: evalType,
                    schoolYear: c.SchoolYear.Title,
                    organization: orgName, //TODO: Client name needs to be part of payload returned, or fix Get Client list call
                    date: c.UpdatedDate,
                    status: status
                });
            }).then(() => {
                setRows(newRows);
            });
        }
    }, [filteredContent, organizations]);

    const getEvalType = (evalType) => {
        for (const [key, value] of Object.entries(EvaluationTypes[0])) {
            if (key === evalType.toString()) {
                return value;
            }
        }
    }

    const getStatus = (status) => {
        for (const [key, value] of Object.entries(StatusTypes[0])) {
            if (key === status.toString()) {
                return value;
            }
        }
    }

    const getOrganizationName = (clientId) => {
        let org = organizations.find(x => x.value === clientId);

        if (org) {
            return org.label;
        } else {
            return 'System';
        }
    }

    const handleCheckDisableAction = (row, action) => {
        if (action === 'delete') {
            return row.status === 'Published' || row.status === 'Archived';
        }
        else if (action === 'edit') {
            return row.status === 'Archived';
        }
        else {
            return false;
        }
    }

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                useIntegratedPaging={['10', '25', '50', '100']}
                checkDisableAction={handleCheckDisableAction}
                onView={onView}
                onEdit={onEdit}
                onDelete={onDelete}
                onPrint={onPrint}
                onDuplicate={onDuplicate}
            />
            {/* <span>
                <FontAwesomeIcon icon={faCirclePlus} className="plus-icon-rubric" onClick={onCreateRubric} />
            </span> */}
        </div>
        {/* <div className="filterControl">

        </div> */}
    </>
}

export default RubricTable;