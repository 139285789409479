import React from 'react';
import NFSlider from './components/NetflixSlider'


export default function Slider({ contents, categoryName, onLike, onView, onShare, onDownload, containerRef, onFullScreen }) {

    const elementRef = React.useRef();
    return (
        <>
            {contents != null && contents.length > 0 && (
                <div ref={containerRef} className="categoryWrapper">
                    <h3 className="categoryTitle">{categoryName}</h3>
                    <NFSlider onLike={onLike} onDownload={onDownload} onView={onView} onShare={onShare} onFullScreen={onFullScreen}>
                        {contents.map((c, i) => (
                            <NFSlider.Item key={`slider_item_${i}`} content={c} id={c.KeyId} rowKey={elementRef}>item1</NFSlider.Item>
                        ))}
                    </NFSlider>
                    <div ref={elementRef}></div>
                </div>)}
        </>
    );
}