/*
    CAUTION: PLEASE READ! This File was auto generated by the system.
    Any changes you make will be removed the next time it's generated.
*/
import { ItemService } from '../services/base/ItemService';

export class RubricDomainItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/RubricDomain", authState, "CORE");
    }

}

export class RubricDomainMapItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/RubricDomainMap", authState, "CORE");
    }

}

export class RubricIndicatorItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/RubricIndicator", authState, "CORE");
    }

}

export class RubricIndicatorMapItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/RubricIndicatorMap", authState, "CORE");
    }

}

export class RubricIndicatorRatingMapItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/RubricIndicatorRatingMap", authState, "CORE");
    }

}

export class RubricItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Rubric", authState, "CORE");
    }

}

export class RubricRatingItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/RubricRating", authState, "CORE");
    }

}

export class RubricRatingMapItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/RubricRatingMap", authState, "CORE");
    }

}

export class PayoutAchievementWeightsItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutAchievementWeights", authState, "CORE");
    }

}

export class PayoutAdminAssistantPrincipalScoreRatioItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutAdminAssistantPrincipalScoreRatio", authState, "CORE");
    }

}

export class PayoutAdminConfigurationItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutAdminConfiguration", authState, "CORE");
    }

}

export class PayoutAdminConfigurationMetricItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutAdminConfigurationMetric", authState, "CORE");
    }

}

export class PayoutAdminItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutAdmin", authState, "CORE");
    }

}

export class PayoutAdminObservationDomainWeightsItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutAdminObservationDomainWeights", authState, "CORE");
    }

}

export class PayoutAdminObserverWeightsItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutAdminObserverWeights", authState, "CORE");
    }

}

export class PayoutAdminPrincipalScoreRatioItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutAdminPrincipalScoreRatio", authState, "CORE");
    }

}

export class PayoutAdminWeightedPerformanceRatioItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutAdminWeightedPerformanceRatio", authState, "CORE");
    }

}

export class PayoutConfigurationItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutConfiguration", authState, "CORE");
    }

}

export class PayoutObservationAchievementPayRatiosItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutObservationAchievementPayRatios", authState, "CORE");
    }

}

export class PayoutObservationDomainWeightsItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutObservationDomainWeights", authState, "CORE");
    }

}

export class PayoutObservationNonAchievementPayRatiosItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutObservationNonAchievementPayRatios", authState, "CORE");
    }

}

export class PayoutObserverWeightsItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutObserverWeights", authState, "CORE");
    }

}

export class PayoutSchoolAchievementPayRatiosItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutSchoolAchievementPayRatios", authState, "CORE");
    }

}

export class PayoutSchoolItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutSchool", authState, "CORE");
    }

}

export class PayoutStudentAchievementPayRatiosItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutStudentAchievementPayRatios", authState, "CORE");
    }

}

export class PayoutTeacherItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PayoutTeacher", authState, "CORE");
    }

}

export class DiscountCodeValidItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/DiscountCodeValid", authState, "CORE");
    }

}

export class DiscountItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Discount", authState, "CORE");
    }

}

export class InvoiceItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Invoice", authState, "CORE");
    }

}

export class InvoiceRefundItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/InvoiceRefund", authState, "CORE");
    }

}

export class SalesTaxItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/SalesTax", authState, "CORE");
    }

}

export class ScheduledInvoiceCancelItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/ScheduledInvoiceCancel", authState, "CORE");
    }

}

export class ScheduledInvoiceItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/ScheduledInvoice", authState, "CORE");
    }

}

export class ScheduledInvoiceSyncItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/ScheduledInvoiceSync", authState, "CORE");
    }

}

export class SubscriptionItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Subscription", authState, "CORE");
    }

}

export class TransactionDetailItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/TransactionDetail", authState, "CORE");
    }

}

export class TransactionItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Transaction", authState, "CORE");
    }

}

export class WebhookInvoiceItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/WebhookInvoice", authState, "CORE");
    }

}

export class ClusterItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Cluster", authState, "CORE");
    }

}

export class UserClusterMapItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/UserClusterMap", authState, "CORE");
    }

}

export class DistrictItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/District", authState, "CORE");
    }

}

export class NotificationItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Notification", authState, "CORE");
    }

}

export class SchoolItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/School", authState, "CORE");
    }

}

export class SchoolYearItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/SchoolYear", authState, "CORE");
    }

}

export class SchoolYearUserItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/SchoolYearUser", authState, "CORE");
    }

}

export class CertificationAnswerItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/CertificationAnswer", authState, "CORE");
    }

}

export class CertificationContentRubricRatingItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/CertificationContentRubricRating", authState, "CORE");
    }

}

export class CertificationItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Certification", authState, "CORE");
    }

}

export class CertificationQuestionItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/CertificationQuestion", authState, "CORE");
    }

}

export class CertificationQuestionMapItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/CertificationQuestionMap", authState, "CORE");
    }

}

export class CertificationRubricRatingItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/CertificationRubricRating", authState, "CORE");
    }

}

export class GeneralSurveyItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/GeneralSurvey", authState, "CORE");
    }

}

export class ObservationConfigItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/ObservationConfig", authState, "CORE");
    }

}

export class UserPositionRubricMapItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/UserPositionRubricMap", authState, "CORE");
    }

}

export class UserPositionAlternativeMapItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/UserPositionAlternativeMap", authState, "CORE");
    }

}

export class UserPositionSurveyTemplateMapItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/UserPositionSurveyTemplateMap", authState, "CORE");
    }

}

export class ObservationItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Observation", authState, "CORE");
    }

}

export class ObservationOptionItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/ObservationOption", authState, "CORE");
    }

}

export class SurveyContentItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/SurveyContent", authState, "CORE");
    }

}

export class SurveyItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Survey", authState, "CORE");
    }

}

export class SurveyLikertMapItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/SurveyLikertMap", authState, "CORE");
    }

}

export class SurveyRatingItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/SurveyRating", authState, "CORE");
    }

}

export class SurveySectionItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/SurveySection", authState, "CORE");
    }

}

export class SurveyTemplateFieldItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/SurveyTemplateField", authState, "CORE");
    }

}

export class SurveyTemplateItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/SurveyTemplate", authState, "CORE");
    }

}

export class UserSurveyMapItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/UserSurveyMap", authState, "CORE");
    }

}

export class AppItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/App", authState, "CORE");
    }

}

export class ConfigItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Config", authState, "CORE");
    }

}

export class DynamicFormDataItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/DynamicFormData", authState, "CORE");
    }

}

export class DynamicFormItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/DynamicForm", authState, "CORE");
    }

}

export class EmailItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Email", authState, "CORE");
    }

}

export class EmailTemplateItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/EmailTemplate", authState, "CORE");
    }

}

export class EventDefinitionItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/EventDefinition", authState, "CORE");
    }

}

export class EventItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Event", authState, "CORE");
    }

}

export class FeedbackItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Feedback", authState, "CORE");
    }

}

export class LogItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Log", authState, "CORE");
    }

}

export class TagItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Tag", authState, "CORE");
    }

}

export class UIMetadataItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/UIMetadata", authState, "CORE");
    }

}

export class UITestItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/UITest", authState, "CORE");
    }

}

export class FileImportExportItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/FileImportExport", authState, "CORE");
    }

}

export class FileItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/File", authState, "CORE");
    }

}

export class MediaItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Media", authState, "CORE");
    }

}

export class ContentAssociationItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/ContentAssociation", authState, "CORE");
    }

}

export class ContentItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Content", authState, "CORE");
    }

}

export class ContentRatingItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/ContentRating", authState, "CORE");
    }

}

export class ContentShareItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/ContentShare", authState, "CORE");
    }

}

export class ContentSyncItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/ContentSync", authState, "CORE");
    }

}

export class ContentTypeItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/ContentType", authState, "CORE");
    }

}

export class ClientItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Client", authState, "CORE");
    }

}

export class PermissionItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Permission", authState, "CORE");
    }

}

export class PermissionSettingItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/PermissionSetting", authState, "CORE");
    }

}

export class RoleItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/Role", authState, "CORE");
    }

}

export class TOSItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/TOS", authState, "CORE");
    }

}

export class UserPermissionItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/UserPermission", authState, "CORE");
    }

}

export class UserProfileEmailItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/UserProfileEmail", authState, "CORE");
    }

}

export class UserProfileItemService extends ItemService {
    static Operations = {
    }

    constructor(authState) {
        super("Items/UserProfile", authState, "CORE");
    }

}

