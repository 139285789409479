import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { useAppUserManager } from "../../../../hooks/useManagers";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/StickyNote2';

const GeneralSurveyList = ({ filteredItems, orgs, districts, schools, onEdit, onPrint, onView, appUserMgr, handlePrintEmpty, printing }) => {
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Title',
            dataProp: 'GeneralSurveyName',
            width: '20%',
            canSort: true
        },
        {
            header: 'Rubric',
            dataProp: 'RubricName',
            width: '15%',
            canSort: true
        },
        {
            header: 'Organization, District, School',
            dataProp: 'FullOrg',
            width: '18%',
            canSort: true,
            sortBy: 'FullOrg'
        },
        {
            header: 'Start Date',
            dataProp: 'StartDate',
            datatype: 'dateTime',
            width: '12%',
            canSort: true
        },
        {
            header: 'Assigned To',
            dataProp: 'AssignedTo',
            width: '16%',
            canSort: true
        },
        {
            header: 'Assigned By',
            dataProp: 'AssignedBy',
            width: '16%',
            canSort: true
        },
        {
            header: 'Assigned By Role',
            dataProp: 'AssignedByRole',
            width: '11%',
            canSort: true
        },
        {
            header: 'Status',
            dataProp: 'DisplayStatus',
            sortBy: 'status',
            width: '8%',
            canSort: true
        },
    ]);
    const statusIdentifer = (item) => {
        if (item?.Survey?.SurveyStatusType == 1) {
            return "In Progress"
        } else if (item?.Survey?.SurveyStatusType == 2) {
            return "In Progress"
        } else if (item?.Survey?.SurveyStatusType == 3) {
            return "Submitted"
        } else return "Not Started"
    }

    const userPositionIdentifer = (value) => {
        const differentName = _.find(appUserMgr?.AppUserState.observationConfig.UserPositionAlternativeMap, x => x?.UserPosition == value && x?.Name)?.Name
        if (differentName) {
            return differentName
        }
        if (value === 40) {
            return "Master Teacher"
        } else if (value === 41) {
            return "Mentor Teacher"
        } else return "Teacher"
    }

    useEffect(() => {
        let newRows = [];
        if (filteredItems && orgs && districts && schools) {
            _.each(filteredItems, (st) => {
                let surveyedEmail = st?.Survey?.SurveyedUser?.Email;
                let org = orgs.find(x => x.OrgId === st.Survey?.SurveyedUser?.ClientId);
                let district = districts.find(x => x.Id === st.Survey?.SurveyedUser?.DistrictIds?.first());
                let school = schools.find(x => x.Id === st.Survey?.SurveyedUser?.SchoolIds?.first());

                newRows.push({
                    ...st,
                    AssignedTo: <div>{st.AssignedTo}<br /><span className='table-sub-email-survey'>{surveyedEmail}</span></div>,
                    FullOrg: <>{`${org?.Name ?? ''}`}<br />{`${district?.Name ?? ''}`}<br /> {`${school?.Name ?? ''}`}</>,
                    AssignedBy: <div>{st?.AssignedByUser?.FullName}<br /><span className='table-sub-email-survey'>{st?.AssignedByUser?.Email}</span></div>,
                    AssignedByRole:  userPositionIdentifer(st?.User?.UserPositionType),
                    status: statusIdentifer(st),
                    DisplayStatus: <>{statusIdentifer(st)}{st.GeneralSurvey?.IsActive === false || st.GeneralSurvey?.IsDeleted === true ? <><br />(Inactive)</> : null}</>
                });
            });
            setRows(newRows);
        }
    }, [filteredItems, orgs, districts, schools]);


    const handleCheckDisableAction = (row, action) => {
        if (action.toLowerCase() == 'edit') {
            return new Date() < new Date(row.StartDate) || row.GeneralSurvey.IsActive === false || row.GeneralSurvey.IsDeleted === true || statusIdentifer(row) === 'Submitted'
                || (row.UserSurveyMap.UserId !== appUserMgr?.AppUserState?.userProfile?.Id && appUserMgr?.AppUserState?.userProfile?.UserPositionType !== 1)
        }
        else if (action.toLowerCase() == 'view') {
            return (statusIdentifer(row) === 'In Progress' || statusIdentifer(row) === 'Not Started');
        } else if (action.toLowerCase() == 'print') {
            return (statusIdentifer(row) === 'Not Started' || statusIdentifer(row) === 'In Progress') || printing;
        }
        else {
            return false;
        }
    }

    const checkLoadingAction = (action, id) => {
        if (action === 'print' && printing) {
            return action
        } else return id
    }
    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                checkDisableAction={handleCheckDisableAction}
                checkLoadingAction={checkLoadingAction}
                useIntegratedPaging={['10', '25', '50', '100']}
                onPrint={async (row) => await onPrint(row)}
                printing={printing}
                onCustom1={{
                    action: (row) => <div title='Print Blank Survey' className='icon-wrapper'>
                        <LocalPrintshopOutlinedIcon className='icon' onClick={() => {
                            if (!handleCheckDisableAction(row, 'custom1')) {
                                handlePrintEmpty(row, true)
                            }
                        }} />
                    </div>
                }}
                onEdit={(row) => onEdit(row)}
                onView={(row) => onView(row)}
            />
        </div>
    </>
}

export default GeneralSurveyList;