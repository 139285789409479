import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import ProfileNav from './ProfileNav';
import { Button } from '@mui/material';
import SidebarHeader from './SidebarHeader';
import SidebarMenu from './SidebarMenu';
import SidebarFooter from './SidebarFooter';

const Navigation = ({
    sidebarFooter
}) => {
    const [sidebarToggle, setSidebarToggle] = useState(false);
    return <>
        <div className='left-nav-btn'>
            <Button
                className={clsx(
                    'navbar-toggler hamburger hamburger--elastic ',
                    { 'is-active': sidebarToggle }
                )}
                onClick={() => setSidebarToggle(!sidebarToggle)}>
                <span className="hamburger-box">
                    <span className="hamburger-inner" />
                </span>
            </Button>
        </div>
        <div
            className={clsx('app-wrapper', {
                'header-drawer-open': sidebarToggle,
                'app-sidebar-collapsed': sidebarToggle,
                'app-sidebar-mobile-open': sidebarToggle,
                'app-sidebar-fixed': false,
                'app-header-fixed': true,
                'app-footer-fixed': true,
                'search-wrapper-open': false
            })}>
            <div>
                <div className="app-sidebar">
                    <SidebarHeader sidebarToggle={sidebarToggle} onSidebarToggle={() => setSidebarToggle(!sidebarToggle)} />
                    <div className="app-sidebar--content">
                        <SidebarMenu onSidebarToggle={() => setSidebarToggle(!sidebarToggle)} />
                    </div>
                    {sidebarFooter && <SidebarFooter />}
                </div>
                <div
                    onClick={() => setSidebarToggle(!sidebarToggle)}
                    className={clsx('app-sidebar-overlay', {
                        'is-active': sidebarToggle
                    })}
                />
            </div>
        </div>
    </>;
}

export default Navigation;