
import { useEffect, useState } from 'react';
import { InputField } from "../../../../components/controls/InputField";
import { SelectPicker, Checkbox, Whisper, Tooltip } from 'rsuite';
import ButtonControl from "../../../../components/controls/ButtonControl";
import { asyncForEach } from "../../../contentLibrary/utils/jsUtil";
import DropdownMenu from 'rsuite/esm/Dropdown/DropdownMenu';
import './../../payout.scss';
import './../payout/styles/payoutRecipients.scss';
import achievementTypes from '../../../dev/data/achievementTypes';
import payoutRoles from '../../../dev/data/payoutRoles';
import { faFlag, faCircleExclamation, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import triangle from './assets/triangle_icon.jpg';
import Loading from '../../../../components/Loading';
import _ from 'lodash';
import moment from 'moment/moment';

export const calcCompassEquivalent = {
    "5.0": 4.0,
    "5": 4.0,
    "4.5": 3.8,
    "4.0": 3.6,
    "4": 3.6,
    "3.5": 3.4,
    "3.0": 2.8,
    "3": 2.8,
    "2.5": 2.4,
    "2.0": 1.8,
    "2": 1.8,
    "1.5": 1.4,
    "1.0": 1.0,
    "1": 1.0
}
const PayoutRecipients = ({ data, payoutInfo, appUserMgr, payoutTeacherConfig, hideCompassEq, onNext, onPrevious, onExport }) => {
    const [recipeients, setRecipients] = useState();
    const [isAdminRecipients, setIsAdminRecipients] = useState(false);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [showPaymentSettings, setShowPaymentSettings] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState();
    const [selectedDistrict, setSelectedDistrict] = useState();
    const [selectedSchool, setSelectedSchool] = useState();
    const [updatedRowIndices, setUpdatedRowIndices] = useState([]);
    const [selectedPayoutRole, setSelectedPayoutRole] = useState('');
    const [selectedAchievementType, setSelectedAchievementType] = useState('');
    const [valueToUpdate, setValueToUpdate] = useState(null);
    const [indicatorMessage, setIndicatorMessage] = useState(false);
    const [columns, setColumns] = useState([
        {
            header: 'Eligible Payee',
            dataProp: "EligiblePayee",
            width: '16.33%',
            canSort: true,
            boldText: true,
            leftSide: true
        },
        {
            header: 'Exclude',
            dataProp: 'IsExclude',
            width: '5.33%',
            isBool: true,
            canSort: true
        },
        {
            header: 'Hard to Fill',
            dataProp: "IsHardToFill",
            width: '4.33%',
            isBool: true,
            canSort: true
        },
        {
            header: 'Payout Role',
            dataProp: "PayoutRoleId",
            width: '11.33%',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'SKR Score',
            dataProp: 'SKRScore',
            width: '6.33%',
            centerInput: true,
            canSort: true
        },
        {
            header: 'Compass Equiv.',
            dataProp: 'CompassEquiv',
            width: '7.33%',
            centerInput: true,
            canSort: true
        },
        {
            header: 'Survey Score',
            dataProp: 'SurveyScore',
            width: '6.33%',
            centerInput: true,
            canSort: true
        },
        {
            header: 'Apply Value-Added Score',
            dataProp: 'IsApplyValueAddedScore',
            width: '7.33%',
            isBool: true,
            canSort: false
        },
        {
            header: 'Achievement Type',
            dataProp: 'PayoutAchievementTypeId',
            width: '12.33%',
            canSort: true
        },
        {
            header: 'Value-Added Score',
            dataProp: 'ValueAddedScore',
            width: '8.33%',
            centerInput: true,
            canSort: false
        },
        {
            header: 'Apply % Payout',
            dataProp: 'PayoutPct',
            width: '7.33%',
            centerItems: true,
            canSort: true,
            centerInput: true
        },
        {
            header: 'Comment',
            dataProp: 'Comment',
            width: '9.33%',
            canSort: true,
            lastCol: true
        },
    ])

    useEffect(() => {
        let newRows = [];
        if (data) {
            if (hideCompassEq)
                setColumns(columns.filter(x => x.dataProp !== 'CompassEquiv'));

            asyncForEach(data, (u) => {
                const flooredSKRScore = Math.floor(u.SKRScore * 2 + 0.49) / 2;
                newRows.push({
                    ...u,
                    IsExclude: !u.IsActive,
                    PayoutConfigurationId: u.PayoutConfigurationId ?? payoutTeacherConfig.Id,
                    isEdited: !u.PayoutConfigurationId
                });
            }).then(() => {
                setRows(newRows);
                setLoading(false);
            });
        }
    }, [data]);

    const handleOnNext = async () => {
        setSaving(true)
        await onNext(rows);
        setSaving(false)
    }

    const handleOnExport = () => {

    }

    const handleOnChange = (rowId, selectedValue, dataProp) => {
        const updatedRows = rows.map((row) => {
            if (row.Id === rowId) {
                if (dataProp === 'ValueAddedScore') {
                    row.StudentAchievementScore = selectedValue
                }
                if (dataProp === 'IsApplyValueAddedScore') {
                    if (!selectedValue) {
                        row.PayoutAchievementTypeId = null
                        row.StudentAchievementScore = null
                        row.ValueAddedScore = null
                    }
                }
                if (dataProp === "SKRScore") {
                    row.CompassEquiv = calcCompassEquivalent[Math.floor(selectedValue * 2 + 0.49) / 2];
                }
                return {
                    ...row,
                    [dataProp]: selectedValue,
                    isEdited: true,
                };
            }
            return row;
        });
        setRows(updatedRows)
    }

    const handleOnCheckBox = (rowId, dataProp) => {
        if (dataProp === 'IsExclude')
            dataProp = 'IsActive'
        const updatedRows = rows.map((row) => {
            if (row.Id === rowId) {
                return {
                    ...row,
                    [dataProp]: !row[dataProp],
                    isEdited: true,
                };
            }
            return row;
        });
        setRows(updatedRows);
    };


    const renderHeader = () => {
        let jsxCols = columns.reduce((r, col) => {
            r.push(
                <div key={col.header} className={`col ${col.leftSide ? 'left-side' : ''} ${col.lastCol ? 'last-col' : ''}`}
                    style={{ width: col.width }}
                >
                    {col.header}
                </div>
            )
            return r;
        }, []);

        return <div className={'header-row'}>{jsxCols}</div>
    };
    const renderRows = () => {
        const dataRows = _.orderBy(data, ['EligiblePayee'], ['asc'])?.map((dataRecord, i) => {
            //If CompletedDate < UpdatedDate, then show the flag
            const flagIcon = moment(dataRecord.CompletedDate).format('YYYY-MM-DDTHH:mm') < moment(dataRecord.UpdatedDate).format('YYYY-MM-DDTHH:mm') ? (
                <FontAwesomeIcon icon={faFlag} className="flag-icon" />
            ) : null;

            const minObsIndicator = (dataRecord['HasMinimumObservation'] === false) ? true : false;

            const columnsElements = columns.map((col, colIndex) => {
                let dataRowValue = dataRecord[col.dataProp]
           
                let colElement;
                if (col.dataProp === 'EligiblePayee') {
                    dataRowValue = dataRecord['IsUserActive'] ? dataRowValue : dataRowValue + " (inactive)"
                    colElement = <>
                        {flagIcon}
                        <div className='col-name' title={dataRowValue}>{dataRowValue} {minObsIndicator ? <FontAwesomeIcon icon={faCircleExclamation} className="obs-icon" /> : ''}</div>
                    </>;
                }                
                else if (col.dataProp === 'PayoutRoleId' || col.dataProp === 'PayoutAchievementTypeId') {
                    const isPayoutRole = col.dataProp === 'PayoutRoleId';
                    const data = isPayoutRole ? payoutRoles : achievementTypes;

                    if (col.dataProp === 'PayoutAchievementTypeId' && !rows.find(x => x.Id === dataRecord.Id)["IsApplyValueAddedScore"]) {
                        colElement = null; // hide the picker if valueAddedScore is not checked  
                    } else {
                        colElement = (
                            <SelectPicker
                                id={`${dataRecord.Id}+${colIndex}`}
                                className='dropdown-is-changed'
                                data={data}
                                searchable={false}
                                onChange={(e) => {
                                    handleOnChange(dataRecord.Id, e, col.dataProp);
                                }}
                                value={rows.find(x => x.Id === dataRecord.Id)[col.dataProp]}
                            />
                        );
                    }
                } else if (col.dataProp === 'ValueAddedScore' && !rows.find(x => x.Id === dataRecord.Id)["IsApplyValueAddedScore"]) {
                    colElement = null; // hide the picker if valueAddedScore is not checked

                } else if (
                    col.dataProp === 'CompassEquiv' ||
                    col.dataProp === 'SurveyScore' ||
                    col.dataProp === 'PayoutPct' ||
                    col.dataProp === 'ValueAddedScore' ||
                    col.dataProp === 'Comment') {

                    let originalValue = _.isNumber(dataRecord[`Original${col.dataProp}`]) && dataRecord[`Original${col.dataProp}`] < 0 ? null : dataRecord[`Original${col.dataProp}`];
                    const isChangedInputIcon = <img src={triangle} alt="Changed Icon" className="is-changed-input-icon" />
                    let currentValue = rows.find(x => x.Id === dataRecord.Id)[col.dataProp] 

                    let displayValue = currentValue;

                    if (col.dataProp === 'PayoutPct' && currentValue === 100) {
                        displayValue = ''; // Set to blank if PayoutPct and value is 100
                    } else if (_.isNil(currentValue)) {
                        displayValue = originalValue;
                    } else if (_.isNumber(currentValue) && currentValue < 0) {
                        displayValue = null;
                    } else {
                        displayValue = currentValue.toString();
                    }

                    colElement = (
                        <>
                            <div className="tooltip-container">
                                <InputField
                                    id={`${dataRecord.Id}+${colIndex}`}
                                    disableError={true}
                                    isDisabled={((col.dataProp === 'SKRScore' && _.isNil(originalValue)) || (col.dataProp === 'CompassEquiv') || (col.dataProp === 'SurveyScore' && _.isNil(originalValue)))}
                                    type={col.dataProp === 'Comment' ? 'text' : 'number'}
                                    value={displayValue}
                                    onChange={(e) => {
                                        handleOnChange(dataRecord.Id, e, col.dataProp);
                                    }} />
                                {rows.find(x => x.Id === dataRecord.Id)[col.dataProp] != originalValue && !_.isNil(originalValue) && originalValue > 0 && <div className="tooltip">{originalValue}</div>}
                                {rows.find(x => x.Id === dataRecord.Id)[col.dataProp] != originalValue && !_.isNil(originalValue) && originalValue > 0 && isChangedInputIcon}
                            </div>
                        </>
                    );

                } else if (col.dataProp === 'SKRScore') {

                    let showSkrScore =  (dataRecord['HasMinimumObservation'] === false && appUserMgr?.AppUserState?.userProfile?.Roles?.some(x => x == 'SysAdmin' || x == 'NIETAdmin')) ? true : 
                    dataRecord['HasMinimumObservation'] === true ? true : false;

                    let originalValue = _.isNumber(dataRecord[`Original${col.dataProp}`]) && dataRecord[`Original${col.dataProp}`] < 0 ? null : dataRecord[`Original${col.dataProp}`];
                    const isChangedInputIcon = <img src={triangle} alt="Changed Icon" className="is-changed-input-icon" />
                    let currentValue = rows.find(x => x.Id === dataRecord.Id)[col.dataProp]

                    const inputValue = (_.isNil(currentValue) || !showSkrScore) ? null :
                                    (_.isNumber(currentValue) && currentValue < 0 ? null : currentValue.toString());

                    const isDisabled = (_.isNil(originalValue) || !showSkrScore);
                    
                    colElement = (
                        <>
                            <div className="tooltip-container">
                                <InputField
                                    id={`${dataRecord.Id}+${colIndex}`}
                                    disableError={true}
                                    isDisabled={isDisabled}
                                    type={'number'}
                                    value={inputValue}
                                    onChange={(e) => {
                                        handleOnChange(dataRecord.Id, e, col.dataProp);
                                    }} />
                                {rows.find(x => x.Id === dataRecord.Id)[col.dataProp] != originalValue && !_.isNil(originalValue) && originalValue > 0 && <div className="tooltip">{originalValue}</div>}
                                {rows.find(x => x.Id === dataRecord.Id)[col.dataProp] != originalValue && !_.isNil(originalValue) && originalValue > 0 && isChangedInputIcon}
                            </div>
                        </>);
                 
                } else if (col.dataProp === 'IsExclude' || col.dataProp === 'IsHardToFill' || col.dataProp === 'IsApplyValueAddedScore') {
                    colElement = (
                        <>
                            <Checkbox
                                id={`${dataRecord.Id}+${colIndex}`}
                                checked={col.dataProp === 'IsExclude' ? (!rows.find(x => x.Id === dataRecord.Id)["IsActive"]) : rows.find(x => x.Id === dataRecord.Id)[col.dataProp]}
                                onChange={() => handleOnCheckBox(dataRecord.Id, col.dataProp)} />

                            {/* {col.dataProp === 'IsExclude' ? rows.find(x => x.Id === dataRecord.Id)["IsActive"] != dataRecord['OriginalIsActive'] && isChangedIcon
                                :
                                rows.find(x => x.Id === dataRecord.Id)[col.dataProp] != dataRecord['Original' + col.dataProp] && !_.isNil(dataRecord['Original' + col.dataProp]) && isChangedIcon} */}
                        </>
                    );

                } else {
                    colElement = <div>{dataRowValue}</div>;
                }

                return (
                    <div
                        key={`${col.header}_${dataRowValue}`}
                        style={{ width: col.width }}
                        className={`col ${col.boldText ? 'bold-text' : ''} ${col.lastCol ? 'last-col' : ''} ${col.isBool ? 'center-item' : ''} ${col.centerInput ? 'center-input' : ''}`}>
                        {colElement}
                    </div>
                );
            });

            return (
                <div key={i} className="data-row">
                    {columnsElements}
                </div>
            );
        });

        return <div className="row-area">{dataRows}</div>;
    };

    return (
        <>
            {!loading ?
                <>
                    <div className='payout-teacher-table'>
                        <div className='payout-recipients-title'>
                            <h2>Edit Teacher Payout</h2>
                            <h5>{payoutInfo?.Client?.Name} &gt; {payoutInfo?.District?.Name} &gt; {payoutInfo?.School?.Name}</h5>
                        </div>
                        <div className='recipientTable'>
                            <div className='control-box-wrapper'>
                                <div className='control-box-list'>
                                    <div className='wrapper'>
                                        {renderHeader()}
                                        {renderRows()}
                                    </div>
                                    {error &&
                                        <div className='error-msg'>*{errorMessage}</div>
                                    }
                                    <div className='button-row'>
                                        <div className='min-obs-indicator' ><FontAwesomeIcon icon={faCircleExclamation} className="obs-icon" /> Indicates Minimum Observations Not Met</div>
                                        <div className="button-wrapper">
                                            <div className='btn-wrapper-left screen-footer__btn'>
                                                <ButtonControl loading={saving} type={'cancel'} onClick={async () => {
                                                    onExport(payoutInfo)
                                                }}>Export Data</ButtonControl>
                                            </div>
                                            <div className='btn-wrapper-left screen-footer__btn'>
                                                <ButtonControl disabled={saving} type={'cancel'} onClick={onPrevious}>Cancel</ButtonControl>
                                            </div>
                                            <div className='btn-wrapper-left screen-footer__btn'>
                                                <ButtonControl loading={saving} type={'okay'} onClick={handleOnNext}>{appUserMgr.canWrite('ConfigurePayoutTeacherConfiguration') ? 'Save & Continue' : 'Save'}</ButtonControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                <div className='loading-container'>
                    <Loading type='default' size='5rem' /><div className='loading-font'>Loading...</div>
                </div>
                </>
            }

        </>
    )
}

export default PayoutRecipients;