import React, { useState } from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _, { debounce } from 'lodash';
import { DatePickerControl, SelectListControl } from '../../../../components/controls';
import { CheckTreePicker } from 'rsuite';
import { useOrgManager } from '../../../../hooks/useManagers';
import { useEffect } from 'react';
import Loading from '../../../../components/Loading';
import { useCallback } from 'react';
import UserOrgTreeControl from '../../../../components/controls/UserOrgTreeControl';

export default function ObservationFilter({ name, observationMgr, onFilterChange }) {

    const orgMgr = useOrgManager()
    // const [searchBy,]
    const [schoolYears, setSchoolYears] = useState([]);
    const [observerTree, setObserverTree] = useState([]);
    const [observeeTree, setObserveeTree] = useState([]);

    const [orgTree, setOrgTree] = useState([]);
    const [subjects, setSubjects] = useState();

    const [statuses, setStatuses] = useState([
        { Text: 'Not Started', Value: 0 },
        { Text: 'In Progress', Value: 1 },
        { Text: 'Self Reflection: Not Started', Value: 3 },
        { Text: 'Self Reflection: In Progress', Value: 4 },
        { Text: 'Self Reflection: Submitted', Value: 5 },
        { Text: 'Post Conference', Value: 6 },
        { Text: 'Observer Signed', Value: 7 },
        { Text: 'Observed Signed', Value: 8 },
        { Text: 'Completed', Value: 9 }
    ]);

    useEffect(() => {
        if (orgMgr) {
            orgMgr.loadOrgTree();
            orgMgr.loadOrgUserTree();
        }
    }, [orgMgr])

    useEffect(() => {
        if (orgMgr?.FullOrgUserTree && observeeTree.length === 0 && observerTree.length === 0) {
            setObserveeTree(orgMgr?.FullOrgUserTree);
            setObserverTree(orgMgr?.FullOrgUserTree);
        }
    }, [orgMgr?.FullOrgUserTree]);

    useEffect(() => {
        if (observationMgr) {
            observationMgr.getOptions('subject').then(subjects => { setSubjects(subjects); })
        }
    }, [observationMgr])

    useEffect(() => {
        if (orgMgr?.FullOrgTree) {
            setOrgTree(orgMgr?.FullOrgTree.map(x => {
                return {
                    ...x, Children: []
                }
            }))
        }
    }, [orgMgr?.FullOrgTree])

    const handleOnChange = (value, field) => {
        if (onFilterChange)
            onFilterChange(field, value);
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        observationMgr.filterData({ search: null, observationType: null });
    }

    const onTreeCleanOrg = (event) => {
        handleOnChange([], "organizations");
    }


    const onTreeSelect = (vals, field) => {
        let newTreeIds = orgMgr?.findSelected(vals);
        console.log(vals, '???')
        if (handleOnChange)
            handleOnChange(newTreeIds, field);
    }





    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">{name}</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search'
                            disableError={true}
                            placeholder={'EE PASS #, Name & Email'}
                            onChange={handleOnChange}
                            value={observationMgr?.Filter?.search ?? []}
                            fieldName='search'></InputField>
                    </div>
                    <div className="filterControl">
                        {orgTree ? <InputField
                            id='organization'
                            title='Organization'
                            value={observationMgr?.Filter?.organizations ?? []}
                            fieldName='organizations'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleOnChange}>
                            <UserOrgTreeControl
                                className={'filter-control-input'}
                                childrenKey="Children"
                                labelKey="Name"
                                valueKey="Id"
                                selectedValues={observationMgr?.Filter?.organizations}
                                data={orgMgr?.FullOrgTree}
                                menuClassName={'org-tree-menu'}
                                onClean={onTreeCleanOrg}
                                onSelect={(a, v) => onTreeSelect(v, 'organizations')}
                                placement="autoHorizontalStart"
                            />
                        </InputField> : <Loading />}
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='userObserved'
                            title='User Observed'
                            value={observationMgr?.Filter?.userObserved ?? []}
                            fieldName='users'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleOnChange}>

                            <UserOrgTreeControl
                                className={'filter-control-input'}
                                childrenKey="Children"
                                labelKey="Name"
                                valueKey="Id"
                                selectedValues={observationMgr?.Filter?.userObserved}
                                data={observeeTree}
                                menuClassName={'org-tree-menu'}
                                onClean={() => handleOnChange([], 'userObserved')}
                                onSelect={(a, v) => onTreeSelect(v, 'userObserved')}
                                placement="autoHorizontalStart"
                            />
                        </InputField>
                    </div>
                    <div className="filterControl">

                        <InputField
                            id='observer'
                            title='Observer'
                            value={observationMgr?.Filter?.observer ?? []}
                            fieldName='observer'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleOnChange}>
                            <UserOrgTreeControl
                                className={'filter-control-input'}
                                childrenKey="Children"
                                labelKey="Name"
                                valueKey="Id"
                                selectedValues={observationMgr?.Filter?.observer}
                                data={observerTree}
                                menuClassName={'org-tree-menu'}
                                onClean={() => handleOnChange([], 'observer')}
                                onSelect={(a, v) => onTreeSelect(v, 'observer')}
                                placement="autoHorizontalStart"
                            />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='subjects'
                            title='Subject'
                            className={'filter-control-input'}
                            value={observationMgr?.Filter?.subject ?? 'UNK'}
                            fieldName='subject'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleOnChange}>
                            <SelectListControl textValuePairs={subjects} />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='status'
                            title='Status'
                            className={'filter-control-input'}
                            value={observationMgr?.Filter?.status ?? 'UNK'}
                            fieldName='status'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleOnChange}>
                            <SelectListControl textValuePairs={statuses} />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='startDate'
                            title='Start Date'
                            className={'filter-control-input'}
                            value={observationMgr?.Filter?.startDate ?? ''}
                            fieldName='startDate'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleOnChange}>
                            <DatePickerControl />
                        </InputField>
                    </div>
                    {/* <div className="filterControl">
                        <InputField
                            id='pcDate'
                            title='Post-Conference Date'
                            className={'filter-control-input'}
                            value={observationMgr?.Filter?.pcDate ?? ''}
                            fieldName='pcDate'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleOnChange}>
                            <DatePickerControl />
                        </InputField>
                        </div>*/}
                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}