import { useEffect, useState } from 'react';
import './details.scss';
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import _ from 'lodash';
import { CheckboxControl, SelectListControl } from '../../../../components/controls';
import { useValidationManager } from '../../../../hooks/useValidation';

const PermissionDetails = ({ permissionMgr, permission, onCancel, onSave, onLoadingError, onLoading, onErrors, onValidationErrors }) => {

    const [permissionItem, setPermissionItem] = useState(permission ?? permissionMgr.DefaultItem);
    const [saving, setSaving] = useState(false);
    const [forceValidate, setForceValidate] = useState(false);
    const validationMgr = useValidationManager(false, 'PERMISSION');

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    const handleOnSave = async (e) => {
        const validateResult = validationMgr.checkIsGroupValid(permissionItem);
        if (validateResult?.length === 0) {
            setForceValidate(false);
            if (onSave) {
                setSaving(true);
                var result = await onSave(permissionItem);
                if (result.Success) {
                    handleOnCancel();
                }
                else if (onErrors) {
                    onErrors([result?.Message?.DisplayMessage ?? [...(result?.MessageDetails ?? ['Unknown Error'])]])
                }

                setSaving(false);
                return result;
            }
        }
        else if (onErrors) {
            setForceValidate(true);
            onValidationErrors(validateResult);
        }

    }

    const handleOnChange = (value, field) => {
        let updatedPermission = { ...permissionItem };
        updatedPermission[field] = value;
        setPermissionItem(updatedPermission);
    }

    const permissionTypes = permissionItem.CreatedDate ? [{ text: 'Data', value: 0 }, { text: 'API', value: 1 }, { text: 'UX', value: 2 }] : [{ text: 'UX', value: 2 }];

    return <div className='control-box-wrapper'>
        {permissionItem && <> <div className='control-box box-two-column'>
            <InputField forceValidate={forceValidate} type="text" subTitle={'Once set, the permission cannot be changed.'} value={permissionItem?.Permission} isDisabled={permissionItem.CreatedDate} title="Permission" fieldName="Permission" groupId={'PERMISSION'} onChange={handleOnChange} />
            <InputField forceValidate={forceValidate} type="text" title="Display Name" value={permissionItem?.DisplayName} fieldName="DisplayName" groupId={'PERMISSION'} onChange={handleOnChange} />
            <InputField forceValidate={forceValidate} type="textarea" title="Description" maxLength={150} value={permissionItem?.Description} fieldName="Description" groupId={'PERMISSION'} onChange={handleOnChange} />
            <InputField forceValidate={forceValidate} title="Permission Type" value={permissionItem?.PermissionType} fieldName="PermissionType" groupId={'PERMISSION'} onChange={handleOnChange}  >
                <SelectListControl hidePleaseSelect={true} fieldName='PermissionType' disabled={permissionItem.CreatedDate} textValuePairs={permissionTypes} />
            </InputField>
        </div>
            <div className='control-box box-two-column'>
                <InputField title="Default Can View" value={permissionItem?.CanView} fieldName="CanView" groupId={'PERMISSION'} onChange={handleOnChange} >
                    <CheckboxControl />
                </InputField>
                <InputField title="Default Can Edit" value={permissionItem?.CanWrite} fieldName="CanWrite" groupId={'PERMISSION'} onChange={handleOnChange}  >
                    <CheckboxControl />
                </InputField>
                <InputField title="Default Can Execute" value={permissionItem?.CanExecute} fieldName="CanExecute" groupId={'PERMISSION'} onChange={handleOnChange} >
                    <CheckboxControl />
                </InputField>
                <InputField title="Default Can Delete" value={permissionItem?.CanDelete} fieldName="CanDelete" groupId={'PERMISSION'} onChange={handleOnChange} >
                    <CheckboxControl />
                </InputField>
                <InputField title="Default Can Permanently Delete" value={permissionItem?.CanHardDelete} fieldName="CanHardDelete" groupId={'PERMISSION'} onChange={handleOnChange} >
                    <CheckboxControl />
                </InputField>
            </div>
        </>}
        <div className='screen-footer right'>
            <div className='btn-wrapper-left screen-footer__btn'><ButtonControl disabled={saving} type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl></div>
            <div className='btn-wrapper-right screen-footer__btn'><ButtonControl loading={saving} type={'okay'} disabled={!permissionItem} onClick={handleOnSave}>Save</ButtonControl></div>
        </div>
    </div>
}

export default PermissionDetails;


