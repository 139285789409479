import './TableControl.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faCaretUp, faCaretDown, faAngleLeft, faAngleRight, faTrashCan, faEye, faPencil, faPrint, faCopy, faFloppyDisk, faCircleCheck, faAngleDown, faXmark, faFlag, faNoteSticky, faReceipt, faPowerOff, faAngleUp, faArrowUpRightFromSquare, faVideo, faFile, faCircle as faSolidCircle, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faCircle as faRegularCircle } from '@fortawesome/free-regular-svg-icons';
import SelectListControl from '../../../components/controls/SelectListControl';
import { InputField } from '../../../components/controls/InputField';
import IndicatorRowTable from '../../../domains/admin/components/rubric/IndicatorRowTable';
import Loading from '../../Loading';
import { useLocation } from 'react-router-dom'
import _ from 'lodash';
import { Flag } from '@mui/icons-material';
import LabelControl from '../LabelControl';
import { FormatUTCDate, FormatUTCDateTime } from '../../../utilities/DateFormater';
import { useAppUserManager } from '../../../hooks/useManagers';
import { useRef } from 'react';
import ButtonControl from '../ButtonControl';

const TableControl = ({
    columns,
    data,
    customBorder,
    onView,
    onPrint,
    onDrillDown,
    onDuplicate,
    onEdit,
    onSave,
    onSaveInner,
    onCheckboxChange,
    onOpen,
    onOrderChange,
    onDelete,
    onChange,
    onChangeInput,
    onNavigate,
    onDisableEnable,
    onLogs,
    onTransactions,
    userTable,
    onCustom1,
    onCustom2,
    onCustom3,
    onCustom4,
    onCustom5,
    actionIsLoadingCheck,
    toggleSelectAll,
    selectedUserIds,
    defaultNumRows,
    dropdownData,
    hideActionColumn,
    hideArrowSort,
    isIndicatorTable,
    customActionsStyle,
    useEmbedTableRow,
    tableRowComponentName,
    useCustomCells,
    useCustomHeader,
    useColumnWidthCustom,
    useIntegratedPaging,
    useMultiDimensionalSort,
    useScrollCol,
    testNum,
    checkHideAction,
    checkDisableAction,
    checkLoadingAction,
    permission,
    noDataText,
    defaultSortBy,
    defaultSortDesc,
    editIcon,
    editTitle,
    printing
}) => {

    const appUserMgr = useAppUserManager();
    const { pathname } = useLocation();
    //sorting and filtering
    const [sortBy, setSortBy] = useState(defaultSortBy ?? 'CreatedDate');
    const [sortAsc, setSortAsc] = useState(defaultSortDesc);
    const [multiDemSort, setMultiDemSort] = useState(null);
    const [sortedData, setSortedData] = useState(data);
    const [recordCount, setRecordCount] = useState(data?.length ?? 0);
    const [actionIsLoading, setActionIsLoading] = useState({ action: '', id: null })
    //paging
    const [currentPage, setCurrentPage] = useState(localStorage.getItem('CurrentPage') ?? 1);
    const [displayedData, setDisplayedData] = useState(localStorage.getItem('NumRows') ?? 10);
    const [isPageSelectionActive, setIsPageSelectionActive] = useState(false);

    //custom border
    const [borderStyle, setBorderStyle] = useState(customBorder);
    const [permissionSet, setPermissionSet] = useState(null);


    useEffect(() => {
        if (appUserMgr && permission && !permissionSet) {
            setPermissionSet({
                canView: appUserMgr.canView(permission),
                canEdit: appUserMgr.canWrite(permission),
                canDelete: appUserMgr.canDelete(permission)
            });
        }
        else if (appUserMgr && !permission && !permissionSet) {
            setPermissionSet({
                canView: true,
                canEdit: true,
                canDelete: true
            });
        }
    }, [appUserMgr])

    useEffect(() => {
        if (actionIsLoadingCheck) {
            setActionIsLoading({ action: actionIsLoadingCheck.action, id: actionIsLoadingCheck.id })
        }
    }, [actionIsLoadingCheck])

    useEffect(() => {
        if (data && data.length > 0 && data?.length !== recordCount) {
            setRecordCount(data.length);

            if (localStorage.getItem('CurrentPage') && localStorage.getItem("CurrentLocation") && localStorage.getItem("CurrentLocation") === pathname) {
                setCurrentPage(localStorage.getItem('CurrentPage'));
            } else {
                localStorage.setItem("CurrentLocation", pathname);
                localStorage.setItem("CurrentPage", 1);
                setCurrentPage(1);
            }
        }
        if (pathname.toLowerCase().includes('user')) {
            if (localStorage.getItem('CurrentPage') && localStorage.getItem("CurrentLocation") && localStorage.getItem("CurrentLocation") === pathname) {
                setCurrentPage(localStorage.getItem('CurrentPage'));
            } else {
                localStorage.setItem("CurrentLocation", pathname);
                localStorage.setItem("CurrentPage", 1);
                setCurrentPage(1);
            }

        }

        if (localStorage.getItem('CurrentPage')) {
            setCurrentPage(localStorage.getItem('CurrentPage'));
        }
        
        if(localStorage.getItem('NumRows')) {
            setDisplayedData(localStorage.getItem('NumRows'));
        }

        if (!localStorage.getItem("CurrentLocation")) {
            localStorage.setItem("CurrentLocation", pathname);
        }

        if (localStorage.getItem('sortBy')) {
            setSortBy(localStorage.getItem('sortBy'));
            setSortAsc(localStorage.getItem('sortAsc'));
        }

    }, [data, pathname])
    const downHandler = ({ key }) => {
        if (key === '1') {
            let multiSort = multiDemSort ?? {};
            multiSort.sort1 = true;
            setMultiDemSort(multiSort);
        }
        else if (key === '2') {
            let multiSort = multiDemSort ?? {};
            multiSort.sort2 = true;
            setMultiDemSort(multiSort);
        }
        else if (key === '3') {
            let multiSort = multiDemSort ?? {};
            multiSort.sort3 = true;
            setMultiDemSort(multiSort);
        }
    };

    const upHandler = () => {
        setMultiDemSort(null);
    };

    //key listeners
    useEffect(() => {
        if (useMultiDimensionalSort) {
            window.addEventListener('keydown', downHandler);
            window.addEventListener('keyup', upHandler);
            return () => {
                window.removeEventListener('keydown', downHandler);
                window.removeEventListener('keyup', upHandler);
            };
        }
    }, []);

    useEffect(() => {
        if (useIntegratedPaging) {
            const firstDataEntry = (currentPage - 1) * displayedData;
            const lastDataEntry = currentPage * displayedData;
    
            let sortedData = [];
            if (data.length > 0) {
                if (typeof sortBy === 'object') {
                    let whatToSort = [];
                    let howToSort = [];
                    for (let i = 0; i < sortBy.length; i++) {
                        if (sortBy[i]) {
                            if (!whatToSort.includes(sortBy[i][0])) {
                                whatToSort.push(sortBy[i][0]);
                                howToSort.push(sortBy[i][1]);
                            }
                        }
                    }
                    howToSort = _.map(howToSort, item => {
                        return item ? 'asc' : 'desc';
                    });
                    sortedData = _.orderBy(data, whatToSort, howToSort);
                } else {
                    if (sortBy !== '') {
                        if (sortBy === 'IsActive') {
                            sortedData = data.sort((a, b) => sortAsc ? a.IsActive - b.IsActive : b.IsActive - a.IsActive);
                        } else if (sortBy === 'SurveyGivenBy') {
                            sortedData = data.sort((a, b) => {
                                if (a.SurveyGivenBy && a.SurveyGivenBy.props && a.SurveyGivenBy.props.children && b.SurveyGivenBy && a.SurveyGivenBy.props && b.SurveyGivenBy.props.children) {
                                    const nameA = a.SurveyGivenBy.props.children[0];
                                    const nameB = b.SurveyGivenBy.props.children[0];
                        
                                    return sortAsc ? nameA.localeCompare(nameB) :  nameB.localeCompare(nameA);
                                } else {
                                    return 0;
                                }
                            });

                        } else if (sortBy.toLowerCase().includes('date') || sortBy.toLowerCase().includes('createdOn')) {
                            sortedData = data.sort((a, b) => sortAsc ? new Date(a[sortBy]) - new Date(b[sortBy]) : new Date(b[sortBy]) - new Date(a[sortBy]));
                        } else if (sortBy === 'FullOrg') {
                            sortedData = data.sort((a, b) => {
                                //compare by OrgName
                                const orgNameComparison = (a.OrgName || '').localeCompare(b.OrgName || '');
                                if (orgNameComparison !== 0) {
                                    return sortAsc ? orgNameComparison : -orgNameComparison;
                                }
                            
                                //if OrgName is the same, compare by DistrictName
                                const districtNameComparison = (a.DistrictName || '').localeCompare(b.DistrictName || '');
                                if (districtNameComparison !== 0) {
                                    return sortAsc ? districtNameComparison : -districtNameComparison;
                                }
                            
                                //if OrgName and DistrictName are the same, compare by SchoolName
                                return sortAsc ? (a.SchoolNameplus || '').localeCompare(b.SchoolNameplus || '') :
                                                 -(a.SchoolNameplus || '').localeCompare(b.SchoolNameplus || '');
                            });
                        } else {
                            sortedData = _.orderBy(data, [data => (typeof data[sortBy] === 'string' ? data[sortBy]?.toLowerCase() : data[sortBy])], [sortAsc ? 'asc' : 'desc']);
                        }
                    }
                }
            }
    
            setSortedData(_.slice(sortedData, firstDataEntry, lastDataEntry));
        } else {
            setSortedData(data);
        }
    }, [sortBy, sortAsc, data, currentPage, displayedData, useIntegratedPaging, testNum]);
    
    useEffect(() => {
        window.sortBy = sortBy;
    }, [sortBy]);

    const handleSort = (whatToSort) => {
        const col = columns.find(x => x.dataProp === whatToSort);
        if (col?.sortBy)
            whatToSort = col.sortBy;

        if (multiDemSort) {
            if (multiDemSort.sort1) {
                if (typeof sortBy === 'object') {
                    if (sortBy[0]) {
                        if (sortBy[0][0] === whatToSort) {
                            let sb = [...sortBy];
                            sb[0][1] = !sb[0][1];
                            setSortBy(sb);
                        } else {
                            let sb = [...sortBy];
                            sb[0][0] = whatToSort;
                            sb[0][1] = true;
                            setSortBy(sb);
                        }
                    }
                    else {
                        let sb = [...sortBy];
                        sb[0][0] = whatToSort;
                        sb[0][1] = true;
                        setSortBy(sb);
                    }
                }
                else {
                    setSortBy([[whatToSort, true], undefined]);
                }
            }
            if (multiDemSort.sort2) {
                if (typeof sortBy === 'object') {
                    if (sortBy[1]) {
                        if (sortBy[1][0] === whatToSort) {
                            let sb = [...sortBy];
                            sb[1][1] = !sb[1][1];
                            setSortBy(sb);
                        } else {
                            let sb = [...sortBy];
                            sb[1][0] = whatToSort;
                            sb[1][1] = true;
                            setSortBy(sb);
                        }
                    }
                    else {
                        let sb = [...sortBy];
                        sb[1] = [whatToSort, true];
                        setSortBy(sb);
                    }
                }
                else {
                    setSortBy([undefined, [whatToSort, true]]);
                }
            }

        }
        else {
            if (sortBy === whatToSort) {
                localStorage.setItem("sortAsc", !sortAsc);
                setSortAsc(!sortAsc);
            }
            else {
                localStorage.setItem("sortAsc", true);
                localStorage.setItem("sortBy", whatToSort);
                setSortBy(whatToSort);
                setSortAsc(true);
            }
        }
    };


    const totalColumnLength = _.reduce(columns, (total, column) => {
        if (_.isNil(column.hideData)) {
            return _.toInteger(column?.width?.replace('%', '') ?? 0) + total
        } else {
            return total
        }
    }, 0)

    const checkHideData = () => {
        const hideDataColumns = {};
        _.each(columns, c => {
            if (c.hideDataMissing) {
                var hadDataList = _.filter(sortedData, sd => sd[c.dataProp] === undefined || sd[c.dataProp] == null || sd[c.dataProp] === '' || sd[c.dataProp] === false);
                if (hadDataList.length > 0) {
                    hideDataColumns[c.dataProp] = true;
                }
            }
        });

        return hideDataColumns;
    }



    const renderHeader = () => {
        let actions = [onView, onPrint, onEdit, onDelete, onDuplicate, onCustom1, onCustom2, onCustom3]; //Order matters
        let presentActions = [];
        for (var i = 0; i < actions.length; i++) {
            if (actions[i]) {
                presentActions.push(actions[i]);
            }
        }
        const hideDataColumns = checkHideData();
        let jsxCols = columns.reduce((r, col, i) => {

            if (!hideDataColumns[col.dataProp]) {
                r.push(
                    <div
                        key={i}
                        className={`col ${!col.canSort ? 'remove-cursor' : ''} ${col.wrapText === true ? 'col-wrap' : col.justifyLeft === true ? 'col-left' : col.leftAlign === true ? 'col-header-left' : ''} 
                                ${useCustomHeader === true ? 'custom-header-cell' : ''} ${col.isIndicatorHeader ? 'col-indicator-header' : ''}
                                ${col.subHeader ? 'col-sub-header' : ''}`}
                        style={!useColumnWidthCustom ? { width: (col.width ? col.width : `${100 / (columns.length + (presentActions.length > 0 ? 1 : 0))}%`) } :
                            { width: `${col.width.length > 0 ? col.width : 100 / (columns.length + (presentActions.length > 0 ? 1 : 0))}%` }}
                        onClick={col.canSort ? () => { handleSort(col.dataProp) } : null}
                    >
                        {col.header}
                        {!hideArrowSort && col.canSort &&
                            <FontAwesomeIcon style={{ display: hideArrowSort ? 'none' : '' }} icon={sortAsc ? faArrowUp : faArrowDown} className={`arrow${col.dataProp === sortBy || col.sortBy === sortBy ? ' active' : ''}`} />
                        }
                        {col.subHeader &&
                            <div>
                                {col.subHeader}
                            </div>
                        }
                    </div>
                )
            }
            return r;
        }, []);



        if (sortedData.length > 0 && sortedData[0] && (Object.keys(sortedData[0]).some(key => key == "HasChange"))) {
            if (sortedData.some(x => x.HasChange == true)) {
                jsxCols.unshift(<div
                    key={`table_action_change_header`}
                    className={'col remove-cursor'}
                    style={{ minWidth: '3rem' }}                >

                </div>);
            }
        }

        if (presentActions.length > 0 && !hideActionColumn) {
            jsxCols.push(
                <div
                    key={'action'}
                    className={'col col-header-actions'}
                    style={{ minWidth: `${(iconCount() * 2) + 6}rem`, width: `${customActionsStyle ? customActionsStyle : 100 - totalColumnLength}%`, cursor: 'unset' }}
                >
                    Actions
                    {toggleSelectAll && <span className='select-all' onClick={() => toggleSelectAll(sortedData)}>Select All ({selectedUserIds?.length ?? 0})</span>}
                </div>
            )
        }

        return useCustomCells ? <div className={'header-row custom-header'}>{jsxCols}</div> : <div className={'header-row'}>{jsxCols}</div>
    };


    const doCheckDisableAction = (row, action, actionCallback, id) => {
        let hasPermission = true;
        if (action.toLowerCase() === 'edit' || action.toLowerCase() === 'save' || action.toLowerCase() === 'duplicate')
            hasPermission = permissionSet?.canEdit;
        else if (action.toLowerCase() === 'view')
            hasPermission = permissionSet?.canView;
        else if (action.toLowerCase() === 'delete') {
            hasPermission = permissionSet?.canDelete;
        }

        const disabled = ((!hasPermission || row.GrayOutAction || (checkDisableAction && checkDisableAction(row, action)) || (actionIsLoading.id && actionIsLoading.id !== row.Id) || (actionIsLoading.action && actionIsLoading.action !== action)) ? ' disableAction' : "");

        if ((actionCallback && !disabled)) {
            //  *** How to make action have loading icons ***
            //
            // Create a function to check what action you want to be able to have the loading icon.
            // Make the actionCallBack function being passed an asynchronous function.
            if (checkLoadingAction) {
                const actionToLoad = checkLoadingAction(action, id)
                if (actionToLoad) {
                    setActionIsLoading({ action: action, id: id, })
                    if (actionCallback) {
                        var callbackResult = actionCallback(row);
                        if (callbackResult) {
                            return callbackResult.then(() => {
                                if (actionToLoad !== id) {
                                    setActionIsLoading({ action: '', id: null })
                                }
                            })
                        }
                    }
                }
                else
                    return actionCallback(row);
            } else return actionCallback(row);
        } else if (!printing && actionIsLoading.id) {
            setActionIsLoading({ action: '', id: '', })
        }
        else
            return disabled;
    }

    const renderRows = () => {
        let dataRows = [];
        let isEmbeddedTableRow = false;
        let actions = [onView, onPrint, onEdit, onDelete, onSave, onCustom1, onCustom2, onCustom3]; //Order matters
        let presentActions = [];
        for (let i = 0; i < actions.length; i++) {
            if (actions[i]) {
                presentActions.push(actions[i]);
            }
        }
        const hideDataColumns = checkHideData();

        for (let i = 0; i < sortedData.length; i++) {
            let dataRecord = sortedData[i];
            let isEmbededRow = false;
            let publishedStatus = dataRecord?.Status ? dataRecord?.Status === 2 ? true : false : false;
            let hideExpandedRow = (!dataRecord.expandRow && dataRecord.useTableRow) ? true : false;
            //TODO: Probably not great to stringify for the key like this. Modify so that it's not like this!
            let isIndicatorTableRow = (tableRowComponentName === 'IndicatorRowTable' && dataRecord.expandRow) ? true : false;

            const hasChangeColumn = sortedData?.some(x => x.HasChange == true);
            dataRows.push(
                <div key={`table_${i}`} className={`data-row  ${customBorder === true ? 'data-row-border' : ''} ${isIndicatorTableRow === true ? 'indicator-table-row' : ''} ${useCustomCells === true ? 'custom-cell' : ''} ${useEmbedTableRow === true ? 'table-row-cell' : ''}
                                ${hideExpandedRow ? 'hide-row' : ''} ${userTable ? 'user-list-row' : ''}`}>
                    {hasChangeColumn && (Object.keys(dataRecord).some(key => key == "HasChange")) &&
                        <div
                            key={`table_action_change`}
                            className={'col'}
                            style={{ minWidth: '3rem' }}
                        >
                            {dataRecord?.HasChange && <FontAwesomeIcon className={'btn-icon-fa warn'} icon={faFlag} />}
                        </div>
                    }
                    {columns.reduce((r, col) => {
                        if (dataRecord.useTableRow !== undefined) {
                            if (dataRecord.useTableRow !== undefined && col.dataProp == 'domain') {
                                let tableRowComponent = tableRowComponentName;
                                isEmbededRow = true;
                                if (tableRowComponent === 'IndicatorRowTable' && dataRecord.expandRow) {
                                    r.push(
                                        <IndicatorRowTable hideColumns={hideDataColumns} data={dataRecord} onDeleteRow={(row) => onDelete(row)} onSaveInner={(indicatorDataSet) => onSaveInner(indicatorDataSet)} />
                                    )
                                    //TODO: hide parent div, div table_[i].style.display = "none";
                                    return r;
                                }
                            }
                            isEmbeddedTableRow = true;
                            return r;
                        }
                        else {
                            isEmbeddedTableRow = false;
                            let dataRowValue = '';
                            if (col.dataProp.split('.').length === 1) {
                                dataRowValue = dataRecord[col.dataProp];

                            }
                            else if (col.dataProp.split('.').length === 2) {
                                const dataPropValues = col.dataProp.split('.');
                                if (dataRecord[dataPropValues[0]])
                                    dataRowValue = dataRecord[dataPropValues[0]][dataPropValues[1]];
                            }
                            else if (col.dataProp.split('.').length === 3) {
                                const dataPropValues = col.dataProp.split('.');
                                if (dataRecord[dataPropValues[0]] && dataRecord[dataPropValues[1]])
                                    dataRowValue = dataRecord[dataPropValues[0]][dataPropValues[1]][dataPropValues[2]];
                            }

                            let isHtml = col.datatype !== null ? col.datatype === 'html' ? true : false : false;
                            let isFlag = col.datatype !== null ? col.datatype === 'flag' ? true : false : false;
                            let isLocalDate = col.datatype !== null ? col.datatype === 'localDate' ? true : false : false;
                            let isDate = col.datatype !== null ? col.datatype === 'date' ? true : false : false;
                            let isDateTime = col.datatype !== null ? col.datatype === 'dateTime' ? true : false : false;
                            let isImage = col.datatype !== null ? col.datatype === 'image' ? true : false : false;
                            let iscontentModal = col.datatype !== null ? col.datatype === 'contentModal' ? true : false : false;
                            let isOrder = col.dataProp !== null ? col.dataProp === 'order' ? true : false : false; //displays arrow up/down icons
                            let isDropdown = col.dataProp !== null ? ((col.dataProp === 'level' || col.dataProp === 'domain') && dataRecord.isEditMode && dataRecord.rubricStatus !== 2) ? true : false : false; //level is a dropdown if in edit mode 
                            let isRating = col.dataProp !== null ? (col.dataProp === 'rating' && dataRecord.isEditMode && dataRecord.ratingId === '' && dataRecord.isNewRow) ? true : false : false; //level is a dropdown if in edit mode 
                            let isEval = col.dataProp !== null ? (col.dataProp === 'showInEval' || col.dataProp === 'useForCert') ? true : false : false;
                            let isNewRowLevel = col.dataProp !== null ? (dataRecord.isNewRow !== null && col.dataProp === 'level' && dataRecord.isNewRow === true) ? true : false : false;
                            let isNewRowDesc = col.dataProp !== null ? (dataRecord.isNewRow !== null && col.dataProp === 'description' && dataRecord.isNewRow === true) ? true : false : false;
                            let isNewRowDomain = col.dataProp !== null ? (dataRecord.isNewRow !== null && col.dataProp === 'domain' && dataRecord.isNewRow === true) ? true : false : false;
                            let isDomainTitle = col.dataProp !== null ? (col.dataProp === 'domain') ? true : false : false;
                            let isBool = (col.dataProp == 'IsActive' || col.isBool) ? true : false;
                            let isIndicatorTitle = false;
                            let isIndicatorCell = false;
                            let showExcludeTooltip = col.dataProp == 'announced' && dataRecord?.excludeFromScoring;
                            let isAnnounceObservationCol = col.dataProp == 'announced';

                            if (isIndicatorTable && col.dataProp !== null) {
                                if (col.dataProp === 'title') {
                                    isIndicatorTitle = true;
                                }
                                else {
                                    isIndicatorCell = true;
                                }
                            }

                            if (!hideDataColumns[col.dataProp]) {
                                r.push(
                                    <div
                                        key={`${col.header}_${dataRowValue}`}
                                        style={!useColumnWidthCustom ?
                                            { width: (col.width ? col.width : `${100 / (columns.length + (presentActions.length > 0 ? 1 : 0))}%`) }
                                            :
                                            { width: `${col.width.length > 0 ? col.width : 100 / (columns.length + (presentActions.length > 0 ? 1 : 0))}%` }
                                        }
                                        className={`col ${isEval || col.dataProp === 'rating' || (col.dataProp === 'level' && isDropdown) ? 'center-cell' : ''} ${col.justifyLeft === true ? 'col-left' : col.centerItems === true ? 'col-center' :
                                            col.leftAlign === true ? 'col-header-left' : !useCustomCells ? 'customCell' : ''}${dataRecord?.Editing ? ' col-inputfield' : ''} ${col.textwrap ? ' text-wrap ' : ''} ${useCustomCells === true ? 'custom-cell' : ''}
                                                ${publishedStatus ? 'bold-row' : ''} ${useScrollCol === true ? 'scroll-col' : ''}`} onClick={() => handleSort(col.header)}
                                    //style={{ width: `${100 / (columns.length + (presentActions.length > 0 ? 1 : 0))}%` }}
                                    // someting={{ width: `${100 / (columns.length + (presentActions.length > 0 ? 1 : 0))}%` }}
                                    // blah={{ width: `${100 / columns.length}%` }}
                                    >
                                        {
                                            isLocalDate && dataRowValue !== null ? (
                                                new Date(dataRowValue).toLocaleDateString()
                                            ) : isDate && dataRowValue !== null ? (
                                                FormatUTCDate(dataRowValue)
                                            ) : isDateTime && dataRowValue !== null ? (
                                                dataRowValue ? FormatUTCDateTime(dataRowValue) : ''
                                            ) : isHtml ? (
                                                <div className='html-wrapper'>
                                                    <div dangerouslySetInnerHTML={{ __html: dataRowValue }}></div>
                                                </div>
                                            ) : isFlag ? (
                                                <div className={'icon-wrapper'}>
                                                    <>{dataRowValue == true && <Flag className='icon' />}</>
                                                </div>
                                            ) : isImage ? (
                                                <div className={'img-wrapper'}>
                                                    <img src={dataRowValue} alt="" className={'img-wrapper'} />
                                                </div>
                                            ) : iscontentModal ? (
                                                dataRowValue.path ? (
                                                    <div className={'img-modal-wrapper'} onClick={() => onOpen(dataRecord)}>
                                                        {(
                                                            dataRowValue.extension === 'mp4' ||
                                                            dataRowValue.extension === 'avi' ||
                                                            dataRowValue.extension === 'mpg' ||
                                                            dataRowValue.extension === 'mpga' ||
                                                            dataRowValue.extension === 'streaming.media.azure.net'
                                                        ) ? (
                                                            <span>{dataRowValue.name}</span>
                                                        ) : (
                                                            dataRowValue.extension === 'apng' ||
                                                            dataRowValue.extension === 'gif' ||
                                                            dataRowValue.extension === 'ico' ||
                                                            dataRowValue.extension === 'jpg' ||
                                                            dataRowValue.extension === 'jpeg' ||
                                                            dataRowValue.extension === 'jfif' ||
                                                            dataRowValue.extension === 'pjpeg' ||
                                                            dataRowValue.extension === 'pjp' ||
                                                            dataRowValue.extension === 'png' ||
                                                            dataRowValue.extension === 'svg'
                                                        ) ? (
                                                            <span>{dataRowValue.name}</span>
                                                        ) : <span>{dataRowValue.name}</span>}
                                                        <FontAwesomeIcon style={{ fontSize: 'large' }} className={'img-middle-icon'} icon={faEye} />
                                                    </div>
                                                ) : null
                                            ) : isOrder ? (
                                                <div style={{ textAlignLast: 'center' }}>
                                                    <FontAwesomeIcon style={{ fontSize: 'large' }} className={`order-by ${i === 0 ? 'disableAction' : ''}`} icon={faCaretUp} onClick={() => { onOrderChange(dataRecord.sequence ? dataRecord.sequence : dataRecord.order, true) }} />
                                                    <FontAwesomeIcon style={{ fontSize: 'large' }}
                                                        className={`order-by ${(i === sortedData.length - 1) || (sortedData[i].domain && (i === sortedData.length - 2) || (sortedData[i].domain === '')) ? 'disableAction' : ''}`}
                                                        icon={faCaretDown} onClick={() => { onOrderChange(dataRecord.sequence ? dataRecord.sequence : dataRecord.order, false) }} />
                                                </div>
                                            ) : (isDropdown && !isNewRowLevel && !isNewRowDomain) ? (
                                                <div>
                                                    {(col.dataProp === 'level') ? (
                                                        <SelectListControl className={"rating"} plsOpt={true} disabledOptions={'UNK'} placeholder={'Select'} addOptionText={"Add New Rating +"} onChange={(e) => { onChange(e, dataRecord) }}
                                                            value={dataRecord.level == '' ? 'UNK' : dataRecord.level} field={{ FieldName: 'PerformanceLevel' }} textValuePairs={dropdownData} />
                                                    ) : (
                                                        <SelectListControl className={"rating"} plsOpt={true} addOptionText={"Add New Domain +"} onChange={(e) => { onChange(e, dataRecord) }}
                                                            value={dataRecord.domain == '' ? 'UNK' : dataRecord.domain} field={{ FieldName: 'Domain' }} textValuePairs={dropdownData} />
                                                    )}
                                                </div>
                                            ) : isEval ? (
                                                <div className={`${dataRecord.showInEval === true || dataRecord.showInEval === false ? 'check-center' : ''}`}>
                                                    {(dataRecord.showInEval === true || dataRecord.useForCert === true) ?
                                                        <FontAwesomeIcon className={`fa-solid checkbox-icon ${dataRecord.isEditMode ? 'is-pointer' : ''}`} icon={faCircleCheck} onClick={() => onCheckboxChange(dataRecord)} />
                                                        : <FontAwesomeIcon className={`fa-regular checkbox-icon ${dataRecord.isEditMode ? 'is-pointer' : ''}`} icon={faSolidCircle} onClick={() => onCheckboxChange(dataRecord)} />
                                                    }
                                                </div>
                                            ) : (isNewRowLevel) ? (
                                                <div>
                                                    <InputField value={dataRecord.level} disableError={true} onUpdate={(e, field) => { onChangeInput(e, field, dataRecord) }} validateOnBlur={true} maxLength={100} fieldName="level" />
                                                </div>
                                            ) : isNewRowDesc ? (
                                                <div>
                                                    <InputField value={dataRecord.description} className={'table-long-text-box'} disableError={true} onUpdate={(e, field) => { onChangeInput(e, field, dataRecord) }} validateOnBlur={true} maxLength={100} fieldName="description" />
                                                </div>
                                            ) : isNewRowDomain ? (
                                                <div>
                                                    <InputField className={'domain-input'} value={dataRecord.domain} onUpdate={(e, field) => { onChangeInput(e, field, dataRecord) }} disableError={true} validateOnBlur={true} maxLength={100} fieldName="domain" />
                                                </div>
                                            ) : (col.IsInputField && dataRecord.Editing) ? (
                                                <InputField value={dataRecord.Title} className={'grid-inputfield'} onUpdate={(e, field) => { onChangeInput(dataRecord, "INPUT", e) }} fieldName={col.dataProp} />

                                            ) : isRating ? (
                                                <div>
                                                    <InputField className={"ratingInput"} value={dataRecord.rating} onUpdate={(e, field) => { onChangeInput(e, field, dataRecord) }} validateOnBlur={true} maxLength={100} fieldName="rating" validationName="user_test" />
                                                </div>
                                            ) : isIndicatorTitle ? (
                                                <div>
                                                    <div style={{ fontWeight: 'bold', paddingTop: '5px' }}>Indicator</div>
                                                    <LabelControl className="indicator-cell" field="title" value={dataRowValue.Indicator}></LabelControl>
                                                    {dataRowValue.Domain !== 'Default' &&
                                                        <>
                                                            <div style={{ fontWeight: 'bold', paddingTop: '2rem' }}>Domain</div>
                                                            <LabelControl className="indicator-cell" field="title" value={dataRowValue.Domain}></LabelControl>
                                                        </>
                                                    }
                                                    <div className='pencil-outer-container'>
                                                        <div className='pencil-container'>
                                                            {dataRowValue.DomainId !== '' ?
                                                                <FontAwesomeIcon title="Edit Indicator and Domain" className={'btn-icon-fa pencil-indicator'} icon={faPencil} onClick={() => onEdit(dataRowValue)} />
                                                                :
                                                                <FontAwesomeIcon className={'btn-icon-fa'} style={{ color: 'darkgray' }} icon={faPencil} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : isIndicatorCell ? (
                                                <>
                                                    <div className='indicator-cell-text-area' dangerouslySetInnerHTML={{ __html: dataRowValue.description }}
                                                    />
                                                    <div className={dataRowValue.description !== '' ? 'pencil-outer-container pencil-outer-container-text-present' : 'pencil-outer-container'} >
                                                        <div className='pencil-container'>
                                                            {dataRowValue.rubricIndicatorId !== '' ?
                                                                <FontAwesomeIcon title="Edit Cell" className={'btn-icon-fa pencil-indicator'} icon={faPencil} onClick={() => onEdit({ row: dataRowValue, rowTitle: dataRecord.title })} />
                                                                :
                                                                <FontAwesomeIcon className={'btn-icon-fa'} style={{ color: 'darkgray' }} icon={faPencil} />
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            ) : isDomainTitle ? (
                                                <>
                                                    <div style={{ width: '100%', display: 'inline' }}>
                                                        {dataRowValue}
                                                        <FontAwesomeIcon className={'btn-icon-fa angle-down'} icon={dataRecord.expandRowClicked ? faAngleUp : faAngleDown}
                                                            onClick={() => onEdit({ row: dataRowValue, expandRow: true, dataRecord: dataRecord })} />
                                                        <div style={{ display: 'inline', paddingRight: '1em', float: 'right' }}>
                                                            <FontAwesomeIcon style={{ fontSize: 'medium' }} title="Switch to Current Domain" className={'btn-icon-fa arrow-up-right-square'} icon={faArrowUpRightFromSquare} onClick={() => onNavigate({ row: dataRowValue, dataRecord: dataRecord })} />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : isBool ? (
                                                <>
                                                    <div className={`circle-check-wrapper ${showExcludeTooltip ? 'show-tooltip-wrapper' : ''}`}>
                                                        {(dataRowValue === true || dataRowValue === 'Yes' || dataRowValue === 'yes' || dataRowValue == '1' || dataRowValue == 'true') ?
                                                            <>
                                                            <FontAwesomeIcon className={'fa-circle-check circle-check'} icon={faCircleCheck} />
                                                            </>
                                                            : 
                                                            isAnnounceObservationCol ? 
                                                                <><FontAwesomeIcon className={'fa-regular fa-circle'} icon={faRegularCircle} /></>
                                                            : null
                                                        }
                                                    {showExcludeTooltip && 
                                                            <>
                                                            <div className='exclude-score-tooltip' title='Excluded from Scoring'>
                                                                <FontAwesomeIcon className={'fa-solid fa-circle-exclamation'} icon={faCircleExclamation} />
                                                            </div>
                                                            </>
                                                    }
                                                    </div>

                                                </>
                                            ) :
                                                <span className='text-wrapper' title={dataRowValue}>{dataRowValue}</span>
                                        }
                                    </div>)
                            }
                            return r
                        }
                    }, [])}
                    {
                        (presentActions.length > 0 && !hideActionColumn && !isEmbeddedTableRow) ?
                            <div
                                key={`table_action_${i}`}
                                className={'col actions'}
                                style={{ minWidth: `${(iconCount() * 2) + 6}rem`, width: `${customActionsStyle ? customActionsStyle : 100 - totalColumnLength}%` }}
                            >
                                {(onView && (!checkHideAction || !checkHideAction(dataRecord, 'view'))) ? actionIsLoading.action == 'view' && actionIsLoading.id === dataRecord.Id ? <Loading type='table-spinner' size={'1.25rem'} /> : <FontAwesomeIcon title='View' className={'btn-icon-fa' + doCheckDisableAction(dataRecord, 'view')} icon={faEye} onClick={() => doCheckDisableAction(dataRecord, 'view', onView, dataRecord.Id)} /> : null}
                                {(onEdit && !dataRecord.isEditMode && (!checkHideAction || !checkHideAction(dataRecord, 'edit'))) ? actionIsLoading.action == 'edit' && actionIsLoading.id === dataRecord.Id ? <Loading type='table-spinner' size={'1.25rem'} /> : <FontAwesomeIcon title={editTitle ?? 'Edit'} className={'btn-icon-fa' + doCheckDisableAction(dataRecord, 'edit')} icon={editIcon ?? faPencil} onClick={() => doCheckDisableAction(dataRecord, 'edit', onEdit, dataRecord.Id)} /> : null}
                                {(onSave && dataRecord.isEditMode && (!checkHideAction || !checkHideAction(dataRecord, 'save'))) ? actionIsLoading.action == 'save' && actionIsLoading.id === dataRecord.Id ? <Loading type='table-spinner' size={'1.25rem'} /> : <FontAwesomeIcon title="Save" className={'btn-icon-fa' + doCheckDisableAction(dataRecord, 'save')} icon={faFloppyDisk} onClick={() => doCheckDisableAction(dataRecord, 'save', onSave, dataRecord.Id)} /> : null}
                                {(onDuplicate && (!checkHideAction || !checkHideAction(dataRecord, 'duplicate'))) ? actionIsLoading.action == 'duplicate' && actionIsLoading.id === dataRecord.Id ? <Loading type='table-spinner' size={'1.25rem'} /> : <FontAwesomeIcon title="Duplicate" className={'btn-icon-fa' + doCheckDisableAction(dataRecord, 'duplicate')} icon={faCopy} onClick={() => doCheckDisableAction(dataRecord, 'duplicate', onDuplicate, dataRecord.Id)} /> : null}
                                {(onPrint && (!checkHideAction || !checkHideAction(dataRecord, 'print'))) ? actionIsLoading.action == 'print' && actionIsLoading.id === dataRecord.Id ? <Loading type='table-spinner' size={'1.25rem'} /> : <FontAwesomeIcon title="Print" className={'btn-icon-fa' + doCheckDisableAction(dataRecord, 'print')} icon={faPrint} onClick={() => doCheckDisableAction(dataRecord, 'print', onPrint, dataRecord.Id)
                                } /> : null}

                                {onCustom1 && (!checkHideAction || !checkHideAction(dataRecord, 'custom1')) ? (typeof (onCustom1.action) === 'function' ? <div className={'action ' + doCheckDisableAction(dataRecord, 'custom1')}> {onCustom1.action(dataRecord, dataRows)}</div> : onCustom1.action) : null}
                                {onCustom2 && (!checkHideAction || !checkHideAction(dataRecord, 'custom2')) ? (typeof (onCustom2.action) === 'function' ? <div className={'action ' + doCheckDisableAction(dataRecord, 'custom2')}> {onCustom2.action(dataRecord, dataRows)}</div> : onCustom2.action) : null}
                                {onCustom3 && (!checkHideAction || !checkHideAction(dataRecord, 'custom3')) ? (typeof (onCustom3.action) === 'function' ? <div className={'action ' + doCheckDisableAction(dataRecord, 'custom3')}> {onCustom3.action(dataRecord, dataRows)}</div> : onCustom3.action) : null}
                                {onCustom4 && (!checkHideAction || !checkHideAction(dataRecord, 'custom4')) ? (typeof (onCustom4.action) === 'function' ? <div className={'action ' + doCheckDisableAction(dataRecord, 'custom4')}> {onCustom4.action(dataRecord, dataRows)}</div> : onCustom4.action) : null}
                                {onCustom5 && (!checkHideAction || !checkHideAction(dataRecord, 'custom5')) ? (typeof (onCustom5.action) === 'function' ? <div className={'action ' + doCheckDisableAction(dataRecord, 'custom5')}> {onCustom5.action(dataRecord, dataRows)}</div> : onCustom5.action) : null}

                                {/* {onDisableEnable ?  : null} */}
                                {onDrillDown ? <FontAwesomeIcon className={'btn-icon-fa'} icon={faArrowDown} onClick={() => onDrillDown(dataRecord)} /> : null}
                                {onTransactions ? <FontAwesomeIcon className={'btn-icon-fa'} icon={faReceipt} onClick={() => onTransactions(dataRecord)} /> : null}
                                {(onDelete && (!checkHideAction || !checkHideAction(dataRecord, 'delete'))) ? <FontAwesomeIcon title="Delete" className={'btn-icon-fa' + doCheckDisableAction(dataRecord, 'delete')} icon={faTrashCan} onClick={() => doCheckDisableAction(dataRecord, 'delete', onDelete)} /> : null}
                                {onLogs ? <FontAwesomeIcon className={'btn-icon-fa'} icon={faNoteSticky} onClick={() => onLogs(dataRecord)} /> : null}
                            </div>
                            :
                            null
                    }
                </div>
            )
        }

        let rowAreaStyle = {};
        // if (useIntegratedPaging) {
        //     rowAreaStyle.height = 'calc(100% - (var(--header-height) + var(--footer-height)))';
        // }

        return (
            <div className={'row-area'} style={rowAreaStyle}>
                {dataRows}
            </div>
        );
    };

    const iconCount = () => {
        return (onView ? 1 : 0) +
            (onEdit ? 1 : 0) +
            (onSave ? 1 : 0) +
            (onDuplicate ? 1 : 0) +
            (onDelete ? 1 : 0) +
            (onCustom1 ? 1 : 0) +
            (onCustom2 ? 1 : 0) +
            (onCustom3 ? 1 : 0) +
            (onCustom4 ? 1 : 0) +
            (onCustom5 ? 1 : 0)
    }

    useEffect(() => {
        const handleDocumentClick = (e, isPageSelectionActive) => {
            if (e.target?.getAttribute('pageselection') !== 'true'
                && e.target?.parentElement?.parentElement?.getAttribute('pageselection') !== 'true'
                && e.target?.parentElement?.getAttribute('pageselection') !== 'true') {
                isPageSelectionActive(false);
            }
        }

        document.addEventListener('click', (e) => handleDocumentClick(e, setIsPageSelectionActive));

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        }

    }, []);

    const renderFooter = () => {
        return (<>
            {data?.length > 0 && <div className={'paging-footer'}>
                <div className={'row-count-selection'} pageselection={'true'} onClick={() => setIsPageSelectionActive(!isPageSelectionActive)}>
                    {`Rows ${displayedData}`}
                    <FontAwesomeIcon icon={faCaretDown} className={'caret-down'} />
                    {
                        isPageSelectionActive ?
                            <div pageselection={'true'} className={'page-selection'}>
                                {useIntegratedPaging.map((pageSizeSelectRow, i) => {
                                    return (
                                        <div pageselection={'true'} key={pageSizeSelectRow} className={`page-selection-item${displayedData == pageSizeSelectRow ? ' active' : ''}`}
                                            onClick={() => {
                                                if ((1 + (displayedData * (currentPage - 1))) >= Math.round(data.length / displayedData)) {
                                                    setCurrentPage(1);
                                                    setDisplayedData(parseInt(pageSizeSelectRow));
                                                    localStorage.setItem('NumRows', parseInt(pageSizeSelectRow));
                                                }
                                                else {
                                                    setDisplayedData(parseInt(pageSizeSelectRow));
                                                    localStorage.setItem('NumRows', parseInt(pageSizeSelectRow));
                                                }
                                            }}>
                                            {pageSizeSelectRow}
                                        </div>
                                    )
                                })}
                            </div>
                            :
                            null
                    }
                </div>
                <div className={'page-range'}>
                    {`${1 + (displayedData * (currentPage - 1))} - ${Math.min((displayedData * currentPage), data.length)} of ${data.length}`}
                </div>
                <div className={'page-toggle'}>
                    <FontAwesomeIcon
                        title="Previous Page"
                        icon={faAngleLeft}
                        className={`angle${currentPage === 1 ? ' inactive' : ''}`}
                        onClick={() => {
                            setCurrentPage(Math.max(parseInt(currentPage) - 1, 1))
                            localStorage.setItem('CurrentPage', Math.max(parseInt(currentPage) - 1, 1))
                        }}
                    />
                    <FontAwesomeIcon
                        title="Next Page"
                        icon={faAngleRight}
                        className={`angle${currentPage == Math.ceil(data.length / displayedData) ? ' inactive' : ''}`}
                        onClick={() => {
                            !(currentPage === Math.ceil(data.length / displayedData)) && setCurrentPage(Math.min((parseInt(currentPage) + 1)))
                            !(currentPage === Math.ceil(data.length / displayedData)) && localStorage.setItem('CurrentPage', Math.min((parseInt(currentPage) + 1)))
                        }}
                    />
                </div>
            </div>}
        </>
        )
    };

    return (
        // {`table-wrapper ${embedTableRow ? ' embed-table-row' : ''}`}
        <>
            <div className='table-wrapper'>
                {renderHeader()}
                {sortedData === undefined || sortedData?.length > 0 ? renderRows() : <div className='no-records'><div><h5>{noDataText ? noDataText : 'No Records Found.'}</h5></div></div>}
            </div>
            {useIntegratedPaging ? renderFooter() : null}
        </>
    )
}

TableControl.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.string.isRequired,
        dataProp: PropTypes.string.isRequired,   //corresponds to data[i].someAttribute
        datatype: PropTypes.string, //Types: text, image. Default is text.
        width: PropTypes.string,  //ex: '10.5%'. Not required. Can pass in width to override default behavior...which is to space evenly
        canSort: PropTypes.bool.isRequired
    })).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired, //Must have columns that match
    textAlign: PropTypes.string, //left, center, right. left is default
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onView: PropTypes.func,
    onPrint: PropTypes.func,
    useIntegratedPaging: PropTypes.array, //Array of page sizes
    defaultNumRows: PropTypes.number,
};

export default TableControl;