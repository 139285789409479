import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useObservationManager, useRubricIndicatorManager, useSurveyManager } from '../../../hooks/useManagers';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';
import ObservationDetailsDialog from '../components/management/details';
import './ObservationScripting.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEllipsisVertical, faPencil } from '@fortawesome/free-solid-svg-icons';
import ObservationNote from '../../admin/components/observation/ObservationNote';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { SelectListControl, TextAreaControl } from '../../../components/controls';
import SurveyTable from '../../admin/components/observation/SurveyTable';
import Print from '../components/print';
import FileUploaderControl from '../../../components/controls/FileUploaderControl';
import ContentRecommendation from '../../admin/components/contentLibrary/ContentRecommendation';
import AccessDeny from '../../../components/security/AccessDeny';
import { InputField } from '../../../components/controls/InputField';
import SystemTypes from '../../../SystemTypes';
import { FormatUTCDateTime } from '../../../utilities/DateFormater';
import { FormatUTCDateTimeLong } from '../../../utilities/DateFormater';
import ObservationManager from '../../../managers/ObservationManager';
import Loading from '../../../components/Loading';

const ObservationScripting = ({ isSelfReflection }) => {

    const location = useLocation();

    const observationMgr = useObservationManager(location.pathname.toLowerCase().includes('/teacher/'));
    const rubricIndicatorMgr = useRubricIndicatorManager();
    const surveyMgr = useSurveyManager();
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedObservation, setSelectedObservation] = useState(null);
    const [displayMode, setDisplayMode] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);
    const [isSnappedLeft, setIsSnappedLeft] = useState(true);
    const [isSnappedRight, setIsSnappedRight] = useState(false);
    const [leftWidth, setLeftWidth] = useState(40);
    const [activeTab, setActiveTab] = useState('notes');
    const [greyOutMostNotes, setGreyOutMostNotes] = useState(false);
    const [survey, setSurvey] = useState(null);
    const [printUrl, setPrintUrl] = useState(null);
    const [printing, setPrinting] = useState(false);
    const [isContentRecommendationDialogueActive, setIsContentRecommendationDialogueActive] = useState(false);
    const [isTeacher, setIsTeacher] = useState(location.pathname.toLowerCase().includes('/teacher/'));
    const [message, setMessage] = useState(null);
    const [isNoteinEditMode, setIsNoteinEditMode] = useState(false);
    const [editNoteOpenDialogue, setEditNoteOpenDialogue] = useState(false);

    //permissions
    const [denyAccess, setDenyAccess] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    const FIXED_ROW_WIDTH = '9rem';

    //content state:
    const [observeeData, setObserveeData] = useState({
        observee: '',
        observeDate: '',
        address: '',
        observerInfo: ''
    })

    const [tagMap, setTagMap] = useState(null);
    const [notes, setNotes] = useState(null);
    const [gridData, setGridData] = useState(null);
    const [appliedTagCnt, setAppliedTagCnt] = useState({});
    const [reinforcementObj, setReinforcementObj] = useState({
        surveySectionId: uuidv4(),
        surveySectionType: 3
    });

    const [refinementObj, setRefinementObj] = useState({
        surveySectionId: uuidv4(),
        surveySectionType: 2
    });
    const [ratingSelectOptions, setRatingSelectOptions] = useState({});
    const [indicatorsRated, setIndicatorsRated] = useState(0);
    const [globallyFocusedIndicator, setGloballyFocusedIndicator] = useState(null);
    const [recommendedContent, setRecommendedContent] = useState(null);

    //stop watch
    const [isStopWatchActive, setIsStopWatchActive] = useState(false);
    const [isStopWatchPaused, setIsStopWatchPaused] = useState(false);
    const [time, setTime] = useState(0);

    //flow
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [isNewNoteStaged, setIsNewNoteStaged] = useState(false);
    const [observationConfig, setObservationConfig] = useState(null);
    const [savingRatings, setSavingRatings] = useState({});

    const params = useParams();
    const navigate = useNavigate();


    /*    useEffect(() => {
            console.log('observationConfig', observationConfig);
        }, [observationConfig])*/

    useEffect(() => {
        if (observationMgr && !params?.observationId) {
            navigate('/observations');
        }
        else if (!selectedObservation && params?.observationId && observationMgr) {
            setLoading(true);
            observationMgr.get(params?.observationId).then((r) => {
                if (r.Success) {
                    setDisplayMode('');
                    if (isSelfReflection) {
                        if (r.Items.first()?.SelfReflectionSurvey) {
                            setSurvey({
                                item: r.Items.first().SelfReflectionSurvey
                            });
                        }
                    }
                    else {
                        if (r.Items.first()?.Survey) {
                            setSurvey({
                                item: r.Items.first().Survey
                            });
                        }
                    }

                    if (r.Items.first().ObservationStatusType === SystemTypes.ObservationStatusType.NotStarted || r.Items.first().ObservationStatusType === SystemTypes.ObservationStatusType.SelfReflectionNotStarted) {
                        const selObs = r.Items.first();
                        selObs.ObservationStatusType = isSelfReflection ? SystemTypes.ObservationStatusType.SelfReflectionInPrgress : SystemTypes.ObservationStatusType.InProgress;
                        observationMgr.saveItem(selObs).then(r2 => {
                            if (r2.Success) {
                                setSelectedObservation(selObs);
                                loadObserveeData(selObs);
                            }
                            else {
                                setErrors(r2.MessageDetails);
                            }
                        })
                    }
                    else {
                        setSelectedObservation(r.Items.first());
                        loadObserveeData(r.Items.first());
                    }
                }
            });
        }
    }, [observationMgr, params?.observationId]);

    const loadObserveeData = (obs) => {
        setObserveeData({
            observee: obs?.Observed?.FullName,
            observeDate: obs?.PlannedObservationDateStart,
            address: `${obs?.School?.Name} - Grades: ${obs?.GradeLevelsData?.replace(/^,+|,+$/g, '')} (${obs?.SubjectsTaughtData?.replace(/^,+|,+$/g, '')})`,
            observerInfo: `Observed by ${obs?.Observer?.FullName} (via ${obs.Rubric?.Name} Rubric)`,
            observerId: obs?.Observer?.Id,
            isLegacy: obs.LegacyReferenceId ? true : false
        });
    }

    useEffect(() => {
        if (selectedObservation && observationMgr) {
            if (!isSelfReflection) {
                setDenyAccess(!(observationMgr.canAccessScripting(selectedObservation) && (observationMgr.canAccessEvidence(selectedObservation))));
            }
            else {
                setDenyAccess(!observationMgr.canAccessSelfReflection(selectedObservation));
            }

            if ((!isSelfReflection && observationMgr.canEditScripting(selectedObservation)) || (isSelfReflection && observationMgr.canEditSelfReflection(selectedObservation))) {
                setCanEdit(true);
            }
        }
        if (selectedObservation && !survey) {
            let defaultItem = surveyMgr.DefaultItem(selectedObservation.Observed?.FullName,
                selectedObservation.ClientId, selectedObservation.DistrictId,
                selectedObservation.SchoolId,
                selectedObservation.RubricId
            );
            defaultItem.SchoolYearIdentifier = selectedObservation.SchoolYearIdentifier;
            defaultItem.SurveyType = 1;
            defaultItem.SurveyedUserId = selectedObservation.UserObserved;

            surveyMgr.save(defaultItem).then(r => {
                setSurvey({
                    item: r.Items.first()
                });
                let updatedObservation = isSelfReflection ? { ...selectedObservation, SelfReflectionSurveyId: r.Items.first().Id, Survey: null, District: null, SchoolYear: null, Client: null, School: null, Observer: null, Observed: null, Rubric: null, SelfReflectionSurvey: null }
                    : { ...selectedObservation, SurveyId: r.Items.first().Id, Survey: null, District: null, SchoolYear: null, Client: null, School: null, Observer: null, Observed: null, Rubric: null, SelfReflectionSurvey: null };

                if (updatedObservation.Id) {
                    observationMgr.saveItem(updatedObservation).then(r => {
                        setSelectedObservation(r.Items.first());

                    });
                }
                else {
                    setErrors(['Invalid observation while updating survey.']);
                }
            })
        }
    }, [selectedObservation])

    useEffect(() => {

        if (selectedObservation?.Id && selectedObservation?.Survey && survey && selectedObservation?.Rubric) {
            if (!gridData) {
                setIsTeacher(selectedObservation.ObservationType != 3);
                //isScriptingPageOnly, isSelfReflectionPageOnly, surveyId, rubricId

                observationMgr.getObservationSurveyAndRubric(selectedObservation.Id, isSelfReflection, !isSelfReflection, isSelfReflection, !isSelfReflection ? selectedObservation.Survey?.Id : selectedObservation.SelfReflectionSurvey?.Id, selectedObservation.Rubric?.Id).then(res => {
                    if (res) {
                        observationMgr.observationConfigOperation(selectedObservation.ClientId, selectedObservation.DistrictId, selectedObservation.SchoolId, selectedObservation.SchoolYearIdentifier).then((ocr) => {
                            if (observationMgr.disableSelfReflection(ocr, isTeacher) && isSelfReflection) {
                                goToReviewPage();
                                setLoading(false);
                            }
                            else {
                                setGridData(res.gridData);
                                setTime(res.stopwatch);
                                if (res.tagMap) {
                                    setTagMap(res.tagMap);

                                    let ratingSelectOpts = {};

                                    Object.keys(res.tagMap).forEach(t => {
                                        ratingSelectOpts[res.tagMap[t].name] = {
                                            isExpanded: false, surveyRatingId: res.gridData.data?.reduce((r, cv) => {
                                                if (res.tagMap[t].name === cv.tag?.name) {
                                                    r = cv.surveyRatingId
                                                }
                                                return r;
                                            }, null), selectedRating: res.gridData.data?.reduce((r, cv) => {
                                                if (res.tagMap[t].name === cv.tag?.name && cv.selectedRating !== '') {
                                                    r = cv.selectedRating;
                                                }
                                                return r;
                                            }, null)
                                        };
                                    })

                                    setRatingSelectOptions(ratingSelectOpts);
                                }

                                let actualNotes = [];
                                res.notes.forEach(item => {
                                    if (item.surveySectionType === 1) {
                                        actualNotes.push(item);
                                    }
                                    else if (item.surveySectionType === 3) {//reinforcement
                                        if (item.tagsServerStruct.length > 0) {
                                            let reinforcementToUpdate = JSON.parse(JSON.stringify(reinforcementObj));
                                            reinforcementToUpdate.persistToDb = false;
                                            reinforcementToUpdate.surveySectionId = item.surveySectionId;
                                            reinforcementToUpdate.evidence = item.evidence;
                                            reinforcementToUpdate.indicator = item.tagsServerStruct[0].RubricIndicators[0].ShortName;
                                            reinforcementToUpdate.indicatorId = item.tagsServerStruct[0].RubricIndicators[0].TagId;
                                            reinforcementToUpdate.surveyContentId = item.tagsServerStruct[0].SurveyContentId;
                                            setReinforcementObj(reinforcementToUpdate);
                                        }
                                    }
                                    else if (item.surveySectionType === 2) {//refinement
                                        if (item.tagsServerStruct.length > 0) {
                                            let refinementToUpdate = JSON.parse(JSON.stringify(refinementObj));
                                            refinementToUpdate.persistToDb = false;
                                            refinementToUpdate.surveySectionId = item.surveySectionId;
                                            refinementToUpdate.evidence = item.evidence;
                                            refinementToUpdate.indicator = item.tagsServerStruct[0].RubricIndicators[0].ShortName;
                                            refinementToUpdate.indicatorId = item.tagsServerStruct[0].RubricIndicators[0].TagId;
                                            refinementToUpdate.surveyContentId = item.tagsServerStruct[0].SurveyContentId;
                                            setRefinementObj(refinementToUpdate);
                                        }
                                    }
                                }, []);
                                if (!notes) {
                                    setNotes(actualNotes);
                                }

                                setIsStopWatchActive(!isSelfReflection && selectedObservation.ObservationStatusType < SystemTypes.ObservationStatusType.Submitted);
                                setObservationConfig(ocr);
                                setLoading(false);
                            }
                        });
                    }
                    else {
                        setLoading(false);
                        setErrors(["No data found for observation, if this problem continues please contact NIET Support for assistance."]);
                    }
                })
            }
        }
        else if (selectedObservation && selectedObservation.RubricId == null) {
            setErrors(["No rubric found. Please check the configuration and retry."]);
            setLoading(null);
        }
        else if (selectedObservation && !selectedObservation.Id) {
            setErrors(['No observation identifier found.']);
            setLoading(null);
        }
    }, [selectedObservation, survey]);

    useEffect(() => {
        let interval = null;

        if (isStopWatchActive && isStopWatchPaused === false && canEdit) {
            interval = setInterval(() => {
                setTime((time) => time + 1000);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isStopWatchActive, isStopWatchPaused, canEdit]);


    useEffect(() => {
        if (isStopWatchActive && !isStopWatchPaused) {
            if (time % 5000 === 0) {
                observationMgr.saveStopWatch(selectedObservation.Id, { SurveyId: selectedObservation.SurveyId, StopWatch: time }).then();
            }
        }
    }, [time])

    useEffect(() => {
        let appliedTagCount = {};
        if (notes?.length > 0) {
            notes.forEach(note => {
                if (note.tags && Object.keys(note.tags).length > 0) {
                    Object.keys(note.tags).forEach(tag => {
                        note.tags[tag].forEach(t => {
                            if (appliedTagCount[t]) {
                                appliedTagCount[t]++;
                            } else {
                                appliedTagCount[t] = 1;
                            }
                        })
                    })
                }
                if (note.media?.length > 0) {
                    note.media.forEach(m => {
                        if (m.tags?.length > 0) {
                            m.tags.forEach(t => {
                                if (appliedTagCount[t]) {
                                    appliedTagCount[t]++;
                                } else {
                                    appliedTagCount[t] = 1;
                                }
                            })
                        }
                    })
                }
            });
        }
        setAppliedTagCnt(appliedTagCount);
    }, [notes]);

    useEffect(() => {
        if (tagMap && rubricIndicatorMgr && refinementObj?.indicator && !isSelfReflection) {
            rubricIndicatorMgr.getRecommendedContent(tagMap[refinementObj.indicator].id, selectedObservation.Id).then(res => {
                setRecommendedContent(res.RecommendedContents);
            })
        }
    }, [refinementObj?.indicator, tagMap]);

    const persistObservationNote = (note, surveySectionId, isSummaryNote) => {
        if (!isSummaryNote) {
            let observationNote = {
                "SurveySectionId": note.surveySectionId ?? uuidv4(),
                "SurveySectionType": note.surveySectionType ?? 1,
                "SurveyId": isSelfReflection ? selectedObservation.SelfReflectionSurveyId : selectedObservation.SurveyId,
                "SectionTitle": note.sectionTitle,
                "Evidence": note.evidence,
                "InProgressEvidence": note.inProgressEvidence,
                "ClientId": selectedObservation.ClientId,
                "Time": note.time ?? 0,
                "Tags": Object.keys(note.tags).map(t => {
                    let tag = {};

                    if (note.tagsServerStruct?.SurveyContentId) {
                        tag.SurveyContentId = note.tagsServerStruct.SurveyContentId;
                        tag.Content = t;
                        tag.ContentType = note.tagsServerStruct.ContentType;
                    }
                    else {
                        tag.Content = t;
                        tag.ContentType = 1;
                    }

                    tag.RubricIndicators = note.tags[t].reduce((r, cv) => {
                        r.push({
                            TagId: tagMap[cv].id,
                            Name: tagMap[cv].name,
                            ShortName: cv
                        })
                        return r;
                    }, []);
                    return tag;
                })
            }

            //handle the media... which is really surveycontent... which is more "Tags" in the DTI
            note.media.forEach(item => {

                let surveyContent = {
                    "Content": item.FileUrl,
                    "FileId": item.FileId,
                    "ContentType": 2,
                    "RubricIndicators": item.tags?.reduce((r, cv) => {
                        if (item.FileId) {
                            r.push({
                                TagId: tagMap[cv].id,
                                Name: tagMap[cv].name,
                                ShortName: cv
                            });
                        }
                        return r;
                    }, [])
                }

                if (item.SurveyContentId) {
                    surveyContent.SurveyContentId = item.SurveyContentId;
                }

                observationNote.Tags.push(surveyContent);
            });

            if (note.IsDeleted) {
                observationNote.IsDeleted = true;
            }

            observationMgr.saveObservationNote(selectedObservation.Id, { observationNote }).then(res => {

                if (!observationNote.IsDeleted) {
                    if (res.Success) {
                        // let item = res.Items.first();
                        // let savedNote = {
                        //     id: item.SurveySectionId,
                        //     surveySectionId: item.SurveySectionId,
                        //     time: item.Time,
                        //     sectionTitle: item.SectionTitle,
                        //     evidence: item.Evidence,
                        //     inProgressEvidence: item.InProgressEvidence,
                        //     tags: item.Tags.reduce((r, cv) => {
                        //         if (cv.ContentType === 1) {
                        //             r[cv.Content] = cv.RubricIndicators.map(ri => ri.ShortName);
                        //         }
                        //         return r;
                        //     }, {}),
                        //     tagsServerStruct: item.Tags,
                        //     media: item.Tags.reduce((r, cv) => {
                        //         if (cv.ContentType === 2) {
                        //             r.push({
                        //                 SurveyContentId: cv.SurveyContentId,
                        //                 FileId: cv.FileId,
                        //                 FileUrl: cv.Content,
                        //                 tags: cv.RubricIndicators?.map(ri => ri.ShortName)
                        //             })
                        //         }
                        //         return r;
                        //     }, []),
                        //     isNewNote: false
                        // };
                        // let notesToUpdate = notes.reduce((r, cv) => {
                        //     if ((cv.surveySectionId === savedNote.surveySectionId) || (cv.id === surveySectionId)) {
                        //         r.push(savedNote);
                        //     }
                        //     else {
                        //         r.push(cv);
                        //     }
                        //     return r;
                        // }, []);
                        //setNotes(notesToUpdate);
                    }
                    else {
                        setErrors(['Error saving note. If this problem persists please contact NIET Support.'])
                    }
                }
            })
        }
        else {
            if (reinforcementObj.persistToDb || refinementObj.persistToDb) {
                let observationNote = {
                    "SurveySectionId": note.surveySectionId ?? uuidv4(),
                    "SurveySectionType": note.surveySectionType,
                    "SurveyId": isSelfReflection ? selectedObservation.SelfReflectionSurveyId : selectedObservation.SurveyId,
                    "SectionTitle": note.sectionTitle,
                    "Evidence": note.evidence,
                    "InProgressEvidence": note.evidence,
                    "ClientId": selectedObservation.ClientId,
                    "Time": note.time ?? 0,
                    "Tags": [
                        {
                            "SurveyContentId": note.surveyContentId,
                            "Content": note.evidence,
                            "ContentType": 4,
                            "RubricIndicators": [
                                {
                                    "TagId": tagMap[note.indicator].id,
                                    "Name": tagMap[note.indicator].name,
                                    "ShortName": note.indicator
                                }
                            ]
                        }
                    ]
                }

                observationMgr.saveObservationNote(selectedObservation.Id, { observationNote }).then(res => {
                    if (!res.Success) {
                        setErrors(res.MessageDetails);
                    }
                    if (note.surveySectionType === 3) {
                        const updatereinforcementObj = { ...reinforcementObj };
                        updatereinforcementObj.indicatorId = res.Items?.first()?.Tags?.first()?.RubricIndicators?.first().TagId;
                        updatereinforcementObj.surveySectionId = res.Items?.first().SurveySectionId;
                        updatereinforcementObj.surveyContentId = res.Items?.first().Tags?.first()?.SurveyContentId;
                        updatereinforcementObj.persistToDb = false;
                        setReinforcementObj(updatereinforcementObj);
                    }
                    else if (note.surveySectionType === 2) {
                        const updateRefinementObj = { ...refinementObj };
                        updateRefinementObj.indicatorId = res.Items?.first()?.Tags?.first()?.RubricIndicators?.first().TagId;
                        updateRefinementObj.surveySectionId = res.Items?.first().SurveySectionId;
                        updateRefinementObj.surveyContentId = res.Items?.first().Tags?.first()?.SurveyContentId;
                        updateRefinementObj.persistToDb = false;
                        setRefinementObj(updateRefinementObj);
                    }
                });
            }
        }
    }

    const handleStart = () => {
        setIsStopWatchActive(true);
        setIsStopWatchPaused(false);
    };

    const handlePauseResume = () => {
        setIsStopWatchPaused(!isStopWatchPaused);
    };

    const handleReset = () => {
        setIsStopWatchActive(false);
        setTime(0);
        observationMgr.saveStopWatch(selectedObservation.Id, { SurveyId: selectedObservation.SurveyId, StopWatch: 0 }).then();
    };

    const mouseDownHandler = (mouseDownEvent) => {

        function onMouseMove(mouseMoveEvent) {
            if (!isSelfReflection) {
                if (isSnappedLeft) {
                    setLeftWidth(40);
                    setIsSnappedLeft(false);
                }
                else if (isSnappedRight) {
                    setLeftWidth(100);
                    setIsSnappedRight(false);
                }
                setLeftWidth(Math.max(40, 100 * (mouseMoveEvent.pageX / document.body.clientWidth)))
            }
        }

        function onMouseUp() {
            document.body.removeEventListener("mousemove", onMouseMove);
        }

        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onMouseUp, { once: true });
    }

    const handleOnSave = async (item) => {
        item.Organization = null;
        item.District = null;
        item.School = null;
        item.Survey = null;
        item.Rubric = null;
        item.Observer = null;
        item.Observed = null;
        item.SurveyId = selectedObservation.SurveyId;
        if (item.Id) {
            var result = await observationMgr.saveItem(item);
            if (result.Success) {
                setOpenDetails(false);
                setSelectedObservation(result.Items.first());
            }
            return result;
        }
        else {
            setErrors(["Invalid observation while saving."]);
            return { Success: false };
        }
    }

    const handleOnDialogCancel = (r) => {
        if (r) {
            setSelectedObservation(r.Items.first());
            loadObserveeData(r.Items.first());
        }
        setOpenDetails(false);
        setLoadingError(null);
        setErrors(null);
    }

    useEffect(() => {
        const handleKeyNewNotePress = (e) => {
            if ((e.ctrlKey || e.metaKey) && (e.keyCode == 13 || e.keyCode == 10)) {
                stageNewNote();
                e.preventDefault();
            }
        }

        document.addEventListener('keydown', handleKeyNewNotePress)
        return () => {
            document.removeEventListener('keydown', handleKeyNewNotePress);
        }
    }, [isNewNoteStaged, notes])

    const stageNewNote = () => {
        if (isNewNoteStaged === false) {
            setIsNewNoteStaged(true);
            let timeToSet = time;
            //handleReset();
            let newNote = {
                id: uuidv4(),
                time: timeToSet,
                sectionTitle: '',
                evidence: '',
                tags: {},
                isNewNote: true,
                media: []
            }

            let updatedNotes = [...notes];
            updatedNotes.push(newNote);
            setNotes(updatedNotes);
        }
    }

    const cancelStagedNewNote = (id) => {
        setIsNewNoteStaged(false);
        let updatedNotes = notes.reduce((r, cv) => {
            if (cv.id !== id) {
                r.push(cv);
            }
            return r;
        }, []);
        setNotes(updatedNotes);
    }

    const updateNote = (action, noteObj) => {
        if (action === 'save') {
            let noteToPublish = { ...noteObj };
            if (!noteToPublish.surveySectionId) {
                noteToPublish.surveySectionId = noteToPublish.id;
            }
            setIsNewNoteStaged(false);
            noteToPublish.isNewNote = false;
            let allNotes = JSON.parse(JSON.stringify(notes)).reduce((r, cv) => {
                if (cv.id === noteToPublish.id) {
                    noteToPublish.time = convertDisplayedTimeToIntTime(noteObj.time);
                    r.push(noteToPublish);
                } else {
                    r.push(cv);
                }
                return r;
            }, []);
            setNotes(allNotes);
            persistObservationNote(noteToPublish, noteToPublish.surveySectionId);
        }
        else if (action === 'save-media') {
            let noteToPublish = { ...noteObj };

            let allNotes = JSON.parse(JSON.stringify(notes)).reduce((r, cv) => {
                if (cv.id === noteToPublish.id) {
                    noteToPublish.time = convertDisplayedTimeToIntTime(noteObj.time);
                    r.push(noteToPublish);
                } else {
                    r.push(cv);
                }
                return r;
            }, []);
            setNotes(allNotes);
            persistObservationNote(noteToPublish, noteToPublish.surveySectionId);
        }
        else if (action === 'delete') {
            let allNotes = notes.reduce((r, cv) => {
                if (cv.id !== noteObj.id) {
                    r.push(cv);
                }
                return r;
            }, [])
            setNotes(allNotes);
            noteObj.IsDeleted = true;
            noteObj.time = convertDisplayedTimeToIntTime(noteObj.time)
            persistObservationNote(noteObj);
        }
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (!reinforcementObj.evidence) {
                reinforcementObj.evidence = "";
            }
            if (reinforcementObj.indicator && reinforcementObj.persistToDb) {
                persistObservationNote(reinforcementObj, null, true);
            }
        }, 2500);
        return () => clearTimeout(timeoutId);

    }, [reinforcementObj]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (refinementObj.indicator && refinementObj.persistToDb) {
                if (!refinementObj.evidence) {
                    refinementObj.evidence = "";
                }
                persistObservationNote(refinementObj, null, true);
            }
        }, 2500);

        return () => clearTimeout(timeoutId);

    }, [refinementObj]);

    const updateSummary = (context, field) => {
        if (context.target.value === "UNK") {
            return;
        }
        if (field.FieldName === 'reinforcement-summary-text') {
            let objToUpdate = { ...reinforcementObj }
            objToUpdate.evidence = context.target.value;
            objToUpdate.persistToDb = true;
            setReinforcementObj(objToUpdate);
        }
        else if (field.FieldName === 'reinforcement-summary-select-list') {
            let objToUpdate = { ...reinforcementObj }
            objToUpdate.indicator = context.target.value;
            objToUpdate.persistToDb = true;
            setReinforcementObj(objToUpdate);
        }
        else if (field.FieldName === 'refinement-summary-text') {
            let objToUpdate = { ...refinementObj };
            objToUpdate.evidence = context.target.value;
            objToUpdate.persistToDb = true;
            setRefinementObj(objToUpdate);
        }
        else if (field.FieldName === 'refinement-summary-select-list') {
            let objToUpdate = { ...refinementObj };
            objToUpdate.indicator = context.target.value;
            objToUpdate.persistToDb = true;
            setRefinementObj(objToUpdate);
        }
    }

    const updateIsNextDisabledFromNotes = (value) => {
        setIsNoteinEditMode(value);
    };

    const renderScriptingSideContent = () => {
        return (<>
            {
                !isSelfReflection &&
                <div className={'tabs-section'}>
                    <div className={`tab${activeTab === 'notes' ? ' active' : ''}`} onClick={() => isNoteinEditMode ? setEditNoteOpenDialogue(true) : setActiveTab('notes')}>EVIDENCE</div>
                    <div className={`tab${activeTab === 'summary' ? ' active' : ''}`} onClick={() => isNoteinEditMode ? setEditNoteOpenDialogue(true) : setActiveTab('summary')}>SUMMARY{reinforcementObj.indicator && reinforcementObj.evidence?.length > 0 && refinementObj.indicator && refinementObj.evidence?.length > 0 && <FontAwesomeIcon className={'fa-icon'} icon={faCircleCheck} />}</div>
                </div>
            }
            {activeTab === 'notes' && !isSelfReflection ? <div className={'content-area'}>
                {notes?.length > 0 && notes.map(note => {
                    return (<div key={note.id} className={'note-row'}>
                        <ObservationNote
                            id={note.id}
                            surveySectionId={note.surveySectionId}
                            time={formatTime(note.time, 'child')}
                            sectionTitle={note.sectionTitle}
                            evidence={note.evidence}
                            inProgressEvidence={note.inProgressEvidence}
                            tags={note.tags}
                            onUpdate={updateNote}
                            newNote={note.isNewNote}
                            cancelNewNote={cancelStagedNewNote}
                            greyOutMostNotes={setGreyOutMostNotes}
                            tagMap={tagMap}
                            media={note.media}
                            globallyFocusedIndicator={globallyFocusedIndicator}
                            setGloballyFocusedIndicator={() => setGloballyFocusedIndicator(null)}
                            canEdit={canEdit}
                            setIsNextDisabled={updateIsNextDisabledFromNotes} 
                        />
                    </div>)
                })}
                <div className={'new-note-btn-txt-wrapper'}>
                    <ButtonControl
                        className={`new-note-btn${isNewNoteStaged ? ' disabled' : ''}`}
                        value={'ADD Evidence'}
                        onClick={() => { stageNewNote() }}
                        disabled={isNewNoteStaged || !canEdit}
                        type={'create'}
                    >
                    </ButtonControl>
                    {canEdit && <div className={`new-script-instruction`}>Control + Enter to create new Script Entry</div>}
                </div>
            </div> : <div className={`content-area`}>
                <h1>{isSelfReflection ? 'Self-Reflection Summary' : 'Observation Summary'}</h1>
                <h2>Reinforcement</h2>
                <div className={'drop-down-and-txt-wrapper'}>
                    <label htmlFor={'reinforcement-select'}>Indicator</label>
                    <div id={'reinforcement-select'} className={'select-list-wrapper'}>
                        <div className='select-list-container'>
                            <SelectListControl
                                field={{
                                    FieldName: 'reinforcement-summary-select-list'
                                }}
                                loading={reinforcementObj.persistToDb}
                                textValuePairs={
                                    Object.keys(tagMap ?? {})?.map(item => {
                                        return { Value: item, Text: tagMap[item]?.name }
                                    })
                                }
                                onChange={updateSummary}
                                value={reinforcementObj.indicator}
                                disabled={!canEdit || reinforcementObj.persistToDb}
                            />

                            {(!canEdit || reinforcementObj.persistToDb) &&
                                <>
                                    <div className='loading-container'>
                                        <Loading />
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                    <label htmlFor='text-area-reinforcement'>Objective/Evidence</label>
                    <div id="text-area-reinforcement">
                        <TextAreaControl field={{
                            FieldName: 'reinforcement-summary-text',
                            PlaceHolder: ''
                        }}
                            className={'summary-text-area'}
                            autoGrowHeight={true}
                            onChange={updateSummary}
                            onBlur={() => { }}
                            value={reinforcementObj.evidence}
                            disabled={!canEdit || !reinforcementObj.indicator}
                        />
                    </div>
                </div>
                <h2>Refinement</h2>
                <div className={'drop-down-and-txt-wrapper'}>
                    <label htmlFor={'refinement-select'}>Indicator</label>
                    <div id={'refinement-select'} className={'select-list-wrapper'}>
                        <div className='select-list-container'>
                            <SelectListControl
                                field={{
                                    FieldName: 'refinement-summary-select-list'
                                }}
                                textValuePairs={
                                    Object.keys(tagMap ?? {})?.map(item => {
                                        return { Value: item, Text: tagMap[item]?.name }
                                    })
                                }

                                onChange={updateSummary}
                                value={refinementObj.indicator}
                                disabled={!canEdit || refinementObj.persistToDb}
                            />
                            {(!canEdit || refinementObj.persistToDb) &&
                                <>
                                    <div className='loading-container'>
                                        <Loading />
                                    </div>
                                </>
                            }
                        </div>

                    </div>


                    <label htmlFor='text-area-refinement'>Objective/Evidence</label>
                    <div id='text-area-refinement'>
                        <TextAreaControl field={{
                            FieldName: 'refinement-summary-text',
                            PlaceHolder: ''
                        }}
                            className={'summary-text-area'}
                            autoGrowHeight={true}
                            onChange={updateSummary}
                            onBlur={() => { }}
                            value={refinementObj.evidence}
                            disabled={!canEdit || !refinementObj.indicator}
                        />
                    </div>
                </div>
            </div>}
        </>)
    }

    const toggleRatingOptions = (indicator, closeAllBut) => {

        if (closeAllBut) {
            let ratingSelectOpts = { ...ratingSelectOptions };
            ratingSelectOpts = Object.keys(ratingSelectOpts).reduce((r, cv) => {
                var curSubObj = ratingSelectOpts[cv];
                if (cv !== closeAllBut) {
                    curSubObj.isExpanded = false;
                }
                r[cv] = curSubObj;
                return r;
            }, {})
            setRatingSelectOptions(ratingSelectOpts);
        }
        else {
            let ratingSelectOpts = { ...ratingSelectOptions };
            if (ratingSelectOpts[indicator]) {
                ratingSelectOpts[indicator].isExpanded = !ratingSelectOpts[indicator]?.isExpanded;
                setRatingSelectOptions(ratingSelectOpts);
            }
        }
    }

    const selectRating = (indicator, rating) => {
        if (indicator) {
            const updatedSavingRatings = { ...savingRatings };
            updatedSavingRatings[indicator] = true;
            setSavingRatings(updatedSavingRatings);
        }
        let ratingSelectOpts = { ...ratingSelectOptions };
        ratingSelectOpts[indicator].isExpanded = false;
        ratingSelectOpts[indicator].selectedRating = rating;

        if (selectedObservation.ObservationStatusType === SystemTypes.ObservationStatusType.Submitted || selectedObservation.ObservationStatusType === SystemTypes.ObservationStatusType.SelfReflectionNotStarted) {
            if (!observationMgr.disableSelfReflection(observationConfig, isTeacher)) {
                var updatedSelectedObs = { ...selectedObservation };
                updatedSelectedObs.ObservationStatusType = SystemTypes.ObservationStatusType.SelfReflectionInPrgress;
                if (updatedSelectedObs.Id) {
                    observationMgr.saveItem(updatedSelectedObs).then(r => {
                        if (r.Success) {
                            setSelectedObservation(r.Items.first());
                        }
                    })
                }
                else {
                    setErrors(["Invalid Observation during saving of ratings."]);
                }
            }
        }


        let surveyratingdti = {
            SurveyId: isSelfReflection ? selectedObservation.SelfReflectionSurveyId : selectedObservation.SurveyId,
            RubricRatingId: gridData.metaData.reduce((r, cv) => {
                if (cv.rating == rating) {
                    r = cv.rubricRatingId;
                }
                return r;
            }, null),
            ObservationRatingTypeId: isSelfReflection ? 2 : selectedObservation.ObservationRatingTypeId ?? 0,
            ClientId: selectedObservation.ClientId,
            RubricIndicatorId: Object.keys(tagMap).reduce((r, cv) => {
                if (tagMap[cv].name === indicator) {
                    r = tagMap[cv].id;
                }
                return r;
            }, null),
            ObservationId: selectedObservation.Id,
            IsSelfReflection: isSelfReflection
        }

        if (ratingSelectOpts[indicator].surveyRatingId) {
            surveyratingdti.SurveyRatingId = ratingSelectOpts[indicator].surveyRatingId
        }

        observationMgr.saveRating({ surveyratingdti }).then(res => {
            if (res && res.Success) {
                if (res?.Items?.first()) {
                    let item = res.Items.first();
                    ratingSelectOpts[indicator].surveyRatingId = item.SurveyRatingId;
                    //intentionally not calling the setState function
                    selectedObservation.AvgRating = item.AvgRating;
                    selectedObservation.AvgSelfRating = item.AvgSelfRating;
                }
            }
            const updatedSavingRatings = { ...savingRatings };
            updatedSavingRatings[indicator] = false;
            setSavingRatings(updatedSavingRatings);
        })
        setRatingSelectOptions(ratingSelectOpts);
    }

    useEffect(() => {
        let ir = Object.keys(ratingSelectOptions).reduce((r, cv) => {
            if (ratingSelectOptions[cv].selectedRating) {
                r++;
            }
            return r;
        }, 0);
        setIndicatorsRated(ir);
    }, [ratingSelectOptions])

    useEffect(() => {
        if (indicatorsRated == gridData?.data?.length && (isSelfReflection || (!isSelfReflection && reinforcementObj.evidence && reinforcementObj.indicator && refinementObj.evidence && refinementObj.indicator))) {
            setIsNextDisabled(false);
        }
        else {
            setIsNextDisabled(true);
        }
    }, [indicatorsRated, reinforcementObj?.evidence, reinforcementObj?.indicatorId, refinementObj?.indicatorId, refinementObj?.evidence])

    const [dialogue, setDialogue] = useState({ isOpen: false })

    const toggleDialogue = (indicator) => {

        let abbr = Object.keys(tagMap).reduce((r, cv) => {
            if (tagMap[cv].name === indicator) {
                r = cv;
            }
            return r;
        }, '');

        let sections = [];

        notes.forEach(note => {
            let section = {
                time: formatTime(note.time, 'child'),
                sectionTitle: note.sectionTitle,
                taggedTextEvidence: []
            };

            let sectionContainsTag = false;

            Object.keys(note.tags).forEach(t => {
                if (note.tags[t].includes(abbr)) {
                    section.taggedTextEvidence.push(t);
                    sectionContainsTag = true;
                }
            });

            let mediaEvidence = [];

            note.media?.forEach(m => {
                if (m.tags.includes(abbr)) {
                    mediaEvidence.push({ FileId: m.FileId, FileUrl: m.FileUrl })
                    sectionContainsTag = true;
                }
            })

            if (sectionContainsTag) {
                sections.push({ section, mediaEvidence });
            }
        });

        let evidenceJsx = <div className={'evidence-dialogue'}>
            <div className={'indicator-label'}>
                {indicator}
                <span>{`(${abbr})`}</span>
            </div>
            {sections?.length === 0 && <div className='dialogue-section empty'>
                No evidence found.
            </div>
            }
            {sections.map(s => {
                return (<div className={'dialogue-section'}>
                    <div className={'time-and-title'}>
                        <div className={'time-piece'}>{s.section.time}</div>
                        <div>-</div>
                        <div className={'title-piece'}>{s.section.sectionTitle}</div>
                    </div>
                    <div className={'tagged-text-evidence-area'}>
                        {s.section.taggedTextEvidence.map((te, i) => {
                            return <div className={'tagged-text-evidence'}>{te}<br /></div>
                        })}
                        {/* {s.section.taggedTextEvidence.length === 0 && <div className={'tagged-text-evidence'}>No Text Evidence<br /></div>} */}
                    </div>
                    <div className={'tagged-media-area'}>
                        {s.mediaEvidence.map(m => {
                            return <div
                                key={`${m.FileId}-media-ed`}
                                className={'upload-media-pane'}
                            >
                                <FileUploaderControl
                                    hideControls={true}
                                    disabled={true}
                                    controlId={m.FileId}
                                    maxFileSize={52428800}
                                    fileUrl={m.FileUrl}
                                    canUploadAnyFile={true}
                                    overrideMaxWidth={50}
                                    widthRes={190}
                                    heightRes={90}
                                    forceDim={true}
                                    dontDisplayFileName={true}
                                />
                            </div>
                        })}
                    </div>
                </div>)
            })}
        </div>

        setDialogue(
            {
                isOpen: !dialogue.isOpen,
                children: evidenceJsx
            }
        );
    }

    const renderRubricSideContent = () => {
        return (gridData?.metaData?.length > 0 && gridData?.data?.length > 0 &&
            <div className={`content-area${isSelfReflection ? ' self-reflection' : ''}`}>
                <SurveyTable
                    isSelfReflection={isSelfReflection}
                    indicatorsRated={indicatorsRated}
                    gridData={gridData}
                    appliedTagCnt={appliedTagCnt}
                    ratingSelectOptions={ratingSelectOptions}
                    toggleDialogue={toggleDialogue}
                    toggleRatingOptions={toggleRatingOptions}
                    savingRatings={savingRatings}
                    selectRating={selectRating}
                    fixedRowWidth={FIXED_ROW_WIDTH}
                    globallyFocusedIndicator={globallyFocusedIndicator}
                    setGloballyFocusedIndicator={setGloballyFocusedIndicator}
                    disableActOfRating={!canEdit}
                />
            </div>
        )
    }

    const formatTime = (t, type) => {
        if (type === 'stop-watch') {
            return (
                <div className="stop-watch">
                    <span>
                        {("0" + Math.floor((t / (60000 * 60)) % 60)).slice(-2)} :<span> </span>
                    </span>
                    <span>
                        {("0" + Math.floor((t / 60000) % 60)).slice(-2)} :<span> </span>
                    </span>
                    <span>
                        {("0" + Math.floor((t / 1000) % 60)).slice(-2)}
                    </span>
                </div>
            )
        }
        else if (type === 'child') {
            return `${("0" + Math.floor((t / (60000 * 60)) % 60)).slice(-2)}:${("0" + Math.floor((t / 60000) % 60)).slice(-2)}:${("0" + Math.floor((t / 1000) % 60)).slice(-2)}`;
        }
        else {
            return (
                <div className="stop-watch">
                    <span>
                        {("0" + Math.floor((t / (60000 * 60)) % 60)).slice(-2)}:
                    </span>
                    <span>
                        {("0" + Math.floor((t / 60000) % 60)).slice(-2)}:
                    </span>
                    <span>
                        {("0" + Math.floor((t / 1000) % 60)).slice(-2)}
                    </span>
                </div>
            )
        }
    }

    const convertDisplayedTimeToIntTime = (displayedTime) => {
        const timeArr = displayedTime.split(':');

        let hours = parseInt(timeArr[0]) * (60000 * 60);
        let mins = parseInt(timeArr[1]) * 60000;
        let secs = parseInt(timeArr[2]) * 1000;

        return hours + mins + secs;
    }

    const handleOnPrintReport = async (isDetailed) => {
        setPrinting(true);
        setPrintUrl(`/observation/print${isDetailed ? 'detailed' : ''}/${selectedObservation.Id}?d=${moment().toISOString(true)}`);
    }

    const saveContentRecommendations = () => {
        observationMgr?.saveContentRecommendations(selectedObservation.Id, recommendedContent, true).then(res => {
            goToReviewPage();
        });
    }
    const goToReviewPage = () => {
        if (selectedObservation.ObservationStatusType < 3) {
            if (observationMgr.disableSelfReflection(observationConfig, isTeacher)) {
                selectedObservation.ObservationStatusType = SystemTypes.ObservationStatusType.Submitted;
                if (selectedObservation.Id) {
                    observationMgr.saveItem(selectedObservation).then(r => {
                        if (r.Success) {
                            navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}/review/${selectedObservation.Id}`)
                        }
                    });
                }
            }
            else {
                selectedObservation.ObservationStatusType = SystemTypes.ObservationStatusType.SelfReflectionNotStarted;
                if (selectedObservation.Id) {
                    observationMgr.saveItem({ ...selectedObservation, SelfReflectionNotifyEmailSent: true }).then(r => {
                        if (r.Success) {
                            if (!selectedObservation.SelfReflectionNotifyEmailSent) {
                                observationMgr.emailStatusChange(selectedObservation.Id, {
                                    ObservationId: selectedObservation.Id,
                                    ObservationStatusType: SystemTypes.ObservationStatusType.SelfReflectionNotStarted,
                                    Env: ObservationManager.getEnv()
                                }).then(navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}/review/${selectedObservation.Id}`));
                            } else {
                                console.log('we did not send!')
                                navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}/review/${selectedObservation.Id}`)
                            }
                        }
                    })
                }
                else {
                    setErrors(["Invalid observation while submitting observation."]);
                }
            }
        }
        else {
            navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}/review/${selectedObservation.Id}`);
        }
    }

    return (
        denyAccess ? <AccessDeny /> :
            <>
                {printUrl && <Print printUrl={printUrl} onPrinting={() => setPrinting(false)} />}
                <ObservationDetailsDialog editMode={true} openDialog={openDetails} observation={selectedObservation} onCancel={handleOnDialogCancel} onSave={handleOnSave} onLoading={setLoading} onErrors={setErrors} onValidationErrors={setValidationErrors} onLoadingError={setLoadingError} />
                <MainLayout messages={message} onMessageClosed={() => navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}`)} errors={errors} className={'observation-page'} validationErrors={validationErrors} >
                    <ScreenWrapper className={'observation-screen-wrapper'} loading={loading} loadingError={loadingError} onReturn={() => { alert('What?') }}>
                        <DialogControl
                            openDialog={dialogue.isOpen}
                            title={dialogue.title}
                            subTitle={''}
                            onOk={() => { setDialogue({ isOpen: false }) }}
                            onCancel={() => { setDialogue({ isOpen: false }) }}
                            okText={'CLOSE'}
                            cancelText={'CLOSE'}
                            excludeCancel={true}
                        >
                            {dialogue.children}
                        </DialogControl>
                        <DialogControl
                            openDialog={editNoteOpenDialogue}
                            title={'Evidence Tab Open'}
                            subTitle={'Please save work in the Evidence Tab before continuing.'}
                            onCancel={() => { setEditNoteOpenDialogue(false) }}
                            cancelText={'CLOSE'}
                            excludeCancel={true}/>
                        <ContentRecommendation
                            isActive={isContentRecommendationDialogueActive}
                            recommendedContentItems={recommendedContent}
                            indicatorName={refinementObj?.indicator ? tagMap[refinementObj?.indicator]?.name : null}
                            observee={observeeData.observee}
                            observer={{ name: observeeData.observerInfo, Id: observeeData.observerId }}
                            showMessage={false}
                            onClose={() => {
                                setIsContentRecommendationDialogueActive(false);
                            }}
                            recommend={(contentId) => {
                                let content = [...recommendedContent]
                                content = content.reduce((r, cv) => {
                                    if (cv._id === contentId) {
                                        cv.Recommended = !cv.Recommended
                                    }
                                    r.push(cv);
                                    return r;
                                }, []);

                                setRecommendedContent(content);
                            }}
                            onSend={() => {
                                saveContentRecommendations();
                            }
                            }
                            onSkip={goToReviewPage}
                            canMakeSelections={observationMgr?.canViewRecommendedContent(selectedObservation) && observationMgr?.canEditRecommendedContent(selectedObservation)}
                        />
                        <div className={`observation-subheader`}>
                            <div className={'observee-area'}>
                                <div className={'observee-banner'}>
                                    <div className={'text'} style={isSelfReflection ? { cursor: 'default' } : {}}>{observeeData?.observee}</div>
                                    {!isSelfReflection && canEdit && <FontAwesomeIcon title="Edit Observation" icon={faPencil} onClick={() => setOpenDetails(true)} className={'edit-icon'} />}
                                </div>
                                <div className={'observee-metadata'}>
                                    <div>{observeeData?.isLegacy ? moment(observeeData?.observeDate).format('MMMM D, YYYY @ h:mm A') : FormatUTCDateTimeLong(observeeData?.observeDate)}</div>
                                    <div>{observeeData?.address}</div>
                                    <div>{observeeData?.observerInfo}</div>
                                </div>
                            </div>
                            {
                                !isSelfReflection &&
                                <div className={'timer-area'}>
                                    {formatTime(time, 'stop-watch')}
                                    <div className={'control-btns'}>
                                        <div className={`btn${!canEdit ? ' disable' : ''}`} onClick={() => {
                                            if (canEdit) {
                                                if (isStopWatchActive) {
                                                    handlePauseResume();
                                                } else {
                                                    handleStart();
                                                }
                                            }
                                        }}>{isStopWatchActive ? isStopWatchPaused ? 'RESUME' : 'PAUSE' : 'START'}</div>
                                        <div className={`btn${!canEdit ? ' disable' : ''}`} onClick={() => {
                                            if (canEdit) {
                                                handleReset()
                                            }
                                        }}>RESET</div>
                                    </div>
                                </div>
                            }
                            <div className='control-box-right-header'>
                                <div className={`control-button-area${isSelfReflection ? ' self-reflection' : ''}`}>
                                    {!isSelfReflection && <ButtonControl loading={printing} type={'cancel'} value={'PRINT'} className={'btn'} onClick={() => handleOnPrintReport(true)} />}
                                    {(!(selectedObservation?.ObservationStatusType == SystemTypes.ObservationStatusType.SelfReflectionSubmitted && isSelfReflection)
                                        || !isSelfReflection)
                                        && <ButtonControl value={!observationMgr?.isObservationComplete(selectedObservation) ? 'CONTINUE LATER' : 'EXIT'}
                                            type={'cancel'} className={'btn continue-later-btn'} 
                                            onClick={() => {
                                                if(isNoteinEditMode) {
                                                    setEditNoteOpenDialogue(true);
                                                }
                                                else {
                                                    navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}`);
                                                }
                                            }} />}
                                    <ButtonControl
                                        value={observationMgr?.isObservationComplete(selectedObservation) ? 'SAVE' : (isSelfReflection ? (selectedObservation?.ObservationStatusType === SystemTypes.ObservationStatusType.SelfReflectionSubmitted ? 'SAVE & EXIT' : 'SUBMIT') : 'NEXT')}
                                        type={'okay'}
                                        className={`btn${isNextDisabled ? ' disabled' : ''}`}
                                        disabled={isNextDisabled}
                                        onClick={() => {
                                            if(isNoteinEditMode) {
                                                setEditNoteOpenDialogue(true);
                                            }
                                            else if (!isSelfReflection) {
                                                selectedObservation.Survey.SurveyStatusType = SystemTypes.SurveyStatusType.Completed;
                                                if (selectedObservation.Survey) {
                                                    selectedObservation.Survey.SurveyedUserId = selectedObservation.UserObserved;
                                                    surveyMgr.save(selectedObservation.Survey);
                                                }
                                                if (observationMgr.canViewRecommendedContent(selectedObservation)) {
                                                    //TODO: logic around recommonded contet needs updating, disable for now.
                                                    //setIsContentRecommendationDialogueActive(true);
                                                    goToReviewPage()
                                                }
                                                else {
                                                    navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}/SelfReflection/${selectedObservation.Id}`);
                                                }
                                            }
                                            else {
                                                let selectedObsv = JSON.parse(JSON.stringify(selectedObservation));
                                                if (selectedObsv.ObservationStatusType < SystemTypes.ObservationStatusType.SelfReflectionSubmitted) {
                                                    selectedObsv.ObservationStatusType = SystemTypes.ObservationStatusType.SelfReflectionSubmitted;
                                                    selectedObsv.District = null;
                                                    selectedObsv.Observed = null;
                                                    selectedObsv.Observer = null;
                                                    selectedObsv.Organization = null;
                                                    selectedObsv.Rubric = null;
                                                    selectedObsv.School = null;
                                                    selectedObsv.SelfReflectionSurvey = null;
                                                    selectedObsv.Survey = null;
                                                    if (selectedObsv.Id) {
                                                        observationMgr.saveItem(selectedObsv).then(res => {
                                                            const savedItem = res.Items?.first();
                                                            if (res.Success && savedItem) {
                                                                savedItem.SelfReflectionSurvey.SurveyStatusType = SystemTypes.SurveyStatusType.Completed;
                                                                setSelectedObservation(savedItem);
                                                                if (savedItem.SelfReflectionSurvey) {
                                                                    surveyMgr.save(savedItem.SelfReflectionSurvey);
                                                                }
                                                            }

                                                            if (selectedObsv.ObservationStatusType >= SystemTypes.ObservationStatusType.PostConference) {
                                                                navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}/Review/${selectedObservation.Id}`);
                                                            }
                                                            else {
                                                                observationMgr.emailStatusChange(selectedObservation.Id, {
                                                                    ObservationId: selectedObservation.Id,
                                                                    ObservationStatusType: SystemTypes.ObservationStatusType.SelfReflectionSubmitted,
                                                                    Env: ObservationManager.getEnv()
                                                                }).then(() => {
                                                                    setMessage([<b>Your self-reflection scores have been successfully submitted!</b>, 'Please wait on communication from your observer for information about your Post-Conference.']);
                                                                });
                                                            }
                                                        });
                                                    }
                                                    else {
                                                        setErrors(["Invalid observation during self-reflection submit."]);
                                                    }
                                                }
                                                else {
                                                    if (selectedObsv.ObservationStatusType >= SystemTypes.ObservationStatusType.PostConference) {
                                                        navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}/Review/${selectedObservation.Id}`);
                                                    }
                                                    else {
                                                        navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}`);
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                {(selectedObservation?.ObservationStatusType == SystemTypes.ObservationStatusType.InProgress && !isSelfReflection) &&
                                    <div className={'observation-message'}>
                                        After scripting the observation in the <b>Evidence Tab</b> on the left, use the rating dropdowns in the rubric below to rate each indicator. Then, be sure to complete the <i>Observation Summary</i> under the <b>Summary Tab</b> to activate the <b>Next</b> button.
                                    </div>
                                }
                                {(selectedObservation?.ObservationStatusType == SystemTypes.ObservationStatusType.SelfReflectionInPrgress && isSelfReflection) &&
                                    <div className={'observation-message'}>
                                        Use the rating dropdowns in the rubric below to rate each indicator. Then, you may complete the <i>Self-Reflection Summary</i> prior to submitting your Self-Reflection using the <b>Submit</b> button.
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={`content-section`}>
                            {!isSelfReflection &&
                                <div className={'script-section'} style={isSnappedLeft ? { width: '40%' } : (isSnappedRight ? { width: `calc(100% - calc(16px + ${FIXED_ROW_WIDTH}))` } : { width: `${leftWidth}%`, maxWidth: `calc(100% - calc(16px + ${FIXED_ROW_WIDTH}))` })}>
                                    {greyOutMostNotes ? <div className={'grey-out-most-notes'}></div> : null}
                                    {!isSelfReflection && renderScriptingSideContent()}
                                </div>
                            }
                            <div className={`rubric-section`} style={isSnappedRight ? { width: `calc(${FIXED_ROW_WIDTH} + 16px)` } : (isSnappedLeft ? { width: '60%' } : { width: `${100 - leftWidth}%` })}>
                                {!isSelfReflection &&
                                    <div
                                        className={`drag-me${isSelfReflection ? ' no-drag' : ''}`}
                                        onMouseDown={mouseDownHandler}
                                        onDoubleClick={(e) => {
                                            if (!isSelfReflection) {
                                                let isLeft = isSnappedLeft;
                                                let isRight = isSnappedRight;

                                                if (isLeft || isRight) {
                                                    setIsSnappedLeft(!isLeft);
                                                    setIsSnappedRight(isLeft);
                                                } else {
                                                    if (e.pageX < ((1.6 * document.body.clientWidth) / 2)) {
                                                        setIsSnappedRight(true);
                                                    } else {
                                                        setIsSnappedLeft(true);
                                                    }
                                                }
                                            }
                                        }}>
                                        {!isSelfReflection && <FontAwesomeIcon icon={faEllipsisVertical} className={'person-icon'} />}
                                    </div>
                                }
                                {renderRubricSideContent()}
                            </div>
                            {
                                isSelfReflection &&
                                <div className={'self-reflection-summary-section'} style={{ width: '40%' }}>
                                    {renderScriptingSideContent()}
                                </div>
                            }
                        </div>
                    </ScreenWrapper>
                </MainLayout>
            </>
    );
}

export default ObservationScripting;