//https://blog.logrocket.com/simplify-react-state-management-with-hookstate/
import { hookstate } from '@hookstate/core'

const globalStagedUserProfileStore = hookstate({
    allUsers: null,
    filteredUsers: [],
    forceUpdate: false,
    userFilter: { Search: '', EEPass: '', IsActive: '', userInGroup: [], JobTitle: '' }
});

export default globalStagedUserProfileStore;