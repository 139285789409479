import React, { useState, useEffect } from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { SelectListControl } from '../../../../components/controls';

export default function CertificationQuestionFilter({ certificationQuestionMgr, onFilterChange, certType }) {

    useEffect(() => {
        handleClearFilter();
    }, [certType])

    const handleFilterChange = (value, field) => {
        if (onFilterChange) {
            onFilterChange(field, value);
        }
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        certificationQuestionMgr.filterData({ search: null, isActive: 'UNK', isDefault: 'UNK' });
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    {certType === "teacher" ? <div className="filterTitle">Teacher Evaluator Certification Management</div> : <div className="filterTitle">Administrator Evaluator Certification Management</div>}
                    <div className="filterControl">
                        <InputField
                            id='certificationQuestion'
                            title='Question'
                            value={certificationQuestionMgr?.Filter?.search ?? ''}
                            fieldName='search'
                            disableError={true}
                            placeholder='Search'
                            onChange={handleFilterChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField id='isActive'
                            title='Active?'
                            value={certificationQuestionMgr?.Filter?.isActive ?? 'UNK'}
                            fieldName='isActive'
                            disableError={true}
                            onChange={handleFilterChange}>
                            <SelectListControl textValuePairs={[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]} />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField id='isDefault'
                            title='Default?'
                            value={certificationQuestionMgr?.Filter?.isDefault ?? 'UNK'}
                            fieldName='isDefault'
                            disableError={true}
                            onChange={handleFilterChange}>
                            <SelectListControl textValuePairs={[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]} />
                        </InputField>
                    </div>
                    <div className="filterControl  btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}