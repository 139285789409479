import { useState } from 'react';
import React, { useEffect } from 'react';

const CheckboxControl = ({
    field,
    fieldName,
    value,
    onChange,
    onBlur,
    className,
    disabled,
    sequence,
    loading,
    props
}) => {

    let id = "field_cbx_" + field?.FieldName ?? fieldName + "_" + sequence ?? 1;
    const isChecked = value !== undefined && value !== null ? value == '1' || value === true || value.toString().toLowerCase() === 'true' || value.toString().toLowerCase() === 'yes' || value.toString().toLowerCase() === 'y' : false;
    return <div className={`cbx_control ${className ?? ''}`}>
        <div>
            <input
                id={id}
                key={id}
                type="checkbox"
                onBlur={(context) => onBlur ? onBlur(context, field) : null}
                checked={isChecked}
                disabled={disabled}
                onChange={(context) => {
                    if (onChange) {
                        onChange({ ...context, value: context.target.checked, target: { ...context.target, value: context.target.checked } }, field)
                    }
                }}
                {...props} />
        </div>
    </div>
}

export default CheckboxControl;