import { ItemServiceFactory } from "../services/ItemServiceFactory";
import ItemManager from "./ItemManager";
import Manager from "./Manager";
import ErrorUtil from '../domains/contentLibrary/utils/ErrorUtil';
  


export default class RubricIndicatorManager extends ItemManager {

    constructor(rubricState, globalAuthState, globalAppUserState) {
        super(ItemServiceFactory.ItemServiceEnum.RubricIndicator, globalAuthState, globalAppUserState, rubricState);
        this._globalAuthState = globalAuthState;
        this._rubricState = rubricState;
    }

    async getRecommendedContent(rubricIndicatorId, observationId, forObserved) {
        return this.runOperation('GetRecommendedContent', rubricIndicatorId, {
            recommendedcontentrequest: {
                RubricIndicatorId: rubricIndicatorId,
                ObservationId: observationId,
                ForObserved: forObserved ?? false
            }
        }).then(async r => {
          if (r?.Items?.first()) {
            return await r.Items.first();
          }
          else {
            return null;
          }
        })
      }

}