import { ItemServiceFactory } from '../services/ItemServiceFactory';
import ItemManager from './ItemManager';
import _ from 'lodash';
import moment from 'moment';

class SurveyManager extends ItemManager {
    constructor(surveyType, globalItemState, globalAuthState, globalAppUserState) {
        super(ItemServiceFactory.ItemServiceEnum.Survey, globalAuthState, globalAppUserState, globalItemState,
            (filter, item) => {
                if (filter) {
                    const hasMessageTypeId = item.MessageTypeId?.toString().toLowerCase()?.includes(filter?.MessageTypeId?.toLowerCase()) || (filter?.MessageTypeId === undefined || filter?.MessageTypeId === null || filter?.MessageTypeId === '');
                    const hasEventName = item.EventName?.toLowerCase()?.includes(filter?.EventName?.toLowerCase()) || (filter?.EventName === undefined || filter?.EventName === null || filter?.EventName === '');
                    const hasItemLevel = item.ItemLevel?.toString().toLowerCase()?.startsWith(filter?.ItemLevel?.toLowerCase()) || (filter?.ItemLevel === undefined || filter?.ItemLevel === null || filter?.ItemLevel === 'UNK');
                    const hasCreatedBy = item.CreatedBy?.toString().toLowerCase()?.startsWith(filter?.CreatedBy?.toLowerCase()) || (filter?.CreatedBy === undefined || filter?.CreatedBy === null || filter?.CreatedBy === '');
                    const hasCreatedDate = (filter?.CreatedDate ? new Date(item.CreatedDate) >= new Date(filter?.CreatedDate) : false) || (filter?.CreatedDate === undefined || filter?.CreatedDate === null || filter?.CreatedDate === '');
                    const hasStatus = (filter?.status == 2 ? item?.SurveyStatusType == 3 : filter?.status == 1 ? item?.SurveyStatusType == 2 || item?.SurveyStatusType == 1 : item?.SurveyStatusType == 0 || item?.SurveyStatusType == undefined) || (filter?.status === undefined || filter?.status === null || filter?.status === '' || filter?.status === "UNK");
                    const hasSearch = item.Name?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || (filter?.search === undefined || filter?.search === null || filter?.search === '');
                    const hasAssignedTo = item?.SurveyedUser?.FullName?.toLowerCase()?.includes(filter?.assignedTo?.toLowerCase()) || (filter?.assignedTo === undefined || filter?.assignedTo === null || filter?.assignedTo === '');
                    const ExculdeFromOverallScoring = item?.ExculdeFromOverallScoring === filter?.ExculdeFromOverallScoring || (filter?.ExculdeFromOverallScoring === undefined || filter?.ExculdeFromOverallScoring === null || filter?.ExculdeFromOverallScoring === 'UNK')
                    const hasOrg = !filter?.organizations || filter?.organizations.length === 0 || _.find(filter?.organizations ?? [], treeId => treeId.indexOf("o-") === 0 && treeId.substr(2).toLowerCase() === item?.OrganizationId?.toLowerCase());                
                    const hasSchool = !filter?.organizations || filter?.organizations.length === 0 || _.find(filter?.organizations ?? [], treeId => treeId.indexOf("s-") === 0 && treeId.substr(2).toLowerCase() === item?.SchoolId?.toLowerCase());
                    const hasDistrict = !filter?.organizations || filter?.organizations.length === 0 || _.find(filter?.organizations ?? [], treeId => treeId.indexOf("d-") === 0 && treeId.substr(2).toLowerCase() === item?.DistrictId?.toLowerCase());
                    return (hasOrg || hasDistrict || hasSchool) && hasSearch && ExculdeFromOverallScoring && hasStatus && hasAssignedTo && hasMessageTypeId && hasEventName 
                            && hasItemLevel && hasCreatedBy && hasCreatedDate;
                } else
                    return true;
    
            }, async (params) => {
                return await this.runOperation("AdminTeacherSuvey", undefined, undefined, surveyType, false, true)
            }
        );
        this._surveyType = surveyType
        this._errorUtil = undefined;
    }


    async getTeacherAdminSurvey(id) {
        return await this.runOperation("AdminTeacherSuvey", id, undefined, this._surveyType, false, true)
    }

    async saveTeacherAdminSurvey(item) {
        const result = await this.runOperation("UpdateSurvey", undefined, item, null, false, true)
        if (result.Success) {
            return result.Items
        } else return []
    }

    async saveGeneralSurvey(item, usmid) {
        const result = await this.runOperation("UpdateSurvey", undefined, item, { usmid }, false, true)
        if (result.Success) {
            return result.Items
        } else return []
    }

    DefaultItem(observedName, ClientId, DistrictId, SchoolId, RubricId) {
        let globalAppUserState = this._globalAppUserState.get();
        return {
            Name: `Observation of ${observedName}`,
            DistrictId,
            SchoolId,
            UserId: globalAppUserState.userProfile.Id,
            SurveyDate: moment().utc().toDate().toJSON(),
            ActualSurveyDateStart: moment().utc().toDate().toJSON(),
            StopWatch: '0',
            RubricId,
            SurveyStatusType: 1,
            ClientId,
            SchoolYearId: globalAppUserState.schoolYearMap[globalAppUserState.selectedSchoolYear].SchoolYearId
        }
    }
}

export default SurveyManager;