import _ from "lodash";
import moment from "moment";
import react, { useState } from "react";
import { useEffect } from "react";
import { faClose as completeIcon } from '@fortawesome/free-solid-svg-icons'
import { TableControl } from "../../../../components/controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormatUTCDate, FormatUTCDateTime } from "../../../../utilities/DateFormater";
import { useOrgManager } from "../../../../hooks/useManagers";
import EmailIcon from '@mui/icons-material/Email';


const GeneralSurveyTable = ({ canWrite, canDelete, filteredItems, onEdit, onDelete, sendEmail }) => {
    const [rows, setRows] = useState([]);

    const [columns] = useState([
        {
            header: 'Title',
            dataProp: 'Name',
            width: '25%',
            canSort: true
        },
        {
            header: 'Description',
            dataProp: 'Description',
            width: '30%',
            canSort: true
        },
        {
            header: 'Start Date',
            dataProp: 'StartDate',
            datatype: 'dateTime',
            width: '15%',
            canSort: true
        },
        {
            header: 'Active?',
            dataProp: "IsActive",
            width: '5%',
            isBool: true,
            canSort: false
        },
        {
            header: 'Create Date',
            dataProp: 'CreatedDate',
            width: '10%',
            datatype: 'dateTime',
            canSort: true
        }
    ]);

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (st) => {
                newRows.push({
                    ...st
                });
            });
            setRows(newRows);
        }
    }, [filteredItems]);
    const handleCheckDisableAction = (row, action) => {
        if (action.toLowerCase() == 'custom1') {
            return row.IsActive === false || new Date() < new Date(moment.utc(row.StartDate).local())
        }
        else {
            return false;
        }
    }

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                useIntegratedPaging={['10', '25', '50', '100']}
                checkDisableAction={handleCheckDisableAction}
                onEdit={(row) => onEdit(row)}
                onDelete={canDelete && onDelete}
                onCustom1={{
                    action: (row) => <div className='icon-wrapper' title="Send Survery Request">
                        <EmailIcon className='icon' onClick={() => {
                            if (!sendEmail(row)(row, 'custom1')) {
                                sendEmail(row)
                            }
                        }} />
                    </div>
                }}
            />
        </div>
    </>
}

export default GeneralSurveyTable;