import { LogItemService } from "../services/ItemServices";
import LocalStorageUtility from "../utilities/LocalStorageUtility";
import ConfigData from "../config.json"
   


class Manager {
    constructor(globalAuthState, globalAppUserState, globalErrorState) {
        if (globalAuthState != null)
            this._logSvc = new LogItemService(globalAuthState);

        this._globalAppUserState = globalAppUserState;
        this._globalAuthState = globalAuthState;
        if (globalErrorState)
            this._globalErrorState = globalErrorState;
        this._localStorageUtil = new LocalStorageUtility()
    }

    get AppUserState() {
        return this._globalAppUserState.get({ noproxy: true });
    }

    get AuthState() {
        return this._globalAuthState.get({ noproxy: true });
    }


    WriteToConsole(type, location, details, info) {

        let style = "";
        if (type === "debug") {
            style = "color:orange; font-size:14px";
        }
        else if (type === "error") {
            style = "color:red; font-size:14px";
        }
        else if (type === "time") {
            style = "color:gray; font-size:14px";
        }
        else {
            style = "color:blue; font-size:14px";
        }

        const today = new Date();
        if (ConfigData.LOGGING.DEFAULT_CONSOLE_ON) {
            console.log("%c" + (today.getHours() < 10 ? ("0" + today.getHours()) : today.getHours()) + ":" + (today.getMinutes() < 10 ? ("0" + today.getMinutes()) : today.getMinutes()) + ":" + (today.getSeconds() < 10 ? ("0" + today.getSeconds()) : today.getSeconds()) + "." + today.getMilliseconds() + " %c[" + location + "]\r\n %c" + details + (info ? " : " + info : ""), "color:darkgray;font-size:14px;font-weight:bold;", "color:darkgray;font-size:14px;", style);
        }
    }

    HandleErrorResult(errorResult) {
        if (this._globalErrorState) {

            //TODO: Figure this out..
            this._globalErrorState.merge([errorResult]);
        }
    }

    WriteDebugLog(location, details, info) {
        if (ConfigData.LOGGING.DEFAULT_DEBUG_ON || this._globalAppUserState.UserProfile.FrontEndLogInfo?.indexOf(0) > -1) {
            this._logSvc.save({
                Location: location,
                MessageDetails: details,
                MessageTypeId: 1003,
                Info: info,
                Message: null,
                LogLevel: 0
            });
        }

        this.WriteToConsole("debug", location, details, info);
    }

    WriteInfoLog(location, details, info) {
        if (ConfigData.LOGGING.DEFAULT_INFO_ON || this._globalAppUserState.UserProfile.FrontEndLogInfo?.indexOf(1) > -1) {
            this._logSvc.save({
                Location: location,
                MessageDetails: details,
                MessageTypeId: 1002,
                Info: info,
                Message: null,
                LogLevel: 1
            });
        }
        this.WriteToConsole("info", location, details, info);
    }

    WriteErrorLog(location, details, info) {
        if (ConfigData.LOGGING.DEFAULT_ERROR_ON || this._globalAppUserState.UserProfile.FrontEndLogInfo?.indexOf(4) > -1) {
            this._logSvc.save({
                MessageTypeId: 3025,
                Location: location,
                MessageDetails: details,
                Info: info,
                Message: null,
                LogLevel: 4
            });
        }

        this.WriteToConsole("error", location, details, info);
    }


    WriteTimingLog(location, details, info, startDateTime, endDateTime) {
        if (ConfigData.LOGGING.DEFAULT_TIMING_ON || this._globalAppUserState.UserProfile.FrontEndLogInfo?.indexOf(2) > -1) {
            this._logSvc.save({
                MessageTypeId: 1002,
                Location: location,
                MessageDetails: details,
                Info: info,
                LogLevel: 2,
                StartTime: startDateTime,
                endDateTime: endDateTime,
                Message: null

            });
        }

        this.WriteToConsole("time", location, details, info);
    }
}

export default Manager;