import React, { useEffect } from 'react';
import { useHookstate } from '@hookstate/core';
import PublicLayout from './layout/PublicLayout';

const Four0Four = () => {
    const render404Error = <PublicLayout>
        <div className='four0four'>
            <h2>Page not found.</h2>
        </div>
    </PublicLayout>
    return render404Error;
}

export default Four0Four;