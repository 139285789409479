const treeViewUserSelect = (activeNode, vals, selectedUserCallback, initalSelectedUserIds, newSelectedUserIds) => {
    if (newSelectedUserIds == undefined)
        newSelectedUserIds = JSON.parse(JSON.stringify(initalSelectedUserIds));

    if (vals.length == 0) {
        newSelectedUserIds = [];
        selectedUserCallback(newSelectedUserIds);
    }
    else if (activeNode.NodeType == "User" && newSelectedUserIds.indexOf(activeNode.UserId) > -1 && !activeNode.check) {
        let index = newSelectedUserIds.indexOf(activeNode.UserId);
        if (index > -1) {
            newSelectedUserIds.splice(index, 1);
            selectedUserCallback(newSelectedUserIds);
        }
    }
    else if (activeNode.check || (activeNode.parentNode != undefined && (activeNode.parentNode.checkAll || activeNode.parentNode.check))) {
        if (activeNode.NodeType == "User" && (activeNode.check || (activeNode.parentNode !== undefined && (activeNode.parentNode.checkAll || activeNode.parentNode.check)))) {
            if (newSelectedUserIds.indexOf(activeNode.UserId) == -1) {
                newSelectedUserIds.push(activeNode.UserId);
                selectedUserCallback(newSelectedUserIds);
            }
        }
        else if (activeNode.Children != null && activeNode.Children.length > 0) {
            activeNode.Children.forEach((chd) => {
                treeViewUserSelect(chd, vals, selectedUserCallback, initalSelectedUserIds, newSelectedUserIds);
            });
        }
    }
}

const treeViewSelect = (activeNode, vals, selectedCallback, initalSelectedIds, newSelectedIds, primaryNodeType, IdName) => {
    if (newSelectedIds)
        newSelectedIds = JSON.parse(JSON.stringify(initalSelectedIds));

    if (vals.length == 0) {
        newSelectedIds = [];
        selectedCallback(newSelectedIds);
    }
    else if (activeNode.NodeType == primaryNodeType && newSelectedIds.indexOf(activeNode[IdName]) > -1 && !activeNode.check) {
        let index = newSelectedIds.indexOf(activeNode[IdName]);
        if (index > -1) {
            newSelectedIds.splice(index, 1);
            selectedCallback(newSelectedIds);
        }
    }
    else if (activeNode.check || (activeNode.parentNode != undefined && (activeNode.parentNode.checkAll || activeNode.parentNode.check))) {
        if (activeNode.NodeType == primaryNodeType && (activeNode.check || (activeNode.parentNode !== undefined && (activeNode.parentNode.checkAll || activeNode.parentNode.check)))) {
            if (newSelectedIds.indexOf(activeNode[IdName]) == -1) {
                newSelectedIds.push(activeNode[IdName]);
                selectedCallback(newSelectedIds);
            }
        }
        else if (activeNode.Children != null && activeNode.Children.length > 0) {
            activeNode.Children.forEach((chd) => {
                treeViewSelect(chd, vals, selectedCallback, initalSelectedIds, newSelectedIds, primaryNodeType, IdName);
            });
        }
    }
}



export { treeViewUserSelect, treeViewSelect };




