import './Token.css';
import React, { useState, useEffect, useContext } from 'react';
import { ThemeProviderContext } from '../../../contexts/ThemeProvider';
import { useAuthState } from '../../../hooks/useAuth';
import globalAuthStateStore from '../../../stores/globalAuthStateStore';
import { useHookstate } from '@hookstate/core';
import MainLayout from '../../../components/layout/MainLayout';

export default function Token() {

    const { colorThemeContext } = useContext(ThemeProviderContext);
    const [colorTheme] = colorThemeContext;
    const [idToken, setIdToken] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [idTokenClaims, setIdTokenClaims] = useState(null);

    function copyToken(tokenType) {
        switch (tokenType) {
            case "ID":
                navigator.clipboard.writeText(idToken);
                break;
            case "ACCESS":
                navigator.clipboard.writeText(accessToken);
                break;
            default:
                break;
        }
    }

    const globalAuthState = useHookstate(globalAuthStateStore);
    const authState = globalAuthState.get();

    const parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    useEffect(() => {
        setIdToken(authState.idToken);
        setIdTokenClaims(authState.idTokenClaims);
        setAccessToken(authState.accessToken);
    }, [authState.idToken]);

    return (
        <MainLayout>
            <div className={"token-header"}>{"Id Token"}</div>
            <div className={"token-display-conatiner"}>
                <div className={"token-display"}>
                    {idToken ? idToken : 'No token'}
                </div>
            </div>
            <div className={"copy-token-btn"} data-theme={colorTheme} onClick={() => copyToken("ID")}>{"Copy Token"}</div>

            <div className={"token-header"}>{"Access Token"}</div>
            <div className={"token-display-conatiner"}>
                <div className={"token-display"}>
                    {accessToken ? accessToken : 'No token'}
                </div>
            </div>
            <div className={"copy-token-btn"} data-theme={colorTheme} onClick={() => copyToken("ACCESS")}>{"Copy Token"}</div>

            <div className={"claims-display-wrapper"}>
                <div className={"claims-display-header"}>{"Id Token Claims Payload"}</div>
                <pre>{JSON.stringify(idTokenClaims, undefined, 2)}</pre>
            </div>

            {
                accessToken != null ?
                    <div className={"claims-display-wrapper"}>
                        <div className={"claims-display-header"}>{"Access Token Claims Payload"}</div>
                        <pre>{JSON.stringify(parseJwt(accessToken), undefined, 2)}</pre>
                    </div>
                    :
                    null
            }
        </MainLayout>
    );
}





