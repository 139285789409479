import { ItemServiceFactory } from '../services/ItemServiceFactory';
import ItemManager from './ItemManager';
import _ from 'lodash';

class LogManager extends ItemManager {
  constructor(globallogState, globalAuthState, globalAppUserState) {
    super(ItemServiceFactory.ItemServiceEnum.Log, globalAuthState, globalAppUserState, globallogState,
      (filter, item) => {
        var hasMessageTypeId = item.MessageTypeId?.toString().toLowerCase()?.includes(filter?.MessageTypeId?.toLowerCase()) || (filter?.MessageTypeId === undefined || filter?.MessageTypeId === null || filter?.MessageTypeId === 'UNK' || filter?.MessageTypeId === '');
        var hasEventName = item.EventName?.toLowerCase()?.includes(filter?.EventName?.toLowerCase()) || (filter?.EventName === undefined || filter?.EventName === null || filter?.EventName === '');
        var hasLogLevel = item.LogLevel?.toString().toLowerCase()?.startsWith(filter?.LogLevel?.toLowerCase()) || (filter?.LogLevel === undefined || filter?.LogLevel === null || filter?.LogLevel === 'UNK');
        var hasCreatedBy = item.CreatedBy?.toString().toLowerCase()?.startsWith(filter?.CreatedBy?.toLowerCase()) || (filter?.CreatedBy === undefined || filter?.CreatedBy === null || filter?.CreatedBy === '');
        var hasCreatedDate = (filter?.CreatedDate ? new Date(item.CreatedDate) >= new Date(filter?.CreatedDate) : false) || (filter?.CreatedDate === undefined || filter?.CreatedDate === null || filter?.CreatedDate === '');
        return hasMessageTypeId && hasEventName && hasLogLevel && hasCreatedBy && hasCreatedDate;
      });
    this._errorUtil = undefined;
  }

  get DefaultLog() {
    return {
      EventName: 'Unknown',
    }
  }
}

export default LogManager;