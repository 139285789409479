import React, { useRef, useState, useEffect, } from 'react';
import loadLibrary from './load-library'


const AzureMP = ({ src, id, className, controls, onEnded, adaptRatio, handlePauseVideo, skin, currentTimeStamp, onUnMount }) => {
  const playerRef = useRef(null);
  const myPlayerRef = useRef(null)
  const DEFAULT_SKIN = 'amp-default'
  const DEFAULT_RATIO = [16, 9]


  const handleUnMount = () => {
    onUnMount(myPlayerRef.current.currentTime())
  }

  const handleOnEnded = () => {
    onEnded()
    //This function is here because it was living in its own world and not adhering to state changes.
    myPlayerRef.current.addEventListener('loadeddata', () => {
      myPlayerRef.current.currentTime(currentTimeStamp)
    });

    myPlayerRef.current.removeEventListener('loadeddata');
  }


  useEffect(() => {
    if (!playerRef.current) {
      return;
    }

    loadLibrary(skin).then(() => {

      myPlayerRef.current = window.amp(playerRef.current,
        {
          nativeControlsForTouch: false,
          autoplay: false,
          controls: controls !== undefined ? controls : true,
          logo: { enabled: false }
        })


      myPlayerRef.current.src(src)


      if (currentTimeStamp) {
        myPlayerRef.current.addEventListener('loadeddata', () => {
          myPlayerRef.current.currentTime(currentTimeStamp)
        });
      }

      if (handlePauseVideo) {
        handlePauseVideo(myPlayerRef.current.currentTime())
      }

      if (onUnMount) {
        window.addEventListener('beforeunload', handleUnMount)
      }

      if (onEnded) {
        myPlayerRef.current.addEventListener('ended', handleOnEnded);
      }
    })

  }, [playerRef, src, myPlayerRef])

  useEffect(() => {

    return () => {
      if (onUnMount) {
        window.removeEventListener('beforeunload', handleUnMount)
      }
      if (myPlayerRef?.current) {
        myPlayerRef.current.dispose();
      }
    }
  }, [])

  return (
    <div
      className={className}>
      <video ref={playerRef}
        className={'azuremediaplayer ' + `${skin ?? DEFAULT_SKIN}-skin ` + 'amp-big-play-centered'}
        tabIndex={0}
      />
    </div>
  )
}

const DEFAULT_SKIN = 'amp-default'
const DEFAULT_RATIO = [16, 9]
// class AzureMP extends Component {
//   constructor(props) {
//     super(props)
//     this.videoNode = React.createRef()
//   }
//   destroyPlayer() {
//     this.player && this.player.dispose()
//   }
//   componentWillUnmount() {
//     this.videoNode.current.removeEventListener('ended', () => { alert('ended') });
//     this.destroyPlayer()
//   }
//   componentDidMount() {
//     const { skin } = this.props
//     this.initialization = loadLibrary(skin).then(() => {
//       this.createPlayer()
//       this.setVideo()
//     })
//     console.log(this.initialization)
//   }
//   componentDidUpdate(prevProps) {
//     if (prevProps.src !== this.props.src) {
//       this.initialization.then(() => this.setVideo())
//     }
//   }
//   setVideo() {
//     const { src } = this.props
//     console.log(src, 'whats the source?')
//     this.player.src(src)
//   }
//   createPlayer() {
//     const { options, onInstanceCreated } = this.props
//     const defaultOptions = {
//       controls: true,
//       logo: { enabled: false }
//     }

//     this.player = window.amp(
//       this.videoNode.current,
//       Object.assign({}, defaultOptions, options)
//     )
//     const { onEnded } = this.props;

//     const checkIsEnded = () => {
//       if (this.player.ended()) {
//         if (onEnded) {
//           onEnded();
//         }
//       }
//       else {
//         setTimeout(checkIsEnded, 1000);
//       }
//     }

//     if (onEnded)
//       checkIsEnded();
//     onInstanceCreated && onInstanceCreated(this.player)
//   }
//   getRatioStyles(ratio) {
//     if (!ratio) {
//       return {}
//     }
//     return { paddingBottom: (ratio[1] / ratio[0]) * 100 + '%' }
//   }
//   render() {
//     const {
//       className,
//       skin = DEFAULT_SKIN,
//       adaptRatio = DEFAULT_RATIO,
//       cornerPlayBtn
//     } = this.props
//     return (
//       <div
//         style={this.getRatioStyles(adaptRatio)} className={classNames({ [styles['azure-mp-container']]: adaptRatio }, className)}>
//         <video
//           ref={this.videoNode}
//           onEnded={() => alert('End')}
//           className={classNames('azuremediaplayer', `${skin}-skin`, {
//             'amp-big-play-centered': !cornerPlayBtn
//           })}
//         />
//       </div>
//     )
//   }
// }

export default AzureMP
