import React, { useState, useEffect, useRef } from 'react';
import './../testimonials.scss';
import testimonialData from './../data/testimonialData';

const MemberTestimonials = () => {
    const [selectedTestimonial, setSelectedTestimonial] = useState({});
    const [previousSelectedTestimonial, setPreviousSelectedTestimonial] = useState({});
    const [previousTestimonialImagePath, setPreviousTestimonialImagePath] = useState();
    const [testimonialSet, setTestimonialSet] = useState();
    const [testimonialImagePath, setTestimonialImagePath] = useState();
    const [stateImagePath, setStateImagePath] = useState();
    const [fadeIn, setFadeIn] = useState(true);
    const [fadeOutPrevious, setFadeOutPrevious] = useState(false);
    const [imgRect, setImgRect] = useState();
    const lineRef = useRef(null);
    const selectedImgRef = useRef(null);
    const [imagesLoaded, setImagesLoaded] = useState();
    const imageRefSet = useRef(new Array())
    const [initialRender, setInitialRender] = useState(false);

    useEffect(() => {
        testimonialData.forEach((testimonial) => {
            import(`./../assets/testimonials/${testimonial.Image}`).then((module) => {
                let imageUrl = module.default;
                let imagePath = (imageUrl);
                let index = testimonialData.indexOf(testimonial);
                let current = testimonialData[index];
                current["ImagePath"] = imagePath;

                if (index === testimonialData.length - 1) {
                    setImagesLoaded(true);
                }
            });
        });

        const firstOrderTestimonial = testimonialData.filter(x => x.Order === 1);

        if (firstOrderTestimonial.length > 0) {
            setSelectedTestimonial(firstOrderTestimonial[0]);
        }
    }, []);

    useEffect(() => {
        if (imageRefSet.current.length === 5) {

            setTimeout(() => {
                selectedImgRef.current = imageRefSet.current[0];

                const imagePosition = selectedImgRef?.current?.getBoundingClientRect();
                if (imagePosition) {
                    updateLineLocation(imagePosition);
                }
            }, 1000);
        }
    }, [imageRefSet]);

    useEffect(() => {
        if (selectedTestimonial?.Image) {
            // Dynamically import the image file
            import(`./../assets/testimonials/${selectedTestimonial.Image}`).then((module) => {
                const imageUrl = module.default;
                setTestimonialImagePath(imageUrl);
                setPreviousTestimonialImagePath(imageUrl);
            });

            if (selectedTestimonial?.State) {
                import(`./../assets/testimonials/map_icons (svg)/${selectedTestimonial.StateImage}`).then((module) => {
                    const stateImageUrl = module.default;
                    setStateImagePath(stateImageUrl);
                });
            }
        }
    }, [selectedTestimonial]);

    useEffect(() => {
        if (imgRect) {
            updateLineLocation();
        }
    }, [imgRect]);

    const handleOnImageClick = (testimonial, i, selectedImgRef) => {
        setPreviousSelectedTestimonial(selectedTestimonial);
        const selectedTestimonialImage = testimonialData.filter(x => x.Order === testimonial.Order);
        selectedImgRef.current = imageRefSet.current[i];

        const imagePosition = selectedImgRef.current.getBoundingClientRect();
        setImgRect(imagePosition);

        setFadeIn(false);
        setFadeOutPrevious(true);
        setTimeout(() => {
            setSelectedTestimonial(selectedTestimonialImage[0]);
            setFadeIn(true);
        }, 300); // wait 500ms before updating the image src and fading in again
    }

    const ImageGrid = () => {

        return (
            <div>
                <div className='img-grid-container'>
                    {testimonialData.map((testimonial, index) => (
                        <div key={index} className='testimonial-list-img-container'>
                            <img ref={(image) => imageRefSet.current.push(image)} key={index}
                                className={`testimonial-list-img ${selectedTestimonial.Order === testimonial.Order ? 'selected-img' : 'not-selected-img'}`}
                                src={testimonial.ImagePath} alt={`Button to see ${testimonial.Name}'s testimonial`}
                                onClick={() => handleOnImageClick(testimonial, index, selectedImgRef)} />
                        </div>
                    ))}
                </div>
                <hr className='line' ref={lineRef} />
            </div>
        );
    }

    const updateLineLocation = (imagePosition) => {
        const lineElement = lineRef.current;
        const selectedImgElement = imgRect ? imgRect : imagePosition;

        if (lineElement && selectedImgElement) {
            const lineRect = lineElement.getBoundingClientRect();

            const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
            const newLeft = ((selectedImgElement.left - lineRect.width / 2 + selectedImgElement.width / 2) / rootFontSize) - 2.5;
            lineElement.style.left = `${newLeft}rem`;
        }
    }

    useEffect(() => {
        function handleResize() {
            const imagePosition = selectedImgRef?.current?.getBoundingClientRect();
            if (imagePosition) {
                updateLineLocation(imagePosition);
            }
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <div className='testimonial-container'>
                <h1 className='testimonial-header'>
                    Member Testimonials
                </h1>
                <div className='selected-member-container'>
                    <div key='selectedDiv' className={`testimonial-selected-container ${fadeIn ? 'fade-in' : 'fade-out'}`}>
                        {/* <img style={{borderRadius: '10px'}} src={previousTestimonialImagePath} className={`selected-testinomial-image ${fadeOutPrevious ? 'fade-out' : 'fade-in'}`} alt={previousSelectedTestimonial.Image}/> */}
                        <img src={testimonialImagePath} className={`selected-testinomial-image`} alt={selectedTestimonial.Image} />
                    </div>
                    <div className='selected-testimonial-info'>
                        <div className='testimonial-body-text'>{selectedTestimonial.Text}</div>
                        <div className='selected-testimonial-location'>
                            <div className='selected-member-info'>
                                {selectedTestimonial.District === '' ?
                                    <>
                                        <div className='member-name'>
                                            {selectedTestimonial.Name}
                                        </div>
                                        <div>
                                            {selectedTestimonial.Position},
                                        </div>
                                        <div>
                                            {selectedTestimonial.School}, {selectedTestimonial.State}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='member-name'>
                                            {selectedTestimonial.Name}
                                        </div>
                                        <div>
                                            {selectedTestimonial.Position}, {selectedTestimonial.School}
                                        </div>
                                        <div>
                                            {selectedTestimonial.District}, {selectedTestimonial.State}
                                        </div>
                                    </>
                                }
                            </div>
                            <img src={stateImagePath} className='selected-testimonial-state-image' alt={selectedTestimonial.State} />
                        </div>
                    </div>
                </div>
                <div className='testimonial-img-container'>
                    {ImageGrid()}
                </div>
            </div>

        </>
    );
}

export default MemberTestimonials;