import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import ObservationDetailsDialog from '../components/management/details';
import ObservationFilter from '../components/management/filter';
import ObservationTable from '../components/management/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import _ from 'lodash';
import './ObservationReview.scss';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';
import { useObservationManager, useRubricIndicatorManager, useSurveyManager } from '../../../hooks/useManagers';
import moment from 'moment';
import { InputField } from '../../../components/controls/InputField';
import refreshIcon from '../../../content/icons/refreshIcon.svg'
import { CheckboxControl } from '../../../components/controls';
import Print from '../components/print';
import Loading from '../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown, faPrint, faSquarePollHorizontal, faXmark } from '@fortawesome/free-solid-svg-icons';
import SurveyTable from '../../admin/components/observation/SurveyTable';
import FileUploaderControl from '../../../components/controls/FileUploaderControl';
import { FormatScriptingTime, FormatUTCDateTimeLong } from '../../../utilities/DateFormater';
import ContentRecommendation from '../../admin/components/contentLibrary/ContentRecommendation';
import AccessDeny from '../../../components/security/AccessDeny';
import SystemTypes from '../../../SystemTypes';
import RubricPrintScreen from '../components/RubricPrintScreen';
import ObservationManager from '../../../managers/ObservationManager';

const ObservationReview = () => {

    const location = useLocation();

    const observationMgr = useObservationManager(location.pathname.toLowerCase().includes('/teacher/'));
    const rubricIndicatorMgr = useRubricIndicatorManager();

    const [loadingError, setLoadingError] = useState(null);
    const [savingObs, setSavingObs] = useState(false);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(true);
    const [verifying, setVerifying] = useState(false);
    const [selectedObservation, setSelectedObservation] = useState(null);
    const [signErrors, setSignErrors] = useState(null);
    const [reviewData, setReviewData] = useState(null);
    const [surveySections, setSurveySections] = useState(null);
    const [savingSignature, setSavingSignature] = useState(false);
    const [rubricModal, setRubricModal] = useState({ isActive: false, indicator: null });
    const [rubricGridData, setRubricGridData] = useState(null);
    const [isContentRecommendationDialogueActive, setIsContentRecommendationDialogueActive] = useState(false);
    const [recommendedContent, setRecommendedContent] = useState(null);
    const [emailSent, setEmailSent] = useState(false)
    const [isTeacher, setIsTeacher] = useState(location.pathname.toLowerCase().includes('/teacher/'));

    //permissions
    const [denyAccess, setDenyAccess] = useState(false);
    const [canPrintDetailed, setCanPrintDetailed] = useState(false);
    const [canViewScripting, setCanViewScripting] = useState(false);
    const [canEditScripting, setCanEditScripting] = useState(false);
    const [canViewSelfReflection, setCanViewSelfReflection] = useState(false);
    const [canEditSelfReflection, setCanEditSelfReflection] = useState(false);

    const [openReccomendations, setOpenReccomendations] = useState(false);
    const [openSignature, setOpenSignature] = useState(false);
    const [printUrl, setPrintUrl] = useState(null);
    const [printing, setPrinting] = useState(false);
    const [printRubric, setPrintRubric] = useState(false)
    const [signaturePhase, setSignaturePhase] = useState({
        currentPhase: 1,
        nextText: 'Next',
        okText: 'Manual Signature'
    });
    const [observeeData, setObserveeData] = useState({
        observee: '',
        observeDate: '',
        address: '',
        observerInfo: ''
    })

    const params = useParams();
    const navigate = useNavigate();

    const [tagMap, setTagMap] = useState(null);
    const [notes, setNotes] = useState(null);
    const [observationConfig, setObservationConfig] = useState(null);

    const loadData = async (observationId) => {
        const r = await observationMgr.get(observationId);
        let obs;
        if (r.Success) {
            obs = r.Items.first()
            setSelectedObservation(obs);

            observationMgr.observationConfigOperation(obs.ClientId, obs.DistrictId, obs.SchoolId, obs.SchoolYearIdentifier).then(res => {
                setObservationConfig(res);
            })

            observationMgr.getObservationReviewRatings(observationId).then(orr => {
                if (orr.Success) {
                    setReviewData(orr.Items.first());
                }
                else {
                    setErrors([`Failed to load Observation data.`, orr.MessageDetails]);
                }
            })

            const ssr = await observationMgr.getObservationSurveySectionsForReview(observationId, true);
            if (ssr.Success) {
                let transformedData = observationMgr.transformData(ssr.Items.first());
                setSurveySections(ssr.Items.first());
                setRubricGridData(transformedData.gridData);
                setNotes(transformedData.notes);
                setTagMap(transformedData.tagMap);
            }
            else {
                setErrors([`Failed to load Observation data.`, ssr.MessageDetails]);
            }
        }

        return obs;
    }

    useEffect(() => {
        if (selectedObservation && observationMgr) {
            if (!observationMgr.canAccessReview(selectedObservation)) {
                setDenyAccess(true);
            }
            if (observationMgr.canAccessEvidence(selectedObservation) &&
                observationMgr.canAccessReview(selectedObservation)) {
                setCanPrintDetailed(true);
            }
            if (observationMgr.canAccessScripting(selectedObservation)) {
                setCanViewScripting(true);
            }
            if (observationMgr.canEditScripting(selectedObservation)) {
                setCanEditScripting(true);
            }
            if (observationMgr.canAccessSelfReflection(selectedObservation)) {
                setCanViewSelfReflection(true);
            }
            if (observationMgr.canEditSelfReflection(selectedObservation)) {
                setCanEditSelfReflection(true);
            }
        }
    }, [selectedObservation, observationMgr])

    useEffect(() => {
        if (openReccomendations) {
            if (observationMgr?.canViewRecommendedContent(selectedObservation)) {
                setIsContentRecommendationDialogueActive(true);
            }
            else {
                navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}`);
            }
        }
    }, [openReccomendations])

    useEffect(() => {
        let result;
        if (!selectedObservation && params?.observationId && observationMgr && !observationMgr?.selectedObservation) {
            setLoading(true);
            result = loadData(params?.observationId);
        }
        else if (observationMgr?.selectedObservation) {
            setLoading(true);
            result = loadData(observationMgr.selectedObservation.Id);
        }
        if (result) {
            result.then((obs) => {
                if (obs) {
                    setSignaturePhase({
                        ...signaturePhase,
                        ObserverSignedDate: obs.ObserverSignedDate,
                        ObserverSignature: obs.ObserverSignature,
                        ObserverSignatureEmail: obs.ObserverSignatureEmail,
                        ObserverSignedChecked: obs.ObserverSignedChecked,
                        ObservedSignedDate: obs.ObservedSignedDate,
                        ObservedSignature: obs.ObservedSignature,
                        ObservedSignatureEmail: obs.ObservedSignatureEmail,
                        ObservedSignedChecked: obs.ObservedSignedChecked
                    });

                    if (obs.ObservationStatusType >= 9)
                        updateSignaturePhase(999)
                    else if (obs.ManualSignature)
                        updateSignaturePhase(2);

                    setObserveeData({
                        observee: obs.Observed?.FullName,
                        observeDate: obs?.ActualObservationDateStart ?? obs?.PlannedObservationDateStart,
                        address: `${obs?.School?.Name} - Grades: ${obs?.GradeLevelsData?.replace(/^,+|,+$/g, '')} (${obs?.SubjectsTaughtData?.replace(/^,+|,+$/g, '')})`,
                        observerInfo: `Observed by ${obs?.Observer?.FullName} (Via ${obs.Rubric?.Name} Rubric)`,
                        observer: obs?.Observer?.FullName,
                        rubricName: obs.Rubric?.Name,
                        isLegacy: obs.LegacyReferenceId ? true : false
                    })
                }
                else {
                    setErrors(['Failed to load observation. If this issue persists please contact customer support.']);
                }
                setLoading(false);
            });
        }
    }, [observationMgr, params?.observationId]);

    const reloadObservation = () => {
        observationMgr.get(params?.observationId).then((r) => {
            if (r.Success) {
                const observation = r.Items.first();
                setSelectedObservation(observation);
                setIsTeacher(!(observation.ObservationType == 3))

                setSignaturePhase({
                    ...signaturePhase,
                    ObserverSignedDate: observation.ObserverSignedDate,
                    ObserverSignature: observation.ObserverSignature,
                    ObserverSignatureEmail: observation.ObserverSignatureEmail,
                    ObserverSignedChecked: observation.ObserverSignedChecked,
                    ObservedSignedDate: observation.ObservedSignedDate,
                    ObservedSignature: observation.ObservedSignature,
                    ObservedSignatureEmail: observation.ObservedSignatureEmail,
                    ObservedSignedChecked: observation.ObservedSignedChecked
                });

                if (observation.ObservationStatusType >= 9)
                    updateSignaturePhase(999)
                else if (observation.ManualSignature)
                    updateSignaturePhase(2);

                setObserveeData({
                    observee: observation?.Observed?.FullName,
                    observeDate: observation?.ActualObservationDateStart ?? observation?.PlannedObservationDateStart,
                    address: `${observation?.School?.Name} - Grades: ${observation?.GradeLevelsData?.replace(/^,+|,+$/g, '')} (${observation?.SubjectsTaughtData?.replace(/^,+|,+$/g, '')})`,
                    observerInfo: `Observed by ${observation?.Observer?.FullName} (via ${observation.Rubric?.Name} Rubric)`,
                    observer: observation?.Observer?.FullName,
                    rubricName: observation.Rubric?.Name
                });
                setLoading(false)
            }
        })
    }


    const [evidenceDialogue, setEvidenceDialogue] = useState({ isOpen: false })

    const toggleEvidenceDialogue = (indicator) => {

        let abbr = Object.keys(tagMap).reduce((r, cv) => {
            if (tagMap[cv].name === indicator) {
                r = cv;
            }
            return r;
        }, '');

        let sections = [];

        notes.forEach(note => {
            let section = {
                time: FormatScriptingTime(note.time, 'child'),
                sectionTitle: note.sectionTitle,
                taggedTextEvidence: []
            };

            let sectionContainsTag = false;

            Object.keys(note.tags).forEach(t => {
                if (note.tags[t].includes(abbr)) {
                    section.taggedTextEvidence.push(t);
                    sectionContainsTag = true;
                }
            });

            let mediaEvidence = [];

            note.media?.forEach(m => {
                if (m.tags.includes(abbr)) {
                    mediaEvidence.push({ FileId: m.FileId, FileUrl: m.FileUrl })
                }
            })

            if (sectionContainsTag || mediaEvidence.length > 0) {
                sections.push({ section, mediaEvidence });
            }
        });

        let evidenceJsx = <div className={'evidence-dialogue'}>
            <div className={'indicator-label'}>
                {indicator}
                <span>{`(${abbr})`}</span>
            </div>
            {sections.map(s => {
                return (<div className={'dialogue-section'}>
                    <div className={'time-and-title'}>
                        <div className={'time-piece'}>{s.section.time}</div>
                        <div>-</div>
                        <div className={'title-piece'}>{s.section.sectionTitle}</div>
                    </div>
                    <div className={'tagged-text-evidence-area'}>
                        {s.section.taggedTextEvidence.map((te, i) => {
                            return <div className={'tagged-text-evidence'}>{te}<br /></div>
                        })}
                    </div>
                    <div className={'tagged-media-area'}>
                        {s.mediaEvidence.map(m => {

                            return <div
                                key={`${m.FileId}-media-ed`}
                                className={'upload-media-pane'}
                            >
                                <FileUploaderControl
                                    hideControls={true}
                                    disabled={true}
                                    controlId={m.FileId}
                                    maxFileSize={52428800}
                                    fileUrl={m.FileUrl}
                                    canUploadAnyFile={true}
                                    overrideMaxWidth={50}
                                    widthRes={190}
                                    heightRes={90}
                                    forceDim={true}
                                    dontDisplayFileName={true}
                                />
                            </div>
                        })}
                    </div>
                </div>)
            })}
        </div>

        setEvidenceDialogue(
            {
                isOpen: !evidenceDialogue.isOpen,
                children: evidenceJsx
            }
        );
    }

    const [refinementObj, setRefinementObj] = useState(null);

    useEffect(() => {
        if (surveySections && !refinementObj) {
            var sections = surveySections?.SurveySection?.Sections ?? [];
            var section = _.find(sections, x => x.SurveySectionType === 2);
            var refObj = null;
            if (section?.Tags?.length > 0 && section?.Tags[0].RubricIndicators.length > 0) {
                refObj = {
                    surveySectionType: 2,
                    evidence: section?.Evidence,
                    indicator: section?.Tags[0].RubricIndicators[0].ShortName,
                    indicatorId: section?.Tags[0].RubricIndicators[0].TagId
                };
            }
            setRefinementObj(refObj);
        }
    }, [surveySections])

    const getRefinementRefinement = (isSelf, surveySectionType) => {
        if (surveySections) {
            var sections = surveySections?.SurveySection?.Sections ?? [];
            if (isSelf) {
                sections = surveySections?.SelfReflectionSurveySection?.Sections ?? [];
            }
            var section = _.find(sections, x => x.SurveySectionType === surveySectionType);
            return { name: section?.Tags?.first()?.RubricIndicators?.first().Name, details: section?.Evidence ?? '' };
        }
        else {
            return { name: '', details: '' }
        }
    }

    const getReinforcement = (isSelf) => {
        return getRefinementRefinement(isSelf, 3);
    }

    const getRefinement = (isSelf) => {
        return getRefinementRefinement(isSelf, 2);
    }

    const hasEvidence = (indicatorId) => {
        if (!canPrintDetailed) {
            return false;
        }
        else if (surveySections) {
            var sections = surveySections?.SurveySection?.Sections ?? [];
            var indicatorEvidence = false;
            var notesSections = _.filter(sections, x => x.SurveySectionType === 1);
            for (var i = 0; i < notesSections.length; i++) {
                for (var j = 0; j < (notesSections[i]?.Tags ?? []).length; j++) {
                    var indicator = _.find(notesSections[i]?.Tags[j].RubricIndicators ?? [], k => k.TagId == indicatorId);
                    if (indicator) {
                        indicatorEvidence = true;
                        break;
                    }
                }
                if (indicatorEvidence) break;
            }

            return indicatorEvidence;
        }
        else {
            return false;
        }
    }

    const handleOnCancel = () => {
        setSelectedObservation(null);
        setLoadingError(null);
        setErrors(null);
        navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}`);
    }

    const handleOnPrintReport = async (isDetailed) => {
        setPrinting(true);
        setPrintUrl(`/observation/print${isDetailed ? 'detailed' : ''}/${selectedObservation.Id}?d=${moment().toISOString(true)}`);
    }

    const handleOnPrintRubric = async () => {
        setPrinting(true)
        setPrintRubric(true)
    }
    const handlePrintCompleted = () => {
        setPrinting(false)
        setPrintRubric(false)
    }
    const handleOnSubmitObservation = () => {
        setOpenSignature(true);
    }

    const handleOnEditObservation = () => {
        navigate(`/observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}/${selectedObservation.Id}`);
    }

    const handleOnEditSelfReflection = () => {
        navigate(`/observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}/selfreflection/${selectedObservation.Id}`);
    }

    const handleOnShowEvidence = () => {

    }

    const handleSignatureCancel = () => {
        setOpenSignature(false);
        updateSignaturePhase(1);
    }

    const updateObsSignature = (currentPhase, obs, isObserver, confirmed) => {
        obs.Organization = null;
        obs.District = null;
        obs.School = null;
        obs.Observed = null;
        obs.Observer = null;
        obs.Rubric = null;
        obs.Survey = null;
        if (currentPhase !== 2) {
            if (isObserver && signaturePhase.ObserverSignatureEmail && signaturePhase.ObserverSignature) {
                obs.ObserverSignatureEmail = signaturePhase.ObserverSignatureEmail;
                obs.ObserverSignature = signaturePhase.ObserverSignature;
                obs.ObserverSignedChecked = confirmed;
                if (confirmed)
                    obs.ObserverSignedDate = moment().utc();
                else
                    obs.ObserverSignedDate = null;
            }

            if (!isObserver && signaturePhase.ObservedSignatureEmail && signaturePhase.ObservedSignature) {
                obs.ObservedSignatureEmail = signaturePhase.ObservedSignatureEmail;
                obs.ObservedSignature = signaturePhase.ObservedSignature;
                obs.ObservedSignedChecked = confirmed;
                if (confirmed)
                    obs.ObservedSignedDate = moment().utc();
                else
                    obs.ObservedSignedDate = null;
            }
            obs.ManualSignature = false;
        }
        else {
            obs.ObservedSignatureEmail = null;
            obs.ObservedSignature = null;
            obs.ObservedSignedChecked = false;
            obs.ObserverSignatureEmail = null;
            obs.ObserverSignature = null;
            obs.ObserverSignedChecked = false;
            obs.ObservedSignedDate = null;
            obs.ObserverSignedDate = null;
            obs.ManualSignature = true;
        }
        return obs;
    }

    const saveSignature = async (currentPhase, isObserver, confirmed) => {
        let isValid = false;
        if (currentPhase === 1) {
            const errorMessage = [];
            setSavingSignature(true)
            if (isObserver) {
                if (signaturePhase.ObserverSignatureEmail && signaturePhase.ObserverSignature) {
                    if (selectedObservation.Observer?.Email?.toLowerCase() !== signaturePhase.ObserverSignatureEmail?.toLowerCase()) {
                        errorMessage.push('Failed to verify observer users email, observer users email does not match the email on file.');
                    }
                }
                else {
                    errorMessage.push('Observer, please digitally sign below by filling out all obsever fields and clicking "Check To Confirm".');
                }
            }
            else {
                if (signaturePhase?.ObservedSignatureEmail && signaturePhase.ObservedSignature) {
                    if (selectedObservation.Observed?.Email?.toLowerCase() !== signaturePhase.ObservedSignatureEmail?.toLowerCase()) {
                        errorMessage.push('Failed to verify observed users email, observed users email does not match the email on file.');
                    }
                }
                else {
                    errorMessage.push('Observed, please digitally sign below by filling out all obsever fields and clicking "Check To Confirm".');
                }
            }

            if (errorMessage.length !== 0) {
                setSignErrors(errorMessage);
                setSavingSignature(false);
            }
            else {
                const r = await observationMgr.get(params?.observationId);
                if (r.Success) {
                    const obs = r.Items.first();
                    const updatedObs = updateObsSignature(currentPhase, obs, isObserver, confirmed);
                    const result = await observationMgr.saveItem(updatedObs);

                    if (!result.Success)
                        setSignErrors(['Failed to save signature to document, if this problem continues please contact NIET Support.']);
                    else {
                        let params = {
                            ObservationId: updatedObs.Id,
                            Env: ObservationManager.getEnv()
                        }

                        if (confirmed) {
                            if (!isObserver) {
                                params = { ...params, ObservationStatusType: SystemTypes.ObservationStatusType.ObservedSubmitted };
                            }
                            else {
                                params = { ...params, ObservationStatusType: SystemTypes.ObservationStatusType.ObserverSubmitted };
                            }
                        }
                        else if (updatedObs.ObservationStatusType == SystemTypes.ObservationStatusType.ObserverSubmitted) {
                            params = { ...params, ObservationStatusType: SystemTypes.ObservationStatusType.ObservedSubmitted };
                        }
                        else {
                            params = { ...params, ObservationStatusType: SystemTypes.ObservationStatusType.PostConference };
                        }

                        await observationMgr.emailStatusChange(updatedObs.Id, params);

                        setSelectedObservation(result.Items.first());
                        setSignErrors(null);
                    }
                    setSavingSignature(false)
                    isValid = true;
                }
                else {
                    setSignErrors(['Failed to save signature to document, if this problem continues please contact NIET Support.']);
                }
            }
        }
        else if (currentPhase === 2) {
            const r = await observationMgr.get(params?.observationId);
            if (r.Success) {
                const obs = r.Items.first();
                const updatedObs = updateObsSignature(currentPhase, obs);
                const result = await observationMgr.saveItem(updatedObs);
                if (!result.Success)
                    setSignErrors(['Failed to convert to manual signature in, if this problem continues please contact NIET Support.']);
                else {
                    setSelectedObservation(result.Items.first());
                    setSignErrors(null);
                }
                setSavingSignature(false)
                isValid = true;
            }
            else {
                setSignErrors(['Failed to set manual signature, if this problem continues please contact NIET Support.']);
            }
        }
        return isValid;
    }

    const handelOnSignatureNext = async () => {
        if (signaturePhase.currentPhase === 3 || signaturePhase.currentPhase === 2) {
            setVerifying(true);
            const r = await observationMgr.get(params?.observationId);
            if (r.Success) {
                const obs = r.Items.first();
                obs.Organization = null;
                obs.District = null;
                obs.School = null;
                obs.Observed = null;
                obs.Observer = null;
                obs.Rubric = null;
                obs.Survey = null;
                obs.ManualSignature = signaturePhase.currentPhase === 2;
                obs.ObservationStatusType = SystemTypes.ObservationStatusType.Completed;

                const result = await observationMgr.saveItem(obs);
                if (!result.Success)
                    setSignErrors(['Failed to complete observation, please contact customer support if this problem continues.']);
                else {
                    observationMgr.emailStatusChange(selectedObservation.Id, {
                        ObservationId: selectedObservation.Id,
                        ObservationStatusType: SystemTypes.ObservationStatusType.Completed,
                    })
                    setSelectedObservation(result.Items.first());
                    setSignErrors(null);
                    setOpenSignature(false);
                    //TODO: hiding content recommendation until logic is fixed on backend
                    navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}`);
                    //setOpenReccomendations(true);
                }
                setVerifying(false);
            }
            else {
                setSignErrors(['Failed to complete observation, please contact customer support if this problem continues.']);
            }
        }
        else if (signaturePhase.currentPhase === 1) {
            if ((selectedObservation?.ObserverSignedChecked && selectedObservation?.ObservedSignedChecked) || selectedObservation.ManualSignature) {
                updateSignaturePhase(3);
            }
        }
    }

    //Manual Signature or Previous
    const handleOnManualSignature = async () => {
        if (signaturePhase.currentPhase === 2) {
            updateSignaturePhase(1);
        }
        else if (signaturePhase.currentPhase === 1) {
            setSignErrors(null);
            updateSignaturePhase(2);
            await saveSignature(2);
        }
        else {
            if (selectedObservation.ManualSignature) {
                updateSignaturePhase(2);
            }
            else {
                updateSignaturePhase(1);
            }
        }
    }

    const updateSignaturePhase = (nextPhase) => {
        const updatedSingaturePhase = { ...signaturePhase };
        updatedSingaturePhase.currentPhase = nextPhase;
        if (nextPhase === 1) {
            updatedSingaturePhase.nextText = 'Next';
            updatedSingaturePhase.okText = 'Manual Signature';
        }
        else if (nextPhase === 2) {
            updatedSingaturePhase.nextText = 'Complete';
            updatedSingaturePhase.okText = 'Digital Signature';
        }
        else if (nextPhase === 3) {
            updatedSingaturePhase.nextText = 'Complete';
            updatedSingaturePhase.okText = 'Previous';
        }
        setSignaturePhase(updatedSingaturePhase);
    }

    const onChangeSignature = async (value, field) => {
        const updateSignaturePhase = { ...signaturePhase };
        if (field === 'ObserverSignedChecked') {
            if (await saveSignature(signaturePhase.currentPhase, true, value)) {
                updateSignaturePhase[field] = value;
            }
        }
        else if (field === 'ObservedSignedChecked') {
            if (await saveSignature(signaturePhase.currentPhase, false, value)) {
                updateSignaturePhase[field] = value;
            }
        }
        else
            updateSignaturePhase[field] = value;
        setSignaturePhase(updateSignaturePhase);
    }

    const showRubric = (indicator) => {
        if (indicator) {
            setRubricModal({ isActive: true, indicator });
        }
        else {
            setRubricModal({ isActive: true, indicator: null });
        }
    }

    useEffect(() => {
        if (selectedObservation?.Id && rubricIndicatorMgr && refinementObj?.indicatorId && !recommendedContent) {
            rubricIndicatorMgr.getRecommendedContent(refinementObj.indicatorId, selectedObservation.Id, true).then(res => {
                setRecommendedContent(res.RecommendedContents);
            })
        }
    }, [selectedObservation, refinementObj]);

    const saveContentRecommendations = (singleItem) => {
        if (observationMgr) {
            if (singleItem) {
                // observationMgr.saveContentRecommendations(selectedObservation.Id, [singleItem], true).then(res => {
                //     //Then do nothing because we're simply marking it HasViewed = true
                // });
            }
            else {
                observationMgr.saveContentRecommendations(selectedObservation.Id, recommendedContent, true, true).then(res => {
                    navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}`);
                });
            }
        }
    }

    const handleStartPostConference = async () => {
        if (selectedObservation) {
            setSavingObs(true)
            const r = await observationMgr.get(selectedObservation.Id);

            if (r.Success) {
                const obs = r.Items.first();
                obs.Organization = null;
                obs.District = null;
                obs.School = null;
                obs.Observed = null;
                obs.Observer = null;
                obs.Rubric = null;
                obs.Survey = null;

                if (obs.ObservationStatusType < SystemTypes.ObservationStatusType.PostConference) {
                    obs.ObservationStatusType = SystemTypes.ObservationStatusType.PostConference;
                    const result = await observationMgr.saveItem(obs);
                    if (result.Success) {
                        setSelectedObservation(result.Items.first());
                        setSavingObs(false);
                        observationMgr.emailStatusChange(selectedObservation.Id, {
                            ObservationId: selectedObservation.Id,
                            ObservationStatusType: SystemTypes.ObservationStatusType.PostConference,
                        })
                    }
                    else {
                        setSavingObs(false);
                    }
                }
            }
        }
    }

    const handleResendSelfReflectionEmail = () => {
        observationMgr.emailStatusChange(selectedObservation.Id, {
            ObservationId: selectedObservation.Id,
            ObservationStatusType: SystemTypes.ObservationStatusType.SelfReflectionNotStarted,
            Env: ObservationManager.getEnv(),
            IsSelfReflectionButton: true
        })
        setEmailSent(true)
    }

    return (denyAccess ? <AccessDeny /> :
        <>
            <DialogControl
                openDialog={evidenceDialogue.isOpen}
                title={evidenceDialogue.title}
                subTitle={''}
                onOk={() => { setEvidenceDialogue({ isOpen: false }) }}
                onCancel={() => { setEvidenceDialogue({ isOpen: false }) }}
                okText={'CLOSE'}
                cancelText={'CLOSE'}
                excludeCancel={true}
            >
                {evidenceDialogue.children}
            </DialogControl>
            {printUrl && <Print printUrl={printUrl} onPrinting={() => setPrinting(false)} />}
            {printRubric && <RubricPrintScreen rubricData={rubricGridData} handlePrintCompleted={handlePrintCompleted} />}
            {(signaturePhase.currentPhase <= 3) &&
                <DialogControl loadingSave={verifying} openDialog={openSignature} footerClassName={'signature-dialog-footer'} title={'Observation Signatures'} excludeCancel={true} nextText={signaturePhase.nextText} customCenterText="Print Report" customCenterType={'cancel'}
                    onCustomCenter={() => handleOnPrintReport(false)} loadingCustomCenter={printing} onCancel={handleSignatureCancel}
                    enableNext={
                        (selectedObservation?.ManualSignature && signaturePhase.currentPhase > 1 && (selectedObservation?.ObserverUser === observationMgr?.AppUserState.userProfile.Id || observationMgr?.AppUserState.userProfile.UserPositionType < SystemTypes.UserPositionType.SchoolAdmin))
                        || (signaturePhase?.ObserverSignedChecked && selectedObservation?.ObserverSignedChecked && selectedObservation?.ObservedSignedChecked && signaturePhase?.ObservedSignedChecked) && ((signaturePhase.currentPhase === 2 || signaturePhase.currentPhase === 3) && (selectedObservation?.ObserverUser === observationMgr?.AppUserState.userProfile.Id || observationMgr?.AppUserState.userProfile.UserPositionType < SystemTypes.UserPositionType.SchoolAdmin))
                        || (signaturePhase?.ObserverSignedChecked && selectedObservation?.ObserverSignedChecked && selectedObservation?.ObservedSignedChecked && signaturePhase?.ObservedSignedChecked && signaturePhase.currentPhase === 1)}
                    okText={((!selectedObservation?.ObservedSignedChecked && !selectedObservation?.ObserverSignedChecked) || signaturePhase.currentPhase === 3) ? signaturePhase.okText : null}
                    onOk={((!selectedObservation?.ObservedSignedChecked && !selectedObservation?.ObserverSignedChecked) || signaturePhase.currentPhase === 3) ? handleOnManualSignature : null}
                    onNext={handelOnSignatureNext} >
                    <div className='signature-dialog'>
                        <img className='refresh-icon' src={refreshIcon} alt="" onClick={reloadObservation} />
                        <div>If you wish to have a physical signature to verify the Summary Report and have not already printed a copy, click "Manual Signature" and follow the instructions provided.</div>
                        <div>Otherwise, fill out your corresponding fields below and click the checkbox to confirm your digital signature.</div> <div>Once both signatures have been completed, please continue by clicking "Next".</div>
                        {signErrors?.length > 0 && <div className='error columns'>{_.map(signErrors, msg => <div>{msg}</div>)}</div>}
                        <div className='signature-dialog-sign'>

                            {(signaturePhase.currentPhase === 1) &&
                                <>
                                    <div className='signature-fields'>
                                        <InputField title={"Observed Signature"} fieldName='ObservedSignature' onChange={onChangeSignature} value={signaturePhase?.ObservedSignature} isDisabled={!observationMgr?.hasObserverEntryPermission(selectedObservation?.UserObserved) && (signaturePhase?.ObservedSignedChecked || selectedObservation?.UserObserved !== observationMgr?.AppUserState.userProfile.Id)} placeholder={'Digital Signature'}></InputField>
                                        <InputField title={"Email Address"} fieldName='ObservedSignatureEmail' onChange={onChangeSignature} value={signaturePhase?.ObservedSignatureEmail} isDisabled={!observationMgr?.hasObserverEntryPermission(selectedObservation?.UserObserved) && (signaturePhase?.ObservedSignedChecked || selectedObservation?.UserObserved !== observationMgr?.AppUserState.userProfile.Id)} placeholder={'Confirm Email'}></InputField>
                                        <div className='confirm-field'>{!savingSignature ? <><CheckboxControl onChange={(e) => onChangeSignature(e.target.value, 'ObservedSignedChecked')} disabled={(signaturePhase?.ObservedSignedChecked || selectedObservation?.UserObserved !== observationMgr?.AppUserState.userProfile.Id || !signaturePhase?.ObservedSignatureEmail || !signaturePhase?.ObservedSignature)} value={signaturePhase?.ObservedSignedChecked ?? false} /><div className='confirm-label'>Check to Verify</div></> : <Loading />}</div>
                                    </div>
                                    <div className='signature-fields'>
                                        <InputField title={"Observer Signature"} isDisabled={!observationMgr?.hasObserverEntryPermission(selectedObservation?.UserObserved) && (!signaturePhase?.ObservedSignedChecked || selectedObservation?.ObserverUser !== observationMgr?.AppUserState.userProfile.Id || !signaturePhase?.ObservedSignedChecked || signaturePhase?.ObserverSignedChecked)} fieldName='ObserverSignature' onChange={onChangeSignature} value={signaturePhase?.ObserverSignature} placeholder={'Digital Signature'}></InputField>
                                        <InputField title={"Email Address"} isDisabled={!observationMgr?.hasObserverEntryPermission(selectedObservation?.UserObserved) && (!signaturePhase?.ObservedSignedChecked || selectedObservation?.ObserverUser !== observationMgr?.AppUserState.userProfile.Id || !signaturePhase?.ObservedSignedChecked || signaturePhase?.ObserverSignedChecked)} fieldName='ObserverSignatureEmail' onChange={onChangeSignature} value={signaturePhase?.ObserverSignatureEmail} placeholder={'Confirm Email'}></InputField>
                                        <div className='confirm-field'>{!savingSignature ? <><CheckboxControl onChange={(e) => onChangeSignature(e.target.value, 'ObserverSignedChecked')} disabled={!observationMgr?.hasObserverEntryPermission(selectedObservation?.UserObserved) && (!signaturePhase?.ObserverSignature || selectedObservation?.ObserverUser !== observationMgr?.AppUserState.userProfile.Id || !signaturePhase?.ObserverSignatureEmail || !signaturePhase?.ObserverSignature)} value={signaturePhase?.ObserverSignedChecked ?? false} /><div className='confirm-label'>Check to Verify</div></> : <Loading />}</div>
                                    </div>
                                </>
                            }
                            {signaturePhase.currentPhase === 2 &&
                                <div className='signature-fields'>
                                    <h4>Please print and sign the observation report. Once the document has been signed, {selectedObservation?.ObserverUser !== observationMgr?.AppUserState.userProfile.Id ? 'your observer will click' : 'click'} "Complete" to complete the observation process.</h4>
                                </div>
                            }
                            {signaturePhase.currentPhase === 3 &&
                                <div className='signature-fields'>
                                    <h4>Digital signature completed successfully! Please click "Print Report" to print a signed copy for your records. {selectedObservation?.ObserverUser !== observationMgr?.AppUserState.userProfile.Id ? 'Your observer will click' : 'Click'} "Complete" to complete the observation process.</h4>
                                </div>
                            }
                        </div>
                    </div>
                </DialogControl>}
            <MainLayout errors={errors} className={'observation-page'} validationErrors={validationErrors} >
                <ScreenWrapper className={'observation-screen-wrapper center-content overflow-auto'} loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                    {<>
                        <ContentRecommendation
                            isActive={isContentRecommendationDialogueActive}
                            recommendedContentItems={recommendedContent}
                            indicatorName={refinementObj?.indicator ? tagMap[refinementObj?.indicator]?.name : null}
                            observee={observeeData.observee}
                            observer={{ name: observeeData.observerInfo, Id: observeeData.observerId }}
                            onClose={() => {
                                setIsContentRecommendationDialogueActive(false);
                            }}
                            recommend={(contentId) => {
                                let content = [...recommendedContent]
                                content = content.reduce((r, cv) => {
                                    if (cv._id === contentId) {
                                        cv.Recommended = !cv.Recommended
                                    }
                                    r.push(cv);
                                    return r;
                                }, []);

                                setRecommendedContent(content);
                            }}
                            onSend={() => {
                                saveContentRecommendations();
                            }
                            }
                            onSkip={() => {
                                navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}`);
                            }}
                            saveContentAsSeen={(contentItem) => {
                                saveContentRecommendations(contentItem);
                            }}
                            isReviewPage={true}
                            canMakeSelections={observationMgr?.canViewRecommendedContent(selectedObservation) && observationMgr?.canEditRecommendedContent(selectedObservation)}
                        />
                        {
                            rubricModal.isActive &&
                            <>
                                <div className={'blockui'}></div>
                                <div className={`rubric-modal${!rubricModal.indicator ? ' expanded-height' : ''}`}>
                                    <div className={'rubric-modal-header'}>
                                        <div className={'rubric-title'}>
                                            {observeeData.rubricName} Rubric
                                        </div>
                                        <div className={'btn-ctrl-section'}>
                                            {printing ? <div style={{ marginRight: '2rem' }}><Loading /></div> : <FontAwesomeIcon title="Print Rubric" className={'fa-icon'} icon={faPrint} onClick={() => handleOnPrintRubric()} />}
                                            {!printing &&
                                                <>
                                                    <FontAwesomeIcon title="Download Rubric" className={'fa-icon'} icon={faCloudArrowDown} />
                                                    <FontAwesomeIcon title="Close" className={'fa-icon'} icon={faXmark} onClick={() => setRubricModal({ isActive: false, indicator: null })} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className={'rubric-section'}>
                                        <SurveyTable
                                            isSelfReflection={true}
                                            disableActOfRating={true}
                                            gridData={rubricModal.indicator ? {
                                                metaData: rubricGridData.metaData,
                                                data: rubricGridData.data.reduce((r, cv) => {
                                                    if (cv.tag?.name === rubricModal.indicator) {
                                                        r.push(cv);
                                                    }
                                                    return r;
                                                }, [])
                                            } : rubricGridData}
                                            fixedRowWidth={'9rem'}
                                            customRowHeight={{ height: rubricModal.indicator ? '100%' : '60%' }}
                                            hideRatingBtn={true}
                                            hideIndicatorsProgress={true}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        {observationMgr && <>
                            <div className={`observation-subheader`}>
                                <div className={'observee-area'}>
                                    <div className={'observee-banner'}>
                                        <div className={'text'}>{observeeData?.observee}</div>
                                    </div>
                                    <div className={'observee-metadata'}>
                                        <div>{observeeData?.isLegacy ? moment(observeeData?.observeDate).format('MMMM D, YYYY @ h:mm A') : FormatUTCDateTimeLong(observeeData?.observeDate)}</div>
                                        <div>{observeeData?.address}</div>
                                        <div>
                                            Observed by {observeeData?.observer} (via{<span title="View Rubric" onClick={() => showRubric()} style={{ textDecoration: 'underline' }}>{`${observeeData?.rubricName} Rubric`}</span>})
                                            <span>
                                                <FontAwesomeIcon icon={faSquarePollHorizontal} onClick={() => showRubric()} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='control-box-right-header'>
                                    <div className={'control-button-area'}>
                                        {(!observationMgr?.disableSelfReflection(observationConfig, isTeacher) && (selectedObservation?.ObservationStatusType === SystemTypes.ObservationStatusType.SelfReflectionNotStarted || selectedObservation?.ObservationStatusType === SystemTypes.ObservationStatusType.SelfReflectionInPrgress)) &&
                                            <ButtonControl className={'resend-selfreflection-email'} disabled={emailSent} loading={savingObs} type={'cancel'} onClick={handleResendSelfReflectionEmail}>Send Self-Reflection Request</ButtonControl>}
                                        {(canPrintDetailed &&
                                            (selectedObservation.ObservationStatusType === SystemTypes.ObservationStatusType.SelfReflectionSubmitted ||
                                                (observationMgr?.disableSelfReflection(observationConfig, isTeacher) && selectedObservation.ObservationStatusType === SystemTypes.ObservationStatusType.Submitted)
                                            )) &&
                                            <ButtonControl loading={savingObs} type={'cancel'} onClick={handleStartPostConference}>Release Scores</ButtonControl>}
                                        {canPrintDetailed && <ButtonControl type={'cancel'} loading={printing} onClick={() => { handleOnPrintReport(true) }}>Print Detailed Report</ButtonControl>}
                                        <ButtonControl type={'cancel'} loading={printing} onClick={() => { handleOnPrintReport(false) }}>Print Report</ButtonControl>
                                        <ButtonControl type={'okay'} onClick={handleOnCancel}>&nbsp;Exit&nbsp;</ButtonControl>
                                    </div>
                                    {selectedObservation?.ObservationStatusType == SystemTypes.ObservationStatusType.SelfReflectionSubmitted &&
                                        <div className={'observation-message'}>
                                            Once the Self-Reflection has been reviewed, please click <b>Release Scores</b> to start the Post-Conference. This allows {observeeData?.observee} to review their observation scores.
                                        </div>
                                    }
                                    {selectedObservation?.ObservationStatusType == SystemTypes.ObservationStatusType.PostConference &&
                                        <div className={'observation-message'}>
                                            Please scroll to the bottom and click <b>Submit</b> to sign, verify and complete the observation.
                                        </div>
                                    }
                                </div>
                            </div>


                            <div className={`content-section-review`}>
                                {_.map(reviewData?.RubricDomains, rd => <>
                                    <div className='sub-section'>
                                        <div className='review-grid'>
                                            <div className='review-header'>
                                                <div className='name'>{rd.DomainName}</div>
                                                <div className={`rating${observationMgr?.disableSelfReflection(observationConfig, isTeacher) ? ' noSelf' : ''}`}>Observer {!observationMgr?.disableSelfReflection(observationConfig, isTeacher) && <br />}Ratings</div>
                                                {!observationMgr?.disableSelfReflection(observationConfig, isTeacher) && <div className='rating'>Self-Reflection<br />Ratings</div>}
                                            </div>
                                            <div className='review-body'>
                                                {_.map(_.sortBy(rd.RubricIndicatorRatings, (o) => { return o.IndicatorSequence }), rir => <>
                                                    <div className='review-row'>
                                                        <div className='name'>
                                                            <div style={{ cursor: 'pointer' }} onClick={() => showRubric(rir.IndicatorName)}>{rir.IndicatorName} ({rir.IndicatorShortName})</div> {hasEvidence(rir.RubricIndicatorId) &&
                                                                <div onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleOnShowEvidence('SectionIdGoesHere');
                                                                    toggleEvidenceDialogue(rir.IndicatorName);
                                                                }}
                                                                    className='show-evidence'
                                                                >
                                                                    Evidence
                                                                </div>}
                                                        </div>
                                                        <div className={`rating${observationMgr?.disableSelfReflection(observationConfig, isTeacher) ? ' noSelf' : ''}`}>{rir.ObservationValue}</div>
                                                        {!observationMgr?.disableSelfReflection(observationConfig, isTeacher) && <div className='rating'>{rir.SelfReflectionValue}</div>}
                                                    </div>
                                                </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>)}

                                <div className='sub-section'>
                                    {//selectedObservation?.ObservationStatusType < 9 &&
                                        <div className='review-btn-row'>
                                            <div>{`Go back and ${canEditScripting && !observationMgr.isObservationComplete(selectedObservation) ? 'edit ' : 'view '} responses:`}</div>

                                            {(canViewScripting || (canViewScripting && canEditScripting)) && <ButtonControl type={'cancel'} onClick={handleOnEditObservation}>{`${canEditScripting && !observationMgr.isObservationComplete(selectedObservation) ? 'Edit ' : 'View '}Observation`}</ButtonControl>}
                                            {(!observationMgr?.disableSelfReflection(observationConfig, isTeacher) && (canViewSelfReflection || canEditSelfReflection)) && <ButtonControl type={'cancel'} onClick={handleOnEditSelfReflection}>{`${canEditSelfReflection && !observationMgr.isObservationComplete(selectedObservation) ? 'Edit ' : 'View '}Self-Reflection`}</ButtonControl>}
                                        </div>
                                    }
                                </div>

                                <div className='sub-section info'>

                                    <div className='info-section'>
                                        <div className='info-header'>Observer</div>
                                        <div className='info-sub-header'>Reinforcement</div>
                                        <div className='info-label'>
                                            <div className='info-label-header'>Evidence/Objective:</div>
                                            <div>{getReinforcement(false).name}</div>
                                        </div>
                                        <div><textarea value={getReinforcement(false).details} readOnly={true} /></div>
                                        <div className='info-sub-header'>Refinement</div>
                                        <div className='info-label'>
                                            <div className='info-label-header'>Evidence/Objective:</div>
                                            <div>{getRefinement(false).name}</div>
                                        </div>
                                        <div><textarea readOnly={true} value={getRefinement(false).details} /></div>
                                    </div>
                                    {!observationMgr?.disableSelfReflection(observationConfig, isTeacher) &&
                                        <div className='info-section'>
                                            <div className='info-header'>Self-Reflection</div>
                                            <div className='info-sub-header'>Reinforcement</div>
                                            <div className='info-label'>
                                                <div className='info-label-header'>Evidence/Objective:</div>
                                                <div>{getReinforcement(true).name}</div>
                                            </div>
                                            <div><textarea readOnly={true} value={getReinforcement(true).details} /></div>
                                            <div className='info-sub-header'>Refinement</div>
                                            <div className='info-label'>
                                                <div className='info-label-header'>Evidence/Objective:</div>
                                                <div>{getRefinement(true).name}</div>
                                            </div>
                                            <div><textarea readOnly={true} value={getRefinement(true).details} /></div>
                                        </div>}

                                </div>

                                <div className='sub-section'>
                                    {!observationMgr?.disableSelfReflection(observationConfig, isTeacher) && (selectedObservation?.ObservationStatusType >= 6 && selectedObservation?.ObservationStatusType < 9) &&
                                        <div className='review-btn-row'>
                                            <div>Review self-reflection for <b>{observeeData?.observee}</b>. To complete this observation, please click the "Submit" button:</div>
                                            <ButtonControl type={'okay'} onClick={handleOnSubmitObservation}>Submit</ButtonControl>
                                        </div>
                                    }
                                    {observationMgr?.disableSelfReflection(observationConfig, isTeacher) && (selectedObservation?.ObservationStatusType >= 6 && selectedObservation?.ObservationStatusType < 9) &&
                                        <div className='review-btn-row'>
                                            <div>To complete this observation, please click the "Submit" button:</div>
                                            <ButtonControl type={'okay'} onClick={handleOnSubmitObservation}>Submit</ButtonControl>
                                        </div>
                                    }
                                    {selectedObservation?.ObservationStatusType < 6 &&
                                        <div className='review-btn-row'>
                                            <div>Observation process has not yet been completed. {!observationMgr?.disableSelfReflection(observationConfig, isTeacher) && <>The self-reflection ratings must be entered.</>}</div>
                                            <ButtonControl type={'okay'} disabled={true}>Submit</ButtonControl>
                                        </div>
                                    }

                                    {selectedObservation?.ObservationStatusType > 8 &&
                                        <div className='review-btn-row'>
                                            <div>Observation has been <b>Completed</b> and <b>Verified</b>.</div>
                                        </div>
                                    }
                                </div>
                                <br />
                            </div>

                        </>}
                    </>}
                </ScreenWrapper>
            </MainLayout>
        </>
    );
}

export default ObservationReview;