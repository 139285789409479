import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonControl from '../../../components/controls/ButtonControl';
import MainLayout from '../../../components/layout/MainLayout';
import { useAdminContentManager, useAppUserManager, useImportExportManager, useRubricManager, useTagManager } from '../../../hooks/useManagers';
import { ContentTypeEnum } from '../../contentLibrary/services/ContentService';
import ContentLibraryDetails from '../components/contentLibrary/details';
import ContentLibraryFilter from '../components/contentLibrary/filter';
import ContentLibraryTable from '../components/contentLibrary/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { DialogControl } from '../../../components/controls/DialogControl';
import { TreePicker } from 'rsuite';
import _ from 'lodash';
import SystemTypes from '../../../SystemTypes';

const ContentMgmt = ({ authMgr }) => {
    const contentMgr = useAdminContentManager();
    const rubricManager = useRubricManager();

    const [updateContent, setUpdateContent] = useState();
    const [manageMode, setManageMode] = useState('list');
    const [loadingError, setLoadingError] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(true);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [message, setMessage] = useState(null);

    const [certificationRubrics, setCertificationRubrics] = useState(null);
    const [manageRubricAnswers, setManageRubricAnswers] = useState(null);
    const [certificationRubricId, setCertificationRubricId] = useState(null);


    const importExportMgr = useImportExportManager();
    const [exportLoading, setExportLoading] = useState(false);
    const appUserMgr = useAppUserManager();

    const params = useParams();
    const [contentId, setContentId] = new useState(params?.contentId === undefined || params?.contentId?.toLowerCase() === 'addnew' ? null : params?.contentId);
    const navigate = useNavigate();
    useEffect(() => {
        if (contentMgr) {
            setLoading(true);
            contentMgr.SearchContent(undefined, true).then(() => {
                setUpdateContent(new Date());
                setLoading(false);
            });
        }
    }, [contentMgr, contentMgr?._forceContentUpdate]);

    useEffect(() => {
        if (params?.contentId?.toLowerCase() === 'addnew') {
            setManageMode('add');
            setContentId(undefined);
        }
        else if (params?.contentId) {
            setManageMode('edit');
            setContentId(params?.contentId);
        }
        else {
            setManageMode('list');
            setContentId(undefined);
        }
    }, [params?.contentId])

    const handleOnFilterChange = (field, value) => {
        const contentFilter = contentMgr._contentFilter;
        const updatedContentFilter = { ...contentFilter };
        if (field.toLowerCase() === 'contenttypeids') {
            let contentTypeIds = [];
            if (!value.resources && !value.learningModules && !value.videos) {
                contentTypeIds.push(ContentTypeEnum.Resource)
                contentTypeIds.push(ContentTypeEnum.LearningModule)
                contentTypeIds.push(ContentTypeEnum.Video)
            }
            else {
                if (value.resources)
                    contentTypeIds.push(ContentTypeEnum.Resource)
                if (value.learningModules)
                    contentTypeIds.push(ContentTypeEnum.LearningModule)
                if (value.videos)
                    contentTypeIds.push(ContentTypeEnum.Video)
            }
            updatedContentFilter.contentTypeIds = contentTypeIds;
        }
        else if (field.toLowerCase() === 'search') {
            updatedContentFilter.search = value !== '' ? value : null;
        }
        else if (field.toLowerCase() === 'tagids') {
            updatedContentFilter.tagIds = value;
        }
        else if (field.toLowerCase() == 'isactive') {
            if (value === '1')
                updatedContentFilter.isActive = true;
            else if (value == '0')
                updatedContentFilter.isActive = false;
            else
                updatedContentFilter.isActive = undefined;
        }
        else if (field.toLowerCase() == 'useforcertification') {
            if (value === '1')
                updatedContentFilter.useForCertification = true;
            else if (value == '0')
                updatedContentFilter.useForCertification = false;
            else
                updatedContentFilter.useForCertification = undefined;
        }

        contentMgr.FilterContent(updatedContentFilter);
    }

    useEffect(() => {
        if (contentMgr?.FilteredContent) {
            setUpdateContent(new Date());
        }
    }, [contentMgr?.FilteredContent]);

    useEffect(() => {
        if (rubricManager) {
            rubricManager.getCertificationRubrics().then((items) => {
                const filteredRubrics = _.filter(items, itm => itm.IsActive && itm.SchoolYearIdentifier && !itm.IsDeleted && _.filter(itm.RubricDomainMap, rdm => rdm.UseForCertification).length > 0);
                setCertificationRubrics(_.map(filteredRubrics, itm => {
                    return {
                        ...itm,
                        fullName: `${itm.Name}`
                    }
                }));
            });
        }
    }, [rubricManager])

    /*useEffect(() => {
        const id = manageRubricAnswers.Id;
        this.contentMgr.ItemManager.get(id).then(content => {
            content.RubricAnswerKey
        });
        setCertificationRubrics()
    }, [manageRubricAnswers]);*/

    const handleOnDelete = async () => {
        const result = await contentMgr.deleteItem(itemToDelete.Id);
        return result;
    }

    const handleOnEdit = (id) => {
        navigate(`/admin/content/${id}`);
    }

    const handleOnView = (id) => {
        navigate(contentMgr.GetViewUrl(id));
    }

    const handleOnCancel = () => {
        navigate('/admin/content');
        setManageMode('list');
        setLoadingError(null);
        setValidationErrors(null);
        setErrors(null);
    }

    const handleOnSave = async (saveResult) => {
        navigate('/admin/content');
        if (saveResult.Success) {
            contentMgr.ForceContentUpdate(true);
            setManageMode('list');
            setLoadingError(null);
            setValidationErrors(null);
            setErrors(null);
        }
    }



    const handleOnExport = async () => {
        const idList = contentMgr?.FilteredContent.map(x => x.Id);
        setExportLoading(true);
        const result = await importExportMgr.ExportData(SystemTypes.ImportExportType.ExportContent, idList);
        if (!result.Success) {
            setExportLoading(null);
            setErrors(["Failed to export content, if this issue continues please contact NIET support."]);
        }
        else {
            importExportMgr.downloadFile(result.Items.first()).then(r => {
                setExportLoading(false);
            }).catch(e => {
                setExportLoading(false);
                setErrors(["School content has been created and failed to download. Please check Import/Export Admin."]);
            });
        }
        return result;
    }


    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete '${itemToDelete?.Title}'? `} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <DialogControl
            openDialog={manageRubricAnswers}
            title={'Certification Rubric Answers'}
            subTitle={`Please select the rubric you would like to provide certifications answers for ${manageRubricAnswers?.Title}.`}
            onCancel={() => setManageRubricAnswers(null)}
            disableOk={!certificationRubricId}
            onOk={() => navigate(`/admin/content/rubricanswers/${manageRubricAnswers?.Id}/${certificationRubricId}`)}
            okText={'Ok'}>
            <div>
                <TreePicker
                    height={320}
                    width={400}
                    value={certificationRubricId}
                    data={certificationRubrics ?? []}
                    valueKey={'Id'}
                    labelKey={'fullName'}
                    onSelect={(a, val) => setCertificationRubricId(val)}
                    placement="autoVertical"
                />
            </div>
        </DialogControl>
        <MainLayout errors={errors} validationErrors={validationErrors} messages={message} >
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' &&
                    <></>
                }
                {manageMode === 'edit' &&
                    <div className='screen-header'>
                        <h3>Content Management</h3>
                        <h5>Edit Content</h5>
                    </div>
                }
                {manageMode === 'add' &&
                    <div className='screen-header'>
                        <h3>Content Management</h3>
                        <h5>Add New Content</h5>
                    </div>
                }

                {manageMode === 'list' ? <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <ContentLibraryFilter contentMgr={contentMgr} avaliableContentFilters={contentMgr?.AvaliableContentFilters ?? []} onFilterChange={handleOnFilterChange} />
                            <ContentLibraryTable onManageRubricAnswers={setManageRubricAnswers} updateContent={updateContent} filteredContent={contentMgr?.FilteredContent ?? []} onDelete={(item) => { setItemToDelete(item); }} onEdit={handleOnEdit} onView={handleOnView} />
                        </div>
                        <div className='screen-footer list'>
                            {authMgr?.canWrite('Content') && <ButtonControl type={'create'} onClick={() => { navigate('/admin/content/addnew') }}>Create Content</ButtonControl>}
                            {appUserMgr?.canView(appUserMgr?.AppUserState?.permissions.ExportContent) && <>&nbsp;&nbsp;<ButtonControl loading={exportLoading} type={'cancel'}
                                onClick={handleOnExport}>
                                Export Content
                            </ButtonControl></>}
                        </div>
                    </div>

                </>
                    :
                    <ContentLibraryDetails onMessage={setMessage} canWrite={authMgr?.canWrite('Content')} contentId={contentId} onSave={handleOnSave} onCancel={handleOnCancel} onValidationErrors={setValidationErrors} onLoading={setLoading} onErrors={setErrors} onLoadingError={setLoadingError} />
                }

            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default ContentMgmt;