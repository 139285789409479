import React, { useState } from 'react';
import '../../rubricList.scss';
import '../../rubricDialog.scss';
import '../../performanceDialog.scss';
import { useEffect } from 'react';
import { DialogControl } from '../../../../components/controls/DialogControl';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const RubricPerformanceLevelDialog = ({ dialogState, dialogClose, data, onSave, saveComplete }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('Edit');
    const [performanceLevelData, setPerformanceLevelData] = useState();
    const [domain, setDomain] = useState();
    const [indicator, setIndicator] = useState();
    const [indicatorId, setIndicatorId] = useState();
    const [ratingId, setRatingId] = useState();
    const [ratingTitle, setRatingTitle] = useState();
    const [description, setDescription] = useState('');
    const [value, setValue] = useState('');
    const [reactQuillRef, setReactQuillRef] = useState();

    useEffect(() => {
        if (dialogState) {
            setOpenDialog(dialogState);
            if (data) {
                setDomain(data.Domain);
                setIndicator(data.Indicator);
                setRatingTitle(data.RatingTitle);
                setDescription(data.Description);
                setRatingId(data.RatingId);
                setIndicatorId(data.IndicatorId);
            }
        }
    }, [dialogState]);

    useEffect(() => {
        if (saveComplete !== null && saveComplete != undefined) {
            if (saveComplete) {
                setOpenDialog(false);
                dialogClose();
            }
        }
    }, [saveComplete]);

    const handleOnSave = () => {
        var editor = reactQuillRef.getEditor();
        let editorText = editor.root.innerHTML;
        setDescription(editorText);
        //setPerformanceLevelData(description, { Domain: domain, IndicatorId: indicatorId, RatingId: ratingId, RubricId: rubricId });
        onSave(editorText, { Domain: domain, IndicatorId: indicatorId, RatingId: ratingId, RubricIndicatorRatingId: data.RubricIndicatorRatingId });
    }

    const handleOnCancel = () => {
        setOpenDialog(false);
        dialogClose();
    }

    return (<>
        <DialogControl openDialog={openDialog} onCancel={handleOnCancel} title={dialogTitle} onSave={handleOnSave} customSave={true} excludeCancel={false} className={'full-width'} footerClassName={'indicator-settings-footer'}>
            <div className='title-header'>
                <div className={'title performance-title'}>
                    <div className='sub-title'>{domain} {'>'} {indicator} {'>'} {ratingTitle}</div>
                </div>
            </div>
            <div style={{ paddingBottom: '10px' }}>
                <ReactQuill ref={(quill) => setReactQuillRef(quill)} theme="snow" className='rq-editing-area' value={description} />
                {/* <div className="rq-editing-area"/>
                </ReactQuill> */}
            </div>
        </DialogControl>
    </>
    );
}

export default RubricPerformanceLevelDialog;