import { useEffect, useState } from 'react';
import './details.scss';
import { InputField } from "../../../../components/controls/InputField";
import { useFeedbackManager } from "../../../../hooks/useManagers";
import ButtonControl from "../../../../components/controls/ButtonControl";
import _ from 'lodash';
import { FormatUTCDateTime } from '../../../../utilities/DateFormater';

const FeedbackDetails = ({ feedback, onCancel, onLoadingError, onLoading }) => {

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    return <div className='control-box-wrapper'>
        {feedback && <div className='control-box'>
            <InputField type="label" title="Name" value={feedback?.Name} fieldName="Name" />
            <InputField type="label" title="Email Address" value={feedback?.Email} fieldName="Email" />
            <InputField type="label" title="Institution" value={feedback?.Institution} fieldName="Institution" />
            <InputField type="label" title="Request Information" value={feedback?.Request} fieldName="Request" />
            <InputField type="label" title="Feedback Message" value={feedback?.Feedback} fieldName="Feedback" />
            <InputField type="label" title="Date Created" value={FormatUTCDateTime(feedback?.CreatedDate)} fieldName="CreatedDate" />
        </div>}
        <div className='screen-footer'>
            <div className='btn-wrapper-right screen-footer__btn'><ButtonControl type={'cancel'} onClick={handleOnCancel}>Back</ButtonControl></div>
        </div>
    </div>
}

export default FeedbackDetails;


