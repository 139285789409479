

const commatizeNum = (num) => {
    if(num){
        return Number(num.replace(/,/g, '')).toLocaleString('en-US', {
            maximumFractionDigits: 0,
            style: 'currency',
            currency: 'USD'
        }).replace('$', '');
    }
    return null;

};

export { commatizeNum };