import React from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { SelectListControl } from '../../../../components/controls';
import UserOrgTreeControl from '../../../../components/controls/UserOrgTreeControl';
import Loading from '../../../../components/Loading';
//import '../styles/survey-filter.scss';

export default function TeacherSurveyFilter({ surveyMgr, orgMgr, orgTree, onFilterChange }) {

    const onTreeClean = (id) => {
        handleOnChange(null, id);
    }

    const handleOnChange = (value, fieldName,) => {
        if (fieldName == "ExculdeFromOverallScoring" && value == "true") {
            value = true
        } else if (fieldName == "ExculdeFromOverallScoring" && value == "false") {
            value = false
        }
        if (onFilterChange)
            onFilterChange(fieldName, value);
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        surveyMgr.filterData({ search: null, surveyType: 2, orgIds: [], IsActive: 'UNK' });
    }

    const onTreeCleanOrg = (event) => {
        handleOnChange([], "organizations");
    }

    const onTreeSelect = (vals, field) => {
        let newTreeIds = orgMgr?.findSelected(vals);
        if (handleOnChange)
            handleOnChange(newTreeIds, field);
    }

    const surveyStatus = surveyMgr?.Filter?.status ?? 'UNK';
    return (
        <>
            <div className="d-flex justify-content-center contentFilter"> {/*survey-filter*/}
                <div>
                    <div className="filterTitle">Teacher Surveys</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search'
                            value={surveyMgr?.Filter?.search ?? ''}
                            fieldName='search'
                            disableError={true}
                            placeholder='Title'
                            onChange={handleOnChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            title='Teacher Surveyed'
                            value={surveyMgr?.Filter?.assignedTo ?? ''}
                            fieldName='assignedTo'
                            disableError={true}
                            placeholder='Teacher'
                            onChange={handleOnChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        {orgTree ? <InputField
                            id='organization'
                            title='Organization'
                            value={surveyMgr?.Filter?.organizations ?? []}
                            fieldName='organizations'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleOnChange}>
                            <UserOrgTreeControl
                                className={'filter-control-input'}
                                childrenKey="Children"
                                labelKey="Name"
                                valueKey="Id"
                                selectedValues={surveyMgr?.Filter?.organizations}
                                data={orgTree}
                                menuClassName={'org-tree-menu'}
                                onClean={onTreeCleanOrg}
                                onSelect={(a, v) => onTreeSelect(v, 'organizations')}
                                placement="autoHorizontalStart"
                            />
                        </InputField> : <Loading />}
                    </div>
                    <div className="filterControl">
                        <InputField id='surveyType'
                            title='Status'
                            value={(surveyStatus != 0 && surveyStatus != 1 && surveyStatus != 'UNK') ? 2 : surveyStatus}
                            fieldName='status'
                            disableError={true}
                            onChange={handleOnChange}>
                            <SelectListControl textValuePairs={[{ Text: 'Not Started', Value: 0 }, { Text: 'In Progress', Value: 1 }, { Text: 'Submitted', Value: 2 }]} />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField id='ExculdeFromOverallScoring'
                            title='Benchmark?'
                            value={surveyMgr?.Filter?.ExculdeFromOverallScoring ?? 'UNK'}
                            fieldName='ExculdeFromOverallScoring'
                            disableError={true}
                            onChange={handleOnChange}>
                            <SelectListControl textValuePairs={[{ Text: 'Yes', Value: true }, { Text: 'No', Value: false }]} />
                        </InputField>
                    </div>
                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}