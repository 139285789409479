import React, { useState, useEffect } from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { SelectListControl } from '../../../../components/controls';
import { TreePicker } from 'rsuite';
import $ from 'jquery';

export default function ClusterFilter({ org, orgMgr, clusterMgr, onFilterChange, hideFilter }) {

    const [districts, setDistricts] = useState(null)
    const [schools, setSchools] = useState(null)

    useEffect(() => {
        if (clusterMgr?.Filter?.ClientId) {
            setDistricts(orgMgr?.DistrictsOnly(clusterMgr?.Filter?.ClientId));
        }
        if (clusterMgr?.Filter?.DistrictId) {
            setSchools(orgMgr?.SchoolsOnly(clusterMgr?.Filter?.DistrictId));
        }
    }, [clusterMgr?.Filter]);




    const handleFilterChange = (value, fieldName) => {

        if (fieldName === "IsActive" && value === "true") {
            value = true
        } else if (fieldName === "IsActive" && value === "false") {
            value = false
        }

        if (onFilterChange) {
            onFilterChange(fieldName, value)
        }
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        if (hideFilter) {
            clusterMgr.filterData({ ...clusterMgr.Filter, search: null, IsSystemAdmin: null, IsActive: null });
        }
        else {
            clusterMgr.filterData({ search: null, ClientId: null, DistrictId: null, SchoolId: null, IsActive: true });
        }


        $(".rs-picker-toggle-clean").trigger("click");
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Cluster Management</div>
                    <div className="filterControl">
                        <InputField
                            id='role'
                            title='Search'
                            value={clusterMgr?.Filter?.search ?? ''}
                            fieldName='search'
                            disableError={true}
                            placeholder='Cluster'
                            onChange={(value, fieldName) => handleFilterChange(value, fieldName)}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        {org && !hideFilter && <InputField
                            title="Organization"
                            fieldName="ClientId"
                            disableError={true}
                        >
                            <TreePicker
                                height={320}
                                width={400}
                                data={org ?? []}
                                valueKey={'OrgId'}
                                labelKey={'Name'}
                                onClean={() => handleFilterChange("", 'ClientId')}
                                onSelect={(a, val) => handleFilterChange(val, 'ClientId')}
                                placement="autoVerticalStart"
                            />
                        </InputField>}
                    </div>
                    <div className="filterControl">
                        {hideFilter ? <></> : clusterMgr?.Filter?.ClientId ? <InputField
                            title="District"
                            fieldName="DistrictId"
                            disableError={true}
                        >
                            <TreePicker
                                height={320}
                                data={districts ?? []}
                                valueKey={'DistrictId'}
                                labelKey={'Name'}
                                onClean={() => handleFilterChange("", 'DistrictId')}
                                onSelect={(a, val) => handleFilterChange(val, 'DistrictId')}
                                placement="autoVerticalStart"
                            />
                        </InputField> : <InputField
                            title="District"
                            fieldName="DistrictId"
                            subTitle="Select an Organization First."
                            disableError={true}
                        >
                            <TreePicker
                                height={320}
                                disabled={true}
                                data={[]}
                                placement="autoVerticalStart"
                            />
                        </InputField>}
                    </div>
                    <div className="filterControl">
                        {hideFilter ? <></> : clusterMgr?.Filter?.DistrictId ? <InputField
                            title="School"
                            fieldName="SchoolId"
                            disableError={true}
                        >
                            <TreePicker
                                height={320}
                                width={400}
                                valueKey={'SchoolId'}
                                labelKey={'Name'}
                                data={schools ?? []}
                                onClean={() => handleFilterChange("", 'SchoolId')}
                                onSelect={(a, val) => handleFilterChange(val, "SchoolId")}
                                placement="autoVerticalStart"
                            />
                        </InputField> : <InputField
                            subTitle="Select a District First."
                            title="School"
                            fieldName="SchoolId"
                            disableError={true}
                        >
                            <TreePicker
                                height={320}
                                width={400}
                                disabled={true}
                                data={[]}
                                placement="autoVerticalStart"
                            />
                        </InputField>}
                    </div>
                    <div className="filterControl">
                        <InputField id='IsActive'
                            title='Active?'
                            fieldName='IsActive'
                            value={clusterMgr?.Filter?.IsActive ?? true}
                            disableError={true}
                            onChange={(value, fieldName) => handleFilterChange(value, fieldName)}>
                            <SelectListControl textValuePairs={[{ text: 'Yes', value: true }, { text: 'No', value: false }]} />
                        </InputField>
                    </div>
                    <div className="filterControl  btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}