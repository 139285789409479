
import ContentManager from '../../../managers/ContentManager';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './index.scss'
import { useAppUserManager, useContentLibraryManager, useContentManager } from '../../../../../hooks/useManagers';
import { DialogControl } from '../../../../../components/controls/DialogControl';
import _ from 'lodash';
import { CatchingPokemonSharp } from '@mui/icons-material';
import { ContentTypeEnum } from '../../../services/ContentService';

export default function StarRatingDialog({ contentId, contentTypeId, currentStarRating, onClose }) {
    const contentLibraryMgr = useContentLibraryManager();
    const contentMgr = useContentManager();
    const appUserMgr = useAppUserManager();

    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [starRating, setStarRating] = useState(null);
    const [isComplete, setIsComplete] = useState(false);
    const toggleDialog = () => setModal(!modal);

    useEffect(() => {
        if (contentId && appUserMgr && contentLibraryMgr) {
            if (!modal) {
                toggleDialog();
            }
        }
    }, [contentLibraryMgr, appUserMgr, contentId]);

    useEffect(() => {
        setStarRating(currentStarRating);
    }, [currentStarRating])

    const handleStarRatingChanged = async (val, e) => {
        if (val == 1 && starRating == 1) {
            val = 0
        }

        setStarRating(val);
        await handleSaveStarRating(val)
    };


    const handleSaveStarRating = async (val) => {
        if (!currentStarRating)
            setIsLoading(true);

        const r = await contentMgr.ChangeContentStarRating(contentId, val);
        if (r?.Success) {
            setTimeout(() => { setIsComplete(true) }, 1000);
            setIsLoading(false);
            contentMgr.ForceContentUpdate();
        }
        return r;
    }

    const closeDialog = () => {
        toggleDialog();
        onClose();
        setIsComplete(false);
    }

    return (
        <>
            <DialogControl hideButtonRow={true} openDialog={modal} onCancel={closeDialog}>
                <div className='rating-dialog'>
                    {!isLoading ? <>
                        {!isComplete && <>

                            <h2>Rate This  {`${contentTypeId === ContentTypeEnum.LearningModule ? 'Learning Module' : (contentTypeId === ContentTypeEnum.Video ? 'Video' : 'Resource')}`}</h2>
                            <div className='rating-dialog-stars'>
                                <div className={`star ${starRating >= 1 ? 'selected' : ''}`} onClick={(e) => handleStarRatingChanged(1, e)}>
                                    <svg>
                                        <path style={{ fill: starRating >= 1 ? "#003c5b" : "white", stroke: "#003c5b", strokeWidth: 2 }} xmlns="http://www.w3.org/2000/svg" d="M23.632 9.201a.628.628 0 0 1-.22.678l-5.726 4.96 1.727 7.394a.606.606 0 0 1-.935.676l-6.503-3.953-6.503 3.953a.713.713 0 0 1-.374.112.57.57 0 0 1-.34-.109.629.629 0 0 1-.222-.679l1.729-7.393L.539 9.879A.607.607 0 0 1 .897 8.78l7.536-.635 2.965-7.083a.62.62 0 0 1 1.155.001l2.965 7.082 7.536.635a.63.63 0 0 1 .578.42z" />
                                    </svg>
                                </div>
                                <div className={`star ${starRating >= 2 ? 'selected' : ''}`} onClick={(e) => handleStarRatingChanged(2, e)}>
                                    <svg>
                                        <path style={{ fill: starRating >= 2 ? "#003c5b" : "white", stroke: "#003c5b", strokeWidth: 2 }} xmlns="http://www.w3.org/2000/svg" d="M23.632 9.201a.628.628 0 0 1-.22.678l-5.726 4.96 1.727 7.394a.606.606 0 0 1-.935.676l-6.503-3.953-6.503 3.953a.713.713 0 0 1-.374.112.57.57 0 0 1-.34-.109.629.629 0 0 1-.222-.679l1.729-7.393L.539 9.879A.607.607 0 0 1 .897 8.78l7.536-.635 2.965-7.083a.62.62 0 0 1 1.155.001l2.965 7.082 7.536.635a.63.63 0 0 1 .578.42z" />
                                    </svg>
                                </div>
                                <div className={`star ${starRating >= 3 ? 'selected' : ''}`} onClick={(e) => handleStarRatingChanged(3, e)}>
                                    <svg>
                                        <path style={{ fill: starRating >= 3 ? "#003c5b" : "white", stroke: "#003c5b", strokeWidth: 2 }} xmlns="http://www.w3.org/2000/svg" d="M23.632 9.201a.628.628 0 0 1-.22.678l-5.726 4.96 1.727 7.394a.606.606 0 0 1-.935.676l-6.503-3.953-6.503 3.953a.713.713 0 0 1-.374.112.57.57 0 0 1-.34-.109.629.629 0 0 1-.222-.679l1.729-7.393L.539 9.879A.607.607 0 0 1 .897 8.78l7.536-.635 2.965-7.083a.62.62 0 0 1 1.155.001l2.965 7.082 7.536.635a.63.63 0 0 1 .578.42z" />
                                    </svg>
                                </div>
                                <div className={`star ${starRating >= 4 ? 'selected' : ''}`} onClick={(e) => handleStarRatingChanged(4, e)}>
                                    <svg>
                                        <path style={{ fill: starRating >= 4 ? "#003c5b" : "white", stroke: "#003c5b", strokeWidth: 2 }} xmlns="http://www.w3.org/2000/svg" d="M23.632 9.201a.628.628 0 0 1-.22.678l-5.726 4.96 1.727 7.394a.606.606 0 0 1-.935.676l-6.503-3.953-6.503 3.953a.713.713 0 0 1-.374.112.57.57 0 0 1-.34-.109.629.629 0 0 1-.222-.679l1.729-7.393L.539 9.879A.607.607 0 0 1 .897 8.78l7.536-.635 2.965-7.083a.62.62 0 0 1 1.155.001l2.965 7.082 7.536.635a.63.63 0 0 1 .578.42z" />
                                    </svg>
                                </div>
                                <div className={`star ${starRating >= 5 ? 'selected' : ''}`} onClick={(e) => handleStarRatingChanged(5, e)}>
                                    <svg>
                                        <path style={{ fill: starRating >= 5 ? "#003c5b" : "white", stroke: "#003c5b", strokeWidth: 2 }} xmlns="http://www.w3.org/2000/svg" d="M23.632 9.201a.628.628 0 0 1-.22.678l-5.726 4.96 1.727 7.394a.606.606 0 0 1-.935.676l-6.503-3.953-6.503 3.953a.713.713 0 0 1-.374.112.57.57 0 0 1-.34-.109.629.629 0 0 1-.222-.679l1.729-7.393L.539 9.879A.607.607 0 0 1 .897 8.78l7.536-.635 2.965-7.083a.62.62 0 0 1 1.155.001l2.965 7.082 7.536.635a.63.63 0 0 1 .578.42z" />
                                    </svg>
                                </div>
                            </div>
                        </>}
                        {isComplete && <div className='rating-dialog-stars'>
                            <h2>Thanks for your feedback!</h2>
                        </div>}
                    </> : <div className="rating-dialog-loader"><CircularProgress color={'inherit'} size={25} /></div>
                    }
                </div>
            </DialogControl>
        </>
    );
}
