//https://blog.logrocket.com/simplify-react-state-management-with-hookstate/
import { hookstate } from '@hookstate/core'

const rubricStore = hookstate({
    rubricFilter: { EvaluationType: [], SchoolYear: [], Organization: [], Date: null, Status: [] },
    allRubrics: null,
    filteredRubrics: [],
    availableRubricFilters: null,
    selectedRubric: null,
    forceRubricUpdate: false,
    rubricMetadata: null
});
export default rubricStore;