import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import ContentSyncDetails from '../components/contentSync/details';
import ContentSyncFilter from '../components/contentSync/filter';
import ContentSyncTable from '../components/contentSync/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useAppUserManager, useContentSyncManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';
import moment from 'moment';

const ContentSyncMgmt = () => {
    const contentSyncMgr = useContentSyncManager();
    const appUserMgr = useAppUserManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedContentSync, setSelectedContentSync] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [activeSync, setActiveSync] = useState(null);
    const [runSync, setRunSync] = useState(false);
    const [reload, setReload] = useState(null);
    const [retryContentSync, setRetryContentSync] = useState(null);
    const [reloadTimer, setReloadTimer] = useState(null);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (contentSyncMgr && !params?.contentSyncId) {
            setLoading(true);
            contentSyncMgr.search('x => x.IsDeleted == false', false, undefined, false, ['LoadedByUser']).then(fb => {
                setManageMode('list');
                setLoading(false);
            });
        }
        else if (!selectedContentSync && params?.contentSyncId && contentSyncMgr) {
            setLoading(true);
            contentSyncMgr.get(params?.contentSyncId).then((r) => {
                if (r.Success) {
                    setManageMode('view');
                    setSelectedContentSync(r.Items.first());
                }
                setLoading(false);
            });
        }
    }, [contentSyncMgr, params?.contentSyncId]);


    useEffect(() => {
        if (reload) {
            if (contentSyncMgr) {
                contentSyncMgr.search('x => x.IsDeleted == false', true, undefined, false, ['LoadedByUser']).then((items) => {
                    const currentSync = _.find(items, x => x.IsComplete == false);
                    if (currentSync)
                        setActiveSync(currentSync);
                    else if (activeSync && items.length === contentSyncMgr._allData?.length - 1) {
                        contentSyncMgr.addActiveSync(activeSync);
                    }
                    else {
                        setActiveSync(null);
                    }

                    setReload(null);
                })
            }
        }
    }, [reload]);

    const handleOnFilterChange = async (field, value) => {
        const contentSyncFilter = contentSyncMgr.Filter;
        const updatedContentSyncFilter = { ...contentSyncFilter };
        if (field.toLowerCase() === 'search') {
            updatedContentSyncFilter.search = value !== '' ? value : null;
        }
        else {
            updatedContentSyncFilter[field] = value;
        }
        await contentSyncMgr.filterData(updatedContentSyncFilter);
    }

    const handleOnComplete = async () => {
        const item = { ...itemToDelete };
        contentSyncMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.Name}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnView = (item) => {
        setSelectedContentSync(item);
        setManageMode('view');
        navigate(`/admin/contentSync/${item.Id}`);
    }


    const handleOnCancel = () => {
        navigate('/admin/contentSync');
        if (contentSyncMgr.FilteredData) {
            setManageMode('list');
        }
        else {
            setManageMode(null);
        }
        setLoadingError(null);
        setErrors(null);
    }

    const handleOnRunSync = async () => {
        contentSyncMgr.runSync(retryContentSync?.Id);
        const userProfile = appUserMgr?.GetGlobalState()?.userProfile;
        let tempActiveSync = null;
        if (!retryContentSync) {
            tempActiveSync = {
                FileName: 'Unknown',
                LoadedByUserId: userProfile.Id,
                LoadedByUser: userProfile,
                RecordCount: 0,
                Status: 'Not Started',
                StartDateTime: moment().utc().toString(),
                EndDateTime: null,
                IsComplete: false,
                FileJson: '[]',
                Messages: []
            }

        }
        else {
            tempActiveSync = {
                ...retryContentSync,
                Id: undefined,
                LoadedByUserId: userProfile.Id,
                LoadedByUser: userProfile,
                RecordCount: 0,
                Status: 'Not Started',
                StartDateTime: moment().utc().toString(),
                EndDateTime: null,
                IsComplete: false,
                ParentContentSyncId: retryContentSync.Id,
                FileJson: '[]',
                Messages: []
            }
        }

        await contentSyncMgr.addActiveSync(tempActiveSync);
        setActiveSync(tempActiveSync);
        setRunSync(false);
        setRetryContentSync(null);
        return { Success: true };
    }


    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to remove the content sync for '${itemToDelete?.FileName ?? 'Unknown'}'?`} onCancel={() => setItemToDelete(null)} onOk={handleOnComplete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <DialogControl openDialog={runSync} title={retryContentSync ? 'Retry Import & Sync' : 'Content Import & Sync'} subTitle={`You are about to ${retryContentSync ? 'retry' : 'execute'} a content sync. Please read the instructions below and click 'Run Sync' to begin the import.`} onCancel={() => setRunSync(false)} onOk={handleOnRunSync} okText={'Run Sync'}>
            <>{!retryContentSync ? <div>
                <b>Directions</b><br />
                1) Create a CSV File ending in 'content_import.csv' the prefix of the file can be anything. Example: '01012023_update_<i>content_import.csv</i>' <br />
                2) Upload all files referenced within the content import to the profiled FTP Directory<br />
                3) Upload CSV File to the FTP Directory<br />
                4) Once the files have completed uploading, click <b>Run Sync</b> below.<br />
                5) The file upload will begin and run in the enviorment. This page will update and provide status during the process.<br />
            </div> : <div> Would you like to rerun this content sync?</div>}
            </>
        </DialogControl>
        <MainLayout errors={errors} >
            {manageMode === 'view' &&
                <div className='screen-header'>
                    <h3>Content Sync Management</h3>
                    <h5>View Content Sync</h5>
                </div>
            }
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <ContentSyncFilter contentSyncMgr={contentSyncMgr} onFilterChange={handleOnFilterChange} />
                            <ContentSyncTable refreshing={reload} onReload={() => setReload(new Date())} onRetry={(row) => { setRetryContentSync(row); setRunSync(true); }} filteredItems={contentSyncMgr?.FilteredData ?? []} onDelete={(item) => setItemToDelete(item)} onView={handleOnView} />
                        </div>
                        <div className='screen-footer list'>
                            {appUserMgr?.canWrite('ContentSync') && <ButtonControl disabled={activeSync !== null} type={'create'} onClick={() => { setRunSync(true) }}>Run Content Sync</ButtonControl>}
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&
                    <ContentSyncDetails contentSync={selectedContentSync} onCancel={handleOnCancel} onLoading={setLoading} onErrors={setErrors} onLoadingError={setLoadingError} />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default ContentSyncMgmt;