const ContentValidateObject = () => {
    return {
        Title: {
            fieldName: 'Title',
            messageTitle: 'title',
            isRequired: true,
            dataType: 'STRING',//IMAGE, INT, DEC, SELECT,BOOL,FILE
            groupId: 'CONTENT',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
        Description: {
            fieldName: 'Description',
            messageTitle: 'description',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'CONTENT',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
        DisplayFileName: {
            fieldName: 'DisplayFileName',
            messageTitle: 'display file name',
            isRequired: true,
            dataType: 'STRING',
            groupId: 'CONTENT',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
        ContentTypeId: {
            fieldName: 'ContentTypeId',
            messageTitle: 'content type',
            isRequired: true,
            dataType: 'SELECT',
            groupId: 'CONTENT',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        },
        FileId: {
            fieldName: 'FileId',
            messageTitle: 'file',
            isRequired: true,
            dataType: 'FILE',
            groupId: 'CONTENT',
            isRequired: true,
            regEx: undefined,
            requiredDependency: undefined
        }
    }
}

export default ContentValidateObject;