import React, { useState, useEffect } from 'react';
import { TableControl } from "../../../../components/controls";
import { asyncForEach } from "../../../contentLibrary/utils/jsUtil";
import CircularProgress from '@mui/material/CircularProgress';
import ButtonControl from '../../../../../src/components/controls/ButtonControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faNoteSticky, faPowerOff, faReceipt, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import userData from './userData.json'
import { useAppUserManager } from '../../../../hooks/useManagers';
import Loading from '../../../../components/Loading';
import _ from "lodash";

const StagedUserTable = ({ filteredUsers, roles, onView, onEdit, onDelete, onDisableEnable, selectedUserIds, toggleSelectAll }) => {
    const appUserMgr = useAppUserManager();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columns] = useState([
        {
            header: 'EE PASS #',
            dataProp: 'SystemId',
            width: '20%',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'Organization',
            dataProp: 'organization',
            width: '20%',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'District',
            dataProp: "district",
            width: '20%',
            leftAlign: true,
            canSort: true
        },
        {
            header: 'School',
            dataProp: "school",
            width: '20%',
            leftAlign: true,
            canSort: true
        },

        {
            header: 'Information',
            dataProp: 'info',
            sortProp: 'LastName',
            width: '25%',
            canSort: false
        },
        {
            header: 'Job Title',
            dataProp: 'userPositionName',
            width: '11%',
            canSort: true
        },
        {
            header: 'Created Date',
            dataProp: 'CreatedDate',
            datatype: 'date',
            width: '10%',
            centerItems: true,
            canSort: true
        }
    ])

    useEffect(() => {
        let newRows = [];
        if (filteredUsers && roles) {
            _.each(filteredUsers, (u) => {
                newRows.push({
                    ...u,
                    organization: u.Organizations,
                    district: u.Districts,
                    school: u.Schools,
                    info: <><div>{u.LastName}, {u.FirstName}<br />{u.Email}</div></>,
                    roles: getRoleData(u.RoleData), //TODO: sort by primary role in case multiple roles are here. Need more test data to test case and scenario
                });
            });
            setRows(newRows);
        }
    }, [filteredUsers, roles]);

    const getRoleData = (roleData) => {

        const data = roleData.split(",");
        const newLineData = data.join("\n");

        return newLineData;
    }

    const handleCheckHideAction = (row, field) => {
        if (field === 'custom1') {
            return !appUserMgr.canWrite(appUserMgr.GetGlobalState().permissions?.AzureB2CSaveUserProfiles);
        }
    }

    const handleCheckDisableAction = (row, field) => {
        if (field === 'edit') {
            return row.UserPositionType < appUserMgr?.GetGlobalState()?.userProfile?.UserPositionType;
        }
    }

    return <>
        <div className="contentDisplay-list">
            {!loading ?
                <>{filteredUsers &&
                    <TableControl
                        columns={columns}
                        data={rows}
                        useIntegratedPaging={['10', '25', '50', '100','1000','5000']}
                        onEdit={onEdit}
                        checkHideAction={handleCheckHideAction}
                        checkDisableAction={handleCheckDisableAction}
                        onDelete={onDelete}
                        toggleSelectAll={toggleSelectAll}
                        selectedUserIds={selectedUserIds}
                        onCustom1={{ action: (row) => <FontAwesomeIcon title='Activate User' className={`activate-check-fa-icon${!selectedUserIds?.some(id => id === row.Id) ? ' not-selected' : ''}`} icon={faSquareCheck} onClick={() => onDisableEnable(row)} /> }}
                        useScrollCol={true}
                        userTable={true}
                    />}
                </>
                : <div className="rating-dialog-loader"><Loading size={'2.5rem'} />Loading Data...{loading.toString()}</div>
            }
        </div>
    </>
}

export default StagedUserTable;