import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import { useOrgManager } from "../../../../hooks/useManagers";
import { FormatUTCDate } from "../../../../utilities/DateFormater";



const OrgTable = ({ filteredItems, onEdit, onDelete, onDrillDown }) => {
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Organization Name',
            dataProp: "Name",
            width: '35%',
            canSort: true
        },
        {
            header: 'Display Name',
            dataProp: 'DisplayName',
            width: '35%',
            canSort: true
        },
        {
            header: 'Active',
            dataProp: 'IsActive',
            width: '10%',
            datatype: 'bool',
            canSort: true
        },
        {
            header: 'Created Date',
            dataProp: 'CreatedDate',
            width: '20%',
            datatype: 'dateTime',
            canSort: true
        }
    ]);

    // const orgMgr = useOrgManager();
    // const [organizations, setOrganizations] = useState(null);

    // useEffect(() => {
    //     if (orgMgr) {
    //         orgMgr.loadOrgTree().then(r => {
    //             setOrganizations(orgMgr.OrgsOnly());
    //         });
    //     }
    // }, [orgMgr]);

    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (c) => {
                if (c) {
                    newRows.push({
                        ...c,
                        OrganizationName: c.DisplayName ?? c.Name
                    });
                }
            });
            setRows(newRows);
        }
    }, [filteredItems]);

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                permission='Client'
                useIntegratedPaging={['10', '25', '50', '100']}
                onEdit={onEdit}
                onDelete={onDelete}
                onCustom1={{ action: (row) => <FontAwesomeIcon className={'btn-icon-fa'} title="View Associated Districts" icon={faArrowDown} onClick={() => onDrillDown(row)} /> }}

            />
        </div>
    </>
}

export default OrgTable;