import React from "react";
import { useState } from "react";

import ButtonControl from "../../../components/controls/ButtonControl";
import PreferencesDialog from "../../contentLibrary/app-components/Content/PreferencesDialog";

import "./PreferencesDetails.scss";

const PreferencesDetails = () => {
  const [openContentPrefs, setOpenContentPrefs] = useState(false);

  return (
    <>
      {openContentPrefs && <PreferencesDialog
        openDialog={openContentPrefs}
        onClose={() => {
          setOpenContentPrefs(false);
        }}
      />}
      <h4 className="preferences__subheading">Preferences</h4>
      <div className="preferences__button">
        <ButtonControl type={'sub-action'} onClick={() => setOpenContentPrefs(true)}>
          Content Preferences
        </ButtonControl>
      </div>
    </>
  );
};

export default PreferencesDetails;
