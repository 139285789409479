import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ContentTypeEnum } from '../../services/ContentService';
import { useContentManager } from '../../../../hooks/useManagers';
import * as gaMgr from '../../../../managers/GoogleAnalyticsManager';
import ShareContentDisplay from '../../app-components/Content/ShareDisplay'
import ShareContentFilter from '../../app-components/Content/ShareFilter'
import ShareDialog from '../../app-components/Content/ShareDialog'
import Loading from '../../../../components/Loading';
import MainLayout from '../../../../components/layout/MainLayout';
import { EventTypeEnum } from '../../../../managers/GoogleAnalyticsManager';
import { DialogControl } from '../../../../components/controls/DialogControl';
import ScreenWrapper from '../../../../components/layout/screenWrapper';

export default function ContentShare({ authMgr }) {
    const navigate = useNavigate();
    const redirect = (path) => navigate(path);
    let contentMgr = useContentManager();
    let globalState = contentMgr?.GetGlobalState();

    const [isSharedWithUser, setIsSharedWithUser] = useState(false);
    const [updateSharedContent, setUpdateShareContent] = useState(null);
    const [confirmId, setConfirmId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (contentMgr) {
            setLoading(true)
            contentMgr.GetSharedContent().then(() => {
                setLoading(false);
            })
        }
    }, [contentMgr, contentMgr?._forceSharedContentUpdate]);

    const handleOnView = (keyId) => {
        redirect(contentMgr.GetViewUrl(keyId));
    }

    const handleOnDownload = (tempKeyId) => {
        if (contentMgr?.SelectedContent && contentMgr?.SelectedContent.KeyId === tempKeyId) {
            gaMgr.AddEvent(EventTypeEnum.Content_Download, tempKeyId, contentMgr?.SelectedContent);
            var link = document.createElement("a");
            link.download = contentMgr?.SelectedContent.DisplayFileName + '.' + contentMgr?.SelectedContent.FileExt;
            link.href = contentMgr?.SelectedContent.FileUrl;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            //window.location.href = contentMgr?.SelectedContent.FileUrl;
        }
        else {
            contentMgr.GetSingleContent(tempKeyId).then((c) => {
                gaMgr.AddEvent(EventTypeEnum.Content_Download, tempKeyId, c);
                var link = document.createElement("a");
                link.href = c.FileUrl;
                link.download = `${c.DisplayFileName}.${c.FileExt}`
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        }
    }

    const handleOnShare = (tempKeyId) => {
        if (globalState.selectedContent != null && globalState.selectedContent.KeyId === tempKeyId) {
            contentMgr.SetSelectedSharedContent(globalState.selectedContent);
        }
        else {
            contentMgr.GetSingleContent(tempKeyId).then((c) => {
                contentMgr.SetSelectedSharedContent(c);
            });
        }
    }

    const handleOnRemoveShare = (id) => {
        setConfirmId(id);
    }

    const doRemoveShareContent = () => {
        return contentMgr.RemoveShareContent(confirmId).then((r) => {
            contentMgr.ForceSharedContentUpdate(true);
            setConfirmId(null);
            setTimeout(() => { setLoading(false) }, 2000);
        });
    }

    const onFilterChange = (field, value) => {
        const { shareContentFilter } = contentMgr.GetGlobalState();
        const updatedShareContentFilter = { ...shareContentFilter };
        if (field === 'contentTypeIds') {
            let contentTypeIds = [];
            if (value.resources)
                contentTypeIds.push(ContentTypeEnum.Resource)
            if (value.learningModules)
                contentTypeIds.push(ContentTypeEnum.LearningModule)
            if (value.videos)
                contentTypeIds.push(ContentTypeEnum.Video)

            updatedShareContentFilter.contentTypeIds = contentTypeIds;
        }
        else if (field === 'search') {
            updatedShareContentFilter.search = value;
        }
        else if (field === 'tagIds') {
            updatedShareContentFilter.tagIds = value;
        }

        contentMgr.FilterSharedContent(updatedShareContentFilter);
        setUpdateShareContent(new Date());
    }

    return (<>
        <ShareDialog />
        <DialogControl openDialog={confirmId === null ? false : true} title={`Confirm Remove Sharing`} subTitle={`Are you sure you want to unshare the selected content?`} okText={'Yes'} cancelText={'No'} onCancel={() => setConfirmId(null)} onOk={() => { return doRemoveShareContent() }} >
        </DialogControl>
        <MainLayout>
            <ScreenWrapper loading={loading}>
                <div className="control-box-wrapper">
                    <div className="control-box-list">
                        <ShareContentFilter avaliableShareContentFilters={globalState?.avaliableShareContentFilters} shareContentFilter={globalState?.shareContentFilter} onFilterChange={onFilterChange} onIsSharedWithUser={setIsSharedWithUser} />
                        {(globalState && globalState?.avaliableShareContentFilters && globalState?.shareFilteredContent) ?
                            <ShareContentDisplay updateContent={updateSharedContent} onRemoveShare={handleOnRemoveShare} onDownload={handleOnDownload} onView={handleOnView} onShare={handleOnShare} shareMode={isSharedWithUser ? "sharedWithUser" : "userHasShared"} />
                            : <Loading />
                        }
                    </div>
                </div>
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}
