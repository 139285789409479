import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import ObservationDetailsDialog from '../components/management/details';
import ObservationFilter from '../components/management/filter';
import ObservationTable from '../components/management/table';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';
import Print from '../components/print';
import moment from 'moment';
import SystemTypes from '../../../SystemTypes';


const ObservationMgmt = ({ isTeacher, observationMgr }) => {
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedObservation, setSelectedObservation] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [printUrl, setPrintUrl] = useState(null);
    const [printing, setPrinting] = useState(null);

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (observationMgr) {
            setLoading(true);
            observationMgr.search({ ist: isTeacher, sy: observationMgr?.AppUserState?.selectedSchoolYear }).then(fb => {
                setLoading(false);
            });
        }
    }, [observationMgr, params?.observationId]);

    const onAddNew = () => {
        setSelectedObservation({ ...observationMgr?.DefaultItem, ObservationType: (isTeacher ? 2 : 3) });
    }

    const handleOnFilterChange = async (field, value) => {
        const observationFilter = observationMgr.Filter;
        const updatedObservationFilter = { ...observationFilter };
        updatedObservationFilter[field] = value !== '' ? value : null;
        await observationMgr.filterData(updatedObservationFilter);
    }

    const handleOnSave = async (item, keepOpen) => {
        item.Organization = null;
        item.District = null;
        item.School = null;
        item.Survey = null;
        item.Rubric = null;
        item.Observer = null;
        item.Observed = null;
        var result = await observationMgr.saveItem(item);
        if (result?.Success && !keepOpen) {
            setSelectedObservation(null);
        }
        else if (result.Success) {
            setSelectedObservation(result.Items.first());
        }
        return result;
    }

    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        observationMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnEdit = async (item) => {
        if (item.ObservationStatusType === 0) {
            observationMgr.get(item.Id).then(r => {

                if (r.Success) {
                    setSelectedObservation(r.Items.first());
                }
                else {
                    setErrors(["Failed to load observation."]);
                }
            });
        }
        else {
            if (observationMgr.canAccessScripting(item)) {
                navigate(`/Observation/${item.ObservationType != 3 ? 'Teacher' : 'SchoolAdmin'}/${item.Id}`);
            }
            else {
                if ((item.ObservationStatusType === SystemTypes.ObservationStatusType.Submitted ||
                    item.ObservationStatusType === SystemTypes.ObservationStatusType.SelfReflectionNotStarted) &&
                    item.UserObserved === observationMgr.AppUserState.userProfile.Id) {
                    const obsConfig = await observationMgr.observationConfigOperation(item.ClientId, item.DistrictId, item.SchoolId, item.SchoolYearIdentifier);
                    if (observationMgr.disableSelfReflection(obsConfig, isTeacher)) {
                        setErrors(["Your observer has not started the post-conference yet. You will receive a notification when the post-conference has begun."]);
                        return;
                    }
                }

                navigate(`/Observation/${item.ObservationType != 3 ? 'Teacher' : 'SchoolAdmin'}/SelfReflection/${item.Id}`);
            }
        }
    }

    const handleOnPrintReport = async (row) => {
        setPrinting(row.Id);
        const isDetailed = false;
        setPrintUrl(`/observation/print${(isDetailed && observationMgr?.canAccessEvidence(row)) ? 'detailed' : ''}/${row.Id}?d=${moment().toISOString(true)}`);
    }

    const handleOnCancel = () => {
        setSelectedObservation(null);
        setLoadingError(null);
        setErrors(null);
    }

    const handleOnView = (item) => {
        navigate(`/observation/${item.ObservationType != 3 ? 'Teacher' : 'SchoolAdmin'}/review/${item.Id}`);
    }

    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete the observation for '${itemToDelete?.ObservedFullName ?? "Unknown"}'?`} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        {printUrl && <Print printUrl={printUrl} onPrinting={() => setPrinting(null)} />}

        {observationMgr && <ObservationDetailsDialog isTeacher={isTeacher} observationMgr={observationMgr} openDialog={selectedObservation} observation={selectedObservation} onCancel={handleOnCancel} onSave={handleOnSave} onLoading={setLoading} onErrors={setErrors} onValidationErrors={setValidationErrors} onLoadingError={setLoadingError} />}

        <MainLayout errors={errors} validationErrors={validationErrors} >
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {<>
                    {observationMgr && <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <ObservationFilter name={isTeacher ? 'Teacher Observations' : 'School Admin Observations'} observationMgr={observationMgr} onFilterChange={handleOnFilterChange} />
                            <ObservationTable filteredItems={observationMgr?.FilteredData ?? []} onPrint={handleOnPrintReport} printing={printing} onView={handleOnView} onEdit={handleOnEdit} onDelete={(item) => { setItemToDelete(item); }} />
                        </div>
                        {observationMgr?.canAccessCreate(isTeacher) &&
                            <div className='screen-footer list'>
                                <ButtonControl type={'create'} onClick={onAddNew}>Create Observation</ButtonControl>
                            </div>
                        }
                    </div>}
                </>}
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default ObservationMgmt;