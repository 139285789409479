import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { faCopy, faShieldBlank } from "@fortawesome/free-solid-svg-icons";

import config from "../../../config.json";
import timeZoneData from '../../dev/data/timezones';
import { ProfileUpdateTypeEnum } from "../types/ProfileUpdateType.enum";
import ButtonControl from "../../../components/controls/ButtonControl";

import "./AccountSettingsDetails.scss";
import { useAppUserManager, useUserManager } from "../../../hooks/useManagers";
import { APIStatusType } from "../../../stores/globalAppUserStore";
import { DialogControl } from "../../../components/controls/DialogControl";
import { SelectPicker } from "rsuite";

const AccountSettingsDetails = ({
  eePassId,
  timeZoneAbbreviation,
  passwordResetOn,
  mobilePhone,
  isMfaEnabled,
  setProfileUpdateType,

}) => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const styles = { width: '100%', display: 'block', marginBottom: 10 };
  const [passwordResetOnText, setPasswordResetOnText] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [copiedTooltipVisible, setCopiedTooltipVisible] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [savingTimezone, setSavingTimezone] = useState(false);
  const appUserManager = useAppUserManager();
  const userManager = useUserManager();
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (appUserManager) {
      setIsAuthenticated(appUserManager.GetGlobalState().apiStatus === APIStatusType.OnlineSecure);
    }
  }, [appUserManager]);

  useEffect(() => {
    if (!selectedTimeZone) {
      setSelectedTimeZone(timeZoneAbbreviation);
    }
  }, [timeZoneAbbreviation]);

  useEffect(() => {
    if (passwordResetOn) {
      const datePasswordReset = new Date(0);
      datePasswordReset.setUTCSeconds(passwordResetOn);

      const dateFormatted = `${datePasswordReset.getMonth() + 1
        }/${datePasswordReset.getDate()}/${datePasswordReset.getFullYear()}`;

      const today = new Date();
      const differenceInTime = today - datePasswordReset.getTime();

      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 3600 * 24)
      );

      const wasChangedToday =
        today.getMonth() === datePasswordReset.getMonth() &&
        today.getDate() === datePasswordReset.getDate() &&
        today.getFullYear() === datePasswordReset.getFullYear();

      if (wasChangedToday === true) {
        setPasswordResetOnText(`Last changed today (${dateFormatted}).`);
      } else {
        setPasswordResetOnText(
          `Last changed ${differenceInDays} days ago (${dateFormatted}).`
        );
      }
    }

    updateTimeZone(timeZoneAbbreviation);

  }, [passwordResetOn, timeZoneAbbreviation]);

  const updateTimeZone = (timeZoneAbbr) => {
    if (timeZoneAbbr) {
      switch (timeZoneAbbr) {
        case "EST":
          setTimeZone("EST (Eastern Standard Time)");
          break;
        case "PST":
          setTimeZone("PST (Pacific Standard Time)");
          break;
        case "CST":
          setTimeZone("CST (Central Standard Time)");
          break;
        case "MST":
          setTimeZone("MST (Mountain Standard Time)");
          break;
        case "AST":
          setTimeZone("AST (Atlantic Standard Time)");
          break;
        case "HST":
          setTimeZone("HST (Hawaii Standard Time)");
          break;
        case "AZ":
          setTimeZone("AZ (AZ Time Zone)");
          break;
        default:
          setTimeZone("");
          break;
      }
    }
  }

  const handleOnSave = async () => {
    setSavingTimezone(true);
    const id = appUserManager.AppUserState.userProfile.Id;
    const userProfileResult = await userManager.get(id);

    if (userProfileResult.Success) {
      const userProfile = userProfileResult.Items.first();
      if (userProfile?.TimeZone !== selectedTimeZone) {

        userProfile.TimeZone = selectedTimeZone;
        const result = await userManager.ItemManager.runOperation('AzureB2CSaveUserProfile', undefined, userProfile, undefined, undefined, true);
        if (result.Success) {
          updateTimeZone(selectedTimeZone);
          window.location.href = window.location.href;
          setSavingTimezone(false);
        }
        else {
          setErrors(['Failed to save timezone.']);
          setSavingTimezone(false);
        }
      }
      else {
        setSavingTimezone(false);
        setOpenDialog(false)
      }
    }
    else {
      setErrors(['Failed to load user profile and save timezone.']);
      setSavingTimezone(false);
    }
  }

  const onEditTimeZone = () => {
    setOpenDialog(true);
  }

  const handleOnCancel = () => {
    setOpenDialog(false);
  }

  const handleOnTimezoneChange = (value) => {
    setSelectedTimeZone(value);
  }


  const onChangePasswordClicked = () => {
    if (isAuthenticated === false) {
      navigate("/logout");
    } else if (isAuthenticated) {
      setProfileUpdateType(ProfileUpdateTypeEnum.PROFILE_CHANGE_PASSWORD);
      instance.loginRedirect({
        redirectUri: window.location.href,
        extraQueryParameters: {
          p: config.AUTH.ADB2C_CHANGE_PASSWORD_POLICY,
          cancelUrl: encodeURIComponent(window.location.href),
        },
      });
    }
  };

  const onConfigureMfaClicked = () => {
    if (isAuthenticated === false) {
      navigate("/logout");
    } else if (isAuthenticated) {
      setProfileUpdateType(ProfileUpdateTypeEnum.PROFILE_CONFIGURE_MFA);
      instance.loginRedirect({
        redirectUri: window.location.href,
        extraQueryParameters: {
          p: config.AUTH.ADB2C_CONFIGURE_MFA_POLICY,
          cancelUrl: encodeURIComponent(window.location.href),
        },
      });
    }
  };

  const displayCopyToClipboard = () => {
    if (navigator.clipboard) return <FontAwesomeIcon icon={faCopy} />;
    return null;
  };

  const copyIdToClipboard = () => {
    navigator.clipboard.writeText(eePassId);
    document.querySelector(".tooltip-hover-text").style.visibility = "hidden";
    setCopiedTooltipVisible(true);
    document.querySelector(".tooltip-text").style.visibility = "visible";
    let toolTipInterval = setInterval(() => {
      clearInterval(toolTipInterval);
      setCopiedTooltipVisible(false);
      document.querySelector(".tooltip-text").style.visibility = "hidden";
    }, 1000);
  };

  const mouseOverCopyId = () => {
    if (!copiedTooltipVisible) {
      document.querySelector(".tooltip-hover-text").style.visibility =
        "visible";
    }
  };

  const mouseOutCopyId = () => {
    document.querySelector(".tooltip-hover-text").style.visibility = "hidden";
  };

  const loadStrengthenYourSecurity = () => {
    return <div className="account-settings__strengthen-security">
      <i>
        {!isMfaEnabled && <>Multi-Factor Authentication is used to strengthen the security of your account, by using your mobile phone to confirm your identity. </>} Please note, by changing your MFA status you will be prompted to log back into the application.
      </i>
    </div>
  };

  return (
    <>
      <DialogControl openDialog={openDialog} onCancel={handleOnCancel} loadingOk={savingTimezone} title={'Please choose a new Time Zone.'} onOk={handleOnSave} excludeCancel={false}>
        <div style={{ paddingBottom: '2.12em' }}>
          {errors &&
            <div className="error">{errors.first()}</div>
          }
          <SelectPicker className={'narrow-dropdown'} searchable={false} onChange={handleOnTimezoneChange}
            placeholder={'Select a Time Zone'} size='md' styles={styles} value={selectedTimeZone} data={timeZoneData} />
          <br /><br />
          <h5><i>Please note, this will cause the page to refresh once when save is completed.</i></h5>
        </div>
      </DialogControl >
      <div className="screen-header account-settings__heading-container">
        <FontAwesomeIcon className="fa-xl" icon={faShieldBlank} />
        <h2 className="account-settings__heading">Account Settings</h2>
      </div>

      <div className="field-input account-settings__main">
        <div className="generic-input-component">
          <div className="account-settings__field-main">
            <div className="title">
              <div>EE PASS #</div>
            </div>
            <div className="account-settings__field-value tooltip">
              <span>{eePassId}</span>
              <span
                className="account-settings__copy-ee-pass-id"
                onClick={copyIdToClipboard}
                onMouseOver={mouseOverCopyId}
                onMouseOut={mouseOutCopyId}
              >
                {displayCopyToClipboard()}
                <span className="tooltip-text">Copied!</span>
                <span className="tooltip-hover-text">Copy ID</span>
              </span>
            </div>
          </div>
          <div className="account-settings__field-main">
            <div className="title">
              <div>Time Zone</div>
            </div>
            <div className="account-settings__field-value">{timeZone}
              <span className="profile__field-edit">
                <FontAwesomeIcon
                  className="btn-icon-fa"
                  icon={faPencil}
                  onClick={onEditTimeZone}
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      <h4 className="account-settings__subheading">Password</h4>
      <h6 className="account-settings__subheading-desc-password">
        {passwordResetOnText}
      </h6>
      <div className="account-settings__button">
        <div className="btn-wrapper-right">
          <ButtonControl type={'sub-action'} onClick={onChangePasswordClicked}>
            Change Password
          </ButtonControl>
        </div>
      </div>

      <h4 className="account-settings__subheading">
        Multi-Factor Authentication (MFA)
      </h4>
      <div className="account-settings__subheading-desc-mfa">
        MFA is currently turned&nbsp;
        <span className="account-settings__subheading-desc-mfa-is-on">
          {isMfaEnabled ? "ON" : "OFF"}
        </span>
        .
      </div>
      <div className="field-input">
        <div className="generic-input-component">
          {isMfaEnabled && <div className="account-settings__field-mfa">
            <div className="title">
              <div>Linked Mobile</div>
            </div>
            <div className="account-settings__field-value">{mobilePhone}</div>
          </div>}
        </div>
      </div>
      {loadStrengthenYourSecurity()}
      <div className="account-settings__button">
        <div className="btn-wrapper-right">
          <ButtonControl type={'sub-action'} onClick={onConfigureMfaClicked}>
            Configure MFA
          </ButtonControl>
        </div>
      </div>
    </>
  );
};

export default AccountSettingsDetails;
