import { useEffect, useState } from 'react';
import { TreePicker } from "rsuite";
import { CheckboxControl } from "../../../../components/controls";
import { InputField } from "../../../../components/controls/InputField";
import '../../../../styles/rsuite_override.css';
import './details.scss';
import { useValidationManager } from "../../../../hooks/useValidation";
import { useTagManager } from "../../../../hooks/useManagers";
import ButtonControl from "../../../../components/controls/ButtonControl";
import _ from 'lodash';
import Loading from '../../../../components/Loading';

const TagDetails = ({ tagId, onSave, onCancel, onValidationErrors, onErrors, onLoadingError, onLoading }) => {

    const [itemData, setItemData] = useState({ UniqueId: tagId });
    const tagMgr = useTagManager();
    const validationManager = useValidationManager(false, ['TAG']);
    const [forceValidation, setForceValidation] = useState(false);
    const [saving, setSaving] = useState(false);
    const [defaultTreeValue, setDefaultTreeValue] = useState(null);
    const [parentTags, setParentTags] = useState(null);
    const [disablePrefRoot, setDisablePrefRoot] = useState(false);

    useEffect(() => {
        if (tagMgr && !parentTags) {
            tagMgr.AllParentTags().then(items => {
                setParentTags(_.map(items.filter(x => x._id !== tagId), x => { return { ...x, children: undefined } }));
            });
        }
    }, [tagMgr]);

    useEffect(() => {
        const screenData = sessionStorage.getItem('screen-data');
        if (screenData) {
            let item = JSON.parse(screenData);
            setItemData(JSON.parse(screenData));
            setDefaultTreeValue(item.ParentTagId ?? undefined)
        }
        else {
            if (tagMgr && tagId) {
                if (onLoading)
                    onLoading(true);

                tagMgr.ItemManager.get(tagId).then((r) => {
                    if (r?.Success && r?.Items?.length > 0) {
                        var item = r?.Items?.first();
                        if (!item.IsPreferenceRoot) {
                            tagMgr.query('x => x.IsActive == true && x.IsDeleted == false && x.IsPreferenceRoot == true').then(r => {
                                if (r.Success) {
                                    setDisablePrefRoot(true);
                                }
                            });
                        }

                        setItemData(item);
                        setDefaultTreeValue(item.ParentTagId ?? undefined)
                        saveItemInStorage(item);
                    }
                    else if (onLoadingError) {
                        onLoadingError(r?.Message.DisplayMessage);
                    }
                    if (onLoading)
                        onLoading(false);
                });
            }
            else {
                setDefaultTreeValue(undefined);
            }
        }
    }, [tagMgr]);

    const saveItemInStorage = (item) => {
        sessionStorage.setItem('screen-data', JSON.stringify(item));
    };

    const handleOnChange = (value, field) => {
        const updatedItemData = { ...itemData };
        updatedItemData[field] = value;
        saveItemInStorage(updatedItemData);
        setItemData(updatedItemData);
    }

    const onTreeClean = (event) => {
        handleOnChange(null, "ParentTagId");
    }

    const onTreeSelect = (activeNode, val) => {
        if (handleOnChange)
            handleOnChange(val, "ParentTagId");
    }

    const handleOnSave = async () => {
        const errors = validationManager.checkIsGroupValid(itemData);
        if (errors.length === 0) {
            if (onSave) {
                setSaving(true);
                const result = await tagMgr.ItemManager.save(itemData);
                if (result.Success) {
                    onSave(result);
                    setSaving(false);
                }
                else if (onErrors) {
                    onErrors([result.Message.DisplayMessage]);
                    setSaving(false);
                }
            }
        }
        else {
            if (onValidationErrors)
                onValidationErrors(errors);
            validationManager.setForceValidate(true);
            setForceValidation(true);
        }
    }

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    return <div className='control-box-wrapper'>
        {itemData && <div className='control-box'>
            <InputField title="Name" isDisabled={itemData?.Id} forceValidate={forceValidation} value={itemData?.Name} onChange={handleOnChange} groupId={'TAG'} placeholder={'Tag Name'} maxLength={400} subTitle={"Enter the title describing your tag."} fieldName="Name" validationName="Tag_Name" />
            <InputField title="Description" forceValidate={forceValidation} value={itemData?.Description} onChange={handleOnChange} maxLength={250} groupId={'TAG'} subTitle={"Provide a detailed description of your tag."} type="textarea" fieldName="Description" validationName="Tag_Description" />
            {(defaultTreeValue !== null && parentTags) ? <InputField
                id='parentTags'
                title='Parent Tag'
                fieldName='ParentTagId'
                className={'field-input__checkTreePicker'}
                validationName={'Tag_ParentTagId'}>
                <TreePicker
                    height={320}
                    width={400}
                    data={parentTags}
                    defaultValue={defaultTreeValue}
                    onClean={onTreeClean}
                    onSelect={onTreeSelect}
                    placement="autoVerticalStart"
                />
            </InputField> : <InputField id='parentTags'
                title='Parent Tags'
                fieldName='ParentTagId'
                className={'field-input__checkTreePicker'}
            ><Loading type="Default"></Loading></InputField>}
            {/*  <InputField title="Secure?" info={'All content marked with this tag will only be accessible by the user with the permission associated with this tag.'} value={itemData?.IsSecure ?? false} onChange={handleOnChange} maxLength={100} groupId={'TAG'} fieldName='IsSecure' validationName={'Tag_IsSecure'} subTitle={"Is this tag used to secure a piece of content?"} >
                <CheckboxControl fieldName='IsSecure' />
            </InputField>*/}
            <InputField title="Preference Parent?" value={itemData?.IsPreferenceRoot} onChange={handleOnChange} maxLength={100} groupId={'TAG'} fieldName='IsPreferenceRoot' validationName={'Tag_IsPreferenceRoot'} subTitle={`${disablePrefRoot ? 'Two preference roots have already been defined.' : 'Is this tag used in content preferences?'} `} info="By selecting this tag, a new section will be displayed in the Content Preferences. This allows for the administrators to add tags to be used within the Content Recommendation System. The description will be displayed as the option within the Content Preferences." >
                <CheckboxControl disabled={disablePrefRoot} fieldName='IsPreferenceRoot' />
            </InputField>

            <InputField title="Show In Content Library?" subTitle={'Allows the tag to be visible as part of the content library search filter.'} value={itemData?.ShowInContentLibrary ?? true} onChange={handleOnChange} maxLength={100} groupId={'TAG'} fieldName='ShowInContentLibrary'>
                <CheckboxControl fieldName='ShowInContentLibrary' />
            </InputField>
            <InputField title="Active?" value={itemData?.IsActive} onChange={handleOnChange} maxLength={100} groupId={'TAG'} fieldName='IsActive'>
                <CheckboxControl fieldName='IsActive' />
            </InputField>
        </div>}
        <div className='screen-footer right'>
            <div className='btn-wrapper-left screen-footer__btn'><ButtonControl disabled={saving} type={'cancel'} onClick={handleOnCancel}>Cancel</ButtonControl></div>
            <div className='btn-wrapper-right screen-footer__btn'><ButtonControl loading={saving} type={'okay'} onClick={handleOnSave}>Save</ButtonControl></div>

        </div>
    </div>
}

export default TagDetails;


