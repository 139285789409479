import React from 'react';

const SidebarFooter = () => {
  return (
    <>
      <div className="sidebar-navigation skMainMenuFooter">
      </div>
    </>
  );
};

export default SidebarFooter;
