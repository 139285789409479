import { ItemServiceFactory } from "../services/ItemServiceFactory";
import ItemManager from "./ItemManager";
import Manager from "./Manager";
import ErrorUtil from '../domains/contentLibrary/utils/ErrorUtil';
  
import _ from "lodash";

export default class RubricManager extends Manager {

    constructor(observationMgr, rubricState, globalAuthState, globalAppUserState) {
        super(globalAuthState, globalAppUserState);
        this._globalAuthState = globalAuthState;
        this._rubricState = rubricState;

        this._setAllRubrics = (value) => rubricState.merge({ allRubrics: value });
        this._setForceRubricUpdate = (value) => rubricState.merge({ forceRubricUpdate: value });
        this._setAvailableRubricFilters = (value) => rubricState.merge({ availableRubricFilters: value });
        this._setFilteredRubrics = (value, filter) => rubricState.merge({ filteredRubrics: value, rubricFilter: filter });
        this._setSelectedRubric = (value) => rubricState.merge({ selectedRubric: value });
        this._setRubricMetadata = (value) => rubricState.merge({ rubricMetadata: value })

        this._errorUtil = new ErrorUtil();

        this._rubricItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Rubric, globalAuthState, globalAppUserState);
        this._rubricDomainItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.RubricDomain, globalAuthState, globalAppUserState);
        this._rubricDomainMapItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.RubricDomainMap, globalAuthState, globalAppUserState);
        this._rubricRatingItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.RubricRating, globalAuthState, globalAppUserState);
        this._rubricIndicatorItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.RubricIndicator, globalAuthState, globalAppUserState);
        this._rubricIndicatorItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.RubricIndicator, globalAuthState, globalAppUserState);
        this._clientMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.Client, globalAuthState, globalAppUserState);
        this._observationMgr = observationMgr
        //Remove These...
        this._rubricIndicatorMapItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.RubricIndicatorMap, globalAuthState, globalAppUserState);
        this._rubricIndicatorRatingMapItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.RubricIndicatorRatingMap, globalAuthState, globalAppUserState);
        this._rubricRatingMapItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.RubricRatingMap, globalAuthState, globalAppUserState);
        this._schoolYearItemMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.SchoolYear, globalAuthState, globalAppUserState);
    }

    get _forceRubricUpdate() { return this._rubricState.get().forceRubricUpdate; }
    get _allRubrics() { return this._rubricState.get().allRubrics; }
    get _selectedRubric() { return this._rubricState.get().selectedRubric; }
    get _rubricFilter() { return this._rubricState.get({ noproxy: true }).rubricFilter; }
    get RubricMetadata() { return this._rubricState.get({ noproxy: true }).rubricMetadata; }

    get FilteredRubrics() { return this._rubricState?.get()?.filteredRubrics ?? []; }
    get AvaiLableRubricFilters() { return this._rubricState?.get({ noproxy: true })?.avaiLableRubricFilters; }

    get ItemManager() {
        return this._rubricItemMgr;
    }

    get DomainItemManager() {
        return this._rubricDomainItemMgr;
    }
    get RubricDomainMapItemMgr() {
        return this._rubricDomainMapItemMgr;
    }

    get RatingItemManager() {
        return this._rubricRatingItemMgr;
    }
    get IndicatorItemManager() {
        return this._rubricIndicatorItemMgr;
    }
    get IndicatorMapItemManager() {
        return this._rubricIndicatorMapItemMgr;
    }
    get IndicatorRatingMapItemMgr() {
        return this._rubricIndicatorRatingMapItemMgr;
    }
    get RatingMapItemMgr() {
        return this._rubricRatingMapItemMgr;
    }
    get SchoolYearItemMgr() {
        return this._schoolYearItemMgr;
    }
    get ClientManager() {
        return this._clientMgr;
    }

    async getFormattedRubric(id) {
        return await this.ItemManager.runOperation("RubricOnly", id).then(r => {
            if (r.Success) {
                return this._observationMgr.transformData({ Rubric: r.Items.first() })
            }
        })
    }

    async getCertificationRubrics() {
        const nextSchoolYear = this.AppUserState.currentSchoolYear + 1;
        const nowSchoolYear = this.AppUserState.currentSchoolYear;
        const result = await this.ItemManager.query("x => x.IsActive == true && x.IsDeleted == false && x.Status != 1 && x.SchoolYearIdentifier == nowSchoolYear || x.SchoolYearIdentifier == nextSchoolYear", { nowSchoolYear, nextSchoolYear }, false, ["RubricDomainMap", "SchoolYear"]);
        if (result.Success) {
            return result.Items;
        }
        else {
            return [];
        }
    }

    async SearchRubrics(rubricId = 0, ignoreSqlInclude = false) {
        var result = {};
        var clientResult = undefined;
        if (rubricId != 0) {
            result = await this.ItemManager.get(rubricId);
        }
        else {
            result = this.ItemManager.query("x => x.IsDeleted == false", null, false, ["SchoolYear"]);
        }
        var domainResult = this.DomainItemManager.query("x => x.IsDeleted == 0");
        var indicatorResult = this.IndicatorItemManager.query("x => x.IsDeleted == 0");
        var rubricRatingMapResult = this.RatingMapItemMgr.query("x => x.IsDeleted == 0");
        var rubricRatingResult = this.RatingItemManager.query("x => x.IsDeleted == 0");
        var schoolYearResult = this.SchoolYearItemMgr.query("x => x.IsDeleted == 0");
        var clientResult = this.ClientManager.query("x => x.IsDeleted == 0");

        [result, domainResult, indicatorResult, rubricRatingMapResult, rubricRatingResult, schoolYearResult, clientResult] = await Promise.all([result, domainResult, indicatorResult, rubricRatingMapResult, rubricRatingResult, schoolYearResult, clientResult]);
        this._setRubricMetadata({ Domains: domainResult.Items, Indicators: indicatorResult.Items, RatingsMap: rubricRatingMapResult.Items, Ratings: rubricRatingResult.Items, SchoolYears: schoolYearResult.Items, Clients: clientResult.Items });

        if (result.Success && domainResult.Success && indicatorResult.Success && rubricRatingResult.Success && rubricRatingMapResult.Success) {
            //Map the incoming data to their corresponding rubrics
            let rubricSet = [];
            [...result.Items].forEach(rubric => {
                rubric = this.mapRubric(rubric, domainResult.Items, indicatorResult.Items, rubricRatingMapResult.Items, clientResult);
                rubricSet.push(rubric);
            });
            if (rubricId != 0) {
                return rubricSet;
            }
            this._setAllRubrics(rubricSet);
            const filteredRubrics = await this._doFilterRubrics(this._rubricFilter, result.Items);
            return filteredRubrics;
        }
        else
            return [];
    }

    mapRubric(rubric, domainItems, indicatorItems, ratingItems, clientResult) {

        if (rubric.RubricDomainMap) {
            rubric.RubricDomainMap = rubric.RubricDomainMap.filter(x => x.IsDeleted !== true);
            rubric.RubricDomainMap.forEach(rubricDomain => {
                let matchingDomain = domainItems.find(d => d.Id === rubricDomain.RubricDomainId);
                if (matchingDomain) {
                    rubricDomain.RubricDomain = matchingDomain;
                }
            })
            rubric.RubricIndicatorMap = rubric.RubricIndicatorMap.filter(x => x.IsDeleted !== true);
            rubric.RubricIndicatorMap.forEach(rubricIndicator => {
                let matchingIndicator = indicatorItems.find(i => i.Id === rubricIndicator.RubricIndicatorId);
                if (matchingIndicator) {
                    rubricIndicator.RubricIndicator = matchingIndicator;
                }
            })
        }
        if (rubric.RubricRatingMap) {
            rubric.RubricRatingMap = rubric.RubricRatingMap.filter(x => x.IsDeleted !== true);
            rubric.RubricRatingMap.forEach(ratingMapItem => {
                let matchingRating = ratingItems.find(i => i.Id === ratingMapItem.Id);
                if (matchingRating) {
                    ratingMapItem.RubricRating = matchingRating.RubricRating;
                }
            })
        }
        if (clientResult) {
            let matchingClient = clientResult.Items.find(x => x.Id === rubric.ClientId);
            if (matchingClient) {
                rubric.CustomFields = matchingClient.DisplayName;
            }
        }
        return rubric;
    }

    async getSchoolYears() {
        var result = await this.SchoolYearItemMgr.list();

        if (result.Success) {
            return result.Items;
        }
        else {
            return result;
        }
    }

    async GetRubricRatings() {
        var result = await this.RatingItemManager.list();

        if (result.Success) {
            return result.Items;
        }
        else {
            return [];
        }
    }

    FilterRubrics(filter) {
        if (!filter)
            filter = this._rubricFilter;

        return this._doFilterRubrics(filter, this._allRubrics);
    }

    async _doFilterRubrics(filter, allRubrics) {
        const data = [...allRubrics];
        const filters = [schoolYearFilter, evalFilter, orgFilter, dateFilter, statusFilter, searchFilter]
        let filteredRubrics = filters.reduce((d, f) => d.filter(f), data);
        function searchFilter(item) {
            if (!filter.Search) {
                return true;
            }
            else if (item.Name.toLowerCase().startsWith(filter.Search.toLowerCase())) {
                return true;
            }
        }

        function evalFilter(item) {
            if (filter.EvaluationType.length === 0) {
                return true;
            }
            else if (filter.EvaluationType.includes(item.RubricType.toString())) {
                return true;
            }
        }

        function orgFilter(item) {
            if (filter.Organization.length === 0) {
                return true;
            }
            else if (filter.Organization.length > 0) {
                if (item.ClientId != null) {
                    if (filter.Organization.includes(item.ClientId)) {
                        return true;
                    }
                }
                else if (filter.Organization.includes(item.ClientId)) {
                    return true;
                }
            }
        }

        function schoolYearFilter(item) {
            if (filter.SchoolYear.length === 0) {
                return true;
            }
            else if (filter.SchoolYear.includes(item.SchoolYear.Id)) {
                return true;
            }
        }

        function dateFilter(item) {
            if (filter.Date === null) {
                return true;
            }
            else {
                let dateSub = item.UpdatedDate.substring(0, 10);
                if ((filter.Date === dateSub)) {
                    return true;
                }
            }
        }

        function statusFilter(item) {
            if (filter.Status.length === 0) {
                return true;
            }
            else if (filter.Status.includes(item.Status)) {
                return true;
            }
        }

        this._setFilteredRubrics(filteredRubrics, filter);
        return filteredRubrics;
    }


}