import React from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { SelectListControl } from '../../../../components/controls';
import { CheckTreePicker } from 'rsuite';
import UserOrgTreeControl from '../../../../components/controls/UserOrgTreeControl';
import Loading from '../../../../components/Loading';

export default function GeneralSurveyFilter({ generalSurveyMgr,  orgMgr, orgTree, onFilterChange }) {
    const onTreeClean = (id) => {
        handleOnChange(null, id);
    }

    const handleOnChange = (value, field,) => {
        if (onFilterChange)
            onFilterChange(field, value);
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        generalSurveyMgr.filterData({ search: null, assignedBy: null, assignedTo: null, status: 'UNK' });
    }
    // ASSIGNED TO, ASSIGEND BY, STATUS, 

    const onTreeCleanOrg = (event) => {
        handleOnChange([], "organizations");
    }

    const onTreeSelect = (vals, field) => {
        let newTreeIds = orgMgr?.findSelected(vals);
        if (handleOnChange)
            handleOnChange(newTreeIds, field);
    }

    const surveyStatus = generalSurveyMgr?.Filter?.status ?? 'UNK';
    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">General Surveys</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search'
                            value={generalSurveyMgr?.Filter?.search ?? ''}
                            fieldName='search'
                            disableError={true}
                            placeholder='Title'
                            onChange={handleOnChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        {orgTree ? <InputField
                            id='organization'
                            title='Organization'
                            value={generalSurveyMgr?.Filter?.organizations ?? []}
                            fieldName='organizations'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleOnChange}>
                            <UserOrgTreeControl
                                className={'filter-control-input'}
                                childrenKey="Children"
                                labelKey="Name"
                                valueKey="Id"
                                selectedValues={generalSurveyMgr?.Filter?.organizations}
                                data={orgTree}
                                menuClassName={'org-tree-menu'}
                                onClean={onTreeCleanOrg}
                                onSelect={(a, v) => onTreeSelect(v, 'organizations')}
                                placement="autoHorizontalStart"
                            />
                        </InputField> : <Loading />}
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='assignedBy'
                            title='Assigned By'
                            value={generalSurveyMgr?.Filter?.assignedBy ?? ''}
                            fieldName='assignedBy'
                            disableError={true}
                            placeholder='User'
                            onChange={handleOnChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='assignedTo'
                            title='Assigned To'
                            value={generalSurveyMgr?.Filter?.assignedTo ?? ''}
                            fieldName='assignedTo'
                            disableError={true}
                            placeholder='User'
                            onChange={handleOnChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField id='surveyType'
                            title='Status'
                            value={(surveyStatus != 0 && surveyStatus != 1 && surveyStatus != 'UNK') ? 2 : surveyStatus}
                            fieldName='status'
                            disableError={true}
                            onChange={handleOnChange}>
                            <SelectListControl textValuePairs={[{ Text: 'Not Started', Value: 0 }, { Text: 'In Progress', Value: 1 }, { Text: 'Submitted', Value: 2 }]} />
                        </InputField>
                    </div>

                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}