import { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { createContext } from "react"
import { useAppUserManager, useCertificationAdminManager, useCertificationRatingManager, useCertificationTeacherManager, useObservationManager, useOrgManager, useUserManager } from '../../../hooks/useManagers';

export const CertificationContext = createContext();


export const CertificationDataProvider = ({ children }) => {
    const [managerIsReady, setManagerIsReady] = useState(false)
    const certificationTeacherMgr = useCertificationTeacherManager();
    const certificationAdminMgr = useCertificationAdminManager();
    const certificationRatingMgr = useCertificationRatingManager();
    const observationMgr = useObservationManager();
    const orgMgr = useOrgManager();
    const userMgr = useUserManager();
    const appUserMgr = useAppUserManager()
    const userProfile = appUserMgr?.GetGlobalState()?.userProfile;

    useEffect(() => {
        if (certificationTeacherMgr && certificationRatingMgr && userMgr && certificationAdminMgr && orgMgr && userProfile && appUserMgr) {
            setManagerIsReady(true)
        }
    }, [certificationTeacherMgr, certificationRatingMgr, userMgr, certificationAdminMgr, orgMgr, userProfile, appUserMgr]);

    return (
        <CertificationContext.Provider value={{
            managerIsReady: managerIsReady,
            certificationTeacherMgr: certificationTeacherMgr,
            certificationRatingMgr: certificationRatingMgr,
            observationMgr: observationMgr,
            userMgr: userMgr,
            certificationAdminMgr: certificationAdminMgr,
            orgMgr: orgMgr,
            userProfile: userProfile,
            appUserMgr: appUserMgr
        }}>
            {children}
        </CertificationContext.Provider>
    );
};

export const useCertificationContext = () => useContext(CertificationContext);
