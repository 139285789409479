import { useHookstate } from '@hookstate/core';
import React, { useEffect } from 'react';
import slideStore from '../../../../stores/sliderStore';
import IconArrowDown from '../Icons/IconArrowDown'
import './ShowDetailsButton.scss'
//import { useScroll } from 'react-scroll-hooks';

const ShowDetailsButton = ({ onClick, rowKey }) => {

  const { containerRef } = useHookstate(slideStore).get();
  const elementRef = rowKey;
  const scrollSpeed = 50;
  //const { scrollToElement, scrollToY } = useScroll({ scrollSpeed, containerRef })

  const handleOnClick = (e) => {
    //scrollToElement(elementRef);
    if (onClick) {
      onClick(e);
    }
  }
  return <button onClick={handleOnClick} className="show-details-button">
    <span>
      <IconArrowDown />
    </span>
  </button>
}

export default ShowDetailsButton;