import React, { useState } from 'react';
import MainLayout from '../../../../components/layout/MainLayout';
import { useRubricManager } from '../../../../hooks/useManagers';
import '../../rubricList.scss';
import { useEffect } from 'react';
import { DialogControl } from '../../../../components/controls/DialogControl';
import '../../rubricDialog.scss';
import { InputField } from '../../../../components/controls/InputField'
import SelectListControl from '../../../../components/controls/SelectListControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const RubricIndicatorSettingsDialog = ({ dialogState, dialogClose, onSave, saveComplete, indicator, takenIndicators, takenIndicatorAbbreviations, domain, rubricData, indicatorData, onNavigate }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedIndicator, setSelectedIndicator] = useState();
    const [selectedIndicatorValue, setSelectedIndicatorValue] = useState();
    const [selectedIndicatorAbbrValue, setSelectedIndicatorAbbrValue] = useState('');
    const [indicatorTaken, setIndicatorTaken] = useState(false);
    const [abbreviationTaken, setAbbreviationTaken] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState();
    const [selectedDomainValue, setSelectedDomainValue] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('Indicator Settings');
    const [indicatorDataSet, setIndicatorDataSet] = useState();
    const [domainDataSet, setDomainDataSet] = useState();
    const [addNewInput, setAddNewInput] = useState(false);
    const [enableSave, setEnableSave] = useState(false);
    const [dataItem, setDataItem] = useState();
    const [loadingSave, setLoadingSave] = useState(false);
    const [isPublished, setIsPublished] = useState(false);

    useEffect(() => {
        setSelectedIndicatorAbbrValue('');
    }, [addNewInput]);

    useEffect(() => {
        if (dialogState) {
            setOpenDialog(dialogState);
            setSelectedDomain(domain.domainName);
            setSelectedIndicator(indicator);
            setSelectedIndicatorValue();

            //map
            if (rubricData && indicatorData) {

                let selectedInd = indicatorData.find(i => i.Id === indicator.label);
                if (selectedInd) {
                    setSelectedIndicator(selectedInd.Id);
                    setSelectedIndicatorAbbrValue(selectedInd.ShortName);

                    if (rubricData.Status == 2) { //Published
                        setIsPublished(true);
                    }
                }

                let orgIndicators = indicatorData.filter(i => Number(i.RatingCount) === rubricData.RubricRatingMap.length);

                orgIndicators = orgIndicators.map(i => {
                    return { label: i.Name, value: i.Id }
                });

                orgIndicators.sort((a, b) => {
                    var textA = a.label.toUpperCase();
                    var textB = b.label.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });

                orgIndicators.unshift({ label: 'Add New Indicator...', value: 1 });
                setIndicatorDataSet(orgIndicators); //DROPDOWN DATA for INDICATORS LOADED HERE

                if (rubricData.RubricDomainMap) {
                    if (rubricData.RubricDomainMap.length > 0) {
                        let domainSet = [];
                        rubricData.RubricDomainMap.forEach(domainMapItem => {
                            domainSet.push(domainMapItem);
                        });
                        mapDomainDataSet(domainSet);
                    }
                }

            }

            if (!selectedDomain || !selectedIndicator) {
                setEnableSave(false);
            }
        }
    }, [dialogState]);

    useEffect(() => {
        if (selectedDomain && selectedIndicator) {
            if (selectedIndicator.value) {
                setSelectedIndicatorValue(selectedIndicator.value);
            } else {
                if (selectedDomain !== 'no domain assigned') {
                    setEnableSave(true);
                }
            }
        }
        else {
            setEnableSave(false);
        }
    }, [selectedDomain, selectedIndicator]);
    useEffect(() => {
        if (indicator && domain) {
            setSelectedIndicatorValue(indicator.value);
            if ((indicator.value !== 'No indicator assigned' && indicator.value.toString().trim() !== '')) {
                setEnableSave(true);
            }
            if (domain !== 'No domain assigned') {
                setSelectedDomain(domain);
                setSelectedDomainValue(domain.Id);
            }
        }
        else {
            setEnableSave(false);
        }
    }, [indicator, domain]);

    useEffect(() => {
        if (saveComplete !== null && saveComplete != undefined) {
            if (saveComplete) {
                setOpenDialog(false);
                dialogClose();
            }
        }
    }, [saveComplete]);

    const mapDomainDataSet = (data) => {
        const domainSet = data.map(
            domain => ({ value: domain.Id, text: domain.RubricDomain.Name, domainId: domain.RubricDomainId })
        );
        domainSet.sort((a, b) => {
            var textA = a.Sequence
            var textB = b.Sequence
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        setDomainDataSet(domainSet);
    }
    const handleOnChange = (value, field) => {
        if (value === 1) { //add new selected
            setAddNewInput(true);
            setSelectedIndicatorValue();
            setSelectedIndicator();
        }
        else if (field === 'indicator-new') {
            setSelectedIndicatorValue(value);
            setSelectedIndicator(value);
            let newAbbr = value?.split(' ')?.reduce((r, cv) => { r += cv[0]?.toUpperCase() ?? ''; return r; }, '');
            setSelectedIndicatorAbbrValue(newAbbr ?? '');

            if (newAbbr) {
                if (indicatorData.find(x => x.ShortName?.toLowerCase() === newAbbr?.toLowerCase() && x.RatingCount == rubricData.RubricRatingMap.length)) {
                    setAbbreviationTaken(true);
                }
                else {
                    setAbbreviationTaken(false);
                }
            }
            else {
                setAbbreviationTaken(false);
            }

            if (value !== '') {
                if (indicatorDataSet.reduce((r, cv) => {
                    r.push(cv.label.toLowerCase());
                    return r;
                }, []).indexOf(value.toLowerCase()) > -1) {
                    setIndicatorTaken(true);
                } else {
                    setIndicatorTaken(false);
                }
            }
            else {
                setIndicatorTaken(false);
            }
        }
        else if (field === 'indicator') {
            setSelectedIndicator(value);
            setSelectedIndicatorValue(value);

            var indForAbbv = indicatorData.find(x => x.Id?.toLowerCase() === value?.toLowerCase() && x.RatingCount == rubricData.RubricRatingMap.length);
            setSelectedIndicatorAbbrValue(indForAbbv.ShortName);

            if (value !== '') {
                if (indicatorDataSet.reduce((r, cv) => {
                    r.push(cv.label.toLowerCase());
                    return r;
                }, []).indexOf(value.toLowerCase()) > -1) {
                    setIndicatorTaken(true);
                } else {
                    setIndicatorTaken(false);
                }
            }
            else {
                setIndicatorTaken(false);
            }
        }
        else if (field === 'indicator-abbr') {
            if (indicatorData.find(x => x.ShortName?.toLowerCase() === value?.toLowerCase() && x.RatingCount == rubricData.RubricRatingMap.length)) {
                setAbbreviationTaken(true);
            }
            else {
                setAbbreviationTaken(false);
            }

            setSelectedIndicatorAbbrValue(value);
        }
        else if (field === 'domain') {
            setSelectedDomain(value);
            setSelectedDomainValue(value);
            let domainFound = domainDataSet.filter(d => d.value === value);
            if (domainFound.length > 0)
                onNavigate(domainFound[0].value);
        }

        const updatedDataItem = { ...dataItem };
        updatedDataItem[field] = value;

        setDataItem(updatedDataItem);
    }
    const handleOnSave = async () => {
        var domainId = selectedDomain.Id ? selectedDomain.Id : selectedDomain;
        const rubricDomain = rubricData?.RubricDomainMap?.find(x => x.RubricDomainId === domainId)
        const rubricDomainId = rubricDomain ? rubricDomain?.RubricDomainId : domainId
        await onSave(selectedIndicator, rubricDomainId, selectedIndicatorAbbrValue);
        setAddNewInput(false);
        setEnableSave(false);
        setSelectedIndicatorAbbrValue('');

    }

    const handleOnCancel = () => {
        // setSelectedIndicator({ label: 'Add New Indicator...', value: 1 });
        setOpenDialog(false);
        dialogClose();
        setAddNewInput(false);
        setEnableSave(false);
        setSelectedIndicatorAbbrValue('');
    }

    const handleOnCancelAdd = () => {
        setAddNewInput(false);
        setSelectedIndicatorAbbrValue('');
    }
    return (<>
        <DialogControl openDialog={openDialog} onCancel={handleOnCancel} title={dialogTitle}
            enableSave={enableSave && !isPublished && (!addNewInput || (!abbreviationTaken && !indicatorTaken && selectedIndicatorAbbrValue.trim()?.length > 0))}
            value={selectedIndicatorValue} onSave={handleOnSave} customSave={true}>
            <div className='title-header'>
                <div className={'title'}>
                    <div>Indicator</div>
                </div>
            </div>

            {addNewInput ? (
                <>
                    <div>
                        <InputField hidePleaseSelect={true} fieldName="indicator-new" onChange={handleOnChange} value={selectedIndicatorValue} placeholder={!selectedIndicatorValue ? "Assign an Indicator" : null}>
                        </InputField>
                    </div>
                    {indicatorTaken && <div className={'abbr-error'}>Indicator already exists. Please enter another indicator name.</div>}
                    {/*<div className='x-icon'>
                    <FontAwesomeIcon icon={faXmark} onClick={handleOnCancelAdd}/>
                </div>*/}
                    <div className={'title'}>Indicator Abbreviation</div>
                    <div className={'new-abbr-input-wrapper'}>
                        <InputField fieldName="indicator-abbr" onChange={handleOnChange} value={selectedIndicatorAbbrValue} placeholder={selectedIndicatorAbbrValue ?? "Assign an indicator abbreviation"}>
                        </InputField>
                    </div>
                    {abbreviationTaken && <div className={'abbr-error'}>Abbreviation already exists. Please enter another indicator abbreviation.</div>}
                </>
            ) :
                (
                    <>
                        {isPublished ?
                            <div className={'new-abbr-input-wrapper'} style={{ paddingBottom: '1rem' }}>
                                {selectedIndicatorValue}
                            </div>
                            :
                            <>
                                <SelectListControl fieldName="indicator" title={'Indicator'} searchable={true} placeholder={!selectedIndicatorValue ? "Assign an Indicator" : null} hidePleaseSelect={true} data={indicatorDataSet}
                                    styles={{ width: '100%' }} onChange={(e) => { handleOnChange(e, 'indicator') }} value={selectedIndicatorValue} disabledOptions={takenIndicators} />
                                <div style={{ paddingBottom: '25px' }}></div>
                            </>
                        }
                        <div className={'title'}>Indicator Abbreviation</div>
                        <div className={'new-abbr-input-wrapper'} style={{ paddingBottom: '1rem' }}>
                            <InputField fieldName="indicator-abbr-lbl" type="label" value={selectedIndicatorAbbrValue} disableError={true}>
                            </InputField>
                        </div>
                    </>
                )
            }
            <div className='title-header'>
                <div className={'title'}>
                    <div>Domain</div>
                </div>
            </div>
            {isPublished ?
                <div className={'new-abbr-input-wrapper'} style={{ paddingBottom: '1rem' }}>
                    {selectedDomainValue}
                </div>
                :
                <InputField
                    fieldName="domain"
                    value={selectedDomainValue}
                    onChange={handleOnChange}
                >
                    <SelectListControl
                        hidePleaseSelect={true}
                        textValuePairs={domainDataSet?.map(x => {
                            return {
                                text: x.text,
                                value: x.domainId
                            }
                        })}
                        value={selectedDomainValue}
                    />
                </InputField>
            }

        </DialogControl>
    </>
    );
}

export default RubricIndicatorSettingsDialog;