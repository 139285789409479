import React, { useEffect, useState, useRef } from 'react';
import '../../admin.scss';
import { SelectPicker, CheckPicker, CheckTreePicker, Toggle } from 'rsuite';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _, { groupBy } from 'lodash';
import $ from 'jquery';
import { useAppUserManager, useContentManager } from '../../../../hooks/useManagers';
import { SelectListControl } from '../../../../components/controls';
import SystemTypes from '../../../../SystemTypes';
import './user-filter.scss';

export default function UserFilter({ orgs, orgMgr, userMgr, onFilterChange, onClearFilter, filter, isStaged, onArchivedToggleChange }) {
    const statusData = [
        { Id: 'true', Text: 'Yes' },
        { Id: 'false', Text: 'No' }
    ].map(item => ({ Text: item.Text, Value: item.Id }));

    const handleOnChange = (value, fieldName) => {
        if (onFilterChange)
            onFilterChange(fieldName, value, null);
    }

    const onTextChange = (value, fieldName) => {
        if (onFilterChange)
            onFilterChange(fieldName, value);
    }
    const onTreeCleanOrg = () => {
        handleOnChange([], "userInGroup");
    }
    const onTreeSelect = (vals, field) => {
        //I actually filter by the user. because those users will be associated with the org/dis/school :)
        let newTreeIds = orgMgr?.findSelected(vals);
        if (handleOnChange)
            handleOnChange(newTreeIds, field);
    }

    const handleClearFilter = async () => {
        localStorage.setItem("CurrentPage", 1);
        $(".rs-picker-toggle-clean").trigger("click");
        if (onClearFilter)
            await onClearFilter();
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">{isStaged ? 'Staged User Management' : 'User Management'}</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search'
                            value={filter?.Search ?? ''}
                            fieldName='Search'
                            disableError={true}
                            placeholder='Search Users'
                            onChange={((fieldname, value) => onTextChange(fieldname, value))}>
                        </InputField>
                    </div>
                    {(isStaged != true) && <div className="filterControl">
                        <InputField
                            id='EEPass'
                            title='EE PASS #'
                            value={filter?.EEPass ?? ''}
                            fieldName='EEPass'
                            disableError={true}
                            placeholder='Search EE PASS #'
                            onChange={((fieldname, value) => onTextChange(fieldname, value))}>
                        </InputField></div>}
                    <div className="filterControl">
                        <InputField
                            id='userInGroup'
                            title='Organization'
                            value={filter?.userInGroup ?? []}
                            fieldName='userInGroup'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleOnChange}>
                            <CheckTreePicker
                                height={320}
                                width={400}
                                className={'filter-control-input'}
                                childrenKey="Children"
                                labelKey="Name"
                                valueKey="Id"
                                data={orgs ?? []}
                                onClean={onTreeCleanOrg}
                                onSelect={(a, v) => onTreeSelect(v, 'userInGroup')}
                                placement="autoHorizontalStart"
                            />
                        </InputField>
                    </div>
                    {(isStaged != true) && <InputField
                        id='IsActive'
                        title='Active?'
                        value={!filter?.IsActive || filter?.IsActive == '' ? 'UNK' : filter?.IsActive}
                        fieldName='IsActive'
                        disableError={true}
                        onChange={((fieldname, value) => handleOnChange(fieldname, value))}>
                        <SelectListControl textValuePairs={statusData} />
                    </InputField>
                    }
                    <InputField
                        id='JobTitle'
                        title='Job Title'
                        value={filter?.JobTitle}
                        fieldName='JobTitle'
                        disableError={true}
                        onChange={((fieldname, value) => handleOnChange(fieldname, value))}>
                        <SelectListControl textValuePairs={_.map(_.filter(Object.entries(SystemTypes.UserPositionType), y => {
                            if (userMgr?.AppUserState?.userProfile?.UserPositionType !== SystemTypes.UserPositionType.Admin) {
                                return y[1] !== SystemTypes.UserPositionType.None && y[1] !== SystemTypes.UserPositionType.Individual;
                            }
                            else {
                                return y[1] !== SystemTypes.UserPositionType.None;
                            }
                        }), x => {
                            const userPostionAlternative = _.find(userMgr?.AppUserState?.observationConfig.UserPositionAlternativeMap, y => y.UserPosition === x[1] && y.Name)?.Name
                            return {
                                value: x[1],
                                text: userPostionAlternative ?? x[0],
                            }
                        })} />
                    </InputField>
                    {isStaged ? 
                        <>
                        <div className='archivedUsersTitle'>
                            Search Archived Users
                        </div>
                        <div>
                            <Toggle defaultChecked={false} onChange={((val) => onArchivedToggleChange(val))} />
                        </div>
                        </>
                    :<></>}
                    <div style={{ paddingTop: '1.3em' }} className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}