import _ from 'lodash';
import React from 'react';
import { useState, useEffect } from 'react';
import { faClose as completeIcon } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as AlertUrgent } from '../../domains/dashboard/screens/assets/images/AlertUrgent.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogControl } from '../controls/DialogControl';
import { useNotificationManager } from '../../hooks/useManagers';
import ReactQuill from 'react-quill';

const NotificationBanner = () => {
    const [openNotifDialog, setOpenNotifDialog] = useState(false)
    const [notification, setNotification] = useState(null)
    const notificationMgr = useNotificationManager();
    useEffect(() => {
        if (notificationMgr && notificationMgr.AppUserState && notificationMgr.AppUserState.urgentNotification) {
            if (!sessionStorage.getItem('notification')) {
                setNotification(notificationMgr.AppUserState.urgentNotification)
            }

        }
    }, [notificationMgr])
    return (
        <>
            <DialogControl
                openDialog={openNotifDialog}
                title={notification?.Title}
                disableXButton={true}
                onCancel={() => {
                    setOpenNotifDialog(false)
                }}
                okText={'Dismiss'}
                onOk={() => {
                    setOpenNotifDialog(false)
                    sessionStorage.setItem('notification', notification?.Id)
                    setNotification(null)
                }}
            >
                <ReactQuill
                    theme="bubble"
                    className="rq"
                    readOnly={true}
                    value={notification?.HtmlValue}
                />
            </DialogControl>
            {
                notification && <div className="notification-banner" onClick={() => setOpenNotifDialog(true)}>
                    <AlertUrgent className="icon-red" />
                    <div className="notification-banner-header">
                        URGENT: {notification?.Title}
                    </div>
                </div>
            }
        </>
    );
}

export default NotificationBanner;


