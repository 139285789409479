
//import FeedbackManager from '../../../managers/FeedbackManager';
import React, { useEffect, useState } from 'react';
//https://rsuitejs.com/components/check-tree-picker/
import '../../../styles/rsuite_override.css';
import { Select, FormControlLabel, MenuItem, TextField } from '@mui/material';
import { DialogControl } from '../../controls/DialogControl';
import { InputField } from '../../controls/InputField';

import { useFeedbackManager } from '../../../hooks/useManagers';
import SelectListControl from '../../controls/SelectListControl';
import _ from 'lodash';
export default function ContactUsDialog() {

  const feedbackMgr = useFeedbackManager();

  const [validationErrors, setValidationErrors] = useState([]);
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    if (feedbackMgr) {
      setFeedback(feedbackMgr.DefaultFeedback);
    }
  }, [feedbackMgr]);


  const handleFieldChanged = (val, name) => {
    setFeedback({ ...feedback, [name]: val });
  }

  const validateEmail = (email) => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }

  const handleSaveContactUs = async () => {
    let tempValidationErrors = [];
    if (feedback.Name == "") {
      tempValidationErrors.push("Please enter your name.");
    }
    if (feedback.Request == "") {
      tempValidationErrors.push("Please select a request.");
    }

    if (feedback.Message == "") {
      tempValidationErrors.push("Please provide details about your problem/question.");
    }

    if (feedback.Email == "") {
      tempValidationErrors.push("Please enter your email.");
    }
    else if (!validateEmail(feedback.Email)) {
      tempValidationErrors.push("Please enter a valid email.");
    }

    if (feedback.Institution == "") {
      tempValidationErrors.push("Please enter your institution.");
    }

    if (tempValidationErrors.length == 0) {
      setValidationErrors(tempValidationErrors);
      return feedbackMgr.save(feedback).then((r) => {
        if (r.Success) {
          feedbackMgr.SetShowContactUs(false);
          reset();
          return r;
        }
        else {
          setValidationErrors(r.MessageDetails ?? ['Unknown Error']);
          return { Success: false }
        }
      });
    }
    else {
      setValidationErrors(tempValidationErrors);
      return { Success: false }
    }
  }

  const reset = () => {
    setFeedback(feedbackMgr.DefaultFeedback);
  }

  const closeDialog = () => {
    reset();
    setValidationErrors([]);
    feedbackMgr.SetShowContactUs(false);
  }

  return (
    <DialogControl openDialog={feedbackMgr?.ShowContactUs} title={'Contact Us'} okText={'Send'} onOk={handleSaveContactUs} onCancel={closeDialog} subTitle={'Support Hours: 8:30am – 5:30pm CST'}>
      <div>
        {feedback &&
          <div className="py-0 modalBody">
            {validationErrors.length > 0 && <div style={{ color: "red" }}>
              {validationErrors.map((error) => (<>{error}<br /></>))}
            </div>}
            <InputField title={'Your Name'} fieldName="Name" placeholder="Full Name" onChange={handleFieldChanged} value={feedback.Name} />
            <InputField title={'Your Request'} subTitle={'Please select the appropriate option for your request.'} fieldName="Request" onChange={handleFieldChanged} value={!feedback.Request ? 'UNK' : feedback.Request}>
              <SelectListControl textValuePairs={_.sortBy([
                { value: "I have a question." },
                { value: "I wish to provide feedback." },
                { value: "I'm having a technical problem." },
                { value: "I have a problem with a user's account." },
                { value: "Purchasing access for my institution." },
                { value: "Analytics" },
                { value: "Resource Library" },
                { value: "Observations" },
                { value: "Online Certification" },
                { value: "Reports" },
                { value: "General Administration" }
              ], x => x.value)}>
              </SelectListControl>
            </InputField>
            <InputField type="textarea" maxLength={350} title={'Explain your problem/question in detail'} fieldName="Feedback" onChange={handleFieldChanged} value={feedback.Feedback} />
            <InputField title={'Email'} fieldName="Email" placeholder="Email Address" onChange={handleFieldChanged} value={feedback.Email} />
            <InputField title={'Your Institution'} fieldName="Institution" placeholder={'District and School'} onChange={handleFieldChanged} value={feedback.Institution} />
          </div>}
      </div>
    </DialogControl>
  );
}
