/*
    PLEASE READ:
    This class is less than desired but unfortunately was created because the reporting solution
    was originally developed not following the existing framework articulated by NIET.Core.Platform. The
    ItemServices class is auto generated so this class simply cannot go there... NIET.Core.Platform has no
    way of generating it. 

    Therefore, this class exists (for now).
    
    Bob's your uncle!
*/
import axios from 'axios';
import configData from '../config.json';
import { Exception } from 'node-sass';

export class ReportingService {

    constructor(globalAuthState) {
        this._urlBase = configData.REPORTS.SERVICE_URL;

        const authState = globalAuthState.get();
        this._globalAuthState = globalAuthState;
        if (authState?.isAuthenticated &&
            authState?.accessToken != null &&
            authState.idToken != null) {
            this._clientId = authState.idTokenClaims.clientId;
            this._isAuth = true;
        }
        else {
            this._isAuth = false;
        }

        this._url = this._urlBase;
        axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
    }

    _createAxiosInstance(isExport) {
        const authState = this._globalAuthState.get();
        const token = authState?.accessToken;

        let headers = {};
        if (token) {
            headers["Authorization"] = "Bearer " + token;
        }

        headers["export-type"] = isExport ?? "PDF"; //For now only PDF

        const instance = axios.create({
            timeout: this.timeout,
            headers: headers
        });
        return instance;
    }

    base64ToArrayBuffer(data) {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    exportPdf(b64bytes, reportName) {
        var bytes = this.base64ToArrayBuffer(b64bytes);
        var file = new Blob([bytes], { type: "application/pdf" });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = reportName ? `${reportName}.pdf` : "Report.pdf";
        link.id = "reportdownload";
        document.body.appendChild(link);
        link.click();
    }

    exportXlsx(b64bytes, reportName) {
        var bytes = this.base64ToArrayBuffer(b64bytes);
        var file = new Blob([bytes], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = reportName ? `${reportName}.xlsx` : "Report.xlsx";
        link.id = "reportdownload";
        document.body.appendChild(link);
        link.click();
    }


    async getExport(payload, fileType, reportName) {
        const axiosInstance = this._createAxiosInstance(fileType);
        let uri = `${this._url}export`;
        return axiosInstance.post(uri, payload ? payload : {})
            .then(r => {
                if (r.data.documentBytes && r.data.documentBytes.length > 0) {
                    if (fileType === 'XLSX') {
                        return this.exportXlsx(r.data.documentBytes, reportName)
                    } else {
                        return this.exportPdf(r.data.documentBytes, reportName)
                    }
                } else {
                    throw Exception("document bytes does not exist!")
                }
            })
            .catch(e => {
                console.log('error: ', e)
                return e;
            })
            .finally(() => {
                var elToRemove = document.getElementById("reportdownload");
                elToRemove?.remove();
            });
    }
}