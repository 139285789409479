import React, { useState, useRef } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';

//https://rsuitejs.com/components/check-tree-picker/
import { CheckTreePicker } from 'rsuite';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import $ from 'jquery';
import SelectListControl from '../../../../components/controls/SelectListControl';
import { useEffect } from 'react';
import { useAdminContentManager, useTagManager } from '../../../../hooks/useManagers';

export default function ContentLibraryFilter({ contentMgr, avaliableContentFilters, onFilterChange }) {

    const [contentTypesChecked, setContentTypeChecked] = useState({ learningModules: false, videos: false, resources: false });
    const learningModuleInput = useRef();
    var tagMgr = useTagManager();
    const resourceInput = useRef();
    const videosInput = useRef();
    const [tags, setTags] = useState([]);


    useEffect(() => {
        //   setTags(avaliableContentFilters?.tags);
        if (tagMgr) {
            tagMgr.FullTagTree().then(r => {
                if (r?.Success) {
                    // setDisabledTags(_.map(_.filter(r.Items, x => x.IsDisabled === true) ?? [], y => y.value));
                    setTags(r.Items);
                }
            });
        }
    }, [tagMgr])

    const handleSearchChange = (value) => {
        if (onFilterChange)
            onFilterChange("search", value);
    }

    const handleActiveChange = (value) => {
        if (onFilterChange)
            onFilterChange("isActive", value);
    }

    const handleCertChange = (value) => {
        if (onFilterChange)
            onFilterChange("useForCertification", value);
    }


    /*const handleCheckChange = (event, name) => {
        const updatedContentTypesChecked = { ...contentTypesChecked, [name]: event.target.checked };
        setContentTypeChecked(updatedContentTypesChecked);
        if (onFilterChange)
            onFilterChange("contentTypeIds", updatedContentTypesChecked);
    };*/


    const onTreeClean = (event) => {
        if (onFilterChange)
            onFilterChange("tagIds", []);
    }

    const onTreeSelect = (activeNode, vals) => {

        let tagTree = tags;
        let newTagIds = [];
        vals.forEach(val => {
            newTagIds.push(val);
            const tag = tagTree.find(t => t.TagId == val);
            if (tag && tag.ParentTagId == null && tag.children) {
                tag.children.forEach(c => {
                    newTagIds.push(c.TagId);
                });
            }
        });

        if (onFilterChange)
            onFilterChange("tagIds", newTagIds);

    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        $(".rs-picker-toggle-clean").trigger("click");
        setContentTypeChecked({ resources: false, videos: false, learningModules: false });
        contentMgr.FilterContent({ tagIds: [], search: null, contentTypeIds: [], isActive: undefined, useForCertification: undefined })
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Content Management</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search'
                            value={contentMgr?._contentFilter?.search ?? ''}
                            fieldName='Title'
                            disableError={true}
                            placeholder='Title'
                            onChange={handleSearchChange}>
                        </InputField>
                    </div>{/*
                    <div className="filterControl categoryFilterControl">
                        <div className="filterSubTitle">
                            Content Types
                        </div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="contentTypes"
                                    id="learningModules"
                                    inputRef={learningModuleInput}
                                    checked={contentTypesChecked.learningModules}
                                    onChange={(e) => handleCheckChange(e, 'learningModules')}
                                />
                            } label="Learning Modules">
                        </FormControlLabel>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="contentTypes"
                                    id="resources"
                                    inputRef={resourceInput}
                                    checked={contentTypesChecked.resources}
                                    onChange={(e) => handleCheckChange(e, 'resources')}
                                />
                            } label="Resources">
                        </FormControlLabel>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="contentTypes"
                                    id="videos"
                                    inputRef={videosInput}
                                    checked={contentTypesChecked.videos}
                                    onChange={(e) => handleCheckChange(e, 'videos')}
                                />
                            } label="Videos">
                        </FormControlLabel>
                        </div>*/}
                    <div className="filterControl">
                        {/*defaultExpandAll*/}
                        {tags && <InputField
                            id='tags'
                            title='Tags'
                            fieldName='TagIds'
                            className={'field-input__checkTreePicker'}
                            disableError={true}>
                            <CheckTreePicker
                                placeholder={'Select one or more...'}
                                height={320}
                                cascade={false}
                                data={tags}
                                defaultValue={contentMgr?._contentFilter?.tagIds}
                                onClean={onTreeClean}
                                onSelect={onTreeSelect}
                                placement="autoHorizontalStart"
                                className="searchWidth"
                            />
                        </InputField>}
                    </div>
                    <div className="filterControl">
                        {/*defaultExpandAll*/}
                        <InputField
                            id='useForCertification'
                            title='Certification?'
                            value={contentMgr?._contentFilter?.useForCertification === undefined ? 'UNK' : (contentMgr?._contentFilter?.useForCertification ? '1' : '0')}
                            fieldName='useForCertification'
                            onChange={handleCertChange}
                            disableError={true}>
                            <SelectListControl
                                hidePleaseSelect={true}
                                textValuePairs={
                                    [
                                        { text: 'Select', value: 'UNK' },
                                        { text: 'Yes', value: '1' },
                                        { text: 'No', value: '0' },
                                    ]} />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        {/*defaultExpandAll*/}
                        <InputField
                            id='isActive'
                            title='Active?'
                            value={contentMgr?._contentFilter?.isActive === undefined ? 'UNK' : (contentMgr?._contentFilter?.isActive ? '1' : '0')}
                            fieldName='IsActive'
                            onChange={handleActiveChange}
                            disableError={true}>
                            <SelectListControl
                                hidePleaseSelect={true}
                                textValuePairs={
                                    [
                                        { text: 'Select', value: 'UNK' },
                                        { text: 'Yes', value: '1' },
                                        { text: 'No', value: '0' },
                                    ]} />
                        </InputField>
                    </div>

                    <div className="filterControl btn-clear">
                        <ButtonControl onClick={handleClearFilter}
                        type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}