import React, { useState } from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { SelectListControl } from '../../../../components/controls';
import { useEffect } from 'react';

export default function OrgFilter({ orgMgr, onFilterChange }) {

    const handleFilterChange = (value, fieldName) => {
        if (fieldName === "IsActive" && value === "true") {
            value = true
        } else if (fieldName === "IsActive" && value === "false") {
            value = false
        }

        if (onFilterChange) {
            onFilterChange(fieldName, value)
        }
    }


    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        orgMgr.filterData({ ...orgMgr.Filter, search: null, IsActive: true });
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Organization Management</div>
                    <div className="filterControl">
                        <InputField
                            id='role'
                            title='Search'
                            value={orgMgr?.Filter?.search ?? ''}
                            fieldName='search'
                            disableError={true}
                            placeholder='Organization'
                            onChange={(value, fieldName) => handleFilterChange(value, fieldName)}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField id='IsActive'
                            title='Active?'
                            fieldName='IsActive'
                            value={orgMgr?.Filter?.IsActive ?? true}
                            disableError={true}
                            onChange={(value, fieldName) => handleFilterChange(value, fieldName)}>
                            <SelectListControl textValuePairs={[{ text: 'Yes', value: true }, { text: 'No', value: false }]} />
                        </InputField>
                    </div>
                    <div className="filterControl  btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}