import { useEffect, useState } from 'react';
import './details.scss';
import { InputField } from "../../../../components/controls/InputField";
import _ from 'lodash';
import { useValidationManager } from '../../../../hooks/useValidation';
import { DialogControl } from '../../../../components/controls/DialogControl';
import { CheckPicker, TreePicker } from 'rsuite';
import { CheckboxControl, DatePickerControl, SelectListControl } from '../../../../components/controls';
import TimePickerControl from '../../../../components/controls/TimePickerControl';
import { useNavigate } from 'react-router-dom';
import { useAppUserManager, useObservationManager, useOrgManager, useRubricManager, useSchoolManager, useUserManager } from '../../../../hooks/useManagers';
import moment from 'moment';
import Loading from '../../../../components/Loading';
import SystemTypes from '../../../../SystemTypes';
import { FormatUTCDate, FormatUTCDateM, FormatUTCDateTime, FormatUTCTimeM } from '../../../../utilities/DateFormater';
import ObservationManager from '../../../../managers/ObservationManager';
import { nl } from 'date-fns/locale';
import { FormattedDate } from 'rsuite/esm/CustomProvider';
import { format } from 'date-fns';

const ObservationDetailsDialog = ({ isTeacher, openDialog, observation, onCancel, onSave, onLoadingError, onLoading, onErrors, onValidationErrors, editMode }) => {

    const navigate = useNavigate();
    const observationMgr = useObservationManager();
    const rubricMgr = useRubricManager();
    const orgMgr = useOrgManager()
    const schoolMgr = useSchoolManager();
    const [observationItem, setObservationItem] = useState(null);
    const [organizations, setOrganizations] = useState(null);
    const [districts, setDistricts] = useState(null);
    const [schools, setSchools] = useState(null);
    const [observerUsers, setObserverUsers] = useState(null);
    const [observedUsers, setObservedUsers] = useState(null);
    const [gradeLevels, setGradeLevels] = useState(null);
    const [subjectsTaught, setSubjectsTaught] = useState(null);
    const [rubricList, setRubricList] = useState(null);
    const [loadingRubricList, setLoadingRubricList] = useState(false);
    const [loading, setLoading] = useState(false);
    const [observationConfig, setObservationConfig] = useState(null);
    const [saving, setSaving] = useState(false);
    const [phase, setPhase] = useState(0);
    const [missingDateInput, setMissingDateInput] = useState(null);
    const [missingtStartTimeInput, setMissingtStartTimeInput] = useState(null);
    const [forceValidate, setForceValidate] = useState(false);
    const [forceFieldValidate, setForceFieldValidate] = useState({});
    const validationPhaseOneMgr = useValidationManager(false, 'OBSERVATION-PHASE-ONE');
    const validationMgr = useValidationManager(false, isTeacher ? ['OBSERVATION-PHASE-ONE', 'OBSERVATION-PHASE-TWO', 'OBSERVATION-PHASE-TWO-TEACHER'] : ['OBSERVATION-PHASE-ONE', 'OBSERVATION-PHASE-TWO']);
    const appUserMgr = useAppUserManager();
    const [orgHeader, setOrgHeader] = useState({
        org: observation?.Organization?.Name ?? "",
        district: observation?.District?.Name ?? "",
        school: observation?.School?.Name ?? ""
    })

    useEffect(() => {
        if (observation && orgMgr && !observationItem) {
            if (observationItem?.Id !== observation?.Id) {
                orgMgr.clearRubricLists();

                filterRubricList(observationItem?.ObservationType ?? (isTeacher ? 2 : 3), observation);
                setObservationItem(observation);
                if (observation?.Id) {
                    setOrgHeader({
                        org: observation?.Organization?.Name ?? "",
                        district: observation?.District?.Name ?? "",
                        school: observation?.School?.Name ?? ""
                    });
                    setPhase(1);
                }
                else {

                    setPhase(0);
                }
            }
            else if (observation?.Id && observationItem?.Id) {
                setOrgHeader({
                    org: observation?.Organization?.Name ?? "",
                    district: observation?.District?.Name ?? "",
                    school: observation?.School?.Name ?? ""
                });
                setPhase(1);
            }
            else {
                setPhase(0);
            }

        }

        if (orgMgr && !orgMgr?.FullOrgTree) {
            orgMgr.loadOrgTree();
        }
        if (observation?.SchoolId && orgMgr) {
            orgMgr.loadOrgUserTree(observation.SchoolId, true)
        }
    }, [orgMgr, observation, observationConfig, openDialog]);

    useEffect(() => {
        if (observationMgr) {
            if (!subjectsTaught)
                observationMgr.getOptions("subject").then((subjects) => {
                    setSubjectsTaught(subjects);

                });

            if (!gradeLevels) {
                observationMgr.getOptions("gradelevels").then((gradelevels) => {
                    setGradeLevels(gradelevels);
                });
            }
        }
    }, [observationMgr, openDialog]);

    useEffect(() => {
        const fullOrgTree = orgMgr?.OrgsOnly()
        if (orgMgr?.FullOrgTree && organizations === null || organizations?.length !== fullOrgTree?.length) {
            setOrganizations(fullOrgTree);
        }
    }, [orgMgr?.FullOrgTree]);


    useEffect(() => {
        if (orgMgr?.FullOrgUserTree && (observedUsers === null || observationItem?.SchoolId !== orgMgr?.FullOrgUserTree?.first()?.SchoolId)) {
            if (observationItem?.SchoolId) {
                if (observationItem?.ObservationType === SystemTypes.ObservationType.SchoolLeaderObservation) {
                    setObservedUsers(orgMgr?.SchoolUsersOnly(observationItem?.SchoolId, SystemTypes.UserPositionType.SchoolAdmin, SystemTypes.UserPositionType.AssitantSchoolAdmin));
                }
                else {
                    setObservedUsers(orgMgr?.SchoolUsersOnly(observationItem?.SchoolId, SystemTypes.UserPositionType.MasterTeacher, SystemTypes.UserPositionType.Teacher));
                }
            }
        }

        if (orgMgr?.FullOrgUserTree && (observerUsers === null || observationItem?.ClientId !== orgMgr?.FullOrgUserTree?.first()?.OrgId ||
            observationItem?.DistrictId !== orgMgr?.FullOrgUserTree?.first()?.DistrictId ||
            observationItem?.SchoolId !== orgMgr?.FullOrgUserTree?.first()?.SchoolId)
        ) {

            if (observationItem?.ClientId && observationItem?.DistrictId && observationItem?.SchoolId) {
                if (observationItem?.ObservationType === SystemTypes.ObservationType.SchoolLeaderObservation) {
                    setObserverUsers(orgMgr?.LoadObserverOnly(observationItem?.ClientId, observationItem?.DistrictId, observationItem?.SchoolId, false));
                }
                else {
                    setObserverUsers(orgMgr?.LoadObserverOnly(observationItem?.ClientId, observationItem?.DistrictId, observationItem?.SchoolId, true));
                }

            }
        }

        if (!rubricList && observationConfig) {
            if (observationItem?.ClientId && observationItem?.DistrictId && observationItem?.SchoolId) {
                orgMgr.clearRubricLists();
                filterRubricList(observationItem?.ObservationType ?? 1);
            }
        }
    }, [orgMgr?.FullOrgUserTree, orgMgr?.RubricList, observationItem?.ClientId, observationItem?.DistrictId, observationItem?.SchoolId, observationConfig]);

    useEffect(() => {
        if (observationItem === null) {
            setObservationItem(observation);
        }
    }, [observation]);

    useEffect(() => {
        if (observationItem?.ClientId || (organizations && organizations?.length == 1)) {
            setDistricts(orgMgr?.DistrictsOnly(observationItem?.ClientId ?? organizations?.first()?.ClientId));
        }

        if (observationItem?.DistrictId || (districts && districts?.length == 1)) {
            setSchools(orgMgr?.SchoolsOnly(observationItem?.DistrictId ?? districts?.first()?.DistrictId));
        }
    }, [observationItem, organizations]);

    useEffect(() => {
        if (observerUsers && observationMgr && observationItem && observationItem.ObserverUser && appUserMgr) {
            const user = observerUsers.find(x => x.UserId == observationItem.ObserverUser);
            if (!user) {
                appUserMgr.getUser(observationItem.ObserverUser).then((userResult) => {
                    if (userResult && userResult.length > 0) {
                        setObserverUsers([
                            {
                                OrgId: userResult.OrganizationIds?.first(),
                                DistrictId: userResult.DistrictIds?.first(),
                                SchoolId: userResult.SchoolIds?.first(),
                                UserId: userResult.Id,
                                Name: userResult.FirstName + " " + userResult.LastName,
                                Email: userResult.Email,
                                FullName: userResult.FullName,
                                NodeType: 'User',
                                UserPositionType: userResult.UserPositionType,
                                IsCertifiedTeacher: true,
                                IsCertifiedSchoolAdmin: false,
                                Children: null,
                                _id: `u-${userResult.Id}`,
                                Id: `u-${userResult.Id}`,
                                UniqueId: `u-${userResult.Id}`,
                                ClientId: '62df3241-1066-4a26-b6a1-b9c95bd19ac7',
                                IsDeleted: false,
                                visible: true,
                                refKey: `String_${userResult.Id}`
                            }
                            , ...observerUsers])
                    }
                })
            }
        }
    }, [observerUsers, observationMgr, observationItem, appUserMgr])

    const handleOnCancel = async (forceExit, obs) => {
        let result = { Success: false };
        if (!editMode && forceExit && phase == 1) {
            if (onCancel) {
                setForceValidate(false);
                result = await onCancel(obs);
                setRubricList(null);
                setObservationItem(null);
                setDistricts(null);
                setSchools(null)
                setOrganizations(null);
                setObservedUsers(null);
                setObserverUsers(null);
                setForceFieldValidate({});
            }
        }
        else if (!editMode && phase === 1 && !forceExit) {
            setPhase(0);
        }
        else {
            setForceValidate(false);
            setObservationItem(null);
            setForceFieldValidate({});
            if (onCancel)
                result = onCancel(obs);
        }

        return result;
    }

    const handleOnEditSave = async () => {
        const validateResult = validationMgr.checkIsGroupValid(observationItem);
        if (validateResult?.length === 0) {
            setForceValidate(false);
            let result = null;
            if (onSave) {
                setSaving(true);
                const userObserver = observerUsers?.find(x => x.UserId == observationItem.ObserverUser);
                const userObserved = observedUsers?.find(x => x.UserId == observationItem.UserObserved);

                observationItem.UserPositionType = userObserved?.UserPositionType;
                observationItem.ObserverUserPositionType = userObserver?.UserPositionType;

                observationItem.SubjectsTaughtData = observationItem?.SubjectsTaught?.toString();
                observationItem.GradeLevels = _.orderBy(gradeLevels.filter(x => observationItem?.GradeLevels.includes(x.Value)), y => y.Sequence).map(z => z.Value);
                observationItem.GradeLevelsData = observationItem.GradeLevels.toString();
                result = await onSave(observationItem);
                if (result?.Success) {
                    setObservationItem(result.Items.first());
                }

                setSaving(false);
                if (onCancel) {
                    onCancel(result);
                }
            }
            return result;
        }
        else {
            setForceValidate(true);
        }
    }
    const handleOnSave = async (saveAndExit) => {
        //Removed Self Reflection option this logic is TBD

        observationItem.SelfReflectionType = 1;
        if (!observationItem?.OrganizationId && organizations?.length === 1) {
            observationItem.OrganizationId = organizations.first().ClientId;
            observationItem.ClientId = observationItem.OrganizationId;
        }
        if (!observationItem?.DistrictId && districts?.length === 1) {
            observationItem.DistrictId = districts.first().DistrictId;
        }
        if (!observationItem?.SchoolId && schools?.length === 1) {
            observationItem.SchoolId = schools.first().SchoolId;
        }

        if (observationItem.ObservationStatusType === 0 && phase === 1 && !saveAndExit) {
            observationItem.ObservationStatusType = 1;
        }
        if (!observationItem.RubricId && rubricList && rubricList.length > 0)
            observationItem.RubricId = rubricList.first().Id;
        if(observationItem.PlannedPostConferanceDate === 'Invalid date')
            observationItem.PlannedPostConferanceDate = null;

        const validateResult = (saveAndExit || phase === 0) ? validationPhaseOneMgr.checkIsGroupValid(observationItem) : validationMgr.checkIsGroupValid(observationItem);
        let validDateInputs = true;
        if(phase === 1) {
            //check date and time inputs that are required
            if(observationItem.PlannedObservationDateStart === null || observationItem.PlannedObservationDateStart === "Invalid date") {
                setMissingDateInput(true);
                setMissingtStartTimeInput(true);
                validDateInputs = false;
            }
        }
        if (validateResult?.length === 0 && validDateInputs) {
            if ((observationItem.RubricId && phase === 1) || phase == 0) {
                setForceValidate(false);
                let result = null;
                if (onSave) {
                    setSaving(true);
                    const userObserver = observerUsers?.find(x => x.UserId == observationItem.ObserverUser);
                    const userObserved = observedUsers?.find(x => x.UserId == observationItem.UserObserved);
                    observationItem.UserPositionType = userObserved?.UserPositionType;
                    observationItem.ObserverUserPositionType = userObserver?.UserPositionType;
                    observationItem.SchoolYearId = appUserMgr.GetGlobalState().schoolYearMap[appUserMgr.GetGlobalState().selectedSchoolYear].SchoolYearId;
                    observationItem.SchoolYearIdentifier = appUserMgr.GetGlobalState().selectedSchoolYear;
                    observationItem.SubjectsTaughtData = observationItem?.SubjectsTaught?.toString();
                    observationItem.GradeLevels = _.orderBy(gradeLevels.filter(x => observationItem?.GradeLevels.includes(x.Value)), y => y.Sequence).map(z => z.Value);
                    observationItem.GradeLevelsData = observationItem.GradeLevels.toString();
                    if (!observationItem.RubricId && rubricList?.length > 0) {
                        observationItem.RubricId = rubricList.first().Id;
                    }

                    let saveSuccess = true;
                    if (saveAndExit || phase === 1) {
                        if (!saveAndExit) {
                            observationItem.ActualObservationDateStart = new Date();
                        }
                        result = await onSave(observationItem);
                        if (result.Success) {
                            if (onCancel) {
                                onCancel();
                            }
                        }
                        else {

                            saveSuccess = false;
                        }
                    }
                    else {
                        result = await onSave(observationItem, true);

                        if (result?.Success) {

                            setOrgHeader({
                                org: result.Items.first()?.Organization?.Name ?? "",
                                district: result.Items.first()?.District?.Name ?? "",
                                school: result.Items.first()?.School?.Name ?? ""
                            });
                            setObservationItem(result.Items.first());
                        }
                        else {
                            saveSuccess = false;
                        }
                    }

                    setSaving(false);
                    if (saveSuccess) {
                        if (!saveAndExit && phase !== 1) {
                            setPhase(1);
                            result = undefined;
                        }
                        else if (!saveAndExit && phase === 1) {
                            if (result?.Success) {
                                handleOnCancel(true, result);
                                navigate(`/Observation/${isTeacher ? 'Teacher' : 'SchoolAdmin'}/${result?.Items?.first()?.Id}`);
                            }
                        }
                        else if (handleOnCancel) {
                            handleOnCancel(true, result);
                        }
                    }
                }

                return result;
            }
        }
        else {
            setForceValidate(true);
        }
    }

    const handleOnBlur = (value, field) => {
        let updatedObservation = { ...observationItem };
        if (field === "PlannedObservationDateStart") {
            let momentDate = moment(observationItem.PlannedObservationDateStart ?? moment());
            if(observationItem.PlannedObservationDateStart === "Invalid date") {
                momentDate = moment();
            }
            if (value.includes(':')) {
                momentDate = moment(`${momentDate.format('YYYY-MM-DD')} ${value}`);
            }
            else {
                momentDate = moment(`${value} ${momentDate.format('HH:mm')}`);
            }
            updatedObservation[field] = momentDate.utc().format();
            setObservationItem(updatedObservation);
        }
        else if (field === "PlannedPostConferanceDate") {
            const dateString = moment(`${value}`).format('YYYY-MM-DD');
            const momentDate = moment(`${dateString} ${observationItem.PlannedPostConferanceTime ? FormatUTCTimeM(observationItem.PlannedPostConferanceTime) : '07:30'}`);

            updatedObservation[field] = momentDate.utc().format();
            setObservationItem(updatedObservation);
        }
        else if (field === "PlannedPostConferanceTime") {
            let momentDate = moment(observationItem.PlannedPostConferanceDate ? FormatUTCDateM(observationItem.PlannedPostConferanceDate) : moment());
            if (value.includes(':')) {
                const dateString = `${momentDate.format('YYYY-MM-DD')} ${value}`;
                momentDate = moment(dateString);
            }
            else {
                momentDate = moment(`${value} ${momentDate.format('HH:mm')}`);
            }
            updatedObservation[field] = momentDate.utc().format();
            updatedObservation["PlannedPostConferanceDate"] = momentDate.utc().format();
            setObservationItem(updatedObservation);
        }

        const updateFFV = { ...forceFieldValidate };
        updateFFV[field] = value;
        setForceFieldValidate(updateFFV);
        setForceValidate(false);
    }

    const handleOnDatePickerChange= (value, field) => {
        var targetValue = value.target.value;
        console.log("Date Picker targetValue: " + targetValue);
        console.log("Current value of observation date field:" + observationItem.PlannedObservationDateStart);
        let updatedObservation = { ...observationItem };
        if (field.FieldName === "PlannedObservationDateStart") {
            let momentDate = moment(observationItem.PlannedObservationDateStart ?? moment());
            if(observationItem.PlannedObservationDateStar === "Invalid date") {
                momentDate = moment();
            }
            console.log("Date Picker formatted current momentDate value of observation: " + momentDate.utc().format());
            if(targetValue !== '') {
                if (targetValue.includes(':')) {
                    momentDate = moment(`${momentDate.format('YYYY-MM-DD')} ${targetValue}`);
                }
                else {
                    momentDate = moment(`${targetValue} ${momentDate.format('HH:mm')}`);
                }
            }      
            let formattedDate =  momentDate.utc().format();

            if(targetValue === ''){
                formattedDate = null;
            }

            console.log("moment date with more formatting after taking account of targetValue: " + momentDate.utc().format());
            console.log("Updating observation date, and therefore the value of the input of: " + momentDate.utc().format());
            updatedObservation[field.FieldName] = formattedDate;
            setObservationItem(updatedObservation);
            
        }
        else if (field.FieldName === "PlannedObservationTimeEnd") {
            let momentDate = moment(observationItem.PlannedObservationDateStart ?? moment());
            if (targetValue.includes(':')) {
                momentDate = moment(`${momentDate.format('YYYY-MM-DD')} ${targetValue}`);
            }
            updatedObservation.PlannedObservationTimeEnd = momentDate.utc().format();
            setObservationItem(updatedObservation);
        }
        else if (field.FieldName === "PlannedPostConferanceDate") {
            const dateString = moment(`${targetValue}`).format('YYYY-MM-DD');
            const momentDate = moment(`${dateString} ${observationItem.PlannedPostConferanceTime ? FormatUTCTimeM(observationItem.PlannedPostConferanceTime) : '07:30'}`);

            if (momentDate.isValid() && momentDate.year() > 1900) {
                updatedObservation[field.FieldName] = momentDate.utc().format();
                setObservationItem(updatedObservation);
            }
            else if(!momentDate.isValid()) {
                updatedObservation[field.FieldName] = momentDate.utc().format();
                setObservationItem(updatedObservation);
            }
        }
        else if (field.FieldName === "PlannedPostConferanceTime") {
            let momentDate = moment(observationItem.PlannedPostConferanceDate ? FormatUTCDateM(observationItem.PlannedPostConferanceDate) : moment());
            if (targetValue.includes(':')) {
                const dateString = `${momentDate.format('YYYY-MM-DD')} ${targetValue}`;
                momentDate = moment(dateString);
            }
            else {
                momentDate = moment(`${value} ${momentDate.format('HH:mm')}`);
            }
            updatedObservation[field.FieldName] = momentDate.utc().format();
            updatedObservation["PlannedPostConferanceDate"] = momentDate.utc().format();
            setObservationItem(updatedObservation);
        }

        const updateFFV = { ...forceFieldValidate };
        updateFFV[field.FieldName] = targetValue;
        setForceFieldValidate(updateFFV);
        setForceValidate(false);
        setMissingDateInput(false);
        setMissingtStartTimeInput(false);
    }

    const handleOnChange = (value, field) => {
        let updatedObservation = { ...observationItem };
        if (field === "PlannedObservationDateStart") {
            let momentDate = moment(observationItem.PlannedObservationDateStart ?? moment());
            if (value.includes(':')) {
                momentDate = moment(`${momentDate.format('YYYY-MM-DD')} ${value}`);
            }
            else {
                momentDate = moment(`${value} ${momentDate.format('HH:mm')}`);
            }
            updatedObservation[field] = momentDate.utc().format();
            setObservationItem(updatedObservation);
        }
        else if (field === "PlannedObservationTimeEnd") {
            let momentDate = moment(observationItem.PlannedObservationDateStart ?? moment());
            if (value.includes(':')) {
                momentDate = moment(`${momentDate.format('YYYY-MM-DD')} ${value}`);
            }
            updatedObservation.PlannedObservationTimeEnd = momentDate.utc().format();
            setObservationItem(updatedObservation);
        }
        else if (field === "PlannedPostConferanceDate") {
            const dateString = moment(`${value}`).format('YYYY-MM-DD');
            const momentDate = moment(`${dateString} ${observationItem.PlannedPostConferanceTime ? FormatUTCTimeM(observationItem.PlannedPostConferanceTime) : '07:30'}`);

            updatedObservation[field] = momentDate.utc().format();
            setObservationItem(updatedObservation);
        }
        else if (field === "PlannedPostConferanceTime") {
            let momentDate = moment(observationItem.PlannedPostConferanceDate ? FormatUTCDateM(observationItem.PlannedPostConferanceDate) : moment());
            if (value.includes(':')) {
                const dateString = `${momentDate.format('YYYY-MM-DD')} ${value}`;
                momentDate = moment(dateString);
            }
            else {
                momentDate = moment(`${value} ${momentDate.format('HH:mm')}`);
            }
            updatedObservation[field] = momentDate.utc().format();
            updatedObservation["PlannedPostConferanceDate"] = momentDate.utc().format();
            setObservationItem(updatedObservation);
        }
        else {

            if (updatedObservation[field] !== value) {
                updatedObservation[field] = value;
                setObservationItem(updatedObservation);
            }

        }

        if (field === "ObservationType") {
            filterRubricList(value);
        }

        const updateFFV = { ...forceFieldValidate };
        updateFFV[field] = value;
        setForceFieldValidate(updateFFV);
        setForceValidate(false);
    }

    const filterRubricList = async (observationType, observation) => {
        if (!observation) {
            observation = observationItem;
        }

        if (observationItem?.UserObserved) {
            setRubricList(null);
            const observed = await appUserMgr.getUserPositionTypeByUserId(observationItem.UserObserved);
            let userPostionRubricMap = null;
            if (observationType < 3)
                userPostionRubricMap = observationConfig?.UserPositionRubricMap.find(x => x.UserPosition == observed.UserPositionType);
            else {
                if (observed.UserPositionType == SystemTypes.UserPositionType.AssitantSchoolAdmin) {
                    userPostionRubricMap = observationConfig?.UserPositionRubricMap.find(x => x.UserPosition == SystemTypes.UserPositionType.SchoolAdmin);
                } else
                    userPostionRubricMap = observationConfig?.UserPositionRubricMap.find(x => x.UserPosition == observed.UserPositionType);
            }

            if (userPostionRubricMap) {
                const result = await rubricMgr.ItemManager.query('x => x.Id == rubricId', { rubricId: userPostionRubricMap.RubricId }, true);
                if (result.Success) {
                    const rubrics = result.Items;
                    if (rubrics && rubrics.length > 0) {
                        setRubricList(rubrics);
                        setObservationItem({ ...observationItem, RubricId: userPostionRubricMap.RubricId });
                        return;
                    }

                    setLoadingRubricList(false);
                }
            }
        }

        const list = await orgMgr.loadRubricList(observation?.ClientId, observation?.DistrictId, observation?.SchoolId, true);
        if (observationType == 1 || observationType == 2) {
            setRubricList(_.filter(list ?? [], x => x.RubricType == 1));
        }
        else if (observationType == 3) {
            setRubricList(_.filter(list ?? [], x => x.RubricType == 4));
        }
        else {
            setRubricList([]);
        }

        setLoadingRubricList(false);
    }

    /*   useEffect(() => {
           if (observationItem && rubricList && rubricList?.length > 0) {
               if (!observationItem.RubricId) {
                   setObservationItem({ ...observationItem, RubricId: rubricList?.first()?.Id });
               }
           }
       }, [rubricList])*/

    useEffect(() => {
        if (observationItem?.UserObserved) {
            filterRubricList(observationItem?.ObservationType ?? (isTeacher ? 2 : 3));
        }
    }, [observationItem?.UserObserved])


    useEffect(() => {
        if ((observationItem?.SchoolId || (schools && schools?.length === 1)) && !observationConfig) {

            setLoading(true);
            schoolMgr.getObservationConfigBySchool(observationItem.SchoolId ?? schools?.first()?.SchoolId, observationItem.DistrictId ?? districts?.first()?.DistrictId, observationItem.ClientId ?? organizations?.first()?.ClientId).then((r) => {
                setLoading(false);
                if (r.Success) {
                    let obsConfig = r.Items.first();
                    if (!obsConfig) {
                        onErrors(["No valid configuration found."]);
                    }
                    else {
                        setObservationConfig(obsConfig)
                    }
                }
            });
        }
    }, [observationItem?.SchoolId, schools]);


    const onTreeClean = (id) => {
        handleOnChange(null, id);
    }

    const [observationStatusTypes] = useState(Object.keys(SystemTypes.ObservationStatusType).map(ost => {
        return { text: ObservationManager.formatStatus(SystemTypes.ObservationStatusType[ost]), value: SystemTypes.ObservationStatusType[ost] }
    }))

    return <DialogControl title={`${editMode ? 'Edit' : 'Create'} Observation`} openDialog={openDialog}
        onCustomCenter={!editMode ? () => { return handleOnSave(true) } : null}
        customCenterText={!editMode ? 'Save & Exit' : null}
        loadingSave={loading}
        onOk={!editMode ? async () => {
            await orgMgr.loadOrgUserTree(observationItem.SchoolId, true)
            return handleOnSave(false)
        } : async () => {
            await orgMgr.loadOrgUserTree(observationItem.SchoolId, true)
            return handleOnEditSave()
        }}
        okText={editMode ? 'Save' : (phase === 0 ? 'Next' : 'Next')}
        cancelText={'Cancel'}
        disableOk={((!rubricList || rubricList?.length == 0) && phase > 0) || !observationConfig}
        excludeCancel={!editMode && phase !== 0}
        onCancel={() => handleOnCancel(true)}
        onPrevious={() => setPhase(0)}
        isPrevious={!editMode && phase == 1}>

        <div className='control-box-wrapper observation-details'>
            {observationItem && <>
                {(phase === 0 && !editMode) && <div className='details-phase'>
                    {organizations && <InputField
                        forceValidate={forceValidate || forceFieldValidate?.ClientId}
                        value={observationItem?.ClientId ?? (organizations.length === 1 ? organizations.first().ClientId : null)}
                        title="Organization"
                        fieldName="ClientId"
                        validationName={'observation_ClientId'}
                        groupId={'OBSERVATION-PHASE-ONE'}
                    >
                        <TreePicker
                            height={320}
                            width={400}
                            data={organizations ?? []}
                            valueKey={'OrgId'}
                            labelKey={'Name'}
                            onClean={() => onTreeClean("ClientId")}
                            onSelect={(a, val) => handleOnChange(val, 'ClientId')}
                            placement="autoVerticalStart"
                        />
                    </InputField>}
                    {districts ? <InputField
                        forceValidate={forceValidate || forceFieldValidate?.DistrictId}
                        value={observationItem?.DistrictId ?? (districts.length === 1 ? districts.first().DistrictId : null)}
                        title="District"
                        fieldName="DistrictId"
                        validationName={'observation_DistrictId'}
                        groupId={'OBSERVATION-PHASE-ONE'}
                    >
                        <TreePicker
                            height={320}
                            width={400}
                            data={districts ?? []}
                            valueKey={'DistrictId'}
                            labelKey={'Name'}
                            onClean={() => onTreeClean("DistrictId")}
                            onSelect={(a, val) => handleOnChange(val, 'DistrictId')}
                            placement="autoVerticalStart"
                        />
                    </InputField> : <InputField
                        forceValidate={forceValidate || forceFieldValidate?.DistrictId}
                        value={observationItem?.DistrictId}
                        title="District"
                        fieldName="DistrictId"
                        subTitle="Please select a organization..."
                        validationName={'observation_DistrictId'}
                        groupId={'OBSERVATION-PHASE-ONE'}
                    >
                        <TreePicker
                            height={320}
                            width={400}
                            disabled={true}
                            data={[]}
                            placement="autoVerticalStart"
                        />
                    </InputField>}
                    {schools ? <InputField
                        forceValidate={forceValidate || forceFieldValidate?.SchoolId}
                        value={observationItem?.SchoolId ?? (schools?.length === 1 ? schools?.first()?.SchoolId : null)}
                        title="School"
                        fieldName="SchoolId"
                        validationName={'observation_SchoolId'}
                        groupId={'OBSERVATION-PHASE-ONE'}
                    >
                        <TreePicker
                            height={320}
                            width={400}
                            valueKey={'SchoolId'}
                            labelKey={'Name'}
                            data={schools ?? []}
                            onClean={() => onTreeClean("SchoolId")}
                            onSelect={(a, val) => { handleOnChange(val, "SchoolId") }}
                            placement="autoVerticalStart"
                        />
                    </InputField> : <InputField
                        forceValidate={forceValidate || forceFieldValidate?.SchoolId}
                        value={observationItem?.SchoolId}
                        subTitle="Please select a district..."
                        title="School"
                        fieldName="SchoolId"
                        validationName={'observation_SchoolId'}
                        groupId={'OBSERVATION-PHASE-ONE'}
                    >
                        <TreePicker
                            height={320}
                            width={400}
                            disabled={true}
                            data={[]}
                            placement="autoVerticalStart"
                        />
                    </InputField>}
                    <div className='spacer-line'>
                    </div>
                </div>}
                {(phase === 1 || editMode) && <div className='details-phase'>
                    <div className='phase-two-header'>
                        <h4>{'Organization > District > School'}</h4>
                        <h5>{`${orgHeader.org} > ${orgHeader.district} > ${orgHeader.school}`}</h5>
                    </div>
                    {(editMode && appUserMgr?.AppUserState?.userProfile.UserPositionType == SystemTypes.UserPositionType.Admin) && <>

                        <InputField
                            value={observationItem?.ObservationStatusType ?? 0}
                            title="Update Observation Status"
                            fieldName="ObservationStatusType"
                            onChange={handleOnChange}
                        >
                            <SelectListControl hidePleaseSelect={true} textValuePairs={observationStatusTypes} />
                        </InputField>
                        <div className='spacer-line'>
                        </div>
                    </>}
                    <div>
                        <h4>Observer</h4>
                        {observerUsers && <InputField
                            forceValidate={forceValidate || forceFieldValidate?.ObserverUser}
                            value={observationItem?.ObserverUser === appUserMgr?.AppUserState?.userProfile?.Id && !observerUsers.find(x => x.UserId == appUserMgr?.AppUserState?.userProfile?.Id) ? null : (observationItem?.ObserverUser ?? (observerUsers.find(x => x.UserId == appUserMgr?.AppUserState?.userProfile?.Id) ? appUserMgr?.AppUserState?.userProfile?.Id : null))}
                            title={null}
                            fieldName="ObserverUser"
                            validationName={'observation_ObserverUser'}
                            groupId={'OBSERVATION-PHASE-TWO'}
                        >
                            <TreePicker
                                height={320}
                                width={400}
                                data={observerUsers ?? []}
                                valueKey='UserId'
                                labelKey='Name'
                                disabledItemValues={[observationItem?.UserObserved]}
                                onClean={() => onTreeClean("ObserverUser")}
                                onSelect={(a, val) => handleOnChange(val, "ObserverUser")}
                                placement="autoVerticalStart"
                            />
                        </InputField>}
                    </div>
                    <h4>Observed</h4>
                    {observedUsers && <InputField
                        forceValidate={forceValidate || forceFieldValidate?.UserObserved}
                        value={observationItem?.UserObserved}
                        title={null}
                        fieldName="UserObserved"
                        validationName={'observation_UserObserved'}
                        groupId={'OBSERVATION-PHASE-TWO'}
                    >
                        <TreePicker
                            height={320}
                            width={400}
                            data={observedUsers ?? []}
                            valueKey='UserId'
                            disabled={editMode}
                            disabledItemValues={[observationItem?.ObserverUser]}
                            labelKey='Name'
                            onClean={() => onTreeClean("UserObserved")}
                            onSelect={(a, val) => handleOnChange(val, "UserObserved")}
                            placement="autoVerticalEnd"
                        />
                    </InputField>}
                    <div>
                        <h4>Session Information</h4>
                        <div className={'column-field'}>
                            <div className={'three'}>
                                <InputField
                                    title={'Date Of Observation'}
                                    forceValidate={forceValidate}
                                    currentData={observationItem}
                                    value={FormatUTCDateM(observationItem?.PlannedObservationDateStart)}
                                    fieldName="PlannedObservationDateStart"
                                    validationName={'observation_PlannedObservationDateStart'}
                                    groupId={'OBSERVATION-PHASE-TWO'}
                                    disableError={true}
                                    onUpdate={handleOnBlur}>
                                    <DatePickerControl ignoreOnChange={true} onChange={handleOnDatePickerChange} />
                                </InputField>
                                <div className={'error-wrapper-custom'}>
                                    {missingDateInput &&
                                        <span className={'error'}>Please enter a start date</span>
                                    }
                                </div>
                            </div>
                            <div className={'three'}>
                                <InputField
                                    title={'Observation Start Time'}
                                    forceValidate={forceValidate || forceFieldValidate?.PlannedObservationDateStart}
                                    currentData={observationItem}
                                    value={FormatUTCTimeM(observationItem?.PlannedObservationDateStart)}
                                    fieldName="PlannedObservationDateStart"
                                    validationName={'observation_PlannedObservationDateStart'}
                                    groupId={'OBSERVATION-PHASE-TWO'}
                                    disableError={true}
                                    onChange={handleOnChange}>
                                    <TimePickerControl />
                                </InputField>
                                <div className={'error-wrapper-custom'}>
                                    {missingtStartTimeInput &&
                                        <span className={'error'}>Please enter a start time</span>
                                    }
                                </div>
                            </div>
                            <div className={'three'}>
                                <InputField
                                    forceValidate={forceValidate || forceFieldValidate?.PlannedObservationTimeEnd}
                                    currentData={observationItem}
                                    value={FormatUTCTimeM(observationItem?.PlannedObservationTimeEnd)}
                                    title={'Observation End Time'}
                                    fieldName="PlannedObservationTimeEnd"
                                    validationName={'observation_PlannedObservationTimeEnd'}
                                    groupId={'OBSERVATION-PHASE-TWO'}
                                    validateOnBlur={true}
                                    onChange={handleOnChange}>
                                    <TimePickerControl disabled={!observationItem?.PlannedObservationDateStart} />
                                </InputField>
                            </div>
                        </div>
                        <div className={'column-field'}>
                            <div className={'three'}>
                                <InputField
                                    forceValidate={forceValidate}
                                    value={observationItem?.ObservationType ?? (isTeacher ? 2 : 3)}
                                    title={'Observation Type'}
                                    fieldName="ObservationType"
                                    validationName={'observation_ObservationType'}
                                    groupId={'OBSERVATION-PHASE-TWO'}
                                    onChange={handleOnChange}>
                                    <SelectListControl hidePleaseSelect={true} textValuePairs={
                                        (isTeacher === true || (isTeacher === undefined && observationItem?.ObservationType < 3)) ? [{ Text: 'Unannounced Teacher Observation', Value: 1 }, { Text: 'Announced Teacher Observation', Value: 2 }] :
                                            [{ Text: 'School Leader Observation', Value: 3 }]
                                    } />
                                </InputField>
                            </div>
                            <div className={'three'}>
                                {(rubricList || loadingRubricList) ? <InputField
                                    forceValidate={forceValidate}
                                    value={observationItem?.RubricId ?? rubricList?.first()?.Id}
                                    title={'Observation Rubric'}
                                    fieldName="RubricId"
                                    isDisabled={editMode}
                                    onChange={handleOnChange}>
                                    <SelectListControl hidePleaseSelect={true} textValuePairs={_.map(rubricList ?? [], x => { return { Text: x.Name, Value: x.Id } })} />
                                </InputField> :
                                    <div className='center'>
                                        <Loading />
                                    </div>
                                }
                            </div>
                            <div className={'three'}>
                                <InputField
                                    forceValidate={forceValidate}
                                    value={observationItem?.ExculdeFromOverallScoring}
                                    title="Exclude from observation scoring?"
                                    fieldName="ExculdeFromOverallScoring"
                                    validationName={'observation_ExculdeFromOverallScoring'}
                                    groupId={'OBSERVATION-PHASE-TWO'}
                                    onChange={handleOnChange}
                                >
                                    <CheckboxControl />
                                </InputField>
                            </div>
                        </div>

                    </div>
                    <div>

                        <div className={'column-field'}>
                            <div className={'two'}>
                                {subjectsTaught && <InputField
                                    forceValidate={forceValidate || forceFieldValidate?.SubjectsTaught}
                                    value={observationItem?.SubjectsTaught ?? []}
                                    title={'Subject(s)'}
                                    fieldName="SubjectsTaught"
                                    validationName={isTeacher ? 'observation_SubjectsTaught' : ''}
                                    groupId={'OBSERVATION-PHASE-TWO-TEACHER'}
                                    onChange={handleOnChange}>
                                    <CheckPicker
                                        height={320}
                                        width={400}
                                        valueKey={'Value'}
                                        labelKey={'Name'}
                                        data={subjectsTaught ?? []}
                                        onClean={() => onTreeClean("SubjectsTaught")}
                                        onSelect={(a, val) => handleOnChange(a.length > 0 ? a : undefined, "SubjectsTaught")}
                                        placement="autoVerticalEnd"
                                    />
                                </InputField>}
                            </div>
                            <div className={'two'}>
                                {gradeLevels && <InputField
                                    forceValidate={forceValidate || forceFieldValidate?.GradeLevels}
                                    value={observationItem?.GradeLevels ?? []}
                                    title={'Grade Level(s)'}
                                    fieldName="GradeLevels"
                                    groupId={'OBSERVATION-PHASE-TWO-TEACHER'}
                                    validationName={isTeacher ? 'observation_gradelevels' : ''}
                                    onChange={handleOnChange}>
                                    <CheckPicker
                                        placeholder="Select"
                                        id="gradeLevels"
                                        width={400}
                                        valueKey={'Value'}
                                        labelKey={'Name'}
                                        data={gradeLevels ?? []}
                                        onClean={() => onTreeClean("GradeLevels")}
                                        onSelect={(a, val) => handleOnChange(a.length > 0 ? a : undefined, "GradeLevels")}
                                        placement="autoVerticalEnd"
                                    />
                                </InputField>}
                            </div>
                        </div>
                        <div className='spacer-line'>
                        </div>
                        <h4>Post-Conference Information</h4>
                        <div className={'column-field'}>
                            <div className={'two'}>
                                <InputField
                                    title={'Post-Conference Date'}
                                    fieldName="PlannedPostConferanceDate"
                                    currentData={observationItem}
                                    value={FormatUTCDateM(observationItem?.PlannedPostConferanceDate)}
                                    groupId={'OBSERVATION-PHASE-TWO'}
                                    onUpdate={handleOnBlur}>
                                    <DatePickerControl onChange={handleOnDatePickerChange} minDate={FormatUTCDateM(observationItem?.PlannedPostConferanceDate)} />
                                </InputField>
                            </div>
                            <div className={'two'}>
                                <InputField
                                    title={'Post-Conference Time'}
                                    fieldName="PlannedPostConferanceTime"
                                    forceValidate={forceValidate}
                                    currentData={observationItem}
                                    value={FormatUTCTimeM(observationItem?.PlannedPostConferanceDate)}
                                    groupId={'OBSERVATION-PHASE-TWO'}
                                    validationName={'observation_plannedpostconferancetime'}
                                    onChange={handleOnChange}>
                                    <TimePickerControl />
                                </InputField>
                            </div>
                        </div>
                    </div>

                </div>}
            </>
            }
        </div>
    </DialogControl>
}

export default ObservationDetailsDialog;


