import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import "../MyDashboard.scss";



const CertificationDashboard = ({ dashboard, handleOpenPrint }) => {
    const [loading, setLoading] = useState(true);
    const [certificationCurrent, setCertificationCurrent] = useState([]);
    const [certificationHistroy, setCertificationHistroy] = useState([]);
    const [canCertify, setCanCertify] = useState(false);
    const [certificationStatus, setCertificationStatus] = useState("");





    useEffect(() => {
        if (dashboard) {
            setCertificationCurrent(dashboard?.CertificationCurrent);
            setCertificationHistroy(dashboard?.CertificationHistory);
            setCanCertify(dashboard?.CanCertify);
            let completedSections = 0;
            dashboard?.CertificationCurrent?.forEach(cert => { if (cert.CompletedDate) completedSections++ });
            if (completedSections === 0)
                setCertificationStatus("Not Started");
            else if (completedSections === dashboard?.CertificationCurrent?.length)
                setCertificationStatus("Completed");
            else if (completedSections > 0 && completedSections < dashboard?.CertificationCurrent?.length)
                setCertificationStatus("In Progress");

            setLoading(false);
        }
    }, [dashboard]);


    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Certification Type',
            dataProp: "Description",
            width: '65%',
            canSort: true,
        },
        {
            header: 'Status',
            dataProp: 'Status',
            width: '35%',
            canSort: true,
        }
    ]);

    const [historyRows, setHistoryRows] = useState([]);
    const [historyColumns] = useState([
        {
            header: 'School Year',
            dataProp: 'SchoolYearName',
            width: '25%',
            canSort: true,
        },
        {
            header: 'Type',
            dataProp: 'CertificationTypeName',
            width: '65%',
            canSort: true,
        },
        {
            header: 'Status',
            dataProp: 'Status',
            width: '15%',
            canSort: true,
        }
    ]);

    useEffect(() => {
        let newRows = [];
        if (certificationCurrent) {
            _.each(certificationCurrent, (c) => {
                newRows.push({
                    ...c,
                    Type: c.CertificationType == 1 ? 'Administrator Evaluator Certification' : 'Teacher Evaluator Certification'
                });
            });
            setRows(newRows);
        }

        let newHistoryRows = [];
        if (certificationHistroy) {
            _.each(certificationHistroy, (c) => {
                newHistoryRows.push({
                    ...c,
                    SchoolYearName: c.SchoolYearName,
                    Status: c.Status,
                });
            });
            setHistoryRows(newHistoryRows);
        }
    }, [certificationCurrent, certificationHistroy]);

    const handleCheckDisableAction = (row, action) => {
        if (action.toLowerCase() == 'print') {
            return row.Status.includes('fail') || row.Status === 'Failed';
        } else {
            return false;
        }
    }



    const checkLoadingAction = (action) => {
        if (action === 'print') {
            return action
        }
    }

    return <>
        <div className="dashboard-section-wrapper">

            <div className="title">My Certifications</div>
            <div className="dashboard-table">
                <TableControl
                    columns={columns}
                    data={rows}
                    defaultNumRows={3}
                    hideActionColumn={true}
                    hideArrowSort={true}
                    useCustomCells={true}
                    useCustomHeader={true}
                    useCustomFooter={true}
                    customBorder={true}
                    noDataText="No Certifications Found."
                />
            </div>
        </div>
        <div className="dashboard-section-wrapper">

            <div className="title mt-3">My Certification History</div>
            <div className="dashboard-table mb-3">
                {/*Replace with print*/}
                <TableControl
                    columns={historyColumns}
                    data={historyRows}
                    defaultNumRows={3}
                    hideArrowSort={true}
                    useCustomCells={true}
                    useCustomHeader={true}
                    printing={true}
                    checkLoadingAction={checkLoadingAction}
                    useCustomFooter={true}
                    customBorder={true}
                    noDataText="No Certification History Found."
                    checkDisableAction={handleCheckDisableAction}
                    onPrint={async (row) => await handleOpenPrint(row)}
                />
            </div>
        </div>
    </>
}

export default CertificationDashboard;