import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams, createSearchParams } from 'react-router-dom';
import MainLayout from '../../../components/layout/MainLayout';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import { useOrgManager, useClusterManager, useSchoolManager, useAppUserManager, useImportExportManager } from '../../../hooks/useManagers';
import _ from 'lodash';
import { DialogControl } from '../../../components/controls/DialogControl';
import ButtonControl from '../../../components/controls/ButtonControl';
import ClusterTable from '../components/cluster/table';
import ClusterFilter from '../components/cluster/filter';
import ClusterDetails from '../components/cluster/details';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import SystemTypes from '../../../services/SystemTypes';

const ClusterMgmt = () => {
    const clusterMgr = useClusterManager();
    const orgMgr = useOrgManager();
    const schoolMgr = useSchoolManager();
    const [manageMode, setManageMode] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams({});
    const [schoolItem, setSchoolItem] = useState(null)
    const [org, setOrg] = useState(null)
    const params = useParams();
    const navigate = useNavigate();

    const appUserMgr = useAppUserManager();
    const importExportMgr = useImportExportManager();
    const [exportLoading, setExportLoading] = useState(false);

    useEffect(() => {
        if (params?.clusterId && clusterMgr && orgMgr) {
            setLoading(true);
            orgMgr.loadOrgTree(false).then(r => {
                setOrg(r)
            });
            if (!selectedItem) {
                if (params?.clusterId !== 'create') {
                    clusterMgr.get(params?.clusterId).then((r) => {
                        if (r.Success) {
                            setManageMode('view');
                            setSelectedItem(r.Items.first());
                            setLoading(false);
                        }
                    });
                } else {
                    setLoading(false)
                    setManageMode('view')
                }
            } else setLoading(false);

        }
        else if (clusterMgr && orgMgr) {
            setLoading(true);
       
            orgMgr.loadOrgTree(false, false).then(r => {
                setOrg(r);
            });


            if (searchParams.get('schoolid')) {
                const schoolId = searchParams.get('schoolid');
                clusterMgr.search("x => x.IsDeleted == 0 && SchoolId == schoolId",true, { schoolId }, false, ['Client', 'School', 'District']).then(fb => {
                    if (_.isEmpty(fb) && searchParams.get('schoolid')) {
                        schoolMgr?.get(searchParams.get('schoolid')).then(r => {
                            setSchoolItem(r.Items.first())
                            setLoading(false);
                        })
                    } else {
                        setLoading(false);
                    }
                    setManageMode('list');
                })
            } else clusterMgr.search("x => x.IsDeleted == false", false, undefined, false, ['Client', 'School', 'District']).then(fb => {
                setManageMode('list');
                setLoading(false);
            });


        }
    }, [clusterMgr, orgMgr, params]);

    const onAddNew = () => {
        setManageMode('view');

        if (searchParams.get('schoolid')) {
            const preSelectedOrgItem = {
                ClientId: clusterMgr?.FilteredData?.first()?.ClientId ?? schoolItem?.ClientId,
                DistrictId: clusterMgr?.FilteredData?.first()?.DistrictId ?? schoolItem?.DistrictId,
                SchoolId: searchParams.get('schoolid'),
            }
            setSelectedItem(preSelectedOrgItem)
            navigate({
                pathname: '/admin/cluster/create',
                search: `?${createSearchParams({ schoolid: searchParams.get('schoolid') })}`,
            });
        } else {
            navigate('/admin/cluster/create')
        }
    }

    const handleOnFilterChange = async (field, value) => {
        const dataFilter = clusterMgr.Filter;
        const updatedDataFilter = { ...dataFilter };
        updatedDataFilter[field] = value !== '' ? value : null;
        await clusterMgr.filterData(updatedDataFilter);
    }

    const handleOnSave = async (item, condition) => {
        const spreadItem = { ...item }
        spreadItem.UserClusters = null
        spreadItem.District = null
        spreadItem.School = null
        spreadItem.Organization = null
        var result = await clusterMgr.saveItem(spreadItem);
        if (result.Success && !condition) {
            setSelectedItem(null);
            navigate('/admin/cluster')
            setLoadingError(null);
            setManageMode('list');
        }
        return result;
    }

    const handleOnDelete = async () => {
        const item = { ...itemToDelete };
        clusterMgr.deleteItem(item).then(r => {
            if (!r?.Success) {
                setItemToDelete(item);
                setDeleteError(`Failed to delete '${item.Name}' ` + ` - ${r?.Message?.DisplayMessage ?? (r?.MessageDetails?.toString() ?? 'Unknown Error')}`)
            }
            else {
                setDeleteError(null);
            }
        });
        setItemToDelete(null);
    }

    const handleOnEdit = (item) => {
        item['UserClusters'] = JSON.parse(JSON.stringify(item.UserClusters))
        setSelectedItem(item);
        setManageMode('view');
        if (searchParams.get('schoolid')) {
            navigate({
                pathname: `/admin/cluster/${item.Id}`,
                search: `?${createSearchParams({ schoolid: searchParams.get('schoolid') })}`,
            });
        } else {
            navigate(`/admin/cluster/${item.Id}`);
        }
    }


    const handleOnCancel = () => {
        if (searchParams.get('schoolid')) {
            navigate({
                pathname: '/admin/cluster',
                search: `?${createSearchParams({ schoolid: searchParams.get('schoolid') })}`,
            });
        } else {
            navigate('/admin/cluster');
        }
        setManageMode('list');
        setLoadingError(null);
        setErrors(null);
        setSelectedItem(null)
    }

    const handleOnExport = async () => {
        const idList = clusterMgr.FilteredData.map(x => x.Id);
        setExportLoading(true);
        const result = await importExportMgr.ExportData(SystemTypes.ImportExportType.ExportCluster, idList);
        if (!result.Success) {
            setExportLoading(null);
            setErrors(["Failed to export Cluster, if this issue continues please contact NIET support."]);
        }
        else {
            importExportMgr.downloadFile(result.Items.first()).then(r => {
                setExportLoading(false);
            }).catch(e => {
                setExportLoading(false);
                setErrors(["Cluster export has been created and failed to download. Please check Import/Export Admin."]);
            });
        }
        return result;
    }


    return (<>
        <DialogControl openDialog={itemToDelete} title={'Confirm Delete'} subTitle={`Are you sure you want to delete '${itemToDelete?.Name}' ? `} onCancel={() => setItemToDelete(null)} onOk={handleOnDelete} okText={'Confirm'}>
            {deleteError && <div className='error'>
                {deleteError}
            </div>}
        </DialogControl>
        <MainLayout errors={errors} validationErrors={validationErrors} >
            {manageMode === 'view' &&
                <div className='screen-header'>
                    <h3>Cluster Management</h3>
                    {params?.clusterId === 'create' ? <h5>Add Cluster</h5> : <h5>Edit Cluster</h5>}
                </div>
            }
            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'list' && <>
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <ClusterFilter orgMgr={orgMgr} org={org} clusterMgr={clusterMgr} onFilterChange={handleOnFilterChange} hideFilter={searchParams.get('schoolid')} />
                            <div className={"breadcrumb-table-wrapper" + (searchParams.get('schoolid') ? "" : '-nodrill')}>
                                {
                                    searchParams.get('schoolid') && <div className="breadcrumb-wrapper">
                                        <div className="breadcrumb" onClick={() => navigate('/admin/organization')}>{clusterMgr?.FilteredData?.first()?.Organization?.Name ?? schoolItem?.Organization?.Name}</div>
                                        <FontAwesomeIcon
                                            icon={faAngleRight}
                                            className={"fa-angle-right"}
                                        />
                                        <div className="breadcrumb" onClick={() => navigate({
                                            pathname: '/admin/district',
                                            search: `?${createSearchParams({ orgid: clusterMgr?.FilteredData?.first()?.ClientId ?? schoolItem?.ClientId })}`,
                                        })}>{clusterMgr?.FilteredData?.first()?.District?.Name ?? schoolItem?.District?.Name}</div>
                                        <FontAwesomeIcon
                                            icon={faAngleRight}
                                            className={"fa-angle-right"}
                                        />
                                        <div className="breadcrumb" onClick={() => navigate({
                                            pathname: '/admin/school',
                                            search: `?${createSearchParams({ districtid: clusterMgr?.FilteredData?.first()?.DistrictId ?? schoolItem?.DistrictId })}`,
                                        })}>{clusterMgr?.FilteredData?.first()?.School?.Name ?? schoolItem?.Name}</div>
                                        <FontAwesomeIcon
                                            icon={faAngleRight}
                                            className={"fa-angle-right"}
                                        />
                                        <div className="breadcrumb">Clusters</div>
                                    </div>
                                }
                                <ClusterTable filteredItems={clusterMgr?.FilteredData ?? []} onEdit={handleOnEdit} onDelete={(item) => { setItemToDelete(item); }} isDrilledDown={searchParams.get('schoolid')} />
                            </div>
                        </div>
                        <div className='screen-footer list'>
                            {clusterMgr?.CanWrite && <><ButtonControl type={"create"} onClick={onAddNew}>Create cluster</ButtonControl>&nbsp;&nbsp;</>}
                            {clusterMgr?.CanView && appUserMgr?.canView(appUserMgr?.AppUserState?.permissions.ExportSchool) && <><ButtonControl loading={exportLoading} type={'cancel'}
                                onClick={handleOnExport}>
                                Export Clusters
                            </ButtonControl>&nbsp;&nbsp;</>}
                        </div>
                    </div>
                </>}
                {manageMode === 'view' &&
                    <ClusterDetails
                        preSelectedSchoolId={searchParams.get('schoolid')}
                        canWrite={clusterMgr.CanWrite} orgMgr={orgMgr} org={org} clusterMgr={clusterMgr} cluster={selectedItem} onCancel={handleOnCancel} onSave={handleOnSave} onLoading={setLoading} onErrors={setErrors} onValidationErrors={setValidationErrors} onLoadingError={setLoadingError} />
                }
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default ClusterMgmt;