import React, { useState } from 'react';
import MainLayout from '../../../components/layout/MainLayout';
import { useEffect } from 'react';
import { useTransactionManager } from '../../../hooks/useManagers';
import TransactionTable from './../components/transaction/TransactionTable';
import TransactionFilter from './../components/transaction/TransactionFilter';
import TransactionDetails from './../components/transaction/TransactionDetails';
import { DialogControl } from '../../../components/controls/DialogControl';
import { useNavigate, useParams } from 'react-router-dom';
import ScreenWrapper from '../../../components/layout/screenWrapper';
import Loading from '../../../components/Loading';
import { useLocation } from 'react-router-dom';
import '../userList.scss';
import '../transactionList.scss';

const TransactionMgmt = () => {
    const [data, setData] = useState([]);
    const [manageMode, setManageMode] = useState('list');
    const [loading, setLoading] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState();
    const [selectedTransactionDetail, setSelectedTransactionDetail] = useState();
    const [openActiveFlagDialog, setOpenActiveFlagDialog] = useState();
    const [selectedUserRow, setSelectedUserRow] = useState({ isActive: false, Email: 'default' });
    const [loadingError, setLoadingError] = useState();
    const [openDialog, setOpenDialog] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [onLoading, setOnLoading] = useState();
    const [subEndDate, setSubEndDate] = useState();
    const [openCancelDialog, setOpenCancelDialog] = useState();
    const params = useParams();
    const [transactionId, setTransactionId] = new useState(params?.transactionId === undefined ? null : params?.transactionId);
    const navigate = useNavigate();
    const transactionMgr = useTransactionManager();
    const location = useLocation();

    useEffect(() => {
        if (params?.transactionId) {
            setManageMode('edit');
            setTransactionId(params?.transactionId);
        }
        else {
            setManageMode('list');
            setTransactionId(undefined);
        }
    }, [params?.transactionId])

    useEffect(() => {
        if (transactionMgr) {
            let ignore = false;
            setOnLoading(true);

            async function fetchData() {
                setLoading(true);
                await transactionMgr.SearchTransactions().then(transactions => {
                    setData(transactions);
                    setLoading(false);
                    setOnLoading(false);
                });
            }

            fetchData();
            return () => { ignore = true }; //cleanup
        }
    }, [transactionMgr]);

    const handleOnFilterChange = async (field, value) => {

        const transactionFilter = transactionMgr._transactionFilter;
        const updatedTransactionFilter = { ...transactionFilter };    //load contents into this 

        if (field === 'Username') {
            updatedTransactionFilter.Search = value;
        }
        else if (field === 'TransactionType') {
            updatedTransactionFilter.TransactionType = value;
        }
        else if (field === 'Status') {
            updatedTransactionFilter.Status = value;
        }
        else if (field === 'Date') {
            updatedTransactionFilter.Date = value;
        }

        setData(await transactionMgr.FilterTransactions(updatedTransactionFilter));
    }

    const handleOnCancel = () => {
        setOpenActiveFlagDialog(false);
    }

    const handleOnEdit = (row) => {
        setManageMode('edit');
        setIsEdit(true);
        navigate(`/admin/Transaction/${row.Id}`);
    }

    const handleOnTransactionDetailsExit = () => {
        setManageMode('list');
        navigate('/admin/Transaction');
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
        setOpenCancelDialog(false);
    }

    const handleOnLink = (row) => {
        let scheduledInvoiceId = row?.ScheduledInvoiceId;
        window.open(`https://app.staxpayments.com/#/invoices?schedule_id=${scheduledInvoiceId}`, '_blank');
    }

    const handleOnCancelSubscription = async () => {
        console.log(selectedTransaction);
        const transactionId = { transactionid: selectedTransaction.Id };
        let result = await transactionMgr.ScheduledInvoieCancelItemMgr.runOperation('PaymentScheduledInvoiceCancel', undefined, transactionId, undefined, undefined, false);
        if (result.Success && result.Items) {
            let updatedData = [...data];
            let canceledSub = updatedData.find(x => x.Id === result.Items[0]?.Id);
            if (canceledSub) {
                canceledSub.Status = 2;
                setData(updatedData);
            }
            setOpenCancelDialog(false);
            handleOnTransactionDetailsExit();
        }
    }

    const openCancelSubscriptionDialog = (transaction) => {
        setSelectedTransaction(transaction);

        if (transaction.TransactionDetails?.length > 1) {
            const latestTransaction = transaction.TransactionDetails[transaction.TransactionDetails.length - 1];

            const date = new Date(latestTransaction.CreatedDate);
            const newDate = getFormatedDate(date); // Add one month to the date

            setSubEndDate(newDate);
        }
        else {
            const date = new Date(transaction.TransactionDetails?.CreatedDate);
            const newDate = getFormatedDate(date); // Add one month to the date

            setSubEndDate(newDate);
        }

        setOpenCancelDialog(true);
    }

    const handleOnLoadingError = async () => {

    }

    const getFormatedDate = (dateInput) => {

        let date = new Date(dateInput);
        var year = date.getFullYear();
        var month = (1 + date.getMonth());

        if (month === 12) {
            month = 1;
            year += 1;
        }
        else {
            month += 1;
        }

        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }

    return (<>
        <MainLayout>
            <DialogControl title={'Cancel Subscription?'} subTitle={'This subscription will be active until ' + subEndDate} openDialog={openCancelDialog}
                excludeCancel={true} onCancel={handleDialogClose} customYes={true} isYes={true} onYes={handleOnCancelSubscription} isNo={true} onNo={handleDialogClose} />

            <ScreenWrapper loading={loading} loadingError={loadingError} onReturn={handleOnCancel}>
                {manageMode === 'edit' &&
                    <div className='screen-header user-mgr-header'>
                        <h3 className='display-inline'>Transaction Management</h3>
                    </div>
                }
                {manageMode === 'list' ?
                    <>
                        <div className='control-box-wrapper'>
                            <div className='control-box-list transactions'>
                                <TransactionFilter onFilterChange={handleOnFilterChange} data={data} />
                                <TransactionTable filteredTransactions={data ?? []} loading={loading} onEdit={handleOnEdit} onLink={handleOnLink} />
                            </div>
                        </div>
                    </>
                    :
                    <TransactionDetails transactionMgr={transactionMgr} isEdit={isEdit} transactionId={transactionId} tableData={data} setTableData={updatedData => setData(updatedData)}
                        onCancel={handleOnTransactionDetailsExit} onCancelSubscription={openCancelSubscriptionDialog} onLoadingError={handleOnLoadingError} />
                }
                <div className='screen-footer'>
                </div>
            </ScreenWrapper>
        </MainLayout>
    </>
    );
}

export default TransactionMgmt;