import _ from "lodash";
import moment from "moment";
import react, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import rubricIcon from "../../../../content/icons/grading.svg"

const ContentLibraryTable = ({ updateContent, filteredContent, onView, onEdit, onDelete, onManageRubricAnswers }) => {
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            header: 'Title',
            dataProp: "Title",
            width: '20%',
            hideArrowSort: false,
            textwrap: true,
            canSort: true
        },
        {
            header: 'Content Type',
            dataProp: 'ContentType',
            width: '10%',
            canSort: true
        },
        {
            header: 'File Name',
            dataProp: 'DisplayFileName',
            width: '15%',
            canSort: true,
            textwrap: true,
        },
        {
            header: 'Tags',
            dataProp: 'HtmlTagList',
            datatype: "html",
            width: '20%',
            canSort: false
        },
        {
            header: 'Create Date',
            dataProp: 'CreatedOn',
            datatype: 'dateTime',
            width: '11%',
            canSort: true
        },
        {
            header: 'Certification?',
            dataProp: "UseForCertification",
            width: '5%',
            canSort: true,
            isBool: true
        },
        {
            header: 'Hidden?',
            dataProp: "HideFromLibrary",
            width: '5%',
            canSort: true,
            isBool: true
        },
        {
            header: 'Enabled?',
            dataProp: "IsEnabled",
            width: '5%',
            canSort: true,
            isBool: true
        }
    ]);

    useEffect(() => {
        let newRows = [];
        if (filteredContent) {
            _.each(filteredContent, (c) => {
                let HtmlTagList = "";
                _.each(c.TagIds, x => { HtmlTagList += '<div>' + x + '</div>' });

                newRows.push({
                    ...c,
                    HtmlTagList,
                    IsEnabled: c.IsEnabled ? 'Yes' : 'No',
                    HideFromLibrary: c.HideFromLibrary ? 'Yes' : 'No',
                    UseForCertification: c.UseForCertification ? 'Yes' : 'No'
                });
            });
            setRows(newRows);
        }
    }, [updateContent]);

    const handleCheckDisableAction = (row, action) => {
        if (action.toLowerCase() == 'custom1') {
            return row.UseForCertification === "No" && true
        }
        else {
            return false;
        }
    }


    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                permission='Content'
                checkDisableAction={handleCheckDisableAction}
                useIntegratedPaging={['10', '25', '50', '100']}
                onView={(row) => onView(row.KeyId)}
                onEdit={(row) => onEdit(row.KeyId)}
                onDelete={onDelete}
                defaultSortBy={'CreatedOn'}
                defaultSortDesc={false}
                onCustom1={{
                    action: (row) => <img title="Configure Rubric Answer Key" src={rubricIcon} onClick={() => {
                        if (!handleCheckDisableAction(row, 'custom1')) {
                            onManageRubricAnswers(row)
                        }
                    }} />
                }}
            />
        </div>
    </>
}

export default ContentLibraryTable;