export const getDefaultRequiredReportParameters = () => ({
  isReportTypeRequired: false,
  isOrganizationIdRequired: false,
  isDistrictIdRequired: false,
  isSchoolIdRequired: false,
  isClusterIdRequired: false,
  isTeacherUserIdsRequired: false,
  isSchoolYearRequired: false,
  isStartDateRequired: false,
  isEndDateRequired: false,
  isSummarizeAtLevelRequired: false,
  isIncludeInactiveUsersRequired: false,
  isIncludeNietAdministratorsRequired: false,
  isIncludeDirectorsRequired: false,
});

export const getDefaultMissingRequiredReportParameters = () => ({
  isReportTypeMissing: false,
  isOrganizationIdMissing: false,
  isDistrictIdMissing: false,
  isSchoolIdMissing: false,
  isClusterIdMissing: false,
  isTeacherUserIdsMissing: false,
  isSchoolYearMissing: false,
  isStartDateMissing: false,
  isEndDateMissing: false,
  isSummarizeAtLevelMissing: false,
  isIncludeInactiveUsersMissing: false,
  isIncludeNietAdministratorsMissing: false,
  isIncludeDirectorsMissing: false,
});

export const getMissingRequiredReportParameters = (
  reportParameters,
  requiredReportParameters,
  missingRequiredReportParameters
) => {

  if (
    reportParameters.reportType === undefined &&
    requiredReportParameters.isReportTypeRequired
  ) {
    missingRequiredReportParameters.isReportTypeMissing = true;
  }
  if (
    !reportParameters.organizationId &&
    requiredReportParameters.isOrganizationIdRequired
  ) {
    missingRequiredReportParameters.isOrganizationIdMissing = true;
  }
  if (
    !reportParameters.districtId &&
    requiredReportParameters.isDistrictIdRequired
  ) {
    missingRequiredReportParameters.isDistrictIdMissing = true;
  }
  if (
    !reportParameters.clusterId &&
    requiredReportParameters.isClusterIdRequired
  ) {
    missingRequiredReportParameters.isClusterIdMissing = true;
  }
  if (
    !reportParameters.teacherUserIds &&
    requiredReportParameters.isTeacherUserIdsRequired
  ) {
    missingRequiredReportParameters.isTeacherUserIdsMissing = true;
  }
  if (
    !reportParameters.schoolYear &&
    requiredReportParameters.isSchoolYearRequired
  ) {
    missingRequiredReportParameters.isSchoolYearMissing = true;
  }
  if (
    !reportParameters.startDate &&
    requiredReportParameters.isStartDateRequired
  ) {
    missingRequiredReportParameters.isStartDateMissing = true;
  }
  if (!reportParameters.endDate && requiredReportParameters.isEndDateRequired) {
    missingRequiredReportParameters.isEndDateMissing = true;
  }
  if (
    (reportParameters.isSummarizeAtLevel === null ||
      reportParameters.isSummarizeAtLevel === undefined) &&
    requiredReportParameters.isSummarizeAtLevelRequired
  ) {
    missingRequiredReportParameters.isSummarizeAtLevelMissing = true;
  }
  if (
    (reportParameters.includeInactiveUsers === null ||
      reportParameters.includeInactiveUsers === undefined) &&
    requiredReportParameters.isIncludeInactiveUsersRequired
  ) {
    missingRequiredReportParameters.isIncludeInactiveUsersMissing = true;
  }
  if (
    (reportParameters.includeNietAdministrators === null ||
      reportParameters.includeNietAdministrators === undefined) &&
    requiredReportParameters.isIncludeNietAdministratorsRequired
  ) {
    missingRequiredReportParameters.isIncludeNietAdministratorsMissing = true;
  }
  if (
    (reportParameters.includeDirectors === null ||
      reportParameters.includeDirectors === undefined) &&
    requiredReportParameters.isIncludeDirectorsRequired
  ) {
    missingRequiredReportParameters.isIncludeDirectorsMissing = true;
  }
  return missingRequiredReportParameters;
};
