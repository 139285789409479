import { React, useEffect, useState } from 'react';
import { Panel } from 'rsuite';
import { InputField } from '../../../../../components/controls/InputField';
import { LabelControl } from '../../../../../components/controls';
import './../styles/adminObservationDomainWeights.scss';
import _ from 'lodash';

const AdminObservationDomainWeights = ({ data, setData }) => {
    const [adminObservationDomainWeightData, setAdminObservationDomainWeightData] = useState();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        if (data) {
            let sortedData = sortObserverDomainData(data);
            setAdminObservationDomainWeightData(sortedData);
        }
    }, [data]);

    const sortObserverDomainData = (dataSet) => {

        //sort the array by "Rubric Domain Name" alphabetically
        const sortedData = dataSet.sort((a, b) => {
            const nameA = a.RubricDomainName?.toLowerCase();
            const nameB = b.RubricDomainName?.toLowerCase();
            if (nameA < nameB)
                return -1;
            if (nameA > nameB)
                return 1;
            return 0;
        });

        const groupedData = sortedData.reduce((groups, item) => {
            const domainName = item.RubricDomainName;
            if (!groups[domainName]) {
                groups[domainName] = [];
            }
            groups[domainName].push(item);
            return groups;
        }, {});

        return groupedData;
    }

    const handleWeightChange = (domain, value) => {
        const updatedObservationDomainWeightData = [...data];
        const domainEntry = updatedObservationDomainWeightData.find(entry => entry.RubricDomainName === domain);

        if (value === '' || value === null || value === undefined) {
            value = 0;
        }

        if (domainEntry) {
            domainEntry.Weight = value;

            setData(updatedObservationDomainWeightData);
        }
    }
    return (
        <>
            <Panel header="Observer Domain Weights" bordered>
                <div className='observation-domain-weights-table'>
                    {adminObservationDomainWeightData ? (
                        <table className="observation-domain-weights-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>%</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(adminObservationDomainWeightData).map((domainObject) => (
                                    <tr key={domainObject[0].RubricDomainName}>
                                        <td className='row-header'>{domainObject[0].RubricDomainName}</td>
                                        <td className='input-area' key={domainObject[0].RubricDomainName} >
                                            <InputField
                                                value={domainObject[0].Weight}
                                                placeholder={'0'}
                                                onChange={(e) => handleWeightChange(domainObject[0].RubricDomainName, e)}
                                                type="text"
                                                disableError={true} />
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <th></th>
                                    <th className='align-center'>Total: {_.sumBy(_.flatten(Object.values(adminObservationDomainWeightData)), x => parseInt(x.Weight))}</th>
                                </tr>
                            </tbody>
                        </table>
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
            </Panel>
        </>
    );
}

export default AdminObservationDomainWeights;