import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas, faImage, faList } from '@fortawesome/free-solid-svg-icons'

import { FormControlLabel, Grid, Checkbox, Switch, Button, TextField } from '@mui/material';

import { InputField } from '../../../../../components/controls/InputField';
import ButtonControl from '../../../../../components/controls/ButtonControl';
import { ContentTypeEnum } from '../../../services/ContentService';
//https://rsuitejs.com/components/check-tree-picker/
import { CheckTreePicker } from 'rsuite';
import '../../../../../styles/rsuite_override.css';
import _ from 'lodash';
import $ from 'jquery';
import { useAppUserManager, useContentManager } from '../../../../../hooks/useManagers';
import Loading from '../../../../../components/Loading';

export default function Filter({ defaultContentTypeId, defaultTags, avaliableContentFilters, contentFilter, onFilterChange, onListView, fullTreeTags }) {
    const authMgr = useAppUserManager();
    const contentMgr = useContentManager();
    const { permissions } = authMgr?.GetGlobalState() ?? {};
    const [search, setSearch] = useState(contentFilter?.search);
    const [tagIds, setTagIds] = useState(defaultTags);
    const [tags, setTags] = useState([]);

    const [contentTypesChecked, setContentTypeChecked] = useState({ learningModules: false, videos: false, resources: false });

    const handleSearchChange = (value) => {
        setSearch(value);
        if (onFilterChange)
            onFilterChange("search", value);
    }

    const handleListViewChange = (e) => {
        if (onListView) {
            onListView(e.target.checked);
            onFilterChange('listView', e.target.checked);
        }
    }

    const handleCheckChange = (event, name) => {
        const updatedContentTypesChecked = { ...contentTypesChecked, [name]: event.target.checked };
        setContentTypeChecked(updatedContentTypesChecked);
        if (onFilterChange)
            onFilterChange("contentTypeIds", updatedContentTypesChecked);
    };


    const onTreeClean = (event) => {
        setTagIds([]);
        if (onFilterChange)
            onFilterChange("tagIds", []);
    }

    const onTreeSelect = (activeNode, vals) => {
        let tagTree = avaliableContentFilters.tags;
        let newTagIds = [];
        vals.forEach(val => {
            newTagIds.push(val);
            /*     const tag = tagTree.find(t => t.TagId == val);
                 if (tag && tag.ParentTagId == null && tag.children) {
                     tag.children.forEach(c => {
                         newTagIds.push(c.TagId);
                     });
                 }*/
        });
        setTagIds(newTagIds);
        if (onFilterChange)
            onFilterChange("tagIds", newTagIds);

    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        $(".rs-picker-toggle-clean").trigger("click");
        setTagIds([]);
        setSearch(null);
        const contentTypes = { resources: false, videos: false, learningModules: false };
        if (defaultContentTypeId === undefined) {
            setContentTypeChecked(contentTypes);
        }
        else {
            if (defaultContentTypeId === ContentTypeEnum.LearningModule) {
                contentTypes.learningModules = true;
            }
            else if (defaultContentTypeId === ContentTypeEnum.Video) {
                contentTypes.videos = true;
            }
            else if (defaultContentTypeId === ContentTypeEnum.Resource) {
                contentTypes.resources = true;
            }
            setContentTypeChecked(contentTypes);
        }
        contentMgr.FilterContent({ tagIds: [], search: null, contentTypeIds: defaultContentTypeId ? [defaultContentTypeId] : [], listView: contentFilter.listView });
    }

    useEffect(() => {
        if (contentFilter !== null) {
            setSearch(contentFilter.search);
            setTagIds(contentFilter.tagIds);
            setContentTypeChecked({
                learningModules: contentFilter.contentTypeIds.includes(ContentTypeEnum.LearningModule),
                videos: contentFilter.contentTypeIds.includes(ContentTypeEnum.Video),
                resources: contentFilter.contentTypeIds.includes(ContentTypeEnum.Resource)
            });
        }
    }, [contentFilter]);

    useEffect(() => {
        if (fullTreeTags && avaliableContentFilters) {
            let tagSet = [];
            fullTreeTags.forEach(t => {
                if (avaliableContentFilters?.tags?.some(tag => tag.label === t.label)) {
                    tagSet.push(t);
                }
            }); 
            setTags(tagSet); 
        }
    }, [fullTreeTags, avaliableContentFilters]);

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Content Library</div>
                    <div className="filterControl">
                        <InputField
                            id='search'
                            title='Search Content'
                            field={{ FieldName: 'contentSearch' }}
                            disableError={true}
                            placeholder='What are you looking for?'
                            value={search ?? ''}
                            onChange={handleSearchChange}>
                        </InputField>
                    </div>
                    {/*}
                    <div className="filterControl categoryFilterControl">
                        <div className="filterSubTitle">
                            Content Types
                        </div>
                        {(!defaultContentTypeId || defaultContentTypeId === ContentTypeEnum.LearningModule) &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="contentTypes"
                                        id="learningModules"
                                        inputRef={learningModuleInput}
                                        checked={contentTypesChecked.learningModules || defaultContentTypeId === ContentTypeEnum.LearningModule}
                                        disabled={defaultContentTypeId == ContentTypeEnum.LearningModule}
                                        onChange={(e) => handleCheckChange(e, 'learningModules')}
                                    />
                                } label="Learning Modules">
                            </FormControlLabel>}
                        {(!defaultContentTypeId || defaultContentTypeId === ContentTypeEnum.Resource) &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="contentTypes"
                                        id="resources"
                                        inputRef={resourceInput}
                                        checked={contentTypesChecked.resources || defaultContentTypeId === ContentTypeEnum.Resource}
                                        disabled={defaultContentTypeId == ContentTypeEnum.Resource}
                                        onChange={(e) => handleCheckChange(e, 'resources')}
                                    />
                                } label="Resources">
                            </FormControlLabel>}
                        {(!defaultContentTypeId || defaultContentTypeId === ContentTypeEnum.Video) &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="contentTypes"
                                        id="videos"
                                        inputRef={videosInput}
                                        checked={contentTypesChecked.videos || defaultContentTypeId === ContentTypeEnum.Video}
                                        onChange={(e) => handleCheckChange(e, 'videos')}
                                    />
                                } label="Videos">
                            </FormControlLabel>}
                            </div>*/}
                    <div className="filterControl">
                        {/*defaultExpandAll*/}
                        <InputField
                            id='tags'
                            title='Categories'
                            field={{ FieldName: 'searchTags' }}
                            className={'field-input__checkTreePicker'}
                            disableError={true}>
                            {tagIds && tags &&
                                <CheckTreePicker
                                    height={320}
                                    data={tags ?? []}
                                    defaultValue={tagIds}
                                    placeholder={'Select one or more...'}
                                    cascade={false}
                                    onClean={onTreeClean}
                                    onSelect={onTreeSelect}
                                    placement="autoHorizontalStart"
                                    className="searchWidth"
                                />
                            }
                        </InputField>
                    </div>
                    <div className="filterControl btn-clear">
                        <ButtonControl
                        type="cancel"
                            onClick={handleClearFilter}>
                            Clear
                        </ButtonControl>
                    </div>
                    <div className="filterTitle pt-5">Layout</div>
                    <div className="filterControl layoutSwitch">
                        <div className="contentIcons mr-2">
                            <FontAwesomeIcon icon={faImage} className="switchIcon upperCase" />
                            Image Mode
                        </div>
                        <FormControlLabel
                            control={<Switch onChange={handleListViewChange} />}
                            className="MuiSwitch-root switch-medium"
                        />
                        <div className="contentIcons ml-2">
                            <FontAwesomeIcon icon={faList} className="switchIcon upperCase" />
                            List Mode
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}//  onChange={handleListViewChange} 