import React, { useState } from 'react';
import { InputField } from '../../../../components/controls/InputField';
import ButtonControl from '../../../../components/controls/ButtonControl';
import '../../../../styles/rsuite_override.css';
import _ from 'lodash';
import { SelectListControl } from '../../../../components/controls';
import { CheckTreePicker, TreePicker } from 'rsuite';

export default function CertificationTeacherFilter({ certificationTeacherMgr, onFilterChange, orgs, orgMgr, hideFilter }) {



    const handleOnChange = (value, fieldName,) => {
        if (onFilterChange)
            onFilterChange(fieldName, value);
    }

    const handleClearFilter = () => {
        localStorage.setItem("CurrentPage", 1);
        certificationTeacherMgr.filterData({ groups: [], orgs: null, districts: null, schools: null, users: null, candidate: null, status: null, IsActive: 'UNK' });
    }
    const onTreeCleanOrg = () => {
        handleOnChange([], "groups");
    }
    const onTreeSelect = (vals, field) => {
        //I actually filter by the user. because those users will be associated with the org/dis/school :)

        let newTreeIds = orgMgr?.findSelected(vals);
        if (handleOnChange)
            handleOnChange(newTreeIds, field);
    }

    return (
        <>
            <div className="d-flex justify-content-center contentFilter">
                <div>
                    <div className="filterTitle">Teacher Evaluator Certifications</div>
                    <div className="filterControl">
                        <InputField
                            title='Find a Candidate'
                            value={certificationTeacherMgr?.Filter?.candidate ?? ''}
                            fieldName='candidate'
                            disableError={true}
                            placeholder='Candidate'
                            onChange={handleOnChange}>
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            title='Status'
                            value={certificationTeacherMgr?.Filter?.status ?? 'UNK'}
                            fieldName='status'
                            disableError={true}
                            onChange={handleOnChange}>
                            <SelectListControl textValuePairs={[{ Text: 'Not Started', Value: 0 },
                            { Text: 'Part I In Progress', Value: 1 },
                            { Text: 'Pass Part 1', Value: 2 },
                            { Text: 'Fail Part 1', Value: 3 },
                            { Text: 'Part II In Progress', Value: 4 },
                            { Text: 'Passed', Value: 5 },
                            { Text: 'Failed', Value: 6 },
                            { Text: 'Manually Passed', Value: 7 }
                            ]} />
                        </InputField>
                    </div>
                    <div className="filterControl">
                        <InputField
                            id='groups'
                            title='Organization'
                            value={certificationTeacherMgr?.Filter?.groups ?? []}
                            fieldName='groups'
                            disableError={true}
                            placeholder='Select'
                            onChange={handleOnChange}>
                            <CheckTreePicker
                                height={320}
                                width={400}
                                className={'filter-control-input'}
                                childrenKey="Children"
                                labelKey="Name"
                                valueKey="Id"
                                data={orgs ?? []}
                                onClean={onTreeCleanOrg}
                                onSelect={(a, v) => onTreeSelect(v, 'groups')}
                                placement="autoHorizontalStart"
                            />
                        </InputField>
                    </div>

                    <div className="filterControl btn-clear">
                        <ButtonControl
                            onClick={handleClearFilter}
                            type="cancel">
                            Clear
                        </ButtonControl>
                    </div>
                </div>
            </div >
        </>
    );
}