import React, { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

const TextBoxControl = ({
    field,
    value,
    onChange,
    onBlur,
    className,
    disabled,
    maxLength,
    loading,
    type,
    preventPasswordAutoComplete,
    error,
    props
}) => {
    const inputRef = useRef();
    useEffect(() => {
        inputRef.current.value = value;
    }, [value]);

    return (
        <>
            <input id={"field_txp_" + field.FieldName}
                placeholder={field.PlaceHolder}
                ref={inputRef}
                onBlur={(context) => onBlur(context, field)}
                defaultValue={value}
                className={error === 'true' ? `input-error ${className ?? ''}` : className}
                disabled={disabled}
                onChange={(e) => onChange(e, field)}
                type={type ?? 'text'}
                maxLength={maxLength}
                autoComplete={preventPasswordAutoComplete ? 'new-password' : null}
            />
        </>
    );
}

export default TextBoxControl;