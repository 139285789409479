import _ from "lodash";
import moment from "moment-timezone";
import react, { useState } from "react";
import { useEffect } from "react";
import { faClose as completeIcon } from '@fortawesome/free-solid-svg-icons'
import { TableControl } from "../../../../components/controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormatUTCDate } from "../../../../utilities/DateFormater";
import { useAppUserManager } from "../../../../hooks/useManagers";



const FeedbackTable = ({ filteredItems, onView, onDelete }) => {
    const [rows, setRows] = useState([]);
    const appUserMgr = useAppUserManager();
    const [columns] = useState([
        {
            header: 'Name',
            dataProp: "Name",
            width: '26%',
            canSort: true
        },
        {
            header: 'Email',
            dataProp: 'Email',
            width: '26%',
            canSort: true
        },
        {
            header: 'Institution',
            dataProp: 'Institution',
            width: '20%',
            canSort: true
        },
        {
            header: 'Create Date',
            dataProp: 'CreatedDate',
            datatype: 'dateTime',
            width: '20%',
            canSort: true
        }
    ]);



    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            _.each(filteredItems, (c) => {
                newRows.push({
                    ...c,
                });
            });
            setRows(newRows);
        }
    }, [filteredItems]);

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                permission='Feedback'
                useIntegratedPaging={['10', '25', '50', '100']}
                onView={(row) => onView(row)}
                onCustom1={appUserMgr?.canDelete('Feedback') && { action: (row) => <FontAwesomeIcon title="Dimiss Feedback" className={'btn-icon-fa'} icon={completeIcon} onClick={() => onDelete(row)} /> }}
            />
        </div>
    </>
}

export default FeedbackTable;