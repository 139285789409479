
import React, { useState } from 'react';
import '../dev.scss';
import { useAuthState } from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { ItemServiceFactory } from '../../../services/ItemServiceFactory';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { useHookstate } from '@hookstate/core';
import globalAppUserStore from '../../../stores/globalAppUserStore';
import globalItemStore from '../../../stores/globalItemStore';
import ItemManager from '../../../managers/ItemManager';
import AppUserManager from '../../../managers/AppUserManager';

const Test = () => {
  const navigate = useNavigate();
  const [selectedItemName, setSelectedItemName] = useState("");
  const [idList, setIdList] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [itemQuery, setItemQuery] = useState(JSON.stringify({ "Skip": 0, "Take": 2147483647, "SortBy": "CreatedDate", "SortOrder": 1 }));
  const [selectedItem, setSelectedItem] = useState(null);
  const [rawData, setRawData] = useState("");
  const [loading, setLoading] = useState(false);
  const globalAuthState = useAuthState();
  const globalAppUserState = useHookstate(globalAppUserStore);
  const globalItemState = useHookstate(globalItemStore);
  const appUserManager = new AppUserManager(globalAuthState, globalAppUserState);

  const selectItem = (context) => {
    setSelectedItemName(context.target.value);
    if (context.target.value) {
      loadTestItemList(context.target.value);
    }
  }

  const selectId = (context) => {

    if (context.target.value !== "unknown") {
      if (context.target.value) {
        setSelectedId(context.target.value);
      }
      else {
        setSelectedId(null);
      }
    }
  }

  const buildItemOptions = () => {
    let render = [];
    for (const key in ItemServiceFactory.ItemServiceEnum) {
      render.push(<option key={key} value={ItemServiceFactory.ItemServiceEnum[key]}>{key}</option>);
    }
    return render;
  }


  const buildIdOptions = () => {
    let render = [];
    idList.forEach(id => {
      render.push(<option key={id} value={id}>{id}</option>);
    });
    return render;
  }

  const getItemServiceMananger = (name) => {
    return new ItemManager(name, globalAuthState, globalAppUserState, globalItemState);
  }

  const saveTestItem = (name) => {
    if (!appUserManager.canWrite(name)) {
      alert("Write Permission Denied.")
      return;
    }

    const itemMananger = getItemServiceMananger(name);

    if (selectedItem) {
      if (selectedId) {
        setLoading(true);
        itemMananger.save(selectedItem).then(result => {
          setRawData(JSON.stringify(result));
          setLoading(false);
        });
      }
      else {
        alert("Please get a single item..");
      }
    }
  }

  const setQueryItem = (context) => {
    try {
      const jsonData = JSON.parse(context.target.value);
      setItemQuery(jsonData);
    }
    catch (e) {
      console.log(e);
    }
  }

  const createTestItem = (name) => {
    if (!appUserManager.canWrite(name)) {
      alert("Write Permission Denied.")
      return;
    }

    //Config Item Only...
    if (name.toLowerCase() === "config") {
      setSelectedItem(null);
      const itemMananger = getItemServiceMananger(name);
      setLoading(true);
      itemMananger.save({
        "AppId": null,
        "Name": "TESTCREATE",
        "Value": new Date(),
        "SettingType": 0,
        "Enviorment": "DEV",
        "IsEncrypted": false,
        "ModuleTypeId": 1,
      },).then(result => {
        setRawData(JSON.stringify(result));
        setLoading(false);
      });
    }
  }

  const loadTestItem = (name) => {
    if (!appUserManager.canView(name)) {
      alert("View Permission Denied.")
      return;
    }

    setSelectedItem(null);
    const itemMananger = getItemServiceMananger(name);

    if (selectedId) {
      setLoading(true);
      itemMananger.get(selectedId).then(result => {
        setRawData(JSON.stringify(result));
        if (result.Items.length > 0) {
          const itemToSave = result.Items.first();
          setSelectedItem(itemToSave);
        }
        setLoading(false);
      });
    }
    else {
      alert("Please select a Id...");
    }
  }

  const loadTestItemList = (name) => {
    if (!appUserManager.canView(name)) {
      alert("View Permission Denied.")
      return;
    }

    setSelectedItem(null);
    const itemMananger = getItemServiceMananger(name);
    setLoading(true);
    itemMananger.list(itemQuery).then(result => {
      setLoading(false);
      setRawData(JSON.stringify(result));
      const listItemIds = [];
      if (result.Items) {
        result.Items.forEach(item => {
          listItemIds.push(item._id);
        });
      }
      setIdList(listItemIds);
    });
  }

  const deleteTestItem = (name) => {
    if (!appUserManager.canDelete(name)) {
      alert("Delete Permission Denied.")
      return;
    }

    setSelectedItem(null);
    const itemMananger = getItemServiceMananger(name);
    if (selectedId) {
      const confirm = window.confirm("Are you sure you want to delete id: " + selectedId + " from " + name);
      if (confirm) {
        setLoading(true);
        itemMananger.delete(selectedId, false).then(result => {
          setLoading(false);
          setRawData(JSON.stringify(result));
        });
      }
    }
  }

  const hardDeleteTestItem = (name) => {
    if (!appUserManager.canHardDelete(name)) {
      alert("Hard Delete Permission Denied.")
      return;
    }


    if (name.toLowerCase() === "config") {
      setSelectedItem(null);
      const itemMananger = getItemServiceMananger(name);
      if (selectedId) {
        const confirm = window.confirm("Are you sure you want to hard delete id: " + selectedId + " from " + name);
        if (confirm) {
          setLoading(true);
          itemMananger.delete(selectedId, true).then(result => {
            setLoading(false);
            setRawData(JSON.stringify(result));
          });
        }
      }
    }
  }

  const loadTestItemMetadata = (name) => {
    setSelectedItem(null);
    const itemService = ItemServiceFactory.GetItemService(name, globalAuthState);
    setLoading(true);
    itemService.uiMetadata().then(result => {
      setLoading(false);
      setRawData(JSON.stringify(result));
    });
  }

  return (
    <div className="test">
      <div>
        <h3>Select an item...</h3>
        <select onChange={selectItem}>
          <option value="unknown">Please Select an Item...</option>
          {buildItemOptions()}
        </select>
      </div>
      {selectedItemName && !loading ?
        <div>
          <div><button onClick={() => navigate(`/admin/${selectedItemName}`)}>Administrate {selectedItemName}</button></div>
          <h3>Load Item Data...</h3>
          <div><button onClick={() => loadTestItemList(selectedItemName)}>Load {selectedItemName}Item List</button></div>
          <div><textarea style={{ width: "100%", height: "50px" }} defaultValue={JSON.stringify(itemQuery)} onChange={setQueryItem}></textarea></div>
          <hr />
          <h3>Load Item Metadata...</h3>
          <div><button onClick={() => loadTestItemMetadata(selectedItemName)}>Load {selectedItemName}Item Metadata</button></div>
          <hr />
          <h3>Run Action On... </h3>
          <div>

            <select onChange={selectId}>
              <option value="unknown">Please Select an Id...</option>
              {buildIdOptions()}
            </select>
          </div>
          {selectedId &&
            <>
              <div><button onClick={() => loadTestItem(selectedItemName)}>Load {selectedItemName}Item</button></div>

              {selectedItem &&
                <div><button onClick={() => saveTestItem(selectedItemName)}>Save {selectedItemName}Item</button></div>
              }
              <div><button onClick={() => deleteTestItem(selectedItemName)}>Delete {selectedItemName}Item</button></div>
              {selectedItemName.toLowerCase() === "config" &&
                <div><button onClick={() => hardDeleteTestItem(selectedItemName)}>Hard Delete {selectedItemName}Item</button></div>
              }
            </>
          }
          {selectedItemName.toLowerCase() === "config" &&
            <div><button onClick={() => createTestItem(selectedItemName)}>Create {selectedItemName}Item</button></div>
          }
          <hr />
          <div>Raw JSON...</div>

          <JSONPretty id="json-pretty" data={rawData}></JSONPretty>
        </div> :
        (loading && <div className='loading'>Loading {selectedItemName}...</div>)}

    </div>
  );
}

export default Test;

